import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as b2cActions from "../../../redux/actions/b2c-actions";
import Card from "../../../images/cap-partner-card.png";
import Wizard from "../shared/wizard/wizard.jsx";
import { Row, Col, Button } from "antd";
import ReactGA from 'react-ga4';
import postscribe from 'postscribe';
import config from "../../../../../config";

// ReactGA.plugin.require('ecommerce');


class ThankYou extends Component {
  async componentDidMount() {
    postscribe("#thank-you","<script> (function(h,o,t,j,a,r){ h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)}; h._hjSettings={hjid:2750169,hjsv:6}; a=o.getElementsByTagName('head')[0]; r=o.createElement('script');r.async=1; r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv; a.appendChild(r); })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv='); </script>");
     var order_response = JSON.parse(localStorage.getItem("order_response"));
    var b2c_order_amount = JSON.parse(localStorage.getItem("b2c_order_amount"));
    console.log("order_response -------------- ");
    console.log(order_response);
    console.log("b2c_order_amount ------------- ")
    console.log(b2c_order_amount);

    if(config.server == "prod"){
      ReactGA.initialize('G-N02B8BDNND');

    if(order_response && b2c_order_amount){
      
       ReactGA.event({
         category: 'Ecommerce',
         action: 'Purchase',
         transaction_id: order_response['orderNumber'],
         affiliation: 'FP',
         value: b2c_order_amount,
         currency: 'USD',
         shipping: 0,
         tax: 0,
      });

       console.log('Transaction data sent to GA4');
      
      // ReactGA.plugin.execute('ecommerce', 'addTransaction', {
      //   id: order_response['orderNumber'],
      //   affiliation: 'FP',
      //   revenue: b2c_order_amount,
      //   shipping: '0',
      //   tax: '0',
      //   currency: 'USD'
      // });
      // ReactGA.plugin.execute('ecommerce', 'send');
      // ReactGA.plugin.execute('ecommerce', 'clear');
      // console.log('inserted  ');    
    }
  }
    
      
    
    if (order_response) {
      window.scrollTo(0, 0);
      localStorage.removeItem("planType");
      localStorage.removeItem("planTerm");
      localStorage.removeItem("traveller");
      localStorage.removeItem("billing_information");
      localStorage.removeItem("order_response");
      localStorage.removeItem("promo");
      localStorage.removeItem("qs");
      localStorage.removeItem("decodedParams");
    } else {
      await this.props.flushOrderData();
      this.props.history.push(this.props.b2cBaseUrl + "/purchase");
    }
  }
  async componentWillUnmount() {
    //await this.props.flushOrderData();
  }

  _bindTravellerInfo(travellers) {
    return travellers.map((traveller, idx) => (
      <div key={idx}>
        <Row>
          <Col xs={24} sm={24} md={24} lg={10} xl={10}>
            {traveller.first_name + " " + traveller.last_name}
          </Col>
          </Row>
        </div>
    ));
  }

  render() {
    return (
      <section id="banner">
        <Wizard wizardActiveClass="step5" />
        <div className="thank-you thank-you-partner" id="thank-you">
          <div
            className="wrap"
            style={{
              background: "#fff",
              border: "2px solid #58b0e2",
              padding: 12,
            }}
          >
            <Row gutter={24}>
              <Col sm={10} xs={24}>
                <div style={{ position: "relative", float: "right" }}>
                  <div
                    id="membership-card"
                    style={{ height: "200px", width: "350px" }}
                  >
                    <img src={Card} alt="" style={{ maxWidth: "350px" }} />
                    <div className="number">
                      {this.props.orderData && this.props.orderData.membership
                        ? this.props.orderData.membership
                        : "FPST-YYYYYYYYYY"}{" "}
                    </div>
                  </div>
                </div>
              </Col>
              <Col sm={14} xs={24}>
                <p
                  style={{
                    marginTop: "20px",
                    color: "#003da5",
                    fontSize: "18px",
                  }}
                >
                  Thank You for Purchasing Care & Assistance Plus.
                  <br />
                  Your Plan Number is:
                  <br />
                </p>
                <div className="member-number">
                  {this.props.orderData && this.props.orderData.membership
                    ? this.props.orderData.membership
                    : "FPST-YYYYYYYYYY"}{" "}
                </div>
                <p
                  style={{
                    marginTop: "20px",
                    color: "#003da5",
                    fontSize: "18px",
                    marginBottom: "3px"
                  }}
                > Traveler(s): <br/></p>
                {this._bindTravellerInfo(this.props.personalDetails)}
                <br/>
                <p style={{ color: "#948e8e" }}>
                  Please keep a copy of your plan number and/or CAP™ Card with
                  you during travel.
                </p>
              </Col>
            </Row>
          </div>
          <div className="wrap">
            <Row gutter={24} style={{ marginTop: 30, marginBottom: 30 }}>
              <Col style={{ textAlign: "center" }}>
                <Button
                  type="primary"
                  style={{ background: "#58b0e2", marginRight: "20px" }}
                  onClick={() =>
                    (window.location = "https://www.captravelassistance.com")
                  }
                >
                  Return Home
                </Button>
                <Button
                  type="primary"
                  style={{ background: "#58b0e2" }}
                  onClick={() =>
                    window.open(
                      "/cap-card.html?mid=" +
                        (this.props.orderData && this.props.orderData.membership
                          ? this.props.orderData.membership
                          : "FPST-YYYYYYYYYY"),
                      "_blank"
                    )
                  }
                >
                  Save a Copy
                </Button>
              </Col>
            </Row>
            <div className="row" style={{ textAlign: "center" }}>
              <div
                className="full-width"
                style={{ color: "black", fontSize: "20px" }}
              >
                <p style={{ color: "#203da5", fontSize: 18 }}>
                  <strong>
                    Please note that you will receive a confirmation email with
                    a summary of your plan and all the details you need to
                    access your program benefits.
                  </strong>
                </p>
                <p></p>
                <p style={{ color: "#222", fontSize: 18 }}>
                  <strong>What if I need help while I’m traveling?</strong>
                  <br />
                  For immediate assistance, call our Global Customer Response
                  Center at +1-619-717-8549 or text +1-954-727-1916. For
                  verification purposes, please have your above plan number on
                  hand.
                </p>
                <p style={{ color: "#222", fontSize: 18 }}>
                  <strong>
                    How do I access the Care & Assistance Plus portal?
                  </strong>
                  <br />
                  You will be sent a welcome email which will include a copy of
                  your CAP card, your login credentials for the on-line Care &
                  Assistance Plus portal, and the mobile app.
                </p>
                <p style={{ color: "#222", fontSize: 18 }}>
                  <strong>
                    What if I don’t get the Care & Assistance Plus welcome
                    email?
                  </strong>
                  <br />
                  First, check your junk and spam folders. If you don’t see it,
                  please call +1-866-340-8569 or email{" "}
                  <a href="mailto:capsupport@wwfocus.com">
                    capsupport@wwfocus.com
                  </a>{" "}
                  and let us know. For verification purposes, please have your
                  above plan number on hand.
                </p>
              </div>
            </div>
            {/* <div className="row">
              <div className="full-width" style={{color: 'black', fontSize: '20px'}}>
              <strong style={{marginBottom: '10px', display: 'block'}}>What to Do if You Need Help During Travel</strong>
                <p>For immediate assistance at any time during your trip, please call our Global Customer Response Center at <strong>+1-619-717-8549</strong> or text
                <strong> +1-954-727-1916</strong>. Please have your plan number and/or CAP™ Card readily available when you call, for verification purposes.</p>
                <strong style={{marginBottom: '10px', display: 'block'}}>How to Access the CAP™ Portal</strong>
                <p>You will receive a <i style={{color: '#2e6fad', fontStyle: 'italic'}}>Welcome To CAP™</i> email with login credentials for the online CAP™ Travel Assistance Portal. A copy of your CAP™ Card
                can be found attached to this email. You will also receive a <i style={{color: '#2e6fad', fontStyle: 'italic'}}>purchase confirmation</i> email that includes a summary of your plan.</p>
                <strong style={{marginBottom: '10px', display: 'block'}}>What to Do if You Cannot Find the CAP™ Email in Your Inbox, Junk or Spam Folders</strong>
                <p>Please call <strong>+1-866-340-8569</strong> or email <a style={{color: '#2e6fad', textDecoration: 'underline'}} href="mailto: capsupport@wwfocus.com"><strong>capsupport@wwfocus.com</strong></a> for assistance. Please have your plan number and/or CAP™ Card
                readily available when you call.</p>
                <strong style={{marginBottom: '10px', display: 'block'}}>Share Your Experience</strong>
                <p>We’d love to hear from you. Feel free to leave us a review on Google, recommend us on Facebook, and follow us online.</p>
                <a href={(this.props.match.params.orgId) ? `https://www.captravelassistance.com/partner/purchase/${this.props.match.params.orgId}` : `https://www.captravelassistance.com/cap/purchase`} style={{color: '#2e6fad', textDecoration: 'underline'}}>Continue Shopping</a>
              </div>
            </div> */}
          </div>
        </div>
      </section>
    );
  }
}
ThankYou.propTypes = {
  orderData: PropTypes.object,
  SaveStep: PropTypes.func,
  flushOrderData: PropTypes.func,
  b2cBaseUrl: PropTypes.string,
};
function mapStateToProps(state) {
  return {
    ...state.b2c,
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...b2cActions }, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(ThankYou);