import React from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import IntlMessages from "../../../../services/intlMesseges";
import * as PromoActions from "../../../../redux/actions/promo-actions";
import { notifyUser } from "../../../../services/notification-service";
import AppLocale from "../../../../languageProvider";
import {
  Typography,
  Form,
  Input,
  Select,
  Button,
  Row,
  Col,
  Spin,
  Switch,
  DatePicker,
} from "antd";
import moment from "moment";

const { Option } = Select;
const dateFormat = "MM/DD/YYYY";
const dateFormatY = "YYYY/MM/DD";

class EditPromoCode extends React.Component {
  constructor(props) {
    super(props);
    this.onTypeChange = this.onTypeChange.bind(this);
  }

  onTypeChange(value) {
    this.setState({ discountType: value });
  }
  state = {
    loading: true,
    promoOfferId: 0,
    activeDisabled: false,
    allDiscountTypes: [
      { id: "N", name: "Fixed", type: "USD $" },
      { id: "P", name: "Percent", type: "%" },
    ],
  };

  async componentDidMount() {
    if (
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.id &&
      this.props.match.params.id !== "new"
    ) {
      this.setState({ promoOfferId: this.props.match.params.id });
      const promo = await this.props.getPromoCode(this.props.match.params.id);
      this.setState(promo.data);
      if (moment(promo.data.expireDate).isBefore(new Date())) {
        this.setState({ activeDisabled: true });
      }
      this.setState({ loading: false });
    } else {
      this.setState({ isActive: true, loading: false });
    }
  }

  submitFormAdd = (data) => {
    const { language } = this.props;
    const currentAppLocale = AppLocale[language.locale];
    this.props
      .updatePromoCode(data)
      .then((response) => {
        if (response.error) {
          notifyUser(response.error.message, "error");
          this.setState({ loading: false });
        } else {
          notifyUser(currentAppLocale.messages["admin.promo.added"], "success");
          //notifyUser("Promo added successfully!", 'success');
          this.props.history.push("./");
          this.setState({ loading: false });
        }
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };

  submitFormEdit = (data) => {
    const { language } = this.props;
    const currentAppLocale = AppLocale[language.locale];
    this.props
      .updatePromoCode(data)
      .then((response) => {
        if (response.error) {
          notifyUser(response.error.message, "error");
          this.setState({ loading: false });
        } else {
          this.props.history.push("./");
          this.setState({ loading: false });
          notifyUser(
            currentAppLocale.messages["admin.promo.edited"],
            "success"
          );
          //notifyUser("Promo updated successfully!", 'success');
        }
      })
      .catch((_err) => {
        this.setState({ loading: false });
      });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        Object.keys(values).map(function(key, _idx) {
          if (values[key] == null) {
            values[key] = "";
          } else {
            if (typeof values[key] == "string") {
              values[key] = values[key].trim();
            }
          }
        });
        this.setState({ loading: true });
        if (this.state.promoOfferId && this.state.promoOfferId > 0) {
          values["promoOfferId"] = this.state.promoOfferId;
          values["isActive"] = this.state.isActive;
          this.submitFormEdit(values);
        } else {
          values["isActive"] = this.state.isActive;
          this.submitFormAdd(values);
        }
      }
    });
  };

  onSwitchChange = (checked, e) => {
    this.setState({ isActive: checked });
    if (checked == true) {
      notifyUser("Activated successfully!", "success");
    } else {
      notifyUser("Inactivated successfully!", "success");
    }
  };

  onInputCode = (e) => {
    e.target.value = e.target.value.toUpperCase();
  };

  setStartDate = (_obj, dateString) => {
    this.setState({ startDate: dateString });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { formLayout } = this.state;
    const formItemLayout =
      formLayout === "horizontal"
        ? {
            labelCol: { span: 4 },
            wrapperCol: { span: 14 },
          }
        : null;
    const discountTypeSelector = getFieldDecorator("discountType", {
      rules: [
        {
          required: true,
          message: <IntlMessages id="admin.input.required" />,
        },
      ],
      initialValue: this.state.discountType,
      onChange: this.onTypeChange,
    })(
      this.state.promoOfferId > 0 ? (
        <Select disabled>
          {this.state.allDiscountTypes.map(function(item) {
            return <Option key={item.id}>{item.name}</Option>;
          })}
        </Select>
      ) : (
        <Select>
          {this.state.allDiscountTypes.map(function(item) {
            return <Option key={item.id}>{item.name}</Option>;
          })}
        </Select>
      )
    );
    return (
      <div>
        <Row gutter={24}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Typography.Title level={4}>
              {this.state.promoOfferId === 0 ? (
                <IntlMessages id="admin.promo.add" />
              ) : (
                <div>
                  <IntlMessages id="admin.promo.edit" />
                </div>
              )}
            </Typography.Title>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Button
              type="primary"
              className="right-fl"
              htmlType="button"
              onClick={() => this.props.history.push("./")}
            >
              <IntlMessages id="admin.userlisting.back" />
            </Button>
          </Col>
        </Row>
        <hr />
        <div>
          <Spin spinning={this.state.loading}>
            <Form layout={formLayout} onSubmit={this.handleSubmit}>
              <Row gutter={24}>
                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                  <Form.Item
                    {...formItemLayout}
                    label={<IntlMessages id="admin.promo.code" />}
                  >
                    {getFieldDecorator("code", {
                      rules: [
                        {
                          required: true,
                          message: <IntlMessages id="admin.input.required" />,
                        },
                    //     {
                    //   pattern: new RegExp(/^[0-9']+$/),
                    //   message: 'Please enter a valid number!'
                    // },
                      ],
                      initialValue:
                        this.state.code === null ? "" : this.state.code,
                    })(
                      this.state.promoOfferId > 0 ? (
                        <Input maxLength={50} disabled />
                      ) : (
                        <Input maxLength={50} onInput={this.onInputCode} />
                      )
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                  <Form.Item
                    {...formItemLayout}
                    label={<IntlMessages id="admin.promo.name" />}
                  >
                    {getFieldDecorator("name", {
                      rules: [
                        {
                          required: true,
                          message: <IntlMessages id="admin.input.required" />,
                        },
                      ],
                      initialValue:
                        this.state.name === null ? "" : this.state.name,
                    })(<Input maxLength={50} />)}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                  <Form.Item
                    {...formItemLayout}
                    label={<IntlMessages id="admin.promo.description" />}
                  >
                    {getFieldDecorator("description", {
                      rules: [
                        {
                          required: true,
                          message: <IntlMessages id="admin.input.required" />,
                        },
                      ],
                      initialValue:
                        this.state.description === null
                          ? ""
                          : this.state.description,
                    })(<Input.TextArea rows={2} maxLength={500} />)}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                  <Form.Item
                    {...formItemLayout}
                    label={<IntlMessages id="admin.promo.discountType" />}
                  >
                    {discountTypeSelector}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                  <Form.Item
                    {...formItemLayout}
                    label={
                      this.state.discountType === "P" ? (
                        <IntlMessages id="admin.userlisting.percent" />
                      ) : (
                        <IntlMessages id="admin.userlisting.dollars" />
                      )
                    }
                    // onChange={changeinfo}
                  >
                    {getFieldDecorator("discountFigure", {
                      rules: [
                        {
                          required: true,
                          message: <IntlMessages id="admin.input.required" />,
                        },
                        {
                          pattern: new RegExp(/^[0-9']+$/),
                          message: 'Please enter a valid number!'
                        },
                      ],
                      initialValue:
                        this.state.discountFigure === null
                          ? ""
                          : this.state.discountFigure,
                    })(
                      this.state.promoOfferId > 0 ? (
                        <Input disabled />
                      ) : (
                        <Input />
                      )
                    )}
                  </Form.Item>
                </Col>
                {/* <Col xs={24} sm={12} md={1} lg={1} xl={1}>
                  <span>{textType}</span>
                </Col> */}
              </Row>
              <Row gutter={24}>
                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                  <Form.Item
                    label={<IntlMessages id="admin.promo.startFrom" />}
                  >
                    {getFieldDecorator("effectiveDate", {
                      rules: [
                        {
                          required: true,
                          message: <IntlMessages id="admin.input.required" />,
                        },
                      ],
                      initialValue: this.state.effectiveDate
                        ? moment(this.state.effectiveDate, dateFormatY)
                        : null,
                    })(
                      this.state.promoOfferId > 0 ? (
                        <DatePicker
                          format={dateFormat}
                          placeholder="-select-"
                          disabled
                        />
                      ) : (
                        <DatePicker
                          format={dateFormat}
                          placeholder="-select-"
                          disabledDate={(current) => {
                            return (
                              current && current < moment().subtract(1, "days")
                            );
                          }}
                          onChange={this.setStartDate}
                        />
                      )
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                  <Form.Item label={<IntlMessages id="admin.promo.endTo" />}>
                    {getFieldDecorator("expireDate", {
                      rules: [
                        {
                          required: true,
                          message: <IntlMessages id="admin.input.required" />,
                        },
                      ],
                      initialValue: this.state.expireDate
                        ? moment(this.state.expireDate, dateFormatY)
                        : null,
                    })(
                      this.state.promoOfferId > 0 ? (
                        <DatePicker
                          format={dateFormat}
                          placeholder="-select-"
                          disabled
                        />
                      ) : (
                        <DatePicker
                          format={dateFormat}
                          placeholder="-select-"
                          disabledDate={(current) => {
                            return (
                              current &&
                              current <
                                moment(this.state.startDate).add(1, "days")
                            );
                          }}
                        />
                      )
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                  <Form.Item
                    {...formItemLayout}
                    label={<IntlMessages id="admin.promo.status" />}
                  >
                    <Switch
                      checkedChildren="Active"
                      unCheckedChildren="Inactive"
                      checked={this.state.isActive}
                      onChange={this.onSwitchChange}
                      name="isActive"
                      disabled={this.state.activeDisabled}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Item>
                    <Button
                      type="primary"
                      style={{ display: "inline-block", marginRight: "10px" }}
                      className="def-blue"
                      htmlType="submit"
                    >
                      {this.state.promoOfferId > 0 ? (
                        <IntlMessages id="admin.promo.update" />
                      ) : (
                        <IntlMessages id="admin.promo.add" />
                      )}
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Spin>
        </div>
      </div>
    );
  }
}

EditPromoCode.propTypes = {
  location: PropTypes.object,
  updateUser: PropTypes.func,
  getAllUserRoles: PropTypes.func,
};

function mapStateToProps(state) {
  return { ...state.user, ...state.language };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...PromoActions }, dispatch);
}
const WrappedForm = Form.create({ name: "edit-promo-code" })(EditPromoCode);
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    WrappedForm
  )
);
