//import Home from "../components/home/home.jsx";
import B2c from "../components/b2c/b2c.jsx";
import B2B2C from "../components/b2b2c/b2b2c.jsx";
import UserDash from "../components/user-dash/user-dash.jsx";
import ResellerDash from "../components/reseller-dash/reseller-dash.jsx";
import HybridDash from "../components/hybrid-dash/hybrid-dash.jsx";
import AgentDash from "../components/agent-dash/agentDash";
import AdminDash from "../components/admin-dash/admin-dash.jsx";
import Login from "../components/login/login.jsx";
import LoginAuto from "../components/login/loginAuto.jsx";
import ForgotPassword from "../components/forgot-password/forgot-password";
import ForgotPasswordAuto from "../components/forgot-password/forgot-passwordAuto";
import ResetPassword from "../components/reset-password/reset-password";
import NotFound from "../components/shared/components/404.jsx";
import reloadId from "../components/shared/components/reloadId.js";
import ServerError from "../components/shared/components/500.jsx";
import ManageCigna from "../components/cigna";
import ManagePlanUpgrade from "../components/plan-upgrade";
import PurchaseUpgradedPlan from "../components/plan-upgrade/purchase-plan";
import childParentPurchase from "../components/family-purchase/index.jsx";
import ThankYouPage  from "../components/family-purchase/thank-you/thank-you.jsx";
import RejectCignaStatus from "../components/cigna/reject.js";
import acceptCignaStatus from "../components/cigna/accept.js";
export default {
  routes: [
    {
      path: '/cap',
      component: B2c,
      exact: false
    },
    {
      path: '/b2c',
      component: reloadId,
      exact: false
    },
    {
      path: '/login',
      component: Login,
      exact: true
    },
    {
      path: '/loginAuto',
      component: LoginAuto,
      exact: true
    },
    {
      path: '/cigna',
      component: ManageCigna,
      exact: true
    },
    {
      path: '/cigna/approvecreateuser/:id',
      component: acceptCignaStatus,
      exact: true
    },
    {
      path: '/cigna/rejectcreateuser/:id',
      component: RejectCignaStatus,
      exact: true
    },
    {
      path: '/ManagePlanUpgrade',
      component: ManagePlanUpgrade,
      exact: true
    },
    {
      path: '/plan-upgrade/purchase-plan',
      component: PurchaseUpgradedPlan,
      exact: true
    },

    {
      path: '/family-purchase/:familypurchaseId',
      component: childParentPurchase,
      exact: true
    },
    {
      path: '/family-purchase/:familypurchaseId/thank-you/',
      component: ThankYouPage,
      exact: true
    },

    {
      path: '/',
      component: Login,
      exact: true
    },
    {
      path: '/forgot-password',
      component: ForgotPassword,
      exact: true
    },
    {
      path: '/forgot-passwordAuto',
      component: ForgotPasswordAuto,
      exact: true
    },
    {
      path: '/reset-password',
      component: ResetPassword,
      exact: true
    },
    {
      path: '/404',
      component: NotFound,
      exact: true
    },
    ,
    {
      path: '/500',
      component: ServerError,
      exact: true
    },
    {
      path: '/partner',
      component: B2B2C,
      exact: false
    },
    {
      path: '/hybrid',
      component: HybridDash,
      exact: false
    }
  ],
  /* redirects: [
    {
      from: "/404",
      to: "/admin-dash",
      status: 301
    }
  ], */
  private: [
    {
      path: '/admin',
      component: AdminDash,
      exact: false,
      roles: ['Administrator', 'CSR', 'Super Admin'],
      status: 200
    },
    {
      path: '/user',
      component: UserDash,
      exact: false,
      roles: [
        'Companion',
        'Membership',
        'Division Manager',
        'Travel Manager',
        'SuperAdmin',
        'Traveler',
        'Employee',
        'Admin',
        'Limited Access'
      ],
      status: 200
    },
    {
      path: '/reseller',
      component: ResellerDash,
      exact: false,
      roles: [
        'Companion',
        'Membership',
        'Division Manager',
        'Travel Manager',
        'SuperAdmin',
        'Traveler',
        'Employee',
        'Admin',
        'Partner'
      ],
      status: 200
    },
    {
      path: '/hybrid',
      component: HybridDash,
      exact: false,
      roles: [
        'Companion',
        'Membership',
        'Division Manager',
        'Travel Manager',
        'SuperAdmin',
        'Traveler',
        'Employee',
        'Admin',
        'Partner',
        'Hybrid Admin'
      ],
      status: 200
    },
    {
      path: '/agent',
      component: AgentDash,
      exact: false,
      roles: ['Agent'],
      status: 200
    }
  ],
  urls: {
    '/user/organizations/': ['Administrator', 'CSR', 'Super Admin', 'Admin', 'Travel Manager']
  }
};
