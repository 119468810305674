import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import IntlMessages from "../../../../services/intlMesseges";
import { Row, Col, Typography, DatePicker, Button, Table, Input, Icon, Form, Select, Tabs } from "antd";
import * as UserService from "../../../../services/user-service";
import * as travellersReportActions from "../../../../redux/actions/travellers-report-actions";
import { ViewCell } from "../../../shared/components/helperCells";
import { CSVLink } from "react-csv";
import PrintComponents from "react-print-components";
import PrintAbleComponent from "../printable-component.jsx";
import moment from "moment";
import * as Permissions from "../../../../services/permissions";
import axios from "axios";
import travellerapi from "../../../../redux/api/travellers-report-api";
import * as UserServices from "../../../../services/user-service";

const { Option } = Select;
const dateFormat = "MM/DD/YYYY ";
const { TabPane } = Tabs;


class WhereareTravelers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activetravellerList: [],
      activeManualtravellerList:[],
      allactivetravellerList: [],
      pagination: {
        hideOnSinglePage: true,
        showTotal: (total, range) => {
          return (
            <span>
              <IntlMessages id="admin.text.showing" /> {range[0]}-{range[1]}{" "}
              <IntlMessages id="admin.text.of" /> {total}{" "}
              <IntlMessages id="admin.text.results" />
            </span>
          );
        },
        pageSize: 10,
        current: 1
      },
      paginationManual: {
        hideOnSinglePage: true,
        showTotal: (total, range) => {
          return (
            <span>
              <IntlMessages id="admin.text.showing" /> {range[0]}-{range[1]}{" "}
              <IntlMessages id="admin.text.of" /> {total}{" "}
              <IntlMessages id="admin.text.results" />
            </span>
          );
        },
        pageSize: 10,
        current: 1,
      },
      filters: {},
      date: '',
      showTable: false,
      formLayout: undefined,
      role: '',
      downloadSelectedValue: 'current',
      csvSearchFilters: {},
      tab:1
    };
    this.handleDateChange = this.handleDateChange.bind(this);
    this.callback=this.callback.bind(this)
  }
  handleSubmit() {
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        if (this.state.filters !== {}) {
          this.setState({ filters: {} })
        }
        if(this.state.tab =='1'){
          this.handleManualTableChange({ current: 1, pageSize: 10 }, {}, {});
        }
        else{
          this.handleTableChange({ current: 1, pageSize: 10 }, {}, {});
        }
        //this.handleTableChange({ current: 1, pageSize: 10 }, {}, {});
       

      }
    })
  }
  componentWillMount() {
    let currentUser = UserService.getUser();
    let userRole = currentUser.role;
    this.setState({ role: userRole })
  }
  viewactiveTravelerList = (pnrId, email) => {
    let url
    if(this.state.tab==1){
       url = "../../traveler-manualitinerary-viewer/" + pnrId;
    }
    else{
      url = "../../traveler-itinerary-viewer/" + pnrId + "/" + email;
    }
    
    window.open(url);
  };

  handleDateChange(e) {
    if (e !== null) {
      var changedDate = moment(e._d).format('YYYY-MM-DD')
      this.setState({ date: changedDate })
    }
  }
  callback(key) {
    this.setState({tab:key})
    if (key ==='1'){
      this.handleManualTableChange({ current: 1, pageSize: 10 }, {}, {});
    
    }else {
      this.handleTableChange({ current: 1, pageSize: 10 }, {}, {});
    
    }
    let filters = this.state.filters;
      if (filters['name']) {
        if (filters['name'].setSelectedKeys && typeof filters['name'].setSelectedKeys === 'function') {
          filters['name'].setSelectedKeys("");
          filters['name'].confirm();
        }
      }
      if (filters['divisionName']) {
        if (filters['divisionName'].setSelectedKeys && typeof filters['divisionName'].setSelectedKeys === 'function') {
          filters['divisionName'].setSelectedKeys("");
          filters['divisionName'].confirm();
        }
      }
      if (filters['parentOrganizationName']) {
        if (filters['parentOrganizationName'].setSelectedKeys && typeof filters['parentOrganizationName'].setSelectedKeys === 'function') {
          filters['parentOrganizationName'].setSelectedKeys("");
          filters['parentOrganizationName'].confirm();
        }
      }
         if (filters['pnrNumber']) {
        if (filters['pnrNumber'].setSelectedKeys && typeof filters['pnrNumber'].setSelectedKeys === 'function') {
          filters['pnrNumber'].setSelectedKeys("");
          filters['pnrNumber'].confirm();
        }
      }
      //filters['pnrNumber'] = { val: "", clearf: "", filter: false };
      
 
       filters['name'] = { val: "", clearf: "", filter: false };
       filters['divisionName'] = { val: "", clearf: "", filter: false };
       filters['parentOrganizationName'] = { val: "", clearf: "", filter: false };
       filters['pnrNumber'] = { val: "", clearf: "", filter: false };
        this.setState({ filters: filters,  searchText: "" });

  }

  getHeaderKeys = () => {
    let data = [
      {
        title: <IntlMessages id="admin.userlisting.name" />,
        dataIndex: "name",
        ...this.getColumnSearchProps("name")
      },
      {
        title: <IntlMessages id="admin.report.division" />,
        dataIndex: "divisionName",
        ...this.getColumnSearchProps("divisionName")
      },
      {
        title: <IntlMessages id="admin.report.pnr" />,
        dataIndex: "pnrNumber",
        ...this.getColumnSearchProps("pnrNumber")
      },
      {
        title: <IntlMessages id="admin.report.city" />,
        dataIndex: "city",

      },
      {
        title: <IntlMessages id="admin.report.departs" />,
        dataIndex: "departureDate",

        //...this.getColumnSearchProps("departureDate")
      },
      {
        title: <IntlMessages id="admin.report.arrival" />,
        dataIndex: "arrivalDate",

        //...this.getColumnSearchProps("departureDate")
      },


      {
        title: <IntlMessages id="admin.report.routing" />,
        dataIndex: "routing"
      },
      {
        title: <IntlMessages id="admin.manageOrders.Action" />,
        rowKey: "action",
        render: (_text, record) => (
          <span>
            <ViewCell
              onViewCell={() => {
                this.viewactiveTravelerList(record.pnrId, record.email);
              }}
            />
          </span>
        )
      }
    ];
    if (Permissions.isRole("CSR") || Permissions.isRole("Administrator")) {
      data.splice(2, 0, {
        title: <IntlMessages id="admin.report.organization" />,
        dataIndex: "parentOrganizationName",
        ...this.getColumnSearchProps("parentOrganizationName")
      })
    }
    return data;
  };

  getHeaderKeys2 = () => {
    let data = [
      {
        title: <IntlMessages id="admin.userlisting.name" />,
        dataIndex: "passengerName",
        ...this.getColumnSearchProps("passengerName")
      },
      {
        title: <IntlMessages id="admin.report.division" />,
        dataIndex: "divisionName",
        ...this.getColumnSearchProps("divisionName")
      },
      {
        title: <IntlMessages id="admin.report.pnr" />,
        dataIndex: "pnrNumber",
        ...this.getColumnSearchProps("pnrNumber")
      },
      {
        title: <IntlMessages id="admin.report.city" />,
        dataIndex: "city",

      },
      {
        title: <IntlMessages id="admin.report.departs" />,
        dataIndex: "departureDate",

        //...this.getColumnSearchProps("departureDate")
      },
      {
        title: <IntlMessages id="admin.report.arrival" />,
        dataIndex: "arrivalDate",

        //...this.getColumnSearchProps("departureDate")
      },


      {
        title: <IntlMessages id="admin.report.routing" />,
        dataIndex: "routing"
      },
      {
        title: <IntlMessages id="admin.manageOrders.Action" />,
        rowKey: "action",
        render: (_text, record) => (
          <span>
            <ViewCell
              onViewCell={() => {
                this.viewactiveTravelerList(record.pnrId, record.email);
              }}
            />
          </span>
        )
      }
    ];
    if (Permissions.isRole("CSR") || Permissions.isRole("Administrator")) {
      data.splice(2, 0, {
        title: <IntlMessages id="admin.report.organization" />,
        dataIndex: "parentOrganizationName",
        ...this.getColumnSearchProps("parentOrganizationName")
      })
    }
    return data;
  };

  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => {
      return (
        <div style={{ padding: 8 }}>
          <Input
            ref={node => {
              this.searchInput = node;
            }}
            value={selectedKeys}
            onChange={e =>
              setSelectedKeys(e.target.value ? e.target.value.trimLeft() : "")
            }
            onPressEnter={() =>
              this.handleSearch(
                selectedKeys,
                confirm,
                dataIndex,
                clearFilters,
                setSelectedKeys
              )
            }
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Button
            type="primary"
            onClick={() =>
              this.handleSearch(
                selectedKeys,
                confirm,
                dataIndex,
                clearFilters,
                setSelectedKeys
              )
            }
            disabled={selectedKeys != "" && selectedKeys !== null ? false : true}
            icon="search"
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            <IntlMessages id="admin.search" />
          </Button>
          <Button
            disabled={selectedKeys != "" && selectedKeys !== null ? false : true}
            onClick={() => this.handleReset(clearFilters, dataIndex)}
            size="small"
            style={{ width: 90 }}
          >
            <IntlMessages id="admin.reset" />
          </Button>
        </div>
      );
    },
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    }
  });

  handleSearch = (
    selectedKeys,
    confirm,
    dataIndex,
    clearFilters,
    setSelectedKeys
  ) => {
    if (selectedKeys === "") {
      return false;
    }

    this.setState({ searchText: selectedKeys });
    let filters = this.state.filters;
    filters[dataIndex] = {
      val: selectedKeys,
      clearf: clearFilters,
      filter: true,
      setSelectedKeys: setSelectedKeys,
      confirm: confirm
    };
    this.setState({ filters: filters });
    confirm();
  };

  handleReset = (clearFilters, dataIndex) => {
    clearFilters();
    let filters = this.state.filters;
    if (filters[dataIndex]) {
      if (filters[dataIndex].setSelectedKeys && typeof filters[dataIndex].setSelectedKeys === 'function') {
        filters[dataIndex].setSelectedKeys("");
      }
      //filters[dataIndex].confirm();
    }
    filters[dataIndex] = { val: "", clearf: "", filter: false };
    this.setState({ filters: filters });
    this.setState({ searchText: "" });
  };

  handleTableChange = (pagination, filters, sorter) => {
    this.setState({activetravellerList:[]})
    let currentUser = UserService.getUser();
    let orgId = currentUser.companyID;
    let userRole = currentUser.role;
    let date = this.state.date
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    filters.date = this.state.date
    this.setState({ loading: true, csvSearchFilters: filters });
    this.props
      .getWhereAreMyTravelers(orgId, userRole, {
        filters: filters,
        pagination: { page: pagination.current, pageSize: pagination.pageSize },
        sorter: sorter,

      })
      .then(resp => {
        pager.total = resp.result.totalElement;

        this.setState({
          activetravellerList: resp.result.content,
          activetravellerList: resp.result.content,
          downloadSelectedValue: "current",
          loading: false,
          pagination: pager,
          showTable: true
        });



      })
      .catch(ex => {
        this.setState({ loading: false });
      });
  };

  handleManualTableChange = (pagination, filters, sorter) => {
    this.setState({activeManualtravellerList:[]})
    let currentUser = UserService.getUser();
    let orgId = currentUser.companyID;
    let userRole = currentUser.role;
    let date = this.state.date
    const pager = { ...this.state.paginationManual };
    pager.current = pagination.current;
    filters.date = this.state.date
    this.setState({ loading: true, csvSearchFilters: filters });
    this.props
      .getManualWhereAreMyTravelers(orgId, userRole, {
        filters: filters,
        pagination: { page: pagination.current, pageSize: pagination.pageSize },
        sorter: sorter,

      })
      .then(resp => {
        pager.total = resp.result.totalElement;

        this.setState({
          activeManualtravellerList: resp.result.content,
          downloadSelectedValue: "current",
          loading: false,
          paginationManual: pager,
          showTable: true
        });
      



      })
      .catch(ex => {
        this.setState({ loading: false });
      });
  };

  onCsvDownloadSelect = (key) => {
    this.setState({ downloadSelectedValue: key })
    if (key === 'all') {
      this.getCsvData()
    }
    // 
  }

  handleCombineAndSubmit=()=>{
    var self=this;
    this.handleSubmit()
    setTimeout(function afterTwoSeconds() {
      self.getMagnatechorCombinedCsv('combined')
    }, 2000)
    
  }
  getCsvData = () => {
    let currentUser = UserService.getUser();
    let orgId = currentUser.companyID;
    let userRole = currentUser.role;
    let date = this.state.date

    this.props
      .getAllWhereAreMyTravelers(orgId, userRole, {
        filters: this.state.csvSearchFilters,
        pagination: { page: 1, pageSize: 1000 },
        sorter: {},

      })
      .then(resp => {
        this.setState({
          allactivetravellerList: resp.result.content,

        });
      })
      .catch(ex => {

      });
  };

  getManualCsv = () => {
    let token = UserServices.getToken();
    let _headers = {'Content-Type': 'multipart/form-data'};
    if (token) _headers['authorization'] = token ? 'Bearer ' + token : undefined;

    let currentUser = UserService.getUser();
    let orgId = currentUser.companyID;
    let userRole = currentUser.role;
    let filename = 'Manual Where Are My Traveller Report.csv';
    try{
      axios({
        url: travellerapi.getManualWhereAreMyTravelersCsv(orgId,userRole, {filters: this.state.csvSearchFilters,pagination: { page: 1, pageSize: 10 },sorter: {}}),
        method: 'GET',
        responseType: 'blob',
        headers: _headers
      }).then((response) => {
         const url = window.URL.createObjectURL(new Blob([response.data]));
         const link = document.createElement('a');
         link.href = url;
         link.setAttribute('download', filename);
         document.body.appendChild(link);
         link.click();
      });
    }catch(e){
      console.log(e);
      
    }
 
  }




  getMagnatechorCombinedCsv = (type) => {
    let token = UserServices.getToken();
    let _headers = { 'Content-Type': 'multipart/form-data' };
    if (token) _headers['authorization'] = token ? 'Bearer ' + token : undefined;
    let currentUser = UserService.getUser();
    let orgId = currentUser.companyID;
    let userRole = currentUser.role;
    if (type == 'magnatech') {
      let filename = 'Automated Where Are My Traveller Report.csv';
      try {
        axios({
          url: travellerapi.getMagnatechWhereAreMyTravelersCsv('magnatech', orgId, userRole, { filters: this.state.csvSearchFilters, pagination: { page: 1, pageSize: 10 }, sorter: {} }),
          method: 'GET',
          responseType: 'blob',
          headers: _headers
        }).then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', filename);
          document.body.appendChild(link);
          link.click();
        });
      } catch (e) {
        console.log(e);
      }
    }
    else {
      let filename = 'Combined Where Are My Traveller Report.csv';
      try {
        axios({
          url: travellerapi.getMagnatechWhereAreMyTravelersCsv('combined', orgId, userRole, { filters: this.state.csvSearchFilters, pagination: { page: 1, pageSize: 10 }, sorter: {} }),
          method: 'GET',
          responseType: 'blob',
          headers: _headers
        }).then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', filename);
          document.body.appendChild(link);
          link.click();
        });
      } catch (e) {
        console.log(e);

      }
    }
  }
  render() {
    const { getFieldDecorator } = this.props.form;
    const { formLayout } = this.props;
    const formItemLayout =
      formLayout === "horizontal"
        ? {
          labelCol: { span: 4 },
          wrapperCol: { span: 14 }
        }
        : null;
    let _state = this.state;
    let _this = this;
    var traveller = this.state.allactivetravellerList
    let headers
    this.state.role === 'Administrator' || this.state.role === 'CSR' ?
      headers = [
        { label: 'Name', key: 'name' },
        { label: 'Divison', key: 'divisionName' },
        { label: 'Organization', key: 'parentOrganizationName' },
        { label: 'PNR', key: 'pnrNumber' },
        { label: 'City', key: 'city' },
        { label: 'Departs', key: 'departureDate' },
        { label: 'Arrival Date', key: 'arrivalDate' },
        { label: 'Routing', key: 'routing' }

      ] : headers = [
        { label: 'Name', key: 'name' },
        { label: 'Divison', key: 'divisionName' },
        { label: 'PNR', key: 'pnrNumber' },
        { label: 'City', key: 'city' },
        { label: 'Departs', key: 'departureDate' },
        { label: 'Arrival Date', key: 'arrivalDate' },
        { label: 'Routing', key: 'routing' }

      ]
    let filtertag = Object.keys(this.state.filters).map(function (key1) {
      let keyLabel = _this.getHeaderKeys().find(el => el.dataIndex === key1);

      if (keyLabel && keyLabel.title && keyLabel.title.props && keyLabel.title.props.id) {
        keyLabel = <IntlMessages id={keyLabel.title.props.id} />;
      } else {
        keyLabel = key1;
      }

      if (_state.filters[key1].filter) {
        return (
          <span>
            <Button
              key={key1}
              type="primary"
              onClick={() =>
                _this.handleReset(_state.filters[key1].clearf, key1)
              }
            >
              {keyLabel} : {_state.filters[key1].val}
              <Icon type="close" />
            </Button>
            &nbsp;
          </span>
        );
      }
    });
    return (
      <div>
        <Row gutter={24}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Typography.Title level={4}>
              <IntlMessages id="admin.sidebar.submenu.wherearemytravelers" />
            </Typography.Title>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12} className="align-right">
            <ul>
              {/* <li>
                {" "}
              <Select value={this.state.downloadSelectedValue} style={{ width: 200 }} onChange={this.onCsvDownloadSelect} >
                    <Option value="current">Current Page</Option>
                    <Option value="all">All</Option>
              </Select>
              {" "}
              <CSVLink filename={"whereAreMyTravelerReport.csv"} headers={headers} data={traveller} >
                  <Button>
                  <Icon type="download" />
                    <span><IntlMessages id="admin.report.csvdownload" /></span>
                  </Button>
                </CSVLink>
              </li> */}
              {/* <li>
                <PrintComponents trigger={<Button type="primary" >
                  <i
                    style={{ display: "inline-block", marginRight: "5px" }}
                    className="fas fa-print"
                  ></i>
                  <span><IntlMessages id="admin.report.print" /></span>
                </Button>}> <PrintAbleComponent headerKeys={this.getHeaderKeys()} data={this.state.activetravellerList} /> </PrintComponents>
              </li> */}
              <li>
                <Button
                  type="primary"
                  htmlType="button"
                  onClick={() => this.props.history.push("../")}
                >
                  <IntlMessages id="admin.userlisting.back" />
                </Button>
              </li>
            </ul>
          </Col>
        </Row>
        <hr />
        <Form layout={formLayout} >
          <Row gutter={24} style={{ marginBottom: "20px" }}>
            <Col xs={5}>
              <Form.Item
                {...formItemLayout}
              //label={<IntlMessages id="userdash-departuredate" />}
              >
                {getFieldDecorator("date", {
                  rules: [
                    {
                      required: true,
                      message: <IntlMessages id="admin.input.required"></IntlMessages>
                    }
                  ],

                })(
                  <DatePicker

                    onChange={(e) => this.handleDateChange(e)}
                    format={dateFormat}
                    style={{ width: "200px", marginTop: '20px' }}
                  />
                )}
              </Form.Item>
            </Col>
            <Col xs={2}>
              <Button
                type="primary"
                style={{ marginTop: '20px' }}
                onClick={() => this.handleSubmit()}
              >
                <IntlMessages id="admin.organization.submit"></IntlMessages>

              </Button>
            </Col>

            <Col xs={6}>
                <Button style={{ marginTop: '20px', marginLeft: "475px" }} onClick={this.handleCombineAndSubmit}>
                  <Icon type="export" />
                  <span><IntlMessages id="admin.report.csvdownloadMagandManual" /></span>
                </Button>
            </Col>
          </Row></Form>
        <div id="printableArea">
          {this.state.showTable ?

            < div >

              <hr />

              
              <div className="card-container">
                <Tabs type="card" onChange={this.callback}>
                  <TabPane tab={<IntlMessages id="admin.report.manual" />} key="1">
                  <Row gutter={24} style={{ marginBottom: "20px" }}>
                <Col xs={24}>{filtertag}</Col>
              </Row>
                    <Row gutter={24}>
                    <Col xs={15} ></Col>
                  <Col xs={6} >

                    <Button style={{ marginBottom: '10px', marginLeft: '88px' }} onClick={this.getManualCsv}>
                      <Icon type="export" />
                      <span><IntlMessages id="admin.report.csvdownloadManual" /></span>
                    </Button>

                  </Col>
                  <Col xs={3}>
                    <PrintComponents trigger={<Button type="primary" style={{ marginLeft: '34px' }}>
                      <i
                        style={{ display: "inline-block", marginRight: '5px' }}
                        className="fas fa-print"
                      ></i>
                      <span><IntlMessages id="admin.report.print" /></span>
                    </Button>}> <PrintAbleComponent headerKeys={this.getHeaderKeys().filter(data =>(data.dataIndex !=="pnrNumber"))} data={this.state.activeManualtravellerList} /> </PrintComponents>
                  </Col>
                    </Row>
                    <Row gutter={24}></Row>
                    <Row gutter={24}>
                      <Col
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        xl={24}
                        style={{ overflowX: "auto" }}
                      >
                        <Table
                          bordered
                          columns={this.getHeaderKeys()}
                          dataSource={this.state.activeManualtravellerList}
                          pagination={this.state.paginationManual}
                          loading={this.state.loading}
                          onChange={this.handleManualTableChange}
                        />
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tab={<IntlMessages id="admin.report.magnatech" />} key="2">
                  <Row gutter={24} style={{ marginBottom: "20px" }}>
                <Col xs={24}>{filtertag}</Col>
              </Row>
                    <Row gutter={24}>
                    <Col xs={15} ></Col>
                  <Col xs={6} >

                    <Button style={{ marginBottom: '10px', marginLeft: '63px' }} onClick={()=>this.getMagnatechorCombinedCsv('magnatech')}>
                      <Icon type="export" />
                      <span><IntlMessages id="admin.report.csvdownloadMagnatech" /></span>
                    </Button>

                  </Col>
                  <Col xs={3}>
                    <PrintComponents trigger={<Button type="primary" style={{ marginLeft: '30px' }}>
                      <i
                        style={{ display: "inline-block", marginRight: '5px' }}
                        className="fas fa-print"
                      ></i>
                      <span><IntlMessages id="admin.report.print" /></span>
                    </Button>}> <PrintAbleComponent headerKeys={this.getHeaderKeys()} data={this.state.activetravellerList} /> </PrintComponents>
                  </Col>
                    </Row>
                    <Row gutter={24}>
                    <Col
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        xl={24}
                        style={{ overflowX: "auto" }}
                      >
                        <Table
                          bordered
                          columns={this.getHeaderKeys2()}
                          dataSource={this.state.activetravellerList}
                          pagination={this.state.pagination}
                          loading={this.state.loading}
                          onChange={this.handleTableChange}
                        />
                      </Col>

                    </Row>
                  </TabPane>
                </Tabs>
              </div>

            </div>
            : ''
          }
        </div>

      </div>

    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...travellersReportActions }, dispatch);
}

const WrappedForm = Form.create({ name: "travelers-report" })(WhereareTravelers);
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    WrappedForm
  )
);