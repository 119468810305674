import React from "react";
import {
		Font,
    Page,
    Text,
    View,
    Document,
    StyleSheet,
		Image
} from "@react-pdf/renderer";
import logo from "../../../../images/logo.png";
import moment from "moment";
import OpenSansRegular from "../../../../assets/fonts/open-sans/OpenSans-Regular.ttf";
import OpenSansLight from "../../../../assets/fonts/open-sans/OpenSans-Light.ttf";
import OpenSansItalic from "../../../../assets/fonts/open-sans/OpenSans-Italic.ttf";
import OpenSansSemibold from "../../../../assets/fonts/open-sans/OpenSans-SemiBold.ttf";
import OpenSansBold from "../../../../assets/fonts/open-sans/OpenSans-Bold.ttf";
import IntlMessages from "../../../../services/intlMesseges";

Font.register({
	src: OpenSansRegular,
	family: 'Open Sans'
});
Font.register({
  family: 'Open Sans',
  src: OpenSansLight
});
Font.register({
  family: 'Open Sans',
  src: OpenSansItalic
});
Font.register({
  family: 'Open Sans',
  src: OpenSansSemibold
});
Font.register({
  family: 'Open Sans',
  src: OpenSansBold
});
const styles = StyleSheet.create({
		page: {
			color: '#222222',
			fontFamily: 'Open Sans',
			width: 1170
		},
		wrap: {
			flexDirection: 'row',
			borderTopWidth: 4,
			borderTopColor: '#146faf',
			borderTopStyle: 'solid',
			alignItems: 'stretch'
		},
		container: {
			paddingTop: 20,
			paddingLeft: 30,
			paddingRight: 30
		},
		row: {
			flexGrow: 1,
    	flexDirection: 'row',
		},
		space1: {
			height: '20px'
		},
		logo: {
			width: 100
		},
		companyInfo: {
			width: "50%",
			marginTop: 10
		},
		textCompanyInfo: {
			lineHeight: 1.5,
			fontSize: 10
		},
		invoiceNumber: {
			width: "50%",
      marginTop: 10,
      borderWidth: 2,
			borderColor: '#000',
      borderStyle: 'solid',
      textAlign: 'center'
    },
    textInvoiceNumber: {
			lineHeight: 1.5,
      fontSize: 11,
      fontWeight: 'light'
    },
    fontWeigthtNormal: {
      fontWeight: 'light'
    },
    billToHeading: {
      marginTop: '20px', 
      fontWeight: 800,
      fontSize: 11.5
		},
		bold: {
			fontWeight: 'bold',
			fontSize: 9
		},
		semibold: {
			fontWeight: 600,
			fontSize: 9
		},
		billTo: {
			fontWeight: 'bold',
			lineHeight: 1.5,
      fontSize: 10
		},
		table: { 
			display: "table", 
			width: "auto", 
			borderStyle: "solid", 
			borderWidth: 1, 
			borderRightWidth: 0, 
			borderBottomWidth: 0 
		}, 
		tableRow: { 
			margin: "auto", 
			flexDirection: "row" 
		}, 
		tableCol: { 
			width: "25%", 
			borderStyle: "solid", 
			borderWidth: 1, 
			borderLeftWidth: 0, 
			borderTopWidth: 0 
		}, 
		tableCell: { 
			margin: "auto", 
			marginTop: 5, 
			fontSize: 10,
			padding: '5px'
		},
		tableCellHeading: { 
			margin: "auto", 
			marginTop: 5, 
			fontSize: 10,
			fontWeight: 'bold'
		},
		totalContainer: {
			borderWidth: 1,
			borderColor: '#000',
			borderStyle: 'solid',
			flexGrow: 1
		},
		totalContainerLeft: {
			padding: '5px',
			borderRightWidth: 1,
			borderRightColor: '#000',
      borderRightStyle: 'solid',
			textAlign: 'center',
			width: '65%'
		},
		totalContainerRight: {
			padding: '5px',
			width: '35%'
		}
	});

class PdfDocument extends React.Component {
	formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2
	});

	render() {
		let invoiceData = this.props.data;
		let invoiceLabels = this.props.labels;
		let termDays = 0;
		if (invoiceData) {
			let createdDate = moment(invoiceData.createDate);
			let dueDate = moment(invoiceData.dueDate);
			termDays = Math.ceil(dueDate.diff(createdDate, "days", true));
		  }
	
		return (
			<Document>
				<Page size="A4" style={styles.page}>
					{this.props.data
						? <View style={styles.wrap}>
								<View style={styles.container}>
									<View style={styles.row}>
										<Image source={logo} style={styles.logo}/>
									</View>
							
									<View style={styles.row}>
										<View style={styles.companyInfo}>
											<Text style={styles.textCompanyInfo}>FocusPoint International Inc.</Text>
											<Text style={styles.textCompanyInfo}>15830 Foltz Parkway</Text>
											<Text style={styles.textCompanyInfo}>Strongsville, Ohio 44149</Text>
											<Text style={styles.textCompanyInfo}>(440) 878-7600</Text>
											<Text style={styles.textCompanyInfo}>(440) 878-0092 Fax</Text>
											<Text style={styles.textCompanyInfo}>Federal ID No. 47-4612937</Text>
										</View>
										<View style={styles.invoiceNumber}>
											<Text style={{fontSize: 20}}>{invoiceLabels["admin.invoice.invoice"]}</Text>
											<Text style={styles.textInvoiceNumber}>{invoiceLabels["admin.invoice.invoicenumber"]}: {invoiceData.invoiceNumber}</Text>
											<Text style={styles.textInvoiceNumber}>{invoiceLabels["admin.invoice.invoicedate"]}: {moment(invoiceData.createDate).format("MM/DD/YYYY")}</Text>
											<Text style={styles.textInvoiceNumber}>{invoiceLabels["admin.invoice.pageno"]}: 1</Text>
										</View>
									</View>

									<View style={styles.row}>
										<View style={styles.billTo}>
											<Text style={styles.billToHeading}>{invoiceLabels["admin.invoice.billto"]}:</Text>
											<Text style={styles.bold}>
												{invoiceLabels["admin.invoice.companyname"]}: <Text style={styles.fontWeigthtNormal}>{invoiceData.billingCompanyName}</Text>
											</Text>
											<Text style={styles.bold}>
												{invoiceLabels["admin.invoice.companyaddress"]}: <Text style={styles.fontWeigthtNormal}>{invoiceData.billing_Address}</Text>
											</Text>
											<Text style={styles.bold}>
												{invoiceLabels["admin.invoice.companycontact"]}: <Text style={styles.fontWeigthtNormal}>{invoiceData.billingContactPerson}</Text>
											</Text>
										</View>
									</View>
									
									<View style={styles.space1}></View>
									<View style={styles.row}>
										<View style={styles.companyInfo}>
											<Text style={styles.bold}>{invoiceLabels["admin.invoice.duedate"]}: <Text style={styles.fontWeigthtNormal}>{moment(invoiceData.dueDate).format("MM/DD/YYYY")}</Text></Text>
											<Text style={styles.bold}>{invoiceLabels["admin.invoice.terms"]}: <Text style={styles.fontWeigthtNormal}>{termDays} {invoiceLabels["admin.invoice.days"]}</Text></Text>
										</View>
										<View style={styles.companyInfo}>
											<Text style={styles.bold}>{invoiceLabels["admin.invoice.customerid"]}: <Text style={styles.fontWeigthtNormal}>{invoiceData.customerID}</Text></Text>
											<Text style={styles.bold}>{invoiceLabels["admin.invoice.ponumber"]}: <Text style={styles.fontWeigthtNormal}>{invoiceData.poNumber}</Text></Text>
											<Text style={styles.bold}>{invoiceLabels["admin.invoice.jobnumber"]}: <Text style={styles.fontWeigthtNormal}>{invoiceData.jobNumber}</Text></Text>
										</View>
									</View>
									
									<View style={styles.space1}></View>
									<View style={styles.table}> 
										<View style={styles.tableRow}> 
											<View style={styles.tableCol}> 
												<Text style={styles.tableCellHeading}>{invoiceLabels["admin.invoice.description"]}</Text> 
											</View> 
											<View style={styles.tableCol}> 
												<Text style={styles.tableCellHeading}>{invoiceLabels["admin.invoice.quantity"]}</Text> 
											</View> 
											<View style={styles.tableCol}> 
												<Text style={styles.tableCellHeading}>{invoiceLabels["admin.invoice.unitprice"]}</Text> 
											</View> 
											<View style={styles.tableCol}> 
												<Text style={styles.tableCellHeading}>{invoiceLabels["admin.invoice.totalprice"]}</Text> 
											</View> 
										</View>
										<View style={styles.tableRow}> 
											<View style={styles.tableCol}> 
												<Text style={styles.tableCell}>{invoiceData.productName}</Text> 
											</View> 
											<View style={styles.tableCol}> 
												<Text style={styles.tableCell}>{invoiceData.productQty}</Text> 
											</View> 
											<View style={styles.tableCol}>
												<Text style={styles.tableCell}>{this.formatter.format(invoiceData.productTotalAmount/invoiceData.productQty)}</Text> 
											</View>
											<View style={styles.tableCol}> 
												<Text style={styles.tableCell}>{this.formatter.format(invoiceData.productTotalAmount)}</Text> 
											</View> 
										</View> 
									</View>

									<View style={styles.space1}></View>
									<View style={styles.bold}>
										<Text style={styles.billToHeading}>{invoiceLabels["admin.invoice.remitwirepayments"]}:</Text>
										<Text>{invoiceLabels["admin.invoice.bankaccountname"]}: Fifth Third Bank</Text>
										<Text>{invoiceLabels["admin.invoice.bankroutingnumber"]}: 042000314</Text>
										<Text>{invoiceLabels["admin.invoice.bankaccountnumber"]}: 7283970288</Text>
										<Text>{invoiceLabels["admin.invoice.bankswiftcode"]}: FTBCUS3C</Text>
									</View>

									<View style={styles.space1}></View>
									<View style={styles.space1}></View>
									<View style={styles.totalContainer}>
										<View style={styles.row}>
											<View style={styles.totalContainerLeft}>
												<View style={styles.semibold}>
													<Text>{invoiceLabels["admin.invoice.remitpaymentscorporate"]}:</Text>
													<Text style={styles.fontWeigthtNormal}>15830 FOLTZ INDUSTRIAL PARKWAY</Text>
													<Text style={styles.fontWeigthtNormal}>CLEVELAND OH 44149</Text>
												</View>
											</View>
											<View style={styles.totalContainerRight}>
												<View style={styles.semibold}>
													<View style={styles.row}>
														<Text style={{width: "50%"}}>{invoiceLabels["admin.invoice.totalusd"]}: </Text>
														<Text style={{textAlign: 'right', width: "50%", paddingRight: '10px'}}>{this.formatter.format(invoiceData.productTotalAmount)}</Text>
													</View>
												</View>
											</View>
										</View>
									</View>
								</View>
							</View>
					: ""
					}
				</Page>
			</Document>
		);
	}
}
export default (PdfDocument);