import React from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as travellerActions from "../../../../redux/actions/travellers-actions";
import * as userActions from "../../../../redux/actions/user-actions";
import * as AdminActions from "../../../../redux/actions/admin-actions";
import * as b2cActions from "../../../../redux/actions/b2c-actions";
import IntlMessages from "../../../../services/intlMesseges";
import { Map, GoogleApiWrapper, Marker } from "google-maps-react";
import { Typography, Form, Input, Select, Button, Row, Col, Spin, Switch } from "antd";
import { DatePicker } from "antd";
import moment from "moment";
import { notifyUser } from "../../../../services/notification-service";
import AppLocale from "../../../../languageProvider";
import * as Permission from '../../../../services/permissions';
const dateFormat = "MM/DD/YYYY";
const dateFormatY = "YYYY/MM/DD";
const { Option } = Select;
var validator = require("email-validator");

class EditTraveler extends React.Component {
  state = {
    data: {},
    loading: true,
    confirmDirty: false,
    id: 0,
    first: "",
    last: "",
    email: "",
    phone: "",
    country: "",
    dateofbirth: "",
    countries: [],
    countryId: "",
    locationFound: false,
    locationData: {},
    checkexisting: true,
    genderList: []
  };

  async componentDidMount() {
    this.props.getCountriesListName().then((response) => {
      this.setState({ countries: response });
    });
    if (
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.id &&
      this.props.match.params.id !== "new"
    ) {
      this.setState({ userId: this.props.match.params.id });

      const user = await this.props.getTraveller(this.props.match.params.id);
      this.setState(user);
      if (user.data.email === null) {
        this.setState({ checkexisting: false });
      }
      
      this.setState({ loading: false });
    }

    this.props.getGendersList().then((response) => {
      this.setState({ genderList: response });
    });
  }

  submitFormAdd = (data) => {
    const { language } = this.props;
    const currentAppLocale = AppLocale[language.locale];
    this.props
      .updateTravellerData(data, this.props.match.params.id)
      .then((response) => {
        if (response.error) {
          notifyUser(response.data.Message, 'error');
          this.setState({ loading: false });
        } else {
          notifyUser(currentAppLocale.messages["admin.traveller.add.success"], "success");
          //notifyUser("Traveller added successfully!", "success");
          this.props.history.push("./");
          this.setState({ loading: false });
        }
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };
  async emailsubmit(data) {
    const { language } = this.props;
    const currentAppLocale = AppLocale[language.locale];
    this.setState({ loading: true });

    if (validator.validate(data.email)) {
      await this.props.validateTravellerEmail(data.email);

      if (this.props.emailCheckResponse.userExist) {
        notifyUser(currentAppLocale.messages["admin.email.alreadyexis"], "error");
        this.setState({ loading: false });
      } else {
        var emaildata = {
          userId: parseInt(this.state.userId),
          organizationId: 1,
          email: data.email
        };
        this.props.updatetravelerEmail(emaildata).then((resp) => {
          if (resp.data.message === "Email Updated. Identity assigned successfully.") {
            this.props.history.push("./");
            this.setState({ loading: false });
            notifyUser(currentAppLocale.messages["admin.traveller.update.success"], "success");
          }
        });
      }
    }
  }
  submitFormEdit = (data) => {
    const { language } = this.props;
    const currentAppLocale = AppLocale[language.locale];
    this.props
      .updateTravellerData(data, this.props.match.params.id)
      .then((response) => {
        if (response.error) {
          notifyUser(response.data.Message, 'error');
          this.setState({ loading: false });
        } else {
          if (this.state.checkexisting === false) {
            this.emailsubmit(data);
          } else {
            this.props.history.push("./");
            this.setState({ loading: false });
            notifyUser(currentAppLocale.messages["admin.traveller.update.success"], "success");
            //notifyUser("Traveller updated successfully!", "success");
          }
        }
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        Object.keys(values).map(function(key, _idx) {
          if (values[key] == null) {
            values[key] = "";
          } else {
            if (typeof values[key] == "string") {
              values[key] = values[key].trim();
            }
          }
        });
        this.setState({ loading: true });
        values["dateOfBirth"] = moment(values["dateOfBirth"]).format(dateFormat);
        if (this.state.userId && this.state.userId > 0) {
          values["id"] = this.state.userId;
          this.submitFormEdit(values);
        } else {
          this.submitFormAdd(values);
        }
      }
    });
  };

  handleContactExistance(rule, value, callback) {
    //const re = /^[0-9']+$/;
    const re = /^(?=[+0-9]*$)(?!.*[<>'"/;`%~@#$^*()_=[\]{}|\\,.?: -])/;
    var test = re.test(value);
    if (value !== "" && (value.length < 6 || test === false)) {
      callback(<IntlMessages id="admin.phonenumber.valid"></IntlMessages>);
    } else {
      callback();
    }
  }

  handleEmgergencyContactExistance(rule, value, callback) {
    //const re = /^[0-9']+$/;
    const re = /^(?=[+0-9]*$)(?!.*[<>'"/;`%~@#$^*()_=[\]{}|\\,.?: -])/;
    var test = re.test(value);
    if (value !== "" && (value.length < 6 || test === false)) {
      callback(<IntlMessages id="admin.phonenumber.valid" />);
    } else {
      callback();
    }
  }

  // onSwitchChange = (checked, e) => {
  //   const { name } = e.target;
  //   this.setState({ [name]: checked });
  //   console.log(this.state);
  // };

  onSwitchChange = (checked, e) => {
    console.log(1, checked, e);
    const { name } = e.target;
    this.setState({ data: { ...this.state.data, [name]: checked } });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { formLayout } = this.state;
    const formItemLayout =
      formLayout === "horizontal"
        ? {
            labelCol: { span: 4 },
            wrapperCol: { span: 14 }
          }
        : null;

    return (
      <div>
        {/* Edit Traveler */}
        <Typography.Title level={3}>
          <IntlMessages id='admin.traveler.personaldetails' />
        </Typography.Title>
        <div>
          <Spin spinning={this.state.loading}>
            <h6>
              <span style={{ fontWeight: 'bold' }}>
                {this.state.data && this.state.data.travelerFirstName}{' '}
                {this.state.data && this.state.data.travelerLastName}
              </span>
              &nbsp;({this.state.data && <a href={`mailto:${this.state.data.email}`}>{this.state.data.email}</a>})
            </h6>
            <hr />
            <Form layout={formLayout} onSubmit={this.handleSubmit}>
              <Row gutter={24}>
                <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                  <Form.Item {...formItemLayout} label={<IntlMessages id='admin.userlisting.firstName' />}>
                    {getFieldDecorator('firstName', {
                      rules: [
                        {
                          whitespace: true,
                          required: true,
                          message: <IntlMessages id='admin.input.required' />
                        },
                        {
                          pattern: new RegExp(/^(\S.*)[a-zA-Z-.']+$/),
                          message: <IntlMessages id='admin.name.valid' />
                        }
                      ],
                      initialValue: this.state.data.travelerFirstName === null ? '' : this.state.data.travelerFirstName
                    })(<Input maxLength={50} />)}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                  <Form.Item {...formItemLayout} label={<IntlMessages id='admin.userlisting.lastName' />}>
                    {getFieldDecorator('lastName', {
                      rules: [
                        {
                          whitespace: true,
                          required: true,
                          message: <IntlMessages id='admin.input.required' />
                        },
                        {
                          pattern: new RegExp(/^(\S.*)[a-zA-Z-.']+$/),
                          message: <IntlMessages id='admin.lname.valid' />
                        }
                      ],
                      initialValue: this.state.data.travelerLastName === null ? '' : this.state.data.travelerLastName
                    })(<Input maxLength={50} />)}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                  <Form.Item {...formItemLayout} label={<IntlMessages id='admin.userlisting.phonenumber' />}>
                    {getFieldDecorator('contactNo', {
                      rules: [
                        {
                          whitespace: true,
                          required: true,
                          message: <IntlMessages id='admin.input.required' />
                        },
                        {
                          validator: this.handleContactExistance
                        }
                        // {

                        //   pattern: new RegExp(/^[0-9']+$/),
                        //   message: <IntlMessages id="admin.phonenumber.valid" />
                        // },
                        // {
                        //   min: 8,
                        //   message: <IntlMessages id="admin.phonenumber.valid" />
                        // }
                      ],
                      initialValue: this.state.data.contactNo === null ? '' : this.state.data.contactNo
                    })(
                      <Input
                        maxLength={15}
                        // addonBefore={prefixSelector}
                        style={{ width: '100%' }}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                  <Form.Item label='Gender'>
                    {getFieldDecorator('gender', {
                      rules: [
                        {
                          required: true,
                          message: 'Please select your Gender!'
                        }
                      ],
                      initialValue: this.state.data.gender
                    })(
                      <Select
                        showSearch
                        placeholder='Please select gender'
                        filterOption={(input, option) =>
                          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {this.state.genderList.map(function(gender) {
                          return (
                            <Option key={gender.id} value={gender.id}>
                              {gender.name}
                            </Option>
                          );
                        })}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                  <Form.Item {...formItemLayout} label={<IntlMessages id='admin.userlisting.email' />}>
                    {getFieldDecorator('email', {
                      rules: [
                        {
                          type: 'email',
                          message: <IntlMessages id='admin.email.valid'></IntlMessages>
                        },
                        {
                          required: true,
                          message: <IntlMessages id='admin.input.required' />
                        }
                      ],
                      initialValue: this.state.data.email === null ? '' : this.state.data.email
                    })(Permission.emailEditAllowded() ? <Input /> : <Input disabled />)}
                  </Form.Item>
                </Col>

                {/* <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                  <Form.Item {...formItemLayout} label={"User Name"}>
                    {getFieldDecorator("userName", {
                      rules: [
                        {
                          whitespace: true,
                          required: true,
                          message: <IntlMessages id="admin.input.required" />
                        },
                        {
                          pattern: new RegExp(/^(\S.*)[a-zA-Z-.']+$/),
                          message: <IntlMessages id="admin.lname.valid" />
                        }
                      ],
                      initialValue: this.state.data.userName === null ? "" : this.state.data.userName
                    })(<Input maxLength={50} />)}
                  </Form.Item>
                </Col> */}
                <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                  <Form.Item {...formItemLayout} label={<IntlMessages id='admin.userlisting.address' />}>
                    {getFieldDecorator('address', {
                      rules: [
                        {
                          whitespace: true,
                          required: false,
                          message: <IntlMessages id='admin.input.required' />
                        }
                      ],
                      initialValue: this.state.data.address === null ? '' : this.state.data.address
                    })(<Input maxLength={100} />)}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                  <Form.Item {...formItemLayout} label={<IntlMessages id='admin.userlisting.state' />}>
                    {getFieldDecorator('state', {
                      rules: [
                        {
                          whitespace: true,
                          required: false,
                          message: <IntlMessages id='admin.input.required' />
                        }
                      ],
                      initialValue: this.state.data.state === null ? '' : this.state.data.state
                    })(<Input maxLength={100} />)}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                  <Form.Item label={<IntlMessages id='admin.userlisting.country' />}>
                    {getFieldDecorator('countryId', {
                      rules: [
                        {
                          required: true,
                          message: <IntlMessages id='admin.input.required' />
                        }
                      ],
                      initialValue: this.state.data.countryId
                    })(
                      <Select
                        showSearch
                        filterOption={(input, option) =>
                          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {this.state.countries.map(function(item) {
                          return (
                            <Option key={item.countryId} value={item.countryId}>
                              {item.countryName}
                            </Option>
                          );
                        })}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                  <Form.Item {...formItemLayout} label='Date of Birth'>
                    {getFieldDecorator('dateOfBirth', {
                      rules: [
                        {
                          required: true,
                          message: <IntlMessages id='admin.input.required' />
                        }
                      ],
                      initialValue:
                        this.state.data.dateOfBirth !== null ? moment(this.state.data.dateOfBirth, dateFormatY) : null
                    })(<DatePicker format={dateFormat} disabledDate={(current) => current && current > moment()} />)}
                  </Form.Item>{' '}
                </Col>
                <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                  <Form.Item {...formItemLayout} label={'Company Name'}>
                    {getFieldDecorator('companyName', {
                      rules: [
                        {
                          whitespace: true,
                          required: false,
                          message: <IntlMessages id='admin.input.required' />
                        }
                      ],
                      initialValue: this.state.data.companyName === null ? '' : this.state.data.companyName
                    })(<Input maxLength={100} />)}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                  <Form.Item {...formItemLayout} label={'How did you hear?'}>
                    {getFieldDecorator('howDidYouHear', {
                      rules: [
                        {
                          whitespace: true,
                          required: false,
                          message: <IntlMessages id='admin.input.required' />
                        }
                      ],
                      initialValue: this.state.data.howDidYouHear === null ? '' : this.state.data.howDidYouHear
                    })(<Input maxLength={100} />)}
                  </Form.Item>
                </Col>
                {/*     <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                  <Form.Item {...formItemLayout} label={'Agent Code'}>
                    {getFieldDecorator('agentCode', {
                      rules: [
                        {
                          whitespace: true,
                          required: false,
                          message: <IntlMessages id='admin.input.required' />
                        }
                      ],
                      initialValue: this.state.data.agentCode === null ? '' : this.state.data.agentCode
                    })(<Input maxLength={100} />)}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                  <Form.Item {...formItemLayout} label={'Identity ID'}>
                    {getFieldDecorator('identityId', {
                      rules: [
                        {
                          whitespace: true,
                          required: false,
                          message: <IntlMessages id='admin.input.required' />
                        }
                      ],
                      initialValue: this.state.data.identityId === null ? '' : this.state.data.identityId
                    })(<Input maxLength={100} disabled />)}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                  <Form.Item {...formItemLayout} label={'Company ID'}>
                    {getFieldDecorator('companyId', {
                      rules: [
                        {
                          whitespace: true,
                          required: false,
                          message: <IntlMessages id='admin.input.required' />
                        }
                      ],
                      initialValue: this.state.data.companyId === null ? '' : this.state.data.companyId
                    })(<Input maxLength={100} disabled />)}
                  </Form.Item>
                </Col> */}
                <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                  <Form.Item {...formItemLayout} label={'Employee ID'}>
                    {getFieldDecorator('employeeId', {
                      rules: [
                        {
                          whitespace: true,
                          required: false,
                          message: <IntlMessages id='admin.input.required' />
                        }
                      ],
                      initialValue: this.state.data.employeeId == null ? '' : this.state.data.employeeId
                    })(<Input maxLength={100} />)}
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                  <Form.Item {...formItemLayout} label='Is Active?'>
                    {getFieldDecorator('isActive', {
                      valuePropName: 'checked',
                      rules: [],
                      initialValue:
                        this.state.data && this.state.data.isActive == null ? false : this.state.data.isActive
                    })(
                      <Switch
                        disabled
                        checkedChildren='Yes'
                        unCheckedChildren='No'
                        onChange={() => this.onSwitchChange}
                        name='isCapAdvantagePurchased'
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                  <Form.Item {...formItemLayout} label='Is CAP Advantage Purchased?'>
                    {getFieldDecorator('isCapAdvantagePurchased', {
                      valuePropName: 'checked',
                      rules: [],
                      initialValue:
                        this.state.data && this.state.data.isCapAdvantagePurchased == null
                          ? false
                          : this.state.data.isCapAdvantagePurchased
                    })(
                      <Switch
                        disabled
                        checkedChildren='Yes'
                        unCheckedChildren='No'
                        onChange={() => this.onSwitchChange}
                        name='isCapAdvantagePurchased'
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                  <Form.Item {...formItemLayout} label='Is Email Active?'>
                    {getFieldDecorator('isEmailActive', {
                      valuePropName: 'checked',
                      rules: [],
                      initialValue:
                        this.state.data && this.state.data.isEmailActive == null ? false : this.state.data.isEmailActive
                    })(
                      <Switch
                        disabled
                        checkedChildren='Yes'
                        unCheckedChildren='No'
                        onChange={() => this.onSwitchChange}
                        name='isEmailActive'
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                  <Form.Item {...formItemLayout} label='Is SMS Active?'>
                    {getFieldDecorator('isSMSActive', {
                      valuePropName: 'checked',
                      rules: [],
                      initialValue:
                        this.state.data && this.state.data.isSMSActive == null ? false : this.state.data.isSMSActive
                    })(
                      <Switch
                        disabled
                        checkedChildren='Yes'
                        unCheckedChildren='No'
                        onChange={() => this.onSwitchChange}
                        name='isSMSActive'
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                  <Form.Item {...formItemLayout} label='Is Push Notification Active?'>
                    {getFieldDecorator('isSMSActive', {
                      valuePropName: 'checked',
                      rules: [],
                      initialValue:
                        this.state.data && this.state.data.isPushNotificationActive == null
                          ? false
                          : this.state.data.isPushNotificationActive
                    })(
                      <Switch
                        disabled
                        checkedChildren='Yes'
                        unCheckedChildren='No'
                        onChange={() => this.onSwitchChange}
                        name='isPushNotificationActive'
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                  <Form.Item {...formItemLayout} label='Is Send Welcome Email?'>
                    {getFieldDecorator('isSendWelcomeEmail', {
                      valuePropName: 'checked',
                      rules: [],
                      initialValue:
                        this.state.data && this.state.data.isSendWelcomeEmail == null
                          ? false
                          : this.state.data.isSendWelcomeEmail
                    })(
                      <Switch
                        disabled
                        checkedChildren='Yes'
                        unCheckedChildren='No'
                        onChange={() => this.onSwitchChange}
                        name='isSendWelcomeEmail'
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                  <Form.Item {...formItemLayout} label='Is Notify On Booking?'>
                    {getFieldDecorator('isNotifyOnBooking', {
                      valuePropName: 'checked',
                      rules: [],
                      initialValue:
                        this.state.data && this.state.data.isNotifyOnBooking == null
                          ? false
                          : this.state.data.isNotifyOnBooking
                    })(
                      <Switch
                        disabled
                        checkedChildren='Yes'
                        unCheckedChildren='No'
                        onChange={() => this.onSwitchChange}
                        name='isNotifyOnBooking'
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Typography.Title level={3}>
                <IntlMessages id='admin.traveler.emergencycontact' />
              </Typography.Title>
              <Row gutter={24}>
                <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                  <Form.Item {...formItemLayout} label={<IntlMessages id='admin.userlisting.firstName' />}>
                    {/* {this.state.data.emergencyFirstName} */}
                    {getFieldDecorator('emergencyFirstName', {
                      rules: [
                        {
                          // required: true,
                          // message: "Please input your firstname!"
                        },
                        {
                          pattern: new RegExp(/^(\S.*)[a-zA-Z-.']+$/),
                          message: <IntlMessages id='admin.name.valid' />
                        }
                      ],
                      initialValue: this.state.data.emergencyFirstName == null ? '' : this.state.data.emergencyFirstName
                    })(<Input maxLength={20} />)}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                  <Form.Item {...formItemLayout} label={<IntlMessages id='admin.userlisting.lastName' />}>
                    {getFieldDecorator('emergencyLastName', {
                      rules: [
                        {
                          // required: true,
                          // message: "Please input your last name!"
                        },
                        {
                          pattern: new RegExp(/^(\S.*)[a-zA-Z-.']+$/),
                          message: <IntlMessages id='admin.lname.valid' />
                        }
                      ],
                      initialValue: this.state.data.emergencyLastName == null ? '' : this.state.data.emergencyLastName
                    })(<Input maxLength={20} />)}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                  <Form.Item {...formItemLayout} label={<IntlMessages id='admin.manageTravellers.Realationship' />}>
                    {getFieldDecorator('emergencyRelationship', {
                      rules: [
                        {
                          pattern: new RegExp(/^(\S.*)[a-zA-Z-.']+$/),
                          message: <IntlMessages id='admin.lname.valid'></IntlMessages>
                        }
                      ],
                      initialValue:
                        this.state.data.emergencyRelationship == null ? '' : this.state.data.emergencyRelationship
                    })(<Input />)}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                  <Form.Item {...formItemLayout} label={<IntlMessages id='admin.userlisting.phonenumber' />}>
                    {getFieldDecorator('emergencyContactNo', {
                      rules: [
                        // {
                        //    required: true,
                        //    message: <IntlMessages id="admin.input.required" />
                        // },
                        {
                          validator: this.handleEmgergencyContactExistance
                        }
                        // {
                        //   pattern: new RegExp(/^[0-9']+$/),
                        //   message: 'Please enter a valid number!'
                        // },
                        // {
                        //   max: 12,
                        //   message: "Please enter a valid number!"
                        // },
                        // {
                        //   min: 8,
                        //   message: "Please enter a valid number!"
                        // }
                      ],
                      initialValue: this.state.data.emergencyContactNo == null ? '' : this.state.data.emergencyContactNo
                    })(
                      <Input
                        maxLength={15}
                        // addonBefore={prefixSelector}
                        style={{ width: '100%' }}
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Item>
                    <Button
                      type='primary'
                      style={{ display: 'inline-block', marginRight: '10px' }}
                      className='def-blue'
                      htmlType='submit'
                    >
                      {this.state.userId > 0 ? (
                        <IntlMessages id='admin.button.update' />
                      ) : (
                        <IntlMessages id='admin.button.add' />
                      )}
                    </Button>
                    {/* <Button
                    type="primary"
                    className=""
                    htmlType="button"
                    onClick={() => this.props.history.goBack()}
                  >
                    Back
                  </Button> */}
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Spin>
        </div>
        {/* Edit Traveler ends*/}
      </div>
    );
  }
}

EditTraveler.propTypes = {
  location: PropTypes.object,
  updateTravellers: PropTypes.func,
  travellerDataById: PropTypes.object,
  validateTravellerEmail: PropTypes.func
};

function mapStateToProps(state) {
  return {
    ...state.traveller,
    ...state.user,
    ...state.b2c,
    ...state.language
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...travellerActions, ...b2cActions, ...userActions, ...AdminActions }, dispatch);
}
const WrappedForm = Form.create({ name: "edit-traveler" })(EditTraveler);
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    GoogleApiWrapper({
      apiKey: "AIzaSyAA_ayr9aMRSAPIugacjp_CL6S5ux_N4is"
    })(WrappedForm)
  )
);
