import Axios from "../../services/axios-service";

const getGeoFence = (id, data) => {
  return Axios.get("/api/InterstedGeos/Geofences/" + id + "?search=" + encodeURIComponent(JSON.stringify(data)), undefined);
};

const getGeoFenceById = id => {
  return Axios.get("/api/InterstedGeos/Geofence/" + id, undefined);
};

const addGeoFence = data => {
  return Axios.post("/api/InterstedGeos/AddGeofenc", data, undefined);
};

const updateGeoFence = (data, id) => {
  return Axios.put("/api/InterstedGeos/Geofence/" + id, data, undefined);
};

const getGeoFenceRulesByGeoFenceId = (id, data) => {
  return Axios.get(
    "/api/InterstedGeoFenceRules/" + id + "?search=" + encodeURIComponent(JSON.stringify(data))
  );
};

const addGeoFenceRule = data => {
  return Axios.post("/api/InterstedGeoRules", data);
};

const updateGeoFenceRule = data => {
  return Axios.put("/api/InterstedGeoRules/" + data.id, data);
};

const getGeoFenceRule = id => {
  return Axios.get("/api/InterstedGeoRules/" + id);
};

export default {
  addGeoFence,
  updateGeoFence,
  getGeoFence,
  getGeoFenceById,
  getGeoFenceRulesByGeoFenceId,
  addGeoFenceRule,
  getGeoFenceRule,
  updateGeoFenceRule
};
