import React, { Component } from "react";
import { Spin } from 'antd';
export default class Loader extends Component{
    render(){
        return(
            <div className="overlay-loader">
                <Spin className="loader" tip="Please wait we are placing your order...">
        
                </Spin>
            </div>
        )
    }
}