import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Table, Checkbox } from "antd";

class TravelerLocationList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false
    };
  }

  getHeaderKeys = () => {
    return [
      {
        title: "Name",
        dataIndex: "firstName",
        render: (text, record) => (
          <a onClick={() => this.handleTravlerLocationClick(record)}>
            {record.firstName + " " + record.lastName}
          </a>
        )
      },
      {
        title: "Contact No",
        dataIndex: "contact"
      },
      {
        title: "State",
        dataIndex: "state",
        render: (text, record) =>
          record.state ? record.state : "Unnamed location"
      },
      {
        title: "Organization",
        dataIndex: "companyName"
      }
    ];
  };

  handleTravlerLocationClick(record) {
    this.props.handleTravlerLocationClick(record);
  }

  update = e => {
    this.props.onChangeTravelerLocationCheckBox(e);
  };

  componentDidMount() {}

  render() {
    return (
      <div className="table-less">
        <ul className="risklevel-check">
          <li>
            <Checkbox
              name="traveler-location"
              checked={this.props.isShowTravelerLocations}
              onChange={this.update}
            />
          </li>
          <li>
            <span>Show/Hide Traveler Location</span>
          </li>
        </ul>
        <Table
          pagination={{ defaultPageSize: 5 }}
          columns={this.getHeaderKeys()}
          dataSource={this.props.travellersLocationList}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    TravelerLocationList
  )
);
