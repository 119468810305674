import React, { Component } from "react";
import { Route } from "react-router-dom";
import PlanType from "../components/b2b2c/plan-type/plan-type.jsx";
import PlanTerm from "../components/b2b2c/plan-term/plan-term.jsx";
import PersonalDetails from "../components/b2b2c/personal-details/personal-details.jsx";
import PersonalInfo from "../components/b2b2c/personal-details/personal-info.jsx";
import BillingInformation from "../components/b2b2c/billing-information/billing-information.jsx";
import PurchaseSummary from "../components/b2b2c/purchase-summary/purchase-summary.jsx";
import ThankYou from "../components/b2b2c/thank-you/thank-you.jsx";
import reloadId from "../components/shared/components/reloadId.js";
const routes = [
  {
    path: "dashboard/:orgId",
    component: reloadId
  },
  {
    path: "purchase/:orgId",
    component: PlanType
  },
  {
    path: "(plan-term|planterm)/:orgId",
    component: PlanTerm
  },
  {
    path: "(personal-details|personaldetails)/:orgId",
    component: PersonalDetails
  },
  {
    path: "(personal-info|personalinfo)/:orgId",
    component: PersonalInfo
  },
  {
    path: "(billing-information|billinginformation)/:orgId",
    component: BillingInformation
  },
  {
    path: "(purchase-summary|purchasesummary)/:orgId",
    component: PurchaseSummary
  },
  {
    path: "(thank-you|thankyou)/:orgId",
    component: ThankYou
  },
  {
    path: "(thank-you|thankyou)",
    component: ThankYou
  }
];

class UserRouter extends Component {
  render() {
    const { url, style } = this.props;
    return (
      <div style={style}>
        {routes.map(singleRoute => {
          const { path, exact, ...otherProps } = singleRoute;
          return (
            <Route
              exact={exact === false ? false : true}
              key={singleRoute.path}
              path={`${url}/${singleRoute.path}`}
              {...otherProps}
            />
          );
        })}
        </div>
    );
  }
}

export default UserRouter;
