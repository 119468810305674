import Axios from "../../services/axios-service";

const getInvoiceData = (id, data) => {
    return Axios.get("/api/Invoice/" + id + "?search="+ encodeURIComponent(JSON.stringify(data)), undefined);
};
const updateInvoiceStatus = (data) => {
    return Axios.put("/api/InvoicePaymentStatus" , data, undefined);
};
const getInvoiceDetails = (id) => {
    return Axios.get("/api/Invoice/InvoiceDetail/" + id, undefined);
};
export default {
    getInvoiceData,
    updateInvoiceStatus,
    getInvoiceDetails
};