import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as logsActions from "../../../redux/actions/audit-logs-actions";
import * as paginationActions from "../../../redux/actions/pagination-actions"
import { Table, Icon, Input, Button, Row, Col, Typography, Modal, Tooltip, Spin } from "antd";
import moment from "moment";
import * as userActions from "../../../redux/actions/user-actions";
import IntlMessages from "../../../services/intlMesseges";
import API from "../../../redux/api/admin-api";
import Config from "../../../../../config";

const dates = "MM/DD/YYYY - HH:mm:ss";

class ActivityLog extends Component {
  constructor(props) {
    super(props);
    this.module = 'users';
    this.state = {
      dataLoaded: false,
      data: [],
      pagination: {
        // hideOnSinglePage: true,
        showTotal: (total, range) => {
          return (
            <span>
              <IntlMessages id="admin.text.showing" /> {range[0]}-{range[1]}{" "}
              <IntlMessages id="admin.text.of" /> {total}{" "}
              <IntlMessages id="admin.text.results" />
            </span>
          );
        },
        pageSize: 10,
        current: 1,
        countries: [],
        loading: true,
        visible: false,
      },

      filters: {}
    };
    this.handleTableChange = this.handleTableChange.bind(this);
  }


  async componentDidMount() {
    await this.props.getUserData();
    if ((this.props.match &&
      this.props.match.params &&
      this.props.match.params.id &&
      this.props.match.params.id !== "new")
      || (this.props.for && this.props.for === "reseller")) {
      let countriesListTemp = await API.getCountriesList();
      this.setState({ countries: countriesListTemp.countries });
      this.handleTableChange({ current: 1, pageSize: 10 }, {}, {});
    }
  }
  handleTableChange(pagination, filters, sorter) {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    var params = {
      filters: filters,
      pagination: { page: pagination.current, pageSize: pagination.pageSize },
      sorter: sorter
    }
    if (this.props.for === "users" || this.props.for === "travellers" || this.props.for === "employees") {
      this.setState({ loading: true });
      this.props.getUserLog(this.props.match.params.id, params).then(data => {

        var filtered_data = [];
        for (var a = 0; a < data.data.length; a++) {
          if (Object.keys(JSON.parse(data.data[a].newValues)).length === 1 &&
            JSON.parse(data.data[a].newValues).UpdateDate !== undefined && JSON.parse(data.data[a].oldValues).UpdateDate !== undefined) {

          } else {
            filtered_data.push((data.data[a]))
          }

          pager.total = data.paging.totalRecords;
          this.setState({
            data: data.data,
            pagination: pager
          });
        }
        this.setState({
          loading: false,
        })

      })
    } else if (this.props.for === "invoice") {

      this.props.getInvoiceLog(this.props.match.params.id, params).then(data => {
        pager.total = data.paging.totalRecords;
        this.setState({
          loading: false,
          data: data.data,
          pagination: pager
        });

      })
    } else if (this.props.for === "organization" || this.props.for === "b2b2c-organization") {
      this.setState({ loading: true });
      this.props.getOrgLogs(this.props.match.params.id, params).then(data => {
        pager.total = data.paging.totalRecords;
        this.setState({
          loading: false,
          data: data.data,
          pagination: pager
        });

      })

    } else if (this.props.for === "reseller") {
      let orgId = (this.props.userData && this.props.userData.companyID) ? this.props.userData.companyID : null;
      if (orgId === null) {
        let cu = JSON.parse(localStorage.getItem("user"));
        if (cu && cu.companyID) {
          orgId = cu.companyID;
        }
      }
      this.props.getOrgLogs(orgId, params).then(data => {
        pager.total = data.paging.totalRecords;
        this.setState({
          loading: false,
          data: data.data,
          pagination: pager
        });

      })

    } else if (this.props.for === "product") {
      this.setState({ loading: true });
      this.props.getProdLogs(this.props.match.params.id, params).then(data => {
        pager.total = data.paging.totalRecords;
        this.setState({
          loading: false,
          data: data.data,
          pagination: pager
        });

      })
    } else if (this.props.for === "pricing") {
      this.setState({ loading: true });
      this.props.getPricingLog(this.props.match.params.id, params).then(data => {
        pager.total = data.paging.totalRecords;
        this.setState({
          loading: false,
          data: data.data,
          pagination: pager
        });

      })
      .catch(ex => {
        this.setState({ loading: false });
      });
    }

  }

  showModal = (data) => {
    this.setState({ activityLogs: [] })
    var value = data;
    var dataPricingInfo = [];
    var dataPricing = [];
    this.setState({
      visible: true,
    });
    let record = this.state.data[data];
    if (this.state.data && JSON.parse(record.newValues) &&
      JSON.parse(record.newValues).PricingInfo.length > 0 &&
      JSON.parse(record.oldValues) &&
      JSON.parse(record.oldValues).PricingInfo.length === 0) {
      dataPricingInfo = [{ pricinginfo: 'New Pricing Created' }]
      this.setState({ activityLogs: dataPricingInfo })

    }
    else {
      if (record.oldValues !== null && JSON.parse(record.newValues).Name !== JSON.parse(record.oldValues).Name) {
        dataPricing.push({
          pricinginfo: "Name changed from <b>" + JSON.parse(record.oldValues).Name +
            "</b> to <b>" + JSON.parse(record.newValues).Name + "</b>"
        })
      }
      if (record.oldValues !== null && JSON.parse(record.newValues).Description !== JSON.parse(record.oldValues).Description) {
        dataPricing.push({
          pricinginfo: "Description changed from <b>" + JSON.parse(record.oldValues).Description +
            "</b> to <b>" + JSON.parse(record.newValues).Description + "</b>"
        })
      }
      if (record.oldValues !== null && JSON.parse(record.newValues).CalculationType !== JSON.parse(record.oldValues).CalculationType) {
        dataPricing.push({
          pricinginfo: "Calculation Type changed from <b>" + JSON.parse(record.oldValues).CalculationType +
            "</b> to <b>" + JSON.parse(record.newValues).CalculationType + "</b>"
        })
      }
      if (record.oldValues !== null && JSON.parse(record.newValues).CalculationFor !== JSON.parse(record.oldValues).CalculationFor) {
        dataPricing.push({
          pricinginfo: "Calculation For changed from <b>" + JSON.parse(record.oldValues).CalculationFor +
            "</b> to <b>" + JSON.parse(record.newValues).CalculationFor + "</b>"
        })
      }
      if (record.oldValues !== null && JSON.parse(record.newValues).DerivedPrice !== JSON.parse(record.oldValues).DerivedPrice) {
        dataPricing.push({
          pricinginfo: "Derived Price changed from <b>" + JSON.parse(record.oldValues).DerivedPrice +
            "</b> to <b>" + JSON.parse(record.newValues).DerivedPrice + "</b>"
        })
      }
      if (record.oldValues !== null && JSON.parse(record.newValues).Operand !== JSON.parse(record.oldValues).Operand) {
        dataPricing.push({
          pricinginfo: "Operand changed from <b>" + JSON.parse(record.oldValues).Operand +
            "</b> to <b>" + JSON.parse(record.newValues).Operand + "</b>"
        })
      }
      if (this.state.data && this.state.data.length > 0 && JSON.parse(this.state.data[value].newValues).PricingInfo &&
        JSON.parse(this.state.data[value].newValues).PricingInfo.length > 0) {
        for (var m = 0; m < JSON.parse(this.state.data[value].newValues).PricingInfo.length; m++) {


          if (JSON.parse(this.state.data[value].oldValues).PricingInfo[m].Price !== JSON.parse(this.state.data[value].newValues).PricingInfo[m].Price) {
            dataPricingInfo.push({
              pricinginfo: ("Price for Tier " + JSON.parse(this.state.data[value].newValues).PricingInfo[m].TierId
                + " and " + JSON.parse(this.state.data[value].newValues).PricingInfo[m].DayPackage
                + " Day Packages changed from <b>" +
                JSON.parse(this.state.data[value].oldValues).PricingInfo[m].Price + "</b> to <b>" +
                JSON.parse(this.state.data[value].newValues).PricingInfo[m].Price + "</b>")
            })
          }
        }
        this.setState({ activityLogs: dataPricing.concat(dataPricingInfo) })

      }
    }

  };

  handleOk = () => {
    this.setState({ visible: false });
  };
  handleCancel = () => {
    this.setState({ visible: false });
  };
  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    }
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };

  backbutton() {
    if (this.props.userData && this.props.userData.role === 'Administrator') {
      if (this.props.for === 'users') {
        this.props.history.push("/admin/users/")

      }
      // else if (this.props.for === 'agents') {
      //   this.props.history.push("/admin/agents/")

      // }
      else if (this.props.for === 'invoice') {
        this.props.history.push("/admin/accounts-billing/organization-invoice/" + this.props.match.params.id)

      } else if (this.props.for === 'organization') {
        this.props.history.push("/admin/organizations/")

      } else if (this.props.for === 'b2b2c-organization') {
        this.props.history.push("/admin/b2b2c-organizations/")

      } else if (this.props.for === 'reseller') {
        this.props.history.push("/reseller/")

      } else if (this.props.for === 'product') {
        this.props.history.push("/admin/products/")

      } else if (this.props.for === 'pricing') {
        this.props.history.push("../")

      } else if (this.props.for === 'employees') {
        this.props.history.push("../")

      }
    } else {

      if (this.props.for === 'organization' || this.props.for === 'reseller' || this.props.for === 'users') {
        this.props.history.goBack();
      } else if (this.props.for === 'employees') {
        this.props.history.push('../');
      } else if (this.props.for === 'pricing') {
        this.props.history.push('../');
      }
    }


  }
  executiveid(id) {
    return id
  }
  countryid(id) {
    if (this.state.countries.filter(data => (data.countryId === id)).length > 0) {
      var info = this.state.countries.filter(data => (data.countryId === id))[0].countryName;
    }
    return info;
  }
  getLogsKeys = () => {

    return [
      {
        title: "Activity Logs",
        dataIndex: "pricinginfo",
        key: "pricinginfo",
        ...this.getColumnSearchProps("pricinginfo"),
        render: (_data, record) => (
          <div>
            <div dangerouslySetInnerHTML={{ __html: record.pricinginfo.replace(/(<? *script)/gi, 'illegalscript') }} >
            </div>
          </div>
        )

      },

    ]
  }

  render() {

    let _this = this;

    const getHeaderKeys = [
      {
        title: <IntlMessages id="admin.viewLogs.datetime" />,
        dataIndex: "dateTimeStamp",
        key: "dateTimeStamp",
        width: 180,
        render: (text, record) => (
          <span>{moment.utc(record.dateTimeStamp).local().format(dates)}</span>

        )
      },

      {
        title: <IntlMessages id="admin.viewLogs.update" />,
        dataIndex: "transUser",
        key: "transUser",
        width: 180,
      },
      {
        title: <IntlMessages id="admin.viewLogs.activity" />,
        dataIndex: "oldValues",
        key: "oldValues",
        //width: "37%",
        render: (text, record, index) => (
          <div>
            {/* // users // */}
            {this.props.for === "users" || this.props.for === "travellers" || this.props.for === "employees" ?
              <div>
                {record.oldValues === null && JSON.parse(record.newValues) ?
                  <div>
                    <IntlMessages id="activity.logs.userCreated" />
                  </div> : ''}
                {record.oldValues !== null && JSON.parse(record.newValues).FirstName !== JSON.parse(record.oldValues).FirstName ?
                  <div>
                    <IntlMessages id="admin.userlisting.firstName" /> <IntlMessages id="activity.logs.changesFrom" /> <b>{JSON.parse(record.oldValues).FirstName ? JSON.parse(record.oldValues).FirstName : '" "'}</b> <IntlMessages id="activity.logs.to" /> <b>{JSON.parse(record.newValues).FirstName ? JSON.parse(record.newValues).FirstName : '" "'}</b>
                  </div> : ''}
                {record.oldValues !== null && JSON.parse(record.newValues).LastName !== JSON.parse(record.oldValues).LastName ?
                  <div>
                    <IntlMessages id="admin.userlisting.lastName" /> <IntlMessages id="activity.logs.changesFrom" /> <b>{JSON.parse(record.oldValues).LastName ? JSON.parse(record.oldValues).LastName : '" "'}</b> <IntlMessages id="activity.logs.to" /> <b>{JSON.parse(record.newValues).LastName ? JSON.parse(record.newValues).LastName : '" "'}</b>
                  </div> : ''}
                {record.oldValues !== null && JSON.parse(record.newValues).ContactNo !== JSON.parse(record.oldValues).ContactNo ?
                  <div>
                    <IntlMessages id="admin.logs.phonenumber" /> <IntlMessages id="activity.logs.changesFrom" /> <b>{JSON.parse(record.oldValues).ContactNo ? JSON.parse(record.oldValues).ContactNo : '" "'}</b> <IntlMessages id="activity.logs.to" /> <b>{JSON.parse(record.newValues).ContactNo ? JSON.parse(record.newValues).ContactNo : '" "'}</b>
                  </div> : ''}
                {record.oldValues !== null && JSON.parse(record.newValues).Address !== JSON.parse(record.oldValues).Address ?
                  <div>
                    <IntlMessages id="admin.userlisting.address" /> <IntlMessages id="activity.logs.changesFrom" /> <b>{JSON.parse(record.oldValues).Address ? JSON.parse(record.oldValues).Address : '" "'}</b> <IntlMessages id="activity.logs.to" /> <b>{JSON.parse(record.newValues).Address ? JSON.parse(record.newValues).Address : '" "'}</b>
                  </div> : ''}
                {record.oldValues !== null && JSON.parse(record.newValues).State !== JSON.parse(record.oldValues).State ?
                  <div>
                    <IntlMessages id="admin.userlisting.state" /> <IntlMessages id="activity.logs.changesFrom" /> <b>{JSON.parse(record.oldValues).State ? JSON.parse(record.oldValues).State : '" "'}</b> <IntlMessages id="activity.logs.to" /> <b>{JSON.parse(record.newValues).State ? JSON.parse(record.newValues).State : '" "'}</b>
                  </div> : ''}
                {record.oldValues !== null && JSON.parse(record.newValues).CountryId !== JSON.parse(record.oldValues).CountryId ?
                  <div>
                    <IntlMessages id="admin.userlisting.country" /> <IntlMessages id="activity.logs.changesFrom" /> <b>{JSON.parse(record.oldValues).CountryId ? this.countryid(JSON.parse(record.oldValues).CountryId) : '" "'}</b> <IntlMessages id="activity.logs.to" /> <b>{JSON.parse(record.newValues).CountryId ? this.countryid(JSON.parse(record.newValues).CountryId) : '" "'}</b>
                  </div> : ''}
                {record.oldValues !== null && JSON.parse(record.newValues).DateOfBirth !== JSON.parse(record.oldValues).DateOfBirth ?
                  <div>
                    <IntlMessages id="admin.employee.dob" /> <IntlMessages id="activity.logs.changesFrom" /> <b>{JSON.parse(record.oldValues).DateOfBirth ? moment(JSON.parse(record.oldValues).DateOfBirth).format("MM/DD/YYYY") : '" "'}</b> <IntlMessages id="activity.logs.to" /> <b>{JSON.parse(record.newValues).DateOfBirth ? moment(JSON.parse(record.newValues).DateOfBirth).format("MM/DD/YYYY") : '" "'}</b>
                  </div> : ''}
                {record.oldValues !== null && JSON.parse(record.newValues).EmergencyContactNo !== JSON.parse(record.oldValues).EmergencyContactNo ?
                  <div>
                    <IntlMessages id="admin.traveler.emergencycontact" /> <IntlMessages id="activity.logs.changesFrom" /> <b>{JSON.parse(record.oldValues).EmergencyContactNo ? JSON.parse(record.oldValues).EmergencyContactNo : '" "'}</b> <IntlMessages id="activity.logs.to" /> <b>{JSON.parse(record.newValues).EmergencyContactNo ? JSON.parse(record.newValues).EmergencyContactNo : '" "'}</b>
                  </div> : ''}
                {record.oldValues !== null && JSON.parse(record.newValues).CompanyName !== JSON.parse(record.oldValues).CompanyName ?
                  <div>
                    <IntlMessages id="admin.traveler.CompanyName" /> <IntlMessages id="activity.logs.changesFrom" /> <b>{JSON.parse(record.oldValues).CompanyName ? JSON.parse(record.oldValues).CompanyName : '" "'}</b> <IntlMessages id="activity.logs.to" /> <b>{JSON.parse(record.newValues).CompanyName ? JSON.parse(record.newValues).CompanyName : '" "'}</b>
                  </div> : ''}
                {record.oldValues !== null && JSON.parse(record.newValues).EmergencyLastName !== JSON.parse(record.oldValues).EmergencyLastName ?
                  <div>
                    <IntlMessages id="admin.traveler.emergencylastName" /> <IntlMessages id="activity.logs.changesFrom" /> <b>{JSON.parse(record.oldValues).EmergencyLastName ? JSON.parse(record.oldValues).EmergencyLastName : '" "'}</b> <IntlMessages id="activity.logs.to" /> <b>{JSON.parse(record.newValues).EmergencyLastName ? JSON.parse(record.newValues).EmergencyLastName : '" "'}</b>
                  </div> : ''}
                {record.oldValues !== null && JSON.parse(record.newValues).EmergencyFirstName !== JSON.parse(record.oldValues).EmergencyFirstName ?
                  <div>
                    <IntlMessages id="admin.traveler.emergencyFirstName" /> <IntlMessages id="activity.logs.changesFrom" /> <b>{JSON.parse(record.oldValues).EmergencyFirstName ? JSON.parse(record.oldValues).EmergencyFirstName : '" "'}</b> <IntlMessages id="activity.logs.to" /> <b>{JSON.parse(record.newValues).EmergencyFirstName ? JSON.parse(record.newValues).EmergencyFirstName : '" "'}</b>
                  </div> : ''}
                {record.oldValues !== null && JSON.parse(record.newValues).EmergencyRelationship !== JSON.parse(record.oldValues).EmergencyRelationship ?
                  <div>
                    <IntlMessages id="admin.traveler.Relationship" /> <IntlMessages id="activity.logs.changesFrom" /> <b>{JSON.parse(record.oldValues).EmergencyRelationship ? JSON.parse(record.oldValues).EmergencyRelationship : '" "'}</b> <IntlMessages id="activity.logs.to" /> <b>{JSON.parse(record.newValues).EmergencyRelationship ? JSON.parse(record.newValues).EmergencyRelationship : '" "'}</b>
                  </div> : ''}
                {record.oldValues !== null && JSON.parse(record.newValues).IsActive !== JSON.parse(record.oldValues).IsActive ?
                  <div>
                    <IntlMessages id="admin.userlisting.status" /> <IntlMessages id="activity.logs.changesFrom" /> {JSON.parse(record.oldValues).IsActive ? <b>Active</b> : <b>Inactive</b>} <IntlMessages id="activity.logs.to" /> {JSON.parse(record.newValues).IsActive ? <b>Active</b> : <b>Inactive</b>}
                  </div> : ''}
                {record.oldValues !== null && JSON.parse(record.newValues).IsGeoLocationEnabled !== JSON.parse(record.oldValues).IsGeoLocationEnabled ?
                  <div>
                    {JSON.parse(record.newValues).IsGeoLocationEnabled === true ? <IntlMessages id="admin.logs.shareLocationOn"></IntlMessages> : <IntlMessages id="admin.logs.shareLocationOff"></IntlMessages>}
                  </div> : ''}
                {Object.keys(JSON.parse(record.newValues)).length === 1 &&
                  JSON.parse(record.newValues).UpdateDate !== undefined && JSON.parse(record.oldValues).UpdateDate !== undefined ? 'Changes at Backend' : ''}

              </div>
              :
              // invoice start
              this.props.for === "invoice" ?
                <div>
                  {record.oldValues === null && JSON.parse(record.newValues).PaymentStatus === 0 ?
                    <IntlMessages id="activity.logs.invoiceCreated" /> :
                    (JSON.parse(record.oldValues).PONumber !== null && JSON.parse(record.newValues).PONumber !== null ||
                      JSON.parse(record.oldValues).PONumber !== null && JSON.parse(record.newValues).PONumber === null ||
                      JSON.parse(record.oldValues).PONumber === null && JSON.parse(record.newValues).PONumber !== null) ?
                      <div>
                        <IntlMessages id="admin.productlist.name" /> <IntlMessages id="activity.logs.changesFrom" /> {JSON.parse(record.oldValues).PONumber} <IntlMessages id="activity.logs.to" /> {JSON.parse(record.newValues).PONumber}
                      </div> : ''}
                  {JSON.parse(record.oldValues) && JSON.parse(record.oldValues).PaymentStatus === 0 && JSON.parse(record.newValues).PaymentStatus !== 0 ?
                    <IntlMessages id="activity.logs.paymentstatus"></IntlMessages> : ''
                  }
                </div>
                // org start

                : this.props.for === "organization" ?
                  <div>
                    {record.oldValues === null && JSON.parse(record.newValues) !== null ?
                      <IntlMessages id="activity.logs.organizationCreated" /> : ''}
                    {JSON.parse(record.oldValues) && JSON.parse(record.newValues) && JSON.parse(record.oldValues).GlobalOrgId !== JSON.parse(record.newValues).GlobalOrgId ?
                      <div>
                        <IntlMessages id="admin.logs.GlobalOrgId" />
                      </div>
                      : ''}

                    {JSON.parse(record.oldValues) && JSON.parse(record.newValues) && JSON.parse(record.oldValues).JobNumber !== JSON.parse(record.newValues).JobNumber ?
                      <div>
                        <IntlMessages id="admin.primaryaddress.jobnumber" /> <IntlMessages id="activity.logs.changesFrom" /> <b>{JSON.parse(record.oldValues).JobNumber ? JSON.parse(record.oldValues).JobNumber : '""'}</b> <IntlMessages id="activity.logs.to" /> <b>{JSON.parse(record.newValues).JobNumber}</b>
                      </div>
                      : ''}
                    {JSON.parse(record.oldValues) && JSON.parse(record.newValues) && JSON.parse(record.oldValues).CustomerID !== JSON.parse(record.newValues).CustomerID ?
                      <div>
                        <IntlMessages id="admin.primaryaddress.customerId" /> <IntlMessages id="activity.logs.changesFrom" /> <b>{JSON.parse(record.oldValues).CustomerID ? JSON.parse(record.oldValues).CustomerID : '""'}</b> <IntlMessages id="activity.logs.to" /> <b>{JSON.parse(record.newValues).CustomerID}</b>
                      </div>
                      : ''}
                    {JSON.parse(record.oldValues) && JSON.parse(record.newValues) && JSON.parse(record.oldValues).Address !== JSON.parse(record.newValues).Address ?
                      <div>
                        <IntlMessages id="admin.primaryaddress.address" /> <IntlMessages id="activity.logs.changesFrom" /> <b>{JSON.parse(record.oldValues).Address ? JSON.parse(record.oldValues).Address : '""'}</b> <IntlMessages id="activity.logs.to" /> <b>{JSON.parse(record.newValues).Address}</b>
                      </div>
                      : ''}
                    {JSON.parse(record.oldValues) && JSON.parse(record.newValues) && JSON.parse(record.oldValues).City !== JSON.parse(record.newValues).City ?
                      <div>
                        <IntlMessages id="admin.primaryaddress.city" /> <IntlMessages id="activity.logs.changesFrom" /> <b>{JSON.parse(record.oldValues).City ? JSON.parse(record.oldValues).City : '""'}</b> <IntlMessages id="activity.logs.to" /> <b>{JSON.parse(record.newValues).City}</b>
                      </div>
                      : ''}
                    {JSON.parse(record.oldValues) && JSON.parse(record.newValues) && JSON.parse(record.oldValues).PhoneNo !== JSON.parse(record.newValues).PhoneNo ?
                      <div>
                        <IntlMessages id="admin.logs.phonenumber" /> <IntlMessages id="activity.logs.changesFrom" /> <b>{JSON.parse(record.oldValues).PhoneNo ? JSON.parse(record.oldValues).PhoneNo : '""'}</b> <IntlMessages id="activity.logs.to" /> <b>{JSON.parse(record.newValues).PhoneNo}</b>
                      </div>
                      : ''}
                    {JSON.parse(record.oldValues) && JSON.parse(record.newValues) && JSON.parse(record.oldValues).State !== JSON.parse(record.newValues).State ?
                      <div>
                        <IntlMessages id="admin.primaryaddress.state" /> <IntlMessages id="activity.logs.changesFrom" /> <b>{JSON.parse(record.oldValues).State ? JSON.parse(record.oldValues).State : '""'}</b> <IntlMessages id="activity.logs.to" /> <b>{JSON.parse(record.newValues).State}</b>
                      </div>
                      : ''}
                    {JSON.parse(record.oldValues) && JSON.parse(record.newValues) && JSON.parse(record.oldValues).ZipCode !== JSON.parse(record.newValues).ZipCode ?
                      <div>
                        <IntlMessages id="admin.userlisting.zipcode" /> <IntlMessages id="activity.logs.changesFrom" /> <b>{JSON.parse(record.oldValues).ZipCode ? JSON.parse(record.oldValues).ZipCode : '""'}</b> <IntlMessages id="activity.logs.to" /> <b>{JSON.parse(record.newValues).ZipCode}</b>
                      </div>
                      : ''}

                    {JSON.parse(record.oldValues) && JSON.parse(record.newValues) && JSON.parse(record.oldValues).CountryId !== JSON.parse(record.newValues).CountryId ?
                      <div>
                        <IntlMessages id="admin.primaryaddress.country" /> <IntlMessages id="activity.logs.changesFrom" /> <b>{this.countryid(JSON.parse(record.oldValues).CountryId)}</b> <IntlMessages id="activity.logs.to" /> <b>{this.countryid(JSON.parse(record.newValues).CountryId)}</b>
                      </div>
                      : ''}

                    {JSON.parse(record.oldValues) && JSON.parse(record.newValues) && JSON.parse(record.oldValues).Logo !== JSON.parse(record.newValues).Logo ?
                      <div>
                        <IntlMessages id="admin.itinerary.logoUpdated" />
                      </div>
                      : ''}
                    {JSON.parse(record.oldValues) && JSON.parse(record.newValues) && JSON.parse(record.oldValues).Name !== JSON.parse(record.newValues).CountryId ?
                      <div>
                        <IntlMessages id="admin.organizations.list.name" /> <IntlMessages id="activity.logs.changesFrom" /> <b>{(JSON.parse(record.oldValues).Name)}</b> <IntlMessages id="activity.logs.to" /> <b>{(JSON.parse(record.newValues).Name)}</b>
                      </div>
                      : ''}
                    {JSON.parse(record.oldValues) && JSON.parse(record.newValues) && JSON.parse(record.oldValues).IsBusinessAddressSame !== JSON.parse(record.newValues).IsBusinessAddressSame ?
                      <div>
                        {JSON.parse(record.newValues).IsBusinessAddressSame === true ? <span> <IntlMessages id="admin.secondary-address"></IntlMessages> <IntlMessages id="activity.logs.changesTo"></IntlMessages> <IntlMessages id="admin.organization.address"> </IntlMessages></span> : <span> <IntlMessages id="admin.secondary-address"></IntlMessages> <IntlMessages id="activity.logs.changesFrom"></IntlMessages> <IntlMessages id="admin.organization.address"> </IntlMessages></span>}
                      </div>
                      : ''}
                    {JSON.parse(record.oldValues) && JSON.parse(record.newValues) && JSON.parse(record.oldValues).ThemeId !== JSON.parse(record.newValues).ThemeId ?
                      <div>
                        <IntlMessages id="admin.logs.themeUpdated"></IntlMessages>
                      </div>
                      : ''}
                    {JSON.parse(record.oldValues) && JSON.parse(record.newValues) && JSON.parse(record.oldValues).IsEmailActive !== JSON.parse(record.newValues).IsEmailActive ?
                      <div>
                        {JSON.parse(record.newValues).IsEmailActive ? <IntlMessages id="admin.logs.emailsetOn"></IntlMessages> : <IntlMessages id="admin.logs.emailsetOff"></IntlMessages>}
                      </div>
                      : ''}
                    {JSON.parse(record.oldValues) && JSON.parse(record.newValues) && JSON.parse(record.oldValues).IsNotificationActive !== JSON.parse(record.newValues).IsNotificationActive ?
                      <div>
                        {JSON.parse(record.newValues).IsNotificationActive ? <IntlMessages id="admin.logs.notificationsetOn"></IntlMessages> : <IntlMessages id="admin.logs.notificationsetOff"></IntlMessages>}

                      </div>
                      : ''}
                    {JSON.parse(record.oldValues) && JSON.parse(record.newValues) && JSON.parse(record.oldValues).IsSmsactive !== JSON.parse(record.newValues).IsSmsactive ?
                      <div>
                        {JSON.parse(record.newValues).IsSmsactive ? <IntlMessages id="admin.logs.messagesetOn"></IntlMessages> : <IntlMessages id="admin.logs.messagesetOff"></IntlMessages>}

                      </div>
                      : ''}


                  </div>
                  // product start
                  : this.props.for === "product" ?
                    <div>
                      {JSON.parse(record.oldValues) && JSON.parse(record.newValues) && JSON.parse(record.oldValues).Name !== JSON.parse(record.newValues).Name ?
                        <div>
                          <IntlMessages id="admin.productlist.name" /> <IntlMessages id="activity.logs.changesFrom" /> <b>{JSON.parse(record.oldValues).Name ? JSON.parse(record.oldValues).Name : '" "'}</b> <IntlMessages id="activity.logs.to" /> <b>{JSON.parse(record.newValues).Name ? JSON.parse(record.newValues).Name : '" "'}</b>
                        </div>

                        : ''}
                    </div>
                    : this.props.for === "pricing" ?
                      <div>
                        <button
                          type="primary"
                          className="icon"
                          onClick={() => this.showModal(index)}

                        >
                          <Tooltip title="View Logs">
                            <i className="fas fa-eye"></i>
                          </Tooltip>

                        </button>
                      </div>

                      : this.props.for === "b2b2c-organization" || this.props.for === "reseller" ?
                        <div>
                          {record.oldValues === null && JSON.parse(record.newValues) !== null ?
                            <IntlMessages id="activity.logs.organizationCreated" /> : ''}
                          {JSON.parse(record.oldValues) && JSON.parse(record.newValues) && JSON.parse(record.oldValues).GlobalOrgId !== JSON.parse(record.newValues).GlobalOrgId ?
                            <div>
                              <IntlMessages id="admin.logs.GlobalOrgId" />
                            </div>
                            : ''}

                          {JSON.parse(record.oldValues) && JSON.parse(record.newValues) && JSON.parse(record.oldValues).JobNumber !== JSON.parse(record.newValues).JobNumber ?
                            <div>
                              <IntlMessages id="admin.primaryaddress.jobnumber" /> <IntlMessages id="activity.logs.changesFrom" /> <b>{JSON.parse(record.oldValues).JobNumber ? JSON.parse(record.oldValues).JobNumber : '""'}</b> <IntlMessages id="activity.logs.to" /> <b>{JSON.parse(record.newValues).JobNumber}</b>
                            </div>
                            : ''}
                          {JSON.parse(record.oldValues) && JSON.parse(record.newValues) && JSON.parse(record.oldValues).CustomerID !== JSON.parse(record.newValues).CustomerID ?
                            <div>
                              <IntlMessages id="admin.primaryaddress.customerId" /> <IntlMessages id="activity.logs.changesFrom" /> <b>{JSON.parse(record.oldValues).CustomerID ? JSON.parse(record.oldValues).CustomerID : '""'}</b> <IntlMessages id="activity.logs.to" /> <b>{JSON.parse(record.newValues).CustomerID}</b>
                            </div>
                            : ''}
                          {JSON.parse(record.oldValues) && JSON.parse(record.newValues) && JSON.parse(record.oldValues).Address !== JSON.parse(record.newValues).Address ?
                            <div>
                              <IntlMessages id="admin.primaryaddress.address" /> <IntlMessages id="activity.logs.changesFrom" /> <b>{JSON.parse(record.oldValues).Address ? JSON.parse(record.oldValues).Address : '""'}</b> <IntlMessages id="activity.logs.to" /> <b>{JSON.parse(record.newValues).Address}</b>
                            </div>
                            : ''}
                          {JSON.parse(record.oldValues) && JSON.parse(record.newValues) && JSON.parse(record.oldValues).City !== JSON.parse(record.newValues).City ?
                            <div>
                              <IntlMessages id="admin.primaryaddress.city" /> <IntlMessages id="activity.logs.changesFrom" /> <b>{JSON.parse(record.oldValues).City ? JSON.parse(record.oldValues).City : '""'}</b> <IntlMessages id="activity.logs.to" /> <b>{JSON.parse(record.newValues).City}</b>
                            </div>
                            : ''}
                          {JSON.parse(record.oldValues) && JSON.parse(record.newValues) && JSON.parse(record.oldValues).PhoneNo !== JSON.parse(record.newValues).PhoneNo ?
                            <div>
                              <IntlMessages id="admin.logs.phonenumber" /> <IntlMessages id="activity.logs.changesFrom" /> <b>{JSON.parse(record.oldValues).PhoneNo ? JSON.parse(record.oldValues).PhoneNo : '""'}</b> <IntlMessages id="activity.logs.to" /> <b>{JSON.parse(record.newValues).PhoneNo}</b>
                            </div>
                            : ''}
                          {JSON.parse(record.oldValues) && JSON.parse(record.newValues) && JSON.parse(record.oldValues).State !== JSON.parse(record.newValues).State ?
                            <div>
                              <IntlMessages id="admin.primaryaddress.state" /> <IntlMessages id="activity.logs.changesFrom" /> <b>{JSON.parse(record.oldValues).State ? JSON.parse(record.oldValues).State : '""'}</b> <IntlMessages id="activity.logs.to" /> <b>{JSON.parse(record.newValues).State}</b>
                            </div>
                            : ''}
                          {JSON.parse(record.oldValues) && JSON.parse(record.newValues) && JSON.parse(record.oldValues).ZipCode !== JSON.parse(record.newValues).ZipCode ?
                            <div>
                              <IntlMessages id="admin.userlisting.zipcode" /> <IntlMessages id="activity.logs.changesFrom" /> <b>{JSON.parse(record.oldValues).ZipCode ? JSON.parse(record.oldValues).ZipCode : '""'}</b> <IntlMessages id="activity.logs.to" /> <b>{JSON.parse(record.newValues).ZipCode}</b>
                            </div>
                            : ''}

                          {JSON.parse(record.oldValues) && JSON.parse(record.newValues) && JSON.parse(record.oldValues).CountryId !== JSON.parse(record.newValues).CountryId ?
                            <div>
                              <IntlMessages id="admin.primaryaddress.country" /> <IntlMessages id="activity.logs.changesFrom" /> <b>{this.countryid(JSON.parse(record.oldValues).CountryId)}</b> <IntlMessages id="activity.logs.to" /> <b>{this.countryid(JSON.parse(record.newValues).CountryId)}</b>
                            </div>
                            : ''}

                          {JSON.parse(record.oldValues) && JSON.parse(record.newValues) && JSON.parse(record.oldValues).Logo !== JSON.parse(record.newValues).Logo ?
                            <div>
                              <IntlMessages id="admin.itinerary.logoUpdated" />
                            </div>
                            : ''}
                          {JSON.parse(record.oldValues) && JSON.parse(record.newValues) && JSON.parse(record.oldValues).Name !== JSON.parse(record.newValues).CountryId ?
                            <div>
                              <IntlMessages id="admin.organizations.list.name" /> <IntlMessages id="activity.logs.changesFrom" /> <b>{(JSON.parse(record.oldValues).Name)}</b> <IntlMessages id="activity.logs.to" /> <b>{(JSON.parse(record.newValues).Name)}</b>
                            </div>
                            : ''}
                          {JSON.parse(record.oldValues) && JSON.parse(record.newValues) && JSON.parse(record.oldValues).IsBusinessAddressSame !== JSON.parse(record.newValues).IsBusinessAddressSame ?
                            <div>
                              {JSON.parse(record.newValues).IsBusinessAddressSame === true ? <span> <IntlMessages id="admin.secondary-address"></IntlMessages> <IntlMessages id="activity.logs.changesTo"></IntlMessages> <IntlMessages id="admin.organization.address"> </IntlMessages></span> : <span> <IntlMessages id="admin.secondary-address"></IntlMessages> <IntlMessages id="activity.logs.changesFrom"></IntlMessages> <IntlMessages id="admin.organization.address"> </IntlMessages></span>}
                            </div>
                            : ''}
                          {JSON.parse(record.oldValues) && JSON.parse(record.newValues) && JSON.parse(record.oldValues).ThemeId !== JSON.parse(record.newValues).ThemeId ?
                            <div>
                              <IntlMessages id="admin.logs.themeUpdated"></IntlMessages>
                            </div>
                            : ''}
                          {JSON.parse(record.oldValues) && JSON.parse(record.newValues) && JSON.parse(record.oldValues).IsEmailActive !== JSON.parse(record.newValues).IsEmailActive ?
                            <div>
                              {JSON.parse(record.newValues).IsEmailActive ? <IntlMessages id="admin.logs.emailsetOn"></IntlMessages> : <IntlMessages id="admin.logs.emailsetOff"></IntlMessages>}
                            </div>
                            : ''}
                          {JSON.parse(record.oldValues) && JSON.parse(record.newValues) && JSON.parse(record.oldValues).IsNotificationActive !== JSON.parse(record.newValues).IsNotificationActive ?
                            <div>
                              {JSON.parse(record.newValues).IsNotificationActive ? <IntlMessages id="admin.logs.notificationsetOn"></IntlMessages> : <IntlMessages id="admin.logs.notificationsetOff"></IntlMessages>}

                            </div>
                            : ''}
                          {JSON.parse(record.oldValues) && JSON.parse(record.newValues) && JSON.parse(record.oldValues).IsSmsactive !== JSON.parse(record.newValues).IsSmsactive ?
                            <div>
                              {JSON.parse(record.newValues).IsSmsactive ? <IntlMessages id="admin.logs.messagesetOn"></IntlMessages> : <IntlMessages id="admin.logs.messagesetOff"></IntlMessages>}

                            </div>
                            : ''}

                          {JSON.parse(record.oldValues) && JSON.parse(record.newValues) && JSON.parse(record.oldValues).Commission === null && JSON.parse(record.newValues).Commission !== null ?
                            <div>
                              <IntlMessages id="admin.organizations.commission" /> <IntlMessages id="activity.logs.setTo" /> <b>{JSON.parse(record.newValues).Commission}%</b>
                            </div>
                          : JSON.parse(record.oldValues) && JSON.parse(record.newValues) && JSON.parse(record.oldValues).Commission !== JSON.parse(record.newValues).Commission ?
                            <div>
                              <IntlMessages id="admin.organizations.commission" /> <IntlMessages id="activity.logs.changesFrom" /> <b>{JSON.parse(record.oldValues).Commission}%</b> <IntlMessages id="activity.logs.to" /> <b>{JSON.parse(record.newValues).Commission}%</b>
                            </div>
                          : ''}

                          {JSON.parse(record.oldValues) && JSON.parse(record.newValues) ?
                            <>
                              {JSON.parse(record.oldValues).WizardIconActive === null && JSON.parse(record.newValues).WizardIconActive !== null ? 
                                <div>
                                  <IntlMessages id="activity.log.wizardiconactive" /> <IntlMessages id="activity.logs.setTo" /> <b>{Config.API1+"/CompanyWizardIcons/"+JSON.parse(record.newValues).WizardIconActive}</b>
                                </div>
                              : JSON.parse(record.oldValues).WizardIconActive !== null && JSON.parse(record.newValues).WizardIconActive === null ? 
                                <div>
                                  <IntlMessages id="activity.logs.removed" /> <IntlMessages id="activity.log.wizardiconactive" />
                                </div>
                              : JSON.parse(record.oldValues).WizardIconActive !== JSON.parse(record.newValues).WizardIconActive ? 
                                <div>
                                  <IntlMessages id="activity.log.wizardiconactive" /> <IntlMessages id="activity.logs.changesFrom" /> <b>{Config.API1+"/CompanyWizardIcons/"+JSON.parse(record.oldValues).WizardIconActive}</b> <IntlMessages id="activity.logs.to" /> <b>{Config.API1+"/CompanyWizardIcons/"+JSON.parse(record.newValues).WizardIconActive}</b>
                                </div>
                              : ''}

                              {JSON.parse(record.oldValues).WizardIcons === null && JSON.parse(record.newValues).WizardIcons !== null ? 
                                <div>
                                  <IntlMessages id="activity.log.wizardiconinactive" /> <IntlMessages id="activity.logs.setTo" /> <b>{Config.API1+"/CompanyWizardIcons/"+JSON.parse(record.newValues).WizardIcons}</b>
                                </div>
                              : JSON.parse(record.oldValues).WizardIcons !== null && JSON.parse(record.newValues).WizardIcons === null ? 
                                <div>
                                  <IntlMessages id="activity.logs.removed" /> <IntlMessages id="activity.log.wizardiconinactive" />
                                </div>
                              : JSON.parse(record.oldValues).WizardIcons !== JSON.parse(record.newValues).WizardIcons ? 
                                <div>
                                  <IntlMessages id="activity.log.wizardiconinactive" /> <IntlMessages id="activity.logs.changesFrom" /> <b>{Config.API1+"/CompanyWizardIcons/"+JSON.parse(record.oldValues).WizardIcons}</b> <IntlMessages id="activity.logs.to" /> <b>{Config.API1+"/CompanyWizardIcons/"+JSON.parse(record.newValues).WizardIcons}</b>
                                </div>
                              : ''}
                            
                            
                              {/* background image */}
                              {JSON.parse(record.oldValues).BackgroundImage === null && JSON.parse(record.newValues).BackgroundImage !== null ? 
                                <div>
                                  <IntlMessages id="activity.log.bgimage" /> <IntlMessages id="activity.logs.setTo" /> <b>{Config.API1+"/CompanyWizardIcons/"+JSON.parse(record.newValues).BackgroundImage}</b>
                                </div>
                              : JSON.parse(record.oldValues).BackgroundImage !== null && JSON.parse(record.newValues).BackgroundImage === null ? 
                                <div>
                                  <IntlMessages id="activity.logs.removed" /> <IntlMessages id="activity.log.bgimage" />
                                </div>
                              : JSON.parse(record.oldValues).BackgroundImage !== JSON.parse(record.newValues).BackgroundImage ? 
                                <div>
                                  <IntlMessages id="activity.log.bgimage" /> <IntlMessages id="activity.logs.changesFrom" /> <b>{Config.API1+"/CompanyWizardIcons/"+JSON.parse(record.oldValues).BackgroundImage}</b> <IntlMessages id="activity.logs.to" /> <b>{Config.API1+"/CompanyWizardIcons/"+JSON.parse(record.newValues).BackgroundImage}</b>
                                </div>
                              : ''}
                              
                              {/* footer visible */}
                              {JSON.parse(record.oldValues).IsFooterVisible === false && JSON.parse(record.newValues).IsFooterVisible === true ? 
                                <div>
                                  <IntlMessages id="activity.log.footervisible" /> <IntlMessages id="activity.logs.setTo" /> <b>{JSON.parse(record.newValues).IsFooterVisible.toString()}</b>
                                </div>
                              : JSON.parse(record.oldValues).IsFooterVisible === true && JSON.parse(record.newValues).IsFooterVisible === false ? 
                                <div>
                                  <IntlMessages id="activity.log.footervisible" /> <IntlMessages id="activity.logs.setTo" /> <b>{JSON.parse(record.newValues).IsFooterVisible.toString()}</b>
                                </div>
                              : ''}

                              {/* price */}
                              {JSON.parse(record.oldValues).B2B2CPriceName === null && JSON.parse(record.newValues).B2B2CPriceName !== null ? 
                                <div>
                                  <IntlMessages id="activity.log.pricing" /> <IntlMessages id="activity.logs.setTo" /> <b>{JSON.parse(record.newValues).B2B2CPriceName.toString()}</b>
                                </div>
                              : ''}
                              {typeof JSON.parse(record.oldValues).B2B2CPriceName !== "undefined" &&
                                typeof JSON.parse(record.newValues).B2B2CPriceName !== "undefined" && 
                                JSON.parse(record.oldValues).B2B2CPriceName !== null && 
                                JSON.parse(record.newValues).B2B2CPriceName !== null && 
                                JSON.parse(record.oldValues).B2B2CPriceId !== JSON.parse(record.newValues).B2B2CPriceId ? 
                                <div>
                                  <IntlMessages id="activity.log.pricing" /> <IntlMessages id="activity.logs.changesFrom" /> <b>{JSON.parse(record.oldValues).B2B2CPriceName.toString()}</b> <IntlMessages id="activity.logs.to" /> <b>{JSON.parse(record.newValues).B2B2CPriceName.toString()}</b>
                                </div>
                              : ''}

                              {/* IsCapLogoVisible */}
                              {JSON.parse(record.oldValues).IsCapLogoVisible !== JSON.parse(record.newValues).IsCapLogoVisible ? 
                                <div>
                                  <IntlMessages id="activity.log.caplogovisible" /> <IntlMessages id="activity.logs.setTo" /> <b>{JSON.parse(record.newValues).IsCapLogoVisible.toString()}</b>
                                </div>
                              : ''}

                              {/* FooterText */}
                              {JSON.parse(record.oldValues).FooterText !== JSON.parse(record.newValues).FooterText ? 
                                <div>
                                  <IntlMessages id="activity.log.footertext" /> <IntlMessages id="activity.logs.setTo" /> <b>{JSON.parse(record.newValues).FooterText}</b>
                                </div>
                              : ''}
                            </>
                          : ''}

                          {JSON.parse(record.oldValues) && JSON.parse(record.newValues) && JSON.parse(record.oldValues).UpdateDate && Object.keys(JSON.parse(record.newValues)).length == 1 ?
                            <div>
                              <IntlMessages id="activity.logs.updated" /> <IntlMessages id="activity.log.organization" />
                            </div>
                          : ''}

                        </div> : ''
            }
          </div>
        )
      },

    ];


    return (
      <div>
        <Spin spinning={this.state.loading}>
          <Modal
            visible={this.state.visible}
            title="Activity Logs"
            onOk={this.handleOk}
            onCancel={this.handleCancel}
            footer={[

              <Button key="submit" type="primary" onClick={this.handleOk}>
                Close
            </Button>,
            ]}
          >

            <Row gutter={24} style={{ overflowY: "auto", height: "400px" }}>
              <Col xs={24} style={{ overflowX: "auto" }}>
                {this.state.visible && <Table
                  bordered
                  columns={_this.getLogsKeys()}
                  dataSource={this.state.activityLogs}
                />}        </Col>
            </Row>
          </Modal>


          {this.props.for !== "travellers" && this.props.for !== "employees" ?
            <>
              <Row gutter={24}>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Typography.Title level={4}>
                    <IntlMessages id="admin.account.activitylog" />
                  </Typography.Title>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Button
                    type="primary"
                    className="right-fl"
                    htmlType="button"


                    onClick={() => this.backbutton()}
                  >
                    <IntlMessages id="admin.userlisting.back" />
                  </Button>
                </Col>
              </Row>
              <hr /> </> : ''}
          <Row gutter={24}>
            <Col xs={24} style={{ overflowX: "auto" }}>
              <Table

                bordered
                columns={getHeaderKeys}
                rowKey={record => record.userId}
                dataSource={this.state.data}
                pagination={this.state.pagination}
                onChange={this.handleTableChange}
              />
            </Col>
          </Row>
        </Spin>
      </div>
    );
  }
}

ActivityLog.propTypes = {
  location: PropTypes.object,
  getUserListing: PropTypes.func
};
function mapStateToProps(state) {
  return {
    ...state.user,
    ...state.pagination
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...logsActions, ...userActions, ...paginationActions }, dispatch);
}
export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    { forwardRef: true }
  )(ActivityLog)
);
