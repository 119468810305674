import React, { Component } from "react";
import { Row, Col, Typography, Spin } from "antd";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import TotalIncome from "./total-income/total-income";
import TotalSales from "./total-sales/total-sales";
import TotalViews from "./total-views/total-views";
import ProductGraph from "./product-graph";
import GoogleMapComponent from "./traveler-map/traveler-map";
import DataAnalysis from "./data-analysis/data-analysis";
import ListCompaines from "./list-compines/list-compaines";
import ListTravelers from "./list-travelers/list-travelers";
import * as Permission from "../../../services/permissions";
import * as AdminActions from "../../../redux/actions/admin-actions";
import * as ProductActions from "../../../redux/actions/product-actions";
import * as paginationActions from "../../../redux/actions/pagination-actions";
import IntlMessages from "../../../services/intlMesseges";
import moment from "moment";
import { DatePicker } from "antd";
import PropTypes from "prop-types";
//import console = require("console");
const { RangePicker } = DatePicker;
const dateFormat = "MM-DD-YYYY";
const queryDateFormatStart = "YYYY-MM-DD 00:00:00";
const queryDateFormatEnd = "YYYY-MM-DD 23:59:59";


//moment.tz.setDefault();
let now = new Date();
let start = moment(new Date(now.getFullYear(), now.getMonth(), 1, 0, 0, 0, 0));
let end = moment(
  new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59, 0)
);

class AdminHome extends Component {
  state = {
    productsSaleData: [],
    salesData: {
      data: [],
      total: 0
    },
    incomeData: {
      data: [],
      total: 0
    },
    profitData: {
      data: [],
      total: 0
    },
    notificationsData: {
      emails: 0,
      sms: 0,
      pushNotifications: 0,
      notifications: 0
    },
    isDPEnabled: true,
    dataPickerDates: [start, end],
    visible: false,
    statsLoading: true
  };
  componentDidMount = () => {
    this.props.clearPaginationExceptMe('dashboard');
    this.loadProductSalesData();
  };
  loadProductSalesData = async () => {
    let _this = this;
    let dateRanges = _this.state.dataPickerDates;

    let args = {
      startDate: dateRanges[0].format(queryDateFormatStart),
      endDate: dateRanges[1].format(queryDateFormatEnd)
    };

    /* total sales */
    this.props.getTotalSales(args).then(response => {
      let _salesData = this.state.salesData;
      _salesData.data = response.data && response.data.map(function(el) {
        return { Date: el.orderDate, "Total Sale": el.totalSale };
      });
      _salesData.total = response.data && response.data.reduce(function(tot, el) {
        return tot + el.totalSale;
      }, 0);
      this.setState({
        salesData: _salesData
      });
    });

    /* total income */
    this.props.getTotalIncome(args).then(response => {
      let _incomeData = this.state.incomeData;
      _incomeData.total = response.data && response.data.reduce(function(tot, el) {
        return tot + el.totalIncome;
      }, 0);
      _incomeData.data = response.data.map(function(el) {
        return { Date: el.orderDate, "Total Income": el.totalIncome };
      });
      this.setState({
        incomeData: _incomeData
      });
    });

    /* total profit */
    this.props.getTotalProfit(args).then(response => {
      let _profitData = this.state.profitData;
      _profitData.data = response.data && response.data.map(function(el) {
        return { Date: el.orderDate, "Total Profit": el.totalProfit };
      });
      _profitData.total = response.data
        .reduce(function(tot, el) {
          return tot + el.totalProfit;
        }, 0)
        .toFixed(2);
      this.setState({
        profitData: _profitData
      });
    });

    /* sales by product */
    let _productsSaleData = /* _this.state.productsSaleData ||  */[];
    _this.props.getProducts({}).then(response1 => {
      response1.data &&
        response1.data.map(function(el1) {
          let data = Object.assign({}, args);
          data.productId = el1.productId;
          _this.props.getTotalSaleByProduct(data).then(response2 => {
            let _salesData = {};
            _salesData.data =
              response2.data &&
              response2.data.map(function(el3) {
                return { Date: el3.orderDate, "Total Sale": el3.totalProducts };
              });
            _salesData.total =
              response2.data &&
              response2.data.reduce(function(tot, el4) {
                return tot + el4.totalProducts;
              }, 0);
            _salesData.name = el1.name;
            _salesData.productId = el1.productId;
            _productsSaleData[el1.productId] = _salesData;
            _this.setState({
              productsSaleData: _productsSaleData
            });
          });
        });
    });
    setTimeout(function() {
      _this.setState({ statsLoading: false });
    }, 1000);

    /* notifications count */
    this.props.getDashboardNotificationsData(args).then(response => {
      let _notificationsData = {
        emails: 0,
        pushNotifications: 0,
        sms: 0,
        notifications: 0
      };
      response.data.map(function(el){
        _notificationsData.emails += el.userNotificationsEmailCount;
        _notificationsData.pushNotifications += el.userNotificationsPushCount;
        _notificationsData.sms += el.userNotificationsSmsCount;
        _notificationsData.notifications += el.userNotificationsCount;
      });
      this.setState({
        notificationsData: _notificationsData
      });
    });
  };

  dateChanged = dates => {
    if(dates.length > 0){
      this.setState(
        {
          statsLoading: true,
          dataPickerDates: dates
        },
        () => this.loadProductSalesData()
      );
    } else {
      this.setState(
        {
          statsLoading: true,
          dataPickerDates: [start, end]
        },
        () => this.loadProductSalesData()
      );
    }
  };

  showDrawer = () => {
    this.setState({
      visible: true
    });
  };

  onClose = () => {
    this.setState({
      visible: false
    });
  };
  render() {
    let _this = this;
    return (
      <div className="super-admin-home">
        <div>
          <Row gutter={[24, 24]} style={{ margin: "0" }}>
            <Col xs={24} sm={24} md={18} lg={18} xl={18}>
              <Typography.Title level={4}>
                <IntlMessages id="admin.sidebar.Dashboard" />
              </Typography.Title>
            </Col>
            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
              {Permission.canAccess("dashboard_charts") ? (
                <RangePicker
                  dropdownClassName="adminRange"
                  format={dateFormat}
                  disabled={!this.state.isDPEnabled}
                  value={this.state.dataPickerDates}
                  onChange={this.dateChanged}
                  ranges={{
                    Today: [moment(), moment()],
                    Yesterday: [
                      moment().add("-1", "days"),
                      moment().add("-1", "days")
                    ],
                    "This Week": [
                      moment().startOf("isoWeek"),
                      moment().endOf("isoWeek")
                    ],
                    "Last Week": [
                      moment()
                        .add("-1", "week")
                        .startOf("isoWeek"),
                      moment()
                        .add("-1", "week")
                        .endOf("isoWeek")
                    ],
                    "This Month": [
                      moment().startOf("month"),
                      moment().endOf("month")
                    ],
                    "Last Month": [
                      moment()
                        .add("-1", "month")
                        .startOf("month"),
                      moment()
                        .add("-1", "month")
                        .endOf("month")
                    ]
                  }}
                />
              ) : (
                  ""
                )}
            </Col>
          </Row>
        </div>
        {Permission.canAccess("dashboard_charts") ? (
          <div>
            <Spin spinning={this.state.statsLoading}>
              <Row gutter={[24, 24]} style={{ margin: "0" }}>
                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                  <TotalSales
                    {..._this.props}
                    salesData={_this.state.salesData}
                    dates={this.state.dataPickerDates}
                  />
                </Col>
                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                  <TotalIncome
                    {..._this.props}
                    incomeData={_this.state.incomeData}
                    dates={this.state.dataPickerDates}
                  />
                </Col>
                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                  <TotalViews
                    {..._this.props}
                    profitData={_this.state.profitData}
                    dates={this.state.dataPickerDates}
                  />
                </Col>
              </Row>
              <Row gutter={[24, 24]} style={{ margin: "0" }}>
                {this.state.productsSaleData.map(function(el) {
                  return (
                    <Col xs={24} sm={24} md={8} lg={8} xl={8} key={el.name+ "-col"}>
                      <ProductGraph
                        {..._this.props}
                        productData={el.data}
                        name={el.name}
                        dates={_this.state.dataPickerDates}
                        key={el.name}
                      />
                    </Col>
                  );
                })}
              </Row>
            </Spin>
          </div>
        ) : (
            ""
          )}
        <Row type="flex" gutter={[24, 24]} style={{ margin: "0" }}>
          <Col xs={24} sm={24} md={16} lg={16} xl={16} className="mapTraveller">
            <GoogleMapComponent />
          </Col>
          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            <DataAnalysis notificationsData={this.state.notificationsData}/>
          </Col>
        </Row>
        <Row gutter={[24, 24]} style={{ margin: "0" }} className="listCompany">
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <ListCompaines />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <ListTravelers />
          </Col>
        </Row>

        {/* <Button type="primary" onClick={this.showDrawer}>
          Open
        </Button>
        <Drawer
          title="Basic Drawer"
          placement="bottom"
          closable={false}
          onClose={this.onClose}
          visible={this.state.visible}
        >
          <p>Some contents...</p>
          <p>Some contents...</p>
          <p>Some contents...</p>
        </Drawer> */}
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...ProductActions, ...AdminActions, ...paginationActions }, dispatch);
}
export default withRouter(
  connect(null, mapDispatchToProps, null, { forwardRef: true })(AdminHome)
);
