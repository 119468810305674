import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Row, Col, Typography, Tabs } from "antd";

const { TabPane } = Tabs;
let riskLevel = { Low: 1, Moderate: 2, Medium: 3, High: 4, Extreme: 5 };

class Report extends Component {
  _bindSummary() {
    return (
      <TabPane
        tab={
          <span>
            <i
              style={{}}
              className={
                "risklevel risk_level_" +
                this.props.countryReport.summary.risk_level.toLowerCase()
              }
            >
              {riskLevel[this.props.countryReport.summary.risk_level]}
            </i>
            Summary
          </span>
        }
        key={"summary_" + this.props.countryReport.summary.id}
      >
        <Typography.Title level={3}>Summary</Typography.Title>
        <div
          dangerouslySetInnerHTML={{
            __html: this.props.countryReport.summary.summary
          }}
        ></div>
      </TabPane>
    );
  }

  _bindChapter() {
    return this.props.countryReport.chapters.map((chapter, index) => (
      <TabPane
        tab={
          <span>
            <i
              style={{}}
              className={
                "risklevel risk_level_" + chapter.risk_level.toLowerCase()
              }
            >
              {riskLevel[chapter.risk_level]}
            </i>
            {chapter.title}
          </span>
        }
        key={chapter.chapter_id}
      >
        <div>
          <h5
            style={{
              border: "none",
              fontWeight: "400",
              color: "#8FBFE7",
              marginBottom: "0"
            }}
          >
            {chapter.title}
          </h5>
        </div>
        {this._bindSection(chapter.sections)}
      </TabPane>
    ));
  }

  _bindSection(sections) {
    return sections.map((section, i) => (
      <div key={i}>
        <h3 style={{ border: "none" }}>{section.title}</h3>
        <div dangerouslySetInnerHTML={{ __html: section.text }}></div>
      </div>
    ));
  }

  render() {
    return (
      <Row gutter={24} style={{ marginTop: "50px" }}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} className="tabs-country">
          <Tabs className="long-tab" tabPosition="left">
            {this._bindSummary()}
            {this._bindChapter()}
          </Tabs>
        </Col>
      </Row>
    );
  }
}

function mapStateToProps(state) {
  return {};
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    Report
  )
);
