import React, { Component } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel
} from "react-accessible-accordion";
export default class CapAdvantageAssistanceMobile extends Component {
  render() {
    return (
      <tr id="row-cap-advantage-assistance-mobile">
        <td colSpan="3">
          <AccordionItem uuid="cap-advantage-assistance-mobile">
            <table>
              <tbody>
                <tr>
                  <td colSpan="3" className="title">
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        <b>CAP™ Advantage – Personal Device Tracking & Assistance App</b>
                      </AccordionItemButton>
                    </AccordionItemHeading>
                  </td>
                </tr>
              </tbody>
            </table>

            <AccordionItemPanel>
              <Accordion allowZeroExpanded={true}>
                <table>
                  <tbody>
                    <tr className="border">
                      <td>
                        <AccordionItem>
                          <AccordionItemHeading>
                            <AccordionItemButton>
                              GPS Travel Tracking
                            </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                            <p>
                              The CAP™ mobile app leverages GPS technology from
                              smartphone devices to determine the proximity of
                              threats in relation to the traveler’s current
                              location, and for other travel assistance
                              purposes.
                            </p>
                          </AccordionItemPanel>
                        </AccordionItem>
                      </td>
                      <td data-label="Core CAP™"><i className="fas fa-check"></i></td>
                      <td data-label="CAP+MED™"><i className="fas fa-check"></i></td>
                      {/* <td data-label="CAP™ Advantage">
                        <i className="fas fa-check"></i>
                      </td> */}
                    </tr>
                  </tbody>
                </table>
                <table>
                  <tbody>
                    <tr className="border">
                      <td>
                        <AccordionItem>
                          <AccordionItemHeading>
                            <AccordionItemButton>
                              Location-Based Mapping Interface
                            </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                            <p>
                            Travelers have access to intuitive dashboards and maps that provide visual representations of
locations experiencing various levels of health, weather, or security challenges, and where
travelers require extra precaution.
                            </p>
                          </AccordionItemPanel>
                        </AccordionItem>
                      </td>
                      <td data-label="Core CAP™"><i className="fas fa-check"></i></td>
                      <td data-label="CAP+MED™"><i className="fas fa-check"></i></td>
                      {/* <td data-label="CAP™ Advantage">
                        <i className="fas fa-check"></i>
                      </td> */}
                    </tr>
                  </tbody>
                </table>
                <table>
                  <tbody>
                    <tr className="border">
                      <td>
                        <AccordionItem>
                          <AccordionItemHeading>
                            <AccordionItemButton>
                              One-Touch Emergency Assistance Button
                            </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                            <p>
                            With the push of the ASSIST button in the CAP™ mobile app, travelers are instantly connected
to the CAP™ Crisis Response Center to request emergency assistance during a travel mishap.
                            </p>
                          </AccordionItemPanel>
                        </AccordionItem>
                      </td>
                      <td data-label="Core CAP™"><i className="fas fa-check"></i></td>
                      <td data-label="CAP+MED™"><i className="fas fa-check"></i></td>
                      {/* <td data-label="CAP™ Advantage">
                        <i className="fas fa-check"></i>
                      </td> */}
                    </tr>
                  </tbody>
                </table>
                <table>
                  <tbody>
                    <tr className="border">
                      <td>
                        <AccordionItem>
                          <AccordionItemHeading>
                            <AccordionItemButton>
                              Two-Way Communications
                            </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                            <p>
                            Our communication tools are synced with phones, email, and SMS, that allow travelers to
receive critical communications from our CAP™ Crisis Response Center, including geofence
alert settings that can be configured using the geo-specific proximity of travelers, concerning
ongoing threats in the area.
                            </p>
                          </AccordionItemPanel>
                        </AccordionItem>
                      </td>
                      <td data-label="Core CAP™"><i className="fas fa-check"></i></td>
                      <td data-label="CAP+MED™"><i className="fas fa-check"></i></td>
                      {/* <td data-label="CAP™ Advantage">
                        <i className="fas fa-check"></i>
                      </td> */}
                    </tr>
                  </tbody>
                </table>
                <table>
                  <tbody>
                    <tr className="border">
                      <td>
                        <AccordionItem>
                          <AccordionItemHeading>
                            <AccordionItemButton>
                              Safety Check-In Button
                            </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                            <p>
                            With the push of the CHECK-IN SAFETY button in the CAP™ mobile app, travelers can confirm
their location, health, and welfare status with the Crisis Response Center.
                            </p>
                          </AccordionItemPanel>
                        </AccordionItem>
                      </td>
                      <td data-label="Core CAP™"><i className="fas fa-check"></i></td>
                      <td data-label="CAP+MED™"><i className="fas fa-check"></i></td>
                      {/* <td data-label="CAP™ Advantage">
                        <i className="fas fa-check"></i>
                      </td> */}
                    </tr>
                  </tbody>
                </table>
                <table>
                  <tbody>
                    <tr className="border">
                      <td>
                        <AccordionItem>
                          <AccordionItemHeading>
                            <AccordionItemButton>
                              User-Defined Tracking
                            </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                            <p>
                            Travelers have the freedom to control GPS-based location sharing features for ultimate control
over privacy before, during, and after travel.
                            </p>
                          </AccordionItemPanel>
                        </AccordionItem>
                      </td>
                      <td data-label="Core CAP™"><i className="fas fa-check"></i></td>
                      <td data-label="CAP+MED™"><i className="fas fa-check"></i></td>
                      {/* <td data-label="CAP™ Advantage">
                        <i className="fas fa-check"></i>
                      </td> */}
                    </tr>
                  </tbody>
                </table>
                <table>
                  <tbody>
                    <tr className="border">
                      <td>
                        <AccordionItem>
                          <AccordionItemHeading>
                            <AccordionItemButton>
                              Itinerary Management
                            </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                            <p>
                            Travelers can upload and manage flight information and other essential details from their
itinerary, in the CAP™ Travel Assistance Portal and app.
                            </p>
                          </AccordionItemPanel>
                        </AccordionItem>
                      </td>
                      <td data-label="Core CAP™"><i className="fas fa-check"></i></td>
                      <td data-label="CAP+MED™"><i className="fas fa-check"></i></td>
                      {/* <td data-label="CAP™ Advantage">
                        <i className="fas fa-check"></i>
                      </td> */}
                    </tr>
                  </tbody>
                </table>
                <table>
                  <tbody>
                    <tr className="border">
                      <td>
                        <AccordionItem>
                          <AccordionItemHeading>
                            <AccordionItemButton>
                              Travel Risk Intelligence
                            </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                            <p>
                            Through online CAP™ Travel Assistance Portal, travelers can access country and city risk reports
to get specific insights on point-in-time health, weather, or security threat profiles.
                            </p>
                          </AccordionItemPanel>
                        </AccordionItem>
                      </td>
                      <td data-label="Core CAP™"><i className="fas fa-check"></i></td>
                      <td data-label="CAP+MED™"><i className="fas fa-check"></i></td>
                      {/* <td data-label="CAP™ Advantage">
                        <i className="fas fa-check"></i>
                      </td> */}
                    </tr>
                  </tbody>
                </table>
              </Accordion>
            </AccordionItemPanel>
          </AccordionItem>
        </td>
      </tr>
    );
  }
}
