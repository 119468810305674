import React, { Component } from "react";
import Moment from "react-moment";
import Text from "react-text";
import { Row, Col, Checkbox, Radio, Modal } from "antd";
import moment from "moment";
const { confirm } = Modal;

export default class TravellerInfo extends Component {
                 constructor(props) {
                   super(props);
                   this.state = {
                     annualChecked: props.planTerm.isAnnual,
                     updateView: true,
                     decodedParams: props.decodedParams
                   };
                   this.onChange = this.onChange.bind(this);
                   this.showConfirm = this.showConfirm.bind(this);
                   this.upgradePlan = this.upgradePlan.bind(this);
                   this.getGender = this.getGender.bind(this);
                 }

                 _getProductName(productId) {
                   let planObject = this.props.productInfo.find((x) => x.productId === parseInt(productId));
                   return <li>{planObject ? planObject.name : ''}</li>;
                   if (this.props.planType && this.props.planType.name) {
                     return <li>{this.props.planType.name}</li>;
                   }
                   if (this.props.planTerm.isAnnual) {
                     let productCount = this.props.productInfo.length;
                     return <li>{this.props.productInfo[productCount - 1].name}</li>;
                   } else {
                     let planObject = this.props.productInfo.find((x) => x.productId === parseInt(productId));
                     return <li>{planObject ? planObject.name : ''}</li>;
                   }
                 }

                 _bindCountries(countryIds, bindType) {
                  //  if (this.props.planTerm.isAnnual && bindType === 'travel') {
                  //    return <li>All Countries</li>;
                  //  } else {
                     var countries = countryIds.toString().split(',');
                     let countryName = '';
                     for (var i = 0; i < countries.length; i++) {
                       var countryObject = this.props.countryTierInfo.find(
                         (x) => x.countryId === parseInt(countries[i])
                       );
                       countryName =
                         countryName === ''
                           ? countryObject && countryObject.countryName
                           : countryName + ', ' + countryObject.countryName;
                     }
                     return <li>{countryName}</li>;
                  // }
                 }
                 
                 getGender(gender) {
                   if (gender == 1) {
                     return 'Male';
                   } else if (gender == 2) {
                     return 'Female';
                   } else if (gender == 3) {
                     return 'Other';
                   } else if (gender == 4) {
                     return 'Prefer Not To Answer';
                   } else {
                     return 'Not Filled';
                   }
                 }

                 bindProductRadio(idx, traveller) {
                   return (
                     <Radio.Group
                       style={{ width: '100%' }}
                       disabled={this.state.annualChecked}
                       defaultValue={idx + ',' + traveller.product_id}
                       onChange={this.props.handlePlanChange}
                     >
                       <Row gutter={24}>
                         {this.props.productInfo.map((product, index) => {
                           let isCapAdvantage = this.props.productInfo.length - 1 === index ? true : false;
                           return this._bindRadioOptions(traveller, product, idx, index, isCapAdvantage);
                         })}
                       </Row>
                     </Radio.Group>
                   );
                 }

                 _bindRadioOptions(traveller, product, indexTraveller, idxProduct, isCapAdvantage) {
                   var priceText = false;
                   var planIncluded = '';
                   if (!this.props.planTerm.isAnnual) {
                     var selectedProductId = traveller.product_id
                       ? parseInt(traveller.product_id)
                       : this.props.planType.productId;
                     if (product.productId > selectedProductId) {
                       priceText = true;
                       var currentProductPrice = this.props.productPriceList.find(
                         (x) =>
                           x.tierId === parseInt(this.props.planTerm.tierValue) &&
                           x.dayPackage === parseInt(this.props.planTerm.days) &&
                           x.productId === parseInt(selectedProductId)
                       );
                       var upGradedProductPrice = this.props.productPriceList.find(
                         (x) =>
                           x.tierId === parseInt(this.props.planTerm.tierValue) &&
                           x.dayPackage === parseInt(this.props.planTerm.days) &&
                           x.productId === parseInt(product.productId)
                       );
                       var priceDiffernce = upGradedProductPrice.retailPrice - currentProductPrice.retailPrice;
                     }
                     if (product.productId === selectedProductId) {
                       planIncluded = 'Selected plan';
                     } else if (product.productId < selectedProductId) {
                       planIncluded = 'Included';
                     }
                   } else {
                     planIncluded = 'Included';
                   }
                   return (
                     <Col
                       xs={24}
                       sm={8}
                       md={8}
                       lg={8}
                       xl={8}
                       style={{ textAlign: 'left' }}
                       className='antd-check'
                       key={idxProduct}
                     >
                       {isCapAdvantage !== false ? (
                         <Radio disabled={true} value={indexTraveller + ',' + product.productId}>
                           <Text>
                             {product.name} {<br></br>}
                             <span
                               style={{
                                 color: '#0986d6',
                                 fontWeight: 'bold',
                                 fontStyle: 'italic'
                               }}
                             >
                               Included
                             </span>
                           </Text>
                         </Radio>
                       ) : (
                         <Radio value={indexTraveller + ',' + product.productId}>
                           {!priceText ? (
                             <Text>
                               {product.name}
                               {<br></br>}
                               <span
                                 style={{
                                   color: '#0683bb',
                                   fontWeight: 'bold',
                                   fontStyle: 'italic'
                                 }}
                               >
                                 {planIncluded !== '' ? planIncluded : ''}
                               </span>
                             </Text>
                           ) : (
                             <Text>
                               {' '}
                               {product.name} {<br></br>}
                               <span
                                 style={{
                                   color: '#0986d6',
                                   fontWeight: 'bold',
                                   fontStyle: 'italic'
                                 }}
                               >
                                 Upgrade for only ${priceDiffernce.toFixed(2)}
                               </span>
                             </Text>
                           )}
                         </Radio>
                       )}
                     </Col>
                   );
                 }

                 showConfirm(objContent, isAnnual) {
                   var self = this;
                   confirm({
                     title: objContent.title,
                     content: objContent.content,
                     className: 'gray-color',
                     centerd: true,
                     onOk() {
                       self.setState({ annualChecked: true });
                       self.upgradePlan(isAnnual);
                     },
                     onCancel() {
                       self.setState({ annualChecked: false });
                       self.upgradePlan(false);
                     }
                   });
                 }

                 onChange(e) {
                   var isAnnual = e.target.checked;
                   if (this.props.personalDetails && this.props.personalDetails.length > 0) {
                     var objContent = {};
                     if (e.target.checked) {
                       objContent.title = 'Do you want the Annual Unlimited Trip CAP+MED™ Plan for all travelers?';
                       objContent.content =
                         'Please click OK to confirm your upgrade to the Annual Unlimited Trip CAP+MED™ Plan. ';
                       this.showConfirm(objContent, isAnnual);
                     } else {
                       this.setState({ annualChecked: false });
                       this.upgradePlan(isAnnual);
                     }
                   } else {
                     this.upgradePlan(isAnnual);
                   }
                 }

                 async upgradePlan(isAnnual) {
                   var totalPrice = 0;
                   var objPlanTerm = this.props.planTerm;
                   objPlanTerm.isAnnual = isAnnual;
                   if (isAnnual) {
                     totalPrice = this.props.planMaxPrice * this.props.personalDetails.length;
                   } else {
                     for (var i = 0; i < this.props.personalDetails.length; i++) {
                       if (this.props.productPriceList && this.props.productPriceList.length > 0) {
                         var dayPriceObject = this.props.productPriceList.find(
                           (x) =>
                             x.tierId === parseInt(this.props.planTerm.tierValue) &&
                             x.dayPackage === parseInt(this.props.planTerm.days) &&
                             x.productId === parseInt(this.props.personalDetails[i].product_id)
                         );
                         totalPrice = totalPrice + dayPriceObject.retailPrice;
                       }
                     }
                   }
                   objPlanTerm.totalPrice = totalPrice;
                   //objPlanTerm.Price = totalPrice;
                   await this.props.SavePlanTerm(objPlanTerm);
                   this.props.updatePrice();
                 }

                 _bindTravellerInfo(travellers) {
                   var decodedParams = this.state.decodedParams;
                   return travellers.map((traveller, idx) => {
                     let isAnnual =
                       (typeof traveller.is_annual !== undefined && traveller.is_annual === true) ||
                       this.props.planTerm.days === 365
                         ? true
                         : false;
                     return (
                       <div key={idx}>
                         <Row gutter={24} className='marg20'>
                           <Col xs={24} sm={24} md={24} lg={10} xl={10}>
                             <h3>{traveller.first_name + ' ' + traveller.last_name}</h3>
                           </Col>
                           {/* <Col
            xs={24}
            sm={24}
            md={24}
            lg={14}
            xl={14}
            style={{ textAlign: "right", paddingRight: "25px" }}
          >
            {this.props.productInfo && this.props.productInfo.length > 0
              ? this.bindProductRadio(idx, traveller)
              : ""}
          </Col> */}
                         </Row>
                         <hr />
                         {/* Plan summary */}
                         {/* <Row gutter={24}>
          <Col span={24}>
            <ul>
              <li>Plan Type</li>
              {this._getProductName(traveller.product_id)}
            </ul>
          </Col>
        </Row> */}
                         <Row gutter={24}>
                           <Col span={24}>
                             <ul>
                               <li>Travel Tier</li>
                               <li>
                                 {/* {this.props.planTerm.isAnnual ? 'Tier 1' : 'Tier ' + this.props.planTerm.tierValue} */}
                                 {'Tier ' + this.props.planTerm.tierValue}
                               </li>
                             </ul>
                           </Col>
                         </Row>
                         <Row gutter={24}>
                           <Col span={24}>
                             {this.props.planTerm && this.props.planTerm.country && this.props.countryTierInfo ? (
                               <ul>
                                 <li>
                                   {this.props.planTerm.country.toString().split(',').length > 1
                                     ? 'Travel countries'
                                     : 'Travel Country'}
                                 </li>
                                 {this._bindCountries(this.props.planTerm.country, 'travel')}
                               </ul>
                             ) : (
                               ''
                             )}
                           </Col>
                         </Row>
                         <Row gutter={24}>
                           <Col span={24}>
                             <ul>
                               <li>Travel Period</li>
                              <li>
                          {moment(this.props.planTerm.endDate).endOf('day').diff(
                              moment(this.props.planTerm.date),
                              'days'
                          ) + 1}{' '}
                          Days
                      </li>
                             </ul>
                           </Col>
                         </Row>
                         {/* Plan summary */}
                         <Row gutter={24}>
                           <Col span={24}>
                             <ul>
                               <li>Name</li>
                               <li>
                                 {' '}
                                 {traveller.first_name
                                   ? traveller.first_name + ' ' + traveller.last_name
                                   : 'Not Filled'}
                               </li>
                             </ul>
                           </Col>
                         </Row>
                         <Row gutter={24}>
                           <Col span={24}>
                             <ul>
                               <li>Gender</li>
                               <li>{traveller.isEmailExit ? 'Existing Gender' : this.getGender(traveller.gender)}</li>
                             </ul>
                           </Col>
                         </Row>
                         <Row gutter={24}>
                           <Col span={24}>
                             <ul>
                               <li>Date of Birth</li>
                               <li>
                                 {traveller.isEmailExit ? (
                                   'Existing DOB'
                                 ) : !traveller.isEmailExit && traveller.dob == 'Invalid date' ? (
                                   'Not Filled'
                                 ) : (
                                   <Moment format={'MM/DD/YYYY'}>{traveller.dob}</Moment>
                                 )}
                               </li>
                             </ul>
                           </Col>
                         </Row>
                         <Row gutter={24}>
                           <Col span={24}>
                             <ul>
                               <li>Email</li>
                               <li>{traveller.email ? traveller.email : 'Not Filled'}</li>
                             </ul>
                           </Col>
                         </Row>
                         <Row gutter={24}>
                           <Col span={24}>
                             <ul>
                               <li>Country</li>
                               <li>
                                 {traveller.isEmailExit
                                   ? 'Existing Country'
                                   : traveller.country
                                   ? this._bindCountries(traveller.country, 'address')
                                   : 'Not Filled'}
                               </li>
                             </ul>
                           </Col>
                         </Row>
                         <Row gutter={24}>
                           <Col span={24}>
                             <ul>
                               <li>Travel Start Date</li>
                               <li>
                                 <Moment format='MM/DD/YYYY'>{this.props.planTerm.date}</Moment>
                               </li>
                             </ul>
                           </Col>
                         </Row>
                         <Row gutter={24}>
                           <Col span={24}>
                             <ul>
                               <li>Travel End Date</li>
                               <li>
                                 <Moment format='MM/DD/YYYY'>{this.props.planTerm.endDate}</Moment>
                               </li>
                             </ul>
                           </Col>
                         </Row>
                         {/* <Row gutter={24}>
                  <Col span={24}>
                    <Checkbox id={idx} value="Core CAP<sup>TM</sup>" checked={traveller.is_annual ? true:false} onChange={this.props.handleAnnualChnage}>
                      <b>Upgrade to an unlimited annual trip plan for only ${this.props.planMaxPrice.toFixed(2)}</b>
                    </Checkbox>
                  </Col>
                </Row> */}
                       </div>
                     );
                   });
                 }
                 render() {
                   return (
                     <div className='plansummary-check'>
                       <div className='row'>
                         <div className='half-width'>
                           <h3>Plan Summary</h3>
                         </div>
                         {/* <div className="half-width" style={{ textAlign: "right" }}>
            {" "}
            <Checkbox
              checked={this.state.annualChecked}
              onChange={this.onChange}
            >
              <b>
              Upgrade to the Annual Unlimited Trip CAP+MED™ Plan for only $
                {this.props.priceToShowAsDiffernce
                  ? this.props.priceToShowAsDiffernce.toFixed(2)
                  : "0.00"}
              </b>
            </Checkbox>
          </div> */}
                       </div>
                       <hr></hr>
                       {this._bindTravellerInfo(this.props.personalDetails)}
                     </div>
                   );
                 }
               }
