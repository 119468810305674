import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import UserProfile from "../components/agent-dash/user-profile/user-profile";
import ManageOrders from "../components/agent-dash/order-history/order-history";
import ViewOrder from "../components/agent-dash/order-history/view";
import UserHome from "../components/agent-dash/user-home/user-home";
import UserChangePassword from "../components/agent-dash/user-profile/change-password";
import AuditLogs from "../components/agent-dash/audit-logs";
import NotFound from "../components/shared/components/404.jsx";
import Notifications from "../components/Notifications/notifications";
import ViewNotification from "../components/Notifications/view";
import Countries from "../components/admin-dash/countries";
import CountriesInfo from "../components/admin-dash/countries/infos";


const routes = [
  {
    path: "",
    component: ManageOrders
  },
  {
    path: "my-profile",
    component: UserProfile
  },
  {
    path: "my-profile/change-password",
    component: UserChangePassword
  },
  {
    path: "manage-orders",
    component: ManageOrders
  },
  {
    path: "view/:orderId",
    component: ViewOrder
  },
  {
    path: "manage-orders/view/:orderId",
    component: ViewOrder
  },
  {
    path: "audit-logs",
    component: AuditLogs
  },
  {
    path: "countries",
    component: Countries
  },
  {
    path: "countries/:id",
    component: CountriesInfo
  },
  {
    path: "notifications",
    component: Notifications
  },
  {
    path: "notifications/:id",
    component: ViewNotification
  },
  {
    path: "",
    component: NotFound,
    exact: false
  }
];

class AgentRouter extends Component {
  componentDidMount(){
  }
  render() {
    const { url, style } = this.props;
    return (
      <div style={style}>
        <Switch>
          {routes.map(singleRoute => {
            const { path, exact, ...otherProps } = singleRoute;
            return (
              <Route
                exact={exact === false ? false : true}
                key={singleRoute.path}
                path={`${url}/${singleRoute.path}`}
                {...otherProps}
              />
            );
          })}
        </Switch>
      </div>
    );
  }
}

export default AgentRouter;
