import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Wizard from "../shared/wizard/wizard.jsx";
import * as b2b2cActions from "../../../redux/actions/b2b2c-actions";
import * as userActions from "../../../redux/actions/user-actions";
import Faq from "./faq/faq.jsx";
import bestvalue from "../../../images/best-value.png";
import { Modal } from "antd";
import postscribe from "postscribe";
import getSymbolFromCurrency from "currency-symbol-map";
import { currencyType } from "../../../components/constants";
import axios from "axios";
import {cadConvertionRate} from "../../b2c/shared/cadConverstion";


const { confirm } = Modal;
class PlanType extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      currency: "USD", 
      currencyRate: 1 };
    this.handleClick = this.handleClick.bind(this);
    this.showConfirm = this.showConfirm.bind(this);
  }

  async handleClick(evt) {
    if (evt.currentTarget.dataset && evt.currentTarget.dataset.value) {
      let planType = evt.currentTarget.dataset.value;
      let planObject = this.props.resellerOrganizationInfo.productList.find(
        x => x.productId === parseInt(planType),
      )
      await this.props.SaveStep("1");
      await this.props.SavePlanType(planObject);
      this.props.history.push(this.props.b2b2cBaseUrl + "/plan-term/"+this.props.match.params.orgId + window.location.search);
    }
  }

  async componentDidMount() {
    this.setState({
      currency: this.state.currency,
      currencyRate: this.state.currencyRate,
    });
    postscribe("#plantype-info","<script> (function(h,o,t,j,a,r){ h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)}; h._hjSettings={hjid:2750169,hjsv:6}; a=o.getElementsByTagName('head')[0]; r=o.createElement('script');r.async=1; r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv; a.appendChild(r); })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv='); </script>");
    window.scrollTo(0, 0);
    await this.props.getUserData();
    if (this.props.isLoggedIn) {
      await this.props.getTravellerInfo(this.props.userData.userID);
      if (this.props.travellerInfo && this.props.travellerInfo.email) {
        var contactNumber = this.props.travellerInfo.contactNo;
        var countryId = this.props.travellerInfo.countryId;
        var selectedDate = new Date(this.props.travellerInfo.dateOfBirth);
        var isValidDOB = this.checkValidDateOfBirth(
          selectedDate.getDay(),
          selectedDate.getMonth(),
          selectedDate.getFullYear()
        );
        var errors = [];
        if (contactNumber === ""){
          errors.push("- Please provide your contact number.")
        }
        if (countryId === null){
          errors.push("- Please provide your home country.")
        }
        if (!isValidDOB){
          errors.push("- You are not eligible to buy a plan because you are under 18 years.\n")
        }
        if(errors.length > 0) {
          var objContent = {};
          objContent.title = "Information not updated. Please correct the errors below!";
          objContent.content = <div dangerouslySetInnerHTML={{__html: errors.join("<br/>")}} />;
          if (!this.props.alreadyVerfified) this.showConfirm(objContent);
          else this.props.checkIfDetailAlreadyVerified(false);
        }
      }
    }
    // var requestOptions = {
    //   method: 'GET',
    //   redirect: 'follow'
    // };
    
    // const response = await fetch("https://free.currconv.com/api/v7/convert?q=USD_CAD&compact=ultra&apiKey=874581d3e2f0fd37447d", requestOptions);

    // var currencyData = await response.json();
    // if (this.state.currency == "USD") {
    //   this.setState({ currencyRate: 1 });
    //   this.props.updateCurrency(value, 1);
    // } else {
    //   this.setState({ currencyRate: currencyData.USD_CAD});
    //   this.props.updateCurrency(value, currencyData.USD_CAD);
    // }

  }

  showConfirm(objContent) {
    this.props.checkIfDetailAlreadyVerified(true);
    var self = this;
    confirm({
      title: objContent.title,
      content: objContent.content,
      onOk() {
        self.props.history.push("/user/my-profile");
      },
      onCancel() {
        self.props.history.push("/user/my-profile");
      }
    });
  }

  checkValidDateOfBirth(day, month, year) {
    return new Date(year + 18, month - 1, day) <= new Date();
  }

  _bindProducts() {
    if(!this.props.productInfo || this.props.productInfo.length === 0){
      return <></>;
    }

    let highestPriceVal = Number.NEGATIVE_INFINITY;
    let lowestPriceVal = Number.POSITIVE_INFINITY;
    let tmp;
    for (var i=this.props.productPriceList.length-1; i>=0; i--) {
      tmp = this.props.productPriceList[i].retailPrice;
      if (tmp < lowestPriceVal) lowestPriceVal = tmp;
      if (tmp > highestPriceVal) highestPriceVal = tmp;
      
    }

   let priceData = {
  highestPriceVal: highestPriceVal,
  lowestPriceVal: lowestPriceVal
    }
    
    if(this.state.currency == "CAD"){
      highestPriceVal = cadConvertionRate( highestPriceVal);
      lowestPriceVal = cadConvertionRate( lowestPriceVal);
     priceData = {
       highestPriceValCAD: highestPriceVal,
       lowestPriceValCAD: lowestPriceVal
     };

    localStorage.setItem('priceData', JSON.stringify(priceData));

    }
    return this.props.resellerOrganizationInfo.planTypeList && this.props.resellerOrganizationInfo.planTypeList.map((plan, index) => {
     let product = this.props.resellerOrganizationInfo.productList[index];
      return (
        <li key={'product' + index}>
          {this.props.resellerOrganizationInfo.planTypeList.length > 1 && product.name.toLowerCase().includes('annual') &&
            <img src={bestvalue} style={{ position: 'absolute', left: '0', top: '0' }} />
          }
          <h2>{product.name}</h2>
          <React.Fragment>
            <h6>
              {product.name.toLowerCase().includes('annual')
                ? 'Only $' + highestPriceVal
                : 'Starting at $' + lowestPriceVal}
            </h6>
            <button data-value={product.productId} onClick={this.handleClick}>
              Purchase
            </button>
          </React.Fragment>
        </li>
      );
    });
    
   

  }

  _bindCurrency(data) {
    return data.map((obj, index) => {
      return (
        <>
          <option value={obj}>{obj}</option>
        </>
      );
    });
  }

  handleCurrencyChange = async(e) => {
    var value = e.target.value;
    this.setState({ currency: e.target.value });
  
    var currencyData = "";
    await this.props.convertCurrency()
    .then(resp => {
      currencyData = resp.data;
    });
    if (this.state.currency == "USD") {
      this.setState({ currencyRate: 1 });
      this.props.updateCurrency(value, 1);
    } else {
      this.setState({ currencyRate: currencyData.value });
      this.props.updateCurrency(value, currencyData.value);
    }
  };

  render() {
    return (
      <div id="plantype-info">
        <section id="banner">
          {this.props.resellerOrganizationInfo && this.props.resellerOrganizationInfo.b2B2COrgSettings ? <Wizard wizardActiveClass="step1" /> :""}
          {/* title */}
          <div className="wrap">
            <div className="title">
              <h1>Travel Fearlessly™</h1>
              <select
                value={this.state.currency}
                onChange={this.handleCurrencyChange}
              >
                {this._bindCurrency(currencyType)}
              </select>
            </div>
          </div>
          {/* title ends */}
          {/*  three boxes product */}
          <div className="three-box">
            <div className="wrap">
              <ul>
                {this.props.productInfo && this.props.productInfo.length > 0
                  ? this._bindProducts()
                  : ""}
              </ul>
            </div>
            {/* three boxes ends */}
          </div>
        </section>
        <div
          className="wrap"
          style={{
            textAlign: "left",
            padding: "0 30px",
            /* marginTop: "-10px", */
            position: "relative",
            zIndex: "100"
          }}
        ></div>
        <Faq />
      </div>
    );
  }
}

PlanType.propTypes = {
  SaveStep: PropTypes.func,
  SavePlanType: PropTypes.func,
  step: PropTypes.string,
  productInfo: PropTypes.array,
  productPricing: PropTypes.object,
  getUserData: PropTypes.func,
  userData: PropTypes.object,
  alreadyVerfified: PropTypes.bool,
  checkIfDetailAlreadyVerified: PropTypes.func,
  b2b2cBaseUrl: PropTypes.string,
  currencyInfo: PropTypes.func,

};
function mapStateToProps(state) {
  return {
    ...state.b2b2c,
    ...state.user
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...b2b2cActions, ...userActions }, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true
})(PlanType);
