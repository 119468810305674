import Axios from "../../services/axios-service";
  const getProductsData = data => {
    return Axios.get("/api/Product?search="+encodeURIComponent(JSON.stringify(data)), undefined)
    .then(res => {
        return res;
      })
      .catch(ex => {
        return ex;
      });
  };

  const getProduct = idx => {
    return Axios.get("/api/Product/"+idx, undefined, undefined);
  };

  const updateProduct = data => {
    return Axios.put("/api/Product/"+data.productId, data, undefined);
  };
  const addProduct = data => {
    return Axios.post("/api/Product", data, undefined);
  };

  const updateProductStatus = data => {
      return Axios.patch("/api/Product/"+data.id, data, undefined);
    };

  export default {
    getProductsData,
    getProduct,
    updateProduct,
    addProduct,
    updateProductStatus
  };