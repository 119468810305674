import React, { Component, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as b2cActions from '../../../redux/actions/b2c-actions';
import { useDispatch, useSelector } from 'react-redux';
import * as userActions from '../../../redux/actions/user-actions';
import { Beforeunload } from 'react-beforeunload';
import { Row, Col, Button, Modal } from 'antd';
import Config from '../../../../../config';
import moment from 'moment';
import { currencyType } from '../../constants';
import { cadConvertionRate } from '../shared/cadConverstion';

const PriceCalculation = (props) => {
  const dispatch = useDispatch();
  const data = props.b2c; //useSelector((state) => state.b2c)
  const productToOrder = Config.wpProductToOrder;

  
  const [currency, setCurrency] = useState('USD');
  // const [isAnnual, setisAnnual] = useState(props.planTerm.isAnnual ? props.planTerm.isAnnual  :false);

  const [cadPrice, setCadPrice] = useState(0);
  const [cadDiscountedPercent, setCadDiscountedPercent] = useState(0);
  const [cadDiscountedAmount, setCadDiscountedAmount] = useState(0);
  const [appliedPromoText, setAppliedPromoText] = useState('');
  const [totalPrice, setTotalPrice] = useState(0);

  let countries = data.planTerm && data.planTerm.country ? data.planTerm.country.split(',').map(Number) : '';

  let ustravel_states =
    data.planTerm && data.planTerm.ustravel_states && data.planTerm.ustravel_states != ''
      ? data.planTerm.ustravel_states.split(',').map((item) => item.trim())
      : '';
  let dp = data.planTerm && data.planTerm.dp ? data.planTerm.dp : '';

  useEffect(() => {
    setCurrency(data.currency && data.currency);

    if (countries.length > 0 && data.countryTierInfo.length > 0) {
      calculateProductPrice(countries, dp, ustravel_states);
    }
    cadPriceCalculation();
  }, [data.planTerm, data.planTerm && data.planTerm.isAnnual, data.currency, data.travelerCount, data.couponInfo]);

  useEffect(() => {
    if (countries.length > 0 && data.countryTierInfo.length > 0) {
      calculateProductPrice(countries, dp, ustravel_states);
    }
    setCurrency(data.currency && data.currency);

    cadPriceCalculation();
  }, []);

  useEffect(() => {
    cadPriceCalculation();
  }, [currency, totalPrice, data.planTerm.isAnnual]);

  const cadPriceCalculation = async () => {
    let cPrice;
    cPrice = cadConvertionRate(
      data.planTerm && data.planTerm.totalPrice && data.planTerm.totalPrice / data.travelerCount
    );
    cPrice = cPrice * data.travelerCount;
    if (data.couponInfo && data.couponInfo.discountType != 'P' && cadDiscountedAmount != 0) {
      cPrice = cPrice - cadDiscountedAmount;
    }

    if (data.couponInfo && data.couponInfo.discountType === 'P' && cadDiscountedPercent != 0) {
      let CadDisAmount = (cPrice * cadDiscountedPercent) / 100;
      cPrice = cPrice - CadDisAmount;
    }
    setCadPrice(cPrice);

    let planTermObject = data.planTerm;
    planTermObject.cadPrice = cPrice;
    await props.SavePlanTerm(planTermObject);
  };

  const calculateProductPrice = async (countryValue, selectedDay, ustravel_states) => {
    let tierValue = 4;
    let calculatedPrice = 0;
    var dayPriceObject;
    var selectedCountryTierList = [];
    if (
      ustravel_states != '' &&
      ustravel_states.length > 0 &&
      data.planTerm.country == '249'
      //data.planTerm.isAnnual == false
    ) {
      // await getPackageRateStates(ustravel_states, dp).then((result) => {
      //   calculatedPrice = result;
      // });
      var statePricing = data.stateProductPriceList;
      var allStates = data.allUsStates;
      var startDate = data.planTerm.date;
      var endDate = data.planTerm.end_date;
      var dayPriceObject;
      //get riskid for states
      var riskTypeId = [];
      ustravel_states.map((travelState, i) => {
        allStates.map((state, index) => {
          if (travelState === state.stateName) {
            if (state && state.stateRiskZones && state.stateRiskZones.length > 0) {
              state.stateRiskZones.map((stateRiskZone, index) => {
                if (
                  new Date(startDate).getTime() <= new Date(stateRiskZone.toDate).getTime() &&
                  new Date(startDate).getTime() >= new Date(stateRiskZone.fromDate).getTime() &&
                  new Date(endDate).getTime() <= new Date(stateRiskZone.toDate).getTime() &&
                  new Date(endDate).getTime() >= new Date(stateRiskZone.fromDate).getTime()
                ) {
                  riskTypeId.push(stateRiskZone.stateRiskTypeId);
                } else {
                  riskTypeId.push(1);
                }
              });
            }
          } else {
            riskTypeId.push(1);
          }
        });
      });
      //get prices for states
      var totalStatePrices = [];
      var stateDp = data.planTerm.isAnnual ? 365 : dp;
      for (var i = 0; i < riskTypeId.length; i++) {
        //var product_Id = 1;

        dayPriceObject = statePricing.find(
          (x) =>
            x.tierId === riskTypeId[i] &&
            x.dayPackage === stateDp &&
            x.productId ===  parseInt(productToOrder)//parseInt(data.productInfo[data.productInfo.length - 3].productId)
        );
        totalStatePrices.push(dayPriceObject.retailPrice);
      }
      calculatedPrice = Math.max(...totalStatePrices);
      setTotalPrice(calculatedPrice * data.travelerCount);
    }

    if (
      selectedDay !== 0 &&
      selectedDay !== 'NaN' &&
      countryValue.length > 0 &&
      ustravel_states.length == 0 //&&
      // data.planTerm.isAnnual == false
    ) {
      if (countryValue.length > 0) {
        for (var i = 0; i < countryValue.length; i++) {
          var countryObject = data.countryTierInfo && data.countryTierInfo.find((x) => x.countryId === countryValue[i]);

          if (i === 0) {
            tierValue = parseInt(countryObject.tierId);
          } else {
            if (parseInt(countryObject.tierId) < tierValue) {
              tierValue = parseInt(countryObject.tierId);
            }
          }
          selectedCountryTierList.push(tierValue);
        }
      } else {
        tierValue = 4;
      }

      let totalCountriesPrice = [];
      let dpackages = data.planTerm && data.planTerm.isAnnual ? parseInt(365) : parseInt(selectedDay);
      for (var i = 0; i < selectedCountryTierList.length; i++) {
        dayPriceObject =
          data.productPriceList &&
          data.productPriceList.length > 0 &&
          data.productPriceList.find(
            (x) =>
              x.tierId === selectedCountryTierList[i] &&
              x.dayPackage === dpackages &&
              x.productId === parseInt(productToOrder)
          );
        totalCountriesPrice.push(dayPriceObject.retailPrice);
        //  setTotalPrice(Math.max(t...totalCountriesPrice) * data.traveller_count);
      }
      calculatedPrice = Math.max(...totalCountriesPrice);
      setTotalPrice(calculatedPrice * data.travelerCount);
    }
    // if (data.planTerm && data.planTerm.isAnnual == true && data.planTerm.country != "249") {
    //   if (data.productPriceList && data.productPriceList.length > 0) {
    //     var dayPriceObject = data.productPriceList.find(
    //       (x) =>
    //         x.tierId === 1 &&
    //         x.dayPackage === 365 &&
    //         x.productId ===
    //           parseInt(
    //             data.productInfo[data.productInfo.length - 3]
    //               .productId /* cap-med price */
    //           )
    //     );
    //   }
    //   calculatedPrice = dayPriceObject.retailPrice;
    //   setTotalPrice(calculatedPrice * data.travelerCount);
    // }

    if (data.couponInfo !== null) {
      //  && data.couponInfo.promoOfferId != undefined || data.couponInfo.promoOfferId != "") {
      bindPlanPrice(calculatedPrice);
    }

    let planTermObject = data.planTerm;
    planTermObject.totalPrice = calculatedPrice * data.travelerCount;
    planTermObject.tierValue = dayPriceObject.tierId;
    await props.SavePlanTerm(planTermObject);
  };

  const getPackageRateStates = async (desti_states, dp) => {
    var statePricing = data.stateProductPriceList;
    var allStates = data.allUsStates;
    var startDate = data.planTerm.date;
    var endDate = data.planTerm.end_date;
    let calculatedPrice = 0;
    var dayPriceObject;
    //get riskid for states

    var riskTypeId = [];
    desti_states.map((travelState, i) => {
      allStates.map((state, index) => {
        if (travelState === state.stateName) {
          if (state && state.stateRiskZones && state.stateRiskZones.length > 0) {
            state.stateRiskZones.map((stateRiskZone, index) => {
              if (
                new Date(startDate).getTime() <= new Date(stateRiskZone.toDate).getTime() &&
                new Date(startDate).getTime() >= new Date(stateRiskZone.fromDate).getTime() &&
                new Date(endDate).getTime() <= new Date(stateRiskZone.toDate).getTime() &&
                new Date(endDate).getTime() >= new Date(stateRiskZone.fromDate).getTime()
              ) {
                riskTypeId.push(stateRiskZone.stateRiskTypeId);
              } else {
                riskTypeId.push(1);
              }
            });
          }
        }
      });
    });
    console.log(riskTypeId);
    //get prices for states

    var totalStatePrices = [];
    for (var i = 0; i < riskTypeId.length; i++) {
      //var product_Id = 1;

      dayPriceObject = statePricing.find(
        (x) =>
          x.tierId === riskTypeId[i] &&
          x.dayPackage === dp &&
          x.productId === parseInt(data.productInfo[data.productInfo.length - 3].productId)
      );
      totalStatePrices.push(dayPriceObject.retailPrice);
    }
    calculatedPrice = Math.max(...totalStatePrices);
    return calculatedPrice;
  };

  const handleCurrencyChange = async (value) => {
    setCurrency(value);
    if (typeof props.onPaymentMethodChange === 'function') {
      props.onPaymentMethodChange('stripe');
    }
    if (value == 'USD') {
      dispatch(b2cActions.updateCurrency(value, 1));
    } else {
      dispatch(b2cActions.updateCurrency(value, 1.3644));
    }

    //  this.updatePrice();
  };

  const bindPlanPrice = async (calculatedPrice = '') => {
    //  dispatch(b2cActions.getAppliedPromo());
    var totalPrice =
      calculatedPrice !== ''
        ? calculatedPrice
        : data.planTerm && data.planTerm.totalPrice
        ? data.planTerm.totalPrice
        : 0;

    totalPrice = totalPrice * data.travelerCount;

    var discountedPrice = 0;
    if (data.couponInfo && data.couponInfo.promoOfferId) {
      if (data.couponInfo.discountType === 'P') {
        discountedPrice = (totalPrice * data.couponInfo.discountFigure) / 100;
        setCadDiscountedPercent(data.couponInfo.discountFigure);
      } else {
        discountedPrice = data.couponInfo.discountFigure;
        setCadDiscountedAmount(discountedPrice ? discountedPrice : 0);
      }
      var PromoText =
        'Discount of ' +
        (data.couponInfo.discountType === 'P'
          ? data.couponInfo.discountFigure + '% '
          : '$ ' + discountedPrice.toFixed(2)) +
        ' has been applied to the total plan fee';
      totalPrice = totalPrice - discountedPrice.toFixed(2);
      setAppliedPromoText(PromoText);
    } else {
      setAppliedPromoText('');
      setCadDiscountedAmount('');
      setCadDiscountedPercent('');
    }
    setTotalPrice(totalPrice);
    discountedPrice = 0;
  };

  return (
    <div>
      <select value={currency} onChange={(e) => handleCurrencyChange(e.target.value)}>
        {currencyType.map((item) => {
          return (
            <option key={item} value={item}>
              {item}
            </option>
          );
        })}
      </select>
      <h1>
        {' '}
        <span>Plan Fee:</span>{' '}
        {data.currency === 'CAD' ? '$' + cadPrice.toFixed(2) : '$' + (totalPrice && totalPrice.toFixed(2))}{' '}
      </h1>
      <span>{appliedPromoText}</span>
    </div>
  );
};

export default connect(
  (state) => {
    return {
      b2c: state.b2c
    };
  },
  { ...b2cActions }
)(PriceCalculation);
