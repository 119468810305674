import React, { createRef } from 'react';
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import IntlMessages from "../../services/intlMesseges";
import logo from "../../images/logo.png";
import md5logo from "../../images/md5logo.jpg";
import * as AdminActions from "../../redux/actions/admin-actions";
import * as b2cActions from "../../redux/actions/b2c-actions";
import moment from 'moment';
import {notifyUser} from "../../services/notification-service";
import {
  Form,
  Input,
  Select,
  Button,
  Row,
  Col,
  Modal,
  Icon,
  DatePicker,
  Tooltip
} from "antd";
import config from "../../../../config";
import StripeProvider from "./stripe-payment/StripeProvider";
import OrgAPI from "../../redux/api/b2b2c-organization-api";

var validator = require('email-validator');
const { Option } = Select;
const { confirm } = Modal;
const dateFormat = 'YYYY/MM/DD';
var validator = require("email-validator");
class childParentPurchase extends React.Component {
  constructor(props) {
    super(props);
    this.showBillingRef = createRef();
    this.state = {
      travellersObjectsArray: [
        {
          FirstName: '',
          LastName: '',
          Email: '',
          DOB: '',
          Country: '',
          Gender: '',
          ContactNo: '',
          Relationship: '',
          EmergencyFirstName: '',
          EmergencyLastName: '',
          EmergencyContactNo: ''
        }
      ],
      disablepayment: true,
      countries: [],
      continueStatus: true,
      genderList: [],
      addtravlerbutton: false,
      childCount: 0,
      totalprice: '',
      payloadformdata: '',
      nextbutton: false,
      hasAddedTraveler: false,
      isindex: undefined,
      currentDate: this.getCurrentDate(),
      parentformData: {
        values: {
          email: '',
          firstname: ''
        }
      },
      addButtonClass: false,
      disableForm: false
    };
    this.removeTraveller = this.removeTraveller.bind(this);
    this.saveDataAndNext = this.saveDataAndNext.bind(this);
    // this.showConfirm = this.showConfirm.bind(this);
  }

  async componentDidMount() {
    const { match, history } = this.props;
    const { familypurchaseId } = match.params;
    if (familypurchaseId != config.familypurchaseId) {
      window.location.href = 'https://www.captravelassistance.com/';
    } else {
    }
    this.props.getCountriesListName().then((data) => {
      this.setState({ countries: data });
    });
    this.props.getGendersList().then((response) => {
      this.setState({ genderList: response });
    });
  }

  // Function to get the current date in the format "DD-MM-YYYY"
  getCurrentDate() {
    const currentDate = new Date();
    const options = { month: 'long', day: 'numeric', year: 'numeric' };
    return currentDate.toLocaleDateString('en-US', options);
}

  // Function to update the current date
  updateCurrentDate() {
    const currentDate = this.getCurrentDate();
    this.setState({ currentDate });
  }

  handlePaymentCallback = (val) => {
    this.setState({ disablepayment: val });
  };

  handleAddTraveler = () => {
    var _travellersObjectsArray = this.state.travellersObjectsArray;
    _travellersObjectsArray.push({
      FirstName: '',
      LastName: '',
      Email: '',
      DOB: '',
      Country: '',
      Gender: '',
      Relationship: '',
      ContactNo: '',
      EmergencyFirstName: '',
      EmergencyLastName: '',
      EmergencyContactNo: ''
    });

    const childCountprice = _travellersObjectsArray.length; // Incrementing the number of travelers
    const calculatedValue = childCountprice * config.familypurchaseprice;
    this.setState({
      childCount: childCountprice,
      totalprice: calculatedValue,
      addButtonClass: true,
      disablepayment: true
    });

    // this.setState((prevState) => ({
    //   travellersObjectsArray: _travellersObjectsArray,
    //   childCount: prevState.childCount,
    // }), () => {
    //   const calculatedValue = this.state.childCount * config.familypurchaseprice;
    //   this.setState({
    //     totalprice: calculatedValue
    //   });
    // });
  };

  scrollToShowBilling = () => {
    if (this.showBillingRef.current) {
      this.showBillingRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  // Function to check for duplicate emails
  hasDuplicateEmail = (values) => {
    const emailSet = new Set();
    for (const key in values) {
        if (values.hasOwnProperty(key) && key.startsWith('Email')) {
            const email = values[key].trim();
            if (email && emailSet.has(email)) {
                return true; // Duplicate found
            }
            emailSet.add(email);
        }
    }
    return false; // No duplicates found
};

  // handleSubmit = (e) => {
  //   e.preventDefault();
  //   this.props.form.validateFieldsAndScroll((err, values) => {
  //     this.setState({ disablepayment: false }, () => {
  //       this.scrollToShowBilling();
  //     });
  //     if (!err) {
  //       if (this.hasDuplicateEmail(values)) {
  //         notifyUser('Duplicate Email! Please Enter New Email. ', 'error');
  //         return;
  //       }
  //       Object.keys(values).map(function(key, _idx) {
  //         if (values[key] == null) {
  //           values[key] = '';
  //         } else {
  //           if (typeof values[key] == 'string') {
  //             values[key] = values[key].trim();
  //           }
  //         }
  //       });
  //     } else {
  //       this.setState({
  //         disablepayment: true
  //       });
  //     }
  //     this.saveDataAndNext(values);
  //   });
  // };
  
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      this.setState({ disablepayment: false }, () => {
        this.scrollToShowBilling();
      });
      if (!err) {
        if (this.hasDuplicateEmail(values)) {
          this.setState({
            disablepayment: true,
        });
            notifyUser("Duplicate Email! Please Enter New Email.", "error");
            return; // Stop further processing
            
        }
        Object.keys(values).forEach(function (key) {
            if (values[key] == null) {
                values[key] = "";
            } else {
                if (typeof values[key] == "string") {
                    values[key] = values[key].trim();
                }
            }
        });
      } 
      else {
        this.setState({
            disablepayment: true,
        });
      }
      this.saveDataAndNext(values);
    });
  };

  async saveDataAndNext(formData) {
    let ChildUserInfo = [];
    // Add parent information at index 0
    for (var i = 0; i < this.state.travellersObjectsArray.length; i++) {
      let travelerPayload = {
        FirstName: formData['FirstName' + i],
        LastName: formData['LastName' + i],
        DOB:
          formData['DOB' + i] && formData['DOB' + i]._d
            ? moment(formData['DOB' + i]._d).format('ddd MMM DD YYYY')
            : moment(formData['DOB' + i]).format('ddd MMM DD YYYY'),
        Gender: formData['Gender' + i],
        Relationship: formData['Relationship' + i],
        Email: formData['Email' + i],
        Country: formData['Country' + i],
        ContactNo: formData['ContactNo' + i],
        EmergencyFirstName: formData['EmergencyFirstName' + i],
        EmergencyLastName: formData['EmergencyLastName' + i],
        EmergencyContactNo: formData['EmergencyContactNo' + i]
      };
      ChildUserInfo.push(travelerPayload);
    }

    let payload = {
      ChildUserInfo: ChildUserInfo
    };

    this.setState({
      payloadformdata: payload
    });
  }

  handleContactExistance(rule, value, callback) {
    //const re = /^[0-9']+$/;
    const re = /^(?=[+0-9]*$)(?!.*[<>'"/;`%~@#$^*()_=[\]{}|\\,.?: -])/;
    var test = re.test(value);
    if (value !== '' && (value.length < 6 || test === false)) {
      callback('Please enter valid Phone Number');
    } else {
      callback();
    }
  }

  async _onKeydown(evt, idx) {
    if (evt.keyCode == 32) {
      evt.preventDefault();
    }
  }

  async _onBlur(evt, index) {
    const fieldName = `Email${index}`;

    const Childemail = evt.target.value.trim();
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(Childemail)) {
      console.log('Invalid email format');
      return;
    }

    if (Childemail === this.state.parentformData.values.email) {
      evt.target.value = '';
      this.props.form.setFieldsValue({ [fieldName]: '' });
      notifyUser('Entered email is the same as parent email', 'error');
      return;
    }
    const resp = await this.props.CheckEmployeeChild(Childemail);
    if (resp.status === true) {
      //notifyUser(resp.message, "success");
    } else {
      notifyUser(resp.message, 'error');
    }
  }

  disabledFutureDate = (current) => {
    return current && current > moment().endOf('day');
  };

  removeTraveller = (evt, indexToRemove) => {
    evt.preventDefault();
    var travellerID = parseInt(evt.target.dataset.value);
    const updatedTravellers = [...this.state.travellersObjectsArray];
    updatedTravellers.splice(travellerID, 1);

    this.setState(
      (prevState) => ({
        travellersObjectsArray: updatedTravellers,
        childCount: Math.max(prevState.childCount - 1, 0),
        displaypayment: false
      }),
      () => {
        const calculatedValue = this.state.childCount * config.familypurchaseprice;
        this.setState({
          totalprice: calculatedValue,
          disablepayment: true,
        });
        if (updatedTravellers.length === 1) {
        }
      }
      
    );
  };

  async _onBlurParent(evt) {
    const email = evt.target.value.trim();
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(email)) {
      return;
    }
    const resp = await this.props.EmployeeExits(email);
    if (resp.status === true) {
      this.setState({ disableForm: false });
    } else {
      this.setState({ disableForm: true });
      notifyUser(resp.message, 'error');
    }
  }

  handleParentChange = (fieldName, value) => {
    this.setState((prevState) => ({
      parentformData: {
        ...prevState.formData,
        values: {
          ...prevState.parentformData.values,
          [fieldName]: value
        }
      }
    }));
  };
  render() {
    const { disablepayment, disableForm, addButtonClass } = this.state;
    const { getFieldDecorator } = this.props.form;
    return (
      <div>
        <header id='headerb2b2c'>
          <div className='headerb2b2c-inner'>
            <div className='wrap'>
              <section className='top-header'>
                <div className='row'>
                  <div className={'logo col-md-12 col-md-12  text-center'}>
                    <img src={md5logo} alt='CAP™' height='90' width='118' />
                    <img src={logo} alt='CAP™' height='90' width='118' />
                  </div>
                </div>
              </section>
            </div>
          </div>
        </header>
        <section id='banner' className='family-purchase'>
          <Row>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <div className='wrap900 price-sticky-head'>
                <div className='title'>
                  <h2>Purchase Now</h2>
                </div>
                <div className='checkout_summary'>
                  <strong>Coverage Start Date: </strong>
                  <span className='my_start_date'> {this.state.currentDate} </span>
                  <br />
                  <strong style={{ marginLeft: '10px' }}>Coverage End Date: </strong>
                  <span className='end_date'>November 29, 2025</span>
                </div>

                <h1>
                  <span>
                    {this.state.childCount > 0 && (
                      <div>
                        <h1 className='family-price'>
                          Price: $
                          {this.state.totalprice && this.state.totalprice != '' && this.state.totalprice.toFixed(2)} USD
                        </h1>
                      </div>
                    )}
                    {this.state.childCount === 0 && (
                      <div>
                        <h1 className='family-price'>Price: ${config.familypurchaseprice} USD</h1>
                      </div>
                    )}
                  </span>
                </h1>
              </div>
            </Col>
          </Row>
          <Form onSubmit={this.handleSubmit}>
            <div className='wrap'>
              <div className='personal-info'>
                <Row gutter={24}>
                  <Col xs={24} sm={24} md={12} lg={8} xl={8} className='toopemail'>
                    <Form.Item
                      label={
                        <span style={{ display: 'flex', alignItems: 'center' }}>
                          Member E-mail :
                          <Tooltip title='Please enter the member e-mail address associated with this purchase'>
                            <Icon type='info-circle' style={{ marginLeft: 5 }} />
                          </Tooltip>
                        </span>
                      }
                    >
                      {getFieldDecorator(`email`, {
                        rules: [
                          {
                            required: true,
                            message: 'Please enter your member Email!',
                            whitespace: false
                          },
                          {
                            type: 'email',
                            message: 'Please enter a valid email address!'
                          },
                          {
                            pattern: /^((?!.*(\.com){2,}).)*$/,
                            message: 'Invalid email format!'
                          }
                        ]
                      })(
                        <Input
                          placeholder='Enter your member Email'
                          onBlur={(e) => this._onBlurParent(e)}
                          onKeyDown={(e) => this._onKeydown(e)}
                          onChange={(e) => {
                            const trimmedValue = e.target.value.trim();
                            e.target.value = trimmedValue;

                            this.handleParentChange('email', e.target.value);
                          }}
                        />
                      )}
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                    {/* // className={disableForm ? 'disabled-form' : ''} > */}
                    <Form.Item label='Member First Name'>
                      {getFieldDecorator(`firstname`, {
                        rules: [
                          {
                            required: true,
                            message: 'Please enter your Member First Name!',
                            whitespace: false
                          },
                          {
                            pattern: new RegExp(/^[a-zA-Z]+$/),
                            message: 'Please enter a valid Member First Name!'
                          }
                        ]
                      })(
                        <Input
                          maxLength={12}
                          placeholder='Member First Name'
                          onChange={(e) => this.handleParentChange('firstname', e.target.value)}
                        />
                      )}
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                    {/* // className={disableForm ? 'disabled-form' : ''} > */}
                    <Form.Item label='Member Last Name'>
                      {getFieldDecorator(`lastname`, {
                        rules: [
                          {
                            required: true,
                            message: 'Please enter your Member Last Name!',
                            whitespace: false
                          },
                          {
                            pattern: new RegExp(/^[a-zA-Z]+$/),
                            message: 'Please enter a valid Member Last Name!'
                          }
                        ]
                      })(
                        <Input
                          maxLength={12}
                          placeholder='Member Last Name'
                          onChange={(e) => this.handleParentChange('lastname', e.target.value)}
                        />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <hr />

                {this.state.travellersObjectsArray.map((traveller, index) => (
                  <div className={index !== 0 ? (addButtonClass ? 'custome-background' : '') : ''} key={index}>
                    <div className={disableForm ? 'disabled-form' : ''}>
                      <Row gutter={24}>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                          <h3>Traveller #{index === 0 ? 1 : index + 1}</h3>
                        </Col>
                      </Row>
                      <Row gutter={24}>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                          <Button
                            type='primary'
                            style={{ float: 'right', marginBottom: '15px' }}
                            data-value={index}
                            onClick={(e) => this.removeTraveller(e, index)}
                            className={index === 0 ? 'hide' : 'show'}
                          >
                            [-] Remove
                          </Button>
                        </Col>
                      </Row>
                      <Row gutter={24}>
                        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                          <Form.Item label='First Name'>
                            {getFieldDecorator('FirstName' + index, {
                              initialValue: traveller.FirstName,

                              rules: [
                                {
                                  required: true,
                                  message: 'Please enter your first name!',
                                  whitespace: false
                                },
                                {
                                  pattern: new RegExp(/^[a-zA-Z]+$/),
                                  message: 'Please enter a valid first name!'
                                }
                              ]
                              //onChange: () => this.disableSameAs(index),
                            })(<Input maxLength={50} />)}
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                          <Form.Item label='Last Name'>
                            {getFieldDecorator('LastName' + index, {
                              initialValue: traveller.LastName,
                              rules: [
                                {
                                  required: true,
                                  message: 'Please enter your last name!',
                                  whitespace: false
                                },
                                {
                                  pattern: new RegExp(/^[a-zA-Z]+$/),
                                  message: 'Please enter a valid last name!'
                                }
                              ]
                              // onChange: () => this.disableSameAs(index),
                            })(<Input maxLength={50} />)}
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                          <Form.Item label={'Date of Birth'}>
                            {getFieldDecorator('DOB' + index, {
                              initialValue: moment(),
                              rules: [
                                {
                                  type: 'object',
                                  required: true,
                                  message: 'Please select Date of Birth!'
                                }
                              ]
                            })(<DatePicker 
                            disabledDate={this.disabledFutureDate} 
                            format={dateFormat}
                            />)}
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                          <Form.Item label='E-mail'>
                            {getFieldDecorator('Email' + index, {
                              initialValue: traveller.Email,
                              rules: [
                                {
                                  type: 'email',
                                  message: 'Please enter a valid Email!',
                                  required: true,
                                  pattern: new RegExp(/^(\S.*)[a-zA-Z-.']+$/)
                                  // whitespace: false,
                                }
                              ]
                            })(
                              <Input
                                id={'email' + index}
                                data-index={index}
                                onBlur={(e) => this._onBlur(e, index)}
                                onKeyDown={(e) => this._onKeydown(e)}
                                onChange={(e) => {
                                  const trimmedValue = e.target.value.trim();
                                  e.target.value = trimmedValue;
                                }}
                              />
                            )}
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                          <Form.Item label='Home Country'>
                            {getFieldDecorator('Country' + index, {
                              initialValue: traveller.Country,
                              rules: [
                                {
                                  required: true,
                                  message: 'Please select your Country!'
                                }
                              ]
                            })(
                              <Select
                                showSearch
                                filterOption={(input, option) =>
                                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                              >
                                {this.state.countries.map(function(item) {
                                  return (
                                    <Option key={item.countryId} value={item.countryId}>
                                      {item.countryName}
                                    </Option>
                                  );
                                })}
                              </Select>
                            )}
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                          <Form.Item label='Phone Number (Ex: +1xxxxxxxxxx)'>
                            {getFieldDecorator('ContactNo' + index, {
                              initialValue: traveller.ContactNo,
                              rules: [
                                {
                                  required: true,
                                  message: 'Please input your phone number!'
                                },
                                {
                                  validator: this.handleContactExistance
                                }
                              ]
                            })(<Input style={{ width: '100%' }} type='text' />)}
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                          <Form.Item label={traveller.isEmailExit ? 'Existing Plan Holder Gender' : 'Gender'}>
                            {getFieldDecorator('Gender' + index, {
                              initialValue: traveller.Gender,
                              rules: [
                                {
                                  required: true,
                                  message: 'Please select your Gender!'
                                }
                              ]
                            })(
                              <Select
                                showSearch
                                placeholder='Please select gender'
                                filterOption={(input, option) =>
                                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                              >
                                {this.state.genderList.map(function(gender) {
                                  return (
                                    <Option key={gender.id} value={gender.id}>
                                      {gender.name}
                                    </Option>
                                  );
                                })}
                              </Select>
                            )}
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                          <Form.Item label='Relationship'>
                            {getFieldDecorator('Relationship' + index, {
                              initialValue: traveller.Relationship,
                              rules: [
                                {
                                  required: true,
                                  message: 'Please enter your Relationship!',
                                  whitespace: false
                                },
                                {
                                  pattern: new RegExp(/^[a-zA-Z]+$/),
                                  message: 'Please enter a valid Relationship!'
                                }
                              ]
                              // onChange: () => this.disableSameAs(index),
                            })(<Input maxLength={50} />)}
                          </Form.Item>
                        </Col>
                      </Row>
                      <hr />
                      <Row gutter={24}>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                          <h3>Emergency Contact</h3>
                        </Col>
                      </Row>
                      <Row gutter={24}>
                        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                          <Form.Item label='Emergency First Name'>
                            {getFieldDecorator('EmergencyFirstName' + index, {
                              initialValue: traveller.EmergencyFirstName,

                              rules: [
                                {
                                  required: false,
                                  message: 'Please enter your Emergency First Name!',
                                  whitespace: false
                                },
                                {
                                  pattern: new RegExp(/^[a-zA-Z]+$/),
                                  message: 'Please enter a valid Emergency First Name!'
                                }
                              ]
                              //onChange: () => this.disableSameAs(index),
                            })(<Input maxLength={12} />)}
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                          <Form.Item label='Emergency Last Name'>
                            {getFieldDecorator('EmergencyLastName' + index, {
                              initialValue: traveller.EmergencyLastName,

                              rules: [
                                {
                                  required: false,
                                  message: 'Please enter your Emergency Last Name!',
                                  whitespace: false
                                },
                                {
                                  pattern: new RegExp(/^[a-zA-Z]+$/),
                                  message: 'Please enter a valid Emergency Last Name!'
                                }
                              ]
                              //onChange: () => this.disableSameAs(index),
                            })(<Input maxLength={12} />)}
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                          <Form.Item label='Primary Phone Number'>
                            {getFieldDecorator('EmergencyContactNo' + index, {
                              initialValue: traveller.EmergencyContactNo,
                              rules: [
                                {
                                  required: false,
                                  message: 'Please input your Contact number!'
                                },
                                {
                                  validator: this.handleContactExistance
                                }
                              ]
                            })(<Input style={{ width: '100%' }} type='text' />)}
                          </Form.Item>
                        </Col>
                      </Row>
                    </div>
                  </div>
                ))}
                <Row gutter={24}>
                  <Col xs={24} sm={24} md={12} lg={4} xl={4}>
                    <div className='add-traveler'>
                      <div className='wrap'>
                        <Button onClick={this.handleAddTraveler}>[+] Add Traveller</Button>
                      </div>
                    </div>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={4} xl={4}>
                    <div className='wrap'>
                      <Form.Item>
                        <Button onClick={this.scrollToPayment} className='checkoutfamily' htmlType='submit'>
                          Proceed For Checkout
                        </Button>
                      </Form.Item>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Form>

          <section className={disablepayment ? 'disabled-section' : ''} ref={this.showBillingRef}>
            <StripeProvider
              handlePaymentSection={this.handlePaymentCallback}
              apiKey={config.stripeKey}
              parentformData={this.state.parentformData}
              childformdata={this.state.payloadformdata}
            ></StripeProvider>
          </section>
        </section>
      </div>
    );
  }
}

childParentPurchase.propTypes = {

};

function mapStateToProps(state) {
    return {
      ...state.b2b2c,
      ...state.user,
    };
  }
  function mapDispatchToProps(dispatch) {
    return bindActionCreators({ ...AdminActions, ...b2cActions }, dispatch);
  }



const WrappedForm = Form.create({  })(
    childParentPurchase
);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    WrappedForm
  )
);
