import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Row, Col, Typography, Button, Switch, Tooltip, Icon, Input, Table } from "antd";
import IntlMessages from "../../../services/intlMesseges";
import * as emailTemplateActions from "../../../redux/actions/email-template-actions";
import * as UserService from "../../../services/user-service";
import * as Permission from "../../../services/permissions";

class CustomizeEmailsList extends Component {
  constructor(props) {
    super(props);
    this.module = 'email_templates';
    this.state = {
      dataLoaded: false,
      loading: false,
      data: [],
      pagination: {
        hideOnSinglePage: true,
        showTotal: (total, range) => {
          return (
            <span>
              <IntlMessages id="admin.text.showing" /> {range[0]}-{range[1]}{" "}
              <IntlMessages id="admin.text.of" /> {total}{" "}
              <IntlMessages id="admin.text.results" />
            </span>
          );
        },
        pageSize: 10,
        current: 1
      },
      filters: {}
    };
  }

  async componentDidMount() {
    this.handleTableChange({ current: 1, pageSize: 10 }, {}, {});
  }

  /* initComponent = async() => {
    if(this.props.paginginfo && this.props.currentModule !== "" && this.props.currentModule !== this.module){
      this.props.clearPaginationExceptMe(this.module);
    } else {
      if(this.props.paginginfo && this.props.paginginfo[this.module]){
        this.handleTableChange(this.props.paginginfo[this.module].pagination, this.props.paginginfo[this.module].filter, {});
        if(this.props.paginginfo[this.module].filters){
        let filters = this.props.paginginfo[this.module].filters
        Object.keys(filters).map(k=> {filters[k].auto = false});
          this.setState({filters :  filters});
        }
      } else {
        this.handleTableChange({ current: 1, pageSize: 10 }, {}, {});
      }
    }
  } */

  handleTableChange = (pagination, filters, sorter) => {
    if(filters === undefined) filters={};
    Object.keys(filters).map( key => { if((!filters[key]) || (Array.isArray(filters[key]) && filters[key].length===0)) { delete filters[key] }} )
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    /* if(manual !== true)
    {
      this.props.updatePaginationRemoveOld({module:this.module, filter: filters,
      pagination: { current: pagination.current, pageSize: pagination.pageSize }})
    } */
    this.setState({ loading: true });
    let companyId = this.props.match.params.id ? this.props.match.params.id : (this.props.userData.companyID ? this.props.userData.companyID : UserService.getUser().companyID);
    this.props
      .getEmailTemplates({
        orgId: companyId,
        filters: filters,
        pagination: { page: pagination.current, pageSize: pagination.pageSize },
        sorter: sorter
      })
      .then(resp => {
        pager.total = resp.paging.totalRecords;
        if(pager.total == 0){

        }
        this.setState({
          loading: false,
          data: resp.data,
          pagination: pager
        });
      })
      .catch(ex => {
        this.setState({ loading: false });
      });
  };

  getSelectedFilterValue = (index) => {
    return this.props.paginginfo[this.module]&& this.props.paginginfo[this.module].filter && this.props.paginginfo[this.module].filter[index] || null;
  } 

  getHeaderKeys = () => {
    return [
      {
        title: <IntlMessages id="admin.emailtemplates.title" />,
        dataIndex: "title",
        filteredValue : this.getSelectedFilterValue('title'),
        ...this.getColumnSearchProps("title"),
        width: "35%"
      },
      {
        title: <IntlMessages id="admin.emailtemplates.type" />,
        dataIndex: "type",
        render: (_text, record) => (
          record.type.replace(/\-/g," ").toLowerCase().replace(/\b[a-z]/g, function(letter) {return letter.toUpperCase()})
        )
      },
      {
        title: <IntlMessages id="admin.emailtemplates.subject" />,
        dataIndex: "subject",
        filteredValue : this.getSelectedFilterValue('subject'),
        ...this.getColumnSearchProps("subject")
      },
      {
        title: <IntlMessages id="admin.userlisting.action" />,
        rowKey: "action",
        width: 150,
        render: (_text, record) => (
          <span>
            <button
              className="icon"
              onClick={() =>
                this.props.history.push("./edit/" + record.templateId)
              }
            >
              <Tooltip title={<IntlMessages id="admin.action.edit" />}>
                <i className="fas fa-edit"></i>
              </Tooltip>
            </button>
          </span>
        )
      }
    ];
  };

  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => {
      return (
        <div style={{ padding: 8 }}>
          <Input
            ref={node => {
              this.searchInput = node;
            }}
            value={selectedKeys}
            onChange={e =>
              setSelectedKeys(e.target.value ? e.target.value.trimLeft() : "")
            }
            onPressEnter={() =>
              this.handleSearch(
                selectedKeys,
                confirm,
                dataIndex,
                clearFilters,
                setSelectedKeys
              )
            }
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Button
            type="primary"
            onClick={() =>
              this.handleSearch(
                selectedKeys,
                confirm,
                dataIndex,
                clearFilters,
                setSelectedKeys
              )
            }
            disabled={selectedKeys != "" && selectedKeys !== null ? false : true}
            icon="search"
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            <IntlMessages id="admin.search" />
          </Button>
          <Button
            disabled={selectedKeys != "" && selectedKeys !== null ? false : true}
            onClick={() => this.handleReset(clearFilters, dataIndex)}
            size="small"
            style={{ width: 90 }}
          >
            <IntlMessages id="admin.reset" />
          </Button>
        </div>
      );
    },
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    }
  });

  handleSearch = (
    selectedKeys,
    confirm,
    dataIndex,
    clearFilters,
    setSelectedKeys
  ) => {
    if (selectedKeys === "") {
      return false;
    }

    this.setState({ searchText: selectedKeys });
    let filters = this.state.filters;
    filters[dataIndex] = {
      val: selectedKeys,
      clearf: clearFilters,
      filter: true,
      setSelectedKeys: setSelectedKeys,
      confirm: confirm,
      auto:true
    };
    this.setState({ filters: filters });
    this.props.updateFilters({module:this.module, filters: filters})
    confirm();
  };

  handleReset = (clearFilters, dataIndex) => {
    clearFilters();
    let filters = this.state.filters;
    if (filters[dataIndex]) {
      if(filters[dataIndex].setSelectedKeys && typeof filters[dataIndex].setSelectedKeys === 'function'){
        filters[dataIndex].setSelectedKeys("");
        //filters[dataIndex].confirm();
      }
    }
    if(filters[dataIndex] && !filters[dataIndex].auto){
      delete this.props.paginginfo[this.module].filter[dataIndex];
      this.handleTableChange({ current: 1, pageSize: 10 }, this.props.paginginfo[this.module].filter, {});
   
    }
    filters[dataIndex] = { val: "", clearf: "", filter: false };
    this.setState({ filters: filters });
    this.props.updateFilters({module: this.module, filters:  filters})
    this.setState({ searchText: "" });
  };

  render() {
    let _state = this.state;
    let _this = this;
    let filtertag = Object.keys(this.state.filters).map(function (key1) {
      let keyLabel = _this.getHeaderKeys().find(el => el.dataIndex === key1);
      if (keyLabel.title.props && keyLabel.title.props.id) {
        keyLabel = <IntlMessages id={keyLabel.title.props.id} />;
      } else {
        keyLabel = key1;
      }
      if (_state.filters[key1].filter) {
        return (
          <span style={{ marginBottom: '7px', display: 'inline-block'}} >
            <Button
              key={key1}
              type="primary"
              onClick={() => _this.handleReset(_state.filters[key1].clearf, key1)}
            >
              {keyLabel} : {_state.filters[key1].val}
              <Icon type="close" />
            </Button>&nbsp;
            </span>
        );
      }
    });
    
    return (
      <div>
        <Row gutter={24}>
          <Col xs={24} sm={24} lg={12}>
            <Typography.Title level={4} style={{ fontSize: 22 }}>
              <IntlMessages id="admin.customizeEmailTemplates" />
            </Typography.Title>
          </Col>
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={12}
            style={{ textAlign: "right" }}
          >
            <Button type="primary" onClick={() => this.props.history.goBack()}>
              <IntlMessages id="admin.button.back"/>
            </Button>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col>{filtertag}</Col>
        </Row>
        <Row>
          <Col>
            <Table
              columns={this.getHeaderKeys()}
              rowKey={record => record.templateId}
              dataSource={this.state.data}
              pagination={this.state.pagination}
              loading={this.state.loading}
              onChange={this.handleTableChange}
            />
          </Col>
        </Row>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    ...state.user,
    ...state.pagination,
    ...state.language
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...emailTemplateActions }, dispatch);
}
export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    { forwardRef: true }
  )(CustomizeEmailsList)
); 
