import Axios from "../../services/axios-service";

const applyPromoCode = async (couponCode) => {
  return await Axios.get(
    "/api/applypromooffer/" + couponCode,
    undefined,
    undefined
  );
};

const placeOrder = async (objOrder) => {
  return await Axios.post("/api/submitorder", objOrder, undefined);
};

const validateTravellerEmail = async (email) => {
  return await Axios.get(
    "/api/TravelerExists?email=" + encodeURIComponent(email),
    undefined,
    undefined
  );
};

const getTravellerInfo = async (userId) => {
  return Axios.get("/api/travelerbasicinfo/" + userId, undefined, undefined);
};

const getProductPricingAndInfo = async () => {
  return await Axios.get("/api/completeproductinfo", undefined, undefined);
};

const getAllCountries = async () => {
  return Axios.get("/api/Country", undefined, undefined);
};

const convertCurrency = async () => {
  return await Axios.get("/api/GetCurrencyValue/CAD", undefined, "NO_TOKEN");
};

const createOrderPaypal = (orderInfoDev) => {
  return Axios.post("/api/Paypal/CreateOrder", orderInfoDev).then(
    (response) => {
      console.log(response.data);
      return response.data.orderId;
    }
  );
};

const captureOrder = (orderId, orderInfoDev) => {
  return Axios.post(`/api/Paypal/${orderId}/CaptureOrder`, orderInfoDev);
};

const getGendersList = () => {
  return Axios.get("/api/GetGendersList", undefined, undefined);
};

//md5 Email Exit
const EmployeeExits = async (email) => {
  return await Axios.get(
    "/api/EmployeeExits?email=" + encodeURIComponent(email),
    undefined,
    undefined
  );
};

//md5 Check Employee Child Exit
const CheckEmployeeChild = async (email) => {
  return await Axios.get(
    "/api/CheckEmployeeChild?email=" + encodeURIComponent(email),
    undefined,
    undefined
  );
};

const EmployeeSubmitOrder = async (objOrder) => {
  return await Axios.post("/api/EmployeeSubmitOrder", objOrder, undefined);
};

const EmployeePaypalCreateOrder = (orderInfoDev) => {
  return Axios.post("/api/EmployeePaypalCreateOrder", orderInfoDev).then(
    (response) => {
      return response.data.orderId;
    }
  );
};

const EmployeePaypalCaptureOrder = (orderId, orderInfoDev) => {
  return Axios.post(`/api/EmployeePaypalCaptureOrder`, orderInfoDev);
};



export default {
  applyPromoCode,
  placeOrder,
  validateTravellerEmail,
  getTravellerInfo,
  getProductPricingAndInfo,
  getAllCountries,
  convertCurrency,
  createOrderPaypal,
  captureOrder,
  getGendersList,
  EmployeeExits,
  CheckEmployeeChild,
  EmployeeSubmitOrder,
  EmployeePaypalCreateOrder,
  EmployeePaypalCaptureOrder
};
