import API from "../api/travellers-report-api";

export function getActiveTravelers(
  orgId,
  userRole,
  { filters, pagination, sorter }
) {
  return async function(dispatch, getState) {
    try {
      let resp = await API.getActiveTravelers(orgId, userRole, {
        filters: filters,
        pagination: pagination,
        sorter: sorter,
      });
      return resp;
    } catch (e) {
      return { error: true };
    }
  };
}

export function getManualActiveTravelers(
  orgId,
  userRole,
  { filters, pagination, sorter }
) {
  return async function(dispatch, getState) {
    try {
      let resp = await API.getActiveManualTravelers(orgId, userRole, {
        filters: filters,
        pagination: pagination,
        sorter: sorter,
      });
      return resp;
    } catch (e) {
      return { error: true };
    }
  };
}

export function getManualTravelersCsv(
  orgId,
  userRole,
  { filters, pagination, sorter }
) {
  return async function(dispatch, getState) {
    try {
      let resp = await API.getManualTravelersCsv(orgId, userRole, {
        filters: filters,
        pagination: pagination,
        sorter: sorter,
      });
      return resp;
    } catch (e) {
      return { error: true };
    }
  };
}

export function getAllActiveTravelers(
  orgId,
  userRole,
  { filters, pagination, sorter }
) {
  return async function(dispatch, getState) {
    try {
      let resp = await API.getActiveTravelers(orgId, userRole, {
        filters: filters,
        pagination: pagination,
        sorter: sorter,
      });
      return resp;
    } catch (e) {
      return { error: true };
    }
  };
}

export function getWhereAreMyTravelers(
  orgId,
  userRole,
  { filters, pagination, sorter }
) {
  return async function(dispatch, getState) {
    try {
      let resp = await API.getWhereAreMyTravelers(orgId, userRole, {
        filters: filters,
        pagination: pagination,
        sorter: sorter,
      });
      return resp;
    } catch (e) {
      return { error: true };
    }
  };
}

export function getAllWhereAreMyTravelers(
  orgId,
  userRole,
  { filters, pagination, sorter }
) {
  return async function(dispatch, getState) {
    try {
      let resp = await API.getWhereAreMyTravelers(orgId, userRole, {
        filters: filters,
        pagination: pagination,
        sorter: sorter,
      });
      return resp;
    } catch (e) {
      return { error: true };
    }
  };
}

export function getManualWhereAreMyTravelers(
  orgId,
  userRole,
  { filters, pagination, sorter }
) {
  return async function(dispatch, getState) {
    try {
      let resp = await API.getManualWhereAreMyTravelers(orgId, userRole, {
        filters: filters,
        pagination: pagination,
        sorter: sorter,
      });
      return resp;
    } catch (e) {
      return { error: true };
    }
  };
}

export function getManualWhereAreMyTravelersCsv(
  orgId,
  userRole,
  { filters, pagination, sorter }
) {
  return async function(dispatch, getState) {
    try {
      let resp = await API.getManualWhereAreMyTravelersCsv(orgId, userRole, {
        filters: filters,
        pagination: pagination,
        sorter: sorter,
      });
      return resp;
    } catch (e) {
      return { error: true };
    }
  };
}

export function getTravelersByDestandTime(
  orgId,
  userRole,
  { filters, pagination, sorter },
  key
) {
  return async function(dispatch, getState) {
    try {
      let resp = await API.getTravelersByDestandTime(
        orgId,
        userRole,
        {
          filters: filters,
          pagination: pagination,
          sorter: sorter,
        },
        key
      );
      return resp;
    } catch (e) {
      return { error: true };
    }
  };
}

export function getManualTravelersByDestandTime(
  orgId,
  userRole,
  { filters, pagination, sorter }
) {
  return async function(dispatch, getState) {
    try {
      let resp = await API.getManualTravelersByDestandTime(orgId, userRole, {
        filters: filters,
        pagination: pagination,
        sorter: sorter,
      });
      return resp;
    } catch (e) {
      return { error: true };
    }
  };
}

export function getAllTravelersByDestandTime(
  orgId,
  userRole,
  { filters, pagination, sorter }
) {
  return async function(dispatch, getState) {
    try {
      let resp = await API.getTravelersByDestandTime(orgId, userRole, {
        filters: filters,
        pagination: pagination,
        sorter: sorter,
      });
      return resp;
    } catch (e) {
      return { error: true };
    }
  };
}

export function getTravelersByFlightType(
  orgId,
  userRole,
  { filters, pagination, sorter }
) {
  return async function(dispatch, getState) {
    try {
      let resp = await API.getTravelersByFlightType(orgId, userRole, {
        filters: filters,
        pagination: pagination,
        sorter: sorter,
      });
      return resp;
    } catch (e) {
      return { error: true };
    }
  };
}

export function getManualTravelersByFlightType(
  orgId,
  userRole,
  { filters, pagination, sorter }
) {
  return async function(dispatch, getState) {
    try {
      let resp = await API.getManualTravelersByFlightType(orgId, userRole, {
        filters: filters,
        pagination: pagination,
        sorter: sorter,
      });
      return resp;
    } catch (e) {
      return { error: true };
    }
  };
}

export function getAllTravelersByFlightType(
  orgId,
  userRole,
  { filters, pagination, sorter }
) {
  return async function(dispatch, getState) {
    try {
      let resp = await API.getTravelersByFlightType(orgId, userRole, {
        filters: filters,
        pagination: pagination,
        sorter: sorter,
      });
      return resp;
    } catch (e) {
      return { error: true };
    }
  };
}

export function getTravelersByFlightSegment(
  orgId,
  userRole,
  { filters, pagination, sorter }
) {
  return async function(dispatch, getState) {
    try {
      let resp = await API.getTravelersByFlightSegment(orgId, userRole, {
        filters: filters,
        pagination: pagination,
        sorter: sorter,
      });
      return resp;
    } catch (e) {
      return { error: true };
    }
  };
}

export function getManualTravelersByFlightSegment(
  orgId,
  userRole,
  { filters, pagination, sorter }
) {
  return async function(dispatch, getState) {
    try {
      let resp = await API.getManualTravelersByFlightSegment(orgId, userRole, {
        filters: filters,
        pagination: pagination,
        sorter: sorter,
      });
      return resp;
    } catch (e) {
      return { error: true };
    }
  };
}

export function getAllTravelersByFlightSegment(
  orgId,
  userRole,
  { filters, pagination, sorter }
) {
  return async function(dispatch, getState) {
    try {
      let resp = await API.getTravelersByFlightSegment(orgId, userRole, {
        filters: filters,
        pagination: pagination,
        sorter: sorter,
      });
      return resp;
    } catch (e) {
      return { error: true };
    }
  };
}

export function getTravelersByActiveCar(
  orgId,
  userRole,
  { filters, pagination, sorter }
) {
  return async function(dispatch, getState) {
    try {
      let resp = await API.getTravelersByActiveCar(orgId, userRole, {
        filters: filters,
        pagination: pagination,
        sorter: sorter,
      });
      return resp;
    } catch (e) {
      return { error: true };
    }
  };
}

export function getAllTravelersByActiveCar(
  orgId,
  userRole,
  { filters, pagination, sorter }
) {
  return async function(dispatch, getState) {
    try {
      let resp = await API.getTravelersByActiveCar(orgId, userRole, {
        filters: filters,
        pagination: pagination,
        sorter: sorter,
      });
      return resp;
    } catch (e) {
      return { error: true };
    }
  };
}

export function getTravelersByAirport(
  orgId,
  userRole,
  { filters, pagination, sorter }
) {
  return async function(dispatch, getState) {
    try {
      let resp = await API.getTravelersByAirport(orgId, userRole, {
        filters: filters,
        pagination: pagination,
        sorter: sorter,
      });
      return resp;
    } catch (e) {
      return { error: true };
    }
  };
}

export function getManualTravelersByAirport(
  orgId,
  userRole,
  { filters, pagination, sorter }
) {
  return async function(dispatch, getState) {
    try {
      let resp = await API.getManualTravelersByAirport(orgId, userRole, {
        filters: filters,
        pagination: pagination,
        sorter: sorter,
      });
      return resp;
    } catch (e) {
      return { error: true };
    }
  };
}

export function getAllTravelersByAirport(
  orgId,
  userRole,
  { filters, pagination, sorter }
) {
  return async function(dispatch, getState) {
    try {
      let resp = await API.getTravelersByAirport(orgId, userRole, {
        filters: filters,
        pagination: pagination,
        sorter: sorter,
      });
      return resp;
    } catch (e) {
      return { error: true };
    }
  };
}

export function getTravelersByArrivalAirport(
  orgId,
  userRole,
  { filters, pagination, sorter }
) {
  return async function(dispatch, getState) {
    try {
      let resp = await API.getTravelersByArrivalAirport(orgId, userRole, {
        filters: filters,
        pagination: pagination,
        sorter: sorter,
      });
      return resp;
    } catch (e) {
      return { error: true };
    }
  };
}

export function getManualTravelersByArrivalAirport(
  orgId,
  userRole,
  { filters, pagination, sorter }
) {
  return async function(dispatch, getState) {
    try {
      let resp = await API.getManualTravelersByArrivalAirport(orgId, userRole, {
        filters: filters,
        pagination: pagination,
        sorter: sorter,
      });
      return resp;
    } catch (e) {
      return { error: true };
    }
  };
}

export function getAllTravelersByArrivalAirport(
  orgId,
  userRole,
  { filters, pagination, sorter }
) {
  return async function(dispatch, getState) {
    try {
      let resp = await API.getTravelersByArrivalAirport(orgId, userRole, {
        filters: filters,
        pagination: pagination,
        sorter: sorter,
      });
      return resp;
    } catch (e) {
      return { error: true };
    }
  };
}

export function getTravelersByDepatureAirport(
  orgId,
  userRole,
  { filters, pagination, sorter }
) {
  return async function(dispatch, getState) {
    try {
      let resp = await API.getTravelersByDepatureAirport(orgId, userRole, {
        filters: filters,
        pagination: pagination,
        sorter: sorter,
      });
      return resp;
    } catch (e) {
      return { error: true };
    }
  };
}

export function getManualTravelersByDepatureAirport(
  orgId,
  userRole,
  { filters, pagination, sorter }
) {
  return async function(dispatch, getState) {
    try {
      let resp = await API.getManualTravelersByDepatureAirport(
        orgId,
        userRole,
        {
          filters: filters,
          pagination: pagination,
          sorter: sorter,
        }
      );
      return resp;
    } catch (e) {
      return { error: true };
    }
  };
}

export function getAllTravelersByDepatureAirport(
  orgId,
  userRole,
  { filters, pagination, sorter }
) {
  return async function(dispatch, getState) {
    try {
      let resp = await API.getTravelersByDepatureAirport(orgId, userRole, {
        filters: filters,
        pagination: pagination,
        sorter: sorter,
      });
      return resp;
    } catch (e) {
      return { error: true };
    }
  };
}

export function getTravellersByFlightNumber(
  orgId,
  userRole,
  { filters, pagination, sorter }
) {
  return async function(dispatch, getState) {
    try {
      let resp = await API.getTravellersByFlightNumber(orgId, userRole, {
        filters: filters,
        pagination: pagination,
        sorter: sorter,
      });
      return resp;
    } catch (e) {
      return { error: true };
    }
  };
}

export function getTravellersByPnrNumber(
  orgId,
  pnrNumber,
  userRole,
  { filters, pagination, sorter }
) {
  return async function(dispatch, getState) {
    try {
      let resp = await API.getTravellersByPnrNumber(
        orgId,
        pnrNumber,
        userRole,
        {
          filters: filters,
          pagination: pagination,
          sorter: sorter,
        }
      );
      return resp;
    } catch (e) {
      return { error: true };
    }
  };
}

export function getManualTravellersByFlightNumber(
  orgId,
  userRole,
  { filters, pagination, sorter }
) {
  return async function(dispatch, getState) {
    try {
      let resp = await API.getManualTravellersByFlightNumber(orgId, userRole, {
        filters: filters,
        pagination: pagination,
        sorter: sorter,
      });
      return resp;
    } catch (e) {
      return { error: true };
    }
  };
}

export function getAllTravellersByFlightNumber(
  orgId,
  userRole,
  { filters, pagination, sorter }
) {
  return async function(dispatch, getState) {
    try {
      let resp = await API.getTravellersByFlightNumber(orgId, userRole, {
        filters: filters,
        pagination: pagination,
        sorter: sorter,
      });
      return resp;
    } catch (e) {
      return { error: true };
    }
  };
}

export function getActiveTravelersByHotel(
  orgId,
  userRole,
  { filters, pagination, sorter }
) {
  return async function(dispatch, getState) {
    try {
      let resp = await API.getActiveTravelersByHotel(orgId, userRole, {
        filters: filters,
        pagination: pagination,
        sorter: sorter,
      });
      return resp;
    } catch (e) {
      return { error: true };
    }
  };
}

export function getAllActiveTravelersByHotel(
  orgId,
  userRole,
  { filters, pagination, sorter }
) {
  return async function(dispatch, getState) {
    try {
      let resp = await API.getActiveTravelersByHotel(orgId, userRole, {
        filters: filters,
        pagination: pagination,
        sorter: sorter,
      });
      return resp;
    } catch (e) {
      return { error: true };
    }
  };
}

export function getEmployeeOnSameFlight(
  orgId,
  userRole,
  { filters, pagination, sorter }
) {
  return async function(dispatch, getState) {
    try {
      let resp = await API.getEmployeeOnSameFlight(orgId, userRole, {
        filters: filters,
        pagination: pagination,
        sorter: sorter,
      });
      return resp;
    } catch (e) {
      return { error: true };
    }
  };
}

export function getManualEmployeeOnSameFlight(
  orgId,
  userRole,
  { filters, pagination, sorter }
) {
  return async function(dispatch, getState) {
    try {
      let resp = await API.getManualEmployeeOnSameFlight(orgId, userRole, {
        filters: filters,
        pagination: pagination,
        sorter: sorter,
      });
      return resp;
    } catch (e) {
      return { error: true };
    }
  };
}

export function getAllEmployeeOnSameFlight(
  orgId,
  userRole,
  { filters, pagination, sorter }
) {
  return async function(dispatch, getState) {
    try {
      let resp = await API.getEmployeeOnSameFlight(orgId, userRole, {
        filters: filters,
        pagination: pagination,
        sorter: sorter,
      });
      return resp;
    } catch (e) {
      return { error: true };
    }
  };
}

export function getTravellersByRiskLevel(
  orgId,
  userRole,
  { filters, pagination, sorter }
) {
  return async function(dispatch, getState) {
    try {
      let resp = await API.getTravellersByRiskLevel(orgId, userRole, {
        filters: filters,
        pagination: pagination,
        sorter: sorter,
      });
      return resp;
    } catch (e) {
      return { error: true };
    }
  };
}

export function getAllTravellersByRiskLevel(
  orgId,
  userRole,
  { filters, pagination, sorter }
) {
  return async function(dispatch, getState) {
    try {
      let resp = await API.getTravellersByRiskLevel(orgId, userRole, {
        filters: filters,
        pagination: pagination,
        sorter: sorter,
      });
      return resp;
    } catch (e) {
      return { error: true };
    }
  };
}

export function getActiveManualTravelersByRiskLevel(
  orgId,
  userRole,
  { filters, pagination, sorter }
) {
  return async function(dispatch, getState) {
    try {
      let resp = await API.getActiveManualTravelersByRiskLevel(
        orgId,
        userRole,
        {
          filters: filters,
          pagination: pagination,
          sorter: sorter,
        }
      );
      return resp;
    } catch (e) {
      return { error: true };
    }
  };
}

export function getActiveAlertList(
  orgId,
  userRole,
  { filters, pagination, sorter }
) {
  return async function(dispatch, getState) {
    try {
      let resp = await API.getActiveAlertList(orgId, userRole, {
        filters: filters,
        pagination: pagination,
        sorter: sorter,
      });
      return resp;
    } catch (e) {
      return { error: true };
    }
  };
}

export function getAffectedTravelersByAlerts(
  orgId,
  userRole,
  { filters, pagination, sorter }
) {
  return async function(dispatch, getState) {
    try {
      let resp = await API.getAffectedTravelersByAlerts(orgId, userRole, {
        filters: filters,
        pagination: pagination,
        sorter: sorter,
      });
      return resp;
    } catch (e) {
      return { error: true };
    }
  };
}
