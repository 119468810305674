import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Table, Tabs } from "antd";
import * as travelerItineraryActions from "../../../../redux/actions/travelers-itinerary-action";

const { TabPane } = Tabs;

let flightStatus = {
  L: "LANDED",
  l: "LANDED",
  SCHEDULED: "SCHEDULED",
  S: "SCHEDULED",
  s: "SCHEDULED",
  LANDED: "LANDED",
  A: "In-Transit",
  U: "Unknown",
  PAXC: "Cancelled By Passenger",
  C: "CANCELED",
  c: "CANCELED"
};

class TravelerListItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      isFlight: true,
      travellers: "",
      departureTravellers: ""
    };
  }

  getHeaderKeys = () => {
    return [
      {
        title: "Name",
        dataIndex: "name",
        render: (text, record) => (
          <a onClick={() => this.handleTravlerClick(record)}>
            {record.firstName}
          </a>
        )
      },
      {
        title: "Email",
        dataIndex: "email"
      },
      {
        title: "Phone",
        dataIndex: "contactNo"
      },
      {
        title: "Flight Status",
        dataIndex: "status",
        render: text => flightStatus[text]
      },
      {
        title: "PNR",
        dataIndex: "pnrNumber"
      },
      {
        title: "Flight No.",
        dataIndex: "flightNumber"
      },
      {
        title: "City Pair",
        dataIndex: "city",
        render: (text, record) => (
          <span>
            {record.departureAirportCode + " " + record.departureCity}
            <i
              style={{ margin: "0 6px", display: "inline-block" }}
              className="fa fa-arrow-right"
            ></i>
            {record.arrivalAirportCode + " " + record.arrivalCity}
          </span>
        )
      }
    ];
  };

  handleTravlerClick(record) {
    this.props.handleTravlerClick([record]);
  }

  componentDidMount() {}

  _bindTravellerDetails(itineraries) {
    var travellers = [];

    itineraries.map(itinerary => {
      if (itinerary.flights.length > 0) {
        itinerary.flights.map(data => {
          let personIcon = "";
          let lat = "";
          let lng = "";
          if (data.status === "L") {
            personIcon = "landed.png";
            lat = data.arrivalLatitude;
            lng = data.arrivalLongitude;
          } else if (
            data.status === "SCHEDULED" ||
            data.status === "S" ||
            data.status === "U" ||
            data.status === "s"
          ) {
            personIcon = "scheduled.png";
            lat = data.departureLatitude;
            lng = data.departureLongitude;
          } else if (data.status === "A") {
            personIcon = "intransit.png";
            lat = data.departureLatitude;
            lng = data.departureLongitude;
          } else if (data.status === "C") {
            personIcon = "canceled.png";
            lat = data.departureLatitude;
            lng = data.departureLongitude;
          }

          travellers.push({
            pnrId: itinerary.pnrId,
            pnrNumber: itinerary.pnrNumber,
            email: itinerary.email,
            status: data.status,
            arrivalDateTime: data.arrivalDateTime,
            arrivalAirportCode: data.arrivalAirportCode,
            arrivalAirport: data.arrivalAirport,
            departureDateTime: data.departureDateTime,
            departureAirportCode: data.departureAirportCode,
            departureAirport: data.departureAirport,
            flightNumber: data.flightNumber,
            departureCity: data.departureCity,
            arrivalCity: data.arrivalCity,
            segmentNumber: data.segmentNumber,
            firstName: itinerary.firstName,
            contactNo: itinerary.contactNo,
            lastName: itinerary.lastName,
            lat: lat,
            lng: lng,
            icon: personIcon,
            source: itinerary.source
          });
        });
      }
    });

    return (
      <div>
        <Tabs
          defaultActiveKey="1"
          type="card"
          className="travellerNotifationTabs"
        >
          <TabPane tab={<span>Travelers</span>} key="1">
            <Table
              pagination={{ defaultPageSize: 5 }}
              columns={this.getHeaderKeys()}
              dataSource={travellers}
            />
          </TabPane>
        </Tabs>
      </div>
    );
  }

  render() {
    return this._bindTravellerDetails(this.props.itineraries);
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...travelerItineraryActions }, dispatch);
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    TravelerListItem
  )
);
