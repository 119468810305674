import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import SideBar from "../sidebar/sidebar.jsx";
import HybridRouter from "../../routes/hybrid-router";
import * as userActions from "../../redux/actions/user-actions"
import { Layout, Modal } from "antd";
import UserHeader from "./user-header/header.jsx";
import * as languageAction from "../../redux/actions/language-actions.js";
import * as themeAction from "../../redux/actions/theme-actions";
import options from "../sidebar/options";
import * as UserService from "../../services/user-service";
const { Header, Sider, Content } = Layout;



class ResellerDash extends Component {
  state = {
    changePasswordModalVisible: false
  };
  async componentDidMount() {
    var currentTheme = JSON.parse(localStorage.getItem('user')),
      currentThemeName = 'theme-' + currentTheme.themeId;
    document.body.setAttribute('class', currentThemeName);
    document.body.setAttribute('style', "");
    if (!this.props.userData || !this.props.userData.userID) {
      await this.props.getUserData();
    }
    let currentUser = UserService.getUser();
    if (currentUser.userID && currentUser.accountConfirmed === false) {
      localStorage.setItem("remember_me", false);
      if (this.props.history.location.pathname.indexOf("change-password") <= -1) {
        this.props.history.push('/hybrid/my-profile/change-password');
      }
    }

  }
  showModal = () => {
    this.setState({
      planExp: true,
    });
  };
  handleOk = e => {
    this.setState({
      planExp: false,
    });
  };
  submitPasswordChangeModal = () => {
    this.setState({ changePasswordModalVisible: false });
    this.props.history.push('/admin/my-profile');
  }

  cancelPasswordChangeModal = () => {
    this.setState({ changePasswordModalVisible: false });
  }
  render() {
    const { url } = this.props.match;
    return (
      <div id="components-layout-demo-custom-trigger" /* className={this.props.theme && this.props.theme.themeId ? this.props.theme.name.toLowerCase().replace(" ","-") : ''} */>
  
        <Modal
          title="Warning! Please change your password."
          visible={this.state.changePasswordModalVisible}
          onOk={this.submitPasswordChangeModal}
          onCancel={this.cancelPasswordChangeModal}
        >
          <p>For security reasons, we suggest you to reset your password.</p>
        </Modal>
        <Layout className={this.props.theme.className}>
          <Sider
            trigger={null}
            collapsible
            collapsed={!this.props.sidebar}
            style={{
              overflow: "auto",
              height: "100vh",
              position: "sticky",
              top: 0,
              left: 0
            }}
            width={300}
          >
            <SideBar options={options.hybridOptions} logo_url={this.props.logo_url} userType="user" />
          </Sider>
          <Layout>
            <Header
              className="header"
              style={{
                background: "#fff",
                position: "sticky",
                zIndex: 100,
                top: 0,
                left: 0,
                width: "100%",
                padding: 0
              }}
              match={this.props.match}
              selectedtheme={this.props.theme}
              location={this.props.location}
              history={this.props.history}
            >
              <UserHeader />
            </Header>
            <Content style={{
              margin: "24px 16px",
              padding: 24,
              background: "#fff",
              minHeight: 280
            }}>
              <HybridRouter url={url} />
            </Content>
          </Layout>
        </Layout>
        {/*<AuthTokenTimer/>*/}
      </div>
    );
  }
}

ResellerDash.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object,
  logo_url: PropTypes.string,
  theme: PropTypes.object
};
function mapStateToProps(state) {
  return {
    ...state.user,
    ...state.language,
    ...state.sidebar,
    ...state.theme
  }
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { ...userActions, ...languageAction, ...themeAction },
    dispatch
  );
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(ResellerDash));
