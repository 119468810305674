import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import IntlMessages from "../../../../services/intlMesseges";
import { Typography, Form, Input, Row, Col, DatePicker, Select, Button, Spin } from "antd";
import moment from "moment";
import API from "../../../../redux/api/admin-api";
import * as OrgActions from "../../../../redux/actions/organization-actions";
import * as userActions from "../../../../redux/actions/user-actions";
import { notifyUser } from "../../../../services/notification-service";

const { Option } = Select;

const dateFormat = "MM/DD/YYYY";
class TravelManager extends Component {
  constructor(props) {
    super(props);
    this.state = {
      countries: [],
      loading: true
    };
    this.handleSubmit = this.handleSubmit.bind(this);

  }
  state = {
    formLayout2: undefined
  };
  async componentDidMount() {

    let countriesListTemp = await API.getCountriesList();
    this.setState({ countries: countriesListTemp.countries });

    this.setState({ loading: false })
  }
  backpage() {

    this.props.onchange('current', 1)
  }
  // handleEmailExistance = (rule, value, callback) => {
  //   if (validator.validate(this.props.email)) {
  //     this.props.validateTravellerEmail(this.props.email);
  //     if (this.props.emailCheckResponse.userExist) {
  //       callback('Email ID Already Exists')
  //     }else {
  //       callback('')

  //     }
  //   }
  //   callback()
  // }



  handleSubmit = () => {   
    // if (1 !== 1) {
    if (this.props.email === this.props.primary_email) {
      notifyUser("Email ID Already Exists!", 'success');
    } else {

      this.props.form.validateFieldsAndScroll((err, values) => {

        var Json_Org = {
          "orgName": this.props.company_name,
          "code": this.props.company_code,
          "orgTypeId": 3,
          "address1": this.props.address,
          "state": this.props.company_state,
          "city": this.props.city,
          "zipCode": this.props.zip,
          "countryId": this.props.country,
          "phoneNo": this.props.phoneNumber,
          "isBusinessAddressSame": this.props.isBusinessAddressSame,
          "isCommunicationAddressSame": this.props.isCommunicationAddressSame,
          "fpManagerID": 0,
          "fpSalesExecutiveID": 0,
          "emailID": this.props.email,
          "contactFirstName": this.props.firstName,
          "contactLastName": this.props.lastName,
          "coverageStartDate": this.props.startDate,
          "coverageEndDate": this.props.endDate,
          "days": 365,
          "countries": this.props.org_country,
          "customerID": this.props.customer_id,
          "jobNumber": this.props.job_number,
          "creditDaysLimit": this.props.creditDays,


          "productInfo": {
            "productID": parseInt(this.props.membership_type),
            "productQty": parseInt(this.props.licence_number),
            "productRetailPrice": parseFloat(this.props.price),
            "productDealPrice": parseFloat(this.props.dealprice),
            "postSalePricePerLicense": 0,
          },
          "orgContacts": [
            {
              "addressTypeID": 2,
              "address": this.props.sec_address,
              "city": this.props.sec_city,
              "state": this.props.sec_state,
              "zipCode": this.props.sec_zip,
              "countryId": this.props.sec_country,
              "contacts": this.props.sec_phoneNumber,
              "contactPerson": this.props.secondary_contactPerson,
              "emailID": this.props.sec_email
            },
            {
              "addressTypeID": 3,
              "address": this.props.billing_address,
              "city": this.props.billing_city,
              "state": this.props.billing_state,
              "zipCode": this.props.billing_zip,
              "countryId": this.props.billing_country,
              "contacts": this.props.billing_phoneNumber,
              "contactPerson": this.props.billing_contactPerson,
              "emailID": this.props.billing_email,
              "billingCompanyName": this.props.billing_company_name
            }
          ],
          "orgPCCAccounts": this.props.pccaccountno,
          "usersInfo": {
            "firstName": this.props.primary_first,
            "lastName": this.props.primary_last,
            "emailID": this.props.primary_email,
            "contactNo": this.props.primary_primaryPhoneNumber,
            "countryId": this.props.primary_country,
            "emergencyFirstName": this.props.emergency_first,
            "emergencyLastName": this.props.emergency_lastname,
            "emergencyRelationship": this.props.emergency_relationship,
            "emergencyContactNo": this.props.emergency_primary_phone_number,
            "dateOfBirth": this.props.primary_dateofbirth,
            "emergencyEmail": this.props.emergencyEmail,
            "employeeID": this.props.primary_id
          }
        }


        if (!err) {
          this.setState({ loading: true })

          this.props
            .AddOrganizations(Json_Org)
            .then(response => {
              if (response.error) {

                notifyUser(response.error.message, 'error');
                this.setState({ loading: false });

              } else if (response.data.message === "Primary Travel manager already exist") {
                notifyUser("Email Already Exists!", 'success');
                this.setState({ loading: false });

              } else if (response.data.message === "Admin user already exist") {
                notifyUser("Email Already Exists!", 'success');
                this.setState({ loading: false });
              } else {
                notifyUser("Organization added successfully!", 'success');
                this.setState({ loading: false });
                this.props.onchange('membershipNumber', response.data.membershipNumber)
                this.props.onchange('organizationId', response.data.organizationID)
                this.props.onchange('current', 3);
              }
            })
            .catch(err => {
              this.setState({ loading: false });
            });
        } else {
          console.log(err);

        }


      })
    }


  }

  handleContactExistance(rule, value, callback){
    //const re = /^[0-9']+$/;
    const re = (/^(?=[+0-9]*$)(?!.*[<>'"/;`%~@#$^*()_=[\]{}|\\,.?: -])/)
    //var val=value.match(/(\d+)/)[0]
    var test=re.test(value)
    if(value !=="" && (value.length < 6 || test ===false)){
      callback(<IntlMessages id ="admin.phonenumber.valid"></IntlMessages>)
    }
    else{
      callback();
    }
  }


  render() {
    const { getFieldDecorator } = this.props.form;
    const { formLayout } = this.state;
    const formItemLayout =
      formLayout === "horizontal"
        ? {
          labelCol: { span: 4 },
          wrapperCol: { span: 14 }
        }
        : null;
    return (
      <div style={{ marginTop: "40px" }}>
        <Typography.Title level={3}>Primary Contact</Typography.Title>
        <Spin spinning={this.state.loading}>
          <Form layout={formLayout} onSubmit={this.handleSubmit}>
            <Row gutter={24}>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Form.Item
                  {...formItemLayout}
                  label={<IntlMessages id="admin.travelmanager.id" />}
                >
                  {getFieldDecorator("primary_id", {
                    rules: [
                      {
                        required: true,
                        message: <IntlMessages id ="admin.input.required"></IntlMessages>
                      }
                    ],
                    onChange: e => {
                      this.props.onchange(e);
                    },
                    value: this.props.data.primary_id,
                    initialValue:
                      this.props.primary_id === null ? "" : this.props.primary_id
                  })(<Input />)}
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Form.Item
                  {...formItemLayout}
                  label={<IntlMessages id="admin.userlisting.primary_first" />}
                >
                  {getFieldDecorator("primary_first", {
                    rules: [
                      {
                        required: true,
                        message: <IntlMessages id ="admin.input.required"></IntlMessages>
                      },
                      {
                        pattern: new RegExp(/^[a-zA-Z']+$/),
                        message: <IntlMessages id ="admin.name.valid"></IntlMessages>
                      }
                    ],
                    onChange: e => {
                      this.props.onchange(e);
                    },
                    value: this.props.data.primary_first,
                    initialValue:
                      this.props.primary_first === null
                        ? ""
                        : this.props.primary_first
                  })(<Input  maxLength="15" />)}
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Form.Item
                  {...formItemLayout}
                  label={<IntlMessages id="admin.userlisting.lastName" />}
                >
                  {getFieldDecorator("primary_last", {
                    rules: [
                      {
                        required: true,
                        message: <IntlMessages id ="admin.input.required"></IntlMessages>
                      },
                      {
                        pattern: new RegExp(/^[a-zA-Z']+$/),
                        message: <IntlMessages id ="admin.lname.valid"></IntlMessages>
                      }
                    ],
                    onChange: e => {
                      this.props.onchange(e);
                    },
                    value: this.props.data.primary_last,
                    initialValue:
                      this.props.primary_last === null
                        ? ""
                        : this.props.primary_last
                  })(<Input  maxLength="15" />)}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Form.Item
                  {...formItemLayout}
                  label={<IntlMessages id="admin.travelmanager.dateofbirth" />}
                >
                  {getFieldDecorator("primary_dateofbirth", {
                    rules: [
                      {
                        required: true,
                        message: <IntlMessages id ="admin.input.required"></IntlMessages>
                      }
                    ],

                    onChange: (e) => { this.props.onchange(e, 'primary_dateofbirth') },
                    // value: this.props.primary_dateofbirth,
                    initialValue: this.props.primary_dateofbirth === null ? "" : moment(
                      this.props.primary_dateofbirth,
                      dateFormat
                    )

                  })(
                    <DatePicker
                      disabledDate={current => {return current && current > moment().subtract(1, 'days');}}
                      initialValue={moment("YYYY/MM/DD", dateFormat)}
                      format={dateFormat}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Form.Item
                  {...formItemLayout}
                  label={<IntlMessages id="admin.primaryaddress.email" />}
                >
                  {getFieldDecorator("primary_email", {
                    rules: [
                      {
                        type: "email",
                        message: <IntlMessages id ="admin.email.valid"></IntlMessages>
                      },
                      {
                        required: true,
                        message: <IntlMessages id ="admin.input.required"></IntlMessages>
                      },
                      {
                        validator: this.handleEmailExistance
                      }
                    ],
                    onChange: e => {
                      this.props.onchange(e);
                    },
                    value: this.props.data.primary_email,
                    initialValue: this.props.primary_email === null ? "" : this.props.primary_email,
                  })(<Input  />)}
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Form.Item
                  {...formItemLayout}
                  label={<IntlMessages id="admin.primaryaddress.phonenumber" />}
                >
                  {getFieldDecorator("primary_primaryPhoneNumber", {
                    rules: [
                      
                        {
                          validator: this.handleContactExistance
                        }
                      ,
                      {
                        required: true,
                        message: <IntlMessages id ="admin.input.required"></IntlMessages>
                      },
                      // {
                      //   pattern: new RegExp(/^[0-9']+$/),
                      //   message: 'Please enter a valid phone number!'
                      // },
                      // {
                      //   max: 12,
                      //   message: "Please enter a valid phone number!"
                      // },
                      // {
                      //   min: 8,
                      //   message: "Please enter a valid phone number!"
                      // }
                    ],
                    onChange: e => {
                      this.props.onchange(e);
                    },
                    value: this.props.data.primary_primaryPhoneNumber,
                    initialValue: this.props.primary_primaryPhoneNumber === null ? "" : this.props.primary_primaryPhoneNumber,
                  })(<Input   maxLength={15} />)}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Form.Item label={<IntlMessages id="admin.primaryaddress.country" />}>
                  {getFieldDecorator("primary_country", {
                    rules: [
                      { required: true, message: <IntlMessages id ="admin.input.required"></IntlMessages> }
                    ],
                    onChange: e => {
                      this.props.onchange(e, "primary_country");
                    },
                    initialValue: this.props.primary_country
                  })(<Select style={{ width: "100%" }}
                    showSearch
                    filterOption={(input, option) =>
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }>

                    {this.state.countries.map(function (item) {
                      return (
                        <Option key={item.countryId} value={item.countryId}>
                          {item.countryName}
                        </Option>
                      );
                    })}
                  </Select>

                  )}
                </Form.Item>
              </Col>
            </Row>
            <Typography.Title level={3}>Alternate Contact</Typography.Title>
            <Row gutter={24}>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Form.Item
                  {...formItemLayout}
                  label={<IntlMessages id="admin.userlisting.firstName" />}
                >
                  {getFieldDecorator("emergency_first", {
                    rules: [
                      {
                        pattern: new RegExp(/^[a-zA-Z']+$/),
                        message: <IntlMessages id ="admin.name.valid"></IntlMessages>
                      }
                    ],
                    onChange: e => {
                      this.props.onchange(e);
                    },
                    value: this.props.data.emergency_first,
                    initialValue: this.props.emergency_first === null ? "" : this.props.emergency_first,
                  })(<Input  maxLength={15} />)}
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Form.Item
                  {...formItemLayout}
                  label={<IntlMessages id="admin.userlisting.lastName" />}
                >
                  {getFieldDecorator("emergency_lastname", {
                    rules: [
                      {
                        pattern: new RegExp(/^[a-zA-Z']+$/),
                        message: <IntlMessages id ="admin.lname.valid"></IntlMessages>
                      }
                    ],
                    onChange: e => {
                      this.props.onchange(e);
                    },
                    value: this.props.data.emergency_lastname,
                    initialValue: this.props.emergency_lastname === null ? "" : this.props.emergency_lastname,
                  })(<Input  maxLength={15} />)}
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Form.Item
                  {...formItemLayout}
                  label={<IntlMessages id="admin.primaryaddress.email" />}
                >
                  {getFieldDecorator("emergencyEmail", {
                    rules: [
                      {
                        type: "email",
                        message: <IntlMessages id ="admin.email.valid"></IntlMessages>
                      },

                      {
                        validator: this.handleEmailExistance
                      }
                    ],
                    onChange: e => {
                      this.props.onchange(e);
                    },
                    value: this.props.data.emergencyEmail,
                    initialValue: this.props.emergencyEmail === null ? "" : this.props.emergencyEmail,
                  })(<Input  />)}
                </Form.Item>

              </Col>
            </Row>
            <Row gutter={24}>





              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Form.Item
                  {...formItemLayout}
                  label={<IntlMessages id="admin.primaryaddress.phonenumber" />}
                >
                  {getFieldDecorator("emergency_primary_phone_number", {
                    rules: [
                      {
                        validator: this.handleContactExistance
                      }
                      // {
                      //   pattern: new RegExp(/^[0-9']+$/),
                      //   message: 'Please enter a valid phone number!'
                      // },
                      // {
                      //   max: 12,
                      //   message: "Please enter a valid phone number!"
                      // },
                      // {
                      //   min: 8,
                      //   message: "Please enter a valid phone number!"
                      // }
                    ],
                    onChange: e => {
                      this.props.onchange(e);
                    },
                    value: this.props.data.emergency_primary_phone_number,
                    initialValue: this.props.emergency_primary_phone_number === null ? "" : this.props.emergency_primary_phone_number,
                  })(<Input  maxLength={15} />)}
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>

                <Form.Item
                  {...formItemLayout}
                  label={<IntlMessages id="admin.userlisting.relationshiptype" />}
                >
                  {getFieldDecorator("emergency_relationship", {

                    onChange: e => {
                      this.props.onchange(e);
                    },
                    value: this.props.data.emergency_relationship,
                    initialValue: this.props.emergency_relationship === null ? "" : this.props.emergency_relationship,
                  })(<Input  />)}
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>

              </Col>
            </Row>
            <Button style={{ marginRight: 8 }} onClick={() => this.backpage()}>
              Previous
          </Button> <Button type="primary" onClick={() => this.handleSubmit()}>
              Next
          </Button>
          </Form>
        </Spin>
      </div>
    );
  }
}
TravelManager.propTypes = {
  validateTravellerEmail: PropTypes.func,
};
function mapStateToProps(state) {
  return { ...state.user };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...OrgActions, ...userActions }, dispatch);
}
const WrappedForm = Form.create({ name: "travel-manager" })(TravelManager);
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    WrappedForm
  )
);
