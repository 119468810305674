import React from "react"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import * as orderActions from "../../../redux/actions/order-actions";
import * as b2cActions from "../../../redux/actions/b2c-actions";
import IntlMessages from "../../../services/intlMesseges"
import config from "../../../../../config";
import { notifyUser } from "../../../services/notification-service";

import { Typography, Form, Input, Button, Row, Col, Spin, DatePicker, Switch, Select } from "antd";
import moment from "moment"
const { Option } = Select;
const dateFormat = "YYYY-MM-DD"
const dateFormatY = "YYYY-MM-DD"
class EditOrder extends React.Component {
  state = {
    loading: false,
    order: {},
    confirmDirty: false,
    productId: 0,
    companyId: 0,
    orderNumber: '',
    orderSubmitterUserId: 0,
    quantity: 0,
    expiryDate: '',
    referrerCode: '',
    couponId: 0,
    isActive: true,
    totalOrderAmount: 0,
    transactionId: '',
    insertDate: '',
    insertUserId: 0,
    updateDate: '',
    updateUserId: 0,
    savedPlanCountriesList: [],
    countryDropDownData: [],
    countrystate: [],
    usCountryData: [],
    travelCountriesId: [],
    travelStates: []
  };

  async componentDidMount() {
    await this.props.getProductPricingAndInfo();

    if (
      this.props.match &&
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.id &&
      this.props.match.params.id !== 'new'
    ) {
      this.setState({ loading: true, id: this.props.match.params.id });
      const orderResponse = await this.props.getOrder(this.props.match.params.id);
      this.setState({ order: orderResponse.data });
      //console.log(this.state.order, this.state.order.travelersInfo[0].country);
      this.setState({ loading: false });
    }

    const usCountryData = this.props.countryTierInfo.filter((country) => {
      return country.countryCode == 'US';
    });

    //Travelling countries
    const travelCountries = this.state.order.membershipCountries.map((country) => {
      if (country.placeType == 1 && this.state.order.destinationType != 'Domestic') {
        return country.countryId;
      } else {
        return usCountryData && usCountryData[0].countryId;
      }
    });

    const travelStates = this.state.order.membershipCountries
      .filter((country) => country.placeType === 2)
      .map((country) => country.countryId);

    //filterd countries
    const filteredCountries = this.props.countryTierInfo.filter((country) => {
      return country.tierId >= this.state.order.tierID;
    });

    //filtered states
    const filteredStates = this.props.stateTierInfo;

    //     debugger
    //     var riskTypeId = [];
    //       travelStates.map((travelState) => {
    //         debugger
    //         this.props.stateTierInfo.map((state, index) => {
    //           if (travelState === state.stateId) {
    //             if (state && state.stateRiskZones && state.stateRiskZones.length > 0) {
    //               state.stateRiskZones.map((stateRiskZone, index) => {
    //                 if (
    //                   new Date(this.state.order.CoverageStartDate).getTime() <= new Date(stateRiskZone.toDate).getTime() &&
    //                   new Date(this.state.order.CoverageStartDate).getTime() >= new Date(stateRiskZone.fromDate).getTime() &&
    //                   new Date(this.state.order.CoverageEndDate).getTime() <= new Date(stateRiskZone.toDate).getTime() &&
    //                   new Date(this.state.order.CoverageEndDate).getTime() >= new Date(stateRiskZone.fromDate).getTime()
    //                 ) {
    //                   riskTypeId.push(stateRiskZone.stateRiskTypeId);
    //                 } else {
    //                   riskTypeId.push(1);
    //                 }
    //               });
    //             }
    //           } else {
    //             riskTypeId.push(1);
    //           }
    //         });
    //       });

    // console.log(riskTypeId)   ;

    this.setState({ countrystate: filteredStates });

    this.setState({
      countryDropDownData: filteredCountries,
      travelCountriesId: [...new Set(travelCountries)],
      usCountryData: usCountryData,
      travelStates: travelStates
    });
  }

  submitFormAdd = (data) => {
    this.props
      .addOrder(data)
      .then((item) => {
        this.setState({ loading: false });
        this.props.history.push('./');
      })
      .catch((err) => {
        console.log(err);
        this.setState({ loading: false });
      });
  };

  submitFormEdit = (data) => {
    if (data.CoverageEndDate.isBefore(data.CoverageStartDate)) {
      notifyUser('Invalid Start/ End Date.', 'error');
      this.setState({ loading: false });
      return false;
    }
    
    data.CoverageStartDate = moment(data.CoverageStartDate).format('YYYY-MM-DDT00:00:00');
    data.CoverageEndDate = moment(data.CoverageEndDate).format('YYYY-MM-DDT00:00:00');
    this.props
      .updateOrder(data)
      .then((res) => {        
      if (res.success && res.response.data) {
        this.setState({
          order: {
            ...this.state.order,
            days: (moment(data.CoverageEndDate).diff(moment(data.CoverageStartDate), 'days') + 1)
          }
        });
      }
      else {
        notifyUser(res.response.error.message, 'error');
      }

        this.setState({ loading: false });
      })
      .catch((err) => {
        //   notifyUser("Error Adding Order", 'error');
        this.setState({ loading: false });
      });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        Object.keys(values).map(function(key, _idx) {
          if (values[key] == null) {
            values[key] = '';
          } else {
            if (typeof values[key] == 'string') {
              values[key] = values[key].trim();
            }
          }
          return values;
        });
        this.setState({ loading: true });
        if (this.state.id && this.state.id > 0) {
          values['id'] = this.state.id;
          this.submitFormEdit(values);
        } else {
          this.submitFormAdd(values);
        }
      }
    });
  };

  onIsReceiveTravelAlertsChange = (checked) => {
    this.setState({ order: { ...this.state.order, isReceiveTravelAlerts: checked } });
  };

  onCoverageStartDateChange = (date, dateString) => {
    console.log(date, dateString, 1);
  };
  disabledDate = (current) => {
    const startDate = moment(this.state.order.coverageStartDate);
    const endDate = startDate.clone().add(this.state.order.maxDays - 1, 'days'); // Subtract 1 day from maxDays to get the inclusive end date
    // return current && (current < moment().startOf('day') || current < startDate || current > endDate.endOf('day'));
      return current && (current < startDate || current > endDate.endOf('day'));

  };
  setCoverageStartDate = (_obj, dateString) => {
    this.setState({
      order: {
        ...this.state.order,
        coverageStartDate: moment(_obj)
          .add(1, 'days')
          .format(dateFormatY),
          
      }
    });
  };

  setCoverageEndDate = (_obj, dateString) => {
    this.setState({
      order: {
        ...this.state.order,
        coverageEndDate: moment(_obj)
          .add(1, 'days')
          .format(dateFormatY),
      }
    });
  };

  // _bindCountries() {
  //   return this.props.countries.map((country, index) => (
  //     <Option key={"con" + index} value={country.countryId}>
  //       {country.countryName}
  //     </Option>
  //   ));
  // }

  render() {
    const { getFieldDecorator } = this.props.form;
    const { formLayout } = this.state;
    const formItemLayout =
      formLayout === 'horizontal'
        ? {
            labelCol: { span: 4 },
            wrapperCol: { span: 14 }
          }
        : null;

    return (
      <div>
        <Typography.Title level={3}>
          {this.state.id === 0 ? (
            <IntlMessages id='admin.manageOrder.addOrder' />
          ) : (
            <div>
              <IntlMessages id='admin.manageOrder.editOrder' /> #{this.state.order.orderID}
            </div>
          )}
        </Typography.Title>
        <div>
          <Spin spinning={this.state.loading}>
            <Row gutter={24}>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <p>
                  Order Number : <b>{this.state.order && this.state.order.orderNumber}</b>
                </p>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <p>
                  Total Order Amount : <b>{this.state.order && this.state.order.totalOrderAmount}</b>
                </p>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <p>
                  Total Order Retail Amount : <b>{this.state.order && this.state.order.totalOrderRetailAmount}</b>
                </p>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <p>
                  Days : <b>{this.state.order && this.state.order.days}</b>
                </p>
              </Col>
            </Row>
            <hr />
            <br />
            {/* <div></div> */}
            <Form layout={formLayout} onSubmit={this.handleSubmit}>
              <Row gutter={24}>
                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                  <Form.Item {...formItemLayout} label='Coverage Start Date'>
                    {getFieldDecorator('CoverageStartDate', {
                      rules: [
                        {
                          required: true,
                          message: <IntlMessages id='admin.input.required' />
                        }
                      ],
                      initialValue:
                        this.state.order.coverageStartDate != null
                          ? moment(this.state.order.coverageStartDate, dateFormat)
                          : ''
                    })(
                      <DatePicker
                        placeholder='-select-'
                        format={dateFormat}
                        onChange={this.setCoverageStartDate}
                        //showTime={{ defaultValue: moment("00:00:00", "HH:mm:ss") }}
                        showTime={false}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                  <Form.Item {...formItemLayout} label='Coverage End Date'>
                    {getFieldDecorator('CoverageEndDate', {
                      rules: [
                        {
                          required: true,
                          message: <IntlMessages id='admin.input.required' />
                        }
                      ],
                      initialValue:
                        this.state.order.coverageEndDate != null
                          ? moment(this.state.order.coverageEndDate, dateFormat)
                          : ''
                    })(
                      <DatePicker
                        placeholder='-select-'
                        format={dateFormat}
                        disabledDate={this.disabledDate}
                        showTime={false}
                        onChange={this.setCoverageEndDate}
                        // showTime={{ defaultValue: moment("00:00:00", "HH:mm:ss") }}
                      />
                    )}
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                  <Form.Item {...formItemLayout} label={<IntlMessages id='admin.report.country' />}>
                    {getFieldDecorator('Countries', {
                      initialValue: this.state.travelCountriesId
                    })(
                      <Select
                        showSearch
                        filterOption={(input, option) =>
                          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        mode='multiple'
                      >
                        {this.state.order.destinationType == 'Domestic'
                          ? this.state.usCountryData.map(function(item) {
                              return (
                                <Option key={item.countryId} value={item.countryId}>
                                  {item.countryName}
                                </Option>
                              );
                            })
                          : this.state.countryDropDownData.map(function(item) {
                              return (
                                <Option key={item.countryId} value={item.countryId}>
                                  {item.countryName}
                                </Option>
                              );
                            })}
                        {}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                {this.state.order.destinationType == 'Domestic' ? (
                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Form.Item {...formItemLayout} label={<IntlMessages id='Please Select State' />}>
                      {getFieldDecorator('States', {
                        initialValue: this.state.travelStates
                      })(
                        <Select
                          showSearch
                          filterOption={(input, option) =>
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }
                          mode='multiple'
                        >
                          {this.state.countrystate.map(function(item) {
                            return (
                              <Option key={item.stateId} value={item.stateId}>
                                {item.stateName}
                              </Option>
                            );
                          })}
                        </Select>
                      )}
                    </Form.Item>
                  </Col>
                ) : (
                  ''
                )}

                {/* <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                  <Form.Item {...formItemLayout} label="Agent Code">
                    {getFieldDecorator("AgentCode", {
                      rules: [],
                      initialValue: this.state.order.agentCode === null ? "" : this.state.order.agentCode
                    })(<Input />)}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                  <Form.Item {...formItemLayout} label="Agent Tracking Number">
                    {getFieldDecorator("AgentTrackingNumber", {
                      rules: [],
                      initialValue:
                        this.state.order.agentTrackingNumber === null ? "" : this.state.order.agentTrackingNumber
                    })(<Input />)}
                  </Form.Item>
                </Col> */}
                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                  <Form.Item {...formItemLayout} label='Referrer Code'>
                    {getFieldDecorator('ReferrerCode', {
                      rules: [],
                      initialValue: this.state.order.referrerCode === null ? '' : this.state.order.referrerCode
                    })(<Input disabled />)}
                  </Form.Item>
                </Col>
                {/* <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                  <Form.Item {...formItemLayout} label="Commission Amount">
                    {getFieldDecorator("CommissionAmount", {
                      rules: [],
                      initialValue: this.state.order.commissionAmount === null ? "" : this.state.order.commissionAmount
                    })(<Input />)}
                  </Form.Item>
                </Col> */}
                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                  {/* <Form.Item {...formItemLayout} label="Coverage End Date">
                    {getFieldDecorator("coverageEndDate", {
                      rules: [],
                      initialValue:
                        this.state.order.coverageEndDate === null
                          ? ""
                          : moment(this.state.order.coverageEndDate, dateFormatY),
                    })(<Input />)}
                  </Form.Item> */}
                </Col>
                {/* <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                  <Form.Item {...formItemLayout} label="Receive Travel Alerts?">
                    {getFieldDecorator("IsReceiveTravelAlerts", {
                      // valuePropName: "checked",
                      initialValue:
                        this.state.order.isReceiveTravelAlerts === null ? false : this.state.order.isReceiveTravelAlerts
                    })(
                      <Switch
                        checkedChildren="Yes"
                        unCheckedChildren="No"
                        checked={this.state.order.isReceiveTravelAlerts}
                        onChange={this.onIsReceiveTravelAlertsChange}
                        name="isReceiveTravelAlerts"
                      />
                    )}
                  </Form.Item>
                </Col>  */}
              </Row>
              {/* <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Form.Item {...formItemLayout} label="Receive Travel Alerts?">
                  {getFieldDecorator("isReceiveTravelAlerts", {
                    valuePropName: "checked",
                    rules: [],
                    initialValue:
                      this.state.order.isReceiveTravelAlerts === null
                        ? false
                        : this.state.order.isReceiveTravelAlerts,
                  })(<Switch checkedChildren="True" unCheckedChildren="False" />)}
                </Form.Item>
              </Col> */}
              <Row gutter={24}>
                <Col>
                  <Form.Item>
                    <Button
                      type='primary'
                      style={{ display: 'inline-block', marginRight: '10px' }}
                      className='def-blue'
                      htmlType='submit'
                    >
                      {this.state.id === 0 ? 'Add' : 'Update'}
                    </Button>
                    <Button
                      type='primary'
                      className=''
                      htmlType='button'
                      onClick={() => this.props.history.push('../')}
                    >
                      Back
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Spin>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    ...state.b2c,

  }
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...orderActions, ...b2cActions,}, dispatch);
}
const WrappedForm = Form.create({ name: "edit-order" })(EditOrder)
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(WrappedForm)
)
