import React, { Component } from "react";
export default class UserHistory extends Component {
  render() {
    return (
      <section>
        <div className="wrap">
          <p>User History</p>
        </div>
      </section>
    );
  }
}
