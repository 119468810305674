import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import IntlMessages from "../../../services/intlMesseges";
import moment from "moment";
import PropTypes from "prop-types";
import {
  Form,
  DatePicker,
  Typography,
  Button,
  Row,
  Col,
  Input,
  Checkbox,
  Select,
  Switch,
  Table,
  Spin,
  TreeSelect,
  Tag,
  Icon,
  Tooltip,
} from "antd";
import { EditCell, ViewCell } from "../../shared/components/helperCells";
import TextArea from "antd/lib/input/TextArea";
import * as geoFenceAction from "../../../redux/actions/geofence-action";
import * as OrgActions from "../../../redux/actions/organization-actions";
import * as travellerActions from "../../../redux/actions/travellers-actions";
import * as userActions from "../../../redux/actions/user-actions";
import * as alertTypeActions from "../../../redux/actions/alert-types-actions";
import * as AssetTrackingActions from "../../../redux/actions/asset-tracking-action";
import { notifyUser } from "../../../services/notification-service";
import * as Permission from "../../../services/permissions";
import * as UserService from "../../../services/user-service";
const { RangePicker, MonthPicker } = DatePicker;
const { Option } = Select;
const dateFormat = "MM/DD/YYYY HH:mm:00";
const dateFormathh = "YYYY-MM-DD HH:mm:ss";
function range(start, end) {
  const result = [];
  for (let i = start; i < end; i++) {
    result.push(i);
  }
  return result;
}
function disabledDate(current) {
  // Can not select days before today and today
  return (
    current &&
    current <
      moment()
        .endOf("day")
        .subtract(1, "days")
  );
}
class AssetTrackngRules extends React.Component {
  constructor(props) {
    super(props);
    this.textInput = React.createRef();
    this.state = {
      pagination: {
        pageSize: 10,
        current: 1,
        hideOnSinglePage: true,
        showTotal: (total, range) => {
          return (
            <span>
              <IntlMessages id="admin.text.showing" /> {range[0]}-{range[1]}{" "}
              <IntlMessages id="admin.text.of" /> {total}{" "}
              <IntlMessages id="admin.text.results" />
            </span>
          );
        },
      },
      filters: {},
      data: [],
      targetOrganizations: [],
      loading: false,
      isShowRulesFrom: false,
      isShowRulesList: true,
      geofencingId: 0,
      fetching: false,
      orgData: [],
      travellerData: [],
      alertTypes: [],
      isDisable: false,
      checkedcommunicatonModes: [],
      defaultSelectedOrgnizations: [],
      defaultSelectedTravellers: [],
      parentOrgAllSelected: false,
      isBothAlertChecked: false,
      hidefields: false,
      stateuser: 1,
      loader: false,

      insider: false,
      outsider: false,
      enterr: false,
      exitr: false,
      instantr: false,
      assignedUsers: [],
      divisionList: [],
      travelerdata: [],
      stateuser2: 1,
      disableinstantcase: false,
      disableNonInstantcase: false,
      assignedDivisionsAuto: false,
      showadd: true,
      radiusdata: [],
    };
    this.instantchange = this.instantchange.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.getOrganizationinfo = this.getOrganizationinfo.bind(this);
    this.loadDivisions = this.loadDivisions.bind(this);
    this.treeloadforParentOrganization = this.treeloadforParentOrganization.bind(
      this
    );
    this.onChange = this.onChange.bind(this);
    this.disabledRangeTime = this.disabledRangeTime.bind(this);
  }
  onChange(checked) {
    this.setState({ selfOpted: checked });
  }
  getHeaderKeys = () => {
    let currentUser = UserService.getUser();
    return [
      {
        title: <IntlMessages id="admin.geofencing.ruleName" />,
        dataIndex: "alertTitle",
      },
      {
        title: "Created By",
        render: (text, record) => <span>{record.createdBy}</span>,
      },
      {
        title: <IntlMessages id="admin.geofencing.startDate" />,
        render: (text, record) => (
          <span>
            {record.startTime
              ? moment(record.startTime).format(dateFormat)
              : ""}
          </span>
        ),
      },
      {
        title: <IntlMessages id="admin.geofencing.endDate" />,
        render: (text, record) => (
          <span>
            {record.endTime ? moment(record.endTime).format(dateFormat) : ""}
          </span>
        ),
      },
      {
        title: <IntlMessages id="admin.geofencing.ruleType" />,
        render: (text, record) => (
          <span>{record.isInstant ? "Instant" : "Scheduled"}</span>
        ),
      },
      {
        title: <IntlMessages id="admin.manageOrders.Status" />,
        dataIndex: "isRevoke",
        key: "isRevoke",
        //width: "10%;",
        render: (text, record) =>
          record.isActive === false ? (
            <Tag color="red">
              <IntlMessages id="admin.traveler.inactive" />
            </Tag>
          ) : moment(record.endTime) > moment(new Date()) ? (
            <Tag color="green">
              <IntlMessages id="admin.traveler.active" />
            </Tag>
          ) : (
            <Tag color="red">
              <IntlMessages id="admin.traveler.inactive" />
            </Tag>
          ),
      },
      {
        title: <IntlMessages id="admin.userlisting.action" />,
        rowKey: "action",
        render: (text, record) => (
          <span>
            {record.insertUserId === currentUser.userID ? (
              <EditCell
                onEditCell={() => {
                  this.editItem(record.id, currentUser.userID);
                }}
              />
            ) : (
              <ViewCell
                onViewCell={() => {
                  this.editItem(record.id, currentUser.userID);
                }}
              />
            )}
          </span>
        ),
      },
    ];
  };
  isEnterchange = (e) => {
    this.setState({ enterr: e.target.checked });
    this.props.form.setFieldsValue({ enterMessage: "" });
  };
  isExitchange = (e) => {
    this.setState({ exitr: e.target.checked });
    this.props.form.setFieldsValue({ exitMessage: "" });
  };
  isWithinRadiuschange = (e) => {
    this.setState({ insider: e.target.checked });
    this.props.form.setFieldsValue({ instantEnterMessage: "" });
  };
  isOutsideRadiuschange = (e) => {
    this.setState({ outsider: e.target.checked });
    this.props.form.setFieldsValue({ instantExitMessage: "" });
  };

  instantchange(e) {
    if (e && e.target) {
      var _geoFenceRule = this.state.geoFenceRule;
      _geoFenceRule.isInstant = e.target.checked;
      this.setState({
        hidefields: e.target.checked,
        instantr: e.target.checked,
        geoFenceRule: _geoFenceRule,
      });
    }
  }

  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;

    this.setState({ loading: true });
    this.props
      .getAlertTypes({
        filters: filters,
        pagination: { page: pagination.current, pageSize: pagination.pageSize },
        sorter: sorter,
      })
      .then((resp) => {
        pager.total = resp.paging.totalRecords;

        this.setState({
          loading: false,
          data: resp.data,
          pagination: pager,
        });
      })
      .catch((ex) => {
        this.setState({ loading: false });
      });
  };
  async componentDidMount() {
    await this.props.getUserData();
    this.getGeoFenceRules(this.props.match.params.id);
    this.handleTableChange({ current: 1, pageSize: 10 }, {}, {});

    // this.loadDivisions(this.props.match.params.orgid);
    this.props
      .getUserListing({
        orgId: this.props.match.params.orgid,
        filters: { isActiveMembership: true },
      })
      .then((data) => {
        this.setState({ travellerinfo: data.data });
      });
    this.props
      .getActiveInternalUsers({
        orgId: this.props.match.params.orgid,
        filters: {},
      })
      .then((data) => {
        this.setState({ activeUsers: data.data });
      });
    this.setState({ geofencingId: this.props.match.params.id });
    this.getOrganizationinfo({});
    this.getAlltravellers({ filters: { isActiveMembership: true } });
    var dat = [];
    for (var i = 2; i <= 60; i++) {
      dat.push(50 * i);
    }
    this.setState({ radiusdata: dat });
    this.props.getAssetTrackingById(this.props.match.params.id).then((resp) => {
      if (resp.data && this.props.userData) {
        this.setState({ UserID: resp.data.insertUserId });
        if (resp.data.insertUserId !== this.props.userData.userID) {
          this.setState({ expired: true });
        }
      }
    });
  }
  getAlltravellers(params, check) {
    this.props.getTravellersListing(params).then((data) => {
      let travellerinfo = [];
      if (Permission.isRole(["Administrator", "CSR"])) {
        travellerinfo = [{ userId: "all", firstName: "All", lastName: "" }];
      }
      if (data.data) {
        this.setState({ travelerdata: travellerinfo.concat(data.data) });
      }
    });
  }
  fetchTravellers = (value) => {
    this.setState({ fetching: true });
    let travellerinfo = [];
    if (Permission.isRole(["Administrator", "CSR"])) {
      travellerinfo = [{ userId: "all", firstName: "All", lastName: "" }];
    }
    this.props
      .getTravellersListing({
        orgId: 1,
        filters: { firstName: value },
        pagination: { page: 1, pageSize: 10 },
        sorter: {},
      })
      .then((resp) => {
        this.setState({
          fetching: false,
          travelerdata: travellerinfo.concat(resp.data),
        });
      })
      .catch((ex) => {
        this.setState({ fetching: false });
      });
    //this.setState({ orgData: [], fetching: true });
  };
  fetchTraveler = (value) => {
    this.props
      .getUserListing({
        orgId: this.props.match.params.orgid,
        filters: { firstName: value, isActiveMembership: true },
        pagination: { page: 1, pageSize: 10 },
        sorter: {},
      })
      .then((data) => {
        this.setState({ travellerinfo: data.data });
      });
  };
  fetchActiveUsers = (value) => {
    this.props
      .getActiveInternalUsers({
        orgId: this.props.match.params.orgid,
        filters: { firstName: value },
        pagination: { page: 1, pageSize: 10 },
        sorter: {},
      })
      .then((data) => {
        this.setState({ activeUsers: data.data });
      });
  };
  getOthertravelerData = (e) => {
    if (e.target.scrollTop + e.target.offsetHeight >= e.target.scrollHeight) {
      var datas = this.state.stateuser2 + 1;
      this.props
        .getTravellersListing({
          orgId: 1,
          filters: {},
          pagination: { page: datas, pageSize: 10 },
          sorter: {},
        })
        .then((resp) => {
          let data = this.state.travelerdata;
          this.setState({ stateuser2: datas });
          this.setState({
            travelerdata: data.concat(resp.data),
          });
        })
        .catch((ex) => {
          this.setState({ loading: false });
        });
    }
  };
  loadOtherOrganizations = (e) => {
    if (e.target.scrollTop + e.target.offsetHeight >= e.target.scrollHeight) {
      var datas = this.state.stateuser + 1;
      this.getOrganizationinfo(
        {
          filters: {},
          pagination: { page: datas, pageSize: 10 },
        },
        true
      );
      this.setState({ stateuser: datas });
    }
  };
  getOrganizationinfo(params, loadother) {
    this.props.getOrganizations(params).then((data) => {
      let orgData = [{ organizationId: 0, name: "All" }];
      if (loadother === true) {
        let _parentOrg = [];
        if (data.data.length > 0) {
          _parentOrg = this.state.parentOrganization;

          this.setState({ parentOrganization: _parentOrg.concat(data.data) });
        }
      } else {
        this.setState({ parentOrganization: orgData.concat(data.data) });
      }
    });
  }
  editItem = (id, userid) => {
    this.props.getGeoFenceRule(id).then((resp) => {
      if (resp.data)
        if (resp.data.insertUserId !== userid) {
          this.setState({
            userrestricted: true,
          });
        } else {
          this.setState({
            userrestricted: false,
          });
        }
      this.getSelectedOrgAndTravllers(resp.data.alertRecipients);
      this.getSelectedOrgAndTravllersOther(resp.data.targetRecipients);
      this.setState({
        instantr: resp.data.isInstant,
        insider: resp.data.isWithinRadius,
        outsider: resp.data.isOutsideRadius,
        enterr: resp.data.isEnter,
        exitr: resp.data.isExit,
        geoFenceRule: resp.data,
        isShowRulesFrom: true,
        isShowRulesList: false,
        hidefields: resp.data.isInstant,
        showadd: false,
      });
      if (resp.data.parentOrganizationId !== 0) {
        this.setState({
          parentOrganizationId: {
            key: resp.data.parentOrganizationId,
            label: resp.data.parentOrganizationName,
          },
        });
      } else {
        if (
          resp.data.alertRecipients.filter(
            (data) => data.recipientOrgId === 0 && data.recipientUserId === 0
          ).length > 0 ||
          resp.data.alertRecipients.filter(
            (data) => data.recipientOrgId === 0 && data.recipientUserId === -1
          ).length > 0
        ) {
          this.setState({
            parentOrganizationId: { key: 0, label: "All" },
          });
          this.props.form.setFieldsValue({
            parent_orgnization: { key: "all", label: "All" },
          });
        } else {
          this.setState({
            parentOrganizationId: undefined,
          });
        }
      }
      if (this.props.userData.role === "Administrator") {
        this.loadDivisions(resp.data.parentOrganizationId);
      } else {
        this.loadDivisions(this.props.match.params.orgid);
      }
      if (resp.data.isInstant) {
        this.setState({
          disableinstantcase: true,
          disableNonInstantcase: false,
        });
      } else {
        this.setState({
          disableNonInstantcase: true,
          disableinstantcase: false,
        });
      }
      if (resp.data && resp.data.alertRecipients.length > 0) {
        var _dataRecep = [];
        for (var i = 0; i < resp.data.alertRecipients.length; i++) {
          if (
            resp.data.alertRecipients[i].recipientUserId === 0 &&
            resp.data.alertRecipients[i].recipientOrgId !== 0 &&
            resp.data.alertRecipients[i].recipientOrgId !== -1
          ) {
            //if (resp.data.alertRecipients[i].recipientOrgId !== parseInt(this.props.match.params.orgid)) {
            _dataRecep.push({
              label: resp.data.alertRecipients[i].organizationName,
              value: resp.data.alertRecipients[i].recipientOrgId,
              key: resp.data.alertRecipients[i].recipientOrgId,
            });
            //}
          }
        }
        this.setState({ multipleOrganizations: _dataRecep });
      }
    });
  };
  addItem = () => {
    this.setState({
      isShowRulesFrom: true,
      isShowRulesList: false,
      geoFenceRule: [],
      hidefields: false,
      disableNonInstantcase: false,
      disableinstantcase: false,
      orgnization: undefined,
      traveller: undefined,
      parent_orgnization: undefined,
      multipleOrganizations: undefined,
      parentOrganizationId: undefined,
      travellerdata: undefined,
      isBothAlertChecked: false,
      defaultSelectedTravellers: [],
      insider: false,
      outsider: false,
      enterr: false,
      exitr: false,
      instantr: false,
      defaultSelectedOtherTravellers: [],
      showadd: true,
      isDisable: false,
      expired: false,
      defaultSelectedOrgnizations: undefined,
      userrestricted: false,
    });
    if (
      this.props.userData &&
      this.props.userData.role.indexOf("Administrator", "CSR") > -1
    ) {
    } else {
      this.loadDivisions(this.props.match.params.orgid);
    }
  };
  handleSubmit = (e) => {
    e.preventDefault();

    this.props.form.validateFieldsAndScroll((err, values) => {
      var _target = [];
      _target = this.state.defaultSelectedOtherTravellers;
      if (!err) {
        if (
          this.state.instantr === true
            ? this.state.insider === true || this.state.outsider === true
            : this.state.instantr === false
            ? this.state.insider === true ||
              this.state.outsider === true ||
              this.state.enterr === true ||
              this.state.exitr === true
            : ""
        ) {
          Object.keys(values).map(function(key, _idx) {
            if (values[key] == null) {
              values[key] = "";
            } else {
              if (typeof values[key] == "string") {
                values[key] = values[key].trim();
              }
            }
          });

          var finalTargetRecipientsArray = [];
          this.setState({ loading: true });
          values.interstedGeoId = this.state.geofencingId;
          if (_target && _target.length > 0) {
            let allExists = _target.findIndex((i) => i.userId === "all");
            if (allExists > -1) {
              finalTargetRecipientsArray.push({
                recipientOrgId: parseInt(this.props.match.params.orgid),
                recipientUserId: 0,
              });
            } else {
              _target.map(function(el) {
                if (
                  !finalTargetRecipientsArray
                    .map(function(data) {
                      return data.companyId;
                    })
                    .includes(el.companyId)
                ) {
                  finalTargetRecipientsArray.push({
                    recipientOrgId: el.companyId,
                    recipientUserId: el.userId,
                  });
                }
              });
            }
          }

          values.parent_orgnization =
            values.parent_orgnization == ""
              ? undefined
              : values.parent_orgnization;
          var alertRecipientsArr = [];
          if (values.bothAlertRecipients === true) {
            alertRecipientsArr.push({ recipientOrgId: 0, recipientUserId: 0 });
          } else {
            if (
              values.parent_orgnization &&
              values.parent_orgnization.key !== undefined &&
              (values.parent_orgnization.key === "all" ||
                values.parent_orgnization.key === 0)
            ) {
              if (
                this.props.userData &&
                (this.props.userData.role === "Administrator" ||
                  this.props.userData.role === "CSR")
              ) {
                if (values.travellerdata && values.travellerdata.length > 0) {
                  let allExists = values.travellerdata.findIndex(
                    (e) => e.key === "all"
                  );
                  if (allExists > -1) {
                    alertRecipientsArr.push({
                      recipientOrgId: 0,
                      recipientUserId: 0,
                    });
                  } else {
                    alertRecipientsArr.push({
                      recipientOrgId: 0,
                      recipientUserId: -1,
                    });
                    this.state.defaultSelectedTravellers.map((row) =>
                      alertRecipientsArr.push({
                        recipientOrgId: row.companyId,
                        recipientUserId: row.userId,
                      })
                    );
                  }
                } else {
                  if (values.traveller && values.traveller.length > 0) {
                    let allExists = values.traveller.findIndex(
                      (e) => e.key === "all"
                    );
                    if (allExists > -1) {
                      alertRecipientsArr.push({
                        recipientOrgId: 0,
                        recipientUserId: 0,
                      });
                    } else {
                      alertRecipientsArr.push({
                        recipientOrgId: 0,
                        recipientUserId: -1,
                      });
                      this.state.defaultSelectedTravellers.map((row) =>
                        alertRecipientsArr.push({
                          recipientOrgId: row.companyId,
                          recipientUserId: row.userId,
                        })
                      );
                    }
                  } else {
                    if (
                      values.parent_orgnization.key === "all" ||
                      values.parent_orgnization.key === 0
                    ) {
                      alertRecipientsArr.push({
                        recipientOrgId: 0,
                        recipientUserId: -1,
                      });
                    }
                  }
                }
              } else {
                alertRecipientsArr.push({
                  recipientOrgId: 0,
                  recipientUserId: -1,
                });
              }
            } else if (
              values.parent_orgnization &&
              values.parent_orgnization.key &&
              values.parent_orgnization.key > 0
            ) {
              if (
                values.multipleOrganizations !== undefined &&
                values.multipleOrganizations.length > 0
              ) {
                values.multipleOrganizations.map((row1) =>
                  alertRecipientsArr.push({
                    recipientOrgId: row1.value,
                    recipientUserId: 0,
                  })
                );
              }
              if (
                this.props.userData &&
                (this.props.userData.role === "Administrator" ||
                  this.props.userData.role === "CSR")
              ) {
                if (values.travellerdata && values.travellerdata.length > 0) {
                  let allExists = values.travellerdata.findIndex(
                    (e) => e.key === "all"
                  );
                  if (allExists > -1) {
                    alertRecipientsArr.push({
                      recipientOrgId: -1,
                      recipientUserId: 0,
                    });
                  } else {
                    this.state.defaultSelectedTravellers.map((row) =>
                      alertRecipientsArr.push({
                        recipientOrgId: row.companyId,
                        recipientUserId: row.userId,
                      })
                    );
                  }
                }
              } else {
                if (values.traveller && values.traveller.length > 0) {
                  let allExists = values.traveller.findIndex(
                    (e) => e.key === "all"
                  );
                  if (allExists > -1) {
                    alertRecipientsArr.push({
                      recipientOrgId: -1,
                      recipientUserId: 0,
                    });
                  } else {
                    this.state.defaultSelectedTravellers.map((row) =>
                      alertRecipientsArr.push({
                        recipientOrgId: row.companyId,
                        recipientUserId: row.userId,
                      })
                    );
                  }
                }
              }
            } else {
              if (
                this.props.userData &&
                (this.props.userData.role === "Administrator" ||
                  this.props.userData.role === "CSR")
              ) {
                if (values.travellerdata && values.travellerdata.length > 0) {
                  let allExists = values.travellerdata.findIndex(
                    (e) => e.key === "all"
                  );
                  if (allExists > -1) {
                    alertRecipientsArr.push({
                      recipientOrgId: -1,
                      recipientUserId: 0,
                    });
                  } else {
                    this.state.defaultSelectedTravellers.map((row) =>
                      alertRecipientsArr.push({
                        recipientOrgId: row.companyId,
                        recipientUserId: row.userId,
                      })
                    );
                  }
                }
              } else {
                if (
                  values.multipleOrganizations !== undefined &&
                  values.multipleOrganizations.length > 0
                ) {
                  values.multipleOrganizations.map((row1) =>
                    alertRecipientsArr.push({
                      recipientOrgId: row1.value,
                      recipientUserId: 0,
                    })
                  );
                }
                if (values.traveller && values.traveller.length > 0) {
                  let allExists = values.traveller.findIndex(
                    (e) => e.key === "all"
                  );
                  if (allExists > -1) {
                    alertRecipientsArr.push({
                      recipientOrgId: -1,
                      recipientUserId: 0,
                    });
                  } else {
                    this.state.defaultSelectedTravellers.map((row) =>
                      alertRecipientsArr.push({
                        recipientOrgId: row.companyId,
                        recipientUserId: row.userId,
                      })
                    );
                  }
                }
              }
            }
          }

          /* if (!this.props.form.getFieldValue("startTime")) {
            values["startTime"] = new Date;
          }
          if (!this.props.form.getFieldValue("endTime")) {
            values["endTime"] = new Date;
          } */
          let _arrayALert = [];

          if (
            finalTargetRecipientsArray &&
            finalTargetRecipientsArray.length > 0
          ) {
            _arrayALert = this.state.defaultSelectedOtherTravellers.concat(
              finalTargetRecipientsArray
            );
          } else {
            _arrayALert = this.state.defaultSelectedOtherTravellers;
          }
          values["alertRecipients"] = alertRecipientsArr;
          values["radius"] = values.radiuss;
          values["isInstant"] = this.state.instantr;
          values["isWithinRadius"] = this.state.insider;
          values["isOutsideRadius"] = this.state.outsider;
          values["isEnter"] = this.state.enterr;
          values["isExit"] = this.state.exitr;
          values["selfOpted"] = this.state.selfOpted;
          values["targetRecipients"] = _arrayALert;
          if (!this.props.form.getFieldValue("startEndTime")) {
            values["startTime"] = new Date();
            values["endTime"] = new Date();
          } else {
            values["startTime"] = moment(values.startEndTime[0]).format(
              dateFormathh
            );
            values["endTime"] = moment(values.startEndTime[1]).format(
              dateFormathh
            );
          }

          let hasErrors = false;
          if (
            this.props.userData &&
            (this.props.userData.role === "Administrator" ||
              this.props.userData.role === "CSR")
          ) {
            if (
              values["alertRecipients"] &&
              values["alertRecipients"].length > 0
            ) {
              hasErrors = false;
            } else {
              hasErrors = true;
            }
          } else {
            if (
              values["alertRecipients"] &&
              values["alertRecipients"].length > 0
            ) {
              hasErrors = false;
            } else {
              hasErrors = true;
            }
          }
          if (hasErrors === false) {
            if (this.state.geoFenceRule.id && this.state.geoFenceRule.id > 0) {
              values["id"] = this.state.geoFenceRule.id;
              values["isActive"] = this.state.geoFenceRule.isActive;
              this.submitFormEdit(values);
            } else {
              values["isActive"] = true;
              this.submitFormAdd(values);
            }
          } else {
            notifyUser("Please select at least 1 target", "error");
          }
        } else {
          notifyUser("Please select at least 1 criteria", "error");
        }
      } else {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
      }
    });
  };
  loadDivisions = (orgId) => {
    this.props.getDivisionsList(orgId).then((resp) => {
      let parentId;
      for (var i in resp.data) {
        if (resp.data[i].organizationId == orgId) {
          parentId = resp.data[i].parentOrgId;
        }
      }

      let list = this.getNestedChildren(resp.data, parentId);
      this.setState({
        divisionList: list,
      });
    });
  };
  getNestedChildren = (arr, parent) => {
    var out = [];
    for (var i in arr) {
      if (arr[i].parentOrgId == parent) {
        var children = this.getNestedChildren(arr, arr[i].organizationId);
        if (children.length) {
          arr[i].children = children;
        }

        arr[i].title = arr[i].name;
        arr[i].value = arr[i].organizationId;
        arr[i].label = arr[i].name;
        arr[i].key = arr[i].organizationId;
        out.push(arr[i]);
      }
    }
    return out;
  };
  submitFormAdd = (data) => {
    this.props
      .addGeoFenceRule(data)
      .then((res) => {
        if (res.data.message === "InterstedGeo Alert added successfully.") {
          notifyUser("Rule has been added successfully.", "success");
          this.getGeoFenceRules(this.state.geofencingId);
          this.setState({
            loading: false,
            isShowRulesList: true,
            isShowRulesFrom: false,
          });
          this.props.form.resetFields();
        } else {
        }
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };

  submitFormEdit = (data) => {
    this.props
      .updateGeoFenceRule(data)
      .then((resp) => {
        if (resp.data.message === "InterstedGeo Alert updated successfully.") {
          notifyUser("Rule has been updated successfully.", "success");
          this.getGeoFenceRules(this.state.geofencingId);
          this.setState({
            loading: false,
            isShowRulesList: true,
            isShowRulesFrom: false,
          });
          this.props.form.resetFields();
        }
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };

  getGeoFenceRules(id) {
    var data = {
      filters: {},
      pagination: { page: 1, pageSize: 100 },
      sorter: {},
    };
    if (id !== "new") {
      this.props.getGeoFenceRulesByGeoFenceId(id, data).then((resp) => {
        if (resp.data) {
          this.setState({
            geoFenceRules: resp.data,
            loader: false,
          });
        }
      });
    } else {
      this.setState({
        loader: false,
      });
    }
  }

  getCheckedcommunicatonModes(modes) {
    var checkedModesArray = [];
    modes.map((m) => checkedModesArray.push(m.id));
    this.setState({ checkedcommunicatonModes: checkedModesArray });
  }

  getSelectedOrgAndTravllers(alertRecipients) {
    var selectedOrgnizationsArray = [];
    var selectedTravellersArray = [];
    if (alertRecipients.length > 0) {
      alertRecipients.map((r) => {
        if (r.recipientOrgId === 0 && r.recipientUserId === 0) {
          this.setState({
            isDisable: true,
            isBothAlertChecked: true,
          });
          selectedTravellersArray.push({
            key: "all",
            label: "All",
          });
        } else {
          if (r.recipientOrgId === 0 && r.recipientUserId === -1) {
            selectedOrgnizationsArray.push({
              key: "all",
              label: "All",
            });
          }
          if (
            r.recipientOrgId ===
              -1 /* parseInt(this.props.match.params.orgid) */ &&
            r.recipientUserId === 0
          ) {
            selectedTravellersArray.push({
              key: "all",
              label: "All",
            });
          }
          if (
            r.recipientOrgId !== 1 &&
            r.recipientOrgId !== parseInt(this.props.match.params.orgid) &&
            r.recipientUserId === 0
          ) {
            if (
              !selectedOrgnizationsArray
                .map((info) => {
                  return info.key;
                })
                .includes(r.recipientOrgId)
            ) {
              if (r.organizationName !== null) {
                selectedOrgnizationsArray.push({
                  value: r.recipientOrgId,
                  label: r.organizationName,
                });
              }
            }
          }
          if (r.recipientOrgId > 0 && r.recipientUserId !== 0) {
            if (
              !selectedTravellersArray
                .map((info) => {
                  return info.key;
                })
                .includes(r.recipientUserId)
            ) {
              selectedTravellersArray.push({
                key: r.recipientUserId,
                label: r.employeeName + " " + "<" + r.employeeEmailId + ">",
                companyId: r.recipientOrgId,
                userId: r.recipientUserId,
              });
            }
          }
          this.setState({
            isBothAlertChecked: false,
            isDisable: false,
          });
        }
      });
    }
    this.setState({
      defaultSelectedOrgnizations: selectedOrgnizationsArray,
      defaultSelectedTravellers: selectedTravellersArray,
    });
  }
  getSelectedOrgAndTravllersOther(alertRecipients) {
    var selectedTravellersArray = [];
    if (alertRecipients.length > 0) {
      alertRecipients.map((r) => {
        if (/* r.recipientOrgId > 0 &&  */ r.recipientUserId !== 0) {
          if (
            !selectedTravellersArray
              .map((info) => {
                return info.key;
              })
              .includes(r.recipientUserId)
          ) {
            selectedTravellersArray.push({
              key: r.recipientUserId,
              label: r.employeeName + " " + "<" + r.employeeEmailId + ">",
              companyId: r.recipientOrgId,
              userId: r.recipientUserId,
              recipientOrgId: r.recipientOrgId,
              recipientUserId: r.recipientUserId,
            });
          }
        }
      });
    }
    this.setState({
      defaultSelectedOtherTravellers: selectedTravellersArray,
    });
  }
  onAlertCheckboxChange = (e) => {
    if (e.target.checked) {
      this.props.form.setFieldsValue({
        orgnization: undefined,
        traveller: undefined,
        parent_orgnization: { key: "all", label: "All" },
        multipleOrganizations: undefined,
        travellerdata: [{ key: "all", firstName: "All", lastName: "" }],
      });
    } else {
      let _travelerData = this.props.form.getFieldValue("travellerdata");
      let isExists = _travelerData.findIndex((e) => e.key === "all");
      if (isExists > -1) {
        _travelerData.splice(isExists, 1);
      }
      this.props.form.setFieldsValue({
        parent_orgnization: undefined,
        travellerdata: _travelerData,
      });
    }
    this.setState({
      isDisable: e.target.checked,
      isBothAlertChecked: e.target.checked,
      parentOrgAllSelected: true,
    });
  };

  enterMassage = (rule, value, callback) => {
    const form = this.props.form;
    if (
      form.getFieldValue("isEnter") &&
      (typeof form.getFieldValue("enterMessage") === "undefined" ||
        form.getFieldValue("enterMessage") === "")
    ) {
      callback("Please fill enter message");
    } else {
      callback();
    }
  };

  exitMassage = (rule, value, callback) => {
    const form = this.props.form;
    if (
      form.getFieldValue("isExit") &&
      (typeof form.getFieldValue("exitMessage") === "undefined" ||
        form.getFieldValue("exitMessage") === "")
    ) {
      callback("Please fill exit message");
    } else {
      callback();
    }
  };

  validateOrgnization = (rule, value, callback) => {
    const form = this.props.form;
    if (
      form.getFieldValue("bothAlertRecipients") === false &&
      form.getFieldValue("orgnization") === "" &&
      form.getFieldValue("traveller") === ""
    ) {
      callback("Please Select Orgnization");
    } else {
      callback();
    }
  };

  validateTraveller = (rule, value, callback) => {
    const form = this.props.form;
    if (
      form.getFieldValue("bothAlertRecipients") === false &&
      form.getFieldValue("traveller") === "" &&
      form.getFieldValue("orgnization") === ""
    ) {
      callback("Please Select Traveller");
    } else {
      callback();
    }
  };

  loadotherUsers = (e) => {
    if (e.target.scrollTop + e.target.offsetHeight >= e.target.scrollHeight) {
      var datas = this.state.stateuser + 1;

      this.props
        .getUserListing({
          orgId: this.props.match.params.orgid,
          filters: { isActiveMembership: true },
          pagination: { page: datas, pageSize: 10 },
          sorter: {},
        })
        .then((resp) => {
          let data = this.state.travellerinfo;
          this.setState({ stateuser: datas });
          this.setState({
            travellerinfo: data.concat(resp.data),
          });
        })
        .catch((ex) => {
          this.setState({ loading: false });
        });
    }
  };
  loadOtherInactiveUsers = (e) => {
    if (e.target.scrollTop + e.target.offsetHeight >= e.target.scrollHeight) {
      var datas = this.state.stateuser + 1;

      this.props
        .getUserListing({
          orgId: this.props.match.params.orgid,
          filters: { isActiveMembership: true },
          pagination: { page: datas, pageSize: 10 },
          sorter: {},
        })
        .then((resp) => {
          let data = this.state.activeUsers;
          this.setState({ stateuser: datas });
          this.setState({
            activeUsers: data.concat(resp.data),
          });
        })
        .catch((ex) => {
          this.setState({ loading: false });
        });
    }
  };
  setAssignedDivisionsAuto = (e) => {
    this.setState({ assignedDivisionsAuto: e.target.checked });
  };
  onChangeAssignedOrganizations = (selected) => {
    let _selected = [];
    if (selected.length > 0) {
      selected.map(function(el) {
        _selected.push({
          branchId: el.value,
        });
      });
    }
    this.setState({ targetOrganizations: _selected });
  };

  onTravelersSelect = (_idx, option) => {
    let assignedUsersList = [];
    if (this.state.defaultSelectedTravellers) {
      assignedUsersList = this.state.defaultSelectedTravellers;
    }
    if (option.key == "all") {
      assignedUsersList.push({
        userId: 0,
        companyId: parseInt(this.props.match.params.orgid),
      });
    } else {
      assignedUsersList.push({
        userId: option.props.value,
        companyId: option.props.companyid,
      });
    }

    this.setState({ defaultSelectedTravellers: assignedUsersList });
  };

  onRemoveTravelers = (uid) => {
    let assignedUsersList = this.state.defaultSelectedTravellers;
    let removedEmployee = -1;
    removedEmployee = assignedUsersList.findIndex(
      (e) => e.userId == (uid.key === "all" ? parseInt("0") : uid.key)
    );
    if (removedEmployee > -1) {
      assignedUsersList.splice(removedEmployee, 1);
    }
    this.setState({ defaultSelectedTravellers: assignedUsersList });
  };

  onOtherTravelersSelect = (_idx, option) => {
    let assignedUsersList = [];
    if (this.state.defaultSelectedOtherTravellers) {
      assignedUsersList = this.state.defaultSelectedOtherTravellers;
    }
    assignedUsersList.push({
      recipientUserId: option.props.value,
      recipientOrgId: option.props.companyid,
    });

    this.setState({ defaultSelectedOtherTravellers: assignedUsersList });
  };

  onOtherRemoveTravelers = (uid) => {
    let assignedUsersList = this.state.defaultSelectedOtherTravellers;
    let removedEmployee = this.state.defaultSelectedOtherTravellers.findIndex(
      (e) => e.userId === uid
    );
    assignedUsersList.splice(removedEmployee, 1);
    this.setState({ defaultSelectedOtherTravellers: assignedUsersList });
  };
  treeloadforParentOrganization(e) {
    if (e && e.key !== undefined) {
      this.loadDivisions(e.key);
      this.props.form.setFieldsValue({
        multipleOrganizations: undefined,
      });
      if (e.key === 0 || e.key === "all") {
        this.setState({ parentOrgAllSelected: true });
      } else {
        this.setState({ parentOrgAllSelected: false });
      }
    }
  }
  goBackForm() {
    this.setState({
      isShowRulesFrom: false,
      isShowRulesList: true,
    });
  }
  onSearch(value, type) {
    var params = {
      filters: { companyName: value },
      pagination: { page: 1, pageSize: 10 },
      sorter: {},
    };
    let orgData = [{ organizationId: 0, name: "All" }];
    this.props.getOrganizations(params).then((data) => {
      this.setState({ parentOrganization: orgData.concat(data.data) });
    });
  }

  changeIsActive = (value) => {
    let _geoFence = this.state.geoFenceRule;
    _geoFence.isActive = value;
    this.setState({ geoFenceRule: _geoFence });
  };

  dateChanged = (dates) => {
    let geoRule = this.state.geoFenceRule;
    geoRule.startTime = dates[0];
    geoRule.endTime = dates[1];
    this.setState({
      statsLoading: true,
      geoFenceRule: geoRule,
    });
  };
  disabledRangeTime(a, type) {
    if (type === "start") {
      var depDate = this.props.form.getFieldValue("startEndTime")
        ? this.props.form.getFieldValue("startEndTime")[0]
        : "";
      if (depDate) {
        if (
          moment(new Date(depDate._d)).format("MM/DD/YYYY") ===
          moment(new Date()).format("MM/DD/YYYY")
        ) {
          if (depDate._d.getHours() === new Date().getHours()) {
            return {
              disabledHours: () => range(0, 24).slice(0, new Date().getHours()),
              disabledMinutes: () => range(0, new Date().getMinutes()),
              disabledSeconds: () => range(0, new Date().getSeconds()),
            };
          } else {
            return {
              disabledHours: () => range(0, 24).slice(0, new Date().getHours()),
            };
          }
        } else {
          return {};
        }
      } else {
        return {
          disabledHours: () => range(0, 24).slice(0, new Date().getHours()),
          disabledMinutes: () => range(0, new Date().getMinutes()),
          disabledSeconds: () => range(0, new Date().getSeconds()),
        };
      }
    }
  }
  render() {
    const { getFieldDecorator } = this.props.form;
    const { formLayout } = this.state;
    const formItemLayout =
      formLayout === "horizontal"
        ? {
            labelCol: { span: 4 },
            wrapperCol: { span: 14 },
          }
        : null;
    return (
      <div>
        <Spin spinning={this.state.loader}>
          {this.state.isShowRulesList && (
            <div>
              <Row gutter={24}>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  {/* <Typography.Title level={4}> */}
                  {/* Manage Asset Tracking Rules */}
                  {/* </Typography.Title> */}
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Button
                    type="primary"
                    className="right-fl def-blue"
                    htmlType="button"
                    onClick={() => this.props.history.push("../")}
                    style={{ margin: "0 10px" }}
                  >
                    <IntlMessages id="admin.userlisting.back" />
                  </Button>
                  <Button
                    type="primary"
                    onClick={() => this.addItem()}
                    className="right-fl def-blue"
                  >
                    <IntlMessages id="admin.userlisting.addnew" />
                  </Button>
                </Col>
              </Row>
              <hr></hr>
              <Row>
                <Col>
                  <Table
                    columns={this.getHeaderKeys()}
                    rowKey={(record) => record.id}
                    dataSource={this.state.geoFenceRules}
                    loading={this.state.loading}
                  />
                </Col>
              </Row>
            </div>
          )}
          {this.state.isShowRulesFrom && (
            <Form {...formItemLayout} onSubmit={this.handleSubmit}>
              <Row gutter={24}>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  {this.state.showadd ? (
                    <Typography.Title level={4}>
                      <IntlMessages id="admin.geofencing.addAssetTrackingRule"></IntlMessages>
                      {this.state.geoFenceRules &&
                      this.state.geoFenceRules.length > 0
                        ? " (" + this.state.geoFenceRules[0].interstedGeo + ")"
                        : ""}
                    </Typography.Title>
                  ) : (
                    <Typography.Title level={4}>
                      <IntlMessages id="admin.geofencing.editAssetTrackingRule"></IntlMessages>
                      {this.state.geoFenceRules &&
                      this.state.geoFenceRules.length > 0
                        ? " (" + this.state.geoFenceRules[0].interstedGeo + ")"
                        : ""}
                    </Typography.Title>
                  )}
                </Col>
                <Col
                  xs={24}
                  sm={24}
                  md={12}
                  lg={12}
                  xl={12}
                  style={{ textAlign: "right" }}
                >
                  <Button
                    type="primary"
                    className=""
                    htmlType="button"
                    onClick={() => this.goBackForm()}
                  >
                    <IntlMessages id="admin.userlisting.back" />
                  </Button>
                </Col>
              </Row>
              <hr />
              <Row gutter={24}>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  {getFieldDecorator(
                    "isInstant",
                    {}
                  )(
                    <Checkbox
                      onChange={this.instantchange}
                      checked={this.state.geoFenceRule.isInstant}
                      disabled={
                        this.state.disableinstantcase ||
                        this.state.userrestricted ||
                        this.state.disableNonInstantcase
                      }
                    >
                      Instant
                    </Checkbox>
                  )}
                </Col>
              </Row>
              <br />
              <Row gutter={24}>
                {!this.state.hidefields ? (
                  <div>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                      <Form.Item label="Start Date/Time - End Date/Time">
                        {getFieldDecorator("startEndTime", {
                          rules: [
                            {
                              required: true,
                              message: (
                                <IntlMessages id="admin.input.required"></IntlMessages>
                              ),
                            },
                          ],
                          initialValue:
                            this.state.geoFenceRule &&
                            this.state.geoFenceRule.startTime
                              ? [
                                  moment(
                                    this.state.geoFenceRule.startTime,
                                    dateFormathh
                                  ),
                                  moment(
                                    this.state.geoFenceRule.endTime,
                                    dateFormathh
                                  ),
                                ]
                              : null,
                        })(
                          <RangePicker
                            style={{ width: "100%" }}
                            disabled={
                              /* this.state.formexpired || (this.state.itineraryData.fromDate && this.state.itineraryData.fromDate !== null) */ this
                                .state.userrestricted
                            }
                            disabledDate={disabledDate}
                            disabledTime={this.disabledRangeTime}
                            showTime={{
                              hideDisabledOptions: true,
                            }}
                            showTime={{
                              minuteStep: 15,
                              secondStep: 60,
                            }}
                            format={dateFormat}
                          />
                        )}
                      </Form.Item>
                    </Col>
                  </div>
                ) : (
                  ""
                )}
                {/* {!this.state.hidefields ?

                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Form.Item label="End Date/Time">
                      {getFieldDecorator("endTime", {
                        rules: [
                          {
                            required: true,
                            message: <IntlMessages id="admin.input.required"></IntlMessages>
                          }
                        ],
                        initialValue:
                          this.state.geoFenceRule.endTime &&
                          moment(this.state.geoFenceRule.endTime, dateFormathh)
                      })(<DatePicker
                        disabled={this.state.geoFenceRule && this.state.geoFenceRule.endTime && new Date(this.state.geoFenceRule.endTime) < new Date() || this.state.userrestricted ? true : false}
                        showTime={{
                          minuteStep: 15,
                          secondStep: 60,
                        }}

                        disabledDate={this.props.form && this.props.form.getFieldValue("startTime") ? current => {
                          return current && current < moment(this.props.form.getFieldValue("startTime"));
                        } : 
                        current => {
                          return current && current < moment().subtract(1, 'days')
                        }}


                        disabledTime={current => {
                          return {
                            disabledHours: () => range(0, 24).slice(0, this.props.form.getFieldValue("startTime")? moment(this.props.form.getFieldValue("startTime").format('HH'))._i:null),
                            disabledMinutes: () => range(0, this.props.form.getFieldValue("startTime") ? moment(this.props.form.getFieldValue("startTime").format('mm'))._i:null),
                            disabledSeconds: () => range(0, this.props.form.getFieldValue("startTime") ? moment(this.props.form.getFieldValue("startTime").format('ss'))._i:null),
                          };
                        }
                        }
                        format={dateFormat} />)}
                    </Form.Item>
                  </Col> : ''} */}

                <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                  <Form.Item {...formItemLayout} label="Radius (meters)">
                    {getFieldDecorator("radiuss", {
                      initialValue:
                        this.state.geoFenceRule &&
                        this.state.geoFenceRule.radius
                          ? this.state.geoFenceRule.radius
                          : "",

                      rules: [
                        {
                          required: true,
                          message: (
                            <IntlMessages id="admin.input.required"></IntlMessages>
                          ),
                        },
                      ],
                    })(
                      <Select
                        disabled={
                          this.state.disableinstantcase ||
                          this.state.userrestricted ||
                          (this.state.geoFenceRule &&
                            this.state.geoFenceRule.endTime &&
                            new Date(this.state.geoFenceRule.endTime) <
                              new Date())
                            ? true
                            : false
                        }
                      >
                        {this.state.radiusdata.map(function(item) {
                          return (
                            <Option key={item} value={item}>
                              {item}
                            </Option>
                          );
                        })}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                  <Form.Item {...formItemLayout} label="Rule Name">
                    {getFieldDecorator("alertTitle", {
                      initialValue:
                        this.state.geoFenceRule.alertTitle === null
                          ? ""
                          : this.state.geoFenceRule.alertTitle,
                      rules: [
                        {
                          whitespace: true,
                          required: true,
                          message: (
                            <IntlMessages id="admin.input.required"></IntlMessages>
                          ),
                        },
                      ],
                    })(
                      <Input
                        maxLength="90"
                        disabled={
                          this.state.disableinstantcase ||
                          this.state.userrestricted ||
                          (this.state.geoFenceRule &&
                          this.state.geoFenceRule.endTime &&
                          new Date(this.state.geoFenceRule.endTime) < new Date()
                            ? true
                            : false)
                        }
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col xs={24} sm={12} md={24} lg={24} xl={24}>
                  <Form.Item {...formItemLayout} label="Description">
                    {getFieldDecorator("alertDescription", {
                      initialValue:
                        this.state.geoFenceRule.alertDescription === null
                          ? ""
                          : this.state.geoFenceRule.alertDescription,
                    })(
                      <TextArea
                        maxLength="200"
                        disabled={
                          this.state.disableinstantcase ||
                          this.state.userrestricted ||
                          (this.state.geoFenceRule &&
                            this.state.geoFenceRule.endTime &&
                            new Date(this.state.geoFenceRule.endTime) <
                              new Date())
                            ? true
                            : false
                        }
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}></Row>
              <Row gutter={24}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Typography.Title level={3}>
                    <span style={{ color: "red" }}>*</span> Criteria
                  </Typography.Title>
                </Col>
              </Row>
              {!this.state.hidefields ? (
                <Row gutter={24}>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Form.Item label="When To Send">
                      {getFieldDecorator("isEnter", {
                        initialValue: this.state.geoFenceRule.isEnter,
                      })(
                        <Row>
                          <Col span={8}>
                            <Checkbox
                              onChange={this.isEnterchange}
                              defaultChecked={this.state.geoFenceRule.isEnter}
                              disabled={
                                this.state.disableinstantcase ||
                                this.state.userrestricted ||
                                (this.state.geoFenceRule &&
                                  this.state.geoFenceRule.endTime &&
                                  new Date(this.state.geoFenceRule.endTime) <
                                    new Date())
                                  ? true
                                  : false
                              }
                            >
                              Enter
                            </Checkbox>
                          </Col>
                        </Row>
                      )}
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Form.Item
                      {...formItemLayout}
                      label={
                        <span>
                          Enter Message&nbsp;
                          <Tooltip title="Message will be sent to the user(s) who is handling the targeted travelers.">
                            <Icon
                              style={{ fontSize: "18px" }}
                              type="question-circle-o"
                            />
                          </Tooltip>
                        </span>
                      }
                    >
                      {getFieldDecorator("enterMessage", {
                        rules: [
                          {
                            whitespace: true,
                            required: this.state.enterr,
                            message: (
                              <IntlMessages id="admin.input.required"></IntlMessages>
                            ),
                          },
                        ],
                        initialValue:
                          this.state.enterr === null
                            ? ""
                            : this.state.geoFenceRule.enterMessage,
                      })(
                        <Input
                          maxLength="160"
                          disabled={
                            this.state.disableinstantcase ||
                            this.state.userrestricted ||
                            !this.state.enterr ||
                            (this.state.geoFenceRule &&
                              this.state.geoFenceRule.endTime &&
                              new Date(this.state.geoFenceRule.endTime) <
                                new Date())
                              ? true
                              : false
                          }
                        />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
              ) : (
                ""
              )}
              {!this.state.hidefields ? (
                <Row gutter={24}>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Form.Item>
                      {getFieldDecorator("isExit", {
                        initialValue: this.state.exitr,
                      })(
                        <Row>
                          <Col span={8}>
                            <Checkbox
                              disabled={
                                this.state.disableinstantcase ||
                                this.state.userrestricted ||
                                (this.state.geoFenceRule &&
                                  this.state.geoFenceRule.endTime &&
                                  new Date(this.state.geoFenceRule.endTime) <
                                    new Date())
                                  ? true
                                  : false
                              }
                              onChange={this.isExitchange}
                              defaultChecked={this.state.geoFenceRule.isExit}
                            >
                              Exit
                            </Checkbox>
                          </Col>
                        </Row>
                      )}
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Form.Item
                      {...formItemLayout}
                      label={
                        <span>
                          Exit Message&nbsp;
                          <Tooltip title="Message will be sent to the user(s) who is handling the targeted travelers.">
                            <Icon
                              style={{ fontSize: "18px" }}
                              type="question-circle-o"
                            />
                          </Tooltip>
                        </span>
                      }
                    >
                      {getFieldDecorator("exitMessage", {
                        rules: [
                          {
                            whitespace: true,
                            required: this.state.exitr,
                            message: (
                              <IntlMessages id="admin.input.required"></IntlMessages>
                            ),
                          },
                        ],
                        initialValue:
                          this.state.geoFenceRule.exitMessage === null
                            ? ""
                            : this.state.geoFenceRule.exitMessage,
                      })(
                        <Input
                          maxLength="160"
                          disabled={
                            this.state.disableinstantcase ||
                            this.state.userrestricted ||
                            !this.state.exitr ||
                            (this.state.geoFenceRule &&
                              this.state.geoFenceRule.endTime &&
                              new Date(this.state.geoFenceRule.endTime) <
                                new Date())
                              ? true
                              : false
                          }
                        />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
              ) : (
                ""
              )}

              <div>
                <Row gutter={24}>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Form.Item>
                      {getFieldDecorator("isWithinRadius", {
                        initialValue: this.state.geoFenceRule.withinRadius,
                      })(
                        <Row>
                          <Col span={8}>
                            <Checkbox
                              disabled={
                                this.state.disableinstantcase ||
                                this.state.userrestricted ||
                                (this.state.geoFenceRule &&
                                  this.state.geoFenceRule.endTime &&
                                  new Date(this.state.geoFenceRule.endTime) <
                                    new Date())
                                  ? true
                                  : false
                              }
                              onChange={this.isWithinRadiuschange}
                              defaultChecked={
                                this.state.geoFenceRule.isWithinRadius
                              }
                            >
                              Within Radius
                            </Checkbox>
                          </Col>
                        </Row>
                      )}
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Form.Item
                      {...formItemLayout}
                      label={
                        <span>
                          Within Radius Message&nbsp;
                          <Tooltip title="Message will be sent to the targeted travelers who are within Radius.">
                            <Icon
                              style={{ fontSize: "18px" }}
                              type="question-circle-o"
                            />
                          </Tooltip>
                        </span>
                      }
                    >
                      {getFieldDecorator("instantEnterMessage", {
                        rules: [
                          {
                            whitespace: true,
                            required: this.state.insider,
                            message: (
                              <IntlMessages id="admin.input.required"></IntlMessages>
                            ),
                          },
                        ],
                        initialValue:
                          this.state.geoFenceRule.instantEnterMessage === null
                            ? ""
                            : this.state.geoFenceRule.instantEnterMessage,
                      })(
                        <Input
                          maxLength="160"
                          disabled={
                            this.state.disableinstantcase ||
                            this.state.userrestricted ||
                            !this.state.insider ||
                            (this.state.geoFenceRule &&
                              this.state.geoFenceRule.endTime &&
                              new Date(this.state.geoFenceRule.endTime) <
                                new Date())
                              ? true
                              : false
                          }
                        />
                      )}
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={24}>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Form.Item>
                      {getFieldDecorator("isOutsideRadius", {
                        rules: [],
                        initialValue:
                          this.state.geoFenceRule &&
                          this.state.geoFenceRule.outsideRadius
                            ? this.state.geoFenceRule.outsideRadius
                            : null,
                      })(
                        <Row>
                          <Col span={8}>
                            <Checkbox
                              disabled={
                                this.state.disableinstantcase ||
                                this.state.userrestricted ||
                                (this.state.geoFenceRule &&
                                  this.state.geoFenceRule.endTime &&
                                  new Date(this.state.geoFenceRule.endTime) <
                                    new Date())
                                  ? true
                                  : false
                              }
                              onChange={this.isOutsideRadiuschange}
                              defaultChecked={
                                this.state.geoFenceRule.isOutsideRadius
                              }
                            >
                              Outside Radius
                            </Checkbox>
                          </Col>
                        </Row>
                      )}
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Form.Item
                      {...formItemLayout}
                      label={
                        <span>
                          Outside Radius Message&nbsp;
                          <Tooltip title="Message will be sent to the targeted travelers who are outside Radius.">
                            <Icon
                              style={{ fontSize: "18px" }}
                              type="question-circle-o"
                            />
                          </Tooltip>
                        </span>
                      }
                    >
                      {getFieldDecorator("instantExitMessage", {
                        rules: [
                          {
                            whitespace: true,
                            required: this.state.outsider,
                            message: (
                              <IntlMessages id="admin.input.required"></IntlMessages>
                            ),
                          },
                        ],
                        initialValue:
                          this.state.geoFenceRule.instantExitMessage === null
                            ? ""
                            : this.state.geoFenceRule.instantExitMessage,
                      })(
                        <Input
                          maxLength="160"
                          disabled={
                            this.state.disableinstantcase ||
                            this.state.userrestricted ||
                            !this.state.outsider ||
                            (this.state.geoFenceRule &&
                              this.state.geoFenceRule.endTime &&
                              new Date(this.state.geoFenceRule.endTime) <
                                new Date())
                              ? true
                              : false
                          }
                        />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
              </div>
              <Row gutter={24}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Typography.Title level={3}>
                    <span style={{ color: "red" }}>*</span> Targeted For
                  </Typography.Title>
                </Col>
              </Row>
              {this.props.userData &&
              (this.props.userData.role === "Administrator" ||
                this.props.userData.role === "CSR") ? (
                <Row gutter={24}>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Form.Item>
                      {getFieldDecorator("bothAlertRecipients", {
                        initialValue: this.state.isBothAlertChecked,
                      })(
                        <Row>
                          <Col span={8}>
                            <Checkbox
                              onChange={this.onAlertCheckboxChange}
                              disabled={
                                this.state.disableinstantcase ||
                                this.state.userrestricted ||
                                (this.state.geoFenceRule &&
                                  this.state.geoFenceRule.endTime &&
                                  new Date(this.state.geoFenceRule.endTime) <
                                    new Date())
                                  ? true
                                  : false
                              }
                              defaultChecked={this.state.isBothAlertChecked}
                            >
                              All
                            </Checkbox>
                          </Col>
                        </Row>
                      )}
                    </Form.Item>
                  </Col>
                </Row>
              ) : (
                <Row gutter={24}>
                  {" "}
                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Form.Item
                      label={
                        <IntlMessages id="admin.organization.division"></IntlMessages>
                      }
                    >
                      {getFieldDecorator("multipleOrganizations", {
                        initialValue: this.state.multipleOrganizations,
                      })(
                        <TreeSelect
                          treeData={this.state.divisionList}
                          style={{ width: "100%" }}
                          dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                          onChange={this.onChangeAssignedOrganizations}
                          treeCheckable={true}
                          disabled={
                            this.state.disableinstantcase ||
                            this.state.userrestricted ||
                            this.state.isDisable ||
                            (this.state.geoFenceRule &&
                              this.state.geoFenceRule.endTime &&
                              new Date(this.state.geoFenceRule.endTime) <
                                new Date())
                              ? true
                              : false
                          }
                          labelInValue={true}
                          treeCheckStrictly={!this.state.assignedDivisionsAuto}
                          showCheckedStrategy={TreeSelect.SHOW_ALL}
                          treeDefaultExpandAll
                        />
                      )}
                      <Checkbox
                        checked={this.state.assignedDivisionsAuto}
                        disabled={
                          this.state.disableinstantcase ||
                          this.state.isDisable ||
                          (this.state.geoFenceRule &&
                            this.state.geoFenceRule.endTime &&
                            new Date(this.state.geoFenceRule.endTime) <
                              new Date())
                            ? true
                            : false
                        }
                        onChange={this.setAssignedDivisionsAuto}
                      />
                      &nbsp;
                      <IntlMessages id="admin.autoselect.childdivisions" />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Form.Item label="Traveler">
                      {getFieldDecorator("traveller", {
                        initialValue: this.state.defaultSelectedTravellers,
                      })(
                        <Select
                          mode="multiple"
                          allowClear
                          disabled={
                            this.state.disableinstantcase ||
                            this.state.userrestricted ||
                            (this.state.geoFenceRule &&
                              this.state.geoFenceRule.endTime &&
                              new Date(this.state.geoFenceRule.endTime) <
                                new Date())
                              ? true
                              : false
                          }
                          onPopupScroll={this.loadotherUsers}
                          optionFilterProp="children"
                          filterOption={false}
                          labelInValue={true}
                          onSelect={this.onTravelersSelect}
                          onDeselect={this.onRemoveTravelers}
                          onSearch={this.fetchTraveler}
                        >
                          {this.state.travellerinfo
                            ? this.state.travellerinfo.map((t) => {
                                return (
                                  <Option
                                    key={t.userId}
                                    value={t.userId}
                                    companyid={t.companyId}
                                  >
                                    {t.firstName} {t.lastName}{" "}
                                    {"<" + t.userName + ">"}
                                  </Option>
                                );
                              })
                            : ""}
                        </Select>
                      )}
                    </Form.Item>
                  </Col>
                </Row>
              )}

              {this.props.userData &&
              (this.props.userData.role === "Administrator" ||
                this.props.userData.role === "CSR") ? (
                <Row gutter={24}>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Form.Item label="Parent Organization">
                      {getFieldDecorator("parent_orgnization", {
                        initialValue: this.state.parentOrganizationId,
                      })(
                        <Select
                          style={{ width: "100%" }}
                          allowClear
                          onPopupScroll={this.loadOtherOrganizations}
                          showSearch
                          onChange={this.treeloadforParentOrganization}
                          onSearch={this.onSearch}
                          labelInValue={true}
                          disabled={
                            this.state.disableinstantcase ||
                            this.state.userrestricted ||
                            this.state.isDisable ||
                            (this.state.geoFenceRule &&
                              this.state.geoFenceRule.endTime &&
                              new Date(this.state.geoFenceRule.endTime) <
                                new Date())
                              ? true
                              : false
                          }
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.props.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {this.state.parentOrganization
                            ? this.state.parentOrganization.map(function(item) {
                                return (
                                  <Option
                                    key={item.organizationId}
                                    value={item.organizationId}
                                  >
                                    {item.name}
                                  </Option>
                                );
                              })
                            : ""}
                        </Select>
                      )}
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Form.Item
                      label={
                        <IntlMessages id="admin.organization.division"></IntlMessages>
                      }
                    >
                      {getFieldDecorator("multipleOrganizations", {
                        rules: [
                          {
                            required:
                              this.props.form.getFieldValue(
                                "parent_orgnization"
                              ) &&
                              this.props.form.getFieldValue(
                                "parent_orgnization"
                              ).key !== 0 &&
                              this.props.form.getFieldValue(
                                "parent_orgnization"
                              ).key !== "all",
                            message: (
                              <IntlMessages id="admin.input.required"></IntlMessages>
                            ),
                          },
                        ],
                        initialValue: this.state.multipleOrganizations,
                      })(
                        <TreeSelect
                          treeData={this.state.divisionList}
                          style={{ width: "100%" }}
                          dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                          onChange={this.onChangeAssignedOrganizations}
                          treeCheckable={true}
                          disabled={
                            this.state.parentOrgAllSelected ||
                            this.state.disableinstantcase ||
                            this.state.userrestricted ||
                            this.state.isDisable ||
                            (this.state.geoFenceRule &&
                              this.state.geoFenceRule.endTime &&
                              new Date(this.state.geoFenceRule.endTime) <
                                new Date())
                              ? true
                              : false
                          }
                          labelInValue={true}
                          treeCheckStrictly={!this.state.assignedDivisionsAuto}
                          showCheckedStrategy={TreeSelect.SHOW_ALL}
                          treeDefaultExpandAll
                        />
                      )}
                      <Checkbox
                        checked={this.state.assignedDivisionsAuto}
                        disabled={
                          this.state.disableinstantcase ||
                          this.state.isDisable ||
                          (this.state.geoFenceRule &&
                            this.state.geoFenceRule.endTime &&
                            new Date(this.state.geoFenceRule.endTime) <
                              new Date())
                            ? true
                            : false
                        }
                        onChange={this.setAssignedDivisionsAuto}
                      />
                      &nbsp;
                      <IntlMessages id="admin.autoselect.childdivisions" />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Form.Item
                      {...formItemLayout}
                      label={
                        <IntlMessages id="admin.manageOrders.travellerNameb2c" />
                      }
                    >
                      {getFieldDecorator("travellerdata", {
                        initialValue: this.state.defaultSelectedTravellers,
                      })(
                        <Select
                          mode="multiple"
                          allowClear
                          filterOption={false}
                          onSearch={this.fetchTravellers}
                          style={{ width: "100%" }}
                          labelInValue={true}
                          disabled={
                            this.state.disableinstantcase ||
                            this.state.userrestricted ||
                            this.state.isDisable ||
                            (this.state.geoFenceRule &&
                              this.state.geoFenceRule.endTime &&
                              new Date(this.state.geoFenceRule.endTime) <
                                new Date())
                              ? true
                              : false
                          }
                          onPopupScroll={this.getOthertravelerData}
                          onSelect={this.onTravelersSelect}
                          onDeselect={this.onRemoveTravelers}
                        >
                          {this.state.travelerdata
                            ? this.state.travelerdata.map((t) => {
                                return (
                                  <Option
                                    key={t.userId}
                                    value={t.userId}
                                    companyid={1}
                                  >
                                    {t.firstName} {t.lastName}{" "}
                                    {t.userName ? "<" + t.userName + ">" : ""}
                                  </Option>
                                );
                              })
                            : ""}
                        </Select>
                      )}
                    </Form.Item>
                  </Col>
                </Row>
              ) : (
                ""
              )}
              {this.state.instantr === false ? (
                <div>
                  <Row gutter={24}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <Typography.Title level={3}>Notify To</Typography.Title>
                    </Col>
                  </Row>
                  <Row gutter={24} style={{ marginTop: "25px" }}>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                      Send Alert to Targeted?
                      {
                        <span>
                          &nbsp;
                          <Tooltip title="Enter/Exit message will be sent to the targeted travelers as well.">
                            <Icon
                              style={{ fontSize: "18px" }}
                              type="question-circle-o"
                            />
                          </Tooltip>
                        </span>
                      }
                    </Col>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                      <Switch
                        checkedChildren="Yes"
                        unCheckedChildren="No"
                        disabled={
                          this.state.disableinstantcase ||
                          this.state.userrestricted ||
                          (this.state.geoFenceRule &&
                            this.state.geoFenceRule.endTime &&
                            new Date(this.state.geoFenceRule.endTime) <
                              new Date())
                            ? true
                            : false
                        }
                        defaultChecked={this.state.selfOpted}
                        onChange={this.onChange}
                      />
                    </Col>
                  </Row>
                  <br />
                  <Row gutter={24}>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                      <span>Select additional persons to notify :</span>
                    </Col>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                      <Form.Item>
                        {getFieldDecorator("notifyTo", {
                          initialValue: this.state
                            .defaultSelectedOtherTravellers,
                        })(
                          <Select
                            mode="multiple"
                            allowClear={true}
                            onSearch={this.fetchActiveUsers}
                            onPopupScroll={this.loadOtherInactiveUsers}
                            optionFilterProp="children"
                            filterOption={false}
                            labelInValue={true}
                            onSelect={this.onOtherTravelersSelect}
                            onDeselect={this.onOtherRemoveTravelers}
                            disabled={
                              this.state.disableinstantcase ||
                              this.state.userrestricted ||
                              (this.state.geoFenceRule &&
                                this.state.geoFenceRule.endTime &&
                                new Date(this.state.geoFenceRule.endTime) <
                                  new Date())
                                ? true
                                : false
                            }
                          >
                            {this.state.activeUsers
                              ? this.state.activeUsers.map((t) => {
                                  return (
                                    <Option
                                      key={t.userId}
                                      value={t.userId}
                                      companyid={t.companyId}
                                    >
                                      {t.firstName} {t.lastName}{" "}
                                      {"<" + t.email + ">"}
                                    </Option>
                                  );
                                })
                              : ""}
                          </Select>
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
              ) : (
                ""
              )}
              <Row gutter={24}>
                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                  Status
                </Col>
                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                  <Switch
                    checkedChildren="Active"
                    disabled={
                      this.state.userrestricted ||
                      this.state.expired ||
                      new Date(this.state.geoFenceRule.endTime) < new Date()
                        ? true
                        : false
                    }
                    unCheckedChildren="Inactive"
                    onChange={this.changeIsActive}
                    checked={
                      this.state.geoFenceRule.isActive === false ||
                      new Date(this.props.form.getFieldValue("endTime")) <
                        new Date()
                        ? false
                        : true
                    }
                    name="status"
                  />
                </Col>
              </Row>
              {this.state.disableinstantcase === false &&
              (this.state.expired === false ||
                (this.state.geoFenceRule &&
                new Date(this.state.geoFenceRule.endTime) > new Date()
                  ? true
                  : false)) &&
              this.state.userrestricted === false ? (
                <Row gutter={24} style={{ marginTop: "25px" }}>
                  <Col>
                    <Form.Item>
                      <Button type="primary" htmlType="submit">
                        Save
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              ) : (
                ""
              )}
            </Form>
          )}
        </Spin>
      </div>
    );
  }
}

AssetTrackngRules.propTypes = {
  userData: PropTypes.object,
  getUserData: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    ...state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...geoFenceAction,
      ...OrgActions,
      ...userActions,
      ...alertTypeActions,
      ...travellerActions,
      ...AssetTrackingActions,
    },
    dispatch
  );
}
const WrappedGeoRulesForm = Form.create({ name: "asset-tracking-rules" })(
  AssetTrackngRules
);
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    WrappedGeoRulesForm
  )
);
