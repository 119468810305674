import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import IntlMessages from "../../../../services/intlMesseges";
import {
  Form,
  Input,
  Radio,
  Select,
  Button,
  Row,
  Col,
  DatePicker,
  Collapse,
  TreeSelect
} from "antd";
import moment from "moment";
import * as UserService from "../../../../services/user-service";
import * as OrgActions from "../../../../redux/actions/organization-actions";
import * as Permission from "../../../../services/permissions";
import * as itiniaryActions from "../../../../redux/actions/itinerary-actions";

const { Panel } = Collapse;
const dateFormat = "MM/DD/YYYY";
const dbDateFormat = "YYYY-MM-DD";
const { Option } = Select;

class TravelFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUserOrgId: 0,
      parentOrgCurrentPage: 1,
      selectedOrgId: 0,
      currentSelectedParentOrg: 0,
      parentOrganizations: [],
      airportData: [],
      airportId: "",
      stateuser: 1,
      parentOrganizationId: "all",
      role: "",
      startValue: null,
      onChangeChildOrganizationValue: {},
      childId: {}
    };
    this.getairportData = this.getairportData.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.handleAirportChange = this.handleAirportChange.bind(this);
    this.getAirportUpdate = this.getAirportUpdate.bind(this);
    this.onResetTravelerFilter = this.onResetTravelerFilter.bind(this);
    this.saveState = this.saveState.bind(this);
  }

  getAirportUpdate() {
    this.getairportData({});
  }

  handleAirportChange(e) {
    this.setState({ airportId: e });
  }
  onSearch(value, type) {
    this.setState({ searchairport: value });
    this.getairportData(
      {
        orgId: 1,
        filters: { displayName: value },
        pagination: { page: 1, pageSize: 10 }
      },
      1
    );
  }

  async getairportData(params, value) {
    var info = [];
    await this.props.getAirportCodesData(params).then(data => {
      if (value === 1) {
        // info = [];
        // info = this.state.airportData;
        console.log(data.data);

        this.setState({ airportData: data.data });
      } else if (value === 2) {
        info = [];
        if (data.data && data.data.length > 0) {
          let _da1 = data.data;
          let _da2 = this.state.airportData;
          let da3 = [];
          if (this.state.airportData && this.state.airportData.length > 0) {
            da3 = _da2.concat(_da1);
          }
          this.setState({ airportData: da3 });
        }
      } else {
        info = [];
        info = data.data;
        this.setState({ airportData: info });
      }
    });
  }

  loadAccountManagers = e => {
    var self = this;
    var _search;
    if (this.state.searchairport) {
      _search = { displayName: this.state.searchairport };
    } else {
      _search = {};
    }

    if (e.target.scrollTop + e.target.offsetHeight >= e.target.scrollHeight) {
      var datas = this.state.stateuser + 1;
      self.getairportData(
        {
          orgId: 1,
          filters: _search,
          pagination: { page: datas, pageSize: 10 }
        },
        2
      );
      this.setState({ stateuser: datas });
    }
  };

  componentDidMount() {
    let currentUser = UserService.getUser();
    let userRole = currentUser.role;
    this.setState({ role: userRole });
    let orgId = currentUser.companyID;

    if (Permission.isRole("Administrator") || Permission.isRole("CSR")) {
      this.loadParentOrganizations();
    } else {
      let list = [];
      this.props.getDivisionsList(orgId).then(resp => {
        let parentId;
        for (var i in resp.data) {
          if (resp.data[i].organizationId === orgId) {
            parentId = resp.data[i].parentOrgId;
          }
        }
        list = this.getNestedChildren(resp.data, parentId);
        this.setState({ currentSelectedParentOrg: orgId });
        this.setState({
          childOrgs: list
        });
      });
    }
    if (
      this.props.savedState.parentOrganizationId != "" &&
      this.props.savedState.parentOrganizationId != "all"
    ) {
      this.setState({
        parentOrganizationId: this.props.savedState.parentOrganizationId
      });
    }
    if (
      this.props.savedState.currentSelectedParentOrg != "" &&
      this.props.savedState.currentSelectedParentOrg != "all"
    ) {
      this.setState({
        currentSelectedParentOrg: this.props.savedState.currentSelectedParentOrg
      });
    }
    if (
      this.props.savedState.childOrg != "" &&
      this.props.savedState.childOrg != undefined
    ) {
      this.setState({
        childId: {
          key: this.props.savedState.childOrg.value,
          label: this.props.savedState.childOrg.label
        }
      });
    }

    this.setState({ currentUserOrgId: orgId });
    this.getairportData({});
  }

  disabledEndDate = endValue => {
    const { startValue } = this.state;
    if (!endValue || !startValue) {
      return false;
    }
    return endValue.valueOf() <= startValue.valueOf();
  };

  onChange = (field, value) => {
    this.setState({
      [field]: value
    });
  };
  onStartChange = value => {
    this.onChange("startValue", value);
  };

  loadParentOrganizations = () => {
    this.props
      .getOrganizations({
        filters: {},
        pagination: { page: 1, pageSize: 10 },
        sorter: {}
      })
      .then(resp => {
        this.setState({
          parentOrganizations: resp.data
        });
      });
  };

  getNestedChildren = (arr, parent) => {
    var out = [];
    for (var i in arr) {
      if (arr[i].parentOrgId === parent) {
        var children = this.getNestedChildren(arr, arr[i].organizationId);
        if (children.length) {
          arr[i].children = children;
        }
        arr[i].title = arr[i].name;
        arr[i].value = arr[i].organizationId;
        arr[i].label = arr[i].name;
        arr[i].key = arr[i].organizationId;
        out.push(arr[i]);
      }
    }
    return out;
  };

  onChangeChildOrganizationTree = (value, label) => {
    this.setState({ onChangeChildOrganizationValue: value });
  };

  onChangeParentOrganizationTree = selected => {
    this.setState({ parentOrganizationId: selected });
    this.setState({ childId: {} });
    this.props.form.setFieldsValue({ childOrgId: {} });
    let list = [];
    this.props.getDivisionsList(selected).then(resp => {
      let parentId;
      for (var i in resp.data) {
        if (resp.data[i].organizationId == selected) {
          parentId = resp.data[i].parentOrgId;
        }
      }
      list = this.getNestedChildren(resp.data, parentId);
      this.setState({
        childOrgs: list
      });
    });
    this.loadParentOrganizations();
    this.setState({ currentSelectedParentOrg: selected });
    this.props.form.setFieldsValue({ childOrgs: list });
  };

  fetchParentOrganizations = e => {
    if (e.target.scrollTop + e.target.offsetHeight >= e.target.scrollHeight) {
      var datas = this.state.parentOrgCurrentPage + 1;
      this.props
        .getOrganizations({
          filters: {},
          pagination: { page: datas, pageSize: 10 },
          sorter: {}
        })
        .then(resp => {
          this.setState({ parentOrgCurrentPage: datas });
          let data = this.state.parentOrganizations;
          this.setState({
            parentOrganizations: data.concat(resp.data)
          });
        })
        .catch(ex => {
          this.setState({ loading: false });
        });
    }
  };

  searchParentOrganizations = async value => {
    let args = {
      filters: { companyName: value },
      pagination: { page: 1, pageSize: 10 },
      sorter: {}
    };
    this.props
      .getOrganizations(args)
      .then(resp => {
        this.setState({
          parentOrganizations: resp.data
        });
      })
      .catch(_ex => {
        this.setState({ loading: false });
      });
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        if (
          this.state.currentSelectedParentOrg !== "all" &&
          this.state.currentSelectedParentOrg !== 0
        ) {
          values.parentOrgId = this.state.currentSelectedParentOrg;
        } else {
          values.parentOrgId = "";
        }

        if (values.childOrg !== undefined) {
          values.childOrgId = values.childOrgId.value;
        } else if (values.childOrg != "") {
          if (values.childOrgId != undefined) {
            values.childOrgId = values.childOrgId.value;
          }
        } else {
          values.childOrgId = "";
        }

        this.props.handleTableChange(values);

        var formValues = values;
        formValues.startDate =
          formValues.startDate != "Invalid date" && formValues.startDate != ""
            ? moment(formValues.startDate).format(dateFormat)
            : "";
        formValues.endDate =
          formValues.endDate != "Invalid date" && formValues.endDate != ""
            ? moment(formValues.endDate).format(dateFormat)
            : "";
        formValues.childOrgId = this.state.onChangeChildOrganizationValue;
        formValues.parentOrganizationId = this.state.parentOrganizationId;
        formValues.currentSelectedParentOrg = this.state.currentSelectedParentOrg;
        this.props.handleStateSave(formValues);
      }
    });
  };

  saveState(values) {}

  onResetTravelerFilter() {
    this.props.form.resetFields();
    this.setState({
      parentOrganizationId: "all",
      startValue: null,
      childId: {}
    });
    if (Permission.isRole("Administrator") || Permission.isRole("CSR")) {
      this.setState({ currentSelectedParentOrg: 0 });
    }
    this.props.handleStateSave();
    this.props.handleTableChange();
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 24 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 }
      }
    };

    return (
      <div>
        <Row gutter={24}>
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <h5 style={{ border: "none" }}>Filters</h5>
          </Col>
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            style={{ textAlign: "right" }}
          >
            <Button
              type="primary"
              onClick={this.onResetTravelerFilter}
              style={{ height: "30px", lineHeight: "30px" }}
            >
              Reset All
            </Button>
          </Col>
        </Row>
        <Form
          {...formItemLayout}
          onSubmit={this.handleSubmit}
          layout="Vertical"
          id="travelerform"
          style={{
            maxHeight: "349px",
            overflowX: "hidden",
            overflowY: "auto"
          }}
        >
          <Row gutter={24}>
            <Collapse accordion>
              <Panel header="By Date Range" key="1">
                <Col>
                  <Form.Item
                    label="Start Date:"
                    labelAlign="left"
                    style={{ margin: "0" }}
                  >
                    {getFieldDecorator("startDate", {
                      initialValue: this.props.savedState.startDate
                        ? moment(this.props.savedState.startDate)
                        : moment()
                    })(
                      <DatePicker
                        format={dateFormat}
                        onChange={this.onStartChange}
                      />
                    )}
                  </Form.Item>
                  <Form.Item
                    label="End Date:"
                    labelAlign="left"
                    style={{ margin: "0" }}
                  >
                    {getFieldDecorator("endDate", {
                      initialValue: this.props.savedState.endDate
                        ? moment(this.props.savedState.endDate)
                        : moment()
                    })(
                      <DatePicker
                        format={dateFormat}
                        disabledDate={this.disabledEndDate}
                      />
                    )}
                  </Form.Item>

                  <Form.Item>
                    <Button type="primary" htmlType="submit">
                      Apply
                    </Button>
                  </Form.Item>
                </Col>
              </Panel>
              <Panel header="By Airport" key="2">
                <Col>
                  <Form.Item className="collection-create-form_last-form-item">
                    {getFieldDecorator("byairport", {
                      initialValue: "departure"
                    })(
                      <Radio.Group>
                        <Radio value="departure">Departure</Radio>
                        <Radio value="arrival">Arrival</Radio>
                      </Radio.Group>
                    )}
                  </Form.Item>
                  <Form.Item>
                    {getFieldDecorator("airportCodes", {
                      initialValue:
                        this.props.savedState &&
                        this.props.savedState.airportCodes
                          ? this.props.savedState.airportCodes
                          : ""
                    })(
                      <Select
                        style={{ width: "100%", marginTop: "-40px" }}
                        onPopupScroll={this.loadAccountManagers}
                        showSearch
                        onSearch={this.onSearch}
                        //placeholder={<IntlMessages id="admin.report.airport"></IntlMessages>}
                        onSelect={this.getAirportUpdate}
                        style={{ width: "100%", marginTop: "-30px" }}
                        onChange={e => this.handleAirportChange(e)}
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {this.state.airportData
                          ? this.state.airportData.map(function(item) {
                              return (
                                <Option key={item.code} value={item.code}>
                                  {item.displayName}
                                </Option>
                              );
                            })
                          : ""}
                      </Select>
                    )}
                  </Form.Item>
                  {/* <Form.Item
                    label="Airport Code :"
                    //className="threeInput"
                    labelAlign="left"
                  >
                    {getFieldDecorator("airportCodes", {})(<Input />)}
                  </Form.Item> */}
                  <Form.Item>
                    <Button type="primary" htmlType="submit">
                      Apply
                    </Button>
                  </Form.Item>
                </Col>
              </Panel>
              <Panel header="By Traveler" key="3">
                <Col>
                  <Form.Item label="First Name:" labelAlign="left">
                    {getFieldDecorator("firstName", {
                      initialValue: this.props.savedState.firstName
                        ? this.props.savedState.firstName
                        : ""
                    })(<Input />)}
                  </Form.Item>
                  <Form.Item label="Last Name:" labelAlign="left">
                    {getFieldDecorator("lastName", {
                      initialValue: this.props.savedState.lastName
                        ? this.props.savedState.lastName
                        : ""
                    })(<Input />)}
                  </Form.Item>
                  <Form.Item>
                    <Button type="primary" htmlType="submit">
                      Apply
                    </Button>
                  </Form.Item>
                </Col>
              </Panel>
              <Panel header="By Airline" key="4">
                <Col>
                  <Form.Item label="Airline code:" labelAlign="left">
                    {getFieldDecorator("airVendorCode", {
                      initialValue: this.props.savedState.airVendorCode
                        ? this.props.savedState.airVendorCode
                        : ""
                    })(<Input />)}
                  </Form.Item>
                  <Form.Item label="Flight number:" labelAlign="left">
                    {getFieldDecorator("flightNumber", {
                      initialValue: this.props.savedState.flightNumber
                        ? this.props.savedState.flightNumber
                        : ""
                    })(<Input />)}
                  </Form.Item>
                  <Form.Item>
                    <Button type="primary" htmlType="submit">
                      Apply
                    </Button>
                  </Form.Item>
                </Col>
              </Panel>
              <Panel header="By Destination" key="5">
                <Col>
                  <Form.Item label="Destination" labelAlign="left">
                    {getFieldDecorator("byDestination", {
                      initialValue: this.props.savedState.byDestination
                        ? this.props.savedState.byDestination
                        : ""
                    })(<Input />)}
                  </Form.Item>
                  <Form.Item>
                    <Button type="primary" htmlType="submit">
                      Apply
                    </Button>
                  </Form.Item>
                </Col>
              </Panel>
              <Panel header="By PNR Number" key="6">
                <Col>
                  <Form.Item label="PNR Number" labelAlign="left">
                    {getFieldDecorator("pnrNumber", {
                      initialValue: this.props.savedState.pnrNumber
                        ? this.props.savedState.pnrNumber
                        : ""
                    })(<Input />)}
                  </Form.Item>
                  <Form.Item>
                    <Button type="primary" htmlType="submit">
                      Apply
                    </Button>
                  </Form.Item>
                </Col>
              </Panel>
              <Panel header="By Organization" key="7">
                <Col>
                  <Form.Item style={{ marginTop: "-10px" }}>
                    {(Permission.isRole("Administrator") ||
                      Permission.isRole("CSR")) &&
                      getFieldDecorator("parentOrgId", {
                        //initialValue: "all"
                      })(
                        <div>
                          <IntlMessages id="admin.division.parentCompany" />
                          <Select
                            style={{ width: "100%" }}
                            onPopupScroll={this.fetchParentOrganizations}
                            onSearch={this.searchParentOrganizations}
                            onChange={this.onChangeParentOrganizationTree}
                            filterOption={false}
                            value={this.state.parentOrganizationId}
                            showSearch
                          >
                            <Option key="all">All</Option>
                            {this.state.parentOrganizations
                              ? this.state.parentOrganizations.map(function(
                                  item
                                ) {
                                  return (
                                    <Option key={item.organizationId}>
                                      {item.name}{" "}
                                    </Option>
                                  );
                                })
                              : ""}
                          </Select>
                        </div>
                      )}
                  </Form.Item>
                  {this.state.currentSelectedParentOrg !== 0 ? (
                    <Form.Item style={{ marginTop: "-10px" }}>
                      <IntlMessages id="admin.organization.company" />
                      {getFieldDecorator("childOrgId", {
                        initialValue: this.state.childId
                        //initialValue:this.props.savedState.childOrg ? { key: this.props.savedState.childOrg.value, label: this.props.savedState.childOrg.label } :""
                      })(
                        <TreeSelect
                          style={{ width: "100%" }}
                          dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                          onChange={this.onChangeChildOrganizationTree}
                          treeData={this.state.childOrgs}
                          labelInValue={true}
                          treeDefaultExpandAll={true}
                        />
                      )}
                    </Form.Item>
                  ) : (
                    ""
                  )}
                  <Form.Item>
                    <Button type="primary" htmlType="submit">
                      Apply
                    </Button>
                  </Form.Item>
                </Col>
              </Panel>
            </Collapse>
          </Row>
          <Row gutter={24} style={{ marginTop: "20px" }}>
            {/* <Col>
            <Card
              title="By Agent"
              bordered={false}
              extra={<i class="fas fa-user-secret"></i>}
            >
              <Form.Item label="Agent initial(s) :" labelAlign="left">
                {getFieldDecorator("byAgent")(<Input />)}
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Apply
                </Button>
              </Form.Item>
            </Card>
          </Col> */}
          </Row>
        </Form>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...OrgActions, ...itiniaryActions }, dispatch);
}
const WrappedForm = Form.create({ name: "travelfilter" })(TravelFilter);

export default withRouter(
  connect(null, mapDispatchToProps, null, { forwardRef: true })(WrappedForm)
);
