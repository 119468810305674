import Axios from "../../services/axios-service";


const getItinerary = data => {
  return Axios.get("/api/TravellerIternaryList?search=" + encodeURIComponent(JSON.stringify(data)), undefined);
};

const getManualItinerary = data => {
  return Axios.get("/api/ManaulItineraryList?search=" + encodeURIComponent(JSON.stringify(data)), undefined);
};

const addItinerary = (data) => {
  return Axios.post("/api/TravellerItinerary", data, undefined);
};
const getAirportCodes = (data) => {
  return Axios.get("/api/AirportCodes?search=" + encodeURIComponent(JSON.stringify(data)), undefined, undefined);
};
const getItineraryById = (idx) => {
  return Axios.get("api/TravellerIternaryInfo/" + idx, undefined, undefined);
};
const getManualItineraryById = (idx) => {
  return Axios.get("api/ManualIternaryInfo/" + idx, undefined, undefined);
};
const updateItineraryById = (idx, params) => {
  return Axios.put("api/TravellerIternary/" + idx, params, undefined, undefined);
};
const updateManualItinerarybyId = (idx, params) => {
  return Axios.put("api/ManualIternary/" + idx, params, undefined, undefined);
};
const getItineraryAdmin = (idx, data) => {
  return Axios.get("api/OrganizationItineraries/" + idx + "?search=" + encodeURIComponent(JSON.stringify(data)), undefined, undefined);
};

const getManualItineraryAdmin = (idx, data) => {
  return Axios.get("api/GetOrganizationItineraries/" + idx + "?search=" + encodeURIComponent(JSON.stringify(data)), undefined, undefined);
}

const getItineraryByFlightNumber = (AirlineCode,FlightNumber, DepartureDate) => {
  return Axios.get("api/FlightInfo/"+ AirlineCode + "/" + FlightNumber + "/" + DepartureDate, undefined, undefined);
};
const saveManualItinerary = (data) => {
  return Axios.post("/api/ManualItinerary", data, undefined);
};
const updateItineraryStatusById = (data) => {
  return Axios.put("/api/UpdateManualIternaryStatus", data, undefined,);
};
const AddFlightSegment = (id, data) => {
  return Axios.post("/api/ManualIternary/AddFlightSegment/" + id, data , undefined,);
};


// /api/OrganizationItineraries/{orgId}/{search}
export default {
  getItinerary,
  addItinerary,
  getAirportCodes,
  getItineraryById,
  updateItineraryById,
  getItineraryAdmin,
  getItineraryByFlightNumber,
  saveManualItinerary,
  getManualItinerary,
  getManualItineraryById,
  getManualItineraryAdmin,
  updateManualItinerarybyId,
  updateItineraryStatusById,
  AddFlightSegment
};

