import { Types } from "../constants/b2c-types";
import API from "../api/b2c-api";
import config from "../../../../config";
import { setWithExpiry, getWithExpiry } from "../../components/shared/apputil";

export function ClearData() {
  return async function(dispatch, getState) {
    try {
      localStorage.clear();
      dispatch({ type: Types.CLEAR_DATA });
    } catch (e) {}
  };
}

export function getPlanInformation() {
  return async function(dispatch, getState) {
    try {
      // let planType = JSON.parse(localStorage.getItem("planType"));
      let planType = getWithExpiry("planType");
      dispatch({ type: Types.SAVE_PLAN_TYPE, payload: planType });
    } catch (e) {}
  };
}

export function getPlanTermInformation() {
  return async function(dispatch, getState) {
    try {
      // let planTerm = JSON.parse(localStorage.getItem("planTerm"));
      let planTerm = getWithExpiry("planTerm");
      dispatch({ type: Types.SAVE_PLAN_TERM, payload: planTerm });
    } catch (e) {}
  };
}

export function getPersonalInformation() {
  return async function(dispatch, getState) {
    try {
      // let personalInformation = JSON.parse(localStorage.getItem("traveller"));
      let personalInformation = getWithExpiry("traveller");
      dispatch({
        type: Types.SAVE_PERSONAL_DETAIL,
        payload: personalInformation,
      });
    } catch (e) {}
  };
}

export function getBillingInformation() {
  return async function(dispatch, getState) {
    try {
      // let billingInformation = JSON.parse(localStorage.getItem("billing_information"));
      let billingInformation = getWithExpiry("billing_information");
      dispatch({
        type: Types.SAVE_BILLING_DETAIL,
        payload: billingInformation,
      });
    } catch (e) {}
  };
}

export function SaveStep(step) {
  return async function(dispatch, getState) {
    try {
      localStorage.setItem("step", step);
      dispatch({ type: Types.SAVE_STEP, payload: step });
    } catch (e) {}
  };
}

export function getStep() {
  return async function(dispatch, getState) {
    try {
      var step = localStorage.getItem("step");
      if (step) dispatch({ type: Types.SAVE_STEP, payload: step });
      else dispatch({ type: Types.SAVE_STEP, payload: "0" });
    } catch (e) {}
  };
}

export function SavePlanType(planType) {
  return async function(dispatch, getState) {
    try {
      // localStorage.setItem("planType",JSON.stringify(planType));
      setWithExpiry("planType", planType);
      dispatch({ type: Types.SAVE_PLAN_TYPE, payload: planType });
    } catch (e) {}
  };
}

export function SavePlanTerm(planTerm) {
  return async function(dispatch, getState) {
    try {
      // localStorage.setItem("planTerm",JSON.stringify(planTerm));
      setWithExpiry("planTerm", planTerm);
      dispatch({ type: Types.SAVE_PLAN_TERM, payload: planTerm });
    } catch (e) {}
  };
}

export function SavePersonalDetails(personalDetails) {
  return async function(dispatch, getState) {
    try {
      // localStorage.setItem("traveller",JSON.stringify(personalDetails));
      setWithExpiry("traveller", personalDetails);
      setWithExpiry("traveller-type", "b2c");
      dispatch({ type: Types.SAVE_PERSONAL_DETAIL, payload: personalDetails });
    } catch (e) {}
  };
}

export function SaveBillingInfornation(billingInformation) {
  return async function(dispatch, getState) {
    try {
      // localStorage.setItem("billing_information",JSON.stringify(billingInformation));
      setWithExpiry("billing_information", billingInformation);
      dispatch({
        type: Types.SAVE_BILLING_DETAIL,
        payload: billingInformation,
      });
    } catch (e) {}
  };
}
export function getAppliedPromo() {
  return async function(dispatch, getState) {
    try {
      let promoData = JSON.parse(localStorage.getItem("promo"));
      dispatch({ type: Types.SAVE_PROMO_INFO, payload: promoData });
    } catch (e) {}
  };
}

export function applyPromoCode(promoCode) {
  return async function(dispatch, getState) {
    try {
      let promoData = await API.applyPromoCode(promoCode);
      if (promoData.data) {
        localStorage.setItem("promo", JSON.stringify(promoData.data));
        dispatch({ type: Types.SAVE_PROMO_INFO, payload: promoData.data });
        dispatch({ type: Types.COUPON_APPLIED_STATUS, payload: true });
      } else {
        localStorage.removeItem("promo");
        dispatch({ type: Types.SAVE_PROMO_INFO, payload: {} });
        dispatch({ type: Types.COUPON_APPLIED_STATUS, payload: false });
      }
    } catch (e) {}
  };
}

export function removePromoCode(promoCode) {
  return async function(dispatch, getState) {
    try {
      localStorage.removeItem("promo");
      dispatch({ type: Types.SAVE_PROMO_INFO, payload: {} });
      dispatch({ type: Types.COUPON_APPLIED_STATUS, payload: false });
    } catch (e) {}
  };
}

export function placeOrder(objOrder) {
  return async function(dispatch, getState) {
    try {
      let responseOrder = await API.placeOrder(objOrder);
      if (responseOrder.data && responseOrder.data.status === 200) {
        localStorage.setItem(
          "order_response",
          JSON.stringify(responseOrder.data)
        );
        localStorage.setItem("b2c_order_amount", objOrder["TotalOrderAmount"]);
        dispatch({
          type: Types.RESPONSE_ORDER_DATA,
          payload: responseOrder.data,
        });
      } else {
        dispatch({
          type: Types.RESPONSE_ERROR_ORDER_DATA,
          payload: responseOrder.data,
        });
      }
    } catch (e) {
      console.log("Order error : " + JSON.stringify(e));
    }
  };
}

export function placeOrderPaypal(responseOrder) {
  return async function(dispatch, getState) {
    try {
      //let responseOrder = await API.placeOrder(objOrder);
      if (responseOrder.data && responseOrder.data.status === 200) {
        localStorage.setItem(
          "order_response",
          JSON.stringify(responseOrder.data)
        );
        //localStorage.setItem("b2c_order_amount", objOrder['TotalOrderAmount']);
        dispatch({
          type: Types.RESPONSE_ORDER_DATA,
          payload: responseOrder.data,
        });
      } else {
        dispatch({
          type: Types.RESPONSE_ERROR_ORDER_DATA,
          payload: responseOrder.data,
        });
      }
    } catch (e) {
      console.log("Order error : " + JSON.stringify(e));
    }
  };
}

export function validateTravellerEmail(email) {
  return async function(dispatch, getState) {
    try {
      let emailResponse = await API.validateTravellerEmail(email);
      dispatch({ type: Types.IS_VALID_EMAIL, payload: emailResponse });
      return emailResponse;
    } catch (e) {}
  };
}

export function flushEmailCheckResponse() {
  return async function(dispatch, getState) {
    try {
      dispatch({ type: Types.FLUSH_EMAIL_CHECK_RESPONSE });
    } catch (e) {}
  };
}

export function getTravellerInfo(userId) {
  return async function(dispatch, getState) {
    try {
      let travellerInfo = await API.getTravellerInfo(userId);
      localStorage.setItem(
        "tarveller_info",
        JSON.stringify(travellerInfo.data)
      );
      dispatch({
        type: Types.SAVE_TRAVELLER_INFO,
        payload: travellerInfo.data,
      });
    } catch (e) {}
  };
}

export function getProductPricingAndInfo() {
  return async function(dispatch, getState) {
    try {
      let productPriceAndInfo = await API.getProductPricingAndInfo();
      dispatch({
        type: Types.PRODUCT_PRICE_LIST,
        payload: productPriceAndInfo.productPriceList,
      });
      dispatch({
        type: Types.STATE_PRODUCT_PRICE_LIST,
        payload: productPriceAndInfo.stateProductPriceList,
      });
      dispatch({
        type: Types.ALL_US_STATES,
        payload: productPriceAndInfo.states,
      });
      dispatch({
        type: Types.PRODUCT_INFO,
        payload: productPriceAndInfo.productList,
      });
      let countries = productPriceAndInfo.countries.filter(
        (data) => data.countryId !== config.allCountriesCountryId
      );
      countries.unshift({
        countryId: config.allCountriesCountryId,
        countryCode: config.allCountriesCountryCode,
        countryName: "All Countries",
        tierId: 1,
      });
      dispatch({ type: Types.COUNTRY_TIER_INFO, payload: countries });
      dispatch({
        type: Types.STATE_TIER_INFO,
        payload: productPriceAndInfo.states,
      });
      dispatch({
        type: Types.DAYS_LIST,
        payload: productPriceAndInfo.dayPackages,
      });
      return productPriceAndInfo;
    } catch (e) {}
  };
}

export function flushOrderData() {
  return async function(dispatch, getState) {
    try {
      dispatch({ type: Types.FLUSH_ORDER_DATA });
    } catch (e) {}
  };
}

export function getAllCountries() {
  return async function(dispatch, getState) {
    try {
      let countries = await API.getAllCountries();
      dispatch({
        type: Types.ALL_COUNTRIES,
        payload: countries && countries.countries ? countries.countries : [],
      });
      return countries;
    } catch (e) {}
  };
}

export function updateCurrency(currencyType, currentCurrencyRate) {
  return async function(dispatch, getState) {
    try {
      dispatch({
        type: Types.UPDATE_CURRENCY,
        payload: {
          currencyType: currencyType,
          currentCurrencyRate: currentCurrencyRate,
        },
      });
    } catch (e) {}
  };
}

export function travellerCount(val) {
  return async function(dispatch, getState) {
    try {
      dispatch({
        type: Types.UPDATE_TRAVELER_COUNT,
        payload: { val },
      });
    } catch (e) {}
  };
}

export function convertCurrency() {
  return async function(_dispatch, _getState) {
    try {
      //static conversion 498 /365
      return { code: "CAD", value: 1.3644 };
      //return await API.convertCurrency();
    } catch (e) {
      return { error: true };
    }
  };
}

export function getGendersList() {
  return async function(_dispatch, _getState) {
    try {
      return await API.getGendersList();
    } catch (e) {
      return { error: true };
    }
  };
}

//mdf email exit
export function EmployeeExits(email) {
  return async function(dispatch, getState) {
    try {
      let emailResponse = await API.EmployeeExits(email);
      dispatch({ type: Types.IS_VALID_EMAIL, payload: emailResponse });
      return emailResponse;
    } catch (e) {
      return { status: false, message: "User is not active"  };
    }
  };
}

//mdf Check Employee Child exit
export function CheckEmployeeChild(email) {
  return async function(dispatch, getState) {
    try {
      let emailResponse = await API.CheckEmployeeChild(email);
      dispatch({ type: Types.IS_VALID_EMAIL, payload: emailResponse });
      return emailResponse;
    } catch (e) {
      return { status: false, message: "User with email id ${email} is already registered. Please remove this to proceed further"  };
    }
  };
}

//mdf Check Employee submit
export function EmployeeSubmitOrder(objOrder) {
  return async function(dispatch, getState) {
    try {
      let ordeResp = await API.EmployeeSubmitOrder(objOrder);
      console.log(ordeResp)
      return ordeResp;
    } catch (e) {
      console.log(e, "action")
      return e;
      // return { status: false, message: e  };
    }
  };
}

