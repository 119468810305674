import React, { Component } from "react";
import ActivityLogs from "../../audit-logs/audit-logs";

class ActivityLog extends Component {
 

  render() {
    return (
      <ActivityLogs for="travellers"/>
      );
  }
}

export default ActivityLog;