import React from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import IntlMessages from "../../../../../services/intlMesseges";
import * as userActions from "../../../../../redux/actions/user-actions";
import * as RolesActions from "../../../../../redux/actions/roles-actions";
import * as OrgActions from "../../../../../redux/actions/organization-actions";
import API from "../../../../../redux/api/admin-api";
import { notifyUser } from "../../../../../services/notification-service";
import OrgTree from "../../../../shared/components/orgTree";
import * as Permission from "../../../../../services/permissions";
import AppLocale from "../../../../../languageProvider";
import * as travellerActions from "../../../../../redux/actions/travellers-actions";
import ActivityLogs from "./activitylogs";
import EmployeesMap from "./employees-map";
import CheckInList from "./checkin-list";
import AssistList from "./assist-list";
import {
  Typography,
  Form,
  Input,
  Select,
  Button,
  Row,
  Col,
  Spin,
  DatePicker,
  TreeSelect,
  Checkbox,
  Modal,
  Tabs,
} from "antd";
import moment from "moment";
const { confirm } = Modal;
const dateFormat = "MM/DD/YYYY";
const dateFormatY = "YYYY/MM/DD";
const { Option } = Select;
const { TabPane } = Tabs;

class EditB2B2COrganizationsTravel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentSelectedRole: null,
      dataLoaded: false,
      fetching: false,
      loading: true,
      confirmDirty: false,
      userId: 0,
      companyId: 0,
      displayEmployeesAssignedRow: "none",
      defaultAssignedOrgs: [],
      divisionList: [],
      countries: [],
      allRoles: [],
      assignedDivisionsAuto: false,
    };
  }

  async componentDidMount() {
    if (this.state.dataLoaded === false) {
      let _companyIdTemp = 0;
      let countriesListTemp = await API.getCountriesList();
      let userRolesList = await this.props.getAllExternalRoles();
      this.setState({
        allRoles: userRolesList,
        countries: countriesListTemp.countries,
      });
      if (
        this.props.match &&
        this.props.match &&
        this.props.match.params &&
        this.props.match.params.id &&
        this.props.match.params.id !== "new"
      ) {
        const user = await this.props.getUser(this.props.match.params.id);
        if (user.error && user.error.message) {
          this.props.history.goBack();
          notifyUser(user.error.message, "error");
        } else {
          this.setState(user.data);
          _companyIdTemp = user.data.companyId;
          this.setState({ currentSelectedRole: user.data.role });
          if (
            user.data &&
            user.data.role &&
            user.data.role.indexOf("Division Manager") > -1
          ) {
            this.setState({ displayEmployeesAssignedRow: "block" });
          }
          if (
            this.props.history.location.state &&
            this.props.history.location.state.divisionChanged
          ) {
            this.setState({ defaultAssignedOrgs: [] });
          } else {
            var tempDefaultOrgs = [];
            if (user.data && user.data.assignedOrganizations) {
              for (var o = 0; o < user.data.assignedOrganizations.length; o++) {
                tempDefaultOrgs.push({
                  key: user.data.assignedOrganizations[o].branchId,
                  value: user.data.assignedOrganizations[o].branchId,
                  label: user.data.assignedOrganizations[o].branchName,
                });
              }
            }
            this.setState({ defaultAssignedOrgs: tempDefaultOrgs });
          }
        }
      } else {
        _companyIdTemp =
          this.props.match.params.divid && this.props.match.params.divid > 0
            ? this.props.match.params.divid
            : this.props.match.params.orgid;
        this.setState({
          companyId: _companyIdTemp,
        });
      }
      this.loadDivisions(_companyIdTemp);
      this.setState({ loading: false, dataLoaded: true });
    }
  }

  loadDivisions = (orgId) => {
    this.props.getDivisionsList(orgId).then((resp) => {
      let parentId;
      for (var i in resp.data) {
        if (resp.data[i].organizationId == orgId) {
          parentId = resp.data[i].parentOrgId;
        }
      }
      let list = this.getNestedChildren(resp.data, parentId);
      this.setState({
        divisionList: list,
      });
    });
  };

  getNestedChildren = (arr, parent) => {
    var out = [];
    for (var i in arr) {
      if (arr[i].parentOrgId == parent) {
        var children = this.getNestedChildren(arr, arr[i].organizationId);
        if (children.length) {
          arr[i].children = children;
        }
        arr[i].title = arr[i].name;
        arr[i].value = arr[i].organizationId;
        arr[i].label = arr[i].name;
        arr[i].key = arr[i].organizationId;
        out.push(arr[i]);
      }
    }
    return out;
  };

  submitFormAdd = (data) => {
    const { language } = this.props;
    const currentAppLocale = AppLocale[language.locale];
    this.props
      .updateUser(data)
      .then((response) => {
        if (response.error) {
          notifyUser(response.error.message, "error");
          this.setState({ loading: false });
        } else {
          notifyUser(
            currentAppLocale.messages["admin.traveler.add"],
            "success"
          );
          this.props.history.push("./../");
          this.setState({ loading: false });
        }
      })
      .catch((_err) => {
        this.setState({ loading: false });
      });
  };

  submitFormEdit = (data) => {
    const { language } = this.props;
    const currentAppLocale = AppLocale[language.locale];
    this.props
      .updateUser(data)
      .then((response) => {
        if (response.error) {
          notifyUser(response.error.message, "error");
          this.setState({ loading: false });
        } else {
          this.props.history.push("./../");
          this.setState({ loading: false });
          notifyUser(
            currentAppLocale.messages["admin.employee.edit"],
            "success"
          );
        }
      })
      .catch((_err) => {
        this.setState({ loading: false });
      });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        Object.keys(values).map(function(key, _idx) {
          if (values[key] == null) {
            values[key] = "";
          } else {
            if (typeof values[key] == "string") {
              values[key] = values[key].trim();
            }
          }
        });
        this.setState({ loading: true });
        if (this.state.userId && this.state.userId > 0) {
          values["userId"] = this.state.userId;
          values["identityId"] = this.state.identityId;
          values["assignedOrganizations"] =
            this.state.assignedOrganizations || [];
          values["companyId"] = this.state.companyId;
          this.submitFormEdit(values);
        } else {
          values["assignedOrganizations"] =
            this.state.assignedOrganizations || [];
          values["companyId"] = this.state.companyId;
          this.submitFormAdd(values);
        }
      }
    });
  };

  onRolesChange = (selected) => {
    let selectedRole = this.state.allRoles.find((role) => role.id === selected);
    if (
      selectedRole.name &&
      selectedRole.name.indexOf("Division Manager") > -1
    ) {
      this.setState({
        displayEmployeesAssignedRow: "block",
        currentSelectedRole: selectedRole.name,
      });
    } else {
      this.setState({
        displayEmployeesAssignedRow: "none",
        currentSelectedRole: selectedRole.name,
      });
    }
  };

  onOrganizationSelect = (_idx, option) => {
    let assignedOrganizationsList = this.state.assignedOrganizations || [];
    assignedOrganizationsList.push({
      branchId: option.props.value,
      branchName: option.props.name,
    });
    this.setState({ assignedOrganizations: assignedOrganizationsList });
  };

  onRemoveOrganizations = (bid) => {
    let assignedOrganizationsList = this.state.assignedOrganizations;
    let removedOrg = this.state.assignedOrganizations.findIndex(
      (e) => e.branchId === bid
    );
    assignedOrganizationsList.splice(removedOrg, 1);
    this.setState({ assignedOrganizations: assignedOrganizationsList });
  };

  onChangeOrganizationTree = (selected) => {
    let _this = this;
    if (
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.id &&
      this.props.match.params.id == "new"
    ) {
      /* if(selected == this.props.match.params.orgid){
        this.props.history.push('../../../../../edit/'+selected+'/employees/new/', {divisionChanged: true});
      } else {
        if(this.props.match.params.divid > 0){
          this.props.history.push('../../../'+selected+'/employees/new/', {divisionChanged: true});
        } else {
          this.props.history.push('../../../../' +this.props.match.params.orgid + '/divisions/'+selected+'/employees/new/', {divisionChanged: true});
        }
      } */
      _this.loadDivisions(selected);
      _this.setState({ companyId: selected });
    } else {
      /* if(selected == this.props.match.params.orgid){
        this.props.history.push('../../../../../edit/'+selected+'/employees/'+this.props.match.params.id+"/", {divisionChanged: true});
      } else {
        if(this.props.match.params.divid > 0){
          this.props.history.push('../../../'+selected+'/employees/'+this.props.match.params.id+"/", {divisionChanged: true});
        } else {
          this.props.history.push('../../../../' +this.props.match.params.orgid + '/divisions/'+selected+'/employees/'+this.props.match.params.id+"/", {divisionChanged: true});
        }
      } */
      if (
        this.state.currentSelectedRole === "Division Manager" &&
        this.state.assignedOrganizations !== null &&
        this.state.assignedOrganizations.length > 0
      ) {
        const { language } = this.props;
        const currentAppLocale = AppLocale[language.locale];
        confirm({
          title:
            currentAppLocale.messages["admin.confirm.divisionchange.title"],
          content:
            currentAppLocale.messages["admin.confirm.divisionchange.desc"],
          onOk() {
            _this.loadDivisions(selected);
            _this.setState({
              companyId: selected,
              defaultAssignedOrgs: [],
              assignedOrganizations: [],
            });
            _this.props.form.setFieldsValue({ assignedOrganizations: [] });
          },
          onCancel() {
            _this.props.history.replace(_this.props.location.pathname);
          },
        });
      } else {
        _this.loadDivisions(selected);
        _this.setState({ companyId: selected });
      }
    }
  };

  onChangeAssignedOrganizations = (selected) => {
    let _selected = [];
    if (selected.length > 0) {
      selected.map(function(el) {
        _selected.push({
          branchId: el.value,
        });
      });
    }
    this.setState({ assignedOrganizations: _selected });
  };

  setAssignedDivisionsAuto = (e) => {
    this.setState({ assignedDivisionsAuto: e.target.checked });
  };

  handleContactExistance(rule, value, callback) {
    //const re = /^[0-9']+$/;
    const re = /^(?=[+0-9]*$)(?!.*[<>'"/;`%~@#$^*()_=[\]{}|\\,.?: -])/;
    var test = re.test(value);
    if (
      value !== undefined &&
      value !== "" &&
      (value.length < 6 || test === false)
    ) {
      callback(<IntlMessages id="admin.phonenumber.valid"></IntlMessages>);
    } else {
      callback();
    }
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const { formLayout } = this.state;
    const formItemLayout =
      formLayout === "horizontal"
        ? {
            labelCol: { span: 4 },
            wrapperCol: { span: 14 },
          }
        : null;
    let roleExists = -1;
    roleExists = this.state.allRoles.findIndex(
      (el) => this.state.roleId === el.id
    );
    if (roleExists > -1) {
      roleExists = true;
    } else {
      roleExists = false;
    }
    const countriesSelector = getFieldDecorator("countryId", {
      initialValue: this.state.countryId,
    })(
      <Select
        showSearch
        filterOption={(input, option) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {this.state.countries.map(function(item) {
          return (
            <Option key={item.countryId} value={item.countryId}>
              {item.countryName}
            </Option>
          );
        })}
      </Select>
    );
    const actionButtons = (
      <Button
        type="primary"
        className="right-fl"
        htmlType="button"
        onClick={() =>
          this.props.location.state && this.props.location.state.oldPath
            ? this.props.history.push(this.props.location.state.oldPath)
            : this.props.history.goBack()
        }
      >
        <IntlMessages id="admin.userlisting.back" />
      </Button>
    );
    return (
      <div>
        <Tabs tabBarExtraContent={actionButtons}>
          <TabPane
            key={1}
            tab={
              this.state.userId > 0 ? (
                <IntlMessages id='admin.b2b2c.edit.travelor' />
              ) : (
                <IntlMessages id='admin.b2b2c.add.travelor' />
              )
            }
          >
            <Row gutter={24}>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Typography.Title level={4}>
                  {this.state.userId > 0 ? (
                    <IntlMessages id='admin.b2b2c.edit.travelor' />
                  ) : (
                    <IntlMessages id='admin.b2b2c.add.travelor' />
                  )}
                </Typography.Title>
              </Col>
              {/* <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Button
                  type="primary"
                  className="right-fl"
                  htmlType="button"
                  onClick={() =>
                    this.props.location.state &&
                    this.props.location.state.oldPath
                      ? this.props.history.push(
                          this.props.location.state.oldPath
                        )
                      : this.props.history.goBack()
                  }
                >
                  <IntlMessages id="admin.userlisting.back" />
                </Button>
              </Col> */}
            </Row>
            <hr />
            <div>
              <Spin spinning={this.state.loading}>
                <Form layout={formLayout} onSubmit={this.handleSubmit}>
                  {/* <Row gutter={24} style={{ paddingBottom: "20px" }}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <div style={{ float: "left", paddingTop: "5px" }}>
                        <IntlMessages id="admin.organization.company" />
                        :&nbsp;
                      </div>
                      <div style={{ float: "left", minWidth: "320px" }}>
                        {this.props.match &&
                        this.props.match.params.id &&
                        this.props.match.params.id === "new"
                          ? getFieldDecorator(
                              "parentOrganization",
                              {}
                            )(
                              <OrgTree
                                parent={
                                  this.props.match.params.divid
                                    ? this.props.match.params.divid
                                    : this.props.match.params.orgid
                                }
                                organizationId={
                                  this.props.match.params.orgid
                                    ? this.props.match.params.orgid
                                    : this.props.match.params.divid
                                }
                                onChange={this.onChangeOrganizationTree}
                              />
                            )
                          : ""}

                        {this.props.match &&
                        this.props.match.params.id &&
                        this.props.match.params.id !== "new" &&
                        this.state.companyId
                          ? getFieldDecorator(
                              "parentOrganization",
                              {}
                            )(
                              <OrgTree
                                parent={this.state.companyId}
                                organizationId={
                                  this.props.match.params.orgid
                                    ? this.props.match.params.orgid
                                    : this.props.match.params.divid
                                }
                                onChange={this.onChangeOrganizationTree}
                              />
                            )
                          : ""}
                      </div>
                    </Col>
                  </Row> */}
                  <Row gutter={24}>
                    {/* <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                      <Form.Item
                        {...formItemLayout}
                        label={<IntlMessages id="admin.travelmanager.id" />}
                      >
                        {getFieldDecorator("employeeId", {
                          rules: [
                            {
                              required: true,
                              message: (
                                <IntlMessages id="admin.input.required" />
                              )
                            }
                          ],
                          initialValue:
                            this.state.employeeId === null
                              ? ""
                              : this.state.employeeId
                        })(<Input />)}
                      </Form.Item>
                    </Col> */}
                    <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                      <Form.Item {...formItemLayout} label={<IntlMessages id='admin.userlisting.firstName' />}>
                        {getFieldDecorator('firstName', {
                          rules: [
                            {
                              whitespace: true,
                              required: true,
                              message: <IntlMessages id='admin.input.required' />
                            },
                            {
                              pattern: new RegExp(/^(\S.*)[a-zA-Z-.']+$/),
                              message: <IntlMessages id='admin.name.valid'></IntlMessages>
                            }
                          ],
                          initialValue: this.state.firstName === null ? '' : this.state.firstName
                        })(<Input maxLength={50} />)}
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                      <Form.Item {...formItemLayout} label={<IntlMessages id='admin.userlisting.lastName' />}>
                        {getFieldDecorator('lastName', {
                          rules: [
                            {
                              whitespace: true,
                              required: true,
                              message: <IntlMessages id='admin.input.required' />
                            },
                            {
                              pattern: new RegExp(/^(\S.*)[a-zA-Z-.']+$/),
                              message: <IntlMessages id='admin.lname.valid'></IntlMessages>
                            }
                          ],
                          initialValue: this.state.lastName === null ? '' : this.state.lastName
                        })(<Input maxLength={50} />)}
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                      <Form.Item {...formItemLayout} label={<IntlMessages id='admin.userlisting.email' />}>
                        {getFieldDecorator('userName', {
                          rules: [
                            {
                              type: 'email',
                              message: <IntlMessages id='admin.email.valid'></IntlMessages>
                            },
                            {
                              required: true,
                              message: <IntlMessages id='admin.input.required' />
                            }
                          ],
                          initialValue: this.state.email === null ? '' : this.state.userName
                        })(this.state.userId === 0 || Permission.emailEditAllowded() ? <Input /> : <Input disabled />)}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={24}>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                      <Form.Item {...formItemLayout} label={<IntlMessages id='admin.userlisting.phonenumber' />}>
                        {getFieldDecorator('contactNo', {
                          rules: [
                            {
                              validator: this.handleContactExistance
                            },
                            {
                              whitespace: true,
                              required: true,
                              message: <IntlMessages id='admin.input.required' />
                            }
                            // {
                            //   pattern: new RegExp(/^[0-9']+$/),
                            //   message: 'Please enter a valid number!'
                            // },
                            // {
                            //   max: 12,
                            //   message: "Please enter a valid number!"
                            // },
                            // {
                            //   min: 8,
                            //   message: "Please enter a valid number!"
                            // }
                          ],
                          initialValue: this.state.contactNo === null ? '' : this.state.contactNo
                        })(<Input maxLength={15} style={{ width: '100%' }} />)}
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                      <Form.Item {...formItemLayout} label={<IntlMessages id='admin.employee.dob' />}>
                        {getFieldDecorator('dateOfBirth', {
                          rules: [
                            {
                              required: true,
                              message: <IntlMessages id='admin.input.required' />
                            }
                          ],
                          initialValue:
                            this.state.dateOfBirth != null ? moment(this.state.dateOfBirth, dateFormatY) : ''
                        })(
                          <DatePicker
                            disabledDate={(current) => {
                              return current && current > moment().subtract(1, 'days');
                            }}
                            placeholder='-select-'
                            format={dateFormat}
                          />
                        )}
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                      <Form.Item {...formItemLayout} label={<IntlMessages id='admin.userlisting.address' />}>
                        {getFieldDecorator('address', {
                          initialValue: this.state.address === null ? '' : this.state.address
                        })(<Input />)}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={24}>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                      <Form.Item label={<IntlMessages id='admin.userlisting.country' />}>{countriesSelector}</Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                      <Form.Item label={<IntlMessages id='admin.primaryaddress.state' />}>
                        {getFieldDecorator('state', {
                          initialValue: this.state.state === null ? '' : this.state.state
                        })(<Input />)}
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                      <Form.Item {...formItemLayout} label={<IntlMessages id='admin.userlisting.role' />}>
                        {getFieldDecorator('roleId', {
                          rules: [
                            {
                              required: true,
                              message: <IntlMessages id='admin.input.required' />
                            }
                          ],
                          initialValue: roleExists ? this.state.roleId : null
                        })(
                          <Select placeholder='-select-' onChange={this.onRolesChange}>
                            {this.state.allRoles.map(function(item) {
                              if (item.name.toLowerCase().indexOf('hybrid') > -1) {
                                return <Option key={item.id}>{item.name}</Option>;
                              }
                            })}
                          </Select>
                        )}
                      </Form.Item>
                    </Col>
                  </Row>

                  {this.state.displayEmployeesAssignedRow == 'block' ? (
                    <Row
                      gutter={24}
                      style={{
                        display: this.state.displayEmployeesAssignedRow
                      }}
                    >
                      <Col xs={24} sm={24} md={16} lg={16} xl={16}>
                        <Form.Item label={<IntlMessages id='admin.organization.assigned.organizations' />}>
                          {getFieldDecorator('assignedOrganizations', {
                            rules: [
                              {
                                required: true,
                                message: <IntlMessages id='admin.input.required' />
                              }
                            ],
                            initialValue: this.state.defaultAssignedOrgs
                          })(
                            <TreeSelect
                              treeData={this.state.divisionList}
                              style={{ width: '100%' }}
                              dropdownStyle={{
                                maxHeight: 400,
                                overflow: 'auto'
                              }}
                              onChange={this.onChangeAssignedOrganizations}
                              placeholder='Please select'
                              treeCheckable={true}
                              treeCheckStrictly={!this.state.assignedDivisionsAuto}
                              labelInValue={true}
                              showCheckedStrategy={TreeSelect.SHOW_ALL}
                              treeDefaultExpandAll
                            />
                          )}
                          <Checkbox
                            checked={this.state.assignedDivisionsAuto}
                            onChange={this.setAssignedDivisionsAuto}
                          />{' '}
                          <IntlMessages id='admin.autoselect.childdivisions' />
                        </Form.Item>
                      </Col>
                    </Row>
                  ) : (
                    ''
                  )}

                  <div className='clear'>&nbsp;</div>
                  <Typography.Title level={3}>
                    <IntlMessages id='admin.employee.alternate.contact' />
                  </Typography.Title>
                  <Row gutter={24}>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                      <Form.Item {...formItemLayout} label={<IntlMessages id='admin.userlisting.firstName' />}>
                        {getFieldDecorator('emergencyFirstName', {
                          rules: [
                            {
                              pattern: new RegExp(/^(\S.*)[a-zA-Z-.']+$/),
                              message: <IntlMessages id='admin.name.valid'></IntlMessages>
                            }
                          ],
                          initialValue: this.state.emergencyFirstName === null ? '' : this.state.emergencyFirstName
                        })(<Input maxLength={50} />)}
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                      <Form.Item {...formItemLayout} label={<IntlMessages id='admin.userlisting.lastName' />}>
                        {getFieldDecorator('emergencyLastName', {
                          rules: [
                            {
                              pattern: new RegExp(/^(\S.*)[a-zA-Z-.']+$/),
                              message: <IntlMessages id='admin.lname.valid'></IntlMessages>
                            }
                          ],
                          initialValue: this.state.emergencyLastName === null ? '' : this.state.emergencyLastName
                        })(<Input maxLength={50} />)}
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                      <Form.Item {...formItemLayout} label={<IntlMessages id='admin.userlisting.email' />}>
                        {getFieldDecorator('emergencyEmail', {
                          rules: [
                            {
                              type: 'email',
                              message: <IntlMessages id='admin.email.valid'></IntlMessages>
                            }
                          ],
                          initialValue: this.state.emergencyEmail === null ? '' : this.state.emergencyEmail
                        })(this.state.emergencyEmail === 0 ? <Input /> : <Input />)}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={24}>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                      <Form.Item {...formItemLayout} label={<IntlMessages id='admin.primaryaddress.phonenumber' />}>
                        {getFieldDecorator('emergencyContactNo', {
                          rules: [
                            {
                              validator: this.handleContactExistance
                            }
                            // {
                            //   pattern: new RegExp(/^[0-9']+$/),
                            //   message: 'Please enter a valid number!'
                            // },
                            // {
                            //   max: 12,
                            //   message: "Please enter a valid number!"
                            // },
                            // {
                            //   min: 8,
                            //   message: "Please enter a valid number!"
                            // }
                          ],
                          initialValue: this.state.emergencyContactNo === null ? '' : this.state.emergencyContactNo
                        })(<Input maxLength={15} style={{ width: '100%' }} />)}
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                      <Form.Item {...formItemLayout} label={<IntlMessages id='admin.manageTravellers.Realationship' />}>
                        {getFieldDecorator('emergencyRelationship', {
                          rules: [
                            {
                              pattern: new RegExp(/^(\S.*)[a-zA-Z-.']+$/),
                              message: <IntlMessages id='admin.relation.valid'></IntlMessages>
                            }
                          ],
                          initialValue:
                            this.state.emergencyRelationship === null ? '' : this.state.emergencyRelationship
                        })(<Input />)}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Item>
                        <Button
                          type='primary'
                          style={{
                            display: 'inline-block',
                            marginRight: '10px'
                          }}
                          className='def-blue'
                          htmlType='submit'
                        >
                          {this.state.userId > 0 ? (
                            <IntlMessages id='admin.userlisting.update' />
                          ) : (
                            <IntlMessages id='admin.userlisting.add' />
                          )}
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                  {this.state.locationFound && this.state.userId > 0 && <div></div>}
                </Form>
              </Spin>
            </div>
          </TabPane>
          {this.props.match && this.props.match.params && this.props.match.params.id !== 'new' ? (
            <TabPane tab='Activity Logs' key={2}>
              <ActivityLogs />
            </TabPane>
          ) : (
            ''
          )}
          {this.props.match.params.id !== 'new' && (
            <TabPane tab='Map' key={3}>
              <EmployeesMap />
            </TabPane>
          )}
          {this.props.match.params.id !== 'new' && (
            <TabPane tab='Check In' key={4}>
              <CheckInList />
            </TabPane>
          )}
          {this.props.match.params.id !== 'new' && (
            <TabPane tab='Assist' key={5}>
              <AssistList />
            </TabPane>
          )}
        </Tabs>
      </div>
    );
  }
}

EditB2B2COrganizationsTravel.propTypes = {
  location: PropTypes.object,
  updateUser: PropTypes.func,
  getAllExternalRoles: PropTypes.func,
};

function mapStateToProps(state) {
  return { ...state.language, ...state.user };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { ...userActions, ...RolesActions, ...OrgActions, ...travellerActions },
    dispatch
  );
}
const WrappedForm = Form.create({ name: "edit-organization-employee" })(
  EditB2B2COrganizationsTravel
);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    WrappedForm
  )
);
