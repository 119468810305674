import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Row, Col, Typography, Button, List } from "antd";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import IntlMessages from "../../../services/intlMesseges";
import * as UserService from "../../../services/user-service";
import * as paginationActions from "../../../redux/actions/pagination-actions";
const data = [
  {
    title: <IntlMessages id="admin.sidebar.submenu.activetraveler" />,
    description: "View all upcoming travel itineraries for your travelers",
    link: "/admin/reports/active-traveler-list",
  },
  /*{
    title: <IntlMessages id="admin.sidebar.submenu.PnrReport" />,
    description: "View the location of your travelers on a particular date",
    link: "/admin/reports/search-by-pnr/"
  },*/
  // {
  //   title: <IntlMessages id="admin.sidebar.submenu.PnrReport" />,
  //   description: "View the location of your travelers on a particular date",
  //   link: "/admin/reports/search-by-pnr/",
  // },
  {
    title: <IntlMessages id="admin.sidebar.submenu.wherearemytravelers" />,
    description: "View the location of your travelers on a particular date",
    link: "/admin/reports/where-are-my-travelers/",
  },
  {
    title: <IntlMessages id="admin.sidebar.submenu.travelersbydestdate" />,
    description:
      "View which travelers are in a particular destination during a range of dates",
    link: "/admin/reports/travelers-by-dest-date/",
  },
  {
    title: <IntlMessages id="admin.sidebar.submenu.travelersbyrisklevel" />,
    description:
      "View all upcoming travel itineraries for your travelers sorted by risk level",
    link: "/admin/reports/travelers-by-risk-level",
  },
  {
    title: <IntlMessages id="admin.sidebar.submenu.EmployeesonthesameFlight" />,
    description: "View which travelers are on the same flight",
    link: "/admin/reports/employees-on-the-same-flight",
  },
  {
    title: <IntlMessages id="admin.sidebar.submenu.DomesticInternational" />,
    description:
      "Display whether your travelers are traveling domestic or international",
    link: "/admin/reports/domestic-international/",
  },
  {
    title: <IntlMessages id="admin.sidebar.submenu.SegmentAnalysis" />,
    description: "View the individual travel segments for your travelers",
    link: "/admin/reports/segment-analysis-report",
  },
  {
    title: <IntlMessages id="admin.sidebar.submenu.AirportReport" />,
    description:
      "View the list of your travelers that will arrive at or depart from a particular airport by date",
    link: "/admin/reports/airport-report/",
  },
  {
    title: <IntlMessages id="admin.sidebar.submenu.ArrivalReport" />,
    description:
      "View the list of your travelers that will arrive at a particular airport by date",
    link: "/admin/reports/arrival-report/",
  },
  {
    title: <IntlMessages id="admin.sidebar.submenu.DepartureReport" />,
    description:
      "View the list of your travelers that will depart from a particular airport by date",
    link: "/admin/reports/departure-report/",
  },
  {
    title: <IntlMessages id="admin.sidebar.submenu.SearchbyFlight" />,
    description: "View the list of your travelers by flight number",
    link: "/admin/reports/search-by-flight/",
  },
  {
    title: <IntlMessages id="admin.sidebar.submenu.ActiveHotel" />,
    description: "View the list of hotels on your travelers’ itineraries",
    link: "/admin/reports/active-hotel",
  },
  {
    title: <IntlMessages id="admin.sidebar.submenu.ActiveCar" />,
    description: "View car rental information for your travelers",
    link: "/admin/reports/active-car-report",
  },
  {
    title: <IntlMessages id="admin.sidebar.submenu.TravelersByActiveAlert" />,
    description: "View travelers based on active alerts",
    link: "/admin/reports/travelers-by-active-alert/",
  },
];

const data1 = [
  {
    title: <IntlMessages id="admin.sidebar.submenu.activetraveler" />,
    description: "View all upcoming travel itineraries for your travelers",
    link: "/user/reports/active-traveler-list",
  },
  {
    title: <IntlMessages id="admin.sidebar.submenu.wherearemytravelers" />,
    description: "View the location of your travelers on a particular date",
    link: "/user/reports/where-are-my-travelers/",
  },
  {
    title: <IntlMessages id="admin.sidebar.submenu.travelersbydestdate" />,
    description:
      "View which travelers are in a particular destination during a range of dates",
    link: "/user/reports/travelers-by-dest-date/",
  },
  {
    title: <IntlMessages id="admin.sidebar.submenu.travelersbyrisklevel" />,
    description:
      "View all upcoming travel itineraries for your travelers sorted by risk level",
    link: "/user/reports/travelers-by-risk-level",
  },
  {
    title: <IntlMessages id="admin.sidebar.submenu.EmployeesonthesameFlight" />,
    description: "View which travelers are on the same flight",
    link: "/user/reports/employees-on-the-same-flight",
  },
  {
    title: <IntlMessages id="admin.sidebar.submenu.DomesticInternational" />,
    description:
      "Display whether your travelers are traveling domestic or international",
    link: "/user/reports/domestic-international/",
  },
  {
    title: <IntlMessages id="admin.sidebar.submenu.SegmentAnalysis" />,
    description: "View the individual travel segments for your travelers",
    link: "/user/reports/segment-analysis-report",
  },
  {
    title: <IntlMessages id="admin.sidebar.submenu.AirportReport" />,
    description:
      "View the list of your travelers that will arrive at or depart from a particular airport by date",
    link: "/user/reports/airport-report/",
  },
  {
    title: <IntlMessages id="admin.sidebar.submenu.ArrivalReport" />,
    description:
      "View the list of your travelers that will arrive at a particular airport by date",
    link: "/user/reports/arrival-report/",
  },
  {
    title: <IntlMessages id="admin.sidebar.submenu.DepartureReport" />,
    description:
      "View the list of your travelers that will depart from a particular airport by date",
    link: "/user/reports/departure-report/",
  },
  {
    title: <IntlMessages id="admin.sidebar.submenu.SearchbyFlight" />,
    description: "View the list of your travelers by flight number",
    link: "/user/reports/search-by-flight/",
  },
  {
    title: <IntlMessages id="admin.sidebar.submenu.ActiveHotel" />,
    description: "View the list of hotels on your travelers’ itineraries",
    link: "/user/reports/active-hotel",
  },
  {
    title: <IntlMessages id="admin.sidebar.submenu.ActiveCar" />,
    description: "View car rental information for your travelers",
    link: "/user/reports/active-car-report",
  },
  {
    title: <IntlMessages id="admin.sidebar.submenu.TravelersByActiveAlert" />,
    description: "View travelers based on active alerts",
    link: "/user/reports/travelers-by-active-alert/",
  },
];
class ReportsList extends Component {
  constructor(props) {
    super(props);
    this.module = "reports";
  }
  viewreport(link) {
    this.props.history.push(link);
  }
  async componentDidMount() {
    this.props.clearPaginationExceptMe(this.module);
  }
  render() {
    let currentUser = UserService.getUser();
    let userRole = currentUser.role;
    return (
      <div>
        <Row gutter={24}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Typography.Title level={4}>
              <IntlMessages id="admin.label.Report" />
            </Typography.Title>
          </Col>
        </Row>
        <hr />
        <Row gutter={24}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            {userRole == "Administrator" || userRole == "CSR" ? (
              <List
                itemLayout="horizontal"
                dataSource={data}
                renderItem={(item) => (
                  <List.Item
                    actions={[
                      <Button
                        type="primary"
                        onClick={() => this.viewreport(item.link)}
                      >
                        <IntlMessages id="admin.label.report.select" />
                      </Button>,
                    ]}
                  >
                    <List.Item.Meta
                      title={<b style={{ fontSize: "16px" }}>{item.title}</b>}
                      description={
                        <span style={{ fontSize: "14px" }}>
                          {item.description}
                        </span>
                      }
                    />
                  </List.Item>
                )}
              />
            ) : (
              <List
                itemLayout="horizontal"
                dataSource={data1}
                renderItem={(item) => (
                  <List.Item
                    actions={[
                      <Button
                        type="primary"
                        onClick={() => this.viewreport(item.link)}
                      >
                        Select
                      </Button>,
                    ]}
                  >
                    <List.Item.Meta
                      title={<b style={{ fontSize: "16px" }}>{item.title}</b>}
                      description={
                        <span style={{ fontSize: "14px" }}>
                          {item.description}
                        </span>
                      }
                    />
                  </List.Item>
                )}
              />
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    ...state.pagination,
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...paginationActions }, dispatch);
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    ReportsList
  )
);
