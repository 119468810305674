import React from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import IntlMessages from "../../services/intlMesseges";
import logo from "../../images/logo.png";
import * as AdminActions from "../../redux/actions/admin-actions";
import { notifyUser } from "../../services/notification-service";
import * as b2cActions from "../../redux/actions/b2c-actions";
import {
  Form,
  Input,
  Select,
  Button,
  Row,
  Col,
  Modal
} from "antd";
const { Option } = Select;
var validator = require("email-validator");
class ManageCigna extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
     //   genderList: [],
        userExist : false,
        showModal: false,
    };
  }

  async componentDidMount() {
    // this.props.getGendersList().then((response) => {
    //     this.setState({ genderList: response });
    //   });
   
  }
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        Object.keys(values).map(function(key, _idx) {
          if (values[key] == null) {
            values[key] = "";
          } else {
            if (typeof values[key] == "string") {
              values[key] = values[key].trim();
            }
          }
        });
        this.setState({ loading: true });
        this.submitFormAdd(values);
      }
     });
  };

  submitFormAdd = (values) => {
    this.props.CreateUserAccess(values).then((response) => {
        console.log(response,"response==>")
        if(response.data.message){
            this.props.form.resetFields();
            notifyUser(response.data.message, 'success');
        }
        else {
            notifyUser(response.data.message.error, 'error');
        }
      })
    .catch((_err) => {
        this.setState({ loading: false });
      });
  };
  
  async _onBlur(evt, idx) {
    var email = evt.target.value;
    if (validator.validate(email)) {
      let userdata = await this.props.validateTravellerEmail(email);
      this.setState({ userExist: userdata });
      if (userdata.userExist) {
        this.handleShowModal(); // Show the confirmation popup
      }
      
    }
  }
  
  
  handleShowModal = () => {
    this.setState({ showModal: true });
  };

  handleHideModal = () => {
    this.setState({ userExist: '', showModal: false, });
  };

  handleConfirmAction = () => {
    this.setState({ showModal: false });
  };
  
  render() {
    const { getFieldDecorator } = this.props.form;
    const { policyNumber, error } = this.state;
    return (
      <div>
        <header id="headerb2b2c">
        <div className="headerb2b2c-inner">
          <div className="wrap">
            <section className="top-header">
              <div className="row">
               <div className={ "logo col-md-12 col-md-12  text-center"}>
                          <img
                            src={logo}
                            alt="CAP™"
                            height = "90"
                            width="118"
                          />
                      </div>
                  </div>
            </section>
          </div>
        </div>
      </header>
        
        <Form onSubmit={this.handleSubmit}>
            <section id ="banner">
              <div className="wrap">
                <div className="personal-info">
                  <Row gutter={24}>
             <Col xs={24} sm={24} md={12} lg={12} xl={12} 
             style={{ display: this.state.userExist.userExist ? "none" : "" }}>
                  <Form.Item label={<IntlMessages id='admin.userlisting.firstName' />}>
                    {getFieldDecorator('firstName', {
                      rules: [
                        {
                          whitespace: true,
                          required: true,
                          message: <IntlMessages id='admin.input.required' />
                        },
                        {
                          pattern: new RegExp(/^(\S.*)[a-zA-Z-.']+$/),
                          message: <IntlMessages id='admin.name.valid' />
                        }
                      ],
                      initialValue: this.state.userExist.userExist
                            ? this.state.userExist.firstName
                            : ""
                      
                    })(<Input maxLength={50} />)}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12} 
               style={{ display: this.state.userExist.userExist ? "none" : "" }}>
                  <Form.Item label={<IntlMessages id='admin.userlisting.lastName' />}>
                    {getFieldDecorator('lastName', {
                      rules: [
                        {
                            whitespace: true,
                            required: true,
                            message: <IntlMessages id='admin.input.required' />
                        },
                        {
                            pattern: new RegExp(/^(\S.*)[a-zA-Z-.']+$/),
                            message: <IntlMessages id='admin.lname.valid' />
                        }
                      ],
                      initialValue: this.state.userExist.userExist
                            ? this.state.userExist.lastName
                            : ""
                      
                    })(<Input maxLength={50} />)}
                  </Form.Item>
                </Col>
                </Row>
                <Row gutter={24}>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Form.Item label={<IntlMessages id='admin.userlisting.email' />}>
                    {getFieldDecorator('email', {
                      rules: [
                        {
                            type: 'email',
                            message: <IntlMessages id='admin.email.valid' />
                        },
                        {
                            whitespace: true,
                            required: true,
                            message: <IntlMessages id='admin.input.required' />
                        }
                      ],
                      
                    })(<Input onBlur={(e) => this._onBlur(e)} maxLength={50} />)}
                  </Form.Item>
                  <Modal
                        visible={this.state.showModal}
                        onCancel={this.handleHideModal}
                        onOk={this.handleConfirmAction}
                        okText='Confirm'
                        cancelText='Cancel'
                        className='modalStyle'
                      >
                        <p>
                        Welcome back! Would you like to proceed with this email ID and existing profile?
                        </p>
                      </Modal>
                </Col>
                
               
               <Col xs={24} sm={24} md={12} lg={12} xl={12} 
               style={{ display: this.state.userExist.userExist ? "none" : "" }}>
                  <Form.Item  label={<IntlMessages id='admin.userlisting.phonenumber' />}>
                    {getFieldDecorator('contactNo', {
                      rules: [
                        {
                          whitespace: true,
                          required: true,
                          message: <IntlMessages id='admin.input.required' />
                        },
                        {
                            pattern: new RegExp(/^[0-9']+$/),
                            message: <IntlMessages id ="admin.phonenumber.valid"></IntlMessages>
                          },
                      ],
                      initialValue: this.state.userExist.userExist
                            ? this.state.userExist.contactNo
                            : ""
                      
                    })(<Input maxLength={50} />)}
                  </Form.Item>
                </Col>
                </Row>
                <Row gutter={24}>
                {/* <Col xs={24} sm={24} md={12} lg={12} xl={12} 
                style={{ display: this.state.userExist.userExist ? "none" : "" }}>
                  <Form.Item label={<IntlMessages id='Gender' />}>
                    {getFieldDecorator('gender', {
                      rules: [
                        {
                          required: true,
                          message: <IntlMessages id='admin.input.required' />
                        },
                        
                      ],
                      initialValue: this.state.userExist.userExist
                            ? this.state.userExist.gender 
                            : ''
                    })( <Select
                        placeholder='Please select gender'
                      >
                        {this.state.genderList.map(function(gender) {
                          return (
                            <Option key={gender.id} value={gender.id}>
                              {gender.name}
                            </Option>
                          );
                        })}
                      </Select>)}
                  </Form.Item>
                </Col> */}
                <Col xs={24} sm={24} md={12} lg={12} xl={12}
                style={{ display: (this.state.userExist.policyNumber && this.state.userExist.userExist) ? "none" : "" }}
                >
                  <Form.Item label={"Policy No / Account No"}>
                    {getFieldDecorator('policyNumber', {
                      rules: [
                        {
                          whitespace: true,
                          required: true,
                          message: <IntlMessages id='admin.input.required' />,
                        },
                        {
                          pattern: /^[a-zA-Z0-9]{6}$|^[a-zA-Z0-9]{9}$/, // Accepts either 6 or 9 digits
                          message: 'Please enter a valid Cigna policy/account number, Must be either 6 or 9 digits. if you still have issues please contact your HR representative for further assistance.',
                        },
                      ],
                      initialValue: this.state.userExist.userExist
                        ? this.state.userExist.policyNumber
                        : '',
                    })(<Input maxLength={9}/>)}
                    
                  </Form.Item>
                </Col>
                
            </Row>
            <Row gutter={24}>
            <Form.Item style={{ display: "inline-block" }}>
                <Button className="submit" htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </Row>
           
                </div>
              </div>
          
        
         
          {/*  traveller alerts */}
          <div className="wrap">
            <div className="traveller-alerts">
              {/* title */}
              <div className="row">
                <div className="full-width">
                  <h3>Opt in to take advantage of SMS travel alerts.</h3>
                  <div className="toogle-button">
                    Yes
                    <label className="switch">
                      <input
                        type="checkbox"
                        onChange={this.handleSwitchChange}
                      />
                      <span className="slider round"></span>
                    </label>
                    No
                  </div>
                  <div className="clear-fix"></div>
                  <hr />
                </div>
              </div>
              {/* title ends */}
              {/* desc start */}
              <div className="row">
                <div className="full-width">
                  <p>
                    Receive real time late breaking travel alerts and emails to
                    warn you of any events that could affect your safety or
                    mobility at your destination. Travel Alerts were designed to
                    provide you with useful information to help you prepare for
                    or modify your travel plans and inform you of any events at
                    your destination that could impact your personal health or
                    safety. You can unsubscribe from travel alerts at any time.
                    (Recommended)
                  </p>
                  <p>
                  You agree to receive automated transactional messages. 
                  Terms and Privacy Policy can be found at <a href="https://www.captravelassistance.com/privacy-policy/">Privacy Policy - CAP Travel Assistance.</a>  
                  Txt and data rates may apply. Reply STOP to end or HELP for help.
                  </p>
                </div>
              </div>
              {/* desc ends */}
            </div>
          </div>
          
          </section>
        </Form>
      </div>
    
    );
  }
        
}

        


ManageCigna.propTypes = {

};

function mapStateToProps(state) {
    return {
      ...state.b2b2c,
      ...state.user,
    };
  }
  function mapDispatchToProps(dispatch) {
    return bindActionCreators({ ...AdminActions, ...b2cActions }, dispatch);
  }



const WrappedForm = Form.create({ name: "edit-cigna-user" })(
    ManageCigna
);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    WrappedForm
  )
);
