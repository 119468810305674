import Axios from "../../services/axios-service";

const getDayPackagesList = data => {
  return Axios.get("/api/DayPackages?search=" + encodeURIComponent(JSON.stringify(data)), undefined, undefined);
};
const getDayPackagesById = data => {
  return Axios.get("/api/DayPackages/" + data, undefined, undefined);

};
const updateDayPackages = (data, id) => {
  return Axios.put("/api/DayPackages/" + id, data, undefined);

};
const addDayPackages = (data, id) => {
  return Axios.post("/api/DayPackages/", data, undefined);

};
export default {
  getDayPackagesList,
  getDayPackagesById,
  updateDayPackages,
  addDayPackages
};

