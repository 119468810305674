import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as userActions from "../../../../../redux/actions/user-actions";
import * as travellerActions from "../../../../../redux/actions/travellers-actions";
import IntlMessages from "../../../../../services/intlMesseges";
import { Table, Icon, Input, Button, Row, Col, Typography, Tag, Tooltip } from "antd";
import moment from "moment";
import OrgAPI from "../../../../../redux/api/b2b2c-organization-api";

//import Highlighter from 'react-highlight-words';
const dateFormat = "MM/DD/YYYY";
const dateFormathh = "MM/DD/YYYY HH:mm:ss";
class PlanType extends Component {
  state = {
    isGuardMe : false ,
    data: {},
    todayDate: Date,
    pagination: { pageSize: 10, current: 1 },
    filters: {}
  };

  getHeaderKeys = () => {
    return [
      {
        title: <IntlMessages id="admin.manageOrders.ProductName" />,
        dataIndex: "productName",
        key: "productName"
        // width: "10%"
        //...this.getColumnSearchProps("productName")
      },
      {
        title: <IntlMessages id="admin.manageTravellers.PlanDate" />,
        dataIndex: "coverageStartDate",
        key: "coverageStartDate",
        //width: "20%",
        render: (text, record) => (
          <span>
            {moment(record.coverageStartDate).format(dateFormat)} to{" "}
            {moment(record.coverageEndDate).format(dateFormat)}
          </span>
        )
      },
      {
        title: <IntlMessages id="admin.manageTravellers.ExpiryInfo" />,
        key: "amount",
        // width: "7%",
        status: 1,
        render: (text, record) => (
          <span className="expiry" title={moment(record.coverageEndDate).format(dateFormathh)}>
            {moment(record.coverageEndDate).fromNow()}
          </span>
        )
      },
      {
        title: <IntlMessages id="admin.manageOrders.Status" />,
        dataIndex: "isRevoke",
        key: "isRevoke",
        //width: "10%;",
        render: (text, record) =>
          moment(record.coverageEndDate) > moment(this.state.todayDate) ? (
            <Tag color="green">
              <IntlMessages id="admin.traveler.active" />
            </Tag>
          ) : (
            <Tag color="red">
              <IntlMessages id="admin.traveler.inactive" />
            </Tag>
          )

        //...this.getColumnSearchProps("status")
      },
      {
        title: <IntlMessages id="admin.manageOrders.membershipNumber" />,
        dataIndex: "membershipNumber",
        key: "membershipNumber"
        //width: "18%"
        //...this.getColumnSearchProps("membershipNumber")
      },
      {
        title: <IntlMessages id="admin.userlisting.country" />,
        render: (text, record) => {
          var c = [];
          record.countries.map(function (data) {
            c.push(data.countryName);
            return c;
          });
          return c.join(", ");
        }

        // width: "20%"
      },
      {
        title: <IntlMessages id="admin.manageOrders.TravellerType" />,
        dataIndex: "travelerType",
        key: "travelerType",
        render: (text, record) =>
          record.travelerType === "companion" ? (
            <Tag color="blue">Companion</Tag>
          ) : (
            <Tag color="green">Primary</Tag>
          )

        //width: "15%"
        //...this.getColumnSearchProps("travelerType")
      },
      { 
        title: <IntlMessages id="admin.manageOrders.OrderDetails" />,
        rowKey: "action",
        hidden: this.state.isGuardMe || this.props.location.pathname.includes('divisions'),
        //width: "25%",
        render: (text, record) => (
          <span>
          
            <button className="icon"
              onClick={() => this.getOrder(record.orderID)}
            >
              <Tooltip title='View'>
                <i className="fas fa-eye"></i>
              </Tooltip>
            </button>
           
          </span>
        )
      }
    ].filter(item => !item.hidden);
  };

  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => {
      return (
        <div style={{ padding: 8 }}>
          <Input
            ref={node => {
              this.searchInput = node;
            }}
            value={selectedKeys}
            onChange={e =>
              setSelectedKeys(e.target.value ? e.target.value.trimLeft() : "")
            }
            onPressEnter={() =>
              this.handleSearch(
                selectedKeys,
                confirm,
                dataIndex,
                clearFilters,
                setSelectedKeys
              )
            }
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Button
            type="primary"
            onClick={() =>
              this.handleSearch(
                selectedKeys,
                confirm,
                dataIndex,
                clearFilters,
                setSelectedKeys
              )
            }
            icon="search"
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            <IntlMessages id="admin.search" />
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters, dataIndex)}
            size="small"
            style={{ width: 90 }}
          >
            <IntlMessages id="admin.reset" />
          </Button>
        </div>
      );
    },
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    }
  });

  handleSearch = (
    selectedKeys,
    confirm,
    dataIndex,
    clearFilters,
    setSelectedKeys
  ) => {
    confirm();
    this.setState({ searchText: selectedKeys });
    let filters = this.state.filters;
    filters[dataIndex] = {
      val: selectedKeys,
      clearf: clearFilters,
      filter: true,
      setSelectedKeys: setSelectedKeys,
      confirm: confirm
    };
    this.setState({ filters: filters });
  };

  handleReset = (_clearFilters, dataIndex) => {
    //clearFilters();
    let filters = this.state.filters;
    filters[dataIndex].setSelectedKeys("");
    filters[dataIndex].confirm();
    filters[dataIndex] = { val: "", clearf: "", filter: false };
    this.setState({ filters: filters });
    this.setState({ searchText: "" });
  };

  async componentDidMount() {
   
    this.state.todayDate = new Date();
    let userOrdId = "";
    if (
      this.props.match &&
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.id &&
      this.props.match.params.id !== "new"
    ) {
      this.setState({ userId: this.props.match.params.id });
       
      const user = await this.props.getTraveller(this.props.match.params.id);

      userOrdId = user.data.orgID;
      
      this.setState(user);
      this.setState({ loading: false });
    }
    
    await OrgAPI.CheckIsGuardMeOrg(userOrdId).then(response =>{  
      this.setState({isGuardMe : response})
    });
  }
  getOrder = id => {
    let path = this.props.history.location.pathname;
    this.props.history.push("../../orders/view/" + id, { fromTab: 'plans', fromLink: path });
  };
  render() {
    let _state = this.state;
    let _this = this;
    let filtertag = Object.keys(this.state.filters).map(function (key1) {
      let keyLabel = _this.getHeaderKeys().find(el => el.dataIndex === key1);
      if (keyLabel.title.props && keyLabel.title.props.id) {
        keyLabel = <IntlMessages id={keyLabel.title.props.id} />;
      } else {
        keyLabel = key1;
      }
      if (_state.filters[key1].filter) {
        return (
          <span>
            <Button
              key={key1}
              type="primary"
              onClick={() =>
                _this.handleReset(_state.filters[key1].clearf, key1)
              }
            >
              {keyLabel} : {_state.filters[key1].val}
              <Icon type="close" />
            </Button>
            &nbsp;
          </span>
        );
      }
    });
    return (
      <div>
        <Row gutter={24}>
          <Col xs={24}>{filtertag}</Col>
        </Row>
        <Row gutter={24}>
          <Col xs={24} style={{ overflowX: "auto" }}>
            <Table
              columns={this.getHeaderKeys()}
              dataSource={this.state.data.travelerOrderDetail}
              bordered
            />
          </Col>
        </Row>
      </div>
    );
  }
}

PlanType.propTypes = {
  location: PropTypes.object,
  getUserListing: PropTypes.func
};
function mapStateToProps(state) {
  return {
    ...state.user
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...userActions, ...travellerActions }, dispatch);
}
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    PlanType
  )
);
