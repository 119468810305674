import React from 'react';
import { StripeProvider, Elements , injectStripe} from 'react-stripe-elements';
import _CheckoutForm from './CheckoutForm'; // Create this component for the payment form
import config from "../../../../../config"
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
const CheckoutForm = injectStripe(_CheckoutForm)

const YourPaymentComponent = ({emaildata, showBilling, sendDataToParent}) => {
  return (
    
    <StripeProvider apiKey={config.stripeKey}>
      <Elements>
        <CheckoutForm showBilling ={showBilling} sendDataToParent={sendDataToParent} emaildata={emaildata}/>
      </Elements>
    </StripeProvider>
  );
};


function mapStateToProps(state) {
    return {
    };
  }
  function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch);
  }

export default connect(mapStateToProps, mapDispatchToProps, null, {
    forwardRef: true
  })(YourPaymentComponent);
