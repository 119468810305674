import API from "../api/traveler-location-api";

export function getTravelersList(data) {
  return async function(dispatch, getState) {
    try {
      let resp = await API.getTravelersList(data);
      return resp;
    } catch (e) {
      return { error: true };
    }
  };
}
