import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import IntlMessages from "../../../../services/intlMesseges";
import API from "../../../../redux/api/pricing-api";
import * as PricingActions from "../../../../redux/actions/pricing-actions";
import { notifyUser } from "../../../../services/notification-service";
import * as Permission from "../../../../services/permissions";
import {
  Table,
  Row,
  Col,
  Typography,
  Button,
  Spin,
  Form,
  Input,
  Select
} from "antd";
const { Option } = Select;

class DerivedPricing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDerivedRow: "block",
      selectedDerivedFrom: "-select-",
      loading: true,
      pricingList: [],
      pricingFor: [
        {
          id: 1,
          name: "RawPrice"
        },
        {
          id: 2,
          name: "RetailPrice"
        }
      ],
      pricingTypes: [
        {
          id: 1,
          name: "Base"
        },
        {
          id: 2,
          name: "Derivative"
        }
      ],
      operators: [
        {
          id: 1,
          value: "*"
        },
        {
          id: 2,
          value: "/"
        }
      ],
      defaultOperator: "*",
      dataSource: [],
      columns: [],
      formData: [],
      pagination: {
        hideOnSinglePage: true,
        showTotal: (total, range) => {
          return (
            <span>
              <IntlMessages id="admin.text.showing" /> {range[0]}-{range[1]}{" "}
              <IntlMessages id="admin.text.of" /> {total}{" "}
              <IntlMessages id="admin.text.results" />
            </span>
          );
        },
        pageSize: 10,
        current: 1
      }
    };
  }

  async componentDidMount() {
    let _this = this;
    this.setState({
      formData: this.props.priceList,
      priceTypeTab:
        this.props.priceList.calculationFor === "RawPrice" ? "#raw" : "#retail"
    });
    let tempDataObj = {},
      tempDataArr = [],
      tempColumnsObj = {},
      tempColumnsArr = [];
    this.props.priceList.pricingInfo.map(function(row, idx) {
      if (tempDataObj[row.tierId]) {
        tempDataObj[row.tierId]["day" + row.dayPackage] = parseFloat(
          Math.round(row.price * 100) / 100
        ).toFixed(2);
      } else {
        tempDataObj[row.tierId] = {};
        tempDataObj[row.tierId]["day" + row.dayPackage] = parseFloat(
          Math.round(row.price * 100) / 100
        ).toFixed(2);
      }
      tempDataObj[row.tierId].key = row.tierId;
      tempDataObj[row.tierId].tier = row.tierId;

      tempColumnsObj["tier"] = {};
      tempColumnsObj["tier"].title = "Tier";
      tempColumnsObj["tier"].dataIndex = "tier";
      // tempColumnsObj['tier'].fixed = 'left';
      //tempColumnsObj['tier'].width = 60;

      if (tempColumnsObj["day" + row.dayPackage]) {
        tempColumnsObj["day" + row.dayPackage].title = row.dayPackage + " days";
        tempColumnsObj["day" + row.dayPackage].dataIndex =
          "day" + row.dayPackage;
        tempColumnsObj["day" + row.dayPackage].editable = true;
        // tempColumnsObj['day'+row.dayPackage].width = 100;
      } else {
        tempColumnsObj["day" + row.dayPackage] = {};
        tempColumnsObj["day" + row.dayPackage].title = row.dayPackage + " days";
        tempColumnsObj["day" + row.dayPackage].dataIndex =
          "day" + row.dayPackage;
        tempColumnsObj["day" + row.dayPackage].editable = true;
        // tempColumnsObj['day'+row.dayPackage].width = 100;
      }
      return tempColumnsObj;
    });
    Object.keys(tempDataObj).forEach(prop => {
      if (tempDataObj[prop]) {
        tempDataArr.push(tempDataObj[prop]);
      }
    });

    Object.keys(tempColumnsObj).forEach(prop => {
      if (tempColumnsObj[prop]) {
        tempColumnsArr.push(tempColumnsObj[prop]);
      }
    });

    let tempPricingList = await API.getPricing({
      filters: {},
      pagination: {},
      sorter: {}
    });
    let tempPricingList1 = [];

    tempPricingList.data.map(function(item) {
      //if(item.calculationType == "Base"){
      tempPricingList1.push(item);
      //}
      if (item.name === _this.props.priceList.derivedPrice) {
        _this.setState({
          selectedDerivedFrom: item.pricingListId
        });
      }
    });
    this.setState({
      dataSource: tempDataArr,
      columns: tempColumnsArr,
      pricingList: tempPricingList1,
      loading: false
    });
  }

  submitFormAdd = data => {
    console.log("in progress...");
    this.setState({ loading: false });
    /* this.props
      .addPricing(data)
      .then(response => {
        if(response.error){
          notifyUser(response.error.message, 'error');
          this.setState({ loading: false });
        } else {
          notifyUser("User added successfully!", 'success');
          this.props.history.push("./");
          this.setState({ loading: false });
        }
      })
      .catch(err => {
        this.setState({ loading: false });
      }); */
  };

  submitFormEdit = data => {
    this.props
      .updatePricing(data)
      .then(response => {
        if (response.error) {
          notifyUser(response.error.message, "error");
          this.setState({ loading: false });
        } else {
          this.props.history.push("./../view/" + data.pricingListId);
          this.setState({ loading: false });
          notifyUser("Pricing updated successfully!", "success");
        }
      })
      .catch(err => {
        this.setState({ loading: false });
      });
  };

  onPriceTypeChange = selected => {
    if (selected === 1) {
      //base price
      this.setState({ showDerivedRow: "none" });
    } else {
      this.setState({ showDerivedRow: "block" });
    }
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        Object.keys(values).map(function(key, _idx) {
          if(values[key] == null){
            values[key] = '';
          } else {
            if(typeof values[key] == 'string'){
              values[key] = values[key].trim();
            }
          }
        });
        this.setState({ loading: true });
        if (
          this.state.formData.pricingListId &&
          this.state.formData.pricingListId > 0
        ) {
          values["pricingListId"] = this.state.formData.pricingListId;
          this.submitFormEdit(values);
        } else {
          this.submitFormAdd(values);
        }
      }
    });
  };

  render() {
    const { dataSource, columns, formData } = this.state;
    const { getFieldDecorator } = this.props.form;
    const { formLayout } = this.state;
    const formItemLayout =
      formLayout === "horizontal"
        ? {
            labelCol: { span: 4 },
            wrapperCol: { span: 14 }
          }
        : null;

    const selectedPriceType = this.state.pricingTypes.find(
      item => item.name === formData.calculationType
    );
    const pricingTypeSelector = getFieldDecorator("priceCalcTypeId", {
      initialValue: selectedPriceType ? selectedPriceType.id : 0
    })(
      <Select onChange={this.onPriceTypeChange}>
        {this.state.pricingTypes.map(function(item) {
          return (
            <Option key={item.id} value={item.id}>
              {item.name}
            </Option>
          );
        })}
      </Select>
    );
    /* const pricingForSelector = getFieldDecorator("calculationFor", {
            initialValue: formData.calculationFor,
          })(<Select >{this.state.pricingFor.map(function(item) {
                return <Option key={item.name}>{item.name}</Option>;
            })}</Select>); */
    const derivedFromSelector = getFieldDecorator("derivedPriceListId", {
      initialValue: this.state.selectedDerivedFrom
    })(
      <Select>
        {this.state.pricingList.length > 0
          ? this.state.pricingList.map(function(item) {
              if (item.name !== formData.name) {
                return (
                  <Option key={item.pricingListId} value={item.pricingListId}>
                    {item.description} ({item.calculationFor})
                  </Option>
                );
              }
            })
          : ""}
      </Select>
    );
    const operatorSelector = getFieldDecorator("arithmeticOperator", {
      rules: [
        {
          required: true,
          message: <IntlMessages id="admin.input.required" />
        }
      ],
      initialValue:
        formData.arithmeticOperator === null
          ? this.state.defaultOperator
          : formData.arithmeticOperator
    })(
      <Select>
        {this.state.operators.map(function(item) {
          return <Option key={item.value}>{item.value}</Option>;
        })}
      </Select>
    );
    return (
      <section>
        <Row gutter={24}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Typography.Title level={4}>
              {/* Griffin CAP Medical Rate (GCMR) = GCRP *{" "} 
              <InputNumber
                placeholder={1.5}
                min={1.5}
                max={10}
                step={0.1}
              />*/}
              {formData.description} ({formData.name})
            </Typography.Title>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Button
              type="primary"
              className="right-fl"
              htmlType="button"
              onClick={() =>
                this.props.history.push("./../" + this.state.priceTypeTab)
              }
            >
              <IntlMessages id="admin.button.back" />
            </Button>
          </Col>
        </Row>
        <hr />
        <div>
          <Spin spinning={this.state.loading}>
            <Form layout={formLayout} onSubmit={this.handleSubmit}>
              {Permission.isRole("Administrator") ? 
                <div>
                  <Row gutter={24}>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                      <Form.Item
                        {...formItemLayout}
                        label={<IntlMessages id="admin.pricingview.name" />}
                      >
                        {getFieldDecorator("name", {
                          rules: [
                            {
                              required: true,
                              message: <IntlMessages id="admin.input.required" />
                            }
                          ],
                          initialValue: formData.name === null ? "" : formData.name
                        })(<Input />)}
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                      <Form.Item
                        {...formItemLayout}
                        label={<IntlMessages id="admin.pricingview.description" />}
                      >
                        {getFieldDecorator("description", {
                          rules: [
                            {
                              required: true,
                              message: <IntlMessages id="admin.input.required" />
                            }
                          ],
                          initialValue:
                            formData.description === null
                              ? ""
                              : formData.description
                        })(<Input />)}
                      </Form.Item>
                    </Col>
                    {/* <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                      <Form.Item
                        {...formItemLayout}
                        label={<IntlMessages id="admin.pricingview.type" />}
                      >
                        {pricingTypeSelector}
                      </Form.Item>
                    </Col> */}

                    {getFieldDecorator("calculationFor", {
                      initialValue: formData.calculationFor
                    })(<Input type="hidden" />)}

                    {/* <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                      <Form.Item
                        {...formItemLayout}
                        label={<IntlMessages id="admin.pricingview.calculationFor" />}
                      >
                        {pricingForSelector}
                      </Form.Item>
                    </Col> */}
                    <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                      <Form.Item
                        {...formItemLayout}
                        label={<IntlMessages id="admin.pricingview.derivedPrice" />}
                      >
                        {derivedFromSelector}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={24} style={{ display: this.state.showDerivedRow }}>
                    
                    <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                      <Form.Item
                        {...formItemLayout}
                        label={<IntlMessages id="admin.pricingview.operator" />}
                      >
                        {operatorSelector}
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                      <Form.Item
                        {...formItemLayout}
                        label={<IntlMessages id="admin.pricingview.operand" />}
                      >
                        {getFieldDecorator("operand", {
                          rules: [
                            {
                              required: true,
                              message: <IntlMessages id="admin.input.required" />
                            }
                          ],
                          initialValue:
                            formData.operand === null ? "" : formData.operand
                        })(<Input />)}
                      </Form.Item>
                    </Col>
                  </Row>
                  <hr />
                </div>
              : "" 
              }

              <Row gutter={24} className="pricing-table">
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={24}
                  style={{ overflowX: "auto" }}
                >
                  <Table columns={columns} pagination={this.state.pagination} bordered dataSource={dataSource} />
                </Col>
              </Row>
              
              {Permission.isRole("Administrator") ? 
                (formData.name && formData.description && (formData.name.indexOf("Advantage") > -1 || formData.description.indexOf("Advantage") > -1) ? 
                  <Row>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                      <Form.Item
                        {...formItemLayout}
                        label="Annual Price for Tier 1"
                      >
                        {getFieldDecorator("overridePrice", {
                          initialValue: formData.overridePrice
                        })(<Input />)}
                      </Form.Item>
                    </Col>
                  </Row>
                :
                  ""
                )
              :
                ""
              }
              {Permission.isRole("Administrator") ? 
                <Row>
                  <Col>
                    <Form.Item>
                      <Button
                        type="primary"
                        style={{ display: "inline-block", marginRight: "10px" }}
                        className="def-blue"
                        htmlType="submit"
                        onClick={this.handleSubmit}
                      >
                        {formData.pricingListId > 0 ? (
                          <IntlMessages id="admin.pricingview.update" />
                        ) : (
                          <IntlMessages id="admin.pricingview.add" />
                        )}
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
                :"" }
            </Form>
          </Spin>
        </div>
      </section>
    );
  }
}
DerivedPricing.propTypes = {
  priceList: PropTypes.object,
  updatePricing: PropTypes.func
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...PricingActions }, dispatch);
}
const WrappedForm = Form.create({ name: "edit-pricing" })(DerivedPricing);
export default withRouter(
  connect(null, mapDispatchToProps, null, { forwardRef: true })(WrappedForm)
);
