import React, { PureComponent } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  Button,
  Row,
  Col,
  Select,
  Input,
  Switch,
  Tabs,
  Form
} from "antd";
import IntlMessages from "../../../services/intlMesseges";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Circle,
  Polygon
} from "react-google-maps";
import * as geoFenceAction from "../../../redux/actions/geofence-action";
import * as userAction from "../../../redux/actions/user-actions";
import { notifyUser } from "../../../services/notification-service";
import * as Permission from "../../../services/permissions";
import GeoFenceRules from "./geo-fence-rules";
import Geocode from "react-geocode";
import OrgTree from "../../shared/components/orgTree";
const mapRefsCustom = {
  map: undefined,
  circle: undefined,
  polygon: undefined
};

const Option = { Select };
const { TabPane } = Tabs;
// cicle = {lanlng : {lat: lng: }, radius: <value>, type circe}
//   poly {lanlng : [{lat: lng: }]}
var UserId;
const MapComponent = withScriptjs(
  withGoogleMap(props => {
    return (
      <GoogleMap
        ref={props.onMapMounted}
        defaultZoom={props.zoom || 5}
        defaultCenter={props.center}
        onClick={
          props.circleEditable || props.polygonEditable
            ? props.onDrawPolygon
            : null
        }
        onTilesLoaded={event =>
          props.staticFences !== undefined && props.staticFences.length > 0
            ? props.onTilesLoaded(event, props.staticFences[0].interstedGeosID)
            : ""
        }
      >
        {props.isCircle && props.circleCenter && (
          <Circle
            center={props.circleCenter}
            radius={props.radius || 100}
            editable={props.circleEditable}
            draggable={props.circleEditable}
            onCenterChanged={props.onCircleCenterChanged}
            onRadiusChanged={props.onRadiusChanged}
            ref={props.onCircleMounted}
            onDragEnd={props.onCircleDragEnd}
          />
        )}

        {props.isPoly && (
          <Polygon
            ref={props.onPolygonMounted}
            strokeColor={"#000000"}
            strokeOpacity={1.0}
            strokeWeight={3}
            editable={props.polygonEditable}
            draggable={props.polygonEditable}
            path={props.polygonPaths}
          />
        )}
      </GoogleMap>
    );
  })
);
class Maps extends PureComponent {
  constructor(props) {
    super(props);
    this.textInput = React.createRef();
    this.addressInput = React.createRef();
    this.radiusInput = React.createRef();
    this.state = {
      loading: false,
      zoom: 7,
      isCircle: false,
      isPoly: false,
      googleMapURL:
        "https://maps.googleapis.com/maps/api/js?key=AIzaSyAA_ayr9aMRSAPIugacjp_CL6S5ux_N4is&v=3.exp",
      loadingElement: <div style={{ height: `100%` }} />,
      containerElement: <div style={{ height: `600px` }} />,
      mapElement: <div style={{ height: `100%` }} />,
      center: { lat: 36.734895510543524, lng: -119.77812369355456 },
      circleCenter: false,
      circleEditable: true,
      polygonEditable: true,
      onMapMounted: this.onMapMounted,
      onCircleMounted: this.onCircleMounted,
      onPolygonMounted: this.onPolygonMounted,
      onDrawPolygon: this.onDrawPolygon,
      onMapClick: this.onMapClick,
      onTilesLoaded: this.onTilesLoaded,
      polygonPaths: [],
      staticFences: [],
      isDrawing: false,
      geofencingId: 0,
      isActive: true,
      fenceType: "polygon",
      geoFenceRules: [],
      ontile: 1,
      radiusdata: [],
      ruleexits: true,
      curentlocation: {},
      tabkey: "1"
    };
    this.onMapMounted = this.onMapMounted.bind(this);
    this.onCircleMounted = this.onCircleMounted.bind(this);
    this.onPolygonMounted = this.onPolygonMounted.bind(this);
    this.onDrawPolygon = this.onDrawPolygon.bind(this);
    this.onMapClick = this.onMapClick.bind(this);
    this.onTilesLoaded = this.onTilesLoaded.bind(this);
    this.getgeoFencebyid = this.getgeoFencebyid.bind(this);
    this.getgeofencestatus = this.getgeofencestatus.bind(this);
  }

  onMapMounted = ref => {
    mapRefsCustom.map = ref;
  };
  onCircleMounted = ref => {
    mapRefsCustom.circle = ref;
  };
  onPolygonMounted = ref => {
    mapRefsCustom.polygon = ref;
  };

  onMapClick = event => { };

  /* onTilesLoaded = (event, fenceId) => {
    if (!this.state.isDrawing) this.drawFence(parseInt(fenceId), true);
  }; */

  onTilesLoaded = (event, fenceId) => {
    if (!this.state.isDrawing) {
      this.setState({ isDrawing: true }, () => {
        this.drawFence(parseInt(fenceId), true);
      });
    }
  };

  onDrawPolygon = event => {
    //map click event
    let position = event.latLng;
    if (this.state.isCircle) {
      this.setState(
        {
          circleCenter: { lat: position.lat(), lng: position.lng() },
          radius: 100
        },
        () => {
          mapRefsCustom.map.fitBounds(mapRefsCustom.circle.getBounds());
        }
      );
    }
    if (this.state.isPoly) {
      let path = mapRefsCustom.polygon.getPath();
      path.push(position);
    }
  };

  getLatLngFromAddress = e => {
    let address = this.addressInput.current.state.value;
    if (address) {
      Geocode.fromAddress(address).then(
        response => {
          let latLng = new window.google.maps.LatLng(
            response.results[0].geometry.location
          );
          var bounds = new window.google.maps.LatLngBounds();
          bounds.extend(latLng);

          new window.google.maps.event.addListener(
            mapRefsCustom.map,
            "projection_changed",
            function () {
              var bounds = mapRefsCustom.map.getBounds();
            }
          );
          mapRefsCustom.map.fitBounds(bounds);
        },
        error => { }
      );
    }
  };

  getLatLngFromAddress2 = e => {
    if (mapRefsCustom && mapRefsCustom.circle && mapRefsCustom.circle.getCenter()) {
      if (this.state.isCircle) {
        let inputradius = e ? e : 100;
        this.setState(
          {
            circleCenter: { lat: +mapRefsCustom.circle.getCenter().lat(), lng: +mapRefsCustom.circle.getCenter().lng() },
            radius: parseInt(inputradius)
          },
          () => {
            mapRefsCustom.map.fitBounds(mapRefsCustom.circle.getBounds());
          }
        );
      }
    }
  };

  async componentDidMount() {
    await this.props.getUserData();
    if (
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.id &&
      this.props.match.params.id !== "new"
    ) {
      this.setState({ geofencingId: this.props.match.params.id });
      this.getgeoFencebyid();
      this.getgeofencestatus();
      this.setState({
        tabkey: window.location.hash
          ? window.location.hash.replace("#", "").toString()
          : "1"
      });
    } else {
      this.setState({
        isCircle: false,
        isPoly: true,
        circleEditable: false,
        polygonEditable: true,
        polygonPaths: [],
        isDrawing: true,
        isFenceActive: true
      });
    }
    let dat = [];

    for (var i = 2; i <= 60; i++) {
      dat.push(50 * i);
    }
    this.setState({ radiusdata: dat });
    navigator.geolocation.getCurrentPosition(pos => {
      this.setState({
        curentlocation: { lat: pos.coords.latitude, lng: pos.coords.longitude }
      });
    });
  }
  async getgeoFencebyid() {
    this.props.getGeoFenceDataById(this.props.match.params.id).then(resp => {
      if (!resp.data.message) {
        UserId = resp.data.insertUserId;
        let staticfences = this.state.staticFences;
        staticfences.push(resp.data);
        this.setState({ title: resp.data.title });
        if (staticfences[0].type === "polygon") {
          this.setState({ Poly: true, isCircle: false });
        }
        this.setState(
          {
            staticFences: staticfences
            // Poly:
          },
          () => {
            this.setState({
              isFenceActive: this.state.staticFences[0].isActive
            });
            if (
              this.textInput &&
              this.textInput.current &&
              this.textInput.current.state &&
              this.textInput.current.state.value
            ) {
              this.textInput.current.state.value = this.state.staticFences[0].title;
            }
          }
        );
      } else {
        notifyUser(resp.data.message, "error");
        this.props.history.push("../../geofencing/");
      }
    });
  }
  getgeofencestatus(e) {
    this.props
      .getGeoFenceRulesByGeoFenceId(this.props.match.params.id, {})
      .then(resp => {
        if (
          (resp.data && resp.data.length > 0) ||
          UserId !== this.props.userData.userID
        ) {
          this.setState({ ruleexits: false });
        } else {
          this.setState({ ruleexits: true });
        }
      });
    if (e) {
      this.setState({ tabkey: e });
    }
  }
  onChange = e => {
    let selectedValue = e;
    if (selectedValue === "circle") {
      this.setState({
        isCircle: true,
        isPoly: false,
        circleEditable: true,
        polygonEditable: false,
        circleCenter: false,
        isDrawing: true
      });
      // this.getLatLngFromAddress('circle');
    }

    if (selectedValue === "polygon") {
      this.setState({
        isCircle: false,
        isPoly: true,
        circleEditable: false,
        polygonEditable: true,
        polygonPaths: [],
        isDrawing: true
      });
    }
  };

  saveGeoFence = e => {
    let unique = new Date().getTime();
    let config = {};
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        if (this.state.isCircle) {
          if (mapRefsCustom.circle) {
            config = {
              name: "test-circle" + unique,
              id: unique,
              config: {
                latLng: {
                  lat: mapRefsCustom.circle.getCenter().lat(),
                  lng: mapRefsCustom.circle.getCenter().lng()
                },
                radius: mapRefsCustom.circle.getRadius(),
                type: "circle"
              }
            };

            let data = {
              type: "circle",
              title: this.textInput.current.state.value,
              maxRadius: values.radius,
              points: JSON.stringify(config.config.latLng),
              isActive: this.state.isActive,
              orgId: values.organizationId ? parseInt(values.organizationId) : parseInt(this.props.match.params.orgid)
            };

            if (this.textInput.current.state.value) {
              this.props
                .updateGeoFenceData(data, parseInt(this.state.geofencingId))
                .then(response => {
                  if (response.error) {
                    notifyUser(response.error.message, "error");
                    this.setState({ loading: false });
                  } else {
                    if (this.state.geofencingId === 0) {
                      if (data.isActive === true) {
                        this.props.history.push(
                          "../" + response.data.geoId + "/#2"
                        );
                        notifyUser(
                          "Geo Location added successfully Please add rules!",
                          "success"
                        );
                      } else {
                        notifyUser(
                          "Geo Location added successfully!",
                          "success"
                        );
                        this.props.history.push("../");
                      }
                    } else {
                      notifyUser(
                        "Geo Location updated successfully!",
                        "success"
                      );
                      this.props.history.push("../");
                    }
                    this.setState(
                      (prevState, props) => {
                        return {
                          isCircle: false,
                          isPoly: false,
                          circleEditable: false,
                          polygonEditable: false,
                          isDrawing: false
                        };
                      },
                      () => {
                        // this.setState({ staticFences: config })
                      }
                    );
                    // this.setState({ loading: false });
                  }
                })
                .catch(err => {
                  this.setState({ loading: false });
                });
            } else {
              notifyUser("Please enter title", "error");
            }
          } else {
            notifyUser("Please draw a Geofence on Map", "error");
          }
        }
        if (this.state.isPoly) {
          const polyArray = mapRefsCustom.polygon.getPath().getArray();
          let paths = [];
          if (polyArray && polyArray.length > 2) {
            polyArray.forEach(function (path) {
              paths.push({ lat: path.lat(), lng: path.lng() });
            });
            config = {
              name: "test-poly" + unique,
              id: unique,
              config: {
                latLng: paths,
                type: "polygon"
              }
            };
            var orgId;
            if (Permission.isRole("CSR") || Permission.isRole("Administrator")) {
              orgId = this.props.match.params.orgid
            } else {
              orgId = values.organizationId
            }
            let data = {
              type: "polygon",
              title: this.textInput.current.state.value,
              maxRadius: 0,
              points: JSON.stringify(paths),
              isActive: this.state.isActive,
              orgId: values.organizationId ? parseInt(values.organizationId) : parseInt(this.props.match.params.orgid)
            };
            this.props
              .updateGeoFenceData(data, parseInt(this.state.geofencingId))
              .then(response => {
                if (response.error) {
                  notifyUser(response.error.message, "error");
                  this.setState({ loading: false });
                } else {
                  if (this.state.geofencingId === 0) {
                    if (data.isActive === true) {
                      notifyUser(
                        "Geo Location added successfully please add rules!",
                        "success"
                      );
                      this.props.history.push(
                        "../" + response.data.geoId + "/#2"
                      );
                    } else {
                      notifyUser(
                        "Geo Location added successfully!",
                        "success"
                      );
                      this.props.history.push("../");
                    }
                  } else {
                    notifyUser("Geo Location updated successfully!", "success");
                    this.props.history.push("../");
                  }
                  this.setState({ loading: false });
                }
              })
              .catch(err => {
                this.setState({ loading: false });
              });
          } else {
            notifyUser("Please draw a Geofence on Map", "error");
          }
        }
      }
    });
  };

  getFence = fenceId => {
    let fence = this.state.staticFences.filter(
      e => e.interstedGeosID === fenceId
    );
    return fence[0];
  };

  showFence = fenceId => e => {
    this.drawFence(fenceId);
  };

  drawFence = (fenceId, editable) => {
    let fence = this.getFence(fenceId);

    let fenceType = fence.type;
    let fencePoints = JSON.parse(fence.points);
    if (fenceType === "circle") {
      this.setState(
        {
          isCircle: true,
          isPoly: false,
          circleCenter: fencePoints,
          radius: fence.maxRadius,
          circleEditable: editable || false,
          polygonEditable: false,
          isActive: fence.isActive,
          isdrawing: true
        },
        () => {
          mapRefsCustom.map.fitBounds(mapRefsCustom.circle.getBounds());
        }
      );
    }
    if (fenceType === "polygon") {
      this.setState(
        {
          isCircle: false,
          isPoly: true,
          circleCenter: false,
          circleEditable: false,
          polygonEditable: editable || false,
          polygonPaths: fencePoints,
          isActive: fence.isActive,
          isdrawing: true
        },
        () => {
          let paths = mapRefsCustom.polygon.getPaths();
          const bounds = new window.google.maps.LatLngBounds();
          paths.forEach(path => {
            path.getArray().forEach(arr => {
              bounds.extend(arr);
            });
          });
          mapRefsCustom.map.fitBounds(bounds);
        }
      );
    }
    return true;
  };

  editFence = fenceId => e => {
    this.drawFence(fenceId, true);
  };
  onSwitchChange = checked => {
    this.setState(() => this.setState({ isActive: checked }));

    // this.setState({ isActive: checked })
  };
  onChangeOrganizationTree = id => {
    if (
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.id &&
      this.props.match.params.id === "new"
    ) {
      //this.props.history.push("../../" + id + "/new/");
      this.setState({ companyId: id });
    }
  };
  render() {
    const { getFieldDecorator } = this.props.form;
    const { formLayout } = this.state;
    const formItemLayout =
      formLayout === "horizontal"
        ? {
          labelCol: { span: 4 },
          wrapperCol: { span: 14 }
        }
        : null;

    return (
      <div>
        <Tabs activeKey={this.state.tabkey} onChange={this.getgeofencestatus}>
          <TabPane tab="Geofence" key={1}>
            <Row gutter={24}>
              <Col xs={24} sm={24} md={18} lg={18} xl={18}>
                <Row gutter={24}>
                  <Col xs={24}>
                    <label>Enter Address to locate</label>
                  </Col>
                  <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                    <span className="ant-input-group-wrapper ant-input-search-enter-button">
                      <span className="ant-input-wrapper ant-input-group">
                        <Input
                          className="ant-input ant-input-lg"
                          type="text"
                          ref={this.addressInput}
                          defaultValue={
                            this.state.address ? this.state.address : ""
                          }
                        />
                        <span class="ant-input-group-addon">
                          <Button
                            type="primary"
                            className="ant-btn ant-input-search-button ant-btn-primary ant-btn-lg"
                            htmlType="button"
                            onClick={this.getLatLngFromAddress}
                          >
                            Locate
                          </Button>
                        </span>
                      </span>
                    </span>
                  </Col>
                </Row>
              </Col>
              <Col
                xs={24}
                sm={24}
                md={6}
                lg={6}
                xl={6}
                style={{ textAlign: "right" }}
              >
                <Button
                  type="primary"
                  className=""
                  htmlType="button"
                  onClick={() => this.props.history.goBack()}
                //onClick={() => this.props.history.push("../")}
                >
                  <IntlMessages id="admin.userlisting.back" />
                </Button>
              </Col>
            </Row>
            <hr></hr>
            <Row gutter={24}>
              <Form layout={formLayout}>
                <Col xs={24} sm={24} md={18} lg={18} xl={18}>
                  <MapComponent {...this.state} />
                </Col>
                <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                  {/* Draw Circle */}
                  {!Permission.isRole([
                    "Administrator",
                    "CSR",
                    "Employee",
                    "Membership"
                  ]) ? (
                      <Row gutter={24}>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                          <Form.Item
                            {...formItemLayout}
                            label={
                              <IntlMessages id="admin.organization.division"></IntlMessages>
                            }
                          >
                            {this.state.staticFences && this.state.staticFences.length > 0 ?
                              getFieldDecorator("organizationId", {
                                rules: [
                                  {
                                    required: true,
                                    message: <IntlMessages id="admin.input.required" />
                                  }
                                ],
                                initialValue: this.state.staticFences[0].orgId
                              })(
                                <span><OrgTree
                                  parent={this.state.staticFences[0].orgId}
                                  organizationId={
                                    this.props.match.params.orgid
                                      ? this.props.match.params.orgid
                                      : this.props.match.params.divid
                                  }
                                  onChange={this.onChangeOrganizationTree}
                                /></span>)
                              :
                              getFieldDecorator("organizationId", {
                                rules: [
                                  {
                                    required: true,
                                    message: <IntlMessages id="admin.input.required" />
                                  }
                                ]
                              })(
                                <OrgTree
                                  parent={-1}
                                  organizationId={
                                    this.props.match.params.orgid
                                      ? this.props.match.params.orgid
                                      : this.props.match.params.divid
                                  }
                                  onChange={this.onChangeOrganizationTree}
                                />)
                            }
                          </Form.Item>
                        </Col>
                      </Row>
                    ) : (
                      ""
                    )}

                  <Row gutter={24}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <Form.Item {...formItemLayout} label="Draw Geofence">
                        {getFieldDecorator("typegeo", {
                          initialValue: this.state.isCircle
                            ? "circle"
                            : "polygon"
                        })(
                          <Select
                            style={{ width: "100%" }}
                            onChange={this.onChange}
                            value={this.state.isCircle ? "circle" : "polygon"}
                          >
                            <Option value="circle">Draw Circle</Option>
                            <Option value="polygon">Draw Polygon</Option>
                          </Select>
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={24}>
                    {this.state.isCircle && (
                      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <Form.Item {...formItemLayout} label="Radius (meters)">
                          {getFieldDecorator("radius", {
                            initialValue:
                              this.state.staticFences &&
                                this.state.staticFences.length > 0 &&
                                this.state.staticFences[0].maxRadius !== 0

                                ? this.state.staticFences[0].maxRadius
                                : 100
                          })(
                            <Select
                              onChange={this.getLatLngFromAddress2}
                              ref={this.radiusInput}
                            >
                              {this.state.radiusdata.map(function (item) {
                                return (
                                  <Option key={item} value={item}>
                                    {item}
                                  </Option>
                                );
                              })}
                            </Select>
                          )}
                        </Form.Item>
                      </Col>
                    )}
                  </Row>
                  {/* Draw Circle ends */}
                  {/* place title */}
                  <Form.Item {...formItemLayout} label="Title">
                    {getFieldDecorator("title", {
                      rules: [
                        {
                          whitespace: true,
                          required: true,
                          message: <IntlMessages id="admin.input.required" />
                        }
                      ],
                      initialValue:
                        this.state.title === null ? "" : this.state.title
                    })(<Input maxLength={20} ref={this.textInput} />)}
                  </Form.Item>

                  {/* place title ends  */}
                  {/* Activate geo fence */}
                  {this.state.ruleexits ? (
                    <Row gutter={24} style={{ marginBottom: "20px" }}>
                      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <Switch
                          checkedChildren="Active"
                          unCheckedChildren="Inactive"
                          checked={this.state.isActive}
                          onChange={this.onSwitchChange}
                          name="status"
                        />
                      </Col>
                    </Row>
                  ) : (
                      ""
                    )}
                  {/* Activate geo fence ends */}
                  {this.state.ruleexits ? (
                    <Row gutter={24} style={{ marginBottom: "20px" }}>
                      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <Button
                          style={{
                            marginRight: "10px",
                            display: "inline-block"
                          }}
                          type="primary"
                          onClick={this.saveGeoFence}
                        >
                          Save Geofence
                        </Button>
                        {/* <Button
                        style={{ marginLeft: "10px", display: "inline-block" }}
                        buttonStyle="solid"
                        type="danger"
                        onClick={this.reset}
                      >
                        RESET
                    </Button> */}
                      </Col>
                    </Row>
                  ) : (
                      ""
                    )}
                </Col>
                <Col xs={24} sm={24} md={4} lg={4} xl={4}></Col>
              </Form>
            </Row>
          </TabPane>
          {this.props.match &&
            this.props.match.params &&
            this.props.match.params.id !== "new" &&
            this.state.isFenceActive ? (
              <TabPane tab="Manage Geofence Rules" key={2}>
                <GeoFenceRules />
              </TabPane>
            ) : (
              ""
            )}
        </Tabs>
      </div>
    );
  }
}

Maps.propTypes = {};

function mapStateToProps(state) {
  return { ...state.user };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...geoFenceAction, ...userAction }, dispatch);
}
const WrappedForm = Form.create({ name: "edit-user" })(Maps);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    WrappedForm
  )
);
