import React, { Component } from "react";
import API from "../../../redux/api/pricing-api";
import BasePricing from "./view/base";
import DerivedPricing from "./view/derived";
import { Spin } from "antd";

class ViewPricing extends Component {
  state = {
    loading: true,
    pricingListId: 0,
    priceList: {}
  };

  async componentDidMount() {
    let priceList = await API.getPrice(this.props.match.params.id);
    this.setState({
      pricingListId: this.props.match.params.id,
      priceList: priceList,
      loading: false
    });
  }

  render() {
    return (
      <Spin spinning={this.state.loading}>
        <section>
          {this.state.pricingListId > 0 ? (
            this.state.priceList.calculationType === "Base" ? (
              <BasePricing {...this.props} priceList={this.state.priceList} />
            ) : (
              <DerivedPricing
                {...this.props}
                priceList={this.state.priceList}
              />
            )
          ) : (
            ""
          )}
        </section>
      </Spin>
    );
  }
}
export default ViewPricing;
