import React, { Component } from "react";
import { StripeProvider } from "react-stripe-elements";
import Checkout from "./stripe-checkout"
import config from "../../../../../../config"

export default class BillingStripe extends Component {
    render(){
        return(
            <StripeProvider apiKey={config.stripeKey}>
                <Checkout history={this.props.history}/>
            </StripeProvider>
        )
    }
}