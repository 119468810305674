import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import * as itiniaryActions from "../../../redux/actions/itinerary-actions";
import * as userActions from "../../../redux/actions/user-actions";
import * as travellerActions from "../../../redux/actions/travellers-actions";
import * as orgActions from "../../../redux/actions/organization-actions";
import { connect } from "react-redux";
import IntlMessages from "../../../services/intlMesseges";
import { notifyUser } from "../../../services/notification-service";
import {
  Form,
  Input,
  Row,
  Col,
  Button,
  DatePicker,
  Typography,
  Select,
  Spin,
} from "antd";
import moment from "moment";
import OrgTree from "../../shared/components/orgTree";
import AppLocale from "../../../languageProvider";
import * as Permission from "../../../services/permissions";
const { RangePicker } = DatePicker;

const dateFormat = "MM/DD/YYYY  HH:mm:00";
const dateFormathh = "YYYY-MM-DD HH:mm:00";
const { Option } = Select;
let _airportData = [];
function range(start, end) {
  const result = [];
  for (let i = start; i < end; i++) {
    result.push(i);
  }
  return result;
}
function disabledDate(current) {
  // Can not select days before today and today
  return (
    current &&
    current <
      moment()
        .endOf("day")
        .subtract(1, "days")
  );
}

class UpdateItinerary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formLayout: undefined,
      fromDate: "",
      fromlocation: "",
      tolocation: "",
      todate: "",
      departuredate: "",
      flightnumber: "",
      isActive: true,
      userid: 0,
      loading: false,
      data: [],
      parentOrgData: {},
      TravellerData: [],
      pagination: { pageSize: 10, current: 1 },
      filters: {},
      airportData: [],
      stateuser: 1,
      itineraryData: {},
      travellerIternaryId: 0,
      searchtrue: false,
      flightno: "",
      departture: "",
      formexpired: false,
      resultsFound: false,
    };
    this.handleAddTraveller = this.handleAddTraveller.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.getdivision = this.getdivision.bind(this);
    this.getflightinformation = this.getflightinformation.bind(this);
    this.getAirportUpdate = this.getAirportUpdate.bind(this);
    this.disableDepartureTime = this.disableDepartureTime.bind(this);
    this.disabledRangeTime = this.disabledRangeTime.bind(this);
  }
  onChange = (selected) => {
    this.props.form.setFieldsValue({ travellerName: null });
    this.setState({ parentOrgId: selected, TravellerData: [] });
    this.getTravellerData({
      orgId: selected,
      filters: { isActiveMembership: true },
    });
  };
  disableDepartureTime() {
    var depDate = this.props.form.getFieldValue("departuredate");
    if (depDate) {
      if (
        moment(new Date(depDate._d)).format("MM/DD/YYYY") ===
        moment(new Date()).format("MM/DD/YYYY")
      ) {
        if (depDate._d.getHours() === new Date().getHours()) {
          return {
            disabledHours: () => range(0, 24).slice(0, new Date().getHours()),
            disabledMinutes: () => range(0, new Date().getMinutes()),
            disabledSeconds: () => range(0, new Date().getSeconds()),
          };
        } else {
          return {
            disabledHours: () => range(0, 24).slice(0, new Date().getHours()),
          };
        }
      } else {
        return {};
      }
    } else {
      return {
        disabledHours: () => range(0, 24).slice(0, new Date().getHours()),
        disabledMinutes: () => range(0, new Date().getMinutes()),
        disabledSeconds: () => range(0, new Date().getSeconds()),
      };
    }
  }
  disabledRangeTime(a, type) {
    if (type === "start") {
      var depDate = this.props.form.getFieldValue("todate")
        ? this.props.form.getFieldValue("todate")[0]
        : "";
      if (depDate) {
        if (
          moment(new Date(depDate._d)).format("MM/DD/YYYY") ===
          moment(new Date()).format("MM/DD/YYYY")
        ) {
          if (depDate._d.getHours() === new Date().getHours()) {
            return {
              disabledHours: () => range(0, 24).slice(0, new Date().getHours()),
              disabledMinutes: () => range(0, new Date().getMinutes()),
              disabledSeconds: () => range(0, new Date().getSeconds()),
            };
          } else {
            return {
              disabledHours: () => range(0, 24).slice(0, new Date().getHours()),
            };
          }
        } else {
          return {};
        }
      } else {
        return {
          disabledHours: () => range(0, 24).slice(0, new Date().getHours()),
          disabledMinutes: () => range(0, new Date().getMinutes()),
          disabledSeconds: () => range(0, new Date().getSeconds()),
        };
      }
    }
  }
  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <div style={{ padding: 8 }}>
          <Input
            ref={(node) => {
              this.searchInput = node;
            }}
            value={selectedKeys}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? e.target.value.trimLeft() : "")
            }
            onPressEnter={() =>
              this.handleSearch(
                selectedKeys,
                confirm,
                dataIndex,
                clearFilters,
                setSelectedKeys
              )
            }
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Button
            type="primary"
            onClick={() =>
              this.handleSearch(
                selectedKeys,
                confirm,
                dataIndex,
                clearFilters,
                setSelectedKeys
              )
            }
            icon="search"
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            <IntlMessages id="admin.search" />
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters, dataIndex)}
            size="small"
            style={{ width: 90 }}
          >
            <IntlMessages id="admin.reset" />
          </Button>
        </div>
      );
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
  });

  async componentDidMount() {
    if (
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.id &&
      this.props.match.params.id !== "new"
    ) {
      this.props.getItineraryByIdx(this.props.match.params.id).then((data) => {
        this.setState({ itineraryData: data.data });
        if (data.data.flightNumber !== null) {
          this.setState({ resultsFound: true });
        }
        this.setState({ travellerIternaryId: data.data.travellerIternaryId });
        this.setState({
          disablefields: true,
          rangedate: [
            moment(data.data.fromDate, "YYYY-MM-DD HH:mm:ss"),
            moment(data.data.toDate, "YYYY-MM-DD HH:mm:ss"),
          ],
        });

        if (data.data && new Date(data.data.toDate) < new Date()) {
          this.setState({ formexpired: true });
        }
        if (data.data.travellerName && data.data.userId) {
          this.setState({
            TravellerData: [
              {
                firstName: data.data.travellerName,
                userId: data.data.userId,
                lastName: "",
              },
            ],
          });
        }
        if (
          !_airportData.includes(
            {
              displayName: data.data.fromLocationName,
              airportID: data.data.fromLocationId,
            } || {
              displayName: data.data.toLocationName,
              airportID: data.data.toLocationId,
            }
          )
        ) {
          _airportData.push({
            displayName: data.data.fromLocationName,
            airportID: data.data.fromLocationId,
          });
          _airportData.push({
            displayName: data.data.toLocationName,
            airportID: data.data.toLocationId,
          });
        }
        let _userData = [];
        _userData.push({
          userId: data.data.userId,
          displayName: data.data.travellerName,
        });
        _userData.push({
          userId: data.data.userId,
          displayName: data.data.travellerName,
        });
        this.setState({ userData: _userData });
      });
    }

    if (
      Permission.isRole("Admin") ||
      Permission.isRole("Travel Manager") ||
      Permission.isRole("Administrator") ||
      Permission.isRole("Division Manager") ||
      Permission.isRole("CSR")
    ) {
      this.getdivision({ orgId: this.props.match.params.orgid });
      if (
        this.props.match &&
        this.props.match.params &&
        this.props.match.params.orgid &&
        this.props.match.params.orgid !== "new"
      ) {
        if (this.props.match.params.orgid)
          this.props
            .getOrganizationId(this.props.match.params.orgid)
            .then(async (resp) => {
              this.setState({ orgName: resp.data.name });
            });
        this.getTravellerData({
          orgId: this.props.match.params.orgid,
          filters: { isActiveMembership: true },
        });
      }
    }

    this.getairportData({});
  }
  getAirportUpdate() {
    this.getairportData({});
  }
  getdivision(data) {
    this.props
      .getDivisions(data)
      .then((resp) => {
        // pager.total = resp.paging.totalRecords;
        this.setState({
          loading: false,
          parentOrgData: resp.data,
          // pagination: pager
        });
      })
      .catch((_ex) => {
        this.setState({ loading: false });
      });
  }
  getTravellerData(params) {
    if (
      this.props.match &&
      this.props.match.params &&
      parseInt(this.props.match.params.orgid) === 1
    ) {
      this.props
        .getTravellersListing(params)
        .then((resp) => {
          this.setState({
            TravellerData: resp.data,
          });
        })
        .catch((ex) => {
          this.setState({ loading: false });
        });
    } else if (
      this.props.match &&
      this.props.match.params &&
      parseInt(this.props.match.params.orgid) > 1
    ) {
      this.props
        .getUserListing(params)
        .then((resp) => {
          this.setState({
            TravellerData: resp.data,
          });
        })
        .catch((ex) => {
          this.setState({ loading: false });
        });
    } else {
    }
  }
  async getairportData(params, value) {
    var info = [];
    await this.props.getAirportCodesData(params).then((data) => {
      if (value === 1) {
        info = [];
        info = this.state.airportData;
        this.setState({ airportData: data.data });
      } else if (value === 2) {
        info = [];
        if (data.data && data.data.length > 0) {
          let _da1 = data.data;
          let _da2 = this.state.airportData;
          let da3 = [];
          if (this.state.airportData && this.state.airportData.length > 0) {
            da3 = _da2.concat(_da1);
          }
          this.setState({ airportData: da3 });
        }
      } else {
        info = [];
        info = data.data;
        if (_airportData.length > 0) {
          info.concat(_airportData);
          this.setState({ airportData: info.concat(_airportData) });
        } else {
          this.setState({ airportData: info });
        }
      }
    });
  }
  getdata() {
    this.props
      .getItinerary(parseInt(this.props.userData.userID))
      .then((resp) => {
        this.setState({ data: resp.data });
      });
  }
  handleAddTraveller() {
    this.props.form.validateFields(
      ["fromlocation", "tolocation", "todate", "travellerName"],
      (err, values) => {
        let _idTraveller;
        if (values.travellerName) {
          _idTraveller = values.travellerName;
        } else {
          _idTraveller = this.props.userData.userID;
        }
        if (!err) {
          this.setState({ loading: true });
          var data = {
            userId: _idTraveller,
            fromLocationId: parseInt(values.fromlocation),
            fromDate: moment(values.todate[0]).format(dateFormathh),
            toLocationId: parseInt(values.tolocation),
            toDate: moment(values.todate[1]).format(dateFormathh),
            departureDate: moment(values.departuredate).format(dateFormathh),
            flightNumber: this.props.form.getFieldValue("flightnumber"),
            airlineCode: this.props.form.getFieldValue("airlineCode"),
            isActive: true,
          };
          if (parseInt(values.fromlocation) !== parseInt(values.tolocation)) {
            if (this.state.travellerIternaryId === 0) {
              this.props.addItineraryById(data).then((resp) => {
                if (resp.data.message === "Itinerary added successfully.") {
                  const { language } = this.props;
                  const currentAppLocale = AppLocale[language.locale];
                  notifyUser(
                    currentAppLocale.messages[
                      "admin.managedayitinerary.success"
                    ],
                    "success"
                  );
                  //notifyUser(resp.data.message, "success");
                  this.props.history.push(
                    "../../../list/" +
                      this.props.match.params.orgid +
                      "/" +
                      this.props.match.params.divid +
                      "/"
                  );
                  this.setState({ loading: false });
                } else {
                  notifyUser(resp.data.message, "error");
                  this.setState({ loading: false });
                }
              });
            } else {
              this.props
                .updateItinerarybtId(this.state.travellerIternaryId, data)
                .then((updateResp) => {
                  if (
                    updateResp.data.message ===
                    "Traveller Iternary updated successfully."
                  ) {
                    const { language } = this.props;
                    const currentAppLocale = AppLocale[language.locale];
                    notifyUser(
                      currentAppLocale.messages[
                        "admin.managedayitinerary.updatesuccess"
                      ],
                      "success"
                    );
                    // notifyUser("Traveler Itinerary updated successfully", "success");
                    this.props.history.push(
                      "../../../list/" +
                        this.props.match.params.orgid +
                        "/" +
                        this.props.match.params.divid +
                        "/"
                    );
                    this.setState({ loading: false });
                  } else {
                    notifyUser(updateResp.error.message, "error");
                    this.setState({ loading: false });
                  }
                });
            }
          } else {
            notifyUser("From Location and To Location cannot be same", "error");
            this.setState({ loading: false });
          }
        }
      }
    );
  }

  onSearch(value, type) {
    this.setState({ searchairport: value });
    this.getairportData(
      {
        orgId: 1,
        filters: { displayName: value },
        pagination: { page: 1, pageSize: 10 },
      },
      1
    );
  }
  loadAccountManagers = (e) => {
    var _search;
    if (this.state.searchairport) {
      _search = { displayName: this.state.searchairport };
    } else {
      _search = {};
    }
    if (e.target.scrollTop + e.target.offsetHeight >= e.target.scrollHeight) {
      var datas = this.state.stateuser + 1;
      this.getairportData(
        {
          orgId: 1,
          filters: _search,
          pagination: { page: datas, pageSize: 10 },
        },
        2
      );
      this.setState({ stateuser: datas });
    }
  };
  loadotherTravellers = (e) => {
    let _search = { isActiveMembership: true };
    if (this.state.seachtrav) {
      _search = { displayName: this.state.seachtrav, isActiveMembership: true };
    }
    if (e.target.scrollTop + e.target.offsetHeight >= e.target.scrollHeight) {
      var datas = this.state.stateuser + 1;
      if (parseInt(this.props.match.params.orgid) === 1) {
        this.props
          .getTravellersListing({
            orgId: this.props.match.params.orgid,
            filters: _search,
            pagination: { page: datas, pageSize: 10 },
            sorter: {},
          })
          .then((resp) => {
            let data = this.state.TravellerData;
            this.setState({ stateuser: datas });
            this.setState({
              TravellerData: data.concat(resp.data),
            });
          })
          .catch((ex) => {
            this.setState({ loading: false });
          });
      } else {
        this.props
          .getUserListing({
            orgId: this.props.form.getFieldValue("parent")
              ? this.props.form.getFieldValue("parent")
              : this.props.match.params.orgid,
            filters: _search,
            pagination: { page: datas, pageSize: 10 },
            sorter: {},
          })
          .then((resp) => {
            let data = this.state.TravellerData;
            this.setState({ stateuser: datas });
            this.setState({
              TravellerData: data.concat(resp.data),
            });
          })
          .catch((ex) => {
            this.setState({ loading: false });
          });
      }
    }
  };
  searchtravellers = (e, value) => {
    this.setState({ seachtrav: e });

    if (e === "" || e === null) {
      this.setState({ stateuser: 1, TravellerData: [] });
    }
    if (
      this.props.match &&
      this.props.match.params &&
      parseInt(this.props.match.params.orgid) === 1
    ) {
      let data = {
        orgId: this.props.match.params.orgid,
        filters: { displayName: e },
        pagination: { page: 1, pageSize: 10 },
        sorter: {},
      };
      this.props
        .getTravellersListing(data)
        .then((resp) => {
          if (value === 1) {
            let data = this.state.TravellerData;

            this.setState({
              fetching: false,
              TravellerData: data.concat(resp.data),
            });
          } else {
            this.setState({
              fetching: false,
              TravellerData: resp.data,
            });
          }
        })
        .catch((ex) => {
          this.setState({ fetching: false });
        });
    } else {
      let data = {
        // orgId: /* this.props.match.params.orgid */ this.props.form.getFieldValue('parent'),
        orgId: this.props.form.getFieldValue("parent")
          ? this.props.form.getFieldValue("parent")
          : this.props.match.params.orgid,
        filters: { displayName: e, isActiveMembership: true },
        pagination: { page: 1, pageSize: 10 },
        sorter: {},
      };
      this.props
        .getUserListing(data)
        .then((resp) => {
          if (value === 1) {
            let data = this.state.TravellerData;

            this.setState({
              fetching: false,
              TravellerData: data.concat(resp.data),
            });
          } else {
            this.setState({
              fetching: false,
              TravellerData: resp.data,
            });
          }
        })
        .catch((ex) => {
          this.setState({ fetching: false });
        });
    }
  };
  getflightinformation() {
    let self = this;
    this.props.form.validateFields(
      ["flightnumber", "departuredate", "airlineCode"],
      (err, values) => {
        this.setState({
          flightno: values.flightnumber,
          departture: values.departuredate,
        });
        if (!err) {
          this.setState({ loading: true });

          this.props
            .getFlightInformation(
              this.props.form.getFieldValue("airlineCode"),
              this.props.form.getFieldValue("flightnumber"),
              moment(this.props.form.getFieldValue("departuredate")).format(
                dateFormathh
              )
            )
            .then((resp) => {
              if (resp.data) {
                let airportData = [];
                airportData.push({
                  displayName: resp.data.departureAirportName,
                  airportID: resp.data.departureAirportID,
                });
                airportData.push({
                  displayName: resp.data.arivalAirportName,
                  airportID: resp.data.arivalAirportID,
                });
                this.setState({
                  airportData: airportData.concat(this.state.airportData),
                });
                let _itineraryData = this.state.itineraryData;
                // _itineraryData.fromDate = resp.data.departureDate;
                _itineraryData.fromDate = [
                  moment(resp.data.departureDate, "YYYY-MM-DD HH:mm:ss"),
                ];
                _itineraryData.toDate = [
                  moment(resp.data.arivalDate, "YYYY-MM-DD HH:mm:ss"),
                ];

                self.props.form.setFieldsValue({
                  fromlocation: resp.data.departureAirportID,
                  tolocation: resp.data.arivalAirportID,
                  // fromdate: moment(resp.data.departureDate),
                  todate: [
                    moment(resp.data.departureDate, "YYYY-MM-DD HH:mm:ss"),
                    moment(resp.data.arivalDate, "YYYY-MM-DD HH:mm:ss"),
                  ],
                });
                this.setState({
                  rangedate: [
                    moment(resp.data.departureDate, "YYYY-MM-DD HH:mm:ss"),
                    moment(resp.data.arivalDate, "YYYY-MM-DD HH:mm:ss"),
                  ],
                  loading: false,
                  itineraryData: _itineraryData,
                  resultsFound: true,
                });
              } else {
                notifyUser(
                  "Please enter valid Flight Number / Departure date",
                  "error"
                );
                this.setState({ loading: false, resultsFound: false });
              }
            });
        }
      }
    );
  }

  resetFlightInformationForm = () => {
    var itineraryData = this.state.itineraryData;
    itineraryData.fromDate = null;
    this.setState({
      itineraryData: itineraryData,
      resultsFound: false,
    });
    this.props.form.setFieldsValue({
      fromlocation: null,
      fromdate: null,
      tolocation: null,
      todate: null,
      departuredate: null,
      flightnumber: null,
      airlineCode: null,
    });
  };
  render() {
    const { getFieldDecorator } = this.props.form;
    const { formLayout } = this.state;
    const formItemLayout =
      formLayout === "horizontal"
        ? {
            labelCol: { span: 4 },
            wrapperCol: { span: 14 },
          }
        : null;
    return (
      <div>
        <Spin spinning={this.state.loading}>
          <Row gutter={24}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              {this.state.travellerIternaryId === 0 ? (
                <div>
                  <Typography.Title level={4}>
                    <IntlMessages id="admin.itinerary.additinerary"></IntlMessages>{" "}
                    {(Permission.isRole("Administrator") ||
                      Permission.isRole("CSR")) &&
                    this.state.orgName
                      ? "(" + this.state.orgName + ")"
                      : ""}
                  </Typography.Title>
                </div>
              ) : (
                <Typography.Title level={4}>
                  <IntlMessages id="admin.itinerary.updateitinerary"></IntlMessages>{" "}
                  {(Permission.isRole("Administrator") ||
                    Permission.isRole("CSR")) &&
                  this.state.orgName
                    ? "(" + this.state.orgName + ")"
                    : ""}
                </Typography.Title>
              )}
            </Col>
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={12}
              style={{ textAlign: "right" }}
            >
              <Button
                type="primary"
                className=""
                htmlType="button"
                onClick={() => this.props.history.goBack()}
              >
                <IntlMessages id="admin.userlisting.back" />
              </Button>
            </Col>
          </Row>
          <hr />
          <div>
            <Row gutter={24}>
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl={24}
                style={{ marginBottom: "20px", marginTop: "20px" }}
              >
                <h6 style={{ fontWeight: "normal" }}>
                  <b>
                    <IntlMessages id="admin.itinerary.flightsegments" />
                  </b>{" "}
                  <IntlMessages id="admin.itinerary.flighsegmentmesg" />{" "}
                </h6>
              </Col>
            </Row>
            <Form layout={formLayout} onSubmit={this.getflightinformation}>
              <Row gutter={24}>
                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                  <Form.Item
                    {...formItemLayout}
                    label={<IntlMessages id="userdash-departuredate" />}
                  >
                    {getFieldDecorator("departuredate", {
                      rules: [
                        {
                          required: true,
                          message: (
                            <IntlMessages id="admin.input.required"></IntlMessages>
                          ),
                        },
                      ],
                      initialValue: this.state.itineraryData.departureDate
                        ? moment(
                            this.state.itineraryData.departureDate,
                            dateFormathh
                          )
                        : "",
                    })(
                      <DatePicker
                        showTime
                        disabled={this.state.formexpired}
                        format={dateFormat}
                        disabledDate={disabledDate}
                        disabledTime={this.disableDepartureTime}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                  <Form.Item
                    {...formItemLayout}
                    label={<IntlMessages id="admin.itinerary.airlinecode" />}
                  >
                    {getFieldDecorator("airlineCode", {
                      rules: [
                        {
                          required: true,
                          message: (
                            <IntlMessages id="admin.input.required"></IntlMessages>
                          ),
                        },
                        {
                          pattern: new RegExp(/^[a-zA-Z0-9]*$/),
                          message: (
                            <IntlMessages id="admin.itinerary.validFlightNumber"></IntlMessages>
                          ),
                        },
                      ],
                      initialValue:
                        this.state.itineraryData.airlineCode === null
                          ? ""
                          : this.state.itineraryData.airlineCode,
                    })(<Input disabled={this.state.formexpired} />)}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                  <Form.Item
                    {...formItemLayout}
                    label={<IntlMessages id="userdash-flightnumber" />}
                  >
                    {getFieldDecorator("flightnumber", {
                      rules: [
                        {
                          required: true,
                          message: (
                            <IntlMessages id="admin.input.required"></IntlMessages>
                          ),
                        },
                        {
                          pattern: new RegExp(/^[a-zA-Z0-9]*$/),
                          message: (
                            <IntlMessages id="admin.itinerary.validFlightNumber"></IntlMessages>
                          ),
                        },
                      ],
                      initialValue:
                        this.state.itineraryData.flightNumber === null
                          ? ""
                          : this.state.itineraryData.flightNumber,
                    })(<Input disabled={this.state.formexpired} />)}
                  </Form.Item>
                </Col>
              </Row>
              {!this.state.formexpired ? (
                <Row gutter={24}>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    {/* {!this.state.formexpired ? */}
                    <Button
                      style={{ textAlign: "right" }}
                      type="primary"
                      class="primary"
                      onClick={this.getflightinformation}
                    >
                      Search
                    </Button>
                    {/*   : ''} */}
                    {this.props.form.getFieldValue("flightnumber") !== null &&
                    this.props.form.getFieldValue("flightnumber") !==
                      undefined &&
                    this.props.form.getFieldValue("flightnumber") !== "" &&
                    this.state.resultsFound ? (
                      <span>
                        &nbsp;
                        <Button
                          style={{ textAlign: "right" }}
                          type="reset"
                          class="primary"
                          onClick={this.resetFlightInformationForm}
                        >
                          Reset
                        </Button>
                      </span>
                    ) : (
                      ""
                    )}
                  </Col>
                </Row>
              ) : (
                ""
              )}
            </Form>
          </div>
          <hr />
          <Row gutter={24} style={{ marginTop: "20px" }}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <h5>
                <IntlMessages id="admin.itinerary.TripSegments"></IntlMessages>{" "}
              </h5>
            </Col>
          </Row>
          <Row gutter={24}>
            {this.props &&
            this.props.match &&
            this.props.match.params &&
            this.props.match.params.orgid !== "1" &&
            !Permission.isRole("Membership") &&
            !Permission.isRole("Employee") ? (
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Form.Item
                  {...formItemLayout}
                  label={<IntlMessages id="admin.organization.division" />}
                >
                  {this.props.match.params.id &&
                  this.props.match.params.id === "new"
                    ? getFieldDecorator("parent")(
                        <OrgTree
                          parent={-1}
                          organizationId={this.props.match.params.orgid}
                          disabled={
                            this.state.disablefields || this.state.formexpired
                          }
                          onChange={this.onChange}
                        />
                      )
                    : this.state.itineraryData && this.state.itineraryData.orgId
                    ? getFieldDecorator("parent")(
                        <OrgTree
                          parent={this.state.itineraryData.orgId}
                          organizationId={this.props.match.params.orgid}
                          disabled={
                            this.state.disablefields || this.state.formexpired
                          }
                          onChange={this.onChange}
                        />
                      )
                    : ""}
                </Form.Item>
              </Col>
            ) : (
              ""
            )}

            {!Permission.isRole("Membership") &&
            !Permission.isRole("Employee") ? (
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Form.Item
                  {...formItemLayout}
                  label={<IntlMessages id="admin.manageOrders.travellerName" />}
                >
                  {getFieldDecorator("travellerName", {
                    rules: [
                      {
                        required: true,
                        message: (
                          <IntlMessages id="admin.input.required"></IntlMessages>
                        ),
                      },
                    ],
                    initialValue:
                      this.state.itineraryData.userId !== null &&
                      this.state.TravellerData &&
                      this.state.TravellerData.length > 0
                        ? this.state.itineraryData.userId
                        : "",

                    // value: this.state.data.userId
                  })(
                    <Select
                      style={{ width: "100%" }}
                      onPopupScroll={this.loadotherTravellers}
                      showSearch
                      disabled={
                        this.state.disablefields || this.state.formexpired
                      }
                      filterOption={false}
                      onSearch={this.searchtravellers}
                      allowClear
                    >
                      {this.state.TravellerData
                        ? this.state.TravellerData.map(function(item) {
                            return (
                              <Option key={item.userId} value={item.userId}>
                                {item.firstName} {item.lastName}{" "}
                                {item.userName ? "<" + item.userName + ">" : ""}
                              </Option>
                            );
                          })
                        : ""}
                    </Select>
                  )}
                </Form.Item>
              </Col>
            ) : (
              ""
            )}
          </Row>
          <Form layout={formLayout} onSubmit={this.handleAddTraveller}>
            <Row gutter={24}>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Form.Item
                  label={
                    <IntlMessages id="userdash-fromlocation"></IntlMessages>
                  }
                >
                  {getFieldDecorator("fromlocation", {
                    rules: [
                      {
                        required: true,
                        message: (
                          <IntlMessages id="admin.input.required"></IntlMessages>
                        ),
                      },
                    ],

                    initialValue: this.state.itineraryData.fromLocationId,
                  })(
                    <Select
                      style={{ width: "100%" }}
                      onPopupScroll={this.loadAccountManagers}
                      showSearch
                      onSearch={this.onSearch}
                      disabled={
                        /* this.state.formexpired || (this.state.itineraryData.fromDate && this.state.itineraryData.fromDate !== null) &&  */
                        this.state.formexpired ||
                        this.state.resultsFound ||
                        (this.props.form.getFieldValue("flightnumber") !==
                          null &&
                          this.props.form.getFieldValue("flightnumber") !==
                            undefined &&
                          this.props.form.getFieldValue("flightnumber") !==
                            "" &&
                          this.state.resultsFound)
                      }
                      optionFilterProp="children"
                      onSelect={this.getAirportUpdate}
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {this.state.airportData
                        ? this.state.airportData.map(function(item) {
                            return (
                              <Option
                                key={item.airportID}
                                value={item.airportID}
                              >
                                {item.displayName}
                              </Option>
                            );
                          })
                        : ""}
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Form.Item
                  label={<IntlMessages id="userdash-tolocation"></IntlMessages>}
                >
                  {getFieldDecorator("tolocation", {
                    rules: [
                      {
                        required: true,
                        message: (
                          <IntlMessages id="admin.input.required"></IntlMessages>
                        ),
                      },
                    ],

                    initialValue: this.state.itineraryData.toLocationId,
                  })(
                    <Select
                      // onSearch={this.onSearch}
                      onPopupScroll={this.loadAccountManagers}
                      showSearch
                      disabled={
                        /* this.state.formexpired || (this.state.itineraryData.fromDate && this.state.itineraryData.fromDate !== null) &&  */ this
                          .state.formexpired ||
                        this.state.resultsFound ||
                        (this.props.form.getFieldValue("flightnumber") !==
                          null &&
                          this.props.form.getFieldValue("flightnumber") !==
                            undefined &&
                          this.props.form.getFieldValue("flightnumber") !==
                            "" &&
                          this.state.resultsFound)
                      }
                      onSearch={this.onSearch}
                      optionFilterProp="children"
                      onSelect={this.getAirportUpdate}
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      style={{ width: "100%" }}
                    >
                      {this.state.airportData &&
                      this.state.airportData.length > 0
                        ? this.state.airportData.map(function(item) {
                            return (
                              <Option
                                key={item.airportID}
                                value={item.airportID}
                              >
                                {item.displayName}
                              </Option>
                            );
                          })
                        : ""}
                    </Select>
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Form.Item
                  {...formItemLayout}
                  label={<IntlMessages id="admin.itinerary.fromtodate" />}
                >
                  {getFieldDecorator("todate", {
                    rules: [
                      {
                        required: true,
                        message: (
                          <IntlMessages id="admin.input.required"></IntlMessages>
                        ),
                      },
                    ],

                    initialValue:
                      this.state.rangedate && this.state.rangedate.length === 2
                        ? this.state.rangedate
                        : this.state.rangedate,
                  })(
                    <RangePicker
                      style={{ width: "100%" }}
                      disabled={
                        /* this.state.formexpired || (this.state.itineraryData.fromDate && this.state.itineraryData.fromDate !== null) &&  */ this
                          .state.formexpired ||
                        this.state.resultsFound ||
                        (this.props.form.getFieldValue("flightnumber") !==
                          null &&
                          this.props.form.getFieldValue("flightnumber") !==
                            undefined &&
                          this.props.form.getFieldValue("flightnumber") !==
                            "" &&
                          this.state.resultsFound)
                      }
                      disabledDate={disabledDate}
                      disabledTime={this.disabledRangeTime}
                      showTime={{
                        hideDisabledOptions: true,
                      }}
                      format={dateFormat}
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>
            {!this.state.formexpired ? (
              <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Button
                    onClick={this.handleAddTraveller}
                    type="primary"
                    style={{
                      display: "inline-block",
                      marginRight: "10px",
                      verticalAlign: "top",
                    }}
                  >
                    <IntlMessages id="admin.manageiteneary.submit" />
                  </Button>
                </Col>
              </Row>
            ) : (
              ""
            )}
          </Form>
        </Spin>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    ...state.user,
    ...state.language,
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { ...itiniaryActions, ...userActions, ...travellerActions, ...orgActions },
    dispatch
  );
}
const WrappedForm = Form.create({ name: "Update-itinerary" })(UpdateItinerary);
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    WrappedForm
  )
);
