import React, { Component } from "react";
import Wizard from "../shared/wizard/wizard.jsx";
import StripeProvider from "./stripe-information/stripe-provide";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as b2b2cActions from "../../../redux/actions/b2b2c-actions";
import TravellerInfo from "../shared/traveller/traveller.jsx";
import { Beforeunload } from "react-beforeunload";
import postscribe from "postscribe";
import { currencyType } from "../../../components/constants";
import getSymbolFromCurrency from "currency-symbol-map";
import { cadConvertionRate } from "../../b2c/shared/cadConverstion";
import PriceCalculation from "../personal-details/price-calculation.jsx";
import config from "../../../../../config.js";

class BillingInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      appliedPromoText: "",
      currency: "USD",
      currencyRate: 1,
      decodedParams: props.planTerm,
      paymentMethod: "stripe",
    };
    //this.updatePrice = this.updatePrice.bind(this);
  }

  UNSAFE_componentWillMount() {
    let localDecodedParams = localStorage.getItem("decodedParams");
    if (
      typeof localDecodedParams !== "undefined" &&
      localDecodedParams !== null &&
      localDecodedParams !== ""
    ) {
      let dparams = JSON.parse(localDecodedParams);
      this.setState({ decodedParams: dparams });
    }
  }

  async componentDidMount() {
    //console.log(props);
    //to get atn no from url
    await this.props.getResellerProductPricingAndInfo();

    let params = new URLSearchParams(window.location.search);
    let atn = params.get("atn");
    await this.props.getAgentName(atn);

    this.setState({
      currency: this.props.currency,
      currencyRate: this.props.currencyRate,
    });
  }

  onUnload = (e) => {
    e.preventDefault();
    /* this.props.flushOrderData();
    this.props.ClearData(); */
  };

  render() {
    // let cadPrice;
    // if(this.state.currency == "CAD"){
    //    cadPrice = cadConvertionRate(this.props.planTerm.totalPrice/ this.props.personalDetails.length);
    // }
    let decodedParams = this.state.decodedParams;

    return (
      <section id="banner">
        <Beforeunload onBeforeunload={(e) => this.onUnload(e)}>
          <Wizard wizardActiveClass="step4" />

          <div
            className="wrap900 price-sticky-head"
            style={{
              display: "block",
              maxWidth: "1300px",
              zIndex: "100",
              position: "sticky",
              fontSize: "18px",
              fontWeight: "bold",
              padding: "16px"
            }}
          >
            {this.props.agentName &&
            this.props.match.params.orgId == config.agentCode
              ? "Agent : " + this.props.agentName
              : ""}
            <br />
          </div>

          <div
            className="wrap900 price-sticky-head"
            style={{
              display: "block",
              maxWidth: "1300px",
              zIndex: "100",
              position: "sticky"
            }}
          >
            <div className="title">
              <PriceCalculation
                paymentMethod={this.state.paymentMethod}
                onPaymentMethodChange={(value) => {
                  this.setState({ paymentMethod: value });
                }}
              />
            </div>
          </div>
          {/* plans upgrade */}
          <div className="wrap">
            <div className="purchase-summary" style={{ marginBottom: "100px" }}>
              {/* traveler deatils */}

              {this.props.personalDetails && this.props.personalDetails.length > 0 && (
                <TravellerInfo
                  productInfo={this.props.productInfo}
                  planTerm={this.props.planTerm}
                  personalDetails={this.props.personalDetails}
                  decodedParams={decodedParams}
                  SavePlanTerm={this.props.SavePlanTerm}
                  countryTierInfo={this.props.countryTierInfo}
                  allCountries={this.props.allCountries}
                />
              )}

              {/* traveler deatils end */}
            </div>
          </div>
          {/* plan upgrade end */}
          <StripeProvider
            history={this.props.history}
            paymentMethod={this.state.paymentMethod}
            onPaymentMethodChange={(value) => {
              this.setState({ paymentMethod: value });
            }}
            //updatePrice={this.updatePrice}
            appliedPromoText={this.state.appliedPromoText}
            billingInformation={this.props.billingInformation}
            match={this.props.match}
          />
        </Beforeunload>
      </section>
    );
  }
}

BillingInformation.propTypes = {
  productPricing: PropTypes.array,
  getPersonalInformation: PropTypes.func,
  getPlanInformation: PropTypes.func,
  getPlanTermInformation: PropTypes.func,
  history: PropTypes.object,
  planTerm: PropTypes.object,
  productInfo: PropTypes.array,
  planType: PropTypes.object,
  personalDetails: PropTypes.array,
  productPriceList: PropTypes.array,
  getResellerProductPricingAndInfo: PropTypes.func,
  countryTierInfo: PropTypes.array,
  getAppliedPromo: PropTypes.func,
  couponInfo: PropTypes.object,
  getStep: PropTypes.func,
  getBillingInformation: PropTypes.func,
  billingInformation: PropTypes.object,
  b2b2cBaseUrl: PropTypes.string,
};
function mapStateToProps(state) {
  return {
    ...state.b2b2c,
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...b2b2cActions }, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(BillingInformation);
