import { Types } from "../constants/traveller-types";
const initialState = {
  travellersData: {},
  travellerDataById:{}
};
export default function userReducer(state = initialState, action) {
  switch (action.type) {
   
    case Types.TRAVELLERS_LIST:
      return { ...state, travellersData: action.payload };
      case Types.TRAVELLERS_DATA_BYID:
        return { ...state, travellerDataById: action.payload };
        case Types.UPDATE_TRAVELLER_DATA:
          return { ...state, travellerDataUpdated: action.payload };
    default:
      return state;
  }
}
