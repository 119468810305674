import React, { Component } from "react";
import _CardForm from "./stripe-card-form";
import { Elements, injectStripe } from "react-stripe-elements";
const CardForm = injectStripe(_CardForm)
export default class StripeCheckout extends Component {
    render() {
        return (
            <div className="Checkout">
                <Elements>
                    <CardForm
                        paymentMethod={this.props.paymentMethod}
                        onPaymentMethodChange={this.props.onPaymentMethodChange}
                        history={this.props.history}
                        match={this.props.match}
                        updatePrice={this.props.updatePrice} />
                </Elements>
            </div>
        )
    }
}
