import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Wizard from "../shared/wizard/wizard.jsx";
import * as b2cActions from "../../../redux/actions/b2c-actions";
import * as userActions from "../../../redux/actions/user-actions";
import Faqb2c from "./faq/faq.jsx";
import bestvalue from "../../../images/best-value.png";
import { Modal } from "antd";
const { confirm } = Modal;
class PlanType extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.handleClick = this.handleClick.bind(this);
    this.showConfirm = this.showConfirm.bind(this);
  }

  async handleClick(evt) {
    if (evt.currentTarget.dataset && evt.currentTarget.dataset.value) {
      let planType = evt.currentTarget.dataset.value;
      let planObject = this.props.productInfo.find(
        x => x.productId === parseInt(planType)
      );
      await this.props.SaveStep("1");
      await this.props.SavePlanType(planObject);
      this.props.history.push(this.props.b2cBaseUrl + "/plan-term");
    }
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    await this.props.getUserData();
    if (this.props.isLoggedIn) {
      await this.props.getTravellerInfo(this.props.userData.userID);
      if (this.props.travellerInfo && this.props.travellerInfo.email) {
        var contactNumber = this.props.travellerInfo.contactNo;
        var countryId = this.props.travellerInfo.countryId;
        var selectedDate = new Date(this.props.travellerInfo.dateOfBirth);
        var isValidDOB = this.checkValidDateOfBirth(
          selectedDate.getDay(),
          selectedDate.getMonth(),
          selectedDate.getFullYear()
        );
        var errors = [];
        if (contactNumber === ""){
          errors.push("- Please provide your contact number.")
        }
        if (countryId === null){
          errors.push("- Please provide your home country.")
        }
        if (!isValidDOB){
          errors.push("- You are not eligible to buy a plan because you are under 18 years.\n")
        }
        if(errors.length > 0) {
          var objContent = {};
          objContent.title = "Information not updated. Please correct the errors below!";
          objContent.content = <div dangerouslySetInnerHTML={{__html: errors.join("<br/>")}} />;
          if (!this.props.alreadyVerfified) this.showConfirm(objContent);
          else this.props.checkIfDetailAlreadyVerified(false);
        }
      }
    }
  }

  showConfirm(objContent) {
    this.props.checkIfDetailAlreadyVerified(true);
    var self = this;
    confirm({
      title: objContent.title,
      content: objContent.content,
      onOk() {
        self.props.history.push("/user/my-profile");
      },
      onCancel() {
        self.props.history.push("/user/my-profile");
      }
    });
  }

  checkValidDateOfBirth(day, month, year) {
    return new Date(year + 18, month - 1, day) <= new Date();
  }

  _bindProducts() {
    return this.props.productInfo.map((product, index) => (
      <li key={"product" + index}>
        {product.name.toLowerCase().indexOf("med") > -1 ? (
          <img
            src={bestvalue}
            style={{ position: "absolute", left: "0", top: "0" }}
          />
        ) : (
          ""
        )}
        <h2>{product.name}</h2>
        {this.props.productInfo.length -1 === index ? 
          (<React.Fragment>
            <h6>Technology Bundle Included in All Plans</h6>
            <button className="btn-blue" onClick={this.scrollToAdvantage}>
              More Info
            </button>
          </React.Fragment>)
        : 
          (<React.Fragment>
            <h6>{"Starting at $" + product.price.toFixed(2)}</h6>
            <button data-value={product.productId} onClick={this.handleClick}>
              Purchase
            </button>
          </React.Fragment>)
        }
        {this.props.productInfo.length -1 === index ? '' : this._bindInclude(product) }
      </li>
    ));
  }

  _bindInclude(product) {
    var includeText = "";
    for (var i = 0; i < this.props.productInfo.length; i++) {
      if (this.props.productInfo[i].productId <= product.productId && i > 0) {
        includeText =
          includeText === ""
            ? "*Includes " + this.props.productInfo[i - 1].name
            : includeText + " & " + this.props.productInfo[i - 1].name;
      }
    }
    return (
      <span style={{ color: "#808285", fontStyle: "italic", fontSize: "15px" }}>
        {includeText}
      </span>
    );
  }

  scrollToAdvantage = () => {
    if(window.innerWidth < 980){
      this._scrollToSmoothly(document.getElementById("row-cap-advantage-assistance-mobile").offsetTop + window.screen.height, 50);
    } else {
      this._scrollToSmoothly(document.getElementById("row-cap-advantage-assistance").offsetTop + window.screen.height, 50);
    }
  }

  _scrollToSmoothly(pos, time) {
    if (isNaN(pos)) {
      throw "Position must be a number";
    }
    if (pos < 0) {
      throw "Position can not be negative";
    }
    var currentPos = window.scrollY || window.screenTop;
    if (currentPos < pos) {
      var t = 10;
      for (let i = currentPos; i <= pos; i += 10) {
        t += 10;
        setTimeout(function() {
          window.scrollTo(0, i);
        }, t / 2);
      }
    } else {
      time = time || 2;
      var i = currentPos;
      var x;
      x = setInterval(function() {
        window.scrollTo(0, i);
        i -= 10;
        if (i <= pos) {
          clearInterval(x);
        }
      }, time);
    }
  }

  render() {
    return (
      <div>
        <section id="banner">
          <Wizard wizardActiveClass="step1" />
          {/* title */}
          <div className="wrap">
            <div className="title">
              <h1>Please Select the CAP™ Plan That's Right For You</h1>
            </div>
          </div>
          {/* title ends */}
          {/*  three boxes product */}
          <div className="three-box">
            <div className="wrap">
              <ul>
                {this.props.productInfo && this.props.productInfo.length > 0
                  ? this._bindProducts()
                  : ""}
              </ul>
            </div>
            {/* three boxes ends */}
          </div>
        </section>
        <div
          className="wrap"
          style={{
            textAlign: "left",
            padding: "0 30px",
            marginTop: "-10px",
            position: "relative",
            zIndex: "100"
          }}
        ></div>
        <Faqb2c />
      </div>
    );
  }
}

PlanType.propTypes = {
  SaveStep: PropTypes.func,
  SavePlanType: PropTypes.func,
  step: PropTypes.string,
  productInfo: PropTypes.array,
  productPricing: PropTypes.object,
  getUserData: PropTypes.func,
  userData: PropTypes.object,
  alreadyVerfified: PropTypes.bool,
  checkIfDetailAlreadyVerified: PropTypes.func,
  b2cBaseUrl: PropTypes.string
};
function mapStateToProps(state) {
  return {
    ...state.b2c,
    ...state.user
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...b2cActions, ...userActions }, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true
})(PlanType);
