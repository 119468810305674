import API from "../api/travellers-api";

export function getTravellersListing({ filters, pagination, sorter }) {
  return async function(dispatch, getState) {
    try {
      let resp = await API.getTravellersList({
        filters: filters,
        pagination: pagination,
        sorter: sorter
      });
      return resp;
      
    } catch (e) {
      return { error: true };
    }
  };
}

export function getB2CTravellersListing({ filters, pagination, sorter }) {
  return async function(dispatch, getState) {
    try {
      let resp = await API.getB2CTravellersList({
        filters: filters,
        pagination: pagination,
        sorter: sorter
      });
      return resp;
      
    } catch (e) {
      return { error: true };
    }
  };
}

export function getTraveller(idx) {
  return async function(dispatch, getState) {
    try {
      let resp = await API.getTravellerbyId(idx);
      return resp;
    } catch (e) {
      return { error: true };
    }
  };
}

export function getTravellerLocations(data) {
  return async function(dispatch, getState) {
    try {
      let resp = await API.getTravellerLocations(data);
      return resp;
    } catch (e) {
      return { error: true };
    }
  };
}

export function updateTravellerData(data, id) {
  return async function(dispatch, getState) {
    try {
      let resp;
      resp = await API.updateTraveller(data, id);
      return resp;
    } catch (e) {
        e.response.error = true;
        return e.response;
                }
  };
}

export function addTraveller(data) {
  return async function(dispatch, getState) {
    try {
      var result = await API.addTraveller(data);
      return {data: result, success:true};
    } catch(error){
      if(error && error.response && error.response.data && error.response.data.Message){
        return  {data:error.response.data.Message, success:false};;
      }else{
        return {data: "Something went wrong", success:false};
      }
    }
  };
}

export function updatetravelerEmail(data) {
  return async function(dispatch, getState) {
    try {
      let resp;
      resp = await API.assignIdentityToTraveller(data);
      return resp;
    } catch (e) {
      return { error: true };
    }
  };
}
