import React, { Component } from "react";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import WizardB2csvg from "../../../shared/svg/b2c-svg/wizard-svg.jsx";
import * as b2b2cActions from "../../../../redux/actions/b2b2c-actions"
import Config from "../../../../../../config";

class WizardB2B2C extends Component {
  constructor(props) {
    super(props);
    this.state = {
      wizardStepIcon: <WizardB2csvg />,
      wizardActiveStepIcon: <WizardB2csvg />
    }
  }

  async componentDidMount() {
   
      if (this.props.resellerOrganizationInfo && this.props.resellerOrganizationInfo.b2B2COrgSettings) {
      let b2B2COrgSettings = Array.isArray(this.props.resellerOrganizationInfo.b2B2COrgSettings) ? this.props.resellerOrganizationInfo.b2B2COrgSettings[0] : this.props.resellerOrganizationInfo.b2B2COrgSettings;
      if (b2B2COrgSettings.wizardIcons !== null && b2B2COrgSettings.wizardIcons !== "" && b2B2COrgSettings.wizardIconActive !== null && b2B2COrgSettings.wizardIconActive !== "") {
        this.setState({ 
          wizardStepIcon: <img src={`${Config.API1}/${b2B2COrgSettings.wizardIcons}`} style={{ maxWidth: 50 }} />,
          wizardActiveStepIcon: <img src={`${Config.API1}/${b2B2COrgSettings.wizardIconActive}`} style={{ maxWidth: 50 }} />
        });
      }
    }
    }

  render() {
    var encodedParameters = window.location.search;
    var getEncodedStr = encodedParameters.substring(1).split("&");
    let patttern = /^([A-Za-z0-9+/]{4})*([A-Za-z0-9+/]{3}=|[A-Za-z0-9+/]{2}==)?$/;
    getEncodedStr.map((str)=>{
      encodedParameters =  str.match(patttern) ? str :"";
   
    })
    return (
     <div className="wizard">
      
        <div className="wrap900">
          <ul>
            {encodedParameters == "" ? 
            <>
            <li className={this.props.wizardActiveClass === "step1" ? "active" : ""}>
              <span>
                {this.props.wizardActiveClass === "step1" ? this.state.wizardActiveStepIcon : this.state.wizardStepIcon}
              </span>
              <div className="name">Plan Type</div>
            </li>
            <li className={this.props.wizardActiveClass === "step2" ? "active" : ""}>
              <span>
                {this.props.wizardActiveClass === "step2" ? this.state.wizardActiveStepIcon : this.state.wizardStepIcon}
              </span>
              <div className="name">Plan Term</div>
            </li> </> : ""}
            <li className={this.props.wizardActiveClass === "step3" ? "active" : ""}>
              <span>
                {this.props.wizardActiveClass === "step3" ? this.state.wizardActiveStepIcon : this.state.wizardStepIcon}
              </span>
              <div className="name">
                Personal <br />
                Details
              </div>
            </li>
            <li className={this.props.wizardActiveClass === "step4" ? "active" : ""}>
              <span>
                {this.props.wizardActiveClass === "step4" ? this.state.wizardActiveStepIcon : this.state.wizardStepIcon}
              </span>
              <div className="name">
                Billing
                <br />
                Information
              </div>
            </li>
            {/* <li className={this.props.wizardActiveClass === "step5" ? "active" : ""}>
              <span>
                {this.props.wizardActiveClass === "step5" ? this.state.wizardActiveStepIcon : this.state.wizardStepIcon}
              </span>
              <div className="name">
                Purchase <br />
                Summary
              </div>
            </li> */}
          </ul>
        </div>
      </div>
    
    );
  }
}

WizardB2B2C.propTypes = {
  step: PropTypes.string,
};
function mapStateToProps(state) {
  return {
    ...state.b2b2c,
  }
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...b2b2cActions }, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(WizardB2B2C);
