import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Table, Button, Icon, Card, Row } from "antd";
import {
  TravelersSvg,
} from "../../../shared/svg/svg/home-icon";
import * as travellerActions from "../../../../redux/actions/travellers-actions";
import IntlMessages from "../../../../services/intlMesseges"
const TravelersSvgIcon = props => <Icon component={TravelersSvg} {...props} />;

class ListTravelers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      data: []
    };
  }

  getHeaderKeys = () => {
    return [
      {
        title:<IntlMessages id="admin.listtravelers.Email" />,
        dataIndex: "userName",
        render: (_, record) => {
          return <span>{record.userName !== null && record.userName !== "" ? record.userName : record.firstName+' '+record.lastName}</span>
        }
      },
      {
        title: "",
        rowKey: "action",
        render: (text, record) => (
          <span>
            <Button
              className="btn-outline"
              onClick={() =>
                this.props.history.push("travelers/" + record.userId)
              }
            >
              <IntlMessages id="admin.listcompanies.moreinfo" />
            </Button>
          </span>
        )
      }
    ];
  };

  async componentDidMount() {
    this.setState({ loading: true });
    this.props
      .getTravellersListing({
        filters: {userName: '@'},
        pagination: { page: 1, pageSize: 5 },
        sorter: {}
      })
      .then(resp => {
        this.setState({
          loading: false,
          data: resp.data
        });
      })
      .catch(ex => {
        this.setState({ loading: false });
      });
  }

  render() {
    return (
      <div>
        <Card
          title={<IntlMessages id="admin.listtravelers.list" />}
          bodyStyle={{ padding: 0 }}
          extra={<TravelersSvgIcon />}
        >
          <Table
            rowKey={record => record.userName}
            pagination={false}
            columns={this.getHeaderKeys()}
            dataSource={this.state.data}
            loading={this.state.loading}
          />
          <Row type="flex" justify="end">
            <Button
              type="primary"
              className="addBtn"
              size={"large"}
              style={{ margin: " 24px 24px 24px 0px" }}
              onClick={() => this.props.history.push("travelers/")}
            >
              <IntlMessages id="admin.listcompanies.viewall" />
            </Button>
          </Row>
        </Card>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...travellerActions }, dispatch);
}
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    ListTravelers
  )
);
