import React, { Component, useDebugValue } from "react";
import Wizard from "../shared/wizard/wizard.jsx";
import StripeProvider from "./stripe-information/stripe-provide";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as b2cActions from "../../../redux/actions/b2c-actions";
import TravellerInfo from "../shared/traveller/travellerOrder.jsx";
import { Beforeunload } from "react-beforeunload";
import { Row, Col, Button } from "antd";
import Config from "../../../../../config";
import moment from 'moment';
import postscribe from "postscribe";
import { currencyType } from "../../../components/constants";
import getSymbolFromCurrency from "currency-symbol-map";
import PriceCalculation from "../order/price-calculation.jsx";

class BillingInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      appliedPromoText: "",
      currency: "USD",
      currencyRate: 1,
      decodedParams: props.planTerm,
      paymentMethod: "stripe",

    };
  }

  UNSAFE_componentWillMount() {
    let localDecodedParams = localStorage.getItem("decodedParams");
    if (typeof localDecodedParams !== "undefined" && localDecodedParams !== null && localDecodedParams !== "") {
      let dparams = JSON.parse(localDecodedParams);
      this.setState({ decodedParams: dparams })
    }
  }

  async componentDidMount() {
    // postscribe("#banner", "<script> (function(h,o,t,j,a,r){ h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)}; h._hjSettings={hjid:2750169,hjsv:6}; a=o.getElementsByTagName('head')[0]; r=o.createElement('script');r.async=1; r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv; a.appendChild(r); })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv='); </script>");
    await this.props.getProductPricingAndInfo();
    window.scrollTo(0, 0);
  }

  onUnload = (e) => {
    e.preventDefault();
   /* this.props.flushOrderData();
    this.props.ClearData(); */
  };

  render() {
    if(this.props.b2c.step < 3){
      localStorage.clear();
      this.props.history.push("/cap/order/"+this.props.location.search);
      return false
    }
    let decodedParams = this.state.decodedParams;
    var plus1Year = decodedParams.start_date;
    plus1Year = moment(plus1Year)
    .add(364, "days")
    .format("YYYY-MM-DD");
    const backPageurl = decodedParams.us_travel_states && decodedParams.us_travel_states != undefined ? "domestic-quote-results/" : "quote-results/";
    return (
      <section id="banner" style={{ paddingTop: 0 }}>
        <Beforeunload onBeforeunload={(e) => this.onUnload(e)}>
          <div
            style={{
              background: "#203da5",
              padding: "20px 0",
              color: "#eee",
            }}
          >
            <div className="container">
              <Row className="plan-quote-header">
                <Col sm={20}>
                  <ul className="desti">
                    <li>
                      <span className="label">Dates:</span>
                      <br />
                      <span className="desti-result">
                        {/* {moment(decodedParams.start_date).format("MM/DD/YYYY")} -{" "}
                        {moment(decodedParams.end_date).format("MM/DD/YYYY")} */}
                        {this.props.b2c.planTerm && this.props.b2c.planTerm.dp < 120 ? (
                    <>
                      {moment(decodedParams.start_date).format("MM/DD/YYYY")} -{" "}
                      {this.props.b2c.planTerm.isAnnual !== false
                        ? moment(plus1Year).format("MM/DD/YYYY")
                        : moment(decodedParams.end_date).format("MM/DD/YYYY")}
                    </>
                  ) : (
                    <>
                     {moment(decodedParams.start_date).format("MM/DD/YYYY")} -{" "}
                        {moment(decodedParams.end_date).format("MM/DD/YYYY")}
                    </>
                  )}
                      </span>
                    </li>

                    <li>
                      <span className="label">Destinations : </span>
                      <br />
                      <span className="desti-result">
                        {decodedParams.countries_list}{decodedParams.us_travel_states ? ", " : ""}
                        <br />
                        {decodedParams.us_travel_states ? decodedParams.us_travel_states : ""}
                      </span>
                    </li>

                    <li>
                      <span className="label"># of Travelers : </span>
                      <br />
                      <span className="desti-result">
                        {this.props.b2c && this.props.b2c.personalDetails.length}
                      </span>
                    </li>
                  </ul>
                </Col>
                <Col sm={4}>
                  <div className="edit-btn">
                    <Button
                      type="primary desti-button"
                      onClick={() => (
                        window.location = Config.wpEditQuoteUrl + backPageurl + window.location.search
                      )}
                    >
                      Edit Quote
                    </Button>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          <Wizard wizardActiveClass="step4" />
          <div
            className="wrap900 price-sticky-head"
            style={{
              display: "block",
              maxWidth: "1300px",
              zIndex: "100",
              position: "sticky",
            }}
          >

            <div className="title">
              <PriceCalculation paymentMethod = {this.state.paymentMethod}  onPaymentMethodChange={(value) => { this.setState({paymentMethod: value})}} />
            </div>
          </div>

          <div className="wrap">
            <div className="purchase-summary" style={{ marginBottom: "100px" }}>

              {/* traveler deatils */}

              {this.props.b2c &&
                this.props.b2c.personalDetails.length > 0 && (

                  <TravellerInfo 
                        b2c={this.props.b2c} 
                        decodedParams={decodedParams}
                        SavePlanTerm={this.props.SavePlanTerm}
                  />
                )}

              {/* traveler deatils end */}
            </div>
          </div>
          <div >
          </div>
          <StripeProvider
            history={this.props.history}
            paymentMethod = {this.state.paymentMethod}
            onPaymentMethodChange={(value) => { this.setState({paymentMethod: value})}}
                   // updatePrice={this.updatePrice}
            appliedPromoText={this.state.appliedPromoText}
            billingInformation={this.props.billingInformation}
          />
        </Beforeunload>
      </section>
    );
  }
}

BillingInformation.propTypes = {
  productPricing: PropTypes.array,
  getPersonalInformation: PropTypes.func,
  getPlanInformation: PropTypes.func,
  getPlanTermInformation: PropTypes.func,
  history: PropTypes.object,
  planTerm: PropTypes.object,
  productInfo: PropTypes.array,
  planType: PropTypes.object,
  personalDetails: PropTypes.array,
  productPriceList: PropTypes.array,
  getProductPricingAndInfo: PropTypes.func,
  countryTierInfo: PropTypes.array,
  getAppliedPromo: PropTypes.func,
  couponInfo: PropTypes.object,
  getStep: PropTypes.func,
  getBillingInformation: PropTypes.func,
  billingInformation: PropTypes.object,
  b2cBaseUrl: PropTypes.string,
};
function mapStateToProps(state) {
  return {
    b2c: state.b2c
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...b2cActions }, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(BillingInformation);
