import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import {
  Col,
  Row,
  Typography,
  Button,
  Table,
  Input,
  Icon,
  Tooltip
} from "antd";
import { connect } from "react-redux";
import IntlMessages from "../../../../services/intlMesseges";
import { EditCell } from "../../../shared/components/helperCells";
import * as OrgActions from "../../../../redux/actions/organization-actions";
import * as userActions from "../../../../redux/actions/organization-actions";
import * as Permission from "../../../../services/permissions";

class ListDivisonOrganization extends Component {
  state = {
    searchText: "",
    searchedColumn: "",
    pagination: {
      pageSize: 10,
      current: 1,
      hideOnSinglePage: true,
      showTotal: (total, range) => {
        return (
          <span>
            <IntlMessages id="admin.text.showing" /> {range[0]}-{range[1]}{" "}
            <IntlMessages id="admin.text.of" /> {total}{" "}
            <IntlMessages id="admin.text.results" />
          </span>
        );
      }
    },
    filters: {}
  };

  async componentDidMount() {
    this.handleTableChange({ current: 1, pageSize: 10 }, {}, {});
  }

  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({ loading: true });
    this.props
      .getDivisions({
        orgId: this.props.match.params.id,
        filters: filters,
        pagination: { page: pagination.current, pageSize: pagination.pageSize },
        sorter: sorter
      })
      .then(resp => {
        pager.total = resp.paging.totalRecords;
        this.setState({
          loading: false,
          data: resp.data,
          pagination: pager
        });
      })
      .catch(_ex => {
        this.setState({ loading: false });
      });
  };

  getHeaderKeys = () => {
    return [
      {
        title: <IntlMessages id="admin.division.name" />,
        dataIndex: "name",
        ...this.getColumnSearchProps("name")
      },
      {
        title: <IntlMessages id="admin.organizations.name" />,
        dataIndex: "parentBranch",
        ...this.getColumnSearchProps("parentBranch")
      },
      {
        title: <IntlMessages id="admin.divisionlist.country" />,
        dataIndex: "country",
        ...this.getColumnSearchProps("country")
      },
      {
        title: <IntlMessages id="admin.divisionlist.state" />,
        dataIndex: "state",
        ...this.getColumnSearchProps("state")
      },
      {
        title: <IntlMessages id="admin.divisionlist.city" />,
        dataIndex: "city",
        ...this.getColumnSearchProps("city")
      },
      {
        title: <IntlMessages id="admin.tablelist.actions" />,
        render: (_text, record) => (
          <span>
            <EditCell
              onEditCell={() => {
                this.editItem(record.organizationId);
              }}
            />

            <button
              className="icon"
              onClick={() => {
                this.viewEmployees(record.organizationId);
              }}
            >
              <Tooltip title="Employees">
                <i className="fas fa-users"></i>
              </Tooltip>
            </button>


            <button
              className="icon"
              onClick={() => {
                this.orderHistory(record.organizationId);
              }}
            >
              <Tooltip title="Order History">
                <i className="fas fa-file-alt"></i>
              </Tooltip>
            </button>
            <button
              className="icon"
              onClick={() => {
                this.accountsBilling(record.organizationId);
              }}
            >
              <Tooltip title="View Invoice">
                <i className="fas fa-file-invoice-dollar"></i>
              </Tooltip>
            </button>
            {(Permission.isRole("Division Manager") || Permission.isRole("Travel Manager")) && Permission.isTopLevelManager() ?
              <button
                className="icon"
                onClick={() => {
                  this.viewLicense(record.organizationId);
                }}
              >
                <Tooltip
                  title={
                    <IntlMessages id="admin.organizations.actions.manageplans" />
                  }
                >
                  <i className="fas fa-id-badge"></i>
                </Tooltip>
              </button> : ''
            }

            {(Permission.isRole('Administrator') || Permission.isRole('CSR') || Permission.isRole('Division Manager') || Permission.isRole('Travel Manager') || Permission.isRole('Admin')) /* && record.parentOrgId */ ?

              <button
                className="icon"
                onClick={() => {
                  this.props.history.push("../../../manage-itinerary/list/"+this.props.match.params.id+"/" + record.organizationId + "/")
                }}
              >
                <Tooltip
                  title={
                    <IntlMessages id="admin.organizations.actions.viewitinerary" />
                  }
                >
                  {/* <i {...AccountsBillingIcon}></i> */}
                  <i className="fas fa-suitcase-rolling"></i>
                </Tooltip>
              </button> : ''}
            {Permission.isRole('Administrator') || Permission.isRole('Admin') || Permission.isRole('CSR')
              || Permission.isRole('Travel Manager') || Permission.isRole('Division Manager') ?
              <button
                className="icon"
                onClick={() => {
                  this.organizationLogs(record.organizationId);
                }}
              >
                <Tooltip title="View Logs">
                  <i className="fas fa-clipboard-list"></i>
                </Tooltip>
              </button>
              : ""}
          </span>
        )
      }
    ];
  };

  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => {
      return (
        <div style={{ padding: 8 }}>
          <Input
            ref={node => {
              this.searchInput = node;
            }}
            value={selectedKeys}
            onChange={e =>
              setSelectedKeys(e.target.value ? e.target.value.trimLeft() : "")
            }
            onPressEnter={() =>
              this.handleSearch(
                selectedKeys,
                confirm,
                dataIndex,
                clearFilters,
                setSelectedKeys
              )
            }
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Button
            type="primary"
            onClick={() =>
              this.handleSearch(
                selectedKeys,
                confirm,
                dataIndex,
                clearFilters,
                setSelectedKeys
              )
            }
            disabled={(selectedKeys != "" && selectedKeys !== null) ? false : true}
            icon="search"
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            <IntlMessages id="admin.search" />
          </Button>
          <Button
            disabled={(selectedKeys != "" && selectedKeys !== null) ? false : true}
            onClick={() => this.handleReset(clearFilters, dataIndex)}
            size="small"
            style={{ width: 90 }}
          >
            <IntlMessages id="admin.reset" />
          </Button>
        </div>
      );
    },
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    }
  });

  handleSearch = (
    selectedKeys,
    confirm,
    dataIndex,
    clearFilters,
    setSelectedKeys
  ) => {
    if (selectedKeys == "") {
      return false;
    }

    this.setState({ searchText: selectedKeys });
    let filters = this.state.filters;
    filters[dataIndex] = {
      val: selectedKeys,
      clearf: clearFilters,
      filter: true,
      setSelectedKeys: setSelectedKeys,
      confirm: confirm
    };
    this.setState({ filters: filters });
    confirm();
  };

  handleReset = (clearFilters, dataIndex) => {
    clearFilters();
    let filters = this.state.filters;
    if (filters[dataIndex]) {
      if(filters[dataIndex].setSelectedKeys && typeof filters[dataIndex].setSelectedKeys === 'function'){
        filters[dataIndex].setSelectedKeys("");
        filters[dataIndex].confirm();
      }
    }
    filters[dataIndex] = { val: "", clearf: "", filter: false };
    this.setState({ filters: filters });
    this.setState({ searchText: "" });
  };

  viewEmployees = id => {
    this.props.history.push("./" + id + "/employees/");
  };

  editItem = id => {
    this.props.history.push("./" + id + "/edit/");
  };

  orderHistory = id => {
    this.props.history.push("./" + id + "/order-history/");
  };
  accountsBilling = id => {
    this.props.history.push("./" + id + "/invoices/");
  };
  organizationLogs = id => {
    this.props.history.push("../../organization-logs/" + id);
  };
  viewLicense = id => {
    this.props.history.push("./" + id + "/license/");
  };
  addItem = () => {
    this.props.history.push("./add/");
  }
  render() {
    let _state = this.state;
    let _this = this;
    let filtertag = Object.keys(this.state.filters).map(function (key1) {
      let keyLabel = _this.getHeaderKeys().find(el => el.dataIndex === key1);
      if (keyLabel.title.props && keyLabel.title.props.id) {
        keyLabel = <IntlMessages id={keyLabel.title.props.id} />;
      } else {
        keyLabel = key1;
      }
      if (_state.filters[key1].filter) {
        return (
          <span>
            <Button
              key={key1}
              type="primary"
              onClick={() =>
                _this.handleReset(_state.filters[key1].clearf, key1)
              }
            >
              {keyLabel} : {_state.filters[key1].val}
              <Icon type="close" />
            </Button>
            &nbsp;
          </span>
        );
      }
    });
    return (
      <div>
        <Row gutter={24}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Typography.Title level={4}>
              <IntlMessages id='admin.organizations.division' />
            </Typography.Title>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <ul className='upload-xlx'>
              <li>
                <Button
                  type='primary'
                  htmlType='button'
                  onClick={() => this.props.history.push('../../') /* this.props.history.goBack() */}
                >
                  <IntlMessages id='admin.userlisting.back' />
                </Button>{' '}
              </li>

              {!Permission.isRole('Division Manager') ? (
                <li>
                  <Button type='primary' htmlType='button' onClick={() => this.addItem()}>
                    <IntlMessages id='admin.userlisting.addnew' />
                  </Button>
                </li>
              ) : (
                ''
              )}
            </ul>
          </Col>
        </Row>
        <hr />
        <Row gutter={24} style={{ marginBottom: '20px' }}>
          <Col xs={24}>{filtertag}</Col>
        </Row>
        <Row gutter={24}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ overfloX: 'auto' }}>
            <Table
              bordered
              columns={this.getHeaderKeys()}
              rowKey={(record) => record.organizationId}
              loading={this.state.loading}
              pagination={this.state.pagination}
              dataSource={this.state.data}
              onChange={this.handleTableChange}
            />
          </Col>
        </Row>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    ...state.user
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...userActions, ...OrgActions }, dispatch);
}
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(ListDivisonOrganization)
);
