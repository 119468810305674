import React, { Component } from "react";
import { Result, Button, Col, Row } from "antd";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import IntlMessages from "../../../../services/intlMesseges";
class OrganizationSucess extends Component {
  render() {
    return (
      <div>
        <Result
          status="success"
          title={`Successfully added new `+ (this.props.organizationTypeLabel ? this.props.organizationTypeLabel : 'B2B2C Organization')}
          // subTitle={<IntlMessages id="admin.success.membership" />}
          extra={this.props.membershipNumber}
        />
        <div className="wrap" style={{ maxWidth: "500px" }}>
          <Row gutter={24}>
            <Col
              xs={24}
              sm={24}
              md={24}
              style={{ marginTop: "20px" }}
            >
              <Button onClick={() => this.props.history.push('../')} style={{ width: "200px" }}><IntlMessages id="admin.view.b2b2c-organizations" /></Button>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}
export default withRouter(
  connect(null, null, null, { forwardRef: true })(OrganizationSucess)
);
