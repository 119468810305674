import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Wizard from "../shared/wizard/wizard.jsx";
import * as b2cActions from "../../../redux/actions/b2c-actions";
import * as userActions from "../../../redux/actions/user-actions";
import { WrappedRegistrationForm } from "./traveller-form";
import { Beforeunload } from 'react-beforeunload';
class PersonalDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      totalPrice: props.planTerm ? props.planTerm.totalPrice : 0,
      appliedPromoText:""
    };
    this.handleBack = this.handleBack.bind(this);
    this.updatePrice = this.updatePrice.bind(this);
  }
  async componentDidMount() {
    window.scrollTo(0, 0);
    await this.props.getPersonalInformation();
//    await this.props.allUsStates();
    if (this.props.planTerm && Object.keys(this.props.planTerm).length === 0) {
      await this.props.getStep();
      await this.props.getUserData();
      await this.props.getPlanInformation();
      await this.props.getPlanTermInformation();
      await this.props.getProductPricingAndInfo();
      if (parseInt(this.props.step) < 2) {
        this.props.history.push(this.props.b2cBaseUrl+ "/plan-term");
        
      }else{
        //Update price to base on refresh
        let planTermObject = this.props.planTerm;
        planTermObject.totalPrice = this.props.planTerm.Price;
        await this.props.SavePlanTerm(planTermObject);
        this.calculateTotalPrice();
      }
    } else if (parseInt(this.props.step) < 2) {
      this.props.history.push(this.props.b2cBaseUrl+ "/plan-term");

    }else{
      //Update price to base on refresh
      let planTermObject = this.props.planTerm;
      planTermObject.totalPrice = this.props.planTerm.Price;
      await this.props.SavePlanTerm(planTermObject);
      this.calculateTotalPrice();
    }
   

  }

  async calculateTotalPrice(){
    var totalPrice = 0;
    var dayPriceObject;
    if (this.props.personalDetails && this.props.personalDetails.length > 0) {
      for(var i=0;i<this.props.personalDetails.length;i++){
        if(this.props.planTerm.isAnnual){
          let productCount = this.props.productInfo.length;
          var productId = this.props.productInfo[productCount - 1].productId;
          dayPriceObject = this.props.productPriceList.find(
            x =>
              x.tierId === 1 &&
              x.dayPackage === 365 &&
              x.productId === parseInt(productId)
          );
          totalPrice = dayPriceObject.retailPrice * this.props.personalDetails.length;
          this.setState({ daysError: "You have selected Annual plan!",countryError:"You have selected Annual plan!" });
          break;
        }else{
          var product_Id = this.props.personalDetails[i].product_id;
          dayPriceObject = this.props.productPriceList.find(
            x =>
              x.tierId === parseInt(this.props.planTerm.tierValue) &&
              x.dayPackage === parseInt(this.props.planTerm.days) &&
              x.productId === parseInt(product_Id)
          );
          totalPrice = totalPrice + dayPriceObject.retailPrice
        }
      }
    }else{
      dayPriceObject = this.props.productPriceList.find(
        x =>
          x.tierId === parseInt(this.props.planTerm.tierValue) &&
          x.dayPackage === parseInt(this.props.planTerm.days) &&
          x.productId === parseInt(this.props.planType.productId)
      );
      totalPrice = dayPriceObject ? dayPriceObject.retailPrice : 0;
    }
    let planTermObject = this.props.planTerm;
    planTermObject.totalPrice = totalPrice;
    await this.props.SavePlanTerm(planTermObject);
    this.updatePrice();
  }

  async handleBack() {
    await this.props.SaveStep("2");
    this.props.history.push(this.props.b2cBaseUrl+ "/plan-term");

  }

  updatePrice(evt) {
    this._bindPlanPrice()
  }

  async  _bindPlanPrice(){
    await this.props.getAppliedPromo();
    var totalPrice = this.props.planTerm.totalPrice;
    if(this.props.couponInfo && this.props.couponInfo.promoOfferId){
      var discountedPrice = 0;
      if(this.props.couponInfo.discountType === "P"){
        discountedPrice = this.props.planTerm.totalPrice*this.props.couponInfo.discountFigure/100;
      }
      else{
        discountedPrice = this.props.couponInfo.discountFigure;
      }
      var appliedPromoText = "Discount of $"+discountedPrice+" has been applied to the total plan fee"
      totalPrice = totalPrice - discountedPrice;
      this.setState({
        appliedPromoText: appliedPromoText,
      });
    }
    this.setState({
      totalPrice: totalPrice < 0 ? 0 : totalPrice
    });
  }

  onUnload = (e) => {
    e.preventDefault();
    //this.props.flushOrderData();
    //this.props.ClearData();
  }
  
  render() {
    return (
      <section id="banner">
        <Beforeunload onBeforeunload={(e) => this.onUnload(e)}>
          <Wizard wizardActiveClass="step3" />

          {this.props.countryTierInfo &&
          this.props.countryTierInfo.length > 0 &&
          this.props.planType ? (
            <div>
              <div
                className="wrap900 price-sticky-head"
                style={{
                  display: "block",
                  maxWidth: "1300px",
                  zIndex: "100",
                  position: "sticky"
                }}
              >
                <div className="title" style={{ background: "#FFF" }}>
                  <h1>
                    <span>Plan Fee:</span>{" "}
                    {this.state.totalPrice
                      ? "$" + this.state.totalPrice.toFixed(2) + " USD"
                      : "$0.00 USD"}
                  </h1>
                  <span>{this.state.appliedPromoText}</span>
                </div>
              </div>
              {this.props.planType && this.props.planType.productId && parseInt(this.props.step) >= 2? (
                <WrappedRegistrationForm
                planTerm={this.props.planTerm}
                SaveStep={this.props.SaveStep}
                SavePlanTerm={this.props.SavePlanTerm}
                updatePrice={this.updatePrice}
                SavePersonalDetails={this.props.SavePersonalDetails}
                handleBack={this.handleBack}
                history={this.props.history}
                productPricing={this.props.productPricing}
                productInfo={this.props.productInfo}
                planType={this.props.planType}
                productPriceList={this.props.productPriceList}
                personalDetails={this.props.personalDetails}
                validateTravellerEmail={this.props.validateTravellerEmail}
                emailCheckResponse={this.props.emailCheckResponse}
                flushEmailCheckResponse={this.props.flushEmailCheckResponse}
                userData={this.props.userData}
                travellerInfo={this.props.travellerInfo}
                getTravellerInfo={this.props.getTravellerInfo}
                isLoggedIn={this.props.isLoggedIn}
                countryTierInfo={this.props.countryTierInfo}
                location={this.props.location}
                b2cBaseUrl={this.props.b2cBaseUrl}
                getAllCountries={this.props.getAllCountries}
                allCountries={this.props.allCountries}
                />
              ):("")}
            </div>
          ) : (
            ""
          )}
        </Beforeunload>
      </section>
    );
  }
}
PersonalDetails.propTypes = {
  getProductPricingAndInfo:PropTypes.func,
  productPricing: PropTypes.array,
  getPersonalInformation: PropTypes.func,
  getPlanInformation: PropTypes.func,
  getPlanTermInformation: PropTypes.func,
  SaveStep: PropTypes.func,
  SavePersonalDetails: PropTypes.func,
  step: PropTypes.string,
  SavePlanTerm: PropTypes.func,
  planTerm: PropTypes.object,
  productInfo: PropTypes.array,
  planType: PropTypes.object,
  productPriceList: PropTypes.array,
  personalDetails: PropTypes.array,
  validateTravellerEmail: PropTypes.func,
  emailCheckResponse: PropTypes.object,
  flushEmailCheckResponse: PropTypes.func,
  getUserData:PropTypes.func,
  userData:PropTypes.object,
  getTravellerInfo:PropTypes.func,
  travellerInfo:PropTypes.object,
  isLoggedIn:PropTypes.bool,
  location:PropTypes.object,
  history:PropTypes.object,
  getAppliedPromo:PropTypes.func,
  couponInfo:PropTypes.object,
  getStep:PropTypes.func,
  b2cBaseUrl:PropTypes.string

};
function mapStateToProps(state) {
  return {
    ...state.b2c,
    ...state.user
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...b2cActions,...userActions }, dispatch);
}
export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { forwardRef: true }
)(PersonalDetails);
