import API from '../api/audit-logs-api'
export function getInvoiceLog(id,data) {
    return async function (dispatch, getAction) {
        try {
            let resp = await API.getInvoiceLogs(id,data);
            return resp;

        } catch (error) {
            return { "error": true }
        }
    }
}
export function getUserLog(id,data) {
    return async function (dispatch, getAction) {
        try {
            let resp = await API.getUserLogs(id,data);
            return resp;

        } catch (error) {
            return { "error": true }
        }
    }
}
export function getOrgLogs(id,data) {
    return async function (dispatch, getAction) {
        try {
            let resp = await API.getOrganizationLogs(id,data);
            return resp;

        } catch (error) {
            return { "error": true }
        }
    }
}
export function getProdLogs(id,data) {
    return async function (dispatch, getAction) {
        try {
            let resp = await API.getProductLogs(id,data);
            return resp;

        } catch (error) {
            return { "error": true }
        }
    }
}
export function getPricingLog(id,data) {
    return async function (dispatch, getAction) {
        try {
            let resp = await API.getPricingLogs(id,data);
            return resp;

        } catch (error) {
            return { "error": true }
        }
    }
}