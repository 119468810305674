import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import NotificationAPI from "../../../redux/api/notification-api";
import IntlMessages from "../../../services/intlMesseges";
import EditTraveler from "./edit/edit";
import PlanType from "./edit/plan-type";
import ActivityLog from "./edit/activity-log";
import { StickyContainer, Sticky } from "react-sticky";
import { Drawer, Input, Tabs, Form, Row, Col, Button } from "antd";
import { notifyUser } from "../../../services/notification-service";
import TravelerMap from "../organizations/edit-organization/employees/employees-map";
import CheckInList from "../organizations/edit-organization/employees/checkin-list";
import AssistList from "../organizations/edit-organization/employees/assist-list";

const { TabPane } = Tabs;
const renderTabBar = (props, DefaultTabBar) => (
  <Sticky bottomOffset={80}>
    {({ style }) => (
      <DefaultTabBar
        {...props}
        style={{ ...style, zIndex: 1, background: "#fff" }}
      />
    )}
  </Sticky>
);
class ViewTravelers extends Component {
  state = {
    notifyVisible: false,
    userId: 0,
  };

  hideNotifyDrawer = () => {
    this.props.form.resetFields();
    this.setState({
      notifyVisible: false,
    });
  };

  showNotifyDrawer = () => {
    this.setState({
      notifyVisible: true,
    });
  };

  submitNotify = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        var data = {
          userId: this.props.match.params.id,
          title: values.title,
          message: values.message,
        };
        this.sendNotification(data);
      }
    });
  };

  async sendNotification(data) {
    let response = await NotificationAPI.notifyUser(data);
    if (response.errors && response.errors.length > 0) {
      notifyUser(response.errors[0].externalMessage, "error");
    } else {
      notifyUser("Success", "success");
    }
    this.hideNotifyDrawer();
  }
  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div>
        <Drawer
          title={<IntlMessages id="admin.button.notify" />}
          width={420}
          onClose={this.hideNotifyDrawer}
          visible={this.state.notifyVisible}
          bodyStyle={{ paddingBottom: 80 }}
          closable={true}
          maskClosable={false}
        >
          <Form layout="vertical">
            <Row gutter={24}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Form.Item label={<IntlMessages id="admin.formfield.title" />}>
                  {getFieldDecorator("title", {
                    rules: [
                      {
                        required: true,
                        message: <IntlMessages id="admin.input.required" />,
                      },
                    ],
                  })(<Input />)}
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Form.Item
                  label={<IntlMessages id="admin.formfield.description" />}
                >
                  {getFieldDecorator("message")(<Input />)}
                </Form.Item>
              </Col>
            </Row>
            <Button onClick={this.submitNotify} type="primary">
              <IntlMessages id="admin.button.send" />
            </Button>
          </Form>
        </Drawer>
        <Row gutter={24} style={{ position: "relative" }}>
          <StickyContainer>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <div
                style={{
                  position: "absolute",
                  right: "10px",
                  top: "0",
                  zIndex: "100",
                }}
              >
                {/* <Button
                  type="primary"
                  className=""
                  htmlType="button"
                  style={{marginBotom:"5px"}}
                  onClick={this.showNotifyDrawer}
                >
                  <IntlMessages id="admin.button.notify" />
                </Button>&nbsp;&nbsp; */}
                <Button
                  type="primary"
                  className=""
                  htmlType="button"
                  style={{ marginBotom: "5px" }}
                  onClick={() => this.props.history.goBack()}
                >
                  <IntlMessages id="admin.button.back" />
                </Button>
              </div>
              <Tabs className="travller-tabs" defaultActiveKey="1" renderTabBar={renderTabBar}>
                <TabPane
                  tab={<IntlMessages id="admin.traveler.travelersdetails" />}
                  key="travelersdetails"
                >
                  <EditTraveler />
                </TabPane>
                <TabPane
                  tab={<IntlMessages id="admin.traveler.plans" />}
                  key="plans"
                >
                  <PlanType />
                </TabPane>
                <TabPane
                  tab={<IntlMessages id="admin.traveler.activitylog" />}
                  key="activitylog"
                >
                  <ActivityLog />
                </TabPane>
                <TabPane tab="Map" key="map">
                  <TravelerMap />
                </TabPane>
                <TabPane tab="Check In" key="check-in">
                  <CheckInList />
                </TabPane>
                <TabPane tab="Assist" key="assist">
                  <AssistList />
                </TabPane>
              </Tabs>
            </Col>
          </StickyContainer>
        </Row>
      </div>
    );
  }
}
ViewTravelers.propTypes = {
  location: PropTypes.object,
  updateUser: PropTypes.func,
};

const WrappedForm = Form.create({ name: "edit-traveler" })(ViewTravelers);
export default withRouter(
  connect(null, null, null, { forwardRef: true })(WrappedForm)
);
