import React, { Component } from "react";
import { Tabs, Button } from "antd";
import moment from "moment";

const dateFormathh = "MM/DD/YYYY HH:mm:ss";

export default class AssistTravelerInfoWindow extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    return this.props.infoWindowData && this.props.type === "assist" ? (
      <div className="assist-info-window">
        <h5>Assist</h5>
        <ul>
          <li>Name: {this.props.infoWindowData.userName}</li>
          <li>
            City:{" "}
            {this.props.infoWindowData.city
              ? this.props.infoWindowData.city
              : "Unnamed location"}
          </li>
          <li>
            State:{" "}
            {this.props.infoWindowData.state
              ? this.props.infoWindowData.state
              : "Unnamed location"}
          </li>
          <li>
            Country:{" "}
            {this.props.infoWindowData.country
              ? this.props.infoWindowData.country
              : "Unnamed location"}
          </li>
          <li>
            Date:{" "}
            {moment(this.props.infoWindowData.creationDateTime).format(
              dateFormathh
            )}
          </li>
        </ul>
      </div>
    ) : this.props.infoWindowData && this.props.type === "traveler-location" ? (
      <div className="assist-info-window">
        <h5>Traveler Details</h5>
        <ul>
          <li>
            Name:{" "}
            {this.props.infoWindowData.firstName +
              " " +
              this.props.infoWindowData.lastName}
          </li>
          <li>
            State:{" "}
            {this.props.infoWindowData.state
              ? this.props.infoWindowData.state
              : "Unnamed location"}
          </li>
          <li>
            Country:{" "}
            {this.props.infoWindowData.country
              ? this.props.infoWindowData.country
              : "Unnamed location"}
          </li>
          <li>
            Date:{" "}
            {moment(this.props.infoWindowData.creationDateTime).format(
              dateFormathh
            )}
          </li>
          <li>Contact: {this.props.infoWindowData.contact}</li>
        </ul>
      </div>
    ) : (
      <div className="assist-info-window">
        <h5>Asset Tracking</h5>
        <ul>
          <li>Title: {this.props.infoWindowData.title}</li>
        </ul>
      </div>
    );
  }
}
