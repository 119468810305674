import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as userActions from "../../../../redux/actions/user-actions";
import IntervalTimer from 'react-interval-timer';
import moment from "moment";

class AuthTokenTimer extends Component {
  constructor(props) {
    super(props);
    this.state = {
        enabled:true,
        timerTime:10000
    };
    this.intervalCallBack=this.intervalCallBack.bind(this)
  }
  async componentDidMount() {
    //console.log("AuthTokenTimer componentDidMount");
    await this.props.getTokenExpiry();
    //console.log("AuthTokenTimer ", this.props);
    if(this.props.expirayDate && this.props.expirayDate !== ""){
        var expiryDateTime = new Date(moment.utc(this.props.expirayDate))
        var currentDateTime = new Date();
        var difference = (currentDateTime - expiryDateTime)/1000;
        //console.log('AuthTokenTimer componentDidMountcurrentDateTime', currentDateTime);
        //console.log('AuthTokenTimer componentDidMountexpiryDateTime', expiryDateTime);
        //console.log('AuthTokenTimer componentDidMount diff ', difference);
        if(difference < 0){
            var time = Math.abs(difference)*1000;
            time = time - 60*1000;
            //console.log('AuthTokenTimer time set for componentDidMount ', time);
            //time=10000;
            this.setState({timerTime:time})
        }else{
            this.props.LogOutUser();
            localStorage.clear("tokenData");
            this.props.history.push("/login");
        }
    }

  }

  async intervalCallBack(evt){
    await this.props.flushTimer();
    if(this.props.token){
        var objData = {
            "accessToken": this.props.token,
            "refreshToken": this.props.refreshToken
        }
        await this.props.updateToken(objData);
        if(this.props.expirayDate !== ""){
          
            var expiryDateTime = new Date(moment.utc(this.props.expirayDate))
            var currentDateTime = new Date();
            var difference = (currentDateTime - expiryDateTime) / 1000;
            //console.log('AuthTokenTimer currentDateTime:intervalCallBack', currentDateTime);
            //console.log('AuthTokenTimer expiryDateTime:intervalCallBack', expiryDateTime);
            //console.log('AuthTokenTimer diff:intervalCallBack ', difference);
            if(difference < 0){
                var time = Math.abs(difference)*1000
                time = time - 60*1000;
               // time=10000;
               //console.log('AuthTokenTimer time set for intervalCallBack ', time);
                this.setState({timerTime:time})
            }else{
                this.props.LogOutUser();
                localStorage.clear("tokenData");
                this.props.history.push("/login");
            }
        }else{
          
            this.props.LogOutUser();
            localStorage.clear("tokenData");
            this.props.history.push("/login");
            
        }
    }else{
        localStorage.clear("tokenData");
        this.props.history.push("/login");
    }
  }

  async componentWillUnmount(){
    //await this.props.flushTimer()
  }

  render() {
    return (
        <div>
            <IntervalTimer
                timeout={this.state.timerTime}
                callback={this.intervalCallBack}
                enabled={this.state.enabled}
                repeat={true}
            />
        </div>
    );
  }
}

AuthTokenTimer.propTypes = {
  flushTimer:PropTypes.func,
  getTokenExpiry: PropTypes.func,
  expirayDate: PropTypes.string,
  refreshToken:PropTypes.string,
  token:PropTypes.string,
  updateToken:PropTypes.func
};
function mapStateToProps(state) {
  return { ...state.user };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...userActions }, dispatch);
}
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(AuthTokenTimer)
);
