import React, { Component } from "react";
import IntlMessages from "../../../../services/intlMesseges";
import { Table, Typography, Row, Col, Button } from "antd";
const dataSource = [
  {
    key: "1",
    name: "Mike",
    age: 32,
    address: "10 Downing Street"
  },
  {
    key: "2",
    name: "John",
    age: 42,
    address: "10 Downing Street"
  }
];
export default class TravelerInvoice extends Component {
  render() {
    const columns = [
      {
        title: "Item/Description",
        dataIndex: "itemdescription",
        key: "itemdescription",
        width: "40%"
      },
      {
        title: "Quantity",
        dataIndex: "quantity",
        key: "quantity"
      },
      {
        title: "Unit",
        dataIndex: "unit",
        key: "unit"
      },
      {
        title: "Unit Price $",
        dataIndex: "unitprice",
        key: "unitprice"
      },
      {
        title: "Total Price $",
        dataIndex: "totalprice",
        key: "totalrice"
      }
    ];
    return (
      <div>
        <Row gutter={24}>
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography.Title level={4}>
              <IntlMessages id="admin.account.viewinvoice" />
            </Typography.Title>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <Button
              type="primary"
              className="right-fl"
              htmlType="button"
              onClick={() => this.props.history.push("../")}
            >
              <IntlMessages id="admin.userlisting.back" />
            </Button>
          </Col>
        </Row>
        <hr />
        <div
          className="wrap"
          style={{
            maxWidth: "1000px",
            padding: "30px",
            border: "1px solid #000000"
          }}
        >
          <Row gutter={24}>
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              style={{ textAlign: "left", fontWeight: "bold" }}
            >
              FocusPoint International Inc.
              <br />
              15830 Foltz Parkway
              <br />
              Strongsville, Ohio 44149
              <br />
              (440) 878-7600
              <br />
              (440) 878-0092 Fax
              <br />
              Federal ID No. 47-4612937
              <br />
            </Col>

            <Col
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              style={{ border: "2px solid #000000", fontWeight: "bold" }}
            >
              <h1 style={{ marginBottom: "0", paddingBottom: "0" }}>INVOICE</h1>
              Invoice Number:xxxxxxx-xx
              <br />
              Invoice Date:mm/dd/year
              <br />
              Page: 1
            </Col>
          </Row>
          <Row gutter={24} style={{ marginTop: "30px" }}>
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              style={{ textAlign: "left", fontWeight: "bold" }}
            >
              Bill
              <Row gutter={24}>
                <Col xs={2} sm={2} md={2} lg={2} xl={2}>
                  To
                </Col>
                <Col xs={22} sm={22} md={22} lg={22} xl={22}>
                  Organization Name:
                  <br />
                  Organization Address:
                  <br />
                  Organization Address
                </Col>
              </Row>
            </Col>
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              style={{ textAlign: "left", fontWeight: "bold" }}
            >
              Ship
              <Row gutter={24}>
                <Col xs={2} sm={2} md={2} lg={2} xl={2}>
                  To
                </Col>
                <Col xs={22} sm={22} md={22} lg={22} xl={22}>
                Organization Name:
                  <br />
                  Organization Address:
                  <br />
                  Organization Address
                </Col>
              </Row>
            </Col>
          </Row>
          <Row gutter={24} style={{ marginTop: "40px" }}>
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              style={{ textAlign: "left", fontWeight: "bold" }}
            >
              <Row gutter={24}>
                <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                  Week Ended :
                </Col>
                <Col xs={18} sm={18} md={18} lg={18} xl={18}>
                  MM/DD/YYYY
                </Col>
              </Row>
              <Row gutter={24}>
                <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                  Duw Date:
                </Col>
                <Col xs={18} sm={18} md={18} lg={18} xl={18}>
                  MM/DD/YYYY
                </Col>
              </Row>
              <Row gutter={24} style={{ marginTop: "10px" }}>
                <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                  Terms:
                </Col>
                <Col xs={18} sm={18} md={18} lg={18} xl={18}>
                  Due On Recipt
                </Col>
              </Row>
            </Col>
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              style={{ textAlign: "left", fontWeight: "bold" }}
            >
              <Row gutter={24}>
                <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                  Customer ID :
                </Col>
                <Col xs={18} sm={18} md={18} lg={18} xl={18}>
                  XXXXXX
                </Col>
              </Row>
              <Row gutter={24}>
                <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                  P.O Number :
                </Col>
                <Col xs={18} sm={18} md={18} lg={18} xl={18}>
                  XXXXXX
                </Col>
              </Row>
              <Row gutter={24}>
                <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                  Job Number :
                </Col>
                <Col xs={18} sm={18} md={18} lg={18} xl={18}>
                  XXXXXX
                </Col>
              </Row>
              <Row gutter={24}>
                <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                  Our Order Number :
                </Col>
                <Col xs={18} sm={18} md={18} lg={18} xl={18}>
                  XXXXXX
                </Col>
              </Row>
            </Col>
          </Row>
          <Row gutter={24} style={{ marginTop: "25px" }}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Table
                bordered
                dataSource={dataSource}
                columns={columns}
                pagination={false}
              />
            </Col>
          </Row>
          <Row gutter={24}>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={24}
              style={{
                textAlign: "left",
                fontWeight: "bold",
                marginTop: "25px"
              }}
            >
              Remit Wire Payments to:
              <br />
              Name of Bank Account: Fifth Third Bank
              <br />
              Routing Number: 042000314
              <br />
              Account Number: 7283970288
              <br />
              SWIFT Code: FTBCUS3C
              <br />
            </Col>
          </Row>
          <Row gutter={24} style={{ fontWeight: "bold", marginTop: "25px" }}>
            <Col
              xs={16}
              sm={16}
              md={16}
              lg={16}
              xl={16}
              style={{
                border: "2px solid #000000",
                padding: "30px",
                height: "120px",
                fontWeight: "bold"
              }}
            >
              REMIT PAYMENTS TO OUR CORPORATE OFFICE: <br />
              15830 FOLTZ INDUSTRIAL PARKWAY; <br />
              CLEVELAND OH 44149
            </Col>
            <Col
              xs={8}
              sm={8}
              md={8}
              lg={8}
              xl={8}
              style={{
                border: "2px solid #000000",
                padding: "15px",
                height: "120px",
                textAlign: "left"
              }}
            >
              <Row gutter={24}>
                <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                  Total (USD):
                </Col>
                <Col xs={16} sm={16} md={16} lg={16} xl={16}>
                  $
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}
