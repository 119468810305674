import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Wizard from "../shared/wizard/wizard.jsx";
import CalendarIconsvg from "../../shared/svg/b2c-svg/calendar-icon-svg.jsx";
import DateIconsvg from "../../shared/svg/b2c-svg/date-icon-svg.jsx";
import GlobeIconsvg from "../../shared/svg/b2c-svg/globe-icon-svg.jsx";
import * as b2b2cActions from "../../../redux/actions/b2b2c-actions";
import { Select, Row, Col, DatePicker } from "antd";
import moment from "moment";
import { Beforeunload } from "react-beforeunload";
//import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import postscribe from "postscribe";
import PriceCalculation from "../personal-details/price-calculation";
import config from "../../../../../config.js";

const { Option } = Select;
class PlanTerm extends Component {
  constructor(props) {
    super(props);
    this.displayData = [];
    this.state = {
      startDate: null,
      selectedCountry: [],
      selectedDaysValue: "",
      productPrice: this.props.planType && this.props.planType.price,
      defaultDaysPlan: [],
      selectedCountryTier: "4",
      countryDropDownData: [],
      countryError: "",
      daysError: "",
      dateError: "",
      savedPlanCountriesList: [],
      savedPlanDate: "",
      savedPlanDays: "",
      is_annual: false,
      appliedPromoText: "",
      savedPlanEndDate: "",
      currency: "USD",
      currencyRate: 1,
      dp: "",
    };
    this.handleClick = this.handleClick.bind(this);
    this.handleCountryCheck = this.handleCountryCheck.bind(this);
    this.handleDaysCheck = this.handleDaysCheck.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  async componentDidMount() {
    postscribe(
      "#banner",
      "<script> (function(h,o,t,j,a,r){ h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)}; h._hjSettings={hjid:2750169,hjsv:6}; a=o.getElementsByTagName('head')[0]; r=o.createElement('script');r.async=1; r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv; a.appendChild(r); })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv='); </script>"
    );
    this.setState({
      currency: this.props.currency,
      currencyRate: this.props.currencyRate,
    });
    window.scrollTo(0, 0);
    await this.props.getPlanTermInformation();
    if (this.props.planType && Object.keys(this.props.planType).length === 0) {
      await this.props.getStep();
      await this.props.getResellerProductPricingAndInfo();
      await this.props.getPlanInformation();
      await this.props.getPersonalInformation();
      if (parseInt(this.props.step) < 1) {
        this.props.history.push(
          this.props.b2b2cBaseUrl +
            "/purchase/" +
            this.props.match.params.orgId +
            window.location.search
        );
      }
    } else if (parseInt(this.props.step) < 1) {
      this.props.history.push(
        this.props.b2b2cBaseUrl +
          "/purchase/" +
          this.props.match.params.orgId +
          window.location.search
      );
    }

    var tempCountryArray = [];
    for (var i = 0; i < this.props.countryTierInfo.length; i++) {
      var dataObject = {
        name: this.props.countryTierInfo[i].countryName,
        value: this.props.countryTierInfo[i].countryName,
      };
      tempCountryArray.push(dataObject);
    }
    this.setState({
      countryDropDownData: tempCountryArray,
    });
    this.getDaysForTier();
    if (this.props.planTerm && this.props.planTerm.country) {
      var planCountries = this.props.planTerm.country.toString().split(",");
      var tempPlanCountries = [];
      for (var j = 0; j < planCountries.length; j++) {
        var objCountry = this.props.countryTierInfo.find(
          (x) => x.countryId === parseInt(planCountries[j])
        );
        tempPlanCountries.push(objCountry.countryId);
      }
      var planStartDate = new Date(this.props.planTerm.date);
      var tempDate =
        planStartDate.getMonth() +
        1 +
        "/" +
        planStartDate.getDate() +
        "/" +
        planStartDate.getFullYear();

      let _savedPlanEndDate = new Date(this.props.planTerm.endDate);
      if (
        this.props.planType &&
        this.props.planType.name.toLowerCase().includes("annual")
      ) {
        _savedPlanEndDate = moment(tempDate).add(1, "year");
      }
      _savedPlanEndDate = moment(_savedPlanEndDate).format("MM/DD/YYYY");
      this.setState({
        savedPlanCountriesList: tempPlanCountries,
        selectedCountry: tempPlanCountries,
        savedPlanDate: tempDate,
        savedPlanEndDate: _savedPlanEndDate,
        startDate: this.props.planTerm.date,
        endDate: this.props.planTerm.endDate,
        selectedDaysValue:
          this.props.planType && this.props.planType.name.toLowerCase().includes('annual')
            ? 365
            : this.props.planTerm.days,
        savedPlanDays: parseInt(this.props.planTerm.days),
        is_annual: this.props.planType && this.props.planType.name.toLowerCase().includes('annual') ? true : false,
        selectedCountryTier: this.props.planTerm.tierValue
      });
      // this.CalculateTotalPrice();
    } else {
      let _selectedDaysValue = "NaN";
      if (this.props.planType && this.props.planType.name.toLowerCase().includes('annual')) {
        _selectedDaysValue = 365;
      }
      this.setState({
        savedPlanCountriesList: [-1],
        savedPlanDate: "NaN",
        selectedDaysValue: _selectedDaysValue,
        savedPlanDays:
          /*  this.props.planType.name.toLowerCase().indexOf("annual") > -1 ? "Up to "+365 : */ "NaN",
      });
    }
    

    let planTermObject = {};
    planTermObject.date = "";
    planTermObject.endDate = "";
    planTermObject.dp = '';
    planTermObject.country ="";
    planTermObject.totalPrice = this.state.productPrice;
    planTermObject.isAnnual =
      (this.props.planType && this.props.planType.name.toLowerCase().includes('annual') ? true : false);
      
      
      await this.props.SavePlanTerm(planTermObject);
    
    

  }

  calculateDp = (days) => {
    let dp = 8;
    if (days <= 8) {
      dp = 8;
    } else if (days <= 15) {
      dp = 15;
    } else if (days <= 30) {
      dp = 30;
    } else if (days <= 60) {
      dp = 60;
    } else if (days <= 90) {
      dp = 90;
    } else if (days <= 120) {
      dp = 120;
    } else if (days <= 365) {
      dp = 365;
    }

    return dp;
    
    
    
  };

  async handleCountryCheck(selectedCountries) {
    let planTermObject = this.props.planTerm;
    planTermObject.country = selectedCountries.join(",");
    planTermObject.dp = this.state.selectedDaysValue;
    await this.props.SavePlanTerm(planTermObject);
    this.setState({ selectedCountry: selectedCountries, countryError: "" });
  }

  handleDaysCheck(evt) {
    this.setState({
      selectedDaysValue: parseInt(evt),
      daysError: "",
    });
  }

  async handleClick(evt) {
    if (evt.currentTarget.dataset && evt.currentTarget.dataset.value) {
      if (evt.currentTarget.dataset.value === "back") {
        await this.props.SaveStep("1");
        this.props.history.push(
          this.props.b2b2cBaseUrl +
            "/purchase/" +
            this.props.match.params.orgId +
            window.location.search
        );
      } else {
        this.saveDataAndNext();
      }
    }
  }

  getDaysForTier() {
    let daysValues = [];
    for (var i = 0; i < this.props.productDays.length; i++) {
      daysValues.push(this.props.productDays[i].days);
    }
    this.setState({ defaultDaysPlan: daysValues });
  }

  handleChange = async (date) => {
    var selectedDate = new Date(date);
    const diffTime = selectedDate - new Date();
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    var dp = this.calculateDp(diffDays);
    if (diffDays < 0) {
      this.setState({
        startDate: date,
        dateError: "Please select a valid plan start date.",
      });
    } 
    
    else {
      this.setState(
        {
          startDate: date,
          dateError: "",
        },
        () => {
          if (
            this.props.planType &&
            this.props.planType.name.toLowerCase().includes("annual")
          ) {
            this.handleChangeEndDate(moment(date).add(364, 'days'));

          }
        }
      );
    }

    let planTermObject = this.props.planTerm;
    planTermObject.dp = this.calculateDp(dp);
    await this.props.SavePlanTerm(planTermObject);

    this.setState({ dp: planTermObject.dp });
  };

  handleChangeEndDate = (date) => {
    let self = this;
    let startDate = moment(this.state.startDate);
    let selectedDate = moment(date);
    let diffDays = selectedDate.diff(startDate, "days") + 1;
    let selectedDaysFromDiff = 0;
    
    // Check if selected end date is same as start date
    if (selectedDate.isSame(startDate, 'day')) {
        self.setState({
            selectedDaysValue: selectedDaysFromDiff,
            savedPlanEndDate: date,
            endDate: date,
            endDateError: "End date must be after the start date.",
        });
        console.log(moment(this.state.endDate).format(), "endat")
        return; // Exit the function early
    }
    
    if (diffDays < 0 || date === null) {
      self.setState({
        selectedDaysValue: selectedDaysFromDiff,
        savedPlanEndDate: date,
        endDate: date,
        endDateError: "Please select a valid plan end date.",
      });
    } else {
      if (self.props.productDays && self.props.productDays.length > 0) {
        for (var i = self.props.productDays.length; i > 0; i--) {
          if (diffDays <= self.props.productDays[i - 1].days) {
            selectedDaysFromDiff = self.props.productDays[i - 1].days;
          }
        }
        self.setState(
          {
            selectedDaysValue: selectedDaysFromDiff,
            savedPlanEndDate: date,
            endDate: date,
            endDateError: "",
          },
          async () => {
            // self.calculateProductPrice(self.state.selectedCountry, parseInt(selectedDaysFromDiff));

            let planTermObject = self.props.planTerm;
            planTermObject.date = startDate.format("MM-DD-YYYY");
            planTermObject.endDate = selectedDate.format("MM-DD-YYYY");
            planTermObject.dp = self.calculateDp(diffDays);

            this.setState({ dp: planTermObject.dp });
            await self.props.SavePlanTerm(planTermObject);
          }
        );
      } else {
        self.setState({
          savedPlanEndDate: date,
          endDate: date,
          endDateError: "",
        });
      }
    }
  };

  async saveDataAndNext() {
    if (moment(this.state.startDate).isSame(this.state.endDate, 'day')) {
      this.setState({ endDateError: "End date must be after the start date." });
      return false; // Exit the function early
    }
    if (
      this.state.selectedDaysValue !== "NaN" &&
      this.state.selectedDaysValue != undefined &&
      this.state.selectedCountry.length > 0 &&
      (this.state.startDate !== null || this.state.startDate != "")
       && this.state.startDate < this.state.endDate
    ) {
      
      var selectedDate = new Date(this.state.startDate);;
      const diffTime = selectedDate - new Date();
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      if (diffDays < 0) {
        this.setState({ dateError: "Please select a valid plan start date." });
      } else {
        let planTermObject = {};
        planTermObject.date = this.state.startDate._d
          ? this.state.startDate._d
          : this.state.startDate;
        planTermObject.endDate =
          typeof this.state.endDate !== "undefined" && this.state.endDate._d
            ? moment(this.state.endDate._d).format()
            : moment(this.state.endDate).format();
        planTermObject.days = this.state.selectedDaysValue;
        planTermObject.travelPeriod =
          Math.floor(
            (new Date(this.state.endDate) - new Date(this.state.startDate)) /
              (1000 * 60 * 60 * 24)
          );

        let days =
          Math.floor(
            (new Date(this.state.endDate) - new Date(this.state.startDate)) /
              (1000 * 60 * 60 * 24)
          ) + 1;
        let dp = this.calculateDp(days);

        planTermObject.dp = dp;
        planTermObject.country = this.state.selectedCountry.toString();
        planTermObject.totalPrice = this.state.productPrice;
        planTermObject.Price =
          this.props.personalDetails && this.props.personalDetails.length > 0
            ? this.state.productPrice / this.props.personalDetails.length
            : this.state.productPrice;
        planTermObject.tierValue = this.state.selectedCountryTier;
        planTermObject.isAnnual = this.props.planType && this.props.planType.name.toLowerCase().includes('annual') ? true : false;
        await this.props.SaveStep("2");
        await this.props.SavePlanTerm(planTermObject);
        //await this.props.SavePlanTermTemp(planTermObject);
        this.props.history.push(
          this.props.b2b2cBaseUrl +
            "/personal-details/" +
            this.props.match.params.orgId +
            window.location.search
        );
      }
    } else {
      console.log("testuuu")
      if (
        this.state.selectedDaysValue === "NaN" ||
        this.state.selectedDaysValue == undefined
      ) {
        this.setState({ endDateError: "Please select end date." });
      }
      if (this.state.selectedCountry.length === 0) {
        this.setState({ countryError: "Please select at least one country." });
      }
      if (this.state.startDate === null || this.state.startDate == "") {
        this.setState({ dateError: "Please select travelling date." });
      }
      // if (
      //   this.state.startDate.isSame(this.state.endDate, "day")
      // ) {
      //   this.setState({ endDateError: "Please select valid end date." });
      // }
      
      if (
        this.state.startDate > this.state.endDate
      ) {
        this.setState({ endDateError: "Please select valid end date" });
      }
    }
  }

  _bindDays() {
    return this.state.defaultDaysPlan.map((day) => (
      <Option key={day} data-value={day}>
        {"Up to " + day + " Days "}
      </Option>
    ));
  }

  _bindCountries() {
    return this.props.countryTierInfo.map((country, index) => (
      <Option key={"con" + index} value={country.countryId}>
        {country.countryName}
      </Option>
    ));
  }

  _bindEndDate() {
    return (
      <DatePicker
        showSearch={false}
        disabledDate={(current) =>
          this.disabledEndDate(current, this.state.startDate)
        }
        value={(this.state.savedPlanEndDate && this.state.savedPlanEndDate !== "" && this.state.savedPlanEndDate !== "NaN")
          ? moment(this.state.savedPlanEndDate, "MM/DD/YYYY")
          : null}
        // defaultValue={
        //   moment.isDate(this.state.savedPlanEndDate) &&
        //   this.state.savedPlanEndDate &&
        //   this.state.savedPlanEndDate !== "" &&
        //   this.state.savedPlanEndDate !== "NaN"
        //     ? moment(this.state.savedPlanEndDate, "MM/DD/YYYY")
        //     : null
        // }
        format="MM/DD/YYYY"
        onChange={this.handleChangeEndDate}
        disabled={
          this.props.planType &&
          this.props.planType.name &&
          this.props.planType.name.toLowerCase().includes('annual')
            ? true
            : false
        }
      />
    );
  }

  _bindPlanDays() {
    if (this.state.savedPlanDays !== "") {
      return (
        <Select
          readonly
          showSearch={false}
          disabled={
            (this.props.planTerm && this.props.planTerm.isAnnual) ||
            this.props.planType.name.toLowerCase().includes("annual")
              ? true
              : false
          }
          placeholder="Please select days"
          style={{ height: "48px", width: "100%" }}
          onChange={this.handleDaysCheck}
          defaultValue={
            this.state.savedPlanDays !== "NaN"
              ? this.state.savedPlanDays + " Days"
              : ""
          }
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
        >
          {this.state.defaultDaysPlan && this.state.defaultDaysPlan.length > 0
            ? this._bindDays()
            : ""}
        </Select>
      );
    }
  }

  _bindCountry() {
    if (this.state.savedPlanCountriesList.length > 0) {
      return (
        <Select
          readonly
          /* disabled={
            this.props.planTerm && this.props.planTerm.isAnnual ? true : false
          } */
          showSearch
          mode="multiple"
          defaultValue={
            this.state.savedPlanCountriesList[0] !== -1
              ? this.state.savedPlanCountriesList
              : []
          }
          placeholder="Please select a country"
          style={{ height: "48px", width: "100%", fontSize: "17px" }}
          onChange={this.handleCountryCheck}
          filterOption={(input, option) =>
            option.props.children.toLowerCase().startsWith(input.toLowerCase())
          }
        >
          {this.state.countryDropDownData.length > 0
            ? this._bindCountries()
            : ""}
        </Select>
      );
    }
  }

  _bindDate() {
    if (this.state.savedPlanDate !== "") {
      return (
        <DatePicker
          showSearch={false}
          disabledDate={this.disabledDate}
          defaultValue={
            moment.isDate(this.state.savedPlanDate) // !== "NaN" || this.state.savedPlanDate != "NaN/NaN/NaN"
              ? moment(this.state.savedPlanDate, "MM/DD/YYYY")
              : null
          }
          format="MM/DD/YYYY"
          onChange={this.handleChange}
        />
      );
    }
  }

  disabledDate(current) {
    return current && current < moment().subtract(1, "day");
  }

  disabledEndDate(current, startDate, endDate) {
    return (
      current &&
      (
        current > moment(startDate).add(365, "day")|| current < moment(endDate).startOf("day"))
    );
  }

  // _bindCurrency(data) {
  //   return data.map((obj, index) => {
  //     return (
  //       <>
  //         <option value={obj}>{obj}</option>
  //       </>
  //     );
  //   });
  // }

  // handleCurrencyChange = async (e) => {
  //   var value = e.target.value;
  //   this.setState({ currency: e.target.value });

  //   var currencyData = "";
  //   await this.props.convertCurrency().then((resp) => {
  //     currencyData = resp.data;
  //   });
  //   if (this.state.currency == "USD") {
  //     this.setState({ currencyRate: 1 });
  //     this.props.updateCurrency(value, 1);
  //   } else {
  //     this.setState({ currencyRate: currencyData.value });
  //     this.props.updateCurrency(value, currencyData.value);
  //   }
  // };

  onUnload = (e) => {
    e.preventDefault();
  };
  render() {
    //console.log(this.state.selectedCountry);

    return (
      <section id='banner'>
        <Beforeunload onBeforeunload={(e) => this.onUnload(e)}>
          <Wizard wizardActiveClass='step2' />
          {/* price detail */}
          <div
            className='wrap900 price-sticky-head'
            style={{
              display: 'block',
              maxWidth: '1300px',
              zIndex: '100',
              position: 'sticky'
            }}
          >
            <div className='title' style={{ background: '#FFF' }}>
              
              <PriceCalculation
                dp={
                  this.props.planType &&
                  this.props.planType.name &&
                  this.props.planType.name.toLowerCase().includes('annual')
                    ? 365
                    : this.state.dp
                }
                country={this.state.selectedCountry}
              />
  
            </div>
          </div>
          {/* price  ends */}
          {/*  plan travel */}
          <div className='wrap'>
            <div className='plan-travel'>
              <div className='main-div'>
                <div className='in-div'>
                  <CalendarIconsvg />
                  <div className='desc'>
                    <span>*</span> When are you leaving?
                  </div>
                  {this._bindDate()}
                  <span className='error'>{this.state.dateError}</span>
                </div>
                <div className='in-div'>
                  <DateIconsvg />
                  <div className='desc'>
                    <span>*</span>{' '}
                    {this.props.planType &&
                    this.props.planType.name &&
                    this.props.planType.name.toLowerCase().includes('annual')
                      ? 'Valid until...'
                      : 'When are you returning?'}
                  </div>
                  <Row type='flex' align='middle'>
                    {this._bindEndDate()}
                  </Row>
                  <span className='error'>{this.state.endDateError}</span>
                </div>
                <div className='in-div'>
                  <GlobeIconsvg />
                  <div className='desc'>
                    <span>*</span> Where are you going?
                  </div>
                  {this._bindCountry()}
                  <span className='error'>{this.state.countryError}</span>
                </div>
              </div>
            </div>
          </div>
          {/* plan travel ends */}
          {/* next and back button */}
          <div className='next-back'>
            <div className='wrap'>
              <button data-value='back' className='ant-btn back' onClick={this.handleClick}>
                Back
              </button>
              <button data-value='next' className='ant-btn next' onClick={this.handleClick}>
                Next
              </button>
            </div>
          </div>
          {/* next and back button */}
        </Beforeunload>
      </section>
    );
  }
}
PlanTerm.propTypes = {
  // getResellerProductPricingAndInfo: PropTypes.func,
  // getPlanInformation: PropTypes.func,
  // SaveStep: PropTypes.func,
  // SavePlanTerm: PropTypes.func,
  // step: PropTypes.string,
  // planType: PropTypes.object,
  // countryTierInfo: PropTypes.array,
  // productPriceList: PropTypes.array,
  // productInfo: PropTypes.array,
  // getPersonalInformation: PropTypes.func,
  // getPlanTermInformation: PropTypes.func,
  // personalDetails: PropTypes.array,
  // planTerm: PropTypes.object,
  // productDays: PropTypes.array,
  // getAppliedPromo: PropTypes.func,
  // couponInfo: PropTypes.object,
  // getStep: PropTypes.func,
  // b2b2cBaseUrl: PropTypes.string
};
function mapStateToProps(state) {
  return {
    ...state.b2b2c,
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...b2b2cActions }, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(PlanTerm);
