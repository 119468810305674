import Axios from "../../services/axios-service";

const getInvoiceLogs = async (id,data) => {
    return Axios.get("/api/AuditTransactions/invoiceAudit/" + id + "?search=" + JSON.stringify(data), undefined, undefined);
};
const getUserLogs = async (id,data) => {
    return Axios.get("/api/AuditTransactions/userAudit/" + id + "?search=" + JSON.stringify(data), undefined, undefined);
};
const getOrganizationLogs = async (id,data) => {
    return Axios.get("/api/AuditTransactions/organizationAudit/" + id + "?search=" + JSON.stringify(data), undefined, undefined);
};
const getProductLogs = async (id,data) => {
    return Axios.get("/api/AuditTransactions/productAudit/" + id + "?search=" + JSON.stringify(data), undefined, undefined);
};
const getPricingLogs = async (id, data) => {
    return Axios.get("/api/AuditTransactions/pricingAudit/" + id + "?search=" + JSON.stringify(data), undefined, undefined);
};
export default {
    getInvoiceLogs,
    getUserLogs,
    getOrganizationLogs,
    getProductLogs,
    getPricingLogs
};