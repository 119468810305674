import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { TreeSelect } from 'antd';
import PropTypes from "prop-types";
import * as OrgActions from "../../../redux/actions/organization-actions";

class OrgTree extends Component {
  constructor(props) {
    super(props);
    this.state = {
      treeParent: 0,
      pageLoaded: false
    }
    this.onChange = this.onChange.bind(this);
    this.onSelect = this.onSelect.bind(this);
    this.getNestedChildren = this.getNestedChildren.bind(this);
  }

  componentDidMount = async() => {
     const currentUser =JSON.parse(localStorage.getItem("user"));
     await this.props.getDivisionsTree(this.props.organizationId);
    if(this.props.organizationTreeParent) { 
      if(this.props.organizationTreeParent != this.props.organizationId){
        await this.props.getDivisionsTree(this.props.organizationId);
      }
    } else {
      await this.props.getDivisionsTree(currentUser.parentOrgId);
    }
    let _treeParent = this.props.parent  && this.props.parent !== -1 ? this.props.parent : this.props.organizationId;
    let parentExists = -1;
    parentExists = this.props.organizationTree && this.props.organizationTree.length > 0 && this.props.organizationTree.findIndex(el => parseInt(el.organizationId) === parseInt(_treeParent));
    if(parentExists > -1){
      this.setState({treeParent: _treeParent, pageLoaded: true});
    } else {
      this.props.organizationTree[0] && this.props.organizationTree.sort(function(a, b){
        var keyA = a.generation,
            keyB = b.generation;
        if(keyA < keyB) return -1;
        if(keyA > keyB) return 1;
        return 0;
      });
      _treeParent = this.props.organizationTree[0] && this.props.organizationTree[0].organizationId;
      this.setState({treeParent: _treeParent, pageLoaded: true});
    }
  }

  loadParent = async() => {
    //if(this.state.pageLoaded === false){
      
    //}
  }

  getTree=(arr) => {
    var out = this.unflatten(arr)
    /*for(var i in arr) {
      if(arr[i].generation == 0) {
        console.log("Getting child for" + arr[i].organizationId)
        var children = this.getNestedChildren(arr,arr[i].organizationId);
        if(children.length) {
          arr[i].children = children
        }
        arr[i].title = arr[i].name;
        arr[i].value = arr[i].organizationId;
        arr[i].key = arr[i].organizationId;
        //console.log(data);
        out.push(arr[i]);
        console.log(out);
      }
    }*/ 
    return out;
  }

   unflatten=(arr)=> {
    var tree = [],
        mappedArr = {},
        arrElem,
        mappedElem;
    for(var i = 0, len = arr.length; i < len; i++) {
      arrElem = arr[i];
      arrElem.title = arrElem.name;
      arrElem.value = arrElem.organizationId;
      arrElem.key = arrElem.organizationId;
      mappedArr[arrElem.organizationId] = arrElem;
      mappedArr[arrElem.organizationId]['children'] = [];
    }
    for (var organizationId in mappedArr) {
      if (mappedArr.hasOwnProperty(organizationId)) {
        mappedElem = mappedArr[organizationId];
        if (mappedElem.parentOrgId &&  mappedArr[mappedElem['parentOrgId']]) {
          mappedArr[mappedElem['parentOrgId']] && mappedArr[mappedElem['parentOrgId']]['children'].push(mappedElem);
        } else {
          tree.push(mappedElem);
        }
      }
    }
    return tree;
  }

  getNestedChildren=(arr, parent)=>{
    var out = []
    for(var i in arr) {
      if(arr[i].parentOrgId == parent) {
        var children = this.getNestedChildren(arr, arr[i].organizationId)
        if(children.length) {
            arr[i].children = children
        }
        arr[i].title = arr[i].name;
        arr[i].value = arr[i].organizationId;
        arr[i].key = arr[i].organizationId;
        out.push(arr[i])
      }
    }
    return out;
  }

  onChange = value => {
    if(this.props.onChange) this.props.onChange(value);
  };

  onSelect = value => {
    if(this.props.onSelect) this.props.onSelect(value);
  };

  render() {
    return (
      this.props.organizationTree && this.props.organizationTree.length > 0 && this.state.treeParent > 0 ? 
        <TreeSelect
          style={{ width: '100%' }}
          defaultValue={this.props.parent && this.props.parent === -1 ? null : parseInt(this.state.treeParent)}
          dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
          treeData={this.getTree(this.props.organizationTree)}
          placeholder="Please select"
          treeCheckable={(this.props.isCheckable) ? this.props.isCheckable : false}
          disabled={(this.props.disabled) ? this.props.disabled : false}
          treeDefaultExpandAll
          onChange={this.onChange}
          onSelect={this.onSelect}
        />
      : ''
    );
  }
}

OrgTree.propTypes = {
  getDivisionsTree: PropTypes.func
};

function mapStateToProps(state) {
  return { ...state.organizationTree, ...state.organizationTreeParent };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...OrgActions }, dispatch);
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(OrgTree));