import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as orderActions from "../../../../redux/actions/order-actions";
import IntlMessages from "../../../../services/intlMesseges";
import {
  Typography,
  Form,
  Button,
  Row,
  Col,
  Spin,
  Descriptions
} from "antd";

import moment from "moment";
const dateFormat = "MM/DD/YYYY";

class ViewOrganizationOrder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      orderInfo: {}
    };
  }

  formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2
  });
  async componentDidMount() {
    if (
      this.props.match &&
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.id &&
      this.props.match.params.id !== "new"
    ) {
      this.setState({ loading: true, id: this.props.match.params.id });
      const order = await this.props.getOrder(this.props.match.params.id);
      this.setState({ orderInfo: order.data });
      this.setState({ loading: false });
    }
  }

  render() {
    const { orderInfo } = this.state;
    return (
      <div>
        <Row gutter={24}>
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography.Title level={4}>
              {<IntlMessages id='admin.manageOrders.Order' />} #{orderInfo.orderNumber}
            </Typography.Title>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <Button
              type='primary'
              className='right-fl def-blue'
              htmlType='button'
              onClick={() => this.props.history.push('./')}
            >
              <IntlMessages id='admin.button.back' />
            </Button>
          </Col>
        </Row>
        <hr></hr>
        <div>
          <Spin spinning={this.state.loading}>
            <Descriptions title={<IntlMessages id='admin.manageOrders.OrderDetails' />}>
              <Descriptions.Item label={<IntlMessages id='admin.organizations.name' />}>
                {orderInfo.companyName ? orderInfo.companyName : 'N/A'}
              </Descriptions.Item>
              <Descriptions.Item label={<IntlMessages id='admin.division.name' />}>
                {orderInfo.divisionName ? orderInfo.divisionName : 'N/A'}
              </Descriptions.Item>
              <Descriptions.Item label={<IntlMessages id='admin.invoice.billto' />}>
                {orderInfo.billingOrganization ? orderInfo.billingOrganization : 'N/A'}
              </Descriptions.Item>
              <Descriptions.Item label={<IntlMessages id='admin.manageOrders.ProductName' />}>
                {orderInfo.productName}
              </Descriptions.Item>
              <Descriptions.Item label={<IntlMessages id='admin.manageOrders.membershipNumber' />}>
                {orderInfo.membershipNumber}
              </Descriptions.Item>
              <Descriptions.Item label={<IntlMessages id='admin.orderlist.numberofplans' />}>
                {orderInfo.quantity}
              </Descriptions.Item>
              <Descriptions.Item label={<IntlMessages id='admin.productlist.retailPrice' />}>
                {this.formatter.format(orderInfo.totalOrderRetailAmount)} {orderInfo.currency}
              </Descriptions.Item>
              <Descriptions.Item label={<IntlMessages id='admin.deal.price' />}>
                {this.formatter.format(orderInfo.totalOrderAmount)} {orderInfo.currency}
              </Descriptions.Item>
              {/* <Descriptions.Item label={<IntlMessages id="admin.postsale.price" />}>
                {this.formatter.format(orderInfo.totalOrderAmount/orderInfo.quantity)} USD
              </Descriptions.Item> */}
              {/* <Descriptions.Item label={<IntlMessages id="admin.manageOrders.countries" />}>
                {orderInfo.countries !== null ? orderInfo.countries : 'N/A'}
              </Descriptions.Item> */}
              <Descriptions.Item label={<IntlMessages id='admin.org.licenselist.startDate' />}>
                {moment(orderInfo.coverageStartDate).format(dateFormat)}
              </Descriptions.Item>
              <Descriptions.Item label={<IntlMessages id='admin.org.licenselist.endDate' />}>
                {moment(orderInfo.coverageEndDate).format(dateFormat)}
              </Descriptions.Item>
            </Descriptions>
          </Spin>
        </div>
      </div>
    );
  }
}

function mapStateToProps(_state) {
  return {};
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...orderActions }, dispatch);
}
const WrappedForm = Form.create({ name: "edit-order" })(ViewOrganizationOrder);
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    WrappedForm
  )
);
