import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import IntlMessages from "../../../services/intlMesseges";
import * as emailTemplateActions from "../../../redux/actions/email-template-actions";
import { notifyUser } from "../../../services/notification-service";
import { Typography, Form, Input, Button, Row, Col, Spin, Tag } from "antd";
import AppLocale from "../../../languageProvider";
import * as UserService from "../../../services/user-service";
import JoditEditor from "jodit-react";

class EditCustomizeEmail extends React.Component {
  constructor(props) {
    super(props);
    this.editorRef = React.createRef();
    this.state = {
      data: {},
      loading: true,
      templateId: 0,
      dataLoaded: false,
      templateType: null
    };
    //this.onChangeEditorContents = this.onChangeEditorContents.bind(this);
  }

  async componentDidMount() {
    if (
      this.props.match &&
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.templateId &&
      this.props.match.params.templateId !== "new"
    ) {
      let companyId = this.props.match.params.id ? this.props.match.params.id : (this.props.userData.companyID ? this.props.userData.companyID : UserService.getUser().companyID);
      await this.props.getEmailTemplate(companyId, this.props.match.params.templateId).then(template => {
        this.setState({
          data: template.data,
          templateType: template.data.type.replace(/\-/g," ").toLowerCase().replace(/\b[a-z]/g, function(letter) {return letter.toUpperCase()}),
          loading: false
        });
      });
    } else {
      this.setState({ loading: false });
    }
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.setState({ loading: true });
        const { language } = this.props;
        const currentAppLocale = AppLocale[language.locale];
        let companyId = this.props.match.params.id ? this.props.match.params.id : (this.props.userData.companyID ? this.props.userData.companyID : UserService.getUser().companyID);
        values.organizationId = companyId;
        values.templateId = this.state.data.templateId;
        values.content = this.state.data.content;
        values.keywords = this.state.data.keywords;
        values.language = this.state.data.language;
        values.type = this.state.data.type;
        this.props
          .updateEmailTemplate(this.state.data.templateId, values)
          .then(response => {
            if (response.error) {
              if (response.error.message) {
                notifyUser(response.error.message, "error");
              } else {
                notifyUser("Unknown error. Please try again!", "error");
              }
              this.setState({ loading: false });
            } else {
              this.props.history.push("../");
              this.setState({ loading: false });
              notifyUser(currentAppLocale.messages["admin.update.success"], "success");
            }
          })
          .catch(err => {
            this.setState({ loading: false });
          });
      }
    });
  };

  onChangeEditorContents(value){
    let _data = this.state.data;
    _data.content = value;
    this.setState({
      data: _data
    });
  }

  restoreToDefault = async() => {
    this.setState({ loading: true });
    await this.props.getEmailTemplate(1, this.props.match.params.templateId).then(template => {
      const { language } = this.props;
      const currentAppLocale = AppLocale[language.locale];
      let companyId = this.props.match.params.id ? this.props.match.params.id : (this.props.userData.companyID ? this.props.userData.companyID : UserService.getUser().companyID);
      template.data.organizationId = companyId;
      this.props.updateEmailTemplate(this.state.data.templateId, template.data).then(response => {
        if (response.error) {
          if (response.error.message) {
            notifyUser(response.error.message, "error");
          } else {
            notifyUser("Unknown error. Please try again!", "error");
          }
        } else {
          notifyUser(currentAppLocale.messages["admin.update.success"], "success");
        }
      })
      .catch(err => {
        this.setState({ loading: false });
      });
      this.setState({
        data: template.data,
        templateType: template.data.type.replace(/\-/g," ").toLowerCase().replace(/\b[a-z]/g, function(letter) {return letter.toUpperCase()}),
        loading: false
      });
    });
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const { formLayout } = this.state;
    return (
      <div>
        <Row gutter={24}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Typography.Title level={4} style={{ fontSize: 22 }}>
              <IntlMessages id="admin.emailtemplates.edit.heading" />
              {this.state.templateType !== null ?
                ` (${this.state.templateType})`
              : ''}
            </Typography.Title>
          </Col>
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={12}
            style={{ textAlign: "right" }}
          >
            <Button
              type="primary"
              className=""
              htmlType="button"
              onClick={() => this.props.history.push("../")}
            >
              <IntlMessages id="admin.userlisting.back" />
            </Button>
          </Col>
        </Row>
        <hr />
        <div>
          <Spin spinning={this.state.loading}>
            <Form layout={formLayout} onSubmit={this.handleSubmit}>
              <Spin spinning={this.state.loading}>
                <Row gutter={24}>
                  <Col xs={24} sm={24} md={16}>
                    <Form.Item
                      label={<IntlMessages id="admin.emailtemplates.title" />}
                    >
                      {getFieldDecorator("title", {
                        initialValue: this.state.data.title,
                        rules: [
                          {
                            whitespace: true,
                            required: true,
                            message: <IntlMessages id="admin.input.required" />
                          }
                        ]
                      })(<Input />)}
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col xs={24} sm={24} md={16}>
                    <Form.Item
                      label={<IntlMessages id="admin.email.subject" />}
                    >
                      {getFieldDecorator("subject", {
                        initialValue: this.state.data.subject,
                        rules: [
                          {
                            whitespace: true,
                            required: true,
                            message: <IntlMessages id="admin.input.required" />
                          }
                        ]
                      })(<Input />)}
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col xs={24} sm={24} md={16}>
                    <Form.Item
                      label={<IntlMessages id="admin.email.content" />}
                    >
                      <div className="text-editor">
                        <JoditEditor
                          ref={this.editorRef}
                          value={this.state.data.content}
                          onBlur={content => this.onChangeEditorContents(content)}
                          onChange={newContent => {}}
                          config={{
                            readonly: false,
                            askBeforePasteHTML: false,
                            processPasteHTML: false
                          }}
                        />
                      </div>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col xs={24} sm={24} md={16}>
                    <IntlMessages id="admin.info.emailTemplateTags" />:<br />
                    <Typography.Text code>{this.state.data.keywords}</Typography.Text>
                  </Col>
                </Row>
                <Row gutter={24} style={{ marginTop: 20 }}>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    {/* <Button type="default" style={{ marginRight: 10 }} onClick={this.previewTemplate}><IntlMessages id="admin.preview.template" /></Button> */}
                    <Button type="primary" style={{ marginRight: 10 }} htmlType="submit"><IntlMessages id="admin.button.update" /></Button>
                    <Button type="dashed" style={{ marginRight: 10 }} onClick={() => this.props.history.goBack()}><IntlMessages id="admin.button.cancel" /></Button>
                    {this.props.match.params.id ? <Button type="default" style={{ marginRight: 10 }} onClick={this.restoreToDefault}><IntlMessages id="admin.restore.to.default" /></Button> : ""}
                  </Col>
                </Row>
              </Spin>
            </Form>
          </Spin>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return { ...state.user, ...state.language };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...emailTemplateActions }, dispatch);
}
const WrappedForm = Form.create({ name: "edit-email-template" })(EditCustomizeEmail);
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    WrappedForm
  )
);
