import React, { Component } from 'react';
import { CardElement, injectStripe } from 'react-stripe-elements';
import { connect } from "react-redux";
import OrgAPI from "../../../redux/api/b2b2c-organization-api";
import { bindActionCreators } from "redux";
import Paypal from "../../../images/paypal.svg";
import CreditCard from "../../../images/creditcard.svg";
import { message, Modal, Tooltip, Popconfirm, Radio } from "antd";
import {notifyUser} from "../../../services/notification-service";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import axios from "axios";
import config from "../../../../../config";
const { confirm } = Modal;


class CheckoutForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      paymentMethod: "",
      clientToken: config.clientTokenPaypal,
      orderObjectPaypal: {},
      confirmOrder: false,
      btnIsDisable: false,
      containOrder :{},
      emailvalue : ""
    }
    this.handleConfirmOrderCheck = this.handleConfirmOrderCheck.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.showConfirm = this.showConfirm.bind(this);
  }
  
  sendDataToParent = () => {
    const {emaildata} = this.props;
    this.setState({ emailvalue: emaildata });
    this.props.sendDataToParent(this.state.emailvalue);
  }
  
  handleConfirmOrderCheck(evt) {
    this.setState({ confirmOrder: evt.currentTarget.checked });
  }
  
  payMethodChange = (e) => {
    this.setState({ paymentMethod: e.target.value });
  };
  
  async handleClick(evt) {
    this.setState({btnIsDisable: true})
          if (this.state.confirmOrder) {
            this.checkForvalidPayment();
          } else {
            var objContent = {};
            objContent.title = "Acknowledge required!";
            objContent.content = "Please acknowledge plan rules and regulations first.";
            this.showConfirm(objContent);
            this.setState({btnIsDisable: false})
          } 
          this.setState({btnIsDisable: false})
  }
    
    async checkForvalidPayment() {
      var self = this;
      const { stripe } = this.props;
      const {emaildata} = this.props;
      if (this.state.paymentMethod == "paypal") {
        await this.instance.requestPaymentMethod((error, payload) => {
          if (error) {
            console.error(error);
          } else {
            
            this.setState({btnIsDisable: false})
            const paymentMethodNonce = payload.nonce;
          }
        });
      } 
      else {
        this.props.stripe.createToken().then(async function(payload) {
            if (payload.token) {
              const data = {
                email: emaildata,
                token: payload.token.id,
                currency: 'USD',
              };
            let resp =  await OrgAPI.B2BEmployeeUpgradeStripe(data);
           if(resp && resp.statusCode == 200) {
            notifyUser(resp.message, "success");
            self.sendDataToParent();
           }
           else {
            notifyUser(resp.message, "error");
           }
          } else {
            var objContent = {};
            objContent.title = "Invalid card detail!";
            objContent.content = "Please enter valid card details.";
            self.showConfirm(objContent);
          }
        });
      }
    }
    
    clickPaypal = () => {
      if (!this.state.confirmOrder) {
        var objContent = {};
        objContent.title = "Acknowledge required!";
        objContent.content = "Please acknowledge plan rules and regulations first.";
        this.showConfirm(objContent);
        this.setState({btnIsDisable: false})
        return false;
      } else {
        const {emaildata} = this.props;
      const datapaypal = {
        email: emaildata,
        currency: 'USD',
        token : "pass",
      };
      this.setState({orderObjectPaypal: datapaypal})
      }
    };
    
    showConfirm(objContent, index) {
      confirm({
        title: objContent.title,
        content: objContent.content,
        className: "gray-color",
        onOk() {},
        onCancel() {}
      });
    }

  render() {
    return (
      
        <div>
          <section  id ="banner">
          <div className="wrap">
            <div className="billing-info">
              <div className="row">
                <div className="full-width">
                  <h3>Billing Information</h3>
                </div>
                <div className="full-width pay-methods">
                  <Radio.Group onChange={this.payMethodChange}>
                    <Radio value={"stripe"}>
                      <img style={{ width: "40px" }} src={CreditCard} alt="Credit Card" />
                      <span style={{ fontWeight: 600, marginLeft: "10px" }}> Pay Via Card </span>
                    </Radio>
                    <Radio value={"paypal"}>
                      <img style={{ width: "100px" }} src={Paypal} alt="Paypal" />
                    </Radio>
                  </Radio.Group>
                </div>
              </div>
              {this.state.paymentMethod === "stripe" ? (
                <div className="row">
                  <div className="full-width">
                    <label>
                      <span>*</span> Credit or debit card (Visa, MasterCard, American Express, JCB, Discover, and Diners
                      Club)
                    </label>
                    <CardElement hidePostalCode={true} />
                    <span className="disclaimer">
                      Your credit card data will be securely transmitted and processed. FocusPoint complies with PCI-DSS
                      SAQ A.
                    </span>
                  </div>
                </div>
              ) : ""}
              <div className="row">
                <div className="full-width">
                  <div className="checkbox-in">
                    <label className="container">
                      By submitting, you acknowledge that you have read and agree to the terms and conditions of the&nbsp;
                      <a
                        target="_blank"
                        href={config.API1 + "/rules/CAP-eCommerce-Rules-and-Regulations-v15.1.pdf"}
                        style={{ textDecoration: "underline" }}
                      >
                        CAP <br />
                        Plan Rules & Regulations
                      </a>
                      <span className="red">*</span>
                      <input
                        type="checkbox"
                        checked={this.state.confirmOrder}
                        onChange={this.handleConfirmOrderCheck}
                        
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                </div>
              </div>
              {this.state.paymentMethod === "paypal" ? (
                <PayPalScriptProvider
                  options={{
                    "client-id": this.state.clientToken,
                  }}
                >
                  <PayPalButtons
                    onClick={this.clickPaypal}
                    style={{ layout: "horizontal" }}
                    createOrder={(data, actions) => {
                      return axios
                        .post(config.API1 + "/api/B2BEmployeeUpgrade/payment/paypal/create", this.state.orderObjectPaypal)
                        .then((response) => {
                          return response.data.data.orderId;
                        });
                    }}
                    onApprove={(data, actions) => {
                      const { emaildata } = this.props;
                      const postData = {
                        token: data.orderID,
                        email: emaildata,
                        currency: 'USD',
                      };
                      this.setState({ containOrder: postData });
    
                      return axios
                        .post(
                          config.API1 + `/api/B2BEmployeeUpgrade/payment/paypal/capture/`, this.state.containOrder
                        )
                        .then((response) => {
                          let resp = response.data;
                          if (resp.statusCode === 200) {
                           // this.billingSection();
                            this.sendDataToParent();
                            notifyUser(resp.message, "success");
                          } else {
                            notifyUser(resp.message, "error");
                          }
                        });
                    }}
                  />
                </PayPalScriptProvider>
              ) : ""}
              {this.state.paymentMethod === "stripe" ? (
                <button
                  disabled={this.state.btnIsDisable}
                  data-value="buy"
                  className="buynow"
                  style={{ background: "#489e3c" }}
                  onClick={this.handleClick}
                >
                  Buy Now
                </button>
              ) : ""}
            </div>
          </div>
          </section>
        </div>
        
      
    );
  }
}

function mapStateToProps(state) {
  return {
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true
})(CheckoutForm);
