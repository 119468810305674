import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Table, Button, Icon, Card, Row } from "antd";
import { Orders, ComapnyListSvg } from "../../shared/svg/svg/home-icon";
import * as OrderActions from "../../../redux/actions/order-actions";
import IntlMessages from "../../../services/intlMesseges";

class ListCompaines extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      data: []
    };
  }

  getHeaderKeys = () => {
    return [
      {
        title: <IntlMessages id="admin.manageOrders.ProductName" />,
        dataIndex: "productName",
      },
      {
        title: <IntlMessages id="admin.manageOrders.membershipNumber" />,
        dataIndex: "membershipNumber",
      },
      {
        title: <IntlMessages id="admin.manageOrders.orderNumber" />,
        dataIndex: "orderNumber",
      },
      {
        title: "",
        rowKey: "action",
        render: (text, record) => (
          <span>
            <Button
              className="btn-outline"
              onClick={() =>
                this.props.history.push(
                  "manage-orders/view/" + record.orderId
                )
              }
            >
              <IntlMessages id="admin.listcompanies.moreinfo" />
            </Button>
          </span>
        )
      }
    ];
  };

  async componentDidMount() {
    this.setState({ loading: true });
    let orgId = (this.props.userData && this.props.userData.companyID) ? this.props.userData.companyID : null;
    if(orgId === null){
      let cu = JSON.parse(localStorage.getItem("user"));
      if(cu && cu.companyID){
        orgId = cu.companyID;
      }
    }
    let args = {
      orgId: orgId,
      filters: {},
      pagination: { page: 1, pageSize: 5 },
      sorter : {}
    };
    this.props
      .getResellerOrders(args)
      .then(resp => {
        this.setState({
          loading: false,
          data: resp.data
        });
      })
      .catch(ex => {
        this.setState({ loading: false });
      });
  }

  render() {
    return (
      <div>
        <Card
          title={<IntlMessages id="admin.dashboard.listoforders"></IntlMessages>}
          bodyStyle={{ padding: 0 }}
          extra={<Icon component={ComapnyListSvg} style={{ fontSize: 36 }}/>}
        >
          <Table
            rowKey={record => record.name}
            pagination={false}
            columns={this.getHeaderKeys()}
            dataSource={this.state.data}
            loading={this.state.loading}
          />
          <Row type="flex" justify="end">
            <Button
              type="primary"
              className="addBtn"
              size={"large"}
              style={{ margin: " 24px 24px 24px 0px" }}
              onClick={() => this.props.history.push("manage-orders/")}
            >
              <IntlMessages id="admin.listcompanies.viewall" />
            </Button>
          </Row>
        </Card>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...OrderActions }, dispatch);
}
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    ListCompaines
  )
);
