import React, { Component } from "react";
//import IntlMessages from "../../../services/intlMesseges";
import FooterLogo from "../../../images/cap_logo_footer.png";
export default class FooterB2c extends Component {
  render() {
    return (
      <div>
        <div id="top">
          <div className="container_wrap footer_color" id="footer">
            <div className="container">
              <div className="flex_column av_one_fourth first el_before_av_one_fourth">
                <section
                  id="media_image-2"
                  class="widget clearfix widget_media_image"
                >
                  <a
                    href="https://captravelassistance.com/"
                    style={{ position: "relative", overflow: "hidden" }}
                  >
                    <img
                      width="211"
                      height="137"
                      src={FooterLogo}
                      style={{ maxWidth: "100%", height: "auto" }}
                    />
                    <span class="image-overlay overlay-type-extern">
                      <span class="image-overlay-inside"></span>
                    </span>
                  </a>
                  <span class="seperator extralight-border"></span>
                </section>
                <section
                  id="custom_html-4"
                  className="widget_text widget clearfix widget_custom_html"
                >
                  <div className="textwidget custom-html-widget">
                    <ul className="socialmedia">
                      <li>
                        <a
                          target="_new"
                          href="https://twitter.com/travelwithcap"
                          rel="noopener"
                        >
                          <i
                            className="fab fa-twitter fa-2x"
                            title="Twitter"
                            aria-hidden="true"
                          ></i>
                          <span className="sr-only">Twitter</span>
                        </a>
                      </li>
                      <li>
                        <a
                          target="_new"
                          href="https://www.facebook.com/travelwithcap/"
                          rel="noopener"
                        >
                          <i
                            className="fab fa-facebook fa-2x"
                            title="Facebook"
                            aria-hidden="true"
                          ></i>
                          <span className="sr-only">Facebook</span>
                        </a>
                      </li>
                      <li>
                        <a
                          target="_new"
                          href="https://www.linkedin.com/showcase/cap%E2%84%A2-travel-assistance-plan/about/"
                          rel="noopener"
                        >
                          <i
                            className="fab fa-linkedin fa-2x"
                            title="LinkedIn"
                            aria-hidden="true"
                          ></i>
                          <span className="sr-only">LinkedIn</span>
                        </a>
                      </li>
                      <li>
                        <a
                          target="_new"
                          href="https://www.youtube.com/channel/UCk1Pxf4Tx6SYQDx37CoNEug"
                          rel="noopener"
                        >
                          <i
                            className="fab fa-youtube fa-2x"
                            title="YouTube"
                            aria-hidden="true"
                          ></i>
                          <span className="sr-only">YouTube</span>
                        </a>
                      </li>
                      <li>
                        <a
                          target="_new"
                          href="https://www.instagram.com/travelwithcap/"
                          rel="noopener"
                        >
                          <i
                            className="fab fa-instagram fa-2x"
                            title="Instagram"
                            aria-hidden="true"
                          ></i>
                          <span className="sr-only">Instagram</span>
                        </a>
                      </li>
                      <li>
                        <a
                          target="_new"
                          href="https://www.pinterest.ca/captravelassistanceplans/_created/"
                          rel="noopener"
                        >
                          <i
                            className="fab fa-pinterest-square fa-2x"
                            title="Pinterest"
                            aria-hidden="true"
                          ></i>
                          <span className="sr-only">Pinterest</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <span className="seperator extralight-border"></span>
                </section>
              </div>
              <div
                className="
        flex_column
        av_one_fourth
        el_after_av_one_fourth el_before_av_one_fourth
      "
              >
                <section
                  id="nav_menu-2"
                  className="widget clearfix widget_nav_menu"
                >
                  <h3 className="widgettitle">Plan Types</h3>
                  <div className="menu-plan-types-container">
                    <ul id="menu-plan-types" className="menu">
                      <li
                        id="menu-item-100"
                        className="
                menu-item
                menu-item-type-post_type
                menu-item-object-page menu-item-100
              "
                      >
                        <a href="https://captravelassistance.com/a-plan-for-everyone/leisure-travel-medical-and-security-assist-plan/">
                          Vacation / Leisure
                        </a>
                      </li>
                      <li
                        id="menu-item-99"
                        className="
                menu-item
                menu-item-type-post_type
                menu-item-object-page menu-item-99
              "
                      >
                        <a href="https://captravelassistance.com/a-plan-for-everyone/business-travel-medical-and-security-assist-plan/">
                          Business / Employer
                        </a>
                      </li>
                      <li
                        id="menu-item-102"
                        className="
                menu-item
                menu-item-type-post_type
                menu-item-object-page menu-item-102
              "
                      >
                        <a href="https://captravelassistance.com/a-plan-for-everyone/student-scholar/">
                          Student / Mission
                        </a>
                      </li>
                      <li
                        id="menu-item-101"
                        className="
                menu-item
                menu-item-type-post_type
                menu-item-object-page menu-item-101
              "
                      >
                        <a href="https://captravelassistance.com/a-plan-for-everyone/lgbtq-travel/">
                          LGBTQ+ Travel
                        </a>
                      </li>
                      <li
                        id="menu-item-98"
                        className="
                menu-item
                menu-item-type-post_type
                menu-item-object-page menu-item-98
              "
                      >
                        <a href="https://captravelassistance.com/a-plan-for-everyone/adventure-travel-medical-and-security-assist-plan/">
                          Adventure Travel
                        </a>
                      </li>
                    </ul>
                  </div>
                  <span className="seperator extralight-border"></span>
                </section>
              </div>
              <div
                className="
        flex_column
        av_one_fourth
        el_after_av_one_fourth el_before_av_one_fourth
      "
              >
                <section
                  id="nav_menu-4"
                  className="widget clearfix widget_nav_menu"
                >
                  <h3 className="widgettitle">Quick Links</h3>
                  <div className="menu-quick-links-container">
                    <ul id="menu-quick-links" className="menu">
                      <li
                        id="menu-item-103"
                        className="
                menu-item
                menu-item-type-post_type
                menu-item-object-page menu-item-103
              "
                      >
                        <a href="https://captravelassistance.com/help-center/">
                          Help Center
                        </a>
                      </li>
                      <li
                        id="menu-item-104"
                        className="
                menu-item
                menu-item-type-custom
                menu-item-object-custom
                menu-item-104
              "
                      >
                        <a href="https://portal.captravelassistance.com/">
                          Customer Login
                        </a>
                      </li>
                      <li
                        id="menu-item-107"
                        className="
                menu-item
                menu-item-type-post_type
                menu-item-object-page menu-item-107
              "
                      >
                        <a href="https://captravelassistance.com/resources/travel-protection-plan-faqs/">
                          Travel Protection Plan FAQs
                        </a>
                      </li>
                      <li
                        id="menu-item-108"
                        className="
                menu-item
                menu-item-type-post_type
                menu-item-object-page menu-item-108
              "
                      >
                        <a href="https://captravelassistance.com/resources/world-travel-trends-blog/">
                          World Travel Trends Blog
                        </a>
                      </li>
                      <li
                        id="menu-item-109"
                        className="
                menu-item
                menu-item-type-custom
                menu-item-object-custom
                menu-item-109
              "
                      >
                        <a href="https://captravelassistance.com/about-us/#">
                          Get Quote
                        </a>
                      </li>
                    </ul>
                  </div>
                  <span className="seperator extralight-border"></span>
                </section>
                <section
                  id="custom_html-2"
                  className="widget_text widget clearfix widget_custom_html"
                >
                  <h3 className="widgettitle">Partners</h3>
                  <div className="textwidget custom-html-widget">
                    <p>
                      <a href="https://captravelassistance.com/partner-with-us/">
                        Become a Partner
                      </a>
                    </p>
                  </div>
                  <span className="seperator extralight-border"></span>
                </section>
              </div>
              <div
                className="
        flex_column
        av_one_fourth
        el_after_av_one_fourth el_before_av_one_fourth
      "
              >
                <section
                  id="nav_menu-5"
                  className="widget clearfix widget_nav_menu"
                >
                  <h3 className="widgettitle">Why CAP?</h3>
                  <div className="menu-why-cap-container">
                    <ul id="menu-why-cap" className="menu">
                      <li
                        id="menu-item-1038"
                        className="
                menu-item
                menu-item-type-post_type
                menu-item-object-page
                current-menu-item
                page_item page-item-21
                current_page_item
                menu-item-1038
              "
                      >
                        <a
                          href="https://captravelassistance.com/about-us/"
                          aria-current="page"
                        >
                          About Us
                        </a>
                      </li>
                      <li
                        id="menu-item-93"
                        className="
                menu-item
                menu-item-type-post_type
                menu-item-object-page menu-item-93
              "
                      >
                        <a href="https://captravelassistance.com/why-cap/customer-stories/">
                          Customer Stories
                        </a>
                      </li>
                      <li
                        id="menu-item-95"
                        className="
                menu-item
                menu-item-type-post_type
                menu-item-object-page menu-item-95
              "
                      >
                        <a href="https://captravelassistance.com/about-us/social-responsibility/">
                          Social Responsibility
                        </a>
                      </li>
                      <li
                        id="menu-item-97"
                        className="
                menu-item
                menu-item-type-post_type
                menu-item-object-page menu-item-97
              "
                      >
                        <a href="https://captravelassistance.com/about-us/careers/">
                          Careers
                        </a>
                      </li>
                    </ul>
                  </div>
                  <span className="seperator extralight-border"></span>
                </section>
              </div>
            </div>
          </div>
          <footer className="container_wrap socket_color" id="socket">
            <div className="container">
              <span className="copyright">
                © 2021 FocusPoint International, Inc. All rights reserved.{" "}
                <br />
                CAP ™ is a FocusPoint International product and is not an
                insurance policy, it is a travel assistance plan.
              </span>
              <nav className="sub_menu_socket">
                <div className="avia3-menu">
                  <ul id="avia3-menu" className="menu">
                    <li
                      id="menu-item-790"
                      className="
              menu-item
              menu-item-type-post_type
              menu-item-object-page menu-item-top-level menu-item-top-level-1
            "
                    >
                      <a href="https://captravelassistance.com/legal-notice/">
                        <span className="avia-bullet"></span>
                        <span className="avia-menu-text">Legal Notice</span>
                        <span className="avia-menu-fx">
                          <span className="avia-arrow-wrap">
                            <span className="avia-arrow"></span>
                          </span>
                        </span>
                      </a>
                    </li>
                    <li
                      id="menu-item-789"
                      className="
              menu-item
              menu-item-type-post_type
              menu-item-object-page
              menu-item-privacy-policy
              menu-item-top-level
              menu-item-top-level-2
            "
                    >
                      <a href="https://captravelassistance.com/privacy-policy/">
                        <span className="avia-bullet"></span>
                        <span className="avia-menu-text">Privacy Policy</span>
                        <span className="avia-menu-fx">
                          <span className="avia-arrow-wrap">
                            <span className="avia-arrow"></span>
                          </span>
                        </span>
                      </a>
                    </li>
                    <li
                      id="menu-item-791"
                      className="
              menu-item
              menu-item-type-post_type
              menu-item-object-page menu-item-top-level menu-item-top-level-3
            "
                    >
                      <a href="https://captravelassistance.com/help-center/">
                        <span className="avia-bullet"></span>
                        <span className="avia-menu-text">Contact</span>
                        <span className="avia-menu-fx">
                          <span className="avia-arrow-wrap">
                            <span className="avia-arrow"></span>
                          </span>
                        </span>
                      </a>
                    </li>
                    <li
                      id="menu-item-1389"
                      className="
              menu-item
              menu-item-type-post_type
              menu-item-object-page menu-item-top-level menu-item-top-level-4
            "
                    >
                      <a href="https://captravelassistance.com/site-map/">
                        <span className="avia-bullet"></span>
                        <span className="avia-menu-text">Site Map</span>
                        <span className="avia-menu-fx">
                          <span className="avia-arrow-wrap">
                            <span className="avia-arrow"></span>
                          </span>
                        </span>
                      </a>
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
          </footer>
        </div>
      </div>
    );
  }
}
