import React, { Component } from "react";
import { Tabs, Button } from "antd";
import moment from "moment";
import config from "../../../../../../config";

const { TabPane } = Tabs;
const dateFormat = "MM/DD/YYYY";
// let flightStatus = {
//   L: "LANDED",
//   LANDED: "LANDED",
//   SCHEDULED: "SCHEDULED",
//   S: "SCHEDULED",
//   A: "In-Transit"
// };

export default class TravelerDetailsByHotel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      travelerData: ""
    };
  }

  componentDidMount() {}

  _bindTravelerInfoWindowContent(hotelData) {
    return (
      <Tabs className="MapTravellerToolTip" tabPosition="left">
        {this._bindTravelerDetails(hotelData)}
      </Tabs>
    );
  }

  _bindTravelerDetails(hotelData) {
    return hotelData.map((value, i) => (
      <TabPane
        tab={
          <span>
            <img src={config.API1 + "/travel-icons/on_location.png"}></img>
            {value.travelerName}
          </span>
        }
        key={value.travelerEmail + value.hotelCode + i}
      >
        <div className="headerContent">
          <i className="fas fa-user"></i> {value.travelerName}
        </div>
        <ul>
          <li>
            <span>Check-in date:</span>
            {moment(value.checkinDate).format(dateFormat)}
          </li>
          <li>
            <span>Check-out date:</span>
            {moment(value.checkoutDate).format(dateFormat)}
          </li>
          <li>
            <span>Address:</span>
            {value.address}
          </li>
          <li>
            <span>Phone number:</span>
            {value.phoneNumber}
          </li>

          <li>
            <Button
              type="primary"
              onClick={() => {
                const url =
                  "traveler-itinerary-viewer/" +
                  value.pnrId +
                  "/" +
                  value.travelerEmail;
                window.open(url);
              }}
            >
              <i className="fas fa-atlas"></i>
            </Button>
          </li>
        </ul>
      </TabPane>
    ));
  }

  render() {
    return this._bindTravelerInfoWindowContent(this.props.hotelInfoWindowData);
  }
}
