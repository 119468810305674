import React, { Component } from "react";
import { Table, Button, Row, Col, Spinner } from "react-bootstrap";
import PropTypes from "prop-types";
import Pagination from "react-js-pagination";

// import ModalForm from "../Modals/Modal";

class DataTable extends Component {
  constructor(props) {
    super(props);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.deleteItem = this.deleteItem.bind(this);
    this.editItem = this.editItem.bind(this);
  }

  handlePageChange(pageNumber) {
    this.props.paginateHandle(pageNumber);
  }

  deleteItem(pageNumber) {
    this.props.deleteItemFromState(pageNumber);
  }

  editItem(idx) {
    this.props.editItemFromState(idx);
  }

  listing = () => {
    let temparr = this.props.items.map(item => {
      let eachRow = this.props.headerData.map(hkey => {
        if (hkey.key !== "action") {
          return <td scope="row"> {item[hkey.key]} </td>;
        } else {
          return (
            <td scope="row">
              <Button variant="primary" onClick={() => this.editItem(item.id)}>
                Edit
              </Button>{" "}
              <Button variant="danger" onClick={() => this.deleteItem(item.id)}>
                Del{" "}
              </Button>
            </td>
          );
        }
      });

      return <tr key={item.id}> {eachRow} </tr>;
    });
    return temparr;
  };

  render() {
    let headerRow = this.props.headerData.map(hkey => {
      return <th scope="row"> {hkey.lable} </th>;
    });

    return (
      <div>
        <Row>
          <Col>
            <Table responsive hover>
              <thead>
                <tr key="header"> {headerRow} </tr>{" "}
              </thead>{" "}
              <tbody> {this.listing()} </tbody>{" "}
            </Table>{" "}
          </Col>{" "}
        </Row>{" "}
        <Row>
          <Col>
            <Button variant="primary" onClick={() => this.editItem("new")}>
              Add New{" "}
            </Button>{" "}
          </Col>{" "}
          <Col>
            {" "}
            {this.props.loading ? (
              <Spinner animation="border" role="status" />
            ) : (
              ""
            )}{" "}
          </Col>{" "}
          <Col>
            <Pagination
              activePage={
                this.props.pagination ? this.props.pagination.currentPage : 1
              }
              itemsCountPerPage={
                this.props.pagination ? this.props.pagination.pageSize : 10
              }
              totalItemsCount={
                this.props.pagination ? this.props.pagination.totalRecords : 0
              }
              pageRangeDisplayed={
                this.props.pagination && this.props.pagination.totalRecords > 10
                  ? 10
                  : ""
              }
              itemClass="page-item"
              linkClass="page-link"
              activeClass="active"
              onChange={this.handlePageChange}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

DataTable.propTypes = {
  items: PropTypes.array,
  pagination: PropTypes.object,
  paginateHandle: PropTypes.func,
  deleteItem: PropTypes.func,
  headerData: PropTypes.array,
  getListData: PropTypes.func
};
export default DataTable;
