import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import NotificationAPI from "../../../../redux/api/notification-api";
import IntlMessages from "../../../../services/intlMesseges";
import EditTraveler from "./edit/edit.jsx";
import PlanType from "./edit/plan-type.jsx";
import ActivityLog from "./edit/activity-log";
import { StickyContainer, Sticky } from "react-sticky";
import { Drawer, Input, Tabs, Form, Row, Col, Button, Typography } from "antd";
import { notifyUser } from "../../../../services/notification-service";
import TravelerMap from "../../organizations/edit-organization/employees/employees-map";
import CheckInList from "../../organizations/edit-organization/employees/checkin-list";
import AssistList from "../../organizations/edit-organization/employees/assist-list";

const { TabPane } = Tabs;
const renderTabBar = (props, DefaultTabBar) => (
  <Sticky bottomOffset={80}>
    {({ style }) => (
      <DefaultTabBar
        {...props}
        style={{ ...style, zIndex: 1, background: "#fff" }}
      />
    )}
  </Sticky>
);

class ViewTravelers extends Component {
  state = {
    notifyVisible: false,
    userId: 0,
    activeTab: "travelersdetails",
  };

  // for tab back button
  componentDidMount() {
    let state = this.props.history.location.state;
    if (state) {
      let setTab = state.setTab;
      this.setState({ activeTab: setTab });
    }
  }

  hideNotifyDrawer = () => {
    this.props.form.resetFields();
    this.setState({
      notifyVisible: false,
    });
  };

  showNotifyDrawer = () => {
    this.setState({
      notifyVisible: true,
    });
  };

  submitNotify = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        var data = {
          userId: this.props.match.params.id,
          title: values.title,
          message: values.message,
        };
        this.sendNotification(data);
      }
    });
  };

  async sendNotification(data) {
    let response = await NotificationAPI.notifyUser(data);
    if (response.errors && response.errors.length > 0) {
      notifyUser(response.errors[0].externalMessage, "error");
    } else {
      notifyUser("Success", "success");
    }
    this.hideNotifyDrawer();
  }

  // for tab change
  handleTabChange = (key) => {
    this.setState({ activeTab: key });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div>
        <Drawer
          title={<IntlMessages id="admin.button.notify" />}
          width={420}
          onClose={this.hideNotifyDrawer}
          visible={this.state.notifyVisible}
          bodyStyle={{ paddingBottom: 80 }}
          closable={true}
          maskClosable={false}
        >
          <Form layout="vertical">
            <Row gutter={24}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Form.Item label={<IntlMessages id="admin.formfield.title" />}>
                  {getFieldDecorator("title", {
                    rules: [
                      {
                        required: true,
                        message: <IntlMessages id="admin.input.required" />,
                      },
                    ],
                  })(<Input />)}
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Form.Item
                  label={<IntlMessages id="admin.formfield.description" />}
                >
                  {getFieldDecorator("message")(<Input />)}
                </Form.Item>
              </Col>
            </Row>
            <Button onClick={this.submitNotify} type="primary">
              <IntlMessages id="admin.button.send" />
            </Button>
          </Form>
        </Drawer>
        <Row gutter={24}>
          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <Typography.Title level={4}>
              <IntlMessages id="admin.edit.traveler" />
            </Typography.Title>
          </Col>
          {/* <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <Button
              type="primary"
              className="right-fl"
              htmlType="button"
              onClick={() => this.props.history.goBack()}
            >
              <IntlMessages id="admin.userlisting.back" />
            </Button>
          </Col> */}
        </Row>
        <hr />
        <Row gutter={24} style={{ position: "relative" }}>
          <StickyContainer>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Tabs
                onChange={this.handleTabChange}
                activeKey={this.state.activeTab}
                defaultActiveKey="travelersdetails"
                renderTabBar={renderTabBar}
                className="travller-tabs"
              >
                <TabPane
                  tab={<IntlMessages id="admin.traveler.travelersdetails" />}
                  key="travelersdetails"
                >
                  <EditTraveler />
                </TabPane>
                <TabPane
                  tab={<IntlMessages id="admin.traveler.plans" />}
                  key="plans"
                >
                  <PlanType />
                </TabPane>
                <TabPane
                  tab={<IntlMessages id="admin.traveler.activitylog" />}
                  key="activitylog"
                >
                  <ActivityLog />
                </TabPane>
                <TabPane tab={<IntlMessages id="admin.map" />} key="map">
                  <TravelerMap />
                </TabPane>
                <TabPane
                  tab={<IntlMessages id="admin.checkin" />}
                  key="check-in"
                >
                  <CheckInList />
                </TabPane>
                <TabPane tab={<IntlMessages id="admin.assist" />} key="assist">
                  <AssistList />
                </TabPane>
              </Tabs>
            </Col>
          </StickyContainer>
        </Row>
      </div>
    );
  }
}
ViewTravelers.propTypes = {
  location: PropTypes.object,
  updateUser: PropTypes.func,
};

const WrappedForm = Form.create({ name: "edit-traveler" })(ViewTravelers);
export default withRouter(
  connect(null, null, null, { forwardRef: true })(WrappedForm)
);
