import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as riskLineActions from "../../../redux/actions/risk-line-actions";
import IntlMessages from "../../../services/intlMesseges";
import { Row, Col,Button, Tabs, Typography, Spin } from "antd";
let riskLevel = {
  1: "Low",
  2: "Moderate",
  3: "Medium",
  4: "High",
  5: "Extreme"
};

const { TabPane } = Tabs;

class Cities extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cityReport: [],
      loading: false
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.citiesList.length === 0) {
      var cList = this.props.citiesList;
      if (cList !== "") {
        for (var i = 0; i < cList.length; i++) {
          var cityCode = cList[0].code;
        }
        this.props
          .getCityReport({
            cityCode: cityCode
          })
          .then(resp => {
            if (resp) {
              this.setState({
                cityReport: resp
              });
            }
          });
      }
    }
  }

  tabClicked = (key, event) => {
    this.setState({ loading: true });
    this.props
      .getCityReport({
        cityCode: key
      })
      .then(resp => {
        this.setState({
          cityReport: resp,
          loading: false
        });
      });
  };

  _bindCityList() {
    return this.props.citiesList.map((city, index) => (
      <TabPane
        tab={
          <span>
            <i
              style={{}}
              className={
                "risklevel risk_level_" +
                riskLevel[city.riskLevel].toLowerCase()
              }
            >
              {city.riskLevel}
            </i>
            {city.name} 
          </span>
        }
        key={city.code}
      >
        <div>
          <Spin spinning={this.state.loading}>
           
            <h5>{city.name}
            <Button
                  className="downloadBtn ant-btn-primary"
                  style={{
                    marginLeft: "30px",
                    verticalAlign: "top",
                    background: "#73c053"
                  }}
                  type="link"
                  icon="download"
                  size="large"
                  href={`https://api.riskline.com/v2/pdf-cities/${this.state.cityReport.id}.pdf?key=cbba61c21372cd3589e584c248dadb08`}
                >
                  <span style={{ marginTop: "-5px" }}>Download City Report</span>
                </Button>
                </h5> 
            <span
              style={{
                borderBottom: "1px solid #d9d9d9",
                padding: "10px 0",
                marginBottom: "15px",
                marginTop: "-12px",
                display: "block"
              }}
            >
              <i
                style={{}}
                className={
                  "risklevel risk_level_" +
                  riskLevel[city.riskLevel].toLowerCase()
                }
              >
                {city.riskLevel}
              </i>
              Risk Level {riskLevel[city.riskLevel]}
            </span>
            <div
              dangerouslySetInnerHTML={{
                __html: this.bindCityDetails(this.state.cityReport)
              }}
            ></div>
          </Spin>
        </div>
      </TabPane>
    ));
  }

  bindCityDetails(cityDetails) {
    let cityReport = "";
    cityReport +=
      cityDetails.overview && "<h4>Overview</h4>" + cityDetails.overview;
    cityReport +=
      cityDetails.arrival && "<h4>Arrival</h4>" + cityDetails.arrival;
    cityReport +=
      cityDetails.getting_around &&
      "<h4>Getting Around</h4>" + cityDetails.getting_around;
    cityReport += cityDetails.safety && "<h4>Safety</h4>" + cityDetails.safety;
    cityReport += cityDetails.health && "<h4>Health</h4>" + cityDetails.health;
    cityReport +=
      cityDetails.calendar && "<h4>Calendar</h4>" + cityDetails.calendar;

    return cityReport;
  }

  render() {
    return (
      <Row gutter={24} style={{ marginTop: "50px" }}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} className="tabs-city">
          <Typography.Title level={3}>
            <IntlMessages id="admin.countries.availablecityreports" />
          </Typography.Title>
          <Tabs
            className="long-tab"
            tabPosition="left"
            onTabClick={this.tabClicked}
          >
            {this._bindCityList()}
          </Tabs>
        </Col>
      </Row>
    );
  }
}

function mapStateToProps(state) {
  return {};
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...riskLineActions }, dispatch);
}
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    Cities
  )
);
