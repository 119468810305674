import { combineReducers } from 'redux';
import languageReducer from "./language-reducer";
import themeReducer from "./theme-reducer";
import userReducer from "./user-reducer";
import b2cReducer from "./b2c-reducer";
import b2b2cReducer from "./b2b2c-reducer";
import sidebarReducer from "./sidebar-reducer";
import notifcationReducer from "./notification-reducer";
import adminReducer from "./admin-reducer";
import travellerReducer from "./traveller-reducer";
import tiersReducer from "./tiers-reducer";
import dayPackagesReducer from "./day-packages-reducer";
import organizationReducer from "./organization-reducer";
import paginationReducer from "./pagination-reducer";

const reducers = combineReducers({
    language: languageReducer,
    theme:themeReducer,
    user:userReducer,
    b2c:b2cReducer,
    b2b2c:b2b2cReducer,
    notification:notifcationReducer,
    sidebar:sidebarReducer,
    adminConfig: adminReducer,
    traveller:travellerReducer,
    tiers:tiersReducer,
    daypackages:dayPackagesReducer,
    organizationTree: organizationReducer,
    pagination: paginationReducer
});

export default reducers;