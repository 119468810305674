import React, { Component } from "react";
import ActivityLog from "../audit-logs/audit-logs";

class ProductAuditLogs extends Component {
  state = {
    data: []
  }
  render() {
      return (
        <ActivityLog for="product"/>
      )
  }
}
export default ProductAuditLogs;