import { Tabs, Form } from "antd";
import OrganizationPlan from "../edit-organization/organization-plan";
import PrimaryAddress from "../edit-organization/primary-address";
import AdminSettings from "../edit-organization/settings";
import React, { Component } from "react";
import IntlMessages from "../../../../services/intlMesseges";
import * as b2b2cOrgActions from "../../../../redux/actions/b2b2c-organization-actions";
import * as Permission from "../../../../services/permissions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { notifyUser } from "../../../../services/notification-service";

const { TabPane } = Tabs;
class EditOrganization extends Component {
  constructor(props) {
    super(props);
    this.state = {
      countries: [],
      productData: [],
      orgData: {},
      addData: false,
      activeTab: "#address",
      tab1title: <IntlMessages id="admin.organization.address" />,
      tab2title: <IntlMessages id="admin.organization.plan" />,
      tab3title: <IntlMessages id="admin.userlisting.settings" />,
    };
    this.onTabsChange = this.onTabsChange.bind(this);
    this.addProduct = this.addProduct.bind(this);
    this.saveproduct = this.saveproduct.bind(this);
  }

  async componentDidMount() {
    if (
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.id &&
      this.props.match.params.id !== "new"
    ) {
      this.fetchOrgData();
    }
  }

  fetchOrgData = () => {
    this.props
      .getOrganizationId(this.props.match.params.id)
      
      .then(async (resp) => {
        console.log(resp, this.props.match.params.id)
        if (resp.error) {
          notifyUser(resp.error.message, "error");
          this.props.history.push("./../../");
          this.setState({ loading: false });
        } else {
          this.setState({
            orgData: resp.data,
            loading: false,
          });
        }
      })
      .catch((ex) => {
        this.setState({ loading: false });
      });
  };

  onTabsChange = (currentTab) => {};
  addProduct() {
    this.setState({ addData: true });
  }
  saveproduct() {
    this.setState({ addData: false });
  }
  updateState = (data) => {
    this.setState({
      orgData: data,
    });
  };
  render() {
    return (
      <div>
        {this.state.orgData && this.state.orgData.organizationId ? (
          <Tabs
            defaultActiveKey={this.state.activeTab}
            onChange={this.onTabsChange}
          >
            <TabPane tab={this.state.tab1title} key="#address">
              <PrimaryAddress
                orgData={this.state.orgData}
                updateStateOnChange={this.fetchOrgData}
              />
            </TabPane>
            <TabPane tab={this.state.tab2title} key="#plan">
              <OrganizationPlan
                onchange={this.onTabsChange}
                orgData={this.state.orgData}
                updateStateOnChange={this.fetchOrgData}
              />
            </TabPane>
            {Permission.isRole([
              "Administrator",
              "CSR",
              "Admin",
              "Travel Manager",
            ]) ? (
              <TabPane tab={this.state.tab3title} key="#users">
                <AdminSettings
                  orgData={this.state.orgData}
                  updateStateOnChange={this.fetchOrgData}
                />
              </TabPane>
            ) : (
              ""
            )}
          </Tabs>
        ) : (
          ""
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...b2b2cOrgActions }, dispatch);
}
// export default EditOrganization;
const WrappedForm = Form.create({ name: "organization-plans" })(
  EditOrganization
);
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    WrappedForm
  )
);
