import React from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import IntlMessages from "../../../../services/intlMesseges";
import * as OrgActions from "../../../../redux/actions/organization-actions";
import {
  Typography,
  Form,
  Input,
  Button,
  Row,
  Col,
  Spin,
  Switch
} from "antd";

class EditOrganizationType extends React.Component {
  state = {
    loading: false,
    confirmDirty: false,
    id: 0,
    name: '',
    status: 0
  };

  async componentDidMount() {
    if (
      this.props.match &&
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.id &&
      this.props.match.params.id !== "new"
    ) {
      this.setState({ loading: true, id: this.props.match.params.id });
      const organization = await this.props.getOrganizationType(this.props.match.params.id);
      //this.props.form.setFieldsValue(user);
      this.setState(organization);
      this.setState({ loading: false });
    }
  }



  onSwitchChange = (checked, e) => {
    const { name } = e.target;
    this.setState({ [name]: checked });
  };

  submitFormAdd = data => {
    this.props
      .updateOrganizationType(data)
      .then(item => {
        this.setState({ loading: false });
        this.props.history.push("./");
      })
      .catch(err => {
        console.log(err);
        this.setState({ loading: false });
      });
  };

  submitFormEdit = data => {
    data.status = this.state.status;
    this.props
      .updateOrganizationType(data)
      .then(item => {
        this.props.history.push("./");
        this.setState({ loading: false });
      })
      .catch(err => {
        this.setState({ loading: false });
      });
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        Object.keys(values).map(function(key, _idx) {
          if(values[key] == null){
            values[key] = '';
          } else {
            if(typeof values[key] == 'string'){
              values[key] = values[key].trim();
            }
          }
          return values;
        });
        this.setState({ loading: true });
        if (this.state.id && this.state.id > 0) {
          values["id"] = this.state.id;
          this.submitFormEdit(values);
        } else {
          this.submitFormAdd(values);
        }
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { formLayout } = this.state;
    const formItemLayout =
      formLayout === "horizontal"
        ? {
            labelCol: { span: 4 },
            wrapperCol: { span: 14 }
          }
        : null;
  
    return (
      <div>
        
        <Typography.Title level={3}>
          {this.state.id === 0 ? (
            <IntlMessages id="admin.organizationTypes.add" />
          ) : (
            <div>
              <IntlMessages id="admin.organizationTypes.edit" />: {this.state.name}
            </div>
          )}
        </Typography.Title>
        <div>
          <Spin spinning={this.state.loading}>
            <Form layout={formLayout} onSubmit={this.handleSubmit}>
              <Row gutter={24}>
                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                  <Form.Item
                    {...formItemLayout}
                    label={<IntlMessages id="admin.organizationTypes.name" />}
                  >
                    {getFieldDecorator("name", {
                      rules: [
                        {
                          required: true,
                          message: "This is a required field!"
                        }
                      ],
                      initialValue:
                        this.state.name === null ? "" : this.state.name
                    })(<Input />)}
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                  <Form.Item
                    {...formItemLayout}
                    label={<IntlMessages id="admin.organizationTypes.status" />}
                  >
                    <Switch checkedChildren="Active" unCheckedChildren="Inactive" checked={this.state.status} onChange={this.onSwitchChange} name="status"/>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Item>
                    <Button
                      type="primary"
                      style={{ display: "inline-block", marginRight: "10px" }}
                      className="def-blue"
                      htmlType="submit"
                    >
                      <IntlMessages id="admin.organizationTypes.update" />
                    </Button>
                    <Button
                      type="primary"
                      className=""
                      htmlType="button"
                      onClick={() => this.props.history.push("./")}
                    >
                      <IntlMessages id="admin.organizationTypes.back" />
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Spin>
        </div>
      </div>
    );
  }
}

EditOrganizationType.propTypes = {
  location: PropTypes.object,
  updateOrganizationType: PropTypes.func,
  getOrganizationType: PropTypes.func
};

function mapStateToProps(state) {
  return {};
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...OrgActions }, dispatch);
}
const WrappedForm = Form.create({ name: "edit-organization-type" })(EditOrganizationType);
export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    { forwardRef: true }
  )(WrappedForm)
);
