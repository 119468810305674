import React, { Component } from "react";
import { Typography, Row, Col, Tabs, Button, Form } from "antd";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import IntlMessages from "../../../services/intlMesseges";
import CustomAlertViewin from "../custom-alert/alert-view/alert-view";
import CustomAlertuser from "../custom-alert/alert-view/user-messags";
import * as CustomAlertActions from "../../../redux/actions/custom-alert-actions";
const { TabPane } = Tabs;

class CustomAlertView extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div>
        <Row gutter={24}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Typography.Title level={4}>
              <IntlMessages id="admin.sidebar.submenu.customAlert" />
            </Typography.Title>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Button
              type="primary"
              className="right-fl def-blue"
              htmlType="button"
              onClick={() => this.props.history.push("../")}
            >
              <IntlMessages id="admin.button.back" />
            </Button>
          </Col>
        </Row>
        <hr />
        <Row gutter={24}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Tabs defaultActiveKey="1">
              <TabPane
                tab={<IntlMessages id="admin.customalert.tab1" />}
                key="1"
              >
                <CustomAlertViewin />
              </TabPane>
              <TabPane
                tab={<IntlMessages id="admin.customalert.tab2" />}
                key="2"
              >
                <CustomAlertuser />
              </TabPane>
            </Tabs>
          </Col>
        </Row>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    ...state.user,
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...CustomAlertActions }, dispatch);
}
const WrappedForm = Form.create({ name: "Update-itinerary" })(CustomAlertView);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    WrappedForm
  )
);
