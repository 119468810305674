import { Types } from "../constants/tiers-types";
const initialState = {
  tiersData: {},
  tiersDataById:{}
};
export default function tiersReducer(state = initialState, action) {
  switch (action.type) {
   
    case Types.TIERS_LIST:
      return { ...state, tiersData: action.payload };
      case Types.TIERS_DATA_BYID:
        return { ...state, tiersDataById: action.payload };
  
    default:
      return state;
  }
}
