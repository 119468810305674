import React, { Component } from "react";
import { Route } from "react-router-dom";
import PlanType from "../components/b2c/plan-type/plan-type.jsx";
import PlanTerm from "../components/b2c/plan-term/plan-term.jsx";
import PersonalDetails from "../components/b2c/personal-details/personal-details.jsx";
import BillingInformation from "../components/b2c/billing-information/billing-information.jsx";
import PurchaseSummary from "../components/b2c/purchase-summary/purchase-summary.jsx";
import Order from "../components/b2c/order/personal-details.jsx";
import priceCalculation from "../components/b2c/order/price-calculation.jsx";
import BillingSummary from "../components/b2c/billing-summary/billing-summary.jsx";
import ThankYou from "../components/b2c/thank-you/thank-you.jsx";
import reloadId from "../components/shared/components/reloadId.js";
const routes = [
  {
    path: "dashboard",
    component: reloadId
  },
  {
    path: "purchase",
    component: PlanType
  },
  {
    path: "(plan-term|planterm)",
    component: PlanTerm
  },
  {
    path: "(personal-details|personaldetails)",
    component: PersonalDetails
  },
  {
    path: "(billing-information|billinginformation)",
    component: BillingInformation
  },
  {
    path: "(purchase-summary|purchasesummary)",
    component: PurchaseSummary
    },
    {
        path: "order",
        component: Order
    },
    {
        path: "(billing-summary|billingsummary)",
        component: BillingSummary
    },
    {
      path: "(price)",
      component: priceCalculation
    },
  {
    path: "(thank-you|thankyou)",
    component: ThankYou
  }
];

class UserRouter extends Component {
  render() {
    const { url, style } = this.props;
    return (
      <div style={style}>
        {routes.map(singleRoute => {
          const { path, exact, ...otherProps } = singleRoute;
          return (
            <Route
              exact={exact === false ? false : true}
              key={singleRoute.path}
              path={`${url}/${singleRoute.path}`}
              {...otherProps}
            />
          );
        })}
      </div>
    );
  }
}

export default UserRouter;
