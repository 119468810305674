import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import IntlMessages from "../../../../services/intlMesseges";
import { Row, Col, Typography, DatePicker, Button, Select, Input, Icon, Table, Form ,Tabs} from "antd";
import * as travellersReportActions from "../../../../redux/actions/travellers-report-actions";
import * as UserService from "../../../../services/user-service";
import { ViewCell } from "../../../shared/components/helperCells";
import { bindActionCreators } from "redux";
import * as itiniaryActions from "../../../../redux/actions/itinerary-actions"
import { CSVLink } from "react-csv";
import PrintAbleComponent from "../printable-component.jsx";
import PrintComponents from "react-print-components";
import moment from "moment";
import * as Permissions from "../../../../services/permissions"
import axios from "axios";
import travellerapi from "../../../../redux/api/travellers-report-api";
import * as UserServices from "../../../../services/user-service";

const { Option } = Select;
const dateFormat = "MM/DD/YYYY ";
const { TabPane } = Tabs;
let filteredKeys=[]
class DepartureReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      travellerList: [],
      travellerListManual:[],
      airportData: [],
      stateuser: 1,
      pagination: {
        hideOnSinglePage: true,
        showTotal: (total, range) => {
          return (
            <span>
              <IntlMessages id="admin.text.showing" /> {range[0]}-{range[1]}{" "}
              <IntlMessages id="admin.text.of" /> {total}{" "}
              <IntlMessages id="admin.text.results" />
            </span>
          );
        },
        pageSize: 10,
        current: 1
      },    
      paginationManual: {
        hideOnSinglePage: true,
        showTotal: (total, range) => {
          return (
            <span>
              <IntlMessages id="admin.text.showing" /> {range[0]}-{range[1]}{" "}
              <IntlMessages id="admin.text.of" /> {total}{" "}
              <IntlMessages id="admin.text.results" />
            </span>
          );
        },
        pageSize: 10,
        current: 1
      },
      filters: {},
      date: '',
      airportId: '',
      showTable: false,
      formLayout: undefined,
      role:'',
      alltravellerList:[],
      downloadSelectedValue:'current',
      csvSearchFilters: {},
      tab:1
    };
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.getairportData = this.getairportData.bind(this);
    this.handleAirportChange = this.handleAirportChange.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.getAirportUpdate = this.getAirportUpdate.bind(this);
    this.callback=this.callback.bind(this)
  }

  componentWillMount() {
    let currentUser = UserService.getUser();
    let userRole = currentUser.role;
    this.setState({role:userRole})
    let nonPrintableKeys = ["action"];
    filteredKeys = this.getHeaderKeys().filter(
    key => !nonPrintableKeys.includes(key.dataIndex)
    
);
console.log(filteredKeys)
  }
  componentDidMount() {
    this.getairportData({})

  }
  getAirportUpdate() {
    this.getairportData({});

  }
  handleDateChange(e) {
    if (e != null) {
      var changedDate = moment(e._d).format('YYYY-MM-DD')
      this.setState({ date: changedDate })
    }
  }

  handleSubmit() {
    this.props.form.validateFieldsAndScroll((err, values) => {

      if (!err) {
        if(this.state.filters!=={}){
          this.setState({filters:{}})
        }
        if (this.state.tab =='1'){
          this.handleManualTableChange({ current: 1, pageSize: 10 }, {}, {});
        
        }else {
          this.handleTableChange({ current: 1, pageSize: 10 }, {}, {});
        
        }
  
      }
    })
  }

  handleTableChange = (pagination, filters, sorter) => {
    this.setState({travellerList:[]})
    let currentUser = UserService.getUser();
    let orgId = currentUser.companyID;
    let userRole = currentUser.role;
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    filters.date = this.state.date
    filters.airportCode=this.state.airportId
    this.setState({ loading: true ,csvSearchFilters: filters });
    this.props
      .getTravelersByDepatureAirport(orgId, userRole, {
        filters: filters,
        pagination: { page: pagination.current, pageSize: pagination.pageSize },
        sorter: sorter,
      })
      .then(resp => {

        pager.total = resp.result.totalElement;
        
        this.setState({
          travellerList: resp.result.content,
          alltravellerList:resp.result.content,
          loading: false,
          pagination: pager,
          showTable: true,
          downloadSelectedValue:"current"
        });
      })
      .catch(ex => {
        this.setState({ loading: false });
      });
  };

  handleManualTableChange = (pagination, filters, sorter) => {
    this.setState({travellerListManual:[]})
    let currentUser = UserService.getUser();
    let orgId = currentUser.companyID;
    let userRole = currentUser.role;
    let date = this.state.date
    const pager = { ...this.state.paginationManual };
    pager.current = pagination.current;
    filters.date = this.state.date
    filters.airportCode=this.state.airportId
    this.setState({ loading: true, csvSearchFilters: filters });
    this.props
      .getManualTravelersByDepatureAirport(orgId, userRole, {
        filters: filters,
        pagination: { page: pagination.current, pageSize: pagination.pageSize },
        sorter: sorter,

      })
      .then(resp => {
        pager.total = resp.result.totalElement;

        this.setState({
          travellerListManual: resp.result.content,
          downloadSelectedValue: "current",
          loading: false,
          paginationManual: pager,
          showTable: true
        });
      



      })
      .catch(ex => {
        console.log('ex',ex)
        this.setState({ loading: false });
      });
  };

  getCsvData = () => {
    let currentUser = UserService.getUser();
    let orgId = currentUser.companyID;
    let userRole = currentUser.role;
    this.props
      .getAllTravelersByAirport(orgId, userRole, {
        filters: this.state.csvSearchFilters,
        pagination: { page: 1, pageSize: 1000 },
        sorter: {},
      })
      .then(resp => {
        this.setState({
          alltravellerList: resp.result.content,
        });
      })
      .catch(ex => {
       
      });
  };
  handleAirportChange(e) {
    this.setState({ airportId: e })
  }

  async getairportData(params, value) {
    var info = [];
    await this.props.getAirportCodesData(params).then(data => {

      if (value === 1) {
        info = [];
        info = this.state.airportData;
        this.setState({ airportData: data.data })
      } else if (value === 2) {

        info = [];
        if (data.data && data.data.length > 0) {
          let _da1 = data.data;
          let _da2 = this.state.airportData;
          let da3 = [];
          if (this.state.airportData && this.state.airportData.length > 0) {
            da3 = _da2.concat(_da1)
          }
          this.setState({ airportData: da3 })
        }

      }
      else {
        info = [];
        info = data.data;
        this.setState({ airportData: info });

      }
    })
  }
  onSearch(value, type) {
    this.setState({ searchairport: value })
    this.getairportData({
      orgId: 1,
      filters: { displayName: value },
      pagination: { page: 1, pageSize: 10 }
    }, 1)
  }
  loadAccountManagers = e => {

    var self = this;
    var _search;
    if (this.state.searchairport) {
      _search = { displayName: this.state.searchairport }
    } else {
      _search = {}
    }

    if (e.target.scrollTop + e.target.offsetHeight >= e.target.scrollHeight) {


      var datas = this.state.stateuser + 1
      self.getairportData({
        orgId: 1,
        filters: _search,
        pagination: { page: datas, pageSize: 10 }
      }, 2)
      this.setState({ stateuser: datas })
    }
  }

  callback(key) {
    this.setState({tab:key})
    if (key ==='1'){
      this.handleManualTableChange({ current: 1, pageSize: 10 }, {}, {});
    
    }else {
      this.handleTableChange({ current: 1, pageSize: 10 }, {}, {});
    
    }
    let filters = this.state.filters;
      if (filters['name']) {
        if (filters['name'].setSelectedKeys && typeof filters['name'].setSelectedKeys === 'function') {
          filters['name'].setSelectedKeys("");
          filters['name'].confirm();
        }
      }
      if (filters['divisionName']) {
        if (filters['divisionName'].setSelectedKeys && typeof filters['divisionName'].setSelectedKeys === 'function') {
          filters['divisionName'].setSelectedKeys("");
          filters['divisionName'].confirm();
        }
      }
      if (filters['parentOrganizationName']) {
        if (filters['parentOrganizationName'].setSelectedKeys && typeof filters['parentOrganizationName'].setSelectedKeys === 'function') {
          filters['parentOrganizationName'].setSelectedKeys("");
          filters['parentOrganizationName'].confirm();
        }
      }
         if (filters['pnrNumber']) {
        if (filters['pnrNumber'].setSelectedKeys && typeof filters['pnrNumber'].setSelectedKeys === 'function') {
          filters['pnrNumber'].setSelectedKeys("");
          filters['pnrNumber'].confirm();
        }
      }
      //filters['pnrNumber'] = { val: "", clearf: "", filter: false };
      
 
       filters['name'] = { val: "", clearf: "", filter: false };
       filters['divisionName'] = { val: "", clearf: "", filter: false };
       filters['parentOrganizationName'] = { val: "", clearf: "", filter: false };
       filters['pnrNumber'] = { val: "", clearf: "", filter: false };
        this.setState({ filters: filters,  searchText: "" });

  }

  getHeaderKeys = () => {
    let data= [
      {
        title: <IntlMessages id="admin.userlisting.name" />,
        dataIndex: "name",
        ...this.getColumnSearchProps("name"),
  
      },
      {
        title: <IntlMessages id="admin.report.division" />,
        dataIndex: "divisionName",
        ...this.getColumnSearchProps("divisionName")
      },
      {
        title: <IntlMessages id="admin.report.pnr" />,
        dataIndex: "pnrNumber",
        ...this.getColumnSearchProps("pnrNumber")
      },
      {
        title: <IntlMessages id="admin.report.departs" />,
        dataIndex: "departureDate",
        
        //...this.getColumnSearchProps("departureDate")
      },
      {
        title: <IntlMessages id="admin.report.details" />,
        dataIndex: "details",
        
        //...this.getColumnSearchProps("departureDate")
      },

      {
        title: <IntlMessages id="admin.manageOrders.Action" />,
        rowKey: "action",
        render: (_text, record) => (
          <span>
            <ViewCell
              onViewCell={() => {
                this.viewTravelerList(record.pnrId, record.email);
              }}
            />
          </span>
        )
      }
    ];
    if (Permissions.isRole("CSR") || Permissions.isRole("Administrator")){
      data.splice(2,0,{
        title: <IntlMessages id="admin.report.organization" />,
        dataIndex: "parentOrganizationName",
        ...this.getColumnSearchProps("parentOrganizationName")
      })
    }
    return data
  };

  getHeaderKeys2 = () => {
    let data= [
      {
        title: <IntlMessages id="admin.userlisting.name" />,
        dataIndex: "passengerName",
        ...this.getColumnSearchProps("passengerName")
      },
      {
        title: <IntlMessages id="admin.report.division" />,
        dataIndex: "divisionName",
        ...this.getColumnSearchProps("divisionName")
      },
      {
        title: <IntlMessages id="admin.report.pnr" />,
        dataIndex: "pnrNumber",
        ...this.getColumnSearchProps("pnrNumber")
      },
      {
        title: <IntlMessages id="admin.report.departs" />,
        dataIndex: "departureDate",
        
        //...this.getColumnSearchProps("departureDate")
      },
      {
        title: <IntlMessages id="admin.report.details" />,
        dataIndex: "details",
        
        //...this.getColumnSearchProps("departureDate")
      },

      {
        title: <IntlMessages id="admin.manageOrders.Action" />,
        rowKey: "action",
        render: (_text, record) => (
          <span>
            <ViewCell
              onViewCell={() => {
                this.viewTravelerList(record.pnrId, record.email);
              }}
            />
          </span>
        )
      }
    ];
    if (Permissions.isRole("CSR") || Permissions.isRole("Administrator")){
      data.splice(2,0,{
        title: <IntlMessages id="admin.report.organization" />,
        dataIndex: "parentOrganizationName",
        ...this.getColumnSearchProps("parentOrganizationName")
      })
    }
    return data
  };

  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => {
      return (
        <div style={{ padding: 8 }}>
          <Input
            ref={node => {
              this.searchInput = node;
            }}
            value={selectedKeys}
            onChange={e =>
              setSelectedKeys(e.target.value ? e.target.value.trimLeft() : "")
            }
            onPressEnter={() =>
              this.handleSearch(
                selectedKeys,
                confirm,
                dataIndex,
                clearFilters,
                setSelectedKeys
              )
            }
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Button
            type="primary"
            onClick={() =>
              this.handleSearch(
                selectedKeys,
                confirm,
                dataIndex,
                clearFilters,
                setSelectedKeys
              )
            }
            disabled={selectedKeys != "" && selectedKeys !== null ? false : true}
            icon="search"
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            <IntlMessages id="admin.search" />
          </Button>
          <Button
            disabled={selectedKeys != "" && selectedKeys !== null ? false : true}
            onClick={() => this.handleReset(clearFilters, dataIndex)}
            size="small"
            style={{ width: 90 }}
          >
            <IntlMessages id="admin.reset" />
          </Button>
        </div>
      );
    },
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    }
  });

  handleSearch = (
    selectedKeys,
    confirm,
    dataIndex,
    clearFilters,
    setSelectedKeys
  ) => {
    if (selectedKeys == "") {
      return false;
    }

    this.setState({ searchText: selectedKeys });
    let filters = this.state.filters;
    filters[dataIndex] = {
      val: selectedKeys,
      clearf: clearFilters,
      filter: true,
      setSelectedKeys: setSelectedKeys,
      confirm: confirm
    };
    this.setState({ filters: filters });
    confirm();
  };
  onCsvDownloadSelect =(key) =>{
    this.setState({downloadSelectedValue:key})
    if(key==='all'){
      this.getCsvData()
    }
    // 
  }
  handleReset = (clearFilters, dataIndex) => {
    clearFilters();
    let filters = this.state.filters;
    if (filters[dataIndex]) {
      if(filters[dataIndex].setSelectedKeys && typeof filters[dataIndex].setSelectedKeys === 'function'){
        filters[dataIndex].setSelectedKeys("");
        filters[dataIndex].confirm();
      }
    }
    filters[dataIndex] = { val: "", clearf: "", filter: false };
    this.setState({ filters: filters });
    this.setState({ searchText: "" });
  };



  viewTravelerList = (pnrId, email) => {
    let url;
    if(this.state.tab==1){
      url = "../../traveler-manualitinerary-viewer/" + pnrId;
   }
   else{
     url = "../../traveler-itinerary-viewer/" + pnrId + "/" + email;
   }
    window.open(url);
  };

  
  getManualCsv = () => {
    let token = UserServices.getToken();
    let _headers = {'Content-Type': 'multipart/form-data'};
    if (token) _headers['authorization'] = token ? 'Bearer ' + token : undefined;

    let currentUser = UserService.getUser();
    let orgId = currentUser.companyID;
    let userRole = currentUser.role;
    let filename = 'Manual Depature Report.csv';
    try{
      axios({
        url: travellerapi.getManualTravelersByDepatureAirportCsv(orgId,userRole, {filters: this.state.csvSearchFilters,pagination: { page: 1, pageSize: 10 },sorter: {}}),
        method: 'GET',
        responseType: 'blob',
        headers: _headers
      }).then((response) => {
         const url = window.URL.createObjectURL(new Blob([response.data]));
         const link = document.createElement('a');
         link.href = url;
         link.setAttribute('download', filename);
         document.body.appendChild(link);
         link.click();
      });
    }catch(e){
      console.log(e);
      
    }
 
  }

  handleCombineAndSubmit=()=>{
    var self=this;
    this.handleSubmit()
    setTimeout(function afterTwoSeconds() {
      self.getMagnatechorCombinedCsv('combined')
    }, 2000)
    
  }

  getMagnatechorCombinedCsv = (type) => {
    let token = UserServices.getToken();
    let _headers = { 'Content-Type': 'multipart/form-data' };
    if (token) _headers['authorization'] = token ? 'Bearer ' + token : undefined;
    let currentUser = UserService.getUser();
    let orgId = currentUser.companyID;
    let userRole = currentUser.role;
    if (type == 'magnatech') {
      let filename = 'Automated Depature Report.csv';
      try {
        axios({
          url: travellerapi.getMagnatechTravelersByDepatureAirportCsv('magnatech', orgId, userRole, { filters: this.state.csvSearchFilters, pagination: { page: 1, pageSize: 10 }, sorter: {} }),
          method: 'GET',
          responseType: 'blob',
          headers: _headers
        }).then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', filename);
          document.body.appendChild(link);
          link.click();
        });
      } catch (e) {
        console.log(e);
      }
    }
    else {
      let filename = 'Combined Depature Traveller Report.csv';
      try {
        axios({
          url: travellerapi.getMagnatechTravelersByDepatureAirportCsv('combined', orgId, userRole, { filters: this.state.csvSearchFilters, pagination: { page: 1, pageSize: 10 }, sorter: {} }),
          method: 'GET',
          responseType: 'blob',
          headers: _headers
        }).then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', filename);
          document.body.appendChild(link);
          link.click();
        });
      } catch (e) {
        console.log(e);

      }
    }
  }
  render() {
    const { getFieldDecorator } = this.props.form;
    const { formLayout } = this.props;
    const formItemLayout =
      formLayout === "horizontal"
        ? {
          labelCol: { span: 4 },
          wrapperCol: { span: 14 }
        }
        : null;
    let _state = this.state;
    let _this = this;
    var traveller = this.state.alltravellerList
    let headers
    this.state.role==='Administrator' || this.state.role ==='CSR' ?
    headers = [
      { label: 'Name', key: 'name' },
      { label: 'Divison', key: 'divisionName' },
      { label: 'Organization', key: 'parentOrganizationName' },
      { label: 'PNR', key: 'pnrNumber' },
      { label: 'Departs', key: 'departureDate' },
      { label: 'Details', key: 'details' }

    ]:headers = [
      { label: 'Name', key: 'name' },
      { label: 'Divison', key: 'divisionName' },
      { label: 'PNR', key: 'pnrNumber' },
      { label: 'Departs', key: 'departureDate' },
      { label: 'Details', key: 'details' }

    ]
    let filtertag = Object.keys(this.state.filters).map(function (key1) {
      let keyLabel = _this.getHeaderKeys().find(el => el.dataIndex === key1);

      if (keyLabel && keyLabel.title && keyLabel.title.props && keyLabel.title.props.id) {
        keyLabel = <IntlMessages id={keyLabel.title.props.id} />;
      } else {
        keyLabel = key1;
      }

      if (_state.filters[key1].filter) {
        return (
          <span>
            <Button
              key={key1}
              type="primary"
              onClick={() =>
                _this.handleReset(_state.filters[key1].clearf, key1)
              }
            >
              {keyLabel} : {_state.filters[key1].val}
              <Icon type="close" />
            </Button>
            &nbsp;
          </span>
        );
      }
    });
    return (

      <div>
        <Row gutter={24}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Typography.Title level={4}>
              <IntlMessages id="admin.sidebar.submenu.DepartureReport" />
            </Typography.Title>
          </Col>
          <Col xs={24} sm={24} md={16} lg={12} xl={12} className="align-right">
            <ul>
              <li>
                {/* {" "}
              <Select value={this.state.downloadSelectedValue} style={{ width: 220 }} onChange={this.onCsvDownloadSelect} >
                    <Option value="current">Current Page</Option>
                    <Option value="all">All</Option>
              </Select>
              {" "} */}
                {/* <CSVLink filename={"airportReport.csv"} headers={headers} data={traveller} > */}
                  {/* <Button>
                  <Icon type="download" />
                    <span><IntlMessages id="admin.report.csvdownload" /></span>
                  </Button> */}
                {/* </CSVLink> */}

              </li>
              {/* <li>
              <PrintComponents trigger={<Button type="primary" >
                  <i
                    style={{ display: "inline-block", marginRight: "5px" }}
                    className="fas fa-print"
                  ></i>
                  <span><IntlMessages id="admin.report.print" /></span>
                </Button>}> <PrintAbleComponent headerKeys={this.getHeaderKeys()} data={this.state.travellerList}/>
               
                </PrintComponents>
              </li> */}
              <li>
                <Button
                  type="primary"
                  htmlType="button"
                  onClick={() => this.props.history.push("../")}
                >
                  <IntlMessages id="admin.userlisting.back" />
                </Button>
              </li>
            </ul>
          </Col>
        </Row>
        <hr />
        <Form layout={formLayout} >
          <Row gutter={24}>
            <Col xs={5} >
              <Form.Item
                {...formItemLayout}
                
              >
                {getFieldDecorator("airportName", {
                  rules: [
                    {
                      required: true,
                      message: <IntlMessages id="admin.input.required"></IntlMessages>
                    }
                  ],
                })(
                <Select style={{ width: '100%' }}
                onPopupScroll={this.loadAccountManagers}
                showSearch
                onSearch={this.onSearch}
                placeholder={<IntlMessages id="admin.report.airport"></IntlMessages>}
                onSelect={this.getAirportUpdate}
                style={{ width: "200px", marginTop: "20px" }}
                onChange={(e) => this.handleAirportChange(e)}
                filterOption={(input, option) =>
                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }

              >
                {this.state.airportData ? this.state.airportData.map(function (item) {
                  return (
                    <Option key={item.airportID} value={item.code}>
                      {item.displayName}
                    </Option>
                  );
                }) : ''}
              </Select>
                )}
              </Form.Item>
              {/* <Select style={{ width: '100%' }}
                onPopupScroll={this.loadAccountManagers}
                showSearch
                onSearch={this.onSearch}
                placeholder='Select Airport'
                onSelect={this.getAirportUpdate}
                style={{ width: "200px", margin: "10px auto" }}
                onChange={(e) => this.handleAirportChange(e)}
              >
                {this.state.airportData ? this.state.airportData.map(function (item) {
                  return (
                    <Option key={item.airportID} value={item.code}>
                      {item.displayName}
                    </Option>
                  );
                }) : ''}
              </Select> */}
            </Col>
            <Col xs={5}>
              <Form.Item
                {...formItemLayout}
              //label={<IntlMessages id="userdash-departuredate" />}
              >
                {getFieldDecorator("airportdate", {
                  rules: [
                    {
                      required: true,
                      message: <IntlMessages id="admin.input.required"></IntlMessages>
                    }
                  ],

                })(
                  <DatePicker
                    onChange={(e) => this.handleDateChange(e)}
                    format={dateFormat}
                    style={{ width: "200px", marginTop: '20px' }}
                  />
                )}
              </Form.Item>
              {/* <DatePicker
              onChange={(e) => this.handleDateChange(e)}
              style={{ width: "200px", margin: "10px auto" }}
            /> */}
            </Col>
            <Col xs={7}>
              <Button
                type="primary"
                onClick={this.handleSubmit}
                style={{  marginTop: '20px' }}
              >
                <IntlMessages id="admin.organization.submit"></IntlMessages>
            </Button>
            </Col>
            <Col xs={3}>
                <Button style={{ marginTop: '20px' }} onClick={this.handleCombineAndSubmit} >
                  <Icon type="export" />
                  <span><IntlMessages id="admin.report.csvdownloadMagandManual" /></span>
                </Button>
            </Col>
            
          </Row>
        </Form>
        < div id='printableArea' >
        {this.state.showTable ?

< div >

  <hr />

  
  <div className="card-container">
    <Tabs type="card" onChange={this.callback}>
      <TabPane tab={<IntlMessages id="admin.report.manual" />} key="1">
      <Row gutter={24} style={{ marginBottom: "20px" }}>
    <Col xs={24}>{filtertag}</Col>
  </Row>
        <Row gutter={24}>
        <Col xs={15} ></Col>
      <Col xs={6} >

        <Button style={{ marginBottom: '10px', marginLeft: '88px' }} onClick={this.getManualCsv}>
          <Icon type="export" />
          <span><IntlMessages id="admin.report.csvdownloadManual" /></span>
        </Button>

      </Col>
      <Col xs={3}>
        <PrintComponents trigger={<Button type="primary" style={{ marginLeft: '34px' }}>
          <i
            style={{ display: "inline-block", marginRight: '5px' }}
            className="fas fa-print"
          ></i>
          <span><IntlMessages id="admin.report.print" /></span>
        </Button>}> <PrintAbleComponent headerKeys={this.getHeaderKeys().filter(data =>(data.dataIndex !=="pnrNumber"))} data={this.state.travellerListManual} /> </PrintComponents>
      </Col>
        </Row>
        <Row gutter={24}></Row>
        <Row gutter={24}>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={24}
            style={{ overflowX: "auto" }}
          >
            <Table
              bordered
              columns={this.getHeaderKeys()}
              dataSource={this.state.travellerListManual}
              pagination={this.state.paginationManual}
              loading={this.state.loading}
              onChange={this.handleManualTableChange}
            />
          </Col>
        </Row>
      </TabPane>
      <TabPane tab={<IntlMessages id="admin.report.magnatech" />} key="2">
      <Row gutter={24} style={{ marginBottom: "20px" }}>
    <Col xs={24}>{filtertag}</Col>
  </Row>
        <Row gutter={24}>
        <Col xs={15} ></Col>
      <Col xs={6} >

        <Button style={{ marginBottom: '10px', marginLeft: '63px' }} onClick={()=>this.getMagnatechorCombinedCsv('magnatech')}>
          <Icon type="export" />
          <span><IntlMessages id="admin.report.csvdownloadMagnatech" /></span>
        </Button>

      </Col>
      <Col xs={3}>
        <PrintComponents trigger={<Button type="primary" style={{ marginLeft: '30px' }}>
          <i
            style={{ display: "inline-block", marginRight: '5px' }}
            className="fas fa-print"
          ></i>
          <span><IntlMessages id="admin.report.print" /></span>
        </Button>}> <PrintAbleComponent headerKeys={this.getHeaderKeys()} data={this.state.travellerList} /> </PrintComponents>
      </Col>
        </Row>
        <Row gutter={24}>
        <Col
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={24}
            style={{ overflowX: "auto" }}
          >
            <Table
              bordered
              columns={this.getHeaderKeys2()}
              dataSource={this.state.travellerList}
              pagination={this.state.pagination}
              loading={this.state.loading}
              onChange={this.handleTableChange}
            />
          </Col>

        </Row>
      </TabPane>
    </Tabs>
  </div>

</div>
: ''
}</div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...travellersReportActions, ...itiniaryActions }, dispatch);
}
const WrappedForm = Form.create({ name: "airport-report" })(DepartureReport);
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    WrappedForm
  )
);