import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as orderActions from "../../../redux/actions/order-actions";
import * as UserActions from "../../../redux/actions/user-actions";
import IntlMessages from "../../../services/intlMesseges";
import * as UserService from "../../../services/user-service";
import {
  Typography,
  Form,
  Button,
  Row,
  Col,
  Spin,
  Descriptions,
  Table,
  Tag,
  Tooltip,
  Modal,
  Input
} from "antd";

import moment from "moment";
import { notifyUser } from "../../../services/notification-service";
import * as b2b2cActions from "../../../redux/actions/b2b2c-actions";

const dateFormat = "MM/DD/YYYY";
const dateFormathh = "MM/DD/YYYY HH:mm:ss";
class ViewOrder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      updateCompanionModalVisible: false,
      currentCompanionId: null,
      currentCompanionEmail: null,
      currentCompanionPhone: null,
      currentCompanionOrgId: null,
      orderInfo: {}
    };
  }

  formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2
  });
  async componentDidMount() {
    this.setState({ loading: true, id: this.props.match.params.orderId });
    this.loadOrder();
  }

  loadOrder = async() => {
    const order = await this.props.getAgentOrder(this.props.match.params.orderId);
    this.setState({ orderInfo: order.data, currentCompanionOrgId: order.data.orgId });
    this.setState({ loading: false });
  }

  submitFormAdd = data => {
    this.props
      .addOrder(data)
      .then(item => {
        this.setState({ loading: false });
        this.props.history.push("./");
      })
      .catch(err => {
        console.log(err);
        this.setState({ loading: false });
      });
  };

  editItem = id => {
    this.props.history.push("./edit/" + id);
  };

  submitFormEdit = data => {
    this.props
      .updateOrder(data)
      .then(item => {
        if (item.error) {
        } else {
          this.props.history.push("./");
        }

        this.setState({ loading: false });
      })
      .catch(err => {
        console.log(err);
        this.setState({ loading: false });
      });
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        Object.keys(values).map(function(key, _idx) {
          if(values[key] == null){
            values[key] = '';
          } else {
            if(typeof values[key] == 'string'){
              values[key] = values[key].trim();
            }
          }
          return key;
        });
        this.setState({ loading: true });
        if (this.state.id && this.state.id > 0) {
          values["id"] = this.state.id;
          this.submitFormEdit(values);
        } else {
          this.submitFormAdd(values);
        }
      }
    });
  };
  
  handleContactExistance(rule, value, callback){
    //const re = /^[0-9']+$/;
    const re = (/^(?=[+0-9]*$)(?!.*[<>'"/;`%~@#$^*()_=[\]{}|\\,.?: -])/)
    var test=re.test(value)
    if(value !=="" && (value.length <6 || test ===false)){
      callback(<IntlMessages id ="admin.phonenumber.valid"></IntlMessages>)
    }
    else{
      callback()
    }
}
  
resendMembershipInfo = async(id) => {
  await this.props.resendMembershipInfo(id);
}

resendProofOfBenefits = async(id) => {
  await this.props.resendProofOfBenefits(id);
}

traveler_columns = () => { 
  let currentUser = UserService.getUser();
  return [
    {
      title: <IntlMessages id="admin.userlisting.name" />,
      dataIndex: "travelerName"
    },
    {
      title: <IntlMessages id="admin.userlisting.email" />,
      dataIndex: "email"
    },
    {
      title: <IntlMessages id="admin.manageOrders.contactnumber" />,
      dataIndex: "contactNo"
    },
    {
      title: <IntlMessages id="admin.manageOrders.ProductName" />,
      dataIndex: "productName"
    },
    {
      title: <IntlMessages id="admin.manageOrders.TravellerType" />,
      dataIndex: "travelerType",
      key: "travelerType",
      render: (text, record) =>
        record.travelerType === "companion" ? (
          <Tag color="blue">Companion</Tag>
        ) : (
          <Tag color="green">Primary</Tag>
        ),

      width: "15%"
      // ...this.getColumnSearchProps("travelerType")
    },
    {
      title: <IntlMessages id="admin.userlisting.action" />,
      dataIndex: "actions",
      key: "actions",
      width: "15%",
      render: (_text, record) => (
        <span>
          {record.travelerType === "companion" ? 
            <button 
              disabled={!(record.email === "" || record.email === null)}
              style={{ opacity: (record.email === "" || record.email === null) ? '1' : '0.5' }}
              className="icon"
              onClick={() => this.showUpdateCompanionModal(record.userId, record.email, record.contactNo)}
            >
              <Tooltip title="Edit">
                <i className="fas fa-edit"></i>
              </Tooltip>
            </button>
          : 
            <button 
              className="icon"
              onClick={() =>
                (currentUser.userID && currentUser.userID === record.userId) ? 
                  this.props.history.push("../../../../my-profile/") 
                : 
                this.props.history.push("../../travelers/edit/" + record.userId)
              }
            >
              <Tooltip title="Edit">
                <i className="fas fa-edit"></i>
              </Tooltip>
            </button>
          }
          {!(record.email === null || record.email === "") ? 
            <button
              className="icon"
              onClick={() => {
                this.resendMembershipInfo(record.userId);
              }}
              >
              <Tooltip
                title={
                  <IntlMessages id="admin.traveler.membershipinformation" />
                }
              >
                <i className="fas fa-info-circle"></i>
              </Tooltip>
            </button> : ""}
            {
              <span>
                <button
                  className="icon"
                  onClick={() =>
                    window.open(
                      "/cap-card.html?mid=" +
                        ((this.state.orderInfo.membershipNumber
                          ? this.state.orderInfo.membershipNumber
                          : "FPST-YYYYYYYYYY") +
                          "&fn=" +
                          (record.travelerName || "") +
                          "&ln=" +
                          "&sd=" +
                          (moment(this.state.orderInfo.coverageStartDate).format(dateFormat) || "") +
                          "&ed=" +
                          (moment(this.state.orderInfo.coverageEndDate).format(dateFormat) || "")),
                      "_blank"
                    )
                  }
                >
                  <Tooltip title="View Card">
                    <i className="fas fa-id-card"></i>
                  </Tooltip>
                </button>
              </span>
            }
            {
              <span>
                <button
                  className="icon"
                  onClick={() => {
                    this.resendProofOfBenefits(record.userId);
                  }}
                >
                  <Tooltip title="Resend Proof of Benefits">
                    <i className="fas fa-file-contract"></i>
                  </Tooltip>
                </button>
              </span>
            }
        </span>
      )
    }
  ];
}
  
  submitUpdateCompanionModal = () => {
    this.props.form.validateFieldsAndScroll((err, _values) => {
      if(!err){
        this.props.assignIdentity({
          userId: this.state.currentCompanionId,
          organizationId: this.state.currentCompanionOrgId,
          email: this.state.currentCompanionEmail,
          ContactNo: this.state.currentCompanionPhone
        }).then(r => {
          if(r.data){
            notifyUser("Details updated successfully!", "success");
            this.cancelUpdateCompanionModal();
            this.loadOrder();
          } else {
            if(r.response && r.response.data && r.response.data){
              notifyUser(r.response.data.Message, "error");
            } else {
              notifyUser("Something went wrong. Please try again!", "error");
            }
          }
        }).catch(e => {
          console.log("e", e)
          notifyUser("Something went wrong. Please try again!", "error");
          this.cancelUpdateCompanionModal();
        })
      }
    });
  }

  showUpdateCompanionModal = (userId, email, contact) => {
    this.setState({ 
      updateCompanionModalVisible: true,
      currentCompanionId: userId,
      currentCompanionEmail: email,
      currentCompanionPhone: contact
    });
  }

  cancelUpdateCompanionModal = () => {
    this.setState({ 
      updateCompanionModalVisible: false,
      currentCompanionId: null,
      currentCompanionEmail: null,
      currentCompanionPhone: null
    });
  }

  onCompanionEmailInputChange = (e) => {
    this.setState({ 
      currentCompanionEmail: e.target.value 
    });
  } 

  onCompanionPhoneInputChange = (e) => {
    this.setState({ 
      currentCompanionPhone: e.target.value 
    });
  } 
  render() {
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = null;
    return (
      <div>
        <Modal
          title="Update companion details"
          visible={this.state.updateCompanionModalVisible}
          onOk={this.submitUpdateCompanionModal}
          onCancel={this.cancelUpdateCompanionModal}
        >
          <Form>
            <Row gutter={24}>
              <Col xs={24}>
                <Form.Item
                  {...formItemLayout}
                  label={<IntlMessages id="admin.userlisting.email" />}
                >
                  {getFieldDecorator("companion_email", {
                    rules: [
                      {
                        type: "email",
                        message: <IntlMessages id="admin.email.valid" />

                      },
                      {
                        required: true,
                        message: <IntlMessages id="admin.input.required" />
                      }
                    ],
                    initialValue: this.state.currentCompanionEmail
                  })(<Input onChange={this.onCompanionEmailInputChange} />)}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col xs={24}>
                <Form.Item
                  {...formItemLayout}
                  label={<IntlMessages id="admin.userlisting.phonenumber" />}
                >
                  {getFieldDecorator("companion_phone", {
                    rules: [
                      {
                        validator: this.handleContactExistance
                      },
                       {
                      //   whitespace: true,
                      //   required: false
                       },
                      // {
                      //   //pattern: new RegExp(/^[0-9']+$/),
                      //   pattern: new RegExp(/^(?=[+0-9]*$)(?!.*[<>'"/;`%~@#$^*()_=[\]{}|\\,.?: -])/),
                      //   message: 'Please enter a Valid Number!'
                      // },
                      // {
                      //   max: 15,
                      //   message: "Please enter a Valid Number!"
                      // },
                      // {
                      //   min: 6,
                      //   message: "Please enter a Valid Number!"
                      // }
                    ],
                    initialValue: this.state.currentCompanionPhone
                  })(<Input onChange={this.onCompanionPhoneInputChange} maxLength={15} />)}
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
        <Row gutter={24}>
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography.Title level={4}>
              {<IntlMessages id="admin.manageOrders.Order" />} #
              {this.state.orderInfo.orderNumber}
            </Typography.Title>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <Button
              type="primary"
              className="right-fl def-blue"
              htmlType="button"
              onClick={() => this.props.history.goBack()}
            >
              <IntlMessages id="admin.button.back" />
            </Button>
          </Col>
        </Row>
        <hr></hr>
        <div>
          <Spin spinning={this.state.loading}>
            <Descriptions title={<IntlMessages id="admin.manageOrders.OrderDetails" />}>
              <Descriptions.Item label={<IntlMessages id="admin.manageOrders.travellerName" />}>{this.state.orderInfo.travelerName}</Descriptions.Item>
              <Descriptions.Item label={<IntlMessages id="admin.manageOrders.quantity" />}>{this.state.orderInfo.quantity}</Descriptions.Item>
              <Descriptions.Item label={<IntlMessages id="admin.manageOrders.countries" />}>{this.state.orderInfo.countries}</Descriptions.Item>
              <Descriptions.Item label={<IntlMessages id="admin.manageOrders.Traveltier" />}>Tier {this.state.orderInfo.tierID}</Descriptions.Item>
              <Descriptions.Item label={<IntlMessages id="admin.manageOrders.days" />}>{this.state.orderInfo.days}</Descriptions.Item>
              <Descriptions.Item label={<IntlMessages id="admin.manageOrders.membershipNumber" />}>{this.state.orderInfo.membershipNumber}</Descriptions.Item>
              <Descriptions.Item label={<IntlMessages id="admin.manageOrders.createdDate" />}>{moment(this.state.orderInfo.insertDate).format(dateFormathh)}</Descriptions.Item>
              <Descriptions.Item label={<IntlMessages id="admin.travel.start.date" />}>{moment(this.state.orderInfo.coverageStartDate).format(dateFormat)}</Descriptions.Item>
              <Descriptions.Item label={<IntlMessages id="admin.travel.end.date" />}>{moment(this.state.orderInfo.coverageEndDate).format(dateFormat)}</Descriptions.Item>
              <Descriptions.Item label={<IntlMessages id="admin.manageOrders.actualAmount" />}>{this.formatter.format(this.state.orderInfo.totalOrderRetailAmount)} {this.state.orderInfo.currency}</Descriptions.Item>
              <Descriptions.Item label={<IntlMessages id="admin.manageOrders.couponID" />}>{this.state.orderInfo.couponCode}</Descriptions.Item>
              <Descriptions.Item label={<IntlMessages id="admin.manageOrders.discountedAmount" />}>{this.formatter.format(this.state.orderInfo.totalOrderAmount)} {this.state.orderInfo.currency}</Descriptions.Item>
            </Descriptions>
            <hr></hr>
            <Descriptions
              title={<IntlMessages id="admin.manageOrders.TravellerInfo" />}
            >
              <Descriptions.Item label="">
                <Table
                  columns={this.traveler_columns()}
                  dataSource={this.state.orderInfo.travelersInfo}
                />
              </Descriptions.Item>
            </Descriptions>
          </Spin>
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...orderActions, ...UserActions, ...b2b2cActions }, dispatch);
}
const WrappedForm = Form.create({ name: "view-order" })(ViewOrder);
export default withRouter(
  connect(null, mapDispatchToProps, null, { forwardRef: true })(
    WrappedForm
  )
);
