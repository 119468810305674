import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Wizard from "../shared/wizard/wizard.jsx";
import * as b2cActions from "../../../redux/actions/b2c-actions";
import * as userActions from "../../../redux/actions/user-actions";
import { WrappedRegistrationForm } from "./traveller-form";
import { Beforeunload } from "react-beforeunload";
import { Row, Col, Button, Modal } from "antd";
import Config from "../../../../../config";
import moment from "moment";
import PriceCalculation from "./price-calculation.jsx";
import { logIn } from "../../../services/user-service.js";
const { confirm } = Modal;
class PersonalDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      planMaxPrice: 0,
      productToOrder: Config.wpProductToOrder,
      totalPrice: props.planTerm ? props.planTerm.totalPrice : 0,
      appliedPromoText: "",
      selectedcountryTierList: [],
      getParams: {
        start_date: "",
        end_date: "",
        traveller_count: props.travelerCount ? props.travelerCount : 1,
        countries: [],
        travel_type: "",
      },
      currency: "USD",
      currencyRate: 1,
      cadDiscountedAmount: 0,
      CadDisAmount: 0,
      genderList: [],
    };
    this.handleBack = this.handleBack.bind(this);
  }

  getUrlDecodedParams() {
    let localDecodedParams = localStorage.getItem("decodedParams");
    if (
      typeof localDecodedParams !== "undefined" &&
      localDecodedParams !== null &&
      localDecodedParams !== ""
    ) {
      return JSON.parse(localDecodedParams);
    }
    var encodedParameters = this.props.location.search;
    var decodedParameterString = atob(encodedParameters.substring(1));
    var parameters = decodedParameterString.split("&");
    var parameterArray = new Map();

    parameters.forEach(async function(parameter) {
      var data = parameter.split("=");
      parameterArray[data[0]] = data[1];
    });
    var selectedGetCountries = parameterArray["countries"].split(",");
    var selectedGetCountriesStr = [];
    for (var i = 0; i < this.props.countryTierInfo.length; i++) {
      if (
        selectedGetCountries.indexOf(
          this.props.countryTierInfo[i].countryCode
        ) > -1
      ) {
        selectedGetCountriesStr.push(this.props.countryTierInfo[i].countryName);
      }
    }

    return {
      start_date: parameterArray["start_date"],
      end_date: parameterArray["end_date"],
      traveller_count: parseInt(parameterArray["traveller_count"]),
      countries: parameterArray["countries"],
      countries_list: selectedGetCountriesStr.join(", "),
      residence_country: parameterArray["residence_country"],
      resi_country_code: parameterArray["resi_c_code"],
      us_travel_states: parameterArray["res_state_lists"],
      residence_state: parameterArray["residence_state"],
      travel_type: parameterArray["travel_type"],
      travel_type_label: parameterArray["traveltypelabel"],
      currency_code: parameterArray["currency_code"],
    };
  }

  async componentDidMount() {
    let is_back = localStorage.getItem("is_back");

    window.scrollTo(0, 0);
    await this.props.getPersonalInformation();
    await this.props.getStep();
    await this.props.getUserData();
    await this.props.getPlanInformation();
    await this.props.getPlanTermInformation();
    await this.props.getProductPricingAndInfo();
    await this.props.getAllCountries();

    var encodedParameters = this.props.location.search;
    var decodedParameterString = atob(encodedParameters.substring(1));
    var parameters = decodedParameterString.split("&");
    var parameterArray = new Map();
    parameters.forEach(async function(parameter) {
      var data = parameter.split("=");
      parameterArray[data[0]] = data[1];
    });

    if (!parameterArray["reload_cap_order"] || is_back != "yes") {
      this.setState({
        currency: parameterArray["currency_code"],
      });
      this.handleCurrencyChange(parameterArray["currency_code"]);
    }

    const _MS_PER_DAY = 1000 * 60 * 60 * 24;
    var start_date = Date.parse(parameterArray["start_date"]);
    var end_date = Date.parse(parameterArray["end_date"]);
    var days = Math.floor((end_date - start_date) / _MS_PER_DAY) + 1;
    let dp = 8;
    if (days <= 8) {
      dp = 8;
    } else if (days <= 15) {
      dp = 15;
    } else if (days <= 30) {
      dp = 30;
    } else if (days <= 60) {
      dp = 60;
    } else if (days <= 90) {
      dp = 90;
    } else if (days <= 120) {
      dp = 120;
    } else if (days <= 365) {
      dp = 365;
    }

    var selectedGetCountries = parameterArray["countries"].split(",");
    var selectedGetCountriesStr = [];
    var selectedGetCountriesArr = [];
    for (var i = 0; i < this.props.countryTierInfo.length; i++) {
      if (
        selectedGetCountries.indexOf(
          this.props.countryTierInfo[i].countryCode
        ) > -1
      ) {
        selectedGetCountriesArr.push(this.props.countryTierInfo[i].countryId);
        selectedGetCountriesStr.push(this.props.countryTierInfo[i].countryName);
      }
    }

    let res_countryid = " ";
    this.props.countryTierInfo.map((country, index) => {
      if (country.countryCode == parameterArray["resi_c_code"]) {
        res_countryid = country.countryId;
      }
    });

    let res_stateid = "";
    let res_statename = "";
    this.props.allUsStates.map((state, index) => {
      if (state.stateCode == parameterArray["residence_state"]) {
        res_stateid = state.stateId;
        res_statename = state.stateName;
      }
    });

    var decodedParamsObj = {
      start_date: parameterArray["start_date"],
      end_date: parameterArray["end_date"],
      traveller_count: parseInt(parameterArray["traveller_count"]),
      countries: parameterArray["countries"],
      countries_list: selectedGetCountriesStr.join(", "),
      travel_type: parameterArray["travel_type"],
      travel_type_label: parameterArray["traveltypelabel"],
      residence_country: parameterArray["residence_country"],
      resi_country_code: parameterArray["resi_country_code"],
      residence_state: res_statename,
      travel_type_label: parameterArray["traveltypelabel"],
      res_countryid: res_countryid,
      res_stateid: res_stateid,
      us_travel_states: parameterArray["res_state_lists"],
    };
    this.setState(
      {
        dayPackage: dp,
        getParams: decodedParamsObj,
      },
      () => {
        // let hasQs = localStorage.getItem("qs");
        // if (typeof hasQs !== "undefined" && hasQs !== null && hasQs !== "" && hasQs === window.location.search.substring(1)) {

        // } else {
        localStorage.setItem("decodedParams", JSON.stringify(decodedParamsObj));
        localStorage.setItem("qs", window.location.search.substring(1));
        // }
      }
    );

    let planType = this.state.productToOrder;
    let planObject = this.props.productInfo.find(
      (x) => x.productId === parseInt(planType)
    );
    await this.props.SaveStep("1");
    await this.props.SavePlanType(planObject);

    /* save plan term */
    let planTermObject = {};
    planTermObject.date = parameterArray["start_date"];
    planTermObject.end_date = parameterArray["end_date"];
    planTermObject.days = Math.floor((end_date - start_date) / _MS_PER_DAY) + 1;
    planTermObject.dp = dp;
    planTermObject.country = selectedGetCountriesArr.join(",");
    planTermObject.countryList = selectedGetCountriesStr.join(", ");
    planTermObject.ustravel_states = parameterArray["res_state_lists"];
    // planTermObject.totalPrice = this.state.productPrice;

    planTermObject.Price =
      this.state.productPrice / parseInt(parameterArray["traveller_count"]);
    planTermObject.tierValue = this.state.selectedCountryTier;
    planTermObject.isAnnual = dp == 365 ? true : false; //this.state.is_annual;
    await this.props.SaveStep("2");
    await this.props.SavePlanTerm(planTermObject);

    this.props.getGendersList().then((response) => {
      this.setState({ genderList: response });
    });

  }
  // }
  async handleBack() {
    await this.props.SaveStep("2");
    this.props.history.push(this.props.b2cBaseUrl + "/plan-term");
  }

  onUnload = (e) => {
    localStorage.removeItem("qs");
    localStorage.removeItem("decodedParams");
    e.preventDefault();
    //this.props.flushOrderData();
    //this.props.ClearData();
  };

  onChangeAnnual = (e) => {
    this.onChangeAnnualCB(e.target.checked);
  };

  async onChangeAnnualCB(checked) {
    var isAnnual = checked;
    if (this.props.personalDetails && this.props.personalDetails.length > 0) {
      var objContent = {};
      if (isAnnual) {
        objContent.title =
          "Do you want the Annual Unlimited Trip Plan for all travelers?";
        objContent.content =
          "Please click OK to confirm your upgrade to the Annual Unlimited Trip Plan. ";
        this.showConfirm(objContent, isAnnual);
      } else {
        this.setState({ annualChecked: false });
        this.upgradePlan(isAnnual);
      }
    } else {
      this.upgradePlan(isAnnual);
    }
  }

  async upgradePlan(isAnnual) {
    let planTermObject = this.props.planTerm;
    planTermObject.isAnnual = isAnnual;
    await this.props.SavePlanTerm(planTermObject);
    this.setState({ annualChecked: isAnnual });
  }

  showConfirm(objContent, isAnnual) {
    var self = this;
    confirm({
      title: objContent.title,
      content: objContent.content,
      className: "gray-color",
      centerd: true,
      onOk() {
        self.setState({ annualChecked: true });
        self.upgradePlan(isAnnual);
      },
      onCancel() {
        self.setState({ annualChecked: false });
        self.upgradePlan(false);
      },
    });
  }

  updateTravellerCount = (count) => {
    this.props.travellerCount(count);
    let _this = this;
    let _getParams = this.state.getParams;
    _getParams.traveller_count = Math.max(1, count);
    this.setState({ getParams: _getParams }, () => {
      localStorage.setItem("decodedParams", JSON.stringify(_getParams));
      _this.onChangeAnnualCB(_this.props.planTerm.isAnnual);
    });
  };

  handleCurrencyChange = async (value) => {
    // var value = e.target.value;
    this.setState({ currency: value });

    var currencyData = "";
    await this.props.convertCurrency().then((resp) => {
      currencyData = resp;
    });
    if (this.state.currency == "USD") {
      this.setState({ currencyRate: 1 });
      this.props.updateCurrency(value, 1);
    } else {
      this.setState({ currencyRate: currencyData.value });
      this.props.updateCurrency(value, currencyData.value);
    }
    //  this.updatePrice();
  };

  render() {
    var decodedParams = this.state.getParams;
    var plus1Year = decodedParams.start_date;

    plus1Year = moment(plus1Year)
      .add(364, "days")
      .format("YYYY-MM-DD");
    const backPageurl =
      decodedParams.us_travel_states &&
      decodedParams.us_travel_states != undefined
        ? "domestic-quote-results/"
        : "quote-results/";

    return (
      <section id="banner" style={{ paddingTop: 0 }}>
        <Beforeunload onBeforeunload={(e) => this.onUnload(e)}>
          <div
            style={{
              background: "#203da5",
              padding: "20px 0",
              color: "#eee",
            }}
          >
            <div className="container">
              <Row className="plan-quote-header">
                <Col sm={20}>
                  <ul className="desti">
                    <li>
                      <span className="label">Dates :</span>
                      <br />
                      <span className="desti-result">
                        {this.props.planTerm && this.props.planTerm.dp < 120 ? (
                    <>
                     {moment(decodedParams.start_date).format("MM/DD/YYYY")} -{" "}
                      {this.props.planTerm && this.props.planTerm.isAnnual !== false
                        ? moment(plus1Year).format("MM/DD/YYYY")
                        : moment(decodedParams.end_date).format("MM/DD/YYYY")}
                    </>
                  ) : (
                    <>
                    {moment(decodedParams.start_date).format("MM/DD/YYYY")} -{" "}
                        {moment(decodedParams.end_date).format("MM/DD/YYYY")}
                      {/* {moment(decodedParams.start_date).format("MM/DD/YYYY")} -{" "}
                      {this.props.planTerm && this.props.planTerm.isAnnual !== false
                        ? moment(plus1Year).format("MM/DD/YYYY")
                        : moment(decodedParams.end_date).format("MM/DD/YYYY")} */}
                    </>
                  )}
                       
                      </span>
                    </li>

                    <li>
                      <span className="label">Destinations :</span>
                      <br />
                      <span className="desti-result">
                        {decodedParams.countries_list}
                        {decodedParams.us_travel_states ? ", " : ""}
                        <br />
                        {decodedParams.us_travel_states
                          ? decodedParams.us_travel_states
                          : ""}
                      </span>
                    </li>

                    <li>
                      <span className="label"># of Travelers :</span>
                      <br />
                      <span className="desti-result">
                        {this.props.travelerCount}
                      </span>
                    </li>
                  </ul>
                </Col>
                <Col className="edit-btn" sm={4}>
                  <Button
                    type="primary desti-button"
                    onClick={() =>
                      (window.location =
                        Config.wpEditQuoteUrl +
                        backPageurl +
                        window.location.search)
                    }
                  >
                    Edit Quote
                  </Button>
                </Col>
              </Row>
            </div>
          </div>
          <Wizard wizardActiveClass="step3" />

          <div>
            <div
              className="wrap900 price-sticky-head"
              style={{
                display: "block",
                maxWidth: "1300px",
                zIndex: "100",
                position: "sticky",
              }}
            >
              <div className="title">
                <PriceCalculation />

                <span>{this.state.appliedPromoText}</span>
              </div>
            </div>

            <WrappedRegistrationForm
              planTerm={this.props.planTerm}
              SaveStep={this.props.SaveStep}
              SavePlanTerm={this.props.SavePlanTerm}
              genderlist={this.state.genderList}
              // updatePrice={this.updatePrice}
              SavePersonalDetails={this.props.SavePersonalDetails}
              handleBack={this.handleBack}
              history={this.props.history}
              productPricing={this.props.productPricing}
              productInfo={this.props.productInfo}
              planType={this.props.planType}
              productPriceList={this.props.productPriceList}
              personalDetails={this.props.personalDetails}
              validateTravellerEmail={this.props.validateTravellerEmail}
              emailCheckResponse={this.props.emailCheckResponse}
              flushEmailCheckResponse={this.props.flushEmailCheckResponse}
              userData={this.props.userData}
              travellerInfo={this.props.travellerInfo}
              getTravellerInfo={this.props.getTravellerInfo}
              isLoggedIn={this.props.isLoggedIn}
              countryTierInfo={this.props.allCountries}
              usstates={this.props.allUsStates}
              location={this.props.location}
              b2cBaseUrl={this.props.b2cBaseUrl}
              traveller_count={this.props.travelerCount}
              rcid={this.state.getParams.res_countryid}
              decoded_params={decodedParams}
              planMaxPrice={this.state.planMaxPrice}
              handleAnnualChnage={this.onChangeAnnual}
              updateTravellerCount={this.updateTravellerCount}
              currency={this.props.currency}
              currencyRate={this.state.currencyRate}
            />
          </div>
        </Beforeunload>
      </section>
    );
  }
}
PersonalDetails.propTypes = {
  getProductPricingAndInfo: PropTypes.func,
  productPricing: PropTypes.array,
  getPersonalInformation: PropTypes.func,
  getPlanInformation: PropTypes.func,
  getPlanTermInformation: PropTypes.func,
  SaveStep: PropTypes.func,
  SavePersonalDetails: PropTypes.func,
  step: PropTypes.string,
  SavePlanTerm: PropTypes.func,
  planTerm: PropTypes.object,
  productInfo: PropTypes.array,
  planType: PropTypes.object,
  productPriceList: PropTypes.array,
  personalDetails: PropTypes.array,
  validateTravellerEmail: PropTypes.func,
  emailCheckResponse: PropTypes.object,
  flushEmailCheckResponse: PropTypes.func,
  getUserData: PropTypes.func,
  userData: PropTypes.object,
  getTravellerInfo: PropTypes.func,
  travellerInfo: PropTypes.object,
  isLoggedIn: PropTypes.bool,
  location: PropTypes.object,
  history: PropTypes.object,
  getAppliedPromo: PropTypes.func,
  couponInfo: PropTypes.object,
  getStep: PropTypes.func,
  b2cBaseUrl: PropTypes.string,
};
function mapStateToProps(state) {
  return {
    ...state.b2c,
    ...state.user,
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...b2cActions, ...userActions }, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(PersonalDetails);
