  import React, { Component } from "react";
import { Row, Col } from "antd";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import IntlMessages from "../../../../services/intlMesseges";
import * as CustomAlertActions from "../../../../redux/actions/custom-alert-actions";
import { Table, Input, Button, Icon } from "antd";
import moment from "moment";
const dateFormathh = "MM/DD/YYYY HH:mm:ss";

class CustomAlertuser extends Component {
  state = {
    loading: false,
    data: [],
    dataLoaded: false,
    searchText: "",
    searchedColumn: "",
    pagination: {
      hideOnSinglePage: true,
      showTotal: (total, range) => {
        return (
          <span>
            <IntlMessages id="admin.text.showing" /> {range[0]}-{range[1]}{" "}
            <IntlMessages id="admin.text.of" /> {total}{" "}
            <IntlMessages id="admin.text.results" />
          </span>
        );
      },
      pageSize: 10,
      current: 1
    },
    filters: {}
  };

  async componentDidMount() {
    if (this.state.dataLoaded === false) {
      this.handleTableChange(this.props.match.params.id, { current: 1, pageSize: 10 }, {}, {});
      this.setState({ dataLoaded: true });
    }
  }

  tableChange = (pagination, filters, sorter) => {
    this.handleTableChange(this.props.match.params.id, pagination, filters, sorter);
  }

  handleTableChange = (alertId, pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;

    this.setState({ loading: true });
    this.props
      .getUserReplies(alertId,{
        filters: filters,
        pagination: { page: pagination.current, pageSize: pagination.pageSize },
        sorter: sorter
      })
      .then(resp => {
        pager.total = resp.paging.totalRecords;
        this.setState({
          loading: false,
          data: resp.data,
          pagination: pager
        });
      })
      .catch(ex => {
        this.setState({ loading: false });
      });
  };

  getHeaderKeys = () => {
    return [
      {
        title: <IntlMessages id="admin.usermsgalert.name" />,
        dataIndex: "userName",
        ...this.getColumnSearchProps("userName")
      },
      {
        title: <IntlMessages id="admin.usermsgalert.message" />,
        dataIndex: "message",
        ...this.getColumnSearchProps("message")
      },
      {
        title: <IntlMessages id="admin.usermsgalert.date" />,
        dataIndex: "insertDate",
        render: (_data, record) => (
          <span>{moment(record.insertDate).format(dateFormathh)}</span>
        )
      }
    ];
  };

  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => {
      return (
        <div style={{ padding: 8 }}>
          <Input
            ref={node => {
              this.searchInput = node;
            }}
            value={selectedKeys}
            onChange={e =>
              setSelectedKeys(e.target.value ? e.target.value.trimLeft() : "")
            }
            onPressEnter={() =>
              this.handleSearch(
                selectedKeys,
                confirm,
                dataIndex,
                clearFilters,
                setSelectedKeys
              )
            }
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Button
            type="primary"
            onClick={() =>
              this.handleSearch(
                selectedKeys,
                confirm,
                dataIndex,
                clearFilters,
                setSelectedKeys
              )
            }
            disabled={(selectedKeys != "" && selectedKeys !== null) ? false : true}
            icon="search"
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            <IntlMessages id="admin.search" />
          </Button>
          <Button
            disabled={(selectedKeys != "" && selectedKeys !== null) ? false : true}
            onClick={() => this.handleReset(clearFilters, dataIndex)}
            size="small"
            style={{ width: 90 }}
          >
            <IntlMessages id="admin.reset" />
          </Button>
        </div>
      );
    },
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    }
  });

  handleSearch = (
    selectedKeys,
    confirm,
    dataIndex,
    clearFilters,
    setSelectedKeys
  ) => {
    if(selectedKeys === ""){
      return false;
    }
    
    this.setState({ searchText: selectedKeys });
    let filters = this.state.filters;
    filters[dataIndex] = {
      val: selectedKeys,
      clearf: clearFilters,
      filter: true,
      setSelectedKeys: setSelectedKeys,
      confirm: confirm
    };
    this.setState({ filters: filters });
    confirm();
  };

  handleReset = (clearFilters, dataIndex) => {
    clearFilters();
    let filters = this.state.filters;
    if (filters[dataIndex]) {
      if(filters[dataIndex].setSelectedKeys && typeof filters[dataIndex].setSelectedKeys === 'function'){
        filters[dataIndex].setSelectedKeys("");
        filters[dataIndex].confirm();
      }
    }
    filters[dataIndex] = { val: "", clearf: "", filter: false };
    this.setState({ filters: filters });
    this.setState({ searchText: "" });
  };

  render() {
    return (
      <div>
        <Row gutter={24}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Table
              bordered
              columns={this.getHeaderKeys()}
              rowKey={record => record.userChatId}
              dataSource={this.state.data}
              pagination={this.state.pagination}
              loading={this.state.loading}
              onChange={this.tableChange}
            />
          </Col>
        </Row>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    ...state.user
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...CustomAlertActions }, dispatch);
}
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    CustomAlertuser
  )
);