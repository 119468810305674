import Axios from "../../services/axios-service";

//organization types
const getOrganizationTypes = data => {
  return Axios.get("/organization-types", data, undefined);
};

const getOrganizationType = idx => {
  return Axios.get("/organization-types/" + idx, undefined, undefined);
};

const updateOrganizationType = data => {
  return Axios.put("/organization-types", data, undefined);
};

const deleteOrganizationType = data => {
  return Axios.delete("/organization-types", data, undefined);
};

//organizations
const getOrganizations = data => {
  return Axios.get("/api/organization?search=" + encodeURIComponent(JSON.stringify(data)));
};

const getOrganization = idx => {
  return Axios.get("/organizations/" + idx, undefined, undefined);
};

const updateOrganization = data => {
  return Axios.put("/organizations", data, undefined);
};

const deleteOrganization = data => {
  return Axios.delete("/organizations", data, undefined);
};
const addOrganizations = data => {
  return Axios.post("/api/Organization/onboard", data, undefined);
};
const getPccList = data => {

  return Axios.get("/api/PCC/PCCList", data, undefined);
};
const PCCAccountList = data => {

  return Axios.get("/api/PCC/PCCAccountList/" + data, undefined);
};
const getOrganizationById = data => {

  return Axios.get("/api/Organization/" + data, undefined);
}
const getOrganizationProducts = orgId => {
  return Axios.get("/api/OrganizationProducts/" + orgId, undefined, undefined);
};
const getOrganizationLicenses = (args) => {
  let orgId = args.orgId;
  delete args.orgId;
  return Axios.get("/api/OrganizationLicenses/" + orgId + "?search=" + encodeURIComponent(JSON.stringify(args)), undefined, undefined);
};
const getOrganizationLicensesByProduct = (args) => {
  let orgId = args.orgId;
  let productId = args.productId;
  delete args.orgId;
  delete args.productId;
  return Axios.get("/api/OrganizationProductLicenses/" + orgId + "/" + productId + "?search=" + encodeURIComponent(JSON.stringify(args)), undefined, undefined);
};
const assignLicense = (data) => {
  return Axios.post("/api/AssignLicenses", data, undefined);
};
const unassignLicense = (data) => {
  return Axios.post("/api/UnAssignLicense", data, undefined);

};
const updateOrganizationById = (data, id) => {

  return Axios.put("/api/Organization/" + id, data, undefined);
};
const getLicenseLogs = (args) => {
  let _licenseId = args.licenseId;
  delete args.licenseId;
  return Axios.get("/api/LicenseLogs/"+_licenseId+"?search=" + encodeURIComponent(JSON.stringify(args)), undefined, undefined);
};

const addorderinOrganization = (data) => {

  return Axios.post("/api/Organization/newOrder" , data, undefined)
}
const getLicenseCount = (orgId) => {
  return Axios.get("/api/OrganizationLicenseCount/"+orgId , undefined, undefined)
}
const removeLogo = (orgId) => {
  return Axios.put("/api/RemoveOrganizationLogo/"+orgId , undefined, undefined)
}
const addDivision = (data) => {
  return Axios.post("/api/OrganizationBranch/" , data, undefined)
}
const getDivisions = (args) => {
  let _orgId = args.orgId;
  delete args.orgId;
  return Axios.get("/api/OrganizationBranches/"+_orgId+"?search=" + encodeURIComponent(JSON.stringify(args)) , undefined, undefined)
}
const getDivisionsTree = (orgId) => {
  return Axios.get("/api/OrganizationHierarchy/"+orgId, undefined, undefined)
}
const getDivision = (divisionId) => {
  return Axios.get("/api/OrganizationBranch/"+divisionId, undefined, undefined)
}
const updateDivision = (divisionId, data) => {
  return Axios.put("/api/OrganizationBranch/"+divisionId , data, undefined)
}
const getOrganizationEmployees = (args) => {
  
  let _orgId = args.orgId;
  delete args.orgId;
  return Axios.get("/api/OrganizationEmployees/"+_orgId+"?search=" + encodeURIComponent(JSON.stringify(args)) , undefined, undefined)
}
const getBillableOrganizations = (orgId) => {
  return Axios.get("/api/BillableOrganizations/"+orgId , undefined, undefined)
}
const getActiveThemes = () => {
  return Axios.get("/api/ActiveThemes/", undefined, undefined)
}

const getOrgUsersList = (id) => {
  return Axios.get("/api/GetOrganizationUsersList/ "+id, undefined, undefined);
};

const ExportOrganizationUsersList = (orgId) => {
  return Axios.get("/api/ExportOrganizationUsersList/" + orgId, undefined);
};

const getOrganizationUsersList = (id) => {
  return Axios.get("/api/OrganizationUsersList/"+id, undefined, undefined);
};

export default {
  getOrganizationTypes,
  getOrganizationType,
  updateOrganizationType,
  deleteOrganizationType,
  getOrganizations,
  getOrganization,
  updateOrganization,
  deleteOrganization,
  addOrganizations,
  getPccList,
  PCCAccountList,
  getOrganizationById,
  getOrganizationProducts,
  getOrganizationLicenses,
  getOrganizationLicensesByProduct,
  assignLicense,
  unassignLicense,
  updateOrganizationById,
  getLicenseLogs,
  addorderinOrganization,
  getLicenseCount,
  removeLogo,
  addDivision,
  getDivisions,
  getDivisionsTree,
  getDivision,
  updateDivision,
  getOrganizationEmployees,
  getBillableOrganizations,
  getActiveThemes,
  getOrgUsersList,
  ExportOrganizationUsersList,
  getOrganizationUsersList
};
