import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as RolesActions from "../../../redux/actions/roles-actions";
import IntlMessages from "../../../services/intlMesseges";
import {
  Table,
  Icon,
  Input,
  Button,
  Row,
  Col,
  Typography,
  Tag,
  Divider
} from "antd";
import { EditCell } from "../../shared/components/helperCells";

class ManageRoles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      data: [],
      pagination: { pageSize: 10, current: 1 },
      filters: {}
    };
  }

  getHeaderKeys = () => {
    return [
      {
        title: <IntlMessages id="admin.roles.name" />,
        dataIndex: "name"
        //...this.getColumnSearchProps("name"),
        // width: "30%"
      },
      {
        title: <IntlMessages id="admin.userlisting.roleType" />,
        //width: "30%",
        render: (text, record) =>
          record.type === 1 ? "Focus Point" : "Organization"
      },
      {
        title: <IntlMessages id="admin.roles.status" />,
        dataIndex: "isActive",
        //width: "25%",
        render: (text, record) => {
          return record.isActive === false ? (
            <Tag color="red">Inactive</Tag>
          ) : (
            <Tag color="green">Active</Tag>
          );
        }
      },
      {
        title: <IntlMessages id="admin.roles.action" />,
        rowKey: "action",
        //width: "15%",
        render: (text, record) => (
          <span>
            <EditCell
              onEditCell={() => {
                this.editItem(record.id);
              }}
            />
            {/*
            <DeleteCell
              onDeleteCell={() => {
                this.deleteItem(record.id);
              }}
            />*/}
          </span>
        )
      }
    ];
  };

  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => {
      return (
        <div style={{ padding: 8 }}>
          <Input
            ref={node => {
              this.searchInput = node;
            }}
            value={selectedKeys[0]}
            onChange={e =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Button
            type="primary"
            onClick={() =>
              this.handleSearch(selectedKeys, confirm, dataIndex, clearFilters)
            }
            icon="search"
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters, dataIndex)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </div>
      );
    },
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    }
  });

  handleSearch = (selectedKeys, confirm, dataIndex, clearFilters) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
    let filters = this.state.filters;
    filters[dataIndex] = {
      val: selectedKeys[0],
      clearf: clearFilters,
      filter: true
    };
    this.setState({ filters: filters });
  };

  handleReset = (clearFilters, dataIndex) => {
    clearFilters();
    let filters = this.state.filters;
    filters[dataIndex] = { val: "", clearf: "", filter: false };
    this.setState({ filters: filters });
    this.setState({ searchText: "" });
  };
  async componentDidMount() {
    this.handleTableChange({ current: 1, pageSize: 10 }, {}, {});
  }

  deleteItem = id => {
    this.setState({ loading: true });
    this.props
      .deleteRole({ id: id })
      .then(item => {
        this.setState({ loading: false });
        this.props.history.push("./");
      })
      .catch(err => {
        console.log(err);
        this.setState({ loading: false });
      });
  };

  editItem = id => {
    this.props.history.push("./" + id);
  };

  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({ loading: true });
    this.props
      .getRoles({
        filters: filters,
        pagination: { page: pagination.current },
        sorter: sorter
      })
      .then(resp => {
        pager.total = resp.paging.totalRecords;
        this.setState({
          loading: false,
          data: resp.data,
          pagination: pager
        });
      })
      .catch(ex => {
        this.setState({ loading: false });
      });
  };

  render() {
    let _state = this.state;
    let _this = this;
    let filtertag = Object.keys(this.state.filters).map(function(key1) {
      if (_state.filters[key1].filter) {
        return (
          <Button
            type="primary"
            onClick={() => _this.handleReset(_state.filters[key1].clearf, key1)}
          >
            {key1} : {_state.filters[key1].val}
            <Icon type="close" />
          </Button>
        );
      }
    });
    return (
      <div>
        <Row gutter={24}>
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography.Title level={4}>
              <IntlMessages id="admin.roles.manage" />
            </Typography.Title>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <Button
              type="primary"
              onClick={() => this.editItem("new")}
              className="right-fl def-blue"
            >
              <IntlMessages id="admin.roles.new" />
            </Button>
          </Col>
        </Row>
        <Divider style={{ margin: "0 0 10px 0" }} />
        <Row gutter={24} style={{ marginBottom: "20px" }}>
          <Col xs={24}>{filtertag}</Col>
        </Row>
        <Row gutter={24}>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={24}
            style={{ overflowX: "auto" }}
          >
            <Table
              columns={this.getHeaderKeys()}
              rowKey={record => record.id}
              dataSource={this.state.data}
              pagination={this.state.pagination}
              loading={this.state.loading}
              onChange={this.handleTableChange}
              bordered
            />
          </Col>
        </Row>
      </div>
    );
  }
}

ManageRoles.propTypes = {
  location: PropTypes.object,
  getRoles: PropTypes.func,
  deleteRole: PropTypes.func
};
function mapStateToProps(state) {
  return {
    ...state.user
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...RolesActions }, dispatch);
}
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    ManageRoles
  )
);
