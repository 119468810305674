import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import IntlMessages from "../../../../../../app/services/intlMesseges";
import {
  Popover,
  Typography,
  Table,
  Input,
  Button,
  Icon,
  Row,
  Col,
  Tooltip,
} from "antd";
import * as OrgActions from "../../../../../redux/actions/organization-actions";
import * as Permission from "../../../../../services/permissions";
import * as paginationActions from "../../../../../redux/actions/pagination-actions";
import * as B2B2COrgActions from "../../../../../redux/actions/b2b2c-organization-actions";
class OrganizationB2b2cLicenseList extends Component {
  constructor(props) {
    super(props);
    this.module = "licenses";
    this.state = {
      organizationName: null,
      allTotalLicenses: 0,
      allAssignedLicenses: 0,
      licenseTotals: [],
      searchText: "",
      dataLoaded: false,
      loading: false,
      data: [],
      pagination: {
        pageSize: 10,
        current: 1,
        hideOnSinglePage: true,
        showTotal: (total, range) => {
          return (
            <span>
              <IntlMessages id="admin.text.showing" /> {range[0]}-{range[1]}{" "}
              <IntlMessages id="admin.text.of" /> {total}{" "}
              <IntlMessages id="admin.text.results" />
            </span>
          );
        },
      },
      filters: {},
    };
  }

  getSelectedFilterValue = (index) => {
    return (
      (this.props.paginginfo[this.module] &&
        this.props.paginginfo[this.module].filter &&
        this.props.paginginfo[this.module].filter[index]) ||
      null
    );
  };

  async componentDidMount() {
    //if(this.state.dataLoaded === false) {
    this.props
      .getOrganizationId(this.props.match.params.id)
      .then((resp) => {
        if (resp.data) {
          this.setState({ organizationName: resp.data.name });
        }
      })
      .catch((_ex) => {
        this.setState({ loading: false });
      });

    /* this.handleTableChange(
        this.props.match.params.id,
        { current: 1, pageSize: 10 },
        {},
        {}
      ); */
    if (
      this.props.paginginfo &&
      this.props.currentModule !== "" &&
      this.props.currentModule !== this.module
    ) {
      this.props.clearPaginationExceptMe(this.module);
    } else {
      if (this.props.paginginfo && this.props.paginginfo[this.module]) {
        this.handleTableChange(
          this.props.match.params.id,
          this.props.paginginfo[this.module].pagination,
          this.props.paginginfo[this.module].filter,
          {},
          true
        );
        if (this.props.paginginfo[this.module].filters) {
          let filters = this.props.paginginfo[this.module].filters;
          Object.keys(filters).map((k) => {
            filters[k].auto = false;
          });
          this.setState({ filters: filters });
        }
      } else {
        this.handleTableChange(
          this.props.match.params.id,
          { current: 1, pageSize: 10 },
          {},
          {},
          true
        );
      }
    }
    this.props
      .getLicenseCount(this.props.match.params.id)
      .then((response) => {
        let _allTotalLicenses = 0,
          _allAssignedLicenses = 0;
        if (response.data && response.data.length) {
          response.data.map(function(el) {
            _allTotalLicenses += el.totalLicenses;
            _allAssignedLicenses += el.usedLicenses;
          });
        }
        this.setState({
          allTotalLicenses: _allTotalLicenses,
          allAssignedLicenses: _allAssignedLicenses,
          licenseTotals: response.data,
        });
      })
      .catch((e) => {
        console.log(e);
      });
    this.setState({
      parentOrg: this.props.match.params.orgid
        ? this.props.match.params.orgid
        : this.props.match.params.id,
      dataLoaded: true,
    });
    //}
  }

  getHeaderKeys = () => {
    return [
      {
        title: <IntlMessages id="admin.org.licenselist.licenseNumber" />,
        dataIndex: "licenseNumber",
        //width: "30%",
        filteredValue: this.getSelectedFilterValue("licenseNumber"),
        ...this.getColumnSearchProps("licenseNumber"),
      },
      {
        title: <IntlMessages id="admin.org.licenselist.product" />,
        dataIndex: "product",
        //width: "30%",
        filteredValue: this.getSelectedFilterValue("product"),
        ...this.getColumnSearchProps("product"),
      },
      {
        title: <IntlMessages id="admin.invoice.companyname" />,
        dataIndex: "organizationName",
        filteredValue: this.getSelectedFilterValue("organizationName"),
        ...this.getColumnSearchProps("organizationName"),
        render: (_text, record) => (
          <span>
            {record.organizationName != null ? record.organizationName : "---"}
          </span>
        ),
      },
      {
        title: <IntlMessages id="admin.division.name" />,
        dataIndex: "divisionName",
        filteredValue: this.getSelectedFilterValue("divisionName"),
        ...this.getColumnSearchProps("divisionName"),
        render: (_text, record) => (
          <span>
            {record.divisionName != null ? record.divisionName : "---"}
          </span>
        ),
      },
      {
        title: <IntlMessages id="admin.org.licenselist.assignedUser" />,
        dataIndex: "assignedUser",
        //width: "30%",
        filteredValue: this.getSelectedFilterValue("assignedUser"),
        ...this.getColumnSearchProps("assignedUser"),
        render: (_text, record) => (
          <span>
            {record.assignedUser != null ? record.assignedUser : "N/A"}
          </span>
        ),
      },
      {
        title: <IntlMessages id="admin.org.licenselist.assignedUserEmail" />,
        dataIndex: "assignedUserEmail",
        //width: "30%",
        filteredValue: this.getSelectedFilterValue("assignedUserEmail"),
        ...this.getColumnSearchProps("assignedUserEmail"),
        render: (_text, record) => (
          <span>
            {record.assignedUserEmail != null
              ? record.assignedUserEmail
              : "N/A"}
          </span>
        ),
      },
      {
        title: <IntlMessages id="admin.invoice.status" />,
        dataIndex: "status",
        filteredValue: this.getSelectedFilterValue("status"),
        ...this.getColumnSearchProps("status"),
        render: (_text, record) => (
          <span>{record.status != null ? record.status : "N/A"}</span>
        ),
      },
      {
        title: <IntlMessages id="admin.tablelist.actions" />,
        width: "8%",
        render: (_text, record) => (
          <span>
            <button
              className="icon"
              onClick={() => {
                this.viewLicense(record.licenseId);
              }}
            >
              <Tooltip title="View">
                <i className="fas fa-eye"></i>
              </Tooltip>
            </button>
          </span>
        ),
      },
    ];
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <div style={{ padding: 8 }}>
          <Input
            ref={(node) => {
              this.searchInput = node;
            }}
            value={selectedKeys}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? e.target.value.trimLeft() : "")
            }
            onPressEnter={() =>
              this.handleSearch(
                selectedKeys,
                confirm,
                dataIndex,
                clearFilters,
                setSelectedKeys
              )
            }
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Button
            type="primary"
            onClick={() =>
              this.handleSearch(
                selectedKeys,
                confirm,
                dataIndex,
                clearFilters,
                setSelectedKeys
              )
            }
            disabled={
              selectedKeys != "" && selectedKeys !== null ? false : true
            }
            icon="search"
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            <IntlMessages id="admin.search" />
          </Button>
          <Button
            disabled={
              selectedKeys != "" && selectedKeys !== null ? false : true
            }
            onClick={() => this.handleReset(clearFilters, dataIndex)}
            size="small"
            style={{ width: 90 }}
          >
            <IntlMessages id="admin.reset" />
          </Button>
        </div>
      );
    },
    filterIcon: (filtered) => (
      <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
  });

  handleSearch = (
    selectedKeys,
    confirm,
    dataIndex,
    clearFilters,
    setSelectedKeys
  ) => {
    if (selectedKeys == "") {
      return false;
    }

    this.setState({ searchText: selectedKeys });
    let filters = this.state.filters;
    filters[dataIndex] = {
      val: selectedKeys,
      clearf: clearFilters,
      filter: true,
      setSelectedKeys: setSelectedKeys,
      confirm: confirm,
      auto: true,
    };
    this.setState({ filters: filters });
    this.props.updateFilters({ module: this.module, filters: filters });
    confirm();
  };

  handleReset = (clearFilters, dataIndex) => {
    clearFilters();
    let filters = this.state.filters;
    if (filters[dataIndex]) {
      if (
        filters[dataIndex].setSelectedKeys &&
        typeof filters[dataIndex].setSelectedKeys === "function"
      ) {
        filters[dataIndex].setSelectedKeys("");
        //filters[dataIndex].confirm();
      }
    }
    if (filters[dataIndex] && !filters[dataIndex].auto) {
      delete this.props.paginginfo[this.module].filter[dataIndex];
      this.handleTableChange(
        { current: 1, pageSize: 10 },
        this.props.paginginfo[this.module].filter,
        {}
      );
    }
    filters[dataIndex] = { val: "", clearf: "", filter: false };
    this.setState({ filters: filters });
    this.props.updateFilters({ module: this.module, filters: filters });
    this.setState({ searchText: "" });
  };

  viewLicense = (id) => {
    this.props.history.push("./" + id);
  };

  tableChange = (pagination, filters, sorter) => {
    let currentOrg = this.props.match.params.id
      ? this.props.match.params.id
      : this.props.match.params.orgid;
    this.handleTableChange(currentOrg, pagination, filters, sorter);
  };

  handleTableChange = (orgId, pagination, filters, sorter, manual) => {
    if (filters === undefined) filters = {};
    Object.keys(filters).map((key) => {
      if (
        !filters[key] ||
        (Array.isArray(filters[key]) && filters[key].length === 0)
      ) {
        delete filters[key];
      }
    });
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    if (manual !== true) {
      this.props.updatePaginationRemoveOld({
        module: this.module,
        filter: filters,
        pagination: {
          current: pagination.current,
          pageSize: pagination.pageSize,
        },
      });
    }
    this.setState({ loading: true });
    this.props
      .getOrganizationLicenses({
        orgId: orgId ? orgId : this.props.match.params.id,
        filters: filters,
        pagination: { page: pagination.current, pageSize: pagination.pageSize },
        sorter: sorter,
      })
      .then((resp) => {
        pager.total = resp.paging.totalRecords;
        this.setState({
          loading: false,
          data: resp.data,
          pagination: pager,
        });
      })
      .catch((_ex) => {
        this.setState({ loading: false });
      });
  };

  onChangeOrganizationTree = (selected) => {
    this.handleTableChange(selected, { current: 1, pageSize: 10 }, {}, {});
  };

  render() {
    let _state = this.state;
    let _this = this;
    let filtertag = Object.keys(this.state.filters).map(function(key1) {
      let keyLabel = _this.getHeaderKeys().find((el) => el.dataIndex === key1);
      if (keyLabel.title.props && keyLabel.title.props.id) {
        keyLabel = <IntlMessages id={keyLabel.title.props.id} />;
      } else {
        keyLabel = key1;
      }
      if (_state.filters[key1].filter) {
        return (
          <span>
            <Button
              key={key1}
              type="primary"
              onClick={() =>
                _this.handleReset(_state.filters[key1].clearf, key1)
              }
            >
              {keyLabel} : {_state.filters[key1].val}
              <Icon type="close" />
            </Button>
            &nbsp;
          </span>
        );
      }
    });

    return (
      <div>
        <Row gutter={24}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Typography.Title level={4}>
              <IntlMessages id="admin.employeesList.manage.plans" />{" "}
              {this.state.organizationName !== null
                ? " (" + this.state.organizationName + ")"
                : ""}
            </Typography.Title>
          </Col>
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={12}
            style={{ textAlign: "right" }}
          >
            <IntlMessages id="admin.employeesList.plans.used" />
            :&nbsp;
            {this.state.licenseTotals ? (
              <Popover
                placement="bottomRight"
                content={
                  this.state.licenseTotals
                    ? this.state.licenseTotals.map((el) => (
                        <div style={{ fontSize: "16px" }}>
                          {el.product}:&nbsp;
                          <strong>
                            {el.usedLicenses} / {el.totalLicenses}
                          </strong>
                        </div>
                      ))
                    : ""
                }
                trigger="hover"
              >
                <strong>
                  {this.state.allAssignedLicenses} /{" "}
                  {this.state.allTotalLicenses}
                </strong>
              </Popover>
            ) : (
              ""
            )}
          </Col>
        </Row>
        <hr />
        <Row gutter={24} style={{ marginBottom: "20px" }}>
          <Col xs={24} sm={24} md={16} lg={16} xl={16}>
            {filtertag}
          </Col>
          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            <ul className="upload-xlx">
              <li>
                <Button
                  type="primary"
                  className=""
                  htmlType="button"
                  onClick={() => {
                    if (Permission.isRole("Division Manager")) {
                      this.props.history.push("../../");
                    } else if (Permission.isRole("Travel Manager") || window.location.pathname === "/divisions/" + this.props.match.params.divid) {
                      if(window.location.pathname.includes('/user/organizations/edit')){
                        this.props.history.push("../../../");
                      }else{
                        this.props.history.push("../../");
                      }
                    }
                     else {
                      this.props.history.push("../../../");
                    }
                  }}
                >
                  <IntlMessages id="admin.userlisting.back" />
                </Button>
              </li>
            </ul>
          </Col>
        </Row>

        {/* <Row gutter={24} style={{paddingBottom: '20px'}}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <div style={{float: "left", paddingTop: "5px"}}>
              <IntlMessages id="admin.organization.company"/>:&nbsp;
            </div>
            <div style={{float: "left", minWidth: "320px"}}>
              <OrgTree parent={this.state.parentOrg} organizationId={this.props.match.params.orgid ?this.props.match.params.orgid : this.props.match.params.id } onChange={this.onChangeOrganizationTree}/>
            </div>
          </Col>
        </Row> */}

        <Table
          columns={this.getHeaderKeys()}
          rowKey={(record) => record.licenseId}
          dataSource={this.state.data}
          pagination={this.state.pagination}
          loading={this.state.loading}
          onChange={this.tableChange}
          scroll={{ x: "100%" }}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    ...state.user,
    ...state.pagination,
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...OrgActions, ...paginationActions, ...B2B2COrgActions }, dispatch);
}
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    OrganizationB2b2cLicenseList
  )
);
