//import { Types } from "../constants/user-types";
import { Types as NotificaionTypes } from "../constants/notification-types";
import { notifyUser } from "../../services/notification-service";
import API from "../api/org-plan-types-api";

//organization plan types
export function getPlanTypes({ filters, pagination }) {
    return async function (dispatch, getState) {
        try {
            let response = await API.getPlanTypes({
                filters: filters,
                pagination: pagination
            });
            return response;
        } catch (e) {
            return { error: true };
        }
    };
}

export function getPlanType(data) {
    return async function (dispatch, getState) {
        try {
            let resp = await API.getPlanType(data);
            return resp;
        } catch (e) {
            return { error: true };
        }
    };
}

export function getGuardMeProducts() {
    return async function (dispatch, getState) {
        try {
            let resp = await API.getGuardMeProducts();
            return resp;
        } catch (e) {
            return { error: true };
        }
    };
}

export function updatePlanType(data) {
    return async function (dispatch, getState) {
        try {
            let resp = await API.updatePlanType(data);
            dispatch({
                type: NotificaionTypes.API_SUCCESS,
                payload: {
                    code: 200,
                    message: data.id > 0 ? "Item Updated" : "Item Added.",
                    type: "primary",
                    timeout: 3000,
                    id: Math.floor(Math.random() * 10000)
                }
            });
        } catch (e) {
            return { error: true };
        }
    };
}
