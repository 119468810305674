import React, { Component } from "react";
export default class FooterB2B2C extends Component {
   render() {
      let { resellerOrganizationInfo } = this.props;
      let footerText = null; 
      let isFooterVisible = true;
      if(resellerOrganizationInfo.b2B2COrgSettings && Object.keys(resellerOrganizationInfo.b2B2COrgSettings).length > 0){
         resellerOrganizationInfo = resellerOrganizationInfo.b2B2COrgSettings;
         if(resellerOrganizationInfo.footerText && resellerOrganizationInfo.footerText !== null){
            footerText =  resellerOrganizationInfo.footerText;
         }
         if(typeof resellerOrganizationInfo.isFooterVisible !== "undefined" && resellerOrganizationInfo.isFooterVisible !== null){
            isFooterVisible =  resellerOrganizationInfo.isFooterVisible;
         }
      }
      return ( isFooterVisible ?
            <div>
               <div className="reseller-footer">
                  <div className="container">
                     <div className="row">
                        <div className="copyright-text text-center" style={{ width: '100%', fontStyle: "italic" }}>
                           <div>
                              {footerText !== null ? footerText : ["Powered by FocusPoint International, Inc. © 2021",<br/>,"All Rights Reserved."]}
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         : '');
   }
}