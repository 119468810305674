import API from '../api/notification-api'
import {
  Types
} from "../constants/user-types";
import { Types as NotificationTypes } from "../constants/notification-types";
export function getNotificationsList(data) {
  return async function(dispatch, _getState) {
    try {
        let countryAndTierInfo = await API.getNotifications(data);
        return countryAndTierInfo;

    } catch (e) {
      return { "error": true };
    }
  };
}
export function updateNotificationStatus(data) {
  return async function(dispatch, _getState) {
    try {
        let countryAndTierInfo = await API.updateNotificationStatus(data);
        return countryAndTierInfo;

    } catch (e) {
      return { "error": true };
    }
  };
}
export function getNotificationsCount(userId) {
  return async function(_dispatch, _getState) {
    try {
        let notificationsCount = await API.getNotificationsCount(userId);
        _dispatch({
          type: Types.UPDATE_NOTIFICATION_COUNT,
          payload: (notificationsCount.data) ? notificationsCount.data.unreadNotificationCount : 0
        });
        return (notificationsCount.data) ? notificationsCount.data.unreadNotificationCount : 0;

    } catch (e) {
    return { "error": true };
    }
  };
}

export function updateNotifcationCount(cnt) {
  return async function(_dispatch, _getState) {
        _dispatch({
          type: Types.INC_NOTIFICATION_COUNT,
          payload: cnt
        });
        
  };
}

export function registerFCMDevice(userId, args) {
  return async function(dispatch, _getState) {
    let response = await API.registerFCMDevice(userId, args);
    if(response.data && response.data.success === true){
      dispatch({
        type: NotificationTypes.FCM_DEVICE_ADDED,
        payload: {
          status: true,
          token: args.uniqueId
        }
      });
    }
  };
}