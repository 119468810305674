import Axios from "../../services/axios-service";

const getAssetTracking = (data, companyId) => {
    return Axios.get("/api/InterstedGeos/AssetTrackings/" + companyId + "?search=" + encodeURIComponent(JSON.stringify(data)), undefined)
}

const getAssetTrackingById = (id) => {
    return Axios.get("/api/InterstedGeos/AssetTracking/" + id, undefined)
}

const addAssetTracking = (data) => {
    return Axios.post("/api/InterstedGeos/AddAssetTracking", data, undefined);
}

const updateAssetTracking = (data, id) => {
    return Axios.put("/api/InterstedGeos/AssetTracking/" + id, data, undefined);
}

export default {
    getAssetTracking,
    getAssetTrackingById,
    addAssetTracking,
    updateAssetTracking
}