import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { CardElement } from "react-stripe-elements";
import * as b2cActions from "../../../../redux/actions/b2c-actions";
import { message, Modal, Tooltip } from "antd";
import config from "../../../../../../config";

const { confirm } = Modal;
let resizeTimer;

class _CardForm extends Component {
  constructor(props) {
    super(props);
    this.howHeardRef = React.createRef();
    this.state = {
      cardNumber: "",
      howHeard: "",
      couponCode: "",
      howHeardInput: "",
      howHeardInputLabel: "",
      showHowHeardInput: false,
      confirmOrder: false,
      appliedPromoText: "",
      promoResponseText: "",
      howheardError:''
    };
    this.handleClick = this.handleClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleHowHeardChange = this.handleHowHeardChange.bind(this);
    this.applyPromo = this.applyPromo.bind(this);
    this.handleHowHeardInputChange = this.handleHowHeardInputChange.bind(this);
    this.handleConfirmOrderCheck = this.handleConfirmOrderCheck.bind(this);
    this.showConfirm = this.showConfirm.bind(this);
    this.removePromo = this.removePromo.bind(this);
  }

  handleConfirmOrderCheck(evt) {
    this.setState({ confirmOrder: evt.currentTarget.checked });
  }

  handleHowHeardInputChange(evt) {
    this.setState({ howHeardInput: evt.currentTarget.value });
  }

  async applyPromo(evt) {
    if (this.state.couponCode !== "") {
      await this.props.applyPromoCode(this.state.couponCode);
      if (this.props.isValidCoupon) {
        message.success("Promo code applied Successfully!");
        var appliedPromoText =
          "Promo code " + this.props.couponInfo.code + " is applied!";
        this.setState({
          appliedPromoText: appliedPromoText,
          promoResponseText: ""
        });
      } else {
        this.setState({
          appliedPromoText: "",
          promoResponseText: "Invalid promo code!"
        });
        message.error("Promo code is not valid!");
      }
      // this.props.updatePrice();
    }
    this.setState({ couponCode: "" });
  }

  async removePromo(evt) {
    await this.props.removePromoCode();
    // this.props.updatePrice();
    this.setState({ couponCode: "", appliedPromoText: "" });
  }

  handleHowHeardChange(evt) {
    if (
      ["Referral", "Other"].includes(
        evt.currentTarget.selectedOptions[0].getAttribute("data-value")
      )
    ) {
      var labelText =
        evt.currentTarget.selectedOptions[0].getAttribute("data-value") ===
          "Referral"
          ? "Please specify name/source"
          : "Please specify";
      this.setState({ showHowHeardInput: true, howHeardInputLabel: labelText });
    } else {
      this.setState({ showHowHeardInput: false, howHeardInput: "" });
    }
    this.setState({
      howHeard: evt.currentTarget.selectedOptions[0].getAttribute("data-value")
    });
    evt.currentTarget.blur();
this.setState({howheardError:''})
  }

  mouseleaveHowHeardChange(evt) {
    evt.currentTarget.blur();
  }

  handleChange(evt) {
    if (evt.currentTarget.dataset) {
      var tempCouponCode = evt.currentTarget.value;
      this.setState({ couponCode: tempCouponCode });
    }
  }

  async componentDidMount() {
    window.addEventListener("resize", this.updateSelectDrop);
    if (
      this.props.personalDetails &&
      Object.keys(this.props.personalDetails).length === 0
    ) {
      await this.props.getPersonalInformation();
      await this.props.getPlanTermInformation();
      await this.props.getPlanInformation();
      await this.props.getBillingInformation();
    }
    await this.props.getAppliedPromo();
    if (this.props.couponInfo && this.props.couponInfo.promoOfferId) {
      var appliedPromoText =
        "Promo code " + this.props.couponInfo.code + " is applied!";
      this.setState({
        appliedPromoText: appliedPromoText
      });
    }
    window.scrollTo(0, 0);
    // this.props.updatePrice();
    if (
      this.props.billingInformation &&
      this.props.billingInformation.paymnet_token
    ) {
      this.setState({ confirmOrder: true });
    }
  }

  updateSelectDrop = () => {
    let self = this;
    if (
      self.howHeardRef &&
      self.howHeardRef.current &&
      self.howHeardRef.current !== null
    ) {
      clearTimeout(resizeTimer);
      resizeTimer = setTimeout(function() {
        self.howHeardRef.current.blur();
      }, 10);
    }
  };

  async handleClick(evt) {
    if (evt.currentTarget.dataset && evt.currentTarget.dataset.value) {
      if (evt.currentTarget.dataset.value === "back") {
        await this.props.SaveStep("3");
        this.props.history.push(this.props.b2cBaseUrl+ "/personal-details");
      } else {
        if (this.state.howHeard && this.state.howHeard !== '' && this.state.howHeard !== 'Select an option') {
          this.setState({howheardError:''})
          if (this.state.confirmOrder) {
            this.checkForvalidPayment();
          } else {
            var objContent = {};
            objContent.title = "Acknowledge required!";
            objContent.content =
              "Please acknowledge plan rules and regulations first.";
            this.showConfirm(objContent);
          }
        }else {
          this.setState({howheardError:"Please select an option"})

        }
      }
    }
  }

  showConfirm(objContent, index) {
    confirm({
      title: objContent.title,
      content: objContent.content,
      className: "gray-color",
      onOk() {},
      onCancel() {}
    });
  }

  checkForvalidPayment() {
    var self = this;
    this.props.stripe.createToken().then(function(payload) {
      if (payload.token) {
        self.saveDataAndNext(payload.token.id);
      } else {
        var objContent = {};
        objContent.title = "Invalid card detail!";
        objContent.content = "Please enter valid card details.";
        self.showConfirm(objContent);
      }
    });
  }

  async saveDataAndNext(token) {
    let billingInfoObject = {
      coupon_code: this.state.couponCode,
      how_heard: this.state.howHeard,
      how_heard_other: (this.state.howHeardInput) ? this.state.howHeardInput : null,
      paymnet_token: token
    };
    await this.props.SaveStep("4");
    await this.props.SaveBillingInfornation(billingInfoObject);
    this.props.history.push(this.props.b2cBaseUrl + "/purchase-summary");

  }

  _bindHowHeard() {
    var def = ['Select an option']
    var howHeardOptions = def.concat(this.props.howHeardOptions);

    return howHeardOptions.map((option, index) => (

      <option key={index} data-value={option}>
        {option}
      </option>
    ));
  }

  onPromoEnter = e => {
    e.target.value = e.target.value.toUpperCase();
  };
  render() {
    return (
      <div>
        <div className="wrap">
          <div className="billing-info">
            {/* title  */}
            <div className="row">
              <div className="full-width">
                <h3>Billing Information</h3>
              </div>
            </div>
            {/* title ends */}
            {/* credit card */}

            <div className="row">
              <div className="full-width">
                <label>
                  <span>*</span> Credit or debit card (Visa, MasterCard,
                  American Express, JCB, Discover, and Diners Club)
                </label>
                <CardElement hidePostalCode={true}/>
                <span className="disclaimer">
                  Your credit card data will be securely transmitted and
                  processed. FocusPoint complies with PCI-DSS SAQ A.
                </span>
              </div>
            </div>
            {/*credit card ends */}
            {/* coupon code hear about us */}
            <div className="row" style={{ marginTop: "25px" }}>
              <div className="half-width">
                <label>Promo Code</label>
                <input
                  type="text"
                  className="coupon-input"
                  style={{ padding: "0 10px" }}
                  data-category="coupon"
                  value={this.state.couponCode}
                  onChange={this.handleChange}
                  onInput={this.onPromoEnter}
                />
                <button className="coupon-but" onClick={this.applyPromo}>
                  Apply
                </button>
                {this.state.appliedPromoText !== "" ? (
                  <span
                    style={{
                      borderBottom: "1px solid gray",
                      paddingBottom: "5px"
                    }}
                  >
                    {this.state.appliedPromoText}{" "}
                    <a onClick={this.removePromo}>
                      <Tooltip title="Remove Coupon Code">
                        <i className="fas fa-times"></i>
                      </Tooltip>
                    </a>
                  </span>
                ) : (
                    ""
                  )}
                {this.state.promoResponseText !== "" ? (
                  <span
                    style={{
                      paddingBottom: "5px",
                      color:
                        this.state.promoResponseText.indexOf("Invalid") > -1
                          ? "#cb0019"
                          : "green"
                    }}
                  >
                    {this.state.promoResponseText}
                  </span>
                ) : (
                    ""
                  )}
              </div>
              <div className="half-width">
                <label><span>* </span>How did you hear about us?</label>
                <div className="select">
                  <div className="select-style">
                    <select
                      ref={this.howHeardRef}
                      onChange={this.handleHowHeardChange}
                    >
                      {this._bindHowHeard()}
                    </select>
                    
                  </div>
                  <div
                    className={this.state.showHowHeardInput ? "show" : "hide"}
                  >
                    <label>{this.state.howHeardInputLabel}</label>
                    <input
                      type="text"
                      style={{ padding: "0 10px" }}
                      value={this.state.howHeardInput}
                      onChange={this.handleHowHeardInputChange}
                    />
                  </div>
                </div>
                <span style={{color:"red"}}>{this.state.howheardError}</span>
              </div>
            </div>
            {/* coupon code ends hear about us ends */}
            {/*  Terms and */}
            <div className="row">
              <div className="full-width">
                <div className="checkbox-in">
                  <label className="container">
                    By submitting, you acknowledge that you have read and agree
                    to the terms and conditions of the&nbsp;
                    <a
                      target="_blank"
                      href=  {config.API1 +"/rules/CAP-eCommerce-Rules-and-Regulations-v15.1.pdf"}
                      style={{ textDecoration: "underline" }}
                    >
                      CAP <br />
                      Plan Rules & Regulations
                    </a>
                    <span className="red">*</span>
                    <input
                      type="checkbox"
                      checked={this.state.confirmOrder}
                      onChange={this.handleConfirmOrderCheck}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
              </div>
            </div>
            {/* Terms and */}
          </div>
        </div>
        {/* billing information ends */}
        {/* next and back button */}
        <div className="next-back">
          <div className="wrap">
            <div className="row">
              <div className="upgrade">
                {/* upgrade plans */}
                {/* <UpgradePlan /> */}
                {/* upgrade plans ends */}
              </div>
              <div className="next-box">
                <button
                  data-value="back"
                  className="back"
                  onClick={this.handleClick}
                >
                  Back
                </button>
                <button
                  data-value="next"
                  className="next"
                  onClick={this.handleClick}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

_CardForm.propTypes = {
  SavePlanTerm: PropTypes.func,
  applyPromoCode: PropTypes.func,
  getPlanInformation: PropTypes.func,
  getPlanTermInformation: PropTypes.func,
  getPersonalInformation: PropTypes.func,
  SaveStep: PropTypes.func,
  SaveBillingInfornation: PropTypes.func,
  step: PropTypes.string,
  howHeardOptions: PropTypes.array,
  planTerm: PropTypes.object,
  isValidCoupon: PropTypes.bool,
  couponInfo: PropTypes.object,
  removePromoCode: PropTypes.func,
  getBillingInformation: PropTypes.func,
  b2cBaseUrl:PropTypes.string

};
function mapStateToProps(state) {
  return {
    ...state.b2c
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...b2cActions }, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true
})(_CardForm);
