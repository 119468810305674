import React, { Component } from "react";
import { Chart, Geom, Tooltip } from "bizcharts";
import { Card } from 'antd';

export default class ProductGraph extends Component {
  render() {
		const scale = {
				date: {
						tickCount: 10,
						type: "time",
						mask: "YYYY"
					}
				};
    return (
        <Card title="">
					<label style={{fontSize:"22px", color: "#3b3b3b", fontWeight: "600"}}>
            {this.props.name} {this.props.productData && this.props.productData.length > 0 ? <span style={{ fontSize: 18 }}>({this.props.productData.reduce(function(tot, arr) { return tot + arr["Total Sale"]; },0)})</span> : <span style={{ fontSize: 18 }}>(0)</span>}
          </label> 
          <Chart
          className="graphchart"
            height={100}
            padding={[5, 0, 0, 0]}
            data={this.props.productData}
            scale={scale}
            forceFit
          >
            <Tooltip crosshairs />
            {/* <Axis />
            <Legend /> */}
            <Geom type="area" position="Date*Total Sale" shape="smooth" />
            <Geom
              type="area"
              position="Date*Total Sale"
              shape="smooth"
              size={2}
            />
          </Chart>
      </Card>
    );
  }
}