import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Table, Checkbox } from "antd";

class AssetTrackingList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false
    };
  }

  getHeaderKeys = () => {
    return [
      {
        title: "Title",
        dataIndex: "title",
        render: (text, record) => (
          <a onClick={() => this.handleAssetTrackingClick(record)}>
            {record.title}
          </a>
        )
      },
      {
        title: "City",
        dataIndex: "city",
        render: (text, record) =>
          record.city ? record.city : "Unnamed location"
      },
      {
        title: "State",
        dataIndex: "state",
        render: (text, record) =>
          record.state ? record.state : "Unnamed location"
      },
      {
        title: "Organization",
        dataIndex: "orgName"
      }
    ];
  };

  handleAssetTrackingClick(record) {
    this.props.handleAssetTrackingClick(record);
  }

  componentDidMount() {}

  render() {
    return (
      <div className="table-less">
        <Table
          pagination={{ defaultPageSize: 5 }}
          columns={this.getHeaderKeys()}
          dataSource={this.props.assetTrackingList}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    AssetTrackingList
  )
);
