import React from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import IntlMessages from "../../../services/intlMesseges";
import * as RolesActions from "../../../redux/actions/roles-actions";
import {
  Typography,
  Form,
  Input,
  Select,
  Button,
  Row,
  Col,
  Spin,
  Switch,
} from "antd";

const { Option } = Select;

class EditRole extends React.Component {
  state = {
    loading: false,
    confirmDirty: false,
    id: "",
    name: "",
    isActive: true,
    type: 0,
  };

  async componentDidMount() {
    if (
      this.props.match &&
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.id &&
      this.props.match.params.id !== "new"
    ) {
      this.setState({ loading: true, id: this.props.match.params.id });
      const role = await this.props.getRole(this.props.match.params.id);
      //this.props.form.setFieldsValue(role);
      this.setState(role);
      this.setState({ loading: false });
    }
  }

  onSwitchChange = (checked, e) => {
    const { name } = e.target;
    this.setState({ [name]: checked });
  };

  submitFormAdd = (data) => {
    console.log("Adding:", data);
    /* this.props
      .addRole(data)
      .then(item => {
        this.setState({ loading: false });
        this.props.history.push("./");
      })
      .catch(err => {
        console.log(err);
        this.setState({ loading: false });
      }); */
  };

  submitFormEdit = (data) => {
    this.props
      .updateRole(data)
      .then((item) => {
        this.props.history.push("./");
        this.setState({ loading: false });
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        Object.keys(values).map(function(key, _idx) {
          if (values[key] == null) {
            values[key] = "";
          } else {
            if (typeof values[key] == "string") {
              values[key] = values[key].trim();
            }
          }
          return values;
        });
        this.setState({ loading: true });
        if (this.props.match.params.id !== "new") {
          values["id"] = this.state.id;
          values["isActive"] = this.state.isActive;
          this.submitFormEdit(values);
        } else {
          values["isActive"] = this.state.isActive;
          this.submitFormAdd(values);
        }
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { formLayout } = this.state;
    const formItemLayout =
      formLayout === "horizontal"
        ? {
            labelCol: { span: 4 },
            wrapperCol: { span: 14 },
          }
        : null;
    const rolesSelector = getFieldDecorator("type", {
      rules: [
        {
          required: true,
          message: <IntlMessages id="admin.input.required" />,
        },
      ],
      initialValue: this.state.type > 0 ? this.state.type : null,
    })(
      <Select placeholder="-select-">
        <Option key={1} value={1}>
          Focus Point
        </Option>
        <Option key={2} value={2}>
          Organization
        </Option>
      </Select>
    );
    return (
      <div>
        <Row gutter={24}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Typography.Title level={4}>
              {this.state.id === 0 ? (
                <IntlMessages id="admin.roles.add" />
              ) : (
                <div>
                  <IntlMessages id="admin.roles.edit" />
                </div>
              )}
            </Typography.Title>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Button
              type=""
              className="right-fl def-blue"
              htmlType="button"
              onClick={() => this.props.history.push("./")}
            >
              <IntlMessages id="admin.button.back" />
            </Button>
          </Col>
        </Row>
        <hr />
        <div>
          <Spin spinning={this.state.loading}>
            <Form layout={formLayout} onSubmit={this.handleSubmit}>
              <Row gutter={24}>
                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                  <Form.Item
                    {...formItemLayout}
                    label={<IntlMessages id="admin.roles.name" />}
                  >
                    {getFieldDecorator("name", {
                      rules: [
                        {
                          required: true,
                          message: <IntlMessages id="admin.input.required" />,
                        },
                      ],
                      initialValue:
                        this.state.name === null ? "" : this.state.name,
                    })(<Input />)}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                  <Form.Item
                    {...formItemLayout}
                    label={<IntlMessages id="admin.userlisting.roleType" />}
                  >
                    {rolesSelector}
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                  <Form.Item
                    {...formItemLayout}
                    label={<IntlMessages id="admin.roles.status" />}
                  >
                    <Switch
                      checkedChildren="Active"
                      unCheckedChildren="Inactive"
                      checked={this.state.isActive}
                      onChange={this.onSwitchChange}
                      name="isActive"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Item>
                    {/* <Button
                      type="default"
                      className=""
                      htmlType="button"
                      onClick={() => this.props.history.push("./")}
                    >
                      <IntlMessages id="admin.roles.back" />
                    </Button>
                    &nbsp;&nbsp; */}
                    <Button
                      type="primary"
                      style={{ display: "inline-block", marginRight: "10px" }}
                      className="def-blue"
                      htmlType="submit"
                    >
                      {this.state.id !== "" ? (
                        <IntlMessages id="admin.roles.update" />
                      ) : (
                        <IntlMessages id="admin.userlisting.add" />
                      )}
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Spin>
        </div>
      </div>
    );
  }
}

EditRole.propTypes = {
  location: PropTypes.object,
  updateRole: PropTypes.func,
  getRole: PropTypes.func,
};

function mapStateToProps(state) {
  return {};
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...RolesActions }, dispatch);
}
const WrappedForm = Form.create({ name: "edit-role" })(EditRole);
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    WrappedForm
  )
);
