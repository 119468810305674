import API from "../api/travelers-itinerary-api";

export function getTravelersItineraries() {
  return async function(dispatch, getState) {
    try {
      let resp = await API.getTravelersItineraries();
      return resp;
    } catch (e) {
      return { error: true };
    }
  };
}

export function getItinerarySummary(data) {
  return async function(dispatch, getState) {
    try {
      let resp = await API.getItinerarySummary(data);
      return resp;
    } catch (e) {
      return { error: true };
    }
  };
}

export function getTravelersItinerariesByOrgId(orgId, userRole, data) {
  return async function(dispatch, getState) {
    try {
      let resp = await API.getTravelersItinerariesByOrgId(
        orgId,
        userRole,
        data
      );
      return resp;
    } catch (e) {
      return { error: true };
    }
  };
}

export function getItineraryByPnrIdAndEmail(data) {
  return async function(dispatch, getState) {
    try {
      let resp = await API.getItineraryByPnrIdAndEmail(data);
      return resp;
    } catch (e) {
      return { error: true };
    }
  };
}

export function getManualItineraryByPnrIdAndEmail(data) {
  return async function(dispatch, getState) {
    try {
      let resp = await API.getManualItineraryByPnrIdAndEmail(data);
      return resp;
    } catch (e) {
      return { error: true };
    }
  };
}
