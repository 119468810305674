import React, { Component } from "react";
export default class LogoSvgb2c extends Component {
  render() {
    return (
      <svg version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 59.9 37">
        <g>
          <polygon
            className="st0"
            points="52.2,4.9 43,6.6 9.4,12.6 17.9,18 23.9,31.1 28.7,26.6 23.8,21.8 35.5,29  "
          />
          <g>
            <polygon
              className="st1"
              points="18.2,16 23.3,29 24.3,25.6 23.8,21.8 41.2,13.9 51,5.9 50,5.6 23.3,13.1   "
            />
            <polygon
              className="st2"
              points="52.2,4.9 43,6.6 43,6.6 9.4,12.6 17.9,18 23.9,31.1 28.7,26.6 23.8,21.8 23.5,27.6 19.1,17.6     50.1,6.1 23.8,21.8 35.5,29   "
            />
          </g>
        </g>
      </svg>
    );
  }
}
