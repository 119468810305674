import React, { Component } from 'react';
import { withRouter,Redirect } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as UserActions from "../../../redux/actions/user-actions";
import * as UserService from "../../../services/user-service";
import { notifyUser } from "../../../services/notification-service";
import moment from 'moment';
import { Spin } from 'antd';
class ReloadId extends Component {
  constructor(props) {
    super(props);
    this.state = {
        pageLoaded: false,
        redirectTo: ''
      };
  }

  async componentDidMount() {
    if(this.state.pageLoaded === false){
      this.setState({pageLoaded: true});
      if(this.props.match.params.pathKey == "newplan"){
        await this.props.myAccount().then(response => {
          if(response.data){
            let currentUser = response.data;
            let errors = [];
            if(currentUser.contactNo == "" || currentUser.contactNo === null){
              errors.push("Please update your mobile number!");
            }
            if(currentUser.countryId <= 0 || currentUser.countryId === null){
              errors.push("Please update your country!");
            }
            if(currentUser.email == "" || currentUser.email === null){
              errors.push("Please update your email address!");
            }
            if(currentUser.dateOfBirth != "" && currentUser.dateOfBirth !== null){
              var years = moment().diff(currentUser.dateOfBirth, 'years');
              if(years < 18){
                errors.push("You are not eligible to buy a plan because you are under 18 years!");
              }
            }
            if(errors.length > 0){
              errors.map(function(error){
                notifyUser(error, 'error');
              });
              this.setState({redirectTo: "/user/my-profile/" });
            } else {
              if(typeof currentUser.orgType !== "undefined" && currentUser.orgType === "B2B2C"){
                this.setState({redirectTo: `/partner/purchase/${currentUser.organizationId}` });
              } else {
                window.location.href = "https://www.captravelassistance.com/";
              }
            }
          } else {
            this.setState({redirectTo: "/login" });
          }
        });
        /* let url = this.props.history.location.pathname;
        url = url.split('/').filter(function (el) {
            return el != null && el != '';
          });
        this.setState({redirectTo: "/"+url[0]+"/"}); */
      }
    }
  }

  render(){
    let currentUser = UserService.getUser();
    if(this.props.match.params.pathKey == "division"){
      return (<Redirect to={{ pathname: "../../"+currentUser.companyID+"/divisions/" }} />)
    }
    if(this.props.match.params.pathKey == "asset-organization"){
      return (<Redirect to={{ pathname: "../../../"+currentUser.companyID+"/" }} />)
    }
    if(this.props.match.params.pathKey == "geofence-organization"){
      return (<Redirect to={{ pathname: "../../../"+currentUser.companyID+"/" }} />)
    }
    if(this.props.match.params.pathKey == "itinerary"){
      return (<Redirect to={{ pathname: "../"+currentUser.companyID+"/" }} />)
    }
    if(this.props.match.params.pathKey == "invoices"){
      return (<Redirect to={{ pathname: "../../"+currentUser.companyID+"/" }} />)
    }
    if(this.props.match.path === "/b2c"/*  || this.props.match.path.indexOf('dashboard') > -1 */){
      return (<Redirect to={{ pathname: "/cap/purchase" }} />)
    }
    if(this.state.redirectTo != ''){
      return (<Redirect to={{ pathname: this.state.redirectTo }} />)
    }
    return (
      <div>
        <Spin tip="Please wait..."></Spin>
      </div>
    );
  }


 
}
function mapStateToProps(state) {
  return {  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...UserActions }, dispatch);
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(ReloadId));