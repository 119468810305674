import API from "../api/tiers-api";

export function getTiersListing({ filters, pagination, sorter }) {
  return async function (dispatch, getState) {
    try {
      let resp = await API.getTiersList({
        filters: filters,
        pagination: pagination,
        sorter: sorter
      });
      return resp;
    } catch (e) {
      return { "error": true };
    }
  };
}

export function getTiers(idx) {
 
  return async function (dispatch, getState) {
    try {
      let resp = await API.getTiersbyId(idx);
     
      return resp;
    } catch (e) {
      return { "error": true };
    }
  };
}
export function updateTiersdata(data,id) {
  return async function(dispatch, getState) {
    try {
      let resp;
      if (id ===0){
        resp = await API.addTiers(data);
      }else {
        resp = await API.updateTiers(data,id)
      }
      return resp;
    } catch (e) {
        return {"error" : true};
     
    }
  };
}