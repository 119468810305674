import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import IntlMessages from "../../../../services/intlMesseges";
import { Table, Icon, Input, Button, Row, Col, Typography } from "antd";
import { ViewCell } from "../../../shared/components/helperCells";
import * as orderActions from "../../../../redux/actions/order-actions";
import OrgTree from "../../../shared/components/orgTree";

import moment from "moment";
const dateFormathh = "MM/DD/YYYY HH:mm:ss";
const dateFormat = "MM/DD/YYYY";

class OrganizationOrderHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ordersLoaded: false,
      loading: false,
      isLoading: true,
      currentPlanName: null,
      data: [],
      pagination: {
        pageSize: 10,
        current: 1,
        hideOnSinglePage: true,
        showTotal: (total, range) => {
          return <span>
            <IntlMessages id="admin.text.showing" /> {range[0]}-{range[1]} <IntlMessages id="admin.text.of" /> {total} <IntlMessages id="admin.text.results" />
          </span>
        }
      },
      filters: {}
    }
  };

  formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2
  });

  getHeaderKeys = () => {
    return [
      {
        title: <IntlMessages id='admin.manageOrders.orderNumber' />,
        dataIndex: 'orderNumber',
        ...this.getColumnSearchProps('orderNumber')
        //width: '15%'
      },
      {
        title: <IntlMessages id='admin.manageOrders.ProductName' />,
        dataIndex: 'productName',
        ...this.getColumnSearchProps('productName')
        //width: '15%'
      },
      {
        title: <IntlMessages id='admin.organizations.list.name' />,
        dataIndex: 'companyName',
        ...this.getColumnSearchProps('companyName')
        //width: '20%'
      },
      {
        title: <IntlMessages id='admin.division.name' />,
        dataIndex: 'divisionName',
        ...this.getColumnSearchProps('divisionName')
      },
      {
        title: <IntlMessages id='admin.invoice.billto' />,
        dataIndex: 'billingOrganization',
        ...this.getColumnSearchProps('billingOrganization')
        //width: '20%'
      },
      /* {
        title: <IntlMessages id="admin.manageOrders.membershipNumber" />,
        dataIndex: "membershipNumber",
        ...this.getColumnSearchProps("membershipNumber"),
        width: '20%'
      },
      {
        title: <IntlMessages id="admin.deal.price" />,
        dataIndex: "quantity",
        width: '10%'
      }, */
      {
        title: <IntlMessages id='admin.manageOrders.discountedAmount' />,
        //width: '10%',
        render: (_text, record) => (
          <span>
            {' '}
            {this.formatter.format(record.totalOrderAmount)} {record.currency}
          </span>
        )
      },
      {
        title: <IntlMessages id='admin.travelmanager.purchasedate' />,
        //width: '10%',
        render: (_text, record) => (
          <span title={moment(record.insertDate).format(dateFormathh)}>
            {moment(record.insertDate).format(dateFormat)}
          </span>
        )
      },
      {
        title: <IntlMessages id='admin.tablelist.actions' />,
        rowKey: 'action',
        //width: '10%',
        render: (_text, record) => (
          <span>
            <ViewCell
              onViewCell={() => {
                this.viewItem(record.orderId);
              }}
            />
          </span>
        )
      }
    ];
  };

  async componentDidMount() {
    //if (this.state.ordersLoaded === false) {
      this.setState({
        ordersLoaded: true
      });
      this.handleTableChange({ page: 1, pageSize: 10 }, {}, {});
    //}
  }

  viewItem = id => {
    /* if (Permission.isRole("Division Manager")) {
      this.props.history.push("../../../order-history/" + id);

    } else { */
      this.props.history.push("" + id );

    /* } */
  };

  loadOrderHistory = (orgId, pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current || 1;
    this.setState({ loading: true });
    this.props
      .getOrders({
        orgId: orgId,
        filters: filters,
        pagination: { page: pagination.current || 1, pageSize: pagination.pageSize || 10 },
        sorter: sorter
      })
      .then(resp => {
        pager.total = resp.paging.totalRecords;
        this.setState({
          loading: false,
          data: resp,
          pagination: pager
        });
        if (resp.data[0].membershipNumber) {
          this.setState({ currentPlanName: resp.data[0].membershipNumber });
        }
      })
      .catch(ex => {
        this.setState({ loading: false });
      });
  }

  handleTableChange = (pagination, filters, sorter) => {
    let orgId = (this.props.match.params.id) ? this.props.match.params.id : ((this.props.match.params.divid) ? this.props.match.params.divid : this.props.match.params.orgid);
    this.loadOrderHistory(orgId, pagination, filters, sorter);
  };

  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => {
      return (
        <div style={{ padding: 8 }}>
          <Input
            ref={node => {
              this.searchInput = node;
            }}
            value={selectedKeys}
            onChange={e =>
              setSelectedKeys(e.target.value ? e.target.value.trimLeft() : "")
            }
            onPressEnter={() =>
              this.handleSearch(
                selectedKeys,
                confirm,
                dataIndex,
                clearFilters,
                setSelectedKeys
              )
            }
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Button
            type="primary"
            onClick={() =>
              this.handleSearch(
                selectedKeys,
                confirm,
                dataIndex,
                clearFilters,
                setSelectedKeys
              )
            }
            disabled={(selectedKeys != "" && selectedKeys !== null) ? false : true}
            icon="search"
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            <IntlMessages id="admin.search" />
          </Button>
          <Button
            disabled={(selectedKeys != "" && selectedKeys !== null) ? false : true}
            onClick={() => this.handleReset(clearFilters, dataIndex)}
            size="small"
            style={{ width: 90 }}
          >
            <IntlMessages id="admin.reset" />
          </Button>
        </div>
      );
    },
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    }
  });

  handleSearch = (
    selectedKeys,
    confirm,
    dataIndex,
    clearFilters,
    setSelectedKeys
  ) => {
    if (selectedKeys === "") {
      return false;
    }

    this.setState({ searchText: selectedKeys });
    let filters = this.state.filters;
    filters[dataIndex] = {
      val: selectedKeys,
      clearf: clearFilters,
      filter: true,
      setSelectedKeys: setSelectedKeys,
      confirm: confirm
    };
    this.setState({ filters: filters });
    confirm();
  };

  handleReset = (clearFilters, dataIndex) => {
    clearFilters();
    let filters = this.state.filters;
    if (filters[dataIndex]) {
      if(filters[dataIndex].setSelectedKeys && typeof filters[dataIndex].setSelectedKeys === 'function'){
        filters[dataIndex].setSelectedKeys("");
        filters[dataIndex].confirm();
      }
    }
    filters[dataIndex] = { val: "", clearf: "", filter: false };
    this.setState({ filters: filters });
    this.setState({ searchText: "" });
  };

  onChangeOrganizationTree = (selected) => {
    this.loadOrderHistory(selected, { page: 1, pageSize: 10 }, {}, {});
    /* if(selected == this.props.match.params.orgid){
      this.props.history.push("../../../../"+selected+"/order-history/");
    } else {
      if(this.props.match.params.divid > 0){
        this.props.history.push("../../"+selected+"/order-history/");
      } else {
        this.props.history.push('../../' +this.props.match.params.orgid + '/divisions/'+selected+'/order-history/');
      }
    } */
  }

  render() {
    let _state = this.state;
    let _this = this;
    let filtertag = Object.keys(this.state.filters).map(function (key1) {
      let keyLabel = _this.getHeaderKeys().find(el => el.dataIndex === key1);
      if (keyLabel.title.props && keyLabel.title.props.id) {
        keyLabel = <IntlMessages id={keyLabel.title.props.id} />;
      } else {
        keyLabel = key1;
      }
      if (_state.filters[key1].filter) {
        return (
          <span style={{ marginBottom: '7px', display: 'inline-block'}} >
            <Button
              key={key1}
              type="primary"
              onClick={() => _this.handleReset(_state.filters[key1].clearf, key1)}
            >
              {keyLabel} : {_state.filters[key1].val}
              <Icon type="close" />
            </Button>&nbsp;
            </span>
        );
      }
    });
    
    return (
      <div>
        <Row gutter={24}>
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography.Title level={4}>
              <IntlMessages id="admin.org.orderhistory.title" />{this.state.currentPlanName !== null ?
                (" (" + this.state.currentPlanName + ")")
                :
                ""
              }
            </Typography.Title>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <Button
              type="primary"
              className="right-fl"
              htmlType="button"
              onClick={() => this.props.history.push('../../')}
            >
              <IntlMessages id="admin.userlisting.back" />
            </Button>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col>{filtertag}</Col>
        </Row>
        {(this.props.match.params.orgid || this.props.match.params.id)/*  && this.state.loading === false */ ?
          <Row gutter={24} style={{ paddingBottom: '20px' }}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <div style={{ float: "left", paddingTop: "5px" }}>
                <IntlMessages id="admin.organization.company" />:&nbsp;
              </div>
              <div style={{ float: "left", minWidth: "320px" }}>
                <OrgTree organizationId={(this.props.match.params.id) ? this.props.match.params.id : ((this.props.match.params.divid) ? this.props.match.params.divid : this.props.match.params.orgid)} onChange={this.onChangeOrganizationTree} />
              </div>
            </Col>
          </Row>
          :
          ""
        }
        <Row>
          <Col>
            <Table
              columns={this.getHeaderKeys()}
              rowKey={record => record.orderId}
              dataSource={this.state.data.data}
              pagination={this.state.pagination}
              loading={this.state.loading}
              onChange={this.handleTableChange}
              scroll={{ x: "100%" }}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    ...state.user
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...orderActions }, dispatch);
}
export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    { forwardRef: true }
  )(OrganizationOrderHistory)
); 