import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Row, Col, Select, Menu, Switch, Button, Checkbox } from "antd";

import moment from "moment";
const dateFormat = "DD MMM YYYY";
const { Option } = Select;
let riskLevel = {
  Low: "#00A651",
  Moderate: "#85C441",
  Medium: "#D6D625",
  High: "#F7963E",
  Extreme: "#CA252B"
};

class AlertFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      defaultCountry: "All",
      defaultCategory: "All",
      selectedRiskLevel: "",
      selectedDuration: ""
    };
  }
  async componentDidMount() {}

  handleCountryChange = code => {
    this.setState({ defaultCountry: code });
    this.props.countryChange(code);
  };
  handleCategoryChange = catname => {
    this.setState({ defaultCategory: catname });
    this.props.categoryChange(catname);
  };
  handleRiskLevelChange = risklevel => {
    this.setState({ selectedRiskLevel: risklevel.key });
    this.props.riskLevelChange(risklevel);
  };
  handleDurationChange = time => {
    this.setState({ selectedDuration: time.key });
    this.props.durationChange(time);
  };
  handleShowNoticesChange = checked => {
    this.props.showNoticesChange(checked);
  };
  update = e => {
    this.props.onChangeAlertCheckBox(e);
  };
  handleResetAlertsFilter = () => {
    this.setState({
      defaultCountry: "All",
      defaultCategory: "All",
      selectedRiskLevel: "",
      selectedDuration: ""
    });
    this.props.onResetAlertsFilter();
  };

  handleSingleAlertListClick = alert => {
    this.props.handleSingleAlertListClick(alert);
  };

  render() {
    return (
      <div>
        <Row
          gutter={24}
          style={{ marginBottom: "10px" }}
          type="flex"
          align="middle"
        >
          <Col xs={24} sm={24} md={12} lg={6}>
            Select Country
            <Select
              showSearch
              style={{ width: "100%" }}
              placeholder="Select a Country"
              optionFilterProp="children"
              onChange={this.handleCountryChange}
              value={this.state.defaultCountry}
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option key="all">All</Option>
              {this.props.countriesList.map(c => (
                <Option key={c.code}>{c.countryName}</Option>
              ))}
            </Select>
          </Col>
          <Col xs={24} sm={24} md={12} lg={6}>
            Select Category
            <Select
              showSearch
              style={{ width: "100%" }}
              placeholder="Select Category"
              optionFilterProp="children"
              onChange={this.handleCategoryChange}
              value={this.state.defaultCategory}
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option key="all">All</Option>
              {this.props.categoryList.map(c => (
                <Option key={c.categoryName}>{c.categoryName}</Option>
              ))}
            </Select>
          </Col>
          <Col style={{ marginTop: "18px" }}>
            <Checkbox
              name="alert"
              defaultChecked={this.props.isAlert}
              style={{
                display: "inline-block",
                verticalAlign: "middle",
                marginRight: "1px"
              }}
              onChange={this.update}
            />
            <span
              style={{
                display: "inline-block",
                verticalAlign: "middle",
                marginLeft: "4px"
              }}
            >
              Alerts
            </span>
          </Col>
          <Col style={{ textAlign: "right", marginTop: "18px" }}>
            <Switch
              style={{
                display: "inline-block",
                verticalAlign: "middle",
                marginRight: "1px"
              }}
              onChange={this.handleShowNoticesChange}
            />
            <span
              style={{
                display: "inline-block",
                verticalAlign: "middle",
                marginLeft: "4px"
              }}
            >
              Show notices
            </span>
          </Col>
          <Button
            type="primary"
            onClick={this.handleResetAlertsFilter}
            style={{ marginTop: "18px", marginLeft: "10px" }}
          >
            Reset
          </Button>
        </Row>
        <Menu
          theme="dark"
          style={{ marginTop: "10px" }}
          selectedKeys={[this.state.selectedRiskLevel]}
          onClick={this.handleRiskLevelChange}
          mode="horizontal"
        >
          <Menu.Item key="Low" style={{ width: "20%", textAlign: "center" }}>
            Low
          </Menu.Item>
          <Menu.Item
            key="Moderate"
            style={{ width: "20%", textAlign: "center" }}
          >
            Moderate
          </Menu.Item>
          <Menu.Item key="Medium" style={{ width: "20%", textAlign: "center" }}>
            Medium
          </Menu.Item>
          <Menu.Item key="High" style={{ width: "20%", textAlign: "center" }}>
            High
          </Menu.Item>
          <Menu.Item
            key="Extreme"
            style={{ width: "20%", textAlign: "center" }}
          >
            Extreme
          </Menu.Item>
        </Menu>
        <Menu
          theme="dark"
          style={{ marginTop: "10px" }}
          selectedKeys={[this.state.selectedDuration]}
          onClick={this.handleDurationChange}
          mode="horizontal"
        >
          <Menu.Item key="1d" style={{ width: "14.28%", textAlign: "center" }}>
            1d
          </Menu.Item>
          <Menu.Item key="2d" style={{ width: "14.28%", textAlign: "center" }}>
            2d
          </Menu.Item>
          <Menu.Item key="3d" style={{ width: "14.28%", textAlign: "center" }}>
            3d
          </Menu.Item>
          <Menu.Item key="1w" style={{ width: "14.28%", textAlign: "center" }}>
            1w
          </Menu.Item>
          <Menu.Item key="2w" style={{ width: "14.28%", textAlign: "center" }}>
            2w
          </Menu.Item>
          <Menu.Item key="3w" style={{ width: "14.28%", textAlign: "center" }}>
            3w
          </Menu.Item>
          <Menu.Item key="1m" style={{ width: "14.28%", textAlign: "center" }}>
            1m
          </Menu.Item>
        </Menu>
        <div
          style={{
            height: "40vh",
            overflowY: "scroll",
            overflowX: "hidden",
            padding: "0 20px"
          }}
        >
          <Row gutter={24}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              {this.props.stores.map((alert, index) => (
                <div
                  style={{
                    margin: "10px 0",
                    padding: "20px 12px",
                    borderBottom: "1px solid #d9d9d9",
                    cursor: "pointer"
                  }}
                  onClick={() => this.handleSingleAlertListClick(alert)}
                >
                  <div className="country-name">
                    <img
                      src={alert.flag_url}
                      alt="map"
                      style={{
                        borderRadius: "100%",
                        width: "25px",
                        height: "25px",
                        float: "left",
                        marginRight: "15px"
                      }}
                    />
                    <strong
                      style={{
                        display: "inline-block",
                        verticalAlign: "middle"
                      }}
                    >
                      {alert.countryName}
                    </strong>
                  </div>
                  <div
                    className="risk-details"
                    style={{ padding: "5px 0", marginLeft: "40px" }}
                  >
                    {alert.title}
                  </div>
                  <div
                    className="date-time"
                    style={{ padding: "5px 0", marginLeft: "40px" }}
                  >
                    <strong>
                      {alert.duration &&
                        moment(
                          new Date(
                            alert.duration.start_date * 1000
                          ).toUTCString()
                        ).format(dateFormat)}
                      {" - "}
                      {alert.duration &&
                        moment(
                          new Date(alert.duration.end_date * 1000).toUTCString()
                        ).format(dateFormat)}
                    </strong>
                  </div>
                  <div
                    className="risk-level"
                    style={{ marginLeft: "40px", padding: "5px 0" }}
                  >
                    <i
                      style={{
                        color: riskLevel[alert.risk_level],
                        marginRight: "10px"
                      }}
                      class="fas fa-circle"
                    ></i>
                    <i>{alert.risk_level} Risk</i>
                  </div>
                </div>
              ))}
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    AlertFilter
  )
);
