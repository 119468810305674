import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Row, Col, Tabs, Spin, Button, Modal, Select} from "antd";
import * as riskLineActions from "../../../redux/actions/risk-line-actions";
import IntlMessages from "../../../services/intlMesseges";
import Maps from "./map";
import Report from "./report";
import Cities from "./cities";
import Alerts from "./alerts";
import Advisories from "./advisories";
import Covid from "./covid";
import config from "../../../../../config";
import * as UserService from "../../../services/user-service";
import * as Permission from "../../../services/permissions";
const { Option } = Select;

const { TabPane } = Tabs;
const refs = {
  map: undefined,
  circle: undefined,
  polygon: undefined,
};

class CountriesInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      riskDataLevel: "risk_level",
      planExpiredVisible: false,
      countriesListByRegion: [],
      countryReport: "",
      coordinates: [],
      citiesList: [],
      riskLineAlerts: [],
      advisories: [],
      covidReport: "",
      mapdata: {
        loading: false,
        zoom: 7,
        isCircle: false,
        isPoly: false,
        isMarkerCluster: false,
        googleMapURL:
          "https://maps.googleapis.com/maps/api/js?key=AIzaSyAA_ayr9aMRSAPIugacjp_CL6S5ux_N4is&v=3.exp",
        loadingElement: <div style={{ height: `100%` }} />,
        containerElement: <div style={{ height: `600px` }} />,
        mapElement: <div style={{ height: `100%` }} />,
        center: { lat: 36.734895510543524, lng: -119.77812369355456 },
        onMapMounted: this.onMapMounted,
        onPolygonMounted: this.onPolygonMounted,
        childLoading: false,
        showCountryList: false,
      },
    };
    this.onPolygonMounted = this.onPolygonMounted.bind(this);
    this.onMapMounted = this.onMapMounted.bind(this);
    this.alertSearch = this.alertSearch.bind(this);
  }

  onMapMounted(ref) {
    refs.map = ref;
  }
  onPolygonMounted(ref) {
    refs.polygon = ref;
  }

  async componentDidMount() {
    let currentUser = UserService.getUser();
    if (
      (currentUser.role === "Membership" || currentUser.role === "Employee") &&
      currentUser.isActiveMembership !== true
    ) {
      this.setState({ planExpiredVisible: true });
    } else {
      if (
        this.props.match &&
        this.props.match &&
        this.props.match.params &&
        this.props.match.params.id &&
        this.props.match.params.id !== "new"
      ) {
        this.setState({ loading: true });
        this.props
          .getCountryReport({
            countryCode: this.props.match.params.id,
          })
          .then((resp) => {
            this.props
              .risklineAlerts({
                filters: {
                  days: "1d",
                  countries: this.props.match.params.id,
                  riskLevel: "",
                },
                pagination: { page: 1, pageSize: 1000 },
                sorter: {},
              })
              .then((riskAlerts) => {
                this.setState({
                  riskLineAlerts: riskAlerts.data,
                  loading: false,
                });
                if (resp) {
                   this.setState(
                    {
                      countryReport: resp,
                      riskDataLevel:resp.summary.risk_level,
                      loading: false,
                      showCountryList: false,
                    },
                    () => {
                      this._bindCordinates();
                    }
                  );
                }
              });
          })
          .catch((ex) => {
            this.setState({ loading: false });
          });
      }
    }  
  }

  tabClicked = (key, event) => {
    this.setState({ childLoading: true });
    if (key === "map") {
      this.setState({ childLoading: true });
      setTimeout(() => {
        if (refs.polygon) {
          let paths = refs.polygon.getPaths();
          const bounds = new window.google.maps.LatLngBounds();
          paths.forEach((path) => {
            path.getArray().forEach((arr) => {
              bounds.extend(arr);
            });
          });
          refs.map.panTo(bounds.getCenter());
          //  refs.map.fitBounds(bounds);
        }
      }, 500);
    }
    if (key === "cities") {
      this.props
        .getCitiesList({
          countryCode: this.state.countryReport.country_code,
        })
        .then((resp) => {
          if (resp) {
            this.setState({
              citiesList: resp.cities,
              childLoading: false,
            });
          }
        })
        .catch((ex) => {
          this.setState({ childLoading: false });
        });
    }
    if (key === "alerts") {
      this.setState({
        childLoading: false,
      });
    }

    if (key === "advisories") {
      this.setState({ childLoading: true });
      this.props
        .getAdvisories({
          countryCode: this.state.countryReport.country_code,
        })
        .then((resp) => {
          this.setState({
            advisories: resp.advisories,
            childLoading: false,
          });
        })
        .catch((ex) => {
          this.setState({ childLoading: false });
        });
    }
    // if (key === "covid") {
    //   this.props
    //     .getCovidData({
    //       countryCode: this.state.countryReport.country_code,
    //     })
    //     .then((resp) => {
    //       this.setState({
    //         covidReport: resp,
    //         childLoading: false,
    //       });
    //     })
    //     .catch((ex) => {
    //       this.setState({ childLoading: false });
    //     });
    // }
    if (key === "regions") {
      this.props
        .getCovidCountryRegion({
          countryCode: this.state.countryReport.country_code,
        })
        .then((resp) => {
          this.setState({
            covidReport: resp,
            childLoading: false,
          });
        })
        .catch((ex) => {
          this.setState({ childLoading: false });
        });
    }
  };

  _bindCordinates() {
    var singlePloygon = [];

    if (
      this.state.countryReport.geometries &&
      this.state.countryReport.geometries.length > 0
    ) {
      let geometries = this.state.countryReport.geometries;

      var tempCordinateArray = [];

      if (geometries[0].name === "Polygon") {
        singlePloygon = [];
        let cordinates = JSON.parse(geometries[0].coordinatesStr);
        if (coordinates) {
          cordinates = cordinates[0];
          if (cordinates) {
            for (var m = 0; m < cordinates.length; m++) {
              singlePloygon.push({
                lat: cordinates[m][1],
                lng: cordinates[m][0],
              });
            }
          }

          tempCordinateArray.push(singlePloygon);
        }
      } else {
        for (var i = 0; i < geometries.length; i++) {
          var coordinates = JSON.parse(geometries[i].coordinatesStr);
          if (coordinates) {
            for (var j = 0; j < coordinates.length; j++) {
              var coords = coordinates[j];
              for (var k = 0; k < coords.length; k++) {
                var coordsArray = coords[k];
                singlePloygon = [];
                for (var l = 0; l < coordsArray.length; l++) {
                  singlePloygon.push({
                    lat: coordsArray[l][1],
                    lng: coordsArray[l][0],
                  });
                }
                tempCordinateArray.push(singlePloygon);
              }
            }
          }
        }
      }
      this.setState({
        mapData: {
          ...this.state.mapdata,
          coordinates: tempCordinateArray,
          alerts: this.state.riskLineAlerts,
          isPoly: true,
          isMarkerCluster: true,
        },
      });
    } else {
      this.setState({
        mapData: {
          ...this.state.mapdata,
          coordinates: tempCordinateArray,
          alerts: this.state.riskLineAlerts,
        },
      });
    }
  }
  
  //Risk Type Selection
  riskTypeSelection = (e) => {
    let CounrtyRecords = this.state.countryReport.summary[e];
      this.setState({riskDataLevel :CounrtyRecords})
  }

  alertSearch(alerts) {
    this.setState({
      riskLineAlerts: alerts.data,
    });
  }

  advisorySearch = (advisories) => {
    this.setState({
      advisories: advisories,
    });
  };

  _showCountryDetails() {
    if (this.state.countryReport !== "") {
      return (
        <div>
          <Row gutter={24} style={{ marginBottom: "20px" }}>
            <Col
               xs={24}
               sm={24}
               md={12}
               lg={12}
               xl={12}
              className="countrydetail"
            >
              <img
                alt=""
                style={{
                  borderRadius: "100%",
                  width: "45px",
                  height: "45px",
                  float: "left",
                  marginRight: "20px",
                }}
                src={
                  config.API1 +
                  "/images/" +
                  this.state.countryReport.country_code +
                  ".png"
                }
              />
              <div style={{ float: "left", width: "85%" }}>
                <h6
                  style={{
                    paddingBottom: "0",
                    lineHeight: "45px",
                    marginBottom: "5px",
                  }}
                >
                  {this.state.countryReport.country_name}
                </h6>
              </div>
            </Col>
           
              <Col
               xs={24}
               sm={24}
               md={12}
               lg={12}
               xl={12}
              style={{ textAlign: "right", paddingTop: "15px" }}
            >
              
              <Select className="riskselection" style={{ width: 180 }} defaultValue="risk_level" onChange={(e)=>this.riskTypeSelection(e)}>
                   <Option  value="risk_level">Overall Risk Level</Option>
                   <Option  value="security_risk_level">Risk Security Level</Option>
                   {/* <Option  value="covid_risk_level">Risk Covid Level</Option> */}
              </Select> 
              <ul className="riskline">
                <li>
                  <b>
                    <IntlMessages id="admin.countries.advisories.lowrisk" />
                  </b>
                </li>
              </ul>
              <ul className="risklevel-ul risklevel">
              
                <li
                  className={
                     //this.state.riskDataLevel.toLowerCase()
                   (this.state.riskDataLevel != null && this.state.riskDataLevel != "") 
                    ? "risk_levelnp_" + this.state.riskDataLevel.toLowerCase() : "risk_levelnp_Low"
                  }
                ></li>
              </ul>
              <ul className="riskline">
                <li>
                  <b>
                    {" "}
                    <IntlMessages id="admin.countries.advisories.highrisk" />
                  </b>
                </li>
              </ul>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col>
              <div
                className="download-report"
                style={{
                  position: "absolute",
                  right: "15px",
                  zIndex: "100",
                  top: "0",
                }}
              >
                <Button
                  className="downloadBtn ant-btn-primary"
                  style={{
                    marginRight: "10px",
                    height: "32px",
                    verticalAlign: "top",
                    background: "#73c053",
                  }}
                  type="link"
                  icon="download"
                  size="large"
                  href={this.state.countryReport.pdf}
                >
                  <span style={{ marginTop: "-5px" }}>Download Report</span>
                </Button>
                <Button
                  type="primary"
                  className=""
                  htmlType="button"
                  onClick={() => this.props.history.push("./")}
                >
                  <IntlMessages id="admin.countries.advisories.goback" />
                </Button>
              </div>
              <Tabs onTabClick={this.tabClicked}>
                <TabPane
                  tab={
                    <span>
                      <i
                        style={{
                          fontSize: "20px",
                          display: "inline-block",
                          marginRight: "10px",
                          verticalAlign: "middle",
                        }}
                        className="fas fa-align-left"
                      ></i>
                      <IntlMessages id="admin.countries.advisories.report" />
                    </span>
                  }
                  key="report"
                >
                  <Report countryReport={this.state.countryReport} />
                </TabPane>
                <TabPane
                  tab={
                    <span>
                      <i
                        style={{
                          fontSize: "20px",
                          display: "inline-block",
                          marginRight: "10px",
                          verticalAlign: "middle",
                        }}
                        className="fas fa-map"
                      ></i>
                      <IntlMessages id="admin.countries.advisories.map" />
                    </span>
                  }
                  key="map"
                >
                  <Maps mapdata={this.state.mapData} mapriskdata = {this.state.countryReport} />
                </TabPane>
                <TabPane
                  tab={
                    <span>
                      <i
                        style={{
                          fontSize: "20px",
                          display: "inline-block",
                          marginRight: "10px",
                          verticalAlign: "middle",
                        }}
                        className="fas fa-bell"
                      ></i>
                      <IntlMessages id="admin.countries.advisories.alerts" />
                    </span>
                  }
                  key="alerts"
                >
                  <Spin size="large" spinning={this.state.childLoading}>
                    <Alerts
                      riskAlerts={this.state.riskLineAlerts}
                      countryCode={this.props.match.params.id}
                      onAlertSearch={this.alertSearch}
                    />
                  </Spin>
                </TabPane>
                <TabPane
                  tab={
                    <span>
                      <i
                        style={{
                          fontSize: "20px",
                          display: "inline-block",
                          marginRight: "10px",
                          verticalAlign: "middle",
                        }}
                        className="fas fa-map-signs"
                      ></i>
                      <IntlMessages id="admin.countries.advisories.Advisories" />
                    </span>
                  }
                  key="advisories"
                >
                  <Spin size="large" spinning={this.state.childLoading}>
                    <Advisories
                      advisories={this.state.advisories}
                      countryCode={this.props.match.params.id}
                      onAdvisorySearch={this.advisorySearch}
                    />
                  </Spin>
                </TabPane>
                <TabPane
                  tab={
                    <span>
                      <i
                        style={{
                          fontSize: "20px",
                          display: "inline-block",
                          marginRight: "10px",
                          verticalAlign: "middle",
                        }}
                        className="fas fa-city"
                      ></i>
                      <IntlMessages id="admin.countries.advisories.cities" />
                    </span>
                  }
                  key="cities"
                >
                  <Spin size="large" spinning={this.state.childLoading}>
                    <Cities citiesList={this.state.citiesList} />
                  </Spin>
                </TabPane>
                {/* <TabPane
                  tab={
                    <span>
                      <i
                        style={{
                          fontSize: "20px",
                          display: "inline-block",
                          marginRight: "10px",
                          verticalAlign: "middle",
                        }}
                        className="fas fa-virus" //change map icon
                      ></i>
                      <IntlMessages id="admin.countries.advisories.covid" />
                    </span>
                  }
                  key="covid"
                >
                   <Spin size="large" spinning={this.state.childLoading}>
                  <Covid
                    coviddata={this.state.covidReport}
                    countryCode={this.state.countryReport.country_code}
                  />
                  </Spin>
                </TabPane> */}
              </Tabs>
            </Col>
          </Row>
          <Row gutter={24} className="legend-row">
            <Col>
              <ul className="legend-risk">
                <li className="low">
                  <i className="fas fa-circle"></i>{" "}
                  <IntlMessages id="admin.countries.advisories.lowrisk" />
                </li>
                <li className="modrate">
                  <i className="fas fa-circle"></i>{" "}
                  <IntlMessages id="admin.countries.advisories.moderate" />
                </li>
                <li className="medium">
                  <i className="fas fa-circle"></i>{" "}
                  <IntlMessages id="admin.countries.advisories.medium" />
                </li>
                <li className="high">
                  <i className="fas fa-circle"></i>{" "}
                  <IntlMessages id="admin.countries.advisories.highrisk" />
                </li>
                <li className="extreme">
                  <i className="fas fa-circle"></i>{" "}
                  <IntlMessages id="admin.countries.advisories.extreme" />
                </li>
              </ul>
            </Col>
          </Row>
        </div>
      );
    }
  }

  render() {
    return (
      <div>
        <Modal
          title="Plan Expired."
          visible={this.state.planExpiredVisible}
          onOk={() => this.props.history.push("../../../cap/purchase")}
          onCancel={() => this.props.history.push("../../")}
          footer={
            Permission.isRole("Membership") ? (
              [
                <Button
                  key="cancel"
                  onClick={() => this.props.history.push("../../")}
                >
                  Cancel
                </Button>,
                <Button
                  key="submit"
                  onClick={() =>
                    this.props.history.push("../../../cap/purchase")
                  }
                >
                  Buy Now
                </Button>,
              ]
            ) : (
              <Button
                key="cancel"
                onClick={() => this.props.history.push("../../")}
              >
                Ok
              </Button>
            )
          }
        >
          {Permission.isRole("Employee") ? (
            <p>
              You do not have any CAP plan assigned. Please contact your
              division manager / travel manager.
            </p>
          ) : (
            <p>Your CAP plan has been expired. Please buy a new plan.</p>
          )}
        </Modal>
        <Spin size="large" spinning={this.state.loading}>
          <Row>
            <Col>{this._showCountryDetails()}</Col>
          </Row>
        </Spin>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...riskLineActions }, dispatch);
}
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    CountriesInfo
  )
);
