import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Table, Button, Icon, Card, Row } from "antd";
import { ComapnySvg, ComapnyListSvg } from "../../../shared/svg/svg/home-icon";
import * as OrgActions from "../../../../redux/actions/organization-actions";
import IntlMessages from "../../../../services/intlMesseges"
const ComapnySvgIcon = props => <Icon component={ComapnySvg} {...props} />;


class ListCompaines extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      data: []
    };
  }

  getHeaderKeys = () => {
    return [
      {
        title: <IntlMessages id="admin.dashboard.organizationname" />,
        dataIndex: "name"
      },
      {
        title: "",
        rowKey: "action",
        render: (text, record) => (
          <span>
            <Button
              className="btn-outline"
              onClick={() =>
                this.props.history.push(
                  "organizations/edit/" + record.organizationId
                )
              }
            >
              <IntlMessages id="admin.listcompanies.moreinfo" />
            </Button>
          </span>
        )
      }
    ];
  };

  async componentDidMount() {
    this.setState({ loading: true });

    this.props
      .getOrganizations({
        filters: {},
        pagination: { page: 1, pageSize: 5 },
        sorter: {}
      })
      .then(resp => {
        this.setState({
          loading: false,
          data: resp.data
        });
      })
      .catch(ex => {
        this.setState({ loading: false });
      });
  }

  render() {
    return (
      <div>
        <Card
          title={<IntlMessages id="admin.dashboard.listofcompanies"></IntlMessages>}
          bodyStyle={{ padding: 0 }}
          extra={<ComapnySvgIcon />}
        >
          <Table
            rowKey={record => record.name}
            pagination={false}
            columns={this.getHeaderKeys()}
            dataSource={this.state.data}
            loading={this.state.loading}
          />
          <Row type="flex" justify="end">
            <Button
              type="primary"
              className="addBtn"
              size={"large"}
              style={{ margin: " 24px 24px 24px 0px" }}
              onClick={() => this.props.history.push("organizations/")}
            >
              <IntlMessages id="admin.listcompanies.viewall" />
            </Button>
          </Row>
        </Card>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...OrgActions }, dispatch);
}
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    ListCompaines
  )
);
