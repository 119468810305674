import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import IntlMessages from "../../../../services/intlMesseges";
import {
  Table,
  Row,
  Col,
  Spin
} from "antd";

class DerivedPricingQuickView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDerivedRow: "block",
      selectedDerivedFrom: "-select-",
      loading: true,
      defaultOperator: "*",
      dataSource: [],
      columns: [],
      pagination: {
        hideOnSinglePage: true,
        showTotal: (total, range) => {
          return (
            <span>
              <IntlMessages id="admin.text.showing" /> {range[0]}-{range[1]}{" "}
              <IntlMessages id="admin.text.of" /> {total}{" "}
              <IntlMessages id="admin.text.results" />
            </span>
          );
        },
        pageSize: 10,
        current: 1
      }
    };
  }

  async componentDidMount() {
    let tempDataObj = {},
      tempDataArr = [],
      tempColumnsObj = {},
      tempColumnsArr = [];
    this.props.priceList.map(function(row, idx) {
      if (tempDataObj[row.tierId]) {
        tempDataObj[row.tierId]["day" + row.dayPackage] = parseFloat(
          Math.round(row.price * 100) / 100
        ).toFixed(2);
      } else {
        tempDataObj[row.tierId] = {};
        tempDataObj[row.tierId]["day" + row.dayPackage] = parseFloat(
          Math.round(row.price * 100) / 100
        ).toFixed(2);
      }
      tempDataObj[row.tierId].key = row.tierId;
      tempDataObj[row.tierId].tier = row.tierId;

      tempColumnsObj["tier"] = {};
      tempColumnsObj["tier"].title = "Tier";
      tempColumnsObj["tier"].dataIndex = "tier";
      // tempColumnsObj['tier'].fixed = 'left';
      //tempColumnsObj['tier'].width = 60;

      if (tempColumnsObj["day" + row.dayPackage]) {
        tempColumnsObj["day" + row.dayPackage].title = row.dayPackage + " days";
        tempColumnsObj["day" + row.dayPackage].dataIndex =
          "day" + row.dayPackage;
        tempColumnsObj["day" + row.dayPackage].editable = true;
        // tempColumnsObj['day'+row.dayPackage].width = 100;
      } else {
        tempColumnsObj["day" + row.dayPackage] = {};
        tempColumnsObj["day" + row.dayPackage].title = row.dayPackage + " days";
        tempColumnsObj["day" + row.dayPackage].dataIndex =
          "day" + row.dayPackage;
        tempColumnsObj["day" + row.dayPackage].editable = true;
        // tempColumnsObj['day'+row.dayPackage].width = 100;
      }
      return tempColumnsObj;
    });
    Object.keys(tempDataObj).forEach(prop => {
      if (tempDataObj[prop]) {
        tempDataArr.push(tempDataObj[prop]);
      }
    });
    Object.keys(tempColumnsObj).forEach(prop => {
      if (tempColumnsObj[prop]) {
        tempColumnsArr.push(tempColumnsObj[prop]);
      }
    });
    this.setState({
      dataSource: tempDataArr,
      columns: tempColumnsArr,
      loading: false
    });
  }

  render() {
    const { dataSource, columns } = this.state;
    return (
      <div>
        <Spin spinning={this.state.loading}>
          <Row gutter={24} className="pricing-table">
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={24}
              style={{ overflowX: "auto" }}
            >
              <Table columns={columns} pagination={this.state.pagination} bordered dataSource={dataSource} />
            </Col>
          </Row>
        </Spin>
      </div>
    );
  }
}
export default withRouter(
  connect(null, null, null, { forwardRef: true })(DerivedPricingQuickView)
);
