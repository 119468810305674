import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Table, Select, Modal, Popconfirm } from "antd";
import moment from "moment";
import * as assistTravelerActions from "../../../../redux/actions/assist-traveler-actions";
import * as Permission from "../../../../services/permissions";

const dateFormathh = "MM/DD/YYYY HH:mm:ss";
const dbDateFormat = "YYYY-MM-DD";
const { Option } = Select;
const { confirm } = Modal;
let statusArray = {
  0: "Pending",
  1: "Assisting",
  2: "Resolved"
};

class AssistTravelersList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false
    };
    this.onAssistStatusChange = this.onAssistStatusChange.bind(this);
  }

  getHeaderKeys = () => {
    return [
      {
        title: "Name",
        dataIndex: "userName",
        render: (text, record) => (
          <a onClick={() => this.handleAssistTravlerClick(record)}>
            {record.userName}
          </a>
        )
      },
      {
        title: "Contact No",
        dataIndex: "contactNumber"
      },
      {
        title: "City",
        dataIndex: "city",
        render: (text, record) =>
          record.city ? record.city : "Unnamed location"
      },
      {
        title: "State",
        dataIndex: "state",
        render: (text, record) =>
          record.state ? record.state : "Unnamed location"
      },
      {
        title: "Country",
        dataIndex: "country",
        render: (text, record) =>
          record.country ? record.country : "Unnamed location"
      },
      {
        title: "Organization",
        dataIndex: "organizationName"
      },
      {
        title: "Division",
        dataIndex: "divisionName"
      },
      {
        title: "Date",
        dataIndex: "creationDateTime",
        render: (text, record) => (
          <span>{moment(record.creationDateTime).format(dateFormathh)}</span>
        )
      },
      {
        title: "Status",
        dataIndex: "status",
        render: (text, record) =>
          Permission.isRole("Administrator") || Permission.isRole("CSR") ? (
            <Select
              style={{ width: 100 }}
              placeholder="Status"
              value={record.assistStatus}
              onChange={event => this.onAssistStatusChange(record, event)}
            >
              <Option value={0}>Pending</Option>
              <Option value={1}>Assisting</Option>
              <Option value={2}>Resolved</Option>
            </Select>
          ) : (
            statusArray[record.assistStatus]
          )
      }
    ];
  };

  handleAssistTravlerClick(record) {
    this.props.handleAssistTravlerClick(record);
  }

  onAssistStatusChange(record, event) {
    this.showConfirm(record, event);
  }

  showConfirm = (record, status) => {
    let _this = this;

    confirm({
      title: "Do you Want to change status?",
      onOk() {
        return new Promise((resolve, reject) => {
          _this.updateTravelerStatus(record.eventLoggingId, status);
          setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
        }).catch(() => console.log("Oops errors!"));
      },
      onCancel() {
        console.log("Cancel");
      }
    });
  };

  componentDidMount() {}

  updateTravelerStatus(eventLoggingId, status) {
    this.props
      .updateAssistTravelerStatus({
        EventLoggingID: eventLoggingId,
        Status: status
      })
      .then(resp => {
        let filters = {};
        if (this.props.savedState.startDate && this.props.savedState.endDate) {
          filters.startDate = moment(this.props.savedState.startDate).format(
            dbDateFormat
          );
          filters.endDate = moment(this.props.savedState.endDate).format(
            dbDateFormat
          );
        }
        if (
          this.props.savedState.parentOrganizationId &&
          this.props.savedState.parentOrganizationId.key !== "all"
        ) {
          filters.orgId = this.props.savedState.parentOrganizationId.key;
        }
        if (this.props.savedState.childOrg) {
          filters.childOrgId = this.props.savedState.childOrg.value;
        }

        this.getAssistTravelersByFilters(filters);
      })
      .catch(ex => {});
  }

  getAssistTravelersByFilters(filter) {
    this.props
      .getAssistTravelersList({
        filters: filter ? filter : {},
        pagination: { page: 1, pageSize: 5 },
        sorter: {}
      })
      .then(resp => {
        this.props.handleTableChange(resp.data);
      })
      .catch(ex => {
        this.setState({ loading: false });
      });
  }

  render() {
    return (
      <div className="table-less">
        <Table
          pagination={{ defaultPageSize: 5 }}
          columns={this.getHeaderKeys()}
          dataSource={this.props.assistTravelers}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...assistTravelerActions }, dispatch);
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    AssistTravelersList
  )
);
