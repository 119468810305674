import React, { Component } from "react";
export default class DateIconsvg extends Component {
  render() {
    return (
      <svg className="svg" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 125.2 105">
        <g>
          <polygon
            className="st0"
            points="17.2,12 17.2,32.9 104.8,32.9 104.8,12 93.8,12 	"
          />
          <rect x="17.2" y="32.9" className="st0" width="87.5" height="66.1" />
          <path
            className="st0"
            d="M33.3,3.4c-0.2,0-0.3,0-0.5,0c-1.5,0-2.7,1.2-2.7,2.7V20c0,1.5,1.2,2.7,2.7,2.7c0.2,0,0.3,0,0.5,0
             c1.5,0,2.7-1.2,2.7-2.7V6.1C36,4.6,34.8,3.4,33.3,3.4z"
          />
          <path
            className="st0"
            d="M89.6,3.4c-0.2,0-0.3,0-0.5,0c-1.5,0-2.7,1.2-2.7,2.7V20c0,1.5,1.2,2.7,2.7,2.7c0.2,0,0.3,0,0.5,0
             c1.5,0,2.7-1.2,2.7-2.7V6.1C92.3,4.6,91,3.4,89.6,3.4z"
          />
          <polygon
            className="st0"
            points="17.2,12 17.2,32.9 104.8,32.9 104.8,12 93.8,12 	"
          />
          <rect x="17.2" y="32.9" className="st0" width="87.5" height="66.1" />
          <path
            className="st1"
            d="M33.3,3.4c-0.2,0-0.3,0-0.5,0c-1.5,0-2.7,1.2-2.7,2.7V20c0,1.5,1.2,2.7,2.7,2.7c0.2,0,0.3,0,0.5,0
             c1.5,0,2.7-1.2,2.7-2.7V6.1C36,4.6,34.8,3.4,33.3,3.4z"
          />
          <path
            className="st1"
            d="M89.6,3.4c-0.2,0-0.3,0-0.5,0c-1.5,0-2.7,1.2-2.7,2.7V20c0,1.5,1.2,2.7,2.7,2.7c0.2,0,0.3,0,0.5,0
             c1.5,0,2.7-1.2,2.7-2.7V6.1C92.3,4.6,91,3.4,89.6,3.4z"
          />
          <g>
            <path
              className="st2"
              d="M74.7,45.9c-3.3,0-6.7,0-10,0c0,3.3,0,6.7,0,10c3.3,0,6.7,0,10,0C74.7,52.6,74.7,49.3,74.7,45.9z"
            />
            <path
              className="st2"
              d="M57.3,45.9c-3.3,0-6.7,0-10,0c0,3.3,0,6.7,0,10c3.3,0,6.7,0,10,0C57.3,52.6,57.3,49.3,57.3,45.9z"
            />
            <path
              className="st0"
              d="M39.9,45.9c-3.3,0-6.7,0-10,0c0,3.3,0,6.7,0,10c3.3,0,6.7,0,10,0C39.9,52.6,39.9,49.3,39.9,45.9z"
            />
            <rect x="82.1" y="45.9" className="st2" width="10" height="10" />
            <rect x="29.9" y="60.9" className="st2" width="10" height="10" />
            <path
              className="st0"
              d="M74.7,60.9c-3.3,0-6.7,0-10,0c0,3.3,0,6.7,0,10c3.3,0,6.7,0,10,0C74.7,67.6,74.7,64.2,74.7,60.9z"
            />
            <path
              className="st2"
              d="M57.3,60.9c-3.3,0-6.7,0-10,0c0,3.3,0,6.7,0,10c3.3,0,6.7,0,10,0C57.3,67.6,57.3,64.2,57.3,60.9z"
            />
            <rect x="82.1" y="60.9" className="st0" width="10" height="10" />
            <rect x="29.9" y="75.9" className="st0" width="10" height="10" />
            <path
              className="st0"
              d="M74.7,75.9c-3.3,0-6.7,0-10,0c0,3.3,0,6.7,0,10c3.3,0,6.7,0,10,0C74.7,82.6,74.7,79.2,74.7,75.9z"
            />
            <path
              className="st0"
              d="M57.3,75.9c-3.3,0-6.7,0-10,0c0,3.3,0,6.7,0,10c3.3,0,6.7,0,10,0C57.3,82.6,57.3,79.2,57.3,75.9z"
            />
          </g>
        </g>
      </svg>
    );
  }
}
