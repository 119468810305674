import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import IntlMessages from "../../../../services/intlMesseges";
import * as OrgActions from "../../../../redux/actions/b2b2c-organization-actions";
import AppLocale from "../../../../languageProvider";
import {
  Typography,
  Form,
  Input,
  Button,
  Row,
  Col,
  Checkbox,
  Select,
  Spin
} from "antd";
import API from "../../../../redux/api/admin-api";
import * as userActions from "../../../../redux/actions/user-actions";
import * as AdminActions from "../../../../redux/actions/admin-actions";
import OrgTree from "../../../shared/components/orgTree";
import { notifyUser } from "../../../../services/notification-service";
const { Option } = Select;

class B2B2CEditDivisonOrganization extends Component {
  state = {
      loading: false,
      isBillingRequired: false,
      formLayout: undefined,
      data: {},
      countries: [],
      stateuser: 1,
      orgData: {},
      secdata: {},
      billingData: {},
      adminInfo: {},
      isBusinessAddressSame: false,
      isCommunicationAddressSame: false,
      disablecheck: false,
      disablecheck2: false,
      pagination: {
        pageSize: 10,
        current: 1,
        hideOnSinglePage: true,
        showTotal: (total, range) => {
          return (
            <span>
              <IntlMessages id="admin.text.showing" /> {range[0]}-{range[1]}{" "}
              <IntlMessages id="admin.text.of" /> {total}{" "}
              <IntlMessages id="admin.text.results" />
            </span>
          );
        }
      },
      filters: {}
    };

  async componentDidMount() {
    this.setState({ loading: true });
    await this.props.getCountriesListName().then(countriesList => {
      this.setState({ countries: countriesList, loading: false });
    });
    this.props.getDivision(this.props.match.params.id).then(async resp => {
      if (resp.error) {
        notifyUser(resp.error.message, 'error');
        this.props.history.push('./../../');
        this.setState({ loading: false });
      } else {
        let _billingData = resp.data.orgContacts.filter(data => (data.addressTypeID === 3))[0];
        this.setState({
          orgData: resp.data,
          secdata: resp.data.orgContacts.filter(data => (data.addressTypeID === 2))[0],
          billingData: resp.data.orgContacts.filter(data => (data.addressTypeID === 3))[0],
          adminInfo: resp.data.adminInfo,
          isBusinessAddressSame: resp.data.isBusinessAddressSame,
          isCommunicationAddressSame: resp.data.isCommunicationAddressSame,
          loading: false,
          isBillingRequired: (_billingData.emailID && _billingData.emailID != "") ? true : false
        });
        if (resp.data.isBusinessAddressSame === true) {
          this.setState({ disablecheck: true });
        } else {
          this.setState({ disablecheck: false });
        }
        if (resp.data.isCommunicationAddressSame === true) {
          this.setState({ disablecheck2: true })
        } else {
          this.setState({ disablecheck2: false });
        }
      }
    }).catch(_ex => {
      this.setState({ loading: false });
    });
  }
  
  handleSubmit() {
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.setState({ loading: true });
        var args = {
          parentOrgId: this.state.orgData.parentOrgId,
          name: values.company_name,
          code: values.company_code,
          email: values.email,
          address: values.address,
          state: values.company_state,
          city: values.city,
          countryId: values.country,
          zipCode: values.zip,
          isBusinessAddressSame: this.state.isBusinessAddressSame,
          isCommunicationAddressSame: this.state.isCommunicationAddressSame,
          phoneNo: values.phoneNumber,
          orgContacts: [{
            addressTypeID: 2,
            address: values.sec_address,
            state: values.sec_state,
            city: values.sec_city,
            zipCode: values.sec_zip,
            countryId: values.sec_country,
            contacts: values.sec_phoneNumber,
            emailID: values.sec_email
          },
          {
            addressTypeID: 3,
            address: values.billing_address,
            state: values.billing_state,
            city: values.billing_city,
            zipCode: values.billing_zip,
            countryId: values.billing_country,
            contacts: values.billing_phoneNumber,
            emailID: values.billing_email,
            billingCompanyName: values.billing_company_name
          }]
        }
        this.props.updateDivision(this.props.match.params.id, args).then(response => {
          if (response.error) {
            if(response.error.message){
              notifyUser(response.error.message, 'error');
            }
            this.setState({ loading: false });
          } else {
            const { language } = this.props;
            const currentAppLocale = AppLocale[language.locale];
            
            this.props.cleanDivisionsTree();
            notifyUser(currentAppLocale.messages["admin.division.edit.success"], 'success');
            //notifyUser("Division updated successfully!", 'success');
            this.props.history.goBack();
            this.setState({ loading: false, divisionId: response.data.organizationId });
          }
        });
      }
    })
  }

  checkchange = (e) => {
    if (e.target.id === "secAddress" && e.target.checked === true) {
      let _secData = this.state.secdata || [];
      _secData.address = this.state.orgData.address;
      _secData.countryId = this.state.orgData.countryId;
      _secData.state = this.state.orgData.state;
      _secData.city = this.state.orgData.city;
      _secData.zipCode = this.state.orgData.zipCode;
      _secData.contacts = this.state.orgData.phoneNo;
      _secData.emailID = this.state.orgData.email;

      this.setState({
        secdata: _secData,
        isBusinessAddressSame: e.target.checked,
        disablecheck: true
      });
      this.props.form.setFieldsValue({
        sec_city: _secData.city,
        sec_address: _secData.address,
        sec_zip: _secData.zipCode,
        sec_phoneNumber: _secData.contacts,
        sec_email: _secData.emailID,
        sec_country: _secData.countryId,
        sec_state: _secData.state
      });
    } else if (e.target.id === "secAddress" && e.target.checked === false) {
      this.setState({
        isBusinessAddressSame: e.target.checked,
        disablecheck: false
      })
    } else if (e.target.id === "billAddress" && e.target.checked === true) {
      let _billingData = this.state.billingData || [];
      _billingData.address = this.state.orgData.address;
      _billingData.countryId = this.state.orgData.countryId;
      _billingData.state = this.state.orgData.state;
      _billingData.city = this.state.orgData.city;
      _billingData.zipCode = this.state.orgData.zipCode;
      _billingData.contacts = this.state.orgData.phoneNo;
      _billingData.emailID = this.state.orgData.email;
      _billingData.billingCompanyName = this.state.orgData.name

      this.setState({
        billingData: _billingData,
        isCommunicationAddressSame: e.target.checked,
        disablecheck2: true,
        isBillingRequired: (typeof _billingData.emailID === "undefined" || _billingData.emailID == "") ? false : true
      });
      this.props.form.setFieldsValue({
        billing_city: _billingData.city,
        billing_address: _billingData.address,
        billing_zip: _billingData.zipCode,
        billing_phoneNumber: _billingData.contacts,
        billing_email: _billingData.emailID,
        billing_country: _billingData.countryId,
        billing_state: _billingData.state,
        billing_company_name:_billingData.billingCompanyName
      })
    } else if (e.target.id === "billAddress" && e.target.checked === false) {
      this.setState({
        isCommunicationAddressSame: e.target.checked,
        disablecheck2: false
      })
    }
  }

  inputChanged = (e, _type) => {
    this.setState({
      isBusinessAddressSame: false,
      isCommunicationAddressSame: false,
      disablecheck: false,
      disablecheck2: false
    });
    let _orgData = this.state.orgData;
    let _adminInfo = this.state.adminInfo;
    if (e && e.target && e.target.id) {
      if (e.target.id.indexOf('_address') > -1) {
        _orgData.address = e.target.value;
      } else if (e.target.id.indexOf('_company_name') > -1) {
        _orgData.name = e.target.value;
      } else if (e.target.id.indexOf('_city') > -1) {
        _orgData.city = e.target.value;
      } else if (e.target.id.indexOf('_zip') > -1) {
        _orgData.zipCode = e.target.value;
      } else if (e.target.id.indexOf('_phoneNumber') > -1) {
        _orgData.phoneNo = e.target.value;
      } else if (e.target.id.indexOf('_firstName') > -1) {
        _adminInfo.firstName = e.target.value;
      } else if (e.target.id.indexOf('_lastName') > -1) {
        _adminInfo.lastName = e.target.value;
      } else if (e.target.id.indexOf('_company_state') > -1) {
        _orgData.state = e.target.value;
      } else if (e.target.id.indexOf('_email') > -1) {
        _orgData.email = e.target.value;
      }
    } else {
      _orgData.countryId = e;
    }
    this.setState({ orgData: _orgData, adminInfo: _adminInfo })
  }

  onChange = (selected) => {
    this.setState({parentOrgId: selected});
  }

  setBillingRequired = (e) => {
    this.setState({isBillingRequired: (e.target.value != "") ? true : false});
  }
  handleEmergencyContactExistance(rule, value, callback){
    //const re = /^[0-9']+$/;
    const re = (/^(?=[+0-9]*$)(?!.*[<>'"/;`%~@#$^*()_=[\]{}|\\,.?: -])/)
    var test=re.test(value)
    if(value !=="" && (value.length <6 || test ===false)){
      callback(<IntlMessages id ="admin.phonenumber.valid"></IntlMessages>)
    }
    else{
      callback();
    }
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const { formLayout } = this.state;
    const formItemLayout =
      formLayout === "horizontal"
        ? {
          labelCol: { span: 4 },
          wrapperCol: { span: 14 }
        }
        : null;
    return (
      <div>
        <Row gutter={24}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Typography.Title level={4}>
              <IntlMessages id="admin.organizations.Divisioninformation" />
            </Typography.Title>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Button
              className="right-fl"
              type="primary"
              htmlType="button"
              onClick={() => this.props.history.goBack()}
            >
              <IntlMessages id="admin.userlisting.back" />
            </Button>
          </Col>
        </Row>
        <hr />
        <Spin spinning={this.state.loading}>
          <Form layout={formLayout}>
            <div className="default-address">
              <Row gutter={24}>
                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                  <Form.Item
                    {...formItemLayout}
                    label={<IntlMessages id="admin.division.parentCompany" />}
                  >
                    {this.state.orgData.name && this.state.orgData.name !== null ? 
                      getFieldDecorator("parent")(<OrgTree parent={this.state.orgData.parentOrgId} organizationId={this.props.match.params.orgid ?this.props.match.params.orgid : this.props.match.params.id } onChange={this.onChange} disabled={true}/>)
                      : 
                      <Input value={this.state.orgData ? this.state.orgData.parentBranch : ''} disabled={true}/>
                    }
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                  <Form.Item
                    {...formItemLayout}
                    label={<IntlMessages id="admin.division.name" />}
                  >
                    {getFieldDecorator("company_name", {
                      rules: [
                        {
                          required: true,
                          message: <IntlMessages id ="admin.input.required"></IntlMessages>,
                          whitespace: true
                        }
                      ],
                      onChange: e => {
                        this.inputChanged(e);
                      },
                      initialValue: this.state.orgData.name === null ? "" : this.state.orgData.name
                    })(<Input  />)}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                  <Form.Item
                    {...formItemLayout}
                    label={<IntlMessages id="admin.division.code" />}
                  >
                    {getFieldDecorator("company_code", {
                      /* rules: [
                        {
                          required: true,
                          message: "This is a required field!",
                          whitespace: true
                        }
                      ] */
                      initialValue: this.state.orgData.code === null ? "" : this.state.orgData.code
                    })(<Input  />)}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                  <Form.Item
                    {...formItemLayout}
                    label={<IntlMessages id="admin.division.address" />}
                  >
                    {getFieldDecorator("address", {
                      rules: [
                        {
                          whitespace: true
                        }
                      ],
                      onChange: e => {
                        this.inputChanged(e);
                      },
                      initialValue: this.state.orgData.address === null ? "" : this.state.orgData.address,
                    })(<Input  />)}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                  <Form.Item
                    {...formItemLayout}
                    label={<IntlMessages id="admin.division.city" />}
                  >
                    {getFieldDecorator("city", {
                      rules: [
                        {
                          whitespace: true
                        },
                        {
                          pattern: new RegExp(/^[ a-zA-Z]+$/),
                          message: <IntlMessages id ="admin.city.valid"></IntlMessages>
                        },
                      ],
                      onChange: e => {
                        this.inputChanged(e);
                      },
                      initialValue: this.state.orgData.city === null ? "" : this.state.orgData.city
                    })(<Input  />)}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                  <Form.Item
                    {...formItemLayout}
                    label={<IntlMessages id="admin.division.state" />}
                  >
                    {getFieldDecorator("company_state", {
                      rules: [
                        {
                          whitespace: true
                        },
                        {
                          pattern: new RegExp(/^[ a-zA-Z]+$/),
                          message: <IntlMessages id ="admin.state.valid"></IntlMessages>
                        },
                      ],
                      onChange: e => {
                        this.inputChanged(e);
                      },
                      initialValue: this.state.orgData.state === null ? "" : this.state.orgData.state,
                    })(<Input  />)}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                  <Form.Item
                    {...formItemLayout}
                    label={<IntlMessages id="admin.division.country" />}
                  >
                    {getFieldDecorator("country", {
                      onChange: e => {
                        this.inputChanged(e);
                      },
                      initialValue: this.state.orgData.countryId
                    })(
                      <Select showSearch filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                        {this.state.countries.map(function (item) {
                          return (
                            <Option key={item.countryId} value={item.countryId}>
                              {item.countryName}
                            </Option>
                          );
                        })}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                  <Form.Item
                    {...formItemLayout}
                    label={<IntlMessages id="admin.division.zip" />}
                  >
                    {getFieldDecorator("zip", {
                      rules: [
                        {
                          whitespace: true
                        }
                      ],
                      onChange: e => {
                        this.inputChanged(e);
                      },
                      initialValue: this.state.orgData.zipCode === null ? "" : this.state.orgData.zipCode
                    })(<Input />)}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                  <Form.Item
                    {...formItemLayout}
                    label={<IntlMessages id="admin.primaryaddress.phonenumber" />}
                  >
                    {getFieldDecorator("phoneNumber", {
                      rules: [
                        {
                          validator: this.handleEmergencyContactExistance
                        }
                        // {
                        //   pattern: new RegExp(/^[0-9']+$/),
                        //   message: <IntlMessages id ="admin.phonenumber.valid"></IntlMessages>
                        // },
                        // {
                        //   max: 12,
                        //   message: "Please enter a valid number!"
                        // },
                        // {
                        //   min: 8,
                        //   message: "Please enter a valid number!"
                        // }
                      ], onChange: e => {
                        this.inputChanged(e);
                      },
                      initialValue:
                        this.state.orgData.phoneNo === null
                          ? ""
                          : this.state.orgData.phoneNo,
                    })(<Input  maxLength={15} />)}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                  <Form.Item
                    {...formItemLayout}
                    label={<IntlMessages id="admin.primaryaddress.email" />}
                  >
                    {getFieldDecorator("email", {
                      rules: [
                        {
                          type: "email",
                          message: <IntlMessages id ="admin.email.valid"></IntlMessages>
                        }
                      ], onChange: e => {
                        this.inputChanged(e);
                      },
                      initialValue: this.state.orgData.email === null ? "" : this.state.orgData.email
                    })(<Input />)}
                  </Form.Item>
                </Col>
              </Row>
            </div>

            <div className="secondary-address">
              <Row gutter={24}>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Typography.Title level={4}>
                    <IntlMessages id="admin.secondary-address" />
                  </Typography.Title>
                </Col>
                <Col
                  xs={24}
                  sm={24}
                  md={12}
                  lg={12}
                  xl={12}
                  style={{ textAlign: "right" }}
                >
                  <Checkbox
                    id="secAddress"
                    name="secAddress"
                    onChange={this.checkchange}
                    checked={this.state.isBusinessAddressSame}
                  >
                     <IntlMessages id="admin.divison.samedivisoninformation" />
                </Checkbox>
                </Col>
              </Row>
              <hr/>

              <Row gutter={24}>
                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                  <Form.Item
                    {...formItemLayout}
                    label={<IntlMessages id="admin.primaryaddress.address" />}
                  >
                    {getFieldDecorator("sec_address", {
                      rules: [
                        {
                          whitespace: true
                        }
                      ],
                      initialValue:
                        this.state.secdata && this.state.secdata.address !== null ? this.state.secdata.address :
                          '',
                    })(<Input disabled={this.state.disablecheck} />)}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                  <Form.Item
                    {...formItemLayout}
                    label={<IntlMessages id="admin.primaryaddress.city" />}
                  >
                    {getFieldDecorator("sec_city", {
                      rules: [
                        {
                          pattern: new RegExp(/^[ a-zA-Z]+$/),
                          message: <IntlMessages id ="admin.city.valid"></IntlMessages>
                        }
                      ],
                      initialValue:
                        this.state.secdata && this.state.secdata.city !== null ? this.state.secdata.city :
                          '',
                    })(<Input disabled={this.state.disablecheck} />)}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                  <Form.Item
                    {...formItemLayout}
                    label={<IntlMessages id="admin.primaryaddress.state" />}
                  >
                    {getFieldDecorator("sec_state", {
                      rules: [
                        {
                          pattern: new RegExp(/^[ a-zA-Z]+$/),
                          message: <IntlMessages id ="admin.state.valid"></IntlMessages>
                        },
                      ],
                      initialValue:
                        this.state.secdata && this.state.secdata.state !== null ? this.state.secdata.state :
                          '',
                    })(<Input disabled={this.state.disablecheck} />)}
                  </Form.Item>
                </Col>              
              </Row>
              <Row gutter={24}>
                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                  <Form.Item label={ <IntlMessages id="admin.division.country" />}>
                    {getFieldDecorator("sec_country", {
                      initialValue: this.state.secdata && this.state.secdata.countryId ? this.state.secdata.countryId : ''
                    })(
                      <Select showSearch filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0} disabled={this.state.disablecheck}>
                        {this.state.countries.map(function (item) {
                          return (
                            <Option key={item.countryId} value={item.countryId}>
                              {item.countryName}
                            </Option>
                          );
                        })}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                  <Form.Item
                    {...formItemLayout}
                    label={<IntlMessages id="admin.primaryaddress.zip" />}
                  >
                    {getFieldDecorator("sec_zip", {
                      rules: [
                        {
                          pattern: new RegExp(/^[a-zA-Z0-9]+$/),
                          message: <IntlMessages id ="admin.zip.valid"></IntlMessages>
                        },
                        {
                          max: 10,
                          message: <IntlMessages id ="admin.zip.valid"></IntlMessages>
                        },
                        {
                          min: 4,
                          message: <IntlMessages id ="admin.zip.valid"></IntlMessages>
                        }
                      ],
                      initialValue:
                        this.state.secdata && this.state.secdata.zipCode !== null ? this.state.secdata.zipCode :
                          '',
                    })(<Input minLength={4} maxLength={10} disabled={this.state.disablecheck} />)}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                  <Form.Item
                    {...formItemLayout}
                    label={<IntlMessages id="admin.primaryaddress.phonenumber" />}
                  >
                    {getFieldDecorator("sec_phoneNumber", {
                      rules: [
                        {
                          validator: this.handleEmergencyContactExistance
                        }
                        // {
                        //   pattern: new RegExp(/^[0-9']+$/),
                        //   message: <IntlMessages id ="admin.phonenumber.valid"></IntlMessages>
                        // },
                        // {
                        //   max: 12,
                        //   message: "Please enter a valid number!"
                        // },
                        // {
                        //   min: 8,
                        //   message: "Please enter a valid number!"
                        // }
                      ],
                      initialValue:
                        this.state.secdata && this.state.secdata.contacts !== null ? this.state.secdata.contacts :
                          '',
                    })(<Input  maxLength={15} disabled={this.state.disablecheck} />)}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Form.Item
                  {...formItemLayout}
                  label={<IntlMessages id="admin.primaryaddress.email" />}
                >
                  {getFieldDecorator("sec_email", {
                    rules: [
                      {
                        type: "email",
                        message:  <IntlMessages id ="admin.email.valid"></IntlMessages>
                      }
                    ],
                    initialValue:
                      this.state.secdata && this.state.secdata.emailID !== null ? this.state.secdata.emailID :
                        '',
                  })(<Input disabled={this.state.disablecheck} />)}
                </Form.Item>
              </Col>
            </Row>
            </div>
            
            <div className="billing-address">
              <Row gutter={24}>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Typography.Title level={4}>
                    <IntlMessages id="admin.billing-address" />
                  </Typography.Title>
                </Col>
                <Col
                  xs={24}
                  sm={24}
                  md={12}
                  lg={12}
                  xl={12}
                  style={{ textAlign: "right" }}
                >
                  <Checkbox
                    id="billAddress"
                    name="billAddress"
                    onChange={this.checkchange}
                    checked={this.state.isCommunicationAddressSame}

                  >
                     <IntlMessages id="admin.divison.samedivisoninformation" />
                </Checkbox>
                </Col>
              </Row>

              <hr/>
              <Row gutter={24}>
                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                  <Form.Item
                    {...formItemLayout}
                    label={<IntlMessages id="admin.primaryaddress.billing_Company_name" />}>
                    {getFieldDecorator("billing_company_name", {
                      rules: [
                        {
                          required: this.state.isBillingRequired, 
                          message: <IntlMessages id="admin.input.required" />
                        }
                      ],
                      initialValue:
                        this.state.billingData && this.state.billingData.billingCompanyName !== null ? this.state.billingData.billingCompanyName :
                          '',
                    })(<Input maxLength={30}  disabled={this.state.disablecheck2} />)}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                  <Form.Item
                    {...formItemLayout}
                    label={<IntlMessages id="admin.primaryaddress.address" />}
                  >
                    {getFieldDecorator("billing_address", {
                      rules: [
                        {
                          whitespace: true
                        }
                      ],
                      initialValue:
                        this.state.billingData && this.state.billingData.address !== null ? this.state.billingData.address :
                          '',
                    })(<Input disabled={this.state.disablecheck2} />)}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                  <Form.Item
                    {...formItemLayout}
                    label={<IntlMessages id="admin.primaryaddress.city" />}
                  >
                    {getFieldDecorator("billing_city", {
                      rules: [
                        {
                          whitespace: true
                        },
                        {
                          pattern: new RegExp(/^[ a-zA-Z]+$/),
                          message:  <IntlMessages id ="admin.city.valid"></IntlMessages>
                        },
                      ],
                      initialValue:
                        this.state.billingData && this.state.billingData.city !== null ? this.state.billingData.city :
                          '',
                    })(<Input disabled={this.state.disablecheck2} />)}
                  </Form.Item>
                </Col>
              </Row>
              
              <Row gutter={24}>
                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                  <Form.Item
                    {...formItemLayout}
                    label={<IntlMessages id="admin.primaryaddress.state" />}
                  >
                    {getFieldDecorator("billing_state", {
                      rules: [
                        {
                          whitespace: true
                        },
                        {
                          pattern: new RegExp(/^[ a-zA-Z]+$/),
                          message:  <IntlMessages id ="admin.state.valid"></IntlMessages>
                        },
                      ],
                      initialValue:
                        this.state.billingData && this.state.billingData.state !== null ? this.state.billingData.state :
                          '',
                    })(<Input disabled={this.state.disablecheck2} />)}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                  <Form.Item label={ <IntlMessages id="admin.division.country" />}>
                    {getFieldDecorator("billing_country", {
                      initialValue:
                        this.state.billingData && this.state.billingData.countryId !== null ? this.state.billingData.countryId :
                          '',
                    })(
                      <Select showSearch filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0} disabled={this.state.disablecheck2}>
                        {this.state.countries.map(function (item) {
                          return (
                            <Option key={item.countryId} value={item.countryId}>
                              {item.countryName}
                            </Option>
                          );
                        })}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                  <Form.Item
                    {...formItemLayout}
                    label={<IntlMessages id="admin.primaryaddress.zip" />}
                  >
                    {getFieldDecorator("billing_zip", {
                      rules: [
                        {
                          whitespace: true
                        }, 
                        {
                          pattern: new RegExp(/^[a-zA-Z0-9]+$/),
                          message: <IntlMessages id ="admin.zip.valid"></IntlMessages>
                        },
                        {
                          max: 10,
                          message: <IntlMessages id ="admin.zip.valid"></IntlMessages>
                        },
                        {
                          min: 4,
                          message: <IntlMessages id ="admin.zip.valid"></IntlMessages>
                        }
                      ],
                      initialValue:
                        this.state.billingData && this.state.billingData.zipCode !== null ? this.state.billingData.zipCode :
                          '',
                    })(<Input minLength={4} maxLength={10} disabled={this.state.disablecheck2} />)}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Form.Item
                  {...formItemLayout}
                  label={<IntlMessages id="admin.primaryaddress.phonenumber" />}
                >
                  {getFieldDecorator("billing_phoneNumber", {
                    rules: [
                      {
                        validator: this.handleEmergencyContactExistance
                      }
                      // {
                      //   whitespace: true
                      // }, {
                      //   pattern: new RegExp(/^[0-9']+$/),
                      //   message: <IntlMessages id ="admin.phonenumber.valid"></IntlMessages>
                      // },
                      // {
                      //   max: 12,
                      //   message: "Please enter a valid number!"
                      // },
                      // {
                      //   min: 8,
                      //   message: "Please enter a valid number!"
                      // }
                    ],
                    initialValue:
                      this.state.billingData && this.state.billingData.contacts !== null ? this.state.billingData.contacts :
                        '',
                  })(<Input  maxLength={15} disabled={this.state.disablecheck2} />)}
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Form.Item
                  {...formItemLayout}
                  label={<IntlMessages id="admin.primaryaddress.email" />}
                >
                  {getFieldDecorator("billing_email", {
                    rules: [
                      {
                        type: "email",
                        message:  <IntlMessages id ="admin.email.valid"></IntlMessages>
                      }
                    ],
                    onChange: e => {
                      this.setBillingRequired(e);
                    },
                    initialValue:
                      this.state.billingData && this.state.billingData.emailID !== null ? this.state.billingData.emailID :
                        '',
                  })(<Input disabled={this.state.disablecheck2} />)}
                </Form.Item>
              </Col>
            </Row>
            </div>
            
            <Button type="primary" onClick={() => this.handleSubmit()}><IntlMessages id="admin.button.update"/></Button>
          </Form>
        </Spin>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    ...state.user,
    ...state.language
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...AdminActions, ...userActions, ...OrgActions }, dispatch);
}
const WrappedForm = Form.create({ name: "edit-division" })(
  B2B2CEditDivisonOrganization
);
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    WrappedForm
  )
);