import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import UserProfile from "../components/reseller-dash/user-profile/user-profile";
import ManageOrders from "../components/reseller-dash/order-history/order-history";
import ViewOrder from "../components/reseller-dash/order-history/view";
import ResellerHome from "../components/reseller-dash/user-home/user-home";
import UserChangePassword from "../components/reseller-dash/user-profile/change-password";
import ManageOrganization from "../components/reseller-dash/manage-organization/edit-organization";
import ManageTravelers from "../components/reseller-dash/travelers/list";
import EditTraveler from "../components/reseller-dash/travelers/edit/edit";
import AuditLogs from "../components/reseller-dash/audit-logs";
import NotFound from "../components/shared/components/404.jsx";
import Notifications from "../components/Notifications/notifications";
import ViewNotification from "../components/Notifications/view";
import CustomizeEmailsList from "../components/common/customize-emails/list";
import EditCustomizeEmail from "../components/common/customize-emails/edit";
import EditB2B2COrganizationAgent from "../components/reseller-dash/agents/edit";
import EditOrganizationAgents from "../components/reseller-dash/agents/list";

const routes = [
  {
    path: "",
    component: ResellerHome
  },
  {
    path: "my-profile",
    component: UserProfile
  },
  {
    path: "my-profile/change-password",
    component: UserChangePassword
  },
  {
    path: "manage-orders",
    component: ManageOrders
  },
  {
    path: "manage-orders/view/:orderId",
    component: ViewOrder
  },
  {
    path: "manage-organization",
    component: ManageOrganization
  },
  {
    path: "manage-agents",
    component: EditOrganizationAgents
  },
  {
    path: "manage-agents/:id",
    component: EditB2B2COrganizationAgent
  },
  {
    path: "manage-travelers",
    component: ManageTravelers
  },
  {
    path: "manage-travelers/edit/:id",
    component: EditTraveler
  },
  {
    path: "manage-orders/travelers/:id",
    component: EditTraveler
  },
  {
    path: "manage-travelers/edit/:id/view-order/:orderId",
    component: ViewOrder
  },
  {
    path: "audit-logs",
    component: AuditLogs
  },
  {
    path: "notifications",
    component: Notifications
  },
  {
    path: "notifications/:id",
    component: ViewNotification
  },
  {
    path: "customize-emails",
    component: CustomizeEmailsList
  },
  {
    path: "customize-emails/edit/:templateId",
    component: EditCustomizeEmail
  },
  {
    path: "",
    component: NotFound,
    exact: false
  }
];

class ResellerRouter extends Component {
  render() {
    const { url, style } = this.props;
    return (
      <div style={style}>
        <Switch>
          {routes.map(singleRoute => {
            const { path, exact, ...otherProps } = singleRoute;
            return (
              <Route
                exact={exact === false ? false : true}
                key={singleRoute.path}
                path={`${url}/${singleRoute.path}`}
                {...otherProps}
              />
            );
          })}
        </Switch>
      </div>
    );
  }
}

export default ResellerRouter;
