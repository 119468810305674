import API from "../api/promo-api";
import {notifyUser} from "../../services/notification-service"

export function getPromoCodes({ filters, pagination, sorter }) {
  return async function(dispatch, getState) {
    try {
      let resp = await API.getPromoCodes({
        filters: filters,
        pagination: pagination,
        sorter : sorter
      });
      return resp;

    } catch (e) {
      console.log(e);
      return {"error" : true};
    }
  };
}

export function updatePromoCode(data) {
  return async function(dispatch, getState) {
    try {
      let resp;
      if(data.promoOfferId > 0){
        resp = await API.updatePromoCode(data);
      } else {
        resp = await API.addPromoCode(data);
      }
      return resp;
    } catch (e) {
      console.log(e);
      return {"error" : true};
    }
  };
}

export function getPromoCode(idx) {
  return async function(dispatch, getState) {
    try {
      let resp = await API.getPromoCode(idx);
     return resp;
    } catch (e) {
      console.log(e);
      return {"error" : true};
    }
  };
}

export function deletePromoCode(idx) {
  return async function(dispatch, getState) {
    try {
      let resp = await API.deletePromoCode(idx);
      notifyUser("User Deleted.", 'info');
      return resp;
    } catch (e) {
      console.log(e);
      return {"error" : true};
    }
  };
}