import React, { Component } from "react";
export default class UserInvoice extends Component {
  render() {
    return (
      <section>
        <div className="wrap">
          <p>User Invoice</p>
        </div>
      </section>
    );
  }
}
