import React, { Component } from "react";
export default class LogoMain extends Component {
  render() {
    return (
      <svg version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 226.7 54">
        <g>
          <g>
            <g>
              <path
                className="st0"
                d="M23.2,42.3c0.2-0.1,0.3-0.1,0.5-0.2C23.5,42.1,23.4,42.2,23.2,42.3z"
              />
              <path
                className="st0"
                d="M19.1,43c-5.7-0.1-10.3-4.7-10.3-10.4c0-4.8,3.2-8.8,7.6-10c-0.2,0.1-0.3,0.1-0.5,0.2
                   c2.6-1.1,5.5-0.6,7.5,1.2l4.5-5c-3.9-3.5-9.6-4.6-14.7-2.3c-7,3.1-10.1,11.2-7.1,18.2c2.8,6.3,9.8,9.5,16.2,7.8
                   C21.3,42.8,20.2,42.9,19.1,43z"
              />
              <path
                className="st0"
                d="M22.5,42.5c0.2-0.1,0.4-0.1,0.6-0.2C22.8,42.4,22.6,42.4,22.5,42.5z"
              />
            </g>
            <g className="st1">
              <path
                className="st0"
                d="M44.2,13.9l-19,18.8l-1.7,1.8c-0.5,0.5-1.2,0.9-1.9,1.2c-3.6,1.6-7.8-0.1-9.3-3.6c-1.6-3.6,0.1-7.8,3.6-9.3
                   c0.2-0.1,0.3-0.1,0.5-0.2c-4.4,1.2-7.6,5.3-7.6,10c0,5.7,4.6,10.3,10.3,10.4c1.7,0,3.5-0.4,5.2-1.2c1.2-0.5,2.3-1.3,3.3-2.1l0,0
                   l21.5-20.2L44.2,13.9z"
              />
            </g>
            <g>
              <g>
                <g>
                  <g>
                    <g>
                      <g>
                        <path
                          className="st0"
                          d="M77,22.6v3h-1v-3h-0.8v-0.8h2.6v0.8H77z"
                        />
                        <path
                          className="st0"
                          d="M77.9,25.6l0.6-3.8h1l0.8,2l0.8-2h1l0.6,3.8h-1l-0.3-2.2l-0.9,2.2h-0.4l-0.9-2.2l-0.3,2.2H77.9z"
                        />
                      </g>
                    </g>
                  </g>
                </g>
                <polygon
                  className="st0"
                  points="59.7,2.2 55.3,4.4 55.3,4.4 39.2,12.4 44.2,13.9 49.2,19.6 51,16.6 47.8,14.9 48.5,17.9 44.8,13.5 
                       58.8,3.1 47.8,14.9 54.7,16.9 				"
                />
              </g>
            </g>
          </g>
          <g>
            <path
              className="st2"
              d="M12.7,24.2C12.6,24.2,12.6,24.2,12.7,24.2c-0.1,0.1-0.1,0.1-0.1,0.1C12.6,24.2,12.6,24.2,12.7,24.2z"
            />
          </g>
          <path
            className="st0"
            d="M49.7,21.8v20.5h-7.3V40c-0.7,1-1.6,1.7-2.7,2.3c-1.1,0.5-2.3,0.8-3.6,0.8c-3,0-5.4-1.1-7.4-3.2
           c-2-2.2-3-4.7-3-7.7c0-3,1-5.6,2.9-7.7c1.9-2.1,4.4-3.2,7.4-3.2c1.3,0,2.4,0.2,3.6,0.7c1.1,0.4,2.1,1.1,2.8,2v-2H49.7z M38,27.4
           c-1.4,0-2.6,0.4-3.4,1.3c-0.8,0.9-1.3,2-1.3,3.3c0,1.4,0.4,2.5,1.2,3.3c0.8,0.9,2,1.3,3.4,1.3c1.4,0,2.6-0.4,3.4-1.3
           c0.8-0.9,1.3-2,1.3-3.3c0-1.3-0.4-2.5-1.2-3.3C40.6,27.9,39.5,27.4,38,27.4z"
          />
          <path
            className="st0"
            d="M59.2,21.8v2.3c0.7-1,1.6-1.7,2.7-2.2c1.1-0.5,2.3-0.8,3.6-0.8c3,0,5.5,1.1,7.4,3.2c1.9,2.1,2.9,4.7,2.9,7.8
           c0,3-1,5.6-2.9,7.7C71,41.9,68.5,43,65.5,43c-1.2,0-2.4-0.2-3.5-0.7c-1.1-0.4-2.1-1.1-2.8-2v12.2h-7.3V21.8H59.2z M63.6,27.4
           c-1.4,0-2.6,0.4-3.4,1.3c-0.8,0.9-1.3,2-1.3,3.3c0,1.4,0.4,2.5,1.2,3.3c0.8,0.9,2,1.3,3.4,1.3c1.4,0,2.6-0.4,3.4-1.3
           c0.8-0.9,1.3-2,1.3-3.3c0-1.3-0.4-2.4-1.2-3.3C66.2,27.9,65.1,27.4,63.6,27.4z"
          />
        </g>
        <g>
          <text transform="matrix(1 0 0 1 84.4849 40.6058)" className="st0 st3 st4">
            Travel Assistance Portal
          </text>
        </g>
      </svg>
    );
  }
}
