import React, { Component } from "react";
import { Card, Progress, Icon, Result, Row, Col, Statistic } from "antd";
import {
  PushnotificationSvg,
  EmailnotificationSvg,
  SmsnotificationSvg,
  NotificationadmindashSvg
} from "../../../shared/svg/svg/home-icon";
const PushnotificationIcon = props => (
  <Icon component={PushnotificationSvg} {...props} />
);
const EmailnotificationIcon = props => (
  <Icon component={EmailnotificationSvg} {...props} />
);
const SmsnotificationIcon = props => (
  <Icon component={SmsnotificationSvg} {...props} />
);
const NotificationadminIcon = props => (
  <Icon component={NotificationadmindashSvg} {...props} />
);
export default class DataAnalysis extends Component {
  render() {
    return (
      <Card title="Notifications">
        <Row gutter={24}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <div
              className="NotificationsAlertSection"
              style={{ marginTop: "0" }}
            >
              <Result
                icon={<PushnotificationIcon />}
                title="Push Notifications"
              />
              <Statistic
                value={
                  this.props.notificationsData
                    ? this.props.notificationsData.pushNotifications
                    : "..."
                }
              />
            </div>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <div
              className="NotificationsAlertSection"
              style={{ marginTop: "0" }}
            >
              <Result icon={<EmailnotificationIcon />} title="Emails Sent" />
              <Statistic
                value={
                  this.props.notificationsData
                    ? this.props.notificationsData.emails
                    : "..."
                }
              />
            </div>
          </Col>
        </Row>
        <Row gutter={24} style={{marginTop:"20px"}}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <div className="NotificationsAlertSection">
              <Result icon={<SmsnotificationIcon />} title="SMS Sent" />
              <Statistic
                value={
                  this.props.notificationsData
                    ? this.props.notificationsData.sms
                    : "..."
                }
              />
            </div>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <div className="NotificationsAlertSection">
              <Result icon={<NotificationadminIcon />} title="Notifications" />
              <Statistic
                value={
                  this.props.notificationsData
                    ? this.props.notificationsData.notifications
                    : "..."
                }
              />
            </div>
          </Col>
        </Row>
      </Card>
    );
  }
}
