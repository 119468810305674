import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import IntlMessages from "../../../../services/intlMesseges";
import AppLocale from "../../../../languageProvider";
import {
  Typography,
  Form,
  Input,
  DatePicker,
  Row,
  Col,
  Select,
  Checkbox,
  Transfer,
  Button,
  Spin,
} from "antd";
import moment from "moment";
import * as productActions from "../../../../redux/actions/product-actions";
import * as OrgActions from "../../../../redux/actions/organization-actions";
import * as AdminActions from "../../../../redux/actions/admin-actions";
import { notifyUser } from "../../../../services/notification-service";
const { Option } = Select;
const dateFormat = "MM/DD/YYYY";
var checkTraveller = false;
var dataAccount = [];

function onChanges(date, dateString) {}
class OrganizationPlan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mockData: [],
      countries: [],
      productData: [],
      pccData: [],
      hasTravelManager: false,
      selectedKeys: [],
      loading: true,
      pccaccountno: "",
    };
    this.onChange = this.onChange.bind(this);
  }

  async componentDidMount() {
    this.setState({ loading: false });

    this.setState({ targetKeys: this.props.targetKeys });
    await this.props.getCountriesListFormatted().then((re) => {
      this.setState({ countries: re });
    });
    // this.getMock();
    this.props
      /// Get Products on INIT
      .getProducts({
        // filters: filters,
        // pagination: { page: pagination.current, pageSize: pagination.pageSize },
        // sorter: sorter
      })
      .then((resp) => {
        let data = resp.data;
        this.setState({
          productData: data,
        });
      })
      .catch((ex) => {});
    // get PCC DATA on INIT
    this.props
      .getPccdata({})
      .then((resp) => {
        let data = resp.data;
        this.setState({
          pccData: data,
        });
      })
      .catch((ex) => {});
  }
  onChange(e) {
    checkTraveller = e.target.checked;
    this.props.onchange(e.target.checked, "tarvellerexits");
  }

  // filterOption = (inputValue, option) =>
  //   option.description.indexOf(inputValue) > -1;

  handleChangeTransfer = (targetKeys) => {
    this.props.onchange(targetKeys, "targetKeys");
    this.setState({ targetKeys });
    this.setState({ isEmpty: true });
    var data = [];
    if (targetKeys.length === 0) {
      this.props.onchange([], "pccaccountno");
    } else {
      for (var i = 0; i < targetKeys.length; i++) {
        data.push({ pccAccountID: +targetKeys[i] });
      }
      this.props.onchange(data, "pccaccountno");
    }
  };
  handleSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
    this.setState({
      selectedKeys: [...sourceSelectedKeys, ...targetSelectedKeys],
    });
  };
  handleSearch = (dir, value) => {};
  state = {
    formLayout: undefined,
  };

  handleSubmit() {
    const { language } = this.props;
    const currentAppLocale = AppLocale[language.locale];
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.setState({ loading: true });

        var Json_Org = {
          orgName: this.props.company_name,
          code: this.props.company_code,
          orgTypeId: 3,
          address1: this.props.address,
          state: this.props.company_state,
          city: this.props.city,
          zipCode: this.props.zip,
          countryId: this.props.country,
          phoneNo: this.props.phoneNumber,
          isBusinessAddressSame: this.props.isBusinessAddressSame,
          isCommunicationAddressSame: this.props.isCommunicationAddressSame,
          fpManagerID: 0,
          fpSalesExecutiveID: 0,
          emailID: this.props.email,
          contactFirstName: this.props.firstName,
          contactLastName: this.props.lastName,
          coverageStartDate: this.props.startDate,
          coverageEndDate: this.props.endDate,
          days: 365,
          countries: this.props.org_country,
          customerID: this.props.customer_id,
          jobNumber: this.props.job_number,
          creditDaysLimit: this.props.creditDays,
          productInfo: {
            productID: this.props.membership_type,
            productQty: this.props.licence_number,
            productRetailPrice: this.props.price,
            productDealPrice: this.props.dealprice,
            postSalePricePerLicense: 0,
          },
          orgContacts: [
            {
              addressTypeID: 2,
              address: this.props.sec_address,
              city: this.props.sec_city,
              state: this.props.sec_state,
              zipCode: this.props.sec_zip,
              countryId: this.props.sec_country,
              contacts: this.props.sec_phoneNumber,
              contactPerson: this.props.secondary_contactPerson,
              emailID: this.props.sec_email,
            },
            {
              addressTypeID: 3,
              address: this.props.billing_address,
              city: this.props.billing_city,
              state: this.props.billing_state,
              zipCode: this.props.billing_zip,
              countryId: this.props.billing_country,
              contacts: this.props.billing_phoneNumber,
              contactPerson: this.props.billing_contactPerson,
              emailID: this.props.billing_email,
              billingCompanyName: this.props.billing_company_name,
            },
          ],
          orgPCCAccounts: this.props.pccaccountno,
        };

        if (checkTraveller) {
          /* if (this.props.pccaccountno.length === 0) {
            notifyUser(currentAppLocale.messages["admin.ponumber.required"], "error");
            this.setState({ loading: false });
          } else { */
          this.props.onchange("current", 2);
          this.setState({ loading: false });
          /* } */
        } else if (!checkTraveller) {
          this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
              /* if (this.props.pccaccountno.length === 0) {
                this.setState({ loading: false });
                notifyUser(currentAppLocale.messages["admin.ponumber.required"], "error");
              } else { */
              this.props
                .AddOrganizations(Json_Org)
                .then((response) => {
                  if (response.error) {
                    notifyUser(
                      "PCCAccount code already in use by another organization!",
                      "error"
                    );
                    this.setState({ loading: false });
                  } else {
                    this.setState({ loading: false });
                    this.props.onchange(
                      "membershipNumber",
                      response.data.membershipNumber
                    );
                    this.props.onchange(
                      "organizationId",
                      response.data.organizationID
                    );
                    this.props.onchange("current", 3);
                  }
                })
                .catch((err) => {
                  this.setState({ loading: false });
                });
              /* } */
            } else {
              this.setState({ loading: false });
            }
          });
        } else {
        }
      }
    });
  }
  backpage() {
    const current = this.props.current - 1;
    this.setState({ current: current });
    this.props.onchange("current", 0);
  }

  async onchangePcc(e) {
    dataAccount = [];
    var value = [];
    var self = this;
    var a = [];
    e.length > 0 &&
      e.map(function(row, idx) {
        self.props.getPccAccountData(row).then((resp) => {
          a.push(row);

          if (resp.data && resp.data.length > 0) {
            value = dataAccount.concat(
              resp.data.map((item) => ({
                account: item.pccaccountNumber,
                key: "" + item.pccAccountId,
              }))
            );
            dataAccount = value;
          }
          if (a.length === e.length) {
            if (
              dataAccount
                .map((data) => data.key)
                .filter((value) => -1 !== self.state.targetKeys.indexOf(value))
                .length === 0
            ) {
              self.setState({
                targetKeys: value
                  .map((info) => info.key)
                  .filter(
                    (value) => -1 !== self.state.targetKeys.indexOf(value)
                  ),
              });
            }
            self.props.onchange(dataAccount, "pccaccountnumber");
            if (dataAccount.length === 0) {
              self.props.onchange([], "pccaccountno");
            }
          }
        });
      });
  }

  limitfunction = (rule, value, callback) => {
    if (parseInt(value) < 0 || parseInt(value) > 366) {
      callback("Credit Limit Must be Between 0 and 365");
    }
    callback();
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { formLayout } = this.state;
    const formItemLayout =
      formLayout === "horizontal"
        ? {
            labelCol: { span: 4 },
            wrapperCol: { span: 14 },
          }
        : null;

    return (
      <div style={{ marginTop: "50px" }}>
        <Typography.Title level={3}>
          <IntlMessages id="admin.primary-plan" />
        </Typography.Title>
        <Spin spinning={this.state.loading}>
          <Form layout={formLayout} onSubmit={this.handleSubmit}>
            <Row gutter={24}>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Form.Item
                  label={<IntlMessages id="admin.organizations.countries" />}
                >
                  {getFieldDecorator("org_country", {
                    rules: [
                      {
                        required: true,
                        message: (
                          <IntlMessages id="admin.input.required"></IntlMessages>
                        ),
                      },
                    ],
                    onChange: (e) => {
                      this.props.onchange(e, "org_country");
                    },
                    initialValue: this.props.org_country,
                  })(
                    <Select
                      style={{ width: "100%" }}
                      mode="multiple"
                      showSearch
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {this.state.countries.map(function(item) {
                        return (
                          <Option key={item.countryId} value={item.countryId}>
                            {item.countryName}
                          </Option>
                        );
                      })}
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Form.Item
                  label={
                    <IntlMessages id="admin.organizations.plantype"></IntlMessages>
                  }
                >
                  {getFieldDecorator("membership_type", {
                    rules: [
                      {
                        required: true,
                        message: (
                          <IntlMessages id="admin.input.required"></IntlMessages>
                        ),
                      },
                    ],
                    onChange: (e) => {
                      this.props.onchange(e, "membership_type");
                    },
                    initialValue: this.props.membership_type,
                  })(
                    <Select
                      style={{ width: "100%" }}
                      showSearch
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {this.state.productData
                        ? this.state.productData.map(function(item) {
                            return item.productCode == "CAP" ? (
                              <Option
                                key={item.productId}
                                value={item.productId}
                              >
                                {item.name}
                              </Option>
                            ) : (
                              ""
                            );
                            // return (
                            //   <Option key={item.productId} value={item.productId}>
                            //     {item.name}
                            //   </Option>
                            // );
                          })
                        : ""}
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Form.Item
                  {...formItemLayout}
                  label={<IntlMessages id="admin.organization.NumberofPlans" />}
                >
                  {getFieldDecorator("licence_number", {
                    rules: [
                      {
                        required: true,
                        message: (
                          <IntlMessages id="admin.input.required"></IntlMessages>
                        ),
                      },
                      {
                        pattern: new RegExp(/^[0-9']+$/),
                        message: (
                          <IntlMessages id="admin.plannumber.valid"></IntlMessages>
                        ),
                      },
                    ],
                    initialValue:
                      this.props.licence_number === null
                        ? ""
                        : this.props.licence_number,
                    onChange: (e) => {
                      this.props.onchange(e);
                    },
                    value: this.props.licence_number,
                  })(<Input />)}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24} style={{ marginBottom: "20px" }}>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Form.Item
                  {...formItemLayout}
                  label={<IntlMessages id="admin.organization.retailprice" />}
                >
                  {getFieldDecorator("price", {
                    rules: [
                      {
                        required: true,
                        message: (
                          <IntlMessages id="admin.input.required"></IntlMessages>
                        ),
                      },
                      {
                        pattern: new RegExp(/^[.0-9']+$/),
                        message: (
                          <IntlMessages id="admin.retailnumber.valid"></IntlMessages>
                        ),
                      },
                    ],
                    initialValue:
                      this.props.price === null ? "" : this.props.price,
                    onChange: (e) => {
                      this.props.onchange(e);
                    },
                    value: this.props.price,
                  })(<Input />)}
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Form.Item
                  {...formItemLayout}
                  label={<IntlMessages id="admin.organization.dealprice" />}
                >
                  {getFieldDecorator("dealprice", {
                    rules: [
                      {
                        required: true,
                        message: (
                          <IntlMessages id="admin.input.required"></IntlMessages>
                        ),
                      },
                      {
                        pattern: new RegExp(/^[.0-9']+$/),
                        message: (
                          <IntlMessages id="admin.dealprice.valid"></IntlMessages>
                        ),
                      },
                    ],
                    initialValue:
                      this.props.dealprice === null ? "" : this.props.dealprice,
                    onChange: (e) => {
                      this.props.onchange(e);
                    },
                    value: this.props.dealprice,
                  })(<Input />)}
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Form.Item
                  {...formItemLayout}
                  label={<IntlMessages id="admin.organization.Startdate" />}
                >
                  {getFieldDecorator("startDate", {
                    rules: [
                      {
                        required: true,
                        message: (
                          <IntlMessages id="admin.input.required"></IntlMessages>
                        ),
                      },
                    ],
                    initialValue:
                      this.props.startDate === null
                        ? ""
                        : moment(this.props.startDate, dateFormat),
                    onChange: (e) => {
                      this.props.onchange(e, "startDate");
                    },

                    value: this.props.startDate,
                  })(
                    <DatePicker
                      disabledDate={(current) => {
                        return (
                          current && current < moment().subtract(1, "days")
                        );
                      }}
                      placeholder="-select-"
                      format={dateFormat}
                      onChanges={onChanges}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Form.Item
                  {...formItemLayout}
                  label={<IntlMessages id="admin.organization.Enddate" />}
                >
                  {getFieldDecorator("endDate", {
                    rules: [
                      // {
                      //   required: true,
                      //   message: <IntlMessages id ="admin.input.required"></IntlMessages>
                      // }
                    ],
                    initialValue:
                      this.props.endDate === null ? "" : this.props.endDate,
                    onChange: (e) => {
                      this.props.onchange(e);
                    },
                    value: this.props.endDate,
                  })(<Input disabled />)}
                </Form.Item>
              </Col>
              {/* <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <Form.Item
                {...formItemLayout}
                label={<IntlMessages id="admin.organization.Postsaleprice" />}
              >
                {getFieldDecorator("postSalePrice", {
                  rules: [
                    {
                      required: true,
                      message: <IntlMessages id ="admin.input.required"></IntlMessages>
                    }, {
                      pattern: new RegExp(/^[.0-9']+$/),
                      message: <IntlMessages id ="admin.dealprice.valid"></IntlMessages>
                    }
                  ],
                  initialValue:
                    this.props.postSalePrice === null
                      ? ""
                      : this.props.postSalePrice,
                  onChange: e => {
                    this.props.onchange(e);
                  },
                  value: this.props.postSalePrice
                })(<Input />)}
              </Form.Item>
            </Col> */}
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Form.Item
                  {...formItemLayout}
                  label={<IntlMessages id="admin.organization.creditdays" />}
                >
                  {getFieldDecorator("creditDays", {
                    rules: [
                      {
                        required: true,
                        message: (
                          <IntlMessages id="admin.input.required"></IntlMessages>
                        ),
                      },
                      {
                        pattern: new RegExp(/^[.0-9']+$/),
                        message: (
                          <IntlMessages id="admin.creditdays.valid"></IntlMessages>
                        ),
                      },
                      {
                        validator: this.limitfunction,
                        message: (
                          <IntlMessages id="admin.creditdays.valid"></IntlMessages>
                        ),
                      },
                    ],
                    initialValue:
                      this.props.creditDays === null
                        ? ""
                        : this.props.creditDays,
                    onChange: (e) => {
                      this.props.onchange(e);
                    },
                    value: this.props.creditDays,
                  })(<Input max={3} />)}
                </Form.Item>
              </Col>{" "}
            </Row>

            <Row gutter={24} style={{ marginBottom: "40px" }}>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Form.Item
                  {...formItemLayout}
                  label={<IntlMessages id="admin.organization.pccnumber" />}
                >
                  {getFieldDecorator("pcc_number", {
                    /* rules: [
                      {
                        required: true,
                        message: <IntlMessages id="admin.input.required"></IntlMessages>
                      }
                    ], */
                    onChange: (e) => {
                      this.props.onchange(e, "pcc_number");
                      this.onchangePcc(e);
                    },
                    value: this.props.pcc_number,

                    initialValue:
                      this.props.pcc_number === null
                        ? ""
                        : this.props.pcc_number,
                  })(
                    // pccdata
                    <Select
                      mode="multiple"
                      style={{ width: "100%" }}
                      showSearch
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {this.state.pccData.map(function(item) {
                        return (
                          <Option key={item.pccId} value={item.pccId}>
                            {item.code}
                          </Option>
                        );
                      })}
                    </Select>
                  )}
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={16} lg={16} xl={16}>
                <Form.Item
                  {...formItemLayout}
                  label={<IntlMessages id="admin.organization.accountnumber" />}
                >
                  {getFieldDecorator("account_number", {
                    rules: [],
                  })(
                    <Transfer
                      dataSource={dataAccount}
                      showSearch
                      filterOption={this.filterOption}
                      targetKeys={this.state.targetKeys}
                      onChange={this.handleChangeTransfer}
                      onSearch={this.handleSearch}
                      render={(item) => item.account}
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={24} style={{ marginBottom: "40px" }}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Checkbox
                  onChange={this.onChange}
                  checked={this.props.tarvellerexits}
                >
                  <IntlMessages id="admin.oraganization.travelmanagercheckbox" />
                </Checkbox>
              </Col>
            </Row>
            <Button style={{ marginRight: 8 }} onClick={() => this.backpage()}>
              <IntlMessages id="admin.oraganization.previous" />
            </Button>
            <Button type="primary" onClick={() => this.handleSubmit()}>
              <IntlMessages id="admin.oraganization.next" />
            </Button>
          </Form>
        </Spin>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return { ...state.user, ...state.language };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { ...AdminActions, ...productActions, ...OrgActions },
    dispatch
  );
}
const WrappedForm = Form.create({ name: "organization-plans" })(
  OrganizationPlan
);
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    WrappedForm
  )
);
