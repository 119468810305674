import React, { Component } from "react";
import Moment from "react-moment";
import Text from "react-text";
import { Row, Col, Checkbox, Radio, Modal } from "antd";
import moment from "moment";
const { confirm } = Modal;
export default class TravellerInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      annualChecked: props.b2c.planTerm.isAnnual,
      updateView: true,
      propsData: props.b2c,
      decodedParams: props.decodedParams,
    };
    this.onChange = this.onChange.bind(this);
    this.showConfirm = this.showConfirm.bind(this);
  }

  componentDidMount() {}

  getGender(gender) {
    if (gender == 1) {
      return "Male";
    } else if (gender == 2) {
      return "Female";
    } else if (gender == 3) {
      return "Other";
    } else if (gender == 4) {
      return "Prefer Not To Answer";
    } else {
      return "Not Filled";
    }
  }

  _bindCountries(countryIds, bindType) {
    if (this.props.b2c.planTerm.isAnnual && bindType === "travel") {
      return <li>All Countries</li>;
    } else {
      var countries = countryIds.toString().split(",");
      let countryName = "";
      // for (var i = 0; i < countries.length; i++) {
      //   debugger;
      var countryObject = this.props.b2c.allCountries.find(
        (x) => x.countryId === parseInt(countryIds) //parseInt(countries[i])
      );
      countryName =
        countryName === ""
          ? countryObject.countryName
          : countryName + ", " + countryObject.countryName;
      // }
      return <li>{countryName}</li>;
    }
  }

  showConfirm(objContent, isAnnual) {
    var self = this;
    confirm({
      title: objContent.title,
      content: objContent.content,
      className: "gray-color",
      centerd: true,
      async onOk() {
        self.setState({ annualChecked: true });
        let planTermObject = self.props.b2c.planTerm;
        planTermObject.isAnnual = isAnnual;
        await self.props.SavePlanTerm(planTermObject);
        // self.upgradePlan(isAnnual);
      },
      onCancel() {
        self.setState({ annualChecked: false });
        // self.upgradePlan(false);
      },
    });
  }

  async onChange(e) {
    var isAnnual = e.target.checked;
    if (
      this.props.b2c.personalDetails &&
      this.props.b2c.personalDetails.length > 0
    ) {
      var objContent = {};
      if (e.target.checked) {
        objContent.title =
          "Do you want the Annual Unlimited Trip Plan for all travelers?";
        objContent.content =
          "Please click OK to confirm your upgrade to the Annual Unlimited Trip Plan. ";
        this.showConfirm(objContent, isAnnual);
      } else {
        this.setState({ annualChecked: false });
        let planTermObject = this.props.b2c.planTerm;
        planTermObject.isAnnual = isAnnual;
        await this.props.SavePlanTerm(planTermObject);
      }
    }

    this.setState({ annualChecked: isAnnual });
  }

  _bindTravellerInfo(travellers) {
    var decodedParams = this.state.decodedParams;
    var plus1Year = decodedParams.start_date;
    plus1Year = moment(plus1Year)
      .add(364, "days")
      .format("YYYY-MM-DD");
    return travellers.map((traveller, idx) => (
      <div key={idx}>
        <Row gutter={24} className="marg20">
          <Col xs={24} sm={24} md={24} lg={10} xl={10}>
            <h3>{traveller.first_name + " " + (traveller.isEmailExit ? "" : traveller.last_name)}</h3>
          </Col>
        </Row>
        <hr />
        {/* Plan summary */}
        {/* <Row gutter={24}>
          <Col span={24}>
            <ul>
              <li>Travel Type</li>
              <li>{decodedParams.travel_type_label}</li>
            </ul>
          </Col>
        </Row>
        */}
        <Row gutter={24}>
          <Col span={24}>
            <ul>
              <li>Dates </li>
              <li>
                {this.props.b2c.planTerm && this.props.b2c.planTerm.dp < 120 ? (
                    <>
                      {moment(decodedParams.start_date).format("MM/DD/YYYY")} -{" "}
                      {this.props.b2c.planTerm && this.props.b2c.planTerm.isAnnual !== false
                        ? moment(plus1Year).format("MM/DD/YYYY")
                        : moment(decodedParams.end_date).format("MM/DD/YYYY")}
                    </>
                  ) : (
                    <>
                      {moment(decodedParams.start_date).format("MM/DD/YYYY")} -{" "}
                        {moment(decodedParams.end_date).format("MM/DD/YYYY")}
                    </>
                  )}
                </li>
            </ul>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <ul>
              <li>Destinations</li>
              <li>
                {decodedParams.countries_list + " "}
                {decodedParams.us_travel_states ? "(" + decodedParams.us_travel_states + ")" : ""}
              </li>
            </ul>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <ul>
              <li>Date of Birth</li>
              <li>
                {traveller.isEmailExit ? (
                  "Existing Plan Holder DOB"
                ) : !traveller.isEmailExit && traveller.dob == "Invalid date" ? (
                  "Not Filled"
                ) : (
                  <Moment format={"MM/DD/YYYY"}>{traveller.dob}</Moment>
                )}
              </li>
            </ul>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <ul>
              <li>Gender</li>
              <li>
                {traveller.isEmailExit
                  ? "Existing Plan Holder Gender"
                  : traveller.gender
                  ? this.getGender(traveller.gender)
                  : "Not Filled"}
              </li>
            </ul>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <ul>
              <li>Email</li>
              <li>{traveller.email ? traveller.email : "Not Filled"}</li>
            </ul>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <ul>
              <li>Home Country</li>
              <li>
                {traveller.isEmailExit
                  ? "Existing Plan Holder Country"
                  : traveller.country
                  ? this._bindCountries(traveller.country, "address")
                  : "Not Filled"}
              </li>
            </ul>
          </Col>
        </Row>
        {traveller.country == 249 && traveller.stateName != "" && (
          <Row gutter={24}>
            <Col span={24}>
              <ul>
                <li>State</li>
                <li>
                  {traveller.isEmailExit && traveller.stateName
                    ? "Existing State"
                    : traveller.stateName
                    ? traveller.stateName
                    : "Not Filled"}
                </li>
              </ul>
            </Col>
          </Row>
        )}
        <Row gutter={24}>
          <Col span={24}>
            <ul>
              <li>Phone Number</li>
              <li>
                {traveller.isEmailExit
                  ? "Existing Plan Holder Phone No."
                  : traveller.mobile_number
                  ? traveller.mobile_number
                  : "Not Filled"}
              </li>
            </ul>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <ul>
              <li>Company Name</li>
              <li>{traveller.company_name && traveller.company_name !== "" ? traveller.company_name : "Not Filled"}</li>
            </ul>
          </Col>
        </Row>
      </div>
    ));
  }
  render() {
    return (
      <div className='plansummary-check'>
        <div className='row'>
          <div className='half-width1'>
            <h3>Plan Summary</h3>
            <Checkbox checked={this.state.annualChecked} onChange={this.onChange}>
              <b>
                Upgrade to our best value year-long plan for only{' '}
                {this.props.b2c.planTerm.tierValue == 1 && this.props.b2c.planTerm.ustravel_states == undefined
                  ? this.props.b2c.currency === 'CAD'
                    ? '$' + (1315 / 365).toFixed(2)
                    : '$' + (997 / 365).toFixed(2)
                  : this.props.b2c.currency === 'CAD'
                  ? '$' + (498 / 365).toFixed(2)
                  : '$' + (385 / 365).toFixed(2)}{' '}
                {this.props.b2c.currency} per day
              </b>
            </Checkbox>
          </div>
        </div>
        <hr></hr>
        {this._bindTravellerInfo(this.props.b2c.personalDetails)}
      </div>
    );
  }
}
