import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as travelerItineraryActions from "../../../../redux/actions/travelers-itinerary-action";
import moment from "moment";
import { Tooltip } from "antd";

const dateFormat = "MM/DD/YYYY HH:mm";
const orgDateFormat = "HH:mm";

let flightStatus = {
  L: "LANDED",
  LANDED: "LANDED",
  SCHEDULED: "SCHEDULED",
  S: "SCHEDULED",
  A: "In-Transit"
};

let travelerItineraries = {};
let hotelCount = 0;
let carCount = 0;
let flightCount = 0;

class TravelerManualItineraryViewer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataLoaded: false,
      travelerEmail: "",
      pnrId: 0,
      itineraryDetails: "",
      itineraryHeader: "",
      itineraryOrganization: "",
      itineraryRemarks: "",
      itineraryCars: "",
      userDetail: {},
      emailaddress: []
    };
  }
  componentDidMount() {
    if (
      this.props.match.params &&
      this.props.match.params.pnrId
    ) {
      this.props
        .getManualItineraryByPnrIdAndEmail({
          pnrId: this.props.match.params.pnrId
        })
        .then(resp => {
          this._bindItineraryDetails(resp);
        })
        .catch(ex => {
          this.setState({ loading: false });
        });
    }
  }

  _bindItineraryDetails(data) {
    let emailadd = [];
    data.emailAddresses.forEach(function(email) {
      let ema = email + " ";
      emailadd.push(ema);
    });
    
      data.flights &&
        data.flights.map((flight, i) => {
          travelerItineraries[i] = [];
          travelerItineraries[i].push({
            key: "Flight",
            value: flight
          });
        });
    
    
      data.hotels &&
        data.hotels.map((hotel, i) => {
          travelerItineraries[hotel.pnrSegmentNumber] = [];
          travelerItineraries[hotel.pnrSegmentNumber].push({
            key: "Hotel",
            value: hotel
          });
        });
    
    
      data.cars &&
        data.cars.map((car, i) => {
          travelerItineraries[car.pnrSegmentNumber] = [];
          travelerItineraries[car.pnrSegmentNumber].push({
            key: "Car",
            value: car
          });
        });
    
       
    console.log('travelerItineraries',travelerItineraries)
    this.setState({
      itineraryHeader: data,
      //itineraryOrganization: data.organization,
      itineraryRemarks: data.remarks,
      itineraryCars: data.cars,
      itineraryDetails: travelerItineraries,
      emailaddress: emailadd,
      userDetail: data,
      dataLoaded: true
    });
  }

  render() {
      
    return (
      this.state.dataLoaded && (
        <div style={{ overflowX: "auto" }}>
          {this.state.itineraryHeader && (
            <table className="itinerary-view" style={{ width: "100%" }}>
              <tr>
                <th colSpan={4}>Passenger Info</th>
              </tr>
              <tr>
                <td className="title">First Name:</td>
                <td>{this.state.userDetail.firstName}</td>
                <td className="title">Last Name</td>
                <td>{this.state.userDetail.lastName}</td>
              </tr>
              <tr>
                <th colSpan={4}>PNR General Info</th>
              </tr>
              <tr>
               
                <td className="title">Invoice:</td>
                <td>Itinerary</td>
                <td className="title">Org:</td>
                <td>{this.state.userDetail.orgName}</td>
                {/* <td ></td>
                <td></td> */}
              </tr>
              {/* <tr>
                <td className="title">Customer:</td>
                <td>{this.state.userDetail.accountNumber}</td>
                
              </tr> */}
            </table>
          )}

          {this.state.itineraryDetails
            ? (((flightCount = 0), (hotelCount = 0), (carCount = 0)),
              Object.entries(this.state.itineraryDetails).map(([key, data]) => {                if (data[0].key === "Flight") {
                    flightCount++;
                    return(
                        <table className="itinerary-view" style={{ width: "100%" }}>
                        <tr>
                          <th colSpan={4}>
                            <span>{flightCount + " "}</span>
                            {data[0].key}
                          </th>
                        </tr>
                        <tr>
                        <td colSpan={4}>
                          {moment(data[0].value.departureDateTime).format(
                            dateFormat
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td className="title">Air Vendor:</td>
                        <td> 
                          {data[0].value.airVendorCode =="" || data[0].value.airVendorCode == null && data[0].value.airVendor =="" || data[0].value.airVendor == null ? "Not Available" :
                          data[0].value.airVendorCode + " " + data[0].value.airVendor}
                        </td>
                        <td className="title">Flight Number:</td>
                        <td>{data[0].value.flightNumber ? data[0].value.flightNumber : "Not Available"}</td>
                      </tr>
                      <tr>
                        <td className="title">Leaving From:</td>
                        <td>
                          {data[0].value.departureAirportCode +
                            " " +
                            data[0].value.departureAirport}
                        </td>
                        <td className="title">Departs:</td>
                        <td>
                          {moment(data[0].value.departureDateTime).format(
                            dateFormat
                          )}
                          {data[0].value.departureOriginalDatetime &&
                          data[0].value.departureOriginalDatetime !==
                            data[0].value.departureDateTime ? (
                            <span style={{ paddingLeft: "5px" }}>
                              original
                              {" " +
                                moment(
                                  data[0].value.departureOriginalDatetime
                                ).format(orgDateFormat)}
                            </span>
                          ) : (
                            ""
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td className="title">Arriving To:</td>
                        <td>
                          {data[0].value.arrivalAirportCode +
                            " " +
                            data[0].value.arrivalAirport}
                        </td>
                        <td className="title">Arrives:</td>
                        <td>
                          {moment(data[0].value.arrivalDateTime).format(
                            dateFormat
                          )}
                          {data[0].value.arrivalOriginalDatetime &&
                          data[0].value.arrivalOriginalDatetime !==
                            data[0].value.arrivalDateTime ? (
                            <span style={{ paddingLeft: "5px" }}>
                              original
                              {" " +
                                moment(
                                  data[0].value.arrivalOriginalDatetime
                                ).format(orgDateFormat)}
                            </span>
                          ) : (
                            ""
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td className="title">Passengers:</td>

                        <td>
                          <Tooltip
                            placement="topLeft"
                            title={this.state.emailaddress}
                          >
                            {this.state.userDetail.emailAddresses.length}
                            <i
                              style={{ marginLeft: "5px" }}
                              className="fas fa-info-circle"
                            ></i>
                          </Tooltip>
                        </td>
                        <td className="title">Flight Status:</td>
                        <td>{data[0].value.status}</td>
                      </tr>
                      
                        </table>
                    )
                    
                }}))
            : ""}

          {this.state.itineraryRemarks &&
            this.state.itineraryRemarks.map((remark, i) => (
              <table className="itinerary-view" style={{ width: "100%" }}>
                {i == 0 && (
                  <tr>
                    <th colSpan={4}>REMARKS</th>
                  </tr>
                )}

                <tr>
                  <td>{remark.text}</td>
                </tr>
              </table>
            ))}
        </div>
      )
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...travelerItineraryActions }, dispatch);
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    TravelerManualItineraryViewer
  )
);
