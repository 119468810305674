import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import IntlMessages from "../../../../services/intlMesseges";
import AppLocale from "../../../../languageProvider";
import {
  Typography,
  Form,
  Input,
  Select,
  Row,
  Col,
  Checkbox,
  Button,
} from "antd";
import * as userActions from "../../../../redux/actions/user-actions";

import * as b2cActions from "../../../../redux/actions/b2c-actions";
import { notifyUser } from "../../../../services/notification-service";
import * as adminActions from "../../../../redux/actions/admin-actions";

var validator = require("email-validator");

const { Option } = Select;

class PrimaryAddress extends Component {
  constructor(props) {
    super(props);
    this.state = {
      countries: [],
      userData: [],
      stateuser: 1,
      disablecheck: false,
      disablecheck2: false,
    };
    this.bill_checkchange = this.bill_checkchange.bind(this);
    this.sec_checkchange = this.sec_checkchange.bind(this);
  }

  async componentDidMount() {
    this.props.getCountriesListName().then((data) => {
      this.setState({ countries: data });
      this.setState({ loading: true });
    });
    await this.props.getUserData();
    // if (this.props.isBusinessAddressSame === true) {
    //   this.setState({ address: true })
    // } else {
    //   this.setState({ address: false })

    // }
    this.props
      .getUserListing({
        orgId: 1,
        // filters: filters,
        // pagination: { page: pagination.current, pageSize: pagination.pageSize },
        // sorter: sorter
      })
      .then((resp) => {
        this.setState({
          userData: resp.data,
        });
      })
      .catch((ex) => {
        this.setState({ loading: false });
      });
  }
  onChange(e) {}
  // this.handleTableChange({ current: 1, pageSize: 10 }, {}, {});

  loadAccountManagers = (e) => {
    if (e.target.scrollTop + e.target.offsetHeight >= e.target.scrollHeight) {
      var datas = this.state.stateuser + 1;
      this.props
        .getUserListing({
          orgId: 1,
          filters: {},
          pagination: { page: datas, pageSize: 10 },
          sorter: {},
        })
        .then((resp) => {
          let data = this.state.userData;
          this.setState({ stateuser: datas });
          this.setState({
            userData: data.concat(resp.data),
          });
        })
        .catch((ex) => {
          this.setState({ loading: false });
        });
    }
  };
  fetchAccountmanagers = (e) => {
    if (e === "" || e === null) {
      this.setState({ stateuser: 1 });
    }
    this.props
      .getUserListing({
        orgId: 1,
        filters: { firstName: e },
        pagination: { page: 1, pageSize: 10 },
        sorter: {},
      })
      .then((resp) => {
        this.setState({
          fetching: false,
          userData: resp.data,
        });
      })
      .catch((ex) => {
        this.setState({ fetching: false });
      });
  };

  // final submit
  async finalform() {
    const { language } = this.props;
    const currentAppLocale = AppLocale[language.locale];
    if (validator.validate(this.props.email)) {
      await this.props.validateTravellerEmail(this.props.email);
      if (this.props.emailCheckResponse.userExist) {
        notifyUser(
          currentAppLocale.messages["admin.email.alreadyexist"],
          "error"
        );
        //notifyUser("Email Already Exists!", 'error');
      } else {
        this.props.onchange("current", 1);
      }
    }
  }
  // button submit
  handleSubmit() {
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        Object.keys(values).map(function(key, _idx) {
          if (values[key] == null || values[key] == " ") {
            values[key] = "";
          } else {
            if (typeof values[key] == "string") {
              values[key] = values[key].trim();
            }
          }
        });
        this.finalform();
      }
    });
  }
  //email validation checker
  handleEmailExistance = (rule, value, callback) => {
    if (validator.validate(this.props.email)) {
      this.props.validateTravellerEmail(this.props.email);
      if (this.props.emailCheckResponse.userExist) {
        callback(<IntlMessages id="admin.email.alreadyexist" />);
      } else {
        callback();
      }
    }

    callback();
  };
  sec_checkchange(e) {
    this.props.onchange(e);
    if (e.target.checked === true) {
      this.props.form.setFieldsValue({
        sec_address: this.props.address,
        sec_city: this.props.city,
        sec_country: this.props.country,
        sec_zip: this.props.zip,
        sec_phoneNumber: this.props.phoneNumber,
        sec_email: this.props.email,
        sec_state: this.props.company_state,
        secondary_contactPerson: this.props.firstName.concat(
          " ".concat(this.props.lastName)
        ),
      });
    } else {
      this.props.form.setFieldsValue({
        sec_address: "",
        sec_city: "",
        sec_country: "",
        sec_zip: "",
        sec_phoneNumber: "",
        sec_email: "",
        sec_state: "",
        secondary_contactPerson: "",
      });
    }
    this.setState({ disablecheck: e.target.checked || false });
  }
  bill_checkchange(e) {
    this.props.onchange(e);
    if (e.target.checked === true) {
      this.props.form.setFieldsValue({
        billing_address: this.props.address,
        billing_city: this.props.city,
        billing_country: this.props.country,
        billing_zip: this.props.zip,
        billing_phoneNumber: this.props.phoneNumber,
        billing_email: this.props.email,
        billing_state: this.props.company_state,
        billing_contactPerson: this.props.firstName.concat(
          " ".concat(this.props.lastName)
        ),
        billing_company_name: this.props.company_name,
      });
    } else {
      this.props.form.setFieldsValue({
        billing_address: "",
        billing_city: "",
        billing_country: "",
        billing_zip: "",
        billing_phoneNumber: "",
        billing_email: "",
        billing_state: "",
        billing_contactPerson: "",
      });
    }
    this.setState({ disablecheck2: e.target.checked || false });
  }
  handleContactExistance(rule, value, callback) {
    //const re = /^[0-9']+$/;
    const re = /^(?=[+0-9]*$)(?!.*[<>'"/;`%~@#$^*()_=[\]{}|\\,.?: -])/;
    var test = re.test(value);
    if (value !== "" && (value.length < 6 || test === false)) {
      callback(<IntlMessages id="admin.phonenumber.valid" />);
    } else {
      callback();
    }
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const { formLayout } = this.props;
    const formItemLayout =
      formLayout === "horizontal"
        ? {
            labelCol: { span: 4 },
            wrapperCol: { span: 14 },
          }
        : null;
    return (
      <div style={{ marginTop: "50px" }}>
        <Row gutter={24}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Typography.Title level={4}>
              <IntlMessages id="admin.primary-address" />
            </Typography.Title>
          </Col>
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={12}
            style={{ textAlign: "right" }}
          >
            <Button type="primary" onClick={() => this.props.history.goBack()}>
              <IntlMessages id="admin.editorganization.back" />
            </Button>
          </Col>
        </Row>
        <hr />
        <Form layout={formLayout} onSubmit={this.handleSubmit}>
          <Row gutter={24}>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <Form.Item
                {...formItemLayout}
                label={<IntlMessages id="admin.primaryaddress.name" />}
              >
                {getFieldDecorator("company_name", {
                  rules: [
                    {
                      required: true,
                      message: (
                        <IntlMessages id="admin.input.required"></IntlMessages>
                      ),
                      whitespace: true,
                    },
                  ],
                  initialValue:
                    this.props.company_name === null
                      ? ""
                      : this.props.company_name,
                  onChange: (e) => {
                    this.props.onchange(e);
                  },
                  value: this.props.data.company_name,
                })(<Input maxlength="50" />)}
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <Form.Item
                {...formItemLayout}
                label={<IntlMessages id="admin.primaryaddress.company_code" />}
              >
                {getFieldDecorator("company_code", {
                  rules: [
                    {
                      required: true,
                      whitespace: true,
                      message: (
                        <IntlMessages id="admin.input.required"></IntlMessages>
                      ),
                    },
                  ],
                  initialValue:
                    this.props.company_code === null
                      ? ""
                      : this.props.company_code,
                  onChange: (e) => {
                    this.props.onchange(e);
                  },
                  value: this.props.data.company_code,
                })(<Input maxLength={4} />)}
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <Form.Item
                {...formItemLayout}
                label={<IntlMessages id="admin.primaryaddress.customerId" />}
              >
                {getFieldDecorator("customer_id", {
                  rules: [
                    {
                      whitespace: true,
                      message: (
                        <IntlMessages id="admin.input.required"></IntlMessages>
                      ),
                    },
                  ],
                  initialValue:
                    this.props.customer_id === null
                      ? ""
                      : this.props.customer_id,
                  onChange: (e) => {
                    this.props.onchange(e);
                  },
                  value: this.props.data.customer_id,
                })(<Input minlength="4" maxlength="10" />)}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <Form.Item
                {...formItemLayout}
                label={<IntlMessages id="admin.primaryaddress.jobnumber" />}
              >
                {getFieldDecorator("job_number", {
                  rules: [
                    {
                      whitespace: true,
                      message: (
                        <IntlMessages id="admin.input.required"></IntlMessages>
                      ),
                    },
                  ],
                  initialValue:
                    this.props.job_number === null ? "" : this.props.job_number,
                  onChange: (e) => {
                    this.props.onchange(e);
                  },
                  value: this.props.data.job_number,
                })(<Input />)}
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <Form.Item
                {...formItemLayout}
                label={<IntlMessages id="admin.primaryaddress.address" />}
              >
                {getFieldDecorator("address", {
                  rules: [
                    {
                      whitespace: true,
                      required: true,
                      message: (
                        <IntlMessages id="admin.input.required"></IntlMessages>
                      ),
                    },
                  ],
                  initialValue:
                    this.props.address === null ? "" : this.props.address,
                  onChange: (e) => {
                    this.props.onchange(e);
                  },
                  value: this.props.data.address,
                })(<Input />)}
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <Form.Item
                label={<IntlMessages id="admin.primaryaddress.country" />}
              >
                {getFieldDecorator("country", {
                  rules: [
                    {
                      required: true,
                      message: (
                        <IntlMessages id="admin.input.required"></IntlMessages>
                      ),
                    },
                  ],
                  onChange: (e) => {
                    this.props.onchange(e, "country");
                  },
                  initialValue: this.props.country,
                })(
                  <Select
                    showSearch
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {this.state.countries.map(function(item) {
                      return (
                        <Option key={item.countryId} value={item.countryId}>
                          {item.countryName}
                        </Option>
                      );
                    })}
                  </Select>
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <Form.Item
                {...formItemLayout}
                label={<IntlMessages id="admin.primaryaddress.state" />}
              >
                {getFieldDecorator("company_state", {
                  rules: [
                    {
                      whitespace: true,
                      required: true,
                      message: (
                        <IntlMessages id="admin.input.required"></IntlMessages>
                      ),
                    },
                    {
                      pattern: new RegExp(/^[ a-zA-Z]+$/),
                      message: (
                        <IntlMessages id="admin.state.valid"></IntlMessages>
                      ),
                    },
                  ],

                  initialValue:
                    this.props.company_state === null
                      ? ""
                      : this.props.company_state,
                  onChange: (e) => {
                    this.props.onchange(e);
                  },
                  value: this.props.data.company_state,
                })(<Input maxLength={20} />)}
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <Form.Item
                {...formItemLayout}
                label={<IntlMessages id="admin.primaryaddress.city" />}
              >
                {getFieldDecorator("city", {
                  rules: [
                    {
                      whitespace: true,
                      required: true,
                      message: (
                        <IntlMessages id="admin.input.required"></IntlMessages>
                      ),
                    },
                    {
                      pattern: new RegExp(/^[ a-zA-Z]+$/),
                      message: (
                        <IntlMessages id="admin.city.valid"></IntlMessages>
                      ),
                    },
                  ],

                  initialValue: this.props.city === null ? "" : this.props.city,
                  onChange: (e) => {
                    this.props.onchange(e);
                  },
                  value: this.props.data.city,
                })(<Input maxLength={20} />)}
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <Form.Item
                {...formItemLayout}
                label={<IntlMessages id="admin.primaryaddress.zip" />}
              >
                {getFieldDecorator("zip", {
                  rules: [
                    {
                      required: true,
                      message: (
                        <IntlMessages id="admin.input.required"></IntlMessages>
                      ),
                    },
                    {
                      pattern: new RegExp(/^[a-zA-Z0-9]+$/),
                      message: (
                        <IntlMessages id="admin.zip.valid"></IntlMessages>
                      ),
                    },
                    {
                      max: 10,
                      message: (
                        <IntlMessages id="admin.zip.valid"></IntlMessages>
                      ),
                    },
                    {
                      min: 4,
                      message: (
                        <IntlMessages id="admin.zip.valid"></IntlMessages>
                      ),
                    },
                  ],
                  initialValue: this.props.zip === null ? "" : this.props.zip,
                  onChange: (e) => {
                    this.props.onchange(e);
                  },
                  value: this.props.data.zip,
                })(<Input minLength={4} maxLength={10} />)}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <Form.Item
                {...formItemLayout}
                label={<IntlMessages id="admin.primaryaddress.phonenumber" />}
              >
                {getFieldDecorator("phoneNumber", {
                  rules: [
                    {
                      validator: this.handleContactExistance,
                    },
                    {
                      required: true,
                      message: <IntlMessages id="admin.input.required" />,
                    }, // {
                    //   pattern: new RegExp(/^[0-9']+$/),
                    //   message: 'Please enter a valid number!'
                    // },
                    // {
                    //   max: 12,
                    //   message: "Please enter a valid number!"
                    // },
                    // {
                    //   min: 8,
                    //   message: "Please enter a valid number!"
                    // }
                  ],
                  initialValue:
                    this.props.phoneNumber === null
                      ? ""
                      : this.props.phoneNumber,
                  onChange: (e) => {
                    this.props.onchange(e);
                  },
                  value: this.props.data.phoneNumber,
                })(<Input maxLength={15} />)}
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <Form.Item
                {...formItemLayout}
                label={<IntlMessages id="admin.primaryaddress.email" />}
              >
                {this.props.emailCheckResponse.userExist}
                {getFieldDecorator("email", {
                  rules: [
                    {
                      type: "email",
                      message: <IntlMessages id="admin.email.valid" />,
                    },
                    {
                      required: true,
                      message: (
                        <IntlMessages id="admin.input.required"></IntlMessages>
                      ),
                    },
                    {
                      validator: this.handleEmailExistance,
                    },
                  ],
                  initialValue:
                    this.props.email === null ? "" : this.props.email,
                  onBlur: (e) => {
                    this.handleEmailExistance(e);
                  },
                  onChange: (e) => {
                    this.props.onchange(e);
                  },
                  value: this.props.data.email,
                })(<Input />)}
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <Form.Item
                {...formItemLayout}
                label={<IntlMessages id="admin.userlisting.firstName" />}
              >
                {getFieldDecorator("firstName", {
                  rules: [
                    {
                      required: true,
                      message: (
                        <IntlMessages id="admin.input.required"></IntlMessages>
                      ),
                    },
                    {
                      pattern: new RegExp(/^(\S.*)[a-zA-Z-.']+$/),
                      message: (
                        <IntlMessages id="admin.name.valid"></IntlMessages>
                      ),
                    },
                  ],
                  initialValue:
                    this.props.firstName === null ? "" : this.props.firstName,
                  onChange: (e) => {
                    this.props.onchange(e);
                  },
                  value: this.props.data.firstName,
                })(<Input maxLength={50} />)}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <Form.Item
                {...formItemLayout}
                label={<IntlMessages id="admin.userlisting.lastName" />}
              >
                {getFieldDecorator("lastName", {
                  rules: [
                    {
                      required: true,
                      message: (
                        <IntlMessages id="admin.input.required"></IntlMessages>
                      ),
                    },
                    {
                      pattern: new RegExp(/^(\S.*)[a-zA-Z-.']+$/),
                      message: (
                        <IntlMessages id="admin.lname.valid"></IntlMessages>
                      ),
                    },
                  ],
                  initialValue:
                    this.props.lastName === null ? "" : this.props.lastName,
                  onChange: (e) => {
                    this.props.onchange(e);
                  },
                  value: this.props.data.lastName,
                })(<Input maxLength={50} />)}
              </Form.Item>
            </Col>
            {/* <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <Form.Item
                {...formItemLayout}
                label={
                  <IntlMessages id="admin.primaryaddress.accountmanager" />
                }
              >
                {getFieldDecorator("accountmanager", {
                  rules: [
                    {
                      required: true,
                      message: <IntlMessages id ="admin.input.required"></IntlMessages>
                    }
                  ],
                  initialValue:
                    this.props.accountmanager === null
                      ? ""
                      : this.props.accountmanager,
                  onChange: e => {
                    this.props.onchange(e, 'accountmanager');
                  },
                  value: this.props.data.accountmanager
                })(
                  <Select
                    
                    onPopupScroll={this.loadAccountManagers}
                    showSearch
                    filterOption={false}
                    onSearch={this.fetchAccountmanagers}
                  >
                    {this.state.userData ? this.state.userData.map(function (item) {
                      return (

                        <Option key={item.userId} value={item.userId}>
                          {item.firstName} {item.lastName}
                        </Option>
                      );
                    }) : ''}
                  </Select>
                )}
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <Form.Item
                {...formItemLayout}
                label={
                  <IntlMessages id="admin.primaryaddress.salesexecutive" />
                }
              >
                {getFieldDecorator("salesexecutive", {
                  rules: [
                    {
                      required: true,
                      message: <IntlMessages id ="admin.input.required"></IntlMessages>
                    }
                  ],
                  initialValue:
                    this.props.salesexecutive === null
                      ? ""
                      : this.props.salesexecutive,
                  onChange: e => {
                    this.props.onchange(e, "salesexecutive");
                  },
                  value: this.props.data.salesexecutive
                })(
                  <Select
                    onPopupScroll={this.loadAccountManagers}
                    showSearch
                    filterOption={false}
                    onSearch={this.fetchAccountmanagers}
                  >
                    {this.state.userData ? this.state.userData.map(function (item) {
                      return (
                        <Option key={item.userId} value={item.userId}>
                          {item.firstName} {item.lastName}
                        </Option>
                      );
                    }) : ''}
                  </Select>
                )}
              </Form.Item>
            </Col> */}
          </Row>
          {/* Primary address ends */}
          {/* Secondary Address */}
          <Row gutter={24}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Typography.Title level={4}>
                <IntlMessages id="admin.secondary-address" />
              </Typography.Title>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={12}
              style={{ textAlign: "right" }}
            >
              <Checkbox
                id="secAddress"
                name="secAddress"
                onChange={this.sec_checkchange}
                checked={this.props.isCommunicationAddressSame}
              >
                <IntlMessages id="admin.sameasinformation.content" />
              </Checkbox>
            </Col>
          </Row>
          <hr></hr>

          <Row gutter={24}>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <Form.Item
                label={<IntlMessages id="admin.primaryaddress.country" />}
              >
                {getFieldDecorator("sec_country", {
                  rules: [
                    {
                      required: true,
                      message: (
                        <IntlMessages id="admin.input.required"></IntlMessages>
                      ),
                    },
                  ],
                  onChange: (e) => {
                    this.props.onchange(e, "sec_country");
                  },
                  initialValue: this.props.sec_country,
                })(
                  <Select
                    disabled={this.props.isCommunicationAddressSame}
                    showSearch
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {this.state.countries.map(function(item) {
                      return (
                        <Option key={item.countryId} value={item.countryId}>
                          {item.countryName}
                        </Option>
                      );
                    })}
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <Form.Item
                {...formItemLayout}
                label={<IntlMessages id="admin.primaryaddress.state" />}
              >
                {getFieldDecorator("sec_state", {
                  rules: [
                    {
                      whitespace: true,
                      required: true,
                      message: (
                        <IntlMessages id="admin.input.required"></IntlMessages>
                      ),
                    },
                    {
                      pattern: new RegExp(/^[ a-zA-Z]+$/),
                      message: (
                        <IntlMessages id="admin.state.valid"></IntlMessages>
                      ),
                    },
                  ],

                  initialValue:
                    this.props.sec_state === null ? "" : this.props.sec_state,
                  onChange: (e) => {
                    this.props.onchange(e);
                  },
                  value: this.props.data.sec_state,
                })(
                  <Input
                    maxLength={20}
                    disabled={this.props.isCommunicationAddressSame}
                  />
                )}
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <Form.Item
                {...formItemLayout}
                label={<IntlMessages id="admin.primaryaddress.city" />}
              >
                {getFieldDecorator("sec_city", {
                  rules: [
                    {
                      whitespace: true,
                      required: true,
                      message: (
                        <IntlMessages id="admin.input.required"></IntlMessages>
                      ),
                    },
                    {
                      pattern: new RegExp(/^[ a-zA-Z]+$/),
                      message: (
                        <IntlMessages id="admin.city.valid"></IntlMessages>
                      ),
                    },
                  ],
                  initialValue:
                    this.props.sec_city === null ? "" : this.props.sec_city,
                  onChange: (e) => {
                    this.props.onchange(e);
                  },
                  value: this.props.data.sec_city,
                })(
                  <Input
                    maxLength={20}
                    disabled={this.props.isCommunicationAddressSame}
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <Form.Item
                {...formItemLayout}
                label={<IntlMessages id="admin.primaryaddress.address" />}
              >
                {getFieldDecorator("sec_address", {
                  rules: [
                    {
                      whitespace: true,
                      required: true,
                      message: (
                        <IntlMessages id="admin.input.required"></IntlMessages>
                      ),
                    },
                  ],
                  initialValue:
                    this.props.sec_address === null
                      ? ""
                      : this.props.sec_address,
                  onChange: (e) => {
                    this.props.onchange(e);
                  },
                  value: this.props.data.sec_address,
                })(<Input disabled={this.props.isCommunicationAddressSame} />)}
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <Form.Item
                {...formItemLayout}
                label={<IntlMessages id="admin.primaryaddress.zip" />}
              >
                {getFieldDecorator("sec_zip", {
                  rules: [
                    {
                      required: true,
                      message: (
                        <IntlMessages id="admin.input.required"></IntlMessages>
                      ),
                    },
                    {
                      pattern: new RegExp(/^[a-zA-Z0-9]+$/),
                      message: (
                        <IntlMessages id="admin.zip.valid"></IntlMessages>
                      ),
                    },
                    {
                      max: 10,
                      message: (
                        <IntlMessages id="admin.zip.valid"></IntlMessages>
                      ),
                    },
                    {
                      min: 4,
                      message: (
                        <IntlMessages id="admin.zip.valid"></IntlMessages>
                      ),
                    },
                  ],
                  initialValue:
                    this.props.sec_zip === null ? "" : this.props.sec_zip,
                  onChange: (e) => {
                    this.props.onchange(e);
                  },
                  value: this.props.data.sec_zip,
                })(
                  <Input
                    minLength={4}
                    maxLength={10}
                    disabled={this.props.isCommunicationAddressSame}
                  />
                )}
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <Form.Item
                {...formItemLayout}
                label={<IntlMessages id="admin.primaryaddress.phonenumber" />}
              >
                {getFieldDecorator("sec_phoneNumber", {
                  rules: [
                    {
                      validator: this.handleContactExistance,
                    },
                    {
                      required: true,
                      message: (
                        <IntlMessages id="admin.input.required"></IntlMessages>
                      ),
                    }, //{
                    // {
                    //   pattern: new RegExp(/^[0-9']+$/),
                    //   message: 'Please enter a valid number!'
                    // },
                    // {
                    //   max: 12,
                    //   message: "Please enter a valid number!"
                    // },
                    // {
                    //   min: 8,
                    //   message: "Please enter a valid number!"
                    // }
                  ],
                  initialValue:
                    this.props.sec_phoneNumber === null
                      ? ""
                      : this.props.sec_phoneNumber,
                  onChange: (e) => {
                    this.props.onchange(e);
                  },
                  value: this.props.data.sec_phoneNumber,
                })(
                  <Input
                    maxLength={15}
                    disabled={this.props.isCommunicationAddressSame}
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <Form.Item
                {...formItemLayout}
                label={<IntlMessages id="admin.primaryaddress.email" />}
              >
                {getFieldDecorator("sec_email", {
                  rules: [
                    {
                      type: "email",
                      message: <IntlMessages id="admin.email.valid" />,
                    },
                    {
                      required: true,
                      message: (
                        <IntlMessages id="admin.input.required"></IntlMessages>
                      ),
                    },
                    // {
                    //   validator: this.handleEmailExistance
                    // }
                  ],
                  initialValue:
                    this.props.sec_email === null ? "" : this.props.sec_email,
                  onChange: (e) => {
                    this.props.onchange(e);
                  },

                  value: this.props.data.sec_email,
                })(<Input disabled={this.props.isCommunicationAddressSame} />)}
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <Form.Item
                {...formItemLayout}
                label={<IntlMessages id="admin.primaryaddress.contactPerson" />}
              >
                {getFieldDecorator("secondary_contactPerson", {
                  rules: [
                    {
                      whitespace: true,
                      required: true,
                      message: (
                        <IntlMessages id="admin.input.required"></IntlMessages>
                      ),
                    },
                    {
                      pattern: new RegExp(/^(\S.*)[a-zA-Z-.']+$/),
                      message: (
                        <IntlMessages id="admin.contactperson.valid"></IntlMessages>
                      ),
                    },
                  ],
                  initialValue:
                    this.props.secondary_contactPerson === null
                      ? ""
                      : this.props.secondary_contactPerson,
                  onChange: (e) => {
                    this.props.onchange(e);
                  },
                  value: this.props.data.secondary_contactPerson,
                })(
                  <Input
                    maxLength={50}
                    disabled={this.props.isCommunicationAddressSame}
                  />
                )}
              </Form.Item>
            </Col>{" "}
          </Row>
          {/* Secondary Address ends  */}
          {/* Billing Address */}
          <Row gutter={24}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Typography.Title level={4}>
                <IntlMessages id="admin.billing-address" />
              </Typography.Title>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={12}
              style={{ textAlign: "right" }}
            >
              <Checkbox
                id="billAddress"
                name="billAddress"
                onChange={this.bill_checkchange}
                checked={this.props.isBusinessAddressSame}
              >
                <IntlMessages id="admin.sameasinformation.content" />
              </Checkbox>
            </Col>
          </Row>
          <hr></hr>
          <Row gutter={24}>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <Form.Item
                {...formItemLayout}
                label={
                  <IntlMessages id="admin.primaryaddress.billing_Company_name" />
                }
              >
                {getFieldDecorator("billing_company_name", {
                  rules: [
                    {
                      whitespace: true,
                      required: true,
                      message: (
                        <IntlMessages id="admin.input.required"></IntlMessages>
                      ),
                    },
                  ],
                  initialValue:
                    this.props.billing_company_name === null
                      ? ""
                      : this.props.billing_company_name,
                  onChange: (e) => {
                    this.props.onchange(e);
                  },
                  value: this.props.data.billing_company_name,
                })(
                  <Input
                    maxLength={30}
                    disabled={
                      this.props.isBusinessAddressSame &&
                      this.props.isBusinessAddressSame
                    }
                  />
                )}
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <Form.Item
                label={<IntlMessages id="admin.primaryaddress.country" />}
              >
                {getFieldDecorator("billing_country", {
                  rules: [
                    {
                      required: true,
                      message: (
                        <IntlMessages id="admin.input.required"></IntlMessages>
                      ),
                    },
                  ],
                  onChange: (e) => {
                    this.props.onchange(e, "billing_country");
                  },
                  initialValue: this.props.billing_country,
                })(
                  <Select
                    disabled={this.props.isBusinessAddressSame}
                    showSearch
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {this.state.countries.map(function(item) {
                      return (
                        <Option key={item.countryId} value={item.countryId}>
                          {item.countryName}
                        </Option>
                      );
                    })}
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <Form.Item
                {...formItemLayout}
                label={<IntlMessages id="admin.primaryaddress.state" />}
              >
                {getFieldDecorator("billing_state", {
                  rules: [
                    {
                      whitespace: true,
                      required: true,
                      message: (
                        <IntlMessages id="admin.input.required"></IntlMessages>
                      ),
                    },
                    {
                      pattern: new RegExp(/^[ a-zA-Z]+$/),
                      message: (
                        <IntlMessages id="admin.state.valid"></IntlMessages>
                      ),
                    },
                  ],
                  initialValue:
                    this.props.billing_state === null
                      ? ""
                      : this.props.billing_state,
                  onChange: (e) => {
                    this.props.onchange(e);
                  },
                  value: this.props.data.billing_state,
                })(
                  <Input
                    maxLength={20}
                    disabled={this.props.isBusinessAddressSame}
                  />
                )}
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={24}>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <Form.Item
                {...formItemLayout}
                label={<IntlMessages id="admin.primaryaddress.city" />}
              >
                {getFieldDecorator("billing_city", {
                  rules: [
                    {
                      whitespace: true,

                      required: true,
                      message: (
                        <IntlMessages id="admin.input.required"></IntlMessages>
                      ),
                    },
                    {
                      pattern: new RegExp(/^[ a-zA-Z]+$/),
                      message: (
                        <IntlMessages id="admin.city.valid"></IntlMessages>
                      ),
                    },
                  ],
                  initialValue:
                    this.props.billing_city === null
                      ? ""
                      : this.props.billing_city,
                  onChange: (e) => {
                    this.props.onchange(e);
                  },
                  value: this.props.data.billing_city,
                })(
                  <Input
                    maxLength={20}
                    disabled={this.props.isBusinessAddressSame}
                  />
                )}
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <Form.Item
                {...formItemLayout}
                label={<IntlMessages id="admin.primaryaddress.address" />}
              >
                {getFieldDecorator("billing_address", {
                  rules: [
                    {
                      whitespace: true,
                      required: true,
                      message: (
                        <IntlMessages id="admin.input.required"></IntlMessages>
                      ),
                    },
                  ],
                  initialValue:
                    this.props.billing_address === null
                      ? ""
                      : this.props.billing_address,
                  onChange: (e) => {
                    this.props.onchange(e);
                  },
                  value: this.props.billing_address,
                })(<Input disabled={this.props.isBusinessAddressSame} />)}
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <Form.Item
                {...formItemLayout}
                label={<IntlMessages id="admin.primaryaddress.zip" />}
              >
                {getFieldDecorator("billing_zip", {
                  rules: [
                    {
                      whitespace: true,
                      required: true,
                      message: (
                        <IntlMessages id="admin.input.required"></IntlMessages>
                      ),
                    },
                    {
                      pattern: new RegExp(/^[a-zA-Z0-9]+$/),
                      message: (
                        <IntlMessages id="admin.zip.valid"></IntlMessages>
                      ),
                    },
                    {
                      max: 10,
                      message: (
                        <IntlMessages id="admin.zip.valid"></IntlMessages>
                      ),
                    },
                    {
                      min: 4,
                      message: (
                        <IntlMessages id="admin.zip.valid"></IntlMessages>
                      ),
                    },
                  ],
                  initialValue:
                    this.props.billing_zip === null
                      ? ""
                      : this.props.billing_zip,
                  onChange: (e) => {
                    this.props.onchange(e);
                  },
                  value: this.props.data.billing_zip,
                })(
                  <Input
                    disabled={this.props.isBusinessAddressSame}
                    minLength={4}
                    maxLength={10}
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <Form.Item
                {...formItemLayout}
                label={<IntlMessages id="admin.primaryaddress.phonenumber" />}
              >
                {getFieldDecorator("billing_phoneNumber", {
                  rules: [
                    {
                      validator: this.handleContactExistance,
                    },
                    {
                      whitespace: true,
                      required: true,
                      message: <IntlMessages id="admin.input.required" />,
                    }, //{
                    //   pattern: new RegExp(/^[0-9']+$/),
                    //   message: 'Please enter a valid number!'
                    // },
                    // {
                    //   max: 12,
                    //   message: "Please enter a valid number!"
                    // },
                    // {
                    //   min: 8,
                    //   message: "Please enter a valid number!"
                    // }
                  ],
                  initialValue:
                    this.props.billing_phoneNumber === null
                      ? ""
                      : this.props.billing_phoneNumber,
                  onChange: (e) => {
                    this.props.onchange(e);
                  },
                  value: this.props.data.billing_phoneNumber,
                })(
                  <Input
                    disabled={this.props.isBusinessAddressSame}
                    maxLength={15}
                  />
                )}
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <Form.Item
                {...formItemLayout}
                label={<IntlMessages id="admin.primaryaddress.email" />}
              >
                {getFieldDecorator("billing_email", {
                  rules: [
                    {
                      type: "email",
                      message: <IntlMessages id="admin.email.valid" />,
                    },
                    {
                      required: true,
                      message: (
                        <IntlMessages id="admin.input.required"></IntlMessages>
                      ),
                    },
                    // {
                    //   validator: this.handleEmailExistance
                    // }
                  ],
                  initialValue:
                    this.props.billing_email === null
                      ? ""
                      : this.props.billing_email,
                  onChange: (e) => {
                    this.props.onchange(e);
                  },
                  value: this.props.data.billing_email,
                })(<Input disabled={this.props.isBusinessAddressSame} />)}
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <Form.Item
                {...formItemLayout}
                label={<IntlMessages id="admin.primaryaddress.contactPerson" />}
              >
                {getFieldDecorator("billing_contactPerson", {
                  rules: [
                    {
                      whitespace: true,
                      required: true,
                      message: (
                        <IntlMessages id="admin.input.required"></IntlMessages>
                      ),
                    },
                    {
                      pattern: new RegExp(/^(\S.*)[a-zA-Z-.']+$/),
                      message: (
                        <IntlMessages id="admin.contactperson.valid"></IntlMessages>
                      ),
                    },
                  ],
                  initialValue:
                    this.props.billing_contactPerson === null
                      ? ""
                      : this.props.billing_contactPerson,
                  onChange: (e) => {
                    this.props.onchange(e);
                  },
                  value: this.props.data.billing_contactPerson,
                })(
                  <Input
                    maxLength={30}
                    disabled={this.props.isBusinessAddressSame}
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Button type="primary" onClick={() => this.handleSubmit()}>
            <IntlMessages id="admin.oraganization.next" />
          </Button>
          {/* Billing Address ends */}
        </Form>
      </div>
    );
  }
}
PrimaryAddress.propTypes = {
  data_primary_address: PropTypes.object,
  validateTravellerEmail: PropTypes.func,
  getUserListing: PropTypes.func,
};
function mapStateToProps(state) {
  return {
    ...state.b2c,
    ...state.user,
    ...state.language,
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { ...b2cActions, ...userActions, ...adminActions },
    dispatch
  );
}
const WrappedForm = Form.create({ name: "primary-address" })(PrimaryAddress);
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    WrappedForm
  )
);
