import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import IntlMessages from "../../../services/intlMesseges";
import {
  Typography,
  Form,
  Row,
  Col,
  Select,
  Button, 
  Spin,
  Checkbox
} from "antd";
import * as B2B2COrgActions from "../../../redux/actions/b2b2c-organization-actions"
import * as OrgPlanTypeActions from "../../../redux/actions/org-plan-types"
import * as PricingActions from "../../../redux/actions/pricing-actions"
import { notifyUser } from "../../../services/notification-service";
import DerivedPricingQuickView from "../../admin-dash/pricing/view/derived-quickview";
import AppLocale from "../../../languageProvider";

const { Option } = Select;
class EditB2B2COrganizationPlan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orgData: [],
      retailPrices: [],
      selectedPriceList: null,
      planType: {
        indeterminate: false,
        checkAll: false,
        selectedPlanTypes: [],
        planTypesList: []
      },
      loading: true
    };
  }

  async componentDidMount() {
    let _retailPrices = this.state.retailPrices;
    let _planType = this.state.planType;
    let _orgData = this.state.orgData;
    await this.props
      .getPricing({
        filters: {},
        pagination: {},
        sorter: {}
      })
      .then(resp => {
        if (resp.data.length > 0) {
          let __retailPrices = [];
          resp.data.map(item => {
            if (item.calculationFor == "RetailPrice") {
              __retailPrices.push(item);
            }
          });
          _retailPrices = __retailPrices;
        }
      })
      .catch(ex => {
      });
    
    await this.props.getPlanTypes({
        filters: {},
        pagination: {},
        sorter: {}
      })
      .then(resp => {
        if (resp.data && resp.data.length > 0) {
          _planType.planTypesList = resp.data;
        }
      });

    
    if(this.props.orgData && this.props.orgData.organizationId){
      _orgData = this.props.orgData;
      if(_orgData.orgPlans && _orgData.orgPlans.length > 0){
        _planType.selectedPlanTypes = _orgData.orgPlans.map(i => parseInt(i.planTypeId));
        if(_planType.selectedPlanTypes.length === _planType.planTypesList.length){
          _planType.indeterminate = false;
          _planType.checkAll = true;
        } else {
          _planType.indeterminate = !!_planType.selectedPlanTypes.length && _planType.selectedPlanTypes.length < _planType.planTypesList.length;
        }
        this.props.form.setFieldsValue({ "orgPlans": _planType.selectedPlanTypes });
      }
      if(_orgData.b2B2CPriceId > 0){
        await this.props.getPrice(_orgData.b2B2CPriceId).then(res => {
          if (res.pricingInfo) {
            this.setState({ selectedPriceList: res.pricingInfo });
          } else {
            this.setState({ selectedPriceList: null });
          }
        });
      }
    }
    this.setState({
      orgData: _orgData,
      retailPrices: _retailPrices,
      planType: _planType,
      loading: false
    });
  }

  handleSubmit() {
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.setState({ loading: true })
        let args = this.state.orgData;
        args.orgPlans = this.state.planType.selectedPlanTypes.map(plan => { return { planTypeId: plan }});
        args.b2B2CPriceId = values.b2B2CPriceId;
        this.props
          .updateOrganizationwithId(args, parseInt(this.props.match.params.id))
          .then(async(response) => {
            if (response.error) {
              notifyUser(response.error.message, 'error');
              this.setState({ loading: false });
            } else {
              const { language } = this.props;
              const currentAppLocale = AppLocale[language.locale];
              this.props.updateStateOnChange();
              notifyUser(currentAppLocale.messages["admin.organization.edit"], 'success');
              this.setState({ loading: false });
            }
          })
          .catch(err => {
            this.setState({ loading: false });
          });
      }
    })
  }

  backpage() {
    const current = this.props.current - 1;
    this.setState({ current: current });
    this.props.onchange('current', 0)
  }

  onChangePlan = async (pricingId) => {
    await this.props.getPrice(pricingId).then(res => {
      if (res.pricingInfo) {
        this.setState({ selectedPriceList: null }, ()=> this.setState({ selectedPriceList: res.pricingInfo }));
      } else {
        this.setState({ selectedPriceList: null });
      }
    });
  }

  onChangePlanType = checkedList => {
    let _planType = this.state.planType;
    _planType.selectedPlanTypes = checkedList;
    _planType.indeterminate = !!checkedList.length && checkedList.length < _planType.planTypesList.length;
    _planType.checkAll = checkedList.length === _planType.planTypesList.length;
    this.setState({ planType: _planType });
  };

  onCheckAllChange = e => {
    let _planType = this.state.planType;
    _planType.indeterminate = false;
    _planType.checkAll = e.target.checked;
    _planType.selectedPlanTypes = e.target.checked ? _planType.planTypesList.map(l => l.autoId) : [];
    this.setState({ planType: _planType });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { formLayout } = this.state;
    return (
      <div>
        <Typography.Title level={3}>
          <IntlMessages id="admin.primary-plan" />
        </Typography.Title>
        <Spin spinning={this.state.loading}>

          <Form layout={formLayout} onSubmit={this.handleSubmit}>
            <Row gutter={24}>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Form.Item label={<IntlMessages id="admin.organizations.plantype"></IntlMessages>}>
                  {getFieldDecorator("orgPlans", {
                    rules: [
                      {
                        required: true,
                        message: <IntlMessages id="admin.input.required"></IntlMessages>
                      }
                    ]
                  })(
                    <div>
                      <Checkbox
                        indeterminate={this.state.planType.indeterminate}
                        onChange={this.onCheckAllChange}
                        checked={this.state.planType.checkAll}
                      >
                        All
                      </Checkbox>
                      <Checkbox.Group
                        options={this.state.planType.planTypesList.map(l => { return { value: l.autoId, label: l.name}} )}
                        value={this.state.planType.selectedPlanTypes}
                        onChange={this.onChangePlanType}
                      />
                    </div>
                  )}
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Form.Item label={<IntlMessages id="admin.organizations.selectplan"></IntlMessages>}>
                  {getFieldDecorator("b2B2CPriceId", {
                    rules: [
                      {
                        required: true,
                        message: <IntlMessages id="admin.input.required"></IntlMessages>
                      }
                    ],
                    onChange: this.onChangePlan,
                    initialValue: this.state.orgData ? this.state.orgData.b2B2CPriceId : null,
                    value: this.state.orgData.b2B2CPriceId
                  })(
                    <Select
                      style={{ width: "100%" }}
                      showSearch
                      filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {this.state.retailPrices ? this.state.retailPrices.map(function (item) {
                        return (
                          <Option key={item.pricingListId} value={item.pricingListId}>
                            {item.name}
                          </Option>
                        );
                      }) : ''}
                    </Select>
                  )}
                </Form.Item>
              </Col>
            </Row>
            {this.state.selectedPriceList !== null ?

              <Row gutter={24}>
                <Col xs={24} sm={24}>
                  <DerivedPricingQuickView
                    {...this.props}
                    priceList={this.state.selectedPriceList}
                  />
                </Col>
              </Row>
              : ''}

            <Button type="primary" onClick={() => this.handleSubmit()}>
              <IntlMessages id="admin.organization.submit" />
            </Button>

          </Form>
        </Spin>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return { ...state.user, ...state.language };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...B2B2COrgActions, ...PricingActions, ...OrgPlanTypeActions }, dispatch);
}
const WrappedForm = Form.create({ name: "organization-plans" })(
  EditB2B2COrganizationPlan
);
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    WrappedForm
  )
);
