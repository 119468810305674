import React, { Component } from "react";
import _CardForm from "./stripe-card-form";
import { Elements, injectStripe } from "react-stripe-elements";
const CardForm = injectStripe(_CardForm)
export default class StripeCheckout extends Component {
    render(){
        return(
            <div className="Checkout">
                <Elements>
                    <CardForm history={this.props.history}/>
                </Elements>
            </div>
        )
    }
}
