import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import logo from "../../images/logo.png";
import LoginImage from "../../images/cap-login-avatar-image.png";
import IntlMessages from "../../services/intlMesseges";
import * as userActions from "../../redux/actions/user-actions";
import * as OrgActions from "../../redux/actions/organization-actions";
import { notifyUser } from "../../services/notification-service";
import { Spin } from "antd";
import { Checkbox } from 'antd';
import { CryptoJS } from 'crypto-js';
import UserRoles from "../../user-roles";

class Login extends Component {
  constructor(props) {
    super(props);
    if (window.performance) {
      if (performance.navigation.type === 1) {
        //this.unsetTempData();
      }
    }
    this.state = {
      email: localStorage.getItem("email_submitted") || "",
      password: localStorage.getItem("password_submitted") || "",
      remember_me: false,
      submitted: false,
      referer: "",
    };
    this.signIn = this.signIn.bind(this);
  }

  componentDidMount() {
    if (this.props.isLoggedIn === true) {
      this.props.history.push(JSON.parse(localStorage.getItem("user")).redirect);
    } else {
      let isRemember = localStorage.getItem("remember_me") || this.state.remember_me;
      if(isRemember == "false"){
        localStorage.clear();
      }
      /* let loggedInUser = localStorage.getItem("user");
      loggedInUser = (loggedInUser != "") ? JSON.parse(localStorage.getItem("user")) : null;
      if(loggedInUser !== null){
        this.props.history.push(UserRoles.types[loggedInUser.role].url);
      } */
      this.emailInput.focus();
    };

    this.setState({ submitted: true });
    const { email, password, remember_me } = this.state;
      this.setTempData(email, password, remember_me);

      let search = window.location.search;
      let params = new URLSearchParams(search);

      var DataEncrypt = decodeURI(params.get('_token'));
      var refererUrl = params.get('referer');
      this.setState({ "referer": refererUrl });
      var CryptoJS = require("crypto-js");
      var DataKey = CryptoJS.enc.Utf8.parse("01234567890123456789012345678901");
      var DataVector = CryptoJS.enc.Utf8.parse("1234567890123412");
      var decrypted = CryptoJS.AES.decrypt(DataEncrypt, DataKey, { iv: DataVector });
      var decrypted = CryptoJS.enc.Utf8.stringify(decrypted);

      var parameters = decrypted.split("&");

      let emailUrl = parameters[0].split("=")[1];
      let passwordUrl = parameters[1].split("=")[1];
      let dayUrl = parameters[2].split("=")[1];
      let hourUrl = parameters[3].split("=")[1];

      let currentDay = new Date().toLocaleDateString("en-US");
      let [hour, minute, second] = new Date().toLocaleTimeString("en-US").split(/:| /);

      if (dayUrl === currentDay) {
          console.log("SUCCESS");
      } else {
          console.log("FAILED");
          console.log(dayUrl);
          console.log(currentDay);
          console.log(hourUrl);
          console.log(hour);
      }

      this.signIn(emailUrl, passwordUrl, refererUrl);
  }

  handleChange = e => {
    this.setState({ loginMessage: "" });
    const { name, value } = e.target;
    if(e.target.type === "checkbox"){
      this.setState({ [name]: e.target.checked });
    } else {
      this.setState({ [name]: value });
    }
  };

  handleSubmit = e => {
    e.preventDefault();
    this.setState({ submitted: true });
    const { email, password, remember_me, referer } = this.state;
    this.setTempData(email, password, remember_me);
    if (email && password) {
      this.signIn(email, password, referer);
    } else {
      notifyUser("Please enter the details.", "error");
      this.setState({ submitted: false });
    }
  };
  signIn = async (email, password, referer) => {
    var self = this;
    console.log("hello referer "+ referer);
    
      await self.props.login(email, password);
      if (self.props.redirect && self.props.redirect !== "") {
        self.props.cleanDivisionsTree();
        self.unsetTempData();
        self.props.history.push(self.props.redirect, { isLoggedIn: true });
      } else {
        if(referer && referer.includes("studentparachute")){
          window.location.href = 'https://studentparachute.com/member-portal?error=USER_NOT_FOUND';
          self.setState({ submitted: false });
        } else if(referer && referer.includes("crisiscover")) {
          window.location.href = 'https://crisiscover.focuspointintl.com//?error=USER_NOT_FOUND';
          self.setState({ submitted: false });
        }
        else{
          window.location.href = 'https://insurance.archgroup.com/cap-advantage-login?error=USER_NOT_FOUND';
          self.setState({ submitted: false });
        }
      }
  };
  setTempData = (email, password, remember_me) => {
    localStorage.setItem("email_submitted", email);
    localStorage.setItem("password_submitted", password);
    localStorage.setItem("remember_me", remember_me);
  };
  unsetTempData = () => {
    localStorage.removeItem("email_submitted");
    localStorage.removeItem("password_submitted");
    //localStorage.removeItem("remember_me");
  };
  render() {
    const { email, password, submitted } = this.state;
    return (
      <Spin size="large" spinning={submitted}>
        <section id="login" className="aligner" hidden>
          <div className="wrap1500">
            {/*Login section left side */}
            <div className="login">
              {/* logo */}
              <div className="logo">
                <img src={logo} alt="Logo" />
              </div>
              {/* logo ends */}
              {/* title */}
              <h2>
                <IntlMessages id="login.loginTitle" />
              </h2>
              {/* title ends */}
              {/* logi form */}
              <form>
                <div className="login-form">
                  {/* Email */}
                  <div className="row">
                    <div className="full-width">
                      <i className="far fa-envelope"></i>
                      <input
                        type="email"
                        placeholder="Email"
                        name="email"
                        value={email}
                        onChange={this.handleChange}
                        ref={input => {
                          this.emailInput = input;
                        }}
                      />
                    </div>
                  </div>
                  {/* Email ends*/}
                  {/* password */}
                  <div className="row">
                    <div className="full-width">
                      <i className="fas fa-lock"></i>
                      <input
                        type="password"
                        placeholder="Password"
                        name="password"
                        value={password}
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>
                  {/* password ends */}
                  <div className="row" style={{marginTop:"20px" , marginBottom:"20px"}}>
                    <div className="half-width">
                      <Checkbox name="remember_me" onChange={this.handleChange}>Remember Me</Checkbox>
                    </div>
                    <div className="half-width" style={{textAlign:"right"}}>
                      <a href="/forgot-password">
                        <IntlMessages id="login.forgotPassword" />
                      </a>
                    </div>
                  </div>
                  {/* submit button */}
                  <div className="row">
                    <div className="full-width">
                      <input
                        type="submit"
                        value="Login"
                        onClick={this.handleSubmit}
                      />
                    </div>
                  </div>
                  <div className="clear">&nbsp;</div>

                  <p>{this.state.loginMessage}</p>
                  <p>{/*this.props.loginErrorMessage*/}</p>
                  {/* submit button ends */}
                </div>
              </form>
              {/* login form ends */}
            </div>
            {/*login section left side ends */}
            {/* Image area  */}
            <div className="image-area log-imag">
              <img src={LoginImage} alt="login" />
            </div>
            {/* image are ends  */}
          </div>
        </section>
      </Spin>
    );
  }
}

Login.propTypes = {
  login: PropTypes.func,
  isLoggedIn: PropTypes.bool,
  loginErrorMessage: PropTypes.string,
  redirect: PropTypes.string,
  userData: PropTypes.object,
  cleanDivisionsTree: PropTypes.func
};
function mapStateToProps(state) {
  return {
    ...state.user
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...userActions, ...OrgActions }, dispatch);
}
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, {
    forwardRef: true
  })(Login)
);
