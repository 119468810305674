import React,{useState,useEffect} from 'react';
import { Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {getUserLinkedAccounts ,switchUser} from "./../../redux/actions/user-actions";
const OrgSwitcher = ({props}) => {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user);
  const { Option } = Select;
  const [userOrgs,setUserOrgs] =  useState([]);
  const [currentOrg,setCurrentOrg] = useState();


  useEffect( () => {
     dispatch(getUserLinkedAccounts());
  }, []);
  
  useEffect(() => {
    const selectedOrg = userData && userData.userOrgs.find((item) => item.isCurrentActive == true);
    if (selectedOrg) {
      setCurrentOrg(selectedOrg.email);
    }
  }, [userData.userOrgs]);

  
  function onChange(value) {
  
    setCurrentOrg(value);
    dispatch(switchUser(value));

  }

     
  
  return (
    <div>
      <Select
        showSearch
        style={{ width: 200 }}
        placeholder='Change Organization'
        optionFilterProp='children'
        value={currentOrg}
        onChange={onChange}
     
        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
      >
        {userData &&
          userData.userOrgs.map((item) => {
            return <Option value={item.email}>{item.companyName}</Option>;
          })}
      </Select>
    </div>
  );
};

export default OrgSwitcher;
