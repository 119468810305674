import React, { Component } from "react";
import { Icon, Card } from 'antd';
import {  Chart, Geom, Tooltip } from "bizcharts";
import { TotalIncomeSvg } from "../../shared/svg/svg/home-icon";
import IntlMessages from "../../../services/intlMesseges";
const TotalIncomeIcon = props => < Icon component={
  TotalIncomeSvg
} {
  ...props
  }
/>;
export default class TotalIncome extends Component {
  formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2
  });
  render() {
    const cols = {
      sales: {
        tickInterval: 20
      }
    };
    return (
         <Card title={<IntlMessages id="admin.dashboard.totalincome"></IntlMessages>} extra={<TotalIncomeIcon />}>
          <div style={{fontSize:"22px", color: "#3b3b3b", fontWeight: "600"}} className="totalValue">{this.formatter.format(this.props.incomeData.total)}</div>
          <Chart  className="graphchart" height={50} data={this.props.incomeData.data} scale={cols}  padding={[5, 0, 0, 0]} forceFit>
            
            <Tooltip
              crosshairs={{
                type: "y"
              }}
            />
            <Geom type="interval" position="Date*Total Income" color="#6cb8ff"/>
          </Chart>
        </Card>
    );
  }
}