import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import AdminHome from "../components/admin-dash/admin-home/admin-home";
import UserListing from "../components/admin-dash/user-listing/index";
import UserEdit from "../components/admin-dash/manage-users/edit";
import UserCovid from "../components/user-dash/user-covid/user-covid";
import ManageUsers from "../components/admin-dash/manage-users";
import GlobalSearch from '../components/admin-dash/global-search';
import ManageProducts from "../components/admin-dash/manage-products";
import ProductEdit from "../components/admin-dash/manage-products/edit";
import ManageRoles from "../components/admin-dash/roles/list";
import EditRole from "../components/admin-dash/roles/edit";
import ManageTravelers from "../components/admin-dash/manage-travelers";
import ViewTraveler from "../components/admin-dash/manage-travelers/view-travelers";
import Organizations from "../components/admin-dash/organizations/list";
import EditOrganization from "../components/admin-dash/organizations/edit-organization/edit-organization";
import EditOrganizationEmployees from "../components/admin-dash/organizations/edit-organization/employees/list";
import EditOrganizationEmployee from "../components/admin-dash/organizations/edit-organization/employees/edit";
import OrganizationLicenseList from "../components/admin-dash/organizations/edit-organization/license/list";
import EditOrganizationLicense from "../components/admin-dash/organizations/edit-organization/license/edit";
import OrganizationOrderHistory from "../components/admin-dash/organizations/order-history/order-history";
import AddOrganization from "../components/admin-dash/organizations/add-organization/add-organization";
//import EditOrganization from "../components/admin-dash/organizations/edit";
import OrganizationChildList from "../components/admin-dash/organizations/edit-organization/employees/child-list/list";
//import ViewOrganization from "../components/admin-dash/organizations/edit-organization/employees/child-list/view";
import Orders from "../components/admin-dash/orders";
import EditOrder from "../components/admin-dash/orders/edit";
import ViewOrder from "../components/admin-dash/orders/view";
import OrganizationTypes from "../components/admin-dash/organizations/types/list";
import EditOrganizationType from "../components/admin-dash/organizations/types/edit";
import Pricing from "../components/admin-dash/pricing/pricing";
import ViewPricing from "../components/admin-dash/pricing/view";
import AddPricing from "../components/admin-dash/pricing/add";
import Countries from "../components/admin-dash/countries";
import CountriesInfo from "../components/admin-dash/countries/infos";
import ManageTiers from "../components/admin-dash/manage-tiers";
import ManageDayPackages from "../components/admin-dash/manage-day-packages";
import TiersEdit from "../components/admin-dash/manage-tiers/edit";
import DayPackagesEdit from "../components/admin-dash/manage-day-packages/edit";
import ManageCountries from "../components/admin-dash/manage-countries";
import CountriesEdit from "../components/admin-dash/manage-countries/edit";
import ManageStates from "../components/admin-dash/manage-states";
import StatesEdit from "../components/admin-dash/manage-states/edit";
import ManagePromoCodes from "../components/admin-dash/pricing/promo-codes/list";
import EditPromoCode from "../components/admin-dash/pricing/promo-codes/edit";
import GeoFencingList from "../components/admin-dash/maps/geo-fencing-list";
import Maps from "../components/admin-dash/maps/view";
import AreaInterest from "../components/admin-dash/area-interest/area-interest";
import AssetTracking from "../components/admin-dash/area-interest/index";
import AccountBilling from "../components/admin-dash/accounts-billing";
import TravelerInvoice from "../components/admin-dash/accounts-billing/travellers-billing/view-invoice";
//import OrganizationInvoices from "../components/admin-dash/accounts-billing/organization-account/organization-invoices";
import OrganizationInvoice from "../components/admin-dash/accounts-billing/organization-account/view-invoice";
import ViewOrganizationOrder from "../components/admin-dash/organizations/order-history/view";
import AdminProfile from "../components/admin-dash/admin-profile/admin-profile";
import AdminChangePassword from "../components/admin-dash/admin-profile/change-password";
import CustomAlertList from "../components/admin-dash/custom-alert/index";
import CustomAlert from "../components/admin-dash/custom-alert/custom-alert";
import CustomAlertView from "../components/admin-dash/custom-alert/custom-alert-view";
import UserAuditLogs from "../components/admin-dash/manage-users/audit-logs";
import BillingAuditLogs from "../components/admin-dash/accounts-billing/audit-logs";
import ActiveTravelerList from "../components/admin-dash/reports/active-traveler-list/index";
import WhereareTravelers from "../components/admin-dash/reports/where-my-travelers/index";
import TravelersbyDestDate from "../components/admin-dash/reports/travelers-by-dest-date/index";
import TravelersRiskLevel from "../components/admin-dash/reports/travelers-by-risk-level/index";
import WhereareTravelersResult from "../components/admin-dash/reports/where-my-travelers/search";
import TravelersbyDestDatersResult from "../components/admin-dash/reports/travelers-by-dest-date/search";
import EmployeesonthesameFlight from "../components/admin-dash/reports/Employees-on-the-same-flight/index";
import DomesticInternational from "../components/admin-dash/reports/domestic-international/index";
import DomesticInternationalResult from "../components/admin-dash/reports/domestic-international/search";
import OrganizationAuditLogs from "../components/admin-dash/organizations/audit-logs";
import ProductAuditLogs from "../components/admin-dash/manage-products/audit-logs";
import SegmentAnalysis from "../components/admin-dash/reports/segment-analysis-report/index";
import AirportReport from "../components/admin-dash/reports/airport-report/index";
import AirportResult from "../components/admin-dash/reports/airport-report/search";
import ArrivalReport from "../components/admin-dash/reports/arrival-report/index";
import ArrivalResult from "../components/admin-dash/reports/arrival-report/search";
// import arrivalReport from "../components/admin-dash/reports/arrival-report/index";
import DepartureReport from "../components/admin-dash/reports/departure-report/index";
import DepartureResult from "../components/admin-dash/reports/departure-report/search";
import SearchFlightReport from "../components/admin-dash/reports/search-by-flight/index";
import SearchByPnr from "../components/admin-dash/reports/search-by-pnr/index";
import SearchFlightResult from "../components/admin-dash/reports/search-by-flight/search";
import ActiveHotelList from "../components/admin-dash/reports/hotel-report/index";
import ActiveCarList from "../components/admin-dash/reports/car-report/index";
import TravelersByActiveAlertReport from "../components/admin-dash/reports/travelers-by-active-alert/index"
import ViewTravelersByActiveAlertReport from "../components/admin-dash/reports/travelers-by-active-alert/view"
import ReportList from "../components/admin-dash/reports/index";
import UpdateItinerary from "../components/admin-dash/manage-itinerary/update-itinerary";
import UpdateManualItinerary from "../components/admin-dash/manage-itinerary/update-manual-itinerary";
import EditItinerary from "../components/admin-dash/manage-itinerary/edit";
import EditManualItinerary from "../components/admin-dash/manage-itinerary/editItinerary";
import ListDivisonOrganization from "../components/admin-dash/organizations/division/list-division";
import EditDivisonOrganization from "../components/admin-dash/organizations/division/edit-division/edit-division";
import AddDivisonOrganization from "../components/admin-dash/organizations/division/add-division/add-division";
// import { AccountsBilling } from "../components/shared/svg/svg/home-icon";
import Notifications from "../components/Notifications/notifications";
import ReloadId from "../components/shared/components/reloadId";
import TravelerItineraryViewer from "../components/user-dash/dashboard-travel-manager/traveler-map/traveler-itinerary-viewer";
import TravelerManualItineraryViewer from "../components/user-dash/dashboard-travel-manager/traveler-map/traveler-manualitinerary-viewer";
import TravellerItinerayMapComponent from "../components/user-dash/dashboard-travel-manager/traveler-map/itinerary-details-map";
import PricingAuditLogs from "../components/admin-dash/pricing/pricing-logs"
import NAVReportB2B from "../components/admin-dash/nav-reports/b2b";
import NAVReportB2C from "../components/admin-dash/nav-reports/b2c";
import NotFound from "../components/shared/components/404.jsx";
import B2B2COrganizations from "../components/admin-dash/b2b2c-organizations/list";
import B2B2CListDivisonOrganization from "../components/admin-dash/b2b2c-organizations/division/list-division";
import B2B2CEditDivisonOrganization from "../components/admin-dash/b2b2c-organizations/division/edit-division/edit-division";
import B2B2CAddDivisonOrganization from "../components/admin-dash/b2b2c-organizations/division/add-division/add-division";
import AddB2B2COrganization from "../components/admin-dash/b2b2c-organizations/add-organization/add-organization";
import EditB2B2COrganization from "../components/admin-dash/b2b2c-organizations/edit-organization/edit-organization";
import B2B2COrganizationTravelersList from "../components/admin-dash/b2b2c-organizations/travelers/list";
import ViewB2B2COrganizationTraveler from "../components/admin-dash/b2b2c-organizations/travelers/view";
import EditTraveler from "../components/admin-dash/b2b2c-organizations/travelers/edit/edit";
import B2B2COrganizationOrderHistory from "../components/admin-dash/b2b2c-organizations/order-history/order-history";
import ViewB2B2COrganizationOrder from "../components/admin-dash/b2b2c-organizations/order-history/view";
import EditB2B2COrganizationOrder from "../components/admin-dash/b2b2c-organizations/order-history/edit";
import B2B2COrganizationAuditLogs from "../components/admin-dash/b2b2c-organizations/audit-logs";
import CustomizeEmailsList from "../components/common/customize-emails/list";
import EditCustomizeEmail from "../components/common/customize-emails/edit";
import EditB2B2COrganizationTrvelor from "../components/admin-dash/b2b2c-organizations/edit-organization/traveler/list";
import EditB2B2COrganizationsTravel from "../components/admin-dash/b2b2c-organizations/edit-organization/traveler/edit";
import EditB2B2COrganizationAgents from "../components/admin-dash/b2b2c-organizations/edit-organization/agents/list";
import EditB2B2COrganizationAgent from "../components/admin-dash/b2b2c-organizations/edit-organization/agents/edit";
import PowerBIReports from "../components/admin-dash/power-bi/index";
import SamplePowerBIReport from "../components/admin-dash/power-bi/sample-report";
import EditB2B2cOrganizationLicense from "../components/admin-dash/b2b2c-organizations/edit-organization/license/edit.jsx";
import OrganizationB2b2cLicenseList from "../components/admin-dash/b2b2c-organizations/edit-organization/license/list.jsx";

const routes = [
  {
    path: '',
    component: AdminHome
  },
  {
    path: 'covid',
    component: UserCovid
  },
  {
    path: 'musers',
    component: UserListing
  },
  {
    path: 'musers/:id',
    component: UserEdit
  },
  {
    path: 'search',
    component: GlobalSearch
  },
  {
    path: 'users',
    component: ManageUsers
  },
  {
    path: 'users/:id',
    component: UserEdit
  },
  {
    path: 'products',
    component: ManageProducts
  },
  {
    path: 'products/:id',
    component: ProductEdit
  },
  {
    path: 'roles',
    component: ManageRoles
  },
  {
    path: 'roles/:id',
    component: EditRole
  },
  {
    path: 'travelers',
    component: ManageTravelers
  },
  {
    path: 'travelers/:id',
    component: ViewTraveler
  },
  {
    path: 'organizations',
    component: Organizations
  },
  {
    path: 'organizations/edit/:id',
    component: EditOrganization
  },
  {
    path: 'organizations/edit/:id/employees',
    component: EditOrganizationEmployees
  },
  {
    path: 'organizations/edit/:orgid/employees/:id',
    component: EditOrganizationEmployee
  },
  {
    path: 'organizations/edit/:id/license',
    component: OrganizationLicenseList
  },
  {
    path: 'organizations/edit/:orgid/license/:id',
    component: EditOrganizationLicense
  },
  {
    path: 'organizations/edit/:orgid/employees/child-list/:id',
    component: OrganizationChildList
  },
  {
    path: 'organizations/edit/:orgid/employees/child-list/:id/edit/:id',
    component: EditOrganizationEmployee
  },
  {
    path: 'organizations/:orgid/divisions/:id/employees/child-list/:id',
    component: OrganizationChildList
  },
  {
    path: 'organizations/:orgid/divisions/:id/employees/child-list/:id/edit/:id',
    component: EditOrganizationEmployee
  },
  {
    path: 'organizations/:orgid/order-history',
    component: OrganizationOrderHistory
  },
  {
    path: 'organizations/add',
    component: AddOrganization
  },
  {
    path: 'orders',
    component: Orders
  },
  {
    path: 'orders/edit/:id',
    component: EditOrder
  },
  {
    path: 'orders/:id',
    component: ViewOrder
  },
  {
    path: 'organization-types',
    component: OrganizationTypes
  },
  {
    path: 'organization-types/:id',
    component: EditOrganizationType
  },
  {
    path: 'pricing',
    component: Pricing
  },
  {
    path: 'pricing/view/:id',
    component: ViewPricing
  },
  {
    path: 'pricing/add',
    component: AddPricing
  },
  {
    path: 'countries',
    component: Countries
  },
  {
    path: 'countries/:id',
    component: CountriesInfo
  },
  {
    path: 'tiers',
    component: ManageTiers
  },
  {
    path: 'day-packages',
    component: ManageDayPackages
  },
  {
    path: 'tiers/:id',
    component: TiersEdit
  },
  {
    path: 'day-packages/:id',
    component: DayPackagesEdit
  },
  {
    path: 'tier-countries',
    component: ManageCountries
  },
  {
    path: 'tier-countries/:id',
    component: CountriesEdit
  },
  {
    path: 'tier-states',
    component: ManageStates
  },
  {
    path: 'tier-states/:id',
    component: StatesEdit
  },
  {
    path: 'promo-codes',
    component: ManagePromoCodes
  },
  {
    path: 'promo-codes/:id',
    component: EditPromoCode
  },
  {
    path: 'custom-alert',
    component: CustomAlertList
  },
  {
    path: 'custom-alert/add',
    component: CustomAlert
  },
  {
    path: 'custom-alert/view/:id',
    component: CustomAlertView
  },
  // {
  //   path: "geofencing",
  //   component: GeoFencingList
  // },
  // {
  //   path: "geofencing/:id",
  //   component: Maps
  // },
  {
    path: 'geofencing/find/org/:pathKey/',
    component: ReloadId
  },
  {
    path: 'geofencing/:orgid/',
    component: GeoFencingList
  },
  {
    path: 'geofencing/:orgid/:id/',
    component: Maps
  },
  {
    path: 'asset-tracking/find/org/:pathKey/',
    component: ReloadId
  },
  {
    path: 'asset-tracking/:orgid/',
    component: AssetTracking
  },
  {
    path: 'asset-tracking/:orgid/:id/',
    component: AreaInterest
  },
  {
    path: 'accounts-billing',
    component: AccountBilling
  },
  {
    path: 'accounts-billing/:id',
    component: AccountBilling
  },
  // {
  //   path: "accounts-billing/organization/:id",
  //   component: OrganizationInvoices
  // },
  {
    path: 'accounts-billing/invoice/:id',
    component: TravelerInvoice
  },
  {
    path: 'accounts-billing/organization-invoice/:id',
    component: OrganizationInvoice
  },
  {
    path: 'organizations/:orgid/order-history/:id',
    component: ViewOrganizationOrder
  },
  {
    path: 'my-profile',
    component: AdminProfile
  },
  {
    path: 'my-profile/change-password',
    component: AdminChangePassword
  },
  {
    path: 'accounts-billing/audit-logs/:id',
    component: BillingAuditLogs
  },
  {
    path: 'user/audit-logs/:id',
    component: UserAuditLogs
  },
  {
    path: 'reports/active-traveler-list',
    component: ActiveTravelerList
  },
  {
    path: 'reports/Where-are-my-travelers',
    component: WhereareTravelers
  },
  {
    path: 'reports/Where-are-my-travelers/search',
    component: WhereareTravelersResult
  },
  {
    path: 'reports/travelers-by-dest-date',
    component: TravelersbyDestDate
  },
  {
    path: 'reports/travelers-by-dest-date/search',
    component: TravelersbyDestDatersResult
  },
  {
    path: 'reports/travelers-by-risk-level',
    component: TravelersRiskLevel
  },
  {
    path: 'reports/employees-on-the-same-flight',
    component: EmployeesonthesameFlight
  },
  {
    path: 'reports/domestic-international',
    component: DomesticInternational
  },
  {
    path: 'reports/domestic-international/search',
    component: DomesticInternationalResult
  },
  {
    path: 'products/product-logs/:id',
    component: ProductAuditLogs
  },
  {
    path: 'organizations/organization-logs/:id',
    component: OrganizationAuditLogs
  },
  {
    path: 'reports/segment-analysis-report',
    component: SegmentAnalysis
  },
  {
    path: 'reports/airport-report',
    component: AirportReport
  },
  {
    path: 'reports/airport-report/search',
    component: AirportResult
  },
  {
    path: 'reports/arrival-report',
    component: ArrivalReport
  },
  {
    path: 'reports/arrival-report/search',
    component: ArrivalResult
  },
  {
    path: 'reports/departure-report',
    component: DepartureReport
  },
  {
    path: 'reports/departure-report/search',
    component: DepartureResult
  },
  {
    path: 'reports/search-by-flight',
    component: SearchFlightReport
  },
  {
    path: 'reports/search-by-pnr',
    component: SearchByPnr
  },
  {
    path: 'reports/search-by-flight/search',
    component: SearchFlightResult
  },
  {
    path: 'reports/active-hotel',
    component: ActiveHotelList
  },
  {
    path: 'reports/active-car-report',
    component: ActiveCarList
  },
  {
    path: 'reports/travelers-by-active-alert',
    component: TravelersByActiveAlertReport
  },
  {
    path: 'reports/travelers-by-active-alert/view/:startDate/:endDate/:id',
    component: ViewTravelersByActiveAlertReport
  },
  {
    path: 'reports/',
    component: ReportList
  },
  {
    path: 'power-bi/',
    component: PowerBIReports
  },
  {
    path: 'power-bi/sample-report',
    component: SamplePowerBIReport
  },
  {
    path: 'manage-itinerary/list/:orgid/:id',
    component: UpdateManualItinerary
  },
  /*{
    path: "manage-manual-itinerary/list/:orgid/:id",
    component: UpdateItinerary
  },*/
  {
    path: 'manage-itinerary/edit/:orgid/:divid/:id',
    component: EditManualItinerary
  },
  /*{
    path: "manage-manual-itinerary/edit/:orgid/:divid/:id",
    component: EditItinerary
  },*/
  {
    path: 'organizations/:id/divisions/',
    component: ListDivisonOrganization
  },
  {
    path: 'organizations/:orgid/divisions/:id/edit/',
    component: EditDivisonOrganization
  },
  {
    path: 'organizations/:orgid/divisions/:id/employees/',
    component: EditOrganizationEmployees
  },
  {
    path: 'organizations/:orgid/divisions/:divid/order-history/',
    component: OrganizationOrderHistory
  },
  {
    path: 'organizations/:id/divisions/:divid/invoices/',
    component: AccountBilling
  },
  {
    path: 'organizations/:orgid/divisions/:id/logs/',
    component: OrganizationAuditLogs
  },
  {
    path: 'organizations/:id/divisions/add/',
    component: AddDivisonOrganization
  },
  {
    path: 'notifications',
    component: Notifications
  },
  {
    path: 'organizations/:orgid/divisions/:divid/employees/:id',
    component: EditOrganizationEmployee
  },
  {
    path: 'organizations/:orgid/divisions/:divid/order-history/:id',
    component: ViewOrganizationOrder
  },
  {
    path: 'organizations/:orgid/divisions/:divid/organization-invoice/:id',
    component: OrganizationInvoice
  },
  {
    path: 'traveler-itinerary-viewer/:pnrId/:email',
    component: TravelerItineraryViewer
  },
  {
    path: 'traveler-manualitinerary-viewer/:pnrId',
    component: TravelerManualItineraryViewer
  },
  {
    path: 'traveller-itineray-details',
    component: TravellerItinerayMapComponent
  },
  {
    path: 'pricing/logs/:id',
    component: PricingAuditLogs
  },
  {
    path: 'nav-report/B2B/',
    component: NAVReportB2B
  },
  {
    path: 'nav-report/B2C/',
    component: NAVReportB2C
  },

  //Main travelers router

  {
    path: 'b2b2c-organizations/:orgid/travelers/:id',
    component: EditTraveler
  },

  //Division b2b2c organizations router
  {
    path: 'b2b2c-organizations/:id/divisions/',
    component: B2B2CListDivisonOrganization
  },
  {
    path: 'b2b2c-organizations/:orgid/divisions/:id/edit/',
    component: B2B2CEditDivisonOrganization
  },

  {
    path: 'b2b2c-organizations/:orgid/divisions/:divid/order-history/',
    component: B2B2COrganizationOrderHistory
  },
  {
    path: 'b2b2c-organizations/:id/divisions/:divid/invoices/',
    component: AccountBilling
  },
  {
    path: 'b2b2c-organizations/:orgid/divisions/:id/logs/',
    component: B2B2COrganizationAuditLogs
  },
  {
    path: 'b2b2c-organizations/:id/divisions/add/',
    component: B2B2CAddDivisonOrganization
  },

  //Division Travler b2b2c organizations router
  {
    path: 'b2b2c-organizations/:orgid/divisions/:divid/traveler/edit/:id',
    component: ViewB2B2COrganizationTraveler
  },

  {
    path: 'b2b2c-organizations/:orgid/divisions/:divid/orders/view/:orderId',
    component: ViewB2B2COrganizationOrder
  },
  {
    path: 'b2b2c-organizations/:orgid/divisions/:divid/traveler/:id',
    component: EditTraveler
  },
  {
    path: 'b2b2c-organizations/:orgid/divisions/:id/traveler/',
    component: B2B2COrganizationTravelersList
  },

  //Main b2b2c organizations router

  {
    path: 'b2b2c-organizations',
    component: B2B2COrganizations
  },
  {
    path: 'b2b2c-organizations/add',
    component: AddB2B2COrganization
  },
  {
    path: 'b2b2c-organizations/edit/:id',
    component: EditB2B2COrganization
  },
  {
    path: 'b2b2c-organizations/:id/orders',
    component: B2B2COrganizationOrderHistory
  },
  {
    path: 'b2b2c-organizations/:id/orders/view/:orderId',
    component: ViewB2B2COrganizationOrder
  },
  {
    path: 'b2b2c-organizations/:id/orders/edit/:orderId',
    component: EditB2B2COrganizationOrder
  },
  {
    path: 'b2b2c-organizations/:id/travelers',
    component: B2B2COrganizationTravelersList
  },
  {
    path: 'b2b2c-organizations/:id/travelers/edit/:id',
    component: ViewB2B2COrganizationTraveler
  },
  {
    path: 'b2b2c-organizations/organization-logs/:id',
    component: B2B2COrganizationAuditLogs
  },
  {
    path: 'b2b2c-organizations/logs/:id',
    component: B2B2COrganizationAuditLogs
  },
  {
    path: 'b2b2c-organizations/:id/travelers/edit/:id/view-order/:orderId',
    component: ViewB2B2COrganizationOrder
  },
  {
    path: 'customize-emails',
    component: CustomizeEmailsList
  },
  {
    path: 'customize-emails/edit/:templateId',
    component: EditCustomizeEmail
  },
  {
    path: 'b2b2c-organizations/:id/customize-emails/',
    component: CustomizeEmailsList
  },
  {
    path: 'b2b2c-organizations/:id/customize-emails/edit/:templateId',
    component: EditCustomizeEmail
  },
  {
    path: 'b2b2c-organizations/edit/:id/traveler',
    component: EditB2B2COrganizationTrvelor
  },
  {
    path: 'b2b2c-organizations/edit/:orgid/traveler/:id',
    component: EditB2B2COrganizationsTravel
  },
  {
    path: 'b2b2c-organizations/edit/:id/agents',
    component: EditB2B2COrganizationAgents
  },
  {
    path: 'b2b2c-organizations/edit/:orgid/agents/:id',
    component: EditB2B2COrganizationAgent
  },
  {
    path: 'b2b2c-organizations/edit/:id/license',
    component: OrganizationB2b2cLicenseList
  },
  {
    path: 'b2b2c-organizations/edit/:orgid/license/:id',
    component: EditB2B2cOrganizationLicense
  },
  {
    path: '',
    component: NotFound,
    exact: false
  }
];

class AdminRouter extends Component {
  render() {
    const { url, style } = this.props;
    return (
      <div style={style}>
        <Switch>
          {routes.map(singleRoute => {
            const { path, exact, ...otherProps } = singleRoute;
            return (
              <Route
                exact={exact === false ? false : true}
                key={singleRoute.path}
                path={`${url}/${singleRoute.path}`}
                {...otherProps}
              />
            );
          })}
        </Switch>
      </div>
    );
  }
}

export default AdminRouter;
