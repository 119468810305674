import React from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import IntlMessages from "../../../services/intlMesseges";
import * as adminActions from "../../../redux/actions/admin-actions";
import * as countriesActions from "../../../redux/actions/countries-actions";
import * as tiersActions from "../../../redux/actions/tiers-actions";
import { notifyUser } from "../../../services/notification-service";
import AppLocale from "../../../languageProvider";
import {
  Typography,
  Form,
  Input,
  Select,
  Button,
  Row,
  Col,
  Spin,
  Switch,
  DatePicker,
} from "antd";
import moment from "moment";

const { Option } = Select;
const dateFormat = "YYYY/MM/DD";
const { RangePicker } = DatePicker;
let now = new Date();
let start = moment(new Date(now.getFullYear(), now.getMonth(), 1, 0, 0, 0, 0));
let end = moment(
  new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59, 0)
);

class StatesEdit extends React.Component {
  state = {
    loading: true,
    confirmDirty: false,
    stateId: 0,
    title: "",
    description: "",
    tierId: 0,
    data: {},
    riskLevels: [],
    stateRisks: {},
    stateRiskTypeId: "",
    tasks: [],
    isActive: Boolean,
    dataPickerDates: [start, end],
    selectedDates: [],
    formValues : "" ,  
  };

  disableDate(current) {
    var _this = this;
    var disable = false;
    if (_this.state.selectedDates.length > 0) {
      _this.state.selectedDates.map((range, index) => {
        let start = range[0];
        let end = range[1];
        if (current > moment(start) && current < moment(end)) {
          disable = true;
        } else {
          disable = false;
        }
      });
    }
    return disable;
  }

  RemoveRiskZone = (e, index) => {
    const tasks = this.state.tasks;
    tasks.splice(index, 1);
    this.setState({ tasks: tasks });
    // document.getElementById(`id-${index}`).remove();
  };
  
   RiskTypeValue = (e,i) => {
    this.setState({ formValues: e });
  }
  
  appendRiskZone = () => {
    const tasks = this.state.tasks;
    this.setState({ tasks: [this.state.tasks.length, ...tasks] });
  };

  onSwitchChange = (checked, e) => {
    this.setState({ isActive: checked });
    if (checked == true) {
      notifyUser("Activated successfully!", "success");
    } else {
      notifyUser("Inactivated successfully!", "success");
    }
  };

  onDateChange = (dates, e,i) => {
    if (dates.length > 0) {
      this.setState({ dataPickerDates: dates });
      this.state.selectedDates.push(dates);
    }
  };

  async componentDidMount() {
    let stateRisks = await this.props.getStateRiskZones({});
    this.setState({ riskLevels: stateRisks });

    if (
      this.props.match &&
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.id &&
      this.props.match.params.id !== "new"
    ) {
      this.setState({ stateId: this.props.match.params.id });
      const userinfo = await this.props.getCountryStateById(this.state.stateId);
      this.setState({ isActive: userinfo.isActive });

      this.setState(userinfo.data);
      if (
        userinfo &&
        userinfo.data.stateRiskZones &&
        userinfo.data.stateRiskZones.length > 0
      ) {
        var tempZones = "";
        userinfo.data.stateRiskZones.map((stateRiskZone, index) => {
          tempZones =
            tempZones +
            (index === 0 || index === stateRiskZone.length - 1 ? "" : ", ") +
            stateRiskZone.stateRiskTypeId;
          this.setState({ stateRisks: stateRiskZone });
        });
      }
      this.setState({ loading: false });
    } else {
      this.setState({ loading: false });
    }
  }

  submitFormAdd = (data) => {
    this.props
      .createCountryState(data)
      .then((response) => {
        if (response.error) {
          if (response.error.message) {
            notifyUser(response.error.message, "error");
            //this.props.form.resetFields();
          }
          this.setState({ loading: false });
        } else {
          const { language } = this.props;
          const currentAppLocale = AppLocale[language.locale];
          notifyUser("State created", "success");
          this.props.history.push("./");
          this.setState({ loading: false });
        }
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };

  submitFormEdit = (data) => {
    this.props
      .updateCountryState(this.state.stateId, data)

      .then((response) => {
        if (response.error) {
          this.setState({ loading: false });
        } else {
          const { language } = this.props;
          const currentAppLocale = AppLocale[language.locale];
          this.props.history.push("./");
          this.setState({ loading: false });
          notifyUser("State updated!", "success");
        }
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      Object.keys(values).map(function(key, _idx) {
        if (values[key] == null) {
          values[key] = "";
        } else {
          if (typeof values[key] == "string") {
            values[key] = values[key].trim();
          }
        }
        return key;
      });
      if (!err) {
        var arr = [];
        if (values.existingStateRiskTypeId0) {
          this.state.stateRiskZones.map((task, i) => {
            var existingStateRisk = {
              stateRiskTypeId: values["existingStateRiskTypeId" + i],
             fromDate: moment(values["existingFromDate" + i][0]).format('YYYY-MM-DD'),
             toDate: moment(values["existingFromDate" + i][1]).format('YYYY-MM-DD'),
              // fromDate: values["existingFromDate" + i][0],
              // toDate: values["existingFromDate" + i][1],
            };
            arr.push(existingStateRisk);
          });
        }
        this.state.tasks.map((task, i) => {
          var stateRisk = {
            stateRiskTypeId: values["stateRiskTypeId" + i],
            // fromDate: values["fromDate" + i][0],
            // toDate: values["fromDate" + i][1],
          fromDate: moment(values["fromDate" + i][0]).format('YYYY-MM-DD'),
          toDate: moment(values["fromDate" + i][1]).format('YYYY-MM-DD'),
          };
          arr.push(stateRisk);
        });
        var payload = {
          stateCode: values.stateCode,
          stateName: values.stateName,
          stateRiskZones: arr,
        };
        this.setState({ loading: true });
        payload["isActive"] = this.state.isActive;
        if (this.state.stateId && this.state.stateId > 0) {
          payload["stateId"] = this.state.stateId;
          // if (!this.state.isActive) {
          //   this.props
          //     .deleteState(this.state.stateId)
          //     .then((resp) => {
          //       this.setState({ loading: false });
          //       this.props.history.push("./");
          //     })
          //     .catch((err) => {
          //       console.log(err);
          //       this.setState({ loading: false });
          //     });
          // }
          this.submitFormEdit(payload);
        } else {
          this.submitFormAdd(payload);
        }
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { formLayout } = this.state;
    const formItemLayout =
      formLayout === "horizontal"
        ? {
            labelCol: { span: 4 },
            wrapperCol: { span: 14 },
          }
        : null;

    return (
      <div>
        <Row gutter={24}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Typography.Title level={4}>
              {this.state.stateId === 0 ? (
                <IntlMessages id="admin.managestate.addstate" />
              ) : (
                <div>
                  <IntlMessages id="admin.managestate.editstate" /> {this.state.description}{" "}
                </div>
              )}
            </Typography.Title>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Button type="primary" className="right-fl" htmlType="button" onClick={() => this.props.history.push("./")}>
              <IntlMessages id="admin.userlisting.back" />
            </Button>
          </Col>
        </Row>
        <hr />
        <div>
          <Spin spinning={this.state.loading}>
            <Form layout={formLayout} onSubmit={this.handleSubmit}>
              <Row gutter={24}>
                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                  <Form.Item {...formItemLayout} label={<IntlMessages id="admin.managestate.statename" />}>
                    {getFieldDecorator("stateName", {
                      rules: [
                        {
                          required: true,
                          message: <IntlMessages id="admin.input.required" />
                        }
                      ],
                      initialValue: this.state.stateName === null ? "" : this.state.stateName
                    })(<Input maxLength={30} />)}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                  <Form.Item {...formItemLayout} label={<IntlMessages id="admin.managestate.statecode" />}>
                    {getFieldDecorator("stateCode", {
                      rules: [
                        {
                          required: true,
                          message: <IntlMessages id="admin.input.required" />
                        }
                      ],
                      initialValue: this.state.stateCode === null ? "" : this.state.stateCode
                    })(<Input maxLength={30} />)}
                  </Form.Item>
                </Col>
                {this.state.stateId > 0 ? (
                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Form.Item {...formItemLayout} label={<IntlMessages id="admin.organizationTypes.status" />}>
                      <Switch
                        checkedChildren="Active"
                        unCheckedChildren="Inactive"
                        checked={this.state.isActive}
                        onChange={this.onSwitchChange}
                        name="status"
                      />
                    </Form.Item>
                  </Col>
                ) : (
                  ""
                )}
              </Row>
              {this.state.stateId > 0 && this.state.stateRiskZones
                ? this.state.stateRiskZones.map((task, idx) => (
                    <div key={idx}>
                      <Form>
                        <Row gutter={24} key={idx} className="range-year">
                          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                            <Form.Item label={<IntlMessages id="admin.managestate.stateRiskType" />} hasFeedback>
                              {getFieldDecorator("existingStateRiskTypeId" + idx, {
                                rules: [
                                  {
                                    required: true,
                                    message: <IntlMessages id="admin.input.required" />
                                  }
                                ],
                                initialValue: task.stateRiskTypeId
                              })(
                                <Select>
                                  {this.state.riskLevels.map(function(riskLevel) {
                                    return (
                                      <Option key={riskLevel.zoneId} value={riskLevel.zoneId}>
                                        {riskLevel.zoneName}
                                      </Option>
                                    );
                                  })}
                                </Select>
                              )}
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                            <Form.Item {...formItemLayout} label={<IntlMessages id="admin.managestate.from" />}>
                              {getFieldDecorator("existingFromDate" + idx, {
                                rules: [
                                  {
                                    required: true,
                                    message: <IntlMessages id="admin.input.required" />
                                  }
                                ],
                                initialValue:
                                  task.fromDate && task.toDate
                                    ? [moment(task.fromDate, dateFormat), moment(task.toDate, dateFormat)]
                                    : null
                              })(
                                <RangePicker
                                  dropdownClassName="hideYear"
                                  format="MM/DD"
                                  value={this.state.dataPickerDates}
                                  onChange={this.onDateChange}
                                  disabledDate={(current) => this.disableDate(current)}
                                  ranges={{
                                    Today: [moment(), moment()],
                                    Yesterday: [moment().add("-1", "days"), moment().add("-1", "days")],
                                    "This Week": [moment().startOf("isoWeek"), moment().endOf("isoWeek")],
                                    "Last Week": [
                                      moment()
                                        .add("-1", "week")
                                        .startOf("isoWeek"),
                                      moment()
                                        .add("-1", "week")
                                        .endOf("isoWeek")
                                    ],
                                    "This Month": [moment().startOf("month"), moment().endOf("month")],
                                    "Last Month": [
                                      moment()
                                        .add("-1", "month")
                                        .startOf("month"),
                                      moment()
                                        .add("-1", "month")
                                        .endOf("month")
                                    ]
                                  }}
                                />
                              )}
                            </Form.Item>
                          </Col>
                        </Row>
                      </Form>
                      <hr />
                    </div>
                  ))
                : ""}
              <Row>
                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                  <div className="wrap">
                    <Button onClick={this.appendRiskZone}>[+] Add Risk Type</Button>
                  </div>
                </Col>
              </Row>
              <hr />
              {!!this.state.tasks.length
                ? this.state.tasks.map((task, i) => (
                    <div key={i} id={`id-${i}`}>
                      <Form>
                        <Row gutter={24} key={i}>
                          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                            <Form.Item label={<IntlMessages id="admin.managestate.stateRiskType" />} hasFeedback>
                              {getFieldDecorator("stateRiskTypeId" + i, {
                                rules: [
                                  {
                                    required: true,
                                    message: <IntlMessages id="admin.input.required" />
                                  }
                                ]
                              })(
                                <Select>
                                  {this.state.riskLevels.map(function(riskLevel) {
                                    return (
                                      <Option key={riskLevel.zoneId} value={riskLevel.zoneId}>
                                        {riskLevel.zoneName}
                                      </Option>
                                    );
                                  })}
                                </Select>
                              )}
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                            <Form.Item {...formItemLayout} label={<IntlMessages id="admin.managestate.from" />}>
                              {getFieldDecorator("fromDate" + i, {
                                rules: [
                                  {
                                    required: true,
                                    message: <IntlMessages id="admin.input.required" />
                                  }
                                ]
                              })(
                                <RangePicker
                                  dropdownClassName="hideYear"
                                  format="MM/DD"
                                  value={this.state.dataPickerDates}
                                  onChange={this.onDateChange}
                                  disabledDate={(current) => this.disableDate(current)}
                                  ranges={{
                                    Today: [moment(), moment()],
                                    Yesterday: [moment().add("-1", "days"), moment().add("-1", "days")],
                                    "This Week": [moment().startOf("isoWeek"), moment().endOf("isoWeek")],
                                    "Last Week": [
                                      moment()
                                        .add("-1", "week")
                                        .startOf("isoWeek"),
                                      moment()
                                        .add("-1", "week")
                                        .endOf("isoWeek")
                                    ],
                                    "This Month": [moment().startOf("month"), moment().endOf("month")],
                                    "Last Month": [
                                      moment()
                                        .add("-1", "month")
                                        .startOf("month"),
                                      moment()
                                        .add("-1", "month")
                                        .endOf("month")
                                    ]
                                  }}
                                />
                              )}
                            </Form.Item>
                          </Col>

                          <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                            <div className="wrap" style={{ marginTop: "30px" }}>
                              <br />
                              <Button onClick={() => this.RemoveRiskZone(task, i)}>Remove</Button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                      <hr />
                    </div>
                  ))
                : ""}

              <Row>
                <Col>
                  <Form.Item>
                    <Button
                      type="primary"
                      style={{ display: "inline-block", marginRight: "10px" }}
                      className="def-blue"
                      htmlType="submit"
                      onClick={this.state.stateId > 0 ? this.submitFormEdit : this.submitFormAdd}
                    >
                      {this.state.stateId > 0 ? (
                        <IntlMessages id="admin.userlisting.update" />
                      ) : (
                        <IntlMessages id="admin.userlisting.add" />
                      )}
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Spin>
        </div>
      </div>
    );
  }
}

StatesEdit.propTypes = {
  location: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    ...state.language,
    ...state.riskLevels,
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { ...countriesActions, ...tiersActions, ...adminActions },
    dispatch
  );
}
const WrappedForm = Form.create({ name: "edit-user" })(StatesEdit);
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    WrappedForm
  )
);
