import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Wizard from "../shared/wizard/wizard.jsx";
import CalendarIconsvg from "../../shared/svg/b2c-svg/calendar-icon-svg.jsx";
import DateIconsvg from "../../shared/svg/b2c-svg/date-icon-svg.jsx";
import GlobeIconsvg from "../../shared/svg/b2c-svg/globe-icon-svg.jsx";
import * as b2cActions from "../../../redux/actions/b2c-actions";
import { Select, Row, Col, DatePicker } from "antd";
import moment from "moment";
import { Beforeunload } from "react-beforeunload";
//import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
const { Option } = Select;
class PlanTerm extends Component {
  constructor(props) {
    super(props);
    this.displayData = [];
    this.state = {
      startDate: null,
      selectedCountry: [],
      selectedDaysValue: "",
      productPrice: 0,
      defaultDaysPlan: [],
      selectedCountryTier: "4",
      countryDropDownData: [],
      countryError: "",
      daysError: "",
      dateError: "",
      savedPlanCountriesList: [],
      savedPlanDate: "",
      savedPlanDays: "",
      is_annual: false,
      appliedPromoText: ""
    };
    this.handleClick = this.handleClick.bind(this);
    this.handleCountryCheck = this.handleCountryCheck.bind(this);
    this.handleDaysCheck = this.handleDaysCheck.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    await this.props.getPlanTermInformation();
    if (this.props.planType && Object.keys(this.props.planType).length === 0) {
      await this.props.getStep();
      await this.props.getProductPricingAndInfo();
      await this.props.getPlanInformation();
      await this.props.getPersonalInformation();
      if (parseInt(this.props.step) < 1) {
        this.props.history.push(this.props.b2cBaseUrl+ "/purchase");
      }
    } else if (parseInt(this.props.step) < 1) {
      this.props.history.push(this.props.b2cBaseUrl+ "/purchase");

    }
    var tempCountryArray = [];
    for (var i = 0; i < this.props.countryTierInfo.length; i++) {
      var dataObject = {
        name: this.props.countryTierInfo[i].countryName,
        value: this.props.countryTierInfo[i].countryName
      };
      tempCountryArray.push(dataObject);
    }
    this.setState({
      countryDropDownData: tempCountryArray
    });
    this.getDaysForTier();
    if (this.props.planTerm && this.props.planTerm.country) {
      var planCountries = this.props.planTerm.country.toString().split(",");
      var tempPlanCountries = [];
      for (var j = 0; j < planCountries.length; j++) {
        var objCountry = this.props.countryTierInfo.find(
          x => x.countryId === parseInt(planCountries[j])
        );
        tempPlanCountries.push(objCountry.countryId);
      }
      var planStartDate = new Date(this.props.planTerm.date);
      var tempDate =
        planStartDate.getMonth() +
        1 +
        "/" +
        planStartDate.getDate() +
        "/" +
        planStartDate.getFullYear();

      this.setState({
        savedPlanCountriesList: tempPlanCountries,
        selectedCountry: tempPlanCountries,
        savedPlanDate: tempDate,
        startDate: this.props.planTerm.date,
        selectedDaysValue: this.props.planTerm.days,
        savedPlanDays: parseInt(this.props.planTerm.days),
        is_annual: this.props.planTerm.isAnnual,
        selectedCountryTier: this.props.planTerm.tierValue
      });
      this.CalculateTotalPrice();
    } else {
      this.setState({
        savedPlanCountriesList: [-1],
        savedPlanDate: "NaN",
        selectedDaysValue: "NaN",
        savedPlanDays: "NaN"
      });
    }
  }

  async CalculateTotalPrice() {
    var totalPrice = 0;
    var dayPriceObject;
    if (this.props.personalDetails && this.props.personalDetails.length > 0) {
      for (var i = 0; i < this.props.personalDetails.length; i++) {
        if (this.props.planTerm && this.props.planTerm.isAnnual) {
          let productCount = this.props.productInfo.length;
          var productId = this.props.productInfo[productCount - 1].productId;
          dayPriceObject = this.props.productPriceList.find(
            x =>
              x.tierId === 1 &&
              x.dayPackage === 365 &&
              x.productId === parseInt(productId)
          );
          totalPrice =
            dayPriceObject.retailPrice * this.props.personalDetails.length;
          this.setState({
            daysError: "You have selected Annual plan!",
            countryError: "You have selected Annual plan!"
          });
          break;
        } else {
          var product_Id = this.props.personalDetails[i].product_id;
          dayPriceObject = this.props.productPriceList.find(
            x =>
              x.tierId === parseInt(this.props.planTerm.tierValue) &&
              x.dayPackage === parseInt(this.props.planTerm.days) &&
              x.productId === parseInt(product_Id)
          );
          totalPrice = totalPrice + dayPriceObject.retailPrice;
        }
      }
    } else {
      dayPriceObject = this.props.productPriceList.find(
        x =>
          x.tierId === parseInt(this.props.planTerm.tierValue) &&
          x.dayPackage === parseInt(this.props.planTerm.days) &&
          x.productId === parseInt(this.props.planType.productId)
      );
      totalPrice = dayPriceObject.retailPrice;
    }
    let planTermObject = this.props.planTerm;
    planTermObject.totalPrice = totalPrice;
    await this.props.SavePlanTerm(planTermObject);
    this._bindPlanPrice(totalPrice);
  }

  handleCountryCheck(selectedCountries) {
    this.setState({ selectedCountry: selectedCountries, countryError: "" });
    this.calculateProductPrice(selectedCountries, this.state.selectedDaysValue);
  }

  calculateProductPrice(countryValue, selectedDay) {
    let tierValue = 4;
    let calculatedPrice = 0;
    var dayPriceObject;
    if (selectedDay !== 0 && selectedDay !== "NaN" && countryValue.length > 0) {
      if (countryValue.length > 0) {
        for (var i = 0; i < countryValue.length; i++) {
          var countryObject = this.props.countryTierInfo.find(
            x => x.countryId === countryValue[i]
          );
          if (i === 0) {
            tierValue = parseInt(countryObject.tierId);
          } else {
            if (parseInt(countryObject.tierId) < tierValue) {
              tierValue = parseInt(countryObject.tierId);
            }
          }
        }
      } else {
        tierValue = 4;
      }
      if (this.props.personalDetails && this.props.personalDetails.length > 0) {
        for (var j = 0; j < this.props.personalDetails.length; j++) {
          dayPriceObject = this.props.productPriceList.find(
            x =>
              x.tierId === tierValue &&
              x.dayPackage === parseInt(selectedDay) &&
              x.productId === parseInt(this.props.personalDetails[j].product_id)
          );
          calculatedPrice = calculatedPrice + dayPriceObject.retailPrice;
        }
      } else {
        dayPriceObject = this.props.productPriceList.find(
          x =>
            x.tierId === tierValue &&
            x.dayPackage === parseInt(selectedDay) &&
            x.productId === parseInt(this.props.planType.productId)
        );
        calculatedPrice = dayPriceObject.retailPrice;
      }

      this._bindPlanPrice(calculatedPrice);
      this.setState({
        selectedCountryTier: tierValue
      });
    } else {
      this.setState({
        productPrice: 0.0
      });
    }
  }

  async _bindPlanPrice(totalPrice) {
    await this.props.getAppliedPromo();
    if (this.props.couponInfo && this.props.couponInfo.promoOfferId) {
      var discountedPrice = 0;
      if (this.props.couponInfo.discountType === "P") {
        discountedPrice =
          (this.props.planTerm.totalPrice *
            this.props.couponInfo.discountFigure) /
          100;
      } else {
        discountedPrice = this.props.couponInfo.discountFigure;
      }
      var appliedPromoText =
        "Discount of $" +
        discountedPrice +
        " has been applied to the total plan fee";
      totalPrice = totalPrice - discountedPrice;
      this.setState({
        appliedPromoText: appliedPromoText
      });
    }
    this.setState({
      productPrice: totalPrice < 0 ? 0 : totalPrice
    });
  }

  handleDaysCheck(evt) {
    this.setState({
      selectedDaysValue: parseInt(evt),
      daysError: ""
    });
    this.calculateProductPrice(this.state.selectedCountry, parseInt(evt));
  }

  async handleClick(evt) {
    if (evt.currentTarget.dataset && evt.currentTarget.dataset.value) {
      if (evt.currentTarget.dataset.value === "back") {
        await this.props.SaveStep("1");
        this.props.history.push(this.props.b2cBaseUrl+ "/purchase");
      } else {
        this.saveDataAndNext();
      }
    }
  }

  getDaysForTier() {
    let daysValues = [];
    for (var i = 0; i < this.props.productDays.length; i++) {
      daysValues.push(this.props.productDays[i].days);
    }
    this.setState({ defaultDaysPlan: daysValues });
  }

  handleChange = date => {
    var selectedDate = new Date(date);
    const diffTime = selectedDate - new Date();
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    if (diffDays < 0) {
      this.setState({
        startDate: date,
        dateError: "Please select a valid plan start date."
      });
    } else {
      this.setState({
        startDate: date,
        dateError: ""
      });
    }
  };

  async saveDataAndNext() {
    if (
      this.state.selectedDaysValue !== "NaN" &&
      this.state.selectedCountry.length > 0 &&
      this.state.startDate !== null
    ) {
      var selectedDate = new Date(this.state.startDate);
      const diffTime = selectedDate - new Date();
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      if (diffDays < 0) {
        this.setState({ dateError: "Please select a valid plan start date." });
      } else {
        let planTermObject = {};
        planTermObject.date = this.state.startDate._d
          ? this.state.startDate._d
          : this.state.startDate;
        planTermObject.days = this.state.selectedDaysValue;
        planTermObject.country = this.state.selectedCountry.toString();
        planTermObject.totalPrice = this.state.productPrice;
        planTermObject.Price =
          this.props.personalDetails && this.props.personalDetails.length > 0
            ? this.state.productPrice / this.props.personalDetails.length
            : this.state.productPrice;
        planTermObject.tierValue = this.state.selectedCountryTier;
        planTermObject.isAnnual = this.state.is_annual;
        await this.props.SaveStep("2");
        await this.props.SavePlanTerm(planTermObject);
        this.props.history.push(this.props.b2cBaseUrl+ "/personal-details");
      }
    } else {
      if (this.state.selectedDaysValue === "NaN") {
        this.setState({ daysError: "Please select days." });
      }
      if (this.state.selectedCountry.length === 0) {
        this.setState({ countryError: "Please select at least one country." });
      }
      if (this.state.startDate === null) {
        this.setState({ dateError: "Please select travelling date." });
      }
    }
  }

  _bindDays() {
    return this.state.defaultDaysPlan.map(day => (
      <Option key={day} data-value={day}>
        {"Up to "+day+" Days "}
      </Option>
    ));
  }

  _bindCountries() {
    return this.props.countryTierInfo.map((country, index) => (
      <Option key={"con" + index} value={country.countryId}>
        {country.countryName}
      </Option>
    ));
  }

  _bindPlanDays() {
    if (this.state.savedPlanDays !== "") {
      return (
        <Select
          readonly
          showSearch={false}
          disabled={
            this.props.planTerm && this.props.planTerm.isAnnual ? true : false
          }
          placeholder="Please select days"
          style={{ height: "48px", width: "100%" }}
          onChange={this.handleDaysCheck}
          defaultValue={
            this.state.savedPlanDays !== "NaN"
              ? this.state.savedPlanDays + " Days"
              : ""
          }
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
        >
          {this.state.defaultDaysPlan && this.state.defaultDaysPlan.length > 0
            ? this._bindDays()
            : ""}
        </Select>
      );
    }
  }

  _bindCountry() {
    if (this.state.savedPlanCountriesList.length > 0) {
      return (
        <Select
          readonly
          disabled={
            this.props.planTerm && this.props.planTerm.isAnnual ? true : false
          }
          showSearch
          mode="multiple"
          defaultValue={
            this.state.savedPlanCountriesList[0] !== -1
              ? this.state.savedPlanCountriesList
              : []
          }
          placeholder="Please select a country"
          style={{ height: "48px", width: "100%" }}
          onChange={this.handleCountryCheck}
          filterOption={(input, option) =>
            option.props.children.toLowerCase().startsWith(input.toLowerCase())
          }
        >
          {this.state.countryDropDownData.length > 0
            ? this._bindCountries()
            : ""}
        </Select>
      );
    }
  }

  /* _bindDate() {
    if (this.state.savedPlanDate !== "") {
      return (
        <DatePicker
          showSearch={false}
          disabledDate={this.disabledDate}
          defaultValue={
            this.state.savedPlanDate !== "NaN"
              ? moment(this.state.savedPlanDate, "MM/DD/YYYY")
              : ""
          }
          format="MM/DD/YYYY"
          onChange={this.handleChange}
        />
      );
    }
  } */

  _bindDate() {
    if (this.state.savedPlanDate !== "") {
      return (
        <DatePicker
          showSearch={false}
          disabledDate={this.disabledDate}
          defaultValue={
            this.state.savedPlanDate !== "NaN"
              ? moment(this.state.savedPlanDate, "MM/DD/YYYY")
              : ""
          }
          format="MM/DD/YYYY"
          onChange={this.handleChange}
        />
      );
    }
  }

  disabledDate(current) {
    return current && current < moment().subtract(1, "day");
  }
  onUnload = e => {
    e.preventDefault();
    /* this.props.flushOrderData();
    this.props.ClearData(); */
  };
  render() {
    return (
      <section id="banner">
        <Beforeunload onBeforeunload={e => this.onUnload(e)}>
          <Wizard wizardActiveClass="step2" />
          {/* price detail */}
          <div
            className="wrap900 price-sticky-head"
            style={{
              display: "block",
              maxWidth: "1300px",
              zIndex: "100",
              position: "sticky"
            }}
          >
            <div className="title" style={{ background: "#FFF" }}>
              <h1>
                <span>Plan Fee:</span> ${this.state.productPrice.toFixed(2)} USD
              </h1>
              <span>{this.state.appliedPromoText}</span>
            </div>
          </div>
          {/* price  ends */}
          {/*  plan travel */}
          <div className="wrap">
            <div className="plan-travel">
              <div className="main-div">
                <div className="in-div">
                  <CalendarIconsvg />
                  <div className="desc">
                    <span>*</span> When are you leaving?
                  </div>
                  {this._bindDate()}
                  <span className="error">{this.state.dateError}</span>
                </div>
                <div className="in-div">
                  <DateIconsvg />
                  <div className="desc">
                    <span>*</span> For how many days?
                  </div>
                  <Row type="flex"  align="middle">
                    {this._bindPlanDays()}
                  </Row>
                  <span className="error">{this.state.daysError}</span>
                </div>
                <div className="in-div">
                  <GlobeIconsvg />
                  <div className="desc">
                    <span>*</span> Where are you going?
                  </div>
                  {this._bindCountry()}
                  <span className="error">{this.state.countryError}</span>
                </div>
              </div>
            </div>
          </div>
          {/* plan travel ends */}
          {/* next and back button */}
          <div className="next-back">
            <div className="wrap">
              <button
                data-value="back"
                className="ant-btn back"
                onClick={this.handleClick}
              >
                Back
              </button>
              <button
                data-value="next"
                className="ant-btn next"
                onClick={this.handleClick}
              >
                Next
              </button>
            </div>
          </div>
          {/* next and back button */}
        </Beforeunload>
      </section>
    );
  }
}
PlanTerm.propTypes = {
  getProductPricingAndInfo: PropTypes.func,
  getPlanInformation: PropTypes.func,
  SaveStep: PropTypes.func,
  SavePlanTerm: PropTypes.func,
  step: PropTypes.string,
  planType: PropTypes.object,
  countryTierInfo: PropTypes.array,
  productPriceList: PropTypes.array,
  productInfo: PropTypes.array,
  getPersonalInformation: PropTypes.func,
  getPlanTermInformation: PropTypes.func,
  personalDetails: PropTypes.array,
  planTerm: PropTypes.object,
  productDays: PropTypes.array,
  getAppliedPromo: PropTypes.func,
  couponInfo: PropTypes.object,
  getStep: PropTypes.func,
  b2cBaseUrl:PropTypes.string
};
function mapStateToProps(state) {
  return {
    ...state.b2c
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...b2cActions }, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true
})(PlanTerm);
