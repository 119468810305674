import React, { Component } from "react"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import IntlMessages from "../../../services/intlMesseges"
import { Table, Icon, Input, Button, Row, Col, Typography } from "antd"
import { ViewCell, EditCell } from "../../shared/components/helperCells"
import * as orderActions from "../../../redux/actions/order-actions"
import * as paginationActions from "../../../redux/actions/pagination-actions"
import * as UserService from "../../../services/user-service"
import moment from "moment"
const dateFormathh = "MM/DD/YYYY HH:mm:ss"
const dateFormat = "MM/DD/YYYY HH:mm:ss"

class ManageOrders extends Component {
  constructor(props) {
    super(props)
    this.module = "orders"
    this.state = {
      ordersLoaded: false,
      isLoading: false,
      data: [],
      pagination: {
        pageSize: 10,
        current: 1,
        hideOnSinglePage: true,
        showTotal: (total, range) => {
          return (
            <span>
              <IntlMessages id="admin.text.showing" /> {range[0]}-{range[1]}{" "}
              <IntlMessages id="admin.text.of" /> {total} <IntlMessages id="admin.text.results" />
            </span>
          )
        },
      },
      filters: {},
    }
  }

  formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
  })

  getHeaderKeys = () => {
    return [
      {
        title: <IntlMessages id="admin.organizations.plantype" />,
        dataIndex: "productName",
        filteredValue: this.getSelectedFilterValue("productName"),
        ...this.getColumnSearchProps("productName"),
        render: (_text, record) =>
          record.productName.split(",").map(function(data) {
            return (
              <span key={data}>
                {data}
                <br />
              </span>
            )
          }),
      },
      {
        title: <IntlMessages id="admin.manageOrders.orderSubmitter" />,
        dataIndex: "orderSubmitterName",
      },
      {
        title: <IntlMessages id="admin.manageOrders.membershipNumber" />,
        dataIndex: "membershipNumber",
        filteredValue: this.getSelectedFilterValue("membershipNumber"),
        ...this.getColumnSearchProps("membershipNumber"),
        // width: "25%"
      },
      {
        title: <IntlMessages id="admin.manageOrders.orderNumber" />,
        dataIndex: "orderNumber",
        //...this.getColumnSearchProps("OrderNumber"),
        //width: "25%"
      },
      {
        title: <IntlMessages id="admin.manageOrders.orderStatus" />,
        dataIndex: "orderStatus",
      },
      {
        title: <IntlMessages id="admin.manageOrders.discountedAmount" />,
        //width: "20%",
        render: (_text, record) => (
          <span>
            {" "}
            {this.formatter.format(record.totalOrderAmount)} {record.currency}
          </span>
        ),
      },
      {
        title: <IntlMessages id="admin.manageOrders.createdDate" />,
        //width: "20%",
        render: (_text, record) => (
          <span title={moment(record.insertDate).format(dateFormathh)}>
            {moment(record.insertDate).format(dateFormat)}
          </span>
        ),
      },
      {
        title: <IntlMessages id="admin.manageOrders.Action" />,
        rowKey: "action",
        //width: "10%",
        render: (_text, record) => (
          <span>
            <ViewCell
              onViewCell={() => {
                this.viewItem(record.orderId)
              }}
            />
            {/* <EditCell
              onEditCell={() => {
                this.editItem(record.orderId);
              }}
            /> */}
          </span>
        ),
      },
    ]
  }

  getSelectedFilterValue = (index) => {
    return (
      (this.props.paginginfo[this.module] &&
        this.props.paginginfo[this.module].filter &&
        this.props.paginginfo[this.module].filter[index]) ||
      null
    )
  }

  async componentDidMount() {
    this.props.clearPaginationExceptMe(this.module)
    if (this.props.paginginfo && this.props.paginginfo[this.module]) {
      this.handleTableChange(
        this.props.paginginfo[this.module].pagination,
        this.props.paginginfo[this.module].filter,
        {},
        true
      )
      if (this.props.paginginfo[this.module].filters) {
        let filters = this.props.paginginfo[this.module].filters
        Object.keys(filters).map((k) => {
          filters[k].auto = false
          return k
        })
        this.setState({ filters: filters })
      }
    } else {
      this.handleTableChange({ current: 1, pageSize: 10 }, {}, {}, true)
    }
  }

  editItem = (id) => {
    this.props.history.push("./edit/" + id)
  }

  viewItem = (id) => {
    this.props.history.push("./" + id)
  }

  onSwitchChange = (id) => (checked, e) => {
    this.setState({ loading: true })
    this.props
      .updateProductStatus({
        id: id,
        isActive: checked,
      })
      .then((resp) => {
        this.setState({
          loading: false,
        })
      })
      .catch((ex) => {
        this.setState({ loading: false })
      })
  }

  handleTableChange = (pagination, filters, sorter, manual) => {
    if (filters === undefined) filters = {}
    Object.keys(filters).map((key) => {
      if (!filters[key] || (Array.isArray(filters[key]) && filters[key].length === 0)) {
        delete filters[key]
      }
    })
    const pager = { ...this.state.pagination }
    pager.current = pagination.current
    if (manual !== true) {
      this.props.updatePaginationRemoveOld({
        module: this.module,
        filter: filters,
        pagination: { current: pagination.current, pageSize: pagination.pageSize },
      })
    }
    this.setState({ loading: true })
    this.props
      .getOrders({
        orgId: this.props.userData.companyID
          ? this.props.userData.companyID
          : UserService.getUser().companyID,
        filters: filters,
        pagination: { page: pagination.current, pageSize: pagination.pageSize },
        sorter: sorter,
      })
      .then((resp) => {
        pager.total = resp.paging.totalRecords
        this.setState({
          loading: false,
          data: resp,
          pagination: pager,
        })
      })
      .catch((ex) => {
        this.setState({ loading: false })
      })
  }

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      return (
        <div style={{ padding: 8 }}>
          <Input
            ref={(node) => {
              this.searchInput = node
            }}
            value={selectedKeys}
            onChange={(e) => setSelectedKeys(e.target.value ? e.target.value.trimLeft() : "")}
            onPressEnter={() =>
              this.handleSearch(selectedKeys, confirm, dataIndex, clearFilters, setSelectedKeys)
            }
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Button
            type="primary"
            onClick={() =>
              this.handleSearch(selectedKeys, confirm, dataIndex, clearFilters, setSelectedKeys)
            }
            disabled={selectedKeys != "" && selectedKeys !== null ? false : true}
            icon="search"
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            <IntlMessages id="admin.search" />
          </Button>
          <Button
            disabled={selectedKeys != "" && selectedKeys !== null ? false : true}
            onClick={() => this.handleReset(clearFilters, dataIndex)}
            size="small"
            style={{ width: 90 }}
          >
            <IntlMessages id="admin.reset" />
          </Button>
        </div>
      )
    },
    filterIcon: (filtered) => (
      <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select())
      }
    },
  })

  handleSearch = (selectedKeys, confirm, dataIndex, clearFilters, setSelectedKeys) => {
    if (selectedKeys === "") {
      return false
    }

    this.setState({ searchText: selectedKeys })
    let filters = this.state.filters
    filters[dataIndex] = {
      val: selectedKeys,
      clearf: clearFilters,
      filter: true,
      setSelectedKeys: setSelectedKeys,
      confirm: confirm,
      auto: true,
    }
    this.setState({ filters: filters })
    this.props.updateFilters({ module: this.module, filters: filters })
    confirm()
  }

  handleReset = (clearFilters, dataIndex) => {
    clearFilters()
    let filters = this.state.filters
    if (filters[dataIndex]) {
      if (
        filters[dataIndex].setSelectedKeys &&
        typeof filters[dataIndex].setSelectedKeys === "function"
      ) {
        filters[dataIndex].setSelectedKeys("")
        //filters[dataIndex].confirm();
      }
    }
    if (filters[dataIndex] && !filters[dataIndex].auto) {
      delete this.props.paginginfo[this.module].filter[dataIndex]
      this.handleTableChange(
        { current: 1, pageSize: 10 },
        this.props.paginginfo[this.module].filter,
        {}
      )
    }
    filters[dataIndex] = { val: "", clearf: "", filter: false }
    this.setState({ filters: filters })
    this.props.updateFilters({ module: this.module, filters: filters })
    this.setState({ searchText: "" })
  }

  render() {
    let _state = this.state
    let _this = this
    let filtertag = Object.keys(this.state.filters).map(function(key1) {
      let keyLabel = _this.getHeaderKeys().find((el) => el.dataIndex === key1)
      if (keyLabel.title.props && keyLabel.title.props.id) {
        keyLabel = <IntlMessages id={keyLabel.title.props.id} />
      } else {
        keyLabel = key1
      }
      if (_state.filters[key1].filter) {
        return (
          <span>
            <Button
              key={key1}
              type="primary"
              onClick={() => _this.handleReset(_state.filters[key1].clearf, key1)}
            >
              {keyLabel} : {_state.filters[key1].val}
              <Icon type="close" />
            </Button>
            &nbsp;
          </span>
        )
      } else {
        return ""
      }
    })
    return (
      <div>
        <Row gutter={24}>
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography.Title level={4}>
              <IntlMessages id="admin.manageOrders" />
            </Typography.Title>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={12}></Col>
        </Row>
        <hr></hr>

        <Row gutter={24} style={{ marginBottom: "20px" }}>
          <Col xs={24}>{filtertag}</Col>
        </Row>
        <Row gutter={24}>
          <Col xs={24} style={{ overflowX: "auto" }}>
            <Table
              bordered
              columns={this.getHeaderKeys()}
              rowKey={(record) => record.orderId}
              dataSource={this.state.data.data}
              pagination={this.state.pagination}
              loading={this.state.loading}
              onChange={this.handleTableChange}
            />
          </Col>
        </Row>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    ...state.user,
    ...state.pagination,
  }
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...orderActions, ...paginationActions }, dispatch)
}
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(ManageOrders)
)
