import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as B2B2COrgActions from "../../../redux/actions/b2b2c-organization-actions";
import * as paginationActions from "../../../redux/actions/pagination-actions";
import IntlMessages from "../../../services/intlMesseges";
import * as Permission from "../../../services/permissions";
import {
  Table,
  Icon,
  Input,
  Button,
  Row,
  Col,
  Typography,
  Tooltip
} from "antd";
import { AccountsBilling } from "../../shared/svg/svg/home-icon";
import { EditCell } from "../../shared/components/helperCells";
import moment from "moment";
import { th } from "date-fns/locale";
const dateFormathh = "MM/DD/YYYY HH:mm:ss";
const AccountsBillingIcon = props => (
  <Icon component={AccountsBilling} {...props} />
);
class B2B2COrganizations extends Component {
  constructor(props) {
    super(props);
    this.module  ='b2b2c_organizations';
    this.state = {
      loading: true,
      data: [],
      pagination: {
        pageSize: 10,
        current: 1,
        hideOnSinglePage: true,
        showTotal: (total, range) => {
          return (
            <span>
              <IntlMessages id="admin.text.showing" /> {range[0]}-{range[1]}{" "}
              <IntlMessages id="admin.text.of" /> {total}{" "}
              <IntlMessages id="admin.text.results" />
            </span>
          );
        }
      },
      filters: {},
      orgTypes: []
    };
  }
  
  getSelectedFilterValue = (index) => {
   return  this.props.paginginfo[this.module]&& this.props.paginginfo[this.module].filter && this.props.paginginfo[this.module].filter[index] || null;
  }

  getHeaderKeys = () => {
    let HybridTypeLabel = "";
    if(this.state.orgTypes.length > 0){
      this.state.orgTypes.find(t => {
        if(t.name.toLowerCase().indexOf("hybrid") > -1){
          HybridTypeLabel = t.name;
        }
      });
    }
    return [
      {
        title: <IntlMessages id="admin.organizations.list.type" />,
        dataIndex: "companyType",
        width: 180,
        filteredValue : this.getSelectedFilterValue('companyType'),
        render: (_text, record) => <span>{record.orgType}</span>,
        ...this.getColumnSearchProps("companyType")
      },
      {
        title: <IntlMessages id="admin.organizations.list.name" />,
        dataIndex: "companyName",
        width: 180,
        filteredValue : this.getSelectedFilterValue('companyName'),
        render: (_text, record) => <span>{record.name}</span>,
        ...this.getColumnSearchProps("companyName")
      },
      {
        title: <IntlMessages id="Is GuardMe" />,
        dataIndex: "isGuardMeOrg",
        width: 120,
        filteredValue : this.getSelectedFilterValue('isGuardMeOrg'),
        render: (_text, record) => <span>{(record.isGuardMeOrg==true ? "Yes" : "No")}</span>,
        ...this.getColumnSearchProps("isGuardMeOrg")
      },
      {
        title: <IntlMessages id="admin.organizations.list.email" />,
        dataIndex: "companyEmail",
        width: 250,
        filteredValue : this.getSelectedFilterValue('companyEmail'),
        render: (_text, record) => <span>{record.orgEmail}</span>,
        ...this.getColumnSearchProps("companyEmail")
      },
      /* {
        title: <IntlMessages id="admin.organization.planId" />,
        dataIndex: "membershipNumber",
        width: 250,
        filteredValue : this.getSelectedFilterValue('membershipNumber'),
        ...this.getColumnSearchProps("membershipNumber")
      }, */
      {
        title: <IntlMessages id="admin.organizations.list.phone" />,
        dataIndex: "phoneNumber",
        width: 160
        //...this.getColumnSearchProps("phoneNumber")
      },
      {
        title: <IntlMessages id="admin.organizations.list.onboardingDate" />,
        dataIndex: "onBoardingDate",
        width: 180,
        render: (text, record) => (
          <span>{moment(record.onBoardingDate).format(dateFormathh)}</span>
        )
      },
      {
        title: <IntlMessages id="admin.organizations.list.actions" />,
        rowKey: "action",
        width: 150,
        render: (_text, record) => {
          return (<span>
            <EditCell
              onEditCell={() => {
                this.editItem(record.organizationId);
              }}
            />

            {HybridTypeLabel === record.orgType && record.isGuardMeOrg ?
            <button
              onClick={() => {
                this.viewDivisions(record.organizationId);
              }}
              className="icon"
            >
              <Tooltip
                title={
                  <IntlMessages id="admin.organizations.actions.viewdivision" />
                }
              >
                <i className="fas fa-code-branch"></i>
              </Tooltip>
            </button>
            :""}
            <button
              className="icon"
              onClick={() => this.props.history.push("./" + record.organizationId + "/orders/", {orgName: record.name})}
            >
              <Tooltip
                title={
                  <IntlMessages id="admin.organizations.actions.orderhistory" />
                }
              >
                <i className="fas fa-money-bill-alt"></i>
              </Tooltip>
            </button>
           
            <button
              className="icon"
              onClick={() => this.props.history.push("./" + record.organizationId + "/travelers/", {orgName: record.name})}
            >
              <Tooltip
                title={
                  <IntlMessages id="admin.traveler.managetraveler" />
                }
              >
                <i className="fas fa-users"></i>
              </Tooltip>
            </button>
            {record.isGuardMeOrg ?
            <button
              className="icon"
              onClick={() => {
                this.viewLicense(record.organizationId);
              }}
            >
              <Tooltip
                title={
                  <IntlMessages id="admin.organizations.actions.manageplans" />
                }
              >
                <i className="fas fa-id-badge"></i>
              </Tooltip>
            </button>
            : ""}

            {!record.isGuardMeOrg ?
            <button
              className="icon"
              onClick={() => this.props.history.push("./edit/" + record.organizationId + "/agents/", {orgName: record.name})}
              >
              <Tooltip
                title="Manage Agents"
              >
                <i className="fas fa-user-tie"></i>
              </Tooltip>
            </button>
            : ""}

            {/* <button
              className="icon"
              onClick={() => this.props.history.push("./" + record.organizationId + "/customize-emails/", {orgName: record.name})}
            >
              <Tooltip
                title={
                  <IntlMessages id="admin.sidebar.submenu.customizeEmails" />
                }
              >
                <i className="fas fa-envelope"></i>
              </Tooltip>
            </button> */}
            {Permission.isRole(['Administrator','Admin','CSR','Travel Manager', 'Division Manager']) ? 
              <button
                className="icon"
                onClick={() => {
                  this.organizationLogs(record.organizationId);
                }}
              >
                <Tooltip
                  title={
                    <IntlMessages id="admin.organizations.actions.viewlogs" />
                  }
                >
                  {/* <i {...AccountsBillingIcon}></i> */}
                  <i className="fas fa-clipboard-list"></i>
                </Tooltip>
              </button>
            : "" }
            {/* {HybridTypeLabel === record.orgType ?
              <button
                className="icon"
                onClick={() => this.props.history.push("./edit/" + record.organizationId + "/traveler/", {orgName: record.name})}
              >
                <Tooltip
                  title={
                    <IntlMessages id="admin.organization.manage.employees" />
                  }
                >
                  <i className="fas fa-users"></i>
                </Tooltip>
              </button>
            : "" } */}
          </span>);
        }
      }
    ];
  };

  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => {
      return (
        <div style={{ padding: 8 }}>
          <Input
            ref={node => {
              this.searchInput = node;
            }}
            value={selectedKeys}
            onChange={e =>
              setSelectedKeys(e.target.value ? e.target.value.trimLeft() : "")
            }
            onPressEnter={() =>
              this.handleSearch(
                selectedKeys,
                confirm,
                dataIndex,
                clearFilters,
                setSelectedKeys
              )
            }
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Button
            type="primary"
            onClick={() =>
              this.handleSearch(
                selectedKeys,
                confirm,
                dataIndex,
                clearFilters,
                setSelectedKeys
              )
            }
            disabled={selectedKeys != "" && selectedKeys !== null ? false : true}
            icon="search"
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            <IntlMessages id="admin.search" />
          </Button>
          <Button
            disabled={selectedKeys != "" && selectedKeys !== null ? false : true}
            onClick={() => this.handleReset(clearFilters, dataIndex)}
            size="small"
            style={{ width: 90 }}
          >
            <IntlMessages id="admin.reset" />
          </Button>
        </div>
      );
    },
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    }
  });

  handleSearch = (
    selectedKeys,
    confirm,
    dataIndex,
    clearFilters,
    setSelectedKeys
  ) => {
    if (selectedKeys == "") {
      return false;
    }

    this.setState({ searchText: selectedKeys });
    let filters = this.state.filters;
    filters[dataIndex] = {
      val: selectedKeys,
      clearf: clearFilters,
      filter: true,
      setSelectedKeys: setSelectedKeys,
      confirm: confirm,
      auto:true
    };
    this.setState({ filters: filters });
    this.props.updateFilters({module:this.module, filters: filters})
    confirm();
  };

  handleReset = (clearFilters, dataIndex) => {
    clearFilters();
    let filters = this.state.filters;
    if (filters[dataIndex]) {
      if(filters[dataIndex].setSelectedKeys && typeof filters[dataIndex].setSelectedKeys === 'function'){
        filters[dataIndex].setSelectedKeys("");
        //filters[dataIndex].confirm();
      }
    }
    
    if(filters[dataIndex] && !filters[dataIndex].auto){
      delete this.props.paginginfo[this.module].filter[dataIndex];
      this.handleTableChange({ current: 1, pageSize: 10 }, this.props.paginginfo[this.module].filter, {});
   
    }
    filters[dataIndex] = { val: "", clearf: "", filter: false };
    this.setState({ filters: filters });
    this.props.updateFilters({module: this.module, filters:  filters})
    this.setState({ searchText: "" });
  };

  async componentDidMount() {
    if(this.props.paginginfo && this.props.currentModule != "" && this.props.currentModule != this.module)
    {
      this.props.clearPaginationExceptMe(this.module);
    }
   else
    {
      await this.props.getResellerOrganizationTypes({
        filters: {},
        pagination: { page: 1 },
        sorter: {}
      }).then(re => {
        if (re.data && re.data.length > 0) {
          this.setState({ orgTypes: re.data });
        }
      });
      if(this.props.paginginfo[this.module]){
        this.handleTableChange(this.props.paginginfo[this.module].pagination, this.props.paginginfo[this.module].filter, {},true);
        if(this.props.paginginfo[this.module].filters){
        let filters = this.props.paginginfo[this.module].filters
        Object.keys(filters).map(k=> {filters[k].auto = false});
          this.setState({filters :  filters});
        }
      }
      else{
        this.handleTableChange({ current: 1, pageSize: 10 }, {}, {}, true);
      }
    }
  }

  deleteItem = id => {
    this.setState({ loading: true });
    this.props
      .deleteOrganization({ id: id })
      .then(item => {
        this.setState({ loading: false });
        this.props.history.push("./");
      })
      .catch(err => {
        this.setState({ loading: false });
      });
  };

  editItem = id => {
    let currentUser = JSON.parse(localStorage.getItem("user"));
    if (currentUser.parentOrgId > 1) {
      if (currentUser.parentOrgId === id) {
        this.props.history.push("./edit/" + id + "/");
      } else {
        this.props.history.push(
          "./" + currentUser.parentOrgId + "/divisions/" + id + "/edit/"
        );
      }
    } else {
      this.props.history.push("./edit/" + id + "/");
    }
  };
  viewDivisions = id => {
    this.props.history.push("./" + id + "/divisions/");
  };
  viewLicense = id => {
    this.props.history.push("./edit/" + id + "/license/");
  };

  accountsBilling = id => {
    this.props.history.push("../accounts-billing/" + id + "/");
  };
  organizationLogs = id => {
    this.props.history.push("../b2b2c-organizations/logs/" + id);
  };
  itineraryForm = (parentOrg, id) => {
    this.props.history.push("../manage-itinerary/list/" +parentOrg+"/"+ id + "/");
  };
  addItem() {
    this.props.history.push("add/");
  }

  handleTableChange = (pagination, filters, sorter,manual) => {
    if(filters === undefined) filters={};

    Object.keys(filters).map( key => { if((!filters[key]) || (Array.isArray(filters[key]) && filters[key].length===0)) { delete filters[key] }} )
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
   
    if(manual !== true)
    {
      this.props.updatePaginationRemoveOld({module:this.module, filter: filters,
      pagination: { current: pagination.current, pageSize: pagination.pageSize }})
    }
    this.setState({ loading: true });
    this.props
      .getOrganizations({
        filters: filters,
        pagination: { page: pagination.current, pageSize: pagination.pageSize },
        sorter: sorter
      })
      .then(resp => {
        pager.total = resp.paging.totalRecords;
        this.setState({
          loading: false,
          data: resp.data,
          pagination: pager
        });
      })
      .catch(ex => {
        this.setState({ loading: false });
      });
  };

  render() {
    let _state = this.state;
    let _this = this;
    let filtertag = Object.keys(this.state.filters).map(function(key1) {
      let keyLabel = _this.getHeaderKeys().find(el => el.dataIndex === key1);
      if (keyLabel.title.props && keyLabel.title.props.id) {
        keyLabel = <IntlMessages id={keyLabel.title.props.id} />;
      } else {
        keyLabel = key1;
      }
      if (_state.filters[key1].filter) {
        return (
          <span>
            <Button
              key={key1}
              type="primary"
              onClick={() =>
                _this.handleReset(_state.filters[key1].clearf, key1)
              }
            >
              {keyLabel} : {_state.filters[key1].val}
              <Icon type="close" />
            </Button>
            &nbsp;
          </span>
        );
      }
    });

    return (
      <div>
        <Row gutter={24}>
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography.Title level={4}>
              <IntlMessages id="admin.b2b2c_organizations.manage" />
            </Typography.Title>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            {Permission.canAccess("add_organization") ? (
              <Button
                type="primary"
                onClick={() => this.addItem()}
                className="right-fl def-blue"
              >
                <IntlMessages id="admin.organizations.add" />
              </Button>
            ) : (
              ""
            )}
          </Col>
        </Row>
        <hr></hr>
        <Row gutter={24} style={{ marginBottom: "20px" }}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            {filtertag}
          </Col>
        </Row>
        <Row gutter={24}>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={24}
            style={{ overflowX: "auto" }}
          >
            <Table
              bordered
              columns={this.getHeaderKeys()}
              rowKey={record => record.id}
              dataSource={this.state.data}
              pagination={this.state.pagination}
              loading={this.state.loading}
              onChange={this.handleTableChange}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

B2B2COrganizations.propTypes = {
  location: PropTypes.object,
  getOrganizations: PropTypes.func,
  deleteOrganization: PropTypes.func
};
function mapStateToProps(state) {
  return {...state.pagination};
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...B2B2COrgActions,...paginationActions }, dispatch);
}
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    B2B2COrganizations
  )
);
