import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import IntlMessages from "../../../../services/intlMesseges";
import { Table, Typography, Row, Col, Button, Form, Spin, Icon } from "antd";
import logo from "../../../../images/logo.png";
import * as invoiceActions from "../../../../redux/actions/invoice-actions";
import { PDFDownloadLink } from "@react-pdf/renderer";
import PdfDocument from "./invoice-pdf";
import AppLocale from "../../../../languageProvider";
import moment from "moment";

class OrganizationInvoice extends Component {
  state = {
    loading: true,
    invoiceData: null,
  };

  async componentDidMount() {
    this.getInvoiceData(this.props.match.params.id);
    const { language } = this.props;
    const currentAppLocale = AppLocale[language.locale];
    let labels = {
      "admin.invoice.invoice":
        currentAppLocale.messages["admin.invoice.invoice"],
      "admin.invoice.invoicenumber":
        currentAppLocale.messages["admin.invoice.invoicenumber"],
      "admin.invoice.invoicedate":
        currentAppLocale.messages["admin.invoice.invoicedate"],
      "admin.invoice.pageno": currentAppLocale.messages["admin.invoice.pageno"],
      "admin.invoice.billto": currentAppLocale.messages["admin.invoice.billto"],
      "admin.invoice.companyname":
        currentAppLocale.messages["admin.invoice.companyname"],
      "admin.invoice.companyaddress":
        currentAppLocale.messages["admin.invoice.companyaddress"],
      "admin.invoice.companycontact":
        currentAppLocale.messages["admin.invoice.companycontact"],
      "admin.invoice.duedate":
        currentAppLocale.messages["admin.invoice.duedate"],
      "admin.invoice.terms": currentAppLocale.messages["admin.invoice.terms"],
      "admin.invoice.days": currentAppLocale.messages["admin.invoice.days"],
      "admin.invoice.customerid":
        currentAppLocale.messages["admin.invoice.customerid"],
      "admin.invoice.ponumber":
        currentAppLocale.messages["admin.invoice.ponumber"],
      "admin.invoice.jobnumber":
        currentAppLocale.messages["admin.invoice.jobnumber"],
      "admin.invoice.description":
        currentAppLocale.messages["admin.invoice.description"],
      "admin.invoice.quantity":
        currentAppLocale.messages["admin.invoice.quantity"],
      "admin.invoice.unitprice":
        currentAppLocale.messages["admin.invoice.unitprice"],
      "admin.invoice.totalprice":
        currentAppLocale.messages["admin.invoice.totalprice"],
      "admin.invoice.remitwirepayments":
        currentAppLocale.messages["admin.invoice.remitwirepayments"],
      "admin.invoice.bankaccountname":
        currentAppLocale.messages["admin.invoice.bankaccountname"],
      "admin.invoice.bankroutingnumber":
        currentAppLocale.messages["admin.invoice.bankroutingnumber"],
      "admin.invoice.bankaccountnumber":
        currentAppLocale.messages["admin.invoice.bankaccountnumber"],
      "admin.invoice.bankswiftcode":
        currentAppLocale.messages["admin.invoice.bankswiftcode"],
      "admin.invoice.remitpaymentscorporate":
        currentAppLocale.messages["admin.invoice.remitpaymentscorporate"],
      "admin.invoice.totalusd":
        currentAppLocale.messages["admin.invoice.totalusd"],
    };
    this.setState({ labels: labels });
  }

  formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
  });

  getInvoiceData = (invoiceId) => {
    this.setState({ loading: true });
    this.props
      .getInvoice(invoiceId)
      .then((resp) => {
        let _invoiceData = resp.data;
        _invoiceData["items"] = [];
        _invoiceData["items"].push({
          key: "1",
          itemdescription: _invoiceData.productName,
          quantity: _invoiceData.productQty,
          unitprice: this.formatter.format(
            _invoiceData.productTotalAmount / _invoiceData.productQty
          ),
          totalprice: this.formatter.format(_invoiceData.productTotalAmount),
        });
        this.setState({
          loading: false,
          invoiceData: _invoiceData,
        });
      })
      .catch((_ex) => {
        this.setState({ loading: false });
      });
  };

  render() {
    const { invoiceData, labels } = this.state;
    let termDays = 0;
    if (invoiceData) {
      let createdDate = moment(invoiceData.createDate);
      let dueDate = moment(invoiceData.dueDate);
      termDays = Math.ceil(dueDate.diff(createdDate, "days", true));
    }
    const invoiceColumns = [
      {
        title: <IntlMessages id="admin.invoice.description" />,
        dataIndex: "itemdescription",
        key: "itemdescription",
        width: "40%",
      },
      {
        title: <IntlMessages id="admin.invoice.quantity" />,
        dataIndex: "quantity",
        key: "quantity",
      },
      {
        title: <IntlMessages id="admin.invoice.unitprice" />,
        dataIndex: "unitprice",
        key: "unitprice",
      },
      {
        title: <IntlMessages id="admin.invoice.totalprice" />,
        dataIndex: "totalprice",
        key: "totalrice",
      },
    ];

    return (
      <div>
        <Spin spinning={this.state.loading}>
          {this.state.invoiceData != null ? (
            <div>
              <Row gutter={24}>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Typography.Title level={4}>
                    <IntlMessages id="admin.account.viewinvoice" />
                  </Typography.Title>
                </Col>
                <Col
                  xs={24}
                  sm={24}
                  md={12}
                  lg={12}
                  xl={12}
                  style={{ textAlign: "right" }}
                >
                  <PDFDownloadLink
                    document={
                      <PdfDocument labels={labels} data={invoiceData} />
                    }
                    fileName="invoice.pdf"
                    className="pdf-button"
                    style={{
                      color: "white",
                      verticalAlign: "top",
                      height: "33px",
                    }}
                  >
                    <Icon
                      type="download"
                      style={{
                        marginRight: "5px",
                        display: "inline-block",
                        verticalAlign: "middle",
                        color: "white",
                      }}
                    />
                    <IntlMessages id="admin.button.download" />
                  </PDFDownloadLink>
                  &nbsp;&nbsp;
                  <Button
                    type="primary"
                    htmlType="button"
                    onClick={() => this.props.history.goBack()}
                  >
                    <IntlMessages id="admin.userlisting.back" />
                  </Button>
                </Col>
              </Row>
              <hr />
              <div
                className="wrap"
                style={{
                  maxWidth: "1000px",
                  padding: "30px",
                  border: "1px solid #000000",
                  borderTop: "5px solid #0f6cac",
                }}
              >
                <Row gutter={24} style={{ marginBottom: "20px" }}>
                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={24}
                    style={{ textAlign: "left" }}
                  >
                    <img src={logo} alt={logo} style={{ maxWidth: "200px" }} />
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    style={{ textAlign: "left", fontWeight: "bold" }}
                  >
                    FocusPoint International Inc.
                    <br />
                    15830 Foltz Parkway
                    <br />
                    Strongsville, Ohio 44149
                    <br />
                    (440) 878-7600
                    <br />
                    (440) 878-0092 Fax
                    <br />
                    Federal ID No. 47-4612937
                    <br />
                  </Col>

                  <Col
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    style={{ border: "2px solid #000000", fontWeight: "bold" }}
                  >
                    <h1 style={{ marginBottom: "0", paddingBottom: "0" }}>
                      <IntlMessages id="admin.invoice.invoice" />
                    </h1>
                    <IntlMessages id="admin.invoice.invoicenumber" />:{" "}
                    {invoiceData.invoiceNumber}
                    <br />
                    <IntlMessages id="admin.invoice.invoicedate" />:{" "}
                    {moment(invoiceData.createDate).format("MM/DD/YYYY")}
                    <br />
                    <IntlMessages id="admin.invoice.pageno" />: 1
                  </Col>
                </Row>
                <Row gutter={24} style={{ marginTop: "30px" }}>
                  <Col
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    style={{ textAlign: "left" }}
                  >
                    <h5
                      style={{ border: "none", margin: "0", color: "#5f5e5e" }}
                    >
                      Bill to:
                    </h5>
                    <Row gutter={24}>
                      <Col xs={22} sm={22} md={22} lg={22} xl={22}>
                        <strong>
                          <IntlMessages id="admin.invoice.companyname" />:
                        </strong>{" "}
                        {invoiceData.billingCompanyName}
                        <br />
                        <strong>
                          <IntlMessages id="admin.invoice.companyaddress" />:
                        </strong>{" "}
                        {invoiceData.billing_Address}
                        <br />
                        <strong>
                          <IntlMessages id="admin.invoice.companycontact" />:
                        </strong>{" "}
                        {invoiceData.billingContactPerson}
                      </Col>
                    </Row>
                  </Col>
                  {/* <Col
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      style={{ textAlign: "left", fontWeight: "bold" }}
                    >
                      Ship
                      <Row gutter={24}>
                        <Col xs={2} sm={2} md={2} lg={2} xl={2}>
                          To
                        </Col>
                        <Col xs={22} sm={22} md={22} lg={22} xl={22}>
                          Company Name:
                          <br />
                          Company Address:
                          <br />
                          Company Contact:
                        </Col>
                      </Row>
                    </Col> */}
                </Row>
                <Row gutter={24} style={{ marginTop: "40px" }}>
                  <Col
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    style={{ textAlign: "left" }}
                  >
                    {/* <Row gutter={24}>
                      <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                        Week Ended :
                      </Col>
                      <Col xs={18} sm={18} md={18} lg={18} xl={18}>
                        MM/DD/YYYY
                      </Col>
                    </Row> */}
                    <Row gutter={24}>
                      <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                        <strong>
                          <IntlMessages id="admin.invoice.duedate" />:{" "}
                        </strong>
                      </Col>
                      <Col xs={18} sm={18} md={18} lg={18} xl={18}>
                        {moment(invoiceData.dueDate).format("MM/DD/YYYY")}
                      </Col>
                    </Row>
                    <Row gutter={24} style={{ marginTop: "10px" }}>
                      <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                        <strong>
                          <IntlMessages id="admin.invoice.terms" />:
                        </strong>
                      </Col>
                      <Col xs={18} sm={18} md={18} lg={18} xl={18}>
                        {termDays} <IntlMessages id="admin.invoice.days" />
                      </Col>
                    </Row>
                  </Col>
                  <Col
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    style={{ textAlign: "left" }}
                  >
                    <Row gutter={24}>
                      <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                        <strong>
                          <IntlMessages id="admin.invoice.customerid" />:
                        </strong>
                      </Col>
                      <Col xs={18} sm={18} md={18} lg={18} xl={18}>
                        {invoiceData.customerID}
                      </Col>
                    </Row>
                    <Row gutter={24}>
                      <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                        <strong>
                          <IntlMessages id="admin.invoice.ponumber" />:
                        </strong>
                      </Col>
                      <Col xs={18} sm={18} md={18} lg={18} xl={18}>
                        {invoiceData.poNumber}
                      </Col>
                    </Row>
                    <Row gutter={24}>
                      <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                        <strong>
                          <IntlMessages id="admin.invoice.jobnumber" />:
                        </strong>
                      </Col>
                      <Col xs={18} sm={18} md={18} lg={18} xl={18}>
                        {invoiceData.jobNumber}
                      </Col>
                    </Row>
                    {/* <Row gutter={24}>
                      <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                        Our Order Number :
                      </Col>
                      <Col xs={18} sm={18} md={18} lg={18} xl={18}>
                        XXXXXX
                      </Col>
                    </Row> */}
                  </Col>
                </Row>
                <Row gutter={24} style={{ marginTop: "25px" }}>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Table
                      bordered
                      dataSource={invoiceData.items}
                      columns={invoiceColumns}
                      pagination={false}
                    />
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={24}
                    style={{
                      textAlign: "left",
                      fontWeight: "bold",
                      marginTop: "25px",
                    }}
                  >
                    <IntlMessages id="admin.invoice.remitwirepayments" />:
                    <br />
                    <IntlMessages id="admin.invoice.bankaccountname" />: Fifth
                    Third Bank
                    <br />
                    <IntlMessages id="admin.invoice.bankroutingnumber" />:
                    042000314
                    <br />
                    <IntlMessages id="admin.invoice.bankaccountnumber" />:
                    7283970288
                    <br />
                    <IntlMessages id="admin.invoice.bankswiftcode" />: FTBCUS3C
                    <br />
                  </Col>
                </Row>
                <Row
                  gutter={24}
                  style={{ fontWeight: "bold", marginTop: "25px" }}
                >
                  <Col
                    xs={16}
                    sm={16}
                    md={16}
                    lg={16}
                    xl={16}
                    style={{
                      border: "2px solid #000000",
                      padding: "30px",
                      height: "120px",
                      fontWeight: "bold",
                    }}
                  >
                    <IntlMessages id="admin.invoice.remitpaymentscorporate" />:{" "}
                    <br />
                    15830 FOLTZ INDUSTRIAL PARKWAY; <br />
                    CLEVELAND OH 44149
                  </Col>
                  <Col
                    xs={8}
                    sm={8}
                    md={8}
                    lg={8}
                    xl={8}
                    style={{
                      border: "2px solid #000000",
                      padding: "15px",
                      height: "120px",
                      textAlign: "left",
                    }}
                  >
                    <Row gutter={24}>
                      <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <IntlMessages id="admin.invoice.totalusd" />:
                      </Col>
                      <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        {this.formatter.format(invoiceData.productTotalAmount)}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </div>
          ) : (
            ""
          )}
        </Spin>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return { ...state.language, ...state.user };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...invoiceActions }, dispatch);
}
const WrappedForm = Form.create({ name: "view-invoice" })(OrganizationInvoice);
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    WrappedForm
  )
);
