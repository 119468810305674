import React, { Component } from "react";
import { connect } from "react-redux";
import "bootstrap/dist/js/bootstrap.bundle.min";
import LogoMain from "../../../images/logo-main.png";
import $ from "jquery";

var didScroll;
var lastScrollTop = 0;
var delta = 5;
var navbarHeight = 150;
class HeaderB2C extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageLoaded: false,
    };
  }

  componentDidMount() {
    $(window).scroll(function(event) {
      didScroll = true;
      clearTimeout($.data(this, "scrollTimer"));
      /* $.data(this, 'scrollTimer', setTimeout(function () {
				$('header#headerb2c').removeAttr('className');
			}, 500)); */
    });
  }

  componentDidUpdate = () => {
    let self = this;
    if (self.state.pageLoaded !== true) {
      self.setState({ pageLoaded: true });
      setInterval(() => {
        if (didScroll) {
          self.hasScrolled();
          didScroll = false;
        }
      }, 250);
    }
  };

  onChangeSearchText = (e) => {
    e.target.value = e.target.value.trimLeft();
  };

  hasScrolled() {
    var st = $(window).scrollTop();
    if (Math.abs(lastScrollTop - st) <= delta) return;
    if (st > lastScrollTop && st > navbarHeight) {
      $("header#headerb2c")
        .removeClass("nav-down")
        .addClass("nav-up");
    } else {
      if (st + $(window).height() < $(document).height()) {
        $("header#headerb2c")
          .removeClass("nav-up")
          .addClass("nav-down");
      }
    }
    lastScrollTop = st;
  }

  toggleMobileNav = () => {
    if($('body').hasClass('mobile-menu-active')){
      $('body').removeClass('mobile-menu-active');
    } else {
      $('body').addClass('mobile-menu-active');
    }
  }
  render() {
    return (
      <div>
        <div id="wrap_all">
          {/*
          <div className="advisory-bar">
            <a href="https://captravelassistance.com">
              Click here for news and updates about Coronavirus (COVID-19) and
              travel advisories.
            </a>
          </div>
          */}
          <header
            id="header-b2c-new"
            className="all_colors header_color light_bg_color av_header_top av_logo_left av_main_nav_header av_menu_right av_custom av_header_sticky av_header_shrinking av_header_stretch_disabled av_mobile_menu_tablet av_header_transparency av_header_searchicon av_header_unstick_top_disabled av_minimal_header av_bottom_nav_disabled av_alternate_logo_active av_header_border_disabled"
            role="banner"
          >
            <div
              id="header_main"
              className="container_wrap container_wrap_logo"
            >
              <div className="container av-logo-container">
                <div className="inner-container">
                  <div className="row">
                    <div className="logo-hwe col-md-5">
                      <a href="https://captravelassistance.com/">
                        <img
                          src={LogoMain}
                          alt="Care and Assistance Plan"
                          title="CAP Travel Fearlessly"
                        />
                      </a>
                    </div>
                    <div className="col-md-7 help-menu">
                      <div
                        id="nav_menu-6"
                        class="widget clearfix widget_nav_menu"
                      >
                        <div className="menu-top-nav-container">
                          <ul id="menu-top-nav" className="menu">
                            <li
                              id="menu-item-41"
                              className="menu-item menu-item-type-custom menu-item-object-custom menu-item-41"
                            >
                              <a href="https://portal.captravelassistance.com/">
                                My Account
                              </a>
                            </li>
                            <li
                              id="menu-item-1882"
                              className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1882"
                            >
                              <a href="https://captravelassistance.com/partner-with-us-2/">
                                Partner With Us
                              </a>
                            </li>
                            <li
                              id="menu-item-43"
                              className="menu-item menu-item-type-post_type menu-item-object-page menu-item-43"
                            >
                              <a href="https://captravelassistance.com/help-center/">
                                Help Center
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="clear"></div>
                  <nav
                    className="main_menu"
                    data-selectname="Select a page"
                    role="navigation"
                  >
                    <nav
                      id="ubermenu-main-4-avia-2"
                      className="ubermenu ubermenu-main ubermenu-menu-4 ubermenu-loc-avia ubermenu-responsive-collapse ubermenu-horizontal ubermenu-transition-slide ubermenu-trigger-hover_intent ubermenu-skin-minimal ubermenu-bar-align-center ubermenu-items-align-left ubermenu-bound-inner ubermenu-sub-indicators ubermenu-retractors-responsive ubermenu-submenu-indicator-closes ubermenu-notouch ubermenu-desktop-view ubermenu-interaction-hover"
                    >
                      <ul
                        id="ubermenu-nav-main-4-avia"
                        className="ubermenu-nav"
                        data-title="Main Menu"
                      >
                        <li
                          id="menu-item-45"
                          className="main-nav-item ubermenu-item ubermenu-item-type-post_type ubermenu-item-object-page ubermenu-item-has-children ubermenu-item-45 ubermenu-item-level-0 ubermenu-column ubermenu-column-auto ubermenu-has-submenu-drop ubermenu-has-submenu-mega"
                        >
                          <span
                            className="ubermenu-target ubermenu-item-layout-default ubermenu-item-layout-text_only"
                            tabindex="0"
                          >
                            <span className="ubermenu-target-title ubermenu-target-text">
                              A Plan For All
                            </span>
                            <i
                              className="ubermenu-sub-indicator fas fa-chevron-down"
                              aria-hidden="true"
                            ></i>
                            <span className="ubermenu-sub-indicator-close">
                              <i
                                className="fas fa-times"
                                aria-hidden="true"
                              ></i>
                            </span>
                          </span>
                          <ul className="ubermenu-submenu ubermenu-submenu-id-45 ubermenu-submenu-type-mega ubermenu-submenu-drop ubermenu-submenu-align-full_width">
                            <li className="  ubermenu-item ubermenu-item-type-custom ubermenu-item-object-ubermenu-custom ubermenu-item-has-children ubermenu-item-384 ubermenu-item-level-1 ubermenu-column ubermenu-column-1-2 ubermenu-has-submenu-stack ubermenu-item-type-column ubermenu-column-id-384">
                              <ul className="ubermenu-submenu ubermenu-submenu-id-384 ubermenu-submenu-type-stack">
                                <li
                                  id="menu-item-383"
                                  className="ubermenu-item ubermenu-item-type-custom ubermenu-item-object-ubermenu-custom ubermenu-item-383 ubermenu-item-auto ubermenu-item-normal ubermenu-item-level-2 ubermenu-column ubermenu-column-auto"
                                >
                                  <div className="ubermenu-content-block ubermenu-custom-content ubermenu-custom-content-padded firstnavcolumn">
                                    <h3>
                                      <b>
                                        Care &amp; Assist Plus Protection Plans
                                      </b>
                                    </h3>
                                    <p>
                                      Choose the protection plan that best
                                      describes your primary travel needs.
                                    </p>
                                    <p>
                                      All travel medical &amp; security
                                      assistance plans allow for uncapped
                                      international travel and are available for
                                      singles, couples, and groups (families,
                                      too).
                                    </p>
                                    <p>
                                      Whether you’re working hard or hardly
                                      working, CAP has your back.
                                    </p>
                                  </div>
                                </li>
                              </ul>
                            </li>
                            <li className=" trees plantype ubermenu-item ubermenu-item-type-custom ubermenu-item-object-ubermenu-custom ubermenu-item-has-children ubermenu-item-267 ubermenu-item-level-1 ubermenu-column ubermenu-column-1-2 ubermenu-has-submenu-stack ubermenu-item-type-column ubermenu-column-id-267">
                              <ul className="ubermenu-submenu ubermenu-submenu-id-267 ubermenu-submenu-type-stack">
                                <li
                                  id="menu-item-444"
                                  className="ubermenu-item ubermenu-item-type-custom ubermenu-item-object-ubermenu-custom ubermenu-item-444 ubermenu-item-auto ubermenu-item-normal ubermenu-item-level-2 ubermenu-column ubermenu-column-auto"
                                >
                                  <div className="ubermenu-content-block ubermenu-custom-content ubermenu-custom-content-padded">
                                    <h4 className="ubercustomtitle">
                                      <b>
                                        What best describes your travel type?
                                      </b>
                                      <br />
                                      <small>
                                        (What you will be doing most.)
                                      </small>
                                    </h4>
                                  </div>
                                </li>
                                <li
                                  id="menu-item-272"
                                  className="ubermenu-item ubermenu-item-type-post_type ubermenu-item-object-page ubermenu-item-272 ubermenu-item-auto ubermenu-item-normal ubermenu-item-level-2 ubermenu-column ubermenu-column-auto"
                                >
                                  <a
                                    className="ubermenu-target ubermenu-item-layout-default ubermenu-item-layout-text_only"
                                    href="https://captravelassistance.com/a-plan-for-everyone/leisure-travel-medical-and-security-assist-plan/"
                                    style={{
                                      height: "235px",
                                      lineHeight: "235px",
                                    }}
                                  >
                                    <span className="ubermenu-target-title ubermenu-target-text">
                                      <span className="palmtree"></span>
                                      Vacation / Leisure
                                    </span>
                                  </a>
                                </li>
                                <li
                                  id="menu-item-273"
                                  className="ubermenu-item ubermenu-item-type-post_type ubermenu-item-object-page ubermenu-item-273 ubermenu-item-auto ubermenu-item-normal ubermenu-item-level-2 ubermenu-column ubermenu-column-auto"
                                >
                                  <a
                                    className="ubermenu-target ubermenu-item-layout-default ubermenu-item-layout-text_only"
                                    href="https://captravelassistance.com/a-plan-for-everyone/business-travel-medical-and-security-assist-plan/"
                                    style={{
                                      height: "235px",
                                      lineHeight: "235px;",
                                    }}
                                  >
                                    <span className="ubermenu-target-title ubermenu-target-text">
                                      <span className="skyscraper"></span>
                                      Business / Employer
                                    </span>
                                  </a>
                                </li>
                                <li
                                  id="menu-item-268"
                                  className="ubermenu-item ubermenu-item-type-post_type ubermenu-item-object-page ubermenu-item-268 ubermenu-item-auto ubermenu-item-normal ubermenu-item-level-2 ubermenu-column ubermenu-column-auto"
                                >
                                  <a
                                    className="ubermenu-target ubermenu-item-layout-default ubermenu-item-layout-text_only"
                                    href="https://captravelassistance.com/a-plan-for-everyone/student-scholar/"
                                    style={{
                                      height: "235px",
                                      lineHeight: "235px;",
                                    }}
                                  >
                                    <span className="ubermenu-target-title ubermenu-target-text">
                                      <span className="gradcap"></span> Student
                                      / Mission
                                    </span>
                                  </a>
                                </li>
                                <li
                                  id="menu-item-269"
                                  className="ubermenu-item ubermenu-item-type-post_type ubermenu-item-object-page ubermenu-item-269 ubermenu-item-auto ubermenu-item-normal ubermenu-item-level-2 ubermenu-column ubermenu-column-auto"
                                >
                                  <a
                                    className="ubermenu-target ubermenu-item-layout-default ubermenu-item-layout-text_only"
                                    href="https://captravelassistance.com/a-plan-for-everyone/lgbtq-travel/"
                                    style={{
                                      height: "235px",
                                      lineHeight: "235px;",
                                    }}
                                  >
                                    <span className="ubermenu-target-title ubermenu-target-text">
                                      <span className="twohearts"></span> LGBTQ+
                                      Travel
                                    </span>
                                  </a>
                                </li>
                                <li
                                  id="menu-item-270"
                                  className="ubermenu-item ubermenu-item-type-post_type ubermenu-item-object-page ubermenu-item-270 ubermenu-item-auto ubermenu-item-normal ubermenu-item-level-2 ubermenu-column ubermenu-column-auto"
                                >
                                  <a
                                    className="ubermenu-target ubermenu-item-layout-default ubermenu-item-layout-text_only"
                                    href="https://captravelassistance.com/a-plan-for-everyone/adventure-travel-medical-and-security-assist-plan/"
                                    style={{
                                      height: "235px",
                                      lineHeight: "235px;",
                                    }}
                                  >
                                    <span className="ubermenu-target-title ubermenu-target-text">
                                      <span className="compass"></span>
                                      Adventure Travel
                                    </span>
                                  </a>
                                </li>
                              </ul>
                            </li>
                          </ul>
                        </li>
                        <li
                          id="menu-item-46"
                          className="main-nav-item ubermenu-item ubermenu-item-type-post_type ubermenu-item-object-page ubermenu-item-has-children ubermenu-item-46 ubermenu-item-level-0 ubermenu-column ubermenu-column-auto ubermenu-has-submenu-drop ubermenu-has-submenu-flyout"
                        >
                          <a
                            className="ubermenu-target ubermenu-item-layout-default ubermenu-item-layout-text_only"
                            href="https://captravelassistance.com/why-cap/cap-overview/"
                            tabindex="0"
                            style={{
                              height: "235px",
                              lineHeight: "235px;",
                            }}
                          >
                            <span className="ubermenu-target-title ubermenu-target-text">
                              Why CAP?
                            </span>
                            <i
                              className="ubermenu-sub-indicator fas fa-chevron-down"
                              aria-hidden="true"
                            ></i>
                            <span className="ubermenu-sub-indicator-close">
                              <i
                                className="fas fa-times"
                                aria-hidden="true"
                              ></i>
                            </span>
                          </a>
                          <ul className="ubermenu-submenu ubermenu-submenu-id-46 ubermenu-submenu-type-flyout ubermenu-submenu-drop ubermenu-submenu-align-left_edge_item">
                            <li
                              id="menu-item-244"
                              className="ubermenu-item ubermenu-item-type-post_type ubermenu-item-object-page ubermenu-item-244 ubermenu-item-normal ubermenu-item-level-1"
                            >
                              <a
                                className="ubermenu-target ubermenu-item-layout-default ubermenu-item-layout-text_only"
                                href="https://captravelassistance.com/why-cap/cap-overview/"
                              >
                                <span className="ubermenu-target-title ubermenu-target-text">
                                  Why Care &amp; Assistance Plus?
                                </span>
                              </a>
                            </li>
                            <li
                              id="menu-item-741"
                              className="ubermenu-item ubermenu-item-type-post_type ubermenu-item-object-page ubermenu-item-741 ubermenu-item-auto ubermenu-item-normal ubermenu-item-level-1"
                            >
                              <a
                                className="ubermenu-target ubermenu-item-layout-default ubermenu-item-layout-text_only"
                                href="https://captravelassistance.com/why-cap/cap-vs-travel-insurance-companies/"
                              >
                                <span className="ubermenu-target-title ubermenu-target-text">
                                  CAP vs. Insurance
                                </span>
                              </a>
                            </li>
                            <li
                              id="menu-item-245"
                              className="ubermenu-item ubermenu-item-type-post_type ubermenu-item-object-page ubermenu-item-245 ubermenu-item-normal ubermenu-item-level-1"
                            >
                              <a
                                className="ubermenu-target ubermenu-item-layout-default ubermenu-item-layout-text_only"
                                href="https://captravelassistance.com/why-cap/customer-stories/"
                              >
                                <span className="ubermenu-target-title ubermenu-target-text">
                                  Customer Stories
                                </span>
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li
                          id="menu-item-734"
                          className="main-nav-item ubermenu-item ubermenu-item-type-custom ubermenu-item-object-custom ubermenu-item-has-children ubermenu-item-734 ubermenu-item-level-0 ubermenu-column ubermenu-column-auto ubermenu-has-submenu-drop ubermenu-has-submenu-flyout"
                        >
                          <a
                            className="ubermenu-target ubermenu-item-layout-default ubermenu-item-layout-text_only"
                            href="https://captravelassistance.com/about-us/#"
                            tabindex="0"
                            style={{
                              height: "235px",
                              lineHeight: "235px;",
                            }}
                          >
                            <span className="ubermenu-target-title ubermenu-target-text">
                              Resources
                            </span>
                            <i
                              className="ubermenu-sub-indicator fas fa-chevron-down"
                              aria-hidden="true"
                            ></i>
                            <span className="ubermenu-sub-indicator-close">
                              <i
                                className="fas fa-times"
                                aria-hidden="true"
                              ></i>
                            </span>
                          </a>
                          <ul className="ubermenu-submenu ubermenu-submenu-id-734 ubermenu-submenu-type-flyout ubermenu-submenu-drop ubermenu-submenu-align-left_edge_item">
                            <li
                              id="menu-item-241"
                              className="ubermenu-item ubermenu-item-type-post_type ubermenu-item-object-page ubermenu-item-241 ubermenu-item-auto ubermenu-item-normal ubermenu-item-level-1"
                            >
                              <a
                                className="ubermenu-target ubermenu-item-layout-default ubermenu-item-layout-text_only"
                                href="https://captravelassistance.com/resources/app-travel-intelligence/"
                              >
                                <span className="ubermenu-target-title ubermenu-target-text">
                                  CAP Advantage App
                                </span>
                              </a>
                            </li>
                            <li
                              id="menu-item-243"
                              className="ubermenu-item ubermenu-item-type-post_type ubermenu-item-object-page ubermenu-item-243 ubermenu-item-auto ubermenu-item-normal ubermenu-item-level-1"
                            >
                              <a
                                className="ubermenu-target ubermenu-item-layout-default ubermenu-item-layout-text_only"
                                href="https://captravelassistance.com/resources/travel-protection-plan-faqs/"
                              >
                                <span className="ubermenu-target-title ubermenu-target-text">
                                  Travel Protection Plan FAQs
                                </span>
                              </a>
                            </li>
                            <li
                              id="menu-item-242"
                              className="ubermenu-item ubermenu-item-type-post_type ubermenu-item-object-page ubermenu-item-242 ubermenu-item-auto ubermenu-item-normal ubermenu-item-level-1"
                            >
                              <a
                                className="ubermenu-target ubermenu-item-layout-default ubermenu-item-layout-text_only"
                                href="https://captravelassistance.com/resources/world-travel-trends-blog/"
                              >
                                <span className="ubermenu-target-title ubermenu-target-text">
                                  World Travel Trends Blog
                                </span>
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li
                          id="menu-item-48"
                          className="main-nav-item ubermenu-item ubermenu-item-type-post_type ubermenu-item-object-page ubermenu-current-menu-item ubermenu-page_item ubermenu-page-item-21 ubermenu-current_page_item ubermenu-current-menu-ancestor ubermenu-current-menu-parent ubermenu-current_page_parent ubermenu-current_page_ancestor ubermenu-item-has-children ubermenu-item-48 ubermenu-item-level-0 ubermenu-column ubermenu-column-auto ubermenu-has-submenu-drop ubermenu-has-submenu-flyout"
                        >
                          <a
                            className="ubermenu-target ubermenu-item-layout-default ubermenu-item-layout-text_only"
                            href="https://captravelassistance.com/about-us/"
                            tabindex="0"
                            style={{ height: "235px", lineHeight: "235px" }}
                          >
                            <span className="ubermenu-target-title ubermenu-target-text">
                              About Us
                            </span>
                            <i
                              className="ubermenu-sub-indicator fas fa-chevron-down"
                              aria-hidden="true"
                            ></i>
                            <span className="ubermenu-sub-indicator-close">
                              <i
                                className="fas fa-times"
                                aria-hidden="true"
                              ></i>
                            </span>
                          </a>
                          <ul className="ubermenu-submenu ubermenu-submenu-id-48 ubermenu-submenu-type-flyout ubermenu-submenu-drop ubermenu-submenu-align-left_edge_item">
                            <li
                              id="menu-item-1184"
                              className="ubermenu-item ubermenu-item-type-post_type ubermenu-item-object-page ubermenu-current-menu-item ubermenu-page_item ubermenu-page-item-21 ubermenu-current_page_item ubermenu-item-1184 ubermenu-item-auto ubermenu-item-normal ubermenu-item-level-1"
                            >
                              <a
                                className="ubermenu-target ubermenu-item-layout-default ubermenu-item-layout-text_only"
                                href="https://captravelassistance.com/about-us/"
                              >
                                <span className="ubermenu-target-title ubermenu-target-text">
                                  Who We Are
                                </span>
                              </a>
                            </li>
                            <li
                              id="menu-item-375"
                              className="ubermenu-item ubermenu-item-type-post_type ubermenu-item-object-page ubermenu-item-375 ubermenu-item-auto ubermenu-item-normal ubermenu-item-level-1"
                            >
                              <a
                                className="ubermenu-target ubermenu-item-layout-default ubermenu-item-layout-text_only"
                                href="https://captravelassistance.com/about-us/our-story/"
                              >
                                <span className="ubermenu-target-title ubermenu-target-text">
                                  Our Story
                                </span>
                              </a>
                            </li>
                            <li
                              id="menu-item-480"
                              className="ubermenu-item ubermenu-item-type-post_type ubermenu-item-object-page ubermenu-item-480 ubermenu-item-auto ubermenu-item-normal ubermenu-item-level-1"
                            >
                              <a
                                className="ubermenu-target ubermenu-item-layout-default ubermenu-item-layout-text_only"
                                href="https://captravelassistance.com/about-us/our-people/"
                              >
                                <span className="ubermenu-target-title ubermenu-target-text">
                                  Our People
                                </span>
                              </a>
                            </li>
                            <li
                              id="menu-item-481"
                              className="ubermenu-item ubermenu-item-type-post_type ubermenu-item-object-page ubermenu-item-481 ubermenu-item-auto ubermenu-item-normal ubermenu-item-level-1"
                            >
                              <a
                                className="ubermenu-target ubermenu-item-layout-default ubermenu-item-layout-text_only"
                                href="https://captravelassistance.com/about-us/protection-plans/"
                              >
                                <span className="ubermenu-target-title ubermenu-target-text">
                                  Protection Plans
                                </span>
                              </a>
                            </li>
                            <li
                              id="menu-item-482"
                              className="ubermenu-item ubermenu-item-type-post_type ubermenu-item-object-page ubermenu-item-482 ubermenu-item-auto ubermenu-item-normal ubermenu-item-level-1"
                            >
                              <a
                                className="ubermenu-target ubermenu-item-layout-default ubermenu-item-layout-text_only"
                                href="https://captravelassistance.com/about-us/social-responsibility/"
                              >
                                <span className="ubermenu-target-title ubermenu-target-text">
                                  Social Responsibility
                                </span>
                              </a>
                            </li>
                            <li
                              id="menu-item-374"
                              className="ubermenu-item ubermenu-item-type-post_type ubermenu-item-object-page ubermenu-item-374 ubermenu-item-auto ubermenu-item-normal ubermenu-item-level-1"
                            >
                              <a
                                className="ubermenu-target ubermenu-item-layout-default ubermenu-item-layout-text_only"
                                href="https://captravelassistance.com/about-us/careers/"
                              >
                                <span className="ubermenu-target-title ubermenu-target-text">
                                  Careers
                                </span>
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li
                          id="menu-item-236"
                          className="showquickquote greenbg ubermenu-item ubermenu-item-type-custom ubermenu-item-object-custom ubermenu-item-236 ubermenu-item-level-0 ubermenu-column ubermenu-column-auto"
                        >
                          <a
                            className="ubermenu-target ubermenu-item-layout-default ubermenu-item-layout-text_only"
                            href="https://captravelassistance.com/about-us/#"
                            tabindex="0"
                            style={{
                              height: "235px",
                              lineHeight: "235px;",
                            }}
                          >
                            <span className="ubermenu-target-title ubermenu-target-text">
                              Quick Quote
                              <i
                                className="fas fa-paper-plane"
                                aria-hidden="true"
                              ></i>
                            </span>
                          </a>
                        </li>
                        <li className="av-burger-menu-main menu-item-avia-special ">
                          <a
                            href="https://captravelassistance.com/about-us/#"
                            aria-label="Menu"
                            aria-hidden="false"
                            style={{
                              height: "235px",
                              lineHeight: "235px;",
                            }}
                          >
                            <span className="av-hamburger av-hamburger--spin av-js-hamburger">
                              <span className="av-hamburger-box">
                                <span className="av-hamburger-inner"></span>
                                <strong>Menu</strong>
                              </span>
                            </span>
                            <span className="avia_hidden_link_text">Menu</span>
                          </a>
                        </li>
                      </ul>
                    </nav>
                  </nav>
                </div>
              </div>
            </div>

            <button id="mobile-toggle-menu" onClick={this.toggleMobileNav}>
              <i className="fas fa-bars"></i>
            </button>

            <div id="avia_alternate_menu_container">
              <nav
                className="main_menu"
                data-selectname="Select a page"
                role="navigation"
              >
                <div className="avia_alternate_menu av-main-nav-wrap">
                  <ul id="avia_alternate_menu" className="menu av-main-nav">
                    <li>
                      <a href="https://captravelassistance.com/">Home</a>
                    </li>
                    <li>
                      <a href="https://captravelassistance.com/a-plan-for-everyone/">
                        A Plan For All
                      </a>
                      <ul className="sub-menu">
                        <li>
                          <a href="https://captravelassistance.com/a-plan-for-everyone/adventure-travel-medical-and-security-assist-plan/">
                            Adventure Travel Medical and Security Assistance
                            Plan
                          </a>
                        </li>
                        <li>
                          <a href="https://captravelassistance.com/a-plan-for-everyone/business-travel-medical-and-security-assist-plan/">
                            Business Travel Medical and Security Assistance Plan
                          </a>
                        </li>
                        <li>
                          <a href="https://captravelassistance.com/a-plan-for-everyone/lgbtq-travel/">
                            LGBTQ+ Travel Medical and Security Assistance Plan
                          </a>
                        </li>
                        <li>
                          <a href="https://captravelassistance.com/a-plan-for-everyone/leisure-travel-medical-and-security-assist-plan/">
                            Leisure Travel Medical and Security Assistance Plan
                          </a>
                        </li>
                        <li>
                          <a href="https://captravelassistance.com/a-plan-for-everyone/student-scholar/">
                            Student/Missions Medical and Security Assistance
                            Plan
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <a href="https://captravelassistance.com/why-cap/">
                        Why CAP?
                      </a>
                      <ul className="sub-menu">
                        <li>
                          <a href="https://captravelassistance.com/why-cap/cap-overview/">
                            WHY CARE &amp; ASSISTANCE PLUS?
                          </a>
                        </li>
                        <li>
                          <a href="https://captravelassistance.com/why-cap/cap-vs-travel-insurance-companies/">
                            CAP vs. Travel Insurance Companies
                          </a>
                        </li>
                        <li>
                          <a href="https://captravelassistance.com/why-cap/customer-stories/">
                            Customer Stories
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <a href="https://captravelassistance.com/about-us/">
                        About Us
                      </a>
                      <ul className="sub-menu">
                        <li>
                          <a href="https://captravelassistance.com/about-us/our-story/">
                            Our Story
                          </a>
                        </li>
                        <li>
                          <a href="https://captravelassistance.com/about-us/our-people/">
                            Our People
                          </a>
                        </li>
                        <li>
                          <a href="https://captravelassistance.com/about-us/protection-plans/">
                            Protection Plans
                          </a>
                        </li>
                        <li>
                          <a href="https://captravelassistance.com/about-us/social-responsibility/">
                            Social Responsibility
                          </a>
                        </li>
                        <li>
                          <a href="https://captravelassistance.com/about-us/careers/">
                            Careers
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <a href="https://captravelassistance.com/resources/">
                        Resources
                      </a>
                      <ul className="sub-menu">
                        <li>
                          <a href="https://captravelassistance.com/resources/app-travel-intelligence/">
                            CAP Advantage App
                          </a>
                        </li>
                        <li>
                          <a href="https://captravelassistance.com/resources/world-travel-trends-blog/">
                            World Travel Trends Blog
                          </a>
                        </li>
                        <li>
                          <a href="https://captravelassistance.com/resources/travel-protection-plan-faqs/">
                            Travel Protection Plan FAQs
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <a href="https://captravelassistance.com/partner-with-us-2/">
                        Partner With Us
                      </a>
                    </li>
                    <li>
                      <a href="https://captravelassistance.com/help-center/">
                        Help Center
                      </a>
                    </li>
                    <li>
                      <a href="https://captravelassistance.com/legal-notice/">
                        Legal Notice
                      </a>
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
            <div className="header_bg"></div>
          </header>
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    ...state.user,
  };
}
export default connect(mapStateToProps, null, null, { forwardRef: true })(
  HeaderB2C
);
