import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {Modal, Tabs } from "antd";

const { TabPane } = Tabs;

class Showmodal extends Component {
    constructor(props) {
      super(props);
      this.state = {
        ModalText: '',
        ModalTitle: '',
        visible: false,
        }
      }

    componentDidMount(){
        this.showModal(this.props.modaldata);
    }
    
    showModal(data){
        this._bindCordinates(data)
    };

    _bindCordinates(data) {
      var text = this._binModalContent(data);
      this.setState({
          visible: true,
          ModalText: text,
          ModalTitle: data.title,
      });
      }

    _binModalContent(data){
        return(
        <Tabs>
        <TabPane tab="Report" key="report">
            <div dangerouslySetInnerHTML={{ __html: data.text }}></div>
        </TabPane>
        </Tabs>
        )
    }

    handleOk = () => {
    this.setState({
        visible: false,
    });
    this.props.onCancel();
    };

    handleCancel = () => {
    this.setState({
        visible: false,
    });
    this.props.onCancel();
    };


    render() {

        return (
            <Modal
                width = {800}
                title= {this.state.ModalTitle}
                visible={this.state.visible}
                onOk={this.handleOk}
                confirmLoading={this.state.confirmLoading}
                onCancel={this.handleCancel}
            >
            {this.state.ModalText}
            </Modal>
        )
      }
    }

function mapStateToProps(state) {
    return {
    };
  }
  function mapDispatchToProps(dispatch) {
    return bindActionCreators({ }, dispatch);
  }
  export default withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps,
      null,
      { forwardRef: true }
    )(Showmodal)
  );
    