import React from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import IntlMessages from "../../../services/intlMesseges";
import * as countriesActions from "../../../redux/actions/countries-actions";
import * as tiersActions from "../../../redux/actions/tiers-actions";
import { notifyUser } from "../../../services/notification-service";
import AppLocale from "../../../languageProvider";
import {
  Typography,
  Form,
  Input,
  Select,
  Button,
  Row,
  Col,
  Spin,
  Switch,
} from "antd";
import API2 from "../../../redux/api/admin-api";

import API from "../../../redux/api/tiers-api";
const { Option } = Select;

class CountriesEdit extends React.Component {
  state = {
    loading: true,
    confirmDirty: false,
    autoId: 0,
    title: "",
    description: "",
    isActive: Boolean,
    tiers: [],
    tierId: 0,
    data: {},
    countries: [],
  };

  async componentDidMount() {
    let countriesListTemp = await API2.getValidCountriesList();
    this.setState({ countries: countriesListTemp.countries });

    let tierslist = await API.getTiersList({});
    this.setState({ tiers: tierslist.data });

    if (
      this.props.match &&
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.id &&
      this.props.match.params.id !== "new"
    ) {
      this.setState({ autoId: this.props.match.params.id });

      const userinfo = await this.props.getCountriesListbyId(
        this.props.match.params.id
      );
      this.setState({ isActive: userinfo.isActive });
      this.setState(userinfo.data);

      this.setState({ loading: false });
    } else {
      this.setState({ loading: false });
    }
  }

  submitFormAdd = (data) => {
    this.props
      .updateCountriesData(data, this.state.autoId)
      .then((response) => {
        if (response.error) {
          notifyUser(response.error.message, "error");
          this.setState({ loading: false });
        } else {
          const { language } = this.props;
          const currentAppLocale = AppLocale[language.locale];
          notifyUser(
            currentAppLocale.messages["admin.managecountries.success"],
            "success"
          );
          this.props.history.push("./");
          this.setState({ loading: false });
        }
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };

  submitFormEdit = (data) => {
    this.props
      .updateCountriesData(data, this.state.autoId)

      .then((response) => {
        if (response.error) {
          notifyUser(response.error.message, "error");
          this.setState({ loading: false });
        } else {
          const { language } = this.props;
          const currentAppLocale = AppLocale[language.locale];
          this.props.history.push("./");
          this.setState({ loading: false });
          notifyUser(
            currentAppLocale.messages["admin.managecountries.updatesuccess"],
            "success"
          );
        }
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      Object.keys(values).map(function(key, _idx) {
        if (values[key] == null) {
          values[key] = "";
        } else {
          if (typeof values[key] == "string") {
            values[key] = values[key].trim();
          }
        }
        return key;
      });
      if (!err) {
        this.setState({ loading: true });
        values["isActive"] = this.state.isActive;
        if (this.state.autoId && this.state.autoId > 0) {
          values["autoId"] = this.state.autoId;
          values[
            "organizationCountryTierId"
          ] = this.state.organizationCountryTierId;
          values["riskLineDerivedCode"] = values.country;
          this.submitFormEdit(values);
        } else {
          this.submitFormAdd(values);
          console.log(values);
        }
      }
    });
  };
  onSwitchChange = (checked, e) => {
    this.setState({ isActive: checked });
    if (checked == true) {
      notifyUser("Activated successfully!", "success");
    } else {
      notifyUser("Inactivated successfully!", "success");
    }
  };
  render() {
    const { getFieldDecorator } = this.props.form;
    const { formLayout } = this.state;
    const formItemLayout =
      formLayout === "horizontal"
        ? {
            labelCol: { span: 4 },
            wrapperCol: { span: 14 },
          }
        : null;

    return (
      <div>
        <Row gutter={24}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Typography.Title level={4}>
              {this.state.autoId === 0 ? (
                <IntlMessages id="admin.manageTiers.addCountries" />
              ) : (
                <div>
                  <IntlMessages id="admin.manageTiers.editCountries" />{" "}
                  {this.state.description}{" "}
                </div>
              )}
            </Typography.Title>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Button
              type="primary"
              className="right-fl"
              htmlType="button"
              onClick={() => this.props.history.push("./")}
            >
              <IntlMessages id="admin.userlisting.back" />
            </Button>
          </Col>
        </Row>
        <hr />
        <div>
          <Spin spinning={this.state.loading}>
            <Form layout={formLayout} onSubmit={this.handleSubmit}>
              <Row gutter={24}>
                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                  <Form.Item
                    {...formItemLayout}
                    label={<IntlMessages id="admin.countries.countryName" />}
                  >
                    {getFieldDecorator("countryName", {
                      rules: [
                        {
                          required: true,
                          message: <IntlMessages id="admin.input.required" />,
                        },
                      ],
                      initialValue:
                        this.state.countryName === null
                          ? ""
                          : this.state.countryName,
                    })(<Input maxLength={30} />)}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                  <Form.Item
                    {...formItemLayout}
                    label={<IntlMessages id="admin.countries.countryCode" />}
                  >
                    {getFieldDecorator("countryCode", {
                      rules: [
                        {
                          required: true,
                          message: <IntlMessages id="admin.input.required" />,
                        },
                      ],
                      initialValue:
                        this.state.countryCode === null
                          ? ""
                          : this.state.countryCode,
                    })(<Input maxLength={30} />)}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                  <Form.Item
                    label={<IntlMessages id="admin.userlisting.tierstitle" />}
                    hasFeedback
                  >
                    {getFieldDecorator("tierLevelId", {
                      rules: [
                        {
                          required: true,
                          message: <IntlMessages id="admin.input.required" />,
                        },
                      ],
                      initialValue: this.state.tierLevelId,
                    })(
                      <Select>
                        {this.state.tiers.map(function(item) {
                          return (
                            <Option key={item.tierId} value={item.tierId}>
                              {item.description}
                            </Option>
                          );
                        })}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
              </Row>

              {this.state.autoId !== 0 ? (
                <Row gutter={24}>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Form.Item
                      label={
                        <IntlMessages id="this.state.risklinecountryCode" />
                      }
                    >
                      {getFieldDecorator("country", {
                        rules: [
                          {
                            required: true,
                            message: <IntlMessages id="admin.input.required" />,
                          },
                        ],

                        initialValue: this.state.riskLineDerivedCode,
                      })(
                        <Select
                          showSearch
                          filterOption={(input, option) =>
                            option.props.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          placeholder="Please enter Risk Line Country"
                        >
                          {this.state.countries.map(function(item) {
                            return (
                              <Option
                                key={item.countryCode}
                                value={item.countryCode}
                              >
                                {item.countryName}
                              </Option>
                            );
                          })}
                        </Select>
                      )}
                    </Form.Item>
                  </Col>
                </Row>
              ) : (
                ""
              )}

              <Row>
                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                  <Form.Item
                    {...formItemLayout}
                    label={<IntlMessages id="admin.organizationTypes.status" />}
                  >
                    <Switch
                      checkedChildren="Active"
                      unCheckedChildren="Inactive"
                      checked={this.state.isActive}
                      onChange={this.onSwitchChange}
                      name="status"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <Form.Item
                    {...formItemLayout}
                    label={<IntlMessages id="admin.manageTiers.editinfomessage" />}
                  >
                    {getFieldDecorator("infoMessage", {
                      rules: [
                        {
                          required: true,
                          message: <IntlMessages id="admin.input.required" />,
                        },
                      ],
                      initialValue:
                        this.state.infoMessage === null
                          ? ""
                          : this.state.infoMessage,
                    })(<Input maxLength={30} />)}
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Item>
                    <Button
                      type="primary"
                      style={{ display: "inline-block", marginRight: "10px" }}
                      className="def-blue"
                      htmlType="submit"
                    >
                      {this.state.autoId > 0 ? (
                        <IntlMessages id="admin.userlisting.update" />
                      ) : (
                        <IntlMessages id="admin.userlisting.add" />
                      )}
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Spin>
        </div>
      </div>
    );
  }
}

CountriesEdit.propTypes = {
  location: PropTypes.object,
  updateCountriesData: PropTypes.func,
  tiersDataById: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    ...state.tiers,
    ...state.language,
    // ...state.user
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...countriesActions, ...tiersActions }, dispatch);
}
const WrappedForm = Form.create({ name: "edit-user" })(CountriesEdit);
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    WrappedForm
  )
);
