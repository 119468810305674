import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import IntlMessages from "../../../../services/intlMesseges";
import { Row, Col, Typography, DatePicker, Button, Table, Input, Icon,  Form, Select } from "antd";
import * as UserService from "../../../../services/user-service";
import * as travellersReportActions from "../../../../redux/actions/travellers-report-actions";
import * as adminActions from "../../../../redux/actions/admin-actions";
import { ViewCell } from "../../../shared/components/helperCells";

import moment from "moment";
const dateFormat = "MM/DD/YYYY ";
const { Option } = Select;
const { RangePicker } = DatePicker;
class TravelersByActiveAlert extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activetravellerList: [],
      countries: [],
      pagination: {
        hideOnSinglePage: true,
        showTotal: (total, range) => {
          return (
            <span>
              <IntlMessages id="admin.text.showing" /> {range[0]}-{range[1]}{" "}
              <IntlMessages id="admin.text.of" /> {total}{" "}
              <IntlMessages id="admin.text.results" />
            </span>
          );
        },
        pageSize: 10,
        current: 1
      },
      filters: {},
      countryId: '',
      showTable: false,
      formLayout: undefined,
      role: '',
      startdate: null,
      enddate: null,
      riskAlerts: [],
      startValue: null
    };
    this.handleCountryChange = this.handleCountryChange.bind(this);
  }
  handleSubmit() {
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        if (this.state.filters !== {}) {
          this.setState({ filters: {} })
        }
        this.handleTableChange({ current: 1, pageSize: 10 }, {}, {});
      }
    })
  }
  disabledEndDate = e => {
    const { startdate } = this.state;
    var endval = moment(e._d).format('YYYY-MM-DD')
    if (!endval || !startdate) {
      return false;
    }
    return endval.valueOf() <= startdate.valueOf();
  };
  componentWillMount() {
    let currentUser = UserService.getUser();
    let userRole = currentUser.role;
    this.setState({ role: userRole })
  }
  async componentDidMount() {
    this.props.getCountriesListName().then(data => {
      this.setState({ countries: data });
      this.setState({ loading: true });
    })
  }

  viewAffectedTravelers = id => {
    const url = "./view/" + this.state.startdate + '/' + this.state.enddate + '/' + id + '/'
    window.open(url);
    //this.props.history.push("./view/" +this.state.startdate+'/'+this.state.enddate +'/'+ id +'/')

  };
  handleCountryChange(e) {
    if(e=== 'all'){
      this.setState({ countryId: '' })
    }else{
    var countryCode = this.state.countries.filter(data => (data.countryId === e))[0].countryCode
    this.setState({ countryId: countryCode })
    }
  }

  handleStartDateChange(e) {
    if (e !== null && e !== undefined && e.length!==0 ) {
      var starDate = moment(e[0]._d).format('YYYY-MM-DD')
      var enDate = moment(e[1]._d).format('YYYY-MM-DD')
      this.setState({ startdate: starDate })
      this.setState({ enddate: enDate })
    }

  }
  handleEndDateChange(e) {
    if (e != null) {
      var changedDate = moment(e._d).format('YYYY-MM-DD')
      this.setState({ enddate: changedDate })
    }
  }

  getHeaderKeys = () => {
    return [
      {
        title: <IntlMessages id="admin.userlisting.alertname" />,
        dataIndex: "alertName",
        ...this.getColumnSearchProps("alertName")
      },
      {
        title: <IntlMessages id="admin.report.alertType" />,
        dataIndex: "alertCategory",
        ...this.getColumnSearchProps("alertCategory")
      },
      {
        title: <IntlMessages id="admin.report.startdate" />,
        dataIndex: "startDate",
      },
      {
        title: <IntlMessages id="admin.report.enddate" />,
        dataIndex: "endDate",
      },
      {
        title: <IntlMessages id="admin.report.riskLevel" />,
        dataIndex: "riskLevel",
        ...this.getColumnSearchProps("riskLevel")
      },
      {
        title: <IntlMessages id="admin.report.traveleraffected" />,
        dataIndex: "affectedUsers",
      },
      {
        title: <IntlMessages id="admin.manageOrders.Action" />,
        rowKey: "action",
        render: (_text, record) => (
          <span>
            <ViewCell
              onViewCell={() => {
                this.viewAffectedTravelers(record.alertId);
              }}
            />

          </span>
        )
      }
    ];
  };

  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => {
      return (
        <div style={{ padding: 8 }}>
          <Input
            ref={node => {
              this.searchInput = node;
            }}
            value={selectedKeys}
            onChange={e =>
              setSelectedKeys(e.target.value ? e.target.value.trimLeft() : "")
            }
            onPressEnter={() =>
              this.handleSearch(
                selectedKeys,
                confirm,
                dataIndex,
                clearFilters,
                setSelectedKeys
              )
            }
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Button
            type="primary"
            onClick={() =>
              this.handleSearch(
                selectedKeys,
                confirm,
                dataIndex,
                clearFilters,
                setSelectedKeys
              )
            }
            disabled={selectedKeys != "" && selectedKeys !== null ? false : true}
            icon="search"
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            <IntlMessages id="admin.search" />
          </Button>
          <Button
            disabled={selectedKeys != "" && selectedKeys !== null ? false : true}
            onClick={() => this.handleReset(clearFilters, dataIndex)}
            size="small"
            style={{ width: 90 }}
          >
            <IntlMessages id="admin.reset" />
          </Button>
        </div>
      );
    },
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    }
  });

  handleSearch = (
    selectedKeys,
    confirm,
    dataIndex,
    clearFilters,
    setSelectedKeys
  ) => {
    if (selectedKeys === "") {
      return false;
    }

    this.setState({ searchText: selectedKeys });
    let filters = this.state.filters;
    filters[dataIndex] = {
      val: selectedKeys,
      clearf: clearFilters,
      filter: true,
      setSelectedKeys: setSelectedKeys,
      confirm: confirm
    };
    this.setState({ filters: filters });
    confirm();
  };

  handleReset = (clearFilters, dataIndex) => {
    clearFilters();
    let filters = this.state.filters;
    if (filters[dataIndex]) {
      if (filters[dataIndex].setSelectedKeys && typeof filters[dataIndex].setSelectedKeys === 'function') {
        filters[dataIndex].setSelectedKeys("");
        filters[dataIndex].confirm();
      }
    }
    filters[dataIndex] = { val: "", clearf: "", filter: false };
    this.setState({ filters: filters });
    this.setState({ searchText: "" });
  };

  handleTableChange = (pagination, filters, sorter) => {
    let currentUser = UserService.getUser();
    let orgId = currentUser.companyID;
    let userRole = currentUser.role;
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    filters.startDate = this.state.startdate
    filters.endDate = this.state.enddate
    filters.countryCode = this.state.countryId
    this.setState({ loading: true });
    this.props
      .getActiveAlertList(orgId, userRole, {
        filters: filters,
        pagination: { page: pagination.current, pageSize: pagination.pageSize },
        sorter: sorter,

      })
      .then(resp => {
        pager.total = resp.result.totalElement;
        this.setState({
          activetravellerList: resp.result.content,
          loading: false,
          pagination: pager,
          showTable: true
        });
      })
      .catch(ex => {
        this.setState({ loading: false });
      });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { formLayout } = this.props;
    const formItemLayout =
      formLayout === "horizontal"
        ? {
          labelCol: { span: 4 },
          wrapperCol: { span: 14 }
        }
        : null;
    let _state = this.state;
    let _this = this;


    let filtertag = Object.keys(this.state.filters).map(function (key1) {
      let keyLabel = _this.getHeaderKeys().find(el => el.dataIndex === key1);

      if (keyLabel.title.props && keyLabel.title.props.id) {
        keyLabel = <IntlMessages id={keyLabel.title.props.id} />;
      } else {
        keyLabel = key1;
      }

      if (_state.filters[key1].filter) {
        return (
          <span>
            <Button
              key={key1}
              type="primary"
              onClick={() =>
                _this.handleReset(_state.filters[key1].clearf, key1)
              }
            >
              {keyLabel} : {_state.filters[key1].val}
              <Icon type="close" />
            </Button>
            &nbsp;
          </span>
        );
      }
    });
    return (
      <div>
        <Row gutter={24}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Typography.Title level={4}>
              <IntlMessages id="admin.sidebar.submenu.TravelersByActiveAlert" />
            </Typography.Title>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12} className="align-right">
            <ul>
              <li>
                {/* <CSVLink filename={"travelersByActiveAlert.csv"} headers={headers} data={traveller} >
                  <Button>
                  <Icon type="download" />
                    <span><IntlMessages id="admin.report.csvdownload" /></span>
                  </Button>
                </CSVLink> */}
              </li>
              <li>
                {/* <Button  type="primary" onClick={() => window.print()}>
                  <i
                    style={{ display: "inline-block", marginRight: "5px" }}
                    className="fas fa-print"
                  ></i>
                  <span  ><IntlMessages id="admin.report.print" /></span>
                </Button> */}
              </li>
              <li>
                <Button
                  type="primary"
                  htmlType="button"
                  onClick={() => this.props.history.push("../")}
                >
                  <IntlMessages id="admin.userlisting.back" />
                </Button>
              </li>
            </ul>
          </Col>
        </Row>
        <hr />
        <Form layout={formLayout} >
          <Row gutter={24} style={{ marginBottom: "20px" }}>
            <Col xs={7}>
              <Form.Item
                {...formItemLayout}

              >
                {getFieldDecorator("todate", {
                  rules: [
                    {
                      required: true,
                      message: <IntlMessages id="admin.input.required"></IntlMessages>
                    }
                  ],

                  //initialValue: this.state.rangedate && this.state.rangedate.length === 2 ? this.state.rangedate : this.state.rangedate

                })(
                  <RangePicker
                    onChange={(e) => this.handleStartDateChange(e)}
                    style={{ width: "300px", marginTop: '20px' }}
                    disabled={/* this.state.formexpired || (this.state.itineraryData.fromDate && this.state.itineraryData.fromDate !== null) &&  */this.state.formexpired || this.state.resultsFound || (this.props.form.getFieldValue('flightnumber') !== null && this.props.form.getFieldValue('flightnumber') !== undefined && this.props.form.getFieldValue('flightnumber') !== "" && this.state.resultsFound)}
                    //disabledDate={disabledDate}

                    format={dateFormat}
                  />

                )}
              </Form.Item>
            </Col>

            <Col xs={5}>
              <Form.Item
                {...formItemLayout}

              >
                {getFieldDecorator("countryName", {
                  rules: [
                    {
                      required: false,
                      message: <IntlMessages id="admin.input.required"></IntlMessages>
                    }
                  ],
                })(
                  <Select
                    showSearch
                    //placeholder={<IntlMessages id="admin.report.country" />}
                    placeholder ="All Countries"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={(e) => this.handleCountryChange(e)}
                    style={{ width: "200px", marginTop: "20px" }}
                    defaultValue="All Countries"
                  >
                    <Option key='all'value='all'>All Countries</Option>
                    {this.state.countries.map(function (item) {
                      return (
                        <Option key={item.countryId} value={item.countryId}>
                          {item.countryName}
                        </Option>
                      );
                    })}
                  </Select>
                )}
              </Form.Item>
            </Col>

            {/* <DatePicker
              onChange={this.handleDateChange}
              style={{ width: "200px", margin: "20px auto" }}
            /> */}

            <Col xs={5}>
              <Button
                type="primary"
                style={{ marginTop: '20px' }}
                onClick={() => this.handleSubmit()}
              >
                <IntlMessages id="admin.organization.submit"></IntlMessages>

              </Button>
            </Col>
          </Row></Form>
        {/* <Row gutter={24}>
          <Col xs={24}>
            <Button
              type="primary"
              onClick={() => this.handleSubmit()}
            >
              Submit
            </Button>
          </Col>
        </Row> */}
        {this.state.showTable ?

          < div >

            <hr />

            <Row gutter={24} style={{ marginBottom: "20px" }}>
              <Col xs={24}>{filtertag}</Col>
            </Row>
            <Row gutter={24}>
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl={24}
                style={{ overflowX: "auto" }}
              >

                <Table
                  bordered
                  columns={this.getHeaderKeys()}
                  dataSource={this.state.activetravellerList}
                  pagination={this.state.pagination}
                  loading={this.state.loading}
                  onChange={this.handleTableChange}
                />
              </Col>
            </Row>
          </div>
          : ''
        }

      </div>

    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...travellersReportActions, ...adminActions }, dispatch);
}

const WrappedForm = Form.create({ name: "travelers-report" })(TravelersByActiveAlert);
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    WrappedForm
  )
);