import React, { Component } from "react";
import { Row, Col, Typography, Spin } from "antd";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import TravellerGoogleMapComponent from "./traveler-map/traveler-dashboard-map";
import TravellerMapDataAnalysis from "./data-analysis/data-analysis";
import TravellerListGeofencing from "./list-geofencing/list-geofencing";
import TravellerDashListTravelers from "./list-travelers/list-travelers";
import { DatePicker } from "antd";
import IntlMessages from "../../../services/intlMesseges";
import moment from "moment";
import * as AdminActions from "../../../redux/actions/admin-actions";
import * as UserService from "../../../services/user-service";

const { RangePicker } = DatePicker;
const dateFormat = "MM-DD-YYYY";
let now = new Date();
let start = moment(new Date(now.getFullYear(), now.getMonth(), 1, 0, 0, 0, 0));
let end = moment(
  new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59, 0)
);
const queryDateFormatStart = "YYYY-MM-DD";
const queryDateFormatEnd = "YYYY-MM-DD";

class TravelManagerDashboard extends Component {
  state = {
    overviewLoading: false,
    dataPickerDates: [start, end],
    itinerariesOverview: {
      flights: 0,
      alerts: 0,
      hotels: 0,
      vehicles: 0
    }
  };

  componentDidMount = () => {
    this.loadItinerariesOverview();
  };

  loadItinerariesOverview = async () => {
    this.setState({ overviewLoading: true });
    let currentUser = UserService.getUser();
    let dateRanges = this.state.dataPickerDates;

    let args = {
      startDate: dateRanges[0].format(queryDateFormatStart),
      endDate: dateRanges[1].format(queryDateFormatEnd),
      orgId: currentUser.companyID
    };

    this.props.getItinerariesOverview(args).then(response => {
      this.setState({ itinerariesOverview: response, overviewLoading: false });
    });
  };

  dateChanged = dates => {
    if(dates.length > 0){
      this.setState(
        {
          statsLoading: true,
          dataPickerDates: dates
        },
        () => this.loadItinerariesOverview()
      );
    } else {
      this.setState(
        {
          statsLoading: true,
          dataPickerDates: [start, end]
        },
        () => this.loadItinerariesOverview()
      );
    }
  };

  render() {
    return (
      <div className="super-admin-home">
        {/* <div>
          <Row gutter={[24, 24]} style={{ margin: "0" }}>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <TotalTravelers />
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <TotalPlaces />
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <TotalMembership />
            </Col>
          </Row>
        </div> */}

        <div>
          <Row gutter={[24, 24]} style={{ margin: "0" }}>
            <Col xs={24} sm={24} md={18} lg={18} xl={18}>
              <Typography.Title level={4}>
                <IntlMessages id="admin.sidebar.Dashboard" />
              </Typography.Title>
            </Col>
            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
              <RangePicker
                format={dateFormat}
                value={this.state.dataPickerDates}
                onChange={this.dateChanged}
                ranges={{
                  Today: [moment(), moment()],
                  Yesterday: [
                    moment().add("-1", "days"),
                    moment().add("-1", "days")
                  ],
                  "This Week": [
                    moment().startOf("isoWeek"),
                    moment().endOf("isoWeek")
                  ],
                  "Last Week": [
                    moment()
                      .add("-1", "week")
                      .startOf("isoWeek"),
                    moment()
                      .add("-1", "week")
                      .endOf("isoWeek")
                  ],
                  "This Month": [
                    moment().startOf("month"),
                    moment().endOf("month")
                  ],
                  "Last Month": [
                    moment()
                      .add("-1", "month")
                      .startOf("month"),
                    moment()
                      .add("-1", "month")
                      .endOf("month")
                  ]
                }}
              />
            </Col>
          </Row>
        </div>

        <Row type="flex" gutter={[24, 24]} style={{ margin: "0" }}>
          <Col xs={24} sm={24} md={16} lg={16} xl={16} className="mapTraveller">
            <TravellerGoogleMapComponent />
          </Col>
          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            <Spin spinning={this.state.overviewLoading}>
              <TravellerMapDataAnalysis
                itinerariesOverview={this.state.itinerariesOverview}
              />
            </Spin>
          </Col>
        </Row>
        <Row gutter={[24, 24]} style={{ margin: "0" }} className="listCompany">
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <TravellerListGeofencing />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <TravellerDashListTravelers />
          </Col>
        </Row>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...AdminActions }, dispatch);
}
export default withRouter(
  connect(null, mapDispatchToProps, null, { forwardRef: true })(
    TravelManagerDashboard
  )
);
