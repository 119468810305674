import Axios from "../../services/axios-service";

const getTravelersItineraries = () => {
  return Axios.get(
    "/api/iternary/fetchAllOrganizationItineraries",
    undefined,
    undefined
  );
};
const getTravelersItinerariesByOrgId = (orgId, userRole, data) => {
  let url;
  if (userRole === "Administrator" || userRole === "CSR") {
    data.filters.allOrgs = true;
    url =
      "/api/iternary/fetchItineraries?orgId=" +
      orgId +
      "&includeMapinfo=true&search=" +
      encodeURIComponent(JSON.stringify(data));
  } else {
    url =
      "/api/iternary/fetchItinerariesByOrgId?orgId=" +
      orgId +
      "&includeMapinfo=true&search=" +
      encodeURIComponent(JSON.stringify(data));
  }

  return Axios.get(url, undefined);
};
const getItinerarySummary = data => {
  return Axios.get(
    "/api/iternary/fetchItinerarySummary?orgId=" + data.orgId,
    undefined
  );
};

const getItineraryByPnrIdAndEmail = data => {
  return Axios.get(
    "/api/iternary/fetchItineraryByPnrIdAndEmail?pnrId=" +
      data.pnrId +
      "&email=" +
      encodeURIComponent(data.email),
    undefined
  );
};

const getManualItineraryByPnrIdAndEmail = data => {
  return Axios.get(
    "/api/ManualItineraryReporting/fetchItineraryByPnrIdAndEmail?pnrId=" +
      data.pnrId ,undefined
  );
};

export default {
  getTravelersItineraries,
  getTravelersItinerariesByOrgId,
  getItinerarySummary,
  getItineraryByPnrIdAndEmail,
  getManualItineraryByPnrIdAndEmail
};
