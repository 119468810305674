import React, { Component } from "react";
import {
  Form,
  Input,
  Select,
  Row,
  Checkbox,
  Col,
  Button,
  //DatePicker,
  Radio,
  Modal,
  message,
} from "antd";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import Text from "react-text";
import config from "../../../../../config";
import getSymbolFromCurrency from "currency-symbol-map";

var validator = require("email-validator");
const { Option } = Select;
const { confirm } = Modal;
class RegistrationForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      travellersObjectsArray: [],
      receive_alerts: true,
      isSavedLoaded: false,
      isEmergencyModalVisible: false,
      currency: "USD",
      currencyRate: 1,
    };
    this.onCheckCompanyChange = this.onCheckCompanyChange.bind(this);
    this.appendTravellerData = this.appendTravellerData.bind(this);
    this.handleBackClik = this.handleBackClik.bind(this);
    this.handleSwitchChange = this.handleSwitchChange.bind(this);
    this.saveDataAndNext = this.saveDataAndNext.bind(this);
    this.removeTraveller = this.removeTraveller.bind(this);
    this.updatePlanPrice = this.updatePlanPrice.bind(this);
    this.handlePlanChange = this.handlePlanChange.bind(this);
    this.showConfirm = this.showConfirm.bind(this);
  }

  async _onBlur(evt, idx) {
    var email = evt.target.value;
    var index = parseInt(evt.currentTarget.dataset.index);
    if (validator.validate(email)) {
      await this.props.validateTravellerEmail(email);
      if (
        this.props.emailCheckResponse &&
        this.props.emailCheckResponse.userExist
      ) {
        if (index > 0) {
          this.state.travellersObjectsArray[index].isEmailExit = true;
          this.setState({
            travellersObjectsArray: this.state.travellersObjectsArray,
          });
        }
        var objContent = {};
        objContent.title = "Email " + email + " already exist!";
        objContent.content =
          index === 0
            ? "Thank you for being a valued client! Please continue to your account to place an order."
            : "We already have contact information of user and will notify user in case its need to be updated.";
        objContent.okText = "Continue";
        this.showConfirm(objContent, index);
      } else {
        if (index > 0) {
          this.state.travellersObjectsArray[index].isEmailExit = false;
          this.setState({
            travellersObjectsArray: this.state.travellersObjectsArray,
          });
        }
      }
    }
  }

  showConfirm(objContent, index) {
    var self = this;
    var confirmArgs = {
      title: objContent.title,
      content: objContent.content,
      className: "gray-color",
      onOk() {
        if (index === 0) {
          self.props.history.push("/login");
        }
      },
      onCancel() {},
    };
    if (objContent.okText && objContent.okText !== null) {
      confirmArgs.okText = objContent.okText;
    }
    if (objContent.okText1 && objContent.okText1 !== null) {
      confirmArgs.okText1 = objContent.okText1;
    }
    confirm(confirmArgs);
    console.log(confirmArgs)
  }

  async componentDidMount() {
    this.setState({
      currency: this.props.currency,
      currencyRate: this.props.currencyRate,
    });
    await this.props.getAllCountries();
    if (this.props.isLoggedIn) {
      var isInfoUpdated = false;
      await this.props.getTravellerInfo(this.props.userData.userID);
      if (this.props.travellerInfo && this.props.travellerInfo.email) {
        if (this.props.personalDetails && this.props.personalDetails.length > 0)
          isInfoUpdated = true;
        var travellerObject = {
          first_name: this.props.travellerInfo.firstName,
          last_name: this.props.travellerInfo.lastName,
          email: this.props.travellerInfo.email,
          dob: this.props.travellerInfo.dateOfBirth,
          country: this.props.travellerInfo.countryId,
          mobile_number: this.props.travellerInfo.contactNo,
          is_bussiness_travelling: this.props.travellerInfo.companyName
            ? true
            : false,
          rel_first_name: isInfoUpdated
            ? this.props.personalDetails[0].rel_first_name
            : this.props.travellerInfo.emergencyFirstName,
          rel_last_name: isInfoUpdated
            ? this.props.personalDetails[0].rel_last_name
            : this.props.travellerInfo.emergencyLastName,
          relationship: isInfoUpdated
            ? this.props.personalDetails[0].relationship
            : this.props.travellerInfo.emergencyRelationship,
          rel_phone_number: isInfoUpdated
            ? this.props.personalDetails[0].rel_phone_number
            : this.props.travellerInfo.emergencyContactNo,
          company_name: this.props.travellerInfo.companyName,
          product_id: isInfoUpdated
            ? this.props.personalDetails[0].product_id
            : this.props.planType.productId,
          is_annual: false,
          isEmailExit: true,
        };
        var tempArray = [];
        tempArray.push(travellerObject);
        if (
          this.props.personalDetails &&
          this.props.personalDetails.length > 1
        ) {
          for (var i = 1; i < this.props.personalDetails.length; i++) {
            tempArray.push(this.props.personalDetails[i]);
          }
        }
        this.setState({ travellersObjectsArray: tempArray });
      } else {
        this.state.travellersObjectsArray.push({
          first_name: "",
          last_name: "",
          email: "",
          dob: "",
          country: "",
          mobile_number: "",
          is_bussiness_travelling: false,
          rel_last_name: "",
          rel_first_name: "",
          relationship: "",
          rel_phone_number: "",
          company_name: "",
          product_id: this.props.planType.productId,
          is_annual: false,
          isEmailExit: false,
        });
      }
    } else {
      if (this.props.personalDetails && this.props.personalDetails.length > 0) {
        this.setState({
          travellersObjectsArray: this.props.personalDetails,
          isSavedLoaded: true,
        });
      } else {
        this.state.travellersObjectsArray.push({
          first_name: "",
          last_name: "",
          email: "",
          dob: "",
          country: "",
          mobile_number: "",
          is_bussiness_travelling: false,
          rel_last_name: "",
          rel_first_name: "",
          relationship: "",
          rel_phone_number: "",
          company_name: "",
          product_id: this.props.planType.productId,
          is_annual: false,
          isEmailExit: false,
        });
      }
    }
  }

  async handlePlanChange(evt) {
    var targetValue = evt.target.value.split(",");
    this.state.travellersObjectsArray[targetValue[0]].product_id =
      targetValue[1];
    var totalPrice = 0;
    for (var i = 0; i < this.state.travellersObjectsArray.length; i++) {
      var product_id = 0;
      if (i === parseInt(targetValue[0])) {
        product_id = targetValue[1];
      } else {
        var travellerObject = this.state.travellersObjectsArray[i];
        product_id = travellerObject.product_id;
      }
      var dayPriceObject = this.props.productPriceList.find(
        (x) =>
          x.tierId === parseInt(this.props.planTerm.tierValue) &&
          x.dayPackage === parseInt(this.props.planTerm.days) &&
          x.productId === parseInt(product_id)
      );
      var productPrice = dayPriceObject ? dayPriceObject.retailPrice : 0;
      totalPrice = totalPrice + parseInt(productPrice);
    }
    let planTermObject = this.props.planTerm;
    planTermObject.totalPrice = totalPrice;
    await this.props.SavePlanTerm(planTermObject);
    this.setState({ isSavedLoaded: false });
    this.props.updatePrice();
  }

  async updatePlanPrice(type, productId) {
    var dayPriceObject;
    if (this.props.planTerm && this.props.planTerm.Price) {
      if (type !== "update") {
        let planTermObject = this.props.planTerm;
        if (this.props.planTerm.isAnnual) {
          var maxPrice = 999;
          if (
            this.props.productPriceList &&
            this.props.productPriceList.length > 0
          ) {
            dayPriceObject = this.props.productPriceList.find(
              (x) =>
                x.tierId === 1 &&
                x.dayPackage === 365 &&
                x.productId ===
                  this.props.productInfo[this.props.productInfo.length - 2]
                    .productId
            );
            maxPrice = dayPriceObject.retailPrice;
          }
          planTermObject.totalPrice =
            type === "add"
              ? planTermObject.totalPrice + maxPrice
              : planTermObject.totalPrice - maxPrice;
        } else {
          dayPriceObject = this.props.productPriceList.find(
            (x) =>
              x.tierId === parseInt(this.props.planTerm.tierValue) &&
              x.dayPackage === parseInt(this.props.planTerm.days) &&
              x.productId === parseInt(productId)
          );
          planTermObject.totalPrice =
            type === "add"
              ? planTermObject.totalPrice + dayPriceObject.retailPrice
              : planTermObject.totalPrice - dayPriceObject.retailPrice;
        }

        await this.props.SavePlanTerm(planTermObject);
        this.props.updatePrice();
      } else {
        let planTermObject = this.props.planTerm;
        //planTermObject.Price = this.props.planTerm.totalPrice;
        await this.props.SavePlanTerm(planTermObject);
      }
    }
  }

  async removeTraveller(evt) {
    var travellerID = parseInt(evt.target.dataset.value);
    this.updatePlanPrice(
      "remove",
      this.state.travellersObjectsArray[travellerID].product_id
    );
    this.state.travellersObjectsArray.splice(travellerID, 1);
    this.setState({
      travellersObjectsArray: this.state.travellersObjectsArray,
    });
  }

  handleSwitchChange(evt) {
    this.setState({ receive_alerts: !this.state.receive_alerts });
  }

  handleBackClik(evt) {
    this.props.handleBack();
  }

  async appendTravellerData() {
    var productId =
      this.props.personalDetails &&
      this.props.personalDetails[0] &&
      this.props.personalDetails[0].product_id
        ? this.props.personalDetails[0].product_id
        : this.props.planType.productId;
    this.state.travellersObjectsArray.push({
      first_name: "",
      last_name: "",
      email: "",
      dob: "",
      country: "",
      mobile_number: "",
      is_bussiness_travelling: false,
      rel_last_name: "",
      rel_first_name: "",
      relationship: "",
      rel_phone_number: "",
      company_name: "",
      product_id: productId,
      is_annual: false,
      isEmailExit: false,
    });

    this.updatePlanPrice("add", productId);
  }

  onCheckCompanyChange(evt) {
    var travellerID = parseInt(
      evt.target.id.replace("traveller_check_company", "")
    );
    this.state.travellersObjectsArray[travellerID]["is_bussiness_travelling"] =
      evt.target.checked;
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        Object.keys(values).map(function(key, _idx) {
          if (values[key] == null) {
            values[key] = "";
          } else {
            if (typeof values[key] == "string") {
              values[key] = values[key].trim();
            }
          }
        });
        this.checkIfEmailsAlreadyExist(values);
        // this.saveDataAndNext(values);
      }
    });
  };

  async checkIfEmailsAlreadyExist(formData) {
    var isValidEmails = true;
    var emailUsed = [];
    for (var i = 0; i < this.state.travellersObjectsArray.length; i++) {
      if (!this.props.isLoggedIn) {
        if (i === 0) {
          await this.props.flushEmailCheckResponse();
          var email = formData["email" + i];
          await this.props.validateTravellerEmail(email);
          if (
            this.props.emailCheckResponse &&
            this.props.emailCheckResponse.userExist
          ) {
            isValidEmails = false;
            const selector = document.getElementById("traveller_email" + i);
            if (selector) selector.focus();
            break;
          }
        }
      }
      if (
        formData["dob" + i] &&
        !this.state.travellersObjectsArray[i].isEmailExit
      ) {
        var selectedDate = new Date(
          /* formData["dob" + i]._d */ formData["dob" + i]
        );
        if (i === 0) {
          var isValidDOB = this.checkValidDateOfBirth(
            selectedDate.getDay(),
            selectedDate.getMonth(),
            selectedDate.getFullYear()
          );
          if (!isValidDOB) {
            isValidEmails = false;
            const selectorDOB = document.getElementById("traveller_dob" + i);
            if (selectorDOB) selectorDOB.focus();
            message.error("Please select a valid date of birth.");
            break;
          }
        } else {
          const diffTime = selectedDate - new Date();
          const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
          if (diffDays > 0) {
            isValidEmails = false;
            const selectorDOB = document.getElementById("traveller_dob" + i);
            if (selectorDOB) selectorDOB.focus();
            message.error("Please select a valid date of birth.");
            break;
          }
        }
      }
      if (formData["email" + i] && formData["email" + i] !== "") {
        if (emailUsed.indexOf(formData["email" + i]) < 0) {
          emailUsed.push(formData["email" + i]);
        } else {
          isValidEmails = false;
          const selector = document.getElementById("traveller_email" + i);
          if (selector) selector.focus();
          message.error(
            "Email " + formData["email" + i] + " already assigned."
          );
          break;
        }
      }
      // To validate phone number
      // if(formData["phone" + i] && formData["phone" + i] !== ""){
      //   if(mobileNumberUsed.indexOf(formData["phone" + i]) < 0 ){
      //     mobileNumberUsed.push(formData["phone" + i]);
      //   }else{
      //       isValidEmails = false;
      //       const selector = document.getElementById("traveller_phone" + i);
      //       if (selector) selector.focus();
      //       message.error('Phone number  '+ formData["phone" + i] + ' already assigned.');
      //       break;
      //   }
      // }
    }

    if (isValidEmails) this.saveDataAndNext(formData);
  }

  checkValidDateOfBirth(day, month, year) {
    return new Date(year + 18, month - 1, day) <= new Date();
  }

  async saveDataAndNext(formData) {
    let personalDetails = [];
    for (var i = 0; i < this.state.travellersObjectsArray.length; i++) {
      let traveller = {
        first_name: formData["first_name" + i],
        last_name: formData["last_name" + i],
        dob:
          formData["dob" + i] && formData["dob" + i]._d
            ? moment(formData["dob" + i]._d).format()
            : moment(formData["dob" + i]).format(),
        email: formData["email" + i],
        country: formData["country" + i],
        mobile_number: formData["phone" + i],
        rel_last_name: formData["rel_last_name" + i],
        rel_first_name: formData["rel_first_name" + i],
        relationship: formData["relationship" + i],
        rel_phone_number: formData["rel_phone_number" + i],
        company_name: formData["company_name" + i],
        is_bussiness_travelling: formData["check_company" + i],
        receiver_alert: this.state.receive_alerts,
        IsPrimary: i === 0 ? true : false,
        product_id: this.state.travellersObjectsArray[i].product_id,
        is_annual: false,
        isEmailExit: this.state.travellersObjectsArray[i].isEmailExit,
      };
      personalDetails.push(traveller);
    }
    await this.props.SaveStep("3");
    await this.props.SavePersonalDetails(personalDetails);
    this.updatePlanPrice("update", "");
    this.props.history.push(this.props.b2cBaseUrl + "/billing-information");
  }

  _bindCountries() {
    return this.props.allCountries.map((country, index) => {
      if (country.countryId !== config.allCountriesCountryId) {
        return (
          <Option key={"con" + index} value={country.countryId}>
            {country.countryName}
          </Option>
        );
      }
    });
  }

  _bindProductRadio(idx, traveller) {
    return (
      <Radio.Group
        style={{ width: "100%" }}
        disabled={this.props.planTerm.isAnnual ? true : false}
        onChange={this.handlePlanChange}
      >
        <Row gutter={24}>
          {this.props.productInfo.map((product, index) => {
            let isCapAdvantage =
              this.props.productInfo.length - 1 === index ? true : false;
            return this._bindRadioOptions(
              traveller,
              product,
              idx,
              index,
              isCapAdvantage
            );
          })}
        </Row>
      </Radio.Group>
    );
  }
  _bindRadioOptions(
    traveller,
    product,
    indexTraveller,
    idxProduct,
    isCapAdvantage
  ) {
    var priceText = false;
    var planIncluded = "";
    if (!this.props.planTerm.isAnnual) {
      var selectedProductId = traveller.product_id
        ? parseInt(traveller.product_id)
        : this.props.planType.productId;
      if (product.productId > selectedProductId) {
        priceText = true;
        var currentProductPrice = this.props.productPriceList.find(
          (x) =>
            x.tierId === parseInt(this.props.planTerm.tierValue) &&
            x.dayPackage === parseInt(this.props.planTerm.days) &&
            x.productId === parseInt(selectedProductId)
        );
        var upGradedProductPrice = this.props.productPriceList.find(
          (x) =>
            x.tierId === parseInt(this.props.planTerm.tierValue) &&
            x.dayPackage === parseInt(this.props.planTerm.days) &&
            x.productId === parseInt(product.productId)
        );
        var priceDiffernce =
          upGradedProductPrice && currentProductPrice
            ? upGradedProductPrice.retailPrice - currentProductPrice.retailPrice
            : 0;
      }
      if (product.productId === selectedProductId) {
        planIncluded = "Selected plan";
      } else if (product.productId < selectedProductId) {
        planIncluded = "Included";
      }
    } else {
      planIncluded = "Included";
    }
    return (
      <Col
        xs={24}
        sm={8}
        md={8}
        lg={8}
        xl={8}
        style={{ textAlign: "left" }}
        className="antd-check"
        key={idxProduct}
      >
        {isCapAdvantage !== false ? (
          <Radio
            disabled={true}
            value={indexTraveller + "," + product.productId}
          >
            <Text>
              {product.name} {<br></br>}
              <span
                style={{
                  color: "#0986d6",
                  fontWeight: "bold",
                  fontStyle: "italic",
                }}
              >
                Included
              </span>
            </Text>
          </Radio>
        ) : (
          <Radio value={indexTraveller + "," + product.productId}>
            {!priceText ? (
              <Text>
                {product.name}
                {<br></br>}
                <span
                  style={{
                    color: "#0683bb",
                    fontWeight: "bold",
                    fontStyle: "italic",
                  }}
                >
                  {planIncluded !== "" ? planIncluded : ""}
                </span>
              </Text>
            ) : (
              <Text>
                {" "}
                {product.name} {<br></br>}
                <span
                  style={{
                    color: "#0986d6",
                    fontWeight: "bold",
                    fontStyle: "italic",
                  }}
                >
                  Upgrade for only ${priceDiffernce.toFixed(2)}
                </span>
              </Text>
            )}
          </Radio>
        )}
      </Col>
    );
  }

  disabledPrimaryDate(current) {
    var currentDate = new Date();
    return (
      current &&
      current >
        moment(
          new Date(
            currentDate.getFullYear() - 18,
            currentDate.getMonth(),
            currentDate.getDay()
          )
        )
    );
  }

  disableCompanionDate(current) {
    return current && current > moment().endOf("day");
  }

  setSameAsPrimary = (index) => {
    let form = this.props.form;
    let rel_first_name = form.getFieldValue("rel_first_name" + (index - 1));
    let rel_last_name = form.getFieldValue("rel_last_name" + (index - 1));
    let relationship = form.getFieldValue("relationship" + (index - 1));
    let rel_phone_number = form.getFieldValue("rel_phone_number" + (index - 1));
    if (
      rel_first_name == "" ||
      rel_last_name == "" ||
      relationship == "" ||
      rel_phone_number == ""
    ) {
      this.setState({
        isEmergencyModalVisible: true,
        ["isSameAsPrimary_" + index]: false,
      });
    } else {
      let isAlreadyChecked = this.state["isSameAsPrimary_" + index] || false;
      if (isAlreadyChecked) {
        form.setFieldsValue({
          ["rel_first_name" + index]: "",
          ["rel_last_name" + index]: "",
          ["relationship" + index]: "",
          ["rel_phone_number" + index]: "",
        });
        this.setState({ ["isSameAsPrimary_" + index]: false });
      } else {
        form.setFieldsValue({
          ["rel_first_name" + index]: form.getFieldValue(
            "rel_first_name" + (index - 1)
          ),
          ["rel_last_name" + index]: form.getFieldValue(
            "rel_last_name" + (index - 1)
          ),
          ["relationship" + index]: form.getFieldValue(
            "relationship" + (index - 1)
          ),
          ["rel_phone_number" + index]: form.getFieldValue(
            "rel_phone_number" + (index - 1)
          ),
        });
        this.setState({ ["isSameAsPrimary_" + index]: true });
      }
    }
  };

  showEmergencyModal = () => {
    this.setState({ isEmergencyModalVisible: true });
  };

  hideEmergencyModal = () => {
    this.setState({ isEmergencyModalVisible: false });
  };

  disableSameAs = (index) => {
    this.state["isSameAsPrimary_" + index] = false;
  };
  handleContactExistance(rule, value, callback) {
    //const re = /^[0-9']+$/;
    const re = /^(?=[+0-9]*$)(?!.*[<>'"/;`%~@#$^*()_=[\]{}|\\,.?: -])/;
    var test = re.test(value);
    if (value !== "" && (value.length < 6 || test === false)) {
      callback("Please enter valid Phone Number");
    } else {
      callback();
    }
  }
  _subYears = (date, years) => {
    var d = new Date(date);
    d.setFullYear(d.getFullYear() - years);
    return d;
  };
  setDOBState = (date, index) => {
    let _dob = this.state.dob || [];
    _dob[index] = new Date(date);
    this.setState({ dob: _dob });
  };
  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div>
        <Modal
          title="Warning!"
          visible={this.state.isEmergencyModalVisible}
          onCancel={this.hideEmergencyModal}
          footer={[
            <Button
              key="cancel"
              type="primary"
              onClick={this.hideEmergencyModal}
            >
              Ok
            </Button>,
          ]}
          style={{ marginTop: "60px" }}
        >
          <p>Please fill all the fields of primary emergency contact first.</p>
        </Modal>
        <Form onSubmit={this.handleSubmit}>
          {this.state.travellersObjectsArray.map((traveller, index) => (
            <div key={index}>
              <div className="wrap">
                <div className="personal-info">
                  <Row gutter={24}>
                    <Col
                      xs={24}
                      sm={24}
                      md={24}
                      lg={24}
                      xl={24}
                      className={index === 0 ? "hide" : "show"}
                    >
                      <Button
                        type="primary"
                        style={{ float: "right", marginBottom: "15px" }}
                        data-value={index}
                        onClick={this.removeTraveller}
                      >
                        Remove Traveler
                      </Button>
                    </Col>
                  </Row>
                  <Row gutter={24}>
                    <Col xs={24} sm={24} md={24} lg={8}>
                      <h3>
                        {index === 0 ? "Personal Details" : "Companion Details"}
                      </h3>
                    </Col>
                    {this.props.productInfo &&
                    this.props.productInfo.length > 0 ? (
                      <Col
                        xs={24}
                        sm={24}
                        md={24}
                        lg={16}
                        style={{ textAlign: "right", paddingRight: "25px" }}
                      >
                        <Form.Item>
                          <Row gutter={24}>
                            {getFieldDecorator("plan_type" + index, {
                              initialValue: index + "," + traveller.product_id,
                            })(this._bindProductRadio(index, traveller))}
                            <span>
                              {this.props.planTerm.isAnnual
                                ? "You have selected Annual plan!"
                                : ""}
                            </span>
                          </Row>
                        </Form.Item>
                      </Col>
                    ) : (
                      ""
                    )}
                  </Row>
                  <hr style={{ clear: "both" }}></hr>
                  <Row gutter={24}>
                    <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                      <Form.Item label="First Name">
                        {getFieldDecorator("first_name" + index, {
                          initialValue: traveller.first_name,
                          rules: [
                            {
                              required: traveller.isEmailExit ? false : true,
                              message: "Please enter your first name!",
                              whitespace: false,
                            },
                            {
                              pattern: new RegExp(/^(\S.*)[a-zA-Z-.']+$/),
                              message: "Please enter a valid first name!",
                            },
                          ],
                          onChange: () => this.disableSameAs(index),
                        })(
                          <Input
                            maxLength={50}
                            disabled={
                              (index === 0 && this.props.isLoggedIn) ||
                              traveller.isEmailExit
                                ? true
                                : false
                            }
                          />
                        )}
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                      <Form.Item label="Last Name">
                        {getFieldDecorator("last_name" + index, {
                          initialValue: traveller.last_name,
                          rules: [
                            {
                              required: traveller.isEmailExit ? false : true,
                              message: "Please enter your last name!",
                              whitespace: false,
                            },
                            {
                              pattern: new RegExp(/^(\S.*)[a-zA-Z-.']+$/),
                              message: "Please enter a valid last name!",
                            },
                          ],
                          onChange: () => this.disableSameAs(index),
                        })(
                          <Input
                            maxLength={50}
                            disabled={
                              (index === 0 && this.props.isLoggedIn) ||
                              traveller.isEmailExit
                                ? true
                                : false
                            }
                          />
                        )}
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                      {traveller.dob === "" ? (
                        <Form.Item
                          label={
                            index === 0
                              ? "Date of Birth (Must be 18+ to purchase)"
                              : "Date of Birth"
                          }
                        >
                          {getFieldDecorator("dob" + index, {
                            rules: [
                              {
                                type: "object",
                                required: traveller.isEmailExit ? false : true,
                                message: "Please select Date of Birth!",
                              },
                            ],
                            initialValue:
                              this.state.dob && this.state.dob[index]
                                ? this.state.dob[index]
                                : null,
                            onChange: (date) => {
                              this.disableSameAs(index);
                              this.setDOBState(date, index);
                            },
                          })(
                            <DatePicker
                              selected={
                                this.state.dob && this.state.dob[index]
                                  ? this.state.dob[index]
                                  : null
                              }
                              peekNextMonth
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              maxDate={
                                index > 0
                                  ? new Date()
                                  : this._subYears(new Date(), 18)
                              }
                              autoComplete="off"
                              style={{ margin: "0" }}
                            />
                          )}
                        </Form.Item>
                      ) : (
                        <Form.Item
                          label={
                            index === 0
                              ? "Date of Birth (Must be 18+ to purchase)"
                              : "Date of Birth"
                          }
                        >
                          {getFieldDecorator("dob" + index, {
                            initialValue:
                              traveller.dob !== ""
                                ? moment(traveller.dob)
                                : this.state.dob && this.state.dob[index]
                                ? this.state.dob[index]
                                : moment(new Date()),
                            rules: [
                              {
                                type: "object",
                                required: traveller.isEmailExit ? false : true,
                                message: "Please select Date of Birth!",
                              },
                            ],
                            onChange: (date) => {
                              this.disableSameAs(index);
                              this.setDOBState(date, index);
                            },
                          })(
                            <DatePicker
                              selected={
                                this.state.dob && this.state.dob[index]
                                  ? this.state.dob[index]
                                  : traveller.dob !== ""
                                  ? new Date(traveller.dob)
                                  : new Date()
                              }
                              disabled={
                                (index === 0 && this.props.isLoggedIn) ||
                                traveller.isEmailExit
                                  ? true
                                  : false
                              }
                              peekNextMonth
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              maxDate={
                                index > 0
                                  ? new Date()
                                  : this._subYears(new Date(), 18)
                              }
                              autoComplete="off"
                              style={{ margin: "0" }}
                            />
                          )}
                        </Form.Item>
                      )}
                    </Col>

                    <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                      <Form.Item label="E-mail">
                        {getFieldDecorator("email" + index, {
                          initialValue: traveller.email,
                          rules: [
                            {
                              type: "email",
                              message: "Please enter a valid Email!",
                            },
                            {
                              required: index === 0 ? true : false,
                              message: "Please enter your Email!",
                            },
                          ],
                          onChange: () => this.disableSameAs(index),
                        })(
                          <Input
                            id={"email" + index}
                            data-index={index}
                            onBlur={(e) => this._onBlur(e)}
                            disabled={
                              index === 0 &&
                              this.props.isLoggedIn &&
                              traveller.email != "" &&
                              traveller.email !== null
                                ? true
                                : false
                            }
                          />
                        )}
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                      <Form.Item label="Home Country">
                        {getFieldDecorator("country" + index, {
                          initialValue: traveller.country,
                          rules: [
                            {
                              required: index === 0 ? true : false,
                              message: "Please select your country!",
                            },
                          ],
                          onChange: () => this.disableSameAs(index),
                        })(
                          <Select
                            showSearch
                            placeholder="Please select a country"
                            style={{ height: "48px", marginBottom: "23px" }}
                            disabled={
                              index === 0 &&
                              this.props.isLoggedIn &&
                              traveller.country >
                                0 /*  ||
                              traveller.isEmailExit */
                                ? true
                                : false
                            }
                            filterOption={(input, option) =>
                              option.props.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {this._bindCountries()}
                          </Select>
                        )}
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                      <Form.Item label="Phone Number (Ex: +1xxxxxxxxxx)">
                        {getFieldDecorator("phone" + index, {
                          initialValue: traveller.mobile_number,
                          rules: [
                            {
                              required: index === 0 ? true : false,
                              message: "Please input your phone number!",
                            },
                            {
                              validator: this.handleContactExistance,
                            },
                          ],
                          onChange: () => this.disableSameAs(index),
                        })(
                          <Input
                            style={{ width: "100%" }}
                            type="text"
                            disabled={
                              index === 0 &&
                              this.props.isLoggedIn &&
                              traveller.mobile_number != "" &&
                              traveller.mobile_number !== null
                                ? true
                                : false
                            }
                          />
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={24}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <Form.Item>
                        {getFieldDecorator("check_company" + index, {
                          valuePropName: "checked",
                          initialValue: traveller.is_bussiness_travelling,
                        })(
                          <Checkbox
                            data-id={"company" + index}
                            id={"chk_company" + index}
                            onChange={this.onCheckCompanyChange}
                          >
                            <span style={{ color: "black" }}>
                              Are you traveling for business?
                            </span>
                          </Checkbox>
                        )}
                      </Form.Item>
                    </Col>
                    {traveller.is_bussiness_travelling ? (
                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Form.Item label="Company Name">
                          {getFieldDecorator("company_name" + index, {
                            initialValue: traveller.company_name,
                            rules: [
                              {
                                required: true,
                                message: "Please enter organization name!",
                                whitespace: false,
                              },
                              {
                                pattern: new RegExp(
                                  /^[A-Za-z0-9](?!.*?\s$)[A-Za-z0-9\s]{0,500}$/
                                ),
                                message: "Please enter organization name!",
                              },
                            ],
                          })(<Input />)}
                        </Form.Item>
                      </Col>
                    ) : (
                      ""
                    )}
                  </Row>
                  <Row gutter={24}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <h3>Emergency Contact</h3>
                    </Col>
                    {index > 0 ? (
                      <Col
                        xs={24}
                        sm={24}
                        md={12}
                        lg={12}
                        xl={12}
                        style={{ textAlign: "right" }}
                      >
                        <div style={{ float: "right" }}>
                          <input
                            type="checkbox"
                            onChange={() => this.setSameAsPrimary(index)}
                            checked={this.state["isSameAsPrimary_" + index]}
                            style={{
                              height: "20px",
                              float: "left",
                              width: "20px",
                              margin: "0 10px",
                              cursor: "pointer",
                            }}
                          />
                          Same as primary emergency contact
                        </div>
                      </Col>
                    ) : (
                      ""
                    )}
                  </Row>
                  <Row gutter={24}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <Form.Item label="First Name">
                        {getFieldDecorator("rel_first_name" + index, {
                          initialValue: traveller.rel_first_name,
                          rules: [
                            {
                              required: index === 0 ? true : false,
                              message:
                                "Please enter emergency contact’s first name!",
                              whitespace: false,
                            },
                            {
                              pattern: new RegExp(/^(\S.*)[a-zA-Z-.']+$/),
                              message: "Please enter a valid first name!",
                            },
                          ],
                        })(
                          <Input
                            maxLength={50}
                            disabled={
                              index >= 1 &&
                              /* traveller.isEmailExit && */ this.state[
                                "isSameAsPrimary_" + index
                              ]
                                ? true
                                : false
                            }
                          />
                        )}
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <Form.Item label="Last Name">
                        {getFieldDecorator("rel_last_name" + index, {
                          initialValue: traveller.rel_last_name,
                          rules: [
                            {
                              required: index === 0 ? true : false,
                              message:
                                "Please enter emergency contact’s last name!",
                              whitespace: false,
                            },
                            {
                              pattern: new RegExp(/^(\S.*)[a-zA-Z-.']+$/),
                              message: "Please enter a valid last name!",
                            },
                          ],
                        })(
                          <Input
                            maxLength={50}
                            disabled={
                              index >= 1 &&
                              this.state["isSameAsPrimary_" + index]
                                ? true
                                : false
                            }
                          />
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={24}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <Form.Item label="Relationship">
                        {getFieldDecorator("relationship" + index, {
                          initialValue: traveller.relationship,
                          rules: [
                            {
                              required: index === 0 ? true : false,
                              message: "Please enter relationship!",
                              whitespace: false,
                            },
                            {
                              pattern: new RegExp(/^(\S.*)[a-zA-Z-.']+$/),
                              message: "Please enter a valid relation name!",
                            },
                          ],
                        })(
                          <Input
                            disabled={
                              index >= 1 &&
                              this.state["isSameAsPrimary_" + index]
                                ? true
                                : false
                            }
                          />
                        )}
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <Form.Item label="Primary Phone (Ex: +1xxxxxxxxxx)">
                        {getFieldDecorator("rel_phone_number" + index, {
                          initialValue: traveller.rel_phone_number,
                          rules: [
                            {
                              required: index === 0 ? true : false,
                              message:
                                "Please enter emergency contact’s primary phone number!",
                              whitespace: false,
                            },
                            {
                              validator: this.handleContactExistance,
                            },
                          ],
                        })(
                          <Input
                            type="text"
                            disabled={
                              index >= 1 &&
                              this.state["isSameAsPrimary_" + index]
                                ? true
                                : false
                            }
                          />
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          ))}
          {/* add Traveller */}
          <div className="add-traveler">
            <div className="wrap">
              <Button onClick={this.appendTravellerData}>
                [+] Add a Traveler
              </Button>
            </div>
          </div>
          {/* add Traveller ends */}
          {/*  traveller alerts */}
          <div className="wrap">
            <div className="traveller-alerts">
              {/* title */}
              <div className="row">
                <div className="full-width">
                  <h3>Opt in to take advantage of SMS travel alerts.</h3>
                  <div className="toogle-button">
                    Yes
                    <label className="switch">
                      <input
                        type="checkbox"
                        onChange={this.handleSwitchChange}
                      />
                      <span className="slider round"></span>
                    </label>
                    No
                  </div>
                  <div className="clear-fix"></div>
                  <hr />
                </div>
              </div>
              {/* title ends */}
              {/* desc start */}
              <div className="row">
                <div className="full-width">
                  <p>
                    Receive real time late breaking travel alerts and emails to
                    warn you of any events that could affect your safety or
                    mobility at your destination. Travel Alerts were designed to
                    provide you with useful information to help you prepare for
                    or modify your travel plans and inform you of any events at
                    your destination that could impact your personal health or
                    safety. You can unsubscribe from travel alerts at any time.
                    (Recommended)
                  </p>
                   <p>
                  You agree to receive automated transactional messages. 
                  Terms and Privacy Policy can be found at <a href="https://www.captravelassistance.com/privacy-policy/">Privacy Policy - CAP Travel Assistance.</a>  
                  Txt and data rates may apply. Reply STOP to end or HELP for help.
                   </p>
                </div>
              </div>
              {/* desc ends */}
            </div>
          </div>
          {/* traveller alerts ends */}
          {/* next and back button*/}
          <div className="next-back">
            <div className="wrap">
              <Form.Item style={{ display: "inline-block" }}>
                <Button className="back" onClick={this.handleBackClik}>
                  Back
                </Button>
              </Form.Item>
              <Form.Item style={{ display: "inline-block" }}>
                <Button className="next" htmlType="submit">
                  Next
                </Button>
              </Form.Item>
            </div>
          </div>
          {/* next and back button*/}
        </Form>
      </div>
    );
  }
}

export const WrappedRegistrationForm = Form.create({ name: "traveller" })(
  RegistrationForm
);
