//import { Types } from "../constants/user-types";
import { Types as NotificaionTypes } from "../constants/notification-types";
import { notifyUser } from "../../services/notification-service";
import API from "../api/organization-api";
import { Types as OrgTypes } from "../constants/organization-types";

//organization types
export function getOrganizationTypes({ filters, pagination }) {
  return async function(dispatch, getState) {
    try {
      let response = await API.getOrganizationTypes({
        filters: filters,
        pagination: pagination
      });
      return response;
    } catch (e) {
      return { error: true };
    }
  };
}

export function updateOrganizationType(data) {
  return async function(dispatch, getState) {
    try {
      let resp = await API.updateOrganizationType(data);
      dispatch({
        type: NotificaionTypes.API_SUCCESS,
        payload: {
          code: 200,
          message: data.id > 0 ? "Item Updated" : "Item Added.",
          type: "primary",
          timeout: 3000,
          id: Math.floor(Math.random() * 10000)
        }
      });
    } catch (e) {
      return { error: true };
    }
  };
}

export function getOrganizationType(idx) {
  return async function(dispatch, getState) {
    try {
      let resp = await API.getOrganizationType(idx);
      return resp;
    } catch (e) {
      return { error: true };
    }
  };
}

export function deleteOrganizationType(idx) {
  return async function(dispatch, getState) {
    try {
      let resp = await API.deleteOrganizationType(idx);
      dispatch({
        type: NotificaionTypes.API_SUCCESS,
        payload: {
          code: 200,
          message: "Item Deleted.",
          type: "primary",
          timeout: 3000,
          id: Math.floor(Math.random() * 10000)
        }
      });
      setTimeout(() => {
        dispatch({ type: NotificaionTypes.CLEAR_API_SUCCESS, payload: {} });
      }, 5000);
      return resp;
    } catch (e) {
      return { error: true };
    }
  };
}

//organizations
export function getOrganizations({ filters, pagination }) {
  return async function(dispatch, getState) {
    try {
      let response = await API.getOrganizations({
        filters: filters,
        pagination: pagination
      });
      return response;
    } catch (e) {
      return { error: true };
    }
  };
}

export function updateOrganization(data) {
  return async function(dispatch, getState) {
    try {
      let resp = await API.updateOrganization(data);
      dispatch({
        type: NotificaionTypes.API_SUCCESS,
        payload: {
          code: 200,
          message: data.id > 0 ? "Item Updated" : "Item Added.",
          type: "primary",
          timeout: 3000,
          id: Math.floor(Math.random() * 10000)
        }
      });
      notifyUser(data.id > 0 ? "Item updated" : "Item added", "success");
    } catch (e) {
      return { error: true };
    }
  };
}

export function getOrganization(idx) {
  return async function(dispatch, getState) {
    try {
      let resp = await API.getOrganization(idx);
      return resp;
    } catch (e) {
      return { error: true };
    }
  };
}

export function deleteOrganization(idx) {
  return async function(dispatch, getState) {
    try {
      let resp = await API.deleteOrganization(idx);
      dispatch({
        type: NotificaionTypes.API_SUCCESS,
        payload: {
          code: 200,
          message: "Item Deleted.",
          type: "primary",
          timeout: 3000,
          id: Math.floor(Math.random() * 10000)
        }
      });
      setTimeout(() => {
        dispatch({ type: NotificaionTypes.CLEAR_API_SUCCESS, payload: {} });
      }, 5000);
      notifyUser("Item deleted.", "info");
      return resp;
    } catch (e) {
      return { error: true };
    }
  };
}
export function AddOrganizations(data) {
  return async function(dispatch, getAction) {
    try {
      let resp = await API.addOrganizations(data);
      return resp;
    } catch (error) {
      return { error: true, response:error };
    }
  };
}
export function getPccdata() {
  return async function(dispatch, getState) {
    try {
      let resp = await API.getPccList();
      return resp;
    } catch (e) {
      return { error: true };
    }
  };
}
export function getPccAccountData(data) {
  return async function(dispatch, getState) {
    try {
      let resp = await API.PCCAccountList(data);
      return resp;
    } catch (e) {
      return { error: true };
    }
  };
}
export function getOrganizationId(data) {
  return async function(dispatch, getState) {
    try {
      let resp = await API.getOrganizationById(data);
      return resp;
    } catch (e) {
      return { error: true };
    }
  };
}

export function getOrganizationLicenses({
  orgId,
  filters,
  pagination,
  sorter
}) {
  return async function(dispatch, getState) {
    try {
      let response = await API.getOrganizationLicenses({
        orgId: orgId,
        filters: filters,
        pagination: pagination,
        sorter: sorter
      });
      return response;
    } catch (e) {
      return { error: true };
    }
  };
}
export function updateOrganizationwithId(data, id) {
  return async function(dispatch, getState) {
    try {
      let resp = await API.updateOrganizationById(data, id);
      return resp;
    } catch (e) {
      return { error: true };
    }
  };
}
export function addorderinOrganizationwithid(data, id) {
  return async function(dispatch, getState) {
    try {
      let resp = await API.addorderinOrganization(data, id);
      return resp;
    } catch (e) {
      return { error: true };
    }
  };
}

export function getLicenseLogs({ licenseId, filters, pagination, sorter }) {
  return async function(_dispatch, _getState) {
    try {
      let response = await API.getLicenseLogs({
        licenseId: licenseId,
        filters: filters,
        pagination: pagination,
        sorter: sorter
      });
      return response;
    } catch (e) {
      return { error: true };
    }
  };
}

export function getLicenseCount(orgId) {
  return async function(_dispatch, _getState) {
    try {
      let response = await API.getLicenseCount(orgId);
      return response;
    } catch (e) {
      return { error: true };
    }
  };
}

export function removeLogo(orgId) {
  return async function(_dispatch, _getState) {
    try {
      let response = await API.removeLogo(orgId);
      return response;
    } catch (e) {
      return { error: true };
    }
  };
}

export function addDivision(data) {
  return async function(_dispatch, _getState) {
    try {
      let response = await API.addDivision(data);
      return response;
    } catch (e) {
      return { error: true };
    }
  };
}

export function getDivisions(data) {
  return async function(_dispatch, _getState) {
    try {
      let response = await API.getDivisions(data);
      return response;
    } catch (e) {
      return { error: true };
    }
  };
}

export function getDivision(divisionId) {
  return async function(_dispatch, _getState) {
    try {
      let response = await API.getDivision(divisionId);
      return response;
    } catch (e) {
      return { error: true };
    }
  };
}

export function getDivisionsList(data) {
  return async function(_dispatch, _getState) {
    try {
      let response = await API.getDivisionsTree(data);
      return response;
    } catch (e) {
      return { error: true };
    }
  };
}

export function getDivisionsTree(orgId) {
  return async function(dispatch, _getState) {
    try {
      let response = await API.getDivisionsTree(orgId);
      dispatch({type: OrgTypes.FETCH_ORGANIZATION_TREE, payload: {organizationTree: (response.data) ? response.data : [], organizationTreeParent: orgId}});
    } catch (e) {
      return { error: true };
    }
  };
}

export function updateDivision(divisionId, data) {
  return async function(_dispatch, _getState) {
    try {
      let response = await API.updateDivision(divisionId, data);
      return response;
    } catch (e) {
      return { error: true };
    }
  };
}
export function getOrganizationEmployee(data) {
  
  return async function(dispatch, getState) {
    try {
      let resp = await API.getOrganizationEmployees(data);
      return resp;
    } catch (e) {
      return {"error" : true};
    }
  };
}



export function cleanDivisionsTree() {
  return async function(dispatch, _getState) {
    dispatch({type: OrgTypes.CLEAN_ORGANIZATION_TREE});
  };
}

export function getBillableOrganizations(orgId) {
  return async function(_dispatch, _getState) {
    try {
      let response = await API.getBillableOrganizations(orgId);
      return response;
    } catch (e) {
      return { error: true };
    }
  };
}

export function getActiveThemes() {
  return async function(_dispatch, _getState) {
    try {
      let response = await API.getActiveThemes();
      return response;
    } catch (e) {
      return { error: true };
    }
  };
}

export function getOrgUsersList(id) {
  return async function(_dispatch, _getState) {
    try {
      return await API.getOrgUsersList(id);
    } catch (e) {
      return { error: true };
    }
  };
}

export function getOrganizationUsersList(id) {
  return async function(_dispatch, _getState) {
    try {
      return await API.getOrganizationUsersList(id);
    } catch (e) {
      return { error: true };
    }
  };
}


