import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Col, Row, Button, Result } from "antd";
import { connect } from "react-redux";
import IntlMessages from "../../../../services/intlMesseges";
class DivisonSucess extends Component {
  render() {
    let parentOrgId = this.props.match.params.id;
    let orgId = this.props.divisionId;
    return (
      <div>
        <Row gutter={24}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Result status="success" title={ <IntlMessages id="admin.division.added" />} />
          </Col>
        </Row>
        <div className="wrap" style={{ maxWidth: "650px" }}>
          <Row gutter={24}>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={24}
              style={{ marginTop: "20px" }}
            >
              <Button
                type="primary"
                onClick={() => this.props.history.push('./../add/')}
                style={{ width: "200px" }}
              >
                Add New Division
              </Button>
            </Col>
            {/* <Col
              xs={24}
              sm={24}
              md={8}
              lg={8}
              xl={8}
              style={{ marginTop: "20px" }}
              onClick={() => this.props.history.push('../../../edit/'+orgId+'/employees/new/', {oldPath: '../../../../'+parentOrgId+'/divisions/'})}
            >
              <Button style={{ width: "200px" }}>Add Travel Manager</Button>
            </Col> */}
            {/* <Col
              xs={24}
              sm={24}
              md={16}
              lg={16}
              xl={16}
              style={{ marginTop: "20px" }}
              onClick={() => this.props.history.push('../../../edit/'+orgId+'/employees/new/', {oldPath: '../../../../'+parentOrgId+'/divisions/'})}
            >
              <Button style={{ width: "200px" }}>Add Division Manager</Button>
            </Col> */}
          </Row>
        </div>
      </div>
    );
  }
}

export default withRouter(
  connect(null, null, null, { forwardRef: true })(DivisonSucess)
);
