import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { CardElement } from "react-stripe-elements";
import * as b2cActions from "../../../../redux/actions/b2c-actions";
import { message, Modal, Tooltip, Radio } from "antd";
import config from "../../../../../../config";
import Loader from "./../../shared/loader/loader";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import b2cApi from "../../../../redux/api/b2c-api";
import axios from "axios";
import Paypal from "../../../../images/paypal.svg";
import CreditCard from "../../../../images/creditcard.svg";

const { confirm } = Modal;
let resizeTimer;

class _CardForm extends Component {
  constructor(props) {
    super(props);
    this.howHeardRef = React.createRef();
    this.state = {
      productToOrder: config.wpProductToOrder,
      cardNumber: "",
      howHeard: "",
      couponCode: "",
      howHeardInput: "",
      howHeardInputLabel: "",
      showHowHeardInput: false,
      confirmOrder: false,
      appliedPromoText: "",
      promoResponseText: "",
      howheardError: "",
      orderObjectPaypal: {},
      cadDiscountedAmount: 0,
      currencyPaypal: this.props.currency,
      cadDiscountedPercent: 0,
      clientToken: config.clientTokenPaypal,
      btnIsDisable: false
    };
    this.handleClick = this.handleClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleHowHeardChange = this.handleHowHeardChange.bind(this);
    this.applyPromo = this.applyPromo.bind(this);
    this.handleHowHeardInputChange = this.handleHowHeardInputChange.bind(this);
    this.handleConfirmOrderCheck = this.handleConfirmOrderCheck.bind(this);
    this.showConfirm = this.showConfirm.bind(this);
    this.removePromo = this.removePromo.bind(this);
  }

  getUrlDecodedParams() {
    let localDecodedParams = localStorage.getItem("decodedParams");
    if (
      typeof localDecodedParams !== "undefined" &&
      localDecodedParams !== null &&
      localDecodedParams !== ""
    ) {
      return JSON.parse(localDecodedParams);
    }
    var encodedParameters = window.location.search; //this.props.location.search;
    var decodedParameterString = atob(encodedParameters.substring(1));
    var parameters = decodedParameterString.split("&");
    var parameterArray = new Map();
    parameters.forEach(async function(parameter) {
      var data = parameter.split("=");
      parameterArray[data[0]] = data[1];
    });

    var selectedGetCountries = parameterArray["countries"].split(",");
    var selectedGetCountriesStr = [];
    for (var i = 0; i < this.props.countryTierInfo.length; i++) {
      if (
        selectedGetCountries.indexOf(
          this.props.countryTierInfo[i].countryCode
        ) > -1
      ) {
        selectedGetCountriesStr.push(this.props.countryTierInfo[i].countryName);
      }
    }
    return {
      start_date: parameterArray["start_date"],
      end_date: parameterArray["end_date"],
      traveller_count: parseInt(parameterArray["traveller_count"]),
      countries: parameterArray["countries"],
      countries_list: selectedGetCountriesStr.join(", "),
      travel_type: parameterArray["travel_type"],
      travel_type_label: parameterArray["traveltypelabel"],
    };
  }

  handleConfirmOrderCheck(evt) {
    this.setState({ confirmOrder: evt.currentTarget.checked });
  }

  handleHowHeardInputChange(evt) {
    this.setState({ howHeardInput: evt.currentTarget.value });
  }

  async applyPromo(evt) {
    if (this.state.couponCode !== "") {
      await this.props.applyPromoCode(this.state.couponCode);
      if (this.props.isValidCoupon) {
        message.success("Promo code applied Successfully!");
        window.scrollTo(0, 0);
        var appliedPromoText =
          "Promo code " + this.props.couponInfo.code + " is applied!";
        this.setState({
          appliedPromoText: appliedPromoText,
          promoResponseText: "",
        });
      } else {
        this.setState({
          appliedPromoText: "",
          promoResponseText: "Invalid promo code!",
        });
        message.error("Promo code is not valid!");
      }
      // this.props.updatePrice();
      // update redux state
    }
    this.setState({ couponCode: "" });
  }

  async removePromo(evt) {
    await this.props.removePromoCode();
    // this.props.updatePrice();
    // update redux state
    this.setState({ couponCode: "", appliedPromoText: "" });
  }

  handleHowHeardChange(evt) {
    if (
      ["Referral", "Other"].includes(
        evt.currentTarget.selectedOptions[0].getAttribute("data-value")
      )
    ) {
      var labelText =
        evt.currentTarget.selectedOptions[0].getAttribute("data-value") ===
        "Referral"
          ? "Please specify name/source"
          : "Please specify";
      this.setState({ showHowHeardInput: true, howHeardInputLabel: labelText });
    } else {
      this.setState({ showHowHeardInput: false, howHeardInput: "" });
    }
    this.setState({
      howHeard: evt.currentTarget.selectedOptions[0].getAttribute("data-value"),
    });
    evt.currentTarget.blur();
    this.setState({ howheardError: "" });
  }

  mouseleaveHowHeardChange(evt) {
    evt.currentTarget.blur();
  }

  handleChange(evt) {
    if (evt.currentTarget.dataset) {
      var tempCouponCode = evt.currentTarget.value;
      this.setState({ couponCode: tempCouponCode });
    }
  }

  async componentDidMount() {
    window.addEventListener("resize", this.updateSelectDrop);
    if (
      this.props.personalDetails &&
      Object.keys(this.props.personalDetails).length === 0
    ) {
      await this.props.getPersonalInformation();
      await this.props.getPlanTermInformation();
      await this.props.getPlanInformation();
      await this.props.getBillingInformation();
    }
    await this.props.getAppliedPromo();
    await this.props.getProductPricingAndInfo();

    if (this.props.couponInfo && this.props.couponInfo.promoOfferId) {
      var appliedPromoText =
        "Promo code " + this.props.couponInfo.code + " is applied!";
      this.setState({
        appliedPromoText: appliedPromoText,
      });
    }
    window.scrollTo(0, 0);
    // this.props.updatePrice();
    if (
      this.props.billingInformation &&
      this.props.billingInformation.paymnet_token
    ) {
      this.setState({ confirmOrder: true });
    }

    localStorage.setItem("is_back", "yes");

    // console.log(this.props.payMethod);
  }

  updateSelectDrop = () => {
    let self = this;
    if (
      self.howHeardRef &&
      self.howHeardRef.current &&
      self.howHeardRef.current !== null
    ) {
      clearTimeout(resizeTimer);
      resizeTimer = setTimeout(function() {
        self.howHeardRef.current.blur();
      }, 10);
    }
  };

  async handleClick(evt) {
    this.setState({btnIsDisable: true})
    if (evt.currentTarget.dataset && evt.currentTarget.dataset.value) {
      if (evt.currentTarget.dataset.value === "back") {
        await this.props.SaveStep("3");
        var decodedParameterString = atob(window.location.search.substring(1));
        decodedParameterString += "&reload_cap_order=true";
        this.props.history.push(
          this.props.b2cBaseUrl + "/order?" + btoa(decodedParameterString)
        );
      } else {
        if (
          this.state.howHeard &&
          this.state.howHeard !== "" &&
          this.state.howHeard !== "Select an option"
        ) {
          this.setState({ howheardError: "" });
          if (this.state.confirmOrder) {
            this.checkForvalidPayment();
          } else {
            var objContent = {};
            objContent.title = "Acknowledge required!";
            objContent.content =
              "Please acknowledge plan rules and regulations first.";
            this.showConfirm(objContent);
            this.setState({btnIsDisable: false})
          }
        } else {
          this.setState({btnIsDisable: false})
          this.setState({ howheardError: "Please select an option" });
        }
      }
    }
  }

  clickPaypal = () => {
    if (
      this.state.howHeard == "" ||
      this.state.howHeard == "Select an option"
    ) {
      this.setState({ howheardError: "Please select an option" });
      this.setState({btnIsDisable: false})
      return false;
    } else {
      this.setState({ howheardError: "" });
    }

    if (!this.state.confirmOrder) {
      var objContent = {};
      objContent.title = "Acknowledge required!";
      objContent.content =
        "Please acknowledge plan rules and regulations first.";
      this.showConfirm(objContent);
      this.setState({btnIsDisable: false})
      return false;
    } else {
      this.saveDataAndNext("pass");
    }
  };

  showConfirm(objContent, index) {
    confirm({
      title: objContent.title,
      content: objContent.content,
      className: "gray-color",
      onOk() {},
      onCancel() {},
    });
  }

  async checkForvalidPayment() {
    var self = this;

    if (this.props.paymentMethod == "paypal") {
      await this.instance.requestPaymentMethod((error, payload) => {
        if (error) {
          console.error(error);
          this.setState({btnIsDisable: false})
        } else {
          const paymentMethodNonce = payload.nonce;
        }
      });
    } else {
      let res = this.props.stripe.createToken();
      res
        .then(function(payload) {
          if (payload.token) {
            self.saveDataAndNext(payload.token.id);
          } else {
            var objContent = {};
            objContent.title = "Invalid card detail!";
            objContent.content = "Please enter valid card details.";
            self.showConfirm(objContent);
          }
        })
        .then((error) => {
          console.log(error);
          this.setState({btnIsDisable: false})
        });
    }
  }

  /* async saveDataAndNext(token) {
    let billingInfoObject = {
      coupon_code: this.state.couponCode,
      how_heard: this.state.howHeard,
      how_heard_other: (this.state.howHeardInput) ? this.state.howHeardInput : null,
      paymnet_token: token
    };
    await this.props.SaveStep("4");
    await this.props.SaveBillingInfornation(billingInfoObject);
    this.props.history.push(this.props.b2cBaseUrl + "/purchase-summary");

  } */

  async saveDataAndNext(token) {
    let billingInfoObject = {
      coupon_code: this.state.couponCode,
      how_heard: this.state.howHeard,
      how_heard_other: this.state.howHeardInput
        ? this.state.howHeardInput
        : null,
      paymnet_token: token,
    };

    if (this.props.paymentMethod == "stripe") {
      this.setState({ showLoader: true });
    } else {
      this.setState({ showLoader: false });
    }

    var orderObject = {};
    var userinfo = [];
    var travellerArray = this.props.personalDetails;
    var countryArray;
    var productId = 0;
    await this.props.getAppliedPromo();
    var discountedPrice = 0;
    var totalPrice = 0;

    if (this.props.couponInfo && this.props.couponInfo.promoOfferId) {
      if (this.props.couponInfo.discountType === "P") {
        discountedPrice =
          (this.props.planTerm.totalPrice *
            this.props.couponInfo.discountFigure) /
          100;
        this.setState({
          cadDiscountedPercent: this.props.couponInfo.discountFigure,
        });
      } else {
        discountedPrice = this.props.couponInfo.discountFigure;
        this.setState({
          cadDiscountedAmount: discountedPrice ? discountedPrice : 0,
        });
      }
    }
    totalPrice =
      discountedPrice > 0
        ? parseInt(this.props.planTerm.totalPrice) - discountedPrice
        : parseInt(this.props.planTerm.totalPrice);
    // if (this.props.planTerm.isAnnual) {
    //   var objCountry = this.props.countryTierInfo.find(
    //     (x) => x.countryCode === "All"
    //   );
    //   countryArray = [objCountry.countryId];
    //   let productCount = this.props.productInfo.length;
    //   //productId = this.props.productInfo[productCount - 1].productId;
    //   productId = this.state.productToOrder;
    // } else {
      productId = this.state.productToOrder;
      countryArray = this.props.planTerm.country.split(",");
    // }

    for (var i = 0; i < travellerArray.length; i++) {
      var travellerObject = {};
      if (this.props.userData && this.props.userData.email && i === 0) {
        travellerObject.userId = this.props.userData.userID;
        travellerObject.Email = this.props.userData.email;
        travellerObject.ProductId = this.props.planTerm.isAnnual
          ? productId
          : travellerArray[i].product_id;
        travellerObject.State = travellerArray[i].state
          ? travellerArray[i].state
          : 0;
        travellerObject.IsPrimary = true;
        /* travellerObject.EmergencyFirstName=travellerArray[i].rel_first_name;
        travellerObject.EmergencyLastName= travellerArray[i].rel_last_name;
        travellerObject.EmergencyRelationship= travellerArray[i].relationship;
        travellerObject.EmergencyContactNo= travellerArray[i].rel_phone_number; */
      } else if (travellerArray[i].isEmailExit) {
        travellerObject.Email = travellerArray[i].email;
        travellerObject.IsPrimary = i == 0 ? true : false;
        travellerObject.ProductId = this.props.planTerm.isAnnual
          ? productId
          : travellerArray[i].product_id;
      } else {
        travellerObject.FirstName = travellerArray[i].first_name;
        travellerObject.LastName = travellerArray[i].last_name;
        travellerObject.Gender = travellerArray[i].gender;
        travellerObject.Email = travellerArray[i].email;
        travellerObject.ContactNo = travellerArray[i].mobile_number;
        travellerObject.Country = travellerArray[i].country;
        travellerObject.State = travellerArray[i].state
          ? travellerArray[i].state
          : 0;
        travellerObject.IsBusinessTravelling = travellerArray[i]
          .is_bussiness_travelling
          ? travellerArray[i].is_bussiness_travelling
          : false;
        travellerObject.DOB =
          travellerArray[i].dob === "Invalid date"
            ? new Date()
            : new Date(travellerArray[i].dob).toDateString();
        /* travellerObject.EmergencyFirstName=travellerArray[i].rel_first_name;
        travellerObject.EmergencyLastName= travellerArray[i].rel_last_name;
        travellerObject.EmergencyRelationship= travellerArray[i].relationship;
        travellerObject.EmergencyContactNo= travellerArray[i].rel_phone_number; */
        travellerObject.CompanyName = travellerArray[i].company_name;
        travellerObject.ProductId = this.props.planTerm.isAnnual
          ? productId
          : travellerArray[i].product_id;
        if (i === 0) {
          travellerObject.HowDidYouHear =
            billingInfoObject.how_heard === "Referral" ||
            billingInfoObject.how_heard === "Other"
              ? billingInfoObject.how_heard +
                " - " +
                billingInfoObject.how_heard_other
              : billingInfoObject.how_heard;
          travellerObject.IsPrimary = true;
        } else {
          travellerObject.IsPrimary = false;
        }
      }
      userinfo.push(travellerObject);
    }
    orderObject.userinfo = userinfo;
    orderObject.IsAnnual = this.props.planTerm.isAnnual;
    orderObject.tierId = this.props.planTerm.tierValue;
    orderObject.overrideTierID = 0;
    orderObject.Countries = countryArray;
    orderObject.CoverageStartDate = this.props.planTerm.date;
    orderObject.Days = this.props.planTerm.isAnnual
      ? 365
      : this.props.planTerm.days;
    orderObject.Quantity = this.props.personalDetails.length;
    orderObject.ReferrerCode = this.state.howHeard ? this.state.howHeard : "";
    orderObject.ReferrerCodeInput = this.state.howHeardInput
      ? this.state.howHeardInput
      : "";
    orderObject.TotalOrderAmount = totalPrice >= 0 ? totalPrice.toFixed(2) : 0;
    orderObject.discount = discountedPrice > 0 ? discountedPrice : null;
    orderObject.Token = billingInfoObject.paymnet_token;
    orderObject.CouponId =
      this.props.couponInfo && this.props.couponInfo.promoOfferId
        ? this.props.couponInfo.promoOfferId
        : null;
    orderObject.IsReceiveTravelAlerts = this.props.personalDetails[0].receiver_alert;
    let decodedParams = this.getUrlDecodedParams();
    var selectedGetState = undefined;
    if (decodedParams["us_travel_states"]) {
      selectedGetState = decodedParams["us_travel_states"]
        .split(",")
        .map((item) => item.trim());
    } else if (decodedParams["travel_us_states"]) {
      selectedGetState = decodedParams["travel_us_states"]
        .split(",")
        .map((item) => item.trim());
    }
    var selectedGetStateStr = [];
    var selectedstateriskzone = "";
    for (var i = 0; i < this.props.stateTierInfo.length; i++) {
      if (
        selectedGetState &&
        selectedGetState.indexOf(this.props.stateTierInfo[i].stateName) > -1
      ) {
        selectedstateriskzone = this.props.stateTierInfo[i].stateRiskZones[0]
          .stateRiskTypeId;
        selectedGetStateStr.push(this.props.stateTierInfo[i].stateId);
      }
    }

    orderObject.travelType = decodedParams.travel_type_label;
    orderObject.states = selectedGetStateStr;
    orderObject.stateRiskZone =
      selectedstateriskzone !== "" ? selectedstateriskzone : 0;
    orderObject.Currency = this.props.currency;
    orderObject.ConversionTotalAmount =
      totalPrice >= 0
        ? this.props.currency === "CAD"
          ? (this.props.planTerm && this.props.planTerm.cadPrice).toFixed(2)
          : totalPrice.toFixed(2)
        : 0;
    if (token == "pass") {
      this.setState({ showLoader: false });
      this.setState({ orderObjectPaypal: orderObject });
    } else {
      await this.props.placeOrder(orderObject);

      if (this.props.orderData && this.props.orderData.orderNumber) {
        await this.props.SaveStep("5");
        this.props.history.push(this.props.b2cBaseUrl + "/thank-you/");
      } else {
        if (
          this.props.orderErrorData &&
          this.props.orderErrorData.status &&
          this.props.orderErrorData.status !== 200 &&
          this.props.orderErrorData.message
        ) {
          alert("Error processing order: " + this.props.orderErrorData.message);
          this.props.history.goBack();
        } else {
          alert("An error occured while processing the order.");
          this.props.history.goBack();
        }
        this.setState({ showLoader: false });
      }
    }
  }

  _bindHowHeard() {
    var def = ["Select an option"];
    var howHeardOptions = def.concat(this.props.howHeardOptions);

    return howHeardOptions.map((option, index) => (
      <option key={index} data-value={option}>
        {option}
      </option>
    ));
  }

  payMethodChange = (e) => {
    this.props.onPaymentMethodChange(e.target.value);
    this.setState({ paymentMethod: e.target.value });
  };
  onPromoEnter = (e) => {
    e.target.value = e.target.value.toUpperCase();
  };
  render() {
    return (
      <div>
        {this.state.showLoader ? <Loader /> : ""}
        <div className="wrap">
          <div className="billing-info">
            {/* title  */}
            <div className="row">
              <div className="full-width">
                <h3>Billing Information</h3>
              </div>
              <div className="full-width pay-methods">
                <Radio.Group
                  onChange={this.payMethodChange}
                  value={this.props.paymentMethod}
                >
                  <Radio value={"stripe"}>
                    <img style={{ width: "40px" }} src={CreditCard} />
                    <span style={{ fontWeight: 600, marginLeft: "10px" }}>
                      {" "}
                      Pay Via Card{" "}
                    </span>
                  </Radio>
                  <Radio value={"paypal"}>
                    <img style={{ width: "100px" }} src={Paypal} alt="Paypal" />
                  </Radio>
                </Radio.Group>
              </div>
            </div>
            {/* title ends */}
            {/* credit card */}

            {this.props.paymentMethod == "stripe" && (
              <div className="row">
                <div className="full-width">
                  <label>
                    <span>*</span> Credit or debit card (Visa, MasterCard, American Express, JCB, Discover, and Diners
                    Club)
                  </label>
                  <CardElement hidePostalCode={true} />
                  <span className="disclaimer">
                    Your credit card data will be securely transmitted and processed. FocusPoint complies with PCI-DSS
                    SAQ A.
                  </span>
                </div>
              </div>
            )}
            {/* credit card ends */}

            {/* coupon code hear about us */}
            <div className="row" style={{ marginTop: "25px" }}>
              <div className="half-width">
                <label>Promo Code</label>
                <input
                  type="text"
                  className="coupon-input"
                  style={{ padding: "0 10px" }}
                  data-category="coupon"
                  value={this.state.couponCode}
                  onChange={this.handleChange}
                  onInput={this.onPromoEnter}
                />
                <button className="coupon-but" onClick={this.applyPromo}>
                  Apply
                </button>
                {this.state.appliedPromoText !== "" ? (
                  <span
                    style={{
                      borderBottom: "1px solid gray",
                      paddingBottom: "5px"
                    }}
                  >
                    {this.state.appliedPromoText}{" "}
                    <a onClick={this.removePromo}>
                      <Tooltip title="Remove Promo Code">
                        <i className="fas fa-times"></i>
                      </Tooltip>
                    </a>
                  </span>
                ) : (
                  ""
                )}
                {this.state.promoResponseText !== "" ? (
                  <span
                    style={{
                      paddingBottom: "5px",
                      color: this.state.promoResponseText.indexOf("Invalid") > -1 ? "#cb0019" : "green"
                    }}
                  >
                    {this.state.promoResponseText}
                  </span>
                ) : (
                  ""
                )}
              </div>
              <div className="half-width">
                <label>
                  <span>* </span>How did you hear about us?
                </label>
                <div className="select">
                  <div className="select-style">
                    <select ref={this.howHeardRef} onChange={this.handleHowHeardChange}>
                      {this._bindHowHeard()}
                    </select>
                  </div>
                  <div className={this.state.showHowHeardInput ? "show" : "hide"}>
                    <label>{this.state.howHeardInputLabel}</label>
                    <input
                      type="text"
                      style={{ padding: "0 10px" }}
                      value={this.state.howHeardInput}
                      onChange={this.handleHowHeardInputChange}
                      maxLength={200}
                    />
                  </div>
                </div>
                <span style={{ color: "red" }}>{this.state.howheardError}</span>
              </div>
            </div>
            {/* coupon code ends hear about us ends */}

            {/*  Terms and */}
            <div className="row">
              <div className="full-width">
                <div className="checkbox-in">
                  <label className="container">
                    By submitting, you acknowledge that you have read and agree to the terms and conditions of the&nbsp;
                    <a
                      target="_blank"
                      href={config.API1 + "/rules/CAP-eCommerce-Rules-and-Regulations-v15.1.pdf"}
                      style={{ textDecoration: "underline" }}
                    >
                      CAP <br />
                      Plan Rules & Regulations
                    </a>
                    <span className="red">*</span>
                    <input type="checkbox" checked={this.state.confirmOrder} onChange={this.handleConfirmOrderCheck} />
                    <span className="checkmark"></span>
                  </label>
                </div>
              </div>
            </div>
            {/* Terms and */}
          </div>
        </div>
        {/* billing information ends */}
        {/* next and back button */}
        <div className="next-back">
          <div className="wrap">
            <div className="row">
              <div className="upgrade">
                {/* upgrade plans */}
                {/* <UpgradePlan /> */}
                {/* upgrade plans ends */}
              </div>
              <div className={`next-box ${this.props.paymentMethod == "paypal" ? "paypalbtn" : ""}`}>
                <button data-value="back" className="back" onClick={this.handleClick}>
                  Back
                </button>

                {this.props.paymentMethod == "paypal" ? (
                  <PayPalScriptProvider
                    options={{
                      "client-id": this.state.clientToken,
                      currency: this.props.currency,
                      "disable-funding": "paylater"
                    }}
                  >
                    <PayPalButtons
                      onClick={this.clickPaypal}
                      style={{ layout: "horizontal" }}
                      createOrder={(data, actions) => {
                        // move api call to api component
                        return axios
                          .post(config.API1 + "/api/Paypal/CreateOrder", this.state.orderObjectPaypal)
                          .then((response) => {
                            return response.data.orderId;
                          });

                        // let oid =   b2cApi.createOrderPaypal(this.state.orderObjectPaypal)
                        //  return oid;
                      }}
                      onApprove={(data, actions) => {
                        return axios
                          .post(config.API1 + `/api/Paypal/${data.orderID}/CaptureOrder`, this.state.orderObjectPaypal)
                          .then((response) => {
                            let resp = response.data;
                            if (resp.data.status && resp.data.status == 200) {
                              this.props.placeOrderPaypal(resp);
                              this.props.SaveStep("5");
                              this.props.history.push(this.props.b2cBaseUrl + "/thank-you/");
                            } else {
                              alert("An error occured while processing the order.");
                              this.props.history.goBack();
                            }
                          });
                      }}
                    />
                  </PayPalScriptProvider>
                ) : (
                  <button
                    data-value="next"
                    className="next"
                    style={{ background: "#489e3c" }}
                    onClick={this.handleClick}
                    disabled={this.state.btnIsDisable}
                  >
                    Buy Now
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

_CardForm.propTypes = {
  SavePlanTerm: PropTypes.func,
  applyPromoCode: PropTypes.func,
  getPlanInformation: PropTypes.func,
  getPlanTermInformation: PropTypes.func,
  getPersonalInformation: PropTypes.func,
  SaveStep: PropTypes.func,
  SaveBillingInfornation: PropTypes.func,
  step: PropTypes.string,
  howHeardOptions: PropTypes.array,
  planTerm: PropTypes.object,
  isValidCoupon: PropTypes.bool,
  couponInfo: PropTypes.object,
  removePromoCode: PropTypes.func,
  getBillingInformation: PropTypes.func,
  b2cBaseUrl: PropTypes.string,
};
function mapStateToProps(state) {
  return {
    ...state.b2c,
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...b2cActions }, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(_CardForm);
