import API from "../api/day-packages-api";

export function getDayPackageListing({ filters, pagination, sorter }) {
  return async function (dispatch, getState) {
    try {
      let resp = await API.getDayPackagesList({
        filters: filters,
        pagination: pagination,
        sorter: sorter
      });
      return resp;
    } catch (e) {
      return { "error": true };
    }
  };
}

export function getDayPackages(idx) {
  return async function (dispatch, getState) {
    try {
      let resp = await API.getDayPackagesById(idx);
      return resp;
      
    } catch (e) {
      return { "error": true };
    }
  };
}
export function updateDayPackagesData(data,id) {
  return async function(dispatch, getState) {
    try {
      let resp;
      if(id > 0){
        resp = await API.updateDayPackages(data,id)
      } else {
        resp = await API.addDayPackages(data,id)

      }
      return resp;
    } catch (e) {
      return {"error" : true};
    }
  };
}