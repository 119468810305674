import Axios from "../../services/axios-service";

const getPricing = data => {
  return Axios.get("/api/Price?search="+JSON.stringify(data), undefined, undefined);
};

const updatePricing = data => {
  return Axios.put("/api/Price/"+data.pricingListId, data, undefined);
};

const addPricing = data => {
  return Axios.post("/api/Price", data, undefined);
};

const getPrice = idx => {
  return Axios.get("/api/Price/"+idx, undefined, undefined);
};

export default {
  getPricing,
  updatePricing,
  addPricing,
  getPrice
};
