import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import IntlMessages from "../../../../services/intlMesseges";
import {
  Table,
  Row,
  Col,
  Typography,
  Button,
  Input,
  Icon,
  Tooltip
} from "antd";
const data = [
  {
    key: "1",
    arrivaltime: "12:30 pm",
    carrier: "Britsh Airways",
    flightnumber: "BA1234",
    from: "Canada",
    name: "John Brown"
  },
  {
    key: "1",
    arrivaltime: "12:30 pm",
    carrier: "Britsh Airways",
    flightnumber: "BA1234",
    from: "Canada",
    name: "John Brown"
  },
  {
    key: "1",
    arrivaltime: "12:30 pm",
    carrier: "Britsh Airways",
    flightnumber: "BA1234",
    from: "Canada",
    name: "John Brown"
  },
  {
    key: "1",
    arrivaltime: "12:30 pm",
    carrier: "Britsh Airways",
    flightnumber: "BA1234",
    from: "Canada",
    name: "John Brown"
  },
  {
    key: "1",
    arrivaltime: "12:30 pm",
    carrier: "Britsh Airways",
    flightnumber: "BA1234",
    from: "Canada",
    name: "John Brown"
  }
];
// rowSelection object indicates the need for row selection
const rowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    console.log(
      `selectedRowKeys: ${selectedRowKeys}`,
      "selectedRows: ",
      selectedRows
    );
  },
  getCheckboxProps: record => ({
    disabled: record.name === "Disabled User", // Column configuration not to be checked
    name: record.name
  })
};
class ArrivalResult extends Component {
  state = {
    searchText: "",
    searchedColumn: ""
  };

  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    }
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex
    });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: "" });
  };
  render() {
    const columns = [
      {
        title: "Arrival Time",
        dataIndex: "arrivaltime",
        ...this.getColumnSearchProps("arrivaltime")
      },
      {
        title: "Carrier",
        dataIndex: "carrier",
        ...this.getColumnSearchProps("carrier")
      },
      {
        title: "Flight Number",
        dataIndex: "flightnumber",
        ...this.getColumnSearchProps("flightnumber")
      },
      {
        title: "From",
        dataIndex: "from",
        ...this.getColumnSearchProps("from")
      },
      {
        title: "Name",
        dataIndex: "name",
        ...this.getColumnSearchProps("name")
      },
      {
        title: "Action",
        dataIndex: "",
        key: "x",
        render: () => (
          <button className="icon">
            <Tooltip title="View">
              <i className="fas fa-eye"></i>
            </Tooltip>
          </button>
        )
      }
    ];
    return (
      <div>
        <Row gutter={24}>
          <Col xs={24} sm={24} md={8} lg={12} xl={12}>
            <Typography.Title level={4}>
              <IntlMessages id="admin.sidebar.submenu.ArrivalReport" />
            </Typography.Title>
          </Col>
          <Col xs={24} sm={24} md={16} lg={12} xl={12} className="align-right">
            <ul>
              <li>
                {" "}
                <Button>
                  <i
                    style={{ display: "inline-block", marginRight: "5px" }}
                    className="fas fa-file-csv"
                  ></i>
                  <span>CSV Download</span>
                </Button>
              </li>
              <li>
                <Button>
                  <i
                    style={{ display: "inline-block", marginRight: "5px" }}
                    className="fas fa-file-csv"
                  ></i>
                  <span>Excel Download</span>
                </Button>
              </li>
              <li>
                <Button type="primary">
                  <i
                    style={{ display: "inline-block", marginRight: "5px" }}
                    className="fas fa-print"
                  ></i>
                  <span>Print</span>
                </Button>
              </li>
              <li>
                <Button
                  type="primary"
                  htmlType="button"
                  onClick={() => this.props.history.push("./")}
                >
                  <IntlMessages id="admin.userlisting.back" />
                </Button>
              </li>
            </ul>
          </Col>
        </Row>
        <hr />
        <Row gutter={24}>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={24}
            style={{ overflowX: "auto" }}
          >
            <Table
              bordered
              rowSelection={rowSelection}
              columns={columns}
              dataSource={data}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

export default withRouter(
  connect(null, null, null, { forwardRef: true })(ArrivalResult)
);