import { Types } from "../constants/organization-types";
const initialState = {
    organizationTree: [],
    organizationTreeParent: 0
};
export default function organizationReducer(state = initialState, action) {
  switch (action.type) {
    case Types.FETCH_ORGANIZATION_TREE:
      return {
        ...state,
        organizationTree: action.payload.organizationTree,
        organizationTreeParent: action.payload.organizationTreeParent
      };
    case Types.CLEAN_ORGANIZATION_TREE:
      return {
        ...state,
        organizationTree: 0,
        organizationTreeParent: []
      };
    default:
      return state;
  }
}
