import Axios from "../../services/axios-service";


const getTiersList = data => {
  return Axios.get("/api/Tiers?search=" + encodeURIComponent(JSON.stringify(data)), undefined, undefined);
};
const getTiersbyId = data => {
  return Axios.get("/api/Tiers/" + data, undefined, undefined);
};
const updateTiers = (data, id) => {
  return Axios.put("/api/Tiers/" + id, data, undefined);
};
const addTiers = (data, id) => {
  return Axios.post("/api/Tiers/", data, undefined);
};
export default {
  getTiersList,
  getTiersbyId,
  updateTiers,
  addTiers
};

