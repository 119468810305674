import React, { Component } from "react";
import { Row, Col, Typography, Spin } from "antd";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as AdminActions from "../../../redux/actions/admin-actions";
import * as ProductActions from "../../../redux/actions/product-actions";
import IntlMessages from "../../../services/intlMesseges";
import moment from "moment";
import { DatePicker } from "antd";
const { RangePicker } = DatePicker;
const dateFormat = "MM-DD-YYYY";
const queryDateFormatStart = "YYYY-MM-DD 00:00:00";
const queryDateFormatEnd = "YYYY-MM-DD 23:59:59";

//moment.tz.setDefault();
let now = new Date();
let start = moment(new Date(now.getFullYear(), now.getMonth(), 1, 0, 0, 0, 0));
let end = moment(
  new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59, 0)
);

class UserHome extends Component {
  state = {
    productsSaleData: [],
    salesData: {
      data: [],
      total: 0
    },
    incomeData: {
      data: [],
      total: 0
    },
    profitData: {
      data: [],
      total: 0
    },
    notificationsData: {
      emails: 0,
      sms: 0,
      pushNotifications: 0,
      notifications: 0
    },
    isDPEnabled: true,
    dataPickerDates: [start, end],
    visible: false,
    statsLoading: true
  };
  componentDidMount = () => {
    //this.props.clearPaginationExceptMe('dashboard');
    this.loadProductSalesData();
  };
  loadProductSalesData = async () => {
    let _this = this;
    let dateRanges = _this.state.dataPickerDates;

    let args = {
      startDate: dateRanges[0].format(queryDateFormatStart),
      endDate: dateRanges[1].format(queryDateFormatEnd)
    };

    /* total sales */
    this.props.getResellerTotalOrders(args).then(response => {
      let _salesData = this.state.salesData;
      _salesData.data = response.data.map(function(el) {
        return { Date: el.orderDate, "Total Sale": el.totalOrder };
      });
      _salesData.total = response.data.reduce(function(tot, el) {
        return tot + el.totalOrder;
      }, 0);
      this.setState({
        salesData: _salesData
      });
    });

    
    this.props.getResellerTotalProfit(args).then(response => {
      let _profitData = this.state.profitData;
      _profitData.data = response.data.map(function(el) {
        return { Date: el.orderDate, "Total Profit": el.totalCommission };
      });
      _profitData.total = response.data
        .reduce(function(tot, el) {
          return tot + el.totalCommission;
        }, 0)
        .toFixed(2);
      this.setState({
        profitData: _profitData
      });
    });

    /* sales by product */
    let _productsSaleData = /* _this.state.productsSaleData ||  */[];
    _this.props.getProducts({}).then(response1 => {
      response1.data &&
        response1.data.map(function(el1) {
          if(el1.name.toLowerCase().indexOf("cap") === -1){
            let data = Object.assign({}, args);
            data.productId = el1.productId;
            _this.props.getResellerTotalSale(data).then(response2 => {
              let _salesData = {};
              _salesData.data =
                response2.data &&
                response2.data.map(function(el3) {
                  return { Date: el3.orderDate, "Total Sale": el3.totalProducts };
                });
              _salesData.total =
                response2.data &&
                response2.data.reduce(function(tot, el4) {
                  return tot + el4.totalProducts;
                }, 0);
              _salesData.name = el1.name;
              _salesData.productId = el1.productId;
              _productsSaleData[el1.productId] = _salesData;
              _this.setState({
                productsSaleData: _productsSaleData
              });
            });
          }
        });
    });
    setTimeout(function() {
      _this.setState({ statsLoading: false });
    }, 1000);

    /* notifications count */
    this.props.getDashboardNotificationsData(args).then(response => {
      let _notificationsData = {
        emails: 0,
        pushNotifications: 0,
        sms: 0,
        notifications: 0
      };
      response.data.map(function(el){
        _notificationsData.emails += el.userNotificationsEmailCount;
        _notificationsData.pushNotifications += el.userNotificationsPushCount;
        _notificationsData.sms += el.userNotificationsSmsCount;
        _notificationsData.notifications += el.userNotificationsCount;
      });
      this.setState({
        notificationsData: _notificationsData
      });
    });
  };

  dateChanged = dates => {
    if(dates.length > 0){
      this.setState(
        {
          statsLoading: true,
          dataPickerDates: dates
        },
        () => this.loadProductSalesData()
      );
    } else {
      this.setState(
        {
          statsLoading: true,
          dataPickerDates: [start, end]
        },
        () => this.loadProductSalesData()
      );
    }
  };

  showDrawer = () => {
    this.setState({
      visible: true
    });
  };

  onClose = () => {
    this.setState({
      visible: false
    });
  };
  render() {
    let _this = this;
    return (
      <div className="agent-home">
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...ProductActions, ...AdminActions }, dispatch);
}
export default withRouter(
  connect(null, mapDispatchToProps, null, { forwardRef: true })(UserHome)
);
