import React, { createRef } from 'react';
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import IntlMessages from "../../services/intlMesseges";
import logo from "../../images/logo.png";
import * as AdminActions from "../../redux/actions/admin-actions";
import * as b2cActions from "../../redux/actions/b2c-actions";
import {notifyUser} from "../../services/notification-service";
import {
  Form,
  Input,
  Select,
  Button,
  Row,
  Col,
  Modal,
  Icon
} from "antd";
import config from "../../../../config";
import StripeProvider from "../plan-upgrade/stripe-payment/StripeProvider";
import OrgAPI from "../../redux/api/b2b2c-organization-api";

const { Option } = Select;
var validator = require("email-validator");
class ManagePlanUpgrade extends React.Component {
  constructor(props) {
    super(props);
    this.showBillingRef = createRef();
    this.state = {
        showModal: false,
        inputValue: '',
        submitStatus: null,
        showBilling : false,
    };
    
  }

  async componentDidMount() {
   
  }
  
  handleChildData = (data) => {
    this.props.form.setFieldsValue({
      email: '',
    });
    this.setState({ showBilling: false });
  }
  
  handleInputChange = (e) => {
    this.setState({ inputValue: e.target.value });
  };
  
  handleSubmit = async (e) => {
    const { form } = this.props;
    e.preventDefault();
    form.validateFields(async (err, values) => {
     if (!err) {
    const  payload = {
        "email":values.email,
      }
      try {
        const resp = await OrgAPI.B2BEmployeeUpgrade(payload);
        if (resp.status === true) {
          this.setState({ submitStatus: 'Success' });
          this.setState({ showBilling: true }, () => {
            this.scrollToShowBilling();
          });
        } else {
          this.handleShowModal();
          this.setState({ submitStatus: 'Error' });
        }
      } catch (error) {
        if (error.response && error.response.status === 500) {
          notifyUser(error.response.data.Message, "error");
        } else {
          console.log("An error occurred:", error.message);
        }
      }
    }
    
    });
  };

  scrollToShowBilling = () => {
    if (this.showBillingRef.current) {
      this.showBillingRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };
  
  handleShowModal = () => {
    this.setState({ showModal: true });
  };

  handleHideModal = () => {
    this.setState({ showModal: false, });
  };

  handleConfirmAction = () => {
    window.location.href = 'https://captravelassistance.com/';
    this.setState({ showModal: false });
  };
  
  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div>
        <header id="headerb2b2c">
        <div className="headerb2b2c-inner">
          <div className="wrap">
            <section className="top-header">
              <div className="row">
               <div className={ "logo col-md-12 col-md-12  text-center"}>
                          <img
                            src={logo}
                            alt="CAP™"
                            height = "90"
                            width="118"
                          />
                      </div>
                  </div>
            </section>
          </div>
        </div>
      </header>
      <section id ="banner">
       <Row className="purchaseform">
       <Col xs={24} sm={24} md={24} lg={24} xl={24}> 
             <div
                  className="wrap900 price-sticky-head"
                >
                <div className="title">
                  <h2>Upgrade Plan</h2>
                  <h1> <span>Price: {config.planUpgradePrice}</span></h1>
                </div>
                  <Form onSubmit={this.handleSubmit}>
              
                  <div className="container">
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Form.Item label={<IntlMessages id='admin.billingemail.email' />}>
                      {getFieldDecorator('email', {
                        rules: [
                          {
                              type: 'email',
                              message: <IntlMessages id='admin.email.valid' />
                          },
                          {
                              whitespace: true,
                              required: true,
                              message: <IntlMessages id='admin.input.required' />
                          }
                        ],
                        
                      })(<Input type="text"
                      prefix={<Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />} // Use the MailOutlined icon
                      value={this.state.inputValue}
                      placeholder={"Enter Your Email"} // Add a placeholder message
                      onChange={this.handleInputChange} />)}
                    </Form.Item>
                    <Form.Item style={{ display: "inline-block" }}>
                    <Button type="primary" className="submit" htmlType="submit">
                      Submit
                    </Button>
                  </Form.Item>
                  
              </Col>
              </div>
             
           </Form> 
               </div> 
           </Col> 
           </Row> 
        </section>
               {this.state.showBilling == true? 
                  <section ref={this.showBillingRef}>
                        <div className="purchase-summary" style={{ marginBottom: "100px" }}>
                        <StripeProvider sendDataToParent={this.handleChildData} showBilling={this.state.showBilling}emaildata={this.state.inputValue} apiKey={config.stripeKey}>
                      </StripeProvider>
                      </div>
                  </section>
                : "" }
                 <Modal
                        visible={this.state.showModal}
                        onCancel={this.handleHideModal}
                        onOk={this.handleConfirmAction}
                        okText='ok'
                        cancelText='Cancel'
                        className='modalStyle'
                      >
                        <p>
                        You don't seems to have an active CAP Plan, Do you wish to proceed with buying new CAP Plan?
                        </p>
                 </Modal>
      </div>
    
    );
  }
        
}

        


ManagePlanUpgrade.propTypes = {

};

function mapStateToProps(state) {
    return {
      ...state.b2b2c,
      ...state.user,
    };
  }
  function mapDispatchToProps(dispatch) {
    return bindActionCreators({ ...AdminActions, ...b2cActions }, dispatch);
  }



const WrappedForm = Form.create({  })(
    ManagePlanUpgrade
);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    WrappedForm
  )
);
