import React, { Component } from "react";
import PrimaryAddress from "../add-organization/primary-address";
import OrganizationSucess from "../add-organization/organization-sucess";
import OrganizationPlan from "../add-organization/organization-plan";
import TravelManager from "../add-organization/travel-manager";
import IntlMessages from "../../../../services/intlMesseges"
import { Steps } from "antd";
import moment from "moment";

const { Step } = Steps;
const dateFormat = "MM/DD/YYYY";

export default class AddOrganization extends Component {
  constructor(props) {
    super(props);

    this.state = {
      current: 0,
      //first form
      company_name: "",
      address: "",
      city: "",
      country: null,
      zip: "",
      phoneNumber: "",
      email: "",
      accountmanager: null,
      firstName: "",
      lastName: "",
      salesexecutive: null,
      company_code: "",
      company_state: "",

      sec_address: "",
      sec_city: "",
      sec_country: "",
      sec_zip: "",
      sec_phoneNumber: "",
      sec_email: "",

      billing_address: "",
      billing_city: "",
      billing_country: "",
      billing_zip: "",
      billing_phoneNumber: "",
      billing_email: "",

      countries: [],
      membership_type: [],
      price: null,
      startDate: null,
      endDate: null,
      org_country: [],
      membership_number: "",
      licence_number: null,
      postSalePrice: 0,
      pcc_number: [],
      account_number: [],
      dealprice: "",
      primary_id: "",
      primary_first: "",
      primary_last: "",
      primary_dateofbirth: null,
      primary_email: "",
      primary_primaryPhoneNumber: "",
      primary_country: null,
      emergency_first: "",
      emergency_lastname: "",
      emergency_relationship: "",
      emergency_primary_phone_number: "",
      secondary_contactPerson: "",
      billing_contactPerson: "",
      sec_state: "",
      billing_state: "",
      pccaccountnumber: [],
      checkTraveller: false,
      pccaccountno: [],
      isBusinessAddressSame: false,
      isCommunicationAddressSame: false,
      creditDays: null,
      customer_id: "",
      job_number: "",
      billing_company_name: "",
      targetKeys: [],
      tarvellerexits: false,
      emergencyEmail: "",
      membershipNumber: null,
      organizationId: null
    };
    this.handleChange = this.handleChange.bind(this);
  }
  componentDidMount() {}
  getSteps = () => {
    return [
      {
        title: <IntlMessages id="admin.organization.address" />,
        content: (
          <PrimaryAddress
            {...this.state}
            data={this.state}
            onchange={this.handleChange}
          />
        )
      },
      {
        title: <IntlMessages id="admin.organization.plan" />,
        content: (
          <OrganizationPlan
            {...this.state}
            data={this.state}
            onchange={this.handleChange}
          />
        )
      },
      {
        title: <IntlMessages id="admin.organization.travelmanager" />,
        content: (
          <TravelManager
            {...this.state}
            data={this.state}
            onchange={this.handleChange}
          />
        )
      },
      {
        title: <IntlMessages id="admin.organization.success" />,
        content: (
          <OrganizationSucess
            {...this.state}
            data={this.state}
            onchange={this.handleChange}
          />
        )
      }
    ];
  };
  getSteps2 = () => {
    return [
      {
        title: <IntlMessages id="admin.organization.address" /> ,
        content: (
          <PrimaryAddress
            {...this.state}
            data={this.state}
            onchange={this.handleChange}
          />
        )
      },
      {
        title: <IntlMessages id="admin.organization.plan" /> ,
        content: (
          <OrganizationPlan
            {...this.state}
            data={this.state}
            onchange={this.handleChange}
          />
        )
      },

      {
        title: <IntlMessages id="admin.organization.success" />,
        content: (
          <OrganizationSucess
            {...this.state}
            data={this.state}
            onchange={this.handleChange}
          />
        )
      }
    ];
  };
  async handleChange(e, value) {
    if (e === "current") {
      this.setState({ current: value });
    }
    if (value === undefined) {
      if (e.target.id === "primary-address_company_name") {
        this.setState({ company_name: e.target.value });
      } else if (e.target.id === "primary-address_address") {
        this.setState({
          address: e.target.value,
          isCommunicationAddressSame: false,
          isBusinessAddressSame: false
        });
      } else if (e.target.id === "primary-address_city") {
        this.setState({
          city: e.target.value,
          isCommunicationAddressSame: false,
          isBusinessAddressSame: false
        });
      } else if (e.target.id === "primary-address_zip") {
        this.setState({
          zip: e.target.value,
          isCommunicationAddressSame: false,
          isBusinessAddressSame: false
        });
      } else if (e.target.id === "primary-address_phoneNumber") {
        this.setState({
          phoneNumber: e.target.value,
          isCommunicationAddressSame: false,
          isBusinessAddressSame: false
        });
      } else if (e.target.id === "primary-address_email") {
        this.setState({
          email: e.target.value,
          isCommunicationAddressSame: false,
          isBusinessAddressSame: false
        });
      } else if (e.target.id === "primary-address_sec_address") {
        this.setState({ sec_address: e.target.value });
      } else if (e.target.id === "primary-address_sec_city") {
        this.setState({ sec_city: e.target.value });
      } else if (e.target.id === "primary-address_sec_zip") {
        this.setState({ sec_zip: e.target.value });
      } else if (e.target.id === "primary-address_sec_phoneNumber") {
        this.setState({ sec_phoneNumber: e.target.value });
      } else if (e.target.id === "primary-address_sec_email") {
        this.setState({ sec_email: e.target.value });
      } else if (e.target.id === "primary-address_billing_address") {
        this.setState({ billing_address: e.target.value });
      } else if (e.target.id === "primary-address_billing_city") {
        this.setState({ billing_city: e.target.value });
      } else if (e.target.id === "primary-address_billing_zip") {
        this.setState({ billing_zip: e.target.value });
      } else if (e.target.id === "primary-address_billing_phoneNumber") {
        this.setState({ billing_phoneNumber: e.target.value });
      } else if (e.target.id === "primary-address_billing_email") {
        this.setState({
          billing_email: e.target.value,
          isCommunicationAddressSame: false,
          isBusinessAddressSame: false
        });
      } else if (e.target.id === "primary-address_firstName") {
        this.setState({
          firstName: e.target.value,
          isCommunicationAddressSame: false,
          isBusinessAddressSame: false
        });
      } else if (e.target.id === "primary-address_lastName") {
        this.setState({
          lastName: e.target.value,
          isCommunicationAddressSame: false,
          isBusinessAddressSame: false
        });
      } else if (e.target.id === "organization-plans_price") {
        this.setState({ price: e.target.value });
      } else if (e.target.id === "organization-plans_membership_number") {
        this.setState({ membership_number: e.target.value });
      } else if (e.target.id === "travel-manager_primary_id") {
        this.setState({ primary_id: e.target.value });
      } else if (e.target.id === "travel-manager_primary_first") {
        this.setState({ primary_first: e.target.value });
      } else if (e.target.id === "travel-manager_primary_last") {
        this.setState({ primary_last: e.target.value });
      } else if (e.target.id === "travel-manager_primary_email") {
        this.setState({ primary_email: e.target.value });
      } else if (e.target.id === "travel-manager_primary_primaryPhoneNumber") {
        this.setState({ primary_primaryPhoneNumber: e.target.value });
      } else if (e.target.id === "travel-manager_emergency_first") {
        this.setState({ emergency_first: e.target.value });
      } else if (e.target.id === "travel-manager_emergency_lastname") {
        this.setState({ emergency_lastname: e.target.value });
      } else if (e.target.id === "travel-manager_emergency_relationship") {
        this.setState({ emergency_relationship: e.target.value });
      } else if (
        e.target.id === "travel-manager_emergency_primary_phone_number"
      ) {
        this.setState({ emergency_primary_phone_number: e.target.value });
      } else if (e.target.id === "organization-plans_licence_number") {
        this.setState({ licence_number: e.target.value });
      } else if (e.target.id === "organization-plans_postSalePrice") {
        this.setState({ postSalePrice: e.target.value });
      } else if (e.target.id === "organization-plans_creditDays") {
        this.setState({ creditDays: e.target.value });
      } else if (e.target.id === "primary-address_secondary_contactPerson") {
        this.setState({ secondary_contactPerson: e.target.value });
      } else if (e.target.id === "primary-address_billing_contactPerson") {
        this.setState({ billing_contactPerson: e.target.value });
      } else if (e.target.id === "organization-plans_dealprice") {
        this.setState({ dealprice: e.target.value });
      } else if (e.target.id === "primary-address_company_code") {
        this.setState({ company_code: e.target.value });
      } else if (e.target.id === "primary-address_company_state") {
        this.setState({
          company_state: e.target.value,
          isCommunicationAddressSame: false,
          isBusinessAddressSame: false
        });
      } else if (e.target.id === "primary-address_sec_state") {
        this.setState({ sec_state: e.target.value });
      } else if (e.target.id === "primary-address_billing_state") {
        this.setState({ billing_state: e.target.value });
      } else if (e.target.id === "primary-address_customer_id") {
        this.setState({ customer_id: e.target.value });
      } else if (e.target.id === "primary-address_job_number") {
        this.setState({ job_number: e.target.value });
      } else if (e.target.id === "primary-address_billing_company_name") {
        this.setState({ billing_company_name: e.target.value });
      } else if (e.target.id === "travel-manager_emergencyEmail") {
        this.setState({ emergencyEmail: e.target.value });
      } else if (e.target.id === "secAddress" && e.target.checked === true) {
        this.setState({ sec_address: this.state.address });
        this.setState({ sec_city: this.state.city });
        this.setState({ sec_country: this.state.country });
        this.setState({ sec_zip: this.state.zip });
        this.setState({ sec_phoneNumber: this.state.phoneNumber });
        this.setState({ sec_email: this.state.email });
        this.setState({
          secondary_contactPerson: this.state.firstName.concat(
            " ",
            this.state.lastName
          )
        });
        this.setState({ sec_state: this.state.company_state });
        this.setState({ isCommunicationAddressSame: e.target.checked });
      } else if (e.target.id === "secAddress" && e.target.checked === false) {
        this.setState({ sec_address: "" });
        this.setState({ sec_city: "" });
        this.setState({ sec_country: "" });
        this.setState({ sec_zip: "" });
        this.setState({ sec_phoneNumber: "" });
        this.setState({ sec_email: "" });
        this.setState({ secondary_contactPerson: "" });
        this.setState({ sec_state: "" });
        this.setState({ isCommunicationAddressSame: e.target.checked });
        // billing address
      } else if (e.target.id === "billAddress" && e.target.checked === true) {
        this.setState({ billing_address: this.state.address });
        this.setState({ billing_city: this.state.city });
        this.setState({ billing_country: this.state.country });
        this.setState({ billing_zip: this.state.zip });
        this.setState({ billing_phoneNumber: this.state.phoneNumber });
        this.setState({ billing_email: this.state.email });
        this.setState({
          billing_contactPerson: this.state.firstName.concat(
            " ",
            this.state.lastName
          )
        });
        this.setState({ billing_state: this.state.company_state });
        this.setState({ isBusinessAddressSame: e.target.checked });
        this.setState({ billing_company_name: this.state.company_name });
      } else if (e.target.id === "billAddress" && e.target.checked === false) {
        this.setState({ billing_address: "" });
        this.setState({ billing_city: "" });
        this.setState({ billing_country: "" });
        this.setState({ billing_zip: "" });
        this.setState({ billing_phoneNumber: "" });
        this.setState({ billing_email: "" });
        this.setState({ billing_contactPerson: "" });
        this.setState({ billing_state: "" });
        this.setState({ isBusinessAddressSame: e.target.checked });
        this.setState({ billing_company_name: "" });
      }
    }

    // countryorg_country
    //checkbox
    //secondary address
    else {
      if (value === "pccaccountnumber") {
        this.setState({ pccaccountnumber: e });
      } else if (value === "account_number") {
        this.setState({ account_number: e });
      } else if (value === "pcc_number") {
        this.setState({ pcc_number: e });
      } else if (value === "membership_type") {
        this.setState({ membership_type: e });
      } else if (value === "countries") {
        this.setState({ countries: e });
      } else if (value === "primary_country") {
        this.setState({ primary_country: e });
      } else if (value === "startDate" && e !== null) {
        var dates = new Date(moment(e._d).format(dateFormat));
        dates.setFullYear(dates.getFullYear() + 1);
        dates.setDate(dates.getDate() - 1);
        this.setState({ startDate: moment(e._d).format(dateFormat) });
        this.setState({ endDate: moment(dates).format(dateFormat) });
      } else if (value === "primary_dateofbirth" && e !== null) {
        this.setState({ primary_dateofbirth: moment(e._d).format(dateFormat) });
      } else if (value === "country") {
        this.setState({
          country: e,
          isCommunicationAddressSame: false,
          isBusinessAddressSame: false
        });
      } else if (value === "sec_country") {
        this.setState({ sec_country: e });
      } else if (value === "accountmanager") {
        this.setState({ accountmanager: e });
      } else if (value === "salesexecutive") {
        this.setState({ salesexecutive: e });
      } else if (value === "org_country") {
        this.setState({ org_country: e });
      } else if (value === "salesexecutive") {
        this.setState({ salesexecutive: e });
      } else if (value === "billing_country") {
        this.setState({ billing_country: e });
      } else if (value === "checkTraveller") {
        this.setState({ checkTraveller: e });
      } else if (value === "pccaccountno") {
        this.setState({ pccaccountno: e });
      } else if (e === "membershipNumber") {
        this.setState({ membershipNumber: value });
      } else if (e === "organizationId") {
        this.setState({ organizationId: value });
      } else if (value === "targetKeys") {
        this.setState({ targetKeys: e });
      } else if (value === "tarvellerexits") {
        this.setState({ tarvellerexits: e });
      }
    }
  }
  next() {
    const current = this.state.current + 1;
    this.setState({ current: current });
  }

  prev() {
    const current = this.state.current - 1;
    this.setState({ current });
  }
  render() {
    const { current } = this.state;
    const steps = this.getSteps();
    const steps2 = this.getSteps2();

    return (
      <div>
        {this.state.tarvellerexits ? (
          <Steps current={current}>
            {steps.map(item => (
              <Step key={item.title} title={item.title} />
            ))}
          </Steps>
        ) : (
          <Steps current={current}>
            {steps2.map(item => (
              <Step key={item.title} title={item.title} />
            ))}
          </Steps>
        )}
        <div className="steps-content">{steps[current].content}</div>
        <div className="steps-action"></div>
      </div>
    );
  }
}
