import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from "react";
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import store from "../src/shared/app/redux/store.js";
import App from "../src/shared/app/App.jsx";
if ("serviceWorker" in navigator) {
  window.addEventListener('load', function() {
    navigator.serviceWorker
      .register(window.location.origin+'/firebase-messaging-sw.js')
      .then(function(registration) {
        /* console.log("Registration successful, scope is:", registration.scope); */
      })
      .catch(function(err) {
        console.log("Service worker registration failed, ERR:", err);
      });
  });
}
ReactDOM.render(
  <Provider store={store}>
    <Router>
      <App />
    </Router>
  </Provider>,

  document.getElementById("root")
);
