import React, { Component } from "react";

import { withRouter } from "react-router-dom";

class ErrorBoundary extends React.Component {
    constructor(props) {
      super(props);
      this.state = { hasError: false };
    }
  
    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
      }
    componentDidCatch(error, info) {
      // Display fallback UI
      console.log(error)
      console.log(info)
    //  this.setState({ hasError: true });
      // You can also log the error to an error reporting service
      //logErrorToMyService(error, info);
     
      window.location = "/500";
    }
  
    render() {
      if (this.state.hasError) {
        // You can render any custom fallback UI
       // return <h1>Something went wrong.</h1>;
      }
      return this.props.children;
    }
  }

  export default withRouter(ErrorBoundary);