import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import IntlMessages from "../../../../services/intlMesseges";
import * as Permission from "../../../../services/permissions";
import AppLocale from "../../../../languageProvider";
import moment from "moment";
import {
  Typography,
  Form,
  Input,
  DatePicker,
  Row,
  Col,
  Select,
  Table,
  Button,
  Icon,
  Transfer,
  Modal,
  Spin,
  TreeSelect,
  Checkbox,
  message,
} from "antd";
import * as B2B2COrgActions from "../../../../redux/actions/b2b2c-organization-actions";
import * as OrgPlanTypeActions from "../../../../redux/actions/org-plan-types";
import * as PricingActions from "../../../../redux/actions/pricing-actions";
import * as productActions from "../../../../redux/actions/product-actions";
import * as AdminActions from "../../../../redux/actions/admin-actions";
import { notifyUser } from "../../../../services/notification-service";
import DerivedPricingQuickView from "../../pricing/view/derived-quickview";

const { Option } = Select;
const dateFormat = "MM/DD/YYYY";
var finalData = [];
class EditB2B2COrganizationPlan extends Component {
  constructor(props) {
    super(props);

    this.addnewProduct = this.addnewProduct.bind(this);
    this.saveproduct = this.saveproduct.bind(this);
    this.showModal = this.showModal.bind(this);
    this.onPlanChange = this.onPlanChange.bind(this);
    this.backpage = this.backpage.bind(this);

    this.state = {
      orgData: [],
      planTypes: [],
      retailPrices: [],
      rawPrices: [],
      selectedRawPriceList: null,
      selectedPriceList: null,
      guardMePlanType: [],
      planType: {
        indeterminate: false,
        checkAll: false,
        selectedPlanTypes: [],
        planTypesList: [],
      },
      selectedKeys: [],
      productData: [],
      pccData: [],
      pccaccountno: "",
      b2B2CPriceName: "",
      pccaccountnumber: [],
      targetKeys: [],
      orgTypes: [],
      dataAccount: [],
      defaultDataAccount: [],
      useDefaultDataAccount: false,
      loading: true,
      selectedGuardmePlan: [],
      sixMonthPlanNo: false,
      twelveMonthPlanNo: false,
      twelveMonthWithoutAss: false,
      formLayoutaddPlan: undefined,
      productInfo: {},

      pagination: {
        pageSize: 1000,
        current: 1,
        hideOnSinglePage: true,
        showTotal: (total, range) => {
          return (
            <span>
              <IntlMessages id="admin.text.showing" /> {range[0]}-{range[1]}{" "}
              <IntlMessages id="admin.text.of" /> {total}{" "}
              <IntlMessages id="admin.text.results" />
            </span>
          );
        },
      },
    };
    // this.handleGuardPlans = this.handleGuardPlans.bind(this);
    //this.onChangeGuardMePlan =  this.onChangeGuardMePlan.bind(this);
  }

  async componentDidMount() {
    let _retailPrices = this.state.retailPrices;
    let _rawPrices = this.state.rawPrices;
    let _planType = this.state.planType;
    let _orgData = this.state.orgData;
    let _planTypes = this.state.orgData.planTypeId;

    const pager = { ...this.state.pagination };
    pager.current = this.state.pagination.current;
    await this.props
      .getPricing({
        filters: {},
        pagination: {
          page: this.state.pagination.current,
          pageSize: this.state.pagination.pageSize,
        },
        sorter: {},
      })
      .then((resp) => {
        if (resp.data.length > 0) {
          let __retailPrices = [];
          let __rawPrices = [];
          resp.data.map((item) => {
            if (item.calculationFor == "RetailPrice" && item.priceType === 1) {
              __retailPrices.push(item);
            }
            if (item.calculationFor == "RawPrice" && item.priceType === 1) {
              __rawPrices.push(item);
            }
          });
          _retailPrices = __retailPrices;
          _rawPrices = __rawPrices;
        }
        pager.total = resp.paging.totalRecords;
        this.setState({
          loading: false,
          pagination: pager,
        });
      })
      .catch((ex) => {});

    if (
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.id &&
      this.props.match.params.id !== "new"
    ) {
      this.setState({ loading: true });
      this.props
        .getOrganizationId(this.props.match.params.id)
        .then((resp) => {
          let orgAccounts = [];
          for (var m = 0; m < resp.data.orgPCCAccounts.length; m++) {
            orgAccounts.push({
              account: resp.data.orgPCCAccounts[m].pccAccountNumber,
              key: "" + resp.data.orgPCCAccounts[m].pccAccountID,
            });
          }
          this.setState({ dataAccount: orgAccounts });
          this.setState({ dataAccountCopy: orgAccounts });
          this.setState({ dataAccountCopyDraft: resp.data.orgPCCAccounts });

          var datasb = [];

          for (
            var a = 0;
            a < resp.data.orgPCCAccounts.map((info) => info.pccid).length;
            a++
          ) {
            if (
              datasb.includes(
                resp.data.orgPCCAccounts.map((info) => info.pccid)[a]
              )
            ) {
            } else {
              datasb.push(
                resp.data.orgPCCAccounts.map((info) => info.pccid)[a]
              );
              this.setState({ pccids: datasb });
              this.onchangePcc(datasb);
            }
          }
          this.setState(resp.data);
          this.props
            .getProducts({})
            .then((respprod) => {
              let values = respprod.data;

              finalData = resp.data.productInfo.map((it1) => {
                it1.name = respprod.data.find(
                  (it2) => it2.productId === it1.productID
                ).name;
                return it1;
              });
              this.setState({
                loading: false,
                productData: values,
                productInfo: resp.data.productInfo,
                endDate: moment(new Date(resp.data.coverageEndDate)).format(
                  dateFormat
                ),
              });
              this.handleChangeTransfer(
                resp.data.orgPCCAccounts.map((data) => "" + data.pccAccountID)
              );
            })
            .catch((ex) => {
              this.setState({ loading: false });
            });
        })
        .catch((ex) => {
          this.setState({ loading: false });
        });
    }

    await this.props
      .getPlanTypes({
        filters: {},
        pagination: {},
        sorter: {},
      })
      .then((resp) => {
        if (resp.data && resp.data.length > 0) {
          _planType.planTypesList = resp.data;
        }
      });

    await this.props.getGuardMeProducts().then((resp) => {
      let guraditem = resp.map((item) => {
        return { label: item.name, value: item.productId };
      });
      this.setState({ guardMePlanType: guraditem });
    });

    let sgplans = [];
    if (this.props.orgData && this.props.orgData.productInfo.length > 0) {
      let selectedGuardmeplans = this.props.orgData.productInfo.map((plan) => {
        sgplans.push(plan.productID);
      });
      this.setState({ selectedGuardmePlan: sgplans });
    }

    if (this.props.orgData && this.props.orgData.organizationId) {
      _orgData = this.props.orgData;

      if (_orgData.orgPlans && _orgData.orgPlans.length > 0) {
        let plantypearr = _orgData.planTypeId
          ? _orgData.planTypeId.split(",")
          : 0;
        _planType.selectedPlanTypes = _orgData.planTypeId
          ? plantypearr.map((str) => Number(str))
          : [0]; // _orgData.orgPlans.map(i => parseInt(i.planType));
        if (
          _planType.selectedPlanTypes.length === _planType.planTypesList.length
        ) {
          _planType.indeterminate = false;
          _planType.checkAll = true;
        } else {
          _planType.indeterminate =
            !!_planType.selectedPlanTypes.length &&
            _planType.selectedPlanTypes.length < _planType.planTypesList.length;
        }
        this.props.form.setFieldsValue({
          orgPlans: _planType.selectedPlanTypes,
        });
      }

      if (_orgData.b2B2CPriceId > 0) {
        await this.props.getPrice(_orgData.b2B2CPriceId).then((res) => {
          if (res.pricingInfo) {
            this.setState({ selectedPriceList: res.pricingInfo });
          } else {
            this.setState({ selectedPriceList: null });
          }
        });
      }
      if (_orgData.rawPriceId > 0) {
        await this.props.getPrice(_orgData.rawPriceId).then((res) => {
          if (res.pricingInfo) {
            this.setState({ selectedRawPriceList: res.pricingInfo });
          } else {
            this.setState({ selectedRawPriceList: null });
          }
        });
      }
      if (_orgData.orgPlans && _orgData.orgPlans.length > 0) {
        _orgData.orgPlans.map((plan) => {
          _orgData.rawPriceId = _orgData.rawPriceId;
        });
      }

      let orgAccounts = [];
      for (var m = 0; m < _orgData.orgPCCAccounts.length; m++) {
        orgAccounts.push({
          account: _orgData.orgPCCAccounts[m].pccAccountNumber,
          key: "" + _orgData.orgPCCAccounts[m].pccAccountID,
        });
      }
      this.setState({
        dataAccount: orgAccounts,
        defaultDataAccount: orgAccounts,
        useDefaultDataAccount: orgAccounts.length > 0 ? true : false,
        dataAccountCopyDraft: _orgData.orgPCCAccounts,
      });

      var datasb = [];

      for (
        var a = 0;
        a < _orgData.orgPCCAccounts.map((info) => info.pccid).length;
        a++
      ) {
        if (
          datasb.includes(_orgData.orgPCCAccounts.map((info) => info.pccid)[a])
        ) {
        } else {
          datasb.push(_orgData.orgPCCAccounts.map((info) => info.pccid)[a]);
          this.setState({ pccaccountnumber: datasb });
        }
      }
      if (datasb.length > 0) {
        this.onchangePcc(datasb);
      }
    }

    this.props
      .getProducts({})
      .then((resp) => {
        let data = resp.data;
        this.setState({
          productData: data,
        });
      })
      .catch((ex) => {});
    this.getBillableOrg(this.props.match.params.id);

    this.props
      .getPccdata({})
      .then((resp) => {
        let data = resp.data;
        this.setState({
          pccData: data,
        });
      })
      .catch((ex) => {});
    this.setState({
      orgData: _orgData,
      retailPrices: _retailPrices,
      rawPrices: _rawPrices,
      planType: _planType,
      loading: false,
    });

    await this.props
      .getResellerOrganizationTypes({
        filters: {},
        pagination: { page: 1 },
        sorter: {},
      })
      .then((re) => {
        if (re.data && re.data.length > 0) {
          this.setState({ orgTypes: re.data });
        }
      });
  }

  handleChangeTransfer = (targetKeys) => {
    this.setState({ targetKeys: targetKeys });
    this.setState({ targetKeys });
    this.setState({ isEmpty: true });
    var data = [];
    if (targetKeys.length === 0) {
      this.setState({ pccaccountno: [] });
    } else {
      for (var i = 0; i < targetKeys.length; i++) {
        data.push({ pccAccountID: +targetKeys[i] });
      }
      this.setState({ pccaccountno: data });
    }
  };
  handleSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
    this.setState({
      selectedKeys: [...sourceSelectedKeys, ...targetSelectedKeys],
    });
  };
  handleSearch = (dir, value) => {};

  handleSubmit() {
    const { language } = this.props;
    const currentAppLocale = AppLocale[language.locale];

    this.props.form.validateFieldsAndScroll((err, values) => {
      if (values.orgPlans == false) {
        message.error("Plans Types Not Selected!");
        return false;
      }
      if (!err) {
        this.setState({ loading: true });
        let args = this.state.orgData;
        let plans = [];
        this.state.planType.selectedPlanTypes.map((plan) => {
          plans.push({ planTypeId: values.rawPriceId, planType: plan });
          //  plans.push({planTypeId : values.b2B2CPriceId, planType: plan});
        });

        args.orgPlans = plans;
        args.rawPriceId = values.rawPriceId;
        args.planTypeId = this.state.planType.selectedPlanTypes.toString();
        args.b2B2CPriceId = values.b2B2CPriceId;
        args.retailPriceId = values.b2B2CPriceId;
        args.commission = values.commission;
        args.creditDaysLimit = values.creditDays;
        args.orgPCCAccounts =
          this.state.pccaccountno.length > 0 ? this.state.pccaccountno : [];
        if (this.state.b2B2CPriceName !== "") {
          args.b2B2CPriceName = this.state.b2B2CPriceName;
        }
        this.props
          .updateOrganizationwithId(args, parseInt(this.props.match.params.id))
          .then(async (response) => {
            if (response.error) {
              notifyUser(response.error.message, "error");
              this.setState({ loading: false });
            } else {
              const { language } = this.props;
              const currentAppLocale = AppLocale[language.locale];
              this.props.updateStateOnChange();
              notifyUser(
                currentAppLocale.messages["admin.organization.edit"],
                "success"
              );
              // this.props.history.push(
              //   "/admin/b2b2c-organizations/edit/" + this.props.match.params.id
              // );

              this.setState({ loading: false });
            }
          })
          .catch((err) => {
            this.setState({ loading: false });
          });
      }
    });
  }

  onChangePlan2 = async (pricingId) => {
    await this.props.getPrice(pricingId).then((res) => {
      if (res.pricingInfo) {
        this.setState({ selectedRawPriceList: null }, () =>
          this.setState({
            selectedRawPriceList: res.pricingInfo,
            b2B2CPriceName: res.name,
          })
        );
      } else {
        this.setState({ selectedRawPriceList: null });
      }
    });
  };

  onChangePlan = async (pricingId) => {
    await this.props.getPrice(pricingId).then((res) => {
      if (res.pricingInfo) {
        this.setState({ selectedPriceList: null }, () =>
          this.setState({
            selectedPriceList: res.pricingInfo,
            b2B2CPriceName: res.name,
          })
        );
      } else {
        this.setState({ selectedPriceList: null });
      }
    });
  };

  onChangePlanType = (checkedList) => {
    let _planType = this.state.planType;
    console.log(_planType);
    _planType.selectedPlanTypes = checkedList;
    _planType.indeterminate =
      !!checkedList.length &&
      checkedList.length < _planType.planTypesList.length;
    _planType.checkAll = checkedList.length === _planType.planTypesList.length;
    this.setState({ planType: _planType });
    console.log(this.state.planType);
  };

  onCheckAllChange = (e) => {
    let _planType = this.state.planType;
    _planType.indeterminate = false;
    _planType.checkAll = e.target.checked;
    _planType.selectedPlanTypes = e.target.checked
      ? _planType.planTypesList.map((l) => l.autoId)
      : [];
    this.setState({ planType: _planType });
  };

  /* async onchangePcc(e) {
    let dataAccount = [];
    var value = [];
    var self = this;
    var a = [];

    e.length > 0 && e.map(async(row, idx) => {
      await self.props.getPccAccountData(row).then(resp => {
        a.push(row);
        if (resp.data && resp.data.length > 0) {
          value = dataAccount.concat(resp.data.map(item => ({ account: item.pccaccountNumber, key: '' + item.pccAccountId })));
          dataAccount = value;
          self.setState({ dataAccount: dataAccount })
        }
      })
      if (a.length === e.length) {
        if (dataAccount.map(data => (data.key)).filter(value => -1 !== self.state.targetKeys.indexOf(value)).length === 0) {
          self.setState({ targetKeys: value.map(info => (info.key)).filter(k => -1 === self.state.targetKeys.indexOf(k)) })
        }
      }
    });
    self.setState({ pccaccountnumber: e })
  }; */

  async onchangePcc(e) {
    var orgAccounts = [];
    var dataaccount = [];
    if (this.state.useDefaultDataAccount === false) {
      if (e.length === 0) {
        this.setState({ dataAccount: [], targetKeys: [] });
      }
      for (var i = 0; i < e.length; i++) {
        var usedraft = [];
        var toto = e[i];
        usedraft = usedraft.concat(
          this.state.dataAccountCopyDraft.filter((data) => data.pccid === toto)
        );
        for (var m = 0; m < usedraft.length; m++) {
          orgAccounts.push({
            account: usedraft[m].pccAccountNumber,
            key: "" + usedraft[m].pccAccountID,
          });
        }
        if (usedraft.length > 0) {
          dataaccount = dataaccount.concat(
            usedraft.map((item) => ({
              account: item.pccAccountNumber,
              key: "" + JSON.stringify(item.pccAccountID),
            }))
          );
          this.setState({
            targetKeys: dataaccount
              .map((info) => info.key)
              .filter((value) => -1 !== this.state.targetKeys.indexOf(value)),
          });
        }
        this.props.getPccAccountData(toto).then((resp) => {
          if (resp.data.length > 0) {
            dataaccount = dataaccount.concat(
              resp.data.map((item) => ({
                account: item.pccaccountNumber,
                key: "" + item.pccAccountId,
              }))
            );
          }
          if (this.state.targetKeys) {
            this.setState({
              targetKeys: dataaccount
                .map((info) => info.key)
                .filter((value) => -1 !== this.state.targetKeys.indexOf(value)),
            });
          }

          this.setState({ dataAccount: dataaccount });
        });
      }
    } else {
      this.state.dataAccountCopyDraft.map((da) => {
        this.props.getPccAccountData(da.pccid).then((resp) => {
          if (resp.data.length > 0) {
            this.setState({
              dataAccount: dataaccount.concat(
                resp.data.map((item) => ({
                  account: item.pccaccountNumber,
                  key: "" + item.pccAccountId,
                }))
              ),
            });
          }
        });
      });
      dataaccount = dataaccount.concat(this.state.defaultDataAccount);
      var pccaccountNoArr = [];
      dataaccount.map((i) => {
        var exists = pccaccountNoArr.findIndex(
          (j) => parseInt(j.pccAccountID) === parseInt(i.key)
        );
        if (exists === -1) {
          pccaccountNoArr.push({ pccAccountID: i.key });
        }
      });
      this.setState({
        pccaccountno: pccaccountNoArr,
        //dataAccount: dataaccount,
        targetKeys: dataaccount
          .map((i) => i.key)
          .filter((el, index, arr) => {
            return index == arr.indexOf(el);
          }),
        useDefaultDataAccount: false,
      });
    }
    if (dataaccount.length === 0) {
      this.setState({ pccaccountnumber: [] });
    }
  }

  // on plann change checks
  onPlanChange(e) {
    if (finalData.map((data) => data.productID).includes(e)) {
      this.setState({ selectedplan: e });
      let _productInfo = this.state.productInfo;
      _productInfo.postSalePricePerLicense = finalData.filter(
        (info) => info.productID === e
      )[0].postSalePricePerLicense;
      this.setState({
        savedproductcheck: true,
        productInfo: _productInfo,
        disableallproductfields: false,
      });
    } else {
      this.setState({
        savedproductcheck: false,
        disableallproductfields: false,
      });
    }
  }
  // on Plan value change calculate prices
  onNoofPlansChange(e) {
    let _productInfo = this.state.productInfo;
    // if (this.state.selectedplan !== undefined && this.state.selectedplan !== null) {
    //   _productInfo.productRetailPrice = parseInt(finalData.filter(info => (info.productID === this.state.selectedplan))[0].postSalePricePerLicense) * parseInt(e.target.value)
    //   _productInfo.productDealPrice = parseInt(finalData.filter(info => (info.productID === this.state.selectedplan))[0].postSalePricePerLicense) * parseInt(e.target.value)

    // }
    this.setState({
      productInfo: _productInfo,
    });
  }

  getHeaderKeys = () => {
    return [
      {
        title: <IntlMessages id="admin.manageOrders.ProductName" />,
        dataIndex: "name",
        width: "15%",
      },

      {
        title: <IntlMessages id="admin.organization.Numberoflicence" />,
        dataIndex: "productQty",
        width: "15%",
      },
      {
        title: <IntlMessages id="admin.org.usedPlans" />,
        dataIndex: "planConsumed",
        width: "15%",
      },
      {
        title: <IntlMessages id="admin.org.unusedPlans" />,
        // dataIndex: "productQty",
        width: "15%",
        render(data, record) {
          const totalusedplan = record.planConsumed + record.planExpired;
          const planunuesd = record.productQty - totalusedplan;
          return planunuesd;
        },
      },
      {
        title: <IntlMessages id="admin.org.expiredPlans" />,
        // dataIndex: "productQty",
        width: "15%",
        render(data, record) {
          return record.planExpired;
        },
      },
    ];
  };

  addnewProduct() {
    let _productInfo = this.state.productInfo;
    _productInfo.productDealPrice = null;
    _productInfo.postSalePricePerLicense = null;
    _productInfo.postSalePrice = null;
    _productInfo.productQty = null;
    _productInfo.productID = null;
    this.setState({
      addnew: true,
      productInfo: _productInfo,
    });
  }

  cancelsaveproduct = () => {
    this.setState({ addnew: false });
  };
  // save and update product
  saveproduct() {
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.setState({ loading: true });
        var data = {
          postSalePricePerLicense: 0,
          productDealPrice: +values.dealprice,
          productID: +values.membership_type,
          productQty: +values.licence_number,
          productRetailPrice: +values.price,
          planConsumed: 0,
          name: this.state.productData.filter(
            (data) => data.productId === values.membership_type
          )[0].name,
          orgID: this.props.match.params.id,
          billingOrgID:
            typeof values.organizationId === "undefined"
              ? this.state.organizationId
              : values.organizationId,
        };

        // console.log(data);
        var form_data = {
          orgID: this.props.match.params.id,
          billingOrgID:
            typeof values.organizationId === "undefined"
              ? this.state.organizationId
              : values.organizationId,
          productInfo: [
            {
              postSalePricePerLicense: 0,
              productDealPrice: +values.dealprice,
              productID: +values.membership_type,
              productQty: +values.licence_number,
              productRetailPrice: +values.price,
              productRowPrice: 0,
              planConsumed: 0,
            },
          ],
        };

        //console.log("neworderda", JSON.stringify(form_data));

        this.props.B2B2CGuardMeNewOrder(form_data).then((resp) => {
          if (resp) {
            if (
              finalData
                .map((data) => data.name)
                .includes(
                  this.state.productData.filter(
                    (data) => data.productId === values.membership_type
                  )[0].name
                )
            ) {
              finalData.filter(
                (data) => data.productID === values.membership_type
              )[0].productQty =
                parseFloat(values.licence_number) +
                parseFloat(
                  finalData.filter(
                    (data) => data.productID === values.membership_type
                  )[0].productQty
                );
              finalData.filter(
                (data) => data.productID === values.membership_type
              )[0].productDealPrice =
                parseFloat(values.dealprice) +
                parseFloat(
                  finalData.filter(
                    (data) => data.productID === values.membership_type
                  )[0].productDealPrice
                );

              this.props
                .getOrganizationId(this.props.match.params.id)
                .then((resp) => {
                  this.setState({ loading: false });
                  notifyUser("Plan Updated successfully!", "success");
                });
            } else {
              this.props
                .getOrganizationId(this.props.match.params.id)
                .then((resp) => {
                  finalData.push(data);
                  this.setState({ loading: false });

                  notifyUser("Plan Added successfully!", "success");
                });
            }

            let _productInfo = this.state.productInfo;
            _productInfo.productDealPrice = null;
            _productInfo.postSalePricePerLicense = null;
            _productInfo.postSalePrice = null;
            _productInfo.productQty = null;
            _productInfo.productID = null;
            this.setState({
              addnew: false,
              disableallproductfields: true,
              productInfo: _productInfo,
            });
          }
        });
      }
    });
  }

  // show modal popup
  showModal = (data, name, price) => {
    this.setState({ index_array: data });
    this.setState({
      visible: true,
      modelName: name,
      postpriceedit: +price,
    });
  };

  handleOk = (e) => {
    let data_array = finalData;
    data_array[this.state.index_array].postSalePricePerLicense = parseInt(
      this.textInput.current.state.value
    );

    this.setState({
      visible: false,
      productInfo: data_array,
    });
    this.textInput.current.state.value = null;
  };
  handleCancel = (e) => {
    this.setState({
      visible: false,
    });
    this.textInput.current.state.value = null;
  };
  // modal end
  onChangeOrganizationTree = (_selected) => {};

  backpage() {
    this.props.history.push(
      "/admin/b2b2c-organizations/edit/" + this.props.match.params.id
    );
  }

  getBillableOrg = (orgId) => {
    let _this = this;
    this.props.getBillableOrganizations(orgId).then((resp) => {
      _this.props.getDivisionsList(orgId).then((resp2) => {
        let parentId;
        for (var i in resp2.data) {
          if (resp2.data[i].organizationId == orgId) {
            parentId = resp2.data[i].parentOrgId;
          }
        }
        let list = this.getNestedChildren(resp2.data, parentId, resp.data);
        this.setState({
          billableDivisionList: list,
        });
      });
    });
  };

  getNestedChildren = (arr, parent, billableOrgs) => {
    var out = [];
    for (var i in arr) {
      if (arr[i].parentOrgId == parent) {
        var children = this.getNestedChildren(
          arr,
          arr[i].organizationId,
          billableOrgs
        );
        if (children.length) {
          arr[i].children = children;
        }
        arr[i].title = arr[i].name;
        arr[i].value = arr[i].organizationId;
        arr[i].label = arr[i].name;
        arr[i].key = arr[i].organizationId;
        let idx = billableOrgs
          ? billableOrgs.findIndex(
              (e) => e.organizationId == arr[i].organizationId
            )
          : -1;
        if (idx === -1) {
          arr[i].disabled = true;
        } else {
          if (billableOrgs[idx]) {
            arr[i].title = billableOrgs[idx].name;
          } else {
            arr[i].title = arr[i].name;
          }
        }
        if (!arr[i].disabled) {
          out.push(arr[i]);
        }
      }
    }
    return out;
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { formLayout, dataAccount } = this.state;
    const { formLayoutaddPlan } = this.state.productInfo;
    // const { getguradmeplans} = this.State.guardPlanType;
    const formItemLayout =
      formLayout === "horizontal"
        ? {
            labelCol: { span: 4 },
            wrapperCol: { span: 14 },
          }
        : null;

    // console.log("gffffffffffffffffffffffffff", this.state.guardMePlanType);
    // // const guardmeOptions =  [
    // //   { label: '6 Months', value: 1 },
    // //   { label: '12 Months', value:  2 },
    // //   { label: '12 Months without Assistance', value: 3 },
    // // ];

    let HybridTypeId = 0;
    if (this.state.orgTypes.length > 0) {
      this.state.orgTypes.find((t) => {
        if (t.name.toLowerCase().indexOf("hybrid") > -1) {
          HybridTypeId = t.id;
        }
      });
    }
    return (
      <div>
        <Row gutter={24}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Typography.Title level={4}>
              <IntlMessages id="admin.primary-plan" />
            </Typography.Title>
          </Col>
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={12}
            style={{ textAlign: "right" }}
          >
            <Button type="primary" onClick={this.backpage}>
              <IntlMessages id="admin.button.back" />
            </Button>
          </Col>
        </Row>
        <hr />
        <Spin spinning={this.state.loading}>
          <Form layout={formLayout} onSubmit={this.handleSubmit}>
            <Row gutter={24}>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Form.Item
                  label={
                    <IntlMessages id="admin.organizations.plantype"></IntlMessages>
                  }
                >
                  {this.state.orgData.orgTypeId == HybridTypeId &&
                  this.state.guardMePlanType.length > 0 &&
                  this.state.isGuardMeOrg
                    ? getFieldDecorator("orgPlansGuardme", {
                        rules: [
                          {
                            //             required: true,
                            message: (
                              <IntlMessages id="admin.input.required"></IntlMessages>
                            ),
                          },
                        ],
                      })(
                        <div>
                          <Checkbox.Group
                            options={this.state.guardMePlanType}
                            // onChange={this.onChangeGuardMePlan}
                            disabled={true}
                            value={this.state.selectedGuardmePlan}
                          />
                        </div>
                      )
                    : getFieldDecorator("orgPlans", {
                        rules: [
                          {
                            required: true,
                            message: "this is required field",
                          },
                        ],
                      })(
                        <div>
                          <Checkbox
                            indeterminate={this.state.planType.indeterminate}
                            onChange={this.onCheckAllChange}
                            checked={this.state.planType.checkAll}
                          >
                            All
                          </Checkbox>
                          <Checkbox.Group
                            options={this.state.planType.planTypesList.map(
                              (l) => {
                                return { value: l.autoId, label: l.name };
                              }
                            )}
                            value={this.state.planType.selectedPlanTypes}
                            onChange={this.onChangePlanType}
                          />
                        </div>
                      )}
                </Form.Item>
              </Col>
              {this.state.isGuardMeOrg == true ? (
                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                  <Form.Item
                    label={<IntlMessages id="admin.organization.creditdays" />}
                  >
                    {getFieldDecorator("creditDays", {
                      rules: [
                        {
                          required: true,
                          message: (
                            <IntlMessages id="admin.input.required"></IntlMessages>
                          ),
                        },
                      ],

                      initialValue:
                        this.state.orgData &&
                        this.state.orgData.creditDaysLimit,

                      value:
                        this.state.orgData &&
                        this.state.orgData.creditDaysLimit,
                    })(<Input max={3} />)}
                  </Form.Item>
                </Col>
              ) : (
                ""
              )}
              {this.state.isGuardMeOrg != true ? (
                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                  {/* <Form.Item label={<IntlMessages id="admin.organizations.selectplan"></IntlMessages>}> */}

                  <Form.Item
                    label={
                      <IntlMessages id="admin.organizations.selectRawPrice"></IntlMessages>
                    }
                  >
                    {getFieldDecorator("rawPriceId", {
                      rules: [
                        {
                          required: true,
                          message: (
                            <IntlMessages id="admin.input.required"></IntlMessages>
                          ),
                        },
                      ],
                      onChange: this.onChangePlan2,
                      initialValue: this.state.orgData
                        ? this.state.orgData.rawPriceId
                        : null,
                      value: this.state.orgData.rawPriceId,
                    })(
                      <Select
                        style={{ width: "100%" }}
                        showSearch
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {this.state.rawPrices
                          ? this.state.rawPrices.map(function(item) {
                              return (
                                <Option
                                  key={item.pricingListId}
                                  value={item.pricingListId}
                                >
                                  {item.name}
                                </Option>
                              );
                            })
                          : ""}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
              ) : (
                ""
              )}

              {this.state.isGuardMeOrg != true ? (
                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                  <Form.Item
                    label={
                      <IntlMessages id="admin.organizations.selectRetailPrice"></IntlMessages>
                    }
                  >
                    {getFieldDecorator("b2B2CPriceId", {
                      rules: [
                        {
                          required: true,
                          message: (
                            <IntlMessages id="admin.input.required"></IntlMessages>
                          ),
                        },
                      ],
                      onChange: this.onChangePlan,
                      initialValue: this.state.orgData
                        ? this.state.orgData.b2B2CPriceId
                        : null,
                      value: this.state.orgData.b2B2CPriceId,
                    })(
                      <Select
                        style={{ width: "100%" }}
                        showSearch
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {this.state.retailPrices
                          ? this.state.retailPrices.map(function(item) {
                              return (
                                <Option
                                  key={item.pricingListId}
                                  value={item.pricingListId}
                                >
                                  {item.name}
                                </Option>
                              );
                            })
                          : ""}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
              ) : (
                ""
              )}
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Form.Item
                  label={
                    <>
                      <IntlMessages id="admin.organizations.commission"></IntlMessages>
                      (%)
                    </>
                  }
                >
                  {getFieldDecorator("commission", {
                    rules: [
                      {
                        required: true,
                        message: (
                          <IntlMessages id="admin.input.required"></IntlMessages>
                        ),
                      },
                    ],
                    initialValue:
                      this.state.orgData &&
                      this.state.orgData.commission &&
                      this.state.orgData.commission !== null
                        ? this.state.orgData.commission
                        : 0,
                    value:
                      this.state.orgData &&
                      this.state.orgData.commission &&
                      this.state.orgData.commission !== null
                        ? this.state.orgData.commission
                        : 0,
                  })(<Input />)}
                </Form.Item>
              </Col>
            </Row>
            {/* <Row gutter={24}>
                {this.state.sixMonthPlanNo ?
                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Form.Item label="6 Months Plan Number">
                  {getFieldDecorator("sixMonthPlanNo", {
                    rules: [
                      {
                        required: true,
                        message: <IntlMessages id="admin.input.required"></IntlMessages>
                      }
                    ],
                    initialValue: 0,
                    onChange: e =>e.target.value
                  })(
                    <Input />
                  )}
                </Form.Item>
              </Col>
                :""}
                 {this.state.twelveMonthPlanNo ?
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Form.Item label="12 Months Plan Number">
                  {getFieldDecorator("twelveMonthPlanNo", {
                    rules: [
                      {
                        required: true,
                        message: <IntlMessages id="admin.input.required"></IntlMessages>
                      }
                    ],
                    initialValue: 0,
                    onChange: e => e.target.value
                  })(
                    <Input />
                  )}
                </Form.Item>
              </Col>
              :""}

              {this.state.twelveMonthWithoutAssist ?
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Form.Item label="12 Months Without Assistance Plan Number">
                  {getFieldDecorator("twelveMonthWithoutAssist", {
                    rules: [
                      {
                        required: true,
                        message: <IntlMessages id="admin.input.required"></IntlMessages>
                      }
                    ],
                    initialValue: 0,
                    onChange: e => e.target.value
                  })(
                    <Input />
                  )}
                </Form.Item>
              </Col>
              :""}
              </Row> */}
            {this.state.selectedPriceList !== null &&
            this.state.isGuardMeOrg != true ? (
              <Row gutter={24}>
                <Col xs={24} sm={24}>
                  <DerivedPricingQuickView
                    {...this.props}
                    priceList={this.state.selectedPriceList}
                  />
                </Col>
              </Row>
            ) : (
              ""
            )}

            {typeof this.state.orgData !== "undefined" &&
            this.state.orgData.orgTypeId === HybridTypeId ? (
              <Row gutter={24}>
                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                  <Form.Item
                    label={<IntlMessages id="admin.organization.pccnumber" />}
                  >
                    {getFieldDecorator("pcc_number", {
                      onChange: (e) => {
                        this.onchangePcc(e);
                      },
                      value: this.state.pccaccountnumber,
                      initialValue:
                        this.state.pccaccountnumber === null
                          ? ""
                          : this.state.pccaccountnumber,
                    })(
                      // pccdata
                      <Select
                        mode="multiple"
                        style={{ width: "100%" }}
                        showSearch
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {this.state.pccData.map(function(item) {
                          return (
                            <Option key={item.pccId} value={item.pccId}>
                              {item.code}
                            </Option>
                          );
                        })}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={16} lg={16} xl={16}>
                  <Form.Item
                    label={
                      <IntlMessages id="admin.organization.accountnumber" />
                    }
                  >
                    {getFieldDecorator("account_number", {
                      rules: [],
                    })(
                      <Transfer
                        dataSource={dataAccount}
                        showSearch
                        filterOption={this.filterOption}
                        targetKeys={this.state.targetKeys}
                        onChange={this.handleChangeTransfer}
                        onSearch={this.handleSearch}
                        render={(item) => item.account}
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>
            ) : (
              ""
            )}
            <Button type="primary" onClick={() => this.handleSubmit()}>
              <IntlMessages id="admin.organization.submit" />
            </Button>
          </Form>

          {(this.state.isGuardMeOrg == true &&
            Permission.isRole("Administrator")) ||
          Permission.isRole("CSR") ? (
            <Row gutter={24} style={{ margin: "20px 0" }}>
              <Col xs={24}>
                {!this.state.addnew === true ? (
                  <Button className="right-fl" onClick={this.addnewProduct}>
                    {" "}
                    <IntlMessages id="admin.organization.addplan" />
                  </Button>
                ) : (
                  ""
                )}
              </Col>
            </Row>
          ) : (
            ""
          )}

          {this.state.addnew === true ? (
            <form layout={formLayoutaddPlan}>
              <Row gutter={24} style={{ margin: "20px 0" }}>
                <Col xs={24}>
                  <Typography.Title level={4}>Add new plan</Typography.Title>
                </Col>
              </Row>
              <hr />
              <Row gutter={24} style={{ marginBottom: "20px" }}>
                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                  <Form.Item
                    {...formItemLayout}
                    label={<IntlMessages id="admin.invoice.billto" />}
                  >
                    {getFieldDecorator("organizationId")(
                      <TreeSelect
                        initialValue={this.state.organizationId}
                        treeData={this.state.billableDivisionList}
                        style={{ width: "100%" }}
                        dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                        onChange={this.onChangeOrganizationTree}
                        placeholder="Please select"
                        treeDefaultExpandAll
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                  <Form.Item
                    label={<IntlMessages id="admin.organizations.plantype" />}
                  >
                    {getFieldDecorator("membership_type", {
                      rules: [
                        {
                          required: true,
                          message: "Please select Plan!",
                        },
                      ],
                      initialValue: this.state.productInfo.productID,
                    })(
                      <Select
                        style={{ width: "100%" }}
                        disabled={!this.state.addnew}
                        onChange={this.onPlanChange}
                      >
                        {this.state.productData.map(function(item) {
                          return (
                            <Option key={item.productId} value={item.productId}>
                              {item.name}
                            </Option>
                          );
                        })}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                  <Form.Item
                    {...formItemLayout}
                    label={
                      <IntlMessages id="admin.organization.NumberofPlans" />
                    }
                  >
                    {getFieldDecorator("licence_number", {
                      rules: [
                        {
                          required: true,
                          message: (
                            <IntlMessages id="admin.input.required"></IntlMessages>
                          ),
                        },
                        {
                          pattern: new RegExp(/^[0-9']+$/),
                          message: (
                            <IntlMessages id="admin.plannumber.valid"></IntlMessages>
                          ),
                        },
                      ],
                      initialValue:
                        this.state.productInfo.productQty === null
                          ? ""
                          : this.state.productInfo.productQty,
                      onChange: (e) => {
                        this.onNoofPlansChange(e);
                      },
                      value: this.state.productInfo.productQty,
                    })(
                      <Input
                        disabled={
                          !this.state.addnew ||
                          this.state.disableallproductfields
                        }
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                  <Form.Item
                    {...formItemLayout}
                    label={<IntlMessages id="admin.organization.retailprice" />}
                  >
                    {getFieldDecorator("price", {
                      rules: [
                        {
                          required: true,
                          message: (
                            <IntlMessages id="admin.input.required"></IntlMessages>
                          ),
                        },
                        {
                          pattern: new RegExp(/^[.0-9']+$/),
                          message: (
                            <IntlMessages id="admin.retailnumber.valid"></IntlMessages>
                          ),
                        },
                      ],
                      initialValue:
                        this.state.productInfo.productRetailPrice === null
                          ? ""
                          : this.state.productInfo.productRetailPrice,
                      onChange: (e) => {},
                      value: this.state.productInfo.productRetailPrice,
                    })(
                      <Input
                        disabled={
                          !this.state.addnew ||
                          this.state.disableallproductfields
                        }
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                  <Form.Item
                    {...formItemLayout}
                    label={<IntlMessages id="admin.organization.dealprice" />}
                  >
                    {getFieldDecorator("dealprice", {
                      rules: [
                        {
                          required: true,
                          message: (
                            <IntlMessages id="admin.input.required"></IntlMessages>
                          ),
                        },
                        {
                          pattern: new RegExp(/^[.0-9']+$/),
                          message: (
                            <IntlMessages id="admin.dealprice.valid"></IntlMessages>
                          ),
                        },
                      ],
                      initialValue:
                        this.state.productInfo.productDealPrice === null
                          ? ""
                          : this.state.productInfo.productDealPrice,
                      onChange: (e) => {},
                      value: this.state.productInfo.productDealPrice,
                    })(
                      <Input
                        disabled={
                          !this.state.addnew ||
                          this.state.disableallproductfields
                        }
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24} style={{ margin: "20px 0" }}>
                <Col xs={24}>
                  {this.state.addnew === true ? (
                    <div>
                      <Button type="primary" onClick={this.saveproduct}>
                        Save Plan
                      </Button>
                      &nbsp;&nbsp;
                      <Button type="" onClick={this.cancelsaveproduct}>
                        Cancel
                      </Button>
                    </div>
                  ) : (
                    ""
                  )}
                </Col>
              </Row>
            </form>
          ) : (
            ""
          )}

          {this.state.isGuardMeOrg == true ? (
            <Table
              columns={this.getHeaderKeys()}
              rowKey={(record) => record.productID}
              dataSource={finalData}
              pagination={this.state.pagination}
              //loading={this.state.loading}
              //onChange={this.handleTableChange}
              scroll={{ x: "100%" }}
            />
          ) : (
            ""
          )}
        </Spin>

        <Modal
          title="Basic Modal"
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
        >
          <p>Plan Name :-{this.state.modelName}</p>
          <p>Post Sale Price/Plan :-</p>{" "}
          <Input
            type="number"
            defaultValue={this.state.postpriceedit}
            //placeholder="Enter Post Sale Price/Plan (In USD)"
            ref={this.textInput}
          />
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return { ...state.user, ...state.language };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...AdminActions,
      ...productActions,
      ...B2B2COrgActions,
      ...PricingActions,
      ...OrgPlanTypeActions,
    },
    dispatch
  );
}
const WrappedForm = Form.create({ name: "organization-plans" })(
  EditB2B2COrganizationPlan
);
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    WrappedForm
  )
);
