import API from "../api/assist-traveler-api";

export function getAssistTravelersList(data) {
  return async function(dispatch, getState) {
    try {
      let resp = await API.getAssistTravelersList(data);
      return resp;
    } catch (e) {
      return { error: true };
    }
  };
}

export function getAssistTravelersByOrgId(data) {
  return async function(dispatch, getState) {
    try {
      let resp = await API.getAssistTravelersByOrgId(data);
      return resp;
    } catch (e) {
      return { error: true };
    }
  };
}

export function updateAssistTravelerStatus(data) {
  return async function(dispatch, getState) {
    try {
      let resp = await API.updateAssistTravelerStatus(data);
      return resp;
    } catch (e) {
      return { error: true };
    }
  };
}

export function getCheckInList(data) {
  return async function(dispatch, getState) {
    try {
      let resp = await API.getCheckInList(data);
      return resp;
    } catch (e) {
      return { error: true };
    }
  };
}
