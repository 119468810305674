import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import IntlMessages from "../../../../services/intlMesseges";
import AppLocale from "../../../../languageProvider";
import {
  Typography,
  Form,
  Row,
  Col,
  Radio,
  Modal,
  Input,
  Select,
  Button,
  Spin,
  Checkbox,
  Transfer,
  message,
} from "antd";
import * as B2B2COrgActions from "../../../../redux/actions/b2b2c-organization-actions";
import * as OrgPlanTypeActions from "../../../../redux/actions/org-plan-types";
import * as PricingActions from "../../../../redux/actions/pricing-actions";
import * as productActions from "../../../../redux/actions/product-actions";
import * as AdminActions from "../../../../redux/actions/admin-actions";
import { notifyUser } from "../../../../services/notification-service";
import DerivedPricingQuickView from "../../pricing/view/derived-quickview";
import moment from "moment";

const { Option } = Select;
class B2B2COrganizationPlan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      retailPrices: [],
      rawPrices: [],
      selectedRawPriceList: null,
      selectedPriceList: null,
      guardMePlanType: [],
      planType: {
        indeterminate: false,
        checkAll: false,
        selectedPlanTypes: [],
        planTypesList: []
      },
      selectedKeys: [],
      targetKeys: [],
      productData: [],
      pccData: [],
      orgTypes: [],
      dataAccount: [],
      isModalVisible: true,
      isGuardme: 'no',
      selectedGuardmePlan: [],

      pccaccountno: '',
      loading: true,
      pagination: {
        pageSize: 1000,
        current: 1,
        hideOnSinglePage: true,
        showTotal: (total, range) => {
          return (
            <span>
              <IntlMessages id='admin.text.showing' /> {range[0]}-{range[1]} <IntlMessages id='admin.text.of' /> {total}{' '}
              <IntlMessages id='admin.text.results' />
            </span>
          );
        }
      }
    };
    this.handleGuardPlans = this.handleGuardPlans.bind(this);
    this.onChangeGuardMePlan = this.onChangeGuardMePlan.bind(this);
  }

  async componentDidMount() {
    this.setState({ loading: false });
    const pager = { ...this.state.pagination };
    pager.current = this.state.pagination.current;
    await this.props
      .getPlanTypes({
        filters: {},
        pagination: {},
        sorter: {}
      })
      .then((resp) => {
        if (resp.data && resp.data.length > 0) {
          let _planType = this.state.planType;
          _planType.planTypesList = resp.data;
          this.setState({ planType: _planType });
        }
      });
    this.props
      .getPricing({
        filters: {},
        pagination: {
          page: this.state.pagination.current,
          pageSize: this.state.pagination.pageSize
        },
        sorter: {}
      })
      .then((resp) => {
        // console.log("Resp: ",resp);
        if (resp.data.length > 0) {
          let _retailPrices = [];
          let _rawPrices = [];
          resp.data.map((item) => {
            if (item.calculationFor == 'RetailPrice' && item.priceType === 1) {
              _retailPrices.push(item);
            }
            if (item.calculationFor == 'RawPrice' && item.priceType === 1) {
              _rawPrices.push(item);
            }
          });
          this.setState({
            retailPrices: _retailPrices,
            rawPrices: _rawPrices
          });
        }
        pager.total = resp.paging.totalRecords;
        this.setState({
          loading: false,
          pagination: pager
        });
      })
      .catch((ex) => {});

    this.props
      .getProducts({})
      .then((resp) => {
        let data = resp.data;
        this.setState({
          productData: data
        });
      })
      .catch((ex) => {});

    this.props
      .getPccdata({})
      .then((resp) => {
        let data = resp.data;
        this.setState({
          pccData: data
        });
      })
      .catch((ex) => {});

    await this.props.getGuardMeProducts().then((resp) => {
      let guraditem = resp.map((item) => {
        return { label: item.name, value: item.productId };
      });
      this.setState({ guardMePlanType: guraditem });
    });

    await this.props
      .getResellerOrganizationTypes({
        filters: {},
        pagination: { page: 1 },
        sorter: {}
      })
      .then((re) => {
        if (re.data && re.data.length > 0) {
          this.setState({ orgTypes: re.data });
        }
      });
  }

  handleChangeTransfer = (targetKeys) => {
    this.props.onchange(targetKeys, 'targetKeys');
    this.setState({ targetKeys });
    this.setState({ isEmpty: true });
    var data = [];
    if (targetKeys.length === 0) {
      this.props.onchange([], 'pccaccountno');
    } else {
      for (var i = 0; i < targetKeys.length; i++) {
        data.push({ pccAccountID: +targetKeys[i] });
      }
      this.props.onchange(data, 'pccaccountno');
    }
  };
  handleSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
    this.setState({
      selectedKeys: [...sourceSelectedKeys, ...targetSelectedKeys]
    });
  };
  handleSearch = (dir, value) => {};

  handleSubmit() {
    const { language } = this.props;
    const currentAppLocale = AppLocale[language.locale];
    this.props.form.validateFieldsAndScroll((err, values) => {

      if (values.orgPlans == false) {
        message.error('Plans Types Not Selected!');
        return false;
      }
      if (!err) {
          this.setState({ loading: true });
          let plans = [];
          this.state.planType.selectedPlanTypes.map((plan) => {
            plans.push({ planTypeId: values.rawPriceId, planType: 2 });
            plans.push({ planTypeId: values.b2B2CPriceId, planType: 1 });
          });
  
          let productinfo = [];
          let guardmeplans = [];
          
          if(this.state.isGuardme == 'yes'){
           guardmeplans = Object.entries(this.state.selectedPlansData)
            .filter(([key]) => this.state.selectedIds.includes(parseInt(key)))
            .map(([key, value]) => ({ [key]: value }));
          
          
          guardmeplans.map((obj) => {
            //data.push(Object.keys(val))
            for (const [key, value] of Object.entries(obj)) {
              productinfo.push({
                productID: `${key}`,
                productQty: `${value}`,
                productRowPrice: 0,
                productRetailPrice: 0,
                planConsumed: 0,
                productDealPrice: 0,
                postSalePricePerLicense: 0
              });
            }
          });
        }
        
        

        var Json_Org = {
          orgName: this.props.company_name,
          code: this.props.company_code,
          orgTypeId: this.props.orgTypeId,
          b2B2CPriceId: this.props.b2B2CPriceId,
          rawPriceId: this.props.rawPriceId,
          retailPriceId: this.props.b2B2CPriceId,
          address1: this.props.address,
          state: this.props.company_state,
          city: this.props.city,
          zipCode: this.props.zip,
          countryId: this.props.country,
          phoneNo: this.props.phoneNumber,
          isBusinessAddressSame: this.props.isBusinessAddressSame,
          isCommunicationAddressSame: this.props.isCommunicationAddressSame,
          fpManagerID: 0,
          fpSalesExecutiveID: 0,
          emailID: this.props.email,
          contactFirstName: this.props.firstName,
          contactLastName: this.props.lastName,
          coverageStartDate: this.props.startDate ? this.props.startDate : new Date().toDateString(),
          coverageEndDate: this.props.endDate
            ? this.props.endDate
            : new Date(new Date().setFullYear(new Date().getFullYear() + 1)).toDateString(),
          days: 365,
          countries: this.props.org_country,
          customerID: this.props.customer_id,
          jobNumber: this.props.job_number,
          creditDaysLimit: this.props.creditDays,
          productInfo: productinfo,
          orgContacts: [
            {
              addressTypeID: 2,
              address: this.props.sec_address,
              city: this.props.sec_city,
              state: this.props.sec_state,
              zipCode: this.props.sec_zip,
              countryId: this.props.sec_country,
              contacts: this.props.sec_phoneNumber,
              contactPerson: this.props.secondary_contactPerson,
              emailID: this.props.sec_email
            },
            {
              addressTypeID: 3,
              address: this.props.billing_address,
              city: this.props.billing_city,
              state: this.props.billing_state,
              zipCode: this.props.billing_zip,
              countryId: this.props.billing_country,
              contacts: this.props.billing_phoneNumber,
              contactPerson: this.props.billing_contactPerson,
              emailID: this.props.billing_email,
              billingCompanyName: this.props.billing_company_name
            }
          ],
          // "orgPlans": this.state.planType.selectedPlanTypes.map(plan => { return { planTypeId: plan } }),
          // "orgPlans": this.state.planType.selectedPlanTypes.map(plan => { return { planTypeId: values.rawPriceId, planType: plan }}),
          orgPlans: plans,
          planTypeId: this.state.planType.selectedPlanTypes.toString(),

          commission: this.props.commission,
          orgPCCAccounts: this.props.pccaccountno,
          orgPlansGuardme: guardmeplans,
          isGuardMeOrg: guardmeplans.length > 0 ? true : false
        };

        this.props.form.validateFieldsAndScroll((err, values) => {
          if (!err) {
            this.props
              .onboardB2B2COrganization(Json_Org)
              .then(async (response) => {
                if (response.error) {
                  notifyUser('An error occured. Please refresh and start over!', 'error');
                  this.setState({ loading: false });
                } else {
                  this.setState({ loading: false });
                  this.props.onchange('organizationId', response.data.organizationID);
                  this.props.onchange('current', 2);
                }
              })
              .catch((err) => {
                this.setState({ loading: false });
              });
          } else {
            this.setState({ loading: false });
          }
        });
      }
    });
  }

  handleGuardPlans(e) {
    console.log(e.target.value);
  }

  hideModal = () => {
    this.setState({ isModalVisible: false });
  };

   onChangeGuardMePlan = (selected) => {
    this.setState({ selectedIds: selected });
    const selectedGuardMePlanType = this.state.guardMePlanType.filter((plan) => selected.includes(plan.value));

    this.setState({ selectedPlans: selectedGuardMePlanType });
  };

  handlePlanNos = (e, data) => {
    this.setState((prevState) => ({
      selectedPlansData: {
        ...prevState.selectedPlansData,
        ...data
      }
    }));
  };
  
  
  backpage() {
    const current = this.props.current - 1;
    this.setState({ current: current });
    this.props.onchange('current', 0);
  }

  onChangePlan = async (pricingId) => {
    await this.props.getPrice(pricingId).then((res) => {
      this.props.onchange(pricingId, 'b2B2CPriceId');
      if (res.pricingInfo) {
        this.setState({ selectedPriceList: null }, () => this.setState({ selectedPriceList: res.pricingInfo }));
      } else {
        this.setState({ selectedPriceList: null });
      }
    });
  };

  onChangePlan2 = async (pricingId) => {
    await this.props.getPrice(pricingId).then((res) => {
      this.props.onchange(pricingId, 'rawPriceId');
      if (res.pricingInfo) {
        this.setState({ selectedRawPriceList: null }, () =>
          this.setState({
            selectedRawPriceList: res.pricingInfo,
            b2B2CPriceName: res.name
          })
        );
      } else {
        this.setState({ selectedRawPriceList: null });
      }
    });
  };

  onChangePlanType = (checkedList) => {
    let _planType = this.state.planType;
    _planType.selectedPlanTypes = checkedList;
    _planType.indeterminate = !!checkedList.length && checkedList.length < _planType.planTypesList.length;
    _planType.checkAll = checkedList.length === _planType.planTypesList.length;
    this.setState({ planType: _planType });
  };

  onCheckAllChange = (e) => {
    let _planType = this.state.planType;
    _planType.indeterminate = false;
    _planType.checkAll = e.target.checked;
    _planType.selectedPlanTypes = e.target.checked ? _planType.planTypesList.map((l) => l.autoId) : [];
    this.setState({ planType: _planType });
  };

  async onchangePcc(e) {
    var dataAccount = [];
    var value = [];
    var self = this;
    var a = [];
    if (e.length > 0) {
      e.map((row) => {
        self.props.getPccAccountData(row).then((resp) => {
          a.push(row);
          if (resp.data && resp.data.length > 0) {
            if (dataAccount.length > 0) {
              dataAccount.map((tkey) => {
                if (parseInt(row.key) !== parseInt(tkey.key)) {
                  dataAccount.push(tkey);
                }
              });
            } else {
              dataAccount = resp.data.map((item) => ({
                account: item.pccaccountNumber,
                key: '' + item.pccAccountId
              }));
            }
          }
          if (a.length === e.length) {
            if (
              dataAccount.map((data) => data.key).filter((value) => -1 !== self.state.targetKeys.indexOf(value))
                .length === 0
            ) {
              self.setState({
                targetKeys: value.map((info) => info.key).filter((value) => -1 !== self.state.targetKeys.indexOf(value))
              });
            }
            this.setState({ dataAccount: dataAccount });
            self.props.onchange(dataAccount, 'pccaccountnumber');
            if (dataAccount.length === 0) {
              self.props.onchange([], 'pccaccountno');
            }
          }
        });
      });
    }
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const { formLayout, dataAccount } = this.state;

    let HybridTypeId = 0;
    if (this.state.orgTypes.length > 0) {
      this.state.orgTypes.find((t) => {
        if (t.name.toLowerCase().indexOf('hybrid') > -1) {
          HybridTypeId = t.id;
        }
      });
    }
    return (
      <div style={{ marginTop: '50px' }}>
        {this.props.orgTypeId == HybridTypeId ? (
          <Modal
            title='Under Guard.me'
            visible={this.state.isModalVisible}
            onCancel={this.hideModal}
            maskClosable={false}
            footer={[
              <Button key='cancel' type='primary' onClick={this.hideModal}>
                Ok
              </Button>
            ]}
            style={{ marginTop: '60px' }}
          >
            <Radio.Group
              onChange={(e) => {
                this.setState({ isGuardme: e.target.value });
              }}
            >
              <Radio value='yes'>Yes</Radio>
              <Radio value='no'>No</Radio>
            </Radio.Group>
          </Modal>
        ) : (
          ''
        )}
        <Typography.Title level={3}>
          <IntlMessages id='admin.primary-plan' />
        </Typography.Title>
        <Spin spinning={this.state.loading}>
          <Form layout={formLayout} onSubmit={this.handleSubmit}>
            <Row gutter={24}>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Form.Item label={<IntlMessages id='admin.organizations.plantype'></IntlMessages>}>
                  {this.state.isGuardme == 'yes' && this.state.guardMePlanType.length > 0
                    ? getFieldDecorator('orgPlansGuardme', {
                        rules: [
                          {
                            required: true,
                            message: <IntlMessages id='admin.input.required'></IntlMessages>
                          }
                        ]
                      })(
                        <div>
                          <Checkbox.Group
                            options={this.state.guardMePlanType}
                            onChange={this.onChangeGuardMePlan}
                            // value={this.state.selectedGuardmePlan}
                          />
                        </div>
                      )
                    : getFieldDecorator('orgPlans', {
                        rules: [
                          {
                            required: true,
                            message: <IntlMessages id='admin.input.required'></IntlMessages>
                          }
                        ]
                      })(
                        <div>
                          <Checkbox
                            indeterminate={this.state.planType.indeterminate}
                            onChange={this.onCheckAllChange}
                            checked={this.state.planType.checkAll}
                          >
                            All
                          </Checkbox>
                          <Checkbox.Group
                            options={this.state.planType.planTypesList.map((l) => {
                              return { value: l.autoId, label: l.name };
                            })}
                            value={this.state.planType.selectedPlanTypes}
                            onChange={this.onChangePlanType}
                          />
                        </div>
                      )}
                </Form.Item>
              </Col>
              {this.state.isGuardme != 'yes' ? (
                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                  {/* <Form.Item label={<IntlMessages id="admin.organizations.selectplan"></IntlMessages>}> */}
                  <Form.Item label={<IntlMessages id='admin.organizations.selectRawPrice'></IntlMessages>}>
                    {getFieldDecorator('rawPriceId', {
                      rules: [
                        {
                          required: true,
                          message: <IntlMessages id='admin.input.required'></IntlMessages>
                        }
                      ],
                      onChange: this.onChangePlan2
                    })(
                      <Select
                        style={{ width: '100%' }}
                        showSearch
                        filterOption={(input, option) =>
                          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {this.state.rawPrices
                          ? this.state.rawPrices.map(function(item) {
                              return (
                                <Option key={item.pricingListId} value={item.pricingListId}>
                                  {item.name}
                                </Option>
                              );
                            })
                          : ''}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
              ) : (
                ''
              )}
              {this.state.isGuardme != 'yes' ? (
                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                  <Form.Item label={<IntlMessages id='admin.organizations.selectRetailPrice'></IntlMessages>}>
                    {getFieldDecorator('b2B2CPriceId', {
                      rules: [
                        {
                          required: true,
                          message: <IntlMessages id='admin.input.required'></IntlMessages>
                        }
                      ],
                      onChange: this.onChangePlan
                    })(
                      <Select
                        style={{ width: '100%' }}
                        showSearch
                        filterOption={(input, option) =>
                          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {this.state.retailPrices
                          ? this.state.retailPrices.map(function(item) {
                              return (
                                <Option key={item.pricingListId} value={item.pricingListId}>
                                  {item.name}
                                </Option>
                              );
                            })
                          : ''}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
              ) : (
                ''
              )}
              {this.state.isGuardme == 'yes' ? (
                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                  <Form.Item label={<IntlMessages id='admin.organization.creditdays' />}>
                    {getFieldDecorator('creditDays', {
                      rules: [
                        {
                          required: true,
                          message: <IntlMessages id='admin.input.required'></IntlMessages>
                        },
                        {
                          pattern: new RegExp(/^[.0-9']+$/),
                          message: <IntlMessages id='admin.creditdays.valid'></IntlMessages>
                        },
                        {
                          validator: this.limitfunction,
                          message: <IntlMessages id='admin.creditdays.valid'></IntlMessages>
                        }
                      ],
                      initialValue: this.props.creditDays === null ? '' : this.props.creditDays,
                      onChange: (e) => {
                        this.props.onchange(e);
                      },
                      value: this.props.creditDays
                    })(<Input max={3} />)}
                  </Form.Item>
                </Col>
              ) : (
                ''
              )}
            </Row>

            <Row gutter={24}>
             {this.state.selectedPlans &&
                this.state.selectedPlans.length > 0 &&
                this.state.selectedPlans.map((item) => {
                  return (
                    <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                      <Form.Item label={`No of Plans for ${item.label}`}>
                        {getFieldDecorator(item.label, {
                          rules: [
                            {
                              required: true,
                              message: <IntlMessages id='admin.input.required'></IntlMessages>
                            }
                          ],
                          // initialValue: 0,
                          onChange: (e) => this.handlePlanNos(e, { [item.value]: e.target.value })
                        })(<Input />)}
                      </Form.Item>
                    </Col>
                  );
                })}
            </Row>

            <Row gutter={24}>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Form.Item
                  label={
                    <>
                      <IntlMessages id='admin.organizations.commission'></IntlMessages>
                      (%)
                    </>
                  }
                >
                  {getFieldDecorator('commission', {
                    rules: [
                      {
                        required: true,
                        message: <IntlMessages id='admin.input.required'></IntlMessages>
                      }
                    ],
                    initialValue: 0,
                    onChange: (e) => this.props.onchange(e.target.value, 'commission')
                  })(<Input />)}
                </Form.Item>
              </Col>
            </Row>
            {this.state.selectedPriceList !== null && this.state.isGuardme != 'yes' ? (
              <Row gutter={24}>
                <Col xs={24} sm={24}>
                  <DerivedPricingQuickView {...this.props} priceList={this.state.selectedPriceList} />
                </Col>
              </Row>
            ) : (
              ''
            )}

            <Row gutter={24}>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Form.Item label={<IntlMessages id='admin.organization.pccnumber' />}>
                  {getFieldDecorator('pcc_number', {
                    onChange: (e) => {
                      this.props.onchange(e, 'pcc_number');
                      this.onchangePcc(e);
                    },
                    value: this.props.pcc_number,

                    initialValue: this.props.pcc_number === null ? '' : this.props.pcc_number
                  })(
                    // pccdata
                    <Select
                      mode='multiple'
                      style={{ width: '100%' }}
                      showSearch
                      filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {this.state.pccData.map(function(item) {
                        return (
                          <Option key={item.pccId} value={item.pccId}>
                            {item.code}
                          </Option>
                        );
                      })}
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={16} lg={16} xl={16}>
                <Form.Item label={<IntlMessages id='admin.organization.accountnumber' />}>
                  {getFieldDecorator('account_number', {
                    rules: []
                  })(
                    <Transfer
                      dataSource={dataAccount}
                      showSearch
                      filterOption={this.filterOption}
                      targetKeys={this.state.targetKeys}
                      onChange={this.handleChangeTransfer}
                      onSearch={this.handleSearch}
                      render={(item) => item.account}
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Button style={{ marginRight: 8 }} onClick={() => this.backpage()}>
              <IntlMessages id='admin.oraganization.previous' />
            </Button>
            <Button type='primary' onClick={() => this.handleSubmit()}>
              <IntlMessages id='admin.organization.submit' />
            </Button>
          </Form>
        </Spin>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return { ...state.user, ...state.language };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...AdminActions,
      ...productActions,
      ...B2B2COrgActions,
      ...PricingActions,
      ...OrgPlanTypeActions,
    },
    dispatch
  );
}
const WrappedForm = Form.create({ name: "organization-plans" })(
  B2B2COrganizationPlan
);
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    WrappedForm
  )
);
