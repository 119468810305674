import OrgAPI from "../redux/api/organization-api";
let currentUser = JSON.parse(localStorage.getItem('user'));
const config = {
  defaultTheme: currentUser && currentUser.themeId ? currentUser.themeId : 1,
  options: [
    {
      themeId: 1,
      primaryColor:'blue',
      secondaryColor:'red',
      fontColor:'black',
      name:'Theme1',
      className:'theme-dark'
    },
    {
      themeId: 2,
      primaryColor:'red',
      secondaryColor:'blue',
      fontColor:'white',
      name:'Theme2',
      className:'theme-neutral'
    },
    {
      themeId: 3,
      primaryColor:'green',
      secondaryColor:'black',
      fontColor:'white',
      name:'Theme3',
      /* text:'Theme3', */
      className:'theme-light'
    },
  ],
};

export async function getCurrentTheme(them) {
  /* let selecetedTheme = {};
  await OrgAPI.getActiveThemes().then(response => {
    if(response.data) {
      let user = JSON.parse(localStorage.getItem('user'));
      user.themeId = them;
      localStorage.setItem("user", JSON.stringify(user));
      return response.data;
    }
  }); */

  let selecetedTheme = config.options.find(x=>x.themeId === them);
  config.options.forEach(theme => {
    if (theme.themeId === them) {
      selecetedTheme = theme;
    }
  });
  return selecetedTheme;
}
export default config;
