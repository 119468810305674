import React, { Component } from "react";
export default class LogoSvgb2c extends Component {
  render() {
    return (
      <svg className="svg" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 125.2 105">
        <g>
          <path
            className="st0"
            d="M17.4,50.9h87.4c2.4,0,4.4,2,4.4,4.5v35c0,2.5-2,4.5-4.4,4.5H17.4c-2.4,0-4.4-2-4.4-4.5v-35
             C13,52.9,15,50.9,17.4,50.9z"
          />
          <path
            className="st0"
            d="M17.4,7h87.4c2.4,0,4.4,2.1,4.4,4.6v34.7c0,2.5-2,4.6-4.4,4.6H17.4c-2.4,0-4.4-2.1-4.4-4.6V11.6
             C13,9,15,7,17.4,7z"
          />
          <path
            className="st1"
            d="M16.5,50.9h89.2c2.1,0,3.8-1.2,4.3-2.9l5.9-22.2c0.3-1.1,0-2.2-0.9-3.2c-0.9-0.9-2-1.4-3.4-1.4H10.6
             c-1.4,0-2.6,0.5-3.4,1.4c-0.9,0.9-1.2,2-0.9,3.2L12.2,48C12.7,49.7,14.4,50.9,16.5,50.9z"
          />
          <path
            className="st2"
            d="M57.5,69H34.2c-0.3,0-0.6,0.1-1.1,0.1c0.9-5,5.8-8,10-10.7c3.4-2.2,7.2-4.5,10-7.5h-5.3
             c-1.7,1.4-3.6,2.6-5.3,3.7c-7.1,4.7-13.2,8.4-13.7,17.9h28.7V69L57.5,69z M67.9,50.9c-2.9,2-4.4,5.3-4.4,9.3
             c0,8,5.8,13.1,14.9,13.1c9.3,0,15-5.1,15-13.5c0-3.9-1.6-7-4.3-9h-7.9c5.1,0.9,8.2,4.4,8.2,9.2c0,6-4.3,9.9-10.9,9.9
             c-6.7,0-11-3.9-11-9.9c0-4.8,3.1-8.3,8.1-9.2H67.9z"
          />
          <path
            className="st2"
            d="M53.1,50.9c1.2-1.1,2.2-2.2,2.9-3.6c0.7-1.4,1.1-2.9,1.1-4.7c-0.1-3.3-1.6-6.2-4.2-8.2
             c-2.7-2.1-6.5-3.3-11.1-3.3c-4.9,0-8.6,1.4-10.9,3.9c-2.2,2.4-3,5.8-2.2,9.8c0,0.2,0.1,0.3,0.1,0.5c1.3,0,2.7,0,4,0
             c-0.5-2.8-0.2-5.4,1.1-7.4c1.4-2,3.9-3.3,8.1-3.3c3.2,0,5.9,0.9,7.9,2.3c1.9,1.4,3,3.5,3.2,5.9c0.1,1.9-0.5,3.4-1.4,4.7
             c-0.9,1.3-2.3,2.4-3.7,3.4C49.5,50.9,51.3,50.9,53.1,50.9L53.1,50.9z M67.9,50.9c2.5,0,5.1,0,7.6,0c0.9-0.1,1.8-0.2,2.8-0.2
             c1,0,2,0.1,2.9,0.2c2.6,0,5.2,0,7.9,0c-1.1-0.7-2.6-1.2-4.3-1.5c2.4-0.6,4.3-1.6,5.8-2.9c1.5-1.4,2.5-3.1,2.9-5.2
             c0.6-3-0.4-5.5-2.6-7.3c-2.2-1.9-5.8-3-10.2-3s-8.3,1.1-10.9,3c-2.6,1.9-4.1,4.5-4.2,7.5c-0.1,2,0.6,3.8,1.8,5.1
             c1.2,1.3,2.9,2.3,5.2,2.8C70.7,49.7,69.2,50.2,67.9,50.9L67.9,50.9z M80.3,34.4c3.2,0,5.7,0.7,7.2,2c1.5,1.2,2.2,3,1.8,5.2
             c-0.4,2.1-1.5,3.8-3.4,4.9c-1.8,1.1-4.3,1.7-7.2,1.7c-2.9,0-5.2-0.6-6.8-1.7c-1.6-1.1-2.5-2.8-2.4-4.9c0.1-2.2,1.2-4,3-5.2
             C74.5,35.1,77.1,34.4,80.3,34.4z"
          />
        </g>
      </svg>
    );
  }
}
