import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as productActions from "../../../redux/actions/product-actions";
import IntlMessages from "../../../services/intlMesseges";
import PricingAPI from "../../../redux/api/pricing-api";
import AppLocale from "../../../languageProvider";
import { notifyUser } from "../../../services/notification-service";
import { Typography, Form, Input, Select, Button, Row, Col, Spin, Switch } from "antd";
import TextArea from "antd/lib/input/TextArea";

const { Option } = Select;

class ProductEdit extends React.Component {
  state = {
    loading: false,
    confirmDirty: false,
    autoId: 0,
    productId: 0,
    name: "",
    price: "",
    insertDate: "",
    description: "",
    rawPriceId: "",
    retailPriceId: "",
    retailPrices: "",
    rawPrices: "",
    stateRawPriceId: "",
    stateRetailPriceId: "",
    isTrackingActive: false
  };

  async componentDidMount() {
    if (
      this.props.match &&
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.id &&
      this.props.match.params.id !== "add"
    ) {
      this.setState({ loading: true, id: this.props.match.params.id });
      const product = await this.props.getProduct(this.props.match.params.id);
      this.setState(product);
      this.setState({ loading: false });
    }
    let tempPricingList = await PricingAPI.getPricing({
      filters: {},
      pagination: {pageSize:100},
      sorter: {},
    
    });
    let retail = [];
    let raw = [];
    tempPricingList.data.map(function(obj, idx) {
      if (obj.calculationFor === "RawPrice") {
        raw.push(obj);
      } else {
        retail.push(obj);
      }
    });
    this.setState({ retailPrices: retail, rawPrices: raw });
  }

  submitFormAdd = data => {
    const { language } = this.props;
    const currentAppLocale = AppLocale[language.locale];
    this.props
      .addProduct(data)
      .then(resp => {
        if(resp != null){
          notifyUser(currentAppLocale.messages["admin.product.add.success"], "success");
          this.props.history.push("./");
        } else {
          notifyUser(currentAppLocale.messages["admin.product.add.error"], "error");
        }
        this.setState({ loading: false });
      })
      .catch(err => {
        console.log(err);
        this.setState({ loading: false });
      });
  };

  submitFormEdit = data => {
    const { language } = this.props;
    const currentAppLocale = AppLocale[language.locale];
    this.props
      .updateProduct(data)
      .then(resp => {
        if(resp === null){
          notifyUser(currentAppLocale.messages["admin.product.updated.success"], "success");
          this.props.history.push("./");
        } else {
          notifyUser(currentAppLocale.messages["admin.product.updated.error"], "error");
        }
        this.setState({ loading: false });
      })
      .catch(err => {
        this.setState({ loading: false });
      });
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        Object.keys(values).map(function(key, _idx) {
          if(values[key] == null){
            values[key] = '';
          } else {
            if(typeof values[key] == 'string'){
              values[key] = values[key].trim();
            }
          }
        });
        this.setState({ loading: true });
        if (this.state.productId && this.state.productId > 0) {
          values["productId"] = this.state.productId;
          values["isTrackingActive"] = this.state.isTrackingActive;
          this.submitFormEdit(values);
        } else {
          values["isTrackingActive"] = this.state.isTrackingActive;
          this.submitFormAdd(values);
        }
      }
    });
  };

  onTrackingChange = (checked, e) => {
    this.setState({ isTrackingActive: checked });
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const { formLayout } = this.state;
    const formItemLayout =
      formLayout === "horizontal"
        ? {
            labelCol: { span: 4 },
            wrapperCol: { span: 14 }
          }
        : null;
    const rawPriceSelector = getFieldDecorator("rawPriceId", {
      rules: [
        {
          required: true,
          message: <IntlMessages id ="admin.input.required"></IntlMessages>
        }
      ],
      initialValue: this.state.rawPriceId
    })(
      <Select>
        {this.state.rawPrices.length > 0
          ? this.state.rawPrices.map(function(item) {
            var countryRawPrice = [];
            if(item.priceType === 1)
            { 
              countryRawPrice.push(item.name);
            
              return (
                <Option key={item.pricingListId} value={item.pricingListId}>
                  {countryRawPrice}
                </Option>
              );
            }
            })
          : ""}
      </Select>
    );
    const retailPriceSelector = getFieldDecorator("retailPriceId", {
      rules: [
        {
          required: true,
          message: <IntlMessages id ="admin.input.required"></IntlMessages>
        }
      ],
      initialValue: this.state.retailPriceId
    })(
      <Select>
        {this.state.retailPrices.length > 0
          ? this.state.retailPrices.map(function(item) {
            var countryRetailPrice = [];
            if(item.priceType === 1 && item.name != "" && item.name != null)
            { 
              countryRetailPrice.push(item.name);
            
              return (
                <Option key={item.pricingListId} value={item.pricingListId}>
                  {countryRetailPrice}
                </Option>
              );
            }
            })
          : ""}
      </Select>
    );

    const stateRawPriceSelector = getFieldDecorator("stateRawPriceId", {
      rules: [
        {
          required: true,
          message: <IntlMessages id ="admin.input.required"></IntlMessages>
        }
      ],
      initialValue: this.state.stateRawPriceId
    })(
      <Select>
        {this.state.rawPrices.length > 0
          ? this.state.rawPrices.map(function(item) {
            var stateRawPrice = [];
            if(item.priceType === 2)
            { 
              stateRawPrice.push(item.name);
            
            return (
                <Option key={item.pricingListId} value={item.pricingListId}>
                  {stateRawPrice}
                </Option>
              );
            }
            })
          : ""}
      </Select>
    );
    const stateRetailPriceSelector = getFieldDecorator("stateRetailPriceId", {
      rules: [
        {
          required: true,
          message: <IntlMessages id ="admin.input.required"></IntlMessages>
        }
      ],
      initialValue: this.state.stateRetailPriceId
    })(
      <Select>
        {this.state.retailPrices.length > 0
          ? this.state.retailPrices.map(function(item) {
            var stateRetailPrice = [];
            if(item.priceType === 2)
            { 
              stateRetailPrice.push(item.name);
            
              return (
                <Option key={item.pricingListId} value={item.pricingListId}>
                  {stateRetailPrice}
                </Option>
              );
            }
            })
          : ""}
      </Select>
    );
    return (
      <div>
        <Row gutter={24}>
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography.Title level={4}>
              {this.state.productId === 0 ? (
                <IntlMessages id="admin.manageProduct.addProduct" />
              ) : (
                <IntlMessages id="admin.manageProduct.editProduct" />
              )}
            </Typography.Title>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <Button
              type=""
              className="right-fl def-blue"
              htmlType="button"
              onClick={() => this.props.history.push("./")}
            >
              <IntlMessages id="admin.button.back" />
            </Button>
          </Col>
        </Row>
        <hr />
        <div>
          <Spin spinning={this.state.loading}>
            <Form layout={formLayout} onSubmit={this.handleSubmit}>
              <Row gutter={24}>
                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                  <Form.Item
                    {...formItemLayout}
                    label={<IntlMessages id="admin.productlist.name" />}
                  >
                    {getFieldDecorator("name", {
                      rules: [
                        {
                          required: true,
                          message: <IntlMessages id="admin.input.required" />
                        }
                      ],
                      initialValue:
                        this.state.name === null ? "" : this.state.name
                    })(<Input />)}
                  </Form.Item>
                </Col>
                {getFieldDecorator("price", {
                  
                  initialValue:
                    this.state.price === null ? "" : this.state.price
                })(<Input type="hidden" />)}
                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                  <Form.Item
                    {...formItemLayout}
                    label={<IntlMessages id="admin.productlist.countryrawPrice" />}
                  >

                    {rawPriceSelector}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                  <Form.Item
                    {...formItemLayout}
                    label={<IntlMessages id="admin.productlist.countryretailPrice" />}
                  >
                    {retailPriceSelector}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
              {getFieldDecorator("stateprice", {
                  
                  initialValue:
                    this.state.stateRawPriceId === null ? "" : this.state.stateRawPriceId
                })(<Input type="hidden" />)}
                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                  <Form.Item
                    {...formItemLayout}
                    label={<IntlMessages id="admin.productlist.stateRawPrice" />}
                  >

                    {stateRawPriceSelector}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                  <Form.Item
                    {...formItemLayout}
                    label={<IntlMessages id="admin.productlist.stateRetailPrice" />}
                  >
                    {stateRetailPriceSelector}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col xs={24} sm={24} md={16} lg={16} xl={16}>
                  <Form.Item
                    {...formItemLayout}
                    label={<IntlMessages id="admin.productlist.description" />}
                  >
                    {getFieldDecorator("description", {
                      initialValue:
                        this.state.description === null
                          ? ""
                          : this.state.description
                    })(<TextArea />)}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                  <Form.Item
                    {...formItemLayout}
                    label={<IntlMessages id="admin.product.trackingStatus" />}
                  >
                    <Switch checkedChildren="Enabled" unCheckedChildren="Disabled" checked={this.state.isTrackingActive} onChange={this.onTrackingChange} name="userTracking"/>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form.Item>
                    {/* <Button
                      type="default"
                      className=""
                      htmlType="button"
                      onClick={() => this.props.history.push("./")}
                    >
                      <IntlMessages id="admin.button.back" />
                    </Button>
                    &nbsp;&nbsp; */}
                    <Button
                      type="primary"
                      style={{ display: "inline-block", marginRight: "10px" }}
                      className="def-blue"
                      htmlType="submit"
                    >
                      {this.state.productId === 0 ? (
                        <IntlMessages id="admin.button.add" />
                      ) : (
                        <IntlMessages id="admin.button.update" />
                      )}
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Spin>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {...state.language};
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...productActions }, dispatch);
}
const WrappedForm = Form.create({ name: "edit-product" })(ProductEdit);
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    WrappedForm
  )
);
