import React, { Component } from "react";
import Wizard from "../shared/wizard/wizard.jsx";
import StripeProvider from "./stripe-information/stripe-provide";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as b2cActions from "../../../redux/actions/b2c-actions";
import TravellerInfo from "../shared/traveller/traveller.jsx";
import { Beforeunload } from 'react-beforeunload';
class BillingInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      totalPrice: props.planTerm ? props.planTerm.totalPrice : 0,
      planMaxPrice: 0,
      appliedPromoText:""
    };
    this.updatePrice = this.updatePrice.bind(this);
    this.handlePlanChange = this.handlePlanChange.bind(this);
    this.calculatePrice = this.calculatePrice.bind(this);
  }

  async handlePlanChange(evt) {
    var targetValue = evt.target.value.split(",");
    this.props.personalDetails[targetValue[0]].product_id = targetValue[1];
    await this.props.SavePersonalDetails(this.props.personalDetails);
    this.calculatePrice();
  }

  async calculatePrice() {
    var totalPrice = 0;
    var dayPriceObject;
    for (var i = 0; i < this.props.personalDetails.length; i++) {
      var travellerObject = this.props.personalDetails[i];
      if (
        this.props.productPriceList &&
        this.props.productPriceList.length > 0
      ) {
        if (travellerObject.is_annual) {
          dayPriceObject = this.props.productPriceList.find(
            x =>
              x.tierId === 1 &&
              x.dayPackage === 365 &&
              x.productId === parseInt(travellerObject.product_id)
          );
          totalPrice = totalPrice + parseInt(dayPriceObject.retailPrice);
        } else {
          dayPriceObject = this.props.productPriceList.find(
            x =>
              x.tierId === parseInt(this.props.planTerm.tierValue) &&
              x.dayPackage === parseInt(this.props.planTerm.days) &&
              x.productId === parseInt(travellerObject.product_id)
          );
          totalPrice = totalPrice + parseInt(dayPriceObject.retailPrice);
        }
      } else {
        totalPrice = this.props.planTerm.totalPrice;
      }
    }
    let planTermObject = this.props.planTerm;
    planTermObject.totalPrice = totalPrice;
    await this.props.SavePlanTerm(planTermObject);
    var maxPriceDifference =
      this.props.personalDetails.length * this.state.planMaxPrice - totalPrice;
    this._bindPlanPrice(maxPriceDifference);
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    var dayPriceObject;
    var maxPrice = 999;
    var maxPriceDifference = 0;
    if (this.props.planTerm && Object.keys(this.props.planTerm).length === 0) {
      await this.props.getStep();
      await this.props.getProductPricingAndInfo();
      await this.props.getPersonalInformation();
      await this.props.getPlanTermInformation();
      await this.props.getPlanInformation();
      await this.props.getBillingInformation();
      if (parseInt(this.props.step) < 3) {
        this.props.history.push(this.props.b2cBaseUrl+ "/personal-details");
      }else{
        if (this.props.productPriceList && this.props.productPriceList.length > 0) {
          dayPriceObject = this.props.productPriceList.find(
            x =>
              x.tierId === 1 &&
              x.dayPackage === 365 &&
              x.productId ===
                parseInt(
                  this.props.productInfo[this.props.productInfo.length - 2].productId /* cap-med price */
                )
          );
          maxPrice = dayPriceObject.retailPrice;
        }
        maxPriceDifference =
          this.props.personalDetails.length * maxPrice -
          this.props.planTerm.totalPrice;
          this._bindPlanPrice(maxPriceDifference);
        this.setState({
          planMaxPrice: maxPrice,
        });
      }
    } else if (parseInt(this.props.step) < 3) {
      this.props.history.push(this.props.b2cBaseUrl+ "/personal-details");
    }else{
      if (this.props.productPriceList && this.props.productPriceList.length > 0) {
        dayPriceObject = this.props.productPriceList.find(
          x =>
            x.tierId === 1 &&
            x.dayPackage === 365 &&
            x.productId ===
              parseInt(
                this.props.productInfo[this.props.productInfo.length - 2]
                  .productId
              )
        );
        maxPrice = dayPriceObject.retailPrice;
      }
      maxPriceDifference =
        this.props.personalDetails.length * maxPrice -
        this.props.planTerm.totalPrice;
        this._bindPlanPrice(maxPriceDifference);
      this.setState({
        planMaxPrice: maxPrice,
      });
    }
  }

  updatePrice() {
    if(this.props.personalDetails && this.props.personalDetails.length > 0){
      var maxPriceDifference =
      this.props.personalDetails.length * this.state.planMaxPrice -
      this.props.planTerm.totalPrice;
      this._bindPlanPrice(maxPriceDifference);
    }
  }

  async  _bindPlanPrice(maxPriceDifference){
    var appliedPromoText = ""
    await this.props.getAppliedPromo();
    var totalPrice = this.props.planTerm.totalPrice;
    if(this.props.couponInfo && this.props.couponInfo.promoOfferId){
      var discountedPrice = 0;
      if(this.props.couponInfo.discountType === "P"){
        discountedPrice = this.props.planTerm.totalPrice*this.props.couponInfo.discountFigure/100;
      }
      else{
        discountedPrice = this.props.couponInfo.discountFigure;
      }
      appliedPromoText = "Discount of $"+discountedPrice+" has been applied to the total plan fee"
      totalPrice = totalPrice - discountedPrice;
    }
    this.setState({
      totalPrice: totalPrice < 0 ? 0 : totalPrice,
      priceToShowAsDiffernce: maxPriceDifference,
      appliedPromoText: appliedPromoText
    });
  }
  onUnload = (e) => {
    e.preventDefault();
    /* this.props.flushOrderData();
    this.props.ClearData(); */
  }
  render() {
    return (
      <section id="banner">
        <Beforeunload onBeforeunload={(e) => this.onUnload(e)}>
          <Wizard wizardActiveClass="step4" />
          <div
            className="wrap900 price-sticky-head"
            style={{
              display: "block",
              maxWidth: "1300px",
              zIndex: "100",
              position: "sticky"
            }}
          >
            <div className="title" style={{ background: "#FFF" }}>
              <h1>
                <span>Plan Fee:</span>
                {this.state.totalPrice
                  ? " $" + this.state.totalPrice.toFixed(2) + " USD"
                  : " $0.00 USD"}
              </h1>
              <span>{this.state.appliedPromoText}</span>
            </div>
          </div>
          {/* plans upgrade */}
          <div className="wrap">
            <div className="purchase-summary" style={{ marginBottom: "100px" }}>
              {/*- title */}

              {/* title ends */}
              {/* traveler deatils */}
              {this.props.personalDetails &&
              this.props.personalDetails.length > 0 &&
              this.props.planTerm &&
              this.props.planTerm.tierValue &&
              this.props.countryTierInfo.length > 0 ? (
                <TravellerInfo
                  priceToShowAsDiffernce={this.state.priceToShowAsDiffernce}
                  productPriceList={this.props.productPriceList}
                  updatePrice={this.updatePrice}
                  SavePlanTerm={this.props.SavePlanTerm}
                  productPricing={this.props.productPricing}
                  planMaxPrice={this.state.planMaxPrice}
                  handlePlanChange={this.handlePlanChange}
                  planTerm={this.props.planTerm}
                  productInfo={this.props.productInfo}
                  personalDetails={this.props.personalDetails}
                  countryTierInfo={this.props.countryTierInfo}
                />
              ) : (
                ""
              )}
              {/* traveler deatils end */}
            </div>
          </div>
          {/* plan upgrade end */}
          <StripeProvider
            history={this.props.history}
            appliedPromoText={this.state.appliedPromoText}
            billingInformation={this.props.billingInformation}
          />
        </Beforeunload>
      </section>
    );
  }
}

BillingInformation.propTypes = {
  productPricing: PropTypes.array,
  getPersonalInformation: PropTypes.func,
  getPlanInformation: PropTypes.func,
  getPlanTermInformation: PropTypes.func,
  history: PropTypes.object,
  planTerm: PropTypes.object,
  productInfo: PropTypes.array,
  planType: PropTypes.object,
  personalDetails: PropTypes.array,
  productPriceList: PropTypes.array,
  getProductPricingAndInfo:PropTypes.func,
  countryTierInfo:PropTypes.array,
  getAppliedPromo:PropTypes.func,
  couponInfo:PropTypes.object,
  getStep:PropTypes.func,
  getBillingInformation:PropTypes.func,
  billingInformation:PropTypes.object,
  b2cBaseUrl:PropTypes.string

};
function mapStateToProps(state) {
  return {
    ...state.b2c
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...b2cActions }, dispatch);
}
export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { forwardRef: true }
)(BillingInformation);
