import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as userActions from "../../../redux/actions/user-actions";
import * as sidebarAction from "../../../redux/actions/sidebar-actions";
import LanguageSwitcher from "../../../languageSwitcher";
//import ThemeSwitcher from "../../../themeSwitcher/index.js";
import IntlMessages from "../../../services/intlMesseges";
import * as notificationActions from "../../../redux/actions/notifications-actions";
import {Tooltip, Icon, Row, Col, Menu, Dropdown, Badge } from "antd";

class UserHeader extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      collapsed: true,
      data: [],
      notificationsCount: 0
    };
    this.logout = this.logout.bind(this);
  }
  toggle = () => {
    this.props.toggleSidebar();
  };
  logout() {
    this.props.LogOutUser();
    this.props.history.push("/login");
  }
  componentDidMount() {
    this.props.getNotificationsList({}).then(data => {
      if(this.props.userData && this.props.userData.userID){
        this.props.getNotificationsCount(this.props.userData.userID).then(r => this.setState({notificationsCount: r}));
      }
      let _data = data.data
      if(_data){
        _data.splice(5);

        this.setState({ 
          data: _data
        })
      }
    })
  }

  notificationsList = () => {
    let notifications = this.state.data || [];
    let output = [];
    let _this = this;
    if(notifications.length > 0){
      notifications.map(function(n){
        output.push(<Menu.Item key={n.notificationMessagesId} onClick={() => _this.props.history.push("/reseller/notifications/")}><Tooltip title={<IntlMessages id="admin.manageOrders.View" />}>
        {n.title.length > 27 ? n.title.substring(0,27)+"...":n.title}
        </Tooltip></Menu.Item>);
      });
      output = <Menu style={{ width: 280 }}>{output}</Menu>;
    }
    return output;
  }

  profile = () => (
    <Menu style={{ width: 200 }}>
      <Menu.Item key="0">
        <Link to="/reseller/my-profile">My Profile</Link>
      </Menu.Item>
      <Menu.Item key="1">
        <Link to="/reseller/my-profile/change-password">Change Password</Link>
      </Menu.Item>
      <Menu.Item key="3" onClick={this.logout}>
        <span>Log Out</span>
      </Menu.Item>
    </Menu>
  );

  render() {
    let currentUserFirstname = this.props.userData.firstName;
    let currentUserLastname = this.props.userData.lastName;
    return (
      <div>
        <Row>
          <Col xs={3}>
            <Icon className='trigger' type={!this.props.sidebar ? 'menu-unfold' : 'menu-fold'} onClick={this.toggle} />
          </Col>
          <Col xs={21}>
            <ul>
              {this.props.notificationCount > 0 ? (
                <li>
                  <Dropdown overlay={this.notificationsList} trigger={['click']}>
                    <Badge style={{ background: '#2c6ba7' }} count={this.props.notificationCount}>
                      <a className='ant-dropdown-link bell-icon' href='#'>
                        <i className='fas fa-bell'></i>
                      </a>
                    </Badge>
                  </Dropdown>
                </li>
              ) : (
                <li>
                  <a
                    className='ant-dropdown-link bell-icon'
                    onClick={() => this.props.history.push('/reseller/notifications/')}
                  >
                    <i className='fas fa-bell'></i>
                  </a>
                </li>
              )}
              <li>
                <LanguageSwitcher />
              </li>
              <li>
                <Dropdown overlay={this.profile} trigger={['click']}>
                  <a className='ant-dropdown-link' href='#'>
                    <i className='fas fa-user-circle'></i>
                    <span className='name'>
                      {currentUserFirstname} {currentUserLastname}
                    </span>
                  </a>
                </Dropdown>
              </li>
              {/* <li>
                <div className="topBar">
                  <div className="wrap1500">
                    <ThemeSwitcher />
                  </div>
                </div>
              </li> */}
            </ul>
          </Col>
        </Row>
      </div>
    );
  }
}

UserHeader.propTypes = {
  LogOutUser: PropTypes.func,
  isLoggedIn: PropTypes.bool,
  toggleSidebar: PropTypes.func,
  notificationCount: PropTypes.string
};

function mapStateToProps(state) {
  return {
    ...state.user,
    ...state.sidebar
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...userActions, ...sidebarAction, ...notificationActions }, dispatch);
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UserHeader)
);
