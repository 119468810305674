import Axios from "../../services/axios-service";

//organization plan types
const getPlanTypes = data => {
  return Axios.get("/api/PlanTypes", data, undefined);
};

const getGuardMeProducts = () => {
  return Axios.get("/api/Product/getGuardMeProducts");
};


const getPlanType = idx => {
  return Axios.get("/api/PlanTypes/" + idx, undefined, undefined);
};

const updatePlanType = data => {
  return Axios.put("/api/PlanTypes/", data, undefined);
};

export default {
  getPlanTypes,
  getPlanType,
  updatePlanType,
  getGuardMeProducts
};
