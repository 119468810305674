import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Row, Col, Select, Tabs, Spin } from "antd";
import * as adminActions from "../../../redux/actions/admin-actions";
import * as paginationActions from "../../../redux/actions/pagination-actions";
import * as riskLineActions from "../../../redux/actions/risk-line-actions";
import API from "../../../redux/api/admin-api";
import config from "../../../../../config";

const { Option } = Select;
const { TabPane } = Tabs;

class Countries extends Component {
  constructor(props) {
    super(props);
    this.module = "countries";
    this.state = {
      countriesListByRegion: [],
      loading: false
    };
  }

  async componentDidMount() {
    this.props.clearPaginationExceptMe(this.module);
    this.setState({ loading: true });
    await API.getCountriesListByRegion()
      .then(resp => {
        this.setState({ countriesListByRegion: resp.data });
        this.setState({ loading: false });
      })
      .catch(ex => {
        this.setState({ loading: false });
      });
  }

  handleChange = id => {
    this.props.history.push("" + id);
  };

  _bindCountrylistByRegion() {
    return this.state.countriesListByRegion.map((r, index) => (
      <TabPane tab={r.regionName} key={r.regionID}>
        {r.countryList.map(c => (
          <a
            onClick={this.handleChange.bind(null, c.code)}
            data-country-code={c.code}
          >
            <Col xs={24} sm={24} md={12} lg={6} style={{ margin: "12px 0" }}>
              <img
                alt=""
                style={{
                  borderRadius: "100%",
                  width: "25px",
                  height: "25px",
                  marginRight: "10px",
                  border: "1px solid lightgray"
                }}
                src={config.API1 + "/images/" + c.code + ".png"}
              />
              <strong>{c.countryName}</strong>
            </Col>{" "}
          </a>
        ))}
      </TabPane>
    ));
  }

  _bindAllCountryList() {
    var tempCountryArray = [];
    this.state.countriesListByRegion.map((r, index) =>
      r.countryList.map((c, index) =>
        tempCountryArray.push({ countryName: c.countryName, code: c.code })
      )
    );
    return (
      <Select
        showSearch
        style={{ width: 200 }}
        placeholder="Select a Country"
        optionFilterProp="children"
        onChange={this.handleChange}
        filterOption={(input, option) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {tempCountryArray.map(c => (
          <Option key={c.code}>{c.countryName}</Option>
        ))}
      </Select>
    );
  }

  render() {
    return (
      <div>
        <Spin size="large" spinning={this.state.loading}>
          <div>
            <Row>
              <Col style={{ textAlign: "right" }}>
                {this._bindAllCountryList()}
              </Col>
            </Row>
            <Row gutter={24}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Tabs>{this._bindCountrylistByRegion()}</Tabs>
              </Col>
            </Row>
          </div>
        </Spin>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return { ...state.pagination };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { ...adminActions, ...riskLineActions, ...paginationActions },
    dispatch
  );
}
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    Countries
  )
);
