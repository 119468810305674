import { Tabs, Form, Row, Col, Typography, Button } from "antd";
import OrganizationPlan from "./organization-plan";
import PrimaryAddress from "./primary-address";
import AdminSettings from "./settings";
import React, { Component } from "react";
import IntlMessages from "../../../services/intlMesseges";
import * as b2b2cOrgActions from "../../../redux/actions/b2b2c-organization-actions";
import * as Permission from "../../../services/permissions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { notifyUser } from "../../../services/notification-service";

const { TabPane } = Tabs;
class EditOrganization extends Component {
  constructor(props) {
    super(props);
    this.state = {
      countries: [],
      productData: [],
      orgData: {},
      addData: false,
      activeTab: "#address",
      tab1title: <IntlMessages id="admin.organization.address" />,
      tab2title: <IntlMessages id="admin.organization.plan" />,
      tab3title: <IntlMessages id="admin.userlisting.settings" />
    };
    this.onTabsChange = this.onTabsChange.bind(this);
    this.addProduct = this.addProduct.bind(this);
    this.saveproduct = this.saveproduct.bind(this);
  }

  async componentDidMount() {
    this.fetchOrgData();
  }

  fetchOrgData = () => {
    let orgId = (this.props.userData && this.props.userData.companyID) ? this.props.userData.companyID : null;
    if(orgId === null){
      let cu = JSON.parse(localStorage.getItem("user"));
      if(cu && cu.companyID){
        orgId = cu.companyID;
      }
    }
    this.props.getOrganizationId(orgId).then(async resp => {
      if (resp.error) {
        notifyUser(resp.error.message, 'error');
        this.props.history.push('./../../');
        this.setState({ loading: false });
      } else {
        this.setState({
          orgData: resp.data,
          loading: false
        });
      }
    }).catch(ex => {
      this.setState({ loading: false });
    });
  }

  onTabsChange = currentTab => {};
  addProduct() {
    this.setState({ addData: true });
  }
  saveproduct() {
    this.setState({ addData: false });
  }
  updateState = (data) => {
    this.setState({
      orgData: data
    });
  }
  render() {
    return (
      <div>
        <Row gutter={24}>
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography.Title level={4}>
              <IntlMessages id="admin.sidebar.manageOrg" />
            </Typography.Title>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <Button
              type="primary"
              className="right-fl def-blue"
              htmlType="button"
              onClick={() => this.props.history.goBack()}
            >
              <IntlMessages id="admin.button.back" />
            </Button>
          </Col>
        </Row>
        <hr/>
        {this.state.orgData && this.state.orgData.organizationId ? 
          <Tabs
            defaultActiveKey={this.state.activeTab}
            onChange={this.onTabsChange}
          >
            <TabPane tab={this.state.tab1title} key="#address">
              <PrimaryAddress orgData={this.state.orgData} updateStateOnChange={this.fetchOrgData} />
            </TabPane>
            {/* <TabPane tab={this.state.tab2title} key="#plan">
              <OrganizationPlan orgData={this.state.orgData} updateStateOnChange={this.fetchOrgData} />
            </TabPane> */}
            {Permission.isRole(["Administrator","CSR","Admin","Travel Manager","Partner"]) ? 
              <TabPane tab={this.state.tab3title} key="#users">
                <AdminSettings orgData={this.state.orgData} updateStateOnChange={this.fetchOrgData} />
              </TabPane>
            : "" }
          </Tabs>
        :'' }
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...b2b2cOrgActions }, dispatch);
}
// export default EditOrganization;
const WrappedForm = Form.create({ name: "organization-plans" })(
  EditOrganization
);
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    WrappedForm
  )
);
