import React, { Component } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel
} from "react-accessible-accordion";
export default class TravelMedicalMobile extends Component {
  render() {
    return (
      <tr>
        <td colSpan="3">
          <AccordionItem uuid="travel-medical-mobile">
            <table>
              <tbody>
                <tr>
                  <td colSpan="3" className="title">
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        <b>Travel Medical Assistance Services</b>
                      </AccordionItemButton>
                    </AccordionItemHeading>
                  </td>
                </tr>
              </tbody>
            </table>

            <AccordionItemPanel>
              <Accordion allowZeroExpanded={true}>
                <table>
                  <tbody>
                    <tr className="border">
                      <td>
                        <AccordionItem>
                          <AccordionItemHeading>
                            <AccordionItemButton>
                              Home Hospital of Choice, Medical Evacuation &
                              Repatriation
                            </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                            <p>
                              Medical transportation from the facility that the
                              CAP™ customer is receiving medical treatment to
                              the hospital of choice, in the CAP™ customer’s
                              primary place of residence, for further medical
                              treatment or recovery.
                            </p>
                          </AccordionItemPanel>
                        </AccordionItem>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table>
                  <tbody>
                    <tr className="border">
                      <td>
                        <AccordionItem>
                          <AccordionItemHeading>
                            <AccordionItemButton>
                              Return of Dependent Children
                            </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                            <p>
                            If a CAP™ customer is hospitalized or in critical condition and was traveling with dependent
children or a designated travel companion, we will arrange their return to the traveler’s country
of residence.
                            </p>
                          </AccordionItemPanel>
                        </AccordionItem>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table>
                  <tbody>
                    <tr className="border">
                      <td>
                        <AccordionItem>
                          <AccordionItemHeading>
                            <AccordionItemButton>
                              Advance of Emergency Medical Expenses
                            </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                            <p>
                            We will advance the CAP™ customer on-site emergency inpatient medical expenses within a
reasonable timeframe upon the satisfactory guarantee of payment from either the CAP™
customer or their insurance company.
                            </p>
                          </AccordionItemPanel>
                        </AccordionItem>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table>
                  <tbody>
                    <tr className="border">
                      <td>
                        <AccordionItem>
                          <AccordionItemHeading>
                            <AccordionItemButton>
                              Medical Case Monitoring
                            </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                            <p>
                            Our multilingual staff will contact local attending medical personnel to monitor a CAP™
customer’s condition and will keep loved ones informed until the medical emergency is resolved.
                            </p>
                          </AccordionItemPanel>
                        </AccordionItem>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table>
                  <tbody>
                    <tr className="border">
                      <td>
                        <AccordionItem>
                          <AccordionItemHeading>
                            <AccordionItemButton>
                              Visit of Family Member or Friend
                            </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                            <p>
                            If a CAP™ customer is hospitalized while traveling for a period in excess of 3 days or is in
critical condition, we will arrange the transportation of a family member or friend designated by
the CAP™ customer, from their home to the hospital where the CAP™ customer is being
treated.
                            </p>
                          </AccordionItemPanel>
                        </AccordionItem>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table>
                  <tbody>
                    <tr className="border">
                      <td>
                        <AccordionItem>
                          <AccordionItemHeading>
                            <AccordionItemButton>
                              Accommodation during Visit of Family Member or
                              Friend
                            </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                            <p>
                              If a CAP™ customer is hospitalized while traveling
                              for a period in excess of 3 days or is in critical
                              condition, we will arrange the accommodations for
                              a visiting family member or friend
                            </p>
                          </AccordionItemPanel>
                        </AccordionItem>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table>
                  <tbody>
                    <tr className="border">
                      <td>
                        <AccordionItem>
                          <AccordionItemHeading>
                            <AccordionItemButton>
                              Transport Escort
                            </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                            <p>
                              We will arrange for a family member, companion, or
                              travel escort to accompany the CAP™ customer
                              during an emergency evacuation or medically
                              necessary repatriation.
                            </p>
                          </AccordionItemPanel>
                        </AccordionItem>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table>
                  <tbody>
                    <tr className="border">
                      <td>
                        <AccordionItem>
                          <AccordionItemHeading>
                            <AccordionItemButton>
                              Coordination / Assistance with Medical Payments
                            </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                            <p>
                            We will assist by coordinating notifications to medical insurers or managed care organizations,
verifying policy enrollment, confirming medical benefits coverage, guaranteeing medical
payments, assisting in the coordination of multiple insurance benefits, and handling claim forms.
                            </p>
                          </AccordionItemPanel>
                        </AccordionItem>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table>
                  <tbody>
                    <tr className="border">
                      <td>
                        <AccordionItem>
                          <AccordionItemHeading>
                            <AccordionItemButton>
                              Repatriation of Mortal Remains
                            </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                            <p>
                            If a CAP™ customer dies while traveling, we will arrange the necessary government
authorization, including the cost of embalming, all required documentation, a container suitable
for transport, and the means of transportation of the remains, to the CAP™ customer’s country
of residence.
                            </p>
                          </AccordionItemPanel>
                        </AccordionItem>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table>
                  <tbody>
                    <tr className="border">
                      <td>
                        <AccordionItem>
                          <AccordionItemHeading>
                            <AccordionItemButton>
                              Medical Emergency Relocation
                            </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                            <p>
                            Medical arrangements for ground or air transportation to and from the hospital, including pre-
admission and medical escorts for ongoing medical supervision.
                            </p>
                          </AccordionItemPanel>
                        </AccordionItem>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table>
                  <tbody>
                    <tr className="border">
                      <td>
                        <AccordionItem>
                          <AccordionItemHeading>
                            <AccordionItemButton>
                              Advance of Medical Transportation Services
                            </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                            <p>
                              We pay upfront for emergency transportation
                              services if they are covered under the CAP™
                              customer’s insurance policy. If a service is not
                              included, we will first contact the CAP™ customer
                              for authorization and written approval prior to
                              making a payment on their behalf.
                            </p>
                          </AccordionItemPanel>
                        </AccordionItem>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table>
                  <tbody>
                    <tr className="border">
                      <td>
                        <AccordionItem>
                          <AccordionItemHeading>
                            <AccordionItemButton>
                              Medical and Dental Referrals and Prescription
                              Assistance
                            </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                            <p>
                              We provide CAP™ customers with contact information
                              for licensed physicians, dentists, hospitals, and
                              clinics in the area and will confirm the
                              provider’s availability, upon request.
                            </p>
                          </AccordionItemPanel>
                        </AccordionItem>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Accordion>
            </AccordionItemPanel>
          </AccordionItem>
        </td>
      </tr>
    );
  }
}
