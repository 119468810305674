import Axios from "../../services/axios-service";
const notifyUser = data => {
    return Axios.post("/Notification/push", data, undefined);
};
const getNotifications = data => {
    return Axios.get("/api/NotificationMessages?search="+encodeURIComponent(JSON.stringify(data)), undefined, undefined);
};
const updateNotificationStatus = data => {
    return Axios.put("/api/NotificationMessages", data, undefined);
};
const getNotificationsCount = id => {
    return Axios.get("/api/TravelerBasicInfo/"+id, undefined, undefined);
};
const registerFCMDevice = (userId, args) => {
    return Axios.post("/api/Device/add/"+userId, args, undefined);
};
export default {
    notifyUser,
    getNotifications,
    updateNotificationStatus,
    getNotificationsCount,
    registerFCMDevice
};
