import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import IntlMessages from "../../../../services/intlMesseges";
import {
  Form,
  Select,
  Button,
  Row,
  Col,
  DatePicker,
  Collapse,
  TreeSelect
} from "antd";
import moment from "moment";
import * as UserService from "../../../../services/user-service";
import * as OrgActions from "../../../../redux/actions/organization-actions";
import * as Permission from "../../../../services/permissions";
import * as itiniaryActions from "../../../../redux/actions/itinerary-actions";
import * as assistTravelerActions from "../../../../redux/actions/assist-traveler-actions";

const { Panel } = Collapse;
const dateFormat = "MM/DD/YYYY";
const dbDateFormat = "YYYY-MM-DD";
const { Option } = Select;

class AssistTravelFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUserOrgId: 0,
      parentOrgCurrentPage: 1,
      selectedOrgId: 0,
      currentSelectedParentOrg: 0,
      parentOrganizations: [],
      parentOrganizationId: { key: "all", label: "All" },
      childOrganizationId: "",
      startValue: null
    };
    this.onResetTravelerFilter = this.onResetTravelerFilter.bind(this);
  }

  disabledEndDate = endValue => {
    const { startValue } = this.state;
    if (!endValue || !startValue) {
      return false;
    }
    return endValue.valueOf() <= startValue.valueOf();
  };

  onChange = (field, value) => {
    this.setState({
      [field]: value
    });
  };
  onStartChange = value => {
    this.onChange("startValue", value);
  };

  componentDidMount() {
    let currentUser = UserService.getUser();
    let orgId = currentUser.companyID;

    if (Permission.isRole("Administrator") || Permission.isRole("CSR")) {
      this.loadParentOrganizations();
    } else {
      let list = [];
      this.props.getDivisionsList(orgId).then(resp => {
        let parentId;
        for (var i in resp.data) {
          if (resp.data[i].organizationId == orgId) {
            parentId = resp.data[i].parentOrgId;
          }
        }
        list = this.getNestedChildren(resp.data, parentId);
        this.setState({ currentSelectedParentOrg: orgId });
        this.setState({
          childOrgs: list
        });
      });
    }
    if (Object.keys(this.props.savedState).length !== 0) {
      if (
        this.props.savedState.childOrgId != "" &&
        this.props.savedState.childOrgId != undefined
      ) {
        this.setState({
          childOrganizationId: {
            key: this.props.savedState.childOrgId.value,
            label: this.props.savedState.childOrgId.label
          },
          currentSelectedParentOrg: this.props.savedState.parentOrgId.key
        });
      }
    }

    this.setState({ currentUserOrgId: orgId });
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        let filters = {};

        if (
          values.parentOrgId !== undefined &&
          values.parentOrgId.key !== "all"
        ) {
          filters.orgId = values.parentOrgId.key;
        }
        if (values.childOrgId !== undefined && values.childOrgId !== "") {
          filters.orgId = values.childOrgId.value;
        }
        if (values.startDate !== "" && values.endDate !== "") {
          filters.startDate = moment(values.startDate).format(dbDateFormat);
          filters.endDate = moment(values.endDate).format(dbDateFormat);
          values.startDate = moment(values.startDate).format(dateFormat);
          values.endDate = moment(values.endDate).format(dateFormat);
        }
        if (
          values.assistStatus !== undefined &&
          values.assistStatus !== "all"
        ) {
          filters.assistStatus = values.assistStatus;
        }

        this.getAssistTravelersByFilters(filters);
        this.props.handleAssistStateSave(values);
      }
    });
  };

  getAssistTravelersByFilters(filter) {
    this.props
      .getAssistTravelersList({
        filters: filter ? filter : {},
        pagination: { page: 1, pageSize: 5 },
        sorter: {}
      })
      .then(resp => {
        this.props.handleTableChange(resp.data);
      })
      .catch(ex => {
        this.setState({ loading: false });
      });
  }

  onResetTravelerFilter() {
    this.props.form.resetFields();
    this.setState({ childOrganizationId: "" });
    if (Permission.isRole("Administrator") || Permission.isRole("CSR")) {
      this.setState({ currentSelectedParentOrg: 0 });
    }
    this.setState({
      parentOrganizationId: "all",
      startValue: null,
      childId: ""
    });
    this.props.handleAssistStateSave();
    this.getAssistTravelersByFilters();
  }

  loadParentOrganizations = () => {
    this.props
      .getOrganizations({
        filters: {},
        pagination: { page: 1, pageSize: 10 },
        sorter: {}
      })
      .then(resp => {
        this.setState({
          parentOrganizations: resp.data
        });
      });
  };

  getNestedChildren = (arr, parent) => {
    var out = [];
    for (var i in arr) {
      if (arr[i].parentOrgId == parent) {
        var children = this.getNestedChildren(arr, arr[i].organizationId);
        if (children.length) {
          arr[i].children = children;
        }
        arr[i].title = arr[i].name;
        arr[i].value = arr[i].organizationId;
        arr[i].label = arr[i].name;
        arr[i].key = arr[i].organizationId;
        out.push(arr[i]);
      }
    }
    return out;
  };

  onChangeParentOrganizationTree = selected => {
    console.log(selected);
    this.setState({ parentOrganizationId: selected });
    this.setState({ childId: "" });
    this.props.form.setFieldsValue({ childOrgId: "" });
    let list = [];
    this.props.getDivisionsList(selected.key).then(resp => {
      let parentId;
      for (var i in resp.data) {
        if (resp.data[i].organizationId == selected) {
          parentId = resp.data[i].parentOrgId;
        }
      }
      list = this.getNestedChildren(resp.data, parentId);
      this.setState({
        childOrgs: list
      });
    });
    this.loadParentOrganizations();
    this.setState({ currentSelectedParentOrg: selected });
    this.props.form.setFieldsValue({ childOrgs: list });
  };

  onChangeChildOrganizationTree = (value, label) => {
    this.setState({ onChangeChildOrganizationValue: value });
  };
  fetchParentOrganizations = e => {
    if (e.target.scrollTop + e.target.offsetHeight >= e.target.scrollHeight) {
      var datas = this.state.parentOrgCurrentPage + 1;
      this.props
        .getOrganizations({
          filters: {},
          pagination: { page: datas, pageSize: 10 },
          sorter: {}
        })
        .then(resp => {
          this.setState({ parentOrgCurrentPage: datas });
          let data = this.state.parentOrganizations;
          this.setState({
            parentOrganizations: data.concat(resp.data)
          });
        })
        .catch(ex => {
          this.setState({ loading: false });
        });
    }
  };

  searchParentOrganizations = async value => {
    let args = {
      filters: { companyName: value },
      pagination: { page: 1, pageSize: 10 },
      sorter: {}
    };
    this.props
      .getOrganizations(args)
      .then(resp => {
        this.setState({
          parentOrganizations: resp.data
        });
      })
      .catch(_ex => {
        this.setState({ loading: false });
      });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 24 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 }
      }
    };

    return (
      <div>
        <Row gutter={24}>
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <h5 style={{ border: "none" }}>Filters</h5>
          </Col>
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            style={{ textAlign: "right" }}
          >
            <Button
              type="primary"
              onClick={this.onResetTravelerFilter}
              style={{ height: "30px", lineHeight: "30px" }}
            >
              Reset All
            </Button>
          </Col>
        </Row>
        <Form
          {...formItemLayout}
          onSubmit={this.handleSubmit}
          layout="Vertical"
          id="travelerform"
          style={{
            maxHeight: "349px",
            overflowX: "hidden",
            overflowY: "auto"
          }}
        >
          <Row gutter={24}>
            <Collapse accordion>
              <Panel header="By Date Range" key="1">
                <Col>
                  <Form.Item
                    label="Start Date:"
                    labelAlign="left"
                    style={{ margin: "0" }}
                  >
                    {getFieldDecorator("startDate", {
                      initialValue: this.props.savedState.startDate
                        ? moment(this.props.savedState.startDate)
                        : moment()
                    })(
                      <DatePicker
                        format={dateFormat}
                        onChange={this.onStartChange}
                      />
                    )}
                  </Form.Item>
                  <Form.Item
                    label="End Date:"
                    labelAlign="left"
                    style={{ margin: "0" }}
                  >
                    {getFieldDecorator("endDate", {
                      initialValue: this.props.savedState.endDate
                        ? moment(this.props.savedState.endDate)
                        : moment()
                    })(
                      <DatePicker
                        format={dateFormat}
                        disabledDate={this.disabledEndDate}
                      />
                    )}
                  </Form.Item>

                  <Form.Item>
                    <Button type="primary" htmlType="submit">
                      Apply
                    </Button>
                  </Form.Item>
                </Col>
              </Panel>
              <Panel header="By Organization" key="2">
                <Row gutter={24}>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <IntlMessages id="admin.division.parentCompany" />
                    <Form.Item>
                      {(Permission.isRole("Administrator") ||
                        Permission.isRole("CSR")) &&
                        getFieldDecorator("parentOrgId", {
                          initialValue: this.props.savedState.parentOrgId
                            ? this.props.savedState.parentOrgId
                            : this.state.parentOrganizationId
                        })(
                          <Select
                            style={{ width: "100%" }}
                            onPopupScroll={this.fetchParentOrganizations}
                            onSearch={this.searchParentOrganizations}
                            onChange={this.onChangeParentOrganizationTree}
                            filterOption={false}
                            // value={this.state.parentOrganizationId}
                            labelInValue={true}
                            showSearch
                          >
                            <Option key="all">All</Option>
                            {this.state.parentOrganizations
                              ? this.state.parentOrganizations.map(function(
                                  item
                                ) {
                                  return (
                                    <Option key={item.organizationId}>
                                      {item.name}
                                    </Option>
                                  );
                                })
                              : ""}
                          </Select>
                        )}
                    </Form.Item>
                  </Col>
                </Row>

                {this.state.currentSelectedParentOrg !== 0 ? (
                  <Form.Item>
                    <Row gutter={24}>
                      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <IntlMessages id="admin.organization.company" />
                        {getFieldDecorator("childOrgId", {
                          initialValue: this.state.childOrganizationId
                        })(
                          <TreeSelect
                            style={{ width: "100%" }}
                            dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                            treeData={this.state.childOrgs}
                            onChange={this.onChangeChildOrganizationTree}
                            //value={this.state.childOrganizationId}
                            labelInValue={true}
                            treeDefaultExpandAll={true}
                          />
                        )}
                      </Col>
                    </Row>
                  </Form.Item>
                ) : (
                  ""
                )}
                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    Apply
                  </Button>
                </Form.Item>
              </Panel>

              <Panel header="By Status" key="3">
                <Row gutter={24}>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Form.Item>
                      {getFieldDecorator("assistStatus", {
                        initialValue: this.props.savedState.assistStatus
                      })(
                        <Select
                          style={{ width: 200 }}
                          placeholder="Select Status"
                        >
                          <Option value="all">All</Option>
                          <Option value={0}>Pending</Option>
                          <Option value={1}>Assisting</Option>
                          <Option value={2}>Resolved</Option>
                        </Select>
                      )}
                    </Form.Item>
                    <Form.Item>
                      <Button type="primary" htmlType="submit">
                        Apply
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Panel>
            </Collapse>
          </Row>
        </Form>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { ...OrgActions, ...itiniaryActions, ...assistTravelerActions },
    dispatch
  );
}
const WrappedForm = Form.create({ name: "assistfilter" })(AssistTravelFilter);

export default withRouter(
  connect(null, mapDispatchToProps, null, { forwardRef: true })(WrappedForm)
);
