import React, { Component } from "react";
import { Tabs, Typography, Row, Col, DatePicker, Button } from "antd";
import IntlMessages from "../../../services/intlMesseges";
import ReportsAPI from "../../../redux/api/reports-api";
import * as UserServices from "../../../services/user-service";
import axios from "axios";
import moment from "moment";
const { TabPane } = Tabs;
const dateFormatAPI = "YYYY-MM-DD";
const dateFormatFilename = "MM-DD-YYYY";
const dateFormat = "MM/DD/YYYY";
const monthFormat = "MM/DD/YYYY";
let now = new Date();
let startDay = moment(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0));
let endDay = moment(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59, 0));
let startMonth = moment(new Date(now.getFullYear(), now.getMonth(), 1, 0, 0, 0, 0));
let endMonth = moment(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59, 0));
class NAVReportB2C extends Component {
	constructor(props){
		super(props);
		this.state = {
			loading: true,
			defaultActiveKey: "daily",
			dailyReportDateSelected: true,
			dailyReportDates: [startDay, endDay],
			monthlyReportDateSelected: true,
			monthlyReportDates: [startMonth, endMonth]
		};
	}
		
  async componentDidMount() {
    if (window.location.hash != "") {
      this.setState({
        defaultActiveKey: window.location.hash.replace("#", "")
      });
    }
  }

  switchNAVTabs = current => {
    if (window.history.pushState) {
      window.history.pushState(null, null, "#" + current);
    } else {
      this.props.location.hash = "#" + current;
    }
    this.setState({ defaultActiveKey: current });
	};
	
	selectDay = (dates) => {
		if(dates.length > 0){
			this.setState({
				dailyReportDates: dates,
				dailyReportDateSelected: true
			});
		} else {
			this.setState({
				dailyReportDates: [startDay, endDay],
				dailyReportDateSelected: false
			});
		}
	}
	
	selectMonth = (dates) => {
		if(dates.length > 0){
			this.setState({
				monthlyReportDates: dates,
				monthlyReportDateSelected: true
			});
		} else {
			this.setState({
				monthlyReportDate: [startMonth, endMonth],
				monthlyReportDateSelected: false
			});
		}
	}
	
	downloadDailyReport = () => {
		let token = UserServices.getToken();
		let _headers = {'Content-Type': 'multipart/form-data'};
		if (token) _headers['authorization'] = token ? 'Bearer ' + token : undefined;
		let filename = 'B2C daily report ('+moment(this.state.dailyReportDates[0]).format(dateFormatFilename)+' to '+moment(this.state.dailyReportDates[1]).format(dateFormatFilename)+').csv';
		let apiUrl = ReportsAPI.getB2CDailyReport(moment(this.state.dailyReportDates[0]).format(dateFormatAPI), moment(this.state.dailyReportDates[1]).format(dateFormatAPI));
		axios({
			url: apiUrl,
			method: 'GET',
			responseType: 'blob',
			headers: _headers
		}).then((response) => {
			 const url = window.URL.createObjectURL(new Blob([response.data]));
			 const link = document.createElement('a');
			 link.href = url;
			 link.setAttribute('download', filename);
			 document.body.appendChild(link);
			 link.click();
		});
	}
	
	downloadMonthlyReport = () => {
		let token = UserServices.getToken();
		let _headers = {'Content-Type': 'multipart/form-data'};
		if (token) _headers['authorization'] = token ? 'Bearer ' + token : undefined;
		let filename = 'B2C monthly report ('+moment(this.state.monthlyReportDates[0]).format(dateFormatFilename)+' to '+moment(this.state.monthlyReportDates[1]).format(dateFormatFilename)+').csv';
		let apiUrl = ReportsAPI.getB2CMonthlyReport(moment(this.state.monthlyReportDates[0]).format(dateFormatAPI), moment(this.state.monthlyReportDates[1]).format(dateFormatAPI));
		axios({
			url: apiUrl,
			method: 'GET',
			responseType: 'blob',
			headers: _headers
		}).then((response) => {
			 const url = window.URL.createObjectURL(new Blob([response.data]));
			 const link = document.createElement('a');
			 link.href = url;
			 link.setAttribute('download', filename);
			 document.body.appendChild(link);
			 link.click();
		});
	}

  render() {
    return (
      <div>
        <Row gutter={24}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Typography.Title level={4}>
              <IntlMessages id="admin.nav-reports.heading" /> - B2C
            </Typography.Title>
          </Col>
        </Row>
        <hr />
        <Tabs
          defaultActiveKey={this.state.defaultActiveKey}
          activeKey={this.state.defaultActiveKey}
          size="large"
          onChange={this.switchNAVTabs}
        >
					<TabPane tab={<strong><IntlMessages id="admin.nav-reports.daily" /></strong>} key="daily">
						<Row gutter={24}>
							<Col xs={24} sm={24}	md={8} lg={8} xl={8} style={{ overflowX: "auto" }}>
								<DatePicker.RangePicker
									format={dateFormat} 
									onChange={this.selectDay}
									defaultValue={this.state.dailyReportDates}
									ranges={{
                    Today: [moment(), moment()],
                    Yesterday: [
                      moment().add("-1", "days"),
                      moment().add("-1", "days")
                    ],
                    "This Week": [
                      moment().startOf("isoWeek"),
                      moment().endOf("isoWeek")
                    ],
                    "Last Week": [
                      moment()
                        .add("-1", "week")
                        .startOf("isoWeek"),
                      moment()
                        .add("-1", "week")
                        .endOf("isoWeek")
                    ]
                  }}
								/>
							</Col>
						</Row>
						<Row gutter={24} style={{ paddingTop: "20px"}}>
							<Col xs={24} sm={24}	md={8} lg={8} xl={8} style={{ overflowX: "auto" }}>
								<Button htmlType="button" type="primary" onClick={this.downloadDailyReport} disabled={!this.state.dailyReportDateSelected}>
									<IntlMessages id="admin.nav-reports.download" />
								</Button>
							</Col>
						</Row>
					</TabPane>
					
          <TabPane tab={<strong><IntlMessages id="admin.nav-reports.monthly" /></strong>}	key="monthly">
            <Row gutter={24}>
							<Col xs={24} sm={24}	md={8} lg={8} xl={8} style={{ overflowX: "auto" }}>
								<DatePicker.RangePicker 
									format={monthFormat} 
									onChange={this.selectMonth}
									defaultValue={this.state.monthlyReportDates}
									ranges={{
                    "This Month": [
                      moment().startOf("month"),
                      moment().endOf("month")
                    ],
                    "Last Month": [
                      moment()
                        .add("-1", "month")
                        .startOf("month"),
                      moment()
                        .add("-1", "month")
                        .endOf("month")
                    ]
									}}
								/>
              </Col>
            </Row>
						<Row gutter={24} style={{ paddingTop: "20px"}}>
							<Col xs={24} sm={24}	md={8} lg={8} xl={8} style={{ overflowX: "auto" }}>
								<Button htmlType="button" type="primary" onClick={this.downloadMonthlyReport} disabled={!this.state.monthlyReportDateSelected}>
									<IntlMessages id="admin.nav-reports.download" />
								</Button>
							</Col>
						</Row>
          </TabPane>
        </Tabs>
      </div>
    );
  }
}
export default NAVReportB2C;
