import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Table, Button, Icon, Card, Row, Spin } from "antd";
import { TravelersSvg } from "../../../shared/svg/svg/home-icon";
import * as userActions from "../../../../redux/actions/user-actions";
import * as UserService from "../../../../services/user-service";
import IntlMessages from "../../../../services/intlMesseges";

const TravelersSvgIcon = props => <Icon component={TravelersSvg} {...props} />;

class TravellerDashListTravelers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      userData: [],
      userOrgId: 0
    };
  }

  getHeaderKeys = () => {
    return [
      {
        title: "Email",
        dataIndex: "userName"
      },
      {
        title: "",
        rowKey: "action",
        render: (text, record) => (
          <span>
            <Button
              className="btn-outline"
              onClick={() =>
                this.props.history.push(
                  "organizations/edit/" +
                    record.companyId +
                    "/employees/" +
                    record.userId
                )
              }
            >
              More Info
            </Button>
          </span>
        )
      }
    ];
  };

  async componentDidMount() {
    this.setState({ loading: true });
    let currentUser = UserService.getUser();
    if (currentUser) {
      this.props
        .getUserListing({
          orgId: currentUser.companyID,
          filters: {},
          pagination: { page: 1, pageSize: 5 },
          sorter: {}
        })
        .then(resp => {
          this.setState({
            userData: resp.data,
            loading: false,
            userOrgId: currentUser.companyID
          });
        })
        .catch(ex => {
          this.setState({ loading: false });
        });
    }
  }

  render() {
    return (
      <div>
        <Spin size="large" spinning={this.state.loading}>
          <Card
            title="List of Employees"
            bodyStyle={{ padding: 0 }}
            extra={<TravelersSvgIcon />}
          >
            {this.state.userData && (
              <div>
                <Table
                  pagination={false}
                  columns={this.getHeaderKeys()}
                  dataSource={this.state.userData}
                />
                <Row type="flex" justify="end">
                  <Button
                    type="primary"
                    className="addBtn"
                    size={"large"}
                    style={{ margin: " 24px 24px 24px 0px" }}
                    onClick={() =>
                      this.props.history.push(
                        "organizations/edit/" +
                          this.state.userOrgId +
                          "/employees/"
                      )
                    }
                  >
                    <IntlMessages id="admin.listcompanies.viewall" />
                  </Button>
                </Row>
              </div>
            )}
          </Card>
        </Spin>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...userActions }, dispatch);
}
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    TravellerDashListTravelers
  )
);
