import React from "react";
import {
  Icon
} from "antd";
import {
  HomeSvg,
  ManageUser,
  ManageCompany,
  Travevelers,
  Products,
  Pricing,
  Orders,
  AccountsBilling,
  TrackingTravelers,
  Reports,
  Settings,
  Countries,
  Email,
  Mobile,
  Reporting,
  Overview,
  // Alerts,
  Flight,
  Booking,
  Vip,
  Traveler,
  Hotels,
  // Risklevel,
  // Geofencing,
  Orderhistory,
  Uploaditinerary,
  Buynow,
  CustomAlert,
  NAVSvg,
  B2B2CSvg
} from "../shared/svg/svg/home-icon";
import AlertsCount from "../user-dash/alerts-count/index.jsx";
import * as UserService from "../../services/user-service";

const HomeIcon = props => < Icon component={
  HomeSvg
} {
  ...props
  }
/>;
const ManageUserIcon = props => < Icon component={
  ManageUser
} {
  ...props
  }
/>;
const ManageCompanyIcon = props => (<
  Icon component={
    ManageCompany
  } {
  ...props
  }
/>
);
const TravevelersIcon = props => < Icon component={
  Travevelers
} {
  ...props
  }
/>;
const ProductsIcon = props => < Icon component={
  Products
} {
  ...props
  }
/>;
const PricingIcon = props => < Icon component={
  Pricing
} {
  ...props
  }
/>;
const OrdersIcon = props => < Icon component={
  Orders
} {
  ...props
  }
/>;
const AccountsBillingIcon = props => (<
  Icon component={
    AccountsBilling
  } {
  ...props
  }
/>
);

const TrackingTravelersIcon = props => (<
  Icon component={
    TrackingTravelers
  } {
  ...props
  }
/>
);
const ReportsIcon = props => < Icon component={
  Reports
} {
  ...props
  }
/>;
const SettingsIcon = props => < Icon component={
  Settings
} {
  ...props
  }
/>;
const CountriesIcon = props => < Icon component={
  Countries
} {
  ...props
  }
/>;
const EmailIcon = props => < Icon component={
  Email
} {
  ...props
  }
/>;
const MobileIcon = props => < Icon component={
  Mobile
} {
  ...props
  }
/>;
const ReportingIcon = props => < Icon component={
  Reporting
} {
  ...props
  }
/>;
const OverviewIcon = props => < Icon component={
  Overview
} {
  ...props
  }
/>;
// const AlertsIcon = props => < Icon component = {
//   Alerts
// } {
//   ...props
// }
// />;
const FlightIcon = props => < Icon component={
  Flight
} {
  ...props
  }
/>;
const BookingIcon = props => < Icon component={
  Booking
} {
  ...props
  }
/>;
const VipIcon = props => < Icon component={
  Vip
} {
  ...props
  }
/>;
const TravelerIcon = props => < Icon component={
  Traveler
} {
  ...props
  }
/>;
const HotelsIcon = props => < Icon component={
  Hotels
} {
  ...props
  }
/>;
// const BranchesOutlined = props => < Icon component={
//   BranchesOutlined
// } {
//   ...props
//   }
// />;
// const RisklevelIcon = props => < Icon component = {
//   Risklevel
// } {
//   ...props
// }
// />;
// const GeofencingIcon = props => < Icon component = {
//   Geofencing
// } {
//   ...props
// }
// />;
const OrderhistoryIcon = props => < Icon component={
  Orderhistory
} {
  ...props
  }
/>;
const UploaditineraryIcon = props => < Icon component={
  Uploaditinerary
} {
  ...props
  }
/>;
const BuynowIcon = props => < Icon component={
  Buynow
} {
  ...props
  }
/>;
const CustomAlertIcon = props => < Icon component={
  CustomAlert
} {
  ...props
  }
/>;
const NAVIcon = props => < Icon component={ NAVSvg } {...props}/>;
const B2B2CIcon = props => < Icon component={ B2B2CSvg } {...props}/>;

const adminOptions = [{
  key: "/admin/",
  label: "admin.sidebar.Dashboard",
  roles: ["Administrator", "SuperAdmin", "CSR"],
  leftIcon: < HomeIcon />
},
{
  key: "/admin/users/",
  //label: "admin.sidebar.manageUserRole",
  label: "admin.sidebar.submenu.ManageUser",
  leftIcon: < ManageUserIcon />,
  roles: ["Administrator", "SuperAdmin"],
  /* children: [{
      key: "/admin/users/",
      label: "admin.sidebar.submenu.ManageUser",
      roles: ["Administrator", "SuperAdmin"]
    },
    {
      key: "/admin/roles/",
      label: "admin.sidebar.submenu.ManageRole",
      roles: ["Administrator", "SuperAdmin"]
    },

  ] */
},
{
  key: "/admin/organizations/",
  label: "admin.sidebar.submenu.ManageCompany",
  leftIcon: < ManageCompanyIcon />,
  roles: ["Administrator", "SuperAdmin", "CSR"],
  /* children: [{
      key: "/admin/organizations/",
      label: "admin.sidebar.submenu.organizations",
      roles: ["Administrator", "SuperAdmin", "CSR"]
    },
    {
      key: "/admin/organization-types/",
      label: "admin.sidebar.submenu.organizationTypes",
      roles: ["Administrator", "SuperAdmin", "CSR"]
    }
  ] */
},
{
  key: "/admin/b2b2c-organizations/",
  label: "admin.sidebar.submenu.ManageB2B2COrg",
  leftIcon: < B2B2CIcon />,
  roles: ["Administrator", "SuperAdmin", "CSR"]
},
{
  key: "/admin/orders/",
  label: "admin.sidebar.submenu.Orders",
  roles: ["Administrator", "SuperAdmin", "CSR"],
  leftIcon: < OrdersIcon />
},
{
  key: "/admin/travelers/",
  label: "admin.sidebar.submenu.Travelers",
  roles: ["Administrator", "SuperAdmin", "CSR"],
  leftIcon: < TravevelersIcon />
},
{
  key: "/admin/manage-itinerary/list/1/1/",
  label: "user.sidebar.manageitinerary",
  roles: ["Administrator", "SuperAdmin", "CSR", "Division Manager", "Travel Manager"],

  leftIcon: < UploaditineraryIcon />,
},

{
  key: "/admin/products/",
  label: "admin.sidebar.submenu.Products",
  roles: ["Administrator", "SuperAdmin","CSR"],
  leftIcon: < ProductsIcon />
},
{
  key: "/admin/pricing/",
  label: "admin.sidebar.submenu.Pricing",
  roles: ["Administrator", "SuperAdmin", "CSR"],
  leftIcon: < PricingIcon />,
  children: [{
    key: "/admin/pricing/",
    label: "admin.sidebar.submenu.managePricing",
    roles: ["Administrator", "SuperAdmin", "CSR"]
  },
  {
    key: "/admin/promo-codes/",
    label: "admin.sidebar.submenu.managePromo",
    roles: ["Administrator", "SuperAdmin", "CSR"]
  }, {
    key: "/admin/tiers/",
    label: "admin.tiers.manageTiers",
    roles: ["Administrator", "SuperAdmin", "CSR"]
  },
  {
    key: "/admin/day-packages/",
    label: "admin.tiers.manageDayPackages",
    roles: ["Administrator", "SuperAdmin", "CSR"]
  }, {
    key: "/admin/tier-countries/",
    label: "admin.countries.manageCountries",
    roles: ["Administrator", "SuperAdmin", "CSR"]
  }, {
    key: "/admin/tier-states/",
    label: "admin.managestate.manageStates",
    roles: ["Administrator", "SuperAdmin", "CSR"]
  }
  ]
},
{
  key: "/admin/accounts-billing/",
  label: "admin.sidebar.submenu.AccountsBilling",
  roles: ["Administrator", "SuperAdmin","CSR"],
  leftIcon: < AccountsBillingIcon />
    },
// {
//     key: "/admin/covid",
//     label: "user.sidebar.covid",
//     leftIcon: < CountriesIcon />,
//     roles: ["Administrator", "SuperAdmin", "CSR", "Division Manager", "Travel Manager"]
// },
{
  key: "/admin/track/",
  label: "admin.sidebar.submenu.TrackingTravelers",
  roles: ["Administrator", "SuperAdmin", "CSR", "Division Manager", "Travel Manager"],
  leftIcon: < TrackingTravelersIcon />,
  children: [{
    key: "/admin/countries/",
    label: "admin.sidebar.submenu.Countries",
    roles: ["Administrator", "SuperAdmin", "CSR"]
  },
  {
    key: "/admin/geofencing/find/org/geofence-organization/",
    label: "admin.sidebar.submenu.GeoFencing",
    roles: ["Administrator", "SuperAdmin", "CSR"]
  },
  {
    key: "/admin/asset-tracking/find/org/asset-organization/",
    label: "admin.sidebar.submenu.AssetTracking",
    roles: ["Administrator", "SuperAdmin", "CSR"]
  }
  ]
},
/* {
  key: "/admin/customize-emails/",
  label: "admin.sidebar.submenu.customizeEmails",
  roles: ["Administrator", "SuperAdmin", "CSR",""],
  leftIcon: < EmailIcon />
}, */
{
  key: "/admin/custom-alert/",
  label: "admin.sidebar.submenu.customAlert",
  roles: ["Administrator", "SuperAdmin", "CSR",""],
  leftIcon: < CustomAlertIcon />
},
{
  key: "/admin/reports/",
  label: "admin.sidebar.submenu.Reports",
  roles: ["Administrator", "SuperAdmin", "CSR","Admin","Travel Manager","Division Manager"],
  leftIcon: < ReportsIcon />,
  children: [{
    key: "/admin/reports/",
    label: "General",
    roles: ["Administrator", "SuperAdmin", "CSR","Admin","Travel Manager","Division Manager"]
  },{
    key: "/admin/power-bi/",
    label: "Power BI",
    roles: ["Administrator", "SuperAdmin", "CSR"]
  }]
},
{
  key: "/admin/nav-report/",
  label: "admin.sidebar.submenu.navReport",
  roles: ["Administrator", "SuperAdmin", "CSR"],
  leftIcon: <NAVIcon/>,
  children: [{
    key: "/admin/nav-report/B2B/",
    label: "B2B",
    roles: ["Administrator", "SuperAdmin", "CSR"]
  },{
    key: "/admin/nav-report/B2C/",
    label: "B2C",
    roles: ["Administrator", "SuperAdmin", "CSR"]
  }]
}
];

const userOptions = [{
  key: "/user/",
  label: "user.sidebar.dashboard",
  leftIcon: < HomeIcon />,
  roles: ["Admin", "Membership", "Travel Manager", "Division Manager", "Employee", "Limited Access"]
},
{
  key: "/user/organizations/",
  label: "admin.sidebar.submenu.ManageCompany",
  leftIcon: < ManageCompanyIcon />,
  roles: ["Admin", "Travel Manager"],
},
{
  key: "/user/accounts-billing/find/invoices/",
  label: "admin.sidebar.submenu.AccountsBilling",
  roles: ["Admin"],
  leftIcon: < AccountsBillingIcon />
},
{
  key: "/user/organizations/find/division/",
  label: "admin.sidebar.submenu.ManageCompany",
  leftIcon: < ManageCompanyIcon />,
  roles: ["Division Manager"]
},
/* {
  key: "/user/orders/",
  label: "admin.sidebar.submenu.Orders",
  roles: ["Travel Manager"],
  leftIcon: < OrdersIcon />
}, */
{
  key: "/user/countries/",
  label: "user.sidebar.allCountryInfo",
  leftIcon: < TrackingTravelersIcon />,
  roles: ["Membership", "Employee", "Limited Access"]
},
// {
//     key: "/user/covid",
//     label: "user.sidebar.covid",
//     leftIcon: < CountriesIcon />,
//     roles: ["Admin", "Membership", "Travel Manager", "Division Manager", "Employee", "Limited Access"]
// },
{
  key: "/user/track/",
  label: "admin.sidebar.submenu.TrackingTravelers",
  roles: ["Administrator", "SuperAdmin", "CSR", "Division Manager", "Admin", "Travel Manager"],
  leftIcon: < TrackingTravelersIcon />,
  children: [{
    key: "/user/countries/",
    label: "admin.sidebar.submenu.Countries",
    roles: ["Administrator", "Division Manager", "Admin", "Travel Manager"]
  },
  {
    key: "/user/geofencing/find/org/geofence-organization/",
    label: "admin.sidebar.submenu.GeoFencing",
    roles: ["Administrator", "Division Manager", "Admin", "Travel Manager"]
  },
  {
    key: "/user/asset-tracking/find/org/asset-organization/",
    label: "admin.sidebar.submenu.AssetTracking",
    roles: ["Administrator", "Division Manager", "Admin", "Travel Manager"]
  }
  ]
},
/* {
  key: "/user/settings/",
  label: "user.sidebar.settings",
  leftIcon: < SettingsIcon />,
  roles: ["Admin", "Travel Manager", "Division Manager"]
}, */
/* {
  key: "/user/emailNotifications/",
  label: "user.sidebar.emailNotificaion",
  leftIcon: < EmailIcon />,
  roles: ["Division Manager"]
}, */
{
  key: "/user/mobileAppSetup/",
  label: "user.sidebar.mobileAppSetup",
  leftIcon: < MobileIcon />,
  roles: []
},
{
  key: "/user/reporting/",
  label: "user.sidebar.reportingOptions",
  leftIcon: < ReportingIcon />,
  roles: []
},
{
  key: "/user/overview/",
  label: "user.sidebar.overview",
  leftIcon: < OverviewIcon />,
  roles: [],
  children: [

    {
      key: "/bookings/",
      label: "user.sidebar.bookings",
      leftIcon: < BookingIcon />,
      alertsCount: < AlertsCount type="bookings" />,
      roles: ["Membership", "Admin", "Travel Manager", "Division Manager","Employee"]
    },
    {
      key: "/flights/",
      label: "user.sidebar.flights",
      leftIcon: < FlightIcon />,
      alertsCount: < AlertsCount type="flights" />,
      roles: ["Membership", "Admin", "Travel Manager", "Division Manager","Employee"]
    },

    {
      key: "/travelers/",
      leftIcon: < TravelerIcon />,
      label: "user.sidebar.travelers",
      alertsCount: < AlertsCount type="travelers" />,
      roles: ["Membership", "Admin", "Travel Manager", "Division Manager","Employee"]
    },
    {
      key: "/vip/",
      leftIcon: < VipIcon />,
      label: "user.sidebar.vip",
      alertsCount: < AlertsCount type="vip" />,
      roles: ["Membership", "Admin", "Travel Manager", "Division Manager","Employee"]
    },
    {
      key: "/hotels/",
      leftIcon: < HotelsIcon />,
      label: "user.sidebar.hotels",
      alertsCount: < AlertsCount type="hotels" />,
      roles: ["Membership", "Admin", "Travel Manager", "Division Manager","Employee"]
    }
  ]
},
{
  key: "/user/orders/",
  label: "user.sidebar.orderhistory",
  leftIcon: < OrderhistoryIcon />,
  roles: ["Membership","NotForGuard"]
},
{
  key: "/user/manage-itinerary/list/1/1/",
  label: "user.sidebar.manageitinerary",
  leftIcon: < UploaditineraryIcon />,
  roles: ["Membership","Employee", "Limited Access"]
},
{
  key: "/user/custom-alert/",
  label: "admin.sidebar.submenu.customAlert",
  roles: ["Travel Manager", "Division Manager","Admin"],
  leftIcon: < CustomAlertIcon />
},
{
  key: "/user/purchase/newplan",
  label: "user.sidebar.buynow",
  leftIcon: < BuynowIcon />,
  roles: ["Membership","NotForGuard"]
},
{
  key: "/user/reports/",
  label: "admin.sidebar.submenu.Reports",
  roles: ["Administrator", "SuperAdmin", "CSR","Admin","Travel Manager","Division Manager"],
  leftIcon: < ReportsIcon />,
},
{
  key: "/user/my-profile",
  label: "admin.profile.myprofile",
  leftIcon: <ManageUserIcon />,
  roles: ["Membership"],
},
{
  key: "/user/my-profile/change-password/",
  label: "admin.profile.changepassword",
  leftIcon: <SettingsIcon />,
  roles: ["Membership"],
},
];

const resellerOptions = [
  {
    key: "/reseller/",
    label: "admin.sidebar.Dashboard",
    leftIcon: <HomeIcon />,
    roles: ["Administrator", "Admin", "Membership", "Travel Manager", "Division Manager", "Employee", "Partner"]
  },
  {
    key: "/reseller/manage-organization/",
    label: "admin.sidebar.manageOrg",
    leftIcon: <B2B2CIcon />,
    roles: ["Administrator", "Admin", "Membership", "Travel Manager", "Division Manager", "Employee", "Partner"]
  },
  {
    key: "/reseller/manage-orders/",
    label: "admin.manage.orders",
    leftIcon: <OrderhistoryIcon />,
    roles: ["Administrator", "Admin", "Membership", "Travel Manager", "Division Manager", "Employee", "Partner"]
  },
  {
    key: "/reseller/manage-travelers/",
    label: "admin.sidebar.manageTravelers",
    leftIcon: <TravevelersIcon />,
    roles: ["Administrator", "Admin", "Membership", "Travel Manager", "Division Manager", "Employee", "Partner"]
  },
  /* {
    key: "/reseller/customize-emails/",
    label: "admin.sidebar.submenu.customizeEmails",
    roles: ["Administrator", "Admin", "Membership", "Travel Manager", "Division Manager", "Employee", "Partner"],
    leftIcon: < EmailIcon />
  }, */
   {
    key: "/reseller/manage-agents/",
    label: "admin.organizations.actions.manageagents",
    leftIcon: <VipIcon />,
    roles: ["Administrator", "Admin", "Membership", "Travel Manager", "Division Manager", "Employee", "Partner","Hybrid Admin"]
  },
  {
    key: "/reseller/audit-logs/",
    label: "admin.sidebar.viewAuditLogs",
    leftIcon: <ReportsIcon />,
    roles: ["Administrator", "Admin", "Membership", "Travel Manager", "Division Manager", "Employee", "Partner"]
  }
];

const hybridOptions = [
  {
    key: "/hybrid/",
    label: "admin.sidebar.Dashboard",
    leftIcon: <HomeIcon />,
    roles: ["Administrator", "Admin", "Membership", "Travel Manager", "Agent","Division Manager", "Employee", "Partner","Hybrid Admin"]
  },
  {
    key: "/hybrid/manage-organization/",
    label: "admin.sidebar.manageOrg",
    leftIcon: < SettingsIcon />,
    roles: ["Administrator", "Admin", "Membership", "Travel Manager", "Division Manager", "Employee", "Partner","Hybrid Admin"]
  },
  {
    key: "/hybrid/manage-users/",
    label: "admin.sidebar.submenu.ManageUser",
    leftIcon: < ManageUserIcon />,
    roles: ["Administrator", "NotForGuard" , "Admin", "Membership", "Travel Manager", "Division Manager", "Employee", "Partner","Hybrid Admin"]
  },
  {
    key: "/hybrid/manage-orders/",
    label: "admin.manage.orders",
    leftIcon: <OrderhistoryIcon />,
    roles: ["Administrator", "Admin", "Membership","Agent", "Travel Manager", "Division Manager", "Employee", "Partner","Hybrid Admin"]
  },
  {
    key: "/hybrid/manage-agents/",
    label: "admin.organizations.actions.manageagents",
    leftIcon: <VipIcon />,
    roles: ["Administrator", "Admin", "NotForGuard", "Membership", "Travel Manager", "Division Manager", "Employee", "Partner","Hybrid Admin"]
  },
  {
    key: "/hybrid/manage-travelers/",
    label: "admin.sidebar.manageTravelers",
    leftIcon: <TravevelersIcon />,
    orgId:["true"],
    roles: ["Administrator", "Admin", "Membership", "Travel Manager", "Division Manager", "Employee", "Partner","Hybrid Admin"]
   
  },
  {
    key: "/hybrid/audit-logs/",
    label: "admin.sidebar.viewAuditLogs",
    leftIcon: <ReportsIcon />,
    roles: ["Administrator", "Admin", "Membership", "Travel Manager", "Agent","Division Manager", "Employee", "Partner","Hybrid Admin"]
  },
  {
    key: "/hybrid/manage-division/",
    label: "admin.sidebar.manageDivision",
    leftIcon: <B2B2CIcon />,
    roles: ["Administrator", "Admin", "Membership", "Travel Manager", "Agent","Division Manager", "Employee", "Partner","Hybrid Admin"],
    orgId:["true"]
  }
];

const agentOptions = [
  // {
  //   key: "/agent/",
  //   label: "admin.sidebar.Dashboard",
  //   leftIcon: <HomeIcon />,
  //   roles: ["Agent"]
  // },
  {
    key: "/agent/manage-orders/",
    label: "admin.manage.orders",
    leftIcon: <OrderhistoryIcon />,
    roles: ["Agent"]
  },
  {
    key: "/agent/countries/",
    label: "admin.sidebar.submenu.Countries",
    leftIcon: <CountriesIcon />,
    roles: ["Agent"]
  },
  {
    key: "/agent/my-profile",
    label: "admin.profile.myprofile",
    leftIcon: <ManageUserIcon />,
    roles: ["Agent"]
  },
  {
    key: "/agent/my-profile/change-password/",
    label: "admin.profile.changepassword",
    leftIcon: <SettingsIcon />,
    roles: ["Agent"]
  }
];
export default {
  adminOptions,
  userOptions,
  resellerOptions,
  hybridOptions,
  agentOptions
};
