import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import IntlMessages from "../../../../services/intlMesseges";
import {
  Form,
  Input,
  Select,
  Button,
  Row,
  Col,
  Collapse,
  TreeSelect
} from "antd";
import * as OrgActions from "../../../../redux/actions/organization-actions";
import * as UserService from "../../../../services/user-service";
import * as Permission from "../../../../services/permissions";
import * as travelerLocationActions from "../../../../redux/actions/traveler-location-actions";

const { Panel } = Collapse;
const { Option } = Select;

class TravelerLocationFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentSelectedParentOrg: 0,
      parentOrganizationId: { key: "all", label: "All" },
      parentOrganizations: [],
      childOrganizationId: ""
    };
    this.onResetTravelerFilter = this.onResetTravelerFilter.bind(this);
  }

  componentDidMount() {
    let currentUser = UserService.getUser();
    let orgId = currentUser.companyID;

    if (Permission.isRole("Administrator") || Permission.isRole("CSR")) {
      this.loadParentOrganizations();
    } else {
      let list = [];
      this.props.getDivisionsList(orgId).then(resp => {
        let parentId;
        for (var i in resp.data) {
          if (resp.data[i].organizationId == orgId) {
            parentId = resp.data[i].parentOrgId;
          }
        }
        list = this.getNestedChildren(resp.data, parentId);
        this.setState({ currentSelectedParentOrg: orgId });
        this.setState({
          childOrgs: list
        });
      });
    }

    if (Object.keys(this.props.savedState).length !== 0) {
      if (
        this.props.savedState.childOrgId != "" &&
        this.props.savedState.childOrgId != undefined
      ) {
        this.setState({
          childOrganizationId: {
            key: this.props.savedState.childOrgId.value,
            label: this.props.savedState.childOrgId.label
          },
          currentSelectedParentOrg: this.props.savedState.parentOrgId.key
        });
      }
    }
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        let filter = {};
        if (
          values.parentOrgId !== undefined &&
          values.parentOrgId.key !== "all"
        ) {
          filter.companyId = values.parentOrgId.key;
        }
        if (values.childOrgId !== undefined && values.childOrgId !== "") {
          filter.companyId = values.childOrgId.value;
        }
        if (values.firstName !== undefined && values.firstName !== "") {
          filter.firstName = values.firstName;
        }
        if (values.lastName !== undefined && values.lastName !== "") {
          filter.lastName = values.lastName;
        }

        this.getTravelerLocationListByFilters(filter, values);
      }
    });
  };

  getTravelerLocationListByFilters(filter, values) {
    this.props
      .getTravelersList({
        filters: filter ? filter : {},
        pagination: { page: 1, pageSize: 1000 },
        sorter: {}
      })
      .then(resp => {
        this.props.handleFilterChange(resp.data, values);
      })
      .catch(ex => {});
  }

  onResetTravelerFilter() {
    this.props.form.resetFields();
    this.setState({
      parentOrganizationId: { key: "all", label: "All" },
      childOrganizationId: ""
    });
    this.getTravelerLocationListByFilters({}, {});
  }

  loadParentOrganizations = () => {
    this.props
      .getOrganizations({
        filters: {},
        pagination: { page: 1, pageSize: 10 },
        sorter: {}
      })
      .then(resp => {
        this.setState({
          parentOrganizations: resp.data
        });
      });
  };

  getNestedChildren = (arr, parent) => {
    var out = [];
    for (var i in arr) {
      if (arr[i].parentOrgId == parent) {
        var children = this.getNestedChildren(arr, arr[i].organizationId);
        if (children.length) {
          arr[i].children = children;
        }
        arr[i].title = arr[i].name;
        arr[i].value = arr[i].organizationId;
        arr[i].label = arr[i].name;
        arr[i].key = arr[i].organizationId;
        out.push(arr[i]);
      }
    }
    return out;
  };

  onChangeParentOrganizationTree = selected => {
    this.setState({ parentOrganizationId: selected });
    this.setState({ childId: "" });
    this.props.form.setFieldsValue({ childOrgId: "" });
    let list = [];
    this.props.getDivisionsList(selected.key).then(resp => {
      let parentId;
      for (var i in resp.data) {
        if (resp.data[i].organizationId == selected) {
          parentId = resp.data[i].parentOrgId;
        }
      }
      list = this.getNestedChildren(resp.data, parentId);
      this.setState({
        childOrgs: list
      });
    });
    this.loadParentOrganizations();
    this.setState({ currentSelectedParentOrg: selected });
    this.props.form.setFieldsValue({ childOrgs: list });
  };

  onChangeChildOrganizationTree = (value, label) => {
    this.setState({ onChangeChildOrganizationValue: value });
  };

  searchParentOrganizations = async value => {
    let args = {
      filters: { companyName: value },
      pagination: { page: 1, pageSize: 10 },
      sorter: {}
    };
    this.props
      .getOrganizations(args)
      .then(resp => {
        this.setState({
          parentOrganizations: resp.data
        });
      })
      .catch(_ex => {
        this.setState({ loading: false });
      });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 24 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 }
      }
    };
    return (
      <div>
        <Row gutter={24}>
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <h5 style={{ border: "none" }}>Filters</h5>
          </Col>
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            style={{ textAlign: "right" }}
          >
            <Button
              type="primary"
              onClick={this.onResetTravelerFilter}
              style={{ height: "30px", lineHeight: "30px" }}
            >
              Reset All
            </Button>
          </Col>
        </Row>
        <Form
          {...formItemLayout}
          onSubmit={this.handleSubmit}
          layout="Vertical"
          id="travelerform"
          style={{
            maxHeight: "349px",
            overflowX: "hidden",
            overflowY: "auto"
          }}
        >
          <Row gutter={24}>
            <Collapse accordion>
              <Panel header="By Organization" key="2">
                <Row gutter={24}>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <IntlMessages id="admin.division.parentCompany" />
                    <Form.Item>
                      {(Permission.isRole("Administrator") ||
                        Permission.isRole("CSR")) &&
                        getFieldDecorator("parentOrgId", {
                          initialValue: this.props.savedState.parentOrgId
                            ? this.props.savedState.parentOrgId
                            : this.state.parentOrganizationId
                        })(
                          <Select
                            style={{ width: "100%" }}
                            onSearch={this.searchParentOrganizations}
                            onChange={this.onChangeParentOrganizationTree}
                            filterOption={false}
                            labelInValue={true}
                            showSearch
                          >
                            <Option key="all">All</Option>
                            {this.state.parentOrganizations
                              ? this.state.parentOrganizations.map(function(
                                  item
                                ) {
                                  return (
                                    <Option key={item.organizationId}>
                                      {item.name}
                                    </Option>
                                  );
                                })
                              : ""}
                          </Select>
                        )}
                    </Form.Item>
                  </Col>
                </Row>

                {this.state.currentSelectedParentOrg !== 0 ? (
                  <Form.Item>
                    <Row gutter={24}>
                      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <IntlMessages id="admin.organization.company" />
                        {getFieldDecorator("childOrgId", {
                          initialValue: this.state.childOrganizationId
                        })(
                          <TreeSelect
                            style={{ width: "100%" }}
                            dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                            treeData={this.state.childOrgs}
                            onChange={this.onChangeChildOrganizationTree}
                            labelInValue={true}
                            treeDefaultExpandAll={true}
                          />
                        )}
                      </Col>
                    </Row>
                  </Form.Item>
                ) : (
                  ""
                )}
                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    Apply
                  </Button>
                </Form.Item>
              </Panel>
              <Panel header="By Traveler" key="3">
                <Col>
                  <Form.Item label="First Name:" labelAlign="left">
                    {getFieldDecorator("firstName", {
                      initialValue: this.props.savedState.firstName
                        ? this.props.savedState.firstName
                        : ""
                    })(<Input />)}
                  </Form.Item>
                  <Form.Item label="Last Name:" labelAlign="left">
                    {getFieldDecorator("lastName", {
                      initialValue: this.props.savedState.lastName
                        ? this.props.savedState.lastName
                        : ""
                    })(<Input />)}
                  </Form.Item>
                  <Form.Item>
                    <Button type="primary" htmlType="submit">
                      Apply
                    </Button>
                  </Form.Item>
                </Col>
              </Panel>
            </Collapse>
          </Row>
        </Form>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { ...OrgActions, ...travelerLocationActions },
    dispatch
  );
}
const WrappedForm = Form.create({ name: "assistfilter" })(
  TravelerLocationFilter
);

export default withRouter(
  connect(null, mapDispatchToProps, null, { forwardRef: true })(WrappedForm)
);
