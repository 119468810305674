export const Types = {
    SAVE_STEP:"SAVE_STEP",
    SAVE_PLAN_TYPE:"SAVE_PLAN_TYPE",
    SAVE_PLAN_TERM:"SAVE_PLAN_TERM",
    SAVE_PERSONAL_DETAIL:"SAVE_PERSONAL_DETAIL",
    SAVE_BILLING_DETAIL:"SAVE_BILLING_DETAIL",
    PURCHASE_SUMMARY:"PURCHASE_SUMMARY",
    CLEAR_DATA:"CLEAR_DATA",
    COUNTRY_TIER_INFO:"COUNTRY_TIER_INFO",
    PRODUCT_INFO:"PRODUCT_INFO",
    SAVE_PROMO_INFO:"SAVE_PROMO_INFO",
    COUPON_APPLIED_STATUS:"COUPON_APPLIED_STATUS",
    RESPONSE_ORDER_DATA:"RESPONSE_ORDER_DATA",
    RESPONSE_ERROR_ORDER_DATA:"RESPONSE_ERROR_ORDER_DATA",
    PRODUCT_PRICE_LIST:"PRODUCT_PRICE_LIST",
    IS_VALID_EMAIL:"IS_VALID_EMAIL",
    FLUSH_EMAIL_CHECK_RESPONSE:"FLUSH_EMAIL_CHECK_RESPONSE",
    SAVE_TRAVELLER_INFO:"SAVE_TRAVELLER_INFO",
    DAYS_LIST:"DAYS_LIST",
    FLUSH_ORDER_DATA:"FLUSH_ORDER_DATA",
    SAVE_RESELLER_ORG_INFO:"SAVE_RESELLER_ORG_INFO",
    SAVE_PLAN_TERM_TEMP:"SAVE_PLAN_TERM_TEMP",
    ALL_COUNTRIES: "ALL_COUNTRIES",
    RESEND_MEMBERSIP_INFO:"RESEND_MEMBERSIP_INFO",
    RESEND_PROOF_OF_BENEFITS:"RESEND_PROOF_OF_BENEFITS",
    UPDATE_CURRENCY: "UPDATE_CURRENCY",
    BUYER_EMAIL: "BUYER_EMAIL",
    AGENT_NAME:"AGENT_NAME",
    UPDATE_TRAVELER_COUNT: "UPDATE_TRAVELER_COUNT",
};
