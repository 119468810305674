import { Types } from "../constants/b2b2c-types";
import API from "../api/b2b2c-api";
import config from "../../../../config";
import { notifyUser } from "../../services/notification-service";
import { getWithExpiry, setWithExpiry } from "../../components/shared/apputil";

export function ClearData() {
  return async function(dispatch, getState) {
    try {
      localStorage.clear();
      dispatch({ type: Types.CLEAR_DATA });
    } catch (e) {}
  };
}

export function getPlanInformation() {
  return async function(dispatch, getState) {
    try {
      // let planType = JSON.parse(localStorage.getItem("planType"));
      let planType = getWithExpiry("planType");
      dispatch({ type: Types.SAVE_PLAN_TYPE, payload: planType });
    } catch (e) {}
  };
}

export function getPlanTermInformation() {
  return async function(dispatch, getState) {
    try {
      // let planTerm = JSON.parse(localStorage.getItem("planTerm"));
      let planTerm = getWithExpiry("planTerm");
      dispatch({ type: Types.SAVE_PLAN_TERM, payload: planTerm });
    } catch (e) {}
  };
}

export function getPersonalInformation() {
  return async function(dispatch, getState) {
    try {
      // let personalInformation = JSON.parse(localStorage.getItem("traveller"));
      let personalInformation = getWithExpiry("traveller");
      dispatch({
        type: Types.SAVE_PERSONAL_DETAIL,
        payload: personalInformation,
      });
    } catch (e) {}
  };
}

export function getBillingInformation() {
  return async function(dispatch, getState) {
    try {
      // let billingInformation = JSON.parse(localStorage.getItem("billing_information"));
      let billingInformation = getWithExpiry("billing_information");
      dispatch({
        type: Types.SAVE_BILLING_DETAIL,
        payload: billingInformation,
      });
    } catch (e) {}
  };
}

export function getBuyerEmail() {
  return async function(dispatch, getState) {
    try {
      let buyerEmail = localStorage.getItem("buyerEmail");
      //			getWithExpiry("buyerEmail");

      dispatch({ type: Types.BUYER_EMAIL, payload: buyerEmail });
    } catch (e) {}
  };
}

export function SaveStep(step) {
  return async function(dispatch, getState) {
    try {
      localStorage.setItem("step", step);
      dispatch({ type: Types.SAVE_STEP, payload: step });
    } catch (e) {}
  };
}

export function getStep() {
  return async function(dispatch, getState) {
    try {
      var step = localStorage.getItem("step");
      if (step) dispatch({ type: Types.SAVE_STEP, payload: step });
      else dispatch({ type: Types.SAVE_STEP, payload: "0" });
    } catch (e) {}
  };
}

export function SavePlanType(planType) {
  return async function(dispatch, getState) {
    try {
      // localStorage.setItem("planType",JSON.stringify(planType));
      setWithExpiry("planType", planType);
      dispatch({ type: Types.SAVE_PLAN_TYPE, payload: planType });
    } catch (e) {}
  };
}

export function getAgentName(atn) {
  return async function(dispatch, getState) {
    try {
      let resp = await API.getAgentName(atn);
      dispatch({ type: Types.AGENT_NAME, payload: resp.name });
    } catch (e) {}
  };
}
export function SavePlanTerm(planTerm) {
  return async function(dispatch, getState) {
    try {
      //localStorage.setItem("planTerm",JSON.stringify(planTerm));
      if (planTerm && planTerm.value) {
        setWithExpiry("planTerm", planTerm.value);
      } else {
        setWithExpiry("planTerm", planTerm);
      }

      dispatch({ type: Types.SAVE_PLAN_TERM, payload: planTerm });
    } catch (e) {}
  };
}

export function SavePlanTermTemp(planTerm) {
  return async function(dispatch, getState) {
    try {
      // localStorage.setItem("planTermTemp",JSON.stringify(planTerm));
      setWithExpiry("planTermTemp", planTerm);
      dispatch({ type: Types.SAVE_PLAN_TERM_TEMP, payload: planTerm });
    } catch (e) {}
  };
}

export function SavePersonalDetails(personalDetails) {
  return async function(dispatch, getState) {
    try {
      // localStorage.setItem("traveller",JSON.stringify(personalDetails));
      setWithExpiry("traveller", personalDetails);
      setWithExpiry("traveller-type", "b2b2c");

      dispatch({ type: Types.SAVE_PERSONAL_DETAIL, payload: personalDetails });
    } catch (e) {}
  };
}

export function SaveBillingInfornation(billingInformation) {
  return async function(dispatch, getState) {
    try {
      // localStorage.setItem("billing_information",JSON.stringify(billingInformation));
      setWithExpiry("billing_information", billingInformation);
      dispatch({
        type: Types.SAVE_BILLING_DETAIL,
        payload: billingInformation,
      });
    } catch (e) {}
  };
}

export function SaveBuyerEmail(buyerEmail) {
  return async function(dispatch, getState) {
    try {
      localStorage.setItem("buyerEmail", buyerEmail);
      dispatch({ type: Types.BUYER_EMAIL, payload: buyerEmail });
    } catch (e) {}
  };
}

export function getAppliedPromo() {
  return async function(dispatch, getState) {
    try {
      let promoData = JSON.parse(localStorage.getItem("promo"));
      dispatch({ type: Types.SAVE_PROMO_INFO, payload: promoData });
    } catch (e) {}
  };
}

export function applyPromoCode(promoCode) {
  return async function(dispatch, getState) {
    try {
      let promoData = await API.applyPromoCode(promoCode);
      if (promoData.data) {
        localStorage.setItem("promo", JSON.stringify(promoData.data));
        dispatch({ type: Types.SAVE_PROMO_INFO, payload: promoData.data });
        dispatch({ type: Types.COUPON_APPLIED_STATUS, payload: true });
      } else {
        localStorage.removeItem("promo");
        dispatch({ type: Types.SAVE_PROMO_INFO, payload: {} });
        dispatch({ type: Types.COUPON_APPLIED_STATUS, payload: false });
      }
    } catch (e) {}
  };
}

  export function resendMembershipInfo(id) {
	return async function(dispatch, getState) {
		  try {
			let dataResponse = await API.resendMembershipInfo(id);
			dispatch({ type: Types.RESEND_MEMBERSIP_INFO, payload: dataResponse });
			notifyUser("E-mail sent successfully!", "success");
		  } catch (e) {
			notifyUser("E-mail cannot be sent. Try again!", "error");
		  }
	  };
  } 
  
	export function resendProofOfBenefits(id) {
      return async function(dispatch, getState) {
        try {
          let dataResponse = await API.resendProofOfBenefits(id);
          dispatch({ type: Types.RESEND_PROOF_OF_BENEFITS, payload: dataResponse });
          notifyUser("E-mail sent successfully!", "success");
        } catch (e) {
          notifyUser("E-mail cannot be sent. Try again!", "error");
        }
      };
    } 
 
  export function validateTravellerEmail(email) {
	return async function(dispatch, getState) {
		  try {
			let emailResponse = await API.validateTravellerEmail(email);
			dispatch({ type: Types.IS_VALID_EMAIL, payload: emailResponse });
		  } catch (e) {
		  }
	  };
  }

  export function flushEmailCheckResponse() {
    return async function(dispatch, getState) {
		try {
            dispatch({ type: Types.FLUSH_EMAIL_CHECK_RESPONSE});
		} catch (e) {
		}
	};
}

export function placeOrder(objOrder) {
  return async function(dispatch, getState) {
    try {
      let responseOrder = await API.placeOrder(objOrder);
      if (responseOrder.data && responseOrder.data.status === 200) {
        localStorage.setItem(
          "order_response",
          JSON.stringify(responseOrder.data)
        );
        localStorage.setItem(
          "b2b2c_order_amount",
          objOrder["TotalOrderAmount"]
        );
        dispatch({
          type: Types.RESPONSE_ORDER_DATA,
          payload: responseOrder.data,
        });
      } else {
        dispatch({
          type: Types.RESPONSE_ERROR_ORDER_DATA,
          payload: responseOrder.data,
        });
      }
    } catch (e) {
      console.log("Order error : " + JSON.stringify(e));
    }
  };
}

export function placeOrderPaypal(responseOrder) {
  return async function(dispatch, getState) {
    try {
      //let responseOrder = await API.placeOrder(objOrder);
      if (responseOrder.data && responseOrder.data.status === 200) {
        localStorage.setItem(
          "order_response",
          JSON.stringify(responseOrder.data)
        );
        //localStorage.setItem("b2c_order_amount", objOrder['TotalOrderAmount']);
        dispatch({
          type: Types.RESPONSE_ORDER_DATA,
          payload: responseOrder.data,
        });
      } else {
        dispatch({
          type: Types.RESPONSE_ERROR_ORDER_DATA,
          payload: responseOrder.data,
        });
      }
    } catch (e) {
      console.log("Order error : " + JSON.stringify(e));
    }
  };
}

// export function resendMembershipInfo(id) {
//   return async function(dispatch, getState) {
//     try {
//       let dataResponse = await API.resendMembershipInfo(id);
//       dispatch({ type: Types.RESEND_MEMBERSIP_INFO, payload: dataResponse });
//       notifyUser("E-mail sent successfully!", "success");
//     } catch (e) {
//       notifyUser("E-mail cannot be sent. Try again!", "error");
//     }
//   };
// }

// export function validateTravellerEmail(email) {
//   return async function(dispatch, getState) {
//     try {
//       let emailResponse = await API.validateTravellerEmail(email);
//       dispatch({ type: Types.IS_VALID_EMAIL, payload: emailResponse });
//     } catch (e) {}
//   };
// }

// export function flushEmailCheckResponse() {
//   return async function(dispatch, getState) {
//     try {
//       dispatch({ type: Types.FLUSH_EMAIL_CHECK_RESPONSE });
//     } catch (e) {}
//   };
// }

export function removePromoCode(promoCode) {
  return async function(dispatch, getState) {
    try {
      localStorage.removeItem("promo");
      dispatch({ type: Types.SAVE_PROMO_INFO, payload: {} });
      dispatch({ type: Types.COUPON_APPLIED_STATUS, payload: false });
    } catch (e) {}
  };
}

export function getTravellerInfo(userId) {
  return async function(dispatch, getState) {
    try {
      let travellerInfo = await API.getTravellerInfo(userId);
      localStorage.setItem(
        "tarveller_info",
        JSON.stringify(travellerInfo.data)
      );
      dispatch({
        type: Types.SAVE_TRAVELLER_INFO,
        payload: travellerInfo.data,
      });
    } catch (e) {}
  };
}

export function getAllCountries() {
  return async function(dispatch, getState) {
    try {
      let countries = await API.getAllCountries();
      dispatch({
        type: Types.ALL_COUNTRIES,
        payload: countries && countries.countries ? countries.countries : [],
      });
    } catch (e) {}
  };
}


  
        
export function getResellerProductPricingAndInfo() {
  return async function(dispatch, getState) {
  try {
      let orgId = window.location.pathname.split("/");
      orgId = orgId[orgId.length - 1];
      if (parseInt(orgId) > 0) {
        //let productPriceAndInfo = await API.getProductPricingAndInfo();
        let productPriceAndInfo = await API.getResellerOrganizationInfo(orgId);
        if (productPriceAndInfo.data) {
          productPriceAndInfo = productPriceAndInfo.data;
          dispatch({
            type: Types.PRODUCT_PRICE_LIST,
            payload: productPriceAndInfo.productPriceList,
          });
          dispatch({
            type: Types.PRODUCT_INFO,
            payload: productPriceAndInfo.productList,
          });
          let countries = productPriceAndInfo.countries.filter(
            (data) => data.countryId !== config.allCountriesCountryId
          );
          countries.unshift({
            countryId: config.allCountriesCountryId,
            countryCode: config.allCountriesCountryCode,
            countryName: "All Countries",
            tierId: 1,
          });
          dispatch({ type: Types.COUNTRY_TIER_INFO, payload: countries });
          dispatch({
            type: Types.DAYS_LIST,
            payload: productPriceAndInfo.dayPackages,
          });
          dispatch({
            type: Types.SAVE_RESELLER_ORG_INFO,
            payload: productPriceAndInfo,
          });
        }
        
      }
     
    } catch (e) {}
  };
}

export function flushOrderData() {
  return async function(dispatch, getState) {
    try {
      dispatch({ type: Types.FLUSH_ORDER_DATA });
    } catch (e) {}
  };
}

export function updateCurrency(currencyType, currentCurrencyRate) {
  return async function(dispatch, getState) {
    try {
      dispatch({
        type: Types.UPDATE_CURRENCY,
        payload: {
          currencyType: currencyType,
          currentCurrencyRate: currentCurrencyRate,
        },
      });
    } catch (e) {}
  };
}

export function travellerCount(val) {
  return async function(dispatch, getState) {
    try {
      dispatch({
        type: Types.UPDATE_TRAVELER_COUNT,
        payload: { val },
      });
    } catch (e) {}
  };
}

export function convertCurrency() {
  return async function(_dispatch, _getState) {
    try {
      return await API.convertCurrency();
      // let data;
      // return (data = {"code": "CAD","value": 1.3644});
    } catch (e) {
      return { error: true };
    }
  };
}

export function getGendersList() {
  return async function(_dispatch, _getState) {
    try {
      return await API.getGendersList();
    } catch (e) {
      return { error: true };
    }
  };
}
