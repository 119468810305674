import Axios from "../../services/axios-service";

  const getOrdersData = data => {
    let _orgId = data.orgId;
    delete data.orgId;
    return Axios.get("/api/OrganizationOrders/" +_orgId+ "?search="+encodeURIComponent(JSON.stringify(data)), undefined)
    .then(res => {
        return res;
      })
      .catch(ex => {
        return ex;
      });
  };

  const getOrder = idx => {
    return Axios.get("/api/order/"+idx, undefined, undefined);
  };

  const getAgentOrder = idx => {
    return Axios.get("/api/getAgentOrder/"+idx, undefined, undefined);
  };

  const updateOrder = data => {
    return Axios.put("/api/updateorder/"+data.id, data, undefined);
  };

  const refundOrder = (data) => {
    return Axios.put("/api/refundorder/" + data.orderID, data, undefined)
  }

  const addOrder = data => {
    return Axios.post("/api/addorder", data, undefined);
  };
  const exportOrders = (orgId = 1, startDate, endDate) => {
    return Axios.post(`/api/ExportOrders/${orgId}/${startDate}/${endDate}`, undefined, undefined);
  };
const getResellerOrders = data => {
  let _orgId = data.orgId;
  let startDateParams = "";
  let endDateParams = "";
  if(data.startDate){
    startDateParams = `&startDate=${encodeURIComponent(data.startDate)}`;
    delete data.startDate;
  }
  if(data.endDate){
    endDateParams = `&endDate=${encodeURIComponent(data.endDate)}`;
    delete data.endDate;
  }
  delete data.orgId;
  let endpoint = "/api/ResellerOrders/" +_orgId+ "?search="+encodeURIComponent(JSON.stringify(data))+startDateParams+endDateParams;
  return Axios.get(endpoint,undefined, undefined)
  .then(res => {
      return res;
    })
    .catch(ex => {
      return ex;
    });
};

const getAgentOrders = data => {
  let _orgId = data.orgId;
  let startDateParams = "";
  let endDateParams = "";
  if(data.startDate){
    startDateParams = `&startDate=${encodeURIComponent(data.startDate)}`;
    delete data.startDate;
  }
  if(data.endDate){
    endDateParams = `&endDate=${encodeURIComponent(data.endDate)}`;
    delete data.endDate;
  }
  delete data.orgId;
  let endpoint = "/api/GetB2B2CAgentOrders/" +_orgId+ "?search="+encodeURIComponent(JSON.stringify(data))+startDateParams+endDateParams;
  return Axios.get(endpoint,undefined, undefined)
  .then(res => {
      return res;
    })
    .catch(ex => {
      return ex;
    });
};

  export default {
    getOrdersData,
    getOrder,
    getAgentOrder,
    updateOrder,
    refundOrder,
    addOrder,
    exportOrders,
    getResellerOrders,
    getAgentOrders
  };
