import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import SideBar from "../sidebar/sidebar.jsx";
import UserRouter from "../../routes/user-router";
import * as userActions from "../../redux/actions/user-actions"
import { Layout, Modal, Form, Input, Button, Icon } from "antd";
import UserHeader from "./user-header/header.jsx";
import * as languageAction from "../../redux/actions/language-actions.js";
import * as themeAction from "../../redux/actions/theme-actions";
import options from "../sidebar/options";
import * as UserService from "../../services/user-service";
import * as Permissions from "../../services/permissions"
import AuthTokenTimer from "../shared/components/auth-token-timer/auth-token-timer";
import { notifyUser } from "../../services/notification-service";
import IntlMessages from "../../services/intlMesseges";
import AppLocale from "../../languageProvider";
const { Header, Sider, Content } = Layout;

class UserDash extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      changePasswordModalVisible: false,
      updateEmergencyContactModalVisible: false
    };
  }

  async componentDidMount() {
    var currentTheme = JSON.parse(localStorage.getItem('user')),
      currentThemeName = 'theme-' + currentTheme.themeId;
    document.body.setAttribute('class', currentThemeName);
    document.body.setAttribute('style', "");
    if (!this.props.userData || !this.props.userData.userID) {
      await this.props.getUserData();
    }
    let currentUser = UserService.getUser();
    if (currentUser.userID && currentUser.accountConfirmed === false) {
      localStorage.setItem("remember_me", false);
      if (this.props.history.location.pathname.indexOf("change-password") <= -1) {
        this.props.history.push('/user/my-profile/change-password');
      }
    }
    await this.props.hasB2B2CEmergencyContact(currentUser.userID).then(response => {
      if (response.data && typeof response.data.emergencyContactExists != "undefined" && response.data.emergencyContactExists != null) {
        if ((response.data.emergencyContactExists === false)) {
          this.setState({ updateEmergencyContactModalVisible: true });
        }
      }
    });
  }
  showModal = () => {
    this.setState({
      planExp: true,
    });
  };
  handleOk = e => {
    this.setState({
      planExp: false,
    });
  };
  submitPasswordChangeModal = () => {
    this.setState({ changePasswordModalVisible: false });
    this.props.history.push('/admin/my-profile');
  }

  cancelPasswordChangeModal = () => {
    this.setState({ changePasswordModalVisible: false });
  }
  
  handleEmergencyContactSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { language } = this.props;
        const currentAppLocale = AppLocale[language.locale];
        this.setState({ loading: true });
        let currentUser = UserService.getUser();
        this.props.updateB2B2CEmergencyContact(currentUser.userID, values).then(response => {
          if (response.error) {
            notifyUser(response.error.message, "error");
            this.setState({ loading: false });
          } else {
            this.setState({ loading: false, updateEmergencyContactModalVisible: false });
            notifyUser(currentAppLocale.messages["admin.update.success"], "success");
          }
        });
      }
    });
  }

  validatePhone(rule, value, callback){
    //const re = /^[0-9']+$/;
    const re = (/^(?=[+0-9]*$)(?!.*[<>'"/;`%~@#$^*()_=[\]{}|\\,.?: -])/)
    var test=re.test(value)
    if(value !=="" && (value.length < 6 || test ===false)){
      callback(<IntlMessages id ="admin.phonenumber.valid"></IntlMessages>)
    }

    else{
      callback();
    }
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const { url } = this.props.match;
    const formItemLayout = null;
    return (
      <div id="components-layout-demo-custom-trigger" /* className={this.props.theme && this.props.theme.themeId ? this.props.theme.name.toLowerCase().replace(" ","-") : ''} */>
        <Modal
          title="Warning! Please change your password."
          visible={this.state.changePasswordModalVisible}
          onOk={this.submitPasswordChangeModal}
          onCancel={this.cancelPasswordChangeModal}
        >
          <p>For security reasons we suggest you to reset your password.</p>
        </Modal>
        <Modal
          title="Please add your emergency contact details"
          visible={this.state.updateEmergencyContactModalVisible}
          closable={false}
          footer={null}
        >
          <p>To ensure your maximum security we need your emergency contact in case we are not reachable to you.</p>
          <Form onSubmit={this.handleEmergencyContactSubmit} className="emergency-popup-form">
            <Form.Item {...formItemLayout} label={<IntlMessages id="admin.userlisting.firstName" />}>
              {getFieldDecorator('emergencyFirstName', {
                rules: [
                  { 
                    required: true, 
                    message: <IntlMessages id="admin.input.required" />
                  },
                  {
                    pattern: new RegExp(/^(\S.*)[a-zA-Z-.']+$/),
                    message: <IntlMessages id ="admin.name.valid"></IntlMessages>
                  }
                ],
              })(
                <Input />,
              )}
            </Form.Item>
            <Form.Item {...formItemLayout} label={<IntlMessages id="admin.userlisting.lastName" />}>
              {getFieldDecorator('emergencyLastName', {
                rules: [
                  { 
                    required: true, 
                    message: <IntlMessages id="admin.input.required" /> 
                  },
                  {
                    pattern: new RegExp(/^(\S.*)[a-zA-Z-.']+$/),
                    message: <IntlMessages id ="admin.lname.valid"></IntlMessages>
                  }
                ],
              })(
                <Input />,
              )}
            </Form.Item>
            <Form.Item {...formItemLayout} label={<IntlMessages id="admin.userlisting.relationshiptype" />}>
              {getFieldDecorator('emergencyRelationship', {
                rules: [
                  { 
                    required: true, 
                    message: <IntlMessages id="admin.input.required" /> 
                  }
                ],
              })(
                <Input />,
              )}
            </Form.Item>
            <Form.Item {...formItemLayout} label={<IntlMessages id="admin.primaryaddress.phonenumber" />}>
              {getFieldDecorator('emergencyContactNo', {
                rules: [
                  {
                    validator: this.validatePhone
                  },
                  { 
                    required: true, 
                    message: <IntlMessages id="admin.input.required" /> 
                  }
                ],
              })(
                <Input />,
              )}
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" className="login-form-button"><IntlMessages id="admin.userlisting.update" /></Button>
            </Form.Item>
          </Form>
        </Modal>
        <Modal
          title="Warning! Please change your password."
          visible={this.state.changePasswordModalVisible}
          onOk={this.submitPasswordChangeModal}
          onCancel={this.cancelPasswordChangeModal}
        >
          <p>For security reasons, we suggest you to reset your password.</p>
        </Modal>
        <Layout className={this.props.theme.className}>
          <Sider
            trigger={null}
            collapsible
            collapsed={!this.props.sidebar}
            style={{
              overflow: "auto",
              height: "100vh",
              position: "sticky",
              top: 0,
              left: 0
            }}
            width={300}
          >
            <SideBar options={options.userOptions} logo_url={this.props.logo_url} userType="user" />
          </Sider>
          <Layout>
            <Header
              className="header"
              style={{
                background: "#fff",
                position: "sticky",
                zIndex: 100,
                top: 0,
                left: 0,
                width: "100%",
                padding: 0
              }}
              match={this.props.match}
              selectedtheme={this.props.theme}
              location={this.props.location}
              history={this.props.history}
            >
              <UserHeader />
            </Header>
            <Content style={{
              margin: "24px 16px",
              padding: 24,
              background: "#fff",
              minHeight: 280
            }}>
              <UserRouter url={url} />
            </Content>
          </Layout>
        </Layout>
        {/*<AuthTokenTimer/>*/}
      </div>
    );
  }
}

UserDash.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object,
  logo_url: PropTypes.string,
  theme: PropTypes.object
};
function mapStateToProps(state) {
  return {
    ...state.user,
    ...state.language,
    ...state.sidebar,
    ...state.theme
  }
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { ...userActions, ...languageAction, ...themeAction },
    dispatch
  );
}
const WrappedForm = Form.create({ name: "user-emergency-contact" })(UserDash);
export default withRouter(connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(WrappedForm));
