import React, { Component } from 'react';
import {
  Form,
  Input,
  Select,
  Row,
  Checkbox,
  Col,
  Button,
  //DatePicker,
  Radio,
  Modal,
  message
} from 'antd';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import Text from 'react-text';
import config from '../../../../../config';
import postscribe from 'postscribe';
import { currencyType } from '../../../components/constants';
import getSymbolFromCurrency from 'currency-symbol-map';
import * as UserService from '../../../services/user-service';

var validator = require('email-validator');
const { Option } = Select;
const { confirm } = Modal;
class RegistrationForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      productToOrder: config.wpProductToOrder,
      travellersObjectsArray: [],
      receive_alerts: true,
      isSavedLoaded: false,
      isEmergencyModalVisible: false,
      userExist: false,
      isUsResident: false,
      resCountry: '',
      resCountryCode: '',
      resUsState: '',
      usStates: [],
      currency: 'USD',
      desCountryCode: '',
      desStateCode: '',
      currencyRate: 1,
      isPopupOpen: false,
      continueStatus: true,
      is_reload_cap_order: false
    };
    this.onCheckCompanyChange = this.onCheckCompanyChange.bind(this);
    this.appendTravellerData = this.appendTravellerData.bind(this);
    this.handleBackClik = this.handleBackClik.bind(this);
    this.handleSwitchChange = this.handleSwitchChange.bind(this);
    this.saveDataAndNext = this.saveDataAndNext.bind(this);
    this.removeTraveller = this.removeTraveller.bind(this);
    this.updatePlanPrice = this.updatePlanPrice.bind(this);
    this.handlePlanChange = this.handlePlanChange.bind(this);
    this.showConfirm = this.showConfirm.bind(this);
    this.handleStateField = this.handleStateField.bind(this);
  }

  async _onKeydown(evt, idx) {
    if (evt.keyCode == 13) {
      this._onBlur(evt);
    }
  }

  async _onBlur(evt, idx) {
    var email = evt.target.value;
    var index = parseInt(evt.currentTarget.dataset.index);
    if (validator.validate(email)) {
      await this.props.validateTravellerEmail(email);
      if (this.props.emailCheckResponse && this.props.emailCheckResponse.userExist) {
        if (index >= 0) {
          this.state.travellersObjectsArray[index].isEmailExit = true;
          this.state.travellersObjectsArray[index].firstName = this.props.emailCheckResponse.firstName;
          this.state.travellersObjectsArray[index].lastName = this.props.emailCheckResponse.lastName;
          this.setState({
            travellersObjectsArray: this.state.travellersObjectsArray
          });
        }
        var objContent = {};
        objContent.title = 'Welcome back! Would you like to proceed with this email ID and existing profile?';
        objContent.content = index === 0 ? email : email;
        objContent.okText = 'Continue';

        if (this.state.continueStatus === true && !this.state.isPopupOpen) {
          this.showConfirm(objContent, index);
          this.setState({ isPopupOpen: true });
        }
      } else {
        if (index >= 0) {
          this.setState({ userExist: false });
          this.state.travellersObjectsArray[index].isEmailExit = false;
          this.setState({
            travellersObjectsArray: this.state.travellersObjectsArray
          });
        }
      }
    }
  }

  showConfirm(objContent, index) {
    var _travellersObjectsArray = this.state.travellersObjectsArray;
    this.setState({ isPopupOpen: true });
    var self = this;
    var confirmArgs = {
      title: objContent.title,
      content: objContent.content,
      className: 'gray-color',
      onOk() {
        if (index === 0) {
          //self.props.history.push("/login");
          self.setState({ userExist: true });
          self.setState({ continueStatus: false });
          self.setState({ isPopupOpen: false });
          self.props.form.resetFields(['first_name0', 'country0', 'last_name0', 'dob0', 'phone0', 'gender0', 'state0']);
        }
      },
      onCancel() {
        if (index === 0 || index >= 0) {
          _travellersObjectsArray[index].isEmailExit = false;
          self.props.form.setFieldsValue({
            [`email${index}`]: '', 
            [`first_name${index}`]: '' 
        });
      }
        self.setState({ isPopupOpen: false });
      }
    };
    if (objContent.okText && objContent.okText !== null) {
      confirmArgs.okText = objContent.okText;
    }
    // if (objContent.okText1 && objContent.okText1 !== null) {
    //   confirmArgs.okText1 = objContent.okText1;
    // }

    confirm(confirmArgs);
  }

  async componentDidMount() {
    let traveller_type = localStorage.getItem('traveller-type');
    traveller_type = JSON.parse(traveller_type) && JSON.parse(traveller_type).value;
    let cap_data = localStorage.getItem('traveller');

    if (cap_data && traveller_type == 'b2c') {
      let arrObj = JSON.parse(cap_data).value;

      let newArr = [];
      arrObj.map((item, idx) => {
        let tempObj = {};
        for (var key in item) {
          if (key == 'first_name') {
            tempObj.firstName = item[key];
          } else if (key == 'last_name') {
            tempObj.lastName = item[key];
          } else if (key == 'isEmailExit') {
            tempObj.isEmailExit = item[key];
          } else if (key == 'dob') {
            tempObj.dob = item[key];
          } else if (key == 'email') {
            tempObj.email = item[key];
          } else if (key == 'gender') {
            tempObj.gender = item[key];
          } else if (key == 'mobile_number') {
            tempObj.phone = item[key];
          } else if (key == 'is_bussiness_travelling') {
            tempObj.is_bussiness_travelling = item[key];
          } else if (key == 'country') {
            tempObj.country = item[key];

            if (tempObj.country == 249) {
              this.setState({
                isUsResident: true
              });
            }
          } else if (key == 'company_name') {
            tempObj.company_name = item[key];
          } else if (key == 'product_id') {
            tempObj.product_id = item[key];
          } else if (key == 'IsPrimary') {
            tempObj.IsPrimary = item[key];
          } else if (key == 'stateName') {
            tempObj.stateName = item[key];
          } else if (key == 'stateid') {
            tempObj.stateid = item[key];
          } else if (key == 'receiver_alert') {
            tempObj.receiver_alert = item[key];
          }

          tempObj.pressedBack = true;
        }
        newArr.push(tempObj);
      });
      this.setState({ travellersObjectsArray: newArr, isSavedLoaded: true }, () => {
        this.props.updateTravellerCount(newArr.length);
      });
    }
    postscribe(
      '#travellers-info',
      "<script> (function(h,o,t,j,a,r){ h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)}; h._hjSettings={hjid:2750169,hjsv:6}; a=o.getElementsByTagName('head')[0]; r=o.createElement('script');r.async=1; r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv; a.appendChild(r); })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv='); </script>"
    );
    var encodedParameters = window.location.search;
    var decodedParameterString = atob(encodedParameters.substring(1));
    var parameters = decodedParameterString.split('&');
    var parameterArray = new Map();
    parameters.forEach(async function(parameter) {
      var data = parameter.split('=');
      parameterArray[data[0]] = data[1];
    });

    var is_reload_cap_order = false;
    var user_exist = false;

    if (parameterArray['reload_cap_order']) {
      is_reload_cap_order = true;
      this.setState({ is_reload_cap_order: true });
    }
    if (parameterArray['user_exist']) {
      user_exist = true;
    }

    this.setState({ resCountry: parameterArray['residence_country'] });
    this.setState({ resCountryCode: parameterArray['resi_c_code'] });
    this.setState({ desCountryCode: parameterArray['countries'] });
    this.setState({ desStateCode: parameterArray['res_state_lists'] });
    if (parameterArray['residence_country'] == 'United States of America') {
      this.setState({ resUsState: parameterArray['residence_state'] });
    }

    if (this.props.isLoggedIn && !(is_reload_cap_order && user_exist)) {
      var isInfoUpdated = false;
      await this.props.getTravellerInfo(this.props.userData.userID);
      if (this.props.travellerInfo && this.props.travellerInfo.email) {
        if (this.props.personalDetails && this.props.personalDetails.length > 0) isInfoUpdated = true;
        var travellerObject = {
          first_name: this.props.travellerInfo.firstName,
          last_name: this.props.travellerInfo.lastName,
          email: this.props.travellerInfo.email,
          dob: this.props.travellerInfo.dateOfBirth,
          country: this.props.travellerInfo.countryId,
          mobile_number: this.props.travellerInfo.contactNo,
          is_bussiness_travelling: this.props.travellerInfo.companyName ? true : false,
          rel_first_name: isInfoUpdated
            ? this.props.personalDetails[0].rel_first_name
            : this.props.travellerInfo.emergencyFirstName,
          rel_last_name: isInfoUpdated
            ? this.props.personalDetails[0].rel_last_name
            : this.props.travellerInfo.emergencyLastName,
          relationship: isInfoUpdated
            ? this.props.personalDetails[0].relationship
            : this.props.travellerInfo.emergencyRelationship,
          rel_phone_number: isInfoUpdated
            ? this.props.personalDetails[0].rel_phone_number
            : this.props.travellerInfo.emergencyContactNo,
          company_name: this.props.travellerInfo.companyName,
          product_id: isInfoUpdated ? this.props.personalDetails[0].product_id : this.state.productToOrder,
          is_annual: false,
          isEmailExit: true
        };
        var tempArray = [];
        tempArray.push(travellerObject);
        if (this.props.personalDetails && this.props.personalDetails.length > 1) {
          for (var i = 1; i < this.props.personalDetails.length; i++) {
            tempArray.push(this.props.personalDetails[i]);
          }
        }
        this.setState({ travellersObjectsArray: tempArray });
      } else {
        this.state.travellersObjectsArray.push({
          first_name: '',
          last_name: '',
          email: '',
          dob: '',
          country: '',
          state: '',
          stateName: '',
          mobile_number: '',
          is_bussiness_travelling: false,
          rel_last_name: '',
          rel_first_name: '',
          relationship: '',
          rel_phone_number: '',
          company_name: '',
          product_id: this.state.productToOrder,
          is_annual: false,
          isEmailExit: false
        });
      }
    } else {
      if (this.props.personalDetails && this.props.personalDetails.length > 0) {
        //  this.setState({
        //     travellersObjectsArray: this.props.personalDetails,
        //    isSavedLoaded: true,
        //   });
      } else {
        this.state.travellersObjectsArray.push({
          first_name: '',
          last_name: '',
          email: '',
          dob: '',
          country: '',
          state: '',
          stateName: '',
          mobile_number: '',
          is_bussiness_travelling: false,
          rel_last_name: '',
          rel_first_name: '',
          relationship: '',
          rel_phone_number: '',
          company_name: '',
          product_id: this.state.productToOrder,
          is_annual: false,
          isEmailExit: false
        });
        let decodedParams = this.props.decoded_params;
        if (decodedParams.traveller_count > 1) {
          for (var t = 1; t < decodedParams.traveller_count; t++) {
            this.appendTravellerData();
          }
        }
      }
    }

    /* for(var i=0; i < this.state.travellersObjectsArray.length; i++){
      console.log('item:', this.state.travellersObjectsArray[i].dob);
      this.props.form.setFieldsValue({ ["dob"+i]: this.state.travellersObjectsArray[i].dob})
    } */
  }

  async handlePlanChange(evt) {
    var targetValue = evt.target.value.split(',');
    this.state.travellersObjectsArray[targetValue[0]].product_id = targetValue[1];
    var totalPrice = 0;
    for (var i = 0; i < this.state.travellersObjectsArray.length; i++) {
      var product_id = 0;
      if (i === parseInt(targetValue[0])) {
        product_id = targetValue[1];
      } else {
        var travellerObject = this.state.travellersObjectsArray[i];
        product_id = travellerObject.product_id;
      }
      var dayPriceObject = this.props.productPriceList.find(
        (x) => x.tierId === 1 && x.dayPackage === parseInt(this.state.dayPackage) && x.productId === 1
      );
      var productPrice = dayPriceObject ? dayPriceObject.retailPrice : 0;
      totalPrice = totalPrice + parseInt(productPrice);
    }
    let planTermObject = this.props.planTerm;
    planTermObject.totalPrice = totalPrice;
    await this.props.SavePlanTerm(planTermObject);
    this.setState({ isSavedLoaded: false });
    this.props.updatePrice();
  }

  async updatePlanPrice(type) {
    var dayPriceObject;
    var decodedParams = this.props.decoded_params;
    const _MS_PER_DAY = 1000 * 60 * 60 * 24;
    var start_date = Date.parse(decodedParams.start_date);
    var end_date = Date.parse(decodedParams.end_date);
    var days = Math.floor((end_date - start_date) / _MS_PER_DAY);
    let dp = 8;
    if (days <= 8) {
      dp = 8;
    } else if (days <= 15) {
      dp = 15;
    } else if (days <= 30) {
      dp = 30;
    } else if (days <= 60) {
      dp = 60;
    } else if (days <= 90) {
      dp = 90;
    } else if (days <= 120) {
      dp = 120;
    } else if (days <= 365) {
      dp = 365;
    }
    if (this.props.planTerm && this.props.planTerm.Price) {
      if (type !== 'update') {
        let planTermObject = this.props.planTerm;
        if (this.props.planTerm.isAnnual) {
          var maxPrice = 999;
          if (this.props.productPriceList && this.props.productPriceList.length > 0) {
            dayPriceObject = this.props.productPriceList.find(
              (x) => x.tierId === 1 && x.dayPackage === parseInt(dp) && x.productId === 1
            );
            maxPrice = dayPriceObject.retailPrice;
          }
          planTermObject.totalPrice =
            type === 'add' ? planTermObject.totalPrice + maxPrice : planTermObject.totalPrice - maxPrice;
        } else {
          dayPriceObject = this.props.productPriceList.find(
            (x) =>
              x.tierId === planTermObject.tierValue &&
              x.dayPackage === parseInt(dp) &&
              x.productId === this.state.productToOrder
          );
          planTermObject.totalPrice =
            type === 'add'
              ? planTermObject.totalPrice + dayPriceObject.retailPrice
              : planTermObject.totalPrice - dayPriceObject.retailPrice;
        }
        await this.props.SavePlanTerm(planTermObject);
        this.props.updatePrice();
      } else {
        let planTermObject = this.props.planTerm;
        //planTermObject.Price = this.props.planTerm.totalPrice;
        await this.props.SavePlanTerm(planTermObject);
      }
    }
  }

  async removeTraveller(evt) {
    var travellerID = parseInt(evt.target.dataset.value);
    this.updatePlanPrice('remove');
    this.state.travellersObjectsArray.splice(travellerID, 1);
    this.setState({
      travellersObjectsArray: this.state.travellersObjectsArray
    });
    let decodedParams = this.props.decoded_params;
    this.props.updateTravellerCount(this.state.travellersObjectsArray.length);
  }

  handleSwitchChange(evt) {
    this.setState({ receive_alerts: !this.state.receive_alerts });
  }

  handleBackClik(evt) {
    this.props.handleBack();
  }

  async appendTravellerData() {
    var productId =
      this.props.personalDetails && this.props.personalDetails[0] && this.props.personalDetails[0].product_id
        ? this.props.personalDetails[0].product_id
        : this.state.productToOrder;
    var _travellersObjectsArray = this.state.travellersObjectsArray;
    _travellersObjectsArray.push({
      first_name: '',
      last_name: '',
      email: '',
      dob: '',
      country: '',
      state: '',
      stateName: '',
      mobile_number: '',
      is_bussiness_travelling: false,
      rel_last_name: '',
      rel_first_name: '',
      relationship: '',
      rel_phone_number: '',
      company_name: '',
      product_id: productId,
      is_annual: false,
      isEmailExit: false
    });
    this.setState({ travellersObjectsArray: _travellersObjectsArray });
    //this.updatePlanPrice("add");
    let decodedParams = this.props.decoded_params;

    this.props.updateTravellerCount(this.state.travellersObjectsArray.length);
  }

  onCheckCompanyChange(evt) {
    var travellerID = parseInt(evt.target.id.replace('traveller_check_company', ''));
    this.state.travellersObjectsArray[travellerID]['is_bussiness_travelling'] = evt.target.checked;
  }

  handleSubmit = (e) => {
    localStorage.setItem('is_back', 'No');

    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        Object.keys(values).map(function(key, _idx) {
          if (values[key] == null) {
            values[key] = '';
          } else {
            if (typeof values[key] == 'string') {
              values[key] = values[key].trim();
            }
          }
        });
        this.checkIfEmailsAlreadyExist(values);
        // this.saveDataAndNext(values);
      }
    });
  };

  async checkIfEmailsAlreadyExist(formData) {
    var isValidEmails = true;
    var emailUsed = [];
    for (var i = 0; i < this.state.travellersObjectsArray.length; i++) {
      if (!this.props.isLoggedIn) {
        if (i === 0) {
          await this.props.flushEmailCheckResponse();
          var email = formData['email' + i];
          await this.props.validateTravellerEmail(email);
          if (this.props.emailCheckResponse && this.props.emailCheckResponse.userExist) {
            /*isValidEmails = false;
            const selector = document.getElementById("traveller_email" + i);
            if (selector) selector.focus();
            break;*/
          }
        }
      }

      //temp disable
      // if (
      //   formData["dob" + i] &&
      //   !this.state.travellersObjectsArray[i].isEmailExit
      // ) {
      //   var selectedDate = new Date(
      //     /* formData["dob" + i]._d */ formData["dob" + i]
      //   );
      //   if (i === 0) {
      //     var isValidDOB = this.checkValidDateOfBirth(
      //       selectedDate.getDay(),
      //       selectedDate.getMonth(),
      //       selectedDate.getFullYear()
      //     );
      //     if (!isValidDOB) {
      //       isValidEmails = false;
      //       const selectorDOB = document.getElementById("traveller_dob" + i);
      //       if (selectorDOB) selectorDOB.focus();
      //       message.error("Please select a valid date of birth.");
      //       break;
      //     }
      //   } else {
      //     const diffTime = selectedDate - new Date();
      //     const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      //     if (diffDays > 0) {
      //       isValidEmails = false;
      //       const selectorDOB = document.getElementById("traveller_dob" + i);
      //       if (selectorDOB) selectorDOB.focus();
      //       message.error("Please select a valid date of birth.");
      //       break;
      //     }
      //   }
      // }
      if (formData['email' + i] && formData['email' + i] !== '') {
        if (emailUsed.indexOf(formData['email' + i]) < 0) {
          emailUsed.push(formData['email' + i]);
        } else {
          isValidEmails = false;
          const selector = document.getElementById('traveller_email' + i);
          if (selector) selector.focus();
          message.error('Email ' + formData['email' + i] + ' already assigned.');
          break;
        }
      }
      // To validate phone number
      // if(formData["phone" + i] && formData["phone" + i] !== ""){
      //   if(mobileNumberUsed.indexOf(formData["phone" + i]) < 0 ){
      //     mobileNumberUsed.push(formData["phone" + i]);
      //   }else{
      //       isValidEmails = false;
      //       const selector = document.getElementById("traveller_phone" + i);
      //       if (selector) selector.focus();
      //       message.error('Phone number  '+ formData["phone" + i] + ' already assigned.');
      //       break;
      //   }
      // }
    }

    if (isValidEmails) this.saveDataAndNext(formData);
  }

  checkValidDateOfBirth(day, month, year) {
    return new Date(year + 18, month - 1, day) <= new Date();
  }

  async saveDataAndNext(formData) {
    let personalDetails = [];
    for (var i = 0; i < this.state.travellersObjectsArray.length; i++) {
      let res_statename = '';
      let res_state_id = '';
      this.props.usstates.map((state, index) => {
        if (state.stateId === formData['state' + i]) {
          res_statename = state.stateName;
          res_state_id = state.stateId;
        }
      });
      let traveller = {
        first_name: formData['first_name' + i],
        last_name: formData['last_name' + i],
        dob:
          formData['dob' + i] && formData['dob' + i]._d
            ? moment(formData['dob' + i]._d).format()
            : moment(formData['dob' + i]).format(),
        gender: formData['gender' + i],
        email: formData['email' + i],
        country: formData['country' + i],
        state: formData['state' + i],
        stateName: res_statename,
        stateid: res_state_id ? res_state_id : '',
        mobile_number: formData['phone' + i],
        rel_last_name: formData['rel_last_name' + i],
        rel_first_name: formData['rel_first_name' + i],
        relationship: formData['relationship' + i],
        rel_phone_number: formData['rel_phone_number' + i],
        company_name: formData['company_name' + i],
        is_bussiness_travelling: formData['check_company' + i],
        receiver_alert: this.state.receive_alerts,
        IsPrimary: i === 0 ? true : false,
        product_id: this.state.travellersObjectsArray[i].product_id,
        is_annual: false,
        isEmailExit: this.state.travellersObjectsArray[i].isEmailExit
      };
      personalDetails.push(traveller);
    }
    await this.props.SaveStep('3');
    await this.props.SavePersonalDetails(personalDetails);
    this.updatePlanPrice('update');

    var encodedParameters = window.location.search;
    var decodedParameterString = atob(encodedParameters.substring(1));
    decodedParameterString.substring(0, decodedParameterString.indexOf('reload_cap_order') - 1);
    if (this.state.userExist) {
      decodedParameterString += '&user_exist=true';
    }

    this.props.history.push(this.props.b2cBaseUrl + '/billing-summary?' + btoa(decodedParameterString));
  }

  _bindCountries() {
    return (
      this.props.countryTierInfo &&
      this.props.countryTierInfo != undefined &&
      this.props.countryTierInfo.map((country, index) => {
        if (country.countryId !== config.allCountriesCountryId) {
          return (
            <Option key={'con' + index} value={country.countryId}>
              {country.countryName}
            </Option>
          );
        }
      })
    );
  }

  handleCurrencyChange = async (e) => {
    var value = e.target.value;
    this.setState({ currency: e.target.value });

    var currencyData = '';
    await this.props.convertCurrency().then((resp) => {
      currencyData = resp;
    });
    if (this.state.currency == 'USD') {
      this.setState({ currencyRate: 1 });
      this.props.updateCurrency(value, 1);
    } else {
      this.setState({ currencyRate: currencyData.value });
      this.props.updateCurrency(value, currencyData.value);
    }
  };

  _bindStates() {
    return this.props.usstates.map((state, index) => {
      return (
        <Option key={'con' + index} value={state.stateId}>
          {state.stateName}
        </Option>
      );
    });
  }

  _bindProductRadio(idx, traveller) {
    return (
      <Radio.Group
        style={{ width: '100%' }}
        disabled={this.props.planTerm.isAnnual ? true : false}
        onChange={this.handlePlanChange}
      >
        <Row gutter={24}>
          {this.props.productInfo.map((product, index) => {
            let isCapAdvantage = this.props.productInfo.length - 1 === index ? true : false;
            return this._bindRadioOptions(traveller, product, idx, index, isCapAdvantage);
          })}
        </Row>
      </Radio.Group>
    );
  }
  _bindRadioOptions(traveller, product, indexTraveller, idxProduct, isCapAdvantage) {
    var priceText = false;
    var planIncluded = '';
    if (!this.props.planTerm.isAnnual) {
      var selectedProductId = traveller.product_id ? parseInt(traveller.product_id) : this.props.planType.productId;
      if (product.productId > selectedProductId) {
        priceText = true;
        var currentProductPrice = this.props.productPriceList.find(
          (x) =>
            x.tierId === parseInt(this.props.planTerm.tierValue) &&
            x.dayPackage === parseInt(this.props.planTerm.days) &&
            x.productId === parseInt(selectedProductId)
        );
        var upGradedProductPrice = this.props.productPriceList.find(
          (x) =>
            x.tierId === parseInt(this.props.planTerm.tierValue) &&
            x.dayPackage === parseInt(this.props.planTerm.days) &&
            x.productId === parseInt(product.productId)
        );
        var priceDiffernce =
          upGradedProductPrice && currentProductPrice
            ? upGradedProductPrice.retailPrice - currentProductPrice.retailPrice
            : 0;
      }
      if (product.productId === selectedProductId) {
        planIncluded = 'Selected plan';
      } else if (product.productId < selectedProductId) {
        planIncluded = 'Included';
      }
    } else {
      planIncluded = 'Included';
    }
    return (
      <Col xs={24} sm={8} md={8} lg={8} xl={8} style={{ textAlign: 'left' }} className='antd-check' key={idxProduct}>
        {isCapAdvantage !== false ? (
          <Radio disabled={true} value={indexTraveller + ',' + product.productId}>
            <Text>
              {product.name} {<br></br>}
              <span
                style={{
                  color: '#0986d6',
                  fontWeight: 'bold',
                  fontStyle: 'italic'
                }}
              >
                Included
              </span>
            </Text>
          </Radio>
        ) : (
          <Radio value={indexTraveller + ',' + product.productId}>
            {!priceText ? (
              <Text>
                {product.name}
                {<br></br>}
                <span
                  style={{
                    color: '#0683bb',
                    fontWeight: 'bold',
                    fontStyle: 'italic'
                  }}
                >
                  {planIncluded !== '' ? planIncluded : ''}
                </span>
              </Text>
            ) : (
              <Text>
                {' '}
                {product.name} {<br></br>}
                <span
                  style={{
                    color: '#0986d6',
                    fontWeight: 'bold',
                    fontStyle: 'italic'
                  }}
                >
                  Upgrade for only ${priceDiffernce.toFixed(2)}
                </span>
              </Text>
            )}
          </Radio>
        )}
      </Col>
    );
  }

  disabledPrimaryDate(current) {
    var currentDate = new Date();
    return (
      current &&
      current > moment(new Date(currentDate.getFullYear() - 18, currentDate.getMonth(), currentDate.getDay()))
    );
  }

  disableCompanionDate(current) {
    return current && current > moment().endOf('day');
  }

  setSameAsPrimary = (index) => {
    let form = this.props.form;
    let rel_first_name = form.getFieldValue('rel_first_name' + (index - 1));
    let rel_last_name = form.getFieldValue('rel_last_name' + (index - 1));
    let relationship = form.getFieldValue('relationship' + (index - 1));
    let rel_phone_number = form.getFieldValue('rel_phone_number' + (index - 1));
    if (rel_first_name == '' || rel_last_name == '' || relationship == '' || rel_phone_number == '') {
      this.setState({
        isEmergencyModalVisible: true,
        ['isSameAsPrimary_' + index]: false
      });
    } else {
      let isAlreadyChecked = this.state['isSameAsPrimary_' + index] || false;
      if (isAlreadyChecked) {
        form.setFieldsValue({
          ['rel_first_name' + index]: '',
          ['rel_last_name' + index]: '',
          ['relationship' + index]: '',
          ['rel_phone_number' + index]: ''
        });
        this.setState({ ['isSameAsPrimary_' + index]: false });
      } else {
        form.setFieldsValue({
          ['rel_first_name' + index]: form.getFieldValue('rel_first_name' + (index - 1)),
          ['rel_last_name' + index]: form.getFieldValue('rel_last_name' + (index - 1)),
          ['relationship' + index]: form.getFieldValue('relationship' + (index - 1)),
          ['rel_phone_number' + index]: form.getFieldValue('rel_phone_number' + (index - 1))
        });
        this.setState({ ['isSameAsPrimary_' + index]: true });
      }
    }
  };

  showEmergencyModal = () => {
    this.setState({ isEmergencyModalVisible: true });
  };

  hideEmergencyModal = () => {
    this.setState({ isEmergencyModalVisible: false });
  };

  disableSameAs = (index) => {
    this.state['isSameAsPrimary_' + index] = false;
  };
  handleContactExistance(rule, value, callback) {
    //const re = /^[0-9']+$/;
    const re = /^(?=[+0-9]*$)(?!.*[<>'"/;`%~@#$^*()_=[\]{}|\\,.?: -])/;
    var test = re.test(value);
    if (value !== '' && (value.length < 6 || test === false)) {
      callback('Please enter valid Phone Number');
    } else {
      callback();
    }
  }
  _subYears = (date, years) => {
    var d = new Date(date);
    d.setFullYear(d.getFullYear() - years);
    return d;
  };
  setDOBState = (date, index) => {
    let _dob = this.state.dob || [];
    _dob[index] = new Date(date);
    this.setState({ dob: _dob });
  };

  handleStateField(e, ind) {
    //console.log(e,ind);
    e == 249 ? this.setState({ isUsResident: true }) : this.setState({ isUsResident: false });
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div id='travellers-info'>
        <Modal
          title='Warning!'
          visible={this.state.isEmergencyModalVisible}
          onCancel={this.hideEmergencyModal}
          footer={[
            <Button key='cancel' type='primary' onClick={this.hideEmergencyModal}>
              Ok
            </Button>
          ]}
          style={{ marginTop: '60px' }}
        >
          <p>Please fill all the fields of primary emergency contact first.</p>
        </Modal>

        <Form onSubmit={this.handleSubmit}>
          {this.state.travellersObjectsArray.map((traveller, index) => (
            <div key={index}>
              <div className='wrap'>
                <div className='personal-info'>
                  <Row gutter={24}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={24}>
                      <h3>Traveler #{index + 1} Details</h3>
                      {index == 0 && (
                        <Row gutter={24}>
                          <Col span={24}>
                            <Checkbox
                              value='Core CAP<sup>TM</sup>'
                              checked={this.props.planTerm && this.props.planTerm.isAnnual ? true : false}
                              onChange={this.props.handleAnnualChnage}
                            >
                              <b>
                                Upgrade to our best value year-long plan for only{' '}
                                {this.props.planTerm.tierValue == 1 && this.props.planTerm.ustravel_states == undefined
                                  ? this.props.currency === 'CAD'
                                    ? '$' + (1315 / 365).toFixed(2)
                                    : '$' + (997 / 365).toFixed(2)
                                  : this.props.currency === 'CAD'
                                  ? '$' + (498 / 365).toFixed(2)
                                  : '$' + (385 / 365).toFixed(2)}{' '}
                                {this.props.currency} per day
                              </b>
                            </Checkbox>
                          </Col>
                        </Row>
                      )}
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12} className={index === 0 ? 'hide' : 'show'}>
                      <Button
                        type='link'
                        className='red-h'
                        style={{
                          float: 'right',
                          marginBottom: '15px',
                          color: '#cb0019'
                        }}
                        data-value={index}
                        onClick={this.removeTraveller}
                      >
                        [-] Remove
                      </Button>
                    </Col>
                  </Row>

                  <hr style={{ clear: 'both' }}></hr>
                  <Row gutter={24}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <Form.Item label={traveller.isEmailExit ? 'Existing Plan Holder E-mail' : 'E-mail'}>
                        {getFieldDecorator('email' + index, {
                          // initialValue: traveller.email,
                          initialValue: traveller.pressedBack ? traveller.email : '',
                          rules: [
                            {
                              type: 'email',
                              message: 'Please enter a valid Email!'
                            },
                            {
                              required: index === 0 && !this.state.userExist ? true : false,
                              message: 'Please enter your Email!'
                            }
                          ],
                          onChange: (e) => {
                            this.disableSameAs(index);
                            this.setState({ continueStatus: true });
                          }
                        })(
                          <Input
                            id={'email' + index}
                            placeholder={traveller.isEmailExit ? traveller.email : 'Enter e-mail'}
                            data-index={index}
                            onBlur={(e) => this._onBlur(e)}
                            onKeyDown={(e) => this._onKeydown(e)}
                            disabled={
                              index === 0 &&
                              this.props.isLoggedIn &&
                              traveller.email != '' &&
                              traveller.email !== null &&
                              this.state.userExist
                                ? true
                                : false
                            }
                          />
                        )}
                      </Form.Item>
                    </Col>

                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <Form.Item label={traveller.isEmailExit ? 'Existing Plan Holder First Name' : 'First Name'}>
                        {getFieldDecorator('first_name' + index, {
                          // initialValue: traveller.firstName,
                          initialValue: traveller.firstName ? traveller.firstName : '',
                          rules: [
                            {
                              required: traveller.isEmailExit || (index == 0 && this.state.userExist) ? false : true,
                              message: 'Please enter your first name!',
                              whitespace: false
                            },
                            {
                              pattern: new RegExp(/^(\S.*)[a-zA-Z-.']+$/),
                              message: 'Please enter a valid first name!'
                            }
                          ],
                          onChange: () => this.disableSameAs(index)
                        })(
                          <Input
                            placeholder={traveller.isEmailExit ? traveller.firstName : 'Enter First Name'}
                            maxLength={50}
                            disabled={
                              (index === 0 && (traveller.isEmailExit || this.state.userExist)) || traveller.isEmailExit
                                ? true
                                : false
                            }
                          />
                        )}
                      </Form.Item>
                    </Col>

                    <Col
                      xs={24}
                      sm={24}
                      md={12}
                      lg={12}
                      xl={12}
                      style={{ display: traveller.isEmailExit ? 'none' : '' }}
                    >
                      <Form.Item label={traveller.isEmailExit ? 'Existing Plan Holder Last Name' : 'Last Name'}>
                        {getFieldDecorator('last_name' + index, {
                          // initialValue: traveller.lastName,
                          initialValue: traveller.pressedBack || traveller.isEmailExit ? traveller.lastName : '',
                          rules: [
                            {
                              required: traveller.isEmailExit || (index == 0 && this.state.userExist) ? false : true,
                              message: 'Please enter your last name!',
                              whitespace: false
                            },
                            {
                              pattern: new RegExp(/^(\S.*)[a-zA-Z-.']+$/),
                              message: 'Please enter a valid last name!'
                            }
                          ],
                          onChange: () => this.disableSameAs(index)
                        })(
                          <Input
                            placeholder={traveller.isEmailExit ? traveller.lastName : 'Enter Last Name'}
                            maxLength={50}
                            disabled={
                              (index === 0 && (traveller.isEmailExit || this.state.userExist)) || traveller.isEmailExit
                                ? true
                                : false
                            }
                          />
                        )}
                      </Form.Item>
                    </Col>
                    <Col
                      xs={24}
                      sm={24}
                      md={12}
                      lg={12}
                      xl={12}
                      style={{ display: traveller.isEmailExit ? 'none' : '' }}
                    >
                      <Form.Item
                        label={
                          index === 0
                            ? traveller.isEmailExit
                              ? 'Existing Plan Holder DOB'
                              : 'Date of Birth (Must be 18+ to purchase)'
                            : 'Date of Birth'
                        }
                      >
                        {getFieldDecorator('dob' + index, {
                          rules: [
                            {
                              type: 'object',
                              required: traveller.isEmailExit ? false : true,
                              message: 'Please select Date of Birth!'
                            }
                          ],
                          initialValue:
                            this.state.dob && this.state.dob[index]
                              ? new Date(this.state.dob[index])
                              : traveller.dob !== '' && traveller.dob !== 'Invalid date'
                              ? new Date(traveller.dob)
                              : null,
                          onChange: (date) => {
                            this.disableSameAs(index);
                            this.setDOBState(date, index);
                          }
                        })(
                          <DatePicker
                            placeholderText={traveller.isEmailExit ? 'Existing Plan Holder DOB' : ''}
                            selected={
                              this.state.dob && this.state.dob[index]
                                ? new Date(this.state.dob[index])
                                : traveller.dob !== '' && traveller.dob !== 'Invalid date'
                                ? new Date(traveller.dob)
                                : null
                            }
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode='select'
                            maxDate={index > 0 ? new Date() : this._subYears(new Date(), 18)}
                            disabled={traveller.isEmailExit ? true : false}
                            autoComplete='off'
                            style={{ margin: '0' }}
                          />
                        )}
                      </Form.Item>
                    </Col>

                    <Col
                      xs={24}
                      sm={24}
                      md={12}
                      lg={12}
                      xl={12}
                      style={{ display: traveller.isEmailExit ? 'none' : '' }}
                    >
                      <Form.Item label={traveller.isEmailExit ? 'Existing Plan Holder Gender' : 'Gender'}>
                        {getFieldDecorator('gender' + index, {
                          rules: [
                            {
                              required: traveller.isEmailExit ? false : true,
                              message: 'Please select your Gender!'
                            }
                          ],
                          onChange: () => this.disableSameAs(index),
                          initialValue: traveller.pressedBack ? traveller.gender : ''
                        })(
                          <Select
                            showSearch
                            placeholder={traveller.isEmailExit ? 'Existing Plan Holder Gender' : 'Please select gender'}
                            style={{ height: '48px', marginBottom: '20px' }}
                            disabled={traveller.isEmailExit ? true : false}
                            filterOption={(input, option) =>
                              option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {this.props.genderlist.map(function(gender) {
                              return (
                                <Option key={gender.id} value={gender.id}>
                                  {gender.name}
                                </Option>
                              );
                            })}
                          </Select>
                        )}
                      </Form.Item>
                    </Col>

                    <Col
                      xs={24}
                      sm={24}
                      md={12}
                      lg={12}
                      xl={12}
                      style={{ display: traveller.isEmailExit ? 'none' : '' }}
                    >
                      <Form.Item
                        label={
                          traveller.isEmailExit
                            ? 'Existing Plan Holder Phone Number'
                            : 'Phone Number (Ex: +1xxxxxxxxxx)'
                        }
                      >
                        {getFieldDecorator('phone' + index, {
                          // initialValue: traveller.mobile_number,
                          initialValue: traveller.pressedBack ? traveller.phone : '',
                          rules: [
                            {
                              required: index === 0 && !this.state.userExist && !traveller.isEmailExit ? true : false,
                              message: 'Please input your phone number!'
                            },
                            {
                              validator: this.handleContactExistance
                            }
                          ],
                          onChange: () => this.disableSameAs(index)
                        })(
                          <Input
                            placeholder={traveller.isEmailExit ? 'Existing Phone Number' : 'Enter Phone Number'}
                            style={{ width: '100%' }}
                            type='text'
                            disabled={traveller.isEmailExit ? true : false}
                            // disabled={
                            //   index === 0 ? (this.state.userExist || (traveller.mobile_number != "" &&
                            //   traveller.mobile_number !== null || traveller.isEmailExit)  ? true : false) : (traveller.isEmailExit ? true : false)
                            // }
                          />
                        )}
                      </Form.Item>
                    </Col>

                    <Col
                      xs={24}
                      sm={24}
                      md={12}
                      lg={12}
                      xl={12}
                      style={{ display: traveller.isEmailExit ? 'none' : '' }}
                    >
                      <Form.Item label={traveller.isEmailExit ? 'Existing Plan Holder Home Country' : 'Home Country'}>
                        {getFieldDecorator('country' + index, {
                          // initialValue: this.props.rcid,
                          // initialValue: traveller.isEmailExit ? "Existing Plan Holder Home Country" : "",
                          initialValue:
                            traveller.isEmailExit || traveller.pressedBack
                              ? traveller.country
                              : this.state.desCountryCode == 'US'
                              ? 249
                              : '',
                          rules: [
                            {
                              required: !this.state.userExist && !traveller.isEmailExit ? true : false,
                              message: 'Please select your country!'
                            }
                          ]
                          //onChange: () => this.disableSameAs(index),
                          //onChange:  () => this.handleStateField
                        })(
                          <Select
                            showSearch
                            onChange={(e) => this.handleStateField(e, index)}
                            placeholder={
                              traveller.isEmailExit ? 'Existing Plan Holder Country' : 'Please select a country'
                            }
                            te
                            style={{ height: '48px', marginBottom: '23px' }}
                            disabled={
                              traveller.isEmailExit ||
                              (this.state.desCountryCode == 'US' && this.state.desStateCode != undefined)
                                ? true
                                : false
                            }
                            // disabled={index === 0 ? (this.state.userExist || traveller.isEmailExit ? true : false) : false}
                            filterOption={(input, option) =>
                              option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {this._bindCountries()}
                          </Select>
                        )}
                      </Form.Item>
                      {/* {(this.state.isUsResident &&
                        !traveller.isEmailExit &&
                        this.state.desCountryCode == "US") ||
                      (traveller.pressedBack &&
                        this.state.isUsResident &&
                        this.state.desCountryCode == "US" &&
                        traveller.stateName != "") ? ( */}

                      {this.state.desCountryCode == 'US' && this.state.desStateCode != undefined ? (
                        <Form.Item
                          label={traveller.isEmailExit ? 'Existing Plan Holder State' : 'State'}
                          style={{ display: traveller.isEmailExit ? 'none' : '' }}
                        >
                          {getFieldDecorator('state' + index, {
                            // initialValue: this.state.resUsState,
                            initialValue: traveller.isEmailExit || traveller.pressedBack ? traveller.stateid : '',
                            rules: [
                              {
                                required: index === 0 && !this.state.userExist && !traveller.isEmailExit ? true : false,
                                message: 'Please select your State!'
                              }
                            ],
                            onChange: () => this.disableSameAs(index)
                          })(
                            <Select
                              showSearch
                              placeholder={traveller.isEmailExit ? 'Existing State' : 'Please select a state'}
                              style={{ height: '48px', marginBottom: '23px' }}
                              disabled={
                                index === 0
                                  ? this.state.userExist || traveller.isEmailExit
                                    ? true
                                    : false
                                  : traveller.isEmailExit
                                  ? true
                                  : false
                              }
                              filterOption={(input, option) =>
                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {this._bindStates()}
                            </Select>
                          )}
                        </Form.Item>
                      ) : (
                        ''
                      )}
                    </Col>
                    <Col
                      xs={24}
                      sm={24}
                      md={24}
                      lg={24}
                      xl={24}
                      style={{ display: traveller.isEmailExit ? '' : 'none' }}
                    >
                      <p style={{ fontWeight: 'bold', fontStyle: 'italic' }}>
                        Note: For your privacy and convenience, we will retrieve the additional information from your
                        existing profile.
                      </p>
                    </Col>
                  </Row>

                  <Row gutter={24}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <Form.Item>
                        {getFieldDecorator('check_company' + index, {
                          valuePropName: 'checked',
                          //initialValue: traveller.is_bussiness_travelling,
                          initialValue: traveller.pressedBack ? traveller.is_bussiness_travelling : ''
                        })(
                          <Checkbox
                            data-id={'company' + index}
                            id={'chk_company' + index}
                            onChange={this.onCheckCompanyChange}
                          >
                            <span style={{ color: 'black' }}>Are you traveling for business?</span>
                          </Checkbox>
                        )}
                      </Form.Item>
                    </Col>

                    {traveller.is_bussiness_travelling ? (
                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Form.Item label='Company Name'>
                          {getFieldDecorator('company_name' + index, {
                            // initialValue: traveller.company_name,
                            initialValue: traveller.pressedBack ? traveller.company_name : '',
                            rules: [
                              {
                                required: true,
                                message: 'Please enter organization name!',
                                whitespace: false
                              },
                              {
                                pattern: new RegExp(/^[A-Za-z0-9](?!.*?\s$)[A-Za-z0-9\s]{0,500}$/),
                                message: 'Please enter organization name!'
                              }
                            ]
                          })(<Input maxLength={20} />)}
                        </Form.Item>
                      </Col>
                    ) : (
                      ''
                    )}
                  </Row>
                  {/* 
                  <Row gutter={24}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <h3>Emergency Contact</h3>
                    </Col>
                    {index > 0 ? 
                      <Col xs={24} sm={24} md={12} lg={12} xl={12} style={{textAlign: "right"}}>
                        <div style={{float: "right"}}>
                          <input 
                            type="checkbox"
                            onChange={() => this.setSameAsPrimary(index)}
                            checked={this.state["isSameAsPrimary_"+index]}
                            style={{ height: "20px", float: "left", width: "20px", margin: "0 10px", cursor: "pointer"}}
                          />
                            Same as primary emergency contact
                        </div>
                      </Col>
                    : "" }
                  </Row>
                  <Row gutter={24}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <Form.Item label="First Name">
                        {getFieldDecorator("rel_first_name" + index, {
                          initialValue: traveller.rel_first_name,
                          rules: [
                            {
                              required: index === 0 ? true : false,
                              message: "Please enter emergency contact’s first name!",
                              whitespace: false
                            },
                            {
                              pattern: new RegExp(/^(\S.*)[a-zA-Z-.']+$/),
                              message: "Please enter a valid first name!"
                            }
                          ]
                        })(
                          <Input
                            maxLength={50}
                            disabled={
                              index >= 1 && /* traveller.isEmailExit && */
                  /*this.state["isSameAsPrimary_"+index] ? true : false
                            }
                          />
                        )}
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <Form.Item label="Last Name">
                        {getFieldDecorator("rel_last_name" + index, {
                          initialValue: traveller.rel_last_name,
                          rules: [
                            {
                              required: index === 0 ? true : false,
                              message: "Please enter emergency contact’s last name!",
                              whitespace: false
                            },
                            {
                              pattern: new RegExp(/^(\S.*)[a-zA-Z-.']+$/),
                              message: "Please enter a valid last name!"
                            }
                          ]
                        })(
                          <Input
                            maxLength={50}
                            disabled={
                              index >= 1 && this.state["isSameAsPrimary_"+index] ? true : false
                            }
                          />
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={24}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <Form.Item label="Relationship">
                        {getFieldDecorator("relationship" + index, {
                          initialValue: traveller.relationship,
                          rules: [
                            {
                              required: index === 0 ? true : false,
                              message: "Please enter relationship!",
                              whitespace: false
                            },
                            {
                              pattern: new RegExp(/^(\S.*)[a-zA-Z-.']+$/),
                              message: "Please enter a valid relation name!"
                            }
                          ]
                        })(
                          <Input
                            disabled={
                              index >= 1 && this.state["isSameAsPrimary_"+index] ? true : false
                            }
                          />
                        )}
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <Form.Item label="Primary Phone (Ex: +1xxxxxxxxxx)">
                        {getFieldDecorator("rel_phone_number" + index, {
                          initialValue: traveller.rel_phone_number,
                          rules: [
                            {
                              required: index === 0 ? true : false,
                              message: "Please enter emergency contact’s primary phone number!",
                              whitespace: false
                            },
                            {
                              validator: this.handleContactExistance
                            },
                            
                          ]
                        })(
                          <Input
                            type="text"
                            disabled={
                              index >= 1 && this.state["isSameAsPrimary_"+index] ? true : false
                            }
                          />
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                          */}
                </div>
              </div>
            </div>
          ))}
          {/* add Traveller */}
          <div className='add-traveler'>
            <div className='wrap'>
              <Button onClick={this.appendTravellerData}>[+] Add Additional Travelers</Button>
            </div>
          </div>
          {/* add Traveller ends */}
          {/*  traveller alerts */}
          <div className='wrap'>
            <div className='traveller-alerts'>
              {/* title */}
              <div className='row'>
                <div className='full-width'>
                  <h3>Opt in to take advantage of SMS travel alerts.</h3>
                  <div className='toogle-button'>
                    Yes
                    <label className='switch'>
                      <input type='checkbox' onChange={this.handleSwitchChange} />
                      <span className='slider round'></span>
                    </label>
                    No
                  </div>
                  <div className='clear-fix'></div>
                  <hr />
                </div>
              </div>
              {/* title ends */}
              {/* desc start */}
              <div className='row'>
                <div className='full-width'>
                  <p>
                    Receive real time late breaking travel alerts and emails to warn you of any events that could affect
                    your safety or mobility at your destination. Travel Alerts were designed to provide you with useful
                    information to help you prepare for or modify your travel plans and inform you of any events at your
                    destination that could impact your personal health or safety. You can unsubscribe from travel alerts
                    at any time. (Recommended)
                  </p>
                  <p>
                  You agree to receive automated transactional messages. 
                  Terms and Privacy Policy can be found at <a href="https://www.captravelassistance.com/privacy-policy/">Privacy Policy - CAP Travel Assistance.</a> 
                  Txt and data rates may apply. Reply STOP to end or HELP for help.
                  </p>
                </div>
              </div>
              {/* desc ends */}
            </div>
          </div>
          {/* traveller alerts ends */}
          {/* next and back button*/}
          <div className='next-back'>
            <div className='wrap'>
              <Form.Item style={{ display: 'inline-block' }}>
                <Button className='next' htmlType='submit'>
                  Next
                </Button>
              </Form.Item>
            </div>
          </div>
          {/* next and back button*/}
        </Form>
      </div>
    );
  }
}

export const WrappedRegistrationForm = Form.create({ name: 'traveller' })(RegistrationForm);
