import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import * as itiniaryActions from "../../../redux/actions/itinerary-actions";
import * as userActions from "../../../redux/actions/user-actions";
import * as paginationActions from "../../../redux/actions/pagination-actions";
import { connect } from "react-redux";

import IntlMessages from "../../../services/intlMesseges";
import { EditCell } from "../../shared/components/helperCells";
import config from "../../../../../config";
import * as UserServices from "../../../services/user-service";
import * as Permission from "../../../services/permissions";
import Axios from "axios";
import { notifyUser } from "../../../services/notification-service";
import {
  Form,
  Input,
  Row,
  Col,
  Button,
  Typography,
  Table,
  Tag,
  Icon,
  Upload,
  message,
  Spin,
  Modal,
} from "antd";
import moment from "moment";
import OrgTree from "../../shared/components/orgTree";
const todaydate = new Date();
class UpdateItinerary extends Component {
  constructor(props) {
    super(props);
    this.module = "itineraries";
    this.state = {
      formLayout: undefined,
      fromDate: "",
      fromlocation: "",
      tolocation: "",
      todate: "",
      departuredate: "",
      flightnumber: "",
      isActive: true,
      userid: 0,
      loading: false,
      data: [],
      pagination: { pageSize: 10, current: 1 },
      filters: {},
      airportData: [],
      stateuser: 1,
      itineraryData: {},
      uploadingEmployees: false,
    };
  }
  getSelectedFilterValue = (index) => {
    return (
      (this.props.paginginfo[this.module] &&
        this.props.paginginfo[this.module].filter &&
        this.props.paginginfo[this.module].filter[index]) ||
      null
    );
  };
  editItem = (orgid, divid, id) => {
    if (Permission.isRole("Membership") || Permission.isRole("Employee")) {
      if (
        JSON.parse(localStorage.getItem("user")).isActiveMembership === false
      ) {
        this.showModal();
      } else {
        this.props.history.push(
          "../../../edit/" + orgid + "/" + divid + "/" + id
        );
      }
    } else {
      this.props.history.push(
        "../../../edit/" + orgid + "/" + divid + "/" + id
      );
    }
  };
  showModal = () => {
    this.setState({
      planExp: true,
    });
  };
  csvUploadProps = () => {
    let _this = this;
    let token = UserServices.getToken();
    let _headers = { "Content-Type": "multipart/form-data" };
    if (token)
      _headers["authorization"] = token ? "Bearer " + token : undefined;
    return {
      name: "file",
      showUploadList: false,
      headers: _headers,
      // action: config.API1 + "/api/BulkUploadTravelerItinerary",
      accept: ".csv",
      beforeUpload: (file) => {
        let ext = file.name.split(".");
        ext = ext.pop();
        if (ext == "csv") {
          const formData = new FormData();
          formData.append("file", file);
          let token = UserServices.getToken();
          let _headers = { "Content-Type": "multipart/form-data" };
          if (token)
            _headers["authorization"] = token ? "Bearer " + token : undefined;

          Axios({
            method: "post",
            url:
              config.API1 +
              "/api/BulkUploadItinerary/" +
              this.props.match.params.id,
            data: formData,
            headers: _headers,
          })
            .then(function(_response) {
              if (_response.data && _response.data.data) {
                notifyUser(_response.data.data.message, "success");
              }
              _this.setState({ uploadingEmployees: false });
              _this.handleTableChange({ current: 1, pageSize: 10 }, {}, {});
            })
            .catch(function(_response) {
              if (_response.response && _response.response.data) {
                notifyUser(_response.response.data.Message, "error");
              } else {
                notifyUser("File upload failed.", "error");
              }
              _this.setState({ uploadingEmployees: false });
              _this.handleTableChange({ current: 1, pageSize: 10 }, {}, {});
            });
        } else {
          message.error("Please select file only from accepted file types.");
          return false;
        }
      },
      onStart: (_file) => {
        _this.setState({ uploadingEmployees: true });
      },
    };
  };
  getHeaderKeys = () => {
    let datacolumns1 = [
      {
        title: <IntlMessages id="admin.userlisting.email"></IntlMessages>,
        dataIndex: "travellerEmail",
        ...this.getColumnSearchProps("travellerEmail"),
      },
      {
        title: (
          <IntlMessages id="admin.itinerary.tripfromLocation"></IntlMessages>
        ),
        dataIndex: "fromLocationName",
        ...this.getColumnSearchProps("fromLocationName"),
        filteredValue: this.getSelectedFilterValue("fromLocationName"),
      },
      {
        title: <IntlMessages id="admin.itinerary.tripfromdate"></IntlMessages>,
        dataIndex: "fromDate",
        render: (data, record) => (
          <span>
            {record.fromDate
              ? moment(record.fromDate).format("MM/DD/YYYY HH:mm:ss")
              : ""}
          </span>
        ),
      },
      {
        title: (
          <IntlMessages id="admin.itinerary.triptolocation"></IntlMessages>
        ),
        dataIndex: "toLocationName",
        ...this.getColumnSearchProps("toLocationName"),
        filteredValue: this.getSelectedFilterValue("toLocationName"),
      },
      {
        title: <IntlMessages id="admin.itinerary.triptodate"></IntlMessages>,
        dataIndex: "toDate",

        render: (data, record) => (
          <span>
            {record.toDate
              ? moment(record.toDate).format("MM/DD/YYYY HH:mm:ss")
              : ""}
          </span>
        ),
      },
      {
        title: <IntlMessages id="admin.itinerary.airlinecode"></IntlMessages>,
        dataIndex: "airlineCode",
        ...this.getColumnSearchProps("airlineCode"),

        render: (data, record) => <span>{record.airlineCode}</span>,
      },
      {
        title: <IntlMessages id="admin.itinerary.flightnumber"></IntlMessages>,
        dataIndex: "flightNumber",
        ...this.getColumnSearchProps("flightNumber"),

        render: (data, record) => <span>{record.flightNumber}</span>,
      },
      // ? <Tag color="green">Active</Tag> : <Tag color="red">Inactive</Tag>}
      {
        title: <IntlMessages id="admin.userlisting.status"></IntlMessages>,
        dataIndex: "isActive",

        render: (data, record) => (
          <span>
            {record.toDate ? (
              new Date() > new Date(record.toDate) ? (
                <Tag color="red">Inactive</Tag>
              ) : (
                <Tag color="green">Active</Tag>
              )
            ) : (
              ""
            )}
          </span>
        ),
      },
    ];
    let datacolumns = [
      {
        title: <IntlMessages id="admin.userlisting.email"></IntlMessages>,
        dataIndex: "travellerEmail",
        ...this.getColumnSearchProps("travellerEmail"),
      },
      {
        title: (
          <IntlMessages id="admin.itinerary.tripfromLocation"></IntlMessages>
        ),
        dataIndex: "fromLocationName",
        ...this.getColumnSearchProps("fromLocationName"),
      },
      {
        title: <IntlMessages id="admin.itinerary.tripfromdate"></IntlMessages>,
        dataIndex: "fromDate",
        render: (data, record) => (
          <span>
            {record.fromDate
              ? moment(record.fromDate).format("MM/DD/YYYY HH:mm:ss")
              : ""}
          </span>
        ),
      },
      {
        title: (
          <IntlMessages id="admin.itinerary.triptolocation"></IntlMessages>
        ),
        dataIndex: "toLocationName",
        ...this.getColumnSearchProps("toLocationName"),
      },
      {
        title: <IntlMessages id="admin.itinerary.triptodate"></IntlMessages>,
        dataIndex: "toDate",

        render: (data, record) => (
          <span>
            {record.toDate
              ? moment(record.toDate).format("MM/DD/YYYY HH:mm:ss")
              : ""}
          </span>
        ),
      },
      {
        title: <IntlMessages id="admin.itinerary.airlinecode"></IntlMessages>,
        dataIndex: "airlineCode",
        ...this.getColumnSearchProps("airlineCode"),

        render: (data, record) => <span>{record.airlineCode}</span>,
      },
      {
        title: <IntlMessages id="admin.itinerary.flightnumber"></IntlMessages>,
        dataIndex: "flightNumber",
        ...this.getColumnSearchProps("flightNumber"),

        render: (data, record) => <span>{record.flightNumber}</span>,
      },
      // ? <Tag color="green">Active</Tag> : <Tag color="red">Inactive</Tag>}
      {
        title: <IntlMessages id="admin.userlisting.status"></IntlMessages>,
        dataIndex: "isActive",

        render: (data, record) => (
          <span>
            {record.toDate ? (
              moment(new Date()).format("MM/DD/YYYY HH:mm:ss") >
              moment(record.toDate).format("MM/DD/YYYY HH:mm:ss") ? (
                <Tag color="red">Inactive</Tag>
              ) : (
                <Tag color="green">Active</Tag>
              )
            ) : (
              ""
            )}
          </span>
        ),
      },
    ];
    if (
      Permission.isRole("CSR") &&
      this.props.match &&
      this.props.match.params.id == "1"
    ) {
    } else {
      datacolumns.push({
        title: <IntlMessages id="admin.userlisting.action" />,
        rowKey: "action",
        // width: "25%",
        render: (_text, record) => (
          <span>
            <EditCell
              onEditCell={() => {
                this.editItem(
                  this.props.match.params.orgid,
                  this.props.match.params.id,
                  record.travellerIternaryId
                );
              }}
            />
          </span>
        ),
      });
      datacolumns1.push({
        title: <IntlMessages id="admin.userlisting.action" />,
        rowKey: "action",
        // width: "25%",
        render: (_text, record) => (
          <span>
            <EditCell
              onEditCell={() => {
                this.editItem(
                  this.props.match.params.orgid,
                  this.props.match.params.id,
                  record.travellerIternaryId
                );
              }}
            />
          </span>
        ),
      });
    }
    if (this.props.match.params.id === "1") {
      return datacolumns1;
    } else {
      return datacolumns;
    }
  };
  onChange = (selected, s) => {
    this.props.history.push("../" + selected + "/");
    /* this.props.getItineraryByIdxForAdmin(selected, {}).then(data => {
      if (data.data) {
        this.setState({ data: data.data });
      }
    }); */
  };
  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <div style={{ padding: 8 }}>
          <Input
            ref={(node) => {
              this.searchInput = node;
            }}
            value={selectedKeys}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? e.target.value.trimLeft() : "")
            }
            onPressEnter={() =>
              this.handleSearch(
                selectedKeys,
                confirm,
                dataIndex,
                clearFilters,
                setSelectedKeys
              )
            }
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Button
            type="primary"
            onClick={() =>
              this.handleSearch(
                selectedKeys,
                confirm,
                dataIndex,
                clearFilters,
                setSelectedKeys
              )
            }
            disabled={
              selectedKeys != "" && selectedKeys !== null ? false : true
            }
            icon="search"
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            <IntlMessages id="admin.search" />
          </Button>
          <Button
            disabled={
              selectedKeys != "" && selectedKeys !== null ? false : true
            }
            onClick={() => this.handleReset(clearFilters, dataIndex)}
            size="small"
            style={{ width: 90 }}
          >
            <IntlMessages id="admin.reset" />
          </Button>
        </div>
      );
    },
    filterIcon: (filtered) => (
      <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
  });

  handleSearch = (
    selectedKeys,
    confirm,
    dataIndex,
    clearFilters,
    setSelectedKeys
  ) => {
    if (selectedKeys == "") {
      return false;
    }

    this.setState({ searchText: selectedKeys });
    let filters = this.state.filters;
    filters[dataIndex] = {
      val: selectedKeys,
      clearf: clearFilters,
      filter: true,
      setSelectedKeys: setSelectedKeys,
      confirm: confirm,
      auto: true,
    };
    this.setState({ filters: filters });
    this.props.updateFilters({ module: this.module, filters: filters });
    confirm();
  };

  handleReset = (clearFilters, dataIndex) => {
    clearFilters();
    let filters = this.state.filters;
    if (filters[dataIndex]) {
      if (
        filters[dataIndex].setSelectedKeys &&
        typeof filters[dataIndex].setSelectedKeys === "function"
      ) {
        filters[dataIndex].setSelectedKeys("");
        //filters[dataIndex].confirm();
      }
    }
    if (filters[dataIndex] && !filters[dataIndex].auto) {
      delete this.props.paginginfo[this.module].filter[dataIndex];
      this.handleTableChange(
        { current: 1, pageSize: 10 },
        this.props.paginginfo[this.module].filter,
        {}
      );
    }
    filters[dataIndex] = { val: "", clearf: "", filter: false };
    this.setState({ filters: filters });
    this.props.updateFilters({ module: this.module, filters: filters });
    this.setState({ searchText: "" });
  };

  async componentDidMount() {
    let currentUser = UserServices.getUser();
    this.setState({ userid: currentUser.userID });

    if (
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.id &&
      this.props.match.params.id === "1"
    ) {
      this.props.clearPaginationExceptMe(this.module);
    }
    if (this.props.paginginfo && this.props.paginginfo[this.module]) {
      this.handleTableChange(
        this.props.paginginfo[this.module].pagination,
        this.props.paginginfo[this.module].filter,
        {},
        true
      );
      if (this.props.paginginfo[this.module].filters) {
        let filters = this.props.paginginfo[this.module].filters;
        Object.keys(filters).map((k) => {
          filters[k].auto = false;
        });
        this.setState({ filters: filters });
      }
    } else {
      this.handleTableChange({ current: 1, pageSize: 10 }, {}, {}, true);
    }
  }
  handleTableChange = (pagination, filters, sorter, manual) => {
    if (filters === undefined) filters = {};
    Object.keys(filters).map((key) => {
      if (
        !filters[key] ||
        (Array.isArray(filters[key]) && filters[key].length === 0)
      ) {
        delete filters[key];
      }
    });
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    if (manual !== true) {
      this.props.updatePaginationRemoveOld({
        module: this.module,
        filter: filters,
        pagination: {
          current: pagination.current,
          pageSize: pagination.pageSize,
        },
      });
    }
    this.setState({ data: [] });
    this.setState({
      loading: true,
    });
    if (
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.id &&
      this.props.match.params.id !== "new"
    ) {
      if (parseInt(this.props.match.params.id) === 1) {
        this.props
          .getItinerary({
            filters: filters,
            pagination: {
              page: pagination.current,
              pageSize: pagination.pageSize,
            },
            sorter: sorter,
          })
          .then((resp) => {
            pager.total = resp.paging.totalRecords;
            this.setState({
              loading: false,
              data: resp.data,
              pagination: pager,
            });
          })
          .catch((ex) => {
            this.setState({ loading: false });
          });
      } else {
        this.props
          .getItineraryByIdxForAdmin(this.props.match.params.id, {
            filters: filters,
            pagination: {
              page: pagination.current,
              pageSize: pagination.pageSize,
            },
            sorter: sorter,
          })
          .then((resp) => {
            pager.total = resp.paging.totalRecords;
            this.setState({
              loading: false,
              data: resp.data,
              pagination: pager,
            });
          })
          .catch((ex) => {
            this.setState({ loading: false });
          });
      }
    }
    // }
  };

  onSwitchChange = (checked, e) => {
    this.setState({ isActive: checked });
  };

  downloadSampleCsv = () => {
    if (Permission.isRole("Membership")) {
      if (
        JSON.parse(localStorage.getItem("user")).isActiveMembership === false
      ) {
        this.showModal();
      } else {
        window.location.href = config.API1 + "/SampleCSV/TravelerItinerary.csv";
      }
    } else {
      window.location.href = config.API1 + "/SampleCSV/TravelerItinerary.csv";
    }
  };
  redirectPlanRenewalPage = () => {
    //this.props.history.push("/cap/purchase");
    window.location.href = "https://www.captravelassistance.com/";
  };
  HidePopup = () => {
    this.setState({ planExp: false });
  };
  render() {
    let _state = this.state;
    let _this = this;
    let filtertag = Object.keys(this.state.filters).map(function(key1) {
      let keyLabel = _this.getHeaderKeys().find((el) => el.dataIndex === key1);
      if (
        keyLabel &&
        keyLabel.title &&
        keyLabel.title.props &&
        keyLabel.title.props.id
      ) {
        keyLabel = <IntlMessages id={keyLabel.title.props.id} />;
      } else {
        keyLabel = key1;
      }
      if (_state.filters[key1].filter) {
        return (
          <span>
            <Button
              key={key1}
              type="primary"
              onClick={() =>
                _this.handleReset(_state.filters[key1].clearf, key1)
              }
            >
              {keyLabel} : {_state.filters[key1].val}
              <Icon type="close" />
            </Button>
            &nbsp;
          </span>
        );
      }
    });
    return (
      <div>
        <Modal
          title="Plan Expired."
          visible={this.state.planExp}
          onOk={this.redirectPlanRenewalPage}
          onCancel={this.HidePopup}
          footer={
            Permission.isRole("Membership") ? (
              [
                <Button key="cancel" onClick={this.HidePopup}>
                  Cancel
                </Button>,
                <Button key="submit" onClick={this.redirectPlanRenewalPage}>
                  Buy Now
                </Button>,
              ]
            ) : (
              <Button key="cancel" onClick={this.HidePopup}>
                Ok
              </Button>
            )
          }
        >
          {Permission.isRole("Employee") ? (
            <p>
              You do not have any CAP plan assigned. Please contact your
              division manager / travel manager.
            </p>
          ) : (
            <p>Your CAP plan has been expired. Please buy a new plan.</p>
          )}
        </Modal>
        <Spin
          tip="Uploading. Please wait..."
          spinning={this.state.uploadingEmployees}
        >
          <Row gutter={24}>
            {this.props &&
            this.props.match &&
            this.props.match.params &&
            this.props.match.params.id === "1" &&
            Permission.isRole("Administrator") ? (
              <Col xs={24} sm={12} md={8}>
                <Typography.Title level={4}>
                  {" "}
                  <IntlMessages id="user.list.manageB2Citinerary" />
                </Typography.Title>
              </Col>
            ) : (
              <Col xs={24} sm={12} md={8}>
                <Typography.Title level={4}>
                  {" "}
                  <IntlMessages id="user.list.manageitinerary" />
                </Typography.Title>
              </Col>
            )}

            {!Permission.isRole("Employee") &&
            !Permission.isRole("CSR") &&
            !Permission.isRole(["Employee"]) ? (
              <Col xs={24} sm={12} md={16}>
                <ul className="upload-xlx">
                  <li>
                    <Button onClick={() => this.downloadSampleCsv()}>
                      <Icon type="download" />{" "}
                      <IntlMessages id="admin.organization.download.csv" />
                    </Button>
                  </li>
                  {JSON.parse(localStorage.getItem("user"))
                    .isActiveMembership === false &&
                  Permission.isRole("Membership") ? (
                    <Button onClick={this.showModal}>
                      <Icon type="upload" />{" "}
                      <IntlMessages id="admin.itinerary.upload.csv" />
                    </Button>
                  ) : (
                    <li>
                      <Upload {...this.csvUploadProps()}>
                        <Button>
                          <Icon type="upload" />{" "}
                          <IntlMessages id="admin.itinerary.upload.csv" />
                        </Button>
                      </Upload>
                    </li>
                  )}
                  <li>
                    <Button
                      type="primary"
                      onClick={() =>
                        this.editItem(
                          this.props.match.params.orgid,
                          this.props.match.params.id,
                          "new"
                        )
                      }
                      className="def-blue"
                      s
                    >
                      <IntlMessages id="admin.userlisting.addnew" />
                    </Button>
                  </li>
                  <li>
                    {this.props.match &&
                    this.props.match.params &&
                    this.props.match.params.id !== "1" ? (
                      <Button
                        type="primary"
                        onClick={() => this.props.history.goBack()}
                      >
                        <IntlMessages id="admin.button.back" />
                      </Button>
                    ) : (
                      ""
                    )}
                  </li>
                </ul>
              </Col>
            ) : !Permission.isRole("CSR") ? (
              <Col xs={24} sm={12} md={16} lg={16}>
                <ul className="upload-xlx">
                  <li>
                    <Button
                      type="primary"
                      onClick={() =>
                        this.editItem(
                          this.props.match.params.orgid,
                          this.props.match.params.id,
                          "new"
                        )
                      }
                      className="def-blue"
                      s
                    >
                      <IntlMessages id="admin.userlisting.addnew" />
                    </Button>
                  </li>
                  <li>
                    {this.props.match &&
                    this.props.match.params &&
                    this.props.match.params.id !== "1" ? (
                      <Button
                        type="primary"
                        onClick={() => this.props.history.goBack()}
                      >
                        <IntlMessages id="admin.button.back" />
                      </Button>
                    ) : (
                      ""
                    )}
                  </li>
                </ul>
              </Col>
            ) : (
              ""
            )}
            {Permission.isRole("CSR") &&
            this.props.match &&
            this.props.match.params &&
            this.props.match.params.id !== "1" ? (
              <Col xs={24} sm={12} md={16} lg={16}>
                <ul className="upload-xlx">
                  <li>
                    <Button onClick={() => this.downloadSampleCsv()}>
                      <Icon type="download" />{" "}
                      <IntlMessages id="admin.organization.download.csv" />
                    </Button>
                  </li>

                  <li>
                    <Upload {...this.csvUploadProps()}>
                      <Button>
                        <Icon type="upload" />{" "}
                        <IntlMessages id="admin.itinerary.upload.csv" />
                      </Button>
                    </Upload>
                  </li>
                  <li>
                    <Button
                      type="primary"
                      onClick={() =>
                        this.editItem(
                          this.props.match.params.orgid,
                          this.props.match.params.id,
                          "new"
                        )
                      }
                      className="def-blue"
                      s
                    >
                      <IntlMessages id="admin.userlisting.addnew" />
                    </Button>
                  </li>
                  <li>
                    {this.props.match &&
                    this.props.match.params &&
                    this.props.match.params.id !== "1" ? (
                      <Button
                        type="primary"
                        onClick={() => this.props.history.goBack()}
                      >
                        <IntlMessages id="admin.button.back" />
                      </Button>
                    ) : (
                      ""
                    )}
                  </li>
                </ul>
              </Col>
            ) : (
              ""
            )}
          </Row>
          <hr />
          {this.props.match &&
          this.props.match.params &&
          this.props.match.params.id !== "1" &&
          !this.state.loading ? (
            // <Row gutter={24}>
            //   <Col
            //     xs={24}
            //     sm={12}
            //     md={12}
            //     lg={12}
            //     xl={12}
            //     style={{ overflowX: "auto" }}
            //   >
            //     <Form.Item
            //       label={<IntlMessages id="admin.organization.company" />}
            //     >
            //       <OrgTree
            //         organizationId={this.props.match.params.id}
            //         onChange={this.onChange}
            //       />
            //     </Form.Item>
            //   </Col>
            // </Row>
            <Row gutter={24} style={{ paddingBottom: "20px" }}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <div>
                  <div style={{ float: "left", paddingTop: "5px" }}>
                    <IntlMessages id="admin.organization.company" />
                    :&nbsp;
                  </div>
                  <div style={{ float: "left", minWidth: "320px" }}>
                    {!this.state.loading ? (
                      <OrgTree
                        parent={this.props.match.params.id}
                        organizationId={this.props.match.params.orgid}
                        onChange={this.onChange}
                      />
                    ) : (
                      <OrgTree
                        parent={-1}
                        organizationId={this.props.match.params.orgid}
                        onChange={this.onChange}
                      />
                    )}
                  </div>
                </div>
              </Col>
            </Row>
          ) : (
            ""
          )}

          <Row gutter={24} style={{ marginBottom: "20px" }}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              {filtertag}
            </Col>
          </Row>
          <Row gutter={24}>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={24}
              style={{ overflowX: "auto" }}
            >
              <Table
                columns={this.getHeaderKeys()}
                rowKey={(record) => record.userId}
                dataSource={this.state.data}
                pagination={this.state.pagination}
                loading={this.state.loading}
                onChange={this.handleTableChange}
              />
            </Col>
          </Row>
        </Spin>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    ...state.user,
    ...state.pagination,
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { ...itiniaryActions, ...userActions, ...paginationActions },
    dispatch
  );
}
const WrappedForm = Form.create({ name: "Update-itinerary" })(UpdateItinerary);
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    WrappedForm
  )
);
