import React, { Component } from "react";
import PropTypes from 'prop-types';
import {
  Typography,
  Row,
  Col,
  Form,
  Spin,
  Button,
  Upload,
  Icon,
  message,
  Checkbox,
  Select,
  Radio,
  Input,
  Divider
} from "antd";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as b2b2cOrgActions from "../../../redux/actions/b2b2c-organization-actions";
import * as userActions from "../../../redux/actions/user-actions";
import IntlMessages from "../../../services/intlMesseges";
import config from "../../../../../config";
import { notifyUser } from "../../../services/notification-service";
import Axios from "axios";
import * as UserServices from "../../../services/user-service";
import AppLocale from "../../../languageProvider";
import 'rc-color-picker/assets/index.css';
import DefaultBackgroundImage from '../../../images/main-bg.png';
import { Panel as ColorPickerPanel } from 'rc-color-picker';
import WizardIconSVG from "../../shared/svg/b2c-svg/wizard-svg.jsx";
const { Option } = Select;
/* const msgLogoremovedSuceess = <IntlMessages id="admin.logoremoved.success"/>;
const msgUnknownError = <IntlMessages id="admin.unknown.error" />;
 */
class AdminSettings extends Component {
  state = {
      logo: null,
      hasLogo: false,
      themeId: 1,
      IsEmail: false,
      IsSms: false,
      IsPushNotification: false,
      file: null,
      formLayout: "vertical",
      orgData: [],
      defaultTheme: 0,
      loading: false,
      uploadFileName: "",
      allThemes: [],
      capLogoVisibility: 1,
      footerVisibility: 1,
      footerText: "Powered by FocusPoint International, Inc. © 2021<br/>All Rights Reserved.",
      primaryColor: "",
      backgroundImage: null,
      backgroundImageUploadFileName: "",
      wizardIcon: null,
      wizardIconUploadFileName: "",
      hasBackgroundImage: false,
      hasWizardIcon: false,
      wizardActiveIcon: null,
      wizardActiveIconUploadFileName: "",
      hasWizardActiveIcon: false
    };

  componentDidMount = async() => {
    await this.props.getActiveThemes().then(response => {
      this.setState({ allThemes: response.data });
    }).catch(e => {
      console.log("Exception: ", e);
    });
    this.loadSettings();
  }

  async loadSettings(){
    let _orgData = this.state.orgData;
    if(this.props.orgData && this.props.orgData.organizationId){
      _orgData = this.props.orgData;
      let currentUser = JSON.parse(localStorage.getItem('user'));
      currentUser.logo = _orgData.logo;
      localStorage.setItem('user', JSON.stringify(currentUser));
      this.props.getUserData();
      this.setState({
        hasLogo: (_orgData && _orgData.logo !== null) ? true : false,
        hasBackgroundImage: (_orgData && _orgData.backgroundImage !== null) ? true : false,
        hasWizardIcon: (_orgData && _orgData.wizardIcons !== null) ? true : false,
        hasWizardActiveIcon: (_orgData && _orgData.wizardIconActive !== null) ? true : false,
        defaultTheme: _orgData.themeId || 1,
        selectedColor: (this.state.allThemes.length > 0) ? this.state.allThemes.find(i => i.themeId === (_orgData.themeId || 1)).primaryColor : null,
        IsEmail: (_orgData.isEmailActive === true) ? true : false,
        IsSms: (_orgData.isSMSActive === true) ? true : false,
        IsPushNotification: (_orgData.isNotificationActive === true) ? true : false,
        capLogoVisibility: (_orgData.isCapLogoVisible === null || _orgData.isCapLogoVisible === true) ? true : false,
        footerVisibility: (_orgData.isFooterVisible === null || _orgData.isFooterVisible === true) ? true : false,
        orgData: _orgData,
        footerText: _orgData.footerText,
        loading: false
      });
    } else {
      this.setState({ loading: false });
    }
  }
  
  fetchSettings = async() => {
    await this.props.getOrganizationId(this.props.orgData.organizationId).then(async resp => {
      if (resp.error) {
        notifyUser(resp.error.message, 'error');
        this.props.history.push('./../../');
        this.setState({ loading: false });
      } else {
        let _orgData = this.state.orgData;
        if(resp.data && resp.data.organizationId){
          _orgData = resp.data;
          let currentUser = JSON.parse(localStorage.getItem('user'));
          currentUser.logo = _orgData.logo;
          localStorage.setItem('user', JSON.stringify(currentUser));
          this.props.getUserData();
          this.setState({
            hasLogo: (_orgData && _orgData.logo !== null) ? true : false,
            hasBackgroundImage: (_orgData && _orgData.backgroundImage !== null) ? true : false,
            hasWizardIcon: (_orgData && _orgData.wizardIcons !== null) ? true : false,
            hasWizardActiveIcon: (_orgData && _orgData.wizardIconActive !== null) ? true : false,
            defaultTheme: _orgData.themeId || 1,
            selectedColor: (this.state.allThemes.length > 0) ? this.state.allThemes.find(i => i.themeId === (_orgData.themeId || 1)).primaryColor : null,
            IsEmail: (_orgData.isEmailActive === true) ? true : false,
            IsSms: (_orgData.isSMSActive === true) ? true : false,
            IsPushNotification: (_orgData.isNotificationActive === true) ? true : false,
            capLogoVisibility: (_orgData.isCapLogoVisible === null || _orgData.isCapLogoVisible === true) ? true : false,
            footerVisibility: (_orgData.isFooterVisible === null || _orgData.isFooterVisible === true) ? true : false,
            orgData: _orgData,
            footerText: _orgData.footerText,
            loading: false
          });
        } else {
          this.setState({ loading: false });
        }
      }
    }).catch(ex => {
      this.setState({ loading: false });
    });
  }

  /* setAlertMediumOptions = (e) => {
    let _values = {};
    switch(e.target.value){
      case 'sms':
        _values.IsSms = e.target.checked;
        break;
      case 'email':
        _values.IsEmail = e.target.checked;
        break;
      case 'pushnotification':
        _values.IsPushNotification = e.target.checked;
        break;
      default:
        break;
    }
    this.setState(_values);
  } */
  
  setTheme = (selected, option) => {
    this.setState({
      themeId: selected,
      selectedColor: option.props.data_color
    });
  }
  
  changeTheme = (selected) => {
    this.setState({
      themeId: selected,
      defaultTheme: selected
    });
  }

  handleSubmit = async(e) => {
    e.preventDefault();
    let _this = this;
    this.setState({loading: true});
    const formData = new FormData();
    formData.append('file', this.state.logo);      
    formData.set('OrgId', this.state.orgData.organizationId);
    formData.set('ThemeId', this.state.themeId);
    /* formData.set('IsEmail', this.state.IsEmail);
    formData.set('IsSms', this.state.IsSms);
    formData.set('IsPushNotification', this.state.IsPushNotification); */
    formData.append('BackgroundLogoFile', this.state.backgroundImage);      
    formData.append('WizardIconFile', this.state.wizardIcon);
    formData.append('WizardIconActiveFile', this.state.wizardActiveIcon);
    formData.set('IsFooterVisible', this.state.footerVisibility);      
    formData.set('PrimaryColor', this.state.primaryColor);      
    formData.set('FooterText', this.state.footerText);      
    //formData.set('IsCapLogoVisible', this.state.capLogoVisibility);      

    let token = UserServices.getToken();
    let _headers = {'Content-Type': 'multipart/form-data'};
    if (token) _headers['authorization'] = token ? 'Bearer ' + token : undefined;
    const { language } = this.props;
    const currentAppLocale = AppLocale[language.locale];
    Axios({
        method: 'post',
        url: config.API1+'/api/B2B2CSettings',
        data: formData,
        headers: _headers
      })
      .then(function(_response){
        let _currentUser = UserServices.getUser();
        if(_currentUser.companyID === _this.state.orgData.organizationId){
          _currentUser.themeId = _this.state.themeId;
          localStorage.setItem('user', JSON.stringify(_currentUser));
          document.body.setAttribute('class', 'theme-'+_this.state.themeId);
        }
        _this.setState({
          logo: null, 
          uploadFileName: "", 
          backgroundImageUploadFileName: "", 
          wizardIconUploadFileName: "", 
          wizardActiveIconUploadFileName: "", 
          loading: false
        }, () => {
          _this.fetchSettings();
        });
        notifyUser(currentAppLocale.messages["admin.organization.editsettings"], "success");
      })
      .catch(function (_response) {
        console.log("Err: ", _response);
        _this.setState({loading: false});
      });
  };

  imageUploadProps = (ImageStateObject, ImageStateNameString, acceptedTypes = ".jpg,.jpeg,.png") => {
    let _this = this;
    const { language } = this.props;
    const currentAppLocale = AppLocale[language.locale];
    return {
      showUploadList: false,
      accept: acceptedTypes,
      multiple: false,
      beforeUpload: file => {
        let isValidFile = false;
        acceptedTypes.split(",").map(type => {
          if(file.type.indexOf(type.replace(".","")) > -1){
            isValidFile = true;
          }
        });
        if(isValidFile === false){
          message.error(currentAppLocale.messages["admin.fileupload.acceptedtypes"]);
          _this.setState({ [ImageStateObject]: null, [ImageStateNameString]: ""});
        } else {
          _this.setState({ [ImageStateObject]: file, [ImageStateNameString]: file.name});
        }
        return false;
      },
      onRemove: _file => {
        _this.setState({ [ImageStateObject]: null, [ImageStateNameString]: ""});
      },
      onSuccess: _res => {
        _this.setState({ [ImageStateObject]: null});
      },
      onError(_err) {
        message.error(currentAppLocale.messages["admin.fileupload.failed"]);
      }
    }
  };

  removeImage(type, hasType) {
    let self = this;
    this.setState({loading: true});
    const { language } = this.props;
    const currentAppLocale = AppLocale[language.locale];
    switch(type){
      case "logo":
        let confirmDeleteLogo = window.confirm(currentAppLocale.messages["admin.org.confirm.remove.icon.logo"]);
        if (confirmDeleteLogo) {
          this.props
            .removeLogo(this.state.orgData.organizationId)
            .then(_response => {
              if (_response.error) {
                if(_response.error.message){
                  notifyUser(_response.error.message, "error");
                } else {
                  notifyUser(currentAppLocale.messages["admin.unknown.error"], "error");
                }
                this.setState({ loading: false });
              } else {
                let currentUser = JSON.parse(localStorage.getItem('user'));
                currentUser.logo = "";
                localStorage.setItem('user', JSON.stringify(currentUser));
                this.setState({ [type]: null, [hasType]: false, loading: false }, () => self.props.updateStateOnChange());
                notifyUser(currentAppLocale.messages["admin.organizationsettings.removelogo"], "success");
              }
            })
            .catch(_err => {
              this.setState({ loading: false });
            });
        } else {
          this.setState({ loading: false });
        }
        break;
      case "backgroundImage":
        let confirmDeleteBackgroundImage = window.confirm(currentAppLocale.messages["admin.org.confirm.remove.icon.background"]);
        if (confirmDeleteBackgroundImage) {
          this.props
            .removeBackgroundImage(this.state.orgData.organizationId)
            .then(_response => {
              if (_response.error) {
                if(_response.error.message){
                  notifyUser(_response.error.message, "error");
                } else {
                  notifyUser(currentAppLocale.messages["admin.unknown.error"], "error");
                }
                this.setState({ loading: false });
              } else {
                this.setState({ [type]: null, [hasType]: false, loading: false }, () => self.props.updateStateOnChange());
                notifyUser(currentAppLocale.messages["admin.image.removed.successfully"], "success");
              }
            })
            .catch(_err => {
              this.setState({ loading: false });
            });
        } else {
          this.setState({ loading: false });
        }
        break;
      case "wizardIcon":
        let confirmDeleteWizardIcon = window.confirm(currentAppLocale.messages["admin.org.confirm.remove.icon.wizard"]);
        if (confirmDeleteWizardIcon) {
          this.props
            .removeWizardIcon(this.state.orgData.organizationId)
            .then(_response => {
              if (_response.error) {
                if(_response.error.message){
                  notifyUser(_response.error.message, "error");
                } else {
                  notifyUser(currentAppLocale.messages["admin.unknown.error"], "error");
                }
                this.setState({ loading: false });
              } else {
                this.setState({ [type]: null, [hasType]: false, loading: false }, () => self.props.updateStateOnChange());
                notifyUser(currentAppLocale.messages["admin.image.removed.successfully"], "success");
              }
            })
            .catch(_err => {
              this.setState({ loading: false });
            });
        } else {
          this.setState({ loading: false });
        }
        break;
      case "wizardActiveIcon":
        let confirmDeleteWizardActiveIcon = window.confirm(currentAppLocale.messages["admin.org.confirm.remove.icon.wizard"]);
        if (confirmDeleteWizardActiveIcon) {
          this.props
            .removeWizardActiveIcon(this.state.orgData.organizationId)
            .then(_response => {
              if (_response.error) {
                if(_response.error.message){
                  notifyUser(_response.error.message, "error");
                } else {
                  notifyUser("Unknown error. Please try again!", "error");
                }
                this.setState({ loading: false });
              } else {
                this.setState({ [type]: null, [hasType]: false, loading: false }, () => self.props.updateStateOnChange());
                notifyUser(currentAppLocale.messages["admin.image.removed.successfully"], "success");
              }
            })
            .catch(_err => {
              this.setState({ loading: false });
            });
        } else {
          this.setState({ loading: false });
        }
        break;
      default:
        this.setState({ [type]: null, [hasType]: false, loading: false });
        notifyUser(currentAppLocale.messages["admin.image.removed.successfully"], "success");
        break;
    }
  }

  onChangeCapLogoVisibility = (e) => {
    this.setState({
      capLogoVisibility: e.target.value,
    });
  }

  onChangeFooterVisibility = (e) => {
    this.setState({
      footerVisibility: e.target.value,
    });
  }

  onChangeFooterText = (e) => {
    this.setState({
      footerText: e.target.value,
    });
  }

  onChangePrimaryColor = (colorObj) => {
    this.setState({
      primaryColor: colorObj.color
    });
  }

  render() {
    const alertMediumOptions = [
        { label: "SMS", value: "sms" },
        { label: "Email", value: "email" },
        { label: "Push Notification", value: "pushnotification" }
      ];
    const { formLayout } = this.state;    
    const themeOptionsSelector = (
        this.state.allThemes.length > 0 ?
            <div>
              <Select onChange={this.changeTheme} onSelect={this.setTheme} value={this.state.defaultTheme > 0 ? this.state.defaultTheme : null} style={{ width: "83%" }} placeholder="--select--">
                {this.state.allThemes.length > 0 ? 
                    this.state.allThemes.map(function(theme){
                      return <Option value={theme.themeId} key={theme.themeId} data_color={theme.primaryColor}>{theme.name}</Option>
                    })
                  : 
                    "" 
                }
              </Select>
              <div className="theme-preview" style={{ borderRadius: "3px", float: "right", position: "relative", height: "34px", width: "60px", backgroundColor: this.state.selectedColor }}></div>
            </div>
          : 
            ""
      );
    return (
      <div>
        <Form layout={formLayout} onSubmit={this.handleSubmit}>
          <Spin spinning={this.state.loading}>
            <Row gutter={24}>
              <Col xs={24} sm={24} md={16} lg={16} xl={16}>
                <Form.Item label={<IntlMessages id="admin.select.uploadOrgFile"/>} help={(<IntlMessages id="admin.help.onlypng" />)}>
                  <Upload {...this.imageUploadProps("logo", "uploadFileName",".png")}>
                    <Button>
                      <Icon type="upload" /> <IntlMessages id="admin.select.uploadfile" />
                    </Button>
                  </Upload>
                  <span>
                    {
                      this.state.uploadFileName !== "" ? 
                        <span>
                          <br/>
                          {typeof this.state.logo !== "string" && typeof this.state.logo !== "undefined" && this.state.logo ?
                            <>
                              <img src={URL.createObjectURL(this.state.logo)} style={{ maxWidth: 400, padding: '10px 0' }} /><br/>
                            </>
                          : ''}
                          {this.state.uploadFileName}
                          <button title="Remove" className="icon" onClick={() => this.setState({ uploadFileName: "", logo: null })}><i className="fas fa-trash"></i></button>
                        </span>
                      : 
                      ""
                    }
                  </span>
                  <br/><br/><span><IntlMessages id="admin.org.recommendsize"/>230 x 60 pixels (16:9)</span>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                {
                  (this.state.hasLogo === true) ? 
                    <div>
                      <img src={config.API1 +"/"+this.state.orgData.logo} style={{maxWidth: '400px', maxHeight: '200px'}}/><br/><br/>
                      <Button type="primary" onClick={() => this.removeImage("logo", "hasLogo")} ><IntlMessages id="admin.remove"/></Button>
                    </div>
                  : 
                    ""
                }
              </Col>
            </Row>
            <Divider />
            {/* <Row gutter={24}>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Form.Item label={<IntlMessages id="admin.label.hidecaplogo"/>}>
                  <Radio.Group onChange={this.onChangeCapLogoVisibility} value={this.state.capLogoVisibility}>
                    <Radio value={true}>Yes</Radio>
                    <Radio value={false}>No</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
            <Divider /> */}
            <Row gutter={24}>
              <Col xs={24} sm={24} md={16} lg={16} xl={16}>
                <Form.Item label={<IntlMessages id="admin.select.uploadOrgBackgroundImage"/>} help={(<IntlMessages id="admin.help.backgroundImage" />)}>
                  <Upload {...this.imageUploadProps("backgroundImage", "backgroundImageUploadFileName")}>
                    <Button>
                      <Icon type="upload" /> <IntlMessages id="admin.select.uploadfile" />
                    </Button>
                  </Upload>
                  <span>
                    {
                      this.state.backgroundImageUploadFileName !== "" ? 
                        <span>
                          <br/>
                          {typeof this.state.backgroundImage !== "string" && typeof this.state.backgroundImage !== "undefined" && this.state.backgroundImage ?
                            <>
                              <img src={URL.createObjectURL(this.state.backgroundImage)} style={{ maxWidth: 400, padding: '10px 0' }} /><br/>
                            </>
                          : ''}
                          {this.state.backgroundImageUploadFileName}
                          <button title="Remove" className="icon" onClick={() => this.setState({ backgroundImageUploadFileName: "", backgroundImage: null })}><i className="fas fa-trash"></i></button>
                        </span>
                      : 
                      ""
                    }
                  </span>
                  <br/><br/><span><IntlMessages id="admin.org.recommendsize"/>1200 x 760 pixels (16:9)</span>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                {
                  (this.state.hasBackgroundImage === true) ? 
                    <div>
                      <img src={config.API1 +"/"+this.state.orgData.backgroundImage} style={{maxWidth: '400px', maxHeight: '200px'}}/><br/><br/>
                      <Button type="primary" onClick={() => this.removeImage("backgroundImage", "hasBackgroundImage")} ><IntlMessages id="admin.remove"/></Button>
                    </div>
                  : 
                    <div>
                      <img src={DefaultBackgroundImage} style={{maxWidth: '400px', maxHeight: '200px'}}/><br/>
                    </div>
                }
              </Col>
            </Row>
            <Divider />
            <Row gutter={24}>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Form.Item label={<IntlMessages id="admin.label.primarycolor"/>}>
                  <ColorPickerPanel
                    color={this.state.primaryColor}
                    onChange={this.onChangePrimaryColor}
                    enableAlpha={false} 
                    mode="RGB"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Divider />
            <Row gutter={24}>
              <Col xs={24} sm={24} md={16} lg={16} xl={16}>
                <Form.Item label={<IntlMessages id="admin.label.wizardicon"/>} help={(<IntlMessages id="admin.help.wizardIconInactive" />)}>
                  <Upload {...this.imageUploadProps("wizardIcon", "wizardIconUploadFileName")}>
                    <Button>
                      <Icon type="upload" /> <IntlMessages id="admin.select.uploadfile" />
                    </Button>
                  </Upload>
                  <span>
                    {
                      this.state.wizardIconUploadFileName !== "" ? 
                        <span>
                          <br/>
                          {typeof this.state.wizardIcon !== "string" && typeof this.state.wizardIcon !== "undefined" && this.state.wizardIcon ?
                            <>
                              <img src={URL.createObjectURL(this.state.wizardIcon)} style={{ maxWidth: 100, padding: '10px 0' }} /><br/>
                            </>
                          : ''}
                          {this.state.wizardIconUploadFileName}
                          <button title="Remove" className="icon" onClick={() => this.setState({ wizardIconUploadFileName: "", wizardIcon: null })}><i className="fas fa-trash"></i></button>
                        </span>
                      : 
                      ""
                    }
                  </span>
                  <br/><br/><span><IntlMessages id="admin.org.recommendsize"/>50 x 50 pixels (1:1)</span>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                {
                  (this.state.hasWizardIcon === true) ? 
                    <div>
                      <img src={config.API1 +"/"+this.state.orgData.wizardIcons} style={{maxWidth: '100px', maxHeight: '100px', padding: "10px 0"}}/><br/><br/>
                      <Button type="primary" onClick={() => this.removeImage("wizardIcon", "hasWizardIcon")} ><IntlMessages id="admin.remove"/></Button>
                    </div>
                  : 
                    <div className="wizard-settings">
                      <WizardIconSVG />
                    </div>
                }
              </Col>
            </Row>
            <Row gutter={24}>
              <Col xs={24} sm={24} md={16} lg={16} xl={16}>
                <Form.Item label={<IntlMessages id="admin.label.wizardActiveIcon"/>} help={(<IntlMessages id="admin.help.wizardIconActive" />)}>
                  <Upload {...this.imageUploadProps("wizardActiveIcon", "wizardActiveIconUploadFileName")}>
                    <Button>
                      <Icon type="upload" /> <IntlMessages id="admin.select.uploadfile" />
                    </Button>
                  </Upload>
                  <span>
                    {
                      this.state.wizardActiveIconUploadFileName !== "" ? 
                        <span>
                          <br/>
                          {typeof this.state.wizardActiveIcon !== "string" && typeof this.state.wizardActiveIcon !== "undefined" && this.state.wizardActiveIcon ?
                            <>
                              <img src={URL.createObjectURL(this.state.wizardActiveIcon)} style={{ maxWidth: 100, padding: '10px 0' }} /><br/>
                            </>
                          : ''}
                          {this.state.wizardActiveIconUploadFileName}
                          <button title="Remove" className="icon" onClick={() => this.setState({ wizardActiveIconUploadFileName: "", wizardActiveIcon: null })}><i className="fas fa-trash"></i></button>
                        </span>
                      : 
                      ""
                    }
                  </span>
                  <br/><br/><span><IntlMessages id="admin.org.recommendsize"/>50 x 50 pixels (1:1)</span>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                {
                  (this.state.hasWizardActiveIcon === true) ? 
                    <div>
                      <img src={config.API1 +"/"+this.state.orgData.wizardIconActive} style={{maxWidth: '100px', maxHeight: '100px', padding: "10px 0"}}/><br/><br/>
                      <Button type="primary" onClick={() => this.removeImage("wizardActiveIcon", "hasWizardActiveIcon")} ><IntlMessages id="admin.remove"/></Button>
                    </div>
                  : 
                    <div className="wizard-settings active">
                      <WizardIconSVG />
                    </div>
                }
              </Col>
            </Row>
            <Row>
              <Col xs={24} style={{ color: '#ca5555' }}><IntlMessages id="admin.wizardicon.output.warning" /></Col>
            </Row>
            <Divider />
            <Row gutter={24}>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Form.Item label={<IntlMessages id="admin.label.hidefooter"/>}>
                  <Radio.Group onChange={this.onChangeFooterVisibility} value={this.state.footerVisibility}>
                    <Radio value={true}>Yes</Radio>
                    <Radio value={false}>No</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
            {this.state.footerVisibility !== false ?
              <>
                <Divider />
                <Row gutter={24}>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Form.Item label={<IntlMessages id="admin.label.footertext"/>}>
                      <Input.TextArea rows={3} onChange={this.onChangeFooterText} value={this.state.footerText} required={this.state.footerVisibility !== false ? true : false}/>
                    </Form.Item>
                  </Col>
                </Row>
              </>
            :
              ""
            }
            <Divider />
            {/* <Row gutter={24}>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Form.Item label={<IntlMessages id="admin.label.alertmedium"/>}>
                  {alertMediumOptions.map(el => {
                    let _checked = false;
                    switch(el.value){
                      case 'sms':
                        _checked = (this.state.IsSms === true) ? true : false;
                        break;
                      case 'email':
                        _checked = (this.state.IsEmail === true) ? true : false;
                        break;
                      case 'pushnotification':
                        _checked = (this.state.IsPushNotification === true) ? true : false;
                        break;
                      default:
                        break;
                    }
                    return <Checkbox value={el.value} onChange={this.setAlertMediumOptions} checked={_checked}>{el.label}</Checkbox>
                  })}
                </Form.Item>
              </Col>
            </Row>
            <Divider /> */}
            <Row gutter={24}>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Form.Item label={<IntlMessages id="admin.label.themeoptions"/>}>
                  {themeOptionsSelector}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Button type="primary" htmlType="submit"><IntlMessages id="admin.button.update" /></Button>
              </Col>
            </Row>
          </Spin>
        </Form>
      </div>
    );
  }
}
AdminSettings.propTypes = {
  logo_url:PropTypes.string,
  getUserData:PropTypes.func
};

function mapStateToProps(state) {
  return { ...state.user, ...state.language, };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...b2b2cOrgActions,...userActions }, dispatch);
}
const WrappedForm = Form.create({ name: "admin-settings" })(AdminSettings);
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    WrappedForm
  )
);
