import { Tabs, Form } from "antd";
import OrganizationPlan from "../edit-organization/organization-plan";
import PrimaryAddress from "../edit-organization/primary-address";
import AdminSettings from "../edit-organization/settings";
import React, { Component } from "react";
import IntlMessages from "../../../../services/intlMesseges";
import * as Permission from "../../../../services/permissions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import API from "../../../../redux/api/admin-api";

const { TabPane } = Tabs;
class EditOrganization extends Component {
  constructor(props) {
    super(props);
    this.state = {
      countries: [],
      productData: [],
      addData: false,
      activeTab: "#address",
      tab1title: <IntlMessages id="admin.organization.address" />,
      tab2title: <IntlMessages id="admin.organization.plan" />,
      tab3title: <IntlMessages id="admin.userlisting.settings" />
    };
    this.onTabsChange = this.onTabsChange.bind(this);
    this.addProduct = this.addProduct.bind(this);
    this.saveproduct = this.saveproduct.bind(this);
  }

  async componentDidMount() {
    let countriesListTemp = await API.getCountriesList();
    this.setState({ countries: countriesListTemp.countries });
    if (
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.id &&
      this.props.match.params.id !== "new"
    ) {
      this.setState({ orgId: this.props.match.params.id });
    }
  }

  onTabsChange = currentTab => {};
  addProduct() {
    this.setState({ addData: true });
  }
  saveproduct() {
    this.setState({ addData: false });
  }
  render() {
    return (
      <div>
        <Tabs
          defaultActiveKey={this.state.activeTab}
          onChange={this.onTabsChange}
        >
          <TabPane tab={this.state.tab1title} key="#address">
            <PrimaryAddress />
          </TabPane>
          <TabPane tab={this.state.tab2title} key="#plan">
            <OrganizationPlan />
          </TabPane>
          {Permission.isRole(["Administrator","CSR","Admin","Travel Manager"]) ? 
            <TabPane tab={this.state.tab3title} key="#users">
              <AdminSettings />
            </TabPane>
          : "" }
        </Tabs>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}
// export default EditOrganization;
const WrappedForm = Form.create({ name: "organization-plans" })(
  EditOrganization
);
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    WrappedForm
  )
);
