import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { bindActionCreators } from "redux";

import {
  Table,
  Row,
  Col,

} from "antd";
import IntlMessages from "../../../services/intlMesseges";


class PrintableArea extends Component {
  state = {
    
  };

  getHeaderKeys = () => {
    return [
      {
        title: <IntlMessages id="admin.userlisting.name" />,
        dataIndex: "name",
        
      },
      {
        title: <IntlMessages id="admin.report.division" />,
        dataIndex: "divisionName",
        
      },
      
      {
        title: <IntlMessages id="admin.report.organization" />,
        dataIndex: "parentOrganizationName",
       
      } ,
      {
        title: <IntlMessages id="admin.report.pnr" />,
        dataIndex: "pnrNumber",
       
      },
      {
        title: <IntlMessages id="admin.report.departs" />,
        dataIndex: "departureDate",
      },

      {
        title: <IntlMessages id="admin.report.routing" />,
        dataIndex: "routing"
      },
    ];
  };
  
  render() {
    
    let nonPrintableKeys = ["action"];
    let filteredKeys = this.props.headerKeys.filter(
    key => !nonPrintableKeys.includes(key.rowKey)
    )
      return(
      
        <div class="printableArea">
        
        <Row gutter={24}>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={24}
            style={{ overflowX: "auto" }}
          >
            
             
              <Table
                bordered
                pagination={false}
                columns={filteredKeys}
                rowKey={record => record.pnrId}
                dataSource={this.props.data}
              />
            
          </Col>
        </Row>
        </div>
      
      );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ }, dispatch);
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    PrintableArea
  )
);