import API from "../api/pricing-api";

export function getPricing({ filters, pagination, sorter }) {
  return async function(dispatch, getState) {
    try {
      let resp = await API.getPricing({
        filters: filters,
        pagination: pagination,
        sorter : sorter
      });
      return resp;

    } catch (e) {
      return {"error" : true};
    }
  };
}

export function getPrice(priceId) {
  return async function(dispatch, getState) {
    try {
      let resp = await API.getPrice(priceId);
      return resp;

    } catch (e) {
      return {"error" : true};
    }
  };
}

export function updatePricing(data) {
  return async function(dispatch, getState) {
    let resp;
    try {
      resp = await API.updatePricing(data);
      return resp;
    } catch (e) {
      let err;
      if(e.response.data && e.response.data.errors && e.response.data.errors.title){
        err = {
            error: {
              message: e.response.data.errors.title
            }
          };
      } else {
        err = {
            error: {
              message: "Unknown error. Please check the fields and try again."
            }
          };
      }
      return err;
    }
  };
}

export function addPricing(data) {
  return async function(dispatch, getState) {
    try {
      let resp = await API.addPricing(data);
      return resp;
    } catch (e) {
      return {"error" : true};
    }
  };
}


