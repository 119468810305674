import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Typography,
  Row,
  Col,
  Form,
  Spin,
  Button,
  Upload,
  Icon,
  message,
  Checkbox,
  Select,
  Radio,
} from "antd";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as OrgActions from "../../../../redux/actions/organization-actions";
import * as userActions from "../../../../redux/actions/user-actions";
import IntlMessages from "../../../../services/intlMesseges";
import config from "../../../../../../config";
import { notifyUser } from "../../../../services/notification-service";
import * as adminActions from "../../../../redux/actions/admin-actions";
import Axios from "axios";
import * as UserServices from "../../../../services/user-service";
import AppLocale from "../../../../languageProvider";
import ReactQuill from "react-quill";
import "../../../.../../../../../shared/app/styles/css/vendor/quill.snow.css";

const { Option } = Select;
/* const msgLogoremovedSuceess = <IntlMessages id="admin.logoremoved.success"/>;
const msgUnknownError = <IntlMessages id="admin.unknown.error" />;
 */
class AdminSettings extends Component {
  state = {
    logo: null,
    hasLogo: false,
    themeId: 1,
    IsEmail: false,
    IsSms: false,
    IsPushNotification: false,
    file: null,
    formLayout: "vertical",
    orgData: [],
    defaultTheme: 0,
    countries: [],
    loading: false,
    uploadFileName: "",
    allThemes: [],
    users: [],
    receivers: [],
    senders: [],
    riskFrequency: 0,
    selectedCountries: [],
    userNames: [],
    notificationReceivers: [],
    emailCustomContent: "",
  };

  componentDidMount = async () => {
    await this.props
      .getActiveThemes()
      .then((response) => {
        this.setState({ allThemes: response.data });
      })
      .catch((e) => {
        console.log("Exception: ", e);
      });
    if (
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.id &&
      this.props.match.params.id !== "new"
    ) {
      this.loadSettings();
    }

    await this.props.getCountriesListName().then((data) => {
      this.setState({ countries: data });
    });

    try {
      const data = await this.props.getOrgUsersList(this.props.match.params.id);
      this.setState({ users: data });

      const filteredUserNames = this.state.users
        .filter((element) => ['Employee', 'Admin', 'Travel Manager', 'Division Manager'].includes(element.role))
        .map((element) => `${element.firstName} ${element.lastName}`);

      this.setState({ userNames: filteredUserNames });
    } catch (error) {
      console.error('Error fetching organization users:', error);
    }
    
    // await this.props.getOrgUsersList(this.props.match.params.id).then((data) => {
    //   this.setState({ users: data });
    // });
    
    //   this.state.users.map((element) => {
    //     if (
    //       element.role === "Employee" ||
    //       element.role === "Admin" ||
    //       element.role === "Travel Manager" ||
    //       element.role === "Division Manager"
    //     ) {
    //       this.setState({
    //         userNames: [...this.state.userNames, element.firstName + " " + element.lastName]
    //       });
    //     }
        
    //   });
  };

  async loadSettings() {
    this.props
      .getOrganizationId(this.props.match.params.id)
      .then((resp) => {
        if (resp.error) {
          notifyUser(resp.error.message, "error");
          this.props.history.push("./../../");
          this.setState({ loading: false });
        } else {
          let currentUser = JSON.parse(localStorage.getItem("user"));
          currentUser.logo = resp.data.logo;
          localStorage.setItem("user", JSON.stringify(currentUser));
          this.props.getUserData();
          this.setState({
            hasLogo: resp.data && resp.data.logo !== null ? true : false,
            defaultTheme: resp.data.themeId || 1,
            selectedColor:
              this.state.allThemes.length > 0
                ? this.state.allThemes.find((i) => i.themeId === (resp.data.themeId || 1)).primaryColor
                : null,
            IsEmail: resp.data.isEmailActive === true ? true : false,
            IsSms: resp.data.isSMSActive === true ? true : false,
            IsPushNotification: resp.data.isNotificationActive === true ? true : false,
            orgData: resp.data,
            loading: false,
            riskFrequency: resp.data.riskReportFrequency,
            selectedCountries: resp.data.riskReportCountries,
            receivers: resp.data.riskReportUsers,
            senders :resp.data.riskAlertNotificationRecipients,
            notificationReceivers: resp.data.assistNotificationRecipients || [],
            emailCustomContent: resp.data.emailCustomContent
          });
        }
      })
      .catch((_ex) => {
        this.setState({ loading: false });
      });
  }

  handleReceiverChange = (value) => {
    this.state.receivers.length = 0;
    this.setState({ receivers: this.state.receivers.concat(value) });
  };
  
  handleSenderChange = (value) => {
    this.state.senders.length = 0;
    this.setState({ senders: this.state.senders.concat(value) });
  };

  handleCountryChange = (value) => {
    this.state.selectedCountries.length = 0;
    this.setState({
      selectedCountries: this.state.selectedCountries.concat(value)
    });
  };

  handleRadioChange(value) {
    this.setState({ riskFrequency: value });
  }

  setAlertMediumOptions = (e) => {
    let _values = {};
    switch (e.target.value) {
      case "sms":
        _values.IsSms = e.target.checked;
        break;
      case "email":
        _values.IsEmail = e.target.checked;
        break;
      case "pushnotification":
        _values.IsPushNotification = e.target.checked;
        break;
      default:
        break;
    }
    this.setState(_values);
  };

  handleNotificationReceiverChange = (value) => {
    this.state.notificationReceivers.length = 0;
    this.setState({ notificationReceivers: this.state.notificationReceivers.concat(value) });
  };

  setTheme = (selected, option) => {
    this.setState({
      themeId: selected,
      selectedColor: option.props.data_color
    });
  };

  changeTheme = (selected) => {
    this.setState({
      themeId: selected,
      defaultTheme: selected
    });
  };

  logoUploadProps = () => {
    let _this = this;
    return {
      showUploadList: false,
      accept: ".jpg,.jpeg,.png",
      multiple: false,
      beforeUpload: (file) => {
        if (file.type.indexOf("image/") === -1) {
          message.error(<IntlMessages id="admin.fileupload.acceptedtypes" />);
          _this.setState({ logo: null, uploadFileName: "" });
        } else {
          _this.setState({ logo: file, uploadFileName: file.name });
        }
        return false;
      },
      onRemove: (_file) => {
        _this.setState({ logo: null, uploadFileName: "" });
      },
      onSuccess: (_res) => {
        _this.setState({ logo: null });
      },
      onError(_err) {
        message.error(<IntlMessages id="admin.fileupload.failed" />);
      }
    };
  };

  handleSubmit = (e) => {
    e.preventDefault();
    let _this = this;
    this.setState({ loading: true });
    const formData = new FormData();
    var receiversString = this.state.receivers.join(";");
    var senderString = this.state.senders.join(";");
    var countriesString = this.state.selectedCountries.join(";");
    var notificationReceiversString = this.state.notificationReceivers.join(";");
    formData.append("file", this.state.logo);
    formData.set("OrgId", this.state.orgData.organizationId);
    formData.set("ThemeId", this.state.themeId);
    formData.set("IsEmail", this.state.IsEmail);
    formData.set("IsSms", this.state.IsSms);
    formData.set("IsPushNotification", this.state.IsPushNotification);
    formData.set("RiskReportCountries", countriesString);
    formData.set("RiskReportUsers", receiversString);
    formData.set("RiskAlertNotificationRecipients", senderString ? senderString : "");
    formData.set("RiskReportFrequency", this.state.riskFrequency);
    formData.set("AssistNotificationRecipients", notificationReceiversString);
    formData.set("EmailCustomContent", this.state.emailCustomContent);

    let token = UserServices.getToken();
    let _headers = { "Content-Type": "multipart/form-data" };
    if (token) _headers["authorization"] = token ? "Bearer " + token : undefined;
    const { language } = this.props;
    const currentAppLocale = AppLocale[language.locale];
    Axios({
      method: "post",
      url: config.API1 + "/api/OrganizationSettings/",
      data: formData,
      headers: _headers
    })
      .then(function(_response) {
        let _currentUser = UserServices.getUser();
        if (_currentUser.companyID === _this.state.orgData.organizationId) {
          _currentUser.themeId = _this.state.themeId;
          localStorage.setItem("user", JSON.stringify(_currentUser));
          document.body.setAttribute("class", "theme-" + _this.state.themeId);
        }
        _this.loadSettings();
        _this.setState({ logo: null, uploadFileName: "" });
        notifyUser(currentAppLocale.messages["admin.organization.editsettings"], "success");
      })
      .catch(function(_response) {
        if (_response.response) {
         notifyUser(_response.response.data.title, "error");
        }
        _this.setState({ loading: false });
      });
  };

  removeLogo() {
    this.setState({ loading: true });
    const { language } = this.props;
    const currentAppLocale = AppLocale[language.locale];
    this.props
      .removeLogo(this.state.orgData.organizationId)
      .then((_response) => {
        if (_response.error) {
          if (_response.error.message) {
            notifyUser(_response.error.message, "error");
          } else {
            notifyUser("Unknown error. Please try again!", "error");
          }
          this.setState({ loading: false });
        } else {
          let currentUser = JSON.parse(localStorage.getItem("user"));
          currentUser.logo = "";
          localStorage.setItem("user", JSON.stringify(currentUser));
          this.props.getUserData();
          this.setState({ logo: null, hasLogo: false, loading: false });
          notifyUser(currentAppLocale.messages["admin.organizationsettings.removelogo"], "success");
        }
      })
      .catch((_err) => {
        this.setState({ loading: false });
      });
  }

  removeUploadingFile = () => {
    this.setState({
      uploadFileName: "",
      logo: null
    });
  };

  onEmailCustomContentChange = (e) => {
    this.setState({ emailCustomContent: e });
  }

  render() {
    const alertMediumOptions = [
      { label: "SMS", value: "sms" },
      { label: "Email", value: "email" },
      { label: "Push Notification", value: "pushnotification" }
    ];
    const { formLayout, emailCustomContent } = this.state;
    const themeOptionsSelector =
      this.state.allThemes.length > 0 ? (
        <div>
          <Select
            onChange={this.changeTheme}
            onSelect={this.setTheme}
            value={this.state.defaultTheme > 0 ? this.state.defaultTheme : null}
            style={{ width: "83%" }}
            placeholder="--select--"
          >
            {this.state.allThemes.length > 0
              ? this.state.allThemes.map(function(theme) {
                  return (
                    <Option value={theme.themeId} key={theme.themeId} data_color={theme.primaryColor}>
                      {theme.name}
                    </Option>
                  );
                })
              : ""}
          </Select>
          <div
            className="theme-preview"
            style={{
              borderRadius: "3px",
              float: "right",
              position: "relative",
              height: "34px",
              width: "60px",
              backgroundColor: this.state.selectedColor
            }}
          ></div>
        </div>
      ) : (
        ""
      );
    return (
      <div>
        <Row gutter={24}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Typography.Title level={4}>
              <IntlMessages id="admin.userlisting.settings" />
            </Typography.Title>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12} style={{ textAlign: "right" }}>
            <Button type="primary" onClick={() => this.props.history.push("./../../")}>
              <IntlMessages id="admin.button.back" />
            </Button>
          </Col>
        </Row>
        <hr />
        <Form
          layout={formLayout}
          onSubmit={this.handleSubmit}
          initialValues={{
            test: this.state.receivers
          }}
        >
          <Spin spinning={this.state.loading}>
            <Row gutter={24}>
              <Col xs={24} sm={24} md={16} lg={16} xl={16}>
                <Form.Item label={<IntlMessages id="admin.label.uploadlogo" />}>
                  <Upload {...this.logoUploadProps()}>
                    <Button>
                      <Icon type="upload" /> <IntlMessages id="admin.select.uploadfile" />
                    </Button>
                  </Upload>
                  <span>
                    {this.state.uploadFileName !== "" ? (
                      <span>
                        <br />
                        {this.state.uploadFileName}
                        <button title="Remove" className="icon" onClick={() => this.removeUploadingFile()}>
                          <i className="fas fa-trash"></i>
                        </button>
                      </span>
                    ) : (
                      ""
                    )}
                  </span>
                  <br />
                  <br />
                  <span>
                    <IntlMessages id="admin.organizationsettings.recommendsize" />
                  </span>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                {this.state.hasLogo === true ? (
                  <div>
                    <img
                      src={config.API1 + "/" + this.state.orgData.logo}
                      style={{ maxWidth: "400px", maxHeight: "200px" }}
                    />
                    <br />
                    <br />
                    <Button type="primary" onClick={() => this.removeLogo()}>
                      <IntlMessages id="admin.remove.logo" />
                    </Button>
                  </div>
                ) : (
                  ""
                )}
              </Col>
            </Row>
            <Row gutter={24}>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Form.Item label={<IntlMessages id="admin.label.alertmedium" />}>
                  {alertMediumOptions.map((el) => {
                    let _checked = false;
                    switch (el.value) {
                      case "sms":
                        _checked = this.state.IsSms === true ? true : false;
                        break;
                      case "email":
                        _checked = this.state.IsEmail === true ? true : false;
                        break;
                      case "pushnotification":
                        _checked = this.state.IsPushNotification === true ? true : false;
                        break;
                      default:
                        break;
                    }
                    return (
                      <Checkbox value={el.value} onChange={this.setAlertMediumOptions} checked={_checked}>
                        {el.label}
                      </Checkbox>
                    );
                  })}
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Form.Item label={<IntlMessages id="admin.label.themeoptions" />}>{themeOptionsSelector}</Form.Item>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Typography.Title level={4}>Risk Reports</Typography.Title>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Form.Item label="Who will receive the report" id="receivers" name="receivers">
                  <Select
                    id="test"
                    mode="multiple"
                    style={{ width: "100%" }}
                    placeholder="Please select"
                    value={this.state.receivers.map((val) => parseInt(val))}
                    defaultValue={this.state.receivers}
                    onChange={(e) => this.handleReceiverChange(e)}
                  >
                    {this.state.users.map(function(item) {
                      return (
                        <Option key={item.userId} value={item.userId}>
                          {item.firstName + " " + item.lastName}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Typography.Title level={4}>Send Risk Alerts to</Typography.Title>
                </Col>
            </Row>
            <Row gutter={24}>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <Form.Item label="Who will send the report" id="sender" name="sender">
              <Select
              id="sender"
              mode="multiple"
              style={{ width: "100%" }}
              placeholder="Please select"
              value={this.state.senders.map((val) => parseInt(val))}
              defaultValue={this.state.senders}
              onChange={(e) => this.handleSenderChange(e)}
              >
              {this.state.users
              .filter(item => item.role === 'Travel Manager') 
              .map(item => (
                <Option key={item.userId} value={item.userId}>
                  {item.firstName + " " + item.lastName}
                </Option>
              ))}
              </Select>
              </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Form.Item label={<IntlMessages id="admin.primaryaddress.country" />}>
                  <Select
                    onChange={(e) => this.handleCountryChange(e)}
                    value={this.state.selectedCountries.map((val) => parseInt(val))}
                    mode="multiple"
                    style={{ width: "100%" }}
                    filterOption={(input, option) =>
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {this.state.countries.map(function(item) {
                      return (
                        <Option key={item.countryId} value={item.countryId}>
                          {item.countryName}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Form.Item label="Frequency">
                  <Radio.Group>
                    <Radio
                      value={1}
                      onClick={(e) => this.handleRadioChange(1)}
                      checked={this.state.riskFrequency === 1}
                    >
                      Daily
                    </Radio>
                    <Radio
                      value={2}
                      onClick={(e) => this.handleRadioChange(2)}
                      checked={this.state.riskFrequency === 2}
                    >
                      Weekly
                    </Radio>
                    <Radio
                      value={3}
                      onClick={(e) => this.handleRadioChange(3)}
                      checked={this.state.riskFrequency === 3}
                    >
                      Monthly
                    </Radio>
                    <Radio
                      value={4}
                      onClick={(e) => this.handleRadioChange(4)}
                      checked={this.state.riskFrequency === 4}
                    >
                      Quarterly
                    </Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>

            <hr />

            <Row gutter={24}>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Typography.Title level={4}>Custom Email Content</Typography.Title>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Form.Item
                  label="Customized Email Header Content for the Organization"
                  htmlFor="emailCustomContent"
                  name="emailCustomContent"
                >
                  <div>
                    <ReactQuill theme="snow" value={emailCustomContent} onChange={this.onEmailCustomContentChange} />
                    <p style={{ textAlign: "right" }}>
                      {this.state.emailCustomContent && this.state.emailCustomContent.length + "/1500"}
                    </p>
                  </div>
                </Form.Item>
              </Col>
            </Row>

            <br />
            <hr />

            <Row gutter={24}>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Typography.Title level={4}>Crisis and Check-In Notifications</Typography.Title>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Form.Item
                  label="Who will receive the Crisis and Checkin Notifications"
                  htmlFor="notificationReceivers"
                  name="notificationReceivers"
                >
                  <Select
                    id="test"
                    mode="multiple"
                    style={{ width: "100%" }}
                    placeholder="Please select"
                    value={this.state.notificationReceivers.map((val) => parseInt(val))}
                    defaultValue={this.state.notificationReceivers}
                    onChange={(e) => this.handleNotificationReceiverChange(e)}
                  >
                    {this.state.users.map(function(item) {
                      return (
                        <Option key={item.userId} value={item.userId}>
                          {item.firstName + " " + item.lastName}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Button type="primary" htmlType="submit">
                  <IntlMessages id="admin.button.update" />
                </Button>
              </Col>
            </Row>
          </Spin>
        </Form>
      </div>
    );
  }
}
AdminSettings.propTypes = {
  logo_url: PropTypes.string,
  getUserData: PropTypes.func,
};

function mapStateToProps(state) {
  return { ...state.user, ...state.language };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { ...OrgActions, ...adminActions, ...userActions },
    dispatch
  );
}
const WrappedForm = Form.create({ name: "admin-settings" })(AdminSettings);
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    WrappedForm
  )
);
