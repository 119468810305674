import Axios from "../../services/axios-service";

const getPromoCodes = data => {
  return Axios.get("/api/PromoOffers?search="+encodeURIComponent(JSON.stringify(data)), undefined, undefined);
};

const updatePromoCode = data => {
  return Axios.put("/api/PromoOffers/"+data.promoOfferId, data, undefined);
};

const addPromoCode = data => {
  return Axios.post("/api/PromoOffers", data, undefined);
};

const getPromoCode = idx => {
  return Axios.get("/api/PromoOffers/"+idx, undefined, undefined);
};

const deletePromoCode = data => {
  return Axios.delete("/api/PromoOffers/"+ data.promoOfferId, data, undefined);
};

export default {
  getPromoCodes,
  updatePromoCode,
  addPromoCode,
  getPromoCode,
  deletePromoCode
};
