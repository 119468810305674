import React from "react";
import { Badge } from "antd";
export default class AlertsCount extends React.Component {
  render() {
    return (
      <div style={{ float: "right" }}>
        <Badge
          count={4}
          style={{
            backgroundColor: "#86bee6",
            color: "#FFF",
            boxShadow: "0 0 0 1px #86bee6 inset"
          }}
        />
      </div>
    );
  }
}
