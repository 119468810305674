import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import IntlMessages from "../../../services/intlMesseges";
import PricingAPI from "../../../redux/api/pricing-api";
import TiersAPI from "../../../redux/api/tiers-api";
import DayPackagesAPI from "../../../redux/api/day-packages-api";
import * as PricingActions from "../../../redux/actions/pricing-actions";
import { notifyUser } from "../../../services/notification-service";
import * as adminActions from "../../../redux/actions/admin-actions";
import {
  Table,
  Row,
  Col,
  Typography,
  Button,
  Spin,
  Form,
  Input,
  Select,
} from "antd";
const { Option } = Select;
const EditableContext = React.createContext();

const EditableRow = ({ form, index, ...props }) => (
  <EditableContext.Provider value={form}>
    <tr {...props} />
  </EditableContext.Provider>
);

const EditableFormRow = Form.create()(EditableRow);

class EditableCell extends React.Component {
  state = {
    editing: false,
  };

  toggleEdit = () => {
    const editing = !this.state.editing;
    this.setState({ editing }, () => {
      if (editing) {
        this.input.focus();
      }
    });
  };

  save = (e) => {
    const { record, handleSave } = this.props;
    this.form.validateFields((error, values) => {
      if (error && error[e.currentTarget.id]) {
        return;
      }
      this.toggleEdit();
      handleSave({ ...record, ...values });
    });
  };

  renderCell = (form) => {
    this.form = form;
    const { children, dataIndex, record, title } = this.props;
    const { editing } = this.state;
    return editing ? (
      <Form.Item style={{ margin: 0 }}>
        {form.getFieldDecorator(dataIndex, {
          rules: [
            {
              required: true,
              message: `${title} is required.`,
            },
          ],
          initialValue: record[dataIndex],
        })(
          <Input
            ref={(node) => (this.input = node)}
            onPressEnter={this.save}
            onBlur={this.save}
          />
        )}
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{ paddingRight: 24 }}
        onClick={this.toggleEdit}
      >
        {children}
      </div>
    );
  };

  render() {
    const {
      editable,
      dataIndex,
      title,
      record,
      index,
      handleSave,
      children,
      ...restProps
    } = this.props;
    return (
      <td {...restProps}>
        {editable ? (
          <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>
        ) : (
          children
        )}
      </td>
    );
  }
}
const editableComponents = {
  body: {
    row: EditableFormRow,
    cell: EditableCell,
  },
};

class BasePricing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isUpdate: false,
      stateRiskZones: [],
      showDerivedRow: "none",
      stateORCountry: 0,
      showBaseRow: "none",
      selectedDerivedFrom: "-select-",
      loading: true,
      defaultDataSource: {},
      dataSource: [],
      tiers: {},
      columns: [],
      formData: [],
      pricingFor: [
        {
          id: 1,
          name: "RawPrice",
        },
        {
          id: 2,
          name: "RetailPrice",
        },
      ],
      pricingTypes: [
        {
          id: 1,
          name: "Base",
        },
        {
          id: 2,
          name: "Derivative",
        },
      ],
      countryOrState: [
        {
          id: 1,
          name: "Country",
        },
        {
          id: 2,
          name: "State",
        },
      ],
      operators: [
        {
          id: 1,
          value: "*",
        },
        {
          id: 2,
          value: "/",
        },
      ],
      defaultOperator: "*",
      pricingList: [],
      tableComponents: {},
      dayPackagesList: [],
      tiers: [],
    };
  }

  async componentDidMount() {
    let tempDataObj = {},
      tempDataArr = [],
      tempColumnsObj = {},
      tempColumnsArr = [];
    //  let tiersList = await TiersAPI.getTiersList({
    //       filters: {},
    //       pagination: {},
    //       sorter : {}
    //     });
    //     console.log("TiersList: "+JSON.stringify(tiersList));
    //     let stateRisks = await this.props.getStateRiskZones({});
    //     console.log(stateRisks);
    let _dayPackagesList = await DayPackagesAPI.getDayPackagesList({
      filters: {},
      pagination: {
        pageSize: 10000000,
      },
      sorter: {
        order: "ascend",
        columnKey: "dayPackageId",
      },
    });

    this.setState({
      dayPackagesList: _dayPackagesList.data,
      loading: false,
    });
  }

  submitFormAdd = (data) => {
    let _this = this,
      postData = [],
      tempResponseArr = [];
    postData = data;
    postData.isActive = true;
    if (postData.priceCalcTypeId === 1) {
      _this.state.dataSource.map(function(row, innerIdx) {
        let rowData = Object.entries(row);
        for (const [key, value] of rowData) {
          if (key !== "tier" && key !== "key") {
            let tempResponseObj = {},
              currentDayPackage = _this.state.dayPackagesList.find(
                (el) => el.days === parseInt(key.replace("day", ""))
              );
            tempResponseObj.tierId = row.key;
            tempResponseObj.price = value;
            //tempResponseObj.isActive = true;
            tempResponseObj.effectiveDate = null;
            tempResponseObj.disableDate = null;
            tempResponseObj.dayPackageId = currentDayPackage.dayPackageId;
            tempResponseObj.dayPackage = key.replace("day", "");
            tempResponseArr.push(tempResponseObj);
          }
        }
      });
      delete postData.derivedPriceListId;
    }
    postData.pricingInfo = [];
    postData.pricingInfo = tempResponseArr;
    this.props
      .addPricing(data)
      .then((response) => {
        if (response.error) {
          notifyUser(response.error.message, "error");
          this.setState({ loading: false });
        } else {
          notifyUser("Price added successfully!", "success");
          this.props.history.push("./../");
          this.setState({ loading: false });
        }
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        Object.keys(values).map(function(key, _idx) {
          if (values[key] == null) {
            values[key] = "";
          } else {
            if (typeof values[key] == "string") {
              values[key] = values[key].trim();
            }
          }
        });
        this.setState({ loading: true });
        this.submitFormAdd(values);
      }
    });
  };

  handleSave = (row) => {
    const newData = [...this.state.dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    this.setState({ dataSource: newData });
  };

  onPriceTypeChange = (selected) => {
    if (selected === 1) {
      //base price
      this.setState({
        showDerivedRow: "none",
        showBaseRow: "block",
        tableComponents: editableComponents,
      });
    } else {
      this.setState({
        showDerivedRow: "block",
        showBaseRow: "none",
        tableComponents: {},
      });
    }
  };

  async onCountryStateChange(selected) {
    let tempDataObj = {},
      tempDataArr = [],
      tempColumnsObj = {},
      tempColumnsArr = [];

    if (selected === "2") {
      let stateRisks = await this.props.getStateRiskZones({});
      this.state.dayPackagesList.map(function(row) {
        stateRisks.map(function(innerRow) {
          if (tempDataObj[innerRow.zoneId]) {
            tempDataObj[innerRow.zoneId]["day" + row.days] = "0.00";
          } else {
            tempDataObj[innerRow.zoneId] = {};
            tempDataObj[innerRow.zoneId]["day" + row.days] = "0.00";
          }
          tempDataObj[innerRow.zoneId].key = innerRow.zoneId;
          tempDataObj[innerRow.zoneId].tier = innerRow.zoneName;

          tempColumnsObj["tier"] = {};
          tempColumnsObj["tier"].title = "Tier";
          tempColumnsObj["tier"].dataIndex = "tier";
          tempColumnsObj["tier"].fixed = "left";
          tempColumnsObj["tier"].width = 60;

          if (tempColumnsObj["day" + row.days]) {
            tempColumnsObj["day" + row.days].title = row.days + " days";
            tempColumnsObj["day" + row.days].dataIndex = "day" + row.days;
            tempColumnsObj["day" + row.days].editable = true;
            tempColumnsObj["day" + row.days].width = 100;
          } else {
            tempColumnsObj["day" + row.days] = {};
            tempColumnsObj["day" + row.days].title = row.days + " days";
            tempColumnsObj["day" + row.days].dataIndex = "day" + row.days;
            tempColumnsObj["day" + row.days].editable = true;
            tempColumnsObj["day" + row.days].width = 100;
          }
        });
      });
    } else {
      let tiersList = await TiersAPI.getTiersList({
        filters: {},
        pagination: {},
        sorter: {},
      });
      this.state.dayPackagesList.map(function(row) {
        tiersList.data.map(function(innerRow) {
          if (tempDataObj[innerRow.tierId]) {
            tempDataObj[innerRow.tierId]["day" + row.days] = "0.00";
          } else {
            tempDataObj[innerRow.tierId] = {};
            tempDataObj[innerRow.tierId]["day" + row.days] = "0.00";
          }
          tempDataObj[innerRow.tierId].key = innerRow.tierId;
          tempDataObj[innerRow.tierId].tier = innerRow.tierId;

          tempColumnsObj["tier"] = {};
          tempColumnsObj["tier"].title = "Tier";
          tempColumnsObj["tier"].dataIndex = "tier";
          tempColumnsObj["tier"].fixed = "left";
          tempColumnsObj["tier"].width = 60;

          if (tempColumnsObj["day" + row.days]) {
            tempColumnsObj["day" + row.days].title = row.days + " days";
            tempColumnsObj["day" + row.days].dataIndex = "day" + row.days;
            tempColumnsObj["day" + row.days].editable = true;
            tempColumnsObj["day" + row.days].width = 100;
          } else {
            tempColumnsObj["day" + row.days] = {};
            tempColumnsObj["day" + row.days].title = row.days + " days";
            tempColumnsObj["day" + row.days].dataIndex = "day" + row.days;
            tempColumnsObj["day" + row.days].editable = true;
            tempColumnsObj["day" + row.days].width = 100;
          }
        });
      });
    }
    Object.keys(tempDataObj).forEach((prop) => {
      if (tempDataObj[prop]) {
        tempDataArr.push(tempDataObj[prop]);
      }
    });

    Object.keys(tempColumnsObj).forEach((prop) => {
      if (tempColumnsObj[prop]) {
        tempColumnsArr.push(tempColumnsObj[prop]);
      }
    });

    let tempPricingList = await PricingAPI.getPricing({
      filters: {},
      pagination: {},
      sorter: {},
    });
    let tempPricingList1 = [];
    tempPricingList.data.map(function(item) {
      tempPricingList1.push(item);
    });

    this.setState({
      dataSource: tempDataArr,
      columns: tempColumnsArr,
      pricingList: tempPricingList1,
      loading: false,
    });
  }

  render() {
    const { dataSource, columns } = this.state;
    const { getFieldDecorator } = this.props.form;
    const { formLayout } = this.state;
    const formItemLayout =
      formLayout === "horizontal"
        ? {
            labelCol: { span: 4 },
            wrapperCol: { span: 14 },
          }
        : null;
    const pricingTypeSelector = getFieldDecorator("priceCalcTypeId", {
      rules: [
        {
          required: true,
          message: <IntlMessages id="admin.input.required" />,
        },
      ],
    })(
      <Select placeholder="-select-" onChange={this.onPriceTypeChange}>
        {this.state.pricingTypes.map(function(item) {
          return (
            <Option key={item.id} value={item.id}>
              {item.name}
            </Option>
          );
        })}
      </Select>
    );
    const derivedFromSelector = getFieldDecorator("derivedPriceListId", {
      initialValue:
        this.state.pricingList.length > 0
          ? this.state.pricingList[0].pricingListId
          : "",
    })(
      <Select>
        {this.state.pricingList.length > 0
          ? this.state.pricingList.map(function(item) {
              return (
                <Option key={item.pricingListId} value={item.pricingListId}>
                  {item.description} ({item.calculationFor})
                </Option>
              );
            })
          : ""}
      </Select>
    );
    const operatorSelector = getFieldDecorator("arithmeticOperator", {
      rules: [
        {
          required: true,
          message: <IntlMessages id="admin.input.required" />,
        },
      ],
      initialValue: this.state.defaultOperator,
    })(
      <Select>
        {this.state.operators.map(function(item) {
          return <Option key={item.value}>{item.value}</Option>;
        })}
      </Select>
    );
    const pricingForSelector = getFieldDecorator("calculationFor", {
      rules: [
        {
          required: true,
          message: <IntlMessages id="admin.input.required" />,
        },
      ],
    })(
      <Select placeholder="-select-">
        {this.state.pricingFor.map(function(item) {
          return <Option key={item.name}>{item.name}</Option>;
        })}
      </Select>
    );

    const countryOrStateSelector = getFieldDecorator("priceType", {
      rules: [
        {
          required: true,
          message: <IntlMessages id="admin.input.required" />,
        },
      ],
    })(
      <Select
        placeholder="-select-"
        onChange={(evt) => this.onCountryStateChange(evt)}
      >
        {this.state.countryOrState.map(function(item) {
          return <Option key={item.id}>{item.name}</Option>;
        })}
      </Select>
    );
    const columnsList = columns.map((col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record) => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave: this.handleSave,
        }),
      };
    });
    return (
      <section>
        <Row gutter={24}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Typography.Title level={4}>
              <IntlMessages id="admin.pricinglist.addnew" />
            </Typography.Title>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Button
              type="primary"
              className="right-fl"
              htmlType="button"
              onClick={() => this.props.history.push("./../")}
            >
              <IntlMessages id="admin.pricingview.back" />
            </Button>
          </Col>
        </Row>
        <hr />
        <div>
          <Spin spinning={this.state.loading}>
            <Form layout={formLayout} onSubmit={this.handleSubmit}>
              <Row gutter={24}>
                <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                  <Form.Item
                    {...formItemLayout}
                    label={<IntlMessages id="admin.pricingview.name" />}
                  >
                    {getFieldDecorator("name", {
                      rules: [
                        {
                          required: true,
                          message: <IntlMessages id="admin.input.required" />,
                        },
                      ],
                    })(<Input maxLength={50} />)}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                  <Form.Item
                    {...formItemLayout}
                    label={<IntlMessages id="admin.pricingview.description" />}
                  >
                    {getFieldDecorator("description", {
                      rules: [
                        {
                          required: true,
                          message: <IntlMessages id="admin.input.required" />,
                        },
                      ],
                    })(<Input maxLength={30} />)}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                  <Form.Item
                    {...formItemLayout}
                    label={
                      <IntlMessages id="admin.pricingview.calculationFor" />
                    }
                  >
                    {pricingForSelector}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                  <Form.Item
                    {...formItemLayout}
                    label={
                      <IntlMessages id="admin.pricinglist.countryorstate" />
                    }
                  >
                    {countryOrStateSelector}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                  <Form.Item
                    {...formItemLayout}
                    label={<IntlMessages id="admin.pricingview.type" />}
                  >
                    {pricingTypeSelector}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24} style={{ display: this.state.showDerivedRow }}>
                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                  <Form.Item
                    {...formItemLayout}
                    label={<IntlMessages id="admin.pricingview.derivedPrice" />}
                  >
                    {derivedFromSelector}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                  <Form.Item
                    {...formItemLayout}
                    label={<IntlMessages id="admin.pricingview.operator" />}
                  >
                    {operatorSelector}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                  <Form.Item
                    {...formItemLayout}
                    label={<IntlMessages id="admin.pricingview.operand" />}
                  >
                    {getFieldDecorator("operand", {
                      rules: [
                        {
                          required: true,
                          message: <IntlMessages id="admin.input.required" />,
                        },
                      ],
                      initialValue: 1,
                    })(<Input />)}
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={24}>
                <Col xs={24}>
                  <span
                    style={{
                      color: "red",
                      paddingBottom: "10px",
                      display: this.state.showBaseRow,
                    }}
                  >
                    Click on numbers to edit price
                  </span>
                </Col>
              </Row>
              <Row
                gutter={24}
                className="pricing-table"
                style={{ display: this.state.showBaseRow }}
              >
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Table
                    components={this.state.tableComponents}
                    rowClassName={() => "editable-row"}
                    bordered
                    dataSource={dataSource}
                    pagination={false}
                    columns={columnsList}
                    scroll={{ x: 1500, y: 300 }}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Item>
                    <Button
                      type="primary"
                      style={{ display: "inline-block", marginRight: "10px" }}
                      className="def-blue"
                      htmlType="submit"
                      onClick={this.handleSubmit}
                    >
                      <IntlMessages id="admin.pricingview.add" />
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Spin>
        </div>
      </section>
    );
  }
}
BasePricing.propTypes = {
  priceList: PropTypes.object,
  updatePricing: PropTypes.func,
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...PricingActions, ...adminActions }, dispatch);
}
const WrappedForm = Form.create({ name: "edit-pricing" })(BasePricing);
export default withRouter(
  connect(null, mapDispatchToProps, null, { forwardRef: true })(WrappedForm)
);
