import API from "../api/custom-alert-api";

export function addAlerts(data) {
  return async function (dispatch, getState) {
    try {
      let resp = await API.addCustomAlert(data);
      return resp;
    } catch (e) {
      return { error: true };
    }
  };
}
export function getAllCustomAlerts(orgId, data) {
  
  return async function (dispatch, getState) {
    try {
      let resp = await API.getCustAlert(orgId, data);
      return resp;
    } catch (e) {
      return { error: true };
    }
  };
}
export function CustomAlertDetail(idx) {
  
  return async function (dispatch, getState) {
    try {
      let resp = await API.getCustomAlertDetail(idx);
      return resp;
    } catch (e) {
      return { error: true };
    }
  };
}
export function getUserReplies(idx, data) {
  
  return async function (dispatch, getState) {
    try {
      let resp = await API.getUserReplies(idx, data);
      return resp;
    } catch (e) {
      return { error: true };
    }
  };
}

