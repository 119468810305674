import Axios from "../../services/axios-service";

const applyPromoCode = async (couponCode) => {
  return await Axios.get(
    "/api/applypromooffer/" + couponCode,
    undefined,
    undefined
  );
};

const placeOrder = async (objOrder) => {
  return await Axios.post("/api/ResellerSubmitOrder", objOrder, undefined);
};

const resendMembershipInfo = async (id) => {
  return await Axios.get(
    "/api/SendMembershipInformation/" + id,
    undefined,
    undefined
  );
};

const resendProofOfBenefits = async (id) => {
  return await Axios.get("/api/SendProofOfBenefits/" + id, undefined, undefined);
};

const validateTravellerEmail = async (email) => {
  return await Axios.get(
    "/api/TravelerExists?email=" + encodeURIComponent(email),
    undefined,
    undefined
  );
};

const getTravellerInfo = async (userId) => {
  return Axios.get("/api/travelerbasicinfo/" + userId, undefined, undefined);
};

const getAgentName = async (atn) => {
  return Axios.get("/api/getagentname/" + atn);
};

const getProductPricingAndInfo = async () => {
  return await Axios.get("/api/completeproductinfo", undefined, undefined);
};

const getResellerOrganizationInfo = async (orgId) => {
  return await Axios.get(
    "/api/ResellerPackages/" + orgId,
    undefined,
    "NO_TOKEN"
  );
};

const convertCurrency = async () => {
  return await Axios.get("/api/GetCurrencyValue/CAD", undefined, "NO_TOKEN");
};

const getAllCountries = async () => {
  return Axios.get("/api/Country", undefined, undefined);
};

const getGendersList = () => {
  return Axios.get("/api/GetGendersList", undefined, undefined);
};

export default {
  applyPromoCode,
  placeOrder,
  validateTravellerEmail,
  getTravellerInfo,
  getProductPricingAndInfo,
  getResellerOrganizationInfo,
  getAllCountries,
  resendProofOfBenefits,
  resendMembershipInfo,
  convertCurrency,
  getAgentName,
  getGendersList,
};
