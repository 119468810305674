import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Row, Col, Card, Switch, Spin } from "antd";
import {
  Map,
  GoogleApiWrapper,
  Marker,
  Polyline,
  InfoWindow
} from "google-maps-react";
import MarkerCluster from "../../user-map/Cluster";
import TravelFilter from "./travel-filter";
import AlertFilter from "./alert-filter";
import RiskLevelFilter from "./risk-level-filter";
import * as UserService from "../../../../services/user-service";
import * as travelerItineraryActions from "../../../../redux/actions/travelers-itinerary-action";
import * as assistTravelerActions from "../../../../redux/actions/assist-traveler-actions";
import * as travelerLocationActions from "../../../../redux/actions/traveler-location-actions";
import * as assetTrackingAction from "../../../../redux/actions/asset-tracking-action";
import ShowTravelerInfoWindow from "./traveler-info-window";
import ShowTravelerHotelInfoWindow from "./traveler-hotel-info-window";
import ShowCommonInfoWindow from "./assist-traveler-info-window";
import Config from "../../../../../../config";
import InfoWindowEx from "./infoWindowEx";
import HotelInfoWindowEx from "./hotel-infoWindowEx";
import TravelerListItem from "./traveler-list-item";
import AssistTravelersList from "./assist-travelers-list";
import API from "../../../../redux/api/admin-api";
import riskLineApi from "../../../../redux/api/risk-line-api";
import AssistTravelFilter from "./assist-traveler-filter";
import moment from "moment";
import config from "../../../../../../config";
import TravelerLocationList from "./travelers-location-list";
import TravelerLocationFilter from "./traveler-location-filter";
import AssetTrackingList from "./asset-tracking-list";

const dbDateFormat = "YYYY-MM-DD";
const mapStyles = {
  height: "90vh",
  width: "100%",
  position: "absolute"
};

const refs = {
  map: undefined,
  alertInfoWindow: undefined
};

let riskLevelColors = {
  Low: "#00a551",
  Moderate: "#84c341",
  Medium: "#d6d625",
  High: "#f7953e",
  Extreme: "#c9252b"
};
let orgId = 0;
let currentStartDate = moment().format(dbDateFormat);
let currentEndDate = moment().format(dbDateFormat);
class TravellerItinerayDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      airportLocation: "",
      hotelLocation: "",
      manageTravellers: "",
      magnaTravelerHtoels: "",
      travelersLocationList: "",
      flightPaths: "",
      singleFlightPath: "",
      showFlightPath: false,
      travelerInfoWindowData: "",
      travelerHotelInfoWindowData: "",
      itineraries: [],
      assistTravelers: [],
      loading: false,
      showTravelerFilter: false,
      showTravelerListFilter: false,
      showAlertFilter: false,
      showAssistFilter: false,
      showTraverLocationFilter: false,
      showRiskLevelFilter: false,
      showAssetTrackingFilter: false,
      showingInfoWindow: false,
      showingHotelInfoWindow: false,
      showingCommonInfoWindow: false,
      infoWindowData: "",
      activeMarker: {},
      infoWindowPosition: null,
      countriesListByRiskLevel: [],
      countriesList: [],
      shownRiskLevelsArray: ["Low", "Moderate", "Medium", "High", "Extreme"],
      alertStore: [],
      cloneStore: [],
      categoryList: [],
      selectedCountries: "",
      selectedRiskLevels: "",
      selectedCategoryName: "",
      selectedAlertTypeName: "",
      isAlert: true,
      filters: {},
      currentUserOrgId: 0,
      currentUserRole: "",
      selectedDuration: "1d",
      isLowRiskLevelsChecked: true,
      isModerateRiskLevelsChecked: true,
      isMediumRiskLevelsChecked: true,
      isHighRiskLevelsChecked: true,
      isExtremeRiskLevelsChecked: true,
      isRiskLevel: true,
      infoWindowType: "",
      travellerFilterValues: {
        startDate: "",
        endDate: "",
        airVendorCode: "",
        airportCodes: "",
        byairport: "departure",
        byDestination: "",
        firstName: "",
        lastName: "",
        flightNumber: "",
        parentOrgId: "",
        pnrNumber: "",
        allOrgs: true,
        parentOrganizationId: "all",
        currentSelectedParentOrg: 0,
        childOrg: ""
      },
      assitFilterValues: {},
      travelerLocationFilters: {},
      isShowTravelerLocations: true,
      assetTrackingList: []
    };
    this.onAirPortLocationMarkerClick = this.onAirPortLocationMarkerClick.bind(
      this
    );
  }

  storeStatesOfAssistTravelers = filter => {
    this.setState({
      assitFilterValues: filter ? filter : {}
    });
  };

  storeStatesOfTravelers = filter => {
    if (filter !== undefined) {
      this.setState({
        travellerFilterValues: {
          ...this.state.travellerFilterValues,
          startDate: filter.startDate,
          endDate: filter.endDate,
          airVendorCode: filter.airVendorCode,
          airportCodes: filter.airportCodes,
          byairport: filter.byairport,
          byDestination: filter.byDestination,
          firstName: filter.firstName,
          lastName: filter.lastName,
          flightNumber: filter.flightNumber,
          parentOrgId: filter.parentOrgId,
          pnrNumber: filter.pnrNumber,
          parentOrganizationId: filter.parentOrganizationId,
          currentSelectedParentOrg: filter.currentSelectedParentOrg,
          childOrg: filter.childOrgId
        }
      });
    } else {
      this.setState({
        travellerFilterValues: {
          ...this.state.travellerFilterValues,
          startDate: "",
          endDate: "",
          airVendorCode: "",
          airportCodes: "",
          byairport: "",
          byDestination: "",
          firstName: "",
          lastName: "",
          flightNumber: "",
          parentOrgId: "",
          pnrNumber: "",
          parentOrganizationId: "all",
          currentSelectedParentOrg: 0,
          childOrg: ""
        }
      });
    }
  };

  onTravelerFilterButtonClick = event => {
    this.setState({
      showTravelerFilter: event
    });
  };

  onTravelerListButtonClick = event => {
    this.setState({
      showTravelerListFilter: event
    });
  };

  onAlertFilterButtonClick = event => {
    this.setState({
      showAlertFilter: event
    });
  };
  onRiskLevelFilterButtonClick = event => {
    this.setState({
      showRiskLevelFilter: event
    });
  };
  onAssistFilterButtonClick = event => {
    this.setState({
      showAssistFilter: event
    });
  };
  onTravelerLocationFilterButtonClick = event => {
    this.setState({
      showTravelerLocationFilter: event
    });
  };
  onAssetTrackingFilterButtonClick = event => {
    this.setState({
      showAssetTrackingFilter: event
    });
  };

  onAirPortLocationMarkerClick = (props, marker, i) => {
    // Close Alert InfoWindow Start
    if (refs.alertInfoWindow) {
      refs.alertInfoWindow.close();
    }
    // Close Alert InfoWindow End

    let data = this.state.manageTravellers[props.code];
    let flightPathData = this.state.flightPaths[data[0].pnrId];

    this.setState({
      activeMarker: marker,
      showingInfoWindow: true,
      showingHotelInfoWindow: false,
      showingCommonInfoWindow: false,
      travelerInfoWindowData: data,
      singleFlightPath: flightPathData,
      showFlightPath: true,
      infoWindowPosition: null
    });
  };
/*
Status: LANDED
Departure: ADD BOLE INTL
Date/Time: 07/03/2021 00:16
Arrival: FRA FRANKFURT MAIN
Date/Time: 07/03/2021 05:41
Medical:112
*/
  onHotelLocationMarkerClick = (props, marker, i) => {
    // Close Alert InfoWindow Start
    if (refs.alertInfoWindow) {
      refs.alertInfoWindow.close();
    }
    // Close Alert InfoWindow End

    let data = this.state.magnaTravelerHtoels[props.code];

    this.setState({
      activeMarker: marker,
      showingInfoWindow: false,
      showingCommonInfoWindow: false,
      showingHotelInfoWindow: true,
      travelerHotelInfoWindowData: data,
      infoWindowPosition: null
    });
  };

  onLocationMarkerClick = (props, marker, e) => {
    // Close Alert InfoWindow Start
    if (refs.alertInfoWindow) {
      refs.alertInfoWindow.close();
    }
    // Close Alert InfoWindow End
    this.setState({
      infoWindowData: props.infoData,
      infoWindowType: props.type,
      activeMarker: marker,
      showingCommonInfoWindow: true,
      showingHotelInfoWindow: false,
      showingInfoWindow: false
    });
  };

  handleAssistTravlerClick = data => {
    this.setState({
      activeMarker: null,
      showingCommonInfoWindow: true,
      showingHotelInfoWindow: false,
      showingInfoWindow: false,
      infoWindowData: data,
      infoWindowType: "assist",
      infoWindowPosition: new window.google.maps.LatLng(data.lat, data.lng),
      showAssistFilter: false
    });
  };
  handleTravlerLocationClick = data => {
    this.setState({
      activeMarker: null,
      showingCommonInfoWindow: true,
      showingHotelInfoWindow: false,
      showingInfoWindow: false,
      infoWindowData: data,
      infoWindowType: "traveler-location",
      infoWindowPosition: new window.google.maps.LatLng(
        data.latitude,
        data.longitude
      ),
      showTravelerLocationFilter: false
    });
  };

  handleAssetTrackingClick = data => {
    let latlng = JSON.parse(data.points);
    this.setState({
      activeMarker: null,
      showingCommonInfoWindow: true,
      showingHotelInfoWindow: false,
      showingInfoWindow: false,
      infoWindowData: data,
      infoWindowType: "asset-tracking",
      infoWindowPosition: new window.google.maps.LatLng(latlng.lat, latlng.lng),
      showAssetTrackingFilter: false
    });
  };

  handleSingleAlertListClick = alert => {
    // Close Alert InfoWindow Start
    if (refs.alertInfoWindow) {
      refs.alertInfoWindow.close();
    }
    // Close Alert InfoWindow End
    var infowindow = new window.google.maps.InfoWindow();
    infowindow.setContent(
      "<div class='markerAlretWindow' style = 'width:400px;min-height:100px; line-height: 20px;'><ul><li><strong>Alert: </strong>" +
        alert.title +
        "</li><li> <strong>Date:</strong> " +
        moment(new Date(alert.startDate * 1000).toUTCString()).format(
          "MM/DD/YYYY"
        ) +
        "</li><li><strong>Location:</strong> " +
        alert.location_explanation +
        "</li></ul</div>"
    );
    infowindow.setPosition({ lat: alert.geo.lat, lng: alert.geo.lng });
    infowindow.open(refs.map);
    refs.alertInfoWindow = infowindow;
    this.setState({ showAlertFilter: false });
  };

  onInfoWindowPersonTabClick = pnrId => {
    let flightPathData = this.state.flightPaths[pnrId];
    this.setState({
      singleFlightPath: flightPathData
    });
  };

  update = e => {
    if (e.target.checked) {
      if ("alert" === e.target.name) {
        this.setState({ isAlert: true });
      }
      if ("person" === e.target.name) {
        this.setState({ isPerson: true });
      }
      if ("flight" === e.target.name) {
        this.setState({ isFlight: true });
      }
      if ("traveler-location" === e.target.name) {
        this.setState({ isShowTravelerLocations: true });
      } else if ("risklevel" === e.target.name) {
        this.setStyle(
          refs.map,
          true,
          this.state.countriesListByRiskLevel,
          this.state.shownRiskLevelsArray
        );
        this.setState({
          isLowRiskLevelsChecked: true,
          isModerateRiskLevelsChecked: true,
          isMediumRiskLevelsChecked: true,
          isHighRiskLevelsChecked: true,
          isExtremeRiskLevelsChecked: true,
          isRiskLevel: true
        });
      }
    } else {
      if ("alert" === e.target.name) {
        this.setState({ isAlert: false });
      }
      if ("person" === e.target.name) {
        this.setState({ isPerson: false });
      }
      if ("flight" === e.target.name) {
        this.setState({ isFlight: false });
      }
      if ("traveler-location" === e.target.name) {
        this.setState({ isShowTravelerLocations: false });
      }
      if ("risklevel" === e.target.name) {
        this.setStyle(refs.map, false);
        this.setState({
          isLowRiskLevelsChecked: false,
          isModerateRiskLevelsChecked: false,
          isMediumRiskLevelsChecked: false,
          isHighRiskLevelsChecked: false,
          isExtremeRiskLevelsChecked: false,
          isRiskLevel: false
        });
      }
    }
  };

  handleCountryChange = code => {
    if (code === "all") {
      this.setState({ loading: true, selectedCountries: "" });
    } else {
      this.setState({ loading: true, selectedCountries: code });
    }

    this.getRiskLineAlertsByFilter(code, "", "", "", "");
  };

  handleRiskLevelChange = risklevel => {
    this.setState({ loading: true, selectedRiskLevels: risklevel.key });
    this.getRiskLineAlertsByFilter("", risklevel.key, "", "", "");
  };

  handleDurationChange = time => {
    this.setState({ loading: true, selectedDuration: time.key });
    this.getRiskLineAlertsByFilter("", "", time.key, "", "");
  };

  handleCategoryChange = catname => {
    if (catname === "all") {
      this.setState({ loading: true, selectedCategoryName: "" });
    } else {
      this.setState({ loading: true, selectedCategoryName: catname });
    }

    this.getRiskLineAlertsByFilter("", "", "", catname, "");
  };

  handleShowNoticesChange = checked => {
    var alertType;
    if (checked) {
      alertType = "Notices";
    } else {
      alertType = "All";
    }
    this.setState({ loading: true, selectedAlertTypeName: alertType });
    this.getRiskLineAlertsByFilter("", "", "", "", alertType);
  };

  onFilterRiskLevelColor = (levels, checked, name) => {
    if (levels.length == 5) {
      this.setState({ isRiskLevel: true });
    } else {
      this.setState({ isRiskLevel: false });
    }
    if (name === "Low") {
      this.setState({ isLowRiskLevelsChecked: checked });
    } else if (name === "Moderate") {
      this.setState({ isModerateRiskLevelsChecked: checked });
    } else if (name === "Medium") {
      this.setState({ isMediumRiskLevelsChecked: checked });
    } else if (name === "High") {
      this.setState({ isHighRiskLevelsChecked: checked });
    } else if (name === "Extreme") {
      this.setState({ isExtremeRiskLevelsChecked: checked });
    }
    this.setState({ shownRiskLevelsArray: levels });
    this.setStyle(refs.map, true, this.state.countriesListByRiskLevel, levels);
  };

  getRiskLineAlertsByFilter(ccode, risklevel, duration, category, type) {
    riskLineApi
      .risklineAlerts({
        filters: {
          countries:
            ccode === "all" ? "" : ccode ? ccode : this.state.selectedCountries,
          riskLevel: risklevel ? risklevel : this.state.selectedRiskLevels,
          days: duration ? duration : this.state.selectedDuration,
          categoryName:
            category === "all"
              ? ""
              : category
              ? category
              : this.state.selectedCategoryName,
          type: type ? type : this.state.selectedAlertTypeName
        },
        pagination: { page: 1, pageSize: 1000 },
        sorter: {}
      })
      .then(riskAlerts => {
        this._bindCordinates(riskAlerts.data);
      })
      .catch(ex => {});
  }

  handleResetAlertFilter = () => {
    this.setState({
      loading: true,
      selectedCountries: "",
      selectedRiskLevels: "",
      selectedCategoryName: "",
      selectedAlertTypeName: "",
      selectedDuration: "1d"
    });
    riskLineApi
      .risklineAlerts({
        filters: {
          countries: "",
          riskLevel: "",
          days: "1d",
          categoryName: "",
          type: ""
        },
        pagination: { page: 1, pageSize: 1000 },
        sorter: {}
      })
      .then(riskAlerts => {
        this._bindCordinates(riskAlerts.data);
      })
      .catch(ex => {});
  };

  handleTravlerClick = data => {
     let marker = new window.google.maps.Marker({
     position: new window.google.maps.LatLng(data[0].lat, data[0].lng),
      map: refs.map,
      vsible: false
  });
    // Close Alert InfoWindow Start
    if (refs.alertInfoWindow) {
      refs.alertInfoWindow.close();
    }
    // Close Alert InfoWindow End
    let flightPathData = this.state.flightPaths[data[0].pnrId];
    this.setState({
      activeMarker: null,
      showingInfoWindow: true,
      showingHotelInfoWindow: false,
      showingCommonInfoWindow: false,
      travelerInfoWindowData: data,
      showTravelerListFilter: false,
      infoWindowPosition: new window.google.maps.LatLng(
        data[0].lat,
        data[0].lng
      ),
      singleFlightPath: flightPathData,
      showFlightPath: true
    });
  };

  handleAssistDetailsTableChange = data => {
    this.setState({ assistTravelers: data });
  };

  handleTravelerDetailsTableChange = filter => {
    // Close Alert InfoWindow Start
    if (refs.alertInfoWindow) {
      refs.alertInfoWindow.close();
    }
    //filter.startDate =moment(values.startDate).format(dbDateFormat)
    if (filter !== undefined) {
      filter.startDate =
        filter.startDate !== "Invalid date" &&
        filter.startDate !== "" &&
        filter.startDate !== undefined
          ? moment(filter.startDate).format(dbDateFormat)
          : "";
      filter.endDate =
        filter.endDate !== "Invalid date" &&
        filter.endDate !== "" &&
        filter.endDate !== undefined
          ? moment(filter.endDate).format(dbDateFormat)
          : "";
    }

    // Close Alert InfoWindow End
    this.props
      .getTravelersItinerariesByOrgId(
        this.state.currentUserOrgId,
        this.state.currentUserRole,
        {
          filters: filter ? filter : {},
          pagination: { page: 1, pageSize: 5 },
          sorter: {}
        }
      )
      .then(resp => {
        this.setState({
          airportLocation: resp.airportLocation,
          manageTravellers: resp.magnaTravellers,
          magnaTravelerHtoels: resp.magnaTravelerHtoels,
          hotelLocation: resp.hotelLocation,
          itineraries: resp.itineraries,
          flightPaths: resp.flightPaths,
          showingInfoWindow: false,
          showingCommonInfoWindow: false,
          showingHotelInfoWindow: false,
          singleFlightPath: ""
        });
      })
      .catch(ex => {
        this.setState({ loading: false });
      });
  };

  handleTravelerLocationFilterChange = (data, filters) => {
    this.setState({
      travelersLocationList: data,
      travelerLocationFilters: filters
    });
  };

  closeOtherInfoWindows = alerInfoWindow => {
    // Close Alert InfoWindow Start
    if (refs.alertInfoWindow && refs.alertInfoWindow !== alerInfoWindow) {
      refs.alertInfoWindow.close();
    }
    // Close Alert InfoWindow End

    refs.alertInfoWindow = alerInfoWindow;
    this.setState({
      showingInfoWindow: false,
      showingHotelInfoWindow: false,
      showingCommonInfoWindow: false
    });
  };

  onMapReady = ({ google }, map) => {
    refs.map = map;
    //map.data.loadGeoJson(config.API1 + "/images/custom.geo.json");
    map.data.loadGeoJson("https://api.captravelassistance.com/images/custom.geo.json"); //using prod json for testing
  };

  setStyle = (map, apply, cData, shownRiskLevelsArray) => {
    refs.mapProjection = map.getProjection();
    /* @type {google.maps.Data.StyleOptions} */
    if (apply) {
      map.data.setStyle(function(feature) {
        var color = "";
        var fillOpacity = 0;
        if (feature.getProperty("wb_a2")) {
          shownRiskLevelsArray.map(risklevel => {
            if (risklevel === cData[feature.getProperty("wb_a2")]) {
              color = riskLevelColors[cData[feature.getProperty("wb_a2")]];
              fillOpacity = 0.5;
            }
          });
        }
        return ({
          fillColor: color,
          // strokeColor: color,
          fillOpacity: fillOpacity,
          strokeWeight: 0
        });
      });
    } else {
      map.data.setStyle({ fillOpacity: 0, strokeWeight: 0 });
    }
  };

  _bindCordinates(riskAlerts) {
    var tempCordinateArray = [];
    var alertArray = [];
    riskAlerts.map((alert, index) => {
      if (alert.geo) {
        var alertIconName =
          alert.risk_level.toLowerCase() +
          "-" +
          alert.category_name.replace(/\s+/g, "-").toLowerCase();
        alert["type"] = "alert";
        alert["icon"] = alertIconName + ".png";

        tempCordinateArray[index] = alert;
      }
    });
    var j = 0;
    tempCordinateArray.map((riskalert, i) => {
      alertArray[j] = riskalert;
      j++;
    });

    this.setState({
      alertStore: alertArray,
      cloneStore: alertArray,
      loading: false
    });
  }

  fetchAssistTravelersList() {
    this.props
      .getAssistTravelersList({
        filters: {},
        pagination: { page: 1, pageSize: 5 },
        sorter: {}
      })
      .then(resp => {
        this.setState({ assistTravelers: resp.data });
      })
      .catch(ex => {
        this.setState({ loading: false });
      });
  }

  async componentDidMount() {
    this.setState({ loading: true });
    let currentUser = UserService.getUser();
    let userRole = currentUser.role;
    if (userRole === "Administrator") {
      orgId = 1;
    } else {
      orgId = currentUser.companyID;
    }
    await this.props
      .getTravelersItinerariesByOrgId(orgId, userRole, {
        filters: {
          startDate: currentStartDate,
          endDate: currentEndDate
        },
        pagination: { page: 1, pageSize: 5 },
        sorter: {}
      })
      .then(resp => {
        this.setState({
          airportLocation: resp.airportLocation,
          hotelLocation: resp.hotelLocation,
          manageTravellers: resp.magnaTravellers,
          magnaTravelerHtoels: resp.magnaTravelerHtoels,
          itineraries: resp.itineraries,
          flightPaths: resp.flightPaths,
          currentUserOrgId: orgId,
          currentUserRole: userRole
        });
      })
      .catch(ex => {
        this.setState({ loading: false });
      });

    this.fetchAssistTravelersList();

    await riskLineApi
      .getRiskLineCategories()
      .then(res => {
        this.setState({
          categoryList: res.categories
        });
      })
      .catch(ex => {
        this.setState({ loading: false });
      });

    await API.getCountriesListByRegion()
      .then(resp => {
        var tempCountryArrayByRiskLevel = [];
        var tempCountryArray = [];
        resp.data.map((r, index) =>
          r.countryList.map(
            (c, index) => (
              (tempCountryArrayByRiskLevel[c.code] = c.riskLevel),
              tempCountryArray.push({
                countryName: c.countryName,
                code: c.code
              })
            )
          )
        );
        this.setStyle(
          refs.map,
          true,
          tempCountryArrayByRiskLevel,
          this.state.shownRiskLevelsArray
        );
        this.setState({
          countriesListByRiskLevel: tempCountryArrayByRiskLevel,
          countriesList: tempCountryArray
        });
      })
      .catch(ex => {
        this.setState({ loading: false });
      });

    await riskLineApi
      .risklineAlerts({
        countries: {},
        riskLevel: {},
        filters: { days: "1d" },
        pagination: { page: 1, pageSize: 1000 },
        sorter: {}
      })
      .then(riskAlerts => {
        this._bindCordinates(riskAlerts.data);
      })
      .catch(ex => {
        this.setState({ loading: false });
      });

    await this.props
      .getTravelersList({
        filters: {},
        pagination: { page: 1, pageSize: 1000 },
        sorter: {}
      })
      .then(resp => {
        this.setState({ travelersLocationList: resp.data });
      })
      .catch(ex => {
        this.setState({ loading: false });
      });

    this.props
      .getAssetTracking(
        {
          filters: {},
          pagination: {
            page: 1,
            pageSize: 1000
          },
          sorter: {}
        },
        orgId
      )
      .then(resp => {
        this.setState({ assetTrackingList: resp.data });
        console.log(resp);
      })
      .catch(ex => {
        this.setState({ loading: false });
      });
  }

  render() {
    return (
      <Spin spinning={this.state.loading}>
        <Card
          title=""
          className="fullItineryDetails no-margin"
          style={{ position: "relative", height: "90vh", width: "100%" }}
        >
          <Map
            google={this.props.google}
            style={mapStyles}
            zoom={3}
            minZoom={2}
            onReady={this.onMapReady}
            visible={true}
          >
            {this.state.isAlert && (
              <MarkerCluster
                onAlertClick={this.closeOtherInfoWindows}
                markers={this.state.alertStore}
                imagePath={
                  "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m"
                }
              ></MarkerCluster>
            )}
            {this.state.airportLocation &&
              Object.entries(this.state.airportLocation).map(
                ([code, value]) => {
                  return (
                    <Marker
                      onClick={this.onAirPortLocationMarkerClick}
                      code={code}
                      icon={{
                        url: Config.API1 + "/travel-icons/" + value.icon,
                        scaledSize: new window.google.maps.Size(32, 33)
                      }}
                      position={{
                        lat: value.lat,
                        lng: value.lng
                      }}
                    />
                  );
                }
              )}
            {this.state.hotelLocation &&
              Object.entries(this.state.hotelLocation).map(([code, value]) => {
                return (
                  <Marker
                    onClick={this.onHotelLocationMarkerClick}
                    code={code}
                    icon={{
                      url: Config.API1 + "/travel-icons/" + value.icon,
                      scaledSize: new window.google.maps.Size(32, 33)
                    }}
                    position={{
                      lat: value.lat,
                      lng: value.lng
                    }}
                  />
                );
              })}
            {this.state.assistTravelers &&
              this.state.assistTravelers.length > 0 &&
              this.state.assistTravelers.map((marker, i) => {
                return (
                  <Marker
                    onClick={this.onLocationMarkerClick}
                    type="assist"
                    infoData={marker}
                    icon={{
                      url:
                        Config.API1 +
                        "/travel-icons/assistance-" +
                        marker.assistStatus +
                        ".png",
                      scaledSize: new window.google.maps.Size(32, 33)
                    }}
                    position={{
                      lat: marker.lat,
                      lng: marker.lng
                    }}
                  />
                );
              })}
            {this.state.isShowTravelerLocations &&
              this.state.travelersLocationList &&
              this.state.travelersLocationList.length > 0 &&
              this.state.travelersLocationList.map((marker, i) => {
                return (
                  <Marker
                    onClick={this.onLocationMarkerClick}
                    type="traveler-location"
                    infoData={marker}
                    icon={{
                      url: Config.API1 + "/travel-icons/traveler-location.png",
                      scaledSize: new window.google.maps.Size(32, 33)
                    }}
                  
                    position={{
                      lat: marker.latitude,
                      lng: marker.longitude
                    }}
                  />
                );
              })}
            {this.state.assetTrackingList &&
              this.state.assetTrackingList.length > 0 &&
              this.state.assetTrackingList.map((marker, i) => {
                let latlng = JSON.parse(marker.points);
                return (
                  <Marker
                    type="asset-tracking"
                    infoData={marker}
                    onClick={this.onLocationMarkerClick}
                    icon={{
                      url: Config.API1 + "/travel-icons/asset-tracking.png",
                      scaledSize: new window.google.maps.Size(32, 33)
                    }}
                    position={{
                      lat: latlng.lat,
                      lng: latlng.lng
                    }}
                  />
                );
              })}
            {this.state.flightPaths &&
              this.state.singleFlightPath &&
              this.state.singleFlightPath.map(path => (
                <Polyline
                  path={path}
                  geodesic={true}
                  options={{
                    strokeColor: "#FF0000",
                    strokeOpacity: 0,
                    strokeWeight: 2,
                    icons: [
                      {
                        icon: {
                          path: "M 0,-1 0,1",
                          strokeOpacity: 1,
                          scale: 3
                        },
                        offset: "0",
                        repeat: "20px"
                      }
                    ]
                  }}
                />
              ))}
            <InfoWindowEx
              marker={this.state.activeMarker}
              visible={this.state.showingInfoWindow}
              position={this.state.infoWindowPosition}
            >
              {this.state.travelerInfoWindowData && (
                <div>
                  <ShowTravelerInfoWindow
                    travelerInfoWindowData={this.state.travelerInfoWindowData}
                    onInfoWindowPersonTabClick={this.onInfoWindowPersonTabClick}
                    {...this.props}
                  />
                </div>
              )}
            </InfoWindowEx>
            <HotelInfoWindowEx
              marker={this.state.activeMarker}
              visible={this.state.showingHotelInfoWindow}
              position={this.state.infoWindowPosition}
            >
              {this.state.travelerHotelInfoWindowData && (
                <div>
                  <ShowTravelerHotelInfoWindow
                    hotelInfoWindowData={this.state.travelerHotelInfoWindowData}
                    {...this.props}
                  />
                </div>
              )}
            </HotelInfoWindowEx>
            <InfoWindow
              marker={this.state.activeMarker}
              visible={this.state.showingCommonInfoWindow}
              position={this.state.infoWindowPosition}
            >
              {this.state.showingCommonInfoWindow && (
                <ShowCommonInfoWindow
                  infoWindowData={this.state.infoWindowData}
                  type={this.state.infoWindowType}
                />
              )}
            </InfoWindow>
          </Map>

          <div className="traveller-filter traveller-notifation">
            <div
              type="primary"
              className="gotomapbtn"
              style={{ width: "220px" }}
            >
              <Switch
                onChange={this.onTravelerListButtonClick}
                checked={this.state.showTravelerListFilter}
              />
              <i className="fas fa-angle-double-down arrow-icon"></i>
              <i class="fas fa-clipboard-list sign-icon"></i>Travelers Detail
            </div>
            {this.state.showTravelerListFilter && (
              <Row gutter={[24]} className="TravelfilterList">
                {this.state.itineraries && (
                  <Row gutter={[24]}>
                    <Col xs={24} sm={24} md={18}>
                      <TravelerListItem
                        itineraries={this.state.itineraries}
                        handleTravlerClick={this.handleTravlerClick}
                      />
                    </Col>
                    <Col xs={24} sm={24} md={6} className="TravelfilterBox">
                      <TravelFilter
                        handleTableChange={
                          this.handleTravelerDetailsTableChange
                        }
                        handleStateSave={this.storeStatesOfTravelers}
                        savedState={this.state.travellerFilterValues}
                      />
                    </Col>
                  </Row>
                )}
              </Row>
            )}
          </div>

          <div className="traveller-filter alert-filter">
            <div
              type="primary"
              className="gotomapbtn"
              style={{ marginRight: "230px", width: "190px", zIndex: "3" }}
            >
              <Switch
                onChange={this.onAlertFilterButtonClick}
                checked={this.state.showAlertFilter}
              />
              <i className="fas fa-angle-double-down arrow-icon"></i>
              <i class="fas fa-info-circle sign-icon"></i>Alert Filters
            </div>
            {this.state.showAlertFilter && (
              <Row
                gutter={[24]}
                className="TravelfilterList"
                style={{ height: "430px" }}
              >
                <AlertFilter
                  stores={this.state.alertStore}
                  isAlert={this.state.isAlert}
                  countriesList={this.state.countriesList}
                  categoryList={this.state.categoryList}
                  countryChange={this.handleCountryChange}
                  riskLevelChange={this.handleRiskLevelChange}
                  durationChange={this.handleDurationChange}
                  categoryChange={this.handleCategoryChange}
                  showNoticesChange={this.handleShowNoticesChange}
                  onResetAlertsFilter={this.handleResetAlertFilter}
                  onChangeAlertCheckBox={this.update}
                  handleSingleAlertListClick={this.handleSingleAlertListClick}
                />
              </Row>
            )}
          </div>
          <div className="traveller-filter risk-level-filter">
            <div
              type="primary"
              className="gotomapbtn"
              style={{ marginRight: "426px", width: "230px", zIndex: "2" }}
            >
              <Switch
                onChange={this.onRiskLevelFilterButtonClick}
                checked={this.state.showRiskLevelFilter}
              />
              <i className="fas fa-angle-double-down arrow-icon"></i>
              <i class="fas fa-exclamation-triangle sign-icon"></i>Risk Level
              Filters
            </div>
            {this.state.showRiskLevelFilter && (
              <Row gutter={[24]} className="TravelfilterList">
                <RiskLevelFilter
                  isLowRiskLevelsChecked={this.state.isLowRiskLevelsChecked}
                  isModerateRiskLevelsChecked={
                    this.state.isModerateRiskLevelsChecked
                  }
                  isMediumRiskLevelsChecked={
                    this.state.isMediumRiskLevelsChecked
                  }
                  isHighRiskLevelsChecked={this.state.isHighRiskLevelsChecked}
                  isExtremeRiskLevelsChecked={
                    this.state.isExtremeRiskLevelsChecked
                  }
                  isRiskLevel={this.state.isRiskLevel}
                  onChangeRiskLevelCheckBox={this.update}
                  onCheckUncheckRiskLevel={this.onFilterRiskLevelColor}
                  shownRiskLevelsArray={this.state.shownRiskLevelsArray}
                />
              </Row>
            )}
          </div>
          <div className="traveller-filter assist-filter">
            <div
              type="primary"
              className="gotomapbtn"
              style={{ marginRight: "663px", width: "150px", zIndex: "1" }}
            >
              <Switch
                onChange={this.onAssistFilterButtonClick}
                checked={this.state.showAssistFilter}
              />
              <i className="fas fa-angle-double-down arrow-icon"></i>
              <i className="fas fa-assistive-listening-systems sign-icon"></i>
              Assist
            </div>
            {this.state.showAssistFilter && (
              <Row gutter={[24]} className="TravelfilterList">
                <Row gutter={[24]}>
                  <Col xs={24} sm={24} md={18}>
                    <AssistTravelersList
                      handleAssistTravlerClick={this.handleAssistTravlerClick}
                      assistTravelers={this.state.assistTravelers}
                      handleTableChange={this.handleAssistDetailsTableChange}
                      savedState={this.state.assitFilterValues}
                    />
                  </Col>
                  <Col xs={24} sm={24} md={6} className="TravelfilterBox">
                    <AssistTravelFilter
                      handleTableChange={this.handleAssistDetailsTableChange}
                      handleAssistStateSave={this.storeStatesOfAssistTravelers}
                      savedState={this.state.assitFilterValues}
                    />
                  </Col>
                </Row>
              </Row>
            )}
          </div>
          <div className="traveller-filter traveler-location-filter">
            <div
              type="primary"
              className="gotomapbtn"
              style={{ marginRight: "820px", width: "220px", zIndex: "1" }}
            >
              <Switch
                onChange={this.onTravelerLocationFilterButtonClick}
                checked={this.state.showTravelerLocationFilter}
              />
              <i className="fas fa-angle-double-down arrow-icon"></i>
              Traveler Location
            </div>
            {this.state.showTravelerLocationFilter && (
              <Row gutter={[24]} className="TravelfilterList">
                <Row gutter={[24]}>
                  <Col xs={24} sm={24} md={18}>
                    <TravelerLocationList
                      travellersLocationList={this.state.travelersLocationList}
                      isShowTravelerLocations={
                        this.state.isShowTravelerLocations
                      }
                      onChangeTravelerLocationCheckBox={this.update}
                      handleTravlerLocationClick={
                        this.handleTravlerLocationClick
                      }
                    />
                  </Col>
                  <Col xs={24} sm={24} md={6} className="TravelfilterBox">
                    <TravelerLocationFilter
                      handleFilterChange={
                        this.handleTravelerLocationFilterChange
                      }
                      savedState={this.state.travelerLocationFilters}
                    />
                  </Col>
                </Row>
              </Row>
            )}
          </div>
          <div className="traveller-filter asset-filter">
            <div
              type="primary"
              className="gotomapbtn"
              style={{ marginRight: "1050px", width: "220px", zIndex: "2" }}
            >
              <Switch
                onChange={this.onAssetTrackingFilterButtonClick}
                checked={this.state.showAssetTrackingFilter}
              />
              <i className="fas fa-angle-double-down arrow-icon"></i>
              <i class="fas fa-exclamation-triangle sign-icon"></i>Asset
              Tracking
            </div>
            {this.state.showAssetTrackingFilter && (
              <Row gutter={[24]} className="TravelfilterList">
                <Row gutter={[24]}>
                  <Col xs={24} sm={24}>
                    <AssetTrackingList
                      assetTrackingList={this.state.assetTrackingList}
                      handleAssetTrackingClick={this.handleAssetTrackingClick}
                    />
                  </Col>
                </Row>
              </Row>
            )}
          </div>
        </Card>
      </Spin>
    );
  }
}

function mapStateToProps(state) {
  return { adminConfig: state.adminConfig };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...travelerItineraryActions,
      ...assistTravelerActions,
      ...travelerLocationActions,
      ...assetTrackingAction
    },
    dispatch
  );
}
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    GoogleApiWrapper({
      apiKey: "AIzaSyAA_ayr9aMRSAPIugacjp_CL6S5ux_N4is"
    })(TravellerItinerayDetails)
  )
);
