import React, { Component } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel
} from "react-accessible-accordion";
export default class TravelSecurity extends Component {
  render() {
    return (
      <tr>
        <td colSpan="3">
          <Accordion>
            {/* <table>
              <tbody>
                <tr>
                  <td colSpan="3" className="title">
                    <b>Travel Security Assistance Services</b>
                  </td>
                </tr>
              </tbody>
            </table> */}

            <table>
              <tbody>
                <tr className="border">
                  <td>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          Assistance to Natural Disasters
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p>
                        Advice and possible deployment of crisis consultants, security personnel, and transportation
assets to assist CAP™ customers directly impacted by a natural disaster up to and including
possible evacuation.
                        </p>
                      </AccordionItemPanel>
                    </AccordionItem>
                  </td>
                </tr>
              </tbody>
            </table>
            <table>
              <tbody>
                <tr className="border">
                  <td>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          Political Threat - Riots, Strikes & Civil Commotion
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p>
                        Advice and possible deployment of crisis consultants, and security personnel to assist with
sheltering in place or evacuation from the impacted area.
                        </p>
                      </AccordionItemPanel>
                    </AccordionItem>
                  </td>
                </tr>
              </tbody>
            </table>
            <table>
              <tbody>
                <tr className="border">
                  <td>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          Assistance from Terrorism
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p>
                        Advice and possible deployment of crisis consultants to assist with seeking safety, emergency
message relay, required medical attention, and the deployment of security personnel to ensure
adequate security of CAP™ customers directly impacted by a terrorist incident up to and
including possible evacuation.
                        </p>
                      </AccordionItemPanel>
                    </AccordionItem>
                  </td>
                </tr>
              </tbody>
            </table>
            <table>
              <tbody>
                <tr className="border">
                  <td>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          Assistance from Violent Crime
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p>
                          Advice and possible deployment of crisis consultants
                          offering support with seeking safety, required medical
                          attention, emergency message relay, and coordination
                          with law enforcement to investigate a crime committed
                          against a CAP™ customer.
                        </p>
                      </AccordionItemPanel>
                    </AccordionItem>
                  </td>
                </tr>
              </tbody>
            </table>
            <table>
              <tbody>
                <tr className="border">
                  <td>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          Assistance from Disappearance of Persons
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p>
                          Advice and possible deployment of crisis consultants
                          to investigate the disappearance of CAP™ customers to
                          locate and return them to safety.
                        </p>
                      </AccordionItemPanel>
                    </AccordionItem>
                  </td>
                </tr>
              </tbody>
            </table>
            <table>
              <tbody>
                <tr className="border">
                  <td>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          Assistance from Wrongful Detention
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p>
                        Advice and possible deployment of crisis consultants to work towards the release of a CAP™
customer being wrongfully detained. An interpreter, along with emergency message relay, legal
referrals, and limited legal expenses, are provided if required.
                        </p>
                      </AccordionItemPanel>
                    </AccordionItem>
                  </td>
                </tr>
              </tbody>
            </table>
            <table>
              <tbody>
                <tr className="border">
                  <td>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          Assistance from Kidnap for Ransom
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p>
                        Advice and possible deployment of crisis consultants to assist families and employers in the
resolution of the incident and safe return of the CAP™ customer.
                        </p>
                      </AccordionItemPanel>
                    </AccordionItem>
                  </td>
                </tr>
              </tbody>
            </table>
            <table>
              <tbody>
                <tr className="border">
                  <td>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          Assistance from Hi-Jacking
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p>
                        Advice and possible deployment of crisis consultants to coordinate with local law enforcement
and government officials to aid with the safe release of a CAP™ customer and bring resolution
to the hi-jacking.
                        </p>
                      </AccordionItemPanel>
                    </AccordionItem>
                  </td>
                </tr>
              </tbody>
            </table>
            <table>
              <tbody>
                <tr className="border">
                  <td>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          Assistance from Extortion
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p>
                        Advice and possible deployment of crisis consultants to investigate and bring resolution to the
blackmail or extortion threat directly impacting a CAP™ customer.
                        </p>
                      </AccordionItemPanel>
                    </AccordionItem>
                  </td>
                </tr>
              </tbody>
            </table>
            <table>
              <tbody>
                <tr className="border">
                  <td>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                        Assistance from Pandemic Outbreaks
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p>
                        Advice and possible deployment of crisis or medical consultants, security personnel, and
transportation assets to assist a CAP™ customer directly impacted by a pandemic up to and
including possible evacuation from the impacted area.
                        </p>
                      </AccordionItemPanel>
                    </AccordionItem>
                  </td>
                </tr>
              </tbody>
            </table>
          </Accordion>
        </td>
      </tr>
    );
  }
}
