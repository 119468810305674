import { ThemeTypes } from "../constants/theme-types";
import OrgAPI from "../api/organization-api";
import config, { getCurrentTheme } from "../../themeSwitcher/config";

export function changeTheme(theme) {
  return async function(dispatch, _getState) {
    try {
      await OrgAPI.getActiveThemes().then(themes => {
        if(themes.data) {
          let user = JSON.parse(localStorage.getItem('user'));
          user.themeId = theme;
          localStorage.setItem("user", JSON.stringify(user));
          let selecetedTheme = themes.data.find(x=>x.themeId === theme);
          dispatch({ type: ThemeTypes.CHANGE_THEME, payload: selecetedTheme });
        }
      });
    } catch (e) {
      
    }

    /* try {
      var them = getCurrentTheme(theme);
      let user = JSON.parse(localStorage.getItem('user'));
      user.themeId = theme;
      localStorage.setItem("user", JSON.stringify(user));
      dispatch({ type: ThemeTypes.CHANGE_THEME, payload: them });
    } catch (e) {
    } */

  };
}

export function setActiveThemes() {
  return async function(dispatch, _getState) {
    try {
      let themes = await OrgAPI.getActiveThemes();
      if(themes.data) {
        dispatch({ type: ThemeTypes.SET_ACTIVE_THEMES, payload: themes.data });
      }
    } catch (e) {
      // TODO: handle error
    }
  };
}

export function setActiveTheme(theme) {
  return async function(dispatch, _getState) {
    try {
      await OrgAPI.getActiveThemes().then(themes => {
        if(themes.data) {
          let user = JSON.parse(localStorage.getItem('user'));
          user.themeId = theme;
          localStorage.setItem("user", JSON.stringify(user));
          let selecetedTheme = themes.data.find(x=>x.themeId === theme);
          dispatch({ type: ThemeTypes.SET_ACTIVE_THEME, payload: selecetedTheme });
        }
      });
    } catch (e) {
      
    }
  };
}

