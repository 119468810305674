import React, { Component} from "react";
import { Chart, Geom, Tooltip } from "bizcharts";
import { Icon, Card } from 'antd';
import { TotalSalesSvg } from "../../shared/svg/svg/home-icon";
import IntlMessages from "../../../services/intlMesseges";
const TotalSalesIcon = props => < Icon component={
  TotalSalesSvg
} {
  ...props
  }
/>;
export default class TotalSales extends Component {
  render() {
    let salesChart;
    const salesScale = {
      date: {
        tickCount: 10,
        type: "time",
        mask: "YYYY"
      }
    };
    return (
        <Card title={<IntlMessages id="admin.dashboard.totalsales"></IntlMessages>} extra={<TotalSalesIcon />}>
          <div style={{fontSize:"22px", color: "#3b3b3b", fontWeight: "600"}} className="totalValue">{this.props.salesData.total}</div>
          <Chart
          className="graphchart"
            height={50}
            data={this.props.salesData.data}
            padding={[5, 0, 0, 0]}
            scale={salesScale}
            onGetG2Instance={g2Chart => {
              salesChart = g2Chart;
            }}
            forceFit
            >
              {/* <Axis name="price" visible={false} /> */}
              <Tooltip
                crosshairs={{
                  type: "line"
                }}
              />
              <Geom
                type="area"
                position="Date*Total Sale"
                color="#6cb8ff"
                shape="smooth"
                acitve={false}
                opacity={0.85}              
              />
            </Chart>
          </Card>
    );
  }
}
