import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as riskLineActions from "../../../redux/actions/risk-line-actions";
import IntlMessages from "../../../services/intlMesseges";
import config from "../../../../../config";
import { Row, Col, Tabs, Typography, Spin } from "antd";
let riskLevel = {
  1: "Low",
  2: "Moderate",
  3: "Medium",
  4: "High",
  5: "Extreme",
};

const { TabPane } = Tabs;

class Covid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      covidRegionData: undefined,
      covidCitiesData: undefined,
    };
  }

  tabClicked = (key, event) => {
    this.setState({ childLoading: true });

    if (key === "regions") {
      this.props
        .getCovidCountryRegion({
          countryCode: this.props.countryCode,
        })
        .then((resp) => {
          console.log(resp);
          this.setState({
            covidRegionData: resp,
            loading: false,
          });
        })
        .catch((ex) => {
          this.setState({ childLoading: false });
        });
    }
    if (key === "covidCities") {
      this.props
        .getCovidCountryCities({
          countryCode: this.props.countryCode,
        })
        .then((resp) => {
          console.log(resp);
          this.setState({
            covidCitiesData: resp,
            loading: false,
          });
        })
        .catch((ex) => {
          this.setState({ childLoading: false });
        });
    }
  };

  bindRegionDetails(cityDetails) {
    let cityReport = "";
    cityReport +=
      cityDetails.summary && "<h3>Summary</h3>" + cityDetails.summary;
    cityReport +=
      cityDetails.policy_status &&
      "<p><strong>What is the current policy?:</strong> " +
        cityDetails.policy_status +
        "</p>";
    cityReport +=
      cityDetails.policy_end &&
      "<p><strong>Policy ends:</strong> " + cityDetails.policy_end + "</p>";
    cityReport +=
      cityDetails.policy_text &&
      "<p><strong>Policy explanation:</strong> " +
        cityDetails.policy_text +
        "</p>";
    cityReport +=
      cityDetails.policy_update &&
      "<p><strong>Last update:</strong> " + cityDetails.policy_update + "</p>";
    cityReport += "<hr><h3>Infection</h3>";
    cityReport +=
      cityDetails.infection_level &&
      "<p><strong>Infection Level:</strong> " +
        cityDetails.infection_level +
        "</p>";
    cityReport +=
      cityDetails.infection_rate &&
      "<p><strong>Infection rate:</strong> " +
        cityDetails.infection_rate +
        " cases per 100k in the last 7 days</p>";
    cityReport +=
      cityDetails.infection_as_of_day &&
      "<p><strong>As of date:</strong> " +
        cityDetails.infection_as_of_day +
        "</p>";
    cityReport += "<hr><h3>Travel</h3>";
    cityReport += "<h4>Entry and borders</h4>";
    cityReport +=
      cityDetails.entry_ban &&
      "<p><strong>Is there a ban on entry for any travellers?</strong> " +
        cityDetails.entry_ban +
        "</p>";
    cityReport +=
      cityDetails.entry_date &&
      "<p><strong>Through what date?</strong> " +
        cityDetails.entry_date +
        "</p>";
    cityReport +=
      cityDetails.entry_text &&
      "<p><strong>Entry Rules Explanation:</strong> " +
        cityDetails.entry_text +
        "</p>";
    cityReport +=
      cityDetails.entry_rules &&
      "<p><strong>Entry Rules:</strong> " + cityDetails.entry_rules + "</p>";
    cityReport +=
      cityDetails.entry_exemptions &&
      "<p><strong>Exemptions:</strong> " +
        cityDetails.entry_exemptions +
        "</p>";
    cityReport +=
      cityDetails.entry_update &&
      "<p><strong>Last Update:</strong> " + cityDetails.entry_update + "</p>";
    cityReport += "<hr><h4>Testing</h4>";
    cityReport +=
      cityDetails.testing_test &&
      "<p><strong>Do travellers need to take a COVID-19 test?</strong> " +
        cityDetails.testing_test +
        "</p>";
    cityReport +=
      cityDetails.testing_when &&
      "<p><strong>When?</strong> " + cityDetails.testing_when + "</p>";
    cityReport +=
      cityDetails.entry_update &&
      "<p><strong>Is it mandatory or optional?</strong> " +
        cityDetails.entry_update +
        "</p>";
    cityReport +=
      cityDetails.testing_text &&
      "<p><strong>Testing Explanation:</strong> " +
        cityDetails.testing_text +
        "</p>";
    cityReport +=
      cityDetails.testing_rules &&
      "<p><strong>Testing Rules:</strong> " +
        cityDetails.testing_rules +
        "</p>";
    cityReport +=
      cityDetails.testing_update &&
      "<p><strong>Last Update:</strong> " + cityDetails.testing_update + "</p>";
    cityReport += "<hr><h4>Health and travel documentations</h4>";
    cityReport +=
      cityDetails.health_need_doc &&
      "<p><strong>Do arriving travellers need any health and/or travel documentation?</strong> " +
        cityDetails.health_need_doc +
        "</p>";
    cityReport +=
      cityDetails.health_text &&
      "<p><strong>Documentation Explanation:</strong> " +
        cityDetails.health_text +
        "</p>";
    cityReport +=
      cityDetails.health_doc &&
      "<p><strong>Health Document:</strong> " + cityDetails.health_doc + "</p>";
    cityReport +=
      cityDetails.health_travel_doc &&
      "<p><strong>Travel Document:</strong> " +
        cityDetails.health_travel_doc +
        "</p>";
    cityReport +=
      cityDetails.health_update &&
      "<p><strong>Last Update:</strong> " + cityDetails.health_update + "</p>";
    cityReport += "<hr><h4>Tracing app</h4>";
    cityReport +=
      cityDetails.tracing_need &&
      "<p><strong>Do travellers need to use a contact tracing app after arriving?</strong> " +
        cityDetails.tracing_need +
        "</p>";
    cityReport +=
      cityDetails.tracing_text &&
      "<p><strong>Tracing App Explanation:</strong> " +
        cityDetails.tracing_text +
        "</p>";
    cityReport +=
      cityDetails.tracing_update &&
      "<p><strong>Last Update:</strong> " + cityDetails.tracing_update + "</p>";
    cityReport += "<hr><h4>Quarantine on arrival</h4>";
    cityReport +=
      cityDetails.quarantine_who &&
      "<p><strong>Who needs to quarantine on arrival?</strong> " +
        cityDetails.quarantine_who +
        "</p>";
    cityReport +=
      cityDetails.quarantine_days &&
      "<p><strong>How many days is quarantine?</strong> " +
        cityDetails.quarantine_days +
        "</p>";
    cityReport +=
      cityDetails.quarantine_type &&
      "<p><strong>Is it self-quarantine or operated by the government?</strong> " +
        cityDetails.quarantine_type +
        "</p>";
    cityReport +=
      cityDetails.quarantine_text &&
      "<p><strong>Quarantine Explanation:</strong> " +
        cityDetails.quarantine_text +
        "</p>";
    cityReport +=
      cityDetails.quarantine_rules &&
      "<p><strong>Quarantine Rules:</strong> " +
        cityDetails.quarantine_rules +
        "</p>";
    cityReport +=
      cityDetails.quarantine_mandate &&
      "<p><strong>Quarantine Mandate List:</strong> " +
        cityDetails.quarantine_mandate +
        "</p>";
    cityReport +=
      cityDetails.quarantine_update &&
      "<p><strong>Last Update:</strong> " +
        cityDetails.quarantine_update +
        "</p>";
    cityReport += "<hr><h4>Masks</h4>";
    cityReport +=
      cityDetails.masks_req &&
      "<p><strong>Are face masks mandatory?</strong> " +
        cityDetails.masks_req +
        "</p>";
    cityReport +=
      cityDetails.masks_text &&
      "<p><strong>Mask Policy Explanation:</strong> " +
        cityDetails.masks_text +
        "</p>";
    cityReport +=
      cityDetails.masks_update &&
      "<p><strong>Last Update:</strong> " + cityDetails.masks_update + "</p>";
    cityReport += "<hr><h3>Internal situation</h3>";
    cityReport += "<h4>Internal travel</h4>";
    cityReport +=
      cityDetails.internal_travel_allowed &&
      "<p><strong>Is allowed:</strong> " +
        cityDetails.internal_travel_allowed +
        "</p>";
    cityReport +=
      cityDetails.internal_travel_text &&
      "<p><strong>Internal Travel Explanation:</strong> " +
        cityDetails.internal_travel_text +
        "</p>";
    cityReport +=
      cityDetails.internal_travel_update &&
      "<p><strong>Last Update:</strong> " +
        cityDetails.internal_travel_update +
        "</p>";
    cityReport += "<hr><h3>Resources</h3>";
    cityReport +=
      cityDetails.resources_gov &&
      "<p><strong>Government COVID Site:</strong> " +
        cityDetails.resources_gov +
        "</p>";
    cityReport +=
      cityDetails.resources_health &&
      "<p><strong>Health Dept COVID Site:</strong> " +
        cityDetails.resources_health +
        "</p>";
    return cityReport;
  }

  bindcovidCitiesDetails(cityDetails) {
    let cityReport = "";
    cityReport += cityDetails.summary && "<p>" + cityDetails.summary + "</p>";
    cityReport += "<p><br><br></p>";
    return cityReport;
  }

  _bindRegionList() {
    return this.state.covidRegionData.map((region, index) => (
      <TabPane
        tab={
          <span>
            <i
              style={{}}
              className={
                "risklevel risk_level_" + region.covid_risk_level.toLowerCase()
              }
            >
              {/*region.covid_risk_level*/}
            </i>
            {region.name}
          </span>
        }
        key={region.code}
      >
        <div>
          <Spin spinning={this.state.loading}>
            <h5>{region.name}</h5>
            <span
              style={{
                borderBottom: "1px solid #d9d9d9",
                padding: "10px 0",
                marginBottom: "15px",
                marginTop: "-12px",
                display: "block",
              }}
            >
              <i
                style={{}}
                className={
                  "risklevel risk_level_" +
                  region.covid_risk_level.toLowerCase()
                }
              >
                {/*region.covid_risk_levell*/}
              </i>
              Risk Level {region.covid_risk_level}
            </span>
            {
              <div
                dangerouslySetInnerHTML={{
                  __html: this.bindRegionDetails(region),
                }}
              ></div>
            }
          </Spin>
        </div>
      </TabPane>
    ));
  }

  _bindcovidCitiesList() {
    return this.state.covidCitiesData.map((covidCities, index) => (
      <TabPane
        tab={
          <span>
            <i
              style={{}}
              className={
                "risklevel risk_level_" +
                covidCities.covid_risk_level.toLowerCase()
              }
            >
              {/*region.covid_risk_level*/}
            </i>
            {covidCities.Cityname}
          </span>
        }
        key={covidCities.code}
      >
        <div>
          <Spin spinning={this.state.loading}>
            <h5>{covidCities.Cityname}</h5>
            <span
              style={{
                //borderBottom: "1px solid #d9d9d9",
                padding: "10px 0",
                marginBottom: "15px",
                marginTop: "-12px",
                display: "block",
              }}
            >
              <b>
                <IntlMessages id="admin.countries.covidCityName" />
              </b>
              <i
                style={{}}
                className={
                  "risklevel risk_level_" +
                  covidCities.covid_risk_level.toLowerCase()
                }
              >
                {/*region.covid_risk_levell*/}
              </i>
              {covidCities.covid_risk_level}
            </span>
            {
              <div
                dangerouslySetInnerHTML={{
                  __html: this.bindcovidCitiesDetails(covidCities),
                }}
              ></div>
            }
          </Spin>
        </div>
      </TabPane>
    ));
  }

  render() {
    return (
      <Spin spinning={this.state.loading}>
        <div>
          <div
            class="covidTab"
            style={{
              textAlign: "center",
            }}
          >
            <Row gutter={24}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Col xs={24} sm={24} md={4} lg={4} xl={4}>
                  <p>
                    <img
                      src={
                        config.API1 + "/travel-icons/icon-covid-risk-level.png"
                      }
                      //src="https://dev.api.captravelassistance.com/travel-icons/icon-covid-risk-level.png"
                    ></img>
                  </p>
                  <label>
                    <b>
                      <IntlMessages id="admin.countries.advisories.covid.CovidRiskLevel" />
                      :
                    </b>
                  </label>
                  <p>{this.props.coviddata.covid_risk_level}</p>
                </Col>
                <Col xs={24} sm={24} md={4} lg={4} xl={4}>
                  <p>
                    <img
                      src={
                        config.API1 +
                        "/travel-icons/icon-infection-risk-level.png"
                      }
                      //src="https://dev.api.captravelassistance.com/travel-icons/icon-infection-risk-level.png"
                    ></img>
                  </p>
                  <label>
                    <b>
                      <IntlMessages id="admin.countries.advisories.covid.InfectionRiskLevel" />
                      :
                    </b>
                  </label>
                  <p>{this.props.coviddata.infection_level}</p>
                </Col>
                <Col xs={24} sm={24} md={4} lg={4} xl={4}>
                  <p>
                    <img
                      src={
                        config.API1 +
                        "/travel-icons/icon-infection-risk-level.png"
                      }
                      //src="https://dev.api.captravelassistance.com/travel-icons/icon-deaths.png"
                    ></img>
                  </p>
                  <label>
                    <b>Deaths:</b>
                  </label>
                  <p>{this.props.coviddata.cases_death}</p>
                </Col>
                <Col xs={24} sm={24} md={4} lg={4} xl={4}>
                  <p>
                    <img
                      src={
                        config.API1 + "/travel-icons/icon-cases-confirmed.png"
                      }
                      //src="https://dev.api.captravelassistance.com/travel-icons/icon-cases-confirmed.png"
                    ></img>
                  </p>
                  <label>
                    <b>Cases Confirmed:</b>
                  </label>
                  <p>{this.props.coviddata.cases_confirmed}</p>
                </Col>
              </Col>
            </Row>
          </div>

          <Row gutter={24} style={{ marginBottom: "20px" }}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <label>
                <b>
                  <IntlMessages id="admin.countries.advisories.covid.Hotspots" />
                  :
                </b>
              </label>
              <div
                dangerouslySetInnerHTML={{
                  __html: this.props.coviddata.hotspots,
                }}
              ></div>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col>
              <Tabs onTabClick={this.tabClicked}>
                <TabPane
                  tab={
                    <span>
                      <i
                        style={{
                          fontSize: "20px",
                          display: "inline-block",
                          marginRight: "10px",
                          verticalAlign: "middle",
                        }}
                        className="fas fa-align-left"
                      ></i>
                      Summary
                    </span>
                  }
                  key="summary"
                >
                  <Row gutter={24} style={{ marginBottom: "20px" }}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <label>
                        <b>
                          <IntlMessages id="admin.countries.advisories.covid.LocalText" />
                          :
                        </b>
                      </label>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: this.props.coviddata.summary,
                        }}
                      ></div>
                    </Col>
                  </Row>
                  <Row gutter={24} style={{ marginBottom: "20px" }}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <label>
                        <b>Policy:</b>
                      </label>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: this.props.coviddata.policy_text,
                        }}
                      ></div>
                    </Col>
                  </Row>
                  <Row gutter={24} style={{ marginBottom: "20px" }}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <label>
                        <b>Policy Start:</b>
                      </label>
                      <p>{this.props.coviddata.policy_start}</p>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <label>
                        <b>Policy End:</b>
                      </label>
                      <p>{this.props.coviddata.policy_end}</p>
                    </Col>
                  </Row>
                  <Row gutter={24} style={{ marginBottom: "20px" }}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <label>
                        <b>Policy Status:</b>
                      </label>
                      <p>{this.props.coviddata.policy_status}</p>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane
                  tab={
                    <span>
                      <i
                        style={{
                          fontSize: "20px",
                          display: "inline-block",
                          marginRight: "10px",
                          verticalAlign: "middle",
                        }}
                        className="fas fa-map"
                      ></i>
                      Infection
                    </span>
                  }
                  key="infection"
                >
                  <Row gutter={24} style={{ marginBottom: "20px" }}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <label>
                        <b>Infection Rate:</b>
                      </label>
                      <p>{this.props.coviddata.infection_rate}</p>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <label>
                        <b>Infection Level:</b>
                      </label>
                      <p>{this.props.coviddata.infection_level}</p>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane
                  tab={
                    <span>
                      <i
                        style={{
                          fontSize: "20px",
                          display: "inline-block",
                          marginRight: "10px",
                          verticalAlign: "middle",
                        }}
                        className="fas fa-bell"
                      ></i>
                      International travel
                    </span>
                  }
                  key="international"
                >
                  <Row gutter={24} style={{ marginBottom: "20px" }}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <label>
                        <b>Flight Information:</b>
                      </label>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: this.props.coviddata.flight_text,
                        }}
                      ></div>
                    </Col>
                  </Row>
                  <Row gutter={24} style={{ marginBottom: "20px" }}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <label>
                        <b>
                          <IntlMessages id="admin.countries.advisories.covid.FlightBan" />
                          :
                        </b>
                      </label>
                      <p>{this.props.coviddata.flight_ban}</p>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <label>
                        <b>
                          <IntlMessages id="admin.countries.advisories.covid.FlightBanEnd" />
                          :
                        </b>
                      </label>
                      <p>{this.props.coviddata.flight_ban_end}</p>
                    </Col>
                  </Row>
                  <Row gutter={24} style={{ marginBottom: "20px" }}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <label>
                        <b>Entry Information:</b>
                      </label>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: this.props.coviddata.entry_text,
                        }}
                      ></div>
                    </Col>
                  </Row>
                  <Row gutter={24} style={{ marginBottom: "20px" }}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <label>
                        <b>
                          <IntlMessages id="admin.countries.advisories.covid.EntryBan" />
                          :
                        </b>
                      </label>
                      <p>{this.props.coviddata.entry_ban}</p>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <label>
                        <b>
                          <IntlMessages id="admin.countries.advisories.covid.EntryBanEnd" />
                          :
                        </b>
                      </label>
                      <p>{this.props.coviddata.entry_ban_end}</p>
                    </Col>
                  </Row>
                  <Row gutter={24} style={{ marginBottom: "20px" }}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <label>
                        <b>Entry Exemptions:</b>
                      </label>
                      <p>{this.props.coviddata.entry_exemptions}</p>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <label>
                        <b>Entry Rules:</b>
                      </label>
                      <p>{this.props.coviddata.entry_rules}</p>
                    </Col>
                  </Row>
                  <Row gutter={24} style={{ marginBottom: "20px" }}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <label>
                        <b>Testing Information:</b>
                      </label>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: this.props.coviddata.testing_text,
                        }}
                      ></div>
                    </Col>
                  </Row>
                  <Row gutter={24} style={{ marginBottom: "20px" }}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <label>
                        <b>When:</b>
                      </label>
                      <p>{this.props.coviddata.testing_when}</p>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <label>
                        <b>Testing Rules:</b>
                      </label>
                      <p>{this.props.coviddata.testing_rules}</p>
                    </Col>
                  </Row>
                  <Row gutter={24} style={{ marginBottom: "20px" }}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <label>
                        <b>Testing Type:</b>
                      </label>
                      <p>{this.props.coviddata.testing_test}</p>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <label>
                        <b>Testing Required:</b>
                      </label>
                      <p>{this.props.coviddata.testing_req}</p>
                    </Col>
                  </Row>
                  <Row gutter={24} style={{ marginBottom: "20px" }}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <label>
                        <b>Health Information:</b>
                      </label>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: this.props.coviddata.health_text,
                        }}
                      ></div>
                    </Col>
                  </Row>
                  <Row gutter={24} style={{ marginBottom: "20px" }}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <label>
                        <b>Health Documents:</b>
                      </label>
                      <p>{this.props.coviddata.health_doc}</p>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <label>
                        <b>Travel Documents:</b>
                      </label>
                      <p>{this.props.coviddata.health_travel_doc}</p>
                    </Col>
                  </Row>
                  <Row gutter={24} style={{ marginBottom: "20px" }}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <label>
                        <b>Required Documents:</b>
                      </label>
                      <p>{this.props.coviddata.health_need_doc}</p>
                    </Col>
                  </Row>
                  <Row gutter={24} style={{ marginBottom: "20px" }}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <label>
                        <b>Tracing Information:</b>
                      </label>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: this.props.coviddata.tracing_text,
                        }}
                      ></div>
                    </Col>
                  </Row>
                  <Row gutter={24} style={{ marginBottom: "20px" }}>
                   { (this.props.coviddata.tracing_android != "System.String[]" && this.props.coviddata.tracing_android != "") ?
                     <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                     <label>
                       <b>IOS:</b>
                     </label>
                     <p>{this.props.coviddata.tracing_ios}</p>
                   </Col>
                     : ""
                    }
                    {(this.props.coviddata.tracing_android != "System.String[]" && this.props.coviddata.tracing_android != "") ?
                     <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                     <label>
                       <b>Android:</b>
                     </label>
                     <p>{this.props.coviddata.tracing_android}</p>
                   </Col>
                     : ""
                    }
                  </Row>
                  <Row gutter={24} style={{ marginBottom: "20px" }}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <label>
                        <b>Tracing Required:</b>
                      </label>
                      <p>{this.props.coviddata.tracing_need}</p>
                    </Col>
                  </Row>
                  <Row gutter={24} style={{ marginBottom: "20px" }}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <label>
                        <b>Quarantine Information:</b>
                      </label>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: this.props.coviddata.tracing_text,
                        }}
                      ></div>
                    </Col>
                  </Row>
                  <Row gutter={24} style={{ marginBottom: "20px" }}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <label>
                        <b>Quarantine Type:</b>
                      </label>
                      <p>{this.props.coviddata.quarantine_type}</p>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <label>
                        <b>
                          <IntlMessages id="admin.countries.advisories.covid.QuarantineDays" />
                          :
                        </b>
                      </label>
                      <p>{this.props.coviddata.quarantine_days}</p>
                    </Col>
                  </Row>
                  <Row gutter={24} style={{ marginBottom: "20px" }}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <label>
                        <b>Quarantine Rules:</b>
                      </label>
                      <p>{this.props.coviddata.quarantine_rules}</p>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <label>
                        <b>Quarantine Mandate:</b>
                      </label>
                      <p>{this.props.coviddata.quarantine_mandate}</p>
                    </Col>
                  </Row>
                  <Row gutter={24} style={{ marginBottom: "20px" }}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <label>
                        <b>Quarantine Who:</b>
                      </label>
                      <p>{this.props.coviddata.quarantine_who}</p>
                    </Col>
                  </Row>
                  <Row gutter={24} style={{ marginBottom: "20px" }}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <label>
                        <b>Masks Information:</b>
                      </label>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: this.props.coviddata.masks_text,
                        }}
                      ></div>
                    </Col>
                  </Row>
                  <Row gutter={24} style={{ marginBottom: "20px" }}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <label>
                        <b>Masks Required:</b>
                      </label>
                      <p>{this.props.coviddata.masks_req}</p>
                    </Col>
                  </Row>
                  <Row gutter={24} style={{ marginBottom: "20px" }}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <label>
                        <b>Departure Information:</b>
                      </label>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: this.props.coviddata.exit_text,
                        }}
                      ></div>
                    </Col>
                  </Row>
                  <Row gutter={24} style={{ marginBottom: "20px" }}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <label>
                        <b>Departure Requirements:</b>
                      </label>
                      <p>{this.props.coviddata.exit_reqs}</p>
                    </Col>
                  </Row>
                  <Row gutter={24} style={{ marginBottom: "20px" }}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <label>
                        <b>Other Restriction Information:</b>
                      </label>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: this.props.coviddata.other_restrictions_text,
                        }}
                      ></div>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane
                  tab={
                    <span>
                      <i
                        style={{
                          fontSize: "20px",
                          display: "inline-block",
                          marginRight: "10px",
                          verticalAlign: "middle",
                        }}
                        className="fas fa-map-signs"
                      ></i>
                      Domestic situation
                    </span>
                  }
                  key="domestic"
                >
                  <Row gutter={24} style={{ marginBottom: "20px" }}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <label>
                        <b>Lockdown Information:</b>
                      </label>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: this.props.coviddata.lockdown_text,
                        }}
                      ></div>
                    </Col>
                  </Row>
                  <Row gutter={24} style={{ marginBottom: "20px" }}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <label>
                        <b>Curfew Information:</b>
                      </label>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: this.props.coviddata.curfew_text,
                        }}
                      ></div>
                    </Col>
                  </Row>
                  <Row gutter={24} style={{ marginBottom: "20px" }}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <label>
                        <b>Domestic Travel Information:</b>
                      </label>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: this.props.coviddata.domestic_travel_text,
                        }}
                      ></div>
                    </Col>
                  </Row>
                  <Row gutter={24} style={{ marginBottom: "20px" }}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <label>
                        <b>Domectic Travel Restrictions:</b>
                      </label>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: this.props.coviddata
                            .domestic_restrictions_text,
                        }}
                      ></div>
                    </Col>
                  </Row>
                </TabPane>

                <TabPane
                  tab={
                    <span>
                      <i
                        style={{
                          fontSize: "20px",
                          display: "inline-block",
                          marginRight: "10px",
                          verticalAlign: "middle",
                        }}
                        className="fas fa-map"
                      ></i>
                      Resources
                    </span>
                  }
                  key="resources"
                >
                  <Row gutter={24} style={{ marginBottom: "20px" }}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <label>
                        <b>
                          <IntlMessages id="Dashboard" />:
                        </b>
                      </label>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: this.props.coviddata.resources_dash,
                        }}
                      ></div>
                    </Col>
                  </Row>
                  <Row gutter={24} style={{ marginBottom: "20px" }}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <label>
                        <b>
                          <IntlMessages id="Government" />:
                        </b>
                      </label>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: this.props.coviddata.resources_gov,
                        }}
                      ></div>
                    </Col>
                  </Row>
                  <Row gutter={24} style={{ marginBottom: "20px" }}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <label>
                        <b>
                          <IntlMessages id="Health" />:
                        </b>
                      </label>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: this.props.coviddata.resources_health,
                        }}
                      ></div>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane
                  tab={
                    <span>
                      <i
                        style={{
                          fontSize: "20px",
                          display: "inline-block",
                          marginRight: "10px",
                          verticalAlign: "middle",
                        }}
                        className="fas fa-map"
                      ></i>
                      Regions
                    </span>
                  }
                  key="regions"
                >
                  {this.state.covidRegionData &&
                  this.state.covidRegionData.length > 0 ? (
                    <Row gutter={24} style={{ marginTop: "50px" }}>
                      <Col
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        xl={24}
                        className="tabs-city"
                      >
                        <Typography.Title level={3}>
                          <IntlMessages id="admin.countries.availablecityreports" />
                        </Typography.Title>
                        <Tabs
                          className="long-tab"
                          tabPosition="left"
                          onTabClick={this.tabClicked}
                        >
                          {this._bindRegionList()}
                        </Tabs>
                      </Col>
                    </Row>
                  ) : (
                    ""
                  )}
                </TabPane>
                <TabPane
                  tab={
                    <span>
                      <i
                        style={{
                          fontSize: "20px",
                          display: "inline-block",
                          marginRight: "10px",
                          verticalAlign: "middle",
                        }}
                        className="fas fa-map"
                      ></i>
                      Cities
                    </span>
                  }
                  key="covidCities"
                >
                  {this.state.covidCitiesData &&
                  this.state.covidCitiesData.length > 0 ? (
                    <Row gutter={24} style={{ marginTop: "50px" }}>
                      <Col
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        xl={24}
                        className="tabs-city"
                      >
                        <Typography.Title level={3}>
                          <IntlMessages id="admin.countries.availablecityreports" />
                        </Typography.Title>
                        <Tabs
                          className="long-tab"
                          tabPosition="left"
                          onTabClick={this.tabClicked}
                        >
                          {this._bindcovidCitiesList()}
                        </Tabs>
                      </Col>
                    </Row>
                  ) : (
                    ""
                  )}
                </TabPane>
              </Tabs>
            </Col>
          </Row>
        </div>
      </Spin>
    );
  }
}

function mapStateToProps(state) {
  return {};
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...riskLineActions }, dispatch);
}
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    Covid
  )
);
