import React, { Component } from "react";
import { Card, Icon, Result, Statistic, Row, Col } from "antd";
import {
  FlightSvg,
  AlertsuserdashSvg,
  HotelsuserdashSvg,
  CarrentalsSvg
} from "../../../shared/svg/svg/home-icon";
const FlightSvgIcon = props => <Icon component={FlightSvg} {...props} />;
const AlertSvgIcon = props => <Icon component={AlertsuserdashSvg} {...props} />;
const HotelSvgIcon = props => <Icon component={HotelsuserdashSvg} {...props} />;
const CarSvgIcon = props => <Icon component={CarrentalsSvg} {...props} />;
export default class TravellerMapDataAnalysis extends Component {
  render() {
    return (
      <div>
        <Card title="Overview">
          <Row gutter={24}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <div className="NotificationsAlertSection">
                <Result icon={<FlightSvgIcon />} title="Flights" />
                <Statistic
                  title="Active Flights"
                  value={this.props.itinerariesOverview.activeFlights}
                />
              </div>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <div className="NotificationsAlertSection">
                <Result icon={<AlertSvgIcon />} title="Travelers" />
                <Statistic
                  title="Active Travelers"
                  value={this.props.itinerariesOverview.activeTravelers}
                />
              </div>
            </Col>
          </Row>
          <Row gutter={24} style={{ marginTop: "20px" }}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <div className="NotificationsAlertSection">
                <Result icon={<HotelSvgIcon />} title="Hotels" />
                <Statistic
                  title="Active Hotels"
                  value={this.props.itinerariesOverview.activeHotels}
                />
              </div>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <div className="NotificationsAlertSection">
                <Result icon={<CarSvgIcon />} title="Vehicles" />
                <Statistic
                  title="Active Vehicles"
                  value={this.props.itinerariesOverview.activeCars}
                />
              </div>
            </Col>
          </Row>
        </Card>
      </div>
    );
  }
}
