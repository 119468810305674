import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {  Button, Spin } from "antd";
import { Map, GoogleApiWrapper, Marker } from "google-maps-react";
import * as UserService from "../../../../services/user-service";
import * as travelerItineraryActions from "../../../../redux/actions/travelers-itinerary-action";
import config from "../../../../../../config";

const mapStyles = {
  width: "100%",
  height: "100%",
  left: "0",
  top: "0",
  position: "relative"
};
let orgId = 0;
class TravellerGoogleMapComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      airportLocation: "",
      loading: false
    };
  }

  fitBounds = ({ google }, map) => {
    if (this.state.airportLocation) {
      const bounds = new google.maps.LatLngBounds();
      Object.entries(this.state.airportLocation).map(([marker, value]) => {
        bounds.extend(new window.google.maps.LatLng(value.lat, value.lng));
      });
      map.fitBounds(bounds);
    }
  };

  async componentDidMount() {
    this.setState({ loading: true });
    let currentUser = UserService.getUser();
    let userRole = currentUser.role;
    if (userRole === "Administrator") {
      orgId = 1;
    } else {
      orgId = currentUser.companyID;
    }
    if (currentUser) {
      await this.props
        .getTravelersItinerariesByOrgId(orgId, userRole, {})
        .then(resp => {
          this.setState({
            airportLocation: resp.airportLocation,
            loading: false
          });
        })
        .catch(ex => {
          this.setState({ loading: false });
        });
    }
  }

  render() {
    return (
      <Spin spinning={this.state.loading}>
        {this.state.airportLocation && this.state.airportLocation !== "" && (
          <div className="map">
            <Map
              google={this.props.google}
              style={mapStyles}
              zoom={8}
              onReady={this.fitBounds}
              visible={true}
            >
              {Object.entries(this.state.airportLocation).map(
                ([marker, value]) => (
                  <Marker
                    icon={{
                      url: config.API1 + "/travel-icons/" + value.icon,
                      scaledSize: new window.google.maps.Size(32, 33)
                    }}
                    position={{
                      lat: value.lat,
                      lng: value.lng
                    }}
                  />
                )
              )}
            </Map>
            <div className="traveller-map-box">
              <Button
                type="primary"
                className="gotomapbtn"
                onClick={() =>
                  this.props.history.push("traveller-itineray-details")
                }
              >
                <i className="fas fa-arrow-circle-right arrow-icon"></i>
                <i className="fas fa-map-signs sign-icon"></i>Go To Map
              </Button>
            </div>
          </div>
        )}
      </Spin>
    );
  }
}

function mapStateToProps(state) {
  return {};
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...travelerItineraryActions }, dispatch);
}
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    GoogleApiWrapper({
      apiKey: "AIzaSyAA_ayr9aMRSAPIugacjp_CL6S5ux_N4is"
    })(TravellerGoogleMapComponent)
  )
);
