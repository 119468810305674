import { ThemeTypes } from "../constants/theme-types";
import config, { getCurrentTheme } from "../../themeSwitcher/config";
const initialState = {
  theme: {},
  activeThemes: []
};
export default function themeReducer(state = initialState, action) {
  switch (action.type) {
    case ThemeTypes.CHANGE_THEME:
      return { ...state, theme: action.payload };
    case ThemeTypes.SET_ACTIVE_THEME:
      return { ...state, theme: action.payload };
    case ThemeTypes.SET_ACTIVE_THEMES:
      return { ...state, activeThemes: action.payload };
    default:
      return state;
  }
}
