import React, { Component } from "react";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import WizardB2csvg from "../../../shared/svg/b2c-svg/wizard-svg.jsx";
import * as b2cActions from "../../../../redux/actions/b2c-actions"
class Wizard extends Component {
  constructor(props){
    super(props);
    this.state ={

    }
  }

  render() {
    return (
      <div className="wizard">
        <div className="wrap900">
          <ul>
            {/* <li className={this.props.wizardActiveClass === "step1" ? "active":""}>
              <span>
                <WizardB2csvg />
              </span>
              <div className="name">Plan Type</div>
            </li>
            <li className={this.props.wizardActiveClass === "step2" ? "active":""}>
              <span>
                <WizardB2csvg />
              </span>
              <div className="name">Plan Term</div>
            </li> */}
            <li className={this.props.wizardActiveClass === "step3" ? "active":""}>
              <span>
                <WizardB2csvg />
              </span>
              <div className="name">
                Personal <br />
                Information
              </div>
            </li>
            <li className={this.props.wizardActiveClass === "step4" ? "active":""}>
              <span>
                <WizardB2csvg />
              </span>
              <div className="name">
                Summary &
                <br />
                Billing
              </div>
            </li>
            <li className={this.props.wizardActiveClass === "step5" ? "active":""}>
              <span>
                <WizardB2csvg />
              </span>
              <div className="name">
                Complete
              </div>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

Wizard.propTypes = {
  step: PropTypes.string,
};
function mapStateToProps(state) {
  return {
      ...state.b2c,
  }
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({...b2cActions}, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(Wizard);
