import React, { Component } from "react";
import { Map, GoogleApiWrapper, Marker } from "google-maps-react";
import InfoWindows from "./info-windows";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  Button,
  Row,
  Col,
  Input,
  Form,
  Tabs,
  Select,
  InputNumber,
  Checkbox,
} from "antd";
import IntlMessages from "../../../services/intlMesseges";
import * as assetTrackingAction from "../../../redux/actions/asset-tracking-action";
import * as userActions from "../../../redux/actions/user-actions";
import * as geoFenceAction from "../../../redux/actions/geofence-action";
import { notifyUser } from "../../../services/notification-service";
import AppLocale from "../../../languageProvider";
import Geocode from "react-geocode";
import AssetTrackngRules from "./asset-tracking-rules";
import OrgTree from "../../shared/components/orgTree";
import * as Permission from "../../../services/permissions";

const { Option } = Select;
let userCountry = "";
let userCity = "";
let userState = "";
var mapStyles = {
  container: {
    position: "relative",
    width: "100%",
    height: "70vh",
  },
  map: {
    position: "relative",
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
  },
};
const mapRefsCustom = {
  marker: undefined,
};
const { TabPane } = Tabs;
var UserId;

Geocode.setApiKey("AIzaSyAA_ayr9aMRSAPIugacjp_CL6S5ux_N4is");
//Geocode.enableDebug();

class AreaInterest extends Component {
  constructor(props) {
    super(props);
    this.textInput = React.createRef();
    this.addressInput = React.createRef();
    this.state = {
      markers: [],
      cloneMarkers: [],
      locations: [],
      showingInfoWindow: true,
      activeMarker: {},
      loading:false,
      selectedPlace: {},
      showingDetails: true,
      assetTrackingId: 0,
      center: {},
      // onMarkerMounted: this.onMarkerMounted,
      address: "",
      latLngFromAddress: {},
      zoom: 3,
      companyId: 0,
      ruleexits: false,
      tabkey: "1",
      travellersData: [],
      selectedOrgId: 0,
      selectedTravelers: [],
      formData: "",
      radiusdata: [],
      city: "",
      state: "",
      country: "",
      isCheckbox: false,
      btnDisable:false
    };

    this.onMarkerDrag = this.onMarkerDrag.bind(this);
    this.getAssetArea = this.getAssetArea.bind(this);
    this._map = null;
  }

  autoCenterMap = ({ google }, map) => {
    this._map = map;
    const bounds = new google.maps.LatLngBounds();
    if (
      typeof this.state.markers !== "undefined" &&
      this.state.markers.length > 0
    ) {
      this.state.markers.forEach((mrk) => {
        const { lat, lng } = mrk;
        map.panTo({ lat, lng });
        bounds.extend(new google.maps.LatLng(lat, lng));
      });
    }
  };

  async componentDidMount() {
    await this.props.getUserData();

    if (
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.id &&
      this.props.match.params.id !== "new"
    ) {
      this.setState({ assetTrackingId: this.props.match.params.id });
      await this.getAssetArea();
      await this.props
        .getAssetTrackingById(this.props.match.params.id)
        .then((resp) => {
          if (resp.data && resp.data.message !== "AssetTracking not found") {
            let assetData = resp.data;
            this.setState({
              formData: assetData,
              selectedOrgId: assetData.orgId,
            });
            UserId = assetData.insertUserId;
            let position = {
              lat: JSON.parse(assetData.points).lat,
              lng: JSON.parse(assetData.points).lng,
              title: assetData.title,
            };
            this.setState(() => ({
              cloneMarkers: [position],
              markers: [position],
              zoom: 13,
            }));

            this.getAddressfromLatLng(position);
            this.getOrgnizationUser(assetData.orgId);

            if (assetData && assetData.alertRecipients.length > 0) {
              var _dataRecep = [];
              for (var i = 0; i < assetData.alertRecipients.length; i++) {
                if (
                  assetData.alertRecipients[i].recipientOrgId !== 0 &&
                  assetData.alertRecipients[i].recipientOrgId !== 0
                ) {
                  _dataRecep.push({
                    label: assetData.alertRecipients[i].employeeName,
                    value: assetData.alertRecipients[i].recipientUserId,
                    key: assetData.alertRecipients[i].recipientUserId,
                    companyId: assetData.alertRecipients[i].recipientOrgId,
                    userId: assetData.alertRecipients[i].recipientUserId,
                  });
                }
              }
              this.setState({
                selectedTravelers: _dataRecep,
                isCheckbox: true,
              });
            }
          }
        });
    } else {
      let position = {
        lat: 43.653225,
        lng: -79.383186,
        title: "",
      };
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (pos) => {
            position.lat = pos.coords.latitude;
            position.lng = pos.coords.longitude;

            this.getAddressfromLatLng(position);
            this.setState({
              markers: [position],
              zoom: 13,
            });
          },
          () => {
            position.lat = 43.653225;
            position.lng = -79.383186;
            position.title = "";
            this.getAddressfromLatLng(position);
            this.setState({
              markers: [position],
              zoom: 13,
            });
          }
        );
      } else {
        position.lat = 43.653225;
        position.lng = -79.383186;
        this.getAddressfromLatLng(position);
        this.setState({
          markers: [position],
          zoom: 13,
        });
      }
      this.getOrgnizationUser(this.props.match.params.orgid);
      this.setState({ selectedOrgId: this.props.match.params.orgid });
    }
    var dat = [];
    for (var i = 2; i <= 60; i++) {
      dat.push(50 * i);
    }
    this.setState({ radiusdata: dat });

    this.setState({
      tabkey: window.location.hash
        ? window.location.hash.replace("#", "").toString()
        : "1",
    });
  }

  getOrgnizationUser(orgId) {
    this.props
      .getUserListing({
        orgId: orgId ? orgId : this.props.match.params.orgid,
        filters: { isActiveMembership: true, isSelfInclude: true },
        pagination: { page: 1, pageSize: 1000 },
      })
      .then((resp) => {
        this.setState({
          travellersData: resp.data,
        });
      });
  }

  fetchTraveler = (value) => {
    this.props
      .getUserListing({
        orgId: this.state.selectedOrgId
          ? this.state.selectedOrgId
          : this.props.match.params.orgid,
        filters: {
          firstName: value,
          isActiveMembership: true,
          isSelfInclude: true,
        },
        pagination: { page: 1, pageSize: 1000 },
        sorter: {},
      })
      .then((data) => {
        this.setState({ travellersData: data.data });
      });
  };

  onTravelersSelect = (_idx, option) => {
    let assignedUsersList = [];
    if (this.state.selectedTravelers) {
      assignedUsersList = this.state.selectedTravelers;
    }

    assignedUsersList.push({
      userId: option.props.value,
      companyId: option.props.companyid,
    });

    this.setState({ selectedTravelers: assignedUsersList });
  };

  onRemoveTravelers = (uid) => {
    let assignedUsersList = this.state.selectedTravelers;
    let removedEmployee = -1;
    removedEmployee = assignedUsersList.findIndex(
      (e) => e.userId == (uid.key === "all" ? parseInt("0") : uid.key)
    );
    if (removedEmployee > -1) {
      assignedUsersList.splice(removedEmployee, 1);
    }

    this.setState({ selectedTravelers: assignedUsersList });
  };

  handleCheckBoxChange = (e) => {
    
    if (!e.target.checked) {
      let tempArray = this.state.formData;
      if (tempArray["monetaryValue"]) {
        tempArray["monetaryValue"] = "";
      }
      if (tempArray["maxRadius"]) {
        tempArray["maxRadius"] = "";
      }
      this.props.form.setFieldsValue({
        travellers: [],
      });
      this.setState({ selectedTravelers: [], formData: tempArray });
    }
    this.setState({
      isCheckbox: e.target.checked,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
            this.setState({ loading: true });
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { language } = this.props;
        const currentAppLocale = AppLocale[language.locale];

        let data = {};

        var alertRecipientsArr = [];
        this.state.selectedTravelers.map((row) =>
          alertRecipientsArr.push({
            recipientOrgId: row.companyId,
            recipientUserId: row.userId,
          })
        );

        let latlng = {
          lat: this.state.markers[0].lat,
          lng: this.state.markers[0].lng,
        };
        data["orgId"] = this.state.selectedOrgId
          ? this.state.selectedOrgId
          : this.props.match.params.orgid;
        data["monetaryValue"] = values["monetaryValue"];
        data["title"] = values["title"];
        data["maxRadius"] = values["maxRadius"];
        data["alertRecipients"] = alertRecipientsArr;
        data["latitude"] = latlng.lat.toString();
        data["longitude"] = latlng.lng.toString();
        data["city"] = this.state.city;
        data["state"] = this.state.state;
        data["country"] = this.state.country;
        data["points"] = JSON.stringify(latlng);
        data["type"] = "assettracking";

        this.props
          .updateAssetTracking(data, parseInt(this.state.assetTrackingId))
          .then((response) => {
            if (response.error) {
            } else {
              if (parseInt(this.state.assetTrackingId) === 0) {
                this.props.history.push(
                  "../" + response.data.assettrackingId + "/#2"
                );
                notifyUser(
                  currentAppLocale.messages["admin.assettracking.add"],
                  "success"
                );
              } else {
                
                this.props.history.push("../");
                notifyUser(
                  currentAppLocale.messages["admin.assettracking.update"],
                  "success"
                );
              }
            }
          })
          .catch((err) => {
            this.setState({ loading: false });
          });
      }
    });
  };

  getAssetArea(e) {
    this.props
      .getGeoFenceRulesByGeoFenceId(this.props.match.params.id, {})
      .then((resp) => {
        if (
          (resp.data && resp.data.length > 0) ||
          UserId !== this.props.userData.userID
        ) {
          this.setState({ ruleexits: true });
        } else {
          this.setState({ ruleexits: false });
        }
      });
    if (e) {
      this.setState({ tabkey: e });
    }
  }

  getLatLngFromAddress = () => {
    let address = this.addressInput.current.state.value;
    Geocode.fromAddress(address).then(
      (response) => {
        this.setState({
          latLngFromAddress: response.results[0].geometry.location,
        });
        this.setState({
          markers: [
            {
              lat: response.results[0].geometry.location.lat,
              lng: response.results[0].geometry.location.lng,
              title: this.state.markers[0].title,
            },
          ],
          showingInfoWindow: true,
          showingDetails: true,
          zoom: 13,
        });
        this.getAddressfromLatLng(response.results[0].geometry.location);
        this._map.panTo(response.results[0].geometry.location);
      },
      (error) => {}
    );
  };

  getAddressfromLatLng = (position) => {
    Geocode.fromLatLng(position.lat, position.lng).then(
      (response) => {
        if (response.status == "OK") {
          response.results.map((element) => {
            element.address_components.map((element2) => {
              element2.types.map((element3) => {
                if (element3 === "country") {
                  userCountry = element2.long_name;
                }
                if (element3 === "locality") {
                  userCity = element2.long_name;
                }
                if (element3 === "administrative_area_level_1") {
                  userState = element2.long_name;
                }
              });
            });
          });
          let address = response.results[0].formatted_address;

          this.setState({
            city: userCity ? userCity : "",
            state: userState ? userState : "",
            country: userCountry ? userCountry : "",
            address: address ? address : "",
          });
        }
      },
      (error) => {}
    );
  };

  onMarkerDrag = (props, marker, e) => {
    if (!this.state.ruleexits) {
      let tempMarker = this.state.markers[0];
      let position = {
        lat: marker.position.lat(),
        lng: marker.position.lng(),
        title: marker.place_.title,
      };
      tempMarker.lat = position.lat;
      tempMarker.lng = position.lng;
      tempMarker.title = position.title;
      this.getAddressfromLatLng(tempMarker);
      this.setState({ markers: [tempMarker] });
    }
  };

  onChangeOrganizationTree = (orgId) => {
    this.props.form.setFieldsValue({
      travellers: [],
    });
    this.props
      .getUserListing({
        orgId: orgId ? orgId : this.props.match.params.orgid,
        pagination: { page: 1, pageSize: 1000 },
        filters: { isActiveMembership: true },
      })
      .then((resp) => {
        this.setState({
          travellersData: resp.data,
          selectedOrgId: orgId,
          selectedTravelers: [],
        });
      });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 14 },
    };

    return (
      <div className="UserMap">
        <Tabs activeKey={this.state.tabkey} onChange={this.getAssetArea}>
          <TabPane
            tab={<IntlMessages id="admin.assettracking.tab1"></IntlMessages>}
            key={1}
          >
            <Row gutter="24">
              <Col xs={24} sm={24} md={18} lg={18} xl={18}>
                <Row gutter={24}>
                  <Col xs={24}>
                    <label>
                      <IntlMessages id="admin.assettracking.address" />
                    </label>
                  </Col>
                  <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                    <span className="ant-input-group-wrapper ant-input-search-enter-button">
                      <span className="ant-input-wrapper ant-input-group">
                        <Input
                          className="ant-input ant-input-lg"
                          type="text"
                          ref={this.addressInput}
                          defaultValue={
                            this.state.address ? this.state.address : ""
                          }
                        />
                        <span class="ant-input-group-addon">
                          <Button
                            type="primary"
                            className="ant-btn ant-input-search-button ant-btn-primary ant-btn-lg"
                            htmlType="button"
                            onClick={this.getLatLngFromAddress}
                            disabled={this.state.ruleexits}
                          >
                            {" "}
                            <IntlMessages id="admin.assettracking.locate" />
                          </Button>
                        </span>
                      </span>
                    </span>
                  </Col>
                </Row>
              </Col>
              <Col
                xs={24}
                sm={24}
                md={6}
                lg={6}
                xl={6}
                style={{ textAlign: "right" }}
              >
                <Button
                  type="primary"
                  className=""
                  htmlType="button"
                  onClick={() => this.props.history.push("../")}
                >
                  <IntlMessages id="admin.userlisting.back" />
                </Button>
              </Col>
            </Row>
            <hr />
            <Row gutter={24}>
              <Col xs={24} sm={24} md={18} lg={18} xl={18}>
                <div style={mapStyles.container}>
                  {this.state.markers.length > 0 && (
                    <Map
                      google={this.props.google}
                      zoom={this.state.zoom || 3}
                      style={mapStyles}
                      onReady={this.autoCenterMap}
                    >
                      {this.state.markers.map((place, i) => {
                        return (
                          <Marker
                            key={i}
                            place_={place}
                            position={{ lat: place.lat, lng: place.lng }}
                            draggable={true}
                            onDragend={this.onMarkerDrag}
                          ></Marker>
                        );
                      })}
                    </Map>
                  )}
                </div>
              </Col>

              <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                <div>
                  {this.state.selectedOrgId > 0 && (
                    <Form onSubmit={this.handleSubmit}>
                      <Row gutter={24}>
                        {this.props.userData.role !== "Administrator" &&
                        this.props.userData.role !== "CSR" ? (
                          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <label>
                              <IntlMessages id="admin.organization.company" />
                              :&nbsp;
                            </label>

                            {this.props.match.params.id !== "new"
                              ? getFieldDecorator("organizationId")(
                                  <span>
                                    <OrgTree
                                      parent={this.state.selectedOrgId}
                                      organizationId={
                                        this.props.match.params.orgid
                                          ? this.props.match.params.orgid
                                          : this.props.match.params.divid
                                      }
                                      onChange={this.onChangeOrganizationTree}
                                    />
                                  </span>
                                )
                              : getFieldDecorator("organizationId")(
                                  <OrgTree
                                    parent={this.props.match.params.orgid}
                                    organizationId={
                                      this.props.match.params.orgid
                                        ? this.props.match.params.orgid
                                        : this.props.match.params.divid
                                    }
                                    onChange={this.onChangeOrganizationTree}
                                  />
                                )}
                          </Col>
                        ) : (
                          ""
                        )}
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                          <Form.Item label="Asset Title">
                            {getFieldDecorator("title", {
                              rules: [
                                {
                                  required: true,
                                  message: "Please enter asset title!",
                                },
                              ],
                              initialValue: this.state.formData.title
                                ? this.state.formData.title
                                : "",
                            })(
                              !this.state.ruleexits ? (
                                <Input />
                              ) : (
                                <Input disabled />
                              )
                            )}
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                          <span>{this.state.address}</span>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                          <Checkbox
                            checked={this.state.isCheckbox}
                            onChange={this.handleCheckBoxChange}
                          >
                            Do you want to enable Asset Tracking
                          </Checkbox>
                        </Col>
                        {this.state.isCheckbox && (
                          <div>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                              <Form.Item label="Send Risk Alerts to:">
                                {getFieldDecorator("travellers", {
                                  initialValue: this.state.selectedTravelers,
                                })(
                                  <Select
                                    mode="multiple"
                                    allowClear
                                    optionFilterProp="children"
                                    filterOption={false}
                                    labelInValue={true}
                                    onSelect={this.onTravelersSelect}
                                    onDeselect={this.onRemoveTravelers}
                                    onSearch={this.fetchTraveler}
                                  >
                                    {this.state.travellersData
                                      ? this.state.travellersData.map((t) => {
                                          return (
                                            <Option
                                              key={t.userId}
                                              value={t.userId}
                                              companyid={t.companyId}
                                            >
                                              {t.firstName} {t.lastName}{" "}
                                              {"<" + t.userName + ">"}
                                            </Option>
                                          );
                                        })
                                      : ""}
                                  </Select>
                                )}
                              </Form.Item>
                            </Col>

                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                              <Form.Item label="Radius (meters)">
                                {getFieldDecorator("maxRadius", {
                                  initialValue:
                                    this.state.formData &&
                                    this.state.formData.maxRadius
                                      ? this.state.formData.maxRadius
                                      : "",
                                })(
                                  <Select disabled={this.state.ruleexits}>
                                    {this.state.radiusdata.map(function(item) {
                                      return (
                                        <Option key={item} value={item}>
                                          {item}
                                        </Option>
                                      );
                                    })}
                                  </Select>
                                )}
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                              <Form.Item label="Monetary Value">
                                {getFieldDecorator("monetaryValue", {
                                  initialValue: this.state.formData
                                    .monetaryValue
                                    ? this.state.formData.monetaryValue
                                    : null,
                                })(
                                  <InputNumber
                                    min={1}
                                    formatter={(value) =>
                                      `$ ${value}`.replace(
                                        /\B(?=(\d{3})+(?!\d))/g,
                                        ","
                                      )
                                    }
                                    parser={(value) =>
                                      value.replace(/\$\s?|(,*)/g, "")
                                    }
                                  />
                                )}
                              </Form.Item>
                            </Col>
                          </div>
                        )}
                      </Row>
                      <Button
                        type="primary"
                        htmlType="submit"
                        loading={this.state.loading}
                        className="asset-form-button"
                        style={{ marginTop: "25px" }}
                      >
                        Save
                      </Button>
                    </Form>
                  )}
                </div>
              </Col>
            </Row>
          </TabPane>

          {this.props.match.params.id !== "new" ? (
            <TabPane
              tab={<IntlMessages id="admin.assettracking.tab2"></IntlMessages>}
              key={2}
            >
              <AssetTrackngRules {...this.state} />
            </TabPane>
          ) : (
            ""
          )}
        </Tabs>
      </div>
    );
  }
}

AreaInterest.propTypes = {};

function mapStateToProps(state) {
  return { ...state.user, ...state.language };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { ...assetTrackingAction, ...userActions, ...geoFenceAction },
    dispatch
  );
}
const WrappedForm = Form.create({ name: "edit-user" })(AreaInterest);
export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(
  GoogleApiWrapper({
    apiKey: "AIzaSyAA_ayr9aMRSAPIugacjp_CL6S5ux_N4is",
  })(WrappedForm)
);
