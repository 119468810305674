import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Col, Row, Typography, Button, Icon, Table,  Menu,Dropdown } from "antd";
import { connect } from "react-redux";
import IntlMessages from "../../services/intlMesseges";
import * as NotificationActions from "../../redux/actions/notifications-actions"
import * as paginationActions from "../../redux/actions/pagination-actions"
import * as notificationActions from "../../redux/actions/notifications-actions";
import { bindActionCreators } from "redux";
import { notifyUser } from "../../services/notification-service";
import AppLocale from "../../../app/languageProvider";
import * as UserService from "../../services/user-service";
import moment from 'moment';
const dateFormathh = "MM/DD/YYYY HH:mm:ss";



class Notifications extends Component {
  constructor(props) {
    super(props);
    this.module = 'notifications';
    this.state = {
      isLoading: false,
      data: [],
      pagination: {
        hideOnSinglePage: true,
        showTotal: (total, range) => {
          return (
            <span>
              <IntlMessages id="admin.text.showing" /> {range[0]}-{range[1]}{" "}
              <IntlMessages id="admin.text.of" /> {total}{" "}
              <IntlMessages id="admin.text.results" />
            </span>
          );
        },
        pageSize: 10,
        current: 1
      },
      filters: {}
    };
    
    this.updatenotificationstatus = this.updatenotificationstatus.bind(this);
  }
  updatenotificationstatus(id,idx) {
    let data = this.state.data;
   
    this.props.updateNotificationStatus({ notificationMessagesId: id, isRead: (id<=0 ? 1 : !data[idx].isRead) }).then((response)=>{
      if(!response.error){

        
        this.props.updateNotifcationCount(data[idx].isRead?1:-1);
        if(id <=0){
          this.handleTableChange({ current: 1, pageSize: 10 }, {}, {}, true);
          let currentUser = UserService.getUser();
          this.props.getNotificationsCount(currentUser.userID);
        }
        else{
        data[idx].isRead = !data[idx].isRead;
        this.setState({data : data});
        }
        
      }
      else{
        notifyUser("Unknown error. Please try again!", "error");
      }
    });
    
  }

  getSelectedFilterValue = (index) => {
    return this.props.paginginfo[this.module]&& this.props.paginginfo[this.module].filter && this.props.paginginfo[this.module].filter[index] || null;
  } 
  getHeaderKeys = () => {

    const { language } = this.props;
    const currentAppLocale = AppLocale[language.locale];

    return [
      {
        title: <IntlMessages id="admin.notification.title" />,
        dataIndex: "title",
        // ...this.getColumnSearchProps("name")
        // width: "25%",
        render:(_data,record,idx)=>(
          <div>
          {record.isRead === false ?
            <strong onClick={()=>{this.updatenotificationstatus(record.notificationMessagesId,idx)}}>
              {record.title}
            </strong>
          :
            <span onClick={()=>{this.updatenotificationstatus(record.notificationMessagesId,idx)}}>{record.title}</span>
          }
          </div>
        )
      },
      {
        title: <IntlMessages id="admin.notification.body" />,
        dataIndex: "body",
        render:(_data,record)=>(
          <div>
                <div dangerouslySetInnerHTML={{__html: record.body.replace(/(<? *script)/gi, 'illegalscript')}} >
                </div>
          </div>
        )
      },
      {
        title: <IntlMessages id="admin.notification.created" />,
        dataIndex: "insertDate",
        render: (text, record) => (
          <span>{moment.utc(record.insertDate).local().format(dateFormathh)}</span>
        )
      },
      {
        title: <IntlMessages id="admin.tablelist.actions" />,
        render:(_data, record,idx)=>(
          <div>
            {record.isRead === false ?
              <button className="icon" title={currentAppLocale.messages["admin.notification.markasread"]}  onClick={()=>{this.updatenotificationstatus(record.notificationMessagesId,idx)}}>
                  <i className="fas fa-envelope" ></i>              
              </button>
            :
            <button className="icon" title={currentAppLocale.messages["admin.notification.markasunread"]}   onClick={()=>{this.updatenotificationstatus(record.notificationMessagesId,idx)}}>          
               <i className="fas fa-envelope-open" ></i>        
             </button>
            }
          </div>
        )
      },
    ];
  };
  async componentDidMount() {
    this.props.clearPaginationExceptMe(this.module);
    if(this.props.paginginfo && this.props.paginginfo[this.module]){
      this.handleTableChange(this.props.paginginfo[this.module].pagination, this.props.paginginfo[this.module].filter, {},true);
      if(this.props.paginginfo[this.module].filters){
       let filters = this.props.paginginfo[this.module].filters
       Object.keys(filters).map(k=> {filters[k].auto = false});
        this.setState({filters :  filters});
      }
    } else {
      this.handleTableChange({ current: 1, pageSize: 10 }, {}, {}, true);
    }
  }
  handleTableChange = (pagination, filters, sorter, manual) => {
    if(filters === undefined) filters={};
    Object.keys(filters).map( key => { if((!filters[key]) || (Array.isArray(filters[key]) && filters[key].length===0)) { delete filters[key] }} )
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    if(manual !== true)
    {
      this.props.updatePaginationRemoveOld({module:this.module, filter: filters,
      pagination: { current: pagination.current, pageSize: pagination.pageSize }})
    }
    this.setState({ loading: true });
    this.props
      .getNotificationsList({
        filters: filters,
        pagination: { page: pagination.current, pageSize: pagination.pageSize },
        sorter: sorter
      })
      .then(resp => {
        pager.total = resp.paging.totalRecords;
        let data = resp.data;
        this.setState({
          loading: false,
          data: data,
          pagination: pager
        });
      })
      .catch(ex => {
        this.setState({ loading: false });
      });
  };
  handleSearch = (
    selectedKeys,
    confirm,
    dataIndex,
    clearFilters,
    setSelectedKeys
  ) => {
    if(selectedKeys === ""){
      return false;
    }
    
    this.setState({ searchText: selectedKeys });
    let filters = this.state.filters;
    filters[dataIndex] = {
      val: selectedKeys,
      clearf: clearFilters,
      filter: true,
      setSelectedKeys: setSelectedKeys,
      confirm: confirm,
      auto:true
    };
    this.setState({ filters: filters });
    this.props.updateFilters({module:this.module, filters: filters})
    confirm();
  };

  handleReset = (clearFilters, dataIndex) => {
    clearFilters();
    let filters = this.state.filters;
    if (filters[dataIndex]) {
      if(filters[dataIndex].setSelectedKeys && typeof filters[dataIndex].setSelectedKeys === 'function'){
        filters[dataIndex].setSelectedKeys("");
      }
      //filters[dataIndex].confirm();
    }
    if(filters[dataIndex] && !filters[dataIndex].auto){
      delete this.props.paginginfo[this.module].filter[dataIndex];
      this.handleTableChange({ current: 1, pageSize: 10 }, this.props.paginginfo[this.module].filter, {});
   
    }
    filters[dataIndex] = { val: "", clearf: "", filter: false };
    this.setState({ filters: filters });
    this.props.updateFilters({module: this.module, filters:  filters})
    this.setState({ searchText: "" });
  };

  handleMenuClick=(e) => {
    //message.info('Click on menu item.');
    console.log('click', e);
    this.updatenotificationstatus(e.key,0);
  }

  render() {

    const menu = (
      <Menu onClick={this.handleMenuClick}>
        <Menu.Item key="0">
        <IntlMessages id="admin.notification.markallasread" />
        </Menu.Item>
        <Menu.Item key="-1">
        <IntlMessages id="admin.notification.markallasunread" />
        </Menu.Item>
      </Menu>
    );
    
    let _state = this.state;
    let _this = this;
    let filtertag = Object.keys(this.state.filters).map(function (key1) {
      let keyLabel = _this.getHeaderKeys().find(el => el.dataIndex === key1);
      if (keyLabel.title.props && keyLabel.title.props.id) {
        keyLabel = <IntlMessages id={keyLabel.title.props.id} />;
      } else {
        keyLabel = key1;
      }
      if (_state.filters[key1].filter) {
        return (
          <span>
            <Button
              key={key1}
              type="primary"
              onClick={() =>
                _this.handleReset(_state.filters[key1].clearf, key1)
              }
            >
              {keyLabel} : {_state.filters[key1].val}
              <Icon type="close" />
            </Button>
            &nbsp;
          </span>
        );
      }
    }); return (
      <div>
        <Row gutter={24}>
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography.Title level={4}>
              <IntlMessages id="admin.notification" />
            </Typography.Title>
          </Col>
        </Row>
        <hr />
        <Row gutter={24}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{textAlign:"right"}}>
        {this.state.data && this.state.data.length >0 && 
        <Dropdown.Button type="primary"  overlay={menu} >
        <IntlMessages id="admin.notification.action" />
       </Dropdown.Button>
        }
        </Col>
        </Row>
        <Row gutter={24} style={{ marginBottom: "20px" }}>
        <Col xs={24}>{filtertag}</Col>
        </Row>
        <Row gutter={24}>
          <Col xs={24} style={{ overflowX: "auto" }}>
            <Table
              columns={this.getHeaderKeys()}
              rowKey={record => record.notificationMessagesId}
              dataSource={this.state.data}
              pagination={this.state.pagination}
              loading={this.state.loading}
              onChange={this.handleTableChange}
              bordered
            />
          </Col>
        </Row>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return { ...state.language, ...state.pagination };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...NotificationActions, ...paginationActions, ...notificationActions }, dispatch);
}
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(Notifications)
);
