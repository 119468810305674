import { Types } from "../constants/day-packages-types";
const initialState = {
  daypackagesData: {},
  dayPackageDataById:{}
};
export default function tiersReducer(state = initialState, action) {
  switch (action.type) {
   
    case Types.DAY_PACKAGES_LIST:
      return { ...state, daypackagesData: action.payload };
      case Types.DAY_PACKAGES_DATA_BYID:
        return { ...state, dayPaackageDataById: action.payload };
  
    default:
      return state;
  }
}
