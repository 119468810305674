import Axios from "../../services/axios-service";


 const addCustomAlert = data => {
    return Axios.post("/api/CustomAlert/", data, undefined, undefined);
        };
 const getCustAlert = (orgId, data) => {
    return Axios.get("/api/CustomAlert/"+orgId+"?search=" + encodeURIComponent(JSON.stringify(data)), undefined, undefined);
};
const getCustomAlertDetail = idx => {

  return Axios.get("/api/CustomAlert/CustomAlertDetail/" + idx, undefined, undefined);
};
const getUserReplies = (alertId, args) => {
  return Axios.get("/api/CustomAlert/CustomAlertChat/" + alertId+"?search=" + encodeURIComponent(JSON.stringify(args)), undefined, undefined);
};
export default {
  addCustomAlert,
  getCustAlert,
  getCustomAlertDetail,
  getUserReplies
};

