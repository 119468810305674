import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Table, Button, Icon, Card, Row, Spin } from "antd";
import { GeofenceSvg } from "../../../shared/svg/svg/home-icon";
import * as geoFenceAction from "../../../../redux/actions/geofence-action";
import * as UserService from "../../../../services/user-service";

const GeofenceSvgIcon = props => <Icon component={GeofenceSvg} {...props} />;

class TravellerListGeofencing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      data: [],
      userOrdId: 0
    };
  }

  getHeaderKeys = () => {
    return [
      {
        title: "Name",
        dataIndex: "title"
      },
      {
        title: "",
        rowKey: "action",
        render: (text, record) => (
          <span>
            <Button
              className="btn-outline"
              onClick={() =>
                this.props.history.push(
                  "geofencing/" + record.orgId + "/" + record.interstedGeosID +"/"
                )
              }
            >
              More Info
            </Button>
          </span>
        )
      }
    ];
  };

  async componentDidMount() {
    this.setState({ loading: true });
    let currentUser = UserService.getUser();
    let orgId = currentUser.companyID;
    this.props
      .getGeoFenceData(orgId, {
        filters: {},
        pagination: { page: 1, pageSize: 5 },
        sorter: {}
      })
      .then(resp => {
        this.setState({
          loading: false,
          data: resp.data,
          userOrdId: orgId
        });
      })
      .catch(ex => {
        this.setState({ loading: false });
      });
  }

  render() {
    return (
      <div>
        <Spin size="large" spinning={this.state.loading}>
          <Card
            title="List of Geofences"
            bodyStyle={{ padding: 0 }}
            extra={<GeofenceSvgIcon />}
          >
            <Table
              pagination={false}
              columns={this.getHeaderKeys()}
              dataSource={this.state.data}
            />
            <Row type="flex" justify="end">
              <Button
                type="primary"
                className="addBtn"
                size={"large"}
                style={{ margin: " 24px 24px 24px 0px" }}
                onClick={() =>
                  this.props.history.push("geofencing/" + this.state.userOrdId +"/")
                }
              >
                View All
              </Button>
            </Row>
          </Card>
        </Spin>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {};
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...geoFenceAction }, dispatch);
}
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    TravellerListGeofencing
  )
);
