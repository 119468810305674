import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import IntlMessages from "../../../../services/intlMesseges";
import * as B2B2COrgActions from "../../../../redux/actions/b2b2c-organization-actions";
import * as adminActions from "../../../../redux/actions/admin-actions";
import {
  Typography,
  Form,
  Input,
  Button,
  Row,
  Col,
  Checkbox,
  Select,
  Spin,
} from "antd";
import * as userActions from "../../../../redux/actions/user-actions";
import { notifyUser } from "../../../../services/notification-service";
import * as b2cActions from "../../../../redux/actions/b2c-actions";
import * as Permission from "../../../../services/permissions";
import AppLocale from "../../../../languageProvider";
const { Option } = Select;
var validator = require("email-validator");

class PrimaryAddress extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formLayout: undefined,
      data: {},
      countries: [],
      userData: [],
      stateuser: 1,
      orgData: {},
      secdata: {},
      billingData: {},
      adminInfo: {},
      isBusinessAddressSame: false,
      isCommunicationAddressSame: false,
      disablecheck: false,
      disablecheck2: false,
      loading: true,
    };
    this.checkchange = this.checkchange.bind(this);
    this.backpage = this.backpage.bind(this);
  }

  async componentDidMount() {
    
    if (this.props.orgData && this.props.orgData.organizationId) {
      let _disablecheck = this.state.disablecheck;
      let _disablecheck2 = this.state.disablecheck2;
      if (this.props.orgData.isBusinessAddressSame) {
        _disablecheck = true;
      } else {
        _disablecheck = false;
      }
      if (this.props.orgData.isCommunicationAddressSame ) {
        _disablecheck2 = true;
      } else {
        _disablecheck = false;
        _disablecheck = true;
      }
      this.setState({
        orgData: this.props.orgData,
        secdata: this.props.orgData.orgContacts.filter(
          (data) => data.addressTypeID === 2
        )[0],
        billingData: this.props.orgData.orgContacts.filter(
          (data) => data.addressTypeID === 3
        )[0],
        adminInfo: this.props.orgData.adminInfo,
        isBusinessAddressSame: this.props.orgData.isBusinessAddressSame,
        isCommunicationAddressSame: this.props.orgData
          .isCommunicationAddressSame,
        disablecheck: _disablecheck,
        disablecheck2: _disablecheck2,
      });
    }
    this.props.getCountriesListName().then((data) => {
      this.setState({
        countries: data,
        loading: false,
      });
    });
  }

  handleSubmit() {
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.setState({ loading: true });

        var JSONorg = {
          organizationId: parseInt(this.props.match.params.id),
          name: values.company_name,
          address1: values.address,
          state: values.company_state,
          city: values.city,
          countryId: values.country,
          zipCode: values.zip,
          fpManagerID: 0,
          fpSalesExecutiveID: 0,
          isBusinessAddressSame: this.state.isBusinessAddressSame,
          isCommunicationAddressSame: this.state.isCommunicationAddressSame,
          productInfo: this.state.orgData.productInfo,
          phoneNumber: values.phoneNumber,
          /* contactFirstName: values.firstName,
          contactLastName: values.lastName, */
          customerID: values.customer_id,
          jobNumber: values.job_number,
          orgContacts: [
            {
              addressTypeID: 2,
              city: values.sec_city,
              zipCode: values.sec_zip,
              state: values.sec_state,
              countryId: values.sec_country,
              contacts: values.sec_phoneNumber,
              contactPerson: values.secondary_contactPerson,
              emailID: values.sec_email,
              address: values.sec_address,
            },
            {
              addressTypeID: 3,
              countryId: values.billing_country,
              address: values.billing_address,
              state: values.billing_state,
              city: values.billing_city,
              contacts: values.billing_phoneNumber,
              contactPerson: values.billing_contactPerson,
              emailID: values.billing_email,
              zipCode: values.billing_zip,

              billingCompanyName: values.billing_company_name,
            },
          ],
          orgPCCAccounts: this.state.orgData.orgPCCAccounts,
        };
        this.props
          .updateOrganizationwithId(JSONorg, this.props.match.params.id)
          .then((response) => {
            if (response.error) {
              this.setState({ loading: false });
            } else {
              const { language } = this.props;
              const currentAppLocale = AppLocale[language.locale];
              notifyUser(
                currentAppLocale.messages["admin.organization.edit"],
                "success"
              );
              this.props.history.goBack();
              this.setState({ loading: false });
            }
          });
      }
    });
  }
  checkchange(e) {
    if (e.target.id === "secAddress" && e.target.checked === true) {
      let _secData = this.state.secdata;
      _secData.address = this.state.orgData.address1;
      _secData.countryId = this.state.orgData.countryId;
      _secData.state = this.state.orgData.state;
      _secData.city = this.state.orgData.city;
      _secData.zipCode = this.state.orgData.zipCode;
      _secData.contacts = this.state.orgData.phoneNumber;
      _secData.emailID = this.state.orgData.orgEmail;
      _secData.contactPerson = this.state.adminInfo.firstName.concat(
        " ",
        this.state.adminInfo.lastName
      );
      this.setState({
        isBusinessAddressSame: e.target.checked,
        disablecheck: true,
      });

      this.props.form.setFieldsValue({
        sec_city: _secData.city,
        sec_address: _secData.address,
        sec_zip: _secData.zipCode,
        sec_phoneNumber: _secData.contacts,
        sec_email: _secData.emailID,
        secondary_contactPerson: _secData.contactPerson,
        sec_country: _secData.countryId,
        sec_state: _secData.state,
      });
    } else if (e.target.id === "secAddress" && e.target.checked === false) {
      this.setState({
        isBusinessAddressSame: e.target.checked,
        disablecheck: false,
      });
  
      // billing address
    } else if (e.target.id === "billAddress" && e.target.checked === true) {
      let _billingData = this.state.billingData;
      _billingData.address = this.state.orgData.address1;
      _billingData.countryId = this.state.orgData.countryId;
      _billingData.state = this.state.orgData.state;
      _billingData.city = this.state.orgData.city;
      _billingData.zipCode = this.state.orgData.zipCode;
      _billingData.contacts = this.state.orgData.phoneNumber;
      _billingData.emailID = this.state.orgData.orgEmail;
      _billingData.contactPerson = this.state.adminInfo.firstName.concat(
        " ",
        this.state.adminInfo.lastName
      );
      _billingData.billingCompanyName = this.state.orgData.name;

      this.setState({
        isCommunicationAddressSame: e.target.checked,
        disablecheck2: true,
      });
      this.props.form.setFieldsValue({
        billing_city: _billingData.city,
        billing_address: _billingData.address,
        billing_zip: _billingData.zipCode,
        billing_phoneNumber: _billingData.contacts,
        billing_email: _billingData.emailID,
        billing_contactPerson: _billingData.contactPerson,
        billing_country: _billingData.countryId,
        billing_state: _billingData.state,
        billing_company_name: _billingData.billingCompanyName,
      });
    } else if (e.target.id === "billAddress" && e.target.checked === false) {
      this.setState({
        isCommunicationAddressSame: e.target.checked,
        disablecheck2: false,
      });

    }
  }
  backpage() {
    this.props.history.push("/admin/b2b2c-organizations/");
  }
  handleEmailExistance = (rule, value, callback) => {
    if (
      this.props.email === null ||
      this.props.email === undefined ||
      this.props.email === ""
    ) {
      callback(<IntlMessages id="admin.email.alreadyexist"></IntlMessages>);
    } else {
      if (validator.validate(rule)) {
        this.props.validateTravellerEmail(this.props.email);
        if (this.props.emailCheckResponse.userExist) {
          callback(<IntlMessages id="admin.email.alreadyexist"></IntlMessages>);
        }
      }
      if (validator.validate(this.props.email)) {
        this.props.validateTravellerEmail(this.props.email);
        if (this.props.emailCheckResponse.userExist) {
          callback(<IntlMessages id="admin.email.alreadyexist"></IntlMessages>);
        }
      }
    }

    callback();
  };
  valuechanges(e, type) {
    this.setState({
      isBusinessAddressSame: false,
      isCommunicationAddressSame: false,
      disablecheck: false,
      disablecheck2: false,
    });
    let _orgData = this.state.orgData;
    let _adminInfo = this.state.adminInfo;
    if (e && e.target && e.target.id) {
      if (e.target.id === "primary-address_address") {
        _orgData.address1 = e.target.value;
      } else if (e.target.id === "primary-address_company_name") {
        _orgData.name = e.target.value;
      } else if (e.target.id === "primary-address_city") {
        _orgData.city = e.target.value;
      } else if (e.target.id === "primary-address_zip") {
        _orgData.zipCode = e.target.value;
      } else if (e.target.id === "primary-address_phoneNumber") {
        _orgData.phoneNumber = e.target.value;
      } else if (e.target.id === "primary-address_firstName") {
        _adminInfo.firstName = e.target.value;
      } else if (e.target.id === "primary-address_lastName") {
        _adminInfo.lastName = e.target.value;
      } else if (e.target.id === "primary-address_company_state") {
        _orgData.state = e.target.value;
      }
    } else {
      _orgData.countryId = e;
    }
    this.setState({ orgData: _orgData, adminInfo: _adminInfo });
  }

  handleContactExistance(rule, value, callback) {
    //const re = /^[0-9']+$/;
    const re = /^(?=[+0-9]*$)(?!.*[<>'"/;`%~@#$^*()_=[\]{}|\\,.?: -])/;
    var test = re.test(value);
    if (value !== "" && (value.length < 6 || test === false)) {
      callback(<IntlMessages id="admin.phonenumber.valid"></IntlMessages>);
    } else {
      callback();
    }
  }

  handleEmergencyContactExistance(rule, value, callback) {
    //const re = /^[0-9']+$/;
    const re = /^(?=[+0-9]*$)(?!.*[<>'"/;`%~@#$^*()_=[\]{}|\\,.?: -])/;
    var test = re.test(value);
    if (value !== "" && (value.length < 6 || test === false)) {
      callback(<IntlMessages id="admin.phonenumber.valid"></IntlMessages>);
    } else {
      callback();
    }
  }
  render() {
    const { getFieldDecorator } = this.props.form;
    const { formLayout } = this.state;
    const formItemLayout =
      formLayout === "horizontal"
        ? {
            labelCol: { span: 4 },
            wrapperCol: { span: 14 },
          }
        : null;
    return (
      <div>
        {/* Primary address*/}
        <Row gutter={24}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Typography.Title level={4}>
              <IntlMessages id='admin.primary-address' />
            </Typography.Title>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12} style={{ textAlign: 'right' }}>
            <Button type='primary' onClick={this.backpage}>
              <IntlMessages id='admin.editorganization.back' />
            </Button>
          </Col>
        </Row>
        <hr />
        <Spin spinning={this.state.loading}>
          <Form layout={formLayout} onSubmit={this.handleSubmit}>
            <Row gutter={24}>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Form.Item {...formItemLayout} label={<IntlMessages id='admin.primaryaddress.name' />}>
                  {getFieldDecorator('company_name', {
                    rules: [
                      {
                        required: true,
                        message: <IntlMessages id='admin.input.required'></IntlMessages>
                      }
                    ],
                    onChange: (e) => {
                      this.valuechanges(e);
                    },
                    initialValue: this.state.orgData.name === null ? '' : this.state.orgData.name,

                    value: this.state.orgData.name
                  })(<Input maxlength='50' />)}
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Form.Item {...formItemLayout} label={<IntlMessages id='admin.primaryaddress.company_code' />}>
                  {getFieldDecorator('company_code', {
                    rules: [
                      {
                        required: true,
                        message: <IntlMessages id='admin.input.required'></IntlMessages>
                      }
                    ],
                    initialValue: this.state.orgData.code === null ? '' : this.state.orgData.code,

                    value: this.state.orgData.code
                  })(<Input maxLength={4} minLength={1} disabled />)}
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Form.Item {...formItemLayout} label={<IntlMessages id='admin.primaryaddress.customerId' />}>
                  {getFieldDecorator('customer_id', {
                    initialValue: this.state.orgData.customerID === null ? '' : this.state.orgData.customerID,

                    value: this.state.orgData.customerID
                  })(<Input />)}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Form.Item {...formItemLayout} label={<IntlMessages id='admin.primaryaddress.jobnumber' />}>
                  {getFieldDecorator('job_number', {
                    initialValue: this.state.orgData.jobNumber === null ? '' : this.state.orgData.jobNumber,

                    value: this.state.orgData.jobNumber
                  })(<Input />)}
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Form.Item {...formItemLayout} label={<IntlMessages id='admin.primaryaddress.address' />}>
                  {getFieldDecorator('address', {
                    rules: [
                      {
                        required: true,
                        message: <IntlMessages id='admin.input.required'></IntlMessages>
                      }
                    ],
                    onChange: (e) => {
                      this.valuechanges(e);
                    },
                    initialValue: this.state.orgData.address1 === null ? '' : this.state.orgData.address1,

                    value: this.state.orgData.address1
                  })(<Input />)}
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Form.Item label={<IntlMessages id='admin.primaryaddress.country' />}>
                  {getFieldDecorator('country', {
                    rules: [
                      {
                        required: true,
                        message: <IntlMessages id='admin.input.required'></IntlMessages>
                      }
                    ],
                    onChange: (e) => {
                      this.valuechanges(e);
                    },
                    initialValue: this.state.orgData.countryId
                  })(
                    <Select
                      showSearch
                      filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {this.state.countries.map(function(item) {
                        return (
                          <Option key={item.countryId} value={item.countryId}>
                            {item.countryName}
                          </Option>
                        );
                      })}
                    </Select>
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Form.Item {...formItemLayout} label={<IntlMessages id='admin.primaryaddress.state' />}>
                  {getFieldDecorator('company_state', {
                    rules: [
                      {
                        required: true,
                        message: <IntlMessages id='admin.input.required'></IntlMessages>
                      },
                      {
                        pattern: new RegExp(/^[ a-zA-Z]+$/),
                        message: <IntlMessages id='admin.state.valid'></IntlMessages>
                      }
                    ],
                    onChange: (e) => {
                      this.valuechanges(e);
                    },

                    initialValue: this.state.orgData.state === null ? '' : this.state.orgData.state,

                    value: this.state.orgData.state
                  })(<Input />)}
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Form.Item {...formItemLayout} label={<IntlMessages id='admin.primaryaddress.city' />}>
                  {getFieldDecorator('city', {
                    rules: [
                      {
                        required: true,
                        message: <IntlMessages id='admin.input.required'></IntlMessages>
                      },
                      {
                        pattern: new RegExp(/^[ a-zA-Z]+$/),
                        message: <IntlMessages id='admin.city.valid'></IntlMessages>
                      }
                    ],
                    onChange: (e) => {
                      this.valuechanges(e);
                    },

                    initialValue: this.state.orgData.city === null ? '' : this.state.orgData.city,

                    value: this.state.orgData.city
                  })(<Input />)}
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Form.Item {...formItemLayout} label={<IntlMessages id='admin.primaryaddress.zip' />}>
                  {getFieldDecorator('zip', {
                    rules: [
                      {
                        required: true,
                        message: <IntlMessages id='admin.input.required'></IntlMessages>
                      },
                      {
                        pattern: new RegExp(/^[a-zA-Z0-9]+$/),
                        message: <IntlMessages id='admin.zip.valid'></IntlMessages>
                      },
                      {
                        max: 10,
                        message: <IntlMessages id='admin.zip.valid'></IntlMessages>
                      },
                      {
                        min: 4,
                        message: <IntlMessages id='admin.zip.valid'></IntlMessages>
                      }
                    ],
                    onChange: (e) => {
                      this.valuechanges(e);
                    },
                    initialValue: this.state.orgData.zipCode === null ? '' : this.state.orgData.zipCode,

                    value: this.state.orgData.zipCode
                  })(<Input minLength={4} maxLength={10} />)}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Form.Item {...formItemLayout} label={<IntlMessages id='admin.primaryaddress.phonenumber' />}>
                  {getFieldDecorator('phoneNumber', {
                    rules: [
                      {
                        validator: this.handleContactExistance
                      },
                      {
                        required: true,
                        message: <IntlMessages id='admin.input.required'></IntlMessages>
                      } //{
                      //   pattern: new RegExp(/^[0-9']+$/),
                      //   message: <IntlMessages id ="admin.phonenumber.valid"></IntlMessages>
                      // },
                      // {
                      //   max: 12,
                      //   message: "Please enter a valid number!"
                      // },
                      // {
                      //   min: 8,
                      //   message: "Please enter a valid number!"
                      // }
                    ],
                    onChange: (e) => {
                      this.valuechanges(e);
                    },
                    initialValue: this.state.orgData.phoneNumber === null ? '' : this.state.orgData.phoneNumber,

                    value: this.state.orgData.phoneNumber
                  })(<Input maxLength={15} />)}
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Form.Item {...formItemLayout} label={<IntlMessages id='admin.primaryaddress.email' />}>
                  {this.props.emailCheckResponse.userExist}
                  {getFieldDecorator('email', {
                    rules: [
                      {
                        type: 'email',
                        message: <IntlMessages id='admin.email.valid'></IntlMessages>
                      },
                      {
                        required: true,
                        message: <IntlMessages id='admin.input.required'></IntlMessages>
                      }
                    ],
                    onChange: (e) => {
                      this.valuechanges(e);
                    },
                    initialValue: this.state.orgData.orgEmail === null ? '' : this.state.orgData.orgEmail,

                    value: this.state.orgData.orgEmail
                  })(<Input disabled />)}
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Form.Item {...formItemLayout} label={<IntlMessages id='admin.userlisting.firstName' />}>
                  {getFieldDecorator('firstName', {
                    rules: [
                      {
                        required: true,
                        message: <IntlMessages id='admin.input.required'></IntlMessages>
                      },
                      {
                        pattern: new RegExp(/^(\S.*)[a-zA-Z-.']+$/),
                        message: <IntlMessages id='admin.name.valid'></IntlMessages>
                      }
                    ],
                    onChange: (e) => {
                      this.valuechanges(e);
                    },
                    initialValue: (this.state.adminInfo && this.state.adminInfo.firstName) || '',
                    value: (this.state.adminInfo && this.state.adminInfo.firstName) || ''
                  })(<Input disabled minLength={2} maxLength={15} />)}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Form.Item {...formItemLayout} label={<IntlMessages id='admin.userlisting.lastName' />}>
                  {getFieldDecorator('lastName', {
                    rules: [
                      {
                        required: true,
                        message: <IntlMessages id='admin.input.required'></IntlMessages>
                      },
                      {
                        pattern: new RegExp(/^(\S.*)[a-zA-Z-.']+$/),
                        message: <IntlMessages id='admin.lname.valid'></IntlMessages>
                      }
                    ],
                    onChange: (e) => {
                      this.valuechanges(e);
                    },
                    initialValue: this.state.adminInfo && this.state.adminInfo.lastName || "",

                    value: this.state.adminInfo && this.state.adminInfo.lastName || "",
                  })(<Input disabled minLength={2} maxLength={50} />)}
                </Form.Item>
              </Col>
            </Row>

            {/* Primary address ends */}
            {/* Secondary Address */}
            <Row gutter={24}>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Typography.Title level={4}>
                  <IntlMessages id='admin.secondary-address' />
                </Typography.Title>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12} style={{ textAlign: 'right' }}>
                <Checkbox
                  id='secAddress'
                  name='secAddress'
                  onChange={this.checkchange}
                  checked={this.state.isBusinessAddressSame}
                >
                  <IntlMessages id='admin.sameasinformation.content' />
                </Checkbox>
              </Col>
            </Row>
            <hr></hr>

            <Row gutter={24}>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Form.Item label={<IntlMessages id='admin.primaryaddress.country'></IntlMessages>}>
                  {getFieldDecorator('sec_country', {
                    rules: [
                      {
                        required: true,
                        message: <IntlMessages id='admin.input.required' />
                      }
                    ],

                    initialValue: this.state.secdata && this.state.secdata.countryId ? this.state.secdata.countryId : ''
                  })(
                    <Select
                      showSearch
                      filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      disabled={this.state.disablecheck}
                    >
                      {this.state.countries.map(function(item) {
                        return (
                          <Option key={item.countryId} value={item.countryId}>
                            {item.countryName}
                          </Option>
                        );
                      })}
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Form.Item {...formItemLayout} label={<IntlMessages id='admin.primaryaddress.state' />}>
                  {getFieldDecorator('sec_state', {
                    rules: [
                      {
                        required: true,
                        message: <IntlMessages id='admin.input.required'></IntlMessages>
                      },
                      {
                        pattern: new RegExp(/^[ a-zA-Z]+$/),
                        message: <IntlMessages id='admin.state.valid'></IntlMessages>
                      }
                    ],

                    initialValue:
                      this.state.secdata && this.state.secdata.state !== null ? this.state.secdata.state : ''
                  })(<Input disabled={this.state.disablecheck} />)}
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Form.Item {...formItemLayout} label={<IntlMessages id='admin.primaryaddress.city' />}>
                  {getFieldDecorator('sec_city', {
                    rules: [
                      {
                        required: true,
                        message: <IntlMessages id='admin.input.required'></IntlMessages>
                      },
                      {
                        pattern: new RegExp(/^[ a-zA-Z]+$/),
                        message: <IntlMessages id='admin.city.valid'></IntlMessages>
                      }
                    ],

                    initialValue: this.state.secdata && this.state.secdata.city !== null ? this.state.secdata.city : ''
                  })(<Input disabled={this.state.disablecheck} />)}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Form.Item {...formItemLayout} label={<IntlMessages id='admin.primaryaddress.address' />}>
                  {getFieldDecorator('sec_address', {
                    rules: [
                      {
                        required: true,
                        message: <IntlMessages id='admin.input.required'></IntlMessages>
                      }
                    ],

                    initialValue:
                      this.state.secdata && this.state.secdata.address !== null ? this.state.secdata.address : ''
                  })(<Input disabled={this.state.disablecheck} />)}
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Form.Item {...formItemLayout} label={<IntlMessages id='admin.primaryaddress.zip' />}>
                  {getFieldDecorator('sec_zip', {
                    rules: [
                      {
                        required: true,
                        message: <IntlMessages id='admin.input.required'></IntlMessages>
                      },
                      {
                        pattern: new RegExp(/[A-Za-z0-9]$/),
                        message: <IntlMessages id='admin.zip.valid'></IntlMessages>
                      },
                      {
                        max: 10,
                        message: <IntlMessages id='admin.zip.valid'></IntlMessages>
                      },
                      {
                        min: 4,
                        message: <IntlMessages id='admin.zip.valid'></IntlMessages>
                      }
                    ],
                    initialValue:
                      this.state.secdata && this.state.secdata.zipCode !== null ? this.state.secdata.zipCode : ''
                  })(<Input minLength={4} maxLength={10} disabled={this.state.disablecheck} />)}
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Form.Item {...formItemLayout} label={<IntlMessages id='admin.primaryaddress.phonenumber' />}>
                  {getFieldDecorator('sec_phoneNumber', {
                    rules: [
                      {
                        validator: this.handleEmergencyContactExistance
                      },
                      {
                        required: true,
                        message: <IntlMessages id='admin.input.required'></IntlMessages>
                      }
                      //  {
                      //    pattern: new RegExp(/^[0-9']+$/),
                      //    message: <IntlMessages id ="admin.phonenumber.valid"></IntlMessages>
                      //  },
                      //  {
                      //    max: 12,
                      //    message: "Please enter a valid number!"
                      //  },
                      //  {
                      //    min: 8,
                      //    message: "Please enter a valid number!"
                      //  }
                    ],
                    initialValue:
                      this.state.secdata && this.state.secdata.contacts !== null ? this.state.secdata.contacts : ''
                  })(<Input maxLength={15} disabled={this.state.disablecheck} />)}
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Form.Item {...formItemLayout} label={<IntlMessages id='admin.primaryaddress.email' />}>
                  {getFieldDecorator('sec_email', {
                    rules: [
                      {
                        type: 'email',
                        message: <IntlMessages id='admin.email.valid'></IntlMessages>
                      },
                      {
                        required: true,
                        message: <IntlMessages id='admin.input.required'></IntlMessages>
                      }
                    ],
                    initialValue:
                      this.state.secdata && this.state.secdata.emailID !== null ? this.state.secdata.emailID : ''
                  })(<Input disabled={this.state.disablecheck} />)}
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Form.Item {...formItemLayout} label={<IntlMessages id='admin.primaryaddress.contactPerson' />}>
                  {getFieldDecorator('secondary_contactPerson', {
                    rules: [
                      {
                        required: true,
                        message: <IntlMessages id='admin.input.required'></IntlMessages>
                      },
                      {
                        pattern: new RegExp(/^(\S.*)[a-zA-Z-.']+$/),
                        message: <IntlMessages id='admin.contactperson.valid'></IntlMessages>
                      }
                    ],
                    initialValue:
                      this.state.secdata && this.state.secdata.contactPerson !== null
                        ? this.state.secdata.contactPerson
                        : ''
                  })(<Input minLength={2} maxLength={50} disabled={this.state.disablecheck} />)}
                </Form.Item>
              </Col>{' '}
            </Row>

            {/* Secondary Address ends  */}
            {/* Billing Address */}
            <Row gutter={24}>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Typography.Title level={4}>
                  <IntlMessages id='admin.billing-address' />
                </Typography.Title>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12} style={{ textAlign: 'right' }}>
                <Checkbox
                  id='billAddress'
                  name='billAddress'
                  onChange={this.checkchange}
                  checked={this.state.isCommunicationAddressSame}
                >
                  <IntlMessages id='admin.sameasinformation.content' />
                </Checkbox>
              </Col>
            </Row>
            <hr></hr>
            <Row gutter={24}>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Form.Item {...formItemLayout} label={<IntlMessages id='admin.primaryaddress.billing_Company_name' />}>
                  {getFieldDecorator('billing_company_name', {
                    rules: [
                      {
                        required: true,
                        message: <IntlMessages id='admin.input.required' />
                      }
                    ],
                    initialValue:
                      this.state.billingData && this.state.billingData.billingCompanyName !== null
                        ? this.state.billingData.billingCompanyName
                        : ''
                  })(<Input maxLength={30} disabled={this.state.disablecheck2} />)}
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Form.Item label={<IntlMessages id='admin.primaryaddress.country' />}>
                  {getFieldDecorator('billing_country', {
                    rules: [
                      {
                        required: true,
                        message: <IntlMessages id='admin.input.required' />
                      }
                    ],
                    initialValue:
                      this.state.billingData && this.state.billingData.countryId !== null
                        ? this.state.billingData.countryId
                        : ''
                  })(
                    <Select
                      showSearch
                      filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      disabled={this.state.disablecheck2}
                    >
                      {this.state.countries.map(function(item) {
                        return (
                          <Option key={item.countryId} value={item.countryId}>
                            {item.countryName}
                          </Option>
                        );
                      })}
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Form.Item {...formItemLayout} label={<IntlMessages id='admin.primaryaddress.state' />}>
                  {getFieldDecorator('billing_state', {
                    rules: [
                      {
                        required: true,
                        message: <IntlMessages id='admin.input.required'></IntlMessages>
                      },
                      {
                        pattern: new RegExp(/^[ a-zA-Z]+$/),
                        message: <IntlMessages id='admin.state.valid'></IntlMessages>
                      }
                    ],
                    initialValue:
                      this.state.billingData && this.state.billingData.state !== null
                        ? this.state.billingData.state
                        : ''
                  })(<Input disabled={this.state.disablecheck2} />)}
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Form.Item {...formItemLayout} label={<IntlMessages id='admin.primaryaddress.city' />}>
                  {getFieldDecorator('billing_city', {
                    rules: [
                      {
                        required: true,
                        message: <IntlMessages id='admin.input.required'></IntlMessages>
                      },
                      {
                        pattern: new RegExp(/^[ a-zA-Z]+$/),
                        message: <IntlMessages id='admin.city.valid'></IntlMessages>
                      }
                    ],
                    initialValue:
                      this.state.billingData && this.state.billingData.city !== null ? this.state.billingData.city : ''
                  })(<Input disabled={this.state.disablecheck2} />)}
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Form.Item {...formItemLayout} label={<IntlMessages id='admin.primaryaddress.address' />}>
                  {getFieldDecorator('billing_address', {
                    rules: [
                      {
                        required: true,
                        message: <IntlMessages id='admin.input.required'></IntlMessages>
                      }
                    ],
                    initialValue:
                      this.state.billingData && this.state.billingData.address !== null
                        ? this.state.billingData.address
                        : ''
                  })(<Input disabled={this.state.disablecheck2} />)}
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Form.Item {...formItemLayout} label={<IntlMessages id='admin.primaryaddress.zip' />}>
                  {getFieldDecorator('billing_zip', {
                    rules: [
                      {
                        required: true,
                        message: <IntlMessages id='admin.input.required'></IntlMessages>
                      },
                      {
                        pattern: new RegExp(/[A-Za-z0-9]$/),
                        message: <IntlMessages id='admin.zip.valid'></IntlMessages>
                      },
                      {
                        max: 10,
                        message: <IntlMessages id='admin.zip.valid'></IntlMessages>
                      },
                      {
                        min: 4,
                        message: <IntlMessages id='admin.zip.valid'></IntlMessages>
                      }
                    ],
                    initialValue:
                      this.state.billingData && this.state.billingData.zipCode !== null
                        ? this.state.billingData.zipCode
                        : ''
                  })(<Input minLength={4} maxLength={10} disabled={this.state.disablecheck2} />)}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Form.Item {...formItemLayout} label={<IntlMessages id='admin.primaryaddress.phonenumber' />}>
                  {getFieldDecorator('billing_phoneNumber', {
                    rules: [
                      {
                        validator: this.handleEmergencyContactExistance
                      },
                      {
                        required: true,
                        message: <IntlMessages id='admin.input.required'></IntlMessages>
                      }
                      //  {
                      //    pattern: new RegExp(/^[0-9']+$/),
                      //    message: <IntlMessages id ="admin.phonenumber.valid"></IntlMessages>
                      //  },
                      //  {
                      //    max: 12,
                      //    message: "Please enter a valid number!"
                      //  },
                      //  {
                      //    min: 8,
                      //    message: "Please enter a valid number!"
                      // }
                    ],
                    initialValue:
                      this.state.billingData && this.state.billingData.contacts !== null
                        ? this.state.billingData.contacts
                        : ''
                  })(<Input maxLength={15} disabled={this.state.disablecheck2} />)}
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Form.Item {...formItemLayout} label={<IntlMessages id='admin.primaryaddress.email' />}>
                  {getFieldDecorator('billing_email', {
                    rules: [
                      {
                        type: 'email',
                        message: <IntlMessages id='admin.email.valid'></IntlMessages>
                      },
                      {
                        required: true,
                        message: <IntlMessages id='admin.input.required'></IntlMessages>
                      }
                    ],
                    initialValue:
                      this.state.billingData && this.state.billingData.emailID !== null
                        ? this.state.billingData.emailID
                        : ''
                  })(<Input disabled={this.state.disablecheck2} />)}
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Form.Item {...formItemLayout} label={<IntlMessages id='admin.primaryaddress.contactPerson' />}>
                  {getFieldDecorator('billing_contactPerson', {
                    rules: [
                      {
                        required: true,
                        message: <IntlMessages id='admin.input.required'></IntlMessages>
                      },
                      {
                        pattern: new RegExp(/^(\S.*)[a-zA-Z-.']+$/),
                        message: <IntlMessages id='admin.contactperson.valid'></IntlMessages>
                      }
                    ],
                    initialValue:
                      this.state.billingData && this.state.billingData.contactPerson !== null
                        ? this.state.billingData.contactPerson
                        : ''
                  })(<Input minLength={2} maxLength={50} disabled={this.state.disablecheck2} />)}
                </Form.Item>
              </Col>
            </Row>
            <Button type='primary' onClick={() => this.handleSubmit()}>
              <IntlMessages id='admin.organization.submit' />
            </Button>
            {/* Billing Address ends */}
          </Form>
        </Spin>
      </div>
    );
  }
}

PrimaryAddress.propTypes = {
  location: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    ...state.b2c,
    ...state.language,
    ...state.user,
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { ...b2cActions, ...userActions, ...B2B2COrgActions, ...adminActions },
    dispatch
  );
}
const WrappedForm = Form.create({ name: "primary-address" })(PrimaryAddress);
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    WrappedForm
  )
);
