import * as firebase from "firebase/app";
import "firebase/messaging";

const initializedFirebaseApp = firebase.initializeApp({
  messagingSenderId: "865108994710",
  apiKey: "AIzaSyBB8sMGWzoDBimt2RboRLbzJit6Ru7ThIs",
  authDomain: "focuspoint-redblink-project.firebaseapp.com",
  databaseURL: "https://focuspoint-redblink-project.firebaseio.com",
  projectId: "focuspoint-redblink-project",
  storageBucket: "focuspoint-redblink-project.appspot.com",
  appId: "1:865108994710:web:78c6b4157c888d813630b7"
});

let FirebaseConfig;
if(firebase.messaging.isSupported())
{
  FirebaseConfig =  initializedFirebaseApp.messaging();
  FirebaseConfig.usePublicVapidKey(
      "BIIzMbapFOnuQpPKLEQrzm627DkoaWknAlAvmr4wYqVrFCRjVBVKwFHUG-tsHVP1W7ecvo8iKp4MVTN3h151l-8"
    );
}


export { FirebaseConfig };
