import React from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as travellerActions from "../../../../../redux/actions/travellers-actions";
import * as userActions from "../../../../../redux/actions/user-actions";
import OrgTree from "../../../../shared/components/orgTree";
import * as AdminActions from "../../../../../redux/actions/admin-actions";
import * as b2b2cActions from "../../../../../redux/actions/b2b2c-actions";
import IntlMessages from "../../../../../services/intlMesseges";
import { Map, GoogleApiWrapper, Marker } from "google-maps-react";
import { Typography, Form, Input, Select, Button, Row, Col, Spin } from "antd";
import { DatePicker } from "antd";
import moment from "moment";
import { notifyUser } from "../../../../../services/notification-service";
import AppLocale from "../../../../../languageProvider";
import * as Permission from '../../../../../services/permissions';

const dateFormat = "MM/DD/YYYY";
const dateFormatY = "YYYY/MM/DD";
const { Option } = Select;
var validator = require("email-validator");

class EditTraveler extends React.Component {
  state = {
    data: {},
    loading: true,
    confirmDirty: false,
    id: 0,
    first: "",
    last: "",
    email: "",
    phone: "",
    country: "",
    dateofbirth: "",
    countries: [],
    countryId: "",
    locationFound: false,
    locationData: {},
    checkexisting: true,
    genderList: [],
  };

  async componentDidMount() {
    this.props.getCountriesListName().then((response) => {
      this.setState({ countries: response });
    });
    if (
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.id &&
      this.props.match.params.id !== "new"
    ) {
      this.setState({ userId: this.props.match.params.id });

      const user = await this.props.getTraveller(this.props.match.params.id);
      this.setState(user);
      if (user.data && user.data.email == null) {
        this.setState({ checkexisting: false });
      }
      this.setState({ loading: false });
    } else {
      this.setState({ loading: false });
      this.setState({ checkexisting: false });
    }
    this.props.getGendersList().then((response) => {
      this.setState({ genderList: response });
    });
  }

  submitFormAdd = async (data) => {
    const { language } = this.props;
    const currentAppLocale = AppLocale[language.locale];
    var resp = await this.props.addTraveller(data, this.props.match.params.id);
    if (resp && resp.success) {
      notifyUser(currentAppLocale.messages["admin.traveler.add"], "success");
      this.props.history.push("./../");
      this.setState({ loading: false });
    } else {
      notifyUser(resp.data, "error");
      this.setState({ loading: false });
      this.props.form.resetFields();
    }
    // this.props
    //   .addTraveller(data, this.props.match.params.id)
    //   .then(response => {
    //     if (response.error) {
    //       notifyUser(response.error.message, "error");
    //       this.setState({ loading: false });
    //     } else {
    //       notifyUser(
    //         currentAppLocale.messages["admin.traveller.add.success"],
    //         "success"
    //       );
    //       //notifyUser("Traveller added successfully!", "success");
    //       this.props.history.push("./../");
    //       this.setState({ loading: false });
    //     }
    //   })
    //   .catch(err => {
    //     this.setState({ loading: false });
    //   });
    this.finalform();
  };
  async emailsubmit(data) {
    const { language } = this.props;
    const currentAppLocale = AppLocale[language.locale];
    this.setState({ loading: true });

    if (validator.validate(data.email)) {
      await this.props.validateTravellerEmail(data.email);

      if (this.props.emailCheckResponse.userExist) {
        notifyUser(
          currentAppLocale.messages["admin.email.alreadyexis"],
          "error"
        );
        this.setState({ loading: false });
      } else {
        var emaildata = {
          userId: parseInt(this.state.userId),
          organizationId: 1,
          email: data.email,
        };
        this.props.updatetravelerEmail(emaildata).then((resp) => {
          if (
            resp.data.message ===
            "Email Updated. Identity assigned successfully."
          ) {
            this.props.history.push("./");
            this.setState({ loading: false });
            notifyUser(
              currentAppLocale.messages["admin.traveller.update.success"],
              "success"
            );
          }
        });
      }
    }
  }
  submitFormEdit = (data) => {
    const { language } = this.props;
    const currentAppLocale = AppLocale[language.locale];
    this.props
      .updateTravellerData(data, this.props.match.params.id)
      .then((response) => {
        if (response.error) {
          notifyUser(response.data.Message, 'error');
          this.setState({ loading: false });
        } else {
          if (this.state.checkexisting === false) {
            this.emailsubmit(data);
          } else {
            this.props.history.push("./../");
            this.setState({ loading: false });
            notifyUser(
              currentAppLocale.messages["admin.traveller.update.success"],
              "success"
            );
            //notifyUser("Traveller updated successfully!", "success");
          }
        }
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };

  //handle submit
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        Object.keys(values).map(function(key, _idx) {
          if (values[key] == null) {
            values[key] = "";
          } else {
            if (typeof values[key] == "string") {
              values[key] = values[key].trim();
            }
          }
        });
        this.setState({ loading: true });
        values["dateOfBirth"] = moment(values["dateOfBirth"]).format(
          dateFormat
        );
        if (this.state.userId && this.state.userId > 0) {
          values["id"] = this.state.userId;

          this.submitFormEdit(values);
        } else {
          values["organizationId"] = this.props.match.params.orgid;
          values["divisionId"] = this.props.match.params.divid
            ? this.props.match.params.divid
            : this.props.match.params.orgid;
          values["travlertype"] = "b2b2c";
          this.submitFormAdd(values);
        }
      }
    });
  };

  // async _onBlur(data) {
  //   const { language } = this.props;
  //   const currentAppLocale = AppLocale[language.locale];
  //   this.setState({ loading: true });
  //   if (validator.validate(data.target.value)) {
  //     await this.props.validateTravellerEmail(data.target.value);

  //     if (this.props.emailCheckResponse.userExist) {
  //       notifyUser(
  //         'Email allready exit!',
  //         "error"
  //       );
  //       this.setState({ loading: false });
  //     }
  //   }

  // }

  // final submit
  async finalform() {
    const { language } = this.props;
    const currentAppLocale = AppLocale[language.locale];
    if (validator.validate(this.props.email)) {
      await this.props.validateTravellerEmail(this.props.email);
      if (this.props.emailCheckResponse.userExist) {
        notifyUser(
          currentAppLocale.messages["admin.email.alreadyexist"],
          "error"
        );
        //notifyUser("Email Already Exists!", 'error');
      } else {
        this.props.onchange("current", 1);
      }
    }
  }

  handleContactExistance(rule, value, callback) {
    //const re = /^[0-9']+$/;
    const re = /^(?=[+0-9]*$)(?!.*[<>'"/;`%~@#$^*()_=[\]{}|\\,.?: -])/;
    var test = re.test(value);
    if (value == "" && (value.length < 6 || test === false)) {
      callback(<IntlMessages id="admin.phonenumber.valid"></IntlMessages>);
    } else {
      callback();
    }
  }

  handleEmgergencyContactExistance(rule, value, callback) {
    //const re = /^[0-9']+$/;
    const re = /^(?=[+0-9]*$)(?!.*[<>'"/;`%~@#$^*()_=[\]{}|\\,.?: -])/;
    var test = re.test(value);
    if (value == "" && (value.length < 6 || test === false)) {
      callback(<IntlMessages id="admin.phonenumber.valid" />);
    } else {
      callback();
    }
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const { formLayout } = this.state;
    const formItemLayout =
      formLayout === "horizontal"
        ? {
            labelCol: { span: 4 },
            wrapperCol: { span: 14 },
          }
        : null;

    return (
      <div>
        {/* Edit Traveler */}
        <div>
          <Spin spinning={this.state.loading}>
            <Form layout={formLayout} onSubmit={this.handleSubmit}>
              <Row gutter={24} style={{ paddingBottom: '20px' }}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <div style={{ float: 'left', paddingTop: '5px' }}>
                    <IntlMessages id='admin.organization.company' />
                    :&nbsp;
                  </div>
                  <div style={{ float: 'left', minWidth: '320px' }}>
                    {this.props.match && this.props.match.params.id && this.props.match.params.id === 'new'
                      ? getFieldDecorator(
                          'parentOrganization',
                          {}
                        )(
                          <OrgTree
                            parent={
                              this.props.match.params.divid
                                ? this.props.match.params.divid
                                : this.props.match.params.orgid
                            }
                            organizationId={
                              this.props.match.params.orgid
                                ? this.props.match.params.orgid
                                : this.props.match.params.divid
                            }
                            onChange={this.onChangeOrganizationTree}
                          />
                        )
                      : ''}

                    {this.props.match &&
                    this.props.match.params.id &&
                    this.props.match.params.id !== 'new' &&
                    this.state.companyId
                      ? getFieldDecorator(
                          'parentOrganization',
                          {}
                        )(
                          <OrgTree
                            parent={this.state.companyId}
                            organizationId={
                              this.props.match.params.orgid
                                ? this.props.match.params.orgid
                                : this.props.match.params.divid
                            }
                            onChange={this.onChangeOrganizationTree}
                          />
                        )
                      : ''}
                  </div>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: 'right' }}>
                  <Button type='primary' className='' htmlType='button' onClick={() => this.props.history.goBack()}>
                    <IntlMessages id='admin.userlisting.back' />
                  </Button>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                  <Form.Item {...formItemLayout} label={<IntlMessages id='admin.userlisting.firstName' />}>
                    {getFieldDecorator('firstName', {
                      rules: [
                        {
                          whitespace: true,
                          required: true,
                          message: <IntlMessages id='admin.input.required' />
                        },
                        {
                          pattern: new RegExp(/^(\S.*)[a-zA-Z-.']+$/),
                          message: <IntlMessages id='admin.name.valid' />
                        }
                      ],
                      initialValue: this.state.data.travelerFirstName === null ? '' : this.state.data.travelerFirstName
                    })(<Input maxLength={50} />)}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                  <Form.Item {...formItemLayout} label={<IntlMessages id='admin.userlisting.lastName' />}>
                    {getFieldDecorator('lastName', {
                      rules: [
                        {
                          whitespace: true,
                          required: true,
                          message: <IntlMessages id='admin.input.required' />
                        },
                        {
                          pattern: new RegExp(/^(\S.*)[a-zA-Z-.']+$/),
                          message: <IntlMessages id='admin.lname.valid' />
                        }
                      ],
                      initialValue: this.state.data.travelerLastName === null ? '' : this.state.data.travelerLastName
                    })(<Input maxLength={50} />)}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                  <Form.Item {...formItemLayout} label='Date of Birth'>
                    {getFieldDecorator('dateOfBirth', {
                      rules: [
                        {
                          required: true,
                          message: <IntlMessages id='admin.input.required' />
                        }
                      ],
                      initialValue:
                        this.state.data.dateOfBirth != null ? moment(this.state.data.dateOfBirth, dateFormatY) : ''
                    })(<DatePicker format={dateFormat} disabledDate={(current) => current && current > moment()} />)}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                  <Form.Item {...formItemLayout} label={<IntlMessages id='admin.userlisting.email' />}>
                    {getFieldDecorator('email', {
                      rules: [
                        {
                          type: 'email',
                          message: <IntlMessages id='admin.email.valid' />
                        },
                        {
                          whitespace: true,
                          required: true,
                          message: <IntlMessages id='admin.input.required' />
                        }
                      ],
                      initialValue: this.state.data.email === null ? '' : this.state.data.email
                    })(<Input disabled={this.state.checkexisting && !Permission.emailEditAllowded()} />)}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                  <Form.Item {...formItemLayout} label={<IntlMessages id='admin.userlisting.phonenumber' />}>
                    {getFieldDecorator('contactNo', {
                      rules: [
                        {
                          whitespace: true,
                          required: true,
                          message: <IntlMessages id='admin.input.required' />
                        },
                        {
                          validator: this.handleContactExistance
                        },
                        {
                          pattern: new RegExp(/^[0-9']+$/),
                          message: <IntlMessages id='admin.phonenumber.valid' />
                        }
                        // {
                        //   min: 8,
                        //   message: <IntlMessages id="admin.phonenumber.valid" />
                        // }
                      ],
                      initialValue: this.state.data.contactNo === null ? '' : this.state.data.contactNo
                    })(
                      <Input
                        maxLength={15}
                        // addonBefore={prefixSelector}
                        style={{ width: '100%' }}
                      />
                    )}
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                  <Form.Item label={<IntlMessages id='admin.userlisting.country' />}>
                    {getFieldDecorator('countryId', {
                      rules: [
                        {
                          required: true,
                          message: <IntlMessages id='admin.input.required' />
                        }
                      ],
                      initialValue: this.state.data.countryId
                    })(
                      <Select
                        showSearch
                        filterOption={(input, option) =>
                          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {this.state.countries.map(function(item) {
                          return (
                            <Option key={item.countryId} value={item.countryId}>
                              {item.countryName}
                            </Option>
                          );
                        })}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                  <Form.Item label={'Gender'}>
                    {getFieldDecorator('gender', {
                      rules: [
                        {
                          required: true,
                          message: <IntlMessages id='admin.input.required' />
                        }
                      ],
                      initialValue: this.state.data.gender
                    })(
                      <Select
                        showSearch
                        placeholder='Please select gender'
                        // style={{ height: "48px", marginBottom: "23px", fontSize: "15px" }}

                        filterOption={(input, option) =>
                          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {this.state.genderList.map(function(gender) {
                          return (
                            <Option key={gender.id} value={gender.id}>
                              {gender.name}
                            </Option>
                          );
                        })}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
              </Row>

              <Typography.Title level={3}>
                <IntlMessages id='admin.traveler.emergencycontact' />
              </Typography.Title>
              <Row gutter={24}>
                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                  <Form.Item {...formItemLayout} label={<IntlMessages id='admin.userlisting.firstName' />}>
                    {/* {this.state.data.emergencyFirstName} */}
                    {getFieldDecorator('emergencyFirstName', {
                      rules: [
                        {
                          //required: true,
                          //message: "Please input your first name!"
                        },
                        {
                          pattern: new RegExp(/^(\S.*)[a-zA-Z-.']+$/),
                          message: <IntlMessages id='admin.name.valid' />
                        }
                      ],
                      initialValue:
                        this.state.data.emergencyFirstName === null ? '' : this.state.data.emergencyFirstName
                    })(<Input maxLength={50} />)}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                  <Form.Item {...formItemLayout} label={<IntlMessages id='admin.userlisting.lastName' />}>
                    {getFieldDecorator('emergencyLastName', {
                      rules: [
                        {
                          // required: true,
                          // message: "Please input your last name!"
                        },
                        {
                          pattern: new RegExp(/^(\S.*)[a-zA-Z-.']+$/),
                          message: <IntlMessages id='admin.lname.valid' />
                        }
                      ],
                      initialValue: this.state.data.emergencyLastName === null ? '' : this.state.data.emergencyLastName
                    })(<Input maxLength={50} />)}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                  <Form.Item {...formItemLayout} label={<IntlMessages id='admin.manageTravellers.Realationship' />}>
                    {getFieldDecorator('emergencyRelationship', {
                      rules: [
                        {
                          //required: true,
                          pattern: new RegExp(/^(\S.*)[a-zA-Z-.']+$/)
                          // message: (
                          //   <IntlMessages id="admin.input.required"></IntlMessages>
                          // )
                        }
                      ],
                      initialValue:
                        this.state.data.emergencyRelationship === null ? '' : this.state.data.emergencyRelationship
                    })(<Input />)}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                  <Form.Item {...formItemLayout} label={<IntlMessages id='admin.userlisting.phonenumber' />}>
                    {getFieldDecorator('emergencyContactNo', {
                      rules: [
                        // {
                        //   //required: true,
                        //   //message: <IntlMessages id="admin.input.required" />
                        // },
                        // {
                        //  //validator: this.handleEmgergencyContactExistance
                        // }
                        {
                          pattern: new RegExp(/^[0-9']+$/),
                          message: 'Please enter a valid number!'
                        }
                      ],
                      initialValue:
                        this.state.data.emergencyContactNo === null ? '' : this.state.data.emergencyContactNo
                    })(
                      <Input
                        maxLength={15}
                        // addonBefore={prefixSelector}
                        style={{ width: '100%' }}
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Item>
                    <Button
                      type='primary'
                      style={{ display: 'inline-block', marginRight: '10px' }}
                      className='def-blue'
                      htmlType='submit'
                    >
                      {this.state.userId > 0 ? (
                        <IntlMessages id='admin.button.update' />
                      ) : (
                        <IntlMessages id='admin.button.add' />
                      )}
                    </Button>
                    {/* <Button
                    type="primary"
                    className=""
                    htmlType="button"
                    onClick={() => this.props.history.goBack()}
                  >
                    Back
                  </Button> */}
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Spin>
        </div>
        {/* Edit Traveler ends*/}
      </div>
    );
  }
}

EditTraveler.propTypes = {
  location: PropTypes.object,
  updateTravellers: PropTypes.func,
  travellerDataById: PropTypes.object,
  validateTravellerEmail: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    ...state.traveller,
    ...state.user,
    ...state.b2c,
    ...state.language,
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { ...travellerActions, ...b2b2cActions, ...userActions, ...AdminActions },
    dispatch
  );
}
const WrappedForm = Form.create({ name: "edit-traveler" })(EditTraveler);
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    GoogleApiWrapper({
      apiKey: "AIzaSyAA_ayr9aMRSAPIugacjp_CL6S5ux_N4is",
    })(WrappedForm)
  )
);
