import React from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import IntlMessages from "../../../services/intlMesseges";
import * as dayPackagesActions from "../../../redux/actions/day-packages-actions";
import AppLocale from "../../../languageProvider";
import { notifyUser } from "../../../services/notification-service";
import {
    Typography,
    Form,
    Input,
    Button,
    Row,
    Col,
    Spin
} from "antd";

class DayPackagesEdit extends React.Component {
    state = {
        loading: true,
        confirmDirty: false,
        dayPackageiD: 0,
        isActive: Boolean,
        title: "",
        description: "",
    };

    async componentDidMount() {
        this.setState({ isActive: true })

        if (
            this.props.match &&
            this.props.match &&
            this.props.match.params &&
            this.props.match.params.id &&
            this.props.match.params.id !== "new"
        ) {
            this.setState({ dayPackageiD: this.props.match.params.id });

            const user = await this.props.getDayPackages(this.props.match.params.id);
            this.setState({ isActive: user.isActive })

            //this.props.form.setFieldsValue(user);
            this.setState(user);

            this.setState({ loading: false });
        } else {
            this.setState({ loading: false });
        }
    }

    submitFormAdd = data => {

        this.props
            .updateDayPackagesData(data, this.state.dayPackageiD)
            .then(response => {
                if (response.error) {
                    notifyUser(response.error.message, 'error');
                    this.setState({ loading: false });
                } else {
                    const { language } = this.props;
                    const currentAppLocale = AppLocale[language.locale];
                    notifyUser(currentAppLocale.messages["admin.managedaypackage.success"], "success");
                    //notifyUser("Day Package added successfully!", 'success');
                    this.props.history.push("./");
                    this.setState({ loading: false });
                }
            })
            .catch(err => {
                this.setState({ loading: false });
            });
    };

    submitFormEdit = data => {

        this.props
            .updateDayPackagesData(data, this.props.match.params.id)
            .then(response => {
                if (response.error) {
                    notifyUser(response.error.message, 'error');
                    this.setState({ loading: false });
                } else {
                    const { language } = this.props;
                    const currentAppLocale = AppLocale[language.locale];
                    this.props.history.push("./");
                    this.setState({ loading: false });
                    notifyUser(currentAppLocale.messages["admin.managedaypackage.updatesuccess"], "success");
                    //notifyUser("Day Package updated successfully!", 'success');
                }
            })
            .catch(err => {
                this.setState({ loading: false });
            });
    };

    handleSubmit = e => {

        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                Object.keys(values).map(function(key, _idx) {
                    if(values[key] == null){
                      values[key] = '';
                    } else {
                      if(typeof values[key] == 'string'){
                        values[key] = values[key].trim();
                      }
                    }
                    return key;
                  });
                this.setState({ loading: true });
                values["isActive"] = this.state.isActive;


                if (this.state.dayPackageiD && this.state.dayPackageiD > 0) {
                    values["dayPackageiD"] = this.state.dayPackageiD;
                    // values["identityId"] = this.state.identityId;
                    this.submitFormEdit(values);
                } else {
                    this.submitFormAdd(values);
                }
            }
        });
    };
    onSwitchChange = (checked, e) => {
        this.setState({ isActive: checked });
    };
    render() {
        const { getFieldDecorator } = this.props.form;
        const { formLayout } = this.state;
        const formItemLayout =
            formLayout === "horizontal"
                ? {
                    labelCol: { span: 4 },
                    wrapperCol: { span: 14 }
                }
                : null;

        return (
            <div>
                <Row gutter={24}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Typography.Title level={4}>
                        {this.state.dayPackageiD === 0 ? (
                            <IntlMessages id="admin.manageTiers.addDayPackages" />
                        ) : (
                                <div>
                                    <IntlMessages id="admin.manageTiers.editDayPackages" />
                                </div>
                            )}
                        </Typography.Title>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Button
                        type="primary"
                        className="right-fl"
                        htmlType="button"
                        onClick={() => this.props.history.push("./")}
                        >
                        <IntlMessages id="admin.userlisting.back" />
                        </Button>
                    </Col>
                </Row>
                <hr />
                <div>
                    <Spin spinning={this.state.loading}>
                        <Form layout={formLayout} onSubmit={this.handleSubmit}>
                           
                                <Row gutter={24}>

                                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                                    <Form.Item
                                        {...formItemLayout}
                                        label={<IntlMessages id="admin.userlisting.days" />}
                                    >
                                        {getFieldDecorator("days", {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: <IntlMessages id="admin.input.required" />
                                                }
                                            ],
                                            initialValue: this.state.days === null ? "" : this.state.days
                                        })(<Input />)}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                                    <Form.Item
                                        {...formItemLayout}
                                        label={<IntlMessages id="admin.userlisting.description" />}
                                    >
                                        {getFieldDecorator("description", {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: <IntlMessages id="admin.input.required" />
                                                }
                                            ],
                                            initialValue: this.state.description === null ? "" : this.state.description
                                        })(<Input />)}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Item>
                                        <Button
                                            type="primary"
                                            style={{ display: "inline-block", marginRight: "10px" }}
                                            className="def-blue"
                                            htmlType="submit"
                                        >
                                            {this.state.dayPackageiD > 0 ? <IntlMessages id="admin.userlisting.update" /> : 'Add'}
                                        </Button>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </Spin>
                </div>
            </div>
        );
    }
}

DayPackagesEdit.propTypes = {
    location: PropTypes.object,
    updateDayPackagesData: PropTypes.func,
    tiersDataById: PropTypes.object
};

function mapStateToProps(state) {
    return {
        ...state.tiers,
        ...state.language
        // ...state.user
    };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({ ...dayPackagesActions }, dispatch);
}
const WrappedForm = Form.create({ name: "edit-dayPackages" })(DayPackagesEdit);
export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps,
        null,
        { forwardRef: true }
    )(WrappedForm)
);
