import React, { useEffect, useState } from 'react';

import logo from '../../images/logo.png';
import config from '../../../../config';

const acceptCignaStatus = (props) => {
  const [msg, setMes] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(`${config.API1}/api/approvecreateuser/${props.match.params.id}`);
      const res = await response.json();
      setMes((res && res.data && res.data.message) || (res.error && res.error.message));
    };

    fetchData();
  }, [props.match.params.id]);

  console.log(props.match.params.id);
  return (
    <div>
      <header id='headerb2b2c'>
        <div className='headerb2b2c-inner'>
          <div className='wrap'>
            <section className='top-header'>
              <div className='row'>
                <div className={'logo col-md-12 col-md-12  text-center'}>
                  <img src={logo} alt='CAP™' height='90' width='118' />
                </div>
              </div>
            </section>
          </div>
        </div>
      </header>
      <br /> <br />{' '}
      <div style={{ textAlign: 'center', margin: '30px' }}>
        <h3>{msg}</h3>
      </div>
    </div>
  );
};

export default acceptCignaStatus;
