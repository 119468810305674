import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import IntlMessages from "../../../../services/intlMesseges";
import * as Permission from "../../../../services/permissions";
import AppLocale from "../../../../languageProvider";
import {
  Typography,
  Form,
  Input,
  DatePicker,
  Row,
  Col,
  Select,
  Table,
  Button,
  Icon,
  Transfer,
  Modal,
  Spin,
  TreeSelect,
} from "antd";

import moment from "moment";
import API from "../../../../redux/api/admin-api";
import * as productActions from "../../../../redux/actions/product-actions";
import * as OrgActions from "../../../../redux/actions/organization-actions";
import { notifyUser } from "../../../../services/notification-service";

const { Option } = Select;
const dateFormat = "MM/DD/YYYY";
const dateFormatY = "YYYY/MM/DD";
var finalData = [];

class OrganizationPlan extends Component {
  constructor(props) {
    super(props);
    this.textInput = React.createRef();

    this.addnewProduct = this.addnewProduct.bind(this);
    this.saveproduct = this.saveproduct.bind(this);
    this.showModal = this.showModal.bind(this);
    this.onPlanChange = this.onPlanChange.bind(this);
    this.backpage = this.backpage.bind(this);
    this.state = {
      mockData: [],
      countriesdropdown: [],
      productData: [],
      pccData: [],
      hasTravelManager: false,
      selectedKeys: [],
      productInfo: {},
      endDate: "",
      // loading: true,
      data: [],
      pagination: {
        pageSize: 10,
        current: 1,
        hideOnSinglePage: true,
        showTotal: (total, range) => {
          return (
            <span>
              <IntlMessages id="admin.text.showing" /> {range[0]}-{range[1]}{" "}
              <IntlMessages id="admin.text.of" /> {total}{" "}
              <IntlMessages id="admin.text.results" />
            </span>
          );
        },
      },
      filters: {},
      formLayout: undefined,
      orgdata: {},
      addnew: false,
      formLayoutaddPlan: undefined,
      index_array: null,
      accountpcc: [],
      modelName: null,
      postpriceedit: null,
      pccids: [],
      savedproductcheck: false,
      selectedplan: null,
      disableallproductfields: true,
      dataAccount: [],
      dataAccountCopy: [],
      pccaccountnumber: "",
    };
  }
  async componentDidMount() {
    let countriesListTemp = await API.getCountriesList();
    this.setState({ countriesdropdown: countriesListTemp.countries });
    if (
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.id &&
      this.props.match.params.id !== "new"
    ) {
      this.setState({ loading: true });
      this.props
        .getOrganizationId(this.props.match.params.id)
        .then((resp) => {
          let orgAccounts = [];
          for (var m = 0; m < resp.data.orgPCCAccounts.length; m++) {
            orgAccounts.push({
              account: resp.data.orgPCCAccounts[m].pccAccountNumber,
              key: "" + resp.data.orgPCCAccounts[m].pccAccountID,
            });
          }
          this.setState({ dataAccount: orgAccounts });
          this.setState({ dataAccountCopy: orgAccounts });
          this.setState({ dataAccountCopyDraft: resp.data.orgPCCAccounts });

          var datasb = [];

          for (
            var a = 0;
            a < resp.data.orgPCCAccounts.map((info) => info.pccid).length;
            a++
          ) {
            if (
              datasb.includes(
                resp.data.orgPCCAccounts.map((info) => info.pccid)[a]
              )
            ) {
            } else {
              datasb.push(
                resp.data.orgPCCAccounts.map((info) => info.pccid)[a]
              );
              this.setState({ pccids: datasb });
              this.onchangePcc(datasb);
            }
          }
          this.setState(resp.data);
          this.setState({
            loading: false,
            productInfo: resp.data.productInfo,
            endDate: moment(new Date(resp.data.coverageEndDate)).format(
              dateFormat
            ),
          });
          this.props
            .getProducts({})
            .then((respprod) => {
              let values = respprod.data;
              this.setState({
                productData: values,
              });

              finalData = resp.data.productInfo.map((it1) => {
                it1.name = respprod.data.find(
                  (it2) => it2.productId === it1.productID
                ).name;
                return it1;
              });
              this.handleChangeTransfer(
                resp.data.orgPCCAccounts.map((data) => "" + data.pccAccountID)
              );
            })
            .catch((ex) => {
              this.setState({ loading: false });
            });
        })
        .catch((ex) => {
          this.setState({ loading: false });
        });
    }
    this.getBillableOrg(this.props.match.params.id);
    // this.getMock();
    // get PCC DATA on INIT
    this.props
      .getPccdata({})
      .then((resp) => {
        let data = resp.data;
        this.setState({
          loading: false,
          pccData: data,
        });
      })
      .catch((ex) => {
        this.setState({ loading: false });
      });
  }
  // show modal popup
  showModal = (data, name, price) => {
    this.setState({ index_array: data });
    this.setState({
      visible: true,
      modelName: name,
      postpriceedit: +price,
    });
  };

  handleOk = (e) => {
    let data_array = finalData;
    data_array[this.state.index_array].postSalePricePerLicense = parseInt(
      this.textInput.current.state.value
    );

    this.setState({
      visible: false,
      productInfo: data_array,
    });
    this.textInput.current.state.value = null;
  };
  handleCancel = (e) => {
    this.setState({
      visible: false,
    });
    this.textInput.current.state.value = null;
  };
  filterOption = (inputValue, option) =>
    option.description.indexOf(inputValue) > -1;

  handleChange = (targetKeys) => {
    this.setState({ targetKeys });
  };

  handleSearch = (dir, value) => {};

  async onchangePcc(e) {
    var orgAccounts = [];
    if (e.length === 0) {
      this.setState({ dataAccount: [], targetKeys: [] });
    }
    var dataaccount = [];
    for (var i = 0; i < e.length; i++) {
      var usedraft = [];
      var toto = e[i];
      usedraft = usedraft.concat(
        this.state.dataAccountCopyDraft.filter((data) => data.pccid === toto)
      );
      for (var m = 0; m < usedraft.length; m++) {
        orgAccounts.push({
          account: usedraft[m].pccAccountNumber,
          key: "" + usedraft[m].pccAccountID,
        });
      }
      if (usedraft.length > 0) {
        dataaccount = dataaccount.concat(
          usedraft.map((item) => ({
            account: item.pccAccountNumber,
            key: "" + JSON.stringify(item.pccAccountID),
          }))
        );
      }
      this.props.getPccAccountData(toto).then((resp) => {
        if (resp.data.length > 0) {
          dataaccount = dataaccount.concat(
            resp.data.map((item) => ({
              account: item.pccaccountNumber,
              key: "" + item.pccAccountId,
            }))
          );
        }
        if (this.state.targetKeys) {
          this.setState({
            targetKeys: dataaccount
              .map((info) => info.key)
              .filter((value) => -1 !== this.state.targetKeys.indexOf(value)),
          });
        }

        this.setState({ dataAccount: dataaccount });
      });
    }
    if (dataaccount.length === 0) {
      this.setState({ pccaccountnumber: [] });
    }
  }
  handleChangeTransfer = (targetKeys) => {
    this.setState({ targetKeys });
    var data = [];
    if (targetKeys.length === 0) {
      this.setState({ pccaccountnumber: data });
    }
    for (var i = 0; i < targetKeys.length; i++) {
      data.push({ pccAccountID: +targetKeys[i] });
      this.setState({ pccaccountnumber: data });
    }
  };
  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <div style={{ padding: 8 }}>
          <Input
            ref={(node) => {
              this.searchInput = node;
            }}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? e.target.value.trimLeft() : "")
            }
            onPressEnter={() =>
              this.handleSearch(
                selectedKeys,
                confirm,
                dataIndex,
                clearFilters,
                setSelectedKeys
              )
            }
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Button
            type="primary"
            onClick={() =>
              this.handleSearch(
                selectedKeys,
                confirm,
                dataIndex,
                clearFilters,
                setSelectedKeys
              )
            }
            icon="search"
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters, dataIndex)}
            size="small"
            style={{ width: 90 }}
          >
            Resets
          </Button>
        </div>
      );
    },
    filterIcon: (filtered) => (
      <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
    ),

    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
  });

  getHeaderKeys = () => {
    return [
      {
        title: <IntlMessages id="admin.manageOrders.ProductName" />,
        dataIndex: "name",
        width: "15%",
      },

      {
        title: <IntlMessages id="admin.organization.Numberoflicence" />,
        dataIndex: "productQty",
        width: "15%",
      },
      {
        title: <IntlMessages id="admin.org.usedPlans" />,
        dataIndex: "planConsumed",
        width: "15%",
      },
      {
        title: <IntlMessages id="admin.org.unusedPlans" />,
        // dataIndex: "productQty",
        width: "15%",
        render(data, record) {
          return record.productQty - record.planConsumed;
        },
      },
    ];
  };

  addnewProduct() {
    let _productInfo = this.state.productInfo;
    _productInfo.productDealPrice = null;
    _productInfo.postSalePricePerLicense = null;
    _productInfo.postSalePrice = null;
    _productInfo.productQty = null;
    _productInfo.productID = null;
    this.setState({
      addnew: true,
      productInfo: _productInfo,
    });
  }

  cancelsaveproduct = () => {
    this.setState({ addnew: false });
  };
  // save and update product
  saveproduct() {
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.setState({ loading: true });

        var data = {
          postSalePricePerLicense: 0,
          productDealPrice: +values.dealprice,
          productID: +values.membership_type,
          productQty: +values.licence_number,
          productRetailPrice: +values.price,
          planConsumed: 0,
          name: this.state.productData.filter(
            (data) => data.productId === values.membership_type
          )[0].name,
          orgID: this.props.match.params.id,
          billingOrgID:
            typeof values.organizationId === "undefined"
              ? this.state.organizationId
              : values.organizationId,
        };
        var form_data = {
          orgID: this.props.match.params.id,
          billingOrgID:
            typeof values.organizationId === "undefined"
              ? this.state.organizationId
              : values.organizationId,
          productInfo: {
            postSalePricePerLicense: 0,
            productDealPrice: +values.dealprice,
            productID: +values.membership_type,
            productQty: +values.licence_number,
            productRetailPrice: +values.price,
          },
        };
        this.props.addorderinOrganizationwithid(form_data).then((resp) => {
          if (resp) {
            if (
              finalData
                .map((data) => data.name)
                .includes(
                  this.state.productData.filter(
                    (data) => data.productId === values.membership_type
                  )[0].name
                )
            ) {
              finalData.filter(
                (data) => data.productID === values.membership_type
              )[0].productQty =
                parseFloat(values.licence_number) +
                parseFloat(
                  finalData.filter(
                    (data) => data.productID === values.membership_type
                  )[0].productQty
                );
              finalData.filter(
                (data) => data.productID === values.membership_type
              )[0].productDealPrice =
                parseFloat(values.dealprice) +
                parseFloat(
                  finalData.filter(
                    (data) => data.productID === values.membership_type
                  )[0].productDealPrice
                );

              this.props
                .getOrganizationId(this.props.match.params.id)
                .then((resp) => {
                  this.setState({ loading: false });
                  notifyUser("Plan Updated successfully!", "success");
                });
            } else {
              this.props
                .getOrganizationId(this.props.match.params.id)
                .then((resp) => {
                  finalData.push(data);
                  this.setState({ loading: false });

                  notifyUser("Plan Added successfully!", "success");
                });
            }

            let _productInfo = this.state.productInfo;
            _productInfo.productDealPrice = null;
            _productInfo.postSalePricePerLicense = null;
            _productInfo.postSalePrice = null;
            _productInfo.productQty = null;
            _productInfo.productID = null;
            this.setState({
              addnew: false,
              disableallproductfields: true,
              productInfo: _productInfo,
            });
          }
        });
      }
    });
  }
  handleformsubmit() {
    this.props.form.validateFieldsAndScroll((err, values) => {
      var JSON_ORG_UPDATE = {
        organizationId: parseInt(this.props.match.params.id),
        name: this.state.name,
        code: this.state.code,
        phoneNumber: this.state.phoneNumber,
        membershipNumber: this.state.membershipNumber,
        orgEmail: this.state.orgEmail,
        address1: this.state.address1,
        state: this.state.state,
        city: this.state.city,
        countryId: this.state.countryId,
        zipCode: this.state.zipCode,
        fpManagerID: 0,
        fpSalesExecutiveID: 0,
        isBusinessAddressSame: true,
        isCommunicationAddressSame: true,
        coverageStartDate: this.state.coverageStartDate,
        coverageEndDate: this.state.endDate,
        countries: this.state.countries,
        productInfo: finalData,
        creditDaysLimit: values.CreditDaysLimit,
        orgContacts: [
          {
            addressTypeID: 2,
            address: this.state.orgContacts[0].address,
            state: this.state.orgContacts[0].state,
            city: this.state.orgContacts[0].city,
            zipCode: this.state.orgContacts[0].zipCode,
            countryId: this.state.orgContacts[0].countryId,
            contacts: this.state.orgContacts[0].contacts,
            contactPerson: this.state.orgContacts[0].contactPerson,
            emailID: this.state.orgContacts[0].emailID,
          },
          {
            addressTypeID: 3,
            address: this.state.orgContacts[1].address,
            state: this.state.orgContacts[1].state,
            city: this.state.orgContacts[1].city,
            zipCode: this.state.orgContacts[1].zipCode,
            countryId: this.state.orgContacts[1].countryId,
            contacts: this.state.orgContacts[1].contacts,
            contactPerson: this.state.orgContacts[1].contactPerson,
            emailID: this.state.orgContacts[1].emailID,
          },
        ],
        orgPCCAccounts: this.state.pccaccountnumber,
      };
      /* if (this.state.pccaccountnumber.length > 0
        && values.pcc_number.length > 0
        && this.state.dataAccount.length > 0 &&
        this.state.targetKeys.length > 0 &&
        this.state.dataAccount.map(info => (info.key)).filter(value => -1 !== this.state.targetKeys.indexOf(value)).length > 0
      ) { */

      if (!err) {
        this.setState({ loading: true });
        this.props
          .updateOrganizationwithId(JSON_ORG_UPDATE, this.props.match.params.id)
          .then((response) => {
            if (response.error) {
              this.setState({ loading: false });
              notifyUser(
                "PCCAccount code already in use by another organization!",
                "error"
              );
            } else {
              const { language } = this.props;
              const currentAppLocale = AppLocale[language.locale];
              notifyUser(
                currentAppLocale.messages["admin.organization.edit"],
                "success"
              );
              // notifyUser("Organization Updated successfully!", "success");
              this.props.history.push("/admin/organizations/");
              this.setState({ loading: false });
            }
          });
      }

      /*  } else {
        this.setState({ pccaccountnumber: [], targetKeys: [] })
        notifyUser("Please add atleast one account number!", "error");

      } */
    });
  }
  // on plann change checks
  onPlanChange(e) {
    if (finalData.map((data) => data.productID).includes(e)) {
      this.setState({ selectedplan: e });
      let _productInfo = this.state.productInfo;
      _productInfo.postSalePricePerLicense = finalData.filter(
        (info) => info.productID === e
      )[0].postSalePricePerLicense;
      this.setState({
        savedproductcheck: true,
        productInfo: _productInfo,
        disableallproductfields: false,
      });
    } else {
      this.setState({
        savedproductcheck: false,
        disableallproductfields: false,
      });
    }
  }
  // on Plan value change calculate prices
  onNoofPlansChange(e) {
    let _productInfo = this.state.productInfo;
    // if (this.state.selectedplan !== undefined && this.state.selectedplan !== null) {
    //   _productInfo.productRetailPrice = parseInt(finalData.filter(info => (info.productID === this.state.selectedplan))[0].postSalePricePerLicense) * parseInt(e.target.value)
    //   _productInfo.productDealPrice = parseInt(finalData.filter(info => (info.productID === this.state.selectedplan))[0].postSalePricePerLicense) * parseInt(e.target.value)

    // }
    this.setState({
      productInfo: _productInfo,
    });
  }
  backpage() {
    this.props.history.push(
      "/admin/organizations/edit/" + this.props.match.params.id
    );
  }
  limitfunction = (rule, value, callback) => {
    if (parseInt(value) < 0 || parseInt(value) > 365) {
      callback("Credit Limit Must be Between 0 and 365");
    }
    callback();
  };

  onChangeOrganizationTree = (_selected) => {};

  getBillableOrg = (orgId) => {
    let _this = this;
    this.props.getBillableOrganizations(orgId).then((resp) => {
      _this.props.getDivisionsList(orgId).then((resp2) => {
        let parentId;
        for (var i in resp2.data) {
          if (resp2.data[i].organizationId == orgId) {
            parentId = resp2.data[i].parentOrgId;
          }
        }
        let list = this.getNestedChildren(resp2.data, parentId, resp.data);
        this.setState({
          billableDivisionList: list,
        });
      });
    });
  };

  getNestedChildren = (arr, parent, billableOrgs) => {
    var out = [];
    for (var i in arr) {
      if (arr[i].parentOrgId == parent) {
        var children = this.getNestedChildren(
          arr,
          arr[i].organizationId,
          billableOrgs
        );
        if (children.length) {
          arr[i].children = children;
        }
        arr[i].title = arr[i].name;
        arr[i].value = arr[i].organizationId;
        arr[i].label = arr[i].name;
        arr[i].key = arr[i].organizationId;
        let idx = billableOrgs
          ? billableOrgs.findIndex(
              (e) => e.organizationId == arr[i].organizationId
            )
          : -1;
        if (idx === -1) {
          arr[i].disabled = true;
        } else {
          if (billableOrgs[idx]) {
            arr[i].title = billableOrgs[idx].name;
          } else {
            arr[i].title = arr[i].name;
          }
        }
        if (!arr[i].disabled) {
          out.push(arr[i]);
        }
      }
    }
    return out;
  };
  render() {
    const { getFieldDecorator } = this.props.form;
    const { formLayout } = this.state;
    const { formLayoutaddPlan } = this.state.productInfo;
    const formItemLayout =
      formLayout === "horizontal"
        ? {
            labelCol: { span: 4 },
            wrapperCol: { span: 14 },
          }
        : null;
    return (
      <div>
        <Row gutter={24}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Typography.Title level={4}>
              <IntlMessages id="admin.organization.planId" />:{" "}
              {this.state.membershipNumber}
            </Typography.Title>
          </Col>
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={12}
            style={{ textAlign: "right" }}
          >
            <Button type="primary" onClick={this.backpage}>
              <IntlMessages id="admin.editorganization.back" />
            </Button>
          </Col>
        </Row>
        <hr />
        <Spin spinning={this.state.loading}>
          <Form layout={formLayout}>
            <Row gutter={24}>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Form.Item
                  label={<IntlMessages id="admin.organizations.countries" />}
                >
                  {getFieldDecorator("org_country", {
                    rules: [
                      {
                        required: true,
                        message: <IntlMessages id="admin.input.required" />,
                      },
                    ],
                    initialValue: this.state.countries,
                  })(
                    <Select style={{ width: "100%" }} disabled mode="multiple">
                      {this.state.countriesdropdown.map(function(item) {
                        return (
                          <Option
                            key={"" + item.countryId}
                            value={"" + item.countryId}
                          >
                            {item.countryName}
                          </Option>
                        );
                      })}
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Form.Item
                  {...formItemLayout}
                  label={<IntlMessages id="admin.organization.Startdate" />}
                >
                  {getFieldDecorator("startDate", {
                    rules: [
                      {
                        required: true,
                        message: (
                          <IntlMessages id="admin.input.required"></IntlMessages>
                        ),
                      },
                    ],
                    initialValue: moment(
                      this.state.coverageStartDate,
                      dateFormatY
                    ),
                  })(<DatePicker disabled format={dateFormat} />)}
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Form.Item
                  {...formItemLayout}
                  label={<IntlMessages id="admin.organization.Enddate" />}
                >
                  {getFieldDecorator("endDate", {
                    rules: [
                      {
                        required: true,
                        message: (
                          <IntlMessages id="admin.input.required"></IntlMessages>
                        ),
                      },
                    ],
                    initialValue: this.state.endDate,
                  })(<Input disabled />)}
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={24} style={{ marginBottom: "40px" }}>
              {Permission.isRole(["Administrator", "CSR"]) ? (
                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                  <Form.Item
                    {...formItemLayout}
                    label={<IntlMessages id="admin.organization.creditdays" />}
                  >
                    {getFieldDecorator("CreditDaysLimit", {
                      rules: [
                        {
                          required: true,
                          message: (
                            <IntlMessages id="admin.input.required"></IntlMessages>
                          ),
                        },
                        {
                          pattern: new RegExp(/^[.0-9']+$/),
                          message: (
                            <IntlMessages id="admin.creditlimit.valid"></IntlMessages>
                          ),
                        },
                        {
                          validator: this.limitfunction,
                          message: (
                            <IntlMessages id="admin.creditlimit.valid"></IntlMessages>
                          ),
                        },
                      ],
                      initialValue:
                        this.state.creditDaysLimit === null
                          ? ""
                          : this.state.creditDaysLimit,
                      value: this.state.creditDaysLimit,
                    })(
                      <Input
                        maxLength={3}
                        // placeholder="Please Enter Credit Days Limit"
                      />
                    )}
                  </Form.Item>
                </Col>
              ) : (
                ""
              )}
              {Permission.isRole([
                "Administrator",
                "CSR",
                "Admin",
                "Travel Manager",
              ]) ? (
                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                  <Form.Item
                    {...formItemLayout}
                    label={<IntlMessages id="admin.organization.pccnumber" />}
                  >
                    {getFieldDecorator("pcc_number", {
                      rules: [
                        // {
                        //   required: true,
                        //   message: <IntlMessages id ="admin.input.required"></IntlMessages>
                        // }
                      ],
                      onChange: (e) => {
                        this.onchangePcc(e);
                      },
                      value: this.state.pccids,

                      initialValue: this.state.pccids,
                    })(
                      // pccdata
                      <Select
                        disabled={
                          Permission.isRole(["Administrator", "CSR"])
                            ? false
                            : true
                        }
                        mode="multiple"
                        style={{ width: "100%" }}
                        showSearch
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {this.state.pccData.map(function(item) {
                          return (
                            <Option key={item.pccId} value={item.pccId}>
                              {item.code}
                            </Option>
                          );
                        })}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
              ) : (
                ""
              )}

              {Permission.isRole([
                "Administrator",
                "CSR",
                "Admin",
                "Travel Manager",
              ]) ? (
                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                  <Form.Item
                    {...formItemLayout}
                    label={
                      <IntlMessages id="admin.organization.accountnumber" />
                    }
                  >
                    {getFieldDecorator(
                      "account_number",
                      {}
                    )(
                      <Transfer
                        disabled={
                          Permission.isRole(["Administrator", "CSR"])
                            ? false
                            : true
                        }
                        dataSource={this.state.dataAccount}
                        filterOption={this.filterOption}
                        targetKeys={this.state.targetKeys}
                        onChange={this.handleChangeTransfer}
                        onSearch={this.handleSearch}
                        render={(item) => item.account}
                      />
                    )}
                  </Form.Item>
                </Col>
              ) : (
                ""
              )}
            </Row>
            {Permission.isRole("Administrator") || Permission.isRole("CSR") ? (
              <Button type="primary" onClick={() => this.handleformsubmit()}>
                <IntlMessages id="admin.organization.submit" />
              </Button>
            ) : (
              ""
            )}
            <br />
            <hr />
          </Form>

          {Permission.isRole("Administrator") || Permission.isRole("CSR") ? (
            <Row gutter={24} style={{ margin: "20px 0" }}>
              <Col xs={24}>
                {!this.state.addnew === true ? (
                  <Button className="right-fl" onClick={this.addnewProduct}>
                    {" "}
                    <IntlMessages id="admin.organization.addplan" />
                  </Button>
                ) : (
                  ""
                )}
              </Col>
            </Row>
          ) : (
            ""
          )}
          {this.state.addnew === true ? (
            <form layout={formLayoutaddPlan}>
              <Row gutter={24} style={{ margin: "20px 0" }}>
                <Col xs={24}>
                  <Typography.Title level={4}>Add new plan</Typography.Title>
                </Col>
              </Row>
              <hr />
              <Row gutter={24} style={{ marginBottom: "20px" }}>
                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                  <Form.Item
                    {...formItemLayout}
                    label={<IntlMessages id="admin.invoice.billto" />}
                  >
                    {getFieldDecorator("organizationId")(
                      <TreeSelect
                      initialValue={this.state.organizationId}
                        treeData={this.state.billableDivisionList}
                        style={{ width: "100%" }}
                        dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                        onChange={this.onChangeOrganizationTree}
                        placeholder="Please select"
                        treeDefaultExpandAll
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                  <Form.Item
                    label={<IntlMessages id="admin.organizations.plantype" />}
                  >
                    {getFieldDecorator("membership_type", {
                      rules: [
                        {
                          required: true,
                          message: "Please select Plan!",
                        },
                      ],
                      initialValue: this.state.productInfo.productID,
                    })(
                      <Select
                        style={{ width: "100%" }}
                        disabled={!this.state.addnew}
                        onChange={this.onPlanChange}
                      >
                        {this.state.productData.map(function(item) {
                          return item.productCode == "CAP" ? (
                            <Option key={item.productId} value={item.productId}>
                              {item.name}
                            </Option>
                          ) : (
                            ""
                          );
                          // return (
                          //   <Option
                          //     key={item.productId}
                          //     value={item.productId}
                          //   >
                          //     {item.name}
                          //   </Option>
                          // );
                        })}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                  <Form.Item
                    {...formItemLayout}
                    label={
                      <IntlMessages id="admin.organization.NumberofPlans" />
                    }
                  >
                    {getFieldDecorator("licence_number", {
                      rules: [
                        {
                          required: true,
                          message: (
                            <IntlMessages id="admin.input.required"></IntlMessages>
                          ),
                        },
                        {
                          pattern: new RegExp(/^[0-9']+$/),
                          message: (
                            <IntlMessages id="admin.plannumber.valid"></IntlMessages>
                          ),
                        },
                      ],
                      initialValue:
                        this.state.productInfo.productQty === null
                          ? ""
                          : this.state.productInfo.productQty,
                      onChange: (e) => {
                        this.onNoofPlansChange(e);
                      },
                      value: this.state.productInfo.productQty,
                    })(
                      <Input
                        disabled={
                          !this.state.addnew ||
                          this.state.disableallproductfields
                        }
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                  <Form.Item
                    {...formItemLayout}
                    label={<IntlMessages id="admin.organization.retailprice" />}
                  >
                    {getFieldDecorator("price", {
                      rules: [
                        {
                          required: true,
                          message: (
                            <IntlMessages id="admin.input.required"></IntlMessages>
                          ),
                        },
                        {
                          pattern: new RegExp(/^[.0-9']+$/),
                          message: (
                            <IntlMessages id="admin.retailnumber.valid"></IntlMessages>
                          ),
                        },
                      ],
                      initialValue:
                        this.state.productInfo.productRetailPrice === null
                          ? ""
                          : this.state.productInfo.productRetailPrice,
                      onChange: (e) => {},
                      value: this.state.productInfo.productRetailPrice,
                    })(
                      <Input
                        disabled={
                          !this.state.addnew ||
                          this.state.disableallproductfields
                        }
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                  <Form.Item
                    {...formItemLayout}
                    label={<IntlMessages id="admin.organization.dealprice" />}
                  >
                    {getFieldDecorator("dealprice", {
                      rules: [
                        {
                          required: true,
                          message: (
                            <IntlMessages id="admin.input.required"></IntlMessages>
                          ),
                        },
                        {
                          pattern: new RegExp(/^[.0-9']+$/),
                          message: (
                            <IntlMessages id="admin.dealprice.valid"></IntlMessages>
                          ),
                        },
                      ],
                      initialValue:
                        this.state.productInfo.productDealPrice === null
                          ? ""
                          : this.state.productInfo.productDealPrice,
                      onChange: (e) => {},
                      value: this.state.productInfo.productDealPrice,
                    })(
                      <Input
                        disabled={
                          !this.state.addnew ||
                          this.state.disableallproductfields
                        }
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24} style={{ margin: "20px 0" }}>
                <Col xs={24}>
                  {this.state.addnew === true ? (
                    <div>
                      <Button type="primary" onClick={this.saveproduct}>
                        Save Plan
                      </Button>
                      &nbsp;&nbsp;
                      <Button type="" onClick={this.cancelsaveproduct}>
                        Cancel
                      </Button>
                    </div>
                  ) : (
                    ""
                  )}
                </Col>
              </Row>
            </form>
          ) : (
            ""
          )}
          <Table
            columns={this.getHeaderKeys()}
            rowKey={(record) => record.productID}
            dataSource={finalData}
            pagination={this.state.pagination}
            //loading={this.state.loading}
            //onChange={this.handleTableChange}
            scroll={{ x: "100%" }}
          />
        </Spin>
        <Modal
          title="Basic Modal"
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
        >
          <p>Plan Name :-{this.state.modelName}</p>
          <p>Post Sale Price/Plan :-</p>{" "}
          <Input
            type="number"
            initialValue={this.state.postpriceedit}
            //placeholder="Enter Post Sale Price/Plan (In USD)"
            ref={this.textInput}
          />
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return { ...state.language };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...productActions, ...OrgActions }, dispatch);
}
const WrappedForm = Form.create({ name: "organization-plans" })(
  OrganizationPlan
);
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    WrappedForm
  )
);
