import Axios from "../../services/axios-service-tracking";
const getCountryReport = data => {
  return Axios.get("api/countryreport/" + data.countryCode);
};
const getCitiesList = data => {
  return Axios.get("api/city/CityList/" + data.countryCode);
};
const getCityReport = data => {
  return Axios.get("api/city/CityDetail/" + data.cityCode);
};
const risklineAlerts = data => {
  return Axios.get(
    "/api/risklineAlert?search=" + encodeURIComponent(JSON.stringify(data))
  );
};
const getAdvisories = data => {
  return Axios.get("api/advisory/" + data.countryCode);
};
const getCovidData = data => {
    return Axios.get("api/risklineAlert/covid/" + data.countryCode);
};

const getCovidCountryRegion = data => {
  return Axios.get("api/risklineAlert/covid/" + data.countryCode +"/regions");
};

const getCovidCountryCities = data => {
  return Axios.get("api/risklineAlert/covid/" + data.countryCode +"/cities");
};
const risklineAlertsFilter = data => {
  return Axios.get(
    "/api/risklineAlert/filterByDateRange?search=" +
      encodeURIComponent(JSON.stringify(data))
  );
};
const advisoriesFilter = data => {
  return Axios.get(
    "api/advisory/?search=" + encodeURIComponent(JSON.stringify(data))
  );
};
const getRiskLineCategories = () => {
  return Axios.get("api/categoryList");
};
const getAdvisoryCoordinatesById = data => {
  return Axios.get(
    "api/advisory/advisoryDetail/" + data.id + "?coordinates=true"
  );
};

export default {
  getCountryReport,
  getCitiesList,
  getCityReport,
  risklineAlerts,
  getAdvisories,
  getCovidData,
  risklineAlertsFilter,
  advisoriesFilter,
  getRiskLineCategories,
  getAdvisoryCoordinatesById,
  getCovidCountryRegion,
  getCovidCountryCities
};
