import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Row, Col, Checkbox, Table } from "antd";

class UserMapPerson extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      isPerson: true
    };
  }

  getHeaderKeys = () => {
    return [
      {
        title: "Name",
        dataIndex: "name",
        render: (text, record) => (
          <a onClick={() => this.handleTravlerClick(record)}>
            {record.travelerName}
          </a>
        )
      }
    ];
  };

  componentDidMount() {}

  update = e => {
    this.props.onChangePersonCheckBox(e);
  };

  handleTravlerClick(record) {
    this.props.handlePersonClick(record);
  }

  render() {
    return (
      <div>
        <Row gutter={24} style={{ marginBottom: "10px" }}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Checkbox
              name="person"
              defaultChecked={this.state.isPerson}
              style={{
                display: "inline-block",
                verticalAlign: "middle",
                marginRight: "5px"
              }}
              onChange={this.update}
            />
            <span
              style={{
                display: "inline-block",
                verticalAlign: "middle",
                marginLeft: "5px"
              }}
            >
              Traveler
            </span>
          </Col>
        </Row>
        <Row gutter={24} sm={24} md={12} lg={12} xl={12}>
          {this.props.persons && this.props.persons.length > 0 && (
            <Table
              pagination={{ defaultPageSize: 5 }}
              columns={this.getHeaderKeys()}
              dataSource={this.props.persons}
            />
          )}
        </Row>
      </div>
    );
  }
}

UserMapPerson.propTypes = {};

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    UserMapPerson
  )
);
