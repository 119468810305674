import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as travelerItineraryActions from "../../../../redux/actions/travelers-itinerary-action";
import moment from "moment";
import { Tooltip } from "antd";

const dateFormat = "MM/DD/YYYY HH:mm";
const orgDateFormat = "HH:mm";

let flightStatus = {
  L: "LANDED",
  LANDED: "LANDED",
  SCHEDULED: "SCHEDULED",
  S: "SCHEDULED",
  A: "In-Transit",
};

let travelerItineraries = {};
let hotelCount = 0;
let carCount = 0;
let flightCount = 0;

class TravelerItineraryViewer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataLoaded: false,
      travelerEmail: "",
      pnrId: 0,
      itineraryDetails: "",
      itineraryHeader: "",
      itineraryOrganization: "",
      itineraryRemarks: "",
      itineraryCars: "",
      userDetail: {},
      emailaddress: [],
    };
  }
  componentDidMount() {
    if (
      this.props.match.params &&
      this.props.match.params.email &&
      this.props.match.params.pnrId
    ) {
      this.props
        .getItineraryByPnrIdAndEmail({
          email: this.props.match.params.email,
          pnrId: this.props.match.params.pnrId,
        })
        .then((resp) => {
          this._bindItineraryDetails(resp);
        })
        .catch((ex) => {
          this.setState({ loading: false });
        });
    }
  }

  _bindItineraryDetails(data) {
    let emailadd = [];
    data.header.emailAddresses.forEach(function(email) {
      let ema = email + " ";
      emailadd.push(ema);
    });

    data.flights &&
      data.flights.map((flight, i) => {
        travelerItineraries[flight.pnrSegmentNumber] = [];
        travelerItineraries[flight.pnrSegmentNumber].push({
          key: "Flight",
          value: flight,
        });
      });

    data.hotels &&
      data.hotels.map((hotel, i) => {
        travelerItineraries[hotel.pnrSegmentNumber] = [];
        travelerItineraries[hotel.pnrSegmentNumber].push({
          key: "Hotel",
          value: hotel,
        });
      });

    data.cars &&
      data.cars.map((car, i) => {
        travelerItineraries[car.pnrSegmentNumber] = [];
        travelerItineraries[car.pnrSegmentNumber].push({
          key: "Car",
          value: car,
        });
      });

    this.setState({
      itineraryHeader: data.header,
      itineraryOrganization: data.organization,
      itineraryRemarks: data.remarks,
      itineraryCars: data.cars,
      itineraryDetails: travelerItineraries,
      emailaddress: emailadd,
      userDetail: data.userDetail,
      dataLoaded: true,
    });
  }

  render() {
    return (
      this.state.dataLoaded && (
        <div style={{ overflowX: "auto" }}>
          {this.state.itineraryHeader && (
            <table className="itinerary-view" style={{ width: "100%" }}>
              <tr>
                <th colSpan={4}>Passenger Info</th>
              </tr>
              <tr>
                <td className="title">First Name:</td>
                <td>
                  {this.state.itineraryHeader.passengers &&
                    this.state.itineraryHeader.passengers[0] &&
                    this.state.itineraryHeader.passengers[0]["firstName"]}
                </td>
                <td className="title">Last Name</td>
                <td>
                  {this.state.itineraryHeader.passengers &&
                    this.state.itineraryHeader.passengers[0] &&
                    this.state.itineraryHeader.passengers[0]["lastName"]}
                </td>
              </tr>
              <tr>
                <th colSpan={4}>PNR General Info</th>
              </tr>
              <tr>
                <td className="title">PNR:</td>
                <td>{this.state.itineraryHeader.pnrNumber}</td>
                <td className="title">Invoice:</td>
                <td>Itinerary</td>
              </tr>
              <tr>
                <td className="title">Customer:</td>
                <td>{this.state.itineraryHeader.accountNumber}</td>
                <td className="title">Org:</td>
                <td>{this.state.itineraryOrganization.name}</td>
              </tr>
            </table>
          )}

          {this.state.itineraryDetails
            ? (((flightCount = 0), (hotelCount = 0), (carCount = 0)),
              Object.entries(this.state.itineraryDetails).map(([key, data]) => {
                if (data[0].key === "Flight") {
                  flightCount++;
                  return (
                    <table className="itinerary-view" style={{ width: "100%" }}>
                      <tr>
                        <th colSpan={4}>
                          <span>{flightCount + " "}</span>
                          {data[0].key}
                        </th>
                      </tr>
                      <tr>
                        <td colSpan={4}>
                          {moment(data[0].value.departure.datetime).format(
                            dateFormat
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td className="title">Air Vendor:</td>
                        <td>
                          {data[0].value.airVendorCode +
                            " " +
                            data[0].value.airVendor}
                        </td>
                        <td className="title">Flight Number:</td>
                        <td>{data[0].value.flightNumber}</td>
                      </tr>
                      <tr>
                        <td className="title">Leaving From:</td>
                        <td>
                          {data[0].value.departure.airportCode +
                            " " +
                            data[0].value.departure.airport}
                        </td>
                        <td className="title">Departs:</td>
                        <td>
                          {moment(data[0].value.departure.datetime).format(
                            dateFormat
                          )}
                          {data[0].value.departure.originalDatetime &&
                          data[0].value.departure.originalDatetime !==
                            data[0].value.departure.datetime ? (
                            <span style={{ paddingLeft: "5px" }}>
                              original
                              {" " +
                                moment(
                                  data[0].value.departure.originalDatetime
                                ).format(orgDateFormat)}
                            </span>
                          ) : (
                            ""
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td className="title">Arriving To:</td>
                        <td>
                          {data[0].value.arrival.airportCode +
                            " " +
                            data[0].value.arrival.airport}
                        </td>
                        <td className="title">Arrives:</td>
                        <td>
                          {moment(data[0].value.arrival.datetime).format(
                            dateFormat
                          )}
                          {data[0].value.arrival.originalDatetime &&
                          data[0].value.arrival.originalDatetime !==
                            data[0].value.arrival.datetime ? (
                            <span style={{ paddingLeft: "5px" }}>
                              original
                              {" " +
                                moment(
                                  data[0].value.arrival.originalDatetime
                                ).format(orgDateFormat)}
                            </span>
                          ) : (
                            ""
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td className="title">Passengers:</td>

                        <td>
                          <Tooltip
                            placement="topLeft"
                            title={this.state.emailaddress}
                          >
                            {this.state.itineraryHeader.emailAddresses.length}
                            <i
                              style={{ marginLeft: "5px" }}
                              className="fas fa-info-circle"
                            ></i>
                          </Tooltip>
                        </td>
                        <td className="title">Flight Status:</td>
                        <td>{flightStatus[data[0].value.status]}</td>
                      </tr>
                    </table>
                  );
                } else if (data[0].key === "Hotel") {
                  hotelCount++;
                  return (
                    <table className="itinerary-view" style={{ width: "100%" }}>
                      <tr>
                        <th colSpan={4}>
                          <span>{hotelCount + " "}</span> {data[0].key}
                        </th>
                      </tr>
                      <tr>
                        <td colSpan={4}>
                          {moment(data[0].value.checkinDate).format(
                            "MM/DD/YYYY"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td className="title">Hotel Vendor:</td>
                        <td>{data[0].value.name}</td>
                        <td className="title">Hotel Name:</td>
                        <td>{data[0].value.originalName}</td>
                      </tr>
                      <tr>
                        <td className="title">Hotel Address:</td>
                        <td>{data[0].value.address}</td>
                        <td className="title">City Code:</td>
                        <td>{data[0].value.cityCode}</td>
                      </tr>
                      <tr>
                        <td className="title">Check-in Date:</td>
                        <td>
                          {moment(data[0].value.checkinDate).format(
                            "MM/DD/YYYY"
                          )}
                        </td>
                        <td className="title">Check-out Date:</td>
                        <td>
                          {moment(data[0].value.checkoutDate).format(
                            "MM/DD/YYYY"
                          )}
                        </td>
                      </tr>
                    </table>
                  );
                } else if (data[0].key === "Car") {
                  carCount++;
                  return (
                    <table className="itinerary-view" style={{ width: "100%" }}>
                      <tr>
                        <th colSpan={4}>
                          <span>{carCount + " "}</span> {data[0].key}
                        </th>
                      </tr>
                      <tr>
                        <td colSpan={4}>
                          {moment(data[0].value.pickupDatetime).format(
                            dateFormat
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td className="title">Car Vendor:</td>
                        <td>
                          {data[0].value.vendorCode +
                            " " +
                            data[0].value.vendorName}
                        </td>
                        <td className="title">Car Type:</td>
                        <td>{data[0].value.carType}</td>
                      </tr>
                      <tr>
                        <td className="title">Pick Up Location:</td>
                        <td>{data[0].value.pickupLocationName}</td>
                        <td className="title">Return Location:</td>
                        <td>{data[0].value.returnLocationName}</td>
                      </tr>
                      <tr>
                        <td className="title">Pick Up Date:</td>
                        <td>
                          {moment(data[0].value.pickupDatetime).format(
                            dateFormat
                          )}
                        </td>
                        <td className="title">Return Date:</td>
                        <td>
                          {moment(data[0].value.returnDatetime).format(
                            dateFormat
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td className="title">Confirmation Number:</td>
                        <td>{data[0].value.confirmationNumber}</td>
                      </tr>
                    </table>
                  );
                }
              }))
            : ""}

          {this.state.itineraryRemarks &&
            this.state.itineraryRemarks.map((remark, i) => (
              <table className="itinerary-view" style={{ width: "100%" }}>
                {i == 0 && (
                  <tr>
                    <th colSpan={4}>REMARKS</th>
                  </tr>
                )}

                <tr>
                  <td>{remark.text}</td>
                </tr>
              </table>
            ))}
        </div>
      )
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...travelerItineraryActions }, dispatch);
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    TravelerItineraryViewer
  )
);
