import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as PricingActions from "../../../redux/actions/pricing-actions";
import * as paginationActions from "../../../redux/actions/pagination-actions";
import { Table, Input, Tabs, Button, Icon, Typography, Row, Col, Tooltip } from "antd";
import IntlMessages from "../../../services/intlMesseges";
import * as Permission from "../../../services/permissions";
const { TabPane } = Tabs;

class Pricing extends Component {
  state = {
    loading: true,
    defaultActiveKey: (Permission.isRole("Administrator") ? "raw" : "retail"),
    rawPrices: [],
    retailPrices: [],
    data: [],
    pagination: {
      pageSize: 1000,
      current: 1,
      hideOnSinglePage: true,
      showTotal: (total, range) => {
        return (
          <span>
            <IntlMessages id="admin.text.showing" /> {range[0]}-{range[1]}{" "}
            <IntlMessages id="admin.text.of" /> {total}{" "}
            <IntlMessages id="admin.text.results" />
          </span>
        );
      }
    },
    filters: {}
  };
  getHeaderKeys = () => {
    return [
      {
        title: <IntlMessages id="admin.pricinglist.name" />,
        dataIndex: "name"
        //...this.getColumnSearchProps("name"),
        // width: "20%"
      },
      {
        title: <IntlMessages id="admin.pricinglist.description" />,
        dataIndex: "description"
        //...this.getColumnSearchProps("description"),
        // width: "20%"
      },
      {
        title: <IntlMessages id="admin.pricinglist.type" />,
        dataIndex: "calculationType"
        //...this.getColumnSearchProps("calculationType"),
        //width: "20%"
      },
      {
        title: <IntlMessages id="admin.pricinglist.countryorstate" />,
        dataIndex: "priceType",
        //...this.getColumnSearchProps("calculationType"),
        //width: "20%"
        render: (text, record) => (
          <span>
            {record.priceType === 1 ? "Country" : "State"}
          </span>
        )
      },
      {
        title: <IntlMessages id="admin.pricinglist.derivedFrom" />,
        dataIndex: "derivedPrice",
        width: "20%",
        render: (text, record) => (
          <span>
            {record.calculationType === "Base" ? "---" : record.derivedPrice}
          </span>
        )
      },
      {
        title: <IntlMessages id="admin.pricinglist.actions" />,
        rowKey: "action",
        // width: "10%",
        render: (text, record) => (
          <span>
             <button
            type="primary"
            className="icon"
            onClick={() => {
              this.editItem(record.pricingListId);
            }}
            
          >
              <Tooltip title="View">
                <i className="fas fa-eye"></i>
              </Tooltip>
            
          </button>
            <button
                className="icon"
                onClick={() => {
                  this.props.history.push('./logs/'+record.pricingListId)
                }}
              >
                <Tooltip
                  title={
                    <IntlMessages id="admin.organizations.actions.viewlogs" />
                  }
                >
                  {/* <i {...AccountsBillingIcon}></i> */}
                  <i className="fas fa-clipboard-list"></i>
                </Tooltip>
              </button>
          </span>
         
          
        )
      }
    ];
  };

  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => {
      return (
        <div style={{ padding: 8 }}>
          <Input
            ref={node => {
              this.searchInput = node;
            }}
            value={selectedKeys}
            onChange={e =>
              setSelectedKeys(e.target.value ? e.target.value.trimLeft() : "")
            }
            onPressEnter={() =>
              this.handleSearch(
                selectedKeys,
                confirm,
                dataIndex,
                clearFilters,
                setSelectedKeys
              )
            }
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Button
            type="primary"
            onClick={() =>
              this.handleSearch(
                selectedKeys,
                confirm,
                dataIndex,
                clearFilters,
                setSelectedKeys
              )
            }
            icon="search"
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            <IntlMessages id="admin.search" />
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters, dataIndex)}
            size="small"
            style={{ width: 90 }}
          >
            <IntlMessages id="admin.reset" />
          </Button>
        </div>
      );
    },
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    }
  });

  handleSearch = (
    selectedKeys,
    confirm,
    dataIndex,
    clearFilters,
    setSelectedKeys
  ) => {
    confirm();
    this.setState({ searchText: selectedKeys });
    let filters = this.state.filters;
    filters[dataIndex] = {
      val: selectedKeys,
      clearf: clearFilters,
      filter: true,
      setSelectedKeys: setSelectedKeys,
      confirm: confirm
    };
    this.setState({ filters: filters });
  };

  handleReset = (_clearFilters, dataIndex) => {
    //clearFilters();
    let filters = this.state.filters;
    filters[dataIndex].setSelectedKeys("");
    filters[dataIndex].confirm();
    filters[dataIndex] = { val: "", clearf: "", filter: false };
    this.setState({ filters: filters });
    this.setState({ searchText: "" });
  };

  async componentDidMount() {
    this.props.clearPaginationExceptMe("pricing");
    if (window.location.hash != "") {
      this.setState({
        defaultActiveKey: window.location.hash.replace("#", "")
      });
    }
    this.handleTableChange({ current: 1, pageSize: this.state.pagination.pageSize }, {}, {});
  }

  editItem = id => {
    this.props.history.push("view/" + id);
  };

  switchPricingTabs = current => {
    if (window.history.pushState) {
      window.history.pushState(null, null, "#" + current);
    } else {
      this.props.location.hash = "#" + current;
    }
    this.setState({ defaultActiveKey: current });
  };

  handleTableChange = (pagination, filters, sorter) => {
    this.setState({ loading: true });

    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.props
      .getPricing({
        filters: filters,
        pagination: { page: pagination.current, pageSize: pagination.pageSize },
        sorter: sorter
      })
      .then(pricing => {
        let retail = [];
        let raw = [];
        pricing.data.map(function(obj, _idx) {
          if (obj.calculationFor === "RawPrice") {
            raw.push(obj);
          } else {
            retail.push(obj);
          }
        });
        this.setState({ retailPrices: retail, rawPrices: raw });

        pager.total = pricing.paging.totalRecords;
        this.setState({
          loading: false,
          pagination: pager
        });
      })
      .catch(_ex => {
        this.setState({ loading: false });
      });
  };

  render() {
    return (
      <div>
        <Row gutter={24}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Typography.Title level={4}>
              <IntlMessages id="admin.pricinglist.heading" />
            </Typography.Title>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Button
                style={{ float: "right" }}
                type="primary"
                htmlType="button"
                onClick={() => this.props.history.push("./add/")}
              >
                <IntlMessages id="admin.pricinglist.add" />
              </Button>
          </Col>
        </Row>
        <hr />
        <Tabs
          defaultActiveKey={this.state.defaultActiveKey}
          activeKey={this.state.defaultActiveKey}
          size="large"
          onChange={this.switchPricingTabs}
        >
          {Permission.isRole("Administrator") ? 
            <TabPane
              tab={<IntlMessages id="admin.pricelist.rawPrice" />}
              key="raw"
            >
              <Row gutter={24}>
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={24}
                  style={{ overflowX: "auto" }}
                >
                  <Table
                    columns={this.getHeaderKeys()}
                    rowKey={record => record.pricingListId}
                    dataSource={this.state.rawPrices}
                    pagination={this.state.pagination}
                    loading={this.state.loading}
                    //onChange={this.handleTableChange}
                  />
                </Col>
              </Row>
            </TabPane>
          : "" }
          <TabPane
            tab={<IntlMessages id="admin.pricelist.retailPrice" />}
            key="retail"
          >
            <Row gutter={24}>
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl={24}
                style={{ overflowX: "auto" }}
              >
                <Table
                  columns={this.getHeaderKeys()}
                  rowKey={record => record.pricingListId}
                  dataSource={this.state.retailPrices}
                  pagination={this.state.pagination}
                  loading={this.state.loading}
                  //onChange={this.handleTableChange}
                />
              </Col>
            </Row>
          </TabPane>
        </Tabs>
      </div>
    );
  }
}
Pricing.propTypes = {
  getPricing: PropTypes.func
};
function mapStateToProps(state) {
  return { 
    ...state.user,
    ...state.pagination
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...PricingActions, ...paginationActions }, dispatch);
}
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    Pricing
  )
);
