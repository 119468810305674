import API from "../api/order-api";
import {notifyUser} from "../../services/notification-service"
export function getOrders({ orgId, filters, pagination, sorter }) {
    return async function(dispatch, getState) {
      try {
        let resp = await API.getOrdersData({
          orgId: orgId,
          filters: filters,
          pagination: pagination,
          sorter : sorter
        });//console.log(resp);
        return resp;
  
      } catch (e) {
        return {"error" : true};
      }
    };
  }
  export function getOrder(idx) {
    return async function(dispatch, getState) {
      try {
        let resp = await API.getOrder(idx);
        return resp;
      } catch (e) {
        return {"error" : true};
      }
    };
  }

  export function getAgentOrder(idx) {
    return async function(dispatch, getState) {
      try {
        let resp = await API.getAgentOrder(idx);
        return resp;
      } catch (e) {
        return {"error" : true};
      }
    };
  }

  export function updateOrder(data) {
    return async function(dispatch, getState) {
      try {
        const response = await API.updateOrder(data);
        if (response.data) {
          notifyUser("Order Updated.", 'info');
        }
     return { "success": true, response };

      } catch (e) {
        notifyUser("Error Updating Order", 'error');
        return {"error" : true};
      }
    };
  }

  export function refundOrder(data) {
    return async function(dispatch, getState) {
      try {
        const response = await API.refundOrder(data)
        return response;
      } catch (e) {
        notifyUser("Error Refunding Order", "error")
        return { error: true }
      }
    }
  }

  export function addOrder(data) {
    return async function(dispatch, getState) {
      try {
         await API.addOrder(data);
        notifyUser("Order Added", 'info');
      } catch (e) {
        notifyUser("Error Adding Order", 'error');
        return {"error" : true};
      }
    };
  }
export function getResellerOrders(data) {
  return async function(_dispatch, _getState) {
    try {
      let resp = await API.getResellerOrders(data);
      return resp;

    } catch (e) {
      return {"error" : true};
    }
  };
}

export function getAgentOrders(data) {
  return async function(_dispatch, _getState) {
    try {
      let resp = await API.getAgentOrders(data);
      return resp;

    } catch (e) {
      return {"error" : true};
    }
  };
}
