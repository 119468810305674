import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import IntlMessages from "../../../../../../services/intlMesseges";
import { notifyUser } from "../../../../../../services/notification-service";
import AppLocale from "../../../../../../languageProvider";
import { Table, Icon, Input, Button, Row, Col, Typography , Switch} from "antd";
import * as userActions from "../../../../../../redux/actions/user-actions";
import * as paginationActions from "../../../../../../redux/actions/pagination-actions";
import { EditCell } from "../../../../../shared/components/helperCells";
import moment from "moment";
const dateFormathh = "MM/DD/YYYY HH:mm:ss";
const dateFormat = "MM/DD/YYYY";



class OrganizationChildList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    dataSource: [],
    parentName : "",
    userStatus: [],
    selectedStatus : null
    }
  };


  async componentDidMount() {
    try {
      const resp = await this.props.getUser(this.props.match.params.id);
      this.setState({
        dataSource: resp.data.childUsers || [], // Set the data in the state
        loading: false,
        parentName :resp.data.firstName
      });
    } catch (ex) {
      console.error(ex);
      this.setState({ loading: false });
    }
  }
  
  getHeaderKeys = () => {
    return [
      { 
      title: 'Employee ID', 
      dataIndex: 'employeeId', 
      key: 'employeeId' 
      },
      { 
        title: 'First Name', 
        dataIndex: 'firstName', 
        key: 'firstName' 
      },
      { 
        title: 'Last Name', 
        dataIndex: 'lastName', 
        key: 'lastName' 
      },
      { 
        title: 'User Name', 
        dataIndex: 'userName', 
        key: 'userName' 
      },
      {
        title: <IntlMessages id="admin.userlisting.status" />,
        render: (_text, record) => (
          <span>
            <Switch
              checkedChildren={<IntlMessages id="admin.status.active" />}
              unCheckedChildren={<IntlMessages id="admin.status.inactive" />}
              checked={record.isActive}
              onClick={() =>
                this.confirmActiveEmployees(this.state.userStatus[record.userId]
                    ? !this.state.userStatus[record.userId]
                    : !record.isActive, record.userId
                )
              }
            />
          </span>
        ),
      },
      { 
        title: 'Action', 
        render: (text, record) => (
          <span>
            <EditCell
              onEditCell={() => {
                this.editItem(record.userId);
              }}
            />
            </span>
             ),
      },
    ];
  };
  
  confirmActiveEmployees = async (selected, userId) =>{
   await  this.updateEmployeeStatus(selected, userId);
    this.setState((prevState) => ({
      dataSource: prevState.dataSource && prevState.dataSource.map((item) =>
        item.userId === userId ? { ...item, isActive: selected} : item
      ),
    }));
    
  }
  
  updateEmployeeStatus = async (selected, userId) => {
    try {
      this.props.updateStatus(userId, selected).then((response) => {
        const { language } = this.props;
        // const currentAppLocale = AppLocale[language.locale];
        if (response.data && response.data.message) {
          let _userStatus = this.state.userStatus || [];
          _userStatus[userId] = selected;
          this.setState({
            userStatus: _userStatus,
          });
          notifyUser(response.data.message, "success");
          // this.componentDidMount();
        } else {
          notifyUser(["admin.unknown.error"], "error");
        }
      });
    } catch (e) {
      console.log("Error:", e);
    }
  };

  editItem = (id) => {
    const userid = id;
    const currentPathnameedit = this.props.location.pathname;
    const modifiedPathname = `${currentPathnameedit}edit/${userid}`;
    this.props.history.push(modifiedPathname);
  };


  render() {
    const { loading, dataSource } = this.state;
    return (
      <div>
        <Row gutter={24}>
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography.Title level={4}>
              {this.state.parentName}{'\u0027'}{"s Child"}
            </Typography.Title>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <Button
              type="primary"
              className="right-fl"
              htmlType="button"
              onClick={() => this.props.history.push('../../')}
            >
              <IntlMessages id="admin.userlisting.back" />
            </Button>
          </Col>
        </Row>
        <hr />
        
        <Row>
          <Col>
          <Table
              dataSource={dataSource}
              columns={this.getHeaderKeys()}
              loading={loading}
            />
          </Col>
        </Row>
      </div>
    );
  }
 }

function mapStateToProps(state) {
  return {
    ...state.user
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({  ...userActions, ...paginationActions}, dispatch);
}
export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    { forwardRef: true }
  )(OrganizationChildList)
); 