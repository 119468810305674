import React, { Component } from "react";
import {
  Accordion,
} from "react-accessible-accordion";
//import PersonalDeviceMobile from "./personal-device-mobile";
import TravelConciergeMobile from "./travel-concierge-mobile";
import TravelMedicalMobile from "./travel-medical-mobile";
import TravelSecurityMobile from "./travel-security-mobile";
//import PersonalDevice from "./personal-device";
import TravelConcierge from "./travel-concierge";
import TravelMedical from "./travel-medical";
import TravelSecurity from "./travel-security";
import CapAdvantageAssistance from "./cap-advantage-assistance";
import CapAdvantageAssistanceMobile from "./cap-advantage-assistance-mobile";
import { Col, Collapse, Icon } from "antd";
import CollapsePanel from "antd/lib/collapse/CollapsePanel";
const { Panel } = Collapse;

export default class PlanType extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onChangeAccordion = (selected) => {
    if(selected.length > 0){
      document.getElementById("accordion__heading-"+selected).closest('table').scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
    }
  }
  customExpandIcon = (isActive) => {
    return <Icon type={(isActive) ? "minus" : "plus"} />
  }
  render() {
    return (
      <section id="faq">
        <div className="wrap">
          <div className="mobile">
            <Accordion allowZeroExpanded={true} /* preExpanded={["cap-advantage-assistance-mobile"]} */ onChange={this.onChangeAccordion}>
              <table>
                <thead>
                  <tr>
                    <th scope="col">
                      <h3>Benefits</h3>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {/* <PersonalDeviceMobile /> */}
                  <TravelMedicalMobile />
                  <TravelSecurityMobile />
                  <TravelConciergeMobile />
                  <CapAdvantageAssistanceMobile />
                  {/* new row */}
                </tbody>
              </table>
            </Accordion>
          </div>
          <div className="desktop">
            <table>
              <thead>
                <tr>
                  <th scope="col">
                    <h3>Benefits</h3>
                  </th>
                </tr>
              </thead>
              <tbody>
                {/* <PersonalDevice /> */}
                <Collapse
                  expandIcon={({isActive}) => this.customExpandIcon(isActive)}
                  defaultActiveKey="TravelMedical"
                >
                  <Panel
                    header={<b>Travel Medical Assistance Services</b>}  
                    key="TravelMedical"
                  >
                    <TravelMedical />
                  </Panel>
                  <Panel
                    header={<b>Travel Security Assistance Services</b>}
                    key="TravelSecurity"
                  >
                    <TravelSecurity />
                  </Panel>
                  <Panel
                    header={<b>Travel Concierge Assistance Services</b>}
                    key="TravelConcierge"
                  >
                    <TravelConcierge />
                  </Panel>
                  <Panel
                    header={<b>CAP™ Advantage – Personal Device Tracking & Assistance App</b>}
                    key="CapAdvantageAssistance"
                  >
                    <CapAdvantageAssistance />
                  </Panel>
                </Collapse>
                {/* new row */}
              </tbody>
            </table>
          </div>
        </div>
      </section>
    );
  }
}
