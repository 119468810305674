import React, { Component, useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as b2b2cActions from "../../../redux/actions/b2b2c-actions";
import { useDispatch, useSelector } from "react-redux";
import * as userActions from "../../../redux/actions/user-actions";
import { Beforeunload } from "react-beforeunload";
import { Row, Col, Button, Modal } from "antd";
import Config from "../../../../../config";
import moment from "moment";
import { currencyType } from "../../constants";
import { cadConvertionRate } from "../../b2c/shared/cadConverstion";

const PriceCalculation = (props) => {
  const dispatch = useDispatch();
  const data = props; //useSelector((state) => state.b2c)
  const productToOrder = Config.b2b2CProductToOrder;
  const [currency, setCurrency] = useState("USD");

  const [cadPrice, setCadPrice] = useState(0);
  const [cadDiscountedPercent, setCadDiscountedPercent] = useState(0);
  const [cadDiscountedAmount, setCadDiscountedAmount] = useState(0);
  const [appliedPromoText, setAppliedPromoText] = useState("");
  const [totalPrice, setTotalPrice] = useState(0);

  let countries =
    props.planTerm && props.planTerm.country && props.planTerm.country != ''
      ? props.planTerm.country.split(',').map(Number)
      : props.planTerm && props.planTerm.isAnnual ? [277] : [248]; //[277]; // for all countries by default
  let dp = props.planTerm && props.planTerm.dp ? props.planTerm.dp : "8";
  useEffect(() => {
    setCurrency(props.currency && props.currency);

    if (countries.length > 0 && props.countryTierInfo.length > 0) {
      calculateProductPrice(countries, dp);
    }
    cadPriceCalculation();
  }, [
    props.planTerm,
    props.country,
    props.dp,
    props.planTerm && props.planTerm.isAnnual,
    props.currency,
    props.travelerCount,
    props.couponInfo,
  ]);

  useEffect(() => {
    if (countries.length > 0 && props.countryTierInfo.length > 0) {
      calculateProductPrice(countries, dp);
    }

    cadPriceCalculation();
  }, []);

  useEffect(() => {
    cadPriceCalculation();
  }, [currency, totalPrice]);

  const cadPriceCalculation = async () => {
    let cPrice;
    cPrice = cadConvertionRate(
      props.planTerm &&
        props.planTerm.totalPrice &&
        props.planTerm.totalPrice / props.travelerCount
    );
    cPrice = cPrice * props.travelerCount;
    if (
      props.couponInfo &&
      props.couponInfo.discountType != "P" &&
      cadDiscountedAmount != 0
    ) {
      cPrice = cPrice - cadDiscountedAmount;
    }

    if (
      props.couponInfo &&
      props.couponInfo.discountType === "P" &&
      cadDiscountedPercent != 0
    ) {
      let CadDisAmount = (cPrice * cadDiscountedPercent) / 100;
      cPrice = cPrice - CadDisAmount;
    }
    setCadPrice(cPrice);

    // let planTermObject = props.planTerm;
    // planTermObject.cadPrice = cPrice;
    //  await props.SavePlanTerm(planTermObject);
  };

  const calculateProductPrice = async (countryValue, selectedDay) => {
    let tierValue = 4;
    let calculatedPrice = 0;
    var dayPriceObject;
    var selectedCountryTierList = [];
    let dpackages = data.planTerm && data.planTerm.isAnnual ? parseInt(365) : parseInt(selectedDay);
    if (selectedDay !== 0 && selectedDay !== "NaN" && countryValue.length > 0) {
      if (countryValue.length > 0) {
        for (var i = 0; i < countryValue.length; i++) {
          var countryObject =
            props.countryTierInfo &&
            props.countryTierInfo.find((x) => x.countryId === countryValue[i]);
          if (i === 0) {
            tierValue = parseInt(countryObject.tierId);
          } else {
            if (parseInt(countryObject.tierId) < tierValue) {
              tierValue = parseInt(countryObject.tierId);
            }
          }
          selectedCountryTierList.push(tierValue);
        }
      } else {
        tierValue = 4;
      }

        dayPriceObject =
        props.productPriceList &&
        props.productPriceList.length > 0 &&
        props.productPriceList.find(
          (x) => x.tierId === tierValue && x.dayPackage === dpackages && x.productId === parseInt(productToOrder)
        );

      calculatedPrice = dayPriceObject.retailPrice;

      setTotalPrice(calculatedPrice * props.travelerCount);

      // if (props.planTerm && props.planTerm.isAnnual == true) {
      //   if (props.productPriceList && props.productPriceList.length > 0) {
      //     var dayPriceObject = props.productPriceList.find(
      //       (x) =>
      //         x.tierId === 1 &&
      //         x.dayPackage === 365 &&
      //           parseInt(
      //             props.resellerOrganizationInfo.productList[0].productId
      //           )
      //     );

      //     calculatedPrice = dayPriceObject.retailPrice;
      //     setTotalPrice(calculatedPrice * props.travelerCount);
      //   }
      // }

      if (props.couponInfo !== null) {
        //  && props.couponInfo.promoOfferId != undefined || props.couponInfo.promoOfferId != "") {
        bindPlanPrice(calculatedPrice);
      }
    } else {
      setTotalPrice(999);
    }

    let planTermObject = props.planTerm;
    if (planTermObject != undefined) {
      planTermObject.totalPrice = calculatedPrice * props.travelerCount;
      planTermObject.tierValue = tierValue;
      await props.SavePlanTerm(planTermObject);
    }
  };

  const handleCurrencyChange = async (value) => {
    if (typeof props.onPaymentMethodChange === "function") {
      props.onPaymentMethodChange("stripe");
    }
    setCurrency(value);
    if (value == "USD") {
      dispatch(b2b2cActions.updateCurrency(value, 1));
    } else {
      dispatch(b2b2cActions.updateCurrency(value, 1.3644));
    }

    //  this.updatePrice();
  };

  const bindPlanPrice = async (calculatedPrice = "") => {
    //  dispatch(b2cActions.getAppliedPromo());
    var totalPrice =
      calculatedPrice !== ""
        ? calculatedPrice
        : props.planTerm && props.planTerm.totalPrice
        ? props.planTerm.totalPrice
        : 0;

    totalPrice = totalPrice * props.travelerCount;
    var discountedPrice = 0;
    if (props.couponInfo && props.couponInfo.promoOfferId) {
      if (props.couponInfo.discountType === "P") {
        discountedPrice = (totalPrice * props.couponInfo.discountFigure) / 100;
        setCadDiscountedPercent(props.couponInfo.discountFigure);
      } else {
        discountedPrice = props.couponInfo.discountFigure;
        setCadDiscountedAmount(discountedPrice ? discountedPrice : 0);
      }
      var PromoText =
        "Discount of " +
        (props.couponInfo.discountType === "P"
          ? props.couponInfo.discountFigure + "% "
          : "$ " + discountedPrice.toFixed(2)) +
        " has been applied to the total plan fee";
      totalPrice = totalPrice - discountedPrice.toFixed(2);
      setAppliedPromoText(PromoText);
    } else {
      setAppliedPromoText("");
      setCadDiscountedAmount("");
      setCadDiscountedPercent("");
    }
    setTotalPrice(totalPrice);
    discountedPrice = 0;
  };

  return (
    <div>
      <select
        value={currency}
        onChange={(e) => handleCurrencyChange(e.target.value)}
      >
        {currencyType.map((item) => {
          return (
            <option key={item} value={item}>
              {item}
            </option>
          );
        })}
      </select>
      <h1>
        {" "}
        <span>Plan Fee:</span>{" "}
        {props.currency === "CAD"
          ? "$" + cadPrice.toFixed(2)
          : "$" + (totalPrice && totalPrice.toFixed(2))}{" "}
      </h1>
      <span>{appliedPromoText}</span>
    </div>
  );
};

export default connect(
  (state) => {
    return {
      ...state.b2b2c,
    };
  },
  { ...b2b2cActions }
)(PriceCalculation);
