import Axios from "../../services/axios-service";

const getAssistTravelersList = data => {
  return Axios.get(
    "/api/AssistList/?search=" + JSON.stringify(data),
    undefined
  );
};

const updateAssistTravelerStatus = data => {
  return Axios.put(
    "/api/UpdateAssistStatus?EventLoggingID=" +
      data.EventLoggingID +
      "&Status=" +
      data.Status,
    undefined,
    undefined
  );
};

const getCheckInList = data => {
  return Axios.get(
    "/api/AssistOrCheckinListByUserID/?search=" + JSON.stringify(data),
    undefined
  );
};

export default {
  getAssistTravelersList,
  updateAssistTravelerStatus,
  getCheckInList
};
