import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Wizard from "../shared/wizard/wizard.jsx";
import * as b2cActions from "../../../redux/actions/b2c-actions";
import * as userActions from "../../../redux/actions/user-actions";
import Loader from "./../shared/loader/loader";
import TravellerInfo from "../shared/traveller/traveller.jsx";

class PurchaseSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      personalSummary: [],
      companionSummary: [],
      travelZone: "",
      showLoader: false,
      planMaxPrice: 0,
      totalPrice: props.planTerm ? props.planTerm.totalPrice : 0,
      appliedPromoText: "",
    };
    this.handleClick = this.handleClick.bind(this);
    this.handlePlanChange = this.handlePlanChange.bind(this);
    this.calculatePrice = this.calculatePrice.bind(this);
    this.updatePrice = this.updatePrice.bind(this);
  }

  async handlePlanChange(evt) {
    var targetValue = evt.target.value.split(",");
    this.props.personalDetails[targetValue[0]].product_id = targetValue[1];
    await this.props.SavePersonalDetails(this.props.personalDetails);
    this.calculatePrice();
  }

  async calculatePrice() {
    var totalPrice = 0;
    var dayPriceObject;
    for (var i = 0; i < this.props.personalDetails.length; i++) {
      var travellerObject = this.props.personalDetails[i];
      if (
        this.props.productPriceList &&
        this.props.productPriceList.length > 0
      ) {
        if (travellerObject.is_annual) {
          dayPriceObject = this.props.productPriceList.find(
            (x) =>
              x.tierId === 1 &&
              x.dayPackage === 365 &&
              x.productId === parseInt(travellerObject.product_id)
          );
          totalPrice = totalPrice + parseInt(dayPriceObject.retailPrice);
        } else {
          dayPriceObject = this.props.productPriceList.find(
            (x) =>
              x.tierId === parseInt(this.props.planTerm.tierValue) &&
              x.dayPackage === parseInt(this.props.planTerm.days) &&
              x.productId === parseInt(travellerObject.product_id)
          );
          totalPrice = totalPrice + parseInt(dayPriceObject.retailPrice);
        }
      } else {
        totalPrice = this.props.planTerm.totalPrice;
      }
    }
    let planTermObject = this.props.planTerm;
    planTermObject.totalPrice = totalPrice;
    await this.props.SavePlanTerm(planTermObject);
    var maxPriceDifference =
      this.props.personalDetails.length * this.state.planMaxPrice -
      this.props.planTerm.totalPrice;
    this._bindPlanPrice(maxPriceDifference);
  }

  async handleClick(evt) {
    if (evt.currentTarget.dataset && evt.currentTarget.dataset.value) {
      if (evt.currentTarget.dataset.value === "back") {
        await this.props.SaveStep("4");
        this.props.history.push(this.props.b2cBaseUrl + "/billing-information");
      } else {
        this.saveDataAndNext();
      }
    }
  }

  async saveDataAndNext() {
    this.setState({ showLoader: true });
    var orderObject = {};
    var userinfo = [];
    var travellerArray = this.props.personalDetails;
    var countryArray;
    var productId = 0;
    await this.props.getAppliedPromo();
    var discountedPrice = 0;
    var totalPrice = 0;
    if (this.props.couponInfo && this.props.couponInfo.promoOfferId) {
      if (this.props.couponInfo.discountType === "P")
        discountedPrice =
          (this.props.planTerm.totalPrice *
            this.props.couponInfo.discountFigure) /
          100;
      else discountedPrice = this.props.couponInfo.discountFigure;
    }
    totalPrice =
      discountedPrice > 0
        ? parseInt(this.props.planTerm.totalPrice) - discountedPrice
        : parseInt(this.props.planTerm.totalPrice);
    if (this.props.planTerm.isAnnual) {
      var objCountry = this.props.countryTierInfo.find(
        (x) => x.countryCode === "All"
      );
      countryArray = [objCountry.countryId];
      let productCount = this.props.productInfo.length;
      productId = this.props.productInfo[productCount - 2].productId;
    } else {
      countryArray = this.props.planTerm.country.split(",");
    }
    for (var i = 0; i < travellerArray.length; i++) {
      var travellerObject = {};
      if (this.props.userData && this.props.userData.email && i === 0) {
        travellerObject.userId = this.props.userData.userID;
        travellerObject.Email = this.props.userData.email;
        travellerObject.ProductId = this.props.planTerm.isAnnual
          ? productId
          : travellerArray[i].product_id;
        travellerObject.IsPrimary = true;
        travellerObject.State = travellerArray[i].state
          ? travellerArray[i].state
          : 0;
        travellerObject.EmergencyFirstName = travellerArray[i].rel_first_name;
        travellerObject.EmergencyLastName = travellerArray[i].rel_last_name;
        travellerObject.EmergencyRelationship = travellerArray[i].relationship;
        travellerObject.EmergencyContactNo = travellerArray[i].rel_phone_number;
      } else if (travellerArray[i].isEmailExit && i != 0) {
        travellerObject.Email = travellerArray[i].email;
        travellerObject.IsPrimary = false;
        travellerObject.ProductId = this.props.planTerm.isAnnual
          ? productId
          : travellerArray[i].product_id;
      } else {
        travellerObject.FirstName = travellerArray[i].first_name;
        travellerObject.LastName = travellerArray[i].last_name;
        travellerObject.Email = travellerArray[i].email;
        travellerObject.ContactNo = travellerArray[i].mobile_number;
        travellerObject.Country = travellerArray[i].country;
        travellerObject.State = travellerArray[i].state
          ? travellerArray[i].state
          : 0;
        travellerObject.IsBusinessTravelling =
          travellerArray[i].is_bussiness_travelling;
        travellerObject.DOB = travellerArray[i].dob;
        travellerObject.EmergencyFirstName = travellerArray[i].rel_first_name;
        travellerObject.EmergencyLastName = travellerArray[i].rel_last_name;
        travellerObject.EmergencyRelationship = travellerArray[i].relationship;
        travellerObject.EmergencyContactNo = travellerArray[i].rel_phone_number;
        travellerObject.CompanyName = travellerArray[i].company_name;
        travellerObject.ProductId = this.props.planTerm.isAnnual
          ? productId
          : travellerArray[i].product_id;
        if (i === 0) {
          travellerObject.HowDidYouHear =
            this.props.billingInformation.how_heard === "Referral" ||
            this.props.billingInformation.how_heard === "Other"
              ? this.props.billingInformation.how_heard +
                " - " +
                this.props.billingInformation.how_heard_other
              : this.props.billingInformation.how_heard;
          travellerObject.IsPrimary = true;
        } else {
          travellerObject.IsPrimary = false;
        }
      }
      userinfo.push(travellerObject);
    }
    orderObject.userinfo = userinfo;
    orderObject.IsAnnual = this.props.planTerm.isAnnual;
    orderObject.tierId = this.props.planTerm.tierValue;
    orderObject.Countries = countryArray;
    orderObject.states = [];
    orderObject.stateRiskZone = 0;
    orderObject.CoverageStartDate = this.props.planTerm.date;
    orderObject.Days = this.props.planTerm.isAnnual
      ? 365
      : this.props.planTerm.days;
    orderObject.Quantity = this.props.personalDetails.length;
    orderObject.ReferrerCode = "";
    orderObject.TotalOrderAmount = totalPrice >= 0 ? totalPrice : 0;
    orderObject.discount = discountedPrice > 0 ? discountedPrice : null;
    orderObject.Token = this.props.billingInformation.paymnet_token;
    orderObject.CouponId =
      this.props.couponInfo && this.props.couponInfo.promoOfferId
        ? this.props.couponInfo.promoOfferId
        : null;
    orderObject.IsReceiveTravelAlerts = this.props.personalDetails[0].receiver_alert;
    orderObject.Currency = this.props.currency;
    orderObject.ConversionTotalAmount =
      totalPrice >= 0 ? totalPrice.toFixed(2) : 0;
    await this.props.placeOrder(orderObject);
    if (this.props.orderData && this.props.orderData.orderNumber) {
      await this.props.SaveStep("5");
      this.props.history.push(this.props.b2cBaseUrl + "/thank-you");
    } else {
      if (
        this.props.orderErrorData &&
        this.props.orderErrorData.status &&
        this.props.orderErrorData.status !== 200 &&
        this.props.orderErrorData.message
      ) {
        alert("Error processing order: " + this.props.orderErrorData.message);
        this.props.history.goBack();
      } else {
        alert("An error occured while processing the order.");
        this.props.history.goBack();
      }
      this.setState({ showLoader: false });
    }
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    var maxPrice = 999;
    var dayPriceObject;
    var maxPriceDifference = 0;
    if (
      this.props.personalDetails &&
      Object.keys(this.props.personalDetails).length === 0
    ) {
      await this.props.getStep();
      await this.props.getUserData();
      await this.props.getProductPricingAndInfo();
      await this.props.getPersonalInformation();
      await this.props.getPlanTermInformation();
      await this.props.getPlanInformation();
      await this.props.getBillingInformation();
      if (parseInt(this.props.step) < 4) {
        this.props.history.push(this.props.b2cBaseUrl + "/billing-information");
      } else if (parseInt(this.props.step) === 5) {
        await this.props.SaveStep("0");
        this.props.history.push(this.props.b2cBaseUrl + "/purchase");
      } else {
        if (
          this.props.productPriceList &&
          this.props.productPriceList.length > 0
        ) {
          dayPriceObject = this.props.productPriceList.find(
            (x) =>
              x.tierId === 1 &&
              x.dayPackage === 365 &&
              x.productId ===
                this.props.productInfo[this.props.productInfo.length - 2]
                  .productId
          );
          maxPrice = dayPriceObject.retailPrice;
        }
        maxPriceDifference =
          this.props.personalDetails.length * maxPrice -
          this.props.planTerm.totalPrice;
        this._bindPlanPrice(maxPriceDifference);
        this.setState({
          planMaxPrice: maxPrice,
        });
      }
    } else if (parseInt(this.props.step) < 4) {
      this.props.history.push(this.props.b2cBaseUrl + "/billing-information");
    } else if (parseInt(this.props.step) === 5) {
      await this.props.SaveStep("0");
      this.props.history.push(this.props.b2cBaseUrl + "/purchase");
    } else {
      if (
        this.props.productPriceList &&
        this.props.productPriceList.length > 0
      ) {
        dayPriceObject = this.props.productPriceList.find(
          (x) =>
            x.tierId === 1 &&
            x.dayPackage === 365 &&
            x.productId ===
              this.props.productInfo[this.props.productInfo.length - 2]
                .productId
        );
        maxPrice = dayPriceObject.retailPrice;
      }
      maxPriceDifference =
        this.props.personalDetails.length * maxPrice -
        this.props.planTerm.totalPrice;
      this._bindPlanPrice(maxPriceDifference);
      this.setState({
        planMaxPrice: maxPrice,
      });
    }
  }

  updatePrice() {
    var maxPriceDifference =
      this.props.personalDetails.length * this.state.planMaxPrice -
      this.props.planTerm.totalPrice;
    this._bindPlanPrice(maxPriceDifference);
  }

  async _bindPlanPrice(maxPriceDifference) {
    await this.props.getAppliedPromo();
    var totalPrice = this.props.planTerm.totalPrice;
    if (this.props.couponInfo && this.props.couponInfo.promoOfferId) {
      var discountedPrice = 0;
      if (this.props.couponInfo.discountType === "P") {
        discountedPrice =
          (this.props.planTerm.totalPrice *
            this.props.couponInfo.discountFigure) /
          100;
      } else {
        discountedPrice = this.props.couponInfo.discountFigure;
      }
      var appliedPromoText =
        "Discount of $" +
        discountedPrice +
        " has been applied to the total plan fee";
      totalPrice = totalPrice - discountedPrice;
      this.setState({
        appliedPromoText: appliedPromoText,
      });
    }
    this.setState({
      totalPrice: totalPrice < 0 ? 0 : totalPrice,
      priceToShowAsDiffernce: maxPriceDifference,
    });
  }

  render() {
    return (
      <div>
        {this.state.showLoader ? <Loader /> : ""}

        <section id="banner">
          <Wizard wizardActiveClass="step5" />
          {/* price detail */}
          <div
            className="wrap900 price-sticky-head"
            style={{
              display: "block",
              maxWidth: "1300px",
              zIndex: "100",
              position: "sticky",
            }}
          >
            <div className="title" style={{ background: "#FFF" }}>
              <h1>
                <span>Plan Fee:</span>{" "}
                {this.state.totalPrice
                  ? "$" + this.state.totalPrice.toFixed(2) + " USD"
                  : "$0.00 USD"}
              </h1>
              <span>{this.state.appliedPromoText}</span>
            </div>
          </div>
          {/* price  ends */}
          {/*  purchase summary */}
          {this.props.personalDetails &&
          this.props.personalDetails.length > 0 ? (
            <div className="wrap">
              <div className="purchase-summary">
                {/*- title */}
                {/* title ends */}
                {/* Traveller Summary */}
                {this.props.personalDetails &&
                this.props.personalDetails.length > 0 &&
                this.props.planTerm &&
                this.props.planTerm.tierValue &&
                this.props.productInfo.length > 0 ? (
                  <TravellerInfo
                    updatePrice={this.updatePrice}
                    priceToShowAsDiffernce={this.state.priceToShowAsDiffernce}
                    productPriceList={this.props.productPriceList}
                    SavePlanTerm={this.props.SavePlanTerm}
                    productPricing={this.props.productPricing}
                    handlePlanChange={this.handlePlanChange}
                    planMaxPrice={this.state.planMaxPrice}
                    planTerm={this.props.planTerm}
                    productInfo={this.props.productInfo}
                    personalDetails={this.props.personalDetails}
                    countryTierInfo={this.props.countryTierInfo}
                  />
                ) : (
                  ""
                )}
                {/* Traveller Summary ends */}
              </div>
            </div>
          ) : (
            ""
          )}

          {/* next and back button */}
          <div className="next-back">
            <div className="wrap">
              <button
                data-value="back"
                className="back"
                onClick={this.handleClick}
              >
                Back
              </button>
              <button
                data-value="next"
                className="next"
                style={{ background: "#489e3c" }}
                onClick={this.handleClick}
              >
                Confirm Purchase
              </button>
            </div>
          </div>
          {/* next and back button */}
        </section>
      </div>
    );
  }
}

PurchaseSummary.propTypes = {
  getProductPricingAndInfo: PropTypes.func,
  placeOrder: PropTypes.func,
  getPlanInformation: PropTypes.func,
  getPlanTermInformation: PropTypes.func,
  getPersonalInformation: PropTypes.func,
  getBillingInformation: PropTypes.func,
  SaveStep: PropTypes.func,
  step: PropTypes.string,
  planType: PropTypes.object,
  planTerm: PropTypes.object,
  productPricing: PropTypes.object,
  personalDetails: PropTypes.array,
  billingInformation: PropTypes.object,
  couponInfo: PropTypes.object,
  orderData: PropTypes.object,
  productPriceList: PropTypes.array,
  getUserData: PropTypes.func,
  userData: PropTypes.object,
  countryTierInfo: PropTypes.array,
  getAppliedPromo: PropTypes.func,
  getStep: PropTypes.func,
  b2cBaseUrl: PropTypes.string,
};
function mapStateToProps(state) {
  return {
    ...state.b2c,
    ...state.user,
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...b2cActions, ...userActions }, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(PurchaseSummary);
