import React, { Component } from "react";
import { Map, GoogleApiWrapper, InfoWindow, Marker } from "google-maps-react";
import MarkerCluster from "./Cluster";
import { Row, Col, Tabs, Spin, Drawer, Button, Modal } from "antd";
import API from "../../../redux/api/admin-api";
import riskLineApi from "../../../redux/api/risk-line-api";
import UserMapAlerts from "./user-covid-alerts";
import UserMapRiskLevel from "./user-covid-risklevel";
import UserMapPerson from "./user-covid-person";
import * as UserService from "../../../services/user-service";
import travellerApi from "../../../redux/api/travellers-api";
import ShowAssistModal from "./show-assist-modal";
import * as Permission from "../../../services/permissions";
import moment from "moment";
import config from "../../../../../config";
import b2cApi from "../../../redux/api/b2c-api";

let riskLevelColors = {
  Low: "#fde5bd",
  Moderate: "#fbb977",
  Medium: "#fa915a",
  High: "#fa6740",
  Extreme: "#f93a2b",
};

let vCountryRiskDataMap = {};
const { TabPane } = Tabs;

{
  /*
  var mapStyles = {
  container: {
    position: "relative",
    width: "100%",
    height: "100%"
  },
  map: {
    position: "relative",
    left: 0,
    right: 0,
    bottom: 0,
    top: 0
  }
};
*/
}

const refs = {
  map: undefined,
  circle: undefined,
  polygon: undefined,
  mapProjection: null,
  alertInfoWindow: undefined,
};

class UserMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stores: [],
      icons: {},
      showingInfoWindow: false, //Hides or the shows the infoWindow
      activeMarker: {}, //Shows the active marker upon click
      travelerInfo: {}, //Shows the infoWindow to the selected traveler upon a marker
      infoWindowPosition: null,
      showingMarker: false,
      isFlight: true,
      isPerson: true,
      isAlert: true,
      cloneStore: [],
      tabPosition: "right",
      tabIndex: 1,
      visible: false,
      countriesListByRiskLevel: [],
      countryRiskDataMap: {},
      countriesList: [],
      alertStore: [],
      personStore: [],
      selectedCountries: "",
      selectedRiskLevels: "",
      selectedCategoryName: "",
      selectedAlertTypeName: "",
      categoryList: [],
      loading: false,
      drawerVisible: false,
      shownRiskLevelsArray: ["Low", "Moderate", "Medium", "High", "Extreme"],
      selectedDuration: "1d",
      userid: 0,
      isRiskLevel: true,
      isLowRiskLevelsChecked: true,
      isModerateRiskLevelsChecked: true,
      isMediumRiskLevelsChecked: true,
      isHighRiskLevelsChecked: true,
      isExtremeRiskLevelsChecked: true,
      isShowAssistModal: false,
      currentTravelerInfo: "",
    };
  }

  onCloseDrawer = () => {
    this.setState({
      drawerVisible: false,
    });
  };

  showDrawer = () => {
    if (Permission.isRole("Membership") || Permission.isRole("Employee")) {
      if (
        JSON.parse(localStorage.getItem("user")).isActiveMembership === false
      ) {
        this.showModal();
      } else {
        this.setState({
          drawerVisible: true,
        });
      }
    } else {
      this.setState({
        drawerVisible: true,
      });
    }
  };

  onClickAssist = () => {
    if (Permission.isRole("Membership") || Permission.isRole("Employee")) {
      if (
        JSON.parse(localStorage.getItem("user")).isActiveMembership === false
      ) {
        this.showModal();
      } else {
        this.setState({ isShowAssistModal: true });
      }
    } else {
      this.setState({ isShowAssistModal: true });
    }
  };

  async componentDidMount() {
    this.setState({ loading: true });

    let currentUser = UserService.getUser();
    await b2cApi
      .getTravellerInfo(currentUser.userID)
      .then((response) =>
        this.setState({ currentTravelerInfo: response.data })
      );

    await API.getAllCovidData()
      .then((resp) => {
        console.log(resp);
        var tempCountryArrayByRiskLevel = [];
        var tempCountryArray = [];
        var countryData = {};
        resp.map(
          (r, index) => (
            (countryData[r.country_code] = r),
            (tempCountryArrayByRiskLevel[r.country_code] = r.covid_risk_level),
            tempCountryArray.push({
              countryName: r.country_name,
              code: r.country_code,
            })
          )
        );
        this.setStyle(
          refs.map,
          true,
          tempCountryArrayByRiskLevel,
          this.state.shownRiskLevelsArray
        );
        this.setState({
          countriesListByRiskLevel: tempCountryArrayByRiskLevel,
          countriesList: tempCountryArray,
          countryRiskDataMap: countryData,
        });
        vCountryRiskDataMap = countryData;
      })
      .catch((ex) => {});

    await riskLineApi
      .risklineAlerts({
        countries: {},
        riskLevel: {},
        filters: { days: "1d" },
        pagination: { page: 1, pageSize: 1000 },
        sorter: {},
      })
      .then((riskAlerts) => {
        this._bindCordinates(riskAlerts.data);
        //this.setState({ alertStore: alertsArray });
      })
      .catch((ex) => {
        this.setState({ loading: false });
      });

    await riskLineApi
      .getRiskLineCategories()
      .then((res) => {
        this.setState({
          categoryList: res.categories,
        });
      })
      .catch((ex) => {});
  }

  _bindCordinates(riskAlerts) {
    var tempCordinateArray = [];
    var alertArray = [];
    riskAlerts.map((alert, index) => {
      if (alert.geo) {
        var alertIconName =
          //alert.risk_level.toLowerCase() +
          "grey-" + alert.category_name.replace(/\s+/g, "-").toLowerCase();
        alert["type"] = "alert";
        alert["icon"] = alertIconName + ".png"; //change icon names to new icons for alerts

        tempCordinateArray[index] = alert;
      }
      return alert;
    });
    var j = 0;
    tempCordinateArray.map((riskalert, i) => {
      alertArray[j] = riskalert;
      j++;
      return riskalert;
    });

    this.setState({
      alertStore: alertArray,
      cloneStore: alertArray,
      loading: false,
    });
  }

  update = (e) => {
    if (e.target.checked) {
      if ("alert" === e.target.name) {
        this.setState({ isAlert: true });
      }
      if ("person" === e.target.name) {
        this.setState({ isPerson: true });
      }
      if ("flight" === e.target.name) {
        this.setState({ isFlight: true });
      } else if ("risklevel" === e.target.name) {
        this.setStyle(
          refs.map,
          true,
          this.state.countriesListByRiskLevel,
          this.state.shownRiskLevelsArray
        );
        this.setState({
          isLowRiskLevelsChecked: true,
          isModerateRiskLevelsChecked: true,
          isMediumRiskLevelsChecked: true,
          isHighRiskLevelsChecked: true,
          isExtremeRiskLevelsChecked: true,
          isRiskLevel: true,
        });
      }
    } else {
      if ("alert" === e.target.name) {
        this.setState({ isAlert: false });
      }
      if ("person" === e.target.name) {
        this.setState({ isPerson: false });
      }
      if ("flight" === e.target.name) {
        this.setState({ isFlight: false });
      }
      if ("risklevel" === e.target.name) {
        this.setStyle(refs.map, false);
        this.setState({
          isLowRiskLevelsChecked: false,
          isModerateRiskLevelsChecked: false,
          isMediumRiskLevelsChecked: false,
          isHighRiskLevelsChecked: false,
          isExtremeRiskLevelsChecked: false,
          isRiskLevel: false,
        });
      }
    }
  };

  handleChange = (code) => {
    if (code === "all") {
      this.setState({ loading: true, selectedCountries: "" });
    } else {
      this.setState({ loading: true, selectedCountries: code });
    }
    this.getRiskLineAlertsByFilter(code, "", "", "", "");
  };

  handleRiskLevelChange = (risklevel) => {
    this.setState({ loading: true, selectedRiskLevels: risklevel.key });
    this.getRiskLineAlertsByFilter("", risklevel.key, "", "", "");
  };

  handleDurationChange = (time) => {
    this.setState({ loading: true, selectedDuration: time.key });
    this.getRiskLineAlertsByFilter("", "", time.key, "", "");
  };

  handleCategoryChange = (catname) => {
    if (catname === "all") {
      this.setState({ loading: true, selectedCategoryName: "" });
    } else {
      this.setState({ loading: true, selectedCategoryName: catname });
    }
    this.getRiskLineAlertsByFilter("", "", "", catname, "");
  };

  handleShowNoticesChange = (checked) => {
    var alertType;
    if (checked) {
      alertType = "Notices";
    } else {
      alertType = "All";
    }
    this.setState({ loading: true, selectedAlertTypeName: alertType });
    this.getRiskLineAlertsByFilter("", "", "", "", alertType);
  };

  onFilterRiskLevelColor = (levels, checked, name) => {
    if (levels.length === 5) {
      this.setState({ isRiskLevel: true });
    } else {
      this.setState({ isRiskLevel: false });
    }
    if (name === "Low") {
      this.setState({ isLowRiskLevelsChecked: checked });
    } else if (name === "Moderate") {
      this.setState({ isModerateRiskLevelsChecked: checked });
    } else if (name === "Medium") {
      this.setState({ isMediumRiskLevelsChecked: checked });
    } else if (name === "High") {
      this.setState({ isHighRiskLevelsChecked: checked });
    } else if (name === "Extreme") {
      this.setState({ isExtremeRiskLevelsChecked: checked });
    }
    this.setState({ shownRiskLevelsArray: levels });
    this.setStyle(refs.map, true, this.state.countriesListByRiskLevel, levels);
  };

  handleResetAlertFilter = () => {
    this.setState({
      loading: true,
      selectedCountries: "",
      selectedRiskLevels: "",
      selectedCategoryName: "",
      selectedAlertTypeName: "",
      selectedDuration: "1d",
    });
    riskLineApi
      .risklineAlerts({
        filters: {
          countries: "",
          riskLevel: "",
          days: "1d",
          categoryName: "",
          type: "",
        },
        pagination: { page: 1, pageSize: 1000 },
        sorter: {},
      })
      .then((riskAlerts) => {
        this._bindCordinates(riskAlerts.data);
      })
      .catch((ex) => {
        this.setState({ loading: false });
      });
  };

  getRiskLineAlertsByFilter(ccode, risklevel, duration, category, type) {
    riskLineApi
      .risklineAlerts({
        filters: {
          countries:
            ccode === "all" ? "" : ccode ? ccode : this.state.selectedCountries,
          riskLevel: risklevel ? risklevel : this.state.selectedRiskLevels,
          days: duration ? duration : this.state.selectedDuration,
          categoryName:
            category === "all"
              ? ""
              : category
              ? category
              : this.state.selectedCategoryName,
          type: type ? type : this.state.selectedAlertTypeName,
        },
        pagination: { page: 1, pageSize: 1000 },
        sorter: {},
      })
      .then((riskAlerts) => {
        this._bindCordinates(riskAlerts.data);
      })
      .catch((ex) => {
        this.setState({ loading: false });
      });
  }

  autoCenterMap = ({ google, countryRiskDataMap }, map) => {
    refs.map = map;
    map.data.loadGeoJson(config.API1 + "/images/custom.geo.json");
    //map.data.loadGeoJson("https://dev.api.captravelassistance.com/images/custom.geo.json"); //using prod json for testing
    var areaInfoWindow;
    map.data.addListener("mouseover", function(event, countryRiskDataMap) {
      if (event.feature.getProperty("wb_a2")) {
        console.log(event.feature.getProperty("name"));
      }
      if (areaInfoWindow != null) {
        areaInfoWindow.close();
        areaInfoWindow = null;
      }
      var countryName = event.feature.getProperty("name");
      var riskLevel =
        vCountryRiskDataMap[event.feature.getProperty("wb_a2")]
          .covid_risk_level;
      var controlMeasure =
        vCountryRiskDataMap[event.feature.getProperty("wb_a2")].policy_status;
      var testing =
        vCountryRiskDataMap[event.feature.getProperty("wb_a2")].testing_test;
      var flightBan =
        vCountryRiskDataMap[event.feature.getProperty("wb_a2")].flight_ban;
      var entryBan =
        vCountryRiskDataMap[event.feature.getProperty("wb_a2")].entry_ban;
      var quarantineOnEntry =
        vCountryRiskDataMap[event.feature.getProperty("wb_a2")].quarantine_who;
      var windowContentString =
        "<h4>" +
        countryName +
        "</h4>" +
        "<br><b>COVID-19 Risk Level: </b>" +
        riskLevel +
        "</br>" +
        "<br><strong>National control measures: </strong>" +
        controlMeasure +
        "</br>" +
        "<br><b>Testing: </b>" +
        testing +
        "</br>" +
        "<br><b>Flight ban: </b>" +
        flightBan +
        "</br>" +
        "<br><b>Entry ban: </b>" +
        entryBan +
        "</br>" +
        "<br><b>Quarantine on entry: </b>" +
        quarantineOnEntry +
        "</br>";
      areaInfoWindow = new google.maps.InfoWindow({
        content: windowContentString,
        position: event.latLng,
      });
      areaInfoWindow.open(refs.map);
    });
    map.data.addListener("click", function(event) {});
  };

  setStyle = (map, apply, cData, shownRiskLevelsArray) => {
    refs.mapProjection = map.getProjection();
    /* @type {google.maps.Data.StyleOptions} */
    if (apply) {
      map.data.setStyle(function(feature) {
        console.log("feature: ", feature);
        var color = "";
        var fillOpacity = 0;
        if (feature.getProperty("wb_a2")) {
          shownRiskLevelsArray.map((risklevel) => {
            if (risklevel === cData[feature.getProperty("wb_a2")]) {
              color = riskLevelColors[cData[feature.getProperty("wb_a2")]];
              fillOpacity = 1.0;
            }
            return risklevel;
          });
        }
        return ({
          fillColor: color,
          strokeColor: "#ffffff", //white boarder around the countries
          fillOpacity: fillOpacity,
          strokeWeight: 1,
        });
      });
    } else {
      map.data.setStyle({ fillOpacity: 0, strokeWeight: 0 });
    }
  };

  handleCancelAssistModal = () => {
    this.setState({ isShowAssistModal: false });
  };

  closeTravelerInfoWindow = (alerInfoWindow) => {
    // Close Alert InfoWindow Start
    if (refs.alertInfoWindow && refs.alertInfoWindow !== alerInfoWindow) {
      refs.alertInfoWindow.close();
    }
    // Close Alert InfoWindow End
    refs.alertInfoWindow = alerInfoWindow;
    this.setState({ showingInfoWindow: false });
  };

  onPersonMarkerClick = (props, marker, e) => {
    // Close Alert InfoWindow Start
    if (refs.alertInfoWindow) {
      refs.alertInfoWindow.close();
    }
    // Close Alert InfoWindow End
    this.setState({
      travelerInfo: props.travelerInfo,
      activeMarker: marker,
      showingInfoWindow: true,
    });
  };

  handlePersonClick = (data) => {
    this.setState({
      activeMarker: null,
      showingInfoWindow: true,
      travelerInfo: data,
      infoWindowPosition: new window.google.maps.LatLng(
        data.latitude,
        data.longitude
      ),
    });
  };

  handleSingleAlertListClick = (alert) => {
    // Close Alert InfoWindow Start
    if (refs.alertInfoWindow) {
      refs.alertInfoWindow.close();
    }
    // Close Alert InfoWindow End
    var infowindow = new window.google.maps.InfoWindow();
    infowindow.setContent(
      "<div class='markerAlretWindow' style = 'width:200px;min-height:100px'><ul><li>" +
        alert.title +
        "</li><li> Date: " +
        moment(new Date(alert.startDate * 1000).toUTCString()).format(
          "MM/DD/YYYY"
        ) +
        "</li><li>Location: " +
        alert.location_explanation +
        "</li></ul</div>"
    );
    infowindow.setPosition({ lat: alert.geo.lat, lng: alert.geo.lng });
    infowindow.open(refs.map);
    refs.alertInfoWindow = infowindow;
  };

  showModal = () => {
    this.setState({
      planExp: true,
    });
  };
  handleOk = (e) => {
    this.setState({
      planExp: false,
    });
  };
  redirectPlanRenewalPage = () => {
    //this.props.history.push("/cap/purchase");
    window.location.href = "https://www.captravelassistance.com/";
  };
  HidePopup = () => {
    this.setState({ planExp: false });
  };
  render() {
    return (
      <div className="UserMap">
        <Modal
          title="Plan Expired."
          visible={this.state.planExp}
          onOk={this.redirectPlanRenewalPage}
          onCancel={this.HidePopup}
          footer={
            Permission.isRole("Membership") ? (
              [
                <Button key="cancel" onClick={this.HidePopup}>
                  Cancel
                </Button>,
                <Button key="submit" onClick={this.redirectPlanRenewalPage}>
                  Buy Now
                </Button>,
              ]
            ) : (
              <Button key="cancel" onClick={this.HidePopup}>
                Ok
              </Button>
            )
          }
        >
          {Permission.isRole("Employee") ? (
            <p>
              You do not have any CAP plan assigned. Please contact your
              division manager / travel manager.
            </p>
          ) : (
            <p>Your CAP plan has been expired. Please buy a new plan.</p>
          )}
        </Modal>
        <Spin size="large" spinning={this.state.loading}>
          <Row gutter={24}>
            <Col sm={18}>{}</Col>
          </Row>
          <Row gutter={24}>
            <Col sm={24}>
              <div
                style={{ height: "75vh", width: "100%", position: "relative" }}
              >
                <Map
                  google={this.props.google}
                  countryRiskDataMap={this.state.countryRiskDataMap}
                  zoom={3}
                  minZoom={2}
                  styles={this.props.mapStyles}
                  onReady={this.autoCenterMap}
                >
                  {this.state.isAlert && this.state.alertStore.length > 0 && (
                    <MarkerCluster
                      onAlertClick={this.closeTravelerInfoWindow}
                      markers={this.state.alertStore}
                      imagePath={
                        "https://captravelassistance.test2.redblink.net/wp-content/uploads/2021/05/clusterm" //new cluster image url
                      }
                    ></MarkerCluster>
                  )}
                  {this.state.isPerson &&
                    /* this.state.currentTravelerInfo.productName ===
                      "CAP™ Advantage" && */
                    this.state.currentTravelerInfo.isActiveMembership ===
                      true &&
                    this.state.personStore &&
                    this.state.personStore.length > 0 &&
                    this.state.personStore.map((marker) => (
                      <Marker
                        onClick={this.onPersonMarkerClick}
                        travelerInfo={marker}
                        icon={{
                          url: config.API1 + "/travel-icons/person.png",
                          scaledSize: new window.google.maps.Size(32, 33),
                        }}
                        position={{
                          lat: marker.latitude,
                          lng: marker.longitude,
                        }}
                      />
                    ))}

                  <InfoWindow
                    marker={this.state.activeMarker}
                    visible={this.state.showingInfoWindow}
                    position={this.state.infoWindowPosition}
                  >
                    <div>{this.state.travelerInfo.travelerName}</div>
                  </InfoWindow>
                </Map>
              </div>

              <Button
                style={{
                  position: "absolute",
                  right: "75px",
                  top: "10px",
                  width: "90px",
                  height: "40px",
                  zIndex: "100",
                  fontWeight: "bold",
                }}
                className="shadow"
                type="primary"
                onClick={this.showDrawer}
              >
                Filters
              </Button>
            </Col>
          </Row>
          <Row gutter={24} className="legend-row">
            <Col>
              <ul className="covid-legend-risk">
                <li className="low">
                  <i class="fas fa-circle"></i> Low Risk
                </li>
                <li className="modrate">
                  <i class="fas fa-circle"></i> Moderate Risk
                </li>
                <li className="medium">
                  <i class="fas fa-circle"></i> Medium Risk
                </li>
                <li className="high">
                  <i class="fas fa-circle"></i> High Risk
                </li>
                <li className="extreme">
                  <i class="fas fa-circle"></i> Extreme Risk
                </li>
              </ul>
            </Col>
          </Row>
          <Drawer
            title="Filters"
            placement="right"
            width={640}
            mask={false}
            closable={true}
            onClose={this.onCloseDrawer}
            visible={this.state.drawerVisible}
          >
            <Tabs tabPosition={this.state.tabPosition}>
              <TabPane tab="Risklevel" key="2">
                <UserMapRiskLevel
                  isLowRiskLevelsChecked={this.state.isLowRiskLevelsChecked}
                  isModerateRiskLevelsChecked={
                    this.state.isModerateRiskLevelsChecked
                  }
                  isMediumRiskLevelsChecked={
                    this.state.isMediumRiskLevelsChecked
                  }
                  isHighRiskLevelsChecked={this.state.isHighRiskLevelsChecked}
                  isExtremeRiskLevelsChecked={
                    this.state.isExtremeRiskLevelsChecked
                  }
                  isRiskLevel={this.state.isRiskLevel}
                  onChangeRiskLevelCheckBox={this.update}
                  onCheckUncheckRiskLevel={this.onFilterRiskLevelColor}
                  shownRiskLevelsArray={this.state.shownRiskLevelsArray}
                />
              </TabPane>
              {/* {this.state.personStore && this.state.personStore.length > 0 && ( */}

              {/* )} */}
            </Tabs>
          </Drawer>
        </Spin>
        {this.state.isShowAssistModal && (
          <ShowAssistModal
            onCancelAssistModal={this.handleCancelAssistModal}
            travelerInfo={this.state.currentTravelerInfo}
          />
        )}
      </div>
    );
  }
}
export default GoogleApiWrapper({
  apiKey: "AIzaSyAA_ayr9aMRSAPIugacjp_CL6S5ux_N4is",
})(UserMap);

UserMap.defaultProps = {
  mapStyles: [
    {
      elementType: "geometry",
      stylers: [
        {
          color: "#f5f5f5",
        },
      ],
    },
    {
      elementType: "labels",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      elementType: "labels.icon",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#616161",
        },
      ],
    },
    {
      elementType: "labels.text.stroke",
      stylers: [
        {
          color: "#f5f5f5",
        },
      ],
    },
    {
      featureType: "administrative",
      elementType: "geometry",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "administrative.land_parcel",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#bdbdbd",
        },
      ],
    },
    {
      featureType: "administrative.neighborhood",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "poi",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "poi",
      elementType: "geometry",
      stylers: [
        {
          color: "#eeeeee",
        },
      ],
    },
    {
      featureType: "poi",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#757575",
        },
      ],
    },
    {
      featureType: "poi.park",
      elementType: "geometry",
      stylers: [
        {
          color: "#e5e5e5",
        },
      ],
    },
    {
      featureType: "poi.park",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#9e9e9e",
        },
      ],
    },
    {
      featureType: "road",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "road",
      elementType: "geometry",
      stylers: [
        {
          color: "#ffffff",
        },
      ],
    },
    {
      featureType: "road",
      elementType: "labels.icon",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "road.arterial",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#757575",
        },
      ],
    },
    {
      featureType: "road.highway",
      elementType: "geometry",
      stylers: [
        {
          color: "#dadada",
        },
      ],
    },
    {
      featureType: "road.highway",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#616161",
        },
      ],
    },
    {
      featureType: "road.local",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#9e9e9e",
        },
      ],
    },
    {
      featureType: "transit",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "transit.line",
      elementType: "geometry",
      stylers: [
        {
          color: "#e5e5e5",
        },
      ],
    },
    {
      featureType: "transit.station",
      elementType: "geometry",
      stylers: [
        {
          color: "#eeeeee",
        },
      ],
    },
    {
      featureType: "water",
      elementType: "geometry",
      stylers: [
        {
          color: "#c9c9c9",
        },
      ],
    },
    {
      featureType: "water",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#9e9e9e",
        },
      ],
    },
  ],
};
