import React, { Component } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel
} from "react-accessible-accordion";
export default class TravelConcierge extends Component {
  render() {
    return (
      <tr>
        <td colSpan="3">
          <Accordion>
            <table>
              <tbody>
                <tr>
                  <td colSpan="3" className="title">
                    <b>Travel Concierge Assistance Services</b>
                  </td>
                </tr>
              </tbody>
            </table>
            <table>
              <tbody>
                <tr className="border">
                  <td>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          24/7 Unlimited Live Assistance
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p>
                        We provide CAP™ customers on-demand advice from experienced multilingual travel
assistance consultants from our International Crisis Response Centers, including dedicated
emergency response hotlines.
                        </p>
                      </AccordionItemPanel>
                    </AccordionItem>
                  </td>
                  <td data-label="Core CAP™">
                    <i className="fas fa-check"></i>
                  </td>
                  <td data-label="CAP+MED™">
                    <i className="fas fa-check"></i>
                  </td>
                  {/* <td data-label="CAP™ Advantage">
                    <i className="fas fa-check"></i>
                  </td> */}
                </tr>
              </tbody>
            </table>
            <table>
              <tbody>
                <tr className="border">
                  <td>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          Lost Document Advice and Assistance
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p>
                          We provide assistance, advice, and support for CAP™
                          customers that have lost their travel documents during
                          their trip.
                        </p>
                      </AccordionItemPanel>
                    </AccordionItem>
                  </td>
                  <td data-label="Core CAP™">
                    <i className="fas fa-check"></i>
                  </td>
                  <td data-label="CAP+MED™">
                    <i className="fas fa-check"></i>
                  </td>
                  {/* <td data-label="CAP™ Advantage">
                    <i className="fas fa-check"></i>
                  </td> */}
                </tr>
              </tbody>
            </table>
            <table>
              <tbody>
                <tr className="border">
                  <td>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          Translation and Interpretation Services
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p>
                          We provide CAP™ customers with professional
                          translation and interpreter services in all major
                          languages.
                        </p>
                      </AccordionItemPanel>
                    </AccordionItem>
                  </td>
                  <td data-label="Core CAP™">
                    <i className="fas fa-check"></i>
                  </td>
                  <td data-label="CAP+MED™">
                    <i className="fas fa-check"></i>
                  </td>
                  {/* <td data-label="CAP™ Advantage">
                    <i className="fas fa-check"></i>
                  </td> */}
                </tr>
              </tbody>
            </table>
            <table>
              <tbody>
                <tr className="border">
                  <td>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          Emergency Message Transmission
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p>
                          We can transmit a CAP™ customer’s urgent message to
                          their family, friends, and business associates.
                          Messages can be retained for up to 15 days after the
                          conclusion of the incident.
                        </p>
                      </AccordionItemPanel>
                    </AccordionItem>
                  </td>
                  <td data-label="Core CAP™">
                    <i className="fas fa-check"></i>
                  </td>
                  <td data-label="CAP+MED™">
                    <i className="fas fa-check"></i>
                  </td>
                  {/* <td data-label="CAP™ Advantage">
                    <i className="fas fa-check"></i>
                  </td> */}
                </tr>
              </tbody>
            </table>
            <table>
              <tbody>
                <tr className="border">
                  <td>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          Legal Referrals
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p>
                          We provide CAP™ customers with access to vetted, legal
                          resources, and referrals for professional support
                          worldwide.
                        </p>
                      </AccordionItemPanel>
                    </AccordionItem>
                  </td>
                  <td data-label="Core CAP™">
                    <i className="fas fa-check"></i>
                  </td>
                  <td data-label="CAP+MED™">
                    <i className="fas fa-check"></i>
                  </td>
                  {/* <td data-label="CAP™ Advantage">
                    <i className="fas fa-check"></i>
                  </td> */}
                </tr>
              </tbody>
            </table>
          </Accordion>
        </td>
      </tr>
    );
  }
}
