import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as OrgActions from "../../../redux/actions/organization-actions";
import OrgAPI from "../../../redux/api/organization-api";
// import RolesAPI from "../../../../../redux/api/roles-api";
import * as UserActions from "../../../redux/actions/user-actions";
import * as paginationActions from "../../../redux/actions/pagination-actions";
import IntlMessages from "../../../services/intlMesseges";
import { notifyUser } from "../../../services/notification-service";
import {
  Switch,
  Modal,
  Spin,
  Popover,
  Popconfirm,
  Table,
  Icon,
  Input,
  Button,
  Row,
  Col,
  Typography,
  Upload,
  message,
  // Divider,
  Tooltip,
  Drawer,
  Form,
  Select,
  Radio
} from "antd";
import { EditCell } from "../../shared/components/helperCells";
import config from "../../../../../config";
import * as UserServices from "../../../services/user-service";
import * as Permission from "../../../services/permissions";
import Axios from "axios";
import OrgTree from "../../shared/components/orgTree";
import AppLocale from "../../../languageProvider";
const { Option } = Select;
const { confirm } = Modal;

class EditOrganizationAgents extends Component {
  constructor(props){
    super(props);
    this.module = 'Partner';
    
    let currentUser = localStorage.getItem("user");
    let OrgID = "";
    if (currentUser !== "") {
      currentUser = JSON.parse(currentUser);
      OrgID = currentUser.companyID;
    }

    this.state = {
      userStatus: [],
      OrgID : OrgID ,
      currentPlanToUnassign: '',
      confirmDeactivateEmployeeVisible: false,
      disableAssignButton: false,
      assigningLicense: false,
      loadMoreOrg: true,
      uploadingEmployees: false,
      orgPagerCurrent: 1,
      showAssignPlan: true,
      editPlanVisible: false,
      employeesLoaded: false,
      isBulkUploadModalVisible: false,
      bulkUploadInstance: {
        sendEmailForNew:true,
        isAssignMembership: false,
        file: null,
        product: -1,
        uploadFileName: ""
      },
      loading: true,
      data: [],
      pagination: {
        pageSize: 10,
        current: 1,
        hideOnSinglePage: true,
        showTotal: (total, range) => {
          return (
            <span>
              <IntlMessages id="admin.text.showing" /> {range[0]}-{range[1]}{" "}
              <IntlMessages id="admin.text.of" /> {total}{" "}
              <IntlMessages id="admin.text.results" />
            </span>
          );
        }
      },
      filters: {}
    }
  }
  
  getSelectedFilterValue = (index) => {
    return this.props.paginginfo[this.module]&& this.props.paginginfo[this.module].filter && this.props.paginginfo[this.module].filter[index] || null;
  }



  
  csvUploadProps = () => {
    const { language } = this.props;
    const currentAppLocale = AppLocale[language.locale];
    let _this = this;
    return {
      name: "file",
      showUploadList: false,
      accept: ".csv",
      beforeUpload: file => {
        let ext = file.name.split(".");
        ext = ext.pop();
        if(ext === "csv"){
          let _bulkUploadInstance = _this.state.bulkUploadInstance;
          _bulkUploadInstance.file = file;
          _bulkUploadInstance.uploadFileName = file.name;
          _this.setState({ bulkUploadInstance: _bulkUploadInstance });
        } else {
          message.error(currentAppLocale.messages["admin.employees.bulkupload.error.csvRequired"]);
          let _bulkUploadInstance = _this.state.bulkUploadInstance;
          _bulkUploadInstance.file = null;
          _bulkUploadInstance.uploadFileName = "";
          _this.setState({ bulkUploadInstance: _bulkUploadInstance });
        }
        return false;
      },
      onStart: _file => {
        _this.setState({ uploadingEmployees: true });
      }
    };
  };

  getHeaderKeys = () => {
    return [
      {
        title: <IntlMessages id="admin.userlisting.agentCode" />,
        dataIndex: "agentCode",
        filteredValue : this.getSelectedFilterValue('agentCode'),
        ...this.getColumnSearchProps("agentCode")
      }, {
        title: <IntlMessages id="admin.userlisting.firstName" />,
        dataIndex: "firstName",
        filteredValue : this.getSelectedFilterValue('firstName'),
        ...this.getColumnSearchProps("firstName")
      },
      {
        title: <IntlMessages id="admin.userlisting.lastName" />,
        dataIndex: "lastName",
        filteredValue : this.getSelectedFilterValue('lastName'),
        ...this.getColumnSearchProps("lastName")
      },
      {
        title: <IntlMessages id="admin.userlisting.email" />,
        dataIndex: "userName",
        filteredValue : this.getSelectedFilterValue('userName'),
        ...this.getColumnSearchProps("userName")
      },
      {
        title: <IntlMessages id="admin.userlisting.phone" />,
        dataIndex: "contactNo"
      },
      {
        title: <IntlMessages id="admin.userlisting.status" />,
        render: (_text, record) => (
          <span>
            <Switch
              checkedChildren={<IntlMessages id="admin.status.active" />}
              unCheckedChildren={<IntlMessages id="admin.status.inactive" />}
              checked={record.isActive}
              onClick={() =>
                this.confirmEmployeeStatusToggle(
                  this.state.userStatus[record.userId]
                    ? !this.state.userStatus[record.userId]
                    : !record.isActive,
                  record.userId,
                  record.memberPlan && record.memberPlan.licenseNumber ? record.memberPlan.licenseNumber : null
                )
              }
            />
          </span>
        )
      },
      {
        title: <IntlMessages id="admin.userlisting.action" />,
        rowKey: "action",
        render: (text, record) => (
          <span>
            <EditCell
              onEditCell={() => {
                this.editItem(record.userId);
              }}
            />

           </span>
        )
      }
    ];
  };

  confirmEmployeeStatusToggle = async (selected, userId, plan) => {
    let self = this;
    const { language } = this.props;
    const currentAppLocale = AppLocale[language.locale];
    if(selected === false){
      if(plan !== null && plan !== ""){
        self.setState({ 
          confirmDeactivateEmployeeVisible: true,
          currentPlanToUnassign: plan,
          currentSelectedEmployee: userId,
          currentSelectedEmployeeStatus: selected
        });
        confirm({
          title: 'Confirmation',
          content: currentAppLocale.messages["admin.confirm.unassignplan"]+'`'+plan+'`?',
          className:"gray-color",
          onOk() {
            self.submitDeactivateEmployee(self.state.currentSelectedEmployeeStatus, self.state.currentSelectedEmployee);
          },
          onCancel() {
            self.cancelDeactivateEmployee();
          }
        });
      } else {
        self.setState({ 
          confirmDeactivateEmployeeVisible: false,
          currentPlanToUnassign: '',
          currentSelectedEmployee: null,
          currentSelectedEmployeeStatus: null
        });
        self.updateEmployeeStatus(selected, userId);
      }
    } else {
      this.setState({ 
        confirmDeactivateEmployeeVisible: false,
        currentPlanToUnassign: '',
        currentSelectedEmployee: null,
        currentSelectedEmployeeStatus: null
      });
      this.updateEmployeeStatus(selected, userId);
    }
  };

  updateEmployeeStatus = async (selected, userId) => {
    try {
      this.props.updateStatus(userId, selected).then(response => {
        const { language } = this.props;
        const currentAppLocale = AppLocale[language.locale];
        if (response.data && response.data.message) {
          let _userStatus = this.state.userStatus || [];
          _userStatus[userId] = selected;
          this.setState({
            userStatus: _userStatus,
            confirmDeactivateEmployeeVisible: false,
            currentPlanToUnassign: '',
            currentSelectedEmployee: null,
            currentSelectedEmployeeStatus: null
          });
          notifyUser(response.data.message, "success");
          if(this.props.paginginfo && this.props.paginginfo[this.module]){
            this.handleTableChange(this.props.paginginfo[this.module].pagination, this.props.paginginfo[this.module].filter, {},true);
            if(this.props.paginginfo[this.module].filters){
            let filters = this.props.paginginfo[this.module].filters
            Object.keys(filters).map(k=> {filters[k].auto = false});
              this.setState({filters :  filters});
            }
          } else {
            this.handleTableChange({ current: 1, pageSize: 10 }, {}, {}, true);
          }
        } else {
          notifyUser(currentAppLocale.messages["admin.unknown.error"], "error");
          this.setState({ 
            confirmDeactivateEmployeeVisible: false,
            currentPlanToUnassign: '',
            currentSelectedEmployee: null,
            currentSelectedEmployeeStatus: null
          });
        }
      });
    } catch (e) {
      console.log("Error:", e);
    }
  };

  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => {
      return (
        <div style={{ padding: 8 }}>
          <Input
            ref={node => {
              this.searchInput = node;
            }}
            value={selectedKeys}
            onChange={e =>
              setSelectedKeys(e.target.value ? e.target.value.trimLeft() : "")
            }
            onPressEnter={() =>
              this.handleSearch(
                selectedKeys,
                confirm,
                dataIndex,
                clearFilters,
                setSelectedKeys
              )
            }
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Button
            type="primary"
            onClick={() =>
              this.handleSearch(
                selectedKeys,
                confirm,
                dataIndex,
                clearFilters,
                setSelectedKeys
              )
            }
            disabled={selectedKeys != "" && selectedKeys !== null ? false : true}
            icon="search"
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            <IntlMessages id="admin.search" />
          </Button>
          <Button
            disabled={selectedKeys != "" && selectedKeys !== null ? false : true}
            onClick={() => this.handleReset(clearFilters, dataIndex)}
            size="small"
            style={{ width: 90 }}
          >
            <IntlMessages id="admin.reset" />
          </Button>
        </div>
      );
    },
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    }
  });

  handleSearch = (
    selectedKeys,
    confirm,
    dataIndex,
    clearFilters,
    setSelectedKeys
  ) => {
    if (selectedKeys === "") {
      return false;
    }

    this.setState({ searchText: selectedKeys });
    let filters = this.state.filters;
    filters[dataIndex] = {
      val: selectedKeys,
      clearf: clearFilters,
      filter: true,
      setSelectedKeys: setSelectedKeys,
      confirm: confirm,
      auto:true
    };
    this.setState({ filters: filters });
    this.props.updateFilters({module:this.module, filters: filters});
    confirm();
  };

  handleReset = (clearFilters, dataIndex) => {
    clearFilters();
    let filters = this.state.filters;
    if (filters[dataIndex]) {
      if(filters[dataIndex].setSelectedKeys && typeof filters[dataIndex].setSelectedKeys === 'function'){
        filters[dataIndex].setSelectedKeys("");
        //filters[dataIndex].confirm();
      }
    }
    if(filters[dataIndex] && !filters[dataIndex].auto){
      delete this.props.paginginfo[this.module].filter[dataIndex];
      this.handleTableChange({ current: 1, pageSize: 10 }, this.props.paginginfo[this.module].filter, {});
   
    }
    filters[dataIndex] = { val: "", clearf: "", filter: false };
    this.setState({ filters: filters });
    this.props.updateFilters({module: this.module, filters:  filters})
    this.setState({ searchText: "" });
  };

  async componentDidMount() {
      this.handleTableChange({ current: 1, pageSize: 10 }, {}, {});
      if(this.props.paginginfo && this.props.paginginfo[this.module]){
        this.handleTableChange(this.props.paginginfo[this.module].pagination, this.props.paginginfo[this.module].filter, {},true);
        if(this.props.paginginfo[this.module].filters){
        let filters = this.props.paginginfo[this.module].filters
        Object.keys(filters).map(k=> {filters[k].auto = false});
          this.setState({filters :  filters});
        }
      } else {
        this.handleTableChange({ current: 1, pageSize: 10 }, {}, {}, true);
      }
  }

  editItem = id => {
    this.props.history.push("./" + id + "/");
  };

  handleTableChange = (pagination, filters, sorter, manual) => {
    if(filters === undefined) filters={};
    Object.keys(filters).map( key => { if((!filters[key]) || (Array.isArray(filters[key]) && filters[key].length===0)) { delete filters[key] }} )
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    if(manual !== true)
    {
      this.props.updatePaginationRemoveOld({module:this.module, filter: filters,
      pagination: { current: pagination.current, pageSize: pagination.pageSize }})
    }
    this.setState({ loading: true });
    
    this.props
      .getAgentListing({
        orgId: this.state.OrgID,
        filters: filters,
        pagination: { page: pagination.current, pageSize: pagination.pageSize },
        sorter: sorter
      })
      .then(resp => {
        pager.total = resp.paging.totalRecords;
        this.setState({
          loading: false,
          data: resp.data,
          pagination: pager
        });
      })
      .catch(ex => {
        this.setState({ loading: false });
      });
  };

  cancelBulkUploadEmployees = () => {
    let _bulkUploadInstance = this.state.bulkUploadInstance;
    _bulkUploadInstance.product = -1;
    _bulkUploadInstance.file = null;
    _bulkUploadInstance.uploadFileName = "";
    _bulkUploadInstance.isAssignMembership = false;
    _bulkUploadInstance.sendEmailForNew = false;
    this.setState({ 
      isBulkUploadModalVisible: false,
      bulkUploadInstance: _bulkUploadInstance 
    });
  }

  initBulkUploadEmployees = () => {
    const { language } = this.props;
    const currentAppLocale = AppLocale[language.locale];
    let _this = this;
    let file = _this.state.bulkUploadInstance.file;
    
    if(file === null){
      message.error(currentAppLocale.messages["admin.fileupload.error.chooseFile"]);
    }
    //  else if(_this.state.bulkUploadInstance.isAssignMembership === true && parseInt(_this.state.bulkUploadInstance.product) === -1){
    //   message.error(currentAppLocale.messages["admin.employees.bulkupload.error.membershipProduct"]);
    // } 
    else {
      let ext = file.name.split(".");
      ext = ext.pop();
      if (ext === "csv") {
        let token = UserServices.getToken();
        let _headers = { "Content-Type": "multipart/form-data" };
        if (token){
          _headers["authorization"] = token ? "Bearer " + token : undefined;
        }
        const formData = new FormData();
        formData.append("file", file);
        //formData.append("productId", _this.state.bulkUploadInstance.product);
        //formData.append("assignMembership", _this.state.bulkUploadInstance.isAssignMembership);
        Axios({
            method: "post",
            //url: config.API1 + "/api/BulkUploadUsers/" + _this.props.match.params.id+"/"+_this.state.bulkUploadInstance.product+"/"+_this.state.bulkUploadInstance.isAssignMembership+"/"+_this.state.bulkUploadInstance.sendEmailForNew,
            url: config.API1 +  "/api/BulkUploadOrganizationAgents/" +  _this.state.OrgID +"/"+_this.state.bulkUploadInstance.sendEmailForNew,
            data: formData,
            headers: _headers
          })
          .then(function(_response) {
            _this.setState({ uploadingEmployees: false });
            if (_response.errors) {
              notifyUser(currentAppLocale.messages["admin.error.fileUploadFailed"], "error");
            } else {
              notifyUser(_response.data.data.message, "success");
              _this.handleTableChange({ current: 1, pageSize: 10 }, {}, {});
              let _bulkUploadInstance = _this.state.bulkUploadInstance;
              _bulkUploadInstance.product = -1;
              _bulkUploadInstance.file = null;
              _bulkUploadInstance.uploadFileName = "";
              _bulkUploadInstance.isAssignMembership = false;
              _bulkUploadInstance.sendEmailForNew = false;
              _this.setState({ 
                isBulkUploadModalVisible: false,
                bulkUploadInstance: _bulkUploadInstance 
              });
            }
          })
          .catch(function(_response) {
            if (_response.response && _response.response.data) {
              notifyUser(_response.response.data.Message, "error");
            } else {
              notifyUser(currentAppLocale.messages["admin.error.fileUploadFailed"], "error");
            }
            _this.setState({ uploadingEmployees: false });
            _this.handleTableChange({ current: 1, pageSize: 10 }, {}, {});
          });
      } else {
        message.error(currentAppLocale.messages["admin.employees.bulkupload.error.csvRequired"]);
      }
    }
  }

   removeBulkUploadFile = () => {
    let _bulkUploadInstance = this.state.bulkUploadInstance;
    _bulkUploadInstance.uploadFileName = "";
    _bulkUploadInstance.file = null;
    this.setState({ bulkUploadInstance: _bulkUploadInstance });
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    let _state = this.state;
    let _this = this;
    let filtertag = Object.keys(this.state.filters).map(function(key1) {
      let keyLabel = _this.getHeaderKeys().find(el => el.dataIndex === key1);
      if (keyLabel.title.props && keyLabel.title.props.id) {
        keyLabel = <IntlMessages id={keyLabel.title.props.id} />;
      } else {
        keyLabel = key1;
      }
      if (_state.filters[key1].filter) {
        return (
          <span>
            <Button
              key={key1}
              type="primary"
              onClick={() =>
                _this.handleReset(_state.filters[key1].clearf, key1)
              }
            >
              {keyLabel} : {_state.filters[key1].val}
              <Icon type="close" />
            </Button>
            &nbsp;
          </span>
        );
      }
    });
    let parentOrg = this.props.match.params.orgid
      ? this.props.match.params.orgid
      : this.props.match.params.id;
    let defaultParentOrg = this.props.match.params.id
      ? this.props.match.params.id
      : this.props.match.params.orgid
      ? this.props.match.params.orgid
      : this.props.match.params.divid;
    if (Permission.isRole("Division Manager")) {
      let currentUser = localStorage.getItem("user");
      if (currentUser !== "") {
        currentUser = JSON.parse(currentUser);
        parentOrg = currentUser.companyID;
      }
      defaultParentOrg = this.props.match.params.id
        ? this.props.match.params.id
        : parentOrg;
    }
    return (
      <div>
        <Modal
          title={<IntlMessages id="admin.warning.rolemissing"/>}
          closable={false}
          visible={this.state.assignRoleWarning}
          onOk={this.hideAssignRoleWarning}
          cancelButtonProps={{ style: { display: "none" } }}
        >
          <p><IntlMessages id="admin.warning.assignrole"/></p>
        </Modal>
        { <Modal
          title={<IntlMessages id="admin.agents.bulkupload.modal.heading"/>}
          closable={false}
          visible={this.state.isBulkUploadModalVisible}
          footer={[
            <Button onClick={this.cancelBulkUploadEmployees}><IntlMessages id="admin.button.cancel"/></Button>,
            <Button onClick={this.initBulkUploadEmployees} type="primary"><IntlMessages id="admin.button.submit"/></Button>
          ]}
        >
          <Row gutter={24} style={{ marginBottom: "20px" }}>
            <Col xs={24} sm={24} md={24}>
              <label><strong><IntlMessages id="admin.employees.bulkupload.modal.selectFile" />:</strong></label><br/>
              <Upload {...this.csvUploadProps()}>
                <Button type="default">
                  <Icon type="upload" />{" "}
                  <IntlMessages id="admin.upload.chooseFile" />
                </Button>
              </Upload>
              <span>
                {
                  this.state.bulkUploadInstance.uploadFileName !== "" ? 
                    <div>
                      <br/>
                      {this.state.bulkUploadInstance.uploadFileName}
                      <button title="Remove" className="icon" onClick={this.removeBulkUploadFile}><i className="fas fa-trash"></i></button>
                    </div>
                  : 
                  ""
                }
              </span>
            </Col>
          </Row>
       
        </Modal> }
   
        <Spin
          tip={<IntlMessages id="admin.loading.pleaseWait" />}
          spinning={this.state.uploadingEmployees}
        >
          <Row gutter={24}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <Typography.Title level={4} style={{ float: "left" }}>
                <IntlMessages id="admin.organizations.actions.manageagents" />
              </Typography.Title>
            </Col>
           
          </Row>
          <hr />
          <Row gutter={24}>
            <Col sx={24} sm={24} md={24} lg={24} xl={24} style={{textAlign:"right"}}>

              <ul className="upload-xlx">
                {/* <li>
                  {" "}
                  <Button onClick={() => this.downloadSampleCsv()}>
                    <Icon type="download" />{" "}
                    <IntlMessages id="admin.organization.download.csv" />
                  </Button>
                </li> */}
                <li>
                  <Button onClick={() => this.setState({ isBulkUploadModalVisible: true })}>
                    <Icon type="upload" />{" "}
                    <IntlMessages id="admin.organization.upload.agents.csv" />
                  </Button>
                </li>
                <li>
                  <Button type="primary" onClick={() => this.editItem("new")}>
                    <IntlMessages id="admin.organization.add.agent" />
                  </Button>
                </li>
                <li>
                  <Button
                    type="primary"
                    className=""
                    htmlType="button"
                    onClick={() => this.props.history.goBack()}
                  >
                    <IntlMessages id="admin.userlisting.back" />
                  </Button>
                </li>
              </ul>
            </Col>
          </Row>
          <Row gutter={24} style={{marginBottom:"20px", marginTop:"20px"}}>
            <Col xs={12} sm={12} md={24} lg={24} xl={24}>
              {filtertag}
            </Col>
           
          </Row>
       

          <Row gutter={24}>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={24}
              style={{ overflow: "auto" }}
            >
              <Table
                columns={this.getHeaderKeys()}
                rowKey={record => record.userId}
                dataSource={this.state.data}
                pagination={this.state.pagination}
                loading={this.state.loading}
                onChange={this.handleTableChange}
              />
            </Col>
          </Row>
        </Spin>
      </div>
    );
  }
}

EditOrganizationAgents.propTypes = {
  location: PropTypes.object,
  getAgentListing: PropTypes.func
};
function mapStateToProps(state) {
  return {
    ...state.user,
    ...state.language,
    ...state.pagination
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...OrgActions, ...UserActions, ...paginationActions }, dispatch);
}
const WrappedForm = Form.create({ name: "edit-agent-plan" })(
  EditOrganizationAgents
);
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    WrappedForm
  )
);
