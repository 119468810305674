import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import IntlMessages from "../../../../services/intlMesseges";
import API from "../../../../redux/api/pricing-api";
import * as PricingActions from "../../../../redux/actions/pricing-actions";
import { notifyUser } from "../../../../services/notification-service";
import {
  Table,
  Row,
  Col,
  Typography,
  Button,
  Spin,
  Form,
  Input,
  Select
} from "antd";
const { Option } = Select;
const EditableContext = React.createContext();

const EditableRow = ({ form, index, ...props }) => (
  <EditableContext.Provider value={form}>
    <tr {...props} />
  </EditableContext.Provider>
);

const EditableFormRow = Form.create()(EditableRow);

class EditableCell extends React.Component {
  state = {
    editing: false
  };

  toggleEdit = () => {
    const editing = !this.state.editing;
    this.setState({ editing }, () => {
      if (editing) {
        this.input.focus();
      }
    });
  };

  save = e => {
    const { record, handleSave } = this.props;
    this.form.validateFields((error, values) => {
      if (error && error[e.currentTarget.id]) {
        return;
      }
      this.toggleEdit();
      handleSave({ ...record, ...values });
    });
  };

  renderCell = form => {
    this.form = form;
    const { children, dataIndex, record, title } = this.props;
    const { editing } = this.state;
    return editing ? (
      <Form.Item style={{ margin: 0 }}>
        {form.getFieldDecorator(dataIndex, {
          rules: [
            {
              required: true,
              message: `${title} is required.`
            }
          ],
          initialValue: record[dataIndex]
        })(
          <Input
            ref={node => (this.input = node)}
            onPressEnter={this.save}
            onBlur={this.save}
          />
        )}
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{ paddingRight: 24 }}
        onClick={this.toggleEdit}
      >
        {children}
      </div>
    );
  };

  render() {
    const {
      editable,
      dataIndex,
      title,
      record,
      index,
      handleSave,
      children,
      ...restProps
    } = this.props;
    return (
      <td {...restProps}>
        {editable ? (
          <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>
        ) : (
          children
        )}
      </td>
    );
  }
}
class BasePricing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDerivedRow: "none",
      selectedDerivedFrom: "-select-",
      loading: true,
      defaultDataSource: {},
      dataSource: [],
      columns: [],
      formData: [],
      pricingFor: [
        {
          id: 1,
          name: "RawPrice"
        },
        {
          id: 2,
          name: "RetailPrice"
        }
      ],
      pricingTypes: [
        {
          id: 1,
          name: "Base"
        },
        {
          id: 2,
          name: "Derivative"
        }
      ],
      operators: [
        {
          id: 1,
          value: "*"
        },
        {
          id: 2,
          value: "/"
        }
      ],
      defaultOperator: "*",
      pricingList: []
    };
  }

  async componentDidMount() {
    let _this = this;
    this.setState({
      defaultDataSource: this.props.priceList,
      formData: this.props.priceList,
      priceTypeTab:
        this.props.priceList.calculationFor == "RawPrice" ? "#raw" : "#retail"
    });
    console.log("Base: ",this.props.priceList);
    let tempDataObj = {},
      tempDataArr = [],
      tempColumnsObj = {},
      tempColumnsArr = [];
      let stateRisk = false;
      if(this.props.priceList.priceType === 2) {
        stateRisk = true; 
        }
    this.props.priceList.pricingInfo.map(function(row, _idx) {
      if (tempDataObj[row.tierId]) {
        tempDataObj[row.tierId]["day" + row.dayPackage] = parseFloat(
          Math.round(row.price * 100) / 100
        ).toFixed(2);
      } else {
        tempDataObj[row.tierId] = {};
        tempDataObj[row.tierId]["day" + row.dayPackage] = parseFloat(
          Math.round(row.price * 100) / 100
        ).toFixed(2);
      }
      tempDataObj[row.tierId].key = row.tierId;
      if(stateRisk && row.tierId === 1) {
        tempDataObj[row.tierId].tier = "High Risk";
      } else if (stateRisk && row.tierId === 2) {
        tempDataObj[row.tierId].tier = "Low Risk";
      } else if(!stateRisk){
      tempDataObj[row.tierId].tier = row.tierId;
      }

      /* temporary columns */
      tempColumnsObj["tier"] = {};
      tempColumnsObj["tier"].title = "Tier";
      tempColumnsObj["tier"].dataIndex = "tier";
      // tempColumnsObj["tier"].fixed = "left";
      // tempColumnsObj["tier"].width = 60;

      if (tempColumnsObj["day" + row.dayPackage]) {
        tempColumnsObj["day" + row.dayPackage].title = row.dayPackage + " days";
        tempColumnsObj["day" + row.dayPackage].dataIndex =
          "day" + row.dayPackage;
        tempColumnsObj["day" + row.dayPackage].editable = true;
        //tempColumnsObj["day" + row.dayPackage].width = 100;
      } else {
        tempColumnsObj["day" + row.dayPackage] = {};
        tempColumnsObj["day" + row.dayPackage].title = row.dayPackage + " days";
        tempColumnsObj["day" + row.dayPackage].dataIndex =
          "day" + row.dayPackage;
        tempColumnsObj["day" + row.dayPackage].editable = true;
        // tempColumnsObj["day" + row.dayPackage].width = 100;
      }
    });
    Object.keys(tempDataObj).forEach(prop => {
      if (tempDataObj[prop]) {
        tempDataArr.push(tempDataObj[prop]);
      }
    });

    Object.keys(tempColumnsObj).forEach(prop => {
      if (tempColumnsObj[prop]) {
        tempColumnsArr.push(tempColumnsObj[prop]);
      }
    });

    let tempPricingList = await API.getPricing({
      filters: {},
      pagination: {},
      sorter: {}
    });

    let tempPricingList1 = [];
    tempPricingList.data.map(function(item) {
      //if(item.calculationType == "Base"){
      tempPricingList1.push(item);
      //}
      if (item.name === _this.props.priceList.derivedPrice) {
        _this.setState({
          selectedDerivedFrom: item.pricingListId
        });
      }
    });
    this.setState({
      dataSource: tempDataArr,
      columns: tempColumnsArr,
      pricingList: tempPricingList1,
      loading: false
    });
  }

  submitFormAdd = data => {
    console.log("in progress...");
    this.setState({ loading: false });
    console.log("Data:", data);
    /* this.props
      .addPricing(data)
      .then(response => {
        if(response.error){
          notifyUser(response.error.message, 'error');
          this.setState({ loading: false });
        } else {
          notifyUser("User added successfully!", 'success');
          this.props.history.push("./");
          this.setState({ loading: false });
        }
      })
      .catch(err => {
        this.setState({ loading: false });
      }); */
  };

  submitFormEdit = data => {
    let postData = [],
      tempResponseArr = [],
      _this = this;
    postData = data;
    postData.pricingInfo = [];
    _this.state.defaultDataSource.pricingInfo.map(function(row, _idx) {
      _this.state.dataSource.map(function(innerRow, _innerIdx) {
        let rowData = Object.entries(innerRow);
        for (const [key, value] of rowData) {
          if(innerRow.tier === "High Risk") {
            innerRow.tier = 1;
          } else if(innerRow.tier === "Low Risk") {
            innerRow.tier = 2;
          }
          if (
            key.indexOf("day") > -1 &&
            parseInt(key.replace("day", "")) === parseInt(row.dayPackage) &&
            parseInt(innerRow.tier) === parseInt(row.tierId) 
          ) {
            let tempResponseObj = {};
            tempResponseObj.pricingId = row.pricingId;
            tempResponseObj.priceListId = row.priceListId;
            tempResponseObj.tierId = row.tierId;
            tempResponseObj.dayPackageId = row.dayPackageId;
            tempResponseObj.price = value;
            tempResponseObj.isActive = row.isActive;
            tempResponseObj.effectiveDate = row.effectiveDate;
            tempResponseObj.disableDate = row.disableDate;
            tempResponseObj.dayPackage = row.dayPackage;
            
            tempResponseArr.push(tempResponseObj);
          }
        }
      });
    });
    
    postData.pricingInfo = tempResponseArr;
    delete postData.derivedPriceListId;
    this.props
      .updatePricing(postData)
      .then(response => {
        if (response.error) {
          notifyUser(response.error.message, "error");
          this.setState({ loading: false });
        } else {
          this.props.history.push("./../view/" + postData.pricingListId);
          this.setState({ loading: false });
          notifyUser("Pricing updated successfully!", "success");
        }
      })
      .catch(_err => {
        this.setState({ loading: false });
      });
  };

  handleSubmit = e => {

    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        Object.keys(values).map(function(key, _idx) {
          if(values[key] == null){
            values[key] = '';
          } else {
            if(typeof values[key] == 'string'){
              values[key] = values[key].trim();
            }
          }
        });
        this.setState({ loading: true });
        if (
          this.state.formData.pricingListId &&
          this.state.formData.pricingListId > 0
        ) {
          values["pricingListId"] = this.state.formData.pricingListId;
          values["priceType"] = this.state.defaultDataSource.priceType;
          
          this.submitFormEdit(values);
        } else {
          this.submitFormAdd(values);
        }
      }
    });
  };

  handleSave = row => {
    const newData = [...this.state.dataSource];
    const index = newData.findIndex(item => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row
    });
    this.setState({ dataSource: newData });
  };

  onPriceTypeChange = selected => {
    if (selected === 1) {
      //base price
      this.setState({ showDerivedRow: "none" });
    } else {
      this.setState({ showDerivedRow: "block" });
    }
  };

  render() {
    const { dataSource, columns, formData } = this.state;
    const components = {
      body: {
        row: EditableFormRow,
        cell: EditableCell
      }
    };
    const { getFieldDecorator } = this.props.form;
    const { formLayout } = this.state;
    const formItemLayout =
      formLayout === "horizontal"
        ? {
            labelCol: { span: 4 },
            wrapperCol: { span: 14 }
          }
        : null;
    const selectedPriceType = this.state.pricingTypes.find(
      item => item.name === formData.calculationType
    );
    const pricingTypeSelector = getFieldDecorator("priceCalcTypeId", {
      initialValue: selectedPriceType ? selectedPriceType.id : 0
    })(
      <Select onChange={this.onPriceTypeChange}>
        {this.state.pricingTypes.map(function(item) {
          return (
            <Option key={item.id} value={item.id}>
              {item.name}
            </Option>
          );
        })}
      </Select>
    );
    /* const pricingForSelector = getFieldDecorator("calculationFor", {
            initialValue: formData.calculationFor,
          })(<Select >{this.state.pricingFor.map(function(item) {
                return <Option key={item.name}>{item.name}</Option>;
            })}</Select>); */
    /* const derivedFromSelector = getFieldDecorator("derivedPriceListId", {
      initialValue: this.state.selectedDerivedFrom
    })(
      <Select>
        {this.state.pricingList.length > 0
          ? this.state.pricingList.map(function(item) {
              if (item.name !== formData.name) {
                return (
                  <Option key={item.pricingListId} value={item.pricingListId}>
                    {item.description} ({item.calculationFor})
                  </Option>
                );
              }
            })
          : ""}
      </Select>
    ); */
    const operatorSelector = getFieldDecorator("arithmeticOperator", {
      rules: [
        {
          required: true,
          message: <IntlMessages id="admin.input.required" />
        }
      ],
      initialValue:
        formData.arithmeticOperator === null
          ? this.state.defaultOperator
          : formData.arithmeticOperator
    })(
      <Select>
        {this.state.operators.map(function(item) {
          return <Option key={item.value}>{item.value}</Option>;
        })}
      </Select>
    );
    const columnsList = columns.map(col => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: record => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave: this.handleSave
        })
      };
    });
    return (
      <section>
        <Row gutter={24}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Typography.Title level={4}>
              {formData.description} ({formData.name})
            </Typography.Title>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Button
              type="primary"
              className="right-fl"
              htmlType="button"
              onClick={() =>
                this.props.history.push("./../" + this.state.priceTypeTab)
              }
            >
              <IntlMessages id="admin.button.back" />
            </Button>
          </Col>
        </Row>
        <hr />
        <div>
          <Spin spinning={this.state.loading}>
            <Form layout={formLayout} onSubmit={this.handleSubmit}>
              <Row gutter={24}>
                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                  <Form.Item
                    {...formItemLayout}
                    label={<IntlMessages id="admin.pricingview.name" />}
                  >
                    {getFieldDecorator("name", {
                      rules: [
                        {
                          required: true,
                          message: <IntlMessages id="admin.input.required" />
                        }
                      ],
                      initialValue: formData.name === null ? "" : formData.name
                    })(<Input />)}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                  <Form.Item
                    {...formItemLayout}
                    label={<IntlMessages id="admin.pricingview.description" />}
                  >
                    {getFieldDecorator("description", {
                      rules: [
                        {
                          required: true,
                          message: <IntlMessages id="admin.input.required" />
                        }
                      ],
                      initialValue:
                        formData.description === null
                          ? ""
                          : formData.description
                    })(<Input />)}
                  </Form.Item>
                </Col>
                {/* <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                  <Form.Item
                    {...formItemLayout}
                    label={<IntlMessages id="admin.pricingview.type" />}
                  >
                    {pricingTypeSelector}
                  </Form.Item>
                </Col> */}

                {getFieldDecorator("calculationFor", {
                  initialValue: formData.calculationFor
                })(<Input type="hidden" />)}

                {/* <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                  <Form.Item
                    {...formItemLayout}
                    label={<IntlMessages id="admin.pricingview.calculationFor" />}
                  >
                    {pricingForSelector}
                  </Form.Item>
                </Col> */}

                {/* <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                  <Form.Item
                    {...formItemLayout}
                    label={<IntlMessages id="admin.pricingview.derivedPrice" />}
                  >
                    {derivedFromSelector}
                  </Form.Item>
                </Col> */}
              </Row>
              <Row gutter={24} style={{ display: this.state.showDerivedRow }}>
                
                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                  <Form.Item
                    {...formItemLayout}
                    label={<IntlMessages id="admin.pricingview.operator" />}
                  >
                    {operatorSelector}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                  <Form.Item
                    {...formItemLayout}
                    label={<IntlMessages id="admin.pricingview.operand" />}
                  >
                    {getFieldDecorator("operand", {
                      rules: [
                        {
                          required: true,
                          message: <IntlMessages id="admin.input.required" />
                        }
                      ],
                      initialValue:
                        formData.operand === null ? "" : formData.operand
                    })(<Input />)}
                  </Form.Item>
                </Col>
              </Row>
              <hr />

              <Row gutter={24}>
                <Col xs={24}>
                  <span
                    style={{
                      color: "red",
                      paddingBottom: "10px",
                      display:
                        this.state.showDerivedRow === "block" ? "none" : "block"
                    }}
                  >
                    <IntlMessages id="admin.clickto.editprice" />
                  </span>
                </Col>
              </Row>
              <Row gutter={24} className="pricing-table">
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={24}
                  style={{ overflowX: "auto" }}
                >
                  <Table
                    components={components}
                    rowClassName={() => "editable-row"}
                    bordered
                    dataSource={dataSource}
                    pagination={false}
                    columns={columnsList}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Item>
                    <Button
                      type="primary"
                      style={{ display: "inline-block", marginRight: "10px" }}
                      className="def-blue"
                      htmlType="submit"
                      onClick={this.handleSubmit}
                    >
                      {formData.pricingListId > 0 ? (
                        <IntlMessages id="admin.pricingview.update" />
                      ) : (
                        <IntlMessages id="admin.pricingview.add" />
                      )}
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Spin>
        </div>
      </section>
    );
  }
}
BasePricing.propTypes = {
  priceList: PropTypes.object,
  updatePricing: PropTypes.func
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...PricingActions }, dispatch);
}
const WrappedForm = Form.create({ name: "edit-pricing" })(BasePricing);
export default withRouter(
  connect(null, mapDispatchToProps, null, { forwardRef: true })(WrappedForm)
);
