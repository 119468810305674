

export const cadConvertionRate = (totalPrice)  =>{
     //  new cad conversion
 // let currentPrice = this.state.totalPrice / decodedParams.traveller_count;
  // var convertCadPriceList = {"79" :99.00 , "152" : 197.00 ,"298" :385.00 , "385":498.00};
  var convertCadPriceList = {
    '79': 99.0,
    '152': 197.00,
    '298': 385.00,
    '385': 498.00,
    '117': 155.00,
    '137': 182.00,
    '247': 325.00,
    '327': 432.00,
    '397': 525.00,
    '497': 655.00,
    '997': 1315.00
  };
  let cadPrice = 0;
    for(const [key , value] of Object.entries(convertCadPriceList)){
    if(key == parseInt(totalPrice)){
          cadPrice = value;
    
    }
  }
  
  return cadPrice.toFixed(2);
}
