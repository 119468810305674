import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { notification } from "antd";
import { FirebaseConfig } from "../../../firebase-config";
import * as NotificationActions from "../redux/actions/notifications-actions";
import * as UserService from "../services/user-service";
import Config from "../../../config";

class FCMNotifier extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fcmLoaded: false,
      isPlayingAudio: false
    };
    this.audioUrl = Config.API1+"/images/alarm.wav";
    this.audio = new Audio(this.audioUrl);
    this.tokenGenerated = false;
  }

  async componentDidMount() {
    let self = this;
    let currentUser = UserService.getUser();
    if((!this.props.fcmActive || this.props.fcmActive !== true) && (currentUser.userID && currentUser.role === 'CSR' && this.state.fcmLoaded !== true) && this.tokenGenerated === false){
      if(FirebaseConfig){
        FirebaseConfig
          .requestPermission()
          .then(async function() {
            const token = await FirebaseConfig.getToken();
            self.tokenGenerated = true;
            console.log("ft: ", token)
            let args = {
              uniqueId: token,
              deviceType: 2
            };
            await self.props.registerFCMDevice(currentUser.userID, args).then(_r => {
              self.registerPushListener();
              self.setState({ fcmLoaded: true });
            });
          })
          .catch(function(err) {
            console.log("Unable to get permission to notify.", err);
          });
        }
    }
  }
  
  registerPushListener = () => {
    let self = this;
    navigator.serviceWorker.addEventListener("message", ({ data }) => {
      let title = data.data ? data.data.title : data["firebase-messaging-msg-data"].data.title;
      let description = data.data ? data.data.body : data["firebase-messaging-msg-data"].data.body;
      if(title !== undefined && description !== undefined && title.toLowerCase().indexOf("assist") > -1){
        self.pushNotification(title, description);
      }
    })
  }

  pushNotification = (notificationTitle, notificationDescription) => {
    this.showNotification(notificationTitle, notificationDescription);
  };

  showNotification = (_title, _description) => {
    const args = {
      message: _title,
      description: _description,
      duration: 0,
    };
    notification.open(args);
    if(this.state.isPlayingAudio !== true){
      this.audio.play();
      this.setState({ isPlayingAudio: true });
    }
    this.audio.onended = () => {
      this.setState({ isPlayingAudio: false });
    }
  };
  
  render() {
    return (
      <div></div>
    )
  }
}
function mapStateToProps(state) {
  return {
    ...state.notification
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...NotificationActions }, dispatch);
}
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    FCMNotifier
  )
);