import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as OrgActions from "../../../../../redux/actions/organization-actions";
import OrgAPI from "../../../../../redux/api/organization-api";
// import RolesAPI from "../../../../../redux/api/roles-api";
import * as UserActions from "../../../../../redux/actions/user-actions";
import * as paginationActions from "../../../../../redux/actions/pagination-actions";
import IntlMessages from "../../../../../services/intlMesseges";
import { notifyUser } from "../../../../../services/notification-service";
import {
  Switch,
  Modal,
  Spin,
  Popover,
  Popconfirm,
  Table,
  Icon,
  Input,
  Button,
  Row,
  Col,
  Typography,
  Upload,
  message,
  // Divider,
  Tooltip,
  Drawer,
  Form,
  Select,
  Radio,
} from "antd";
import { EditCell , ViewCell} from "../../../../shared/components/helperCells";
import config from "../../../../../../../config";
import * as UserServices from "../../../../../services/user-service";
import * as Permission from "../../../../../services/permissions";
import Axios from "axios";
import OrgTree from "../../../../shared/components/orgTree";
import AppLocale from "../../../../../languageProvider";
import axios from "axios";
const { Option } = Select;
const { confirm } = Modal;

class EditOrganizationEmployees extends Component {
  constructor(props) {
    super(props);
    this.module = "employees";
    this.state = {
      userStatus: [],
      currentPlanToUnassign: "",
      confirmDeactivateEmployeeVisible: false,
      disableAssignButton: false,
      assigningLicense: false,
      loadMoreOrg: true,
      uploadingEmployees: false,
      orgPagerCurrent: 1,
      licenseIdDisabled: true,
      allTotalLicenses: 0,
      allAssignedLicenses: 0,
      licenseTotals: [],
      currentOrganization: 0,
      currentProduct: null,
      currentProductName: "",
      currentLicense: null,
      currentLicenseName: "",
      currentEmployee: null,
      showAssignPlan: true,
      editPlanVisible: false,
      employeesLoaded: false,
      isBulkUploadModalVisible: false,
      bulkUploadInstance: {
        sendEmailForNew: false,
        isAssignMembership: false,
        file: null,
        product: -1,
        uploadFileName: "",
      },
      organizationProducts: [],
      organizationProductsInfo: [],
      organizationLicenses: [],
      loading: false,
      data: [],
      pagination: {
        pageSize: 10,
        current: 1,
        hideOnSinglePage: true,
        showTotal: (total, range) => {
          return (
            <span>
              <IntlMessages id="admin.text.showing" /> {range[0]}-{range[1]}{" "}
              <IntlMessages id="admin.text.of" /> {total}{" "}
              <IntlMessages id="admin.text.results" />
            </span>
          );
        },
      },
      filters: {},
    };
  }

  getSelectedFilterValue = (index) => {
    return (
      (this.props.paginginfo[this.module] &&
        this.props.paginginfo[this.module].filter &&
        this.props.paginginfo[this.module].filter[index]) ||
      null
    );
  };

  csvUploadProps = () => {
    const { language } = this.props;
    const currentAppLocale = AppLocale[language.locale];
    let _this = this;
    return {
      name: "file",
      showUploadList: false,
      accept: ".csv",
      beforeUpload: (file) => {
        let ext = file.name.split(".");
        ext = ext.pop();
        if (ext === "csv") {
          let _bulkUploadInstance = _this.state.bulkUploadInstance;
          _bulkUploadInstance.file = file;
          _bulkUploadInstance.uploadFileName = file.name;
          _this.setState({ bulkUploadInstance: _bulkUploadInstance });
        } else {
          message.error(
            currentAppLocale.messages[
              "admin.employees.bulkupload.error.csvRequired"
            ]
          );
          let _bulkUploadInstance = _this.state.bulkUploadInstance;
          _bulkUploadInstance.file = null;
          _bulkUploadInstance.uploadFileName = "";
          _this.setState({ bulkUploadInstance: _bulkUploadInstance });
        }
        return false;
      },
      onStart: (_file) => {
        _this.setState({ uploadingEmployees: true });
      },
    };
  };

  getHeaderKeys = () => {
    return [
      {
        title: <IntlMessages id="admin.userlisting.firstName" />,
        dataIndex: "firstName",
        filteredValue: this.getSelectedFilterValue("firstName"),
        ...this.getColumnSearchProps("firstName"),
      },
      {
        title: <IntlMessages id="admin.userlisting.lastName" />,
        dataIndex: "lastName",
        filteredValue: this.getSelectedFilterValue("lastName"),
        ...this.getColumnSearchProps("lastName"),
      },
      {
        title: <IntlMessages id="admin.userlisting.email" />,
        dataIndex: "userName",
        filteredValue: this.getSelectedFilterValue("userName"),
        ...this.getColumnSearchProps("userName"),
      },
      {
        title: <IntlMessages id="admin.userlisting.phone" />,
        dataIndex: "contactNo",
      },
      {
        title: <IntlMessages id="admin.employeeList.division" />,
        dataIndex: "divison",
        filteredValue: this.getSelectedFilterValue("divison"),
        ...this.getColumnSearchProps("divison"),
      },
      {
        title: <IntlMessages id="admin.employeesList.role" />,
        dataIndex: "role",
        filteredValue: this.getSelectedFilterValue("role"),
        ...this.getColumnSearchProps("role"),
      },
      {
        title: <IntlMessages id="admin.assign.plan.name" />,
        render: (text, record) => (
          <span>
            {record.memberProduct && record.memberProduct !== null
              ? record.memberProduct.productName
              : "N/A"}
          </span>
        ),
      },
      {
        title: <IntlMessages id="admin.employeesList.assigned.plan" />,
        render: (text, record) => (
          <span>
            {record.memberPlan && record.memberPlan !== null
              ? record.memberPlan.licenseNumber
              : "N/A"}
          </span>
        ),
      },
      {
        title: <IntlMessages id="admin.userlisting.status" />,
        render: (_text, record) => (
          <span>
            <Switch
              checkedChildren={<IntlMessages id="admin.status.active" />}
              unCheckedChildren={<IntlMessages id="admin.status.inactive" />}
              checked={record.isActive}
              onClick={() =>
                this.confirmEmployeeStatusToggle(
                  this.state.userStatus[record.userId]
                    ? !this.state.userStatus[record.userId]
                    : !record.isActive,
                  record.userId,
                  record.memberPlan && record.memberPlan.licenseNumber
                    ? record.memberPlan.licenseNumber
                    : null
                )
              }
            />
          </span>
        ),
      },
      {
        title: <IntlMessages id="admin.userlisting.action" />,
        rowKey: "action",
        render: (text, record) => (
         
          <span>
            <EditCell
              onEditCell={() => {
                this.editItem(record.userId);
              }}
            />

            {/* {record.role == null || record.role == "" ?
              <button className="icon" onClick={() => this.showAssignRoleWarning()}>
                <Tooltip title={<IntlMessages id="admin.employeesList.manage.plan" />}>
                  <i className="fas fa-id-card"></i>
                </Tooltip>
              </button>
            : */}
            <button
              style={{ opacity: record.isActive === true ? "1" : "0.5" }}
              disabled={!record.isActive}
              className="icon"
              onClick={() => this.showEditPlanDrawer(record.userId)}
            >
              <Tooltip
                title={<IntlMessages id="admin.employeesList.manage.plan" />}
              >
                <i className="fas fa-id-card"></i>
              </Tooltip>
            </button>
            
            {record.hasChildren == true ? 
            <button
            className="icon"
            onClick={() => this.handleChildList(record.userId)}
          >
            <Tooltip
            title={<IntlMessages id="admin.employeesList.view.child" />}
          >
            <i className="fas fa-list"></i>
          </Tooltip>
            </button> : ""
            }
            {/* } */}
            </span>
             ),
        
      },
     
      
    ];
  };
  
  handleChildList = (uid) => {
  const userid = uid;
  const currentPathname = this.props.location.pathname;
  const modifiedPathname = `${currentPathname}child-list/${userid}/`;
  this.props.history.push(modifiedPathname);
  };
  
  confirmEmployeeStatusToggle = async (selected, userId, plan) => {
    let self = this;
    const { language } = this.props;
    const currentAppLocale = AppLocale[language.locale];
    if (selected === false) {
      if (plan !== null && plan !== "") {
        self.setState({
          confirmDeactivateEmployeeVisible: true,
          currentPlanToUnassign: plan,
          currentSelectedEmployee: userId,
          currentSelectedEmployeeStatus: selected,
        });
        confirm({
          title: "Confirmation",
          content:
            currentAppLocale.messages["admin.confirm.unassignplan"] +
            "`" +
            plan +
            "`?",
          className: "gray-color",
          onOk() {
            self.submitDeactivateEmployee(
              self.state.currentSelectedEmployeeStatus,
              self.state.currentSelectedEmployee
            );
          },
          onCancel() {
            self.cancelDeactivateEmployee();
          },
        });
      } else {
        self.setState({
          confirmDeactivateEmployeeVisible: false,
          currentPlanToUnassign: "",
          currentSelectedEmployee: null,
          currentSelectedEmployeeStatus: null,
        });
        self.updateEmployeeStatus(selected, userId);
      }
    } else {
      this.setState({
        confirmDeactivateEmployeeVisible: false,
        currentPlanToUnassign: "",
        currentSelectedEmployee: null,
        currentSelectedEmployeeStatus: null,
      });
      this.updateEmployeeStatus(selected, userId);
    }
  };

  updateEmployeeStatus = async (selected, userId) => {
    try {
      this.props.updateStatus(userId, selected).then((response) => {
        const { language } = this.props;
        const currentAppLocale = AppLocale[language.locale];
        if (response.data && response.data.message) {
          let _userStatus = this.state.userStatus || [];
          _userStatus[userId] = selected;
          this.setState({
            userStatus: _userStatus,
            confirmDeactivateEmployeeVisible: false,
            currentPlanToUnassign: "",
            currentSelectedEmployee: null,
            currentSelectedEmployeeStatus: null,
          });
          notifyUser(response.data.message, "success");
          if (this.props.paginginfo && this.props.paginginfo[this.module]) {
            this.handleTableChange(
              this.props.paginginfo[this.module].pagination,
              this.props.paginginfo[this.module].filter,
              {},
              true
            );
            if (this.props.paginginfo[this.module].filters) {
              let filters = this.props.paginginfo[this.module].filters;
              Object.keys(filters).map((k) => {
                filters[k].auto = false;
              });
              this.setState({ filters: filters });
            }
          } else {
            this.handleTableChange({ current: 1, pageSize: 10 }, {}, {}, true);
          }
        } else {
          notifyUser(currentAppLocale.messages["admin.unknown.error"], "error");
          this.setState({
            confirmDeactivateEmployeeVisible: false,
            currentPlanToUnassign: "",
            currentSelectedEmployee: null,
            currentSelectedEmployeeStatus: null,
          });
        }
      });
    } catch (e) {
      console.log("Error:", e);
    }
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <div style={{ padding: 8 }}>
          <Input
            ref={(node) => {
              this.searchInput = node;
            }}
            value={selectedKeys}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? e.target.value.trimLeft() : "")
            }
            onPressEnter={() =>
              this.handleSearch(
                selectedKeys,
                confirm,
                dataIndex,
                clearFilters,
                setSelectedKeys
              )
            }
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Button
            type="primary"
            onClick={() =>
              this.handleSearch(
                selectedKeys,
                confirm,
                dataIndex,
                clearFilters,
                setSelectedKeys
              )
            }
            disabled={
              selectedKeys != "" && selectedKeys !== null ? false : true
            }
            icon="search"
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            <IntlMessages id="admin.search" />
          </Button>
          <Button
            disabled={
              selectedKeys != "" && selectedKeys !== null ? false : true
            }
            onClick={() => this.handleReset(clearFilters, dataIndex)}
            size="small"
            style={{ width: 90 }}
          >
            <IntlMessages id="admin.reset" />
          </Button>
        </div>
      );
    },
    filterIcon: (filtered) => (
      <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
  });

  handleSearch = (
    selectedKeys,
    confirm,
    dataIndex,
    clearFilters,
    setSelectedKeys
  ) => {
    if (selectedKeys === "") {
      return false;
    }

    this.setState({ searchText: selectedKeys });
    let filters = this.state.filters;
    filters[dataIndex] = {
      val: selectedKeys,
      clearf: clearFilters,
      filter: true,
      setSelectedKeys: setSelectedKeys,
      confirm: confirm,
      auto: true,
    };
    this.setState({ filters: filters });
    this.props.updateFilters({ module: this.module, filters: filters });
    confirm();
  };

  handleReset = (clearFilters, dataIndex) => {
    clearFilters();
    let filters = this.state.filters;
    if (filters[dataIndex]) {
      if (
        filters[dataIndex].setSelectedKeys &&
        typeof filters[dataIndex].setSelectedKeys === "function"
      ) {
        filters[dataIndex].setSelectedKeys("");
        //filters[dataIndex].confirm();
      }
    }
    if (filters[dataIndex] && !filters[dataIndex].auto) {
      delete this.props.paginginfo[this.module].filter[dataIndex];
      this.handleTableChange(
        { current: 1, pageSize: 10 },
        this.props.paginginfo[this.module].filter,
        {}
      );
    }
    filters[dataIndex] = { val: "", clearf: "", filter: false };
    this.setState({ filters: filters });
    this.props.updateFilters({ module: this.module, filters: filters });
    this.setState({ searchText: "" });
  };

  async componentDidMount() {
    //if(this.state.employeesLoaded === false){
    let orgProducts = await OrgAPI.getOrganizationProducts(
      this.props.match.params.orgid
        ? this.props.match.params.orgid
        : this.props.match.params.id
    );

    this.setState({
      organizationProducts: orgProducts.data,
      currentOrganization: this.props.match.params.id,
      employeesLoaded: true,
    });

    await OrgAPI.getOrganizationById(
      this.props.match.params.orgid
        ? this.props.match.params.orgid
        : this.props.match.params.id
    ).then((res) => {
      let _orgProducts = [];
      if (res.data.productInfo && res.data.productInfo.length > 0) {
        res.data.productInfo.map((item) => {
          let _item = orgProducts.data.find(
            (i) => parseInt(i.productId) === parseInt(item.productID)
          );
          if (_item && _item.productName) {
            item.productName = _item.productName;
            _orgProducts.push(item);
          }
        });
        this.setState({ organizationProductsInfo: _orgProducts });
      }
    });
    //this.handleTableChange({ current: 1, pageSize: 10 }, {}, {});
    if (this.props.paginginfo && this.props.paginginfo[this.module]) {
      this.handleTableChange(
        this.props.paginginfo[this.module].pagination,
        this.props.paginginfo[this.module].filter,
        {},
        true
      );
      if (this.props.paginginfo[this.module].filters) {
        let filters = this.props.paginginfo[this.module].filters;
        Object.keys(filters).map((k) => {
          filters[k].auto = false;
        });
        this.setState({ filters: filters });
      }
    } else {
      this.handleTableChange({ current: 1, pageSize: 10 }, {}, {}, true);
    }
    this.getUsedLicenseCount();
    //}
  }

  getUsedLicenseCount = () => {
    if (
      Permission.isTopLevelManager() &&
      Permission.canAccess("license_count")
    ) {
      this.props
        .getLicenseCount(this.props.match.params.id)
        .then((response) => {
          let _allTotalLicenses = 0,
            _allAssignedLicenses = 0;
          if (response.data && response.data.length) {
            response.data.map(function(el) {
              _allTotalLicenses += el.totalLicenses;
              _allAssignedLicenses += el.usedLicenses;
            });
          }
          this.setState({
            allTotalLicenses: _allTotalLicenses,
            allAssignedLicenses: _allAssignedLicenses,
            licenseTotals: response.data,
          });
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  editItem = (id) => {
    this.props.history.push("./" + id + "/");
  };

  onOrgLicenseChange = (_idx, licenseId) => {
    this.setState({
      currentLicense: licenseId.key === "undefined" ? null : licenseId.key,
    });
  };

  onOrgLicenseDeselect = () => {
    return;
  };

  onOrgProductChange = async (productId) => {
    this.setState(
      {
        currentProduct: productId,
        currentLicense: null,
        editPlanVisible: true,
        licenseIdDisabled: false,
      },
      () => {
        this.fetchOrgLicenses("");
        this.props.form.setFieldsValue({ licenseId: null });
      }
    );
  };

  showEditPlanDrawer = async (uid) => {
    let currentUser = this.state.data.find((el) => el.userId === uid);
    let data = {
      currentEmployee: uid,
      editPlanVisible: true,
    };

    if (currentUser.memberProduct && currentUser.memberProduct.productId) {
      let args = {
        orgId: this.state.currentOrganization,
        productId: currentUser.memberProduct.productId,
        filters: {},
        pagination: { page: 1, pageSize: 10 },
        sorter: {},
      };
      let orgLicenses = await OrgAPI.getOrganizationLicensesByProduct(args);
      data.currentProduct = currentUser.memberProduct.productId;
      data.currentProductName = currentUser.memberProduct.productName;
      data.organizationLicenses = orgLicenses.data;
      data.showAssignPlan = false;
    }

    if (currentUser.memberPlan && currentUser.memberPlan.licenseId !== null) {
      data.currentLicense = currentUser.memberPlan.licenseId;
      data.currentLicenseName = currentUser.memberPlan.licenseNumber;
    } else {
      data.currentLicense = null;
      data.currentLicenseName = "";
    }
    this.setState(data);
  };

  hideEditPlanDrawer = () => {
    this.props.form.resetFields();
    this.getUsedLicenseCount();
    this.setState({
      licenseIdDisabled: true,
      currentEmployee: null,
      currentLicense: null,
      currentLicenseName: "",
      currentProduct: null,
      currentProductName: "",
      organizationLicenses: [],
      showAssignPlan: true,
      loadMoreOrg: true,
      orgPagerCurrent: 1,
      editPlanVisible: false,
    });
  };

  onAssign = () => {
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.setState({ disableAssignButton: true, assigningLicense: true });
        this.updateEmployeePlan();
      } else {
        this.setState({ disableAssignButton: false, assigningLicense: false });
      }
    });
  };

  updateEmployeePlan = async () => {
    let data = {
      userId: this.state.currentEmployee,
      licenseId: this.state.currentLicense,
    };
    let response = await OrgAPI.assignLicense(data);
    if (response.data.message) {
      notifyUser("Plan assigned successfully!", "success");
      this.hideEditPlanDrawer();
      if (this.props.paginginfo && this.props.paginginfo[this.module]) {
        this.handleTableChange(
          this.props.paginginfo[this.module].pagination,
          this.props.paginginfo[this.module].filter,
          {},
          true
        );
        if (this.props.paginginfo[this.module].filters) {
          let filters = this.props.paginginfo[this.module].filters;
          Object.keys(filters).map((k) => {
            filters[k].auto = false;
          });
          this.setState({ filters: filters });
        }
      } else {
        this.handleTableChange(this.state.pagination, {}, {}, true);
      }
      this.setState({ disableAssignButton: false, assigningLicense: false });
    } else {
      this.setState({ disableAssignButton: false, assigningLicense: false });
    }
  };

  confirmUnassignEmployee = async () => {
    let data = {
      userId: this.state.currentEmployee,
      licenseId: this.state.currentLicense,
    };
    let response = await OrgAPI.unassignLicense(data);
    if (response.data.message) {
      notifyUser("Plan unassigned successfully!", "success");
      this.hideEditPlanDrawer();
      if (this.props.paginginfo && this.props.paginginfo[this.module]) {
        this.handleTableChange(
          this.props.paginginfo[this.module].pagination,
          this.props.paginginfo[this.module].filter,
          {},
          true
        );
        if (this.props.paginginfo[this.module].filters) {
          let filters = this.props.paginginfo[this.module].filters;
          Object.keys(filters).map((k) => {
            filters[k].auto = false;
          });
          this.setState({ filters: filters });
        }
      } else {
        this.handleTableChange(this.state.pagination, {}, {}, true);
      }
    }
  };

  fetchOrgLicenses = async (value) => {
    let args = {
      orgId: this.state.currentOrganization,
      productId: this.state.currentProduct,
      filters: { licenseNumber: value },
      pagination: { page: 1, pageSize: 10 },
      sorter: {},
    };
    let orgLicenses = await OrgAPI.getOrganizationLicensesByProduct(args);
    this.setState({ organizationLicenses: orgLicenses.data });
  };

  loadOrgLicenses = async (e) => {
    if (
      this.state.loadMoreOrg === true &&
      e.target.scrollTop + e.target.offsetHeight >= e.target.scrollHeight
    ) {
      var _organizationLicenses = this.state.organizationLicenses;
      var _orgPagerCurrent = this.state.orgPagerCurrent + 1;
      let args = {
        orgId: this.state.currentOrganization,
        productId: this.state.currentProduct,
        filters: {
          licenseNumber:
            this.state.currentLicense != null ? this.state.currentLicense : "",
        },
        pagination: { page: _orgPagerCurrent, pageSize: 10 },
        sorter: {},
      };
      let orgLicenses = await OrgAPI.getOrganizationLicensesByProduct(args);
      if (orgLicenses.data.length > 0) {
        orgLicenses.data.map(function(item) {
          _organizationLicenses.push(item);
        });
        this.setState({
          organizationLicenses: _organizationLicenses,
          orgPagerCurrent: _orgPagerCurrent,
        });
      } else {
        this.setState({ loadMoreOrg: false });
      }
    }
  };

  handleTableChange = (pagination, filters, sorter, manual) => {
    if (filters === undefined) filters = {};
    Object.keys(filters).map((key) => {
      if (
        !filters[key] ||
        (Array.isArray(filters[key]) && filters[key].length === 0)
      ) {
        delete filters[key];
      }
    });
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    if (manual !== true) {
      this.props.updatePaginationRemoveOld({
        module: this.module,
        filter: filters,
        pagination: {
          current: pagination.current,
          pageSize: pagination.pageSize,
        },
      });
    }
    this.setState({ loading: true });
    this.props
      .getUserListing({
        orgId: this.props.match.params.id,
        filters: filters,
        pagination: { page: pagination.current, pageSize: pagination.pageSize },
        sorter: sorter,
      })
      .then((resp) => {
        pager.total = resp.paging.totalRecords;
        this.setState({
          loading: false,
          data: resp.data,
          pagination: pager,
        });
      })
      .catch((ex) => {
        this.setState({ loading: false });
      });
  };

  downloadSampleCsv = () => {
    window.location.href = config.API1 + "/SampleCSV/OrganizationUsers.csv";
  };

  
  ExportEmployessList = (orgId) => {
    let token = UserServices.getToken();
    let _headers = { "Content-Type": "multipart/form-data" };
    if (token)
      _headers["authorization"] = token ? "Bearer " + token : undefined;
    let filename = "Employee_list.csv";
    let apiUrl = config.API1 + "/api/ExportOrganizationUsersList/" + orgId;
    axios({
      url: apiUrl,
      method: "GET",
      responseType: "blob",
      headers: _headers,
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
     // window.open(url);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
    });
  };

  onChangeOrganizationTree = (selected) => {
    if (Permission.isRole("Division Manager")) {
      if (selected === this.props.match.params.orgid) {
        this.props.history.push("../../../../edit/" + selected + "/employees/");
      } else {
        if (this.props.match.params.orgid > 0) {
          this.props.history.push("../../" + selected + "/employees/");
        } else {
          this.props.history.push(
            "../../../../" +
              this.props.match.params.id +
              "/divisions/" +
              selected +
              "/employees/"
          );
        }
      }
    } else {
      if (selected === this.props.match.params.orgid) {
        this.props.history.push("../../../../edit/" + selected + "/employees/");
      } else {
        if (this.props.match.params.orgid > 0) {
          this.props.history.push("../../" + selected + "/employees/");
        } else {
          this.props.history.push(
            "../../../" +
              this.props.match.params.id +
              "/divisions/" +
              selected +
              "/employees/"
          );
        }
      }
    }
  };

  showAssignRoleWarning = () => {
    this.setState({ assignRoleWarning: true });
  };

  hideAssignRoleWarning = () => {
    this.setState({ assignRoleWarning: false });
  };

  cancelDeactivateEmployee = () => {
    this.setState({
      confirmDeactivateEmployeeVisible: false,
      currentSelectedEmployee: null,
      currentSelectedEmployeeStatus: null,
      currentPlanToUnassign: "",
    });
  };

  submitDeactivateEmployee = (selected, userId) => {
    this.updateEmployeeStatus(selected, userId);
  };

  cancelBulkUploadEmployees = () => {
    let _bulkUploadInstance = this.state.bulkUploadInstance;
    _bulkUploadInstance.product = -1;
    _bulkUploadInstance.file = null;
    _bulkUploadInstance.uploadFileName = "";
    _bulkUploadInstance.isAssignMembership = false;
    _bulkUploadInstance.sendEmailForNew = true;
    this.setState({
      isBulkUploadModalVisible: false,
      bulkUploadInstance: _bulkUploadInstance,
    });
  };

  initBulkUploadEmployees = () => {
    const { language } = this.props;
    const currentAppLocale = AppLocale[language.locale];
    let _this = this;
    let file = _this.state.bulkUploadInstance.file;

    if (file === null) {
      message.error(
        currentAppLocale.messages["admin.fileupload.error.chooseFile"]
      );
    } else if (
      _this.state.bulkUploadInstance.isAssignMembership === true &&
      parseInt(_this.state.bulkUploadInstance.product) === -1
    ) {
      message.error(
        currentAppLocale.messages[
          "admin.employees.bulkupload.error.membershipProduct"
        ]
      );
    } else {
      let ext = file.name.split(".");
      ext = ext.pop();
      if (ext === "csv") {
        let token = UserServices.getToken();
        let _headers = { "Content-Type": "multipart/form-data" };
        if (token) {
          _headers["authorization"] = token ? "Bearer " + token : undefined;
        }
        const formData = new FormData();
        formData.append("file", file);
        //formData.append("productId", _this.state.bulkUploadInstance.product);
        //formData.append("assignMembership", _this.state.bulkUploadInstance.isAssignMembership);
        Axios({
          method: "post",
          //url: config.API1 + "/api/BulkUploadUsers/" + _this.props.match.params.id+"/"+_this.state.bulkUploadInstance.product+"/"+_this.state.bulkUploadInstance.isAssignMembership+"/"+_this.state.bulkUploadInstance.sendEmailForNew,
          url:
            config.API1 +
            "/api/BulkUploadUsers/" +
            _this.props.match.params.id +
            "/" +
            _this.state.bulkUploadInstance.product +
            "/" +
            _this.state.bulkUploadInstance.isAssignMembership +
            "/" +
            _this.state.bulkUploadInstance.sendEmailForNew,
          data: formData,
          headers: _headers,
        })
          .then(function(_response) {
            _this.setState({ uploadingEmployees: false });
            if (_response.errors) {
              notifyUser(
                currentAppLocale.messages["admin.error.fileUploadFailed"],
                "error"
              );
            } else {
              notifyUser(_response.data.data.message, "success");
              _this.handleTableChange({ current: 1, pageSize: 10 }, {}, {});
              let _bulkUploadInstance = _this.state.bulkUploadInstance;
              _bulkUploadInstance.product = -1;
              _bulkUploadInstance.file = null;
              _bulkUploadInstance.uploadFileName = "";
              _bulkUploadInstance.isAssignMembership = false;
              _bulkUploadInstance.sendEmailForNew = true;
              _this.setState({
                isBulkUploadModalVisible: false,
                bulkUploadInstance: _bulkUploadInstance,
              });
            }
          })
          .catch(function(_response) {
            if (_response.response && _response.response.data) {
              notifyUser(_response.response.data.Message, "error");
            } else {
              notifyUser(
                currentAppLocale.messages["admin.error.fileUploadFailed"],
                "error"
              );
            }
            _this.setState({ uploadingEmployees: false });
            _this.handleTableChange({ current: 1, pageSize: 10 }, {}, {});
          });
      } else {
        message.error(
          currentAppLocale.messages[
            "admin.employees.bulkupload.error.csvRequired"
          ]
        );
      }
    }
  };

  onMembershipProductChange = (productId) => {
    let _bulkUploadInstance = this.state.bulkUploadInstance;
    _bulkUploadInstance.product = productId;
    this.setState({ bulkUploadInstance: _bulkUploadInstance });
  };

  onChangeAssignMembershipToggle = (e) => {
    let _bulkUploadInstance = this.state.bulkUploadInstance;
    _bulkUploadInstance.isAssignMembership = e.target.value;
    this.setState({ bulkUploadInstance: _bulkUploadInstance });
  };

  onChangeSendEmailToggle = (e) => {
    let _bulkUploadInstance = this.state.bulkUploadInstance;
    _bulkUploadInstance.sendEmailForNew = e.target.value;
    this.setState({ bulkUploadInstance: _bulkUploadInstance });
  };

  removeBulkUploadFile = () => {
    let _bulkUploadInstance = this.state.bulkUploadInstance;
    _bulkUploadInstance.uploadFileName = "";
    _bulkUploadInstance.file = null;
    this.setState({ bulkUploadInstance: _bulkUploadInstance });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    let _state = this.state;
    let _this = this;
    let filtertag = Object.keys(this.state.filters).map(function(key1) {
      let keyLabel = _this.getHeaderKeys().find((el) => el.dataIndex === key1);
      if (keyLabel.title.props && keyLabel.title.props.id) {
        keyLabel = <IntlMessages id={keyLabel.title.props.id} />;
      } else {
        keyLabel = key1;
      }
      if (_state.filters[key1].filter) {
        return (
          <span>
            <Button
              key={key1}
              type="primary"
              onClick={() =>
                _this.handleReset(_state.filters[key1].clearf, key1)
              }
            >
              {keyLabel} : {_state.filters[key1].val}
              <Icon type="close" />
            </Button>
            &nbsp;
          </span>
        );
      }
    });
    let parentOrg = this.props.match.params.orgid
      ? this.props.match.params.orgid
      : this.props.match.params.id;
    let defaultParentOrg = this.props.match.params.id
      ? this.props.match.params.id
      : this.props.match.params.orgid
      ? this.props.match.params.orgid
      : this.props.match.params.divid;
    if (Permission.isRole("Division Manager")) {
      let currentUser = localStorage.getItem("user");
      if (currentUser !== "") {
        currentUser = JSON.parse(currentUser);
        parentOrg = currentUser.companyID;
      }
      defaultParentOrg = this.props.match.params.id
        ? this.props.match.params.id
        : parentOrg;
    }
    return (
      <div>
        <Modal
          title={<IntlMessages id="admin.warning.rolemissing" />}
          closable={false}
          visible={this.state.assignRoleWarning}
          onOk={this.hideAssignRoleWarning}
          cancelButtonProps={{ style: { display: "none" } }}
        >
          <p>
            <IntlMessages id="admin.warning.assignrole" />
          </p>
        </Modal>
        {/* <Modal
          title="Please confirm"
          closable={false}
          visible={this.state.confirmDeactivateEmployeeVisible}
          onOk={() => this.submitDeactivateEmployee(this.state.currentSelectedEmployeeStatus, this.state.currentSelectedEmployee)}
          onCancel={this.cancelDeactivateEmployee}
        >
          <p><IntlMessages id="admin.confirm.unassignplan"/> `{this.state.currentPlanToUnassign}`?</p>
        </Modal> */}
        <Modal
          title={<IntlMessages id="admin.employees.bulkupload.modal.heading" />}
          closable={false}
          visible={this.state.isBulkUploadModalVisible}
          footer={[
            <Button onClick={this.cancelBulkUploadEmployees}>
              <IntlMessages id="admin.button.cancel" />
            </Button>,
            <Button onClick={this.initBulkUploadEmployees} type="primary">
              <IntlMessages id="admin.button.submit" />
            </Button>,
          ]}
        >
          <Row gutter={24} style={{ marginBottom: "20px" }}>
            <Col xs={24} sm={24} md={24}>
              <label>
                <strong>
                  <IntlMessages id="admin.employees.bulkupload.modal.selectFile" />
                  :
                </strong>
              </label>
              <br />
              <Upload {...this.csvUploadProps()}>
                <Button type="default">
                  <Icon type="upload" />{" "}
                  <IntlMessages id="admin.upload.chooseFile" />
                </Button>
              </Upload>
              <span>
                {this.state.bulkUploadInstance.uploadFileName !== "" ? (
                  <div>
                    <br />
                    {this.state.bulkUploadInstance.uploadFileName}
                    <button
                      title="Remove"
                      className="icon"
                      onClick={this.removeBulkUploadFile}
                    >
                      <i className="fas fa-trash"></i>
                    </button>
                  </div>
                ) : (
                  ""
                )}
              </span>
            </Col>
          </Row>
          {
            <Row gutter={24} style={{ marginBottom: "20px" }}>
              <Col xs={24} sm={24} md={24}>
                <label>
                  <strong>
                    <IntlMessages id="admin.userlisting.sendWelcomeEmail" />
                  </strong>
                </label>
                <br />
                <Radio.Group
                  onChange={this.onChangeSendEmailToggle}
                  value={this.state.bulkUploadInstance.sendEmailForNew}
                >
                  <Radio value={false}>No</Radio>
                  <Radio value={true}>Yes</Radio>
                </Radio.Group>
              </Col>
            </Row>
          }
          <Row gutter={24} style={{ marginBottom: "20px" }}>
            <Col xs={24} sm={24} md={24}>
              <label>
                <strong>
                  <IntlMessages id="admin.employees.bulkupload.modal.assignMembership" />
                </strong>
              </label>
              <br />
              <Radio.Group
                onChange={this.onChangeAssignMembershipToggle}
                value={this.state.bulkUploadInstance.isAssignMembership}
              >
                <Radio value={false}>No</Radio>
                <Radio value={true}>Yes</Radio>
              </Radio.Group>
            </Col>
          </Row>
          <Row
            gutter={24}
            style={{
              marginBottom: "20px",
              display: this.state.bulkUploadInstance.isAssignMembership
                ? "block"
                : "none",
            }}
          >
            <Col xs={24} sm={24} md={24}>
              <label>
                <strong>
                  <IntlMessages id="admin.employees.bulkupload.modal.assignMembershipProduct" />
                  :
                </strong>
              </label>
              <br />
              <Select
                placeholder="-select-"
                onChange={this.onMembershipProductChange}
                style={{ minWidth: "240px" }}
                value={this.state.bulkUploadInstance.product}
                defaultActiveFirstOption={false}
              >
                <Option key={-1} value={-1}>
                  -Select-
                </Option>
                {this.state.organizationProductsInfo
                  ? this.state.organizationProductsInfo.map((el) => {
                      if (el.productQty - el.planConsumed > 0) {
                        return (
                          <Option key={el.productID} value={el.productID}>
                            {el.productName}
                          </Option>
                        );
                      }
                    })
                  : ""}
              </Select>
            </Col>
          </Row>
        </Modal>
        <Drawer
          title={<IntlMessages id="admin.employeesList.manage.plan" />}
          width={420}
          onClose={this.hideEditPlanDrawer}
          visible={this.state.editPlanVisible}
          bodyStyle={{ paddingBottom: 80 }}
          closable={true}
          maskClosable={false}
        >
          <Spin spinning={this.state.assigningLicense}>
            <Form layout="vertical">
              <Row gutter={24}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form.Item
                    label={<IntlMessages id="admin.assign.plan.name" />}
                  >
                    {this.state.showAssignPlan ? (
                      getFieldDecorator("productId", {
                        rules: [
                          {
                            required: true,
                            message: <IntlMessages id="admin.input.required" />,
                          },
                        ],
                        defaultValue:
                          this.state.currentProduct > 0
                            ? this.state.currentProduct
                            : null,
                      })(
                        <Select
                          placeholder="-select-"
                          onChange={this.onOrgProductChange}
                        >
                          {this.state.organizationProducts
                            ? this.state.organizationProducts.map((el) => {
                                return (
                                  <Option
                                    key={el.productId}
                                    value={el.productId}
                                  >
                                    {el.productName}
                                  </Option>
                                );
                              })
                            : ""}
                        </Select>
                      )
                    ) : (
                      <Input disabled value={this.state.currentProductName} />
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form.Item
                    label={<IntlMessages id="admin.assign.plan.number" />}
                  >
                    {this.state.showAssignPlan ? (
                      getFieldDecorator("licenseId", {
                        rules: [
                          {
                            required: true,
                            message: <IntlMessages id="admin.input.required" />,
                          },
                        ],
                      })(
                        <Select
                          showSearch
                          allowClear
                          onSelect={this.onOrgLicenseChange}
                          onDeselect={this.onOrgLicenseDeselect}
                          onSearch={this.fetchOrgLicenses}
                          placeholder="Type to search"
                          disabled={this.state.licenseIdDisabled}
                          onPopupScroll={this.loadOrgLicenses}
                          defaultActiveFirstOption={false}
                        >
                          {this.state.organizationLicenses
                            ? this.state.organizationLicenses.map((el) => {
                                return (
                                  <Option key={el.licenseId}>
                                    {el.licenseNumber}
                                  </Option>
                                );
                              })
                            : null}
                        </Select>
                      )
                    ) : (
                      <Input disabled value={this.state.currentLicenseName} />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              {this.state.showAssignPlan ? (
                <Button
                  onClick={this.onAssign}
                  disabled={this.state.disableAssignButton}
                  type="primary"
                >
                  Assign
                </Button>
              ) : (
                <Popconfirm
                  placement="bottomLeft"
                  title="Are you sure, you want to unassign the plan number?"
                  onConfirm={this.confirmUnassignEmployee}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button type="primary">Unassign</Button>
                </Popconfirm>
              )}
            </Form>
          </Spin>
        </Drawer>
        <Spin
          tip={<IntlMessages id="admin.loading.pleaseWait" />}
          spinning={this.state.uploadingEmployees}
        >
          <Row gutter={24}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <Typography.Title level={4} style={{ float: "left" }}>
                <IntlMessages id="admin.organization.manage.employees" />
              </Typography.Title>
            </Col>
            <Col
              xs={12}
              sm={12}
              md={24}
              lg={12}
              xl={12}
              style={{ textAlign: "right" }}
            >
              {this.state.licenseTotals &&
              this.state.licenseTotals.length > 0 ? (
                <div>
                  <IntlMessages id="admin.organization.licences.used" />
                  :&nbsp;
                  <Popover
                    placement="bottomRight"
                    content={
                      this.state.licenseTotals
                        ? this.state.licenseTotals.map(function(el, idx) {
                            return (
                              <div key={idx} style={{ fontSize: "16px" }}>
                                {el.product}:&nbsp;
                                <strong>
                                  {el.usedLicenses} / {el.totalLicenses}
                                </strong>
                              </div>
                            );
                          })
                        : ""
                    }
                    trigger="hover"
                  >
                    <strong>
                      {this.state.allAssignedLicenses} /{" "}
                      {this.state.allTotalLicenses}
                    </strong>
                  </Popover>
                </div>
              ) : (
                ""
              )}
            </Col>
          </Row>
          <hr />
          <Row gutter={24}>
            <Col
              sx={24}
              sm={24}
              md={24}
              lg={24}
              xl={24}
              style={{ textAlign: "right" }}
            >
              <ul className="upload-xlx">
                <li>
                  <Button onClick={() => this.ExportEmployessList(this.props.match.params.id)}>
                        <Icon type="download" /> <IntlMessages id="admin.b2b.download.employeelist" />
                      </Button>
                </li>
                <li>
                  {" "}
                  <Button onClick={() => this.downloadSampleCsv()}>
                    <Icon type="download" />{" "}
                    <IntlMessages id="admin.organization.download.csv" />
                  </Button>
                </li>
                <li>
                  <Button
                    onClick={() =>
                      this.setState({ isBulkUploadModalVisible: true })
                    }
                  >
                    <Icon type="upload" />{" "}
                    <IntlMessages id="admin.organization.upload.csv" />
                  </Button>
                </li>
                <li>
                  <Button type="primary" onClick={() => this.editItem("new")}>
                    <IntlMessages id="admin.organization.add.employee" />
                  </Button>
                </li>
                <li>
                  <Button
                    type="primary"
                    className=""
                    htmlType="button"
                    onClick={() => this.props.history.goBack()}
                  >
                    <IntlMessages id="admin.userlisting.back" />
                  </Button>
                </li>
              </ul>
            </Col>
          </Row>
          <Row gutter={24} style={{ marginBottom: "20px", marginTop: "20px" }}>
            <Col xs={12} sm={12} md={24} lg={24} xl={24}>
              {filtertag}
            </Col>
          </Row>
          {!this.state.loading ? (
            <Row gutter={24} style={{ paddingBottom: "20px" }}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <div style={{ float: "left", paddingTop: "5px" }}>
                  <IntlMessages id="admin.organization.company" />
                  :&nbsp;
                </div>
                <div style={{ float: "left", minWidth: "320px" }}>
                  <OrgTree
                    parent={defaultParentOrg}
                    organizationId={parentOrg}
                    onChange={this.onChangeOrganizationTree}
                  />
                </div>
              </Col>
            </Row>
          ) : (
            ""
          )}

          <Row gutter={24}>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={24}
              style={{ overflow: "auto" }}
            >
              <Table
                columns={this.getHeaderKeys()}
                rowKey={(record) => record.userId}
                dataSource={this.state.data}
                pagination={this.state.pagination}
                loading={this.state.loading}
                onChange={this.handleTableChange}
              />
            </Col>
          </Row>
        </Spin>
      </div>
    );
  }
}

EditOrganizationEmployees.propTypes = {
  location: PropTypes.object,
  getUserListing: PropTypes.func,
};
function mapStateToProps(state) {
  return {
    ...state.user,
    ...state.language,
    ...state.pagination,
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { ...OrgActions, ...UserActions, ...paginationActions },
    dispatch
  );
}
const WrappedForm = Form.create({ name: "edit-employee-plan" })(
  EditOrganizationEmployees
);
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    WrappedForm
  )
);
