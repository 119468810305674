import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import UserProfile from "../components/hybrid-dash/user-profile/user-profile";
import ManageOrders from "../components/hybrid-dash/order-history/order-history";
import ViewOrder from "../components/hybrid-dash/order-history/view";
import HybridHome from "../components/hybrid-dash/user-home/user-home";
import UserChangePassword from "../components/hybrid-dash/user-profile/change-password";
import ManageOrganization from "../components/hybrid-dash/manage-organization/edit-organization";
import ManageUsers from "../components/hybrid-dash/manage-users/index";
import EditUser from "../components/hybrid-dash/manage-users/edit";
import ManageTravelers from "../components/hybrid-dash/travelers/list";
import EditTraveler from "../components/hybrid-dash/travelers/edit/edit";
import AuditLogs from "../components/hybrid-dash/audit-logs";
import NotFound from "../components/shared/components/404.jsx";
import Notifications from "../components/Notifications/notifications";
import ViewNotification from "../components/Notifications/view";
import CustomizeEmailsList from "../components/common/customize-emails/list";
import EditCustomizeEmail from "../components/common/customize-emails/edit";
import EditB2B2COrganizationAgent from "../components/hybrid-dash/agents/edit";
import EditOrganizationAgents from "../components/hybrid-dash/agents/list";
import B2B2CListDivisonOrganization from "../components/hybrid-dash/manage-division/list-division";
import B2B2CAddDivisonOrganization from "../components/hybrid-dash/manage-division/add-division/add-division";
import B2B2CEditDivisonOrganization from "../components/hybrid-dash/manage-division/edit-division/edit-division";
import B2B2COrganizationTravelersList from "../components/hybrid-dash/travelers/list";
const routes = [
  {
    path: '',
    component: HybridHome
  },
  {
    path: 'my-profile',
    component: UserProfile
  },
  {
    path: 'my-profile/change-password',
    component: UserChangePassword
  },
  {
    path: 'manage-orders',
    component: ManageOrders
  },
  {
    path: 'manage-orders/view/:orderId',
    component: ViewOrder
  },
  {
    path: 'manage-organization',
    component: ManageOrganization
  },
  {
    path: 'manage-users',
    component: ManageUsers
  },
  {
    path: 'manage-users/:id',
    component: EditUser
  },
  {
    path: 'manage-agents',
    component: EditOrganizationAgents
  },
  {
    path: 'manage-agents/:id',
    component: EditB2B2COrganizationAgent
  },
  {
    path: 'manage-travelers/',
    component: ManageTravelers
  },
  // company manage travler router
  {
    path: 'manage-travelers/:id/list/',
    component: ManageTravelers
  },
  {
    path: 'manage-travelers/:orgid/list/edit/:id',
    component: EditTraveler
  },
  {
    path: 'manage-travelers/:orgid/divisions/:id/traveler',
    component: ManageTravelers
  },
  {
    path: 'manage-travelers/:orgid/divisions/:divid/traveler/:new',
    component: EditTraveler
  },

  // end
  {
    path: 'manage-travelers/:orgid/list/:new',
    component: EditTraveler
  },
  {
    path: 'manage-travelers/edit/:id',
    component: EditTraveler
  },

  {
    path: 'manage-division/',
    component: B2B2CListDivisonOrganization
  },
  {
    path: 'manage-division/divisions/add',
    component: B2B2CAddDivisonOrganization
  },
  {
    path: 'manage-division/divisions/:id/edit/',
    component: B2B2CEditDivisonOrganization
  },
  // for company gurad me login
  {
    path: 'manage-division/:id/list/',
    component: B2B2CListDivisonOrganization
  },
  {
    path: 'manage-division/:orgid/list/divisions/:id/edit/',
    component: B2B2CEditDivisonOrganization
  },
  {
    path: 'manage-division/:id/list/divisions/add',
    component: B2B2CAddDivisonOrganization
  },
  {
    path: 'manage-division/:id/list/divisions/:orgid/traveler/',
    component: ManageTravelers
  },
  {
    path: 'manage-division/:id/list/divisions/:orgid/traveler/edit/:id',
    component: EditTraveler
  },

  // end
  {
    path: 'manage-division/divisions/:id/traveler/',
    component: ManageTravelers
  },
  {
    path: 'manage-division/divisions/:divid/traveler/:id',
    component: EditTraveler
  },
  {
    path: 'manage-division/divisions/:divid/traveler/edit/:id',
    component: EditTraveler
  },
  {
    path: 'manage-travelers/edit/:id/view-order/:orderId',
    component: ViewOrder
  },
  {
    path: 'audit-logs',
    component: AuditLogs
  },
  {
    path: 'user/audit-logs/:id',
    component: AuditLogs
  },
  {
    path: 'notifications',
    component: Notifications
  },
  {
    path: 'notifications/:id',
    component: ViewNotification
  },
  {
    path: 'customize-emails',
    component: CustomizeEmailsList
  },
  {
    path: 'customize-emails/edit/:templateId',
    component: EditCustomizeEmail
  },
  {
    path: '',
    component: NotFound,
    exact: false
  }
];

class HybridRouter extends Component {
  componentDidMount(){
    console.log("mount")
  }
  render() {
    const { url, style } = this.props;
    return (
      <div style={style}>
        <Switch>
          {
            routes.map(singleRoute => {
            const { path, exact, ...otherProps } = singleRoute;
            return (
               <Route
                exact={exact === false ? false : true}
                key={singleRoute.path}
                path={`${url}/${singleRoute.path}`}
                {...otherProps}
              />
            );
          })}
        </Switch>
      </div>
    );
  }
}

export default HybridRouter;
