import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { CardElement, injectStripe } from 'react-stripe-elements';
import { connect } from "react-redux";
import OrgAPI from "../../../redux/api/b2b2c-organization-api";
import { bindActionCreators } from "redux";
import ThankYouPage  from "../thank-you/thank-you";
import  Loader from "../../b2c/shared/loader/loader";
import Paypal from "../../../images/paypal.svg";
import CreditCard from "../../../images/creditcard.svg";
import { message, Modal, Tooltip, Popconfirm, Radio } from "antd";
import {notifyUser} from "../../../services/notification-service";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import * as b2cActions from "../../../redux/actions/b2c-actions";
import axios from "axios";
import config from "../../../../../config";
const { confirm } = Modal;


class CheckoutForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      paymentMethod: '',
      clientToken: config.clientTokenPaypal,
      orderObjectPaypal: {},
      confirmOrder: false,
      // btnIsDisable: false,
      containOrder: {},
      showLoader: false
    };
    this.handleConfirmOrderCheck = this.handleConfirmOrderCheck.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.showConfirm = this.showConfirm.bind(this);
    this.placeorder = this.placeorder.bind(this);
  }

  handleConfirmOrderCheck(evt) {
    this.setState({ confirmOrder: evt.currentTarget.checked });
  }

  payMethodChange = (e) => {
    this.setState({ paymentMethod: e.target.value });
  };

  paymentSectionCallback = (val) => {
    this.props.handlePaymentSection(val);
  };
  async handleClick(evt) {
    // this.setState({ btnIsDisable: true });
    if (this.state.confirmOrder) {
      this.checkForvalidPayment();
    } else {
      var objContent = {};
      objContent.title = 'Acknowledge required!';
      objContent.content = 'Please acknowledge plan rules and regulations first.';
      this.showConfirm(objContent);
    }
  }

  async checkForvalidPayment() {
    var self = this;
    const { stripe } = this.props;

    if (this.state.paymentMethod == 'paypal') {
      await this.instance.requestPaymentMethod((error, payload) => {
        if (error) {
          console.error(error);
        } else {
          const paymentMethodNonce = payload.nonce;
        }
      });
    } else {
      this.props.stripe.createToken().then(async function(payload) {
        if (payload.token) {
          const data = {
            token: payload.token.id
          };
          self.placeorder(data);
        } else {
          var objContent = {};
          objContent.title = 'Invalid card detail!';
          objContent.content = 'Please enter valid card details.';
          self.showConfirm(objContent);
        }
      });
    }
  }

  placeorder = (data) => {
    // const{disablepayment} = this.state;
    const { childformdata, parentformData } = this.props;
    const childUserInfo = childformdata.ChildUserInfo;
    const parentUserEmail = parentformData.values.email;

    const newPayload = {
      Token: data.token,
      ParentUserEmail: parentUserEmail,
      ChildUserInfo: childUserInfo
    };
    // Stringify the childUserInfo object
    const childUserInfoString = JSON.stringify(childUserInfo);
    this.setState({ showLoader: true });
    this.props
      .EmployeeSubmitOrder(newPayload)
      .then((response) => {
        if (response.status === true) {
          this.setState({ showLoader: false });
          const childUserInfoString = JSON.stringify(childUserInfo);
          localStorage.setItem('childUserInfo', childUserInfoString);
          // Redirect to the thank you page
          const currentPath = `${window.location.pathname}/thank-you`;
          window.location.href = currentPath;
          notifyUser('Order created successfully!', 'success');
        } else {
          this.paymentSectionCallback(true);
          this.setState({ showLoader: false });
          notifyUser(response.message, 'error');
        }
      })
      .catch((error) => {
        this.paymentSectionCallback(true);
        notifyUser(error.message, 'error');
        console.error(error);
      });
  };

  clickPaypal = () => {
    if (!this.state.confirmOrder) {
      var objContent = {};
      objContent.title = 'Acknowledge required!';
      objContent.content = 'Please acknowledge plan rules and regulations first.';
      this.showConfirm(objContent);
      return false;
    } else {
      const { childformdata, parentformData } = this.props;
      const childUserInfo = childformdata.ChildUserInfo;
      const parentUserEmail = parentformData.values.email;
      const datapaypal = {
        Token: 'pass',
        ParentUserEmail: parentUserEmail,
        ChildUserInfo: childUserInfo
      };
      this.setState({ orderObjectPaypal: datapaypal });
    }
  };

  showConfirm(objContent, index) {
    confirm({
      title: objContent.title,
      content: objContent.content,
      className: 'gray-color',
      onOk() {},
      onCancel() {}
    });
  }

  render() {
    return (
      <div>
        <section id='banner'>
          {this.state.showLoader ? <Loader /> : ''}
          <div className='wrap'>
            <div className='billing-info'>
              <div className='row'>
                <div className='full-width'>
                  <h3>Billing Information</h3>
                </div>
                <div className='full-width pay-methods'>
                  <Radio.Group onChange={this.payMethodChange}>
                    <Radio value={'stripe'}>
                      <img style={{ width: '40px' }} src={CreditCard} alt='Credit Card' />
                      <span style={{ fontWeight: 600, marginLeft: '10px' }}> Pay Via Card </span>
                    </Radio>
                    <Radio value={'paypal'}>
                      <img style={{ width: '100px' }} src={Paypal} alt='Paypal' />
                    </Radio>
                  </Radio.Group>
                </div>
              </div>
              {this.state.paymentMethod === 'stripe' ? (
                <div className='row'>
                  <div className='full-width'>
                    <label>
                      <span>*</span> Credit or debit card (Visa, MasterCard, American Express, JCB, Discover, and Diners
                      Club)
                    </label>
                    <CardElement hidePostalCode={true} />
                    <span className='disclaimer'>
                      Your credit card data will be securely transmitted and processed. FocusPoint complies with PCI-DSS
                      SAQ A.
                    </span>
                  </div>
                </div>
              ) : (
                ''
              )}
              <div className='row'>
                <div className='full-width'>
                  <div className='checkbox-in'>
                    <label className='container'>
                      By submitting, you acknowledge that you have read and agree to the terms and conditions of
                      the&nbsp;
                      <a
                        target='_blank'
                        href={config.API1 + '/rules/CAPMED-ORG-100-MILECAPADVTG-EXSEC-RR-V16-10.19.23_v2.pdf'}
                        style={{ textDecoration: 'underline' }}
                      >
                        CAP <br />
                        Plan Rules & Regulations
                      </a>
                      <span className='red'>*</span>
                      <input
                        type='checkbox'
                        checked={this.state.confirmOrder}
                        onChange={this.handleConfirmOrderCheck}
                      />
                      <span className='checkmark'></span>
                    </label>
                  </div>
                </div>
              </div>
              {this.state.paymentMethod === 'paypal' ? (
                <PayPalScriptProvider
                  options={{
                    'client-id': this.state.clientToken
                  }}
                >
                  <PayPalButtons
                    onClick={this.clickPaypal}
                    style={{ layout: 'horizontal' }}
                    createOrder={(data, actions) => {
                      return axios
                        .post(config.API1 + '/api/EmployeePaypalCreateOrder', this.state.orderObjectPaypal)
                        .then((response) => {
                          if (response.data.status === true) {
                            return response.data.data.orderId;
                          } else {
                            if (response.data.statusCode === 400) {
                                 this.paymentSectionCallback(true);
                              notifyUser(response.data.message, 'error');
                            } else {
                               this.paymentSectionCallback(true);

                              notifyUser('not created order', 'error');
                            }
                          }
                        })
                        .catch((error) => {
                          if (
                            error.response &&
                            error.response.status === 400 &&
                            error.response.data.message.includes('User with email')
                          ) {
                            this.paymentSectionCallback(true);
                            notifyUser(error.response.data.message, 'error');
                          } else {
                            notifyUser('An error occurred', 'error');
                          }
                        });
                    }}
                    onApprove={(data, actions) => {
                      const { childformdata, parentformData } = this.props;
                      const childUserInfo = childformdata.ChildUserInfo;
                      const parentUserEmail = parentformData.values.email;
                      const postData = {
                        Token: data.orderID,
                        ParentUserEmail: parentUserEmail,
                        ChildUserInfo: childUserInfo
                      };
                      this.setState({ containOrder: postData });
                      return axios
                        .post(config.API1 + `/api/EmployeePaypalCaptureOrder`, this.state.containOrder)
                        .then((response) => {
                          let resp = response.data;
                          if (resp.status === true) {
                            notifyUser('Order created successfully!', 'success');
                            const childUserInfoString = JSON.stringify(childUserInfo);
                            localStorage.setItem('childUserInfo', childUserInfoString);
                            // Redirect to the thank you page
                            const currentPath = `${window.location.pathname}/thank-you`;
                            window.location.href = currentPath;
                          } else {
                            notifyUser(response.message, 'success');
                          }
                        })
                        .catch((error) => {
                          this.paymentSectionCallback(true);
                          notifyUser(error.message, 'error');
                          console.error(error);
                        });
                    }}
                  />
                </PayPalScriptProvider>
              ) : (
                ''
              )}
              {this.state.paymentMethod === 'stripe' ? (
                <button
                  // disabled={this.state.btnIsDisable}
                  data-value='buy'
                  className='buynow'
                  style={{ background: '#489e3c' }}
                  onClick={this.handleClick}
                >
                  Buy Now
                </button>
              ) : (
                ''
              )}
            </div>
          </div>
        </section>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({...b2cActions}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true
})(CheckoutForm);
