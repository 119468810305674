import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Row, Col, Table } from "antd";
import * as TravelerActions from "../../../../../redux/actions/assist-traveler-actions";
import moment from "moment";

let statusArray = {
  0: "Pending",
  1: "Assisting",
  2: "Resolved"
};
const dateFormat = "MM/DD/YYYY HH:mm:ss";

class AssistList extends React.Component {
  constructor(props) {
    super(props);
    this.state = { loading: false };
  }

  getHeaderKeys = () => {
    return [
      {
        title: "Name",
        dataIndex: "userName"
      },
      {
        title: "Organization",
        dataIndex: "organizationName"
      },
      {
        title: "Division",
        dataIndex: "divisionName"
      },
      {
        title: "Contact",
        dataIndex: "contactNumber"
      },
      {
        title: "City",
        dataIndex: "city"
      },
      {
        title: "State",
        dataIndex: "state"
      },
      {
        title: "Country",
        dataIndex: "country"
      },
      {
        title: "Date",
        dataIndex: "creationDateTime",
        render: (text, record) =>
          moment
            .utc(record.creationDateTime)
            .local()
            .format(dateFormat)
      },
      {
        title: "Status",
        dataIndex: "assistStatus",
        render: (text, record) => statusArray[record.assistStatus]
      }
    ];
  };

  async componentDidMount() {
    if (
      this.props.match &&
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.id &&
      this.props.match.params.id !== "new"
    ) {
      this.setState({ loading: true });
      this.props
        .getCheckInList({
          filters: {
            userId: this.props.match.params.id,
            notificationType: 10
          },
          pagination: { page: 1, pageSize: 1000 },
          sorter: {}
        })
        .then(resp => {
          this.setState({
            data: resp.data.results,
            loading: false
          });
        })
        .catch(ex => {
          this.setState({ loading: false });
        });
    }
  }

  render() {
    return (
      <Row gutter={24}>
        <Col xs={24} style={{ overflowX: "auto" }}>
          <Table
            bordered
            columns={this.getHeaderKeys()}
            rowKey={record => record.eventLoggingId}
            dataSource={this.state.data}
            loading={this.state.loading}
          />
        </Col>
      </Row>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...TravelerActions }, dispatch);
}

export default withRouter(
  connect(null, mapDispatchToProps, null, { forwardRef: true })(AssistList)
);
