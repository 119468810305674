import React, { Component } from "react";
import { Chart, Geom, Tooltip } from "bizcharts";
import { Icon, Card } from 'antd';
import { TotalViewSvg } from "../../shared/svg/svg/home-icon";
import IntlMessages from "../../../services/intlMesseges";
const TotalViewIcon = props => < Icon component={
  TotalViewSvg
} {
  ...props
  }
/>;
export default class TotalView extends Component {
  state = {
    salesData: {
      data: [],
      total: 0
    }
  }
  render() {
    const scale = {
      value: {
        alias: "The Share Price in Dollars",
        formatter: function(val) {
          return "$" + val;
        }
      },
      year: {
        range: [0, 1]
      }
    };
    return (
         <Card title={<IntlMessages id="admin.dashboard.totalcommission"></IntlMessages>} extra={<TotalViewIcon />}>
          <div style={{fontSize:"22px", color: "#3b3b3b", fontWeight: "600"}} className="totalValue">${this.props.profitData.total}</div>
          <Chart
            className="graphchart"
            height={50}
            padding={[5, 0, 0, 0]}
            data={this.props.profitData.data}
            scale={scale}
            forceFit
          >
            <Tooltip crosshairs />
            {/* <Axis />
            <Legend /> */}
            <Geom type="area" position="Date*Total Profit" color="#6cb8ff" shape="smooth" />
            <Geom
              type="line"
              position="year*value"
              shape="smooth"
              size={2}
            />
          </Chart>
        </Card>
    );
  }
}