import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as UserActions from "../../../redux/actions/user-actions";
import * as paginationActions from "../../../redux/actions/pagination-actions";
import IntlMessages from "../../../services/intlMesseges";
import { DatePicker, Switch, Typography, Form, Input, Select, Button, Row, Col, Spin, Icon } from "antd";
import API from "../../../redux/api/admin-api";
import { notifyUser } from "../../../services/notification-service";
import * as Permission from "../../../services/permissions";
import moment from 'moment';
const { Option } = Select;
const dateFormat = "MM/DD/YYYY";
const dateFormatY = "YYYY/MM/DD";

class UserProfile extends Component {
  state = {
    data: {},
    loading: false,
    confirmDirty: false,
    id: 0,
    first: "",
    last: "",
    email: "",
    phone: "",
    country: "",
    dateofbirth: "",
    countries: [],
    countryId: "",
    locationFound: false,
    locationData: {},
    checkexisting: true,
    purchaseLink: ""
  };

  async componentDidMount() {
    this.props.clearPaginationExceptMe('profile');
    let user = await this.props.myAccount();
    this.setState(user.data);
    this.setState({
      loading: false,
      dataLoaded: true
    });
    let countriesListTemp = await API.getCountriesList();
    this.setState({ countries: countriesListTemp.countries });
    if (
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.id &&
      this.props.match.params.id !== "new"
    ) {
      this.setState({ userId: this.props.match.params.id });

      const user = await this.props.getTraveller(this.props.match.params.id);
      this.setState(user);
      if (user.data.email === null) {
        this.setState({ checkexisting: false });
      }
      this.setState({ loading: false });
    }
    
    // To Show the Agent Purchase Link on his/her profile page
    const PRODUCTION_QUOTE_PAGE_URI = "https://www.captravelassistance.com/quote";
    if (
      this.props.userData &&
      this.props.userData.companyID &&
      this.props.userData.agentCode
    ) {
      this.setState({
        purchaseLink: `${PRODUCTION_QUOTE_PAGE_URI}/?partner=${this.props.userData.companyID}&atn=${this.props.userData.agentCode}`
      });
    }
  }

  handleFormLayoutChange = e => {
    this.setState({ formLayout: e.target.value });
  };

  handleSubmit = e => {
    e.preventDefault();
    let _this = this;
    this.props.form.validateFieldsAndScroll((_err, values) => {
      if (!_err) {
        Object.keys(values).map(function (key, _idx) {
          if (values[key] == null) {
            values[key] = '';
          } else {
            if (typeof values[key] == 'string') {
              values[key] = values[key].trim();
            }
          }
          return values;
        });
        if (_this.state.isReceiveTravelAlerts != null) {
          values['isReceiveTravelAlerts'] = _this.state.isReceiveTravelAlerts;
        }
        this.setState({ loading: true });
        values['dateOfBirth'] = values['dateOfBirth'].format('YYYY-MM-DD');

        this.props
          .updateMyAccount(values)
          .then(response => {
            if (response.error) {
              if (response.error.message) {
                notifyUser(response.error.message, "error");
              } else {
                notifyUser("Unknown error. Please try again!", "error");
              }
              this.setState({ loading: false });
            } else {
              let currentUser = JSON.parse(localStorage.getItem('user'));
              currentUser.firstName = values.firstName;
              currentUser.lastName = values.lastName;
              currentUser.contactNo = values.contactNo;
              localStorage.setItem('user', JSON.stringify(currentUser));
              this.setState({ loading: false });
              notifyUser("Profile updated successfully!", "success");
            }
          })
          .catch(_err => {
            this.setState({ loading: false });
          });
      }
    });
  };

  onSwitchChange = (checked, e) => {
    this.setState({ isReceiveTravelAlerts: checked });
  };

  handleContactExistance(rule, value, callback) {
    //const re = /^[0-9']+$/;
    const re = (/^(?=[+0-9]*$)(?!.*[<>'"/;`%~@#$^*()_=[\]{}|\\,.?: -])/)
    var test = re.test(value)
    if (value !== "" && (value.length < 6 || test === false)) {
      callback(<IntlMessages id="admin.phonenumber.valid"></IntlMessages>)
    }
    else {
      callback()
    }
  }
  render() {
    const { getFieldDecorator } = this.props.form;
    const { formLayout } = this.state;
    const formItemLayout =
      formLayout === "horizontal"
        ? {
          labelCol: { span: 4 },
          wrapperCol: { span: 14 }
        }
        : null;

    return (
      <div>
        <Row gutter={24}>
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography.Title level={4}>
              <IntlMessages id="admin.profile.myprofile" />
            </Typography.Title>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <a
              href={this.state.purchaseLink}
              className="ant-btn right-fl"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icon type="shopping-cart" style={{ padding: "6px 0" }} />{" "}
              <IntlMessages id="agent.profile.purchaseCap" />
            </a>
          </Col>
        </Row>
        <hr />
        <Spin spinning={this.state.loading}>
          <Form layout={formLayout} onSubmit={this.handleSubmit}>
            <Row gutter={24}>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Form.Item
                  {...formItemLayout}
                  label={<IntlMessages id="admin.userlisting.firstName" />}
                >
                  {getFieldDecorator("firstName", {
                    rules: [
                      {
                        required: true,
                        message: <IntlMessages id="admin.input.required" />
                      },
                      {
                        pattern: new RegExp(/^(\S.*)[a-zA-Z-.']+$/),
                        message: <IntlMessages id="admin.name.valid"></IntlMessages>
                      }
                    ],
                    initialValue:
                      this.state.firstName === null
                        ? ""
                        : this.state.firstName
                  })(<Input />)}
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Form.Item
                  {...formItemLayout}
                  label={<IntlMessages id="admin.userlisting.lastName" />}
                >
                  {getFieldDecorator("lastName", {
                    rules: [
                      {
                        required: true,
                        message: <IntlMessages id="admin.input.required" />
                      },
                      {
                        pattern: new RegExp(/^(\S.*)[a-zA-Z-.']+$/),
                        message: <IntlMessages id="admin.lname.valid"></IntlMessages>
                      }
                    ],
                    initialValue:
                      this.state.lastName === null ? "" : this.state.lastName
                  })(<Input />)}
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Form.Item
                  {...formItemLayout}
                  label={<IntlMessages id="admin.userlisting.email" />}
                >
                  {getFieldDecorator("email", {
                    rules: [
                      {
                        type: "email",
                        message: "The input is not valid Email!"
                      },
                      {
                        required: true,
                        message: <IntlMessages id="admin.input.required" />
                      }
                    ],
                    initialValue:
                      this.state.email === null
                        ? ""
                        : this.state.email
                  })(<Input disabled={this.state.checkexisting} />)}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Form.Item
                  {...formItemLayout}
                  label={<IntlMessages id="admin.employee.dob" />}
                >
                  {getFieldDecorator("dateOfBirth", {
                    rules: [
                      {
                        required: true,
                        message: <IntlMessages id="admin.input.required" />
                      }
                    ],
                    initialValue:
                      this.state.dateOfBirth === null
                        ? ""
                        : moment(this.state.dateOfBirth, dateFormatY)
                  })(<DatePicker
                    disabledDate={current => {
                      return (
                        current && current > moment().subtract(1, "days")
                      );
                    }}
                    placeholder="-select-"
                    format={dateFormat}
                  />)}
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Form.Item
                  {...formItemLayout}
                  label={<IntlMessages id="admin.userlisting.phonenumber" />}
                >
                  {getFieldDecorator("contactNo", {
                    rules: [
                      {
                        required: true,
                        message: <IntlMessages id="admin.input.required" />
                      },
                      {

                        validator: this.handleContactExistance
                      }
                    ],
                    initialValue:
                      this.state.contactNo === null
                        ? ""
                        : this.state.contactNo
                  })(<Input maxLength={15} style={{ width: "100%" }} />)}
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Form.Item
                  {...formItemLayout}
                  label={<IntlMessages id="admin.userlisting.address" />}
                >
                  {getFieldDecorator("address", {
                    initialValue:
                      this.state.address === null ? "" : this.state.address
                  })(<Input />)}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Form.Item
                  label={<IntlMessages id="admin.userlisting.country" />}
                >
                  {getFieldDecorator("countryId", {
                    rules: [
                      { required: true, message: "Please select your country!" }
                    ],
                    initialValue: this.state.countryId
                  })(
                    <Select showSearch={true} filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                      {this.state.countries.map(function (item) {
                        return (
                          <Option key={item.countryId} value={item.countryId}>
                            {item.countryName}
                          </Option>
                        );
                      })}
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Form.Item
                  label={<IntlMessages id="admin.userlisting.state" />}
                >
                  {getFieldDecorator("state", {
                    initialValue:
                      this.state.state === null ? "" : this.state.state
                  })(<Input />)}
                </Form.Item>
              </Col>
            </Row>

            {(Permission.canAccess("update_receive_travel_alerts")) ?
              <div>
                <Row gutter={24}>
                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={24}
                    style={{ border: "1px solid gray", padding: "20px" }}
                  >
                    <Row gutter={24}>
                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Typography.Title level={4}>
                          Would you like to receive Travel Alerts?
                        </Typography.Title>
                      </Col>
                      <Col
                        xs={24}
                        sm={24}
                        md={12}
                        lg={12}
                        xl={12}
                        style={{ textAlign: "right" }}
                      >
                        <Switch checkedChildren="Yes" unCheckedChildren="No" checked={this.state.isReceiveTravelAlerts} onChange={this.onSwitchChange} name="isReceiveTravelAlerts" />
                      </Col>
                    </Row>
                    <hr></hr>
                    <Row gutter={24}>
                      <Col xs={24}>
                        <p>
                          Receive real time late breaking travel alerts and emails
                          to warn you of any events that could affect your safety or
                          mobility at your destination. Travel Alerts were designed
                          to provide you with useful information to help you prepare
                          for or modify your travel plans and inform you of any
                          events at your destination that could impact your personal
                          health or safety. You can unsubscribe from travel alerts
                          at any time. (Recommended)
                        </p>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <br /><br />
              </div>
              :
              ""
            }
            <Row>
              <Col>
                <Form.Item>
                  <Button
                    type="primary"
                    style={{ display: "inline-block", marginRight: "10px" }}
                    className="def-blue"
                    htmlType="submit"
                  >
                    <IntlMessages id="admin.button.update" />
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Spin>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return { ...state.user };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...UserActions, ...paginationActions }, dispatch);
}
const WrappedForm = Form.create({ name: "admin-profile" })(UserProfile);
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    WrappedForm
  )
);
