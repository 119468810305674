import API from "../api/asset-tracking-api";

export function addAssetTracking(data) {
    return async function (dispatch, getAction) {
        try {
            let resp = await API.addAssetTracking(data);
            return resp;

        } catch (error) {
            return { "error": true }
        }
    }
}

export function updateAssetTracking(data, id) {
    return async function (dispatch, getAction) {
        try {
            let resp;
            if (id > 0) {
                resp = await API.updateAssetTracking(data, id);
            } else {
                resp = await API.addAssetTracking(data);
            }
            return resp;
        } catch (error) {
            return { "error": true }
        }
    }
}

export function getAssetTracking(data,id) {
    return async function (dispatch, getAction) {
        try {
            let resp = await API.getAssetTracking(data,id);
            return resp;

        } catch (error) {
            return { "error": true }
        }
    }
}

export function getAssetTrackingById(id) {
    return async function (dispatch, getAction) {
        try {
            let resp = await API.getAssetTrackingById(id);
            return resp;
        } catch (error) {
            return { "error": true }
        }
    }
}