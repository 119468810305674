//import Axios from "../../services/axios-service";
import Config from "../../../../config";

const getB2BDailyReport = (startDate, endDate) => {
  return Config.API1+'/api/Invoice/B2BDailyData?startDate='+encodeURIComponent(startDate)+'&endDate='+encodeURIComponent(endDate);
};
const getB2BMonthlyReport = (startDate, endDate) => {
	return Config.API1+'/api/Invoice/B2BMonthlyData?startDate='+encodeURIComponent(startDate)+'&endDate='+encodeURIComponent(endDate);
};
const getB2CDailyReport = (startDate, endDate) => {
	return Config.API1+'/api/Invoice/B2CDailyData?startDate='+encodeURIComponent(startDate)+'&endDate='+encodeURIComponent(endDate);
};
const getB2CMonthlyReport = (startDate, endDate) => {
	return Config.API1+'/api/Invoice/B2CMonthlyData?startDate='+encodeURIComponent(startDate)+'&endDate='+encodeURIComponent(endDate);
};

export default {
    getB2BDailyReport,
    getB2BMonthlyReport,
    getB2CDailyReport,
    getB2CMonthlyReport
};
