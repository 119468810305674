import API from "../api/geofence-api";

export function addGeoFenceData(data) {
    return async function (dispatch, getAction) {
        try {
            let resp = await API.addGeoFence(data);
            return resp;
        } catch (error) {
            return { error: true };
        }
    };
}

export function updateGeoFenceData(data, id) {
    return async function (dispatch, getAction) {
        try {
            let resp;
            if (id > 0) {
                resp = await API.updateGeoFence(data, id);
            } else {
                resp = await API.addGeoFence(data);
            }
            return resp;
        } catch (error) {
            return { "error": true }
        }
    }
}

export function getGeoFenceData(id,data) {
    return async function (dispatch, getAction) {
        try {
            let resp = await API.getGeoFence(id,data);
            return resp;
        } catch (error) {
            return { error: true };
        }
    };
}

export function getGeoFenceDataById(id) {
    return async function (dispatch, getAction) {
        try {
            let resp = await API.getGeoFenceById(id);
            return resp;
        } catch (error) {
            return { error: true };
        }
    };
}

export function addGeoFenceRule(data) {
    return async function (dispatch, getAction) {
        try {
            let resp = await API.addGeoFenceRule(data);
            return resp;
        } catch (error) {
            return { error: true };
        }
    };
}

export function updateGeoFenceRule(data) {
    return async function (dispatch, getAction) {
        try {
            let resp = await API.updateGeoFenceRule(data);
            return resp;
        } catch (error) {
            return { error: true };
        }
    };
}

export function getGeoFenceRule(id) {
    return async function (dispatch, getAction) {
        try {
            let resp = await API.getGeoFenceRule(id);
            return resp;
        } catch (error) {
            return { error: true };
        }
    };
}

export function getGeoFenceRulesByGeoFenceId(id, data) {
    return async function (dispatch, getAction) {
        try {
            let resp = await API.getGeoFenceRulesByGeoFenceId(id, data);
            return resp;
        } catch (error) {
            return { error: true };
        }
    };
}
