import React, { Component } from "react";
import ActivityLog from "../audit-logs/audit-logs";

class B2B2COrganizationAuditLogs extends Component {
  state = {
    data: []
  }
  render() {
      return (
        <ActivityLog for="b2b2c-organization"/>
      )
  }
}
export default B2B2COrganizationAuditLogs;