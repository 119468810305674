import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as b2b2cActions from "../../redux/actions/b2b2c-actions";
import { message, Modal, Tooltip, Popconfirm, Radio , Form} from "antd";
import config from "../../../../config";
import logo from "../../images/logo.png";
//import { StripeProvider } from 'react-stripe-elements';
import StripeProvider from "../plan-upgrade/stripe-payment/StripeProvider";


class PurchaseUpgradedPlan extends Component {
  constructor(props) {
    super(props);
    this.state = {
        status : false,
    };
  }

  async componentDidMount() {
    console.log(this.props)
  }
  
  
  
  render() {
    return (
      <div>
        <header id="headerb2b2c">
        <div className="headerb2b2c-inner">
          <div className="wrap">
            <section className="top-header">
              <div className="row">
               <div className={ "logo col-md-12 col-md-12  text-center"}>
                          <img
                            src={logo}
                            alt="CAP™"
                            height = "90"
                            width="118"
                          />
                      </div>
                  </div>
            </section>
          </div>
        </div>
      </header>
      <section id="banner">
      </section>
</div>
    
    );
  }
        
}

        


PurchaseUpgradedPlan.propTypes = {

};

function mapStateToProps(state) {
    return {
      ...state.b2b2c,
    };
  }
  function mapDispatchToProps(dispatch) {
    return bindActionCreators({ ...b2b2cActions,  }, dispatch);
  }



const WrappedForm = Form.create({ name: "" })(
    PurchaseUpgradedPlan
);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    WrappedForm
  )
);
