import API from "../api/countries-api";

export function getCountriesListing({ filters, pagination, sorter }) {
  return async function(dispatch, getState) {
    try {
      let resp = await API.getCountriesList({
        filters: filters,
        pagination: pagination,
        sorter: sorter
      });
      return resp;
    } catch (e) {
      return { error: true };
    }
  };
}

export function getCountriesListbyId(idx) {
  return async function(dispatch, getState) {
    try {
      let resp = await API.getCountriesbyId(idx);

      return resp;
    } catch (e) {
      return { error: true };
    }
  };
}
export function updateCountriesData(data, id) {
  return async function(dispatch, getState) {
    try {
      let resp;
      if (id === 0) {
        resp = await API.addCountries(data);
      } else {
        resp = await API.updateCountries(data, id);
      }
      return resp;
    } catch (e) {
      console.log(e);
      return { error: true };
    }
  };
}
