import Axios from "../../services/axios-service";

//organization types
const getResellerOrganizationTypes = (data) => {
  return Axios.get("/api/OrganizationResellerTypes", data, undefined);
};

const getTravellerInfo = async (userId) => {
  return Axios.get("/api/travelerbasicinfo/" + userId, undefined, undefined);
};

const getOrganizationTypes = (data) => {
  return Axios.get("/api/OrganizationActiveTypes", data, undefined);
};

const getOrganizationType = (idx) => {
  return Axios.get("/organization-types/" + idx, undefined, undefined);
};

const updateOrganizationType = (data) => {
  return Axios.put("/organization-types", data, undefined);
};

const deleteOrganizationType = (data) => {
  return Axios.delete("/organization-types", data, undefined);
};

//organizations
const getOrganizations = (data) => {
  return Axios.get(
    "/api/B2B2COrganization?search=" + encodeURIComponent(JSON.stringify(data))
  );
};

const getOrganization = (idx) => {
  return Axios.get("/api/B2B2COrganization/" + idx, undefined, undefined);
};
const CheckIsGuardMeOrg = (idx) => {
  return Axios.get("/api/CheckIsGuardMeOrg/" + idx, undefined, undefined);
};
const updateOrganization = (data) => {
  return Axios.put("/api/B2B2COrganization", data, undefined);
};

const deleteOrganization = (data) => {
  return Axios.delete("/organizations", data, undefined);
};
const addOrganizations = (data) => {
  return Axios.post("/api/Organization/onboard", data, undefined);
};
const B2B2CGuardMeNewOrder = (data) => {
  return Axios.post(
    "/api/B2B2COrganization/B2B2CGuardMeNewOrder",
    data,
    undefined
  );
};
const onboardB2B2COrganization = (data) => {
  return Axios.post("/api/B2B2COnBoard", data, undefined);
};
const getPccList = (data) => {
  return Axios.get("/api/PCC/PCCList", data, undefined);
};
const PCCAccountList = (data) => {
  return Axios.get("/api/PCC/PCCAccountList/" + data, undefined);
};
const getPccAccountById = (data) => {
  return Axios.get("/api/PCC/PCCAccount/" + data, undefined);
};
const getOrganizationById = (data) => {
  return Axios.get("/api/B2B2COrganization/" + data, undefined);
};
const getOrganizationProducts = (orgId) => {
  return Axios.get("/api/OrganizationProducts/" + orgId, undefined, undefined);
};
const getOrganizationLicenses = (args) => {
  let orgId = args.orgId;
  delete args.orgId;
  return Axios.get(
    "/api/OrganizationLicenses/" +
      orgId +
      "?search=" +
      encodeURIComponent(JSON.stringify(args)),
    undefined,
    undefined
  );
};
const getOrganizationLicensesByProduct = (args) => {
  let orgId = args.orgId;
  let productId = args.productId;
  delete args.orgId;
  delete args.productId;
  return Axios.get(
    "/api/OrganizationProductLicenses/" +
      orgId +
      "/" +
      productId +
      "?search=" +
      encodeURIComponent(JSON.stringify(args)),
    undefined,
    undefined
  );
};
const assignLicense = (data) => {
  return Axios.post("/api/AssignLicenses", data, undefined);
};
const unassignLicense = (data) => {
  return Axios.post("/api/UnAssignLicense", data, undefined);
};
const updateOrganizationById = (data, id) => {
  return Axios.put("/api/B2B2COrganization/" + id, data, undefined);
};
const getLicenseLogs = (args) => {
  let _licenseId = args.licenseId;
  delete args.licenseId;
  return Axios.get(
    "/api/LicenseLogs/" +
      _licenseId +
      "?search=" +
      encodeURIComponent(JSON.stringify(args)),
    undefined,
    undefined
  );
};

const addorderinOrganization = (data) => {
  return Axios.post("/api/Organization/newOrder", data, undefined);
};
const getLicenseCount = (orgId) => {
  return Axios.get(
    "/api/OrganizationLicenseCount/" + orgId,
    undefined,
    undefined
  );
};
const removeLogo = (orgId) => {
  return Axios.put(
    "/api/RemoveOrganizationLogo/" + orgId,
    undefined,
    undefined
  );
};
const addDivision = (data) => {
  return Axios.post("/api/OrganizationBranch/", data, undefined);
};
const getDivisions = (args) => {
  console.log(args);
  let _orgId = args.orgId;
  delete args.orgId;
  return Axios.get(
    "/api/OrganizationBranches/" +
      _orgId +
      "?search=" +
      encodeURIComponent(JSON.stringify(args)),
    undefined,
    undefined
  );
};
const getDivisionsTree = (orgId) => {
  return Axios.get("/api/OrganizationHierarchy/" + orgId, undefined, undefined);
};
const getDivision = (divisionId) => {
  return Axios.get(
    "/api/OrganizationBranch/" + divisionId,
    undefined,
    undefined
  );
};
const updateDivision = (divisionId, data) => {
  return Axios.put("/api/OrganizationBranch/" + divisionId, data, undefined);
};
const getOrganizationEmployees = (args) => {
  let _orgId = args.orgId;
  delete args.orgId;
  return Axios.get(
    "/api/OrganizationEmployees/" +
      _orgId +
      "?search=" +
      encodeURIComponent(JSON.stringify(args)),
    undefined,
    undefined
  );
};
const getBillableOrganizations = (orgId) => {
  return Axios.get("/api/BillableOrganizations/" + orgId, undefined, undefined);
};
const getActiveThemes = () => {
  return Axios.get("/api/ActiveThemes/", undefined, undefined);
};
const removeBackgroundImage = (orgId) => {
  return Axios.put("/api/RemoveBackgroundImage/" + orgId, undefined, undefined);
};
const removeWizardIcon = (orgId) => {
  return Axios.put(
    "/api/RemoveInactiveWizaredIcons/" + orgId,
    undefined,
    undefined
  );
};
const removeWizardActiveIcon = (orgId) => {
  return Axios.put(
    "/api/RemoveActiveWizardIcons/" + orgId,
    undefined,
    undefined
  );
};
const guardMeAssignLicense = (orgId, data) => {
  return Axios.post("/api/GuardMeAssignLicense/" + orgId, data, undefined);
};
const ExportGuardMeTravellersList = (orgId) => {
  return Axios.get("/api/ui/ExportGuardMeTravellersList/" + orgId, undefined);
};

const guardMeChangeAssignedPlanDate = (data) => {
  return Axios.post("/api/guardMeChangeAssignedPlanDate/", data, undefined);
};
const B2BEmployeeUpgrade = (data) => {
  return Axios.post("/api/B2BEmployeeUpgrade/check/", data, undefined, undefined);
};

const B2BEmployeeUpgradeStripe = (data) => {
  return Axios.post("/api/B2BEmployeeUpgrade/payment/stripe", data, undefined);
};


export default {
  getOrganizationTypes,
  getOrganizationType,
  updateOrganizationType,
  deleteOrganizationType,
  getOrganizations,
  getOrganization,
  updateOrganization,
  deleteOrganization,
  addOrganizations,
  getPccList,
  PCCAccountList,
  getOrganizationById,
  getOrganizationProducts,
  getOrganizationLicenses,
  getOrganizationLicensesByProduct,
  assignLicense,
  unassignLicense,
  updateOrganizationById,
  getLicenseLogs,
  addorderinOrganization,
  getLicenseCount,
  removeLogo,
  addDivision,
  getDivisions,
  getDivisionsTree,
  getDivision,
  updateDivision,
  getOrganizationEmployees,
  getBillableOrganizations,
  getResellerOrganizationTypes,
  getActiveThemes,
  onboardB2B2COrganization,
  removeBackgroundImage,
  removeWizardIcon,
  removeWizardActiveIcon,
  getPccAccountById,
  B2B2CGuardMeNewOrder,
  guardMeAssignLicense,
  getTravellerInfo,
  ExportGuardMeTravellersList,
  guardMeChangeAssignedPlanDate,
  CheckIsGuardMeOrg,
  B2BEmployeeUpgrade,
  B2BEmployeeUpgradeStripe
};
