import { Types } from "../constants/notification-types";
const initialState = {
  nlist : [],
  fcmActive: false,
  fcmToken: null
};
  
export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case Types.API_ERROR:
      return {
        ...state,
        apiError :{
        code: action.payload.code,
        message: action.payload.message}
      };
    case Types.API_SUCCESS:
      return {
        ...state,nlist:[...state.nlist,action.payload]
      };
    case Types.CLEAR_API_SUCCESS:
      return {
        ...state,
        nlist: state.nlist.filter(item => item.id !== action.payload.id)
      };
    case Types.FCM_DEVICE_ADDED:
      return {
        ...state,
        fcmActive: action.payload.status,
        fcmToken: action.payload.token
      };    
    default:
      return state;
  }
}
