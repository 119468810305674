import React, { Component } from "react";
import {
  Typography,
  Row,
  Col,
  Form,
  Input,
  Button,
  Upload,
  Radio,
  Icon,
  Select,
  Checkbox,
  message,
  TreeSelect,
  Spin,Popconfirm
} from "antd";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as customAlertActions from "../../../redux/actions/custom-alert-actions";
import IntlMessages from "../../../services/intlMesseges";
import Axios from "axios";
import * as UserServices from "../../../services/user-service";
import config from "../../../../../config";
import { notifyUser } from "../../../services/notification-service";
import * as OrgActions from "../../../redux/actions/organization-actions";
import * as travellerActions from "../../../redux/actions/travellers-actions";
import * as Permission from "../../../services/permissions";
import AppLocale from "../../../languageProvider";
import * as userActions from "../../../redux/actions/user-actions";
const options = [
  { label: <IntlMessages id="admin.customalert.sms" />, value: 1 },
  { label: <IntlMessages id="admin.customalert.email" />, value: 2 },
  { label: <IntlMessages id="admin.customalert.push" />, value: 3 },
];
const { TextArea } = Input;
const { Option } = Select;

const children = [];
for (let i = 10; i < 36; i++) {
  children.push(<Option key={i.toString(36) + i}>{i.toString(36) + i}</Option>);
}

class CustomAlert extends Component {
  constructor() {
    super();
    this.state = {
      orgsRequired: false,
      parentOrgAllSelected: false,
      sendtoAllAlertRecepients: 0,
      childOrgs: [],
      parentOrganization: null,
      assignedDivisionsAuto: false,
      parentOrgCurrentPage: 1,
      formLayout: "vertical",
      logo: null,
      hasLogo: false,
      stateuser: 1,
      stateuser2: 1,
      value: false,
      orgData: [],
      travelerData: [],
      employeedata: [],
      alertto: [],
      sms: false,
      email: false,
      notification: false,
      disabledfields: false,
      loading: false,
      uploadFileName: "",
      usercanreply: false,
    };
    this.onChangeids = this.onChangeids.bind(this);
    this.handlesubmit = this.handlesubmit.bind(this);
    this.fetchChildOrganizations = this.fetchChildOrganizations.bind(this);
  }
  onChangeids(checkedValues) {
    if (checkedValues.filter((data) => data === 3).length > 0) {
      this.setState({ usercanreply: true, notification: true });
    } else {
      this.setState({ usercanreply: false, notification: false });
    }
    if (checkedValues.filter((data) => data === 2).length > 0) {
      this.setState({ email: true });
    } else {
      this.setState({ email: false });
    }
    if (checkedValues.filter((data) => data === 1).length > 0) {
      this.setState({ sms: true });
    } else {
      this.setState({ sms: false });
    }
    let data = [];
    for (var i = 0; i < checkedValues.length; i++) {
      data.push({ id: checkedValues[i] });
      this.setState({ alertto: data });
    }
  }
  componentDidMount() {
    if (Permission.isRole("Administrator") || Permission.isRole("CSR")) {
      this.props.getOrganizations({}).then((org) => {
        let data = [{ organizationId: 0, name: "All" }];
        this.setState({
          orgData: data.concat(org.data),
          parentOrgs: data.concat(org.data),
        });
      });
      this.props.getTravellersListing({}).then((traveler) => {
        let travellerinfo = [{ userId: 0, firstName: "All", lastName: "" }];
        this.setState({ travelerData: travellerinfo.concat(traveler.data) });
      });
    }
    this.props
      .getUserListing({
        orgId: UserServices.getUser().companyID,
      })
      .then((traveler) => {
        this.setState({ employeedata: traveler.data });
      });
    if (!Permission.isRole("Administrator") && !Permission.isRole("CSR")) {
      this.fetchChildOrganizations(UserServices.getUser().companyID);
    }
  }
  onChange = (e) => {
    this.setState({
      value: e.target.value,
    });
  };
  handleFormLayoutChange = (e) => {
    this.setState({ formLayout: e.target.value });
  };
  logoUploadProps = () => {
    let _this = this;
    return {
      multiple: false,
      showUploadList: false,
      accept: ".jpg,.jpeg,.png, .txt,.pdf,.csv,.docx,.jpg,.xlsx",
      beforeUpload: (file) => {
        _this.setState({ logo: file, uploadFileName: file.name });
        return false;
      },
      onRemove: (_file) => {
        _this.setState({ logo: null, uploadFileName: "" });
      },
      onSuccess: (_res) => {
        _this.setState({ logo: null });
      },
      onError(_err) {
        message.error(<IntlMessages id="admin.fileupload.failed" />);
      },
    };
  };
  handlesubmit() {
    let _this = this;
    const { language } = this.props;
    const currentAppLocale = AppLocale[language.locale];
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        if (
          values.parentOrg &&
          typeof values.parentOrg === "undefined" &&
          (typeof values.orgname === "undefined" ||
            values.orgname.length <= 0) &&
          typeof values.travellerdata === "undefined" &&
          this.state.sendtoAllAlertRecepients == 0
        ) {
          notifyUser("Please Select one of target recipient!", "error");
        } else {
          let allExists =
            values.travellerdata &&
            values.travellerdata.findIndex(
              (e) => e.key === "all" || e.key === 0 || e.key === -1
            );
          if (
            /* values.travellerdata && values.travellerdata.includes("0") */ allExists >
              -1 &&
            values.parentOrg &&
            values.parentOrg.key === "0"
          ) {
            _alertRecepients = [{ recipientOrgId: 0, recipientUserId: 0 }];
          } else {
            var _alertRecepients = [];
            if (this.state.sendtoAllAlertRecepients == 1) {
              _alertRecepients.push({ recipientOrgId: 0, recipientUserId: -1 });
            }
            if (
              typeof values.orgname !== "undefined" &&
              values.orgname.length > 0
            ) {
              for (var a = 0; a < values.orgname.length; a++) {
                _alertRecepients.push({
                  recipientOrgId: values.orgname[a].value,
                  recipientUserId: 0,
                });
              }
            }
            if (
              typeof values.travellerdata !== "undefined" &&
              values.travellerdata.length > 0
            ) {
              let _allTravelers = values.travellerdata.findIndex(
                (e) => e.key === "all" || e.key === 0 || e.key === -1
              );
              if (_allTravelers > -1) {
                _alertRecepients.push({
                  recipientOrgId: -1,
                  recipientUserId: 0,
                });
              } else {
                for (
                  var a = 0;
                  a <
                  this.state.defaultSelectedTravellers
                    .length /* values.travellerdata.length */;
                  a++
                ) {
                  _alertRecepients.push({
                    recipientOrgId:
                      this.state.defaultSelectedTravellers[a].companyId > 0
                        ? this.state.defaultSelectedTravellers[a].companyId
                        : 1,
                    recipientUserId: this.state.defaultSelectedTravellers[a]
                      .userId,
                  });
                }
              }
            }
            if (
              typeof values.parentOrg !== "undefined" &&
              (values.parentOrg == 0 ||
                values.parentOrg == "0" ||
                parseInt(values.parentOrg.key) === 0)
            ) {
              _alertRecepients.push({ recipientOrgId: 0, recipientUserId: -1 });
            }
          }
          const formData = new FormData();
          formData.append("file", this.state.logo);
          formData.set("Summary", values.summary);
          formData.set("Description", values.description);
          formData.set("AlertName", values.alert_name);
          formData.set(
            "UserCanReply",
            values.usercanapply ? values.usercanapply : false
          );
          formData.set("IsActive", true);
          formData.set("CommunicationModes", this.state.alertto);
          formData.set("AlertRecipientsStr", JSON.stringify(_alertRecepients));
          formData.set("IsSMSNotification", this.state.sms);
          formData.set("IsEmailNotification", this.state.email);
          formData.set("IsPushNotification", this.state.notification);
          let token = UserServices.getToken();
          let _headers = { "Content-Type": "multipart/form-data" };
          if (token)
            _headers["authorization"] = token ? "Bearer " + token : undefined;

          Axios({
            method: "post",
            url: config.API1 + "/api/CustomAlert",
            data: formData,
            headers: _headers,
          })
            .then(function(_response) {
              _this.pushdata();
              notifyUser(
                currentAppLocale.messages["admin.customalert.success"],
                "success"
              );
              _this.setState({ loading: false });
            })
            .catch(function(_response) {
              _this.setState({ loading: false });
            });
        }
      }
    });
  }
  pushdata() {
    this.props.history.push("../");
  }
  getOtherOrgData = (e) => {
    if (e.target.scrollTop + e.target.offsetHeight >= e.target.scrollHeight) {
      var datas = this.state.stateuser + 1;
      this.props
        .getOrganizations({
          orgId: 1,
          filters: {},
          pagination: { page: datas, pageSize: 10 },
          sorter: {},
        })
        .then((resp) => {
          let data = this.state.orgData;
          this.setState({ stateuser: datas });
          this.setState({
            orgData: data.concat(resp.data),
          });
        })
        .catch((ex) => {
          this.setState({ loading: false });
        });
    }
  };

  fetchtraveller = (value) => {
    this.setState({ fetching: true });
    let travellerinfo = [];
    if (Permission.isRole(["Administrator", "CSR"])) {
      travellerinfo = [{ userId: -1, firstName: "All", lastName: "" }];
    }

    this.props
      .getTravellersListing({
        orgId: 1,
        filters: { displayName: value },
        pagination: { page: 1, pageSize: 10 },
        sorter: {},
      })
      .then((resp) => {
        this.setState({
          fetching: false,
          travelerData: travellerinfo.concat(resp.data),
        });
      })
      .catch((ex) => {
        this.setState({ fetching: false });
      });
  };
  fetchemployee = (value) => {
    this.setState({ fetching: true });
    let travellerinfo = [];
    if (Permission.isRole(["Administrator", "CSR"])) {
      travellerinfo = [{ userId: -1, firstName: "All", lastName: "" }];
    }
    this.props
      .getUserListing({
        orgId: UserServices.getUser().companyID,
        filters: { firstName: value },
        pagination: { page: 1, pageSize: 10 },
        sorter: {},
      })
      .then((resp) => {
        this.setState({
          fetching: false,
          employeedata: travellerinfo.concat(resp.data),
        });
      })
      .catch((ex) => {
        this.setState({ fetching: false });
      });
  };
  fetchParentOrganizations = (e) => {
    if (e.target.scrollTop + e.target.offsetHeight >= e.target.scrollHeight) {
      var datas = this.state.parentOrgCurrentPage + 1;
      this.props
        .getOrganizations({
          filters: {},
          pagination: { page: datas, pageSize: 10 },
          sorter: {},
        })
        .then((resp) => {
          let data = this.state.parentOrgs;
          this.setState({ parentOrgCurrentPage: datas });
          this.setState({
            parentOrgs: data.concat(resp.data),
          });
        })
        .catch((ex) => {
          this.setState({ loading: false });
        });
    }
  };
  searchparentOrg = (e) => {
    if (e === "" || e === null) {
      this.setState({ stateuser: 1 });
    }
    this.props
      .getOrganizations({
        orgId: 1,
        filters: { companyName: e },
        pagination: { page: 1, pageSize: 10 },
        sorter: {},
      })
      .then((resp) => {
        this.setState({
          fetching: false,
          parentOrgs: resp.data,
        });
      })
      .catch((ex) => {
        this.setState({ fetching: false });
      });
  };
  fetchChildOrganizations = (orgId) => {
    let data;
    if (orgId) {
      if (orgId.key) {
        data = orgId.key;
      } else {
        data = orgId;
      }
      if (data === 0 || data === "0" || data === "all") {
        this.setState({ parentOrgAllSelected: true, orgsRequired: false });
      } else {
        this.setState({ parentOrgAllSelected: false, orgsRequired: true });
      }
      if (data) {
        this.setState({ parentOrganization: data });
        this.props.getDivisionsList(data).then((resp) => {
          let parentId;
          for (var i in resp.data) {
            if (resp.data[i].organizationId == data) {
              parentId = resp.data[i].parentOrgId;
            }
          }
          let list = this.unflatten(resp.data);
          this.setState({
            childOrgs: list,
          });
          this.props.form.setFieldsValue({ orgname: [] });
        });
      }
    }
  };
  unflatten = (arr) => {
    var tree = [],
      mappedArr = {},
      arrElem,
      mappedElem;
    if (arr) {
      for (var i = 0, len = arr.length; i < len; i++) {
        arrElem = arr[i];
        arrElem.title = arrElem.name;
        arrElem.value = arrElem.organizationId;
        arrElem.key = arrElem.organizationId;
        mappedArr[arrElem.organizationId] = arrElem;
        mappedArr[arrElem.organizationId]["children"] = [];
      }
      for (var organizationId in mappedArr) {
        if (mappedArr.hasOwnProperty(organizationId)) {
          mappedElem = mappedArr[organizationId];
          if (mappedElem.parentOrgId && mappedArr[mappedElem["parentOrgId"]]) {
            mappedArr[mappedElem["parentOrgId"]] &&
              mappedArr[mappedElem["parentOrgId"]]["children"].push(mappedElem);
          } else {
            tree.push(mappedElem);
          }
        }
      }
    }
    return tree;
  };
  getNestedChildren = (arr, parent) => {
    var out = [];
    for (var i in arr) {
      if (arr[i].parentOrgId == parent) {
        var children = this.getNestedChildren(arr, arr[i].organizationId);
        if (children.length) {
          arr[i].children = children;
        }
        arr[i].title = arr[i].name;
        arr[i].value = arr[i].organizationId;
        arr[i].label = arr[i].name;
        arr[i].key = arr[i].organizationId;
        out.push(arr[i]);
      }
    }
    return out;
  };
  fetchOrgnizations = (value) => {
    this.setState({ fetching: true });
    let data = [{ organizationId: -1, name: "All" }];
    this.props
      .getOrganizations({
        filters: { companyName: value },
        pagination: { page: 1, pageSize: 10 },
        sorter: {},
      })
      .then((resp) => {
        this.setState({
          fetching: false,
          orgData: data.concat(resp.data),
        });
      })
      .catch((ex) => {
        this.setState({ fetching: false });
      });
    //this.setState({ orgData: [], fetching: true });
  };
  getOthertravelerData = (e) => {
    if (e.target.scrollTop + e.target.offsetHeight >= e.target.scrollHeight) {
      var datas = this.state.stateuser2 + 1;
      this.props
        .getTravellersListing({
          orgId: 1,
          filters: {},
          pagination: { page: datas, pageSize: 10 },
          sorter: {},
        })
        .then((resp) => {
          let data = this.state.travelerData;
          this.setState({ stateuser2: datas });
          this.setState({
            travelerData: data.concat(resp.data),
          });
        })
        .catch((ex) => {
          this.setState({ loading: false });
        });
    }
  };
  getOtheremployeedata = (e) => {
    if (e.target.scrollTop + e.target.offsetHeight >= e.target.scrollHeight) {
      var datas = this.state.stateuser2 + 1;
      this.props
        .getUserListing({
          orgId: UserServices.getUser().companyID,
          filters: {},
          pagination: { page: datas, pageSize: 10 },
          sorter: {},
        })
        .then((resp) => {
          let data = this.state.employeedata;
          this.setState({ stateuser2: datas });
          this.setState({
            employeedata: data.concat(resp.data),
          });
        })
        .catch((ex) => {
          this.setState({ loading: false });
        });
    }
  };

  onChangeTargetedOrganizations = (selected) => {
    let _selected = [];
    if (selected.length > 0) {
      selected.map(function(el) {
        _selected.push({
          key: el.value,
          label: el.label,
          branchId: el.value,
        });
      });
    }
    this.setState({ assignedOrganizations: _selected });
  };

  onAlertCheckboxChange = (e) => {
    if (e.target.checked) {
      this.setState({
        disabledfields: true,
        orgData: 0,
        sendtoAllAlertRecepients: 1,
        orgsRequired: false,
      });
      this.props.form.setFieldsValue({
        travellerdata: [{ key: "all", label: "All" }],
        orgname: undefined,
        parentOrg: { key: "0", label: ["All", " "] },
      });
    } else {
      this.setState({ disabledfields: false, sendtoAllAlertRecepients: 0 });
      this.props.form.setFieldsValue({
        travellerdata: undefined,
        orgname: undefined,
        parentOrg: undefined,
      });
    }
  };

  setAssignedDivisionsAuto = (e) => {
    this.setState({ assignedDivisionsAuto: e.target.checked });
  };

  setTravelers = (_idx, option) => {
    let assignedUsersList = [];
    if (this.state.defaultSelectedTravellers) {
      assignedUsersList = this.state.defaultSelectedTravellers;
    }
    if (option.key == "all") {
      assignedUsersList.push({
        userId: 0,
        companyId: -1,
      });
    } else {
      assignedUsersList.push({
        userId: option.props.value,
        companyId: option.props.companyid,
      });
    }

    this.setState({ defaultSelectedTravellers: assignedUsersList });
  };

  unsetTravelers = (uid) => {
    let assignedUsersList = this.state.defaultSelectedTravellers;
    let removedEmployee = -1;
    removedEmployee = assignedUsersList.findIndex(
      (e) => e.userId == (uid.key === "all" ? parseInt("0") : uid.key)
    );
    if (removedEmployee > -1) {
      assignedUsersList.splice(removedEmployee, 1);
    }
    this.setState({ defaultSelectedTravellers: assignedUsersList });
  };
  removeUploadingFile = () => {
    this.setState({
      uploadFileName: "",
      logo: null,
    });
  };
  render() {
    const { getFieldDecorator } = this.props.form;
    const { formLayout } = this.state;
    const formItemLayout =
      formLayout === "horizontal"
        ? {
            labelCol: { span: 4 },
            wrapperCol: { span: 14 },
          }
        : null;
    return (
      <Spin spinning={this.state.loading}>
        <div>
          <Row gutter={24}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Typography.Title level={4}>
                <IntlMessages id="admin.sidebar.submenu.customAlert" />
              </Typography.Title>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Button
                type="primary"
                className="right-fl def-blue"
                htmlType="button"
                onClick={() => this.props.history.goBack()}
              >
                <IntlMessages id="admin.button.back" />
              </Button>
            </Col>
          </Row>
          <hr />
          <Row gutter={24}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                {...formItemLayout}
                label={<IntlMessages id="admin.customalert.name" />}
              >
                {getFieldDecorator("alert_name", {
                  rules: [
                    {
                      required: true,
                      message: <IntlMessages id="admin.input.required" />,
                    },
                  ],
                  initialValue:
                    this.state.alertName === null ? "" : this.state.alertName,
                })(<Input maxLength={100} max={50} />)}
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                {...formItemLayout}
                label={<IntlMessages id="admin.customalert.summary" />}
              >
                {getFieldDecorator("summary", {
                  rules: [
                    {
                      required: true,
                      message: <IntlMessages id="admin.input.required" />,
                    },
                  ],
                  initialValue:
                    this.state.summary === null ? "" : this.state.summary,
                })(<Input maxLength={200} />)}
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={24}>
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={12}
              className="custome-alert-des"
            >
              <Form.Item
                {...formItemLayout}
                label={<IntlMessages id="admin.customalert.description" />}
              >
                {getFieldDecorator("description", {
                  rules: [
                    {
                      required: true,
                      message: <IntlMessages id="admin.input.required" />,
                    },
                  ],
                  initialValue:
                    this.state.description === null
                      ? ""
                      : this.state.description,
                })(<TextArea maxLength={500} rows={4} />)}
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form.Item
                className="upload-custome-alert"
                label={<IntlMessages id="admin.label.customAlertDoc" />}
              >
                <Upload {...this.logoUploadProps()}>
                  <Button>
                    <Icon type="upload" />{" "}
                    <IntlMessages id="admin.select.uploadfile" />
                  </Button>
                </Upload>
                <span>
                  {this.state.uploadFileName != "" ? (
                    <span>
                      <br />
                      {this.state.uploadFileName}
                      {/* <button
                        title="Remove"
                        className="icon"
                        onClick={() => this.removeUploadingFile()}
                      >
                        <i className="fas fa-trash"></i>
                      </button> */}
                      <Popconfirm
                        title="Are you sure to delete this file?"
                        description="Remove"
                        onConfirm={() => this.removeUploadingFile()}
                      >
                      <Button style={{ marginLeft: 8 }}><i className="fas fa-trash"></i></Button>
                      </Popconfirm>
                    </span>
                  ) : (
                    ""
                  )}
                </span>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={24}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Typography.Title level={3}>
                <IntlMessages id="admin.customAlerts.target"></IntlMessages>
              </Typography.Title>
            </Col>
          </Row>

          {Permission.isRole("Administrator") || Permission.isRole("CSR") ? (
            <Row gutter={24}>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Form.Item>
                  {getFieldDecorator("bothAlertRecipients", {
                    initialValue: this.state.isBothAlertChecked,
                  })(
                    <Row>
                      <Col span={8}>
                        <Checkbox
                          defaultChecked={this.state.isBothAlertChecked}
                          onChange={this.onAlertCheckboxChange}
                        >
                          <IntlMessages id="admin.customAlerts.both"></IntlMessages>
                        </Checkbox>
                      </Col>
                    </Row>
                  )}
                </Form.Item>
              </Col>
            </Row>
          ) : (
            ""
          )}
          <Row gutter={24}>
            {Permission.isRole("Administrator") || Permission.isRole("CSR") ? (
              <div>
                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                  <Form.Item
                    {...formItemLayout}
                    label={<IntlMessages id="admin.division.parentCompany" />}
                  >
                    {getFieldDecorator("parentOrg")(
                      <Select
                        allowClear
                        showSearch
                        onSearch={this.searchparentOrg}
                        disabled={this.state.disabledfields}
                        // filterOption={false}
                        style={{ width: "100%" }}
                        optionFilterProp="children"
                        labelInValue={true}
                        onPopupScroll={this.fetchParentOrganizations}
                        onChange={this.fetchChildOrganizations}
                      >
                        {this.state.parentOrgs
                          ? this.state.parentOrgs.map(function(item) {
                              return (
                                <Option key={item.organizationId}>
                                  {item.name}{" "}
                                </Option>
                              );
                            })
                          : ""}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                  <Form.Item
                    {...formItemLayout}
                    label={
                      <IntlMessages id="admin.invoice.organization/division" />
                    }
                  >
                    {getFieldDecorator("orgname", {
                      rules: [
                        {
                          required: this.state.orgsRequired,
                          message: (
                            <IntlMessages id="admin.input.required"></IntlMessages>
                          ),
                        },
                      ],
                    })(
                      <TreeSelect
                        allowClear
                        disabled={
                          this.state.parentOrgAllSelected ||
                          this.state.disabledfields
                        }
                        treeData={this.state.childOrgs}
                        style={{ width: "100%" }}
                        onChange={this.onChangeTargetedOrganizations}
                        treeCheckable={true}
                        labelInValue={true}
                        showCheckedStrategy={TreeSelect.SHOW_ALL}
                        treeCheckStrictly={!this.state.assignedDivisionsAuto}
                        treeDefaultExpandAll
                      />
                    )}
                    <Checkbox
                      checked={this.state.assignedDivisionsAuto}
                      onChange={this.setAssignedDivisionsAuto}
                    />{" "}
                    <IntlMessages id="admin.autoselect.childdivisions" />
                  </Form.Item>
                </Col>
              </div>
            ) : (
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Form.Item
                  {...formItemLayout}
                  label={
                    <IntlMessages id="admin.invoice.organization/division" />
                  }
                >
                  {getFieldDecorator("orgname", {
                    initialValue:
                      this.state.orgname === null ? "" : this.state.orgname,
                  })(
                    <TreeSelect
                      allowClear
                      disabled={this.state.disabledfields}
                      treeData={this.state.childOrgs}
                      style={{ width: "100%" }}
                      onChange={this.onChangeTargetedOrganizations}
                      treeCheckable={true}
                      labelInValue={true}
                      showCheckedStrategy={TreeSelect.SHOW_ALL}
                      treeCheckStrictly={!this.state.assignedDivisionsAuto}
                      treeDefaultExpandAll
                    />
                  )}
                  <Checkbox
                    checked={this.state.assignedDivisionsAuto}
                    onChange={this.setAssignedDivisionsAuto}
                  />{" "}
                  <IntlMessages id="admin.autoselect.childdivisions" />
                </Form.Item>
              </Col>
            )}
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              {Permission.isRole("Administrator") ||
              Permission.isRole("CSR") ? (
                <Form.Item
                  {...formItemLayout}
                  label={
                    <IntlMessages id="admin.manageOrders.travellerNameb2c" />
                  }
                >
                  {getFieldDecorator("travellerdata", {
                    initialValue: this.state.defaultSelectedTravellers,
                  })(
                    <Select
                      allowClear
                      disabled={this.state.disabledfields}
                      mode="multiple"
                      filterOption={false}
                      labelInValue={true}
                      onSearch={this.fetchtraveller}
                      style={{ width: "100%" }}
                      onPopupScroll={this.getOthertravelerData}
                      onSelect={this.setTravelers}
                      onDeselect={this.unsetTravelers}
                    >
                      {this.state.travelerData
                        ? this.state.travelerData.map(function(item) {
                            return (
                              <Option
                                key={item.userId}
                                value={item.userId}
                                companyid={item.companyId}
                              >
                                {item.firstName}{" "}
                                {item.lastName ? item.lastName : ""}{" "}
                                {item.userName ? "<" + item.userName + ">" : ""}
                              </Option>
                            );
                          })
                        : ""}
                    </Select>
                  )}
                </Form.Item>
              ) : (
                <Form.Item
                  {...formItemLayout}
                  label={<IntlMessages id="admin.manageOrders.travellerName" />}
                >
                  {getFieldDecorator("travellerdata", {
                    initialValue: this.state.defaultSelectedTravellers,
                  })(
                    <Select
                      allowClear
                      disabled={this.state.disabledfields}
                      mode="multiple"
                      filterOption={false}
                      labelInValue={true}
                      onSearch={this.fetchemployee}
                      style={{ width: "100%" }}
                      onPopupScroll={this.getOtheremployeedata}
                      onSelect={this.setTravelers}
                      onDeselect={this.unsetTravelers}
                    >
                      {this.state.employeedata
                        ? this.state.employeedata.map(function(item) {
                            return (
                              <Option
                                key={item.userId}
                                value={item.userId}
                                companyid={item.companyId}
                              >
                                {item.firstName} {item.lastName}{" "}
                                {item.userName ? "<" + item.userName + ">" : ""}
                              </Option>
                            );
                          })
                        : ""}
                    </Select>
                  )}
                </Form.Item>
              )}
            </Col>
          </Row>

          <Row gutter={24}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                {...formItemLayout}
                label={<IntlMessages id="admin.customalert.sendalertto" />}
              >
                {getFieldDecorator("sendalertto", {
                  rules: [
                    {
                      required: true,
                      message: <IntlMessages id="admin.input.required" />,
                    },
                  ],
                })(
                  <Checkbox.Group
                    options={options}
                    // defaultValue={["sms"]}
                    onChange={this.onChangeids}
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
          {this.state.usercanreply ? (
            <Row gutter={24}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Form.Item
                  {...formItemLayout}
                  label={<IntlMessages id="admin.customalert.userscanreply" />}
                >
                  {getFieldDecorator("usercanapply", {
                    rules: [
                      {
                        required: true,
                        message: <IntlMessages id="admin.input.required" />,
                      },
                    ],
                  })(
                    <Radio.Group onChange={this.onChange}>
                      <Radio value={true}>
                        <IntlMessages id="admin.customalert.yes" />
                      </Radio>
                      <Radio value={false}>
                        <IntlMessages id="admin.customalert.no" />
                      </Radio>
                    </Radio.Group>
                  )}
                </Form.Item>
              </Col>
            </Row>
          ) : (
            ""
          )}
          <Row gutter={24}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Button onClick={this.handlesubmit} type="primary">
                <IntlMessages id="admin.customalert.submit" />
              </Button>
            </Col>
          </Row>
        </div>
      </Spin>
    );
  }
}

function mapStateToProps(state) {
  return { ...state.user, ...state.language };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...customAlertActions,
      ...OrgActions,
      ...travellerActions,
      ...userActions,
    },
    dispatch
  );
}
const WrappedForm = Form.create({ name: "custom-alert" })(CustomAlert);
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    WrappedForm
  )
);
