import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as AdminActions from "../../../redux/actions/admin-actions";
import * as UserActions from "../../../redux/actions/user-actions";
import * as paginationActions from "../../../redux/actions/pagination-actions";
import API from "../../../redux/api/admin-api";
import IntlMessages from "../../../services/intlMesseges";
import AppLocale from "../../../languageProvider";
import {notifyUser} from "../../../services/notification-service";
import { Select, Spin, Typography, Row, Col, Form, Input, Button } from "antd";
const {Option} = Select;

class AdminProfile extends Component {
  state = {
    formLayout: "vertical",
    loading: true,
    countries: []
  };

  async componentDidMount(){
    this.props.clearPaginationExceptMe('profile');
    let user = await this.props.myAccount();
    this.setState(user.data);
    this.setState({
      loading: false, 
      dataLoaded: true
    });
    let countriesListTemp = await API.getCountriesList();
    this.setState({
      countries: countriesListTemp.countries
    });
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((_err, values) => {
      if (!_err) {
        Object.keys(values).map(function(key, _idx) {
          if(values[key] == null){
            values[key] = '';
          } else {
            if(typeof values[key] == 'string'){
              values[key] = values[key].trim();
            }
          }
        });
        this.setState({ loading: true });
        this.props
          .updateMyAccount(values)
          .then(response => {
            if (response.error) {
              if(response.error.message){
                notifyUser(response.error.message, "error");
              } else {
                notifyUser("Unknown error. Please try again!", "error");
              }
              this.setState({ loading: false });
            } else {
              const { language } = this.props;
              const currentAppLocale = AppLocale[language.locale];
              this.setState({ loading: false });
              this.props.updateLoggedInUserAccount(values);
              let currentUser = JSON.parse(localStorage.getItem('user'));
              currentUser.firstName = values.firstName;
              currentUser.lastName = values.lastName;
              currentUser.contactNo = values.contactNo;
              localStorage.setItem('user', JSON.stringify(currentUser));
              this.props.history.push('/admin/my-profile');
              notifyUser(currentAppLocale.messages["admin.profile.success"], 'success');
            }
          })
          .catch(_err => {
            this.setState({ loading: false });
          });
      }
    });
  };

  handleContactExistance(rule, value, callback){
    //const re = /^[0-9']+$/;
    const re = /^(?=[+0-9]*$)(?!.*[<>'"/;`%~@#$^*()_=[\]{}|\\,.?: -])/;
    var test=re.test(value)
    if(value !=="" && (value.length < 6 || test ===false)){
      callback(<IntlMessages id ="admin.phonenumber.valid"/>)
    }
    else{
      callback()
    }
}
  render() {
    const { getFieldDecorator } = this.props.form;
    const { formLayout } = this.state;
    const formItemLayout =
      formLayout === "horizontal"
        ? {
            labelCol: { span: 4 },
            wrapperCol: { span: 14 }
          }
        : null;
    
    const countriesSelector = getFieldDecorator("countryId", {
          initialValue: this.state.countryId
        })(
          <Select
          showSearch={true}
          placeholder="Select a person"
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }>
            {this.state.countries.map(function(item) {
              return (
                <Option key={item.countryId} value={item.countryId}>
                  {item.countryName}
                </Option>
              );
            })}
          </Select>
        );
    return (
      <div>
        <Row gutter={24}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Typography.Title level={4}>
              <IntlMessages id="admin.profile.myprofile" />
            </Typography.Title>
          </Col>
        </Row>
        <hr />
        <Spin spinning={this.state.loading}>
          <Form layout={formLayout} onSubmit={this.handleSubmit}>
            <Row gutter={24}>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Form.Item
                  {...formItemLayout}
                  label={<IntlMessages id="admin.userlisting.firstName" />}
                >
                  {getFieldDecorator("firstName", {
                    rules: [
                      {
                        required: true,
                        message: <IntlMessages id="admin.input.required" />
                      },
                      {
                        pattern: new RegExp(/^(\S.*)[a-zA-Z-.']+$/),
                        message: <IntlMessages id ="admin.name.valid"></IntlMessages>
                      }
                    ],
                    initialValue:
                      this.state.firstName === null
                        ? ""
                        : this.state.firstName
                  })(<Input />)}
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Form.Item
                  {...formItemLayout}
                  label={<IntlMessages id="admin.userlisting.lastName" />}
                >
                  {getFieldDecorator("lastName", {
                    rules: [
                      {
                        required: true,
                        message: <IntlMessages id="admin.input.required" />
                      },
                      {
                        pattern: new RegExp(/^(\S.*)[a-zA-Z-.']+$/),
                        message:  <IntlMessages id ="admin.lname.valid"></IntlMessages>
                      }
                    ],
                    initialValue:
                      this.state.lastName === null ? "" : this.state.lastName
                  })(<Input />)}
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Form.Item
                  {...formItemLayout}
                  label={<IntlMessages id="admin.userlisting.email" />}
                >
                  {getFieldDecorator("email", {
                    rules: [
                      {
                        type: "email",
                        message: "The input is not valid Email!"
                      },
                      {
                        required: true,
                        message: <IntlMessages id="admin.input.required" />
                      }
                    ],
                    initialValue:
                      this.state.email === null ? "" : this.state.email
                  })(
                    <Input disabled />
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Form.Item
                  {...formItemLayout}
                  label={<IntlMessages id="admin.userlisting.phonenumber" />}
                >
                  {getFieldDecorator("contactNo", {
                    rules: [
                      {
                        required: true,
                        validator: this.handleContactExistance
                      },
                      {
                        required: true,
                        message: <IntlMessages id="admin.input.required" />
                      },
                      
                    ],
                    initialValue:
                      this.state.contactNo === null
                        ? ""
                        : this.state.contactNo
                  })(<Input  maxLength={15} style={{ width: "100%" }} />)}
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Form.Item
                  {...formItemLayout}
                  label={<IntlMessages id="admin.userlisting.address" />}
                >
                  {getFieldDecorator("address", {
                    initialValue:
                      this.state.address === null ? "" : this.state.address
                  })(<Input />)}
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Form.Item
                  label={<IntlMessages id="admin.userlisting.country" />}
                >
                  {countriesSelector}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Form.Item
                  label={<IntlMessages id="admin.userlisting.state" />}
                >
                  {getFieldDecorator("state", {
                    initialValue:
                      this.state.state === null ? "" : this.state.state
                  })(<Input />)}
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Item>
                  <Button
                    type="primary"
                    style={{ display: "inline-block", marginRight: "10px" }}
                    className="def-blue"
                    htmlType="submit"
                  >
                    <IntlMessages id="admin.userlisting.update" />
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Spin>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return { ...state.user ,...state.language };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...AdminActions, ...UserActions, ...paginationActions }, dispatch);
}
const WrappedForm = Form.create({ name: "admin-profile" })(AdminProfile);
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    WrappedForm
  )
);
