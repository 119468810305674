import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as b2cActions from "../../../redux/actions/b2c-actions.js";
import { Row, Col, Button } from "antd";
import config from "../../../../../config.js";
import logo from "../../../images/logo.png";
import md5logo from "../../../images/md5logo.jpg";



class ThankYouPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      childNames: []
    };
  }
  
  componentDidMount() {
    const { location } = this.props;
    const childUserInfoString = localStorage.getItem('childUserInfo');
      // Parse the retrieved string back into an object
      const childUserInfo = JSON.parse(childUserInfoString);
    if (childUserInfoString) {
      const childUserInfoArray = JSON.parse(decodeURIComponent(childUserInfoString));
      const childNames = childUserInfoArray.map(childInfo => ({
        firstName: childInfo.FirstName,
        lastName: childInfo.LastName
      }));
      this.setState({ childNames });
    }
  }

   
  async componentWillUnmount() {
    //await this.props.flushOrderData();
  }
  render() {
    const { childNames } = this.state;
    return (
      <div>
      <header id="headerb2b2c">
      <div className="headerb2b2c-inner">
        <div className="wrap">
          <section className="top-header">
            <div className="row">
             <div className={ "logo col-md-12 col-md-12  text-center"}>
                        <img
                          src={md5logo}
                          alt="CAP™"
                          height = "90"
                          width="118"
                        />
                        <img
                          src={logo}
                          alt="CAP™"
                          height = "90"
                          width="118"
                        />
                        
                    </div>
                </div>
          </section>
        </div>
      </div>
    </header>
    <section id="banner">
      
        <div className="thank-you thank-you-partner" id="thank-you">
          <div
            className="wrap"
            style={{
              background: "#fff",
              border: "2px solid #58b0e2",
              padding: 12,
            }}
          >
            <Row gutter={24}>
              
              <Col sm={24} xs={24}>
                <h2
                  style={{
                    marginTop: "20px",
                    color: "#003da5",
                    fontSize: "18px",
                    textAlign: "center"
                  }}
                >
                  Thank You for Purchasing Care & Assistance Plus.
                 
                </h2>
                <div className="member-number">
                  
                </div>
                <p
                  style={{
                    marginTop: "20px",
                    color: "#003da5",
                    fontSize: "18px",
                    marginBottom: "3px",
                    textAlign: "center"
                  }}
                > Traveler List <br/>
                
                {childNames.map((child, index) => (
          <div key={index}>
            <p>Traveler {index + 1 } Name :  {child.firstName} {child.lastName}</p>
            
          </div>
        ))}
                </p>
                
                <br/>
                
              </Col>
            </Row>
          </div>
          <div className="wrap">
            <Row gutter={24} style={{ marginTop: 30, marginBottom: 30 }}>
              <Col style={{ textAlign: "center" }}>
           
                <Button
                  type="primary"
                  style={{ background: "#58b0e2", marginRight: "20px" }}
                  onClick={() =>
                    (window.location = "/family-purchase/688")
                  }
                >
                  Return Home
                </Button>
               
                
              </Col>
            </Row>
            <div className="row" style={{ textAlign: "center" }}>
              <div
                className="full-width"
                style={{ color: "black", fontSize: "20px" }}
              >
                <p style={{ color: "#203da5", fontSize: 18 }}>
                  <strong>
                    Please note that you will receive a confirmation email with
                    a summary of your plan and all the details you need to
                    access your program benefits.
                  </strong>
                </p>
                <p></p>
                <p style={{ color: "#222", fontSize: 18 }}>
                  <strong>What if I need help while I’m traveling?</strong>
                  <br />
                  For immediate assistance, call our Global Customer Response
                  Center at +1-619-717-8549 or text +1-954-727-1916. For
                  verification purposes, please have your above plan number on
                  hand.
                </p>
                <p style={{ color: "#222", fontSize: 18 }}>
                  <strong>
                    How do I access the Care & Assistance Plus portal?
                  </strong>
                  <br />
                  You will be sent a welcome email which will include a copy of
                  your CAP card, your login credentials for the on-line Care &
                  Assistance Plus portal, and the mobile app.
                </p>
                <p style={{ color: "#222", fontSize: 18 }}>
                  <strong>
                    What if I don’t get the Care & Assistance Plus welcome
                    email?
                  </strong>
                  <br />
                  First, check your junk and spam folders. If you don’t see it,
                  please call +1-866-340-8569 or email{" "}
                  <a href="mailto:capsupport@wwfocus.com">
                    capsupport@wwfocus.com
                  </a>{" "}
                  and let us know. For verification purposes, please have your
                  above plan number on hand.
                </p>
              </div>
            </div>
            {/* <div className="row">
              <div className="full-width" style={{color: 'black', fontSize: '20px'}}>
              <strong style={{marginBottom: '10px', display: 'block'}}>What to Do if You Need Help During Travel</strong>
                <p>For immediate assistance at any time during your trip, please call our Global Customer Response Center at <strong>+1-619-717-8549</strong> or text
                <strong> +1-954-727-1916</strong>. Please have your plan number and/or CAP™ Card readily available when you call, for verification purposes.</p>
                <strong style={{marginBottom: '10px', display: 'block'}}>How to Access the CAP™ Portal</strong>
                <p>You will receive a <i style={{color: '#2e6fad', fontStyle: 'italic'}}>Welcome To CAP™</i> email with login credentials for the online CAP™ Travel Assistance Portal. A copy of your CAP™ Card
                can be found attached to this email. You will also receive a <i style={{color: '#2e6fad', fontStyle: 'italic'}}>purchase confirmation</i> email that includes a summary of your plan.</p>
                <strong style={{marginBottom: '10px', display: 'block'}}>What to Do if You Cannot Find the CAP™ Email in Your Inbox, Junk or Spam Folders</strong>
                <p>Please call <strong>+1-866-340-8569</strong> or email <a style={{color: '#2e6fad', textDecoration: 'underline'}} href="mailto: capsupport@wwfocus.com"><strong>capsupport@wwfocus.com</strong></a> for assistance. Please have your plan number and/or CAP™ Card
                readily available when you call.</p>
                <strong style={{marginBottom: '10px', display: 'block'}}>Share Your Experience</strong>
                <p>We’d love to hear from you. Feel free to leave us a review on Google, recommend us on Facebook, and follow us online.</p>
                <a href={(this.props.match.params.orgId) ? `https://www.captravelassistance.com/partner/purchase/${this.props.match.params.orgId}` : `https://www.captravelassistance.com/cap/purchase`} style={{color: '#2e6fad', textDecoration: 'underline'}}>Continue Shopping</a>
              </div>
            </div> */}
          </div>
        </div>
      </section>
      </div>
    );
  }
}
ThankYouPage.propTypes = {
  b2cBaseUrl: PropTypes.string,
};
function mapStateToProps(state) {
  return {
    ...state.b2c,
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...b2cActions }, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(ThankYouPage);