import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SideBar from "../sidebar/sidebar.jsx";
import AdminRouter from "../../routes/admin-router";
import * as userActions from "../../redux/actions/user-actions";
import AdminHeader from "./admin-header/header.jsx";
import * as languageAction from "../../redux/actions/language-actions.js";
import * as themeAction from "../../redux/actions/theme-actions";
import * as adminActions from "../../redux/actions/admin-actions";
import { Layout, Modal, Form } from "antd";
import options from "../sidebar/options";
import * as UserService from "../../services/user-service";
import AuthTokenTimer from "../shared/components/auth-token-timer/auth-token-timer.jsx";
import FCMNotifier from "../../services/fcm-notifier";

const { Header, Sider, Content } = Layout;
class AdminDash extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bindNotifier: false,
      changePasswordModalVisible: false,
      updateEmergencyContactModalVisible: false
    };
    this.bindNotifier = false;
  }
  async componentDidMount() {
    //await this.props.getUserData();
    var currentTheme = JSON.parse(localStorage.getItem("user")),
      currentThemeName = "theme-" + currentTheme.themeId;
    document.body.setAttribute("class", currentThemeName);
    if (!this.props.userData || !this.props.userData.userID) {
      await this.props.getUserData();
    }
    let currentUser = UserService.getUser();
    if (currentUser.userID && currentUser.accountConfirmed === false) {
      localStorage.setItem("remember_me", false);
      if (
        this.props.history.location.pathname.indexOf("change-password") <= -1
      ) {
        this.props.history.push("/admin/my-profile/change-password");
      }
    }
    /* await this.props.hasB2B2CEmergencyContact(currentUser.userID).then(response => {
      if (response.data && typeof response.data.emergencyContactExists != "undefined" && response.data.emergencyContactExists != null) {
        if ((response.data.emergencyContactExists === false)) {
          this.setState({ updateEmergencyContactModalVisible: true });
        }
      }
    }); */
  }

  async componentDidUpdate() {
    if (
      this.props.adminConfig &&
      this.props.adminConfig.emergencyContacts &&
      Object.keys(this.props.adminConfig.emergencyContacts).length > 0
    ) {
    } else {
      this.bindNotifier = true;
      await this.props.loadEmergencyContacts();
    }
  }
  submitPasswordChangeModal = () => {
    this.setState({ changePasswordModalVisible: false });
    this.props.history.push("/admin/my-profile");
  };

  cancelPasswordChangeModal = () => {
    this.setState({ changePasswordModalVisible: false });
  };

  submitUpdateEmergencyContactModal = () => {

  }

  render() {
    const { url } = this.props.match;
    return (
      <div id="components-layout-demo-custom-trigger">
        {this.bindNotifier !== false ? <FCMNotifier /> : ''}
        <Modal
          title="Warning! Please change your password."
          visible={this.state.changePasswordModalVisible}
          onOk={this.submitPasswordChangeModal}
          onCancel={this.cancelPasswordChangeModal}
        >
          <p>For security reasons we suggest you to reset your password.</p>
        </Modal>
        <Modal
          title="Please update your emergency contact details"
          visible={this.state.updateEmergencyContactModalVisible}
          closable={false}
        >
          <p>To ensure your maximum security we need your emergency contact in case we are not reachable to you.</p>
          {/* <Form onSubmit={this.handleEmergencyContactSubmit} className="login-form">
            <Form.Item>
              {getFieldDecorator('emergencyFirstName', {
                rules: [{ required: true, message: 'Please input your username!' }],
              })(
                <Input
                  prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                  placeholder="Username"
                />,
              )}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator('emergencyLastName', {
                rules: [{ required: true, message: 'Please input your username!' }],
              })(
                <Input
                  prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                  placeholder="Username"
                />,
              )}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator('emergencyRelationship', {
                rules: [{ required: true, message: 'Please input your username!' }],
              })(
                <Input
                  prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                  placeholder="Username"
                />,
              )}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator('emergencyContactNo', {
                rules: [{ required: true, message: 'Please input your username!' }],
              })(
                <Input
                  prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                  placeholder="Username"
                />,
              )}
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" className="login-form-button">Update Details</Button>
            </Form.Item>
          </Form> */}
        </Modal>
        <Layout>
          <Sider
            trigger={null}
            collapsible
            collapsed={!this.props.sidebar}
            style={{
              overflow: "auto",
              height: "100vh",
              position: "sticky",
              top: 0,
              left: 0
            }}
            width={300}
          >
            <SideBar options={options.adminOptions} userType="admin" />
          </Sider>
          <Layout>
            <Header
              className="header"
              style={{
                background: "#fff",
                position: "sticky",
                zIndex: 100,
                top: 0,
                left: 0,
                width: "100%",
                padding: 0
              }}
              match={this.props.match}
              selectedtheme={this.props.theme}
              location={this.props.location}
              history={this.props.history}
            //userData={this.props.userData}
            >
              <AdminHeader />
            </Header>
            <Content
              style={{
                margin: "24px 16px",
                padding: 24,
                background: "#fff",
                maxWidth: "100%",
                position: "relative",
                minHeight: 280
              }}
            >
              <AdminRouter url={url} />
            </Content>
          </Layout>
        </Layout>
        {/*<AuthTokenTimer/>*/}
      </div>
    );
  }
}

AdminDash.propTypes = {
  location: PropTypes.object,
  theme: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object,
  sidebar: PropTypes.bool,
  userData: PropTypes.object,
  adminConfig: PropTypes.object,
  getUserData: PropTypes.func
};
function mapStateToProps(state) {
  return {
    ...state.user,
    ...state.language,
    ...state.sidebar,
    ...state.theme,
    adminConfig: state.adminConfig
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { ...userActions, ...languageAction, ...themeAction, ...adminActions },
    dispatch
  );
}
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    AdminDash
  )
);
