import React, { Component } from "react"
import { withRouter } from "react-router-dom"
import { Row, Col, Typography, Button, List } from "antd"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import IntlMessages from "../../../services/intlMesseges"
import * as UserService from "../../../services/user-service"
import * as paginationActions from "../../../redux/actions/pagination-actions"
const data = [
  {
    title: "FocusPoint RB Portal - eCommerce",
    description: "FocusPoint RB Portal - eCommerce",
    link: "/admin/power-bi/sample-report"
  }
];

const data1 = [
  {
    title: <IntlMessages id="admin.label.PowerBIReports.sample" />,
    description: "Sample Report",
    link: "/user/power-bi/sample-report",
  }
]
class PowerBIReports extends Component {
  constructor(props) {
    super(props)
    this.module = "reports"
  }
  viewreport(link) {
    this.props.history.push(link)
  }
  async componentDidMount() {
    this.props.clearPaginationExceptMe(this.module)
  }
  render() {
    let currentUser = UserService.getUser()
    let userRole = currentUser.role
    return (
      <div>
        <Row gutter={24}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Typography.Title level={4}>
              <IntlMessages id="admin.label.PowerBIReports" />
            </Typography.Title>
          </Col>
        </Row>
        <hr />
        <Row gutter={24}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            {userRole == "Administrator" || userRole == "CSR" ? (
              <List
                itemLayout="horizontal"
                dataSource={data}
                renderItem={(item) => (
                  <List.Item
                    actions={[
                      <Button type="primary" onClick={() => this.viewreport(item.link)}>
                        <IntlMessages id="admin.label.report.select" />
                      </Button>,
                    ]}
                  >
                    <List.Item.Meta
                      title={<b style={{ fontSize: "16px" }}>{item.title}</b>}
                      description={<span style={{ fontSize: "14px" }}>{item.description}</span>}
                    />
                  </List.Item>
                )}
              />
            ) : (
              <List
                itemLayout="horizontal"
                dataSource={data1}
                renderItem={(item) => (
                  <List.Item
                    actions={[
                      <Button type="primary" onClick={() => this.viewreport(item.link)}>
                        Select
                      </Button>,
                    ]}
                  >
                    <List.Item.Meta
                      title={<b style={{ fontSize: "16px" }}>{item.title}</b>}
                      description={<span style={{ fontSize: "14px" }}>{item.description}</span>}
                    />
                  </List.Item>
                )}
              />
            )}
          </Col>
        </Row>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    ...state.pagination,
  }
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...paginationActions }, dispatch)
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(PowerBIReports)
)
