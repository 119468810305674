import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Modal, Button } from "antd";
import * as UserService from "../../../services/user-service";
import API from "../../../redux/api/admin-api";
import Geocode from "react-geocode";

Geocode.setApiKey("AIzaSyAA_ayr9aMRSAPIugacjp_CL6S5ux_N4is");

let contactNo = "";
let userCity = "";
let userCountry = "";
let userState = "";
let userPostalCode = "";

class ShowAssistModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ModalText: "",
      ModalTitle: "",
      visible: false
    };
  }

  async componentDidMount() {
    let currentUser = UserService.getUser();

    navigator.geolocation.getCurrentPosition(pos => {
      if (pos.coords && pos.coords.latitude && pos.coords.longitude) {
        let lat = pos.coords.latitude;
        let lng = pos.coords.longitude;
        this.postCheckInEventlog(lat, lng);
      }
    });

    if (this.props.travelerInfo) {
      contactNo = this.props.travelerInfo.companyEmergencyContactNo
        ? this.props.travelerInfo.companyEmergencyContactNo
        : "No Contact Available";

      this.setState({
        visible: true,
        ModalText:
          "Assist email has been sent to the concerned person. You can call on the number: " +
          contactNo,
        ModalTitle: "Assist"
      });
    }
  }

  postCheckInEventlog(lat, lng) {
    Geocode.fromLatLng(lat, lng).then(resp => {
      if (resp.status == "OK") {
        resp.results.map(element => {
          element.address_components.map(element2 => {
            element2.types.map(element3 => {
              if (element3 === "country") {
                userCountry = element2.long_name;
              }
              if (element3 === "locality") {
                userCity = element2.long_name;
              }
              if (element3 === "administrative_area_level_1") {
                userState = element2.long_name;
              }
              if (element3 === "postal_code") {
                userPostalCode = element2.long_name;
              }
            });
          });
        });

        API.checkInEventlog({
          type: "assist",
          lat: lat,
          log: lng,
          city: userCity,
          state: userState,
          country: userCountry,
          zipcode: userPostalCode
        }).then(resp => {
          console.log(resp);
        });
      }
    });
  }

  handleOk = () => {
    this.setState({
      visible: false
    });
    this.props.onCancelAssistModal();
  };

  handleCancel = () => {
    this.setState({
      visible: false
    });
    this.props.onCancelAssistModal();
  };

  render() {
    return (
      <Modal
        width={400}
        title={this.state.ModalTitle}
        visible={this.state.visible}
        onOk={this.handleOk}
        onCancel={this.handleCancel}
        footer={[
          <Button key="submit" type="primary" onClick={this.handleOk}>
            Ok
          </Button>
        ]}
      >
        {this.state.ModalText}
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {};
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    ShowAssistModal
  )
);
