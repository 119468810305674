import React from "react";
import { Link } from "react-router";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import IntlMessages from "../../../../../services/intlMesseges";
import Config from "../../../../../../../config";
import { Map, GoogleApiWrapper, Marker, Polyline } from "google-maps-react";
import { Typography, Row, Col, Button, Spin, List, Card } from "antd";
import * as travellerActions from "../../../../../redux/actions/travellers-actions";
import moment from "moment";
import { DatePicker } from "antd";

const { RangePicker } = DatePicker;
const dateFormat = "MM/DD/YYYY HH:mm:ss";
const dataPickerDateFormat = "MM/DD/YYYY";
let iconName = "";

let listHeader = (
  <div>
    <List.Item>
      <Col className="gutter-row" span={3}>
        <strong>City</strong>
      </Col>
      <Col className="gutter-row" span={3}>
        <strong>State</strong>
      </Col>
      <Col className="gutter-row" span={3}>
        <strong>Country</strong>
      </Col>
      <Col className="gutter-row" span={4}>
        <strong>Date</strong>
      </Col>
      <Col className="gutter-row" span={4}>
        <strong>Duration</strong>
      </Col>
      <Col className="gutter-row" span={6}>
        <strong>Coordinates</strong>
      </Col>
    </List.Item>
  </div>
);

class EmployeeMap extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      initLoading: true,
      data: [],
      listData: [],
      pathCoordinates: [],
      currentUserId: 0,
      paginationToken: {},
      dataPickerDates: []
    };
  }

  async componentDidMount() {
    if (
      this.props.match &&
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.id &&
      this.props.match.params.id !== "new"
    ) {
      this.setState({
        currentUserId: this.props.match.params.id,
        loading: true
      });
      this.getTravelerLocationsData();
    }
  }

  onLoadMore = () => {
    this.setState({
      loading: true
    });
    this.props
      .getTravellerLocations({
        filters: { userId: this.props.match.params.id },
        pagination: {
          pageSize: 10,
          paginationToken: this.state.paginationToken
        },
        sorter: {}
      })
      .then(resp => {
        let data = this.state.data.concat(resp.data);
        let pathData = this._bindPathCoordinates(data);
        if (Object.keys(resp.paginationToken).length === 2) {
          this.setState({ initLoading: false });
        }
        this.setState({
          data: data,
          listData: data,
          pathCoordinates: pathData,
          loading: false,
          paginationToken: resp.paginationToken
        });
      })
      .catch(ex => {
        this.setState({ loading: false });
      });
  };

  dateChanged = dates => {
    this.setState({
      dataPickerDates: dates,
      loading: true
    });
    if (dates.length > 0) {
      let start = new Date(dates[0]);
      let end = new Date(dates[1]);

      let startDate = moment(
        new Date(
          start.getFullYear(),
          start.getMonth(),
          start.getDate(),
          0,
          0,
          0,
          0
        )
      ).format("X");

      let endDate = moment(
        new Date(
          end.getFullYear(),
          end.getMonth(),
          end.getDate(),
          23,
          59,
          59,
          0
        )
      ).format("X");

      this.getTravelerLocationsData(startDate, endDate);
    } else {
      this.getTravelerLocationsData();
    }
  };

  getTravelerLocationsData(startDate, endDate) {
    let filters = {};
    if (startDate && endDate) {
      filters = {
        userId: this.props.match.params.id,
        startDate: startDate,
        endDate: endDate
      };
    } else {
      filters = { userId: this.props.match.params.id };
    }

    this.props
      .getTravellerLocations({
        filters: filters,
        pagination: { current: 1, pageSize: 10 },
        sorter: {}
      })
      .then(resp => {
        let pathData = this._bindPathCoordinates(resp.data);
        if (Object.keys(resp.paginationToken).length === 2) {
          this.setState({ initLoading: false });
        }
        this.setState({
          data: resp.data,
          listData: resp.data,
          pathCoordinates: pathData,
          loading: false,
          paginationToken: resp.paginationToken
        });
      })
      .catch(ex => {
        this.setState({ loading: false });
      });
  }

  _bindPathCoordinates(data) {
    let tempArray = [];
    if (data && data.length > 0) {
      data.map(data => {
        tempArray.push({
          lat: data.latitude,
          lng: data.longitude
        });
      });
      return tempArray;
    }
  }

  fetchPlaces = ({ google }, map) => {
    if (this.state.listData && this.state.listData.length > 0) {
      const bounds = new window.google.maps.LatLngBounds();
      this.state.listData.map(data => {
        bounds.extend({ lat: data.latitude, lng: data.longitude });
      });
      map.panTo(bounds.getCenter());
    }
  };

  secondsToMS(seconds) {
    var d = Number(seconds);
    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);
    var s = Math.floor(d % 3600 % 60);

    var hDisplay = h > 0 ? h + (h == 1 ? " hour, " : " hours, ") : "";
    var mDisplay = m > 0 ? m + (m == 1 ? " minute, " : " minutes, ") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
    return hDisplay + mDisplay + sDisplay; 
  }

  render() {
    const loadMore = this.state.initLoading ? (
      <div
        style={{
          textAlign: "center",
          marginTop: 12,
          height: 32,
          lineHeight: "32px"
        }}
      >
        <Button onClick={this.onLoadMore}>load more</Button>
      </div>
    ) : null;

    return (
      <div>
        <Spin spinning={this.state.loading}>
          <Row gutter={24}>
            <Col xs={24} sm={24} md={16} lg={16} xl={16}>
              <Typography.Title level={4}>
                <IntlMessages id="admin.traveler.lastLocation" />
              </Typography.Title>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <RangePicker
                format={dataPickerDateFormat}
                value={this.state.dataPickerDates}
                onChange={this.dateChanged}
                ranges={{
                  Today: [moment(), moment()],
                  Yesterday: [
                    moment().add("-1", "days"),
                    moment().add("-1", "days")
                  ],
                  "This Week": [
                    moment().startOf("isoWeek"),
                    moment().endOf("isoWeek")
                  ],
                  "Last Week": [
                    moment()
                      .add("-1", "week")
                      .startOf("isoWeek"),
                    moment()
                      .add("-1", "week")
                      .endOf("isoWeek")
                  ],
                  "This Month": [
                    moment().startOf("month"),
                    moment().endOf("month")
                  ],
                  "Last Month": [
                    moment()
                      .add("-1", "month")
                      .startOf("month"),
                    moment()
                      .add("-1", "month")
                      .endOf("month")
                  ]
                }}
              />
            </Col>
          </Row>

          {this.state.listData && this.state.listData.length > 0 ? (
            <div>
              <Row gutter={24}>
                <Col xs={24} sm={24} md={18} lg={18} xl={18}>
                  {this.state.listData[0].city}, {this.state.listData[0].state},
                  {this.state.listData[0].country},{" "}
                  {this.state.listData[0].zipCode} {"( "}
                  <span
                    title={moment
                      .utc(this.state.listData[0].modifiedOnUtc)
                      .format(dateFormat)}
                  >
                    {moment.utc(this.state.listData[0].modifiedOnUtc).fromNow()}
                  </span>
                  {")"}
                </Col>
              </Row>
              <hr />
              <Row gutter={24}>
                <Col>
                  <div
                    style={{
                      position: "relative",
                      width: "100%",
                      height: "500px"
                    }}
                  >
                    <Map
                      google={this.props.google}
                      zoom={14}
                      onReady={this.fetchPlaces}
                      options={{
                        gestureHandling: "greedy"
                      }}
                    >
                      {this.state.listData &&
                        this.state.listData.length > 0 &&
                        this.state.listData.map((marker, i) => {
                          if (i == 0) {
                            iconName = "scheduled.png";
                          } else {
                            iconName = "traveler-route.png";
                          }
                          return (
                            <Marker
                              icon={{
                                url: Config.API1 + "/travel-icons/" + iconName
                              }}
                              title={marker.city}
                              position={{
                                lat: marker.latitude,
                                lng: marker.longitude
                              }}
                            />
                          );
                        })}
                      {this.state.pathCoordinates &&
                        this.state.pathCoordinates.length > 0 && (
                          <Polyline
                            path={this.state.pathCoordinates}
                            geodesic={true}
                            options={{
                              strokeColor: "#000000",
                              strokeOpacity: 0,
                              strokeWeight: 2,
                              icons: [
                                {
                                  icon: {
                                    path: "M 0,-1 0,1",
                                    strokeOpacity: 1,
                                    scale: 3
                                  },
                                  offset: "0",
                                  repeat: "20px"
                                }
                              ]
                            }}
                          />
                        )}
                    </Map>
                  </div>
                </Col>
              </Row>
              <Typography.Title level={4}>Past Locations</Typography.Title>
              <Row gutter={24}>
                {this.state.initLoading ? (
                  <div
                    style={{
                      textAlign: "center",
                      marginTop: 12,
                      height: 32,
                      lineHeight: "32px"
                    }}
                  >
                    <Button onClick={this.onLoadMore}>load more</Button>
                  </div>
                ) : (
                  ""
                )}
                <List
                  loading={this.state.loadnig}
                  header={listHeader}
                  itemLayout="horizontal"
                  loadMore={loadMore}
                  /* dataSource={this.state.listData} */
                  /* renderItem={item => (
                    <List.Item>
                      <Col className="gutter-row" span={3}>
                        {item.city ? item.city : "N/A"}
                      </Col>
                      <Col className="gutter-row" span={3}>
                        {item.state ? item.state : "N/A"}
                      </Col>
                      <Col className="gutter-row" span={3}>
                        {item.country ? item.country : "N/A"}
                      </Col>
                      <Col className="gutter-row" span={4}>
                        {moment
                          .utc(item.modifiedOnUtc)
                          .local()
                          .format(dateFormat)}
                      </Col>
                      <Col className="gutter-row" span={4}>
                        {this.secondsToMS(item.timeSpent)}
                      </Col>
                      <Col className="gutter-row" span={6}>
                        <a
                          href={
                            "https://www.google.com/maps/search/?api=1&query=" +
                            item.latitude +
                            "," +
                            item.longitude
                          }
                          target="_blank"
                        >
                          {item.latitude + "," + item.longitude}
                        </a>
                      </Col>
                    </List.Item>
                  )} */
                >
                  {this.state.listData.map((item, idx) => {
                    let locTimeSpent = item.timeSpent;
                    /* if(idx === 0) {
                      var seconds = (new Date().getTime() / 1000) - item.date;
                      locTimeSpent = this.secondsToMS(seconds - item.timeSpent);
                    } else {
                      var seconds = (this.state.listData[idx - 1].timeSpent);
                      locTimeSpent = this.secondsToMS(seconds);
                    } */
                    locTimeSpent = this.secondsToMS(item.timeSpent)
                    return (<List.Item>
                        <Col className="gutter-row" span={3}>
                          {item.city ? item.city : "N/A"}
                        </Col>
                        <Col className="gutter-row" span={3}>
                          {item.state ? item.state : "N/A"}
                        </Col>
                        <Col className="gutter-row" span={3}>
                          {item.country ? item.country : "N/A"}
                        </Col>
                        <Col className="gutter-row" span={4}>
                          {moment
                            .unix(item.date)
                            .local()
                            .format(dateFormat)}
                        </Col>
                        <Col className="gutter-row" span={4}>
                          {locTimeSpent}
                        </Col>
                        <Col className="gutter-row" span={6}>
                          <a
                            href={
                              "https://www.google.com/maps/search/?api=1&query=" +
                              item.latitude +
                              "," +
                              item.longitude
                            }
                            target="_blank"
                          >
                            {item.latitude + "," + item.longitude}
                          </a>
                        </Col>
                      </List.Item>);
                  })}
                </List>
              </Row>
            </div>
          ) : (
            "Location Not Found"
          )}
        </Spin>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...travellerActions }, dispatch);
}

export default withRouter(
  connect(null, mapDispatchToProps, null, { forwardRef: true })(
    GoogleApiWrapper({
      apiKey: "AIzaSyAA_ayr9aMRSAPIugacjp_CL6S5ux_N4is"
    })(EmployeeMap)
  )
);
