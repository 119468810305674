import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { ViewCell } from "../../../shared/components/helperCells";
import { bindActionCreators } from "redux";
import { CSVLink } from "react-csv";
import moment from "moment";
import {
  Table,
  Row,
  Col,
  Typography,
  Button,
  Input,
  Icon,
  Tooltip,
  Select,
  Tabs
} from "antd";
import IntlMessages from "../../../../services/intlMesseges";
import * as travellersReportActions from "../../../../redux/actions/travellers-report-actions";
import * as UserService from "../../../../services/user-service";
import PrintComponents from "react-print-components";
import PrintAbleComponent from "../printable-component.jsx";
import * as Permissions from "../../../../services/permissions";
import axios from "axios";
import travellerapi from "../../../../redux/api/travellers-report-api";
import * as UserServices from "../../../../services/user-service";
const { TabPane } = Tabs;
const { Option } = Select;

class EmployeesonthesameFlight extends Component {
  state = {
    travellerList: [],
    alltravellerList:[],
    pagination: {
      hideOnSinglePage: true,
      showTotal: (total, range) => {
        return (
          <span>
            <IntlMessages id="admin.text.showing" /> {range[0]}-{range[1]}{" "}
            <IntlMessages id="admin.text.of" /> {total}{" "}
            <IntlMessages id="admin.text.results" />
          </span>
        );
      },
      pageSize: 10,
      current: 1
    },
    paginationManual: {
      hideOnSinglePage: true,
      showTotal: (total, range) => {
        return (
          <span>
            <IntlMessages id="admin.text.showing" /> {range[0]}-{range[1]}{" "}
            <IntlMessages id="admin.text.of" /> {total}{" "}
            <IntlMessages id="admin.text.results" />
          </span>
        );
      },
      pageSize: 10,
      current: 1
    },
    filters: {},
    currentUserOrgId: 0,
    role:'',
    downloadSelectedValue:'current',
    csvSearchFilters: {},
    travellerListManual:[],
    tab:1
  };

  componentWillMount() {
    let currentUser = UserService.getUser();
    let userRole = currentUser.role;
    this.setState({role:userRole})
  }

  callback=(key) =>{
    this.setState({tab:key})
    if (key ==='1'){
      this.handleTableChangeManual({ current: 1, pageSize: 10 }, {}, {});
    
    }else {
      this.handleTableChange({ current: 1, pageSize: 10 }, {}, {});
    
    }

    let filters = this.state.filters;   
    if (filters['airVendor']) {
      if (filters['airVendor'].setSelectedKeys && typeof filters['airVendor'].setSelectedKeys === 'function') {
        filters['airVendor'].setSelectedKeys("");
        filters['airVendor'].confirm();
      }
    }
    if (filters['airVendorCode']) {
      if (filters['airVendorCode'].setSelectedKeys && typeof filters['airVendorCode'].setSelectedKeys === 'function') {
        filters['airVendorCode'].setSelectedKeys("");
        filters['airVendorCode'].confirm();
      }
    }
    if (filters['flightNumber']) {
      if (filters['flightNumber'].setSelectedKeys && typeof filters['flightNumber'].setSelectedKeys === 'function') {
        filters['flightNumber'].setSelectedKeys("");
        filters['flightNumber'].confirm();
      }
    }
    filters['airVendor'] = { val: "", clearf: "", filter: false };
    filters['airVendorCode'] = { val: "", clearf: "", filter: false };
    filters['flightNumber'] = { val: "", clearf: "", filter: false };

    
    
    this.setState({ filters: filters,  searchText: "" });
  }

  getHeaderKeys = () => {
    return [
      {
        title: <IntlMessages id="admin.userlisting.airvendorName" />,
        dataIndex: "airVendor",
        ...this.getColumnSearchProps("airVendor")
      },
      {
        title: <IntlMessages id="admin.userlisting.airvendorcode" />,
        dataIndex: "airVendorCode",
        ...this.getColumnSearchProps("airVendorCode")
      },
      {
        title: <IntlMessages id="admin.report.depaturelocation" />,
        dataIndex: "departureLoc",
      
      },
      {
        title: <IntlMessages id="admin.report.arrivallocation" />,
        dataIndex: "arrivalLoc",
        
      },
      {
        title: <IntlMessages id="admin.report.departs" />,
        dataIndex: "departureDate",
        //...this.getColumnSearchProps("departureDate")
      },
      {
        title: <IntlMessages id="admin.report.flightNumber" />,
        dataIndex: "flightNumber",
        ...this.getColumnSearchProps("flightNumber")
      },

      {
        title: <IntlMessages id="admin.report.numberOfTravalers" />,
        dataIndex: "numberOfTravalers"
      },
     
    ];
  };
  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => {
      return (
        <div style={{ padding: 8 }}>
          <Input
            ref={node => {
              this.searchInput = node;
            }}
            value={selectedKeys}
            onChange={e =>
              setSelectedKeys(e.target.value ? e.target.value.trimLeft() : "")
            }
            onPressEnter={() =>
              this.handleSearch(
                selectedKeys,
                confirm,
                dataIndex,
                clearFilters,
                setSelectedKeys
              )
            }
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Button
            type="primary"
            onClick={() =>
              this.handleSearch(
                selectedKeys,
                confirm,
                dataIndex,
                clearFilters,
                setSelectedKeys
              )
            }
            disabled={selectedKeys != "" && selectedKeys !== null ? false : true}
            icon="search"
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            <IntlMessages id="admin.search" />
          </Button>
          <Button
            disabled={selectedKeys != "" && selectedKeys !== null ? false : true}
            onClick={() => this.handleReset(clearFilters, dataIndex)}
            size="small"
            style={{ width: 90 }}
          >
            <IntlMessages id="admin.reset" />
          </Button>
        </div>
      );
    },
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    }
  });

  handleSearch = (
    selectedKeys,
    confirm,
    dataIndex,
    clearFilters,
    setSelectedKeys
  ) => {
    if (selectedKeys == "") {
      return false;
    }

    this.setState({ searchText: selectedKeys });
    let filters = this.state.filters;
    filters[dataIndex] = {
      val: selectedKeys,
      clearf: clearFilters,
      filter: true,
      setSelectedKeys: setSelectedKeys,
      confirm: confirm
    };
    this.setState({ filters: filters });
    confirm();
  };

  handleReset = (clearFilters, dataIndex) => {
    clearFilters();
    let filters = this.state.filters;
    if (filters[dataIndex]) {
      if(filters[dataIndex].setSelectedKeys && typeof filters[dataIndex].setSelectedKeys === 'function'){
        filters[dataIndex].setSelectedKeys("");
        filters[dataIndex].confirm();
      }
    }
    filters[dataIndex] = { val: "", clearf: "", filter: false };
    this.setState({ filters: filters });
    this.setState({ searchText: "" });
  };

  handleTableChange = (pagination, filters, sorter) => {
    this.setState({travellerList:[]})
    let currentUser = UserService.getUser();
    let orgId = currentUser.companyID;
    let userRole = currentUser.role;

    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({ loading: true ,csvSearchFilters: filters});
    this.props
      .getEmployeeOnSameFlight(orgId, userRole, {
        filters: filters,
        pagination: { page: pagination.current, pageSize: pagination.pageSize },
        sorter: sorter
      })
      .then(resp => {
        pager.total = resp.result.totalElement;
        

        this.setState({
          travellerList: resp.result.content,
          allactivetravellerList:resp.result.content,
            downloadSelectedValue:"current",
          loading: false,
          pagination: pager
        });
      
      })
      .catch(ex => {
        this.setState({ loading: false });
      });
  };


  handleTableChangeManual = (pagination, filters, sorter) => {
    this.setState({travellerListManual:[]})
    let currentUser = UserService.getUser();
    let orgId = currentUser.companyID;
    let userRole = currentUser.role;

    const pager = { ...this.state.paginationManual };
    pager.current = pagination.current;
    this.setState({ loading: true, csvSearchFilters: filters });
    this.props
      .getManualEmployeeOnSameFlight(orgId, userRole, {
        filters: filters,
        pagination: { page: pagination.current, pageSize: pagination.pageSize },
        sorter: sorter
      })
      .then(resp => {
        pager.total = resp.result.totalElement;
        this.setState({
          travellerListManual: resp.result.content,
          // allTravellerList:resp.result.content,
          loading: false,
          paginationManual: pager,
          downloadSelectedValue: "current"
        });

      })
      .catch(ex => {
        this.setState({ loading: false });
      });
  };
  onCsvDownloadSelect =(key) =>{
    this.setState({downloadSelectedValue:key})
    if(key==='all'){
      this.getCsvData()
    }
    // 
  }
  getCsvData = () => {
    let currentUser = UserService.getUser();
    let orgId = currentUser.companyID;
    let userRole = currentUser.role;

    this.props
      .getAllEmployeeOnSameFlight(orgId, userRole, {
        filters: this.state.csvSearchFilters,
        pagination: { page: 1, pageSize: 1000 },
        sorter: {},
      })
      .then(resp => {
        this.setState({
          alltravellerList: resp.result.content,
        });
      })
      .catch(ex => {
        
      });
  };
  viewTravelerList = (pnrId, email) => {

    let url;
    if(this.state.tab==1){
      url = "../traveler-manualitinerary-viewer/" + pnrId;
   }
   else{
     url = "../traveler-itinerary-viewer/" + pnrId + "/" + email;
   }
    window.open(url);
  };

  async componentDidMount() {
    //this.handleTableChange({ current: 1, pageSize: 10 }, {}, {});
    this.handleTableChangeManual({ current: 1, pageSize: 10 }, {}, {});
  }

  getManualCsv = () => {
    let token = UserServices.getToken();
    let _headers = {'Content-Type': 'multipart/form-data'};
    if (token) _headers['authorization'] = token ? 'Bearer ' + token : undefined;

    let currentUser = UserService.getUser();
    let orgId = currentUser.companyID;
    let userRole = currentUser.role;
    let filename = 'Manual Employee On Same Flight Report.csv';
    try{
      axios({
        url: travellerapi.getManualEmployeeOnSameFlightCsv(orgId,userRole, {filters: this.state.csvSearchFilters,pagination: { page: 1, pageSize: 10 },sorter: {}}),
        method: 'GET',
        responseType: 'blob',
        headers: _headers
      }).then((response) => {
         const url = window.URL.createObjectURL(new Blob([response.data]));
         const link = document.createElement('a');
         link.href = url;
         link.setAttribute('download', filename);
         document.body.appendChild(link);
         link.click();
      });
    }catch(e){
      console.log(e);
      
    }
 
  }

  getMagnatechorCombinedCsv = (type) => {
    let token = UserServices.getToken();
    let _headers = { 'Content-Type': 'multipart/form-data' };
    if (token) _headers['authorization'] = token ? 'Bearer ' + token : undefined;
    let currentUser = UserService.getUser();
    let orgId = currentUser.companyID;
    let userRole = currentUser.role;
    if (type == 'magnatech') {
      let filename = 'Automated Employee On Same Flight Report.csv';
      try {
        axios({
          url: travellerapi.getMagnatechEmployeeOnSameFlightCsv('magnatech', orgId, userRole, { filters: this.state.csvSearchFilters, pagination: { page: 1, pageSize: 10 }, sorter: {} }),
          method: 'GET',
          responseType: 'blob',
          headers: _headers
        }).then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', filename);
          document.body.appendChild(link);
          link.click();
        });
      } catch (e) {
        console.log(e);
      }
    }
    else {
      let filename = 'Combined Employee On Same Flight Report.csv';
      try {
        axios({
          url: travellerapi.getMagnatechEmployeeOnSameFlightCsv('combined', orgId, userRole, { filters: this.state.csvSearchFilters, pagination: { page: 1, pageSize: 10 }, sorter: {} }),
          method: 'GET',
          responseType: 'blob',
          headers: _headers
        }).then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', filename);
          document.body.appendChild(link);
          link.click();
        });
      } catch (e) {
        console.log(e);

      }
    }
  }
  render() {
    let _state = this.state;
    let _this = this;
    var traveller = this.state.alltravellerList
    traveller = traveller.map(row => ({...row, tripStartDate: moment(row.tripStartDate).format("MM/DD/YYYY"),tripEndDate: moment(row.tripEndDate).format("MM/DD/YYYY")}))  
    let headers
    this.state.role==='Administrator' || this.state.role ==='CSR' ?
    headers = [
      { label: 'Name', key: 'name' },
      { label: 'Divison', key: 'divisionName' },
      { label: 'Organization', key: 'parentOrganizationName' },
      { label: 'PNR', key: 'pnrNumber' },
      { label: 'Trip Start', key: 'tripStartDate' },
      { label: 'Trip End', key: 'tripEndDate' },
      { label: 'Country Name', key: 'country' },
      { label: 'Risk Level', key: 'riskLevel' },
    ]:headers = [
      { label: 'Name', key: 'name' },
      { label: 'Divison', key: 'divisionName' },
      { label: 'PNR', key: 'pnrNumber' },
      { label: 'Trip Start', key: 'tripStartDate' },
      { label: 'Trip End', key: 'tripEndDate' },
      { label: 'Trip End', key: 'tripEndDate' },
      { label: 'Country Name', key: 'country' },
      { label: 'Risk Level', key: 'riskLevel' },
    ]
    let filtertag = Object.keys(this.state.filters).map(function (key1) {
      let keyLabel = _this.getHeaderKeys().find(el => el.dataIndex === key1);

      if (keyLabel && keyLabel.title && keyLabel.title.props && keyLabel.title.props.id) {
        keyLabel = <IntlMessages id={keyLabel.title.props.id} />;
      } else {
        keyLabel = key1;
      }

      if (_state.filters[key1].filter) {
        return (
          <span>
            <Button
              key={key1}
              type="primary"
              onClick={() =>
                _this.handleReset(_state.filters[key1].clearf, key1)
              }
            >
              {keyLabel} : {_state.filters[key1].val}
              <Icon type="close" />
            </Button>
            &nbsp;
          </span>
        );
      }
    });
    return (
      <div>
        <Row gutter={24}>
          <Col xs={24} sm={24} md={8} lg={12} xl={12}>
            <Typography.Title level={4}>
              <IntlMessages id="admin.sidebar.submenu.EmployeesonthesameFlight" />
            </Typography.Title>
          </Col>
          <Col xs={24} sm={24} md={16} lg={12} xl={12} className="align-right">
            <ul>
              <li>
                {/* {" "}
                <Select value={this.state.downloadSelectedValue} style={{ width: 200 }} onChange={this.onCsvDownloadSelect} >
                    <Option value="current">Current Page</Option>
                    <Option value="all">All</Option>
              </Select>
              {" "} */}
                {/* <CSVLink filename={"riskLevelReport.csv"} headers={headers} data={traveller} > */}
                  <Button onClick={()=>this.getMagnatechorCombinedCsv('combined')}>
                  <Icon type="export" />
                    <span><IntlMessages id="admin.report.csvdownloadMagandManual" /></span>
                  </Button>
                {/* </CSVLink> */}

              </li>

              {/* <li>
              <PrintComponents trigger={<Button type="primary" >
                  <i
                    style={{ display: "inline-block", marginRight: "5px" }}
                    className="fas fa-print"
                  ></i>
                  <span><IntlMessages id="admin.report.print" /></span>
                </Button> }> <PrintAbleComponent headerKeys={this.getHeaderKeys()} data={this.state.travellerList}/> </PrintComponents>
              </li> */}
              <li>
                <Button
                  type="primary"
                  htmlType="button"
                  onClick={() => this.props.history.push("./")}
                >
                  <IntlMessages id="admin.userlisting.back" />
                </Button>
              </li>
            </ul>
          </Col>
        </Row>
        <hr />
        <div className="card-container">
            <Tabs type="card" onChange={this.callback}>
              <TabPane tab={<IntlMessages id="admin.report.manual" /> } key="1" >
              <Row gutter={24} style={{ marginBottom: "20px" }}>
            <Col xs={24}>{filtertag}</Col>
          </Row>
          <Row gutter={24}>
                  <Col xs={15} ></Col>
                  <Col xs={6} >

                    <Button style={{ marginBottom: '10px', marginLeft: '88px' }} onClick={this.getManualCsv}>
                      <Icon type="export" />
                      <span><IntlMessages id="admin.report.csvdownloadManual" /></span>
                    </Button>

                  </Col>
                  <Col xs={3}>
                    <PrintComponents trigger={<Button type="primary" style={{ marginLeft: '34px' }}>
                      <i
                        style={{ display: "inline-block", marginRight: '5px' }}
                        className="fas fa-print"
                      ></i>
                      <span><IntlMessages id="admin.report.print" /></span>
                    </Button>}> <PrintAbleComponent headerKeys={this.getHeaderKeys()} data={this.state.travellerListManual} /> </PrintComponents>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={24}
                    style={{ overflowX: "auto" }}
                  >
                    {this.state.travellerListManual ? (
                      <Table
                        bordered

                        columns={this.getHeaderKeys().filter(data =>(data.dataIndex !=="airVendor"))}
                        rowKey={record => record.pnrId}
                        dataSource={this.state.travellerListManual}
                        pagination={this.state.paginationManual}
                        loading={this.state.loading}
                        onChange={this.handleTableChangeManual}
                      />

                    ) : ''}
                  </Col>
                </Row>
              </TabPane>
              <TabPane tab={<IntlMessages id="admin.report.magnatech" /> } key="2">
              <Row gutter={24} style={{ marginBottom: "20px" }}>
            <Col xs={24}>{filtertag}</Col>
          </Row>
          <Row gutter={24}>
                  <Col xs={15} ></Col>
                  <Col xs={6} >

                    <Button style={{ marginBottom: '10px', marginLeft: '63px' }} onClick={()=>this.getMagnatechorCombinedCsv('magnatech')}>
                      <Icon type="export" />
                      <span><IntlMessages id="admin.report.csvdownloadMagnatech" /></span>
                    </Button>

                  </Col>
                  <Col xs={3}>
                    <PrintComponents trigger={<Button type="primary" style={{ marginLeft: '30px' }}>
                      <i
                        style={{ display: "inline-block", marginRight: '5px' }}
                        className="fas fa-print"
                      ></i>
                      <span><IntlMessages id="admin.report.print" /></span>
                    </Button>}> <PrintAbleComponent headerKeys={this.getHeaderKeys()} data={this.state.travellerList} /> </PrintComponents>
                  </Col>
                </Row>
                <Row gutter={24}>

                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={24}
                    style={{ overflowX: "auto" }}
                  >
                    {this.state.travellerList ? (
                      <Table
                        bordered

                        columns={this.getHeaderKeys()}
                        rowKey={record => record.pnrId}
                        dataSource={this.state.travellerList}
                        pagination={this.state.pagination}
                        loading={this.state.loading}
                        onChange={this.handleTableChange}
                      />

                    ) : ''}
                  </Col>
                </Row>
              </TabPane>
            </Tabs>
          </div>,
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...travellersReportActions }, dispatch);
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    EmployeesonthesameFlight
  )
);