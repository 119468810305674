import { Types } from "../constants/b2b2c-types";
import config from "../../../../config";
const initialState = {
  step: "0",
  planType: {},
  planTerm: {},
  planTermTemp: {},
  personalDetails: [],
  billingInformation: {},
  howHeardOptions: [
    "Internet Search ",
    "Social Media",
    "Online Advertisement",
    "Blog or News Article",
    "Referral",
    "Other",
  ],
  productInfo: [],
  countryTierInfo: [],
  couponInfo: {},
  isValidCoupon: false,
  orderData: {},
  orderErrorData: {},
  productPriceList: [],
  emailCheckResponse: {},
  travellerInfo: {},
  productDays: [],
  resellerOrganizationInfo: {},
  b2b2cBaseUrl: config.b2b2cBaseUrl,
  currency: "USD",
  currencyRate: 1,
  buyerEmail: "",
  agentName: "",
  travelerCount: 1,
};
export default function b2b2cReducer(state = initialState, action) {
  switch (action.type) {
    case Types.CLEAR_DATA:
      return {
        ...state,
        step: initialState.step,
        planType: initialState.planType,
        planTerm: initialState.planTerm,
        planTermTemp: initialState.planTermTemp,
        personalDetails: initialState.personalDetails,
        billingInformation: initialState.billingInformation,
        productPriceList: initialState.productPriceList,
      };
    case Types.UPDATE_TRAVELER_COUNT:
      return { ...state, travelerCount: action.payload.val };
    case Types.SAVE_STEP:
      return { ...state, step: action.payload };
    case Types.SAVE_PLAN_TYPE:
      return { ...state, planType: action.payload };
    case Types.SAVE_PLAN_TERM:
      return { ...state, planTerm: action.payload };
    case Types.SAVE_PLAN_TERM_TEMP:
      return { ...state, planTermTemp: action.payload };
    case Types.SAVE_PERSONAL_DETAIL:
      return { ...state, personalDetails: action.payload };
    case Types.SAVE_BILLING_DETAIL:
      return { ...state, billingInformation: action.payload };
    case Types.COUNTRY_TIER_INFO:
      return { ...state, countryTierInfo: action.payload };
    case Types.PRODUCT_INFO:
      return { ...state, productInfo: action.payload };
    case Types.SAVE_PROMO_INFO:
      return { ...state, couponInfo: action.payload };
    case Types.COUPON_APPLIED_STATUS:
      return { ...state, isValidCoupon: action.payload };
    case Types.RESPONSE_ORDER_DATA:
      return { ...state, orderData: action.payload };
    case Types.RESPONSE_ERROR_ORDER_DATA:
      return { ...state, orderErrorData: action.payload };
    case Types.PRODUCT_PRICE_LIST:
      return { ...state, productPriceList: action.payload };
    case Types.IS_VALID_EMAIL:
      return { ...state, emailCheckResponse: action.payload };
    case Types.FLUSH_EMAIL_CHECK_RESPONSE:
      return { ...state, emailCheckResponse: initialState.emailCheckResponse };
    case Types.SAVE_TRAVELLER_INFO:
      return { ...state, travellerInfo: action.payload };
    case Types.ALL_COUNTRIES:
      return { ...state, allCountries: action.payload };
    case Types.DAYS_LIST:
      return { ...state, productDays: action.payload };
    case Types.BUYER_EMAIL:
      return { ...state, buyerEmail: action.payload };
    case Types.AGENT_NAME:
      return { ...state, agentName: action.payload };

    case Types.UPDATE_CURRENCY:
      return {
        ...state,
        currencyRate: action.payload.currentCurrencyRate,
        currency: action.payload.currencyType,
      };
    case Types.FLUSH_ORDER_DATA:
      return {
        ...state,
        step: initialState.step,
        planType: initialState.planType,
        planTerm: initialState.planTerm,
        personalDetails: initialState.personalDetails,
        billingInformation: initialState.billingInformation,
        couponInfo: initialState.couponInfo,
        orderData: initialState.orderData,
        orderErrorData: initialState.orderErrorData,
        travellerInfo: initialState.travellerInfo,
        emailCheckResponse: initialState.emailCheckResponse,
      };
    case Types.SAVE_RESELLER_ORG_INFO:
      return { ...state, resellerOrganizationInfo: action.payload };
    default:
      return state;
  }
}
