import React, { Component } from "react";
import {
  Accordion,
} from "react-accessible-accordion";
//import PersonalDeviceMobile from "./personal-device-mobile";
import TravelConciergeMobile from "./travel-concierge-mobile";
import TravelMedicalMobile from "./travel-medical-mobile";
import TravelSecurityMobile from "./travel-security-mobile";
//import PersonalDevice from "./personal-device";
import TravelConcierge from "./travel-concierge";
import TravelMedical from "./travel-medical";
import TravelSecurity from "./travel-security";
import CapAdvantageAssistance from "./cap-advantage-assistance";
import CapAdvantageAssistanceMobile from "./cap-advantage-assistance-mobile";
export default class PlanType extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onChangeAccordion = (selected) => {
    if(selected.length > 0){
      document.getElementById("accordion__heading-"+selected).closest('table').scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
    }
  }
  render() {
    return (
      <section id="faq">
        <div className="wrap">
          <div className="mobile">
            <Accordion allowZeroExpanded={true} /* preExpanded={["cap-advantage-assistance-mobile"]} */ onChange={this.onChangeAccordion}>
              <table>
                <thead>
                  <tr>
                    <th scope="col">
                      <h3>Benefits</h3>
                    </th>
                    <th scope="col">Core CAP™</th>
                    <th scope="col">CAP+MED™</th>
                    {/* <th scope="col"> 
                      CAP™ <br />
                      Advantage
                    </th> */}
                  </tr>
                </thead>
                <tbody>
                  {/* <PersonalDeviceMobile /> */}
                  <TravelMedicalMobile />
                  <TravelSecurityMobile />
                  <TravelConciergeMobile />
                  <CapAdvantageAssistanceMobile />
                  {/* new row */}
                </tbody>
              </table>
            </Accordion>
          </div>
          <div className="desktop">
            <table>
              <thead>
                <tr>
                  <th scope="col">
                    <h3>Benefits</h3>
                  </th>
                  <th scope="col">Core CAP™</th>
                  <th scope="col">CAP+MED™</th>
                  {/* <th scope="col">
                    CAP™ <br />
                    Advantage
                  </th> */}
                </tr>
              </thead>
              <tbody>
                {/* <PersonalDevice /> */}
                <TravelMedical />
                <TravelSecurity />
                <TravelConcierge />
                <CapAdvantageAssistance />
                {/* new row */}
              </tbody>
            </table>
          </div>
        </div>
      </section>
    );
  }
}
