import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
//import UserMap from "../user-map/user-map";
import UserMap from "../user-map/user-map";
import TravelManagerDash from "../dashboard-travel-manager/index";
import * as Permission from "../../../services/permissions";
import * as paginationActions from "../../../redux/actions/pagination-actions";
class UserHome extends Component {
  componentDidMount() {
    this.props.clearPaginationExceptMe('dashboard');
    }

  render() {
    return (
      <div>
        {Permission.canAccess("traveler_dashboard") ? <UserMap {...this.props} /> : ""}
        {Permission.canAccess("travel_manager_dashboard") ? <TravelManagerDash {...this.props} /> : ""}
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...paginationActions }, dispatch);
}
export default withRouter(
  connect(null, mapDispatchToProps, null, { forwardRef: true })(
    UserHome
  )
);