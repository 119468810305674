import React, { Component } from "react";
import IntlMessages from "../../services/intlMesseges";
import { withRouter, Link } from "react-router-dom";
import LogoMain from "../shared/svg/svg/logo-main.jsx";
import { Menu } from "antd";

import OrgAPI from "../../redux/api/b2b2c-organization-api";

const { SubMenu } = Menu;

class SideBar extends Component {
  constructor(props) {
    super(props);
    this.state = { roleName: "",
    isGuardMeOrg:"false",
    currentId:""
   };
  }
 async componentDidMount() {
    const currentUser = JSON.parse(localStorage.getItem("user"));
    if (currentUser.role) this.setState({ roleName: currentUser.role });
     
    this.setState({currentId : currentUser.parentOrgId})
    await OrgAPI.CheckIsGuardMeOrg(currentUser.parentOrgId).then(response =>{
     
      this.setState({isGuardMeOrg : response}) 
      
  });
   
  }

  getMenuItem = ({ singleOption }) => {
    const { key, label, leftIcon, children ,orgId } = singleOption;
    const hideMenu =  singleOption.roles.includes("NotForGuard") && this.state.isGuardMeOrg ? true : false;
    if (children) {
      return (
        singleOption.roles &&
        singleOption.roles.indexOf(this.state.roleName) !== -1 && (
          <SubMenu
            key={key}
            title={
              <span className="isoMenuHolder">
                {leftIcon}
                <span className="nav-text">
                  <IntlMessages id={label} />
                </span>
              </span>
            }
          >
            {children.map(child => {
              return (
                child.roles && 
                child.roles.indexOf(this.state.roleName) !== -1 && (
                  <Menu.Item key={child.key}>
                    <Link to={child.key}>
                      {child.leftIcon}
                      <IntlMessages id={child.label} />
                      {child.alertsCount}
                    </Link>
                  </Menu.Item>
                )
              );
            })}
          </SubMenu>
        )
      );
    }
    return (
      singleOption.roles &&
      singleOption.roles.indexOf(this.state.roleName) !== -1 && !hideMenu &&  (
        <Menu.Item key={key}>
          <Link to={`${key}`+ (this.state.isGuardMeOrg && orgId == "true" ? this.state.currentId +"/list/" : "")}>
            <span className="isoMenuHolder">
              {leftIcon}
              <span className="nav-text">
                <IntlMessages id={label} />
              </span>
            </span>
          </Link>
        </Menu.Item>
      )
    );
  };

  render() {
    return (
      <div className="menu">
        <div className="logo">
          {this.props.logo_url !== "" && this.props.userType === "user"
            ? 
              <img src={this.props.logo_url} title="FocusPoint" style={{maxWidth: '100%', padding: '10px'}}/>
            : 
              <LogoMain />
          }
        </div>
        <Menu
          theme="dark"
          mode="inline"
          selectedKeys={[this.props.history.location.pathname]}
        >
          {this.props.options &&
            this.props.options.map(singleOption =>
              this.getMenuItem({ singleOption })
            )}
        </Menu>
      </div>
    );
  }
}

export default withRouter(SideBar);
