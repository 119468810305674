import { useEffect } from "react";
import PropTypes from "prop-types";
import { MarkerClusterer } from "./MarkerClusterer";
import moment from "moment";
import config from "../../../../../config";

const dateFormat = "MM/DD/YYYY";
const evtNames = [
  "click",
  "dblclick",
  "dragend",
  "mousedown",
  "mouseout",
  "mouseover",
  "mouseup",
  "recenter"
];

const MarkerCluster = props => {
  const { map, google, markers, imagePath, filterArray } = props;

  // markers.sort((a, b) => {
  //   let x = a.type.toLocaleLowerCase();
  //   let y = b.type.toLocaleLowerCase();
  //   if (x < y) {
  //     return -1;
  //   }
  //   if (x > y) {
  //     return 1;
  //   }
  //   return 0;
  // });

  const handleEvent = ({ event, marker, entry, map, infowindow }) => {
    // console.log(marker);
    //  console.log("event >>>>>" + event);
    if (event === "click") {
      infowindow.setContent(
        "<div class='markerAlretWindow' style = 'width:400px;min-height:100px; line-height: 20px;'><ul><li><strong>Alert: </strong>" +
          marker.title +
          "</li><li> <strong>Date:</strong> " +
          moment(new Date(marker.startDate * 1000).toUTCString()).format(
            dateFormat
          ) +
          "</li><li><strong>Location:</strong> " +
          marker.location_explanation +
          "</li></ul</div>"
      );
      infowindow.open(map, entry);
      props.onAlertClick(infowindow);
    }

    if (props[event]) {
      props[event]({
        props: props,
        marker: marker,
        event: event,
        entry: entry
      });
    }
  };

  // This hook works like ComponentWillMount
  // The  hook isn't really needed, this whole thing worked without it,
  // I added the hook so that I could implement a cleanup function
  useEffect(() => {
    if (map && markers) {
      let bounds = new window.google.maps.LatLngBounds();
      var infowindow = new google.maps.InfoWindow({
        content: ""
      });
      const mapMarkers = markers.map((marker, index) => {
        const entry = new google.maps.Marker({
          position: {
            lat: parseFloat(marker.geo.lat),
            lng: parseFloat(marker.geo.lng)
          },
          icon: {
            url: config.API1 + "/travel-icons/" + marker.icon,
            Size: new google.maps.Size(32, 33)
          },
          map: map
          // name: marker.title,
          //  content: marker.text
        });

        const position = new google.maps.LatLng(marker.geo.lat, marker.geo.lng);
        bounds.extend(position);

        evtNames.forEach(e => {
          entry.addListener(e, () =>
            handleEvent({
              event: e,
              marker: marker,
              entry: entry,
              map: map,
              infowindow: infowindow
            })
          );
        });
        return entry;
      });

      const clusterer = new MarkerClusterer(map, mapMarkers, {
        imagePath: imagePath,
        maxZoom: 15,
        zoomOnClick: true
      });
      map.panTo(bounds.getCenter());
      // Cleanup function. Note, this is only returned if we create the markers
      return () => {
        //console.log('Cleaning up markers')
        clusterer.clearMarkers();
      };
    }
  }, [map, google, markers, filterArray]);

  // Do we need to render anything??
  return null;
};

MarkerCluster.propTypes = {
  map: PropTypes.object,
  google: PropTypes.object,
  markers: PropTypes.arrayOf(
    PropTypes.shape({
      position: PropTypes.shape({
        lat: PropTypes.number.isRequired,
        lng: PropTypes.number.isRequired
      }).isRequired,
      name: PropTypes.string.isRequired
    })
  )
};

export default MarkerCluster;
