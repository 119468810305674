import React, { Component } from "react";
import { Result, Button, Col, Row } from "antd";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import IntlMessages from "../../../../services/intlMesseges";
class OrganizationSucess extends Component {
  render() {
    return (
      <div>
        <Result
          status="success"
          title="Successfully Added New Organization!"
          // subTitle={<IntlMessages id="admin.success.membership" />}
          extra={this.props.membershipNumber}
        />
        <div className="wrap" style={{ maxWidth: "500px" }}>
          <Row gutter={24}>
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={12}
              style={{ marginTop: "20px" }}
            >
              <Button
                type="primary"
                onClick={() => this.props.history.push('../'+this.props.organizationId+'/divisions/add/')}
                style={{ width: "200px" }}
              >
                <IntlMessages id="admin.division.addnewdivision" />
              </Button>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={12}
              style={{ marginTop: "20px" }}
            >
              <Button onClick={() => this.props.history.push('../edit/'+this.props.organizationId+'/employees/new/')} style={{ width: "200px" }}><IntlMessages id="admin.employee.addnewemployees" /></Button>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}
export default withRouter(
  connect(null, null, null, { forwardRef: true })(OrganizationSucess)
);
