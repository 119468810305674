import React, { Component } from "react";
import ActivityLog from "../admin-dash/audit-logs/audit-logs";

class ResellerAuditLogs extends Component {
  state = {
    data: []
  }
  render() {
    console.log("in")
      return (
        <ActivityLog for="reseller"/>
      )
  }
}
export default ResellerAuditLogs;