import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import IntlMessages from "../../../../services/intlMesseges";
import {
  Table,
  Icon,
  Input,
  Button,
  Form,
  Row,
  Col,
  Typography,
  Modal,
  Radio,
  DatePicker,
  Spin,
} from "antd";
import { ViewCell } from "../../../shared/components/helperCells";
import * as orderActions from "../../../../redux/actions/order-actions";
import * as UserServices from "../../../../services/user-service";
import Config from "../../../../../../config";
import axios from "axios";
import moment from "moment";
import { notifyUser } from "../../../../services/notification-service";
import OrgAPI from "../../../../redux/api/b2b2c-organization-api";

const dateFormathh = "MM/DD/YYYY HH:mm:ss";
const dateFormat = "MM/DD/YYYY";
const dateDownloadFormat = "YYYY-MM-DD";
const { RangePicker } = DatePicker;

let now = new Date();
let start = moment(new Date(now.getFullYear(), now.getMonth(), 1, 0, 0, 0, 0));
let end = moment(
  new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59, 0)
);

class B2B2COrganizationOrderHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ordersLoaded: false,
      loading: false,
      isLoading: true,
      currentPlanName: null,
      data: [],
      isGuardMeOrg: "",
      pagination: {
        pageSize: 10,
        current: 1,
        hideOnSinglePage: true,
        showTotal: (total, range) => {
          return (
            <span>
              <IntlMessages id="admin.text.showing" /> {range[0]}-{range[1]}{" "}
              <IntlMessages id="admin.text.of" /> {total}{" "}
              <IntlMessages id="admin.text.results" />
            </span>
          );
        },
      },
      filters: {},
      exportToCSVModal: {
        visible: false,
        exportType: "all",
        dateRange: [start, end],
        loading: false,
      },
      filterDateRange: [null, null],
      dateRangeFilterApplied: false,
      totalCommission: 0,
      totalCommissionCAD: 0,
    };
  }

  formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
  });

  getHeaderKeys = () => {
    return [
      {
        title: <IntlMessages id="admin.manageOrders.ProductName" />,
        dataIndex: "productName",
        ...this.getColumnSearchProps("productName"),
        //width: '15%'
      },
      {
        title: <IntlMessages id="admin.manageOrders.membershipNumber" />,
        dataIndex: "membershipNumber",
        ...this.getColumnSearchProps("membershipNumber"),
      },
      {
        title: <IntlMessages id="admin.manageOrders.orderNumber" />,
        dataIndex: "orderNumber",
        ...this.getColumnSearchProps("orderNumber"),
        //width: '15%'
      },
      {
        hidden: this.state.isGuardMeOrg,
        title: <IntlMessages id="admin.manageOrders.orderStatus" />,
        dataIndex: "orderStatus",
        ...this.getColumnSearchProps("orderStatus"),
        //width: '15%'
      },
      {
        title: <IntlMessages id="admin.agent.tracking.number" />,
        dataIndex: "agentTrackingNumber",
        hidden: this.state.isGuardMeOrg,
        //...this.getColumnSearchProps("agentTrackingNumber"),
        render: (_text, record) =>
          record.agentTrackingNumber ? record.agentTrackingNumber : "-",
      },
      {
        hidden: this.state.isGuardMeOrg,
        title: <IntlMessages id="admin.manageOrders.discountedAmount" />,
        //width: '10%',
        render: (_text, record) => (
          <span>
            {" "}
            {this.formatter.format(record.totalOrderAmount)} {record.currency}{" "}
          </span>
        ),
      },
      {
        hidden: true,
        title: <IntlMessages id="admin.travelmanager.purchasedate" />,
        //width: '10%',
        render: (_text, record) => (
          <span title={moment(record.insertDate).format(dateFormathh)}>
            {moment(record.insertDate).format(dateFormat)}
          </span>
        ),
      },
      {
        hidden: this.state.isGuardMeOrg,
        title: <IntlMessages id="admin.manageOrders.commissionAmount" />,
        //width: '10%',
        render: (_text, record) => (
          <span>
            {" "}
            {this.formatter.format(
              record.commissionAmount !== null ? record.commissionAmount : 0
            )}{" "}
            {record.currency}
          </span>
        ),
      },
      {
        title: <IntlMessages id="admin.tablelist.actions" />,
        rowKey: "action",
        width: 100,
        render: (_text, record) => (
          <span>
            <ViewCell
              onViewCell={() =>
                this.props.history.push("view/" + record.orderId)
              }
            />
          </span>
        ),
      },
    ].filter((item) => !item.hidden);
  };

  async componentDidMount() {
    //if (this.state.ordersLoaded === false) {
    this.setState({
      ordersLoaded: true,
    });
    this.handleTableChange({ page: 1, pageSize: 10 }, {}, {});
    //}

    let orgId = this.props.match.params.id
      ? this.props.match.params.id
      : this.props.match.params.divid
      ? this.props.match.params.divid
      : this.props.match.params.orgid;
    //  console.log(orgId)

    await OrgAPI.getOrganization(orgId).then((response) => {
      this.setState({ isGuardMeOrg: response.data.isGuardMeOrg });
    });
  }

  loadOrderHistory = (orgId, pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current || 1;
    this.setState({ loading: true });
    let args = {
      orgId: orgId,
      filters: filters,
      pagination: {
        page: pagination.current || 1,
        pageSize: pagination.pageSize || 10,
      },
      sorter: sorter,
    };
    if (this.state.filterDateRange[0] !== null) {
      args.startDate = moment(this.state.filterDateRange[0]).format(
        dateDownloadFormat
      );
    }
    if (this.state.filterDateRange[1] !== null) {
      args.endDate = moment(this.state.filterDateRange[1]).format(
        dateDownloadFormat
      );
    }

    this.props
      .getResellerOrders(args)
      .then((resp) => {
        pager.total = resp.paging.totalRecords;
        this.setState({
          loading: false,
          data: resp.data,
          pagination: pager,
          totalCommission:
            resp.data.length > 0 ? resp.data[0].totalCommission : 0,
          totalCommissionCAD:
            resp.data.length > 0 ? resp.data[0].totalCommissionCAD : 0,
        });
        if (resp.data[0].membershipNumber) {
          this.setState({ currentPlanName: resp.data[0].membershipNumber });
        }
      })
      .catch((ex) => {
        this.setState({ loading: false });
      });
  };

  handleTableChange = (pagination, filters, sorter) => {
    let orgId = this.props.match.params.id
      ? this.props.match.params.id
      : this.props.match.params.divid
      ? this.props.match.params.divid
      : this.props.match.params.orgid;
    this.loadOrderHistory(orgId, pagination, filters, sorter);
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <div style={{ padding: 8 }}>
          <Input
            ref={(node) => {
              this.searchInput = node;
            }}
            value={selectedKeys}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? e.target.value.trimLeft() : "")
            }
            onPressEnter={() =>
              this.handleSearch(
                selectedKeys,
                confirm,
                dataIndex,
                clearFilters,
                setSelectedKeys
              )
            }
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Button
            type="primary"
            onClick={() =>
              this.handleSearch(
                selectedKeys,
                confirm,
                dataIndex,
                clearFilters,
                setSelectedKeys
              )
            }
            disabled={
              selectedKeys != "" && selectedKeys !== null ? false : true
            }
            icon="search"
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            <IntlMessages id="admin.search" />
          </Button>
          <Button
            disabled={
              selectedKeys != "" && selectedKeys !== null ? false : true
            }
            onClick={() => this.handleReset(clearFilters, dataIndex)}
            size="small"
            style={{ width: 90 }}
          >
            <IntlMessages id="admin.reset" />
          </Button>
        </div>
      );
    },
    filterIcon: (filtered) => (
      <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
  });

  handleSearch = (
    selectedKeys,
    confirm,
    dataIndex,
    clearFilters,
    setSelectedKeys
  ) => {
    if (selectedKeys === "") {
      return false;
    }

    this.setState({ searchText: selectedKeys });
    let filters = this.state.filters;
    filters[dataIndex] = {
      val: selectedKeys,
      clearf: clearFilters,
      filter: true,
      setSelectedKeys: setSelectedKeys,
      confirm: confirm,
    };
    this.setState({ filters: filters });
    confirm();
  };

  handleReset = (clearFilters, dataIndex) => {
    clearFilters();
    let filters = this.state.filters;
    if (filters[dataIndex]) {
      if (
        filters[dataIndex].setSelectedKeys &&
        typeof filters[dataIndex].setSelectedKeys === "function"
      ) {
        filters[dataIndex].setSelectedKeys("");
        filters[dataIndex].confirm();
      }
    }
    filters[dataIndex] = { val: "", clearf: "", filter: false };
    this.setState({ filters: filters });
    this.setState({ searchText: "" });
  };

  onChangeOrganizationTree = (selected) => {
    this.loadOrderHistory(selected, { page: 1, pageSize: 10 }, {}, {});
    /* if(selected == this.props.match.params.orgid){
      this.props.history.push("../../../../"+selected+"/order-history/");
    } else {
      if(this.props.match.params.divid > 0){
        this.props.history.push("../../"+selected+"/order-history/");
      } else {
        this.props.history.push('../../' +this.props.match.params.orgid + '/divisions/'+selected+'/order-history/');
      }
    } */
  };

  showExportToCSVModal = () => {
    let exportToCSVModal = this.state.exportToCSVModal;
    exportToCSVModal.visible = true;
    this.setState({ exportToCSVModal });
  };

  resetExportToCSVModal = () => {
    let exportToCSVModal = this.state.exportToCSVModal;
    exportToCSVModal.visible = false;
    exportToCSVModal.exportType = "all";
    exportToCSVModal.dateRange = [start, end];
    exportToCSVModal.loading = false;
    this.setState({ exportToCSVModal });
  };

  onChangeExportToCSVType = (e) => {
    let exportToCSVModal = this.state.exportToCSVModal;
    exportToCSVModal.exportType = e.target.value;
    this.setState({ exportToCSVModal });
  };

  submitExportToCSV = () => {
    let exportToCSVModal = this.state.exportToCSVModal;
    exportToCSVModal.loading = true;
    this.setState({ exportToCSVModal });
    let self = this;
    let args = {
      startDate: null,
      endDate: null,
    };
    if (this.state.exportToCSVModal.exportType === "all") {
      args.startDate = moment("1971-01-01").format(dateDownloadFormat);
      args.endDate = moment(end).format(dateDownloadFormat);
    } else {
      args.startDate = moment(this.state.exportToCSVModal.dateRange[0]).format(
        dateDownloadFormat
      );
      args.endDate = moment(this.state.exportToCSVModal.dateRange[1]).format(
        dateDownloadFormat
      );
    }
    let orgId = this.props.match.params.id
      ? this.props.match.params.id
      : this.props.match.params.divid
      ? this.props.match.params.divid
      : this.props.match.params.orgid;

    let token = UserServices.getToken();
    let _headers = { "Content-Type": "multipart/form-data" };
    if (token)
      _headers["authorization"] = token ? "Bearer " + token : undefined;
    let filename =
      "Orders export (" + args.startDate + " to " + args.endDate + ").csv";
    if (this.state.exportToCSVModal.exportType === "all") {
      filename = "Orders export (All).csv";
    }
    if (
      this.props.history.location.state &&
      this.props.history.location.state.orgName &&
      this.props.history.location.state.orgName !== null
    ) {
      filename = this.props.history.location.state.orgName + " - " + filename;
    }
    axios({
      url: `${Config.API1}/api/ExportOrders/${orgId}/${encodeURIComponent(
        args.startDate
      )}/${encodeURIComponent(args.endDate)}`,
      method: "GET",
      responseType: "blob",
      headers: _headers,
    })
      .then((response) => {
        var reader = new FileReader();
        reader.onload = function() {
          if (
            reader.result.indexOf("status") > -1 &&
            reader.result.indexOf("message") > -1
          ) {
            let result = JSON.parse(reader.result);
            if (
              typeof result === "object" &&
              result.data &&
              result.data.status &&
              result.data.message
            ) {
              if (result.data.status === 200) {
                notifyUser(result.data.message, "success");
              } else {
                notifyUser(result.data.message, "error");
              }
            } else {
              notifyUser(
                "Unknown error! Please refresh the page and try again.",
                "error"
              );
            }
            self.resetExportToCSVModal();
          } else {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", filename);
            document.body.appendChild(link);
            link.click();
            self.resetExportToCSVModal();
          }
        };
        reader.readAsText(response.data);
      })
      .catch((err) => {
        console.log("Error: ", err);
        self.resetExportToCSVModal();
      });
  };

  onDateRangeChanged = (dates) => {
    let exportToCSVModal = this.state.exportToCSVModal;
    if (dates.length > 0) {
      exportToCSVModal.dateRange = dates;
    } else {
      exportToCSVModal.dateRange = [start, end];
    }
    this.setState({ exportToCSVModal });
  };

  onFilterDateRangeChanged = (dates) => {
    if (dates.length > 0) {
      this.setState({ filterDateRange: dates });
    } else {
      this.setState({ filterDateRange: [] });
    }
  };

  applyDateRangeFilter = () => {
    if (
      this.state.filterDateRange[0] !== null &&
      this.state.filterDateRange[1] !== null
    ) {
      this.setState(
        {
          dateRangeFilterApplied: true,
        },
        () => {
          this.handleTableChange(
            this.state.pagination,
            this.state.filters,
            this.state.sorter
          );
        }
      );
    }
  };

  resetDateRangeFilter = () => {
    this.setState(
      {
        dateRangeFilterApplied: false,
        filterDateRange: [null, null],
      },
      () => {
        this.handleTableChange(
          this.state.pagination,
          this.state.filters,
          this.state.sorter
        );
      }
    );
  };

  render() {
    let _state = this.state;
    let _this = this;
    let filtertag = Object.keys(this.state.filters).map(function(key1) {
      let keyLabel = _this.getHeaderKeys().find((el) => el.dataIndex === key1);
      if (keyLabel.title.props && keyLabel.title.props.id) {
        keyLabel = <IntlMessages id={keyLabel.title.props.id} />;
      } else {
        keyLabel = key1;
      }
      if (_state.filters[key1].filter) {
        return (
          <span style={{ marginBottom: "7px", display: "inline-block" }}>
            <Button
              key={key1}
              type="primary"
              onClick={() =>
                _this.handleReset(_state.filters[key1].clearf, key1)
              }
            >
              {keyLabel} : {_state.filters[key1].val}
              <Icon type="close" />
            </Button>
            &nbsp;
          </span>
        );
      }
    });

    return (
      <div>
        <Modal
          title={<IntlMessages id="admin.export.orders.to.csv" />}
          visible={this.state.exportToCSVModal.visible}
          onCancel={this.resetExportToCSVModal}
          footer={[
            <Button type="button" onClick={this.submitExportToCSV}>
              <IntlMessages id="admin.export" />
            </Button>,
          ]}
        >
          <Spin spinning={this.state.exportToCSVModal.loading}>
            <Form>
              <Row gutter={24}>
                <Col xs={24}>
                  <Form.Item
                    label={
                      <strong>
                        <IntlMessages id="admin.export.type" />
                      </strong>
                    }
                  >
                    <Radio.Group
                      value={this.state.exportToCSVModal.exportType}
                      onChange={this.onChangeExportToCSVType}
                    >
                      <Radio value={"all"}>
                        <IntlMessages id="admin.all" />
                      </Radio>
                      <Radio value={"dateRange"}>
                        <IntlMessages id="admin.date.range" />
                      </Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
              <Row
                gutter={24}
                style={{
                  display:
                    this.state.exportToCSVModal.exportType === "dateRange"
                      ? "block"
                      : "none",
                }}
              >
                <Col xs={24}>
                  <Form.Item
                    label={<IntlMessages id="admin.select.date.range" />}
                  >
                    <RangePicker
                      format={dateFormat}
                      value={this.state.exportToCSVModal.dateRange}
                      onChange={this.onDateRangeChanged}
                      ranges={{
                        Today: [moment(), moment()],
                        Yesterday: [
                          moment().add("-1", "days"),
                          moment().add("-1", "days"),
                        ],
                        "This Week": [
                          moment().startOf("isoWeek"),
                          moment().endOf("isoWeek"),
                        ],
                        "Last Week": [
                          moment()
                            .add("-1", "week")
                            .startOf("isoWeek"),
                          moment()
                            .add("-1", "week")
                            .endOf("isoWeek"),
                        ],
                        "This Month": [
                          moment().startOf("month"),
                          moment().endOf("month"),
                        ],
                        "Last Month": [
                          moment()
                            .add("-1", "month")
                            .startOf("month"),
                          moment()
                            .add("-1", "month")
                            .endOf("month"),
                        ],
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Spin>
        </Modal>
        <Row gutter={24}>
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography.Title level={4}>
              <IntlMessages id="admin.organizations.history" />
              {this.props.history.location.state &&
              this.props.history.location.state.orgName &&
              this.props.history.location.state.orgName !== null
                ? " (" + this.props.history.location.state.orgName + ")"
                : this.state.currentPlanName !== null
                ? " (" + this.state.currentPlanName + ")"
                : ""}
            </Typography.Title>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <Button
              type="primary"
              className="right-fl"
              htmlType="button"
              onClick={() => this.props.history.push("../../")}
            >
              <IntlMessages id="admin.userlisting.back" />
            </Button>
            <Button
              type="info"
              className="right-fl"
              htmlType="button"
              onClick={this.showExportToCSVModal}
              style={{ marginRight: 10 }}
            >
              <IntlMessages id="admin.export.to.csv" />
            </Button>
          </Col>
        </Row>
        <hr />
        <Row gutter={24}>
          <Col xs={24} sm={16}>
            <label style={{ float: "left", paddingRight: 5 }}>
              <IntlMessages id="admin.filter.purchase.range" />:{" "}
            </label>
            <RangePicker
              dropdownClassName="rangeOrder"
              style={{ float: "left", maxWidth: 450 }}
              format={dateFormat}
              value={this.state.filterDateRange}
              onChange={this.onFilterDateRangeChanged}
              ranges={{
                Today: [moment(), moment()],
                Yesterday: [
                  moment().add("-1", "days"),
                  moment().add("-1", "days"),
                ],
                "This Week": [
                  moment().startOf("isoWeek"),
                  moment().endOf("isoWeek"),
                ],
                "Last Week": [
                  moment()
                    .add("-1", "week")
                    .startOf("isoWeek"),
                  moment()
                    .add("-1", "week")
                    .endOf("isoWeek"),
                ],
                "This Month": [
                  moment().startOf("month"),
                  moment().endOf("month"),
                ],
                "Last Month": [
                  moment()
                    .add("-1", "month")
                    .startOf("month"),
                  moment()
                    .add("-1", "month")
                    .endOf("month"),
                ],
              }}
            />
            <Button
              type="primary"
              htmlType="button"
              onClick={this.applyDateRangeFilter}
              style={{ marginLeft: 10 }}
            >
              <IntlMessages id="admin.apply.filters" />
            </Button>
            {this.state.dateRangeFilterApplied !== false ? (
              <Button
                type="primary"
                htmlType="button"
                onClick={this.resetDateRangeFilter}
                style={{ marginLeft: 10 }}
              >
                <IntlMessages id="admin.reset" />
              </Button>
            ) : (
              ""
            )}
          </Col>
          {!this.state.isGuardMeOrg ? (
            <>
              <Col xs={24} sm={8} style={{ textAlign: "right" }}>
                <IntlMessages id="admin.total.commission.for.usd" />:{" "}
                <strong>
                  {this.formatter.format(this.state.totalCommission)} USD
                </strong>
              </Col>
              <Col xs={24} sm={8} style={{ textAlign: "right" }}>
                <IntlMessages id="admin.total.commission.for.cad" />:{" "}
                <strong>
                  {this.formatter.format(this.state.totalCommissionCAD)} CAD
                </strong>
              </Col>{" "}
            </>
          ) : (
            ""
          )}
        </Row>
        <br />
        <Row>
          <Col>{filtertag}</Col>
        </Row>
        <Row>
          <Col>
            <Table
              columns={this.getHeaderKeys()}
              rowKey={(record) => record.orderId}
              dataSource={this.state.data}
              pagination={this.state.pagination}
              loading={this.state.loading}
              onChange={this.handleTableChange}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    ...state.user,
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...orderActions }, dispatch);
}
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    B2B2COrganizationOrderHistory
  )
);
