import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import IntlMessages from "../../../services/intlMesseges";
import {
  Table,
  Icon,
  Input,
  Button,
  Row,
  Col,
  Typography,
  Modal,
  Tooltip,
  Switch,
  Tag,
  Form,
  Select,
  TreeSelect,
} from "antd";
import { EditCell } from "../../shared/components/helperCells";
import * as invoiceActions from "../../../redux/actions/invoice-actions";
import * as OrgActions from "../../../redux/actions/organization-actions";
import * as paginationActions from "../../../redux/actions/pagination-actions";
import { bindActionCreators } from "redux";
import moment from "moment";
import * as Permission from "../../../services/permissions";
import { notifyUser } from "../../../services/notification-service";
const { Option } = Select;

var _poNumber;
var organizationName;
const dateFormathh = "MM/DD/YYYY HH:mm:ss";
class AccountBilling extends Component {
  constructor(props) {
    super(props);
    this.module = "invoices";
    this.state = {
      currentSelectedParentOrg: 0,
      parentOrgCurrentPage: 1,
      loading: false,
      data: [],
      poNumber: "",
      pagination: {
        pageSize: 10,
        current: 1,
        hideOnSinglePage: true,
        showTotal: (total, range) => {
          return (
            <span>
              <IntlMessages id="admin.text.showing" /> {range[0]}-{range[1]}{" "}
              <IntlMessages id="admin.text.of" /> {total}{" "}
              <IntlMessages id="admin.text.results" />
            </span>
          );
        },
      },
      filters: {},
      searchText: "",
      searchedColumn: "",
      defcheck: false,
      isActive: false,
      showbackbutton: false,
      parentOrganizations: [],
      childOrgs: [],
    };
    this.textchange = this.textchange.bind(this);
    this.confirm = this.confirm.bind(this);
  }

  formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
  });

  componentDidMount() {
    this.fetchdata();
    if (
      (Permission.isRole("Administrator") || Permission.isRole("CSR")) &&
      !this.props.match.params.id
    ) {
      this.loadParentOrganizations();
      this.props.clearPaginationExceptMe(this.module);
    }
    if (this.props.match.params.divid && this.props.match.params.divid > 0) {
      //this.updatevalues({ current: 1, pageSize: 10 }, {}, {}, this.props.match.params.divid);
      if (this.props.match.params.id && this.props.match.params.id > 0) {
        let list = [];
        this.props.getDivisionsList(this.props.match.params.id).then((resp) => {
          let parentId;
          for (var i in resp.data) {
            if (resp.data[i].organizationId === this.props.match.params.id) {
              parentId = resp.data[i].parentOrgId;
            } else if (
              resp.data[i].organizationId === this.props.match.params.divid
            ) {
              parentId = resp.data[i].parentOrgId;
            }
          }
          list = this.unflatten(resp.data);
          this.setState({
            childOrgs: list,
          });
        });
        this.setState({ currentSelectedParentOrg: this.props.match.params.id });
      }
    } else {
      if (this.props.match.params.id && this.props.match.params.id > 0) {
        this.onChangeParentOrganizationTree(this.props.match.params.id);
        this.setState({ currentSelectedParentOrg: this.props.match.params.id });
      }
    }
  }

  unflatten = (arr) => {
    var tree = [],
      mappedArr = {},
      arrElem,
      mappedElem;
    for (var i = 0, len = arr.length; i < len; i++) {
      arrElem = arr[i];
      arrElem.title = arrElem.name;
      arrElem.value = arrElem.organizationId;
      arrElem.key = arrElem.organizationId;
      mappedArr[arrElem.organizationId] = arrElem;
      mappedArr[arrElem.organizationId]["children"] = [];
    }
    for (var organizationId in mappedArr) {
      if (mappedArr.hasOwnProperty(organizationId)) {
        mappedElem = mappedArr[organizationId];
        if (mappedElem.parentOrgId && mappedArr[mappedElem["parentOrgId"]]) {
          mappedArr[mappedElem["parentOrgId"]] &&
            mappedArr[mappedElem["parentOrgId"]]["children"].push(mappedElem);
        } else {
          tree.push(mappedElem);
        }
      }
    }
    return tree;
  };
  handleCancel = (e) => {
    this.setState({
      visible: false,
    });
  };

  loadParentOrganizations = () => {
    this.props
      .getOrganizations({
        filters: {},
        pagination: { page: 1, pageSize: 10 },
        sorter: {},
      })
      .then((resp) => {
        this.setState({
          parentOrganizations: resp.data,
        });
      });
  };
  fetchdata() {
    if (this.props.match.params.id || this.props.match.params.divid) {
      this.updatevalues(
        { current: 1, pageSize: 10 },
        {},
        {},
        this.props.match.params.divid
          ? this.props.match.params.divid
          : this.props.match.params.id
      );
      this.setState({ showbackbutton: true });
    } else {
      this.setState({ showbackbutton: false });
      this.updatevalues({ current: 1, pageSize: 10 }, {}, {}, 0);
    }
  }
  showModal = (data) => {
    organizationName = data.organizationName;

    if (data.paymentStatus === 1) {
      this.setState({ isActive: true });
    } else {
      this.setState({ isActive: false });
    }
    this.props.form.setFieldsValue({ po_number: data.poNumber });
    this.setState(
      {
        info: data,
        poNumber: data.poNumber,
      },
      () => this.setState({ visible: true })
    );
  };

  handleOk = (e) => {
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (err) {
        return false;
      }
      if (_poNumber !== null && values.po_number === "") {
        this.setState({ textstate: true });
      } else {
        var paystatus = 0;
        if (this.state.isActive === true) {
          paystatus = 1;
        } else {
          paystatus = 0;
        }
        var params = {
          invoiceId: this.state.info.invoiceId,
          organizationId: this.state.info.organizationId,
          paymentStatus: paystatus,
          poNumber: values.po_number,
        };
        this.props.updateInvoice(params).then((data) => {
          if (!data.error) {
            // this.setState({ poNumber: null })
            // const pager = { ...this.state.pagination };

            this.fetchdata();
            /* this.props
              .getInvoices(0, {
                filters: {},
                pagination: { page: this.state.pagination.current, pageSize: 10 },
                sorter: {}
              })
              .then(resp => {
                pager.total = resp.paging.totalRecords;
                this.setState({
                  loading: false,
                  data: resp.data,
                  pagination: pager,
                  visible: false,
                  defcheck: true
                });
                this.setState({ textstate: false })
              }); */
          } else {
            // this.fetchdata();
          }
        });
      }
    });
  };
  getHeaderKeys = () => {
    return [
      {
        title: <IntlMessages id="admin.organizations.name" />,
        dataIndex: "organizationName",
        key: "organizationName",
        ...this.getColumnSearchProps("companyName"),
      },
      {
        title: <IntlMessages id="admin.division.name" />,
        dataIndex: "divisionName",
        key: "divisionName",
        ...this.getColumnSearchProps("divisionName"),
      },
      {
        title: <IntlMessages id="admin.invoice.billto" />,
        dataIndex: "billingCompanyName",
        key: "billingCompanyName",
        ...this.getColumnSearchProps("billingCompanyName"),
      },
      {
        title: <IntlMessages id="admin.invoice.plannumber" />,
        dataIndex: "membershipNumber",
        key: "membershipNumber",
        ...this.getColumnSearchProps("membershipNumber"),
      },
      {
        title: <IntlMessages id="admin.invoice.planname" />,
        dataIndex: "planName",
        key: "planName",
      },
      {
        title: <IntlMessages id="admin.invoice.noofplans" />,
        dataIndex: "planQty",
        key: "planQty",
      },
      {
        title: <IntlMessages id="admin.invoice.amount" />,
        dataIndex: "totalOrderAmount",
        key: "totalOrderAmount",
        render: (data, record) => (
          <span>{this.formatter.format(record.totalOrderAmount)}</span>
        ),
      },
      {
        title: <IntlMessages id="admin.invoice.purchasedatetime" />,
        dataIndex: "purchaseDate",
        key: "purchaseDate",
        render: (data, record) => (
          <span>{moment(record.purchaseDate).format(dateFormathh)}</span>
        ),
      },
      {
        title: <IntlMessages id="admin.invoice.duedatetime" />,
        dataIndex: "dueDate",
        key: "dueDate",
        render: (data, record) => (
          <span>{moment(record.dueDate).format(dateFormathh)}</span>
        ),
      },
      {
        title: <IntlMessages id="admin.invoice.ponumber" />,

        dataIndex: "poNumber",
        key: "poNumber",
        // ...this.getColumnSearchProps("poNumber")
      },
      {
        title: <IntlMessages id="admin.invoice.paiddate" />,
        dataIndex: "paidDate",
        key: "paidDate",
        render: (data, record) =>
          record.paidDate ? (
            <span>{moment(record.paidDate).format(dateFormathh)}</span>
          ) : (
            ""
          ),
      },
      {
        title: <IntlMessages id="admin.invoice.status" />,
        dataIndex: "paymentStatus",
        key: "paymentStatus",
        render: (_text, record) => (
          <span>
            {record.paymentStatus === 1 ? (
              <Tag color="green">
                <IntlMessages id="admin.organizationTypes.status.paid" />
              </Tag>
            ) : (
              <Tag color="red">
                <IntlMessages id="admin.organizationTypes.status.unpaid" />
              </Tag>
            )}
          </span>
        ),
      },
      {
        title: <IntlMessages id="admin.invoice.action" />,
        dataIndex: "viewinvoices",
        key: "viewinvoices",
        render: (text, record) => (
          <span>
            <button
              className="icon"
              onClick={() => {
                this.viewInvoices(record.invoiceId);
              }}
            >
              <Tooltip title={<IntlMessages id="admin.account.viewInvoices" />}>
                <i className="fas fa-file-invoice-dollar"></i>
              </Tooltip>
            </button>
            {Permission.isRole("Administrator") || Permission.isRole("CSR") ? (
              <EditCell
                onEditCell={() => {
                  this.showModal(record);
                }}
              />
            ) : (
              ""
            )}
          </span>
        ),
      },
    ];
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <div style={{ padding: 8 }}>
          <Input
            ref={(node) => {
              this.searchInput = node;
            }}
            value={selectedKeys}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? e.target.value.trimLeft() : "")
            }
            onPressEnter={() =>
              this.handleSearch(
                selectedKeys,
                confirm,
                dataIndex,
                clearFilters,
                setSelectedKeys
              )
            }
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Button
            type="primary"
            onClick={() =>
              this.handleSearch(
                selectedKeys,
                confirm,
                dataIndex,
                clearFilters,
                setSelectedKeys
              )
            }
            disabled={
              selectedKeys != "" && selectedKeys !== null ? false : true
            }
            icon="search"
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            <IntlMessages id="admin.search" />
          </Button>
          <Button
            disabled={
              selectedKeys != "" && selectedKeys !== null ? false : true
            }
            onClick={() => this.handleReset(clearFilters, dataIndex)}
            size="small"
            style={{ width: 90 }}
          >
            <IntlMessages id="admin.reset" />
          </Button>
        </div>
      );
    },
    filterIcon: (filtered) => (
      <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
  });

  handleSearch = (
    selectedKeys,
    confirm,
    dataIndex,
    clearFilters,
    setSelectedKeys
  ) => {
    if (selectedKeys === "") {
      return false;
    }

    this.setState({ searchText: selectedKeys });
    let filters = this.state.filters;
    filters[dataIndex] = {
      val: selectedKeys,
      clearf: clearFilters,
      filter: true,
      setSelectedKeys: setSelectedKeys,
      confirm: confirm,
    };
    this.setState({ filters: filters });
    confirm();
  };

  handleReset = (clearFilters, dataIndex) => {
    clearFilters();
    let filters = this.state.filters;
    if (filters[dataIndex]) {
      if (
        filters[dataIndex].setSelectedKeys &&
        typeof filters[dataIndex].setSelectedKeys === "function"
      ) {
        filters[dataIndex].setSelectedKeys("");
        //filters[dataIndex].confirm();
      }
    }
    filters[dataIndex] = { val: "", clearf: "", filter: false };
    this.setState({ filters: filters });
    this.setState({ searchText: "" });
  };
  handleTableChange = (pagination, filters, sorter) => {
    this.updatevalues(pagination, filters, sorter, 0);
  };

  updatevalues = (pagination, filters, sorter, id) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({ loading: true });
    this.props
      .getInvoices(id, {
        filters: filters,
        pagination: { page: pagination.current, pageSize: pagination.pageSize },
        sorter: sorter,
      })
      .then((resp) => {
        pager.total = resp.paging.totalRecords;
        this.setState({
          loading: false,
          visible: false,
          data: resp.data,
          pagination: pager,
        });
      })
      .catch((ex) => {
        this.setState({ loading: false });
      });
  };
  onSwitchChange = (checked, e) => {
    this.setState({ isActive: checked });
  };
  viewInvoices = (invoiceId) => {
    if (this.props.match.params.id) {
      this.props.history.push("../organization-invoice/" + invoiceId);
    } else {
      this.props.history.push("./organization-invoice/" + invoiceId);
    }
  };
  confirm() {
    let _this = this;
    Modal.confirm({
      title: "Confirmation",
      className: "org-india",
      content: "Are you sure, you want to update the status?",
      onOk() {
        _this.setState({ isActive: !(_this.state.isActive) });
      },
      onCancel() {_this.setState({ isActive: false });
    },
    });
  }
  textchange(e) {
    this.setState({ poNumber: e.target.value });
  }
  onChangeParentOrganizationTree = (selected) => {
    let list = [];
    this.props.getDivisionsList(selected).then((resp) => {
      let parentId;
      for (var i in resp.data) {
        if (resp.data[i].organizationId === selected) {
          parentId = resp.data[i].parentOrgId;
        }
      }
      list = this.unflatten(resp.data);
      this.setState({
        childOrgs: list,
      });
    });
    this.setState({ currentSelectedParentOrg: selected });
    this.props.form.setFieldsValue({ childOrgs: list });
    this.updatevalues({ current: 1, pageSize: 10 }, {}, {}, selected);
    /* if(this.props.match.params.id){
      this.props.history.push("../"+selected+"/");
    } else {
      this.props.history.push("./"+selected+"/");
    } */
  };
  getNestedChildren = (arr, parent) => {
    var out = [];
    for (var i in arr) {
      if (arr[i].parentOrgId === parent) {
        var children = this.getNestedChildren(arr, arr[i].organizationId);
        if (children.length) {
          arr[i].children = children;
        }
        arr[i].title = arr[i].name;
        arr[i].value = arr[i].organizationId;
        arr[i].label = arr[i].name;
        arr[i].key = arr[i].organizationId;
        out.push(arr[i]);
      }
    }
    return out;
  };
  onChangeOrganizationTree = (selected) => {
    this.updatevalues({ current: 1, pageSize: 10 }, {}, {}, selected);
    this.setState({ showbackbutton: true });
  };
  fetchParentOrganizations = (e) => {
    if (e.target.scrollTop + e.target.offsetHeight >= e.target.scrollHeight) {
      var datas = this.state.parentOrgCurrentPage + 1;
      this.props
        .getOrganizations({
          filters: {},
          pagination: { page: datas, pageSize: 10 },
          sorter: {},
        })
        .then((resp) => {
          let data = this.state.parentOrganizations;
          this.setState({ parentOrgCurrentPage: datas });
          this.setState({
            parentOrganizations: data.concat(resp.data),
          });
        })
        .catch((ex) => {
          this.setState({ loading: false });
        });
    }
  };
  searchParentOrganizations = async (value) => {
    let args = {
      filters: { companyName: value },
      pagination: { page: 1, pageSize: 10 },
      sorter: {},
    };
    this.props
      .getOrganizations(args)
      .then((resp) => {
        this.setState({
          parentOrganizations: resp.data,
        });
      })
      .catch((_ex) => {
        this.setState({ loading: false });
      });
  };
  render() {
    let _state = this.state;
    let _this = this;
    const { getFieldDecorator } = this.props.form;
    const { formLayout } = this.state;
    const formItemLayout =
      formLayout === "horizontal"
        ? {
            labelCol: { span: 4 },
            wrapperCol: { span: 14 },
          }
        : null;
    let filtertag = Object.keys(this.state.filters).map(function(key1) {
      let keyLabel = _this.getHeaderKeys().find((el) => el.dataIndex === key1);
      if (
        typeof keyLabel !== "undefined" &&
        keyLabel.title.props &&
        keyLabel.title.props.id
      ) {
        keyLabel = <IntlMessages id={keyLabel.title.props.id} />;
      } else {
        keyLabel = key1;
      }
      if (_state.filters[key1].filter) {
        return (
          <span>
            <Button
              key={key1}
              type="primary"
              onClick={() =>
                _this.handleReset(_state.filters[key1].clearf, key1)
              }
            >
              {keyLabel} : {_state.filters[key1].val}
              <Icon type="close" />
            </Button>
            &nbsp;
          </span>
        );
      } else {
        return "";
      }
    });
    return (
      <div id="org-inv">
        <Modal
          visible={this.state.visible}
          title={
            <h6>
              <IntlMessages id="activity.viewinvoice.organizationname" />:{" "}
              {organizationName}
            </h6>
          }
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={[
            <Button
              key="back"
              className="gray"
              onClick={this.handleCancel}
              disabled={
                this.state.info && this.state.info.paymentStatus === 1
                  ? true
                  : false
              }
            >
              <IntlMessages id="activity.viewinvoice.cancel" />
            </Button>,
            <Button
              key="submit"
              type="primary"
              onClick={this.handleOk}
              disabled={
                (this.state.info && this.state.info.paymentStatus === 1) ||
                this.state.poNumber === ""
                  ? true
                  : false
              }
            >
              <IntlMessages id="activity.viewinvoice.Update" />
            </Button>,
          ]}
        >
          <Form>
            <Row gutter={24}>
              <Form.Item
                {...formItemLayout}
                label={<IntlMessages id="activity.logs.ponumber" />}
              >
                {getFieldDecorator("po_number", {
                  rules: [
                    {
                      required: true,
                      message: <IntlMessages id="admin.input.required" />,
                    },
                  ],
                  initialValue:
                    this.state.poNumber === null ? "" : this.state.poNumber,
                  // value:this.state.poNumber
                })(
                  <Input
                    max={50}
                    disabled={
                      this.state.info && this.state.info.paymentStatus === 1
                        ? true
                        : false
                    }
                  />
                )}
              </Form.Item>
            </Row>
            <Row>
              <Form.Item>
                <Row>
                  {<IntlMessages id="admin.organiztion.paymentStatus" />}
                </Row>
                <Row>
                  {
                    <Switch
                      checkedChildren="Paid"
                      unCheckedChildren="Unpaid"
                      disabled={
                        this.state.info && this.state.info.paymentStatus === 1
                          ? true
                          : false
                      }
                      checked={this.state.isActive}
                      onClick={this.confirm}
                      name="status"
                    />
                  }
                </Row>
              </Form.Item>
            </Row>
          </Form>
        </Modal>
        <Row gutter={24}>
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography.Title level={4}>
              <IntlMessages id="admin.account.organizationInvoices" />
            </Typography.Title>
          </Col>

          {this.state.showbackbutton ? (
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <Button
                className="right-fl"
                type="primary"
                htmlType="button"
                onClick={() => this.props.history.goBack()}
              >
                <IntlMessages id="admin.userlisting.back" />
              </Button>
            </Col>
          ) : (
            ""
          )}
        </Row>
        <Row>
          <Col>{filtertag}</Col>
        </Row>
        <hr />
        <Row gutter={24} style={{ paddingBottom: "20px" }}>
          {(Permission.isRole("Administrator") || Permission.isRole("CSR")) &&
          !this.props.match.params.id ? (
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <div style={{ float: "left", paddingTop: "5px" }}>
                <IntlMessages id="admin.invoice.parentorganization" />
                &nbsp;
              </div>
              <div style={{ float: "left", minWidth: "320px" }}>
                <Select
                  defaultValue={0}
                  style={{ width: "100%" }}
                  onPopupScroll={this.fetchParentOrganizations}
                  onSearch={this.searchParentOrganizations}
                  onChange={this.onChangeParentOrganizationTree}
                  filterOption={false}
                  showSearch
                >
                  <Option key={0} value={0}>
                    All
                  </Option>
                  {this.state.parentOrganizations
                    ? this.state.parentOrganizations.map(function(item) {
                        return (
                          <Option key={item.organizationId}>{item.name}</Option>
                        );
                      })
                    : ""}
                </Select>
              </div>
            </Col>
          ) : (
            ""
          )}
          {this.state.currentSelectedParentOrg > 0 &&
          this.state.childOrgs.length > 0 ? (
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <div style={{ float: "left", paddingTop: "5px" }}>
                <IntlMessages id="admin.organization.company" />
                :&nbsp;
              </div>
              <div style={{ float: "left", minWidth: "320px" }}>
                {getFieldDecorator("childOrgs")(
                  <TreeSelect
                    defaultValue={
                      (this.props.match.params.divid &&
                      this.props.match.params.divid > 0
                        ? this.props.match.params.divid
                        : this.props.match.params.id) ||
                      this.state.currentSelectedParentOrg
                    }
                    style={{ width: "100%" }}
                    dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                    treeData={this.state.childOrgs}
                    onChange={this.onChangeOrganizationTree}
                    treeDefaultExpandAll={true}
                  />
                )}
              </div>
            </Col>
          ) : (
            ""
          )}
        </Row>
        <Row gutter={24}>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={24}
            style={{ overflowX: "auto" }}
          >
            <Table
              columns={this.getHeaderKeys()}
              rowKey={(record) => record.invoiceId}
              dataSource={this.state.data}
              pagination={this.state.pagination}
              loading={this.state.loading}
              onChange={this.handleTableChange}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    ...state.pagination,
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { ...invoiceActions, ...OrgActions, ...paginationActions },
    dispatch
  );
}
const WrappedForm = Form.create({ name: "custom-alert" })(AccountBilling);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    WrappedForm
  )
);
