import Axios from "../../services/axios-service";


const getCountriesList = data => {
  return Axios.get("/api/Country/OrgCountryList?search=" + encodeURIComponent(JSON.stringify(data)), undefined, undefined);
};
const getCountriesbyId = data => {
  return Axios.get("/api/CountryInfo/" + data, undefined, undefined);
};
const updateCountries = (data, id) => {
  return Axios.put("/api/Country/" + id, data, undefined);
};
const addCountries = (data, id) => {
  return Axios.post("/api/Country/", data, undefined);
};
export default {
  getCountriesList,
  getCountriesbyId,
  updateCountries,
  addCountries
};

