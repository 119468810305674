import API from "../api/risk-line-api";
export function getCountryReport(data) {
  return async function(dispatch, getState) {
    try {
      let resp = await API.getCountryReport(data);
      return resp;
    } catch (e) {}
  };
}

export function getCitiesList(data) {
  return async function(dispatch, getState) {
    try {
      let resp = await API.getCitiesList(data);
      return resp;
    } catch (e) {}
  };
}

export function getCityReport(data) {
  return async function(dispatch, getState) {
    try {
      let resp = await API.getCityReport(data);
      return resp;
    } catch (e) {}
  };
}

export function risklineAlerts({
  countries,
  riskLevel,
  filters,
  pagination,
  sorter
}) {
  return async function(dispatch, getState) {
    try {
      let resp = await API.risklineAlerts({
        countries: countries,
        riskLevel: riskLevel,
        filters: filters,
        pagination: pagination,
        sorter: sorter
      });
      return resp;
    } catch (e) {}
  };
}

export function getAdvisories(data) {
  return async function(dispatch, getState) {
    try {
      let resp = await API.getAdvisories(data);
      return resp;
    } catch (e) {}
  };
}

export function getCovidData(data) {
    return async function (dispatch, getState) {
        try {
            let resp = await API.getCovidData(data);
            return resp;
        } catch (e) { }
    };
}
export function getCovidCountryRegion(data) {
  return async function (dispatch, getState) {
      try {
          let resp = await API.getCovidCountryRegion(data);
          return resp;
      } catch (e) { }
  };
}
export function getCovidCountryCities(data) {
  return async function (dispatch, getState) {
      try {
          let resp = await API.getCovidCountryCities(data);
          return resp;
      } catch (e) { }
  };
}

export function risklineAlertsFilter({
  countries,
  searchText,
  startDate,
  endDate,
  riskLevel,
  categoryName,
  filters,
  pagination,
  sorter
}) {
  return async function(dispatch, getState) {
    try {
      let resp = await API.risklineAlertsFilter({
        countries: countries,
        searchText: searchText,
        startDate: startDate,
        endDate: endDate,
        riskLevel: riskLevel,
        categoryName: categoryName,
        filters: filters,
        pagination: pagination,
        sorter: sorter
      });
      return resp;
    } catch (e) {}
  };
}

export function advisoriesFilter({
  countries,
  searchText,
  riskLevel,
  filters,
  pagination,
  sorter
}) {
  return async function(dispatch, getState) {
    try {
      let resp = await API.advisoriesFilter({
        countries: countries,
        searchText: searchText,
        riskLevel: riskLevel,
        filters: filters,
        pagination: pagination,
        sorter: sorter
      });
      return resp;
    } catch (e) {}
  };
}

export function getRiskLineCategories() {
  return async function(dispatch, getState) {
    try {
      let resp = await API.getRiskLineCategories();
      return resp;
    } catch (e) {}
  };
}

export function getAdvisoryCoordinatesById(data) {
  return async function(dispatch, getState) {
    try {
      let resp = await API.getAdvisoryCoordinatesById(data);
      return resp;
    } catch (e) {}
  };
}
