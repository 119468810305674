import React, { Component } from "react";
import { connect } from "react-redux";
import "bootstrap/dist/js/bootstrap.bundle.min";
import $ from 'jquery';
import ResellerDemoLogo from "../../images/reseller-logo-1.png";
import CapLogo from "../../images/logo.png";
import Config from "../../../../config";

var didScroll;
var lastScrollTop = 0;
var delta = 5;
var navbarHeight = 150;
class HeaderB2B2C extends Component {
	constructor(props){
		super(props);
		this.state = {
			pageLoaded: false,
      dataLoaded: false
		};
	}

	componentDidMount () {
		$(window).scroll(function (event) {
			didScroll = true;
			clearTimeout($.data(this, 'scrollTimer'));
			/* $.data(this, 'scrollTimer', setTimeout(function () {
				$('header#headerb2c').removeAttr('class');
			}, 500)); */
		});
    if(this.props.resellerOrganizationInfo.b2B2COrgSettings){
      this.setState({ dataLoaded: true });
    }
	}

	componentDidUpdate = () => {
		let self= this;
		if(self.state.pageLoaded !== true){
			self.setState({ pageLoaded: true });
			setInterval(() => {
				if (didScroll) {
					self.hasScrolled();
						didScroll = false;
				}
			}, 250);
		}
	}

  onChangeSearchText = e => {
    e.target.value = e.target.value.trimLeft();
  };

	hasScrolled(){
			var st = $(window).scrollTop();
			if (Math.abs(lastScrollTop - st) <= delta)
					return;
			if (st > lastScrollTop && st > navbarHeight) {
				$('header#headerb2c').removeClass('nav-down').addClass('nav-up');
			} else {
				if (st + $(window).height() < $(document).height()) {
						$('header#headerb2c').removeClass('nav-up').addClass('nav-down');
				}
			}
			lastScrollTop = st;
	}
  render() {
    let { resellerOrganizationInfo } = this.props;
    let VendorLogo = null; 
    let isCapLogoVisible = false;
    if(resellerOrganizationInfo.b2B2COrgSettings && Object.keys(resellerOrganizationInfo.b2B2COrgSettings).length > 0){
      resellerOrganizationInfo = resellerOrganizationInfo.b2B2COrgSettings;
      if(resellerOrganizationInfo.logo && resellerOrganizationInfo.logo !== null){
        VendorLogo =  Config.API1 +"/"+resellerOrganizationInfo.logo;
      }
      if(typeof resellerOrganizationInfo.isCapLogoVisible !== "undefined" && resellerOrganizationInfo.isCapLogoVisible !== null){
        isCapLogoVisible =  resellerOrganizationInfo.isCapLogoVisible;
      }
    }
    if(this.props.resellerOrganizationInfo.b2B2COrgSettings && this.state.dataLoaded !== true){
      this.setState({ dataLoaded: true });
    }
    return (
      <header id="headerb2b2c">
        <div className="headerb2b2c-inner">
          <div className="wrap">
            <section className="top-header">
              <div className="row">
                {this.state.dataLoaded !== false ?
                  isCapLogoVisible ?
                    <>
                      {VendorLogo !== null ? 
                        <div className="logo col-md-6 col-md-6 text-right">
                            <img
                              src={VendorLogo}
                              alt="Vendor"
                              height = "90"
                              width="118"
                              />
                        </div>
                      : ''}
                      <div className={VendorLogo !== null ? "logo col-md-6 col-md-6" : "logo col-md-12 col-md-12  text-center"}>
                          <img
                            src={CapLogo}
                            alt="CAP™"
                            height = "90"
                            width="118"
                          />
                      </div>
                    </>
                  : 
                    <>
                      <div className="logo col-md-12 col-md-12 text-center">
                          <img
                            src={VendorLogo !== null ? VendorLogo : CapLogo}
                            alt="Vendor"
                            height = "90"
                            width="118"
                          />
                      </div>
                    </>
                  : ''  
                }
                
              </div>
            </section>
          </div>
        </div>
      </header>
    );
  }
}
function mapStateToProps(state) {
  return {
    ...state.user
  };
}
export default connect(
  mapStateToProps,
  null,
  null,
  { forwardRef: true }
)(HeaderB2B2C);