import API from "../api/email-template-api";
export function getEmailTemplates({ orgId, filters, pagination, sorter }) {
  return async function(_dispatch, _getState) {
    try {
      return await API.getEmailTemplates({
        orgId: orgId,
        filters: filters,
        pagination: pagination,
        sorter : sorter
      });
    } catch (e) {
      return {"error" : true};
    }
  };
}
export function getEmailTemplate(orgId, templateId) {
  return async function(_dispatch, _getState) {
    try {
      return await API.getEmailTemplate(orgId, templateId);
    } catch (e) {
      return {"error" : true};
    }
  };
}
export function updateEmailTemplate(templateId, data) {
  return async function(_dispatch, _getState) {
    try {
      return await API.updateEmailTemplate(templateId, data);
    } catch (e) {
      return {"error" : true};
    }
  };
}
export function addEmailTemplate(data) {
  return async function(_dispatch, _getState) {
    try {
      return await API.addEmailTemplate(data);
    } catch (e) {
      return {"error" : true};
    }
  };
}