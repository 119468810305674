import API from "../api/alert-types-api";

export function getAlertTypes(data) {
  return async function(dispatch, getAction) {
    try {
      let resp = await API.getAlertTypes(data);
      return resp;

    } catch (error) {
      return { error: true };
    }
  };
}
