import Axios from "../../services/axios-service";
import Config from "../../../../config";

const getActiveTravelers = (orgId, userRole, data) => {
  let url;
  if (userRole === "Administrator") {
    url =
      "/api/ItineraryReporting/ActiveTravelers?search=" +
      encodeURIComponent(JSON.stringify(data));
  } else {
    url =
      "/api/ItineraryReporting/ActiveTravelers?orgId=" +
      orgId +
      "&search=" +
      encodeURIComponent(JSON.stringify(data));
  }
  return Axios.get(url, undefined, undefined);
};

const getActiveManualTravelers = (orgId, userRole, data) => {
  let url;
  if (userRole === "Administrator") {
    url =
      "/api/ManualItineraryReporting/ActiveTravelers?search=" +
      encodeURIComponent(JSON.stringify(data));
  } else {
    url =
      "/api/ManualItineraryReporting/ActiveTravelers?orgId=" +
      orgId +
      "&search=" +
      encodeURIComponent(JSON.stringify(data));
  }
  return Axios.get(url, undefined, undefined);
};

const getManualTravelersCsv = (orgId, userRole, data) => {
  let url;
  if (userRole === "Administrator") {
    url =
      "/api/ManualItineraryReporting/Export/ActiveTravelers?search=" +
      encodeURIComponent(JSON.stringify(data));
  } else {
    url =
      "/api/ManualItineraryReporting/Export/ActiveTravelers?orgId=" +
      orgId +
      "&search=" +
      encodeURIComponent(JSON.stringify(data));
  }
  return Config.API1 + url;
};

const getMagnatechTravelersCsv = (type, orgId, userRole, data) => {
  let url;
  if (type == "magnatech") {
    if (userRole === "Administrator") {
      url =
        "/api/ItineraryReporting/Export/ActiveTravelers?search=" +
        encodeURIComponent(JSON.stringify(data));
    } else {
      url =
        "/api/ItineraryReporting/Export/ActiveTravelers?orgId=" +
        orgId +
        "&search=" +
        encodeURIComponent(JSON.stringify(data));
    }
    return Config.API1 + url;
  } else {
    if (userRole === "Administrator") {
      url =
        "/api/CombinedItineraryReporting/Export/ActiveTravelers?search=" +
        encodeURIComponent(JSON.stringify(data));
    } else {
      url =
        "/api/CombinedItineraryReporting/Export/ActiveTravelers?orgId=" +
        orgId +
        "&search=" +
        encodeURIComponent(JSON.stringify(data));
    }
    return Config.API1 + url;
  }
};

const getTravelersByDestandTime = (orgId, userRole, data, key) => {
  let url;
  if (userRole === "Administrator" && key != 3) {
    url =
      "api/ItineraryReporting/GetTravelerByDestination?search=" +
      encodeURIComponent(JSON.stringify(data));
  } else if (userRole === "Administrator" && key == 3) {
    url =
      "api/ItineraryReporting/GetUnmappedTravelerByDestination?search=" +
      encodeURIComponent(JSON.stringify(data));
  } else {
    url =
      "api/ItineraryReporting/GetTravelerByDestination?orgId=" +
      orgId +
      "&search=" +
      encodeURIComponent(JSON.stringify(data));
  }
  return Axios.get(url, undefined, undefined);
};

const getManualTravelersByDestandTime = (orgId, userRole, data, key) => {
  let url;
  if (userRole === "Administrator") {
    url =
      "api/ManualItineraryReporting/GetTravelerByDestination?search=" +
      encodeURIComponent(JSON.stringify(data));
  } else {
    url =
      "api/ManualItineraryReporting/GetTravelerByDestination?orgId=" +
      orgId +
      "&search=" +
      encodeURIComponent(JSON.stringify(data));
  }
  return Axios.get(url, undefined, undefined);
};

const getManualTravelersByDestandTimeCsv = (orgId, userRole, data) => {
  let url;
  if (userRole === "Administrator") {
    url =
      "/api/ManualItineraryReporting/Export/GetTravelerByDestination?search=" +
      encodeURIComponent(JSON.stringify(data));
  } else {
    url =
      "/api/ManualItineraryReporting/Export/GetTravelerByDestination?orgId=" +
      orgId +
      "&search=" +
      encodeURIComponent(JSON.stringify(data));
  }
  return Config.API1 + url;
};


const getUnmappedTravelersByDestandTimeCsv = (orgId, userRole, data) => {
  let url;
  if (userRole === "Administrator") {
    url =
      "/api/ItineraryReporting/Export/GetUnmappedTravelerByDestination?search=" +
      encodeURIComponent(JSON.stringify(data));
  } else {
    url =
      "/api/ItineraryReporting/Export/GetUnmappedTravelerByDestination?orgId=" +
      orgId +
      "&search=" +
      encodeURIComponent(JSON.stringify(data));
  }
  return Config.API1 + url;
};

const getMagnatechTravelersByDestandTimeCsv = (type, orgId, userRole, data) => {
  let url;
  if (type == "magnatech") {
    if (userRole === "Administrator") {
      url =
        "/api/ItineraryReporting/Export/GetTravelerByDestination?search=" +
        encodeURIComponent(JSON.stringify(data));
    } else if (type == "nonCap") {
      url =
        "/api/ItineraryReporting/Export/UnmappedGetTravelerByDestination?search=" +
        encodeURIComponent(JSON.stringify(data));
    } else {
      url =
        "/api/ItineraryReporting/Export/GetTravelerByDestination?orgId=" +
        orgId +
        "&search=" +
        encodeURIComponent(JSON.stringify(data));
    }
    return Config.API1 + url;
  } else {
    if (userRole === "Administrator") {
      url =
        "/api/CombinedItineraryReporting/Export/GetTravelerByDestination?search=" +
        encodeURIComponent(JSON.stringify(data));
    } else {
      url =
        "/api/CombinedItineraryReporting/Export/GetTravelerByDestination?orgId=" +
        orgId +
        "&search=" +
        encodeURIComponent(JSON.stringify(data));
    }
    return Config.API1 + url;
  }
};

const getWhereAreMyTravelers = (orgId, userRole, data) => {
  let url;
  if (userRole === "Administrator") {
    url =
      "/api/ItineraryReporting/GetTravelerActiveLocation?search=" +
      encodeURIComponent(JSON.stringify(data));
  } else {
    url =
      "/api/ItineraryReporting/GetTravelerActiveLocation?orgId=" +
      orgId +
      "&search=" +
      encodeURIComponent(JSON.stringify(data));
  }
  return Axios.get(url, undefined, undefined);
};

const getManualWhereAreMyTravelers = (orgId, userRole, data) => {
  let url;
  if (userRole === "Administrator") {
    url =
      "api/ManualItineraryReporting/GetTravelerActiveLocation?search=" +
      encodeURIComponent(JSON.stringify(data));
  } else {
    url =
      "api/ManualItineraryReporting/GetTravelerActiveLocation?orgId=" +
      orgId +
      "&search=" +
      encodeURIComponent(JSON.stringify(data));
  }
  return Axios.get(url, undefined, undefined);
};

const getManualWhereAreMyTravelersCsv = (orgId, userRole, data) => {
  let url;
  if (userRole === "Administrator") {
    url =
      "/api/ManualItineraryReporting/Export/GetTravelerActiveLocation?search=" +
      encodeURIComponent(JSON.stringify(data));
  } else {
    url =
      "/api/ManualItineraryReporting/Export/GetTravelerActiveLocation?orgId=" +
      orgId +
      "&search=" +
      encodeURIComponent(JSON.stringify(data));
  }
  return Config.API1 + url;
};

const getMagnatechWhereAreMyTravelersCsv = (type, orgId, userRole, data) => {
  let url;
  if (type == "magnatech") {
    if (userRole === "Administrator") {
      url =
        "/api/ItineraryReporting/Export/GetTravelerActiveLocation?search=" +
        encodeURIComponent(JSON.stringify(data));
    } else {
      url =
        "/api/ItineraryReporting/Export/GetTravelerActiveLocation?orgId=" +
        orgId +
        "&search=" +
        encodeURIComponent(JSON.stringify(data));
    }
    return Config.API1 + url;
  } else {
    if (userRole === "Administrator") {
      url =
        "/api/CombinedItineraryReporting/Export/GetTravelerActiveLocation?search=" +
        encodeURIComponent(JSON.stringify(data));
    } else {
      url =
        "/api/CombinedItineraryReporting/Export/GetTravelerActiveLocation?orgId=" +
        orgId +
        "&search=" +
        encodeURIComponent(JSON.stringify(data));
    }
    return Config.API1 + url;
  }
};

const getTravelersByFlightType = (orgId, userRole, data) => {
  let url;
  if (userRole === "Administrator") {
    url =
      "/api/ItineraryReporting/GetTravelerFlightType?search=" +
      encodeURIComponent(JSON.stringify(data));
  } else {
    url =
      "/api/ItineraryReporting/GetTravelerFlightType?orgId=" +
      orgId +
      "&search=" +
      encodeURIComponent(JSON.stringify(data));
  }
  return Axios.get(url, undefined, undefined);
};

const getManualTravelersByFlightType = (orgId, userRole, data) => {
  let url;
  if (userRole === "Administrator") {
    url =
      "/api/ManualItineraryReporting/GetTravelerFlightType?search=" +
      encodeURIComponent(JSON.stringify(data));
  } else {
    url =
      "/api/ManualItineraryReporting/GetTravelerFlightType?orgId=" +
      orgId +
      "&search=" +
      encodeURIComponent(JSON.stringify(data));
  }
  return Axios.get(url, undefined, undefined);
};

const getManualTravelersByFlightTypeCsv = (orgId, userRole, data) => {
  let url;
  if (userRole === "Administrator") {
    url =
      "/api/ManualItineraryReporting/Export/GetTravelerFlightType?search=" +
      encodeURIComponent(JSON.stringify(data));
  } else {
    url =
      "/api/ManualItineraryReporting/Export/GetTravelerFlightType?orgId=" +
      orgId +
      "&search=" +
      encodeURIComponent(JSON.stringify(data));
  }
  return Config.API1 + url;
};

const getMagnatechTravelersByFlightTypeCsv = (type, orgId, userRole, data) => {
  let url;
  if (type == "magnatech") {
    if (userRole === "Administrator") {
      url =
        "/api/ItineraryReporting/Export/GetTravelerFlightType?search=" +
        encodeURIComponent(JSON.stringify(data));
    } else {
      url =
        "/api/ItineraryReporting/Export/GetTravelerFlightType?orgId=" +
        orgId +
        "&search=" +
        encodeURIComponent(JSON.stringify(data));
    }
    return Config.API1 + url;
  } else {
    if (userRole === "Administrator") {
      url =
        "/api/CombinedItineraryReporting/Export/GetTravelerFlightType?search=" +
        encodeURIComponent(JSON.stringify(data));
    } else {
      url =
        "/api/CombinedItineraryReporting/Export/GetTravelerFlightType?orgId=" +
        orgId +
        "&search=" +
        encodeURIComponent(JSON.stringify(data));
    }
    return Config.API1 + url;
  }
};

const getTravelersByFlightSegment = (orgId, userRole, data) => {
  let url;
  if (userRole === "Administrator") {
    url =
      "api/ItineraryReporting/GetTravelerByFlightSegment?search=" +
      encodeURIComponent(JSON.stringify(data));
  } else {
    url =
      "api/ItineraryReporting/GetTravelerByFlightSegment?orgId=" +
      orgId +
      "&search=" +
      encodeURIComponent(JSON.stringify(data));
  }
  return Axios.get(url, undefined, undefined);
};

const getManualTravelersByFlightSegment = (orgId, userRole, data) => {
  let url;
  if (userRole === "Administrator") {
    url =
      "api/ManualItineraryReporting/GetTravelerByFlightSegment?search=" +
      encodeURIComponent(JSON.stringify(data));
  } else {
    url =
      "api/ManualItineraryReporting/GetTravelerByFlightSegment?orgId=" +
      orgId +
      "&search=" +
      encodeURIComponent(JSON.stringify(data));
  }
  return Axios.get(url, undefined, undefined);
};

const getManualTravelersByFlightSegmentCsv = (orgId, userRole, data) => {
  let url;
  if (userRole === "Administrator") {
    url =
      "/api/ManualItineraryReporting/Export/GetTravelerByFlightSegment?search=" +
      encodeURIComponent(JSON.stringify(data));
  } else {
    url =
      "/api/ManualItineraryReporting/Export/GetTravelerByFlightSegment?orgId=" +
      orgId +
      "&search=" +
      encodeURIComponent(JSON.stringify(data));
  }
  return Config.API1 + url;
};

const getMagnatechTravelersByFlightSegmentCsv = (
  type,
  orgId,
  userRole,
  data
) => {
  let url;
  if (type == "magnatech") {
    if (userRole === "Administrator") {
      url =
        "/api/ItineraryReporting/Export/GetTravelerByFlightSegment?search=" +
        encodeURIComponent(JSON.stringify(data));
    } else {
      url =
        "/api/ItineraryReporting/Export/GetTravelerByFlightSegment?orgId=" +
        orgId +
        "&search=" +
        encodeURIComponent(JSON.stringify(data));
    }
    return Config.API1 + url;
  } else {
    if (userRole === "Administrator") {
      url =
        "/api/CombinedItineraryReporting/Export/GetTravelerByFlightSegment?search=" +
        encodeURIComponent(JSON.stringify(data));
    } else {
      url =
        "/api/CombinedItineraryReporting/Export/GetTravelerByFlightSegment?orgId=" +
        orgId +
        "&search=" +
        encodeURIComponent(JSON.stringify(data));
    }
    return Config.API1 + url;
  }
};

const getTravelersByActiveCar = (orgId, userRole, data) => {
  let url;
  if (userRole === "Administrator") {
    url =
      "api/ItineraryReporting/GetTravelerByActiveCar?search=" +
      encodeURIComponent(JSON.stringify(data));
  } else {
    url =
      "api/ItineraryReporting/GetTravelerByActiveCar?orgId=" +
      orgId +
      "&search=" +
      encodeURIComponent(JSON.stringify(data));
  }
  return Axios.get(url, undefined, undefined);
};

const getTravelersByActiveCarCsv = (orgId, userRole, data) => {
  let url;
  if (userRole === "Administrator") {
    url =
      "/api/ItineraryReporting/Export/GetTravelerByActiveCar?search=" +
      encodeURIComponent(JSON.stringify(data));
  } else {
    url =
      "/api/ItineraryReporting/Export/GetTravelerByActiveCar?orgId=" +
      orgId +
      "&search=" +
      encodeURIComponent(JSON.stringify(data));
  }
  return Config.API1 + url;
};

const getTravelersByAirport = (orgId, userRole, data) => {
  let url;
  if (userRole === "Administrator") {
    url =
      "api/ItineraryReporting/GetTravelerByAirportCode?search=" +
      encodeURIComponent(JSON.stringify(data));
  } else {
    url =
      "api/ItineraryReporting/GetTravelerByAirportCode?orgId=" +
      orgId +
      "&search=" +
      encodeURIComponent(JSON.stringify(data));
  }
  return Axios.get(url, undefined, undefined);
};

const getManualTravelersByAirport = (orgId, userRole, data) => {
  let url;
  if (userRole === "Administrator") {
    url =
      "api/ManualItineraryReporting/GetTravelerByAirportCode?search=" +
      encodeURIComponent(JSON.stringify(data));
  } else {
    url =
      "api/ManualItineraryReporting/GetTravelerByAirportCode?orgId=" +
      orgId +
      "&search=" +
      encodeURIComponent(JSON.stringify(data));
  }
  return Axios.get(url, undefined, undefined);
};

const getManualTravelersByAirportCsv = (orgId, userRole, data) => {
  let url;
  if (userRole === "Administrator") {
    url =
      "/api/ManualItineraryReporting/Export/GetTravelerByAirportCode?search=" +
      encodeURIComponent(JSON.stringify(data));
  } else {
    url =
      "/api/ManualItineraryReporting/Export/GetTravelerByAirportCode?orgId=" +
      orgId +
      "&search=" +
      encodeURIComponent(JSON.stringify(data));
  }
  return Config.API1 + url;
};

const getMagnatechTravelersByAirportCsv = (type, orgId, userRole, data) => {
  let url;
  if (type == "magnatech") {
    if (userRole === "Administrator") {
      url =
        "/api/ItineraryReporting/Export/GetTravelerByAirportCode?search=" +
        encodeURIComponent(JSON.stringify(data));
    } else {
      url =
        "/api/ItineraryReporting/Export/GetTravelerByAirportCode?orgId=" +
        orgId +
        "&search=" +
        encodeURIComponent(JSON.stringify(data));
    }
    return Config.API1 + url;
  } else {
    if (userRole === "Administrator") {
      url =
        "/api/CombinedItineraryReporting/Export/GetTravelerByAirportCode?search=" +
        encodeURIComponent(JSON.stringify(data));
    } else {
      url =
        "/api/CombinedItineraryReporting/Export/GetTravelerByAirportCode?orgId=" +
        orgId +
        "&search=" +
        encodeURIComponent(JSON.stringify(data));
    }
    return Config.API1 + url;
  }
};

const getTravelersByArrivalAirport = (orgId, userRole, data) => {
  let url;
  if (userRole === "Administrator") {
    url =
      "/api/ItineraryReporting/GetTravelerByArrivalAirportCode?search=" +
      encodeURIComponent(JSON.stringify(data));
  } else {
    url =
      "/api/ItineraryReporting/GetTravelerByArrivalAirportCode?orgId=" +
      orgId +
      "&search=" +
      encodeURIComponent(JSON.stringify(data));
  }
  return Axios.get(url, undefined, undefined);
};

const getManualTravelersByArrivalAirport = (orgId, userRole, data) => {
  let url;
  if (userRole === "Administrator") {
    url =
      "/api/ManualItineraryReporting/GetTravelerByArrivalAirportCode?search=" +
      encodeURIComponent(JSON.stringify(data));
  } else {
    url =
      "/api/ManualItineraryReporting/GetTravelerByArrivalAirportCode?orgId=" +
      orgId +
      "&search=" +
      encodeURIComponent(JSON.stringify(data));
  }
  return Axios.get(url, undefined, undefined);
};

const getManualTravelersByArrivalAirportCsv = (orgId, userRole, data) => {
  let url;
  if (userRole === "Administrator") {
    url =
      "/api/ManualItineraryReporting/Export/GetTravelerByArrivalAirportCode?search=" +
      encodeURIComponent(JSON.stringify(data));
  } else {
    url =
      "/api/ManualItineraryReporting/Export/GetTravelerByArrivalAirportCode?orgId=" +
      orgId +
      "&search=" +
      encodeURIComponent(JSON.stringify(data));
  }
  return Config.API1 + url;
};

const getMagnatechTravelersByArrivalAirportCsv = (
  type,
  orgId,
  userRole,
  data
) => {
  let url;
  if (type == "magnatech") {
    if (userRole === "Administrator") {
      url =
        "/api/ItineraryReporting/Export/GetTravelerByArrivalAirportCode?search=" +
        encodeURIComponent(JSON.stringify(data));
    } else {
      url =
        "/api/ItineraryReporting/Export/GetTravelerByArrivalAirportCode?orgId=" +
        orgId +
        "&search=" +
        encodeURIComponent(JSON.stringify(data));
    }
    return Config.API1 + url;
  } else {
    if (userRole === "Administrator") {
      url =
        "/api/CombinedItineraryReporting/Export/GetTravelerByArrivalAirportCode?search=" +
        encodeURIComponent(JSON.stringify(data));
    } else {
      url =
        "/api/CombinedItineraryReporting/Export/GetTravelerByArrivalAirportCode?orgId=" +
        orgId +
        "&search=" +
        encodeURIComponent(JSON.stringify(data));
    }
    return Config.API1 + url;
  }
};

const getTravelersByDepatureAirport = (orgId, userRole, data) => {
  let url;
  if (userRole === "Administrator") {
    url =
      "/api/ItineraryReporting/GetTravelerByDepartureAirportCode?search=" +
      encodeURIComponent(JSON.stringify(data));
  } else {
    url =
      "/api/ItineraryReporting/GetTravelerByDepartureAirportCode?orgId=" +
      orgId +
      "&search=" +
      encodeURIComponent(JSON.stringify(data));
  }
  return Axios.get(url, undefined, undefined);
};

const getManualTravelersByDepatureAirport = (orgId, userRole, data) => {
  let url;
  if (userRole === "Administrator") {
    url =
      "/api/ManualItineraryReporting/GetTravelerByDepartureAirportCode?search=" +
      encodeURIComponent(JSON.stringify(data));
  } else {
    url =
      "/api/ManualItineraryReporting/GetTravelerByDepartureAirportCode?orgId=" +
      orgId +
      "&search=" +
      encodeURIComponent(JSON.stringify(data));
  }
  return Axios.get(url, undefined, undefined);
};

const getManualTravelersByDepatureAirportCsv = (orgId, userRole, data) => {
  let url;
  if (userRole === "Administrator") {
    url =
      "/api/ManualItineraryReporting/Export/GetTravelerByDepartureAirportCode?search=" +
      encodeURIComponent(JSON.stringify(data));
  } else {
    url =
      "/api/ManualItineraryReporting/Export/GetTravelerByDepartureAirportCode?orgId=" +
      orgId +
      "&search=" +
      encodeURIComponent(JSON.stringify(data));
  }
  return Config.API1 + url;
};

const getMagnatechTravelersByDepatureAirportCsv = (
  type,
  orgId,
  userRole,
  data
) => {
  let url;
  if (type == "magnatech") {
    if (userRole === "Administrator") {
      url =
        "/api/ItineraryReporting/Export/GetTravelerByDepartureAirportCode?search=" +
        encodeURIComponent(JSON.stringify(data));
    } else {
      url =
        "/api/ItineraryReporting/Export/GetTravelerByDepartureAirportCode?orgId=" +
        orgId +
        "&search=" +
        encodeURIComponent(JSON.stringify(data));
    }
    return Config.API1 + url;
  } else {
    if (userRole === "Administrator") {
      url =
        "/api/CombinedItineraryReporting/Export/GetTravelerByDepartureAirportCode?search=" +
        encodeURIComponent(JSON.stringify(data));
    } else {
      url =
        "/api/CombinedItineraryReporting/Export/GetTravelerByDepartureAirportCode?orgId=" +
        orgId +
        "&search=" +
        encodeURIComponent(JSON.stringify(data));
    }
    return Config.API1 + url;
  }
};

const getTravellersByFlightNumber = (orgId, userRole, data) => {
  let url;
  if (userRole === "Administrator") {
    url =
      "/api/ItineraryReporting/GetTravelerByFlightSearch?search=" +
      encodeURIComponent(JSON.stringify(data));
  } else {
    url =
      "/api/ItineraryReporting/GetTravelerByFlightSearch?orgId=" +
      orgId +
      "&search=" +
      encodeURIComponent(JSON.stringify(data));
  }
  return Axios.get(url, undefined, undefined);
};

const getTravellersByPnrNumber = (orgId, pnrNumber, userRole, data) => {
  let url;
  if (userRole === "Administrator") {
    url =
      "/api/ItineraryReporting/GetTravelerByPnrSearch?pnrNumber=" +
      pnrNumber +
      "&search=" +
      encodeURIComponent(JSON.stringify(data));
  } else {
    url =
      "/api/ItineraryReporting/GetTravelerByPnrSearch?orgId=" +
      orgId +
      "&pnrNumber=" +
      pnrNumber +
      "&search=" +
      encodeURIComponent(JSON.stringify(data));
  }
  return Axios.get(url, undefined, undefined);
};

const getManualTravellersByFlightNumber = (orgId, userRole, data) => {
  let url;
  if (userRole === "Administrator") {
    url =
      "/api/ManualItineraryReporting/GetTravelerByFlightSearch?search=" +
      encodeURIComponent(JSON.stringify(data));
  } else {
    url =
      "/api/ManualItineraryReporting/GetTravelerByFlightSearch?orgId=" +
      orgId +
      "&search=" +
      encodeURIComponent(JSON.stringify(data));
  }
  return Axios.get(url, undefined, undefined);
};

const getManualTravellersByFlightNumberCsv = (orgId, userRole, data) => {
  let url;
  if (userRole === "Administrator") {
    url =
      "/api/ManualItineraryReporting/Export/GetTravelerByFlightSearch?search=" +
      encodeURIComponent(JSON.stringify(data));
  } else {
    url =
      "/api/ManualItineraryReporting/Export/GetTravelerByFlightSearch?orgId=" +
      orgId +
      "&search=" +
      encodeURIComponent(JSON.stringify(data));
  }
  return Config.API1 + url;
};

const getMagnatechTravellersByFlightNumberCsv = (
  type,
  orgId,
  userRole,
  data
) => {
  let url;
  if (type == "magnatech") {
    if (userRole === "Administrator") {
      url =
        "/api/ItineraryReporting/Export/GetTravelerByFlightSearch?search=" +
        encodeURIComponent(JSON.stringify(data));
    } else {
      url =
        "/api/ItineraryReporting/Export/GetTravelerByFlightSearch?orgId=" +
        orgId +
        "&search=" +
        encodeURIComponent(JSON.stringify(data));
    }
    return Config.API1 + url;
  } else {
    if (userRole === "Administrator") {
      url =
        "/api/CombinedItineraryReporting/Export/GetTravelerByFlightSearch?search=" +
        encodeURIComponent(JSON.stringify(data));
    } else {
      url =
        "/api/CombinedItineraryReporting/Export/GetTravelerByFlightSearch?orgId=" +
        orgId +
        "&search=" +
        encodeURIComponent(JSON.stringify(data));
    }
    return Config.API1 + url;
  }
};

const getActiveTravelersByHotel = (orgId, userRole, data) => {
  let url;
  if (userRole === "Administrator") {
    url =
      "/api/ItineraryReporting/GetTravelerByActiveHotel?search=" +
      encodeURIComponent(JSON.stringify(data));
  } else {
    url =
      "/api/ItineraryReporting/GetTravelerByActiveHotel?orgId=" +
      orgId +
      "&search=" +
      encodeURIComponent(JSON.stringify(data));
  }
  return Axios.get(url, undefined, undefined);
};

const getActiveTravelersByHotelCsv = (orgId, userRole, data) => {
  let url;
  if (userRole === "Administrator") {
    url =
      "/api/ItineraryReporting/Export/GetTravelerByActiveHotel?search=" +
      encodeURIComponent(JSON.stringify(data));
  } else {
    url =
      "/api/ItineraryReporting/Export/GetTravelerByActiveHotel?orgId=" +
      orgId +
      "&search=" +
      encodeURIComponent(JSON.stringify(data));
  }
  return Config.API1 + url;
};

const getEmployeeOnSameFlight = (orgId, userRole, data) => {
  let url;
  if (userRole === "Administrator") {
    url =
      "/api/ItineraryReporting/GetTravelersOnSameFlight?search=" +
      encodeURIComponent(JSON.stringify(data));
  } else {
    url =
      "/api/ItineraryReporting/GetTravelersOnSameFlight?orgId=" +
      orgId +
      "&search=" +
      encodeURIComponent(JSON.stringify(data));
  }
  return Axios.get(url, undefined, undefined);
};

const getManualEmployeeOnSameFlight = (orgId, userRole, data) => {
  let url;
  if (userRole === "Administrator") {
    url =
      "/api/ManualItineraryReporting/GetTravelersOnSameFlight?search=" +
      encodeURIComponent(JSON.stringify(data));
  } else {
    url =
      "/api/ManualItineraryReporting/GetTravelersOnSameFlight?orgId=" +
      orgId +
      "&search=" +
      encodeURIComponent(JSON.stringify(data));
  }
  return Axios.get(url, undefined, undefined);
};

const getManualEmployeeOnSameFlightCsv = (orgId, userRole, data) => {
  let url;
  if (userRole === "Administrator") {
    url =
      "/api/ManualItineraryReporting/Export/GetTravelersOnSameFlight?search=" +
      encodeURIComponent(JSON.stringify(data));
  } else {
    url =
      "/api/ManualItineraryReporting/Export/GetTravelersOnSameFlight?orgId=" +
      orgId +
      "&search=" +
      encodeURIComponent(JSON.stringify(data));
  }
  return Config.API1 + url;
};

const getMagnatechEmployeeOnSameFlightCsv = (type, orgId, userRole, data) => {
  let url;
  if (type == "magnatech") {
    if (userRole === "Administrator") {
      url =
        "/api/ItineraryReporting/Export/GetTravelersOnSameFlight?search=" +
        encodeURIComponent(JSON.stringify(data));
    } else {
      url =
        "/api/ItineraryReporting/Export/GetTravelersOnSameFlight?orgId=" +
        orgId +
        "&search=" +
        encodeURIComponent(JSON.stringify(data));
    }
    return Config.API1 + url;
  } else {
    if (userRole === "Administrator") {
      url =
        "/api/CombinedItineraryReporting/Export/GetTravelersOnSameFlight?search=" +
        encodeURIComponent(JSON.stringify(data));
    } else {
      url =
        "/api/CombinedItineraryReporting/Export/GetTravelersOnSameFlight?orgId=" +
        orgId +
        "&search=" +
        encodeURIComponent(JSON.stringify(data));
    }
    return Config.API1 + url;
  }
};

const getTravellersByRiskLevel = (orgId, userRole, data) => {
  let url;
  if (userRole === "Administrator") {
    url =
      "/api/ItineraryReporting/GetTravelersByRiskAlert?search=" +
      encodeURIComponent(JSON.stringify(data));
  } else {
    url =
      "/api/ItineraryReporting/GetTravelersByRiskAlert?orgId=" +
      orgId +
      "&search=" +
      encodeURIComponent(JSON.stringify(data));
  }
  return Axios.get(url, undefined, undefined);
};

const getActiveManualTravelersByRiskLevel = (orgId, userRole, data) => {
  let url;
  if (userRole === "Administrator") {
    url =
      "/api/ManualItineraryReporting/GetTravelersByRiskAlert?search=" +
      encodeURIComponent(JSON.stringify(data));
  } else {
    url =
      "/api/ManualItineraryReporting/GetTravelersByRiskAlert?orgId=" +
      orgId +
      "&search=" +
      encodeURIComponent(JSON.stringify(data));
  }
  return Axios.get(url, undefined, undefined);
};

const getActiveManualTravelersByRiskLevelCSV = (orgId, userRole, data) => {
  let url;
  if (userRole === "Administrator") {
    url =
      "/api/ManualItineraryReporting/Export/GetTravelersByRiskAlert?search=" +
      encodeURIComponent(JSON.stringify(data));
  } else {
    url =
      "/api/ManualItineraryReporting/Export/GetTravelersByRiskAlert?orgId=" +
      orgId +
      "&search=" +
      encodeURIComponent(JSON.stringify(data));
  }
  return Config.API1 + url;
};

const getActiveMagnatechTravelersByRiskLevelCSV = (
  type,
  orgId,
  userRole,
  data
) => {
  let url;
  if (type == "magnatech") {
    if (userRole === "Administrator") {
      url =
        "/api/ItineraryReporting/Export/GetTravelersByRiskAlert?search=" +
        encodeURIComponent(JSON.stringify(data));
    } else {
      url =
        "/api/ItineraryReporting/Export/GetTravelersByRiskAlert?orgId=" +
        orgId +
        "&search=" +
        encodeURIComponent(JSON.stringify(data));
    }
    return Config.API1 + url;
  } else {
    if (userRole === "Administrator") {
      url =
        "/api/CombinedItineraryReporting/Export/GetTravelersByRiskAlert?search=" +
        encodeURIComponent(JSON.stringify(data));
    } else {
      url =
        "/api/CombinedItineraryReporting/Export/GetTravelersByRiskAlert?orgId=" +
        orgId +
        "&search=" +
        encodeURIComponent(JSON.stringify(data));
    }
    return Config.API1 + url;
  }
};

const getActiveAlertList = (orgId, userRole, data) => {
  let url;
  if (userRole === "Administrator") {
    url =
      "/api/ItineraryReporting/GetAffectedUser?search=" +
      encodeURIComponent(JSON.stringify(data));
  } else {
    url =
      "/api/ItineraryReporting/GetAffectedUser?orgId=" +
      orgId +
      "&search=" +
      encodeURIComponent(JSON.stringify(data));
  }
  return Axios.get(url, undefined, undefined);
};

const getAffectedTravelersByAlerts = (orgId, userRole, data) => {
  let url;
  if (userRole === "Administrator") {
    url =
      "/api/ItineraryReporting/GetAffectedUserListByRiskLineAlert?search=" +
      encodeURIComponent(JSON.stringify(data));
  } else {
    url =
      "/api/ItineraryReporting/GetAffectedUserListByRiskLineAlert?orgId=" +
      orgId +
      "&search=" +
      encodeURIComponent(JSON.stringify(data));
  }
  return Axios.get(url, undefined, undefined);
};

const getAffectedTravelersByAlertsCsv = (orgId, userRole, data) => {
  let url;
  if (userRole === "Administrator") {
    url =
      "/api/ItineraryReporting/Export/GetAffectedUserListByRiskLineAlert?search=" +
      encodeURIComponent(JSON.stringify(data));
  } else {
    url =
      "/api/ItineraryReporting/Export/GetAffectedUserListByRiskLineAlert?orgId=" +
      orgId +
      "&search=" +
      encodeURIComponent(JSON.stringify(data));
  }
  return Config.API1 + url;
};

export default {
  getActiveTravelers,
  getMagnatechTravelersCsv,
  getWhereAreMyTravelers,
  getTravelersByDestandTime,
  getTravelersByFlightType,
  getManualTravelersByFlightType,
  getManualTravelersByFlightTypeCsv,
  getMagnatechTravelersByFlightTypeCsv,
  getTravelersByFlightSegment,
  getManualTravelersByFlightSegment,
  getManualTravelersByFlightSegmentCsv,
  getMagnatechTravelersByFlightSegmentCsv,
  getTravelersByActiveCar,
  getTravelersByActiveCarCsv,
  getTravelersByAirport,
  getManualTravelersByAirport,
  getManualTravelersByAirportCsv,
  getMagnatechTravelersByAirportCsv,
  getTravelersByArrivalAirport,
  getManualTravelersByArrivalAirport,
  getManualTravelersByArrivalAirportCsv,
  getMagnatechTravelersByArrivalAirportCsv,
  getTravelersByDepatureAirport,
  getManualTravelersByDepatureAirport,
  getManualTravelersByDepatureAirportCsv,
  getMagnatechTravelersByDepatureAirportCsv,
  getTravellersByFlightNumber,
  getManualTravellersByFlightNumber,
  getManualTravellersByFlightNumberCsv,
  getMagnatechTravellersByFlightNumberCsv,
  getActiveTravelersByHotel,
  getActiveTravelersByHotelCsv,
  getEmployeeOnSameFlight,
  getManualEmployeeOnSameFlight,
  getManualEmployeeOnSameFlightCsv,
  getMagnatechEmployeeOnSameFlightCsv,
  getTravellersByRiskLevel,
  getActiveAlertList,
  getAffectedTravelersByAlerts,
  getAffectedTravelersByAlertsCsv,
  getActiveManualTravelers,
  getManualTravelersCsv,
  getManualWhereAreMyTravelers,
  getManualWhereAreMyTravelersCsv,
  getMagnatechWhereAreMyTravelersCsv,
  getActiveManualTravelersByRiskLevel,
  getActiveManualTravelersByRiskLevelCSV,
  getActiveMagnatechTravelersByRiskLevelCSV,
  getManualTravelersByDestandTime,
  getManualTravelersByDestandTimeCsv,
  getMagnatechTravelersByDestandTimeCsv,
  getTravellersByPnrNumber,
  getUnmappedTravelersByDestandTimeCsv,
};
