import API from "../api/invoice-api";

export function getInvoices(orgId, { filters, pagination, sorter }) {
  return async function (dispatch, getState) {
    try {
      let resp = await API.getInvoiceData(orgId, {
        filters: filters,
        pagination: pagination,
        sorter: sorter
      });//console.log(resp);
      return resp;

    } catch (e) {
      return { "error": true };
    }
  };
}
export function updateInvoice(data) {
  return async function (dispatch, getState) {
    try {
      let resp = await API.updateInvoiceStatus(data)
      return resp;

    } catch (e) {
      return { "error": true };
    }
  };
}
export function getInvoice(invoiceId) {
  return async function (_dispatch, _getState) {
    try {
      let resp = await API.getInvoiceDetails(invoiceId);
      return resp;
    } catch (e) {
      return { "error": true };
    }
  };
}