import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Checkbox } from "antd";

class UserMapRiskLevel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false
    };
  }
  async componentDidMount() {}

  update = e => {
    this.props.onChangeRiskLevelCheckBox(e);
  };

  onCheckUncheckRiskLevel = e => {
    var riskLevels = this.props.shownRiskLevelsArray;
    if (!e.target.checked && e.target.name) {
      var filteredLevels = riskLevels.filter(
        level => !e.target.name.includes(level)
      );
      this.props.onCheckUncheckRiskLevel(
        filteredLevels,
        e.target.checked,
        e.target.name
      );
    } else {
      riskLevels.push(e.target.name);
      this.props.onCheckUncheckRiskLevel(
        riskLevels,
        e.target.checked,
        e.target.name
      );
    }
  };

  render() {
    return (
      <div>
        <ul className="risklevel-check">
          <li>
            <Checkbox
              name="risklevel"
              checked={this.props.isRiskLevel}
              onChange={this.update}
            />
          </li>
          <li>
            <span>Show/Hide all Risk Levels</span>
          </li>
        </ul>
        <hr style={{ marginTop: "5px" }} />
        <ul className="risklevel-check">
          <li>
            <Checkbox
              checked={this.props.isLowRiskLevelsChecked}
              onChange={this.onCheckUncheckRiskLevel}
              name="Low"
            ></Checkbox>
          </li>
          <li>
            <span>Low</span>
          </li>
        </ul>
        <ul className="risklevel-check">
          <li>
            <Checkbox
              checked={this.props.isModerateRiskLevelsChecked}
              onChange={this.onCheckUncheckRiskLevel}
              name="Moderate"
            ></Checkbox>
          </li>
          <li>
            <span>Moderate</span>
          </li>
        </ul>
        <ul className="risklevel-check">
          <li>
            <Checkbox
              checked={this.props.isMediumRiskLevelsChecked}
              onChange={this.onCheckUncheckRiskLevel}
              name="Medium"
            ></Checkbox>
          </li>
          <li>
            <span>Medium</span>
          </li>
        </ul>
        <ul className="risklevel-check">
          <li>
            <Checkbox
              checked={this.props.isHighRiskLevelsChecked}
              onChange={this.onCheckUncheckRiskLevel}
              name="High"
            ></Checkbox>
          </li>
          <li>
            <span>High</span>
          </li>
        </ul>
        <ul className="risklevel-check">
          <li>
            <Checkbox
              checked={this.props.isExtremeRiskLevelsChecked}
              onChange={this.onCheckUncheckRiskLevel}
              name="Extreme"
            ></Checkbox>
          </li>
          <li>
            <span>Extreme</span>
          </li>
        </ul>
      </div>
    );
  }
}

UserMapRiskLevel.propTypes = {};

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    UserMapRiskLevel
  )
);
