import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Wizard from "../shared/wizard/wizard.jsx";
import * as b2b2cActions from "../../../redux/actions/b2b2c-actions";
import * as userActions from "../../../redux/actions/user-actions";
import { WrappedRegistrationForm } from "./traveller-form";
import { Beforeunload } from "react-beforeunload";
import { currencyType } from "../../../components/constants";
import getSymbolFromCurrency from "currency-symbol-map";
import { cadConvertionRate } from "../../b2c/shared/cadConverstion";
import PriceCalculation from "./price-calculation.jsx";
import config from "../../../../../config.js";

class PersonalDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      totalPrice: props.planTerm ? props.planTerm.totalPrice : 0,
      appliedPromoText: "",
      currency: "USD",
      currencyRate: 1,
      agentName: "",
      traveller_count: 1,
      genderList: [],
    };
    this.handleBack = this.handleBack.bind(this);
  }

  getUrlDecodedParams() {
    let localDecodedParams = localStorage.getItem("decodedParams");
    if (
      typeof localDecodedParams !== "undefined" &&
      localDecodedParams !== null &&
      localDecodedParams !== ""
    ) {
      return JSON.parse(localDecodedParams);
    }
    var encodedParameters = this.props.location.search;
    var getEncodedStr = encodedParameters.substring(1).split("&");
    let patttern = /^([A-Za-z0-9+/]{4})*([A-Za-z0-9+/]{3}=|[A-Za-z0-9+/]{2}==)?$/;
    getEncodedStr.map((str) => {
      encodedParameters = str.match(patttern) ? str : "";
    });

    var decodedParameterString = atob(encodedParameters);
    var parameters = decodedParameterString.split("&");
    var parameterArray = new Map();

    parameters.forEach(async function(parameter) {
      var data = parameter.split("=");
      parameterArray[data[0]] = data[1];
    });
    var selectedGetCountries = parameterArray["countries"].split(",");
    var selectedGetCountriesStr = [];
    for (var i = 0; i < this.props.countryTierInfo.length; i++) {
      if (
        selectedGetCountries.indexOf(
          this.props.countryTierInfo[i].countryCode
        ) > -1
      ) {
        selectedGetCountriesStr.push(this.props.countryTierInfo[i].countryName);
      }
    }

    return {
      start_date: parameterArray["start_date"],
      end_date: parameterArray["end_date"],
      traveller_count: parseInt(parameterArray["traveller_count"]),
      countries: parameterArray["countries"],
      countries_list: selectedGetCountriesStr.join(", "),

      travel_type: parameterArray["travel_type"],
      travel_type_label: parameterArray["traveltypelabel"],
      currency_code: parameterArray["currency_code"],
    };
  }

  async componentDidMount() {
    localStorage.removeItem("traveller");
    this.setState({
      currency: this.props.currency,
      currencyRate: this.props.currencyRate,
    });
    window.scrollTo(0, 0);
    //to get atn no from url
    let params = new URLSearchParams(window.location.search);
    let atn = params.get("atn");
    await this.props.getAgentName(atn);
    await this.props.getAllCountries();
    await this.props.getPersonalInformation();
    if (this.props.planTerm && Object.keys(this.props.planTerm).length === 0) {
      await this.props.getStep();
      await this.props.getUserData();
      await this.props.getPlanInformation();
      await this.props.getPlanTermInformation();
      await this.props.getResellerProductPricingAndInfo();
      if (parseInt(this.props.step) < 2) {
        this.props.history.push(
          this.props.b2b2cBaseUrl +
            "/plan-term/" +
            this.props.match.params.orgId +
            window.location.search
        );
      } else {
        //Update price to base on refresh
        let planTermObject = this.props.planTerm;
        planTermObject.totalPrice =
          this.props.planTerm && this.props.planTerm.Price;
        await this.props.SavePlanTerm(planTermObject);
      }
    } else if (parseInt(this.props.step) < 2) {
      this.props.history.push(
        this.props.b2b2cBaseUrl +
          "/plan-term/" +
          this.props.match.params.orgId +
          window.location.search
      );
    } else {
      //Update price to base on refresh
      let planTermObject = this.props.planTerm;
      planTermObject.totalPrice = this.props.planTerm.Price;
      await this.props.SavePlanTerm(planTermObject);
    }
    this.props.getGendersList().then((response) => {
      this.setState({ genderList: response });
    });
  }

  async handleBack() {
    await this.props.SaveStep("2");
    this.props.history.push(
      this.props.b2b2cBaseUrl +
        "/plan-term/" +
        this.props.match.params.orgId +
        window.location.search
    );
  }

  onUnload = (e) => {
    localStorage.removeItem("qs");
    localStorage.removeItem("decodedParams");
    e.preventDefault();
    //this.props.flushOrderData();
    //this.props.ClearData();
  };

  updateTravellerCount = (count) => {
    this.props.travellerCount(count);
    let _this = this;
    // let _getParams = this.state.getParams;
    // _getParams.traveller_count = Math.max(1,count);
    // this.setState({getParams: _getParams}, () => {
    //   localStorage.setItem("decodedParams", JSON.stringify(_getParams));
    //  // _this.onChangeAnnualCB(_this.props.planTerm.isAnnual);
    // });
    this.setState({ traveller_count: count });
    //console.log("traveller" ,  count)
  };

  render() {
    let cadPrice = cadConvertionRate(
      this.state.totalPrice / this.state.traveller_count
    );
    return (
      <section id="banner" className="b2b2c-personal-details">
        <Beforeunload onBeforeunload={(e) => this.onUnload(e)}>
          <Wizard wizardActiveClass="step3" />

          {this.props.countryTierInfo && this.props.countryTierInfo.length > 0 && this.props.planType ? (
            <div>
              <div
                className="wrap900 price-sticky-head"
                style={{
                  display: "block",
                  maxWidth: "1300px",
                  zIndex: "100",
                  position: "sticky",
                  fontSize: "18px",
                  fontWeight: "bold",
                  padding: "16px"
                }}
              >
                {this.props.agentName && this.props.match.params.orgId == config.agentCode
                  ? "Agent : " + this.props.agentName
                  : ""}
                <br />
              </div>
              <div
                className="wrap900 price-sticky-head"
                style={{
                  display: "block",
                  maxWidth: "1300px",
                  zIndex: "100",
                  position: "sticky"
                }}
              >
                <div className="title" style={{ background: "#FFF" }}>
                  <PriceCalculation planTerm={this.props.planTerm}/>
                </div>
              </div>
              {this.props.planType && this.props.planType.productId && parseInt(this.props.step) >= 2 ? (
                <WrappedRegistrationForm
                  planTerm={this.props.planTerm}
                  SaveStep={this.props.SaveStep}
                  SavePlanTerm={this.props.SavePlanTerm}
                  genderlist={this.state.genderList}
                  //updatePrice={this.updatePrice}
                  updateTravellerCount={this.updateTravellerCount}
                  SavePersonalDetails={this.props.SavePersonalDetails}
                  SaveBuyerEmail={this.props.SaveBuyerEmail}
                  handleBack={this.handleBack}
                  history={this.props.history}
                  productPricing={this.props.productPricing}
                  productInfo={this.props.productInfo}
                  planType={this.props.planType}
                  productPriceList={this.props.productPriceList}
                  personalDetails={this.props.personalDetails}
                  validateTravellerEmail={this.props.validateTravellerEmail}
                  emailCheckResponse={this.props.emailCheckResponse}
                  flushEmailCheckResponse={this.props.flushEmailCheckResponse}
                  userData={this.props.userData}
                  travellerInfo={this.props.travellerInfo}
                  getTravellerInfo={this.props.getTravellerInfo}
                  isLoggedIn={this.props.isLoggedIn}
                  countryTierInfo={this.props.allCountries}
                  location={this.props.location}
                  b2b2cBaseUrl={this.props.b2b2cBaseUrl}
                  match={this.props.match}
                  resellerOrganizationInfo={this.props.resellerOrganizationInfo}
                  getAllCountries={this.props.getAllCountries}
                  allCountries={this.props.allCountries}
                  currency={this.props.currency}
                  currencyRate={this.props.currencyRate}
                />
              ) : (
                ""
              )}
            </div>
          ) : (
            ""
          )}
        </Beforeunload>
      </section>
    );
  }
}
PersonalDetails.propTypes = {
  getResellerProductPricingAndInfo: PropTypes.func,
  productPricing: PropTypes.array,
  getPersonalInformation: PropTypes.func,
  getPlanInformation: PropTypes.func,
  getPlanTermInformation: PropTypes.func,
  SaveStep: PropTypes.func,
  SavePersonalDetails: PropTypes.func,
  SaveBuyerEmail: PropTypes.func,
  step: PropTypes.string,
  SavePlanTerm: PropTypes.func,
  planTerm: PropTypes.object,
  productInfo: PropTypes.array,
  planType: PropTypes.object,
  productPriceList: PropTypes.array,
  personalDetails: PropTypes.array,
  validateTravellerEmail: PropTypes.func,
  emailCheckResponse: PropTypes.object,
  flushEmailCheckResponse: PropTypes.func,
  getUserData: PropTypes.func,
  userData: PropTypes.object,
  getTravellerInfo: PropTypes.func,
  getAllCountries: PropTypes.func,
  travellerInfo: PropTypes.object,
  isLoggedIn: PropTypes.bool,
  location: PropTypes.object,
  history: PropTypes.object,
  getAppliedPromo: PropTypes.func,
  couponInfo: PropTypes.object,
  getStep: PropTypes.func,
  b2b2cBaseUrl: PropTypes.string,
};
function mapStateToProps(state) {
  return {
    ...state.b2b2c,
    ...state.user,
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...b2b2cActions, ...userActions }, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(PersonalDetails);
