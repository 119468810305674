import Axios from "../../services/axios-service";
const getEmailTemplates = data => {
  let _orgId = data.orgId;
  delete data.orgId;
  return Axios.get("/api/EmailTemplates/" +_orgId+ "?search="+encodeURIComponent(JSON.stringify(data)), undefined)
  .then(res => {
      return res;
    })
    .catch(ex => {
      return ex;
    });
};
const getEmailTemplate = (orgId, templateId) => {
  return Axios.get(`/api/EmailTemplates/${orgId}/${templateId}`, undefined, undefined);
};
const updateEmailTemplate = (templateId, data) => {
  return Axios.put(`/api/EmailTemplates/${templateId}`, data, undefined);
};
const addEmailTemplate = data => {
  return Axios.post("/api/EmailTemplates", data, undefined);
};
export default {
  getEmailTemplates,
  getEmailTemplate,
  updateEmailTemplate,
  addEmailTemplate
};