import API from "../api/itinerary-api";

export function getItinerary(data) {
  return async function (dispatch, getState) {
    try {
      let resp = await API.getItinerary(data)

      return resp;

    } catch (e) {
      return { "error": true };
    }
  };
}
export function getManualItinerary(data) {
  return async function (dispatch, getState) {
    try {
      let resp = await API.getManualItinerary(data)

      return resp;

    } catch (e) {
      return { "error": true };
    }
  };
}
export function addItineraryById(data) {
  return async function (dispatch, getState) {
    try {
      let resp = await API.addItinerary(data)
      return resp;

    } catch (e) {
      return { "error": true };
    }
  };
}
export function saveManualItinerary(data) {
  return async function (dispatch, getState) {
    try {
      let resp = await API.saveManualItinerary(data)
      return resp;

    } catch (e) {
      return { "error": true };
    }
  };
}
export function updateItinerarybtId(idx, data) {
  return async function (dispatch, getState) {
    try {
      let resp = await API.updateItineraryById(idx, data)
      return resp;

    } catch (e) {
      return { "error": true };
    }
  };
}
export function updateManualItineraryById(idx, data) {
  return async function (dispatch, getState) {
    try {
      let resp = await API.updateManualItinerarybyId(idx, data)
      return resp;

    } catch (e) {
      return { "error": true };
    }
  };
}
export function getAirportCodesData(data) {
  return async function (dispatch, getState) {
    try {
      let resp = await API.getAirportCodes(data)
      return resp;

    } catch (e) {
      return { "error": true };
    }
  };
}
export function getItineraryByIdx(data) {
  return async function (dispatch, getState) {
    try {
      let resp = await API.getItineraryById(data)
      return resp;

    } catch (e) {
      return { "error": true };
    }
  };
}
export function getManualItineraryByIdx(data) {
  return async function (dispatch, getState) {
    try {
      let resp = await API.getManualItineraryById(data)
      return resp;

    } catch (e) {
      return { "error": true };
    }
  };
}
export function getItineraryByIdxForAdmin(id, data) {
  return async function (dispatch, getState) {
    try {
      let resp = await API.getItineraryAdmin(id, data)
      return resp;

    } catch (e) {
      return { "error": true };
    }
  };
}
export function getManualItineraryByIdxForAdmin(id, data) {
  return async function (dispatch, getState) {
    try {
      let resp = await API.getManualItineraryAdmin(id, data)
      return resp;

    } catch (e) {
      return { "error": true };
    }
  };
}
export function getFlightInformation(AirlineCode, FlightNumber, date) {
  return async function (dispatch, getState) {
    try {
      let resp = await API.getItineraryByFlightNumber(AirlineCode,FlightNumber,date)
      return resp;

    } catch (e) {
      return { "error": true };
    }
  };
}

export function updateItineraryStatusById(data) {
  return async function (dispatch, getState) {
    try {
      let resp = await API.updateItineraryStatusById(data)
      return resp;

    } catch (e) {
      return { "error": true };
    }
  };
}
export function AddFlightSegment(id, data) {
  return async function (dispatch, getState) {
    let resp = await API.AddFlightSegment(id, data);
   // console.log(resp);
    return resp;
    
    // try {
    //    resp = await API.AddFlightSegment(id, data)
    //  // console.log(resp, "add");
    //   return resp; 
    // }
  
    // catch (err) {
     // debugger;
    //   console.log(resp, "resp");
    //  return err;
    // }
  };
}




