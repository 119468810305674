import React, { Component } from "react";
import { Select, Row, Col, Form, Spin } from "antd";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import IntlMessages from "../../../../services/intlMesseges";
import * as CustomAlertActions from "../../../../redux/actions/custom-alert-actions";
import { bindActionCreators } from "redux";
import config from "../../../../../../config";
import * as Permission from "../../../../services/permissions";
import moment from "moment";
class CustomAlertViewin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formLayout: undefined,
      orgData: [],
      loading: true,
    };
    this.downloadPdf = this.downloadPdf.bind(this);
  }
  componentDidMount() {
    this.props.CustomAlertDetail(this.props.match.params.id).then((data) => {
      this.setState(data.data);
      let response = data.data;
      let organizations = [];
      let employees = [];
      if (response.alertRecipients && response.alertRecipients.length > 0) {
        let allSelected = response.alertRecipients.findIndex(
          (e) => e.recipientOrgId === 0 && e.recipientUserId === 0
        );
        if (allSelected > -1) {
          organizations.push("All");
          employees.push("All");
        } else {
          let allOrgSelected = response.alertRecipients.findIndex(
            (e) => e.recipientOrgId === 0 && e.recipientUserId === -1
          );
          let allEmployeesSelected = response.alertRecipients.findIndex(
            (e) => e.recipientOrgId === -1 && e.recipientUserId === 0
          );
          if (allOrgSelected > -1) {
            organizations.push("All");
          }
          if (allEmployeesSelected > -1) {
            employees.push("All");
          }
          response.alertRecipients.map(function(el) {
            if (el.recipientOrgId > 0 && el.recipientUserId > 0) {
              employees.push(el.employeeName + "<" + el.employeeEmailId + ">");
            } else if (el.recipientOrgId > 0 && el.recipientUserId === 0) {
              organizations.push(el.organizationName);
            }
          });
        }
      }
      if (employees.length === 0) {
        employees.push("None");
      }
      if (organizations.length === 0) {
        organizations.push("None");
      }
      this.setState({
        empData: employees,
        receptdata: organizations,
      });

      /* if (data.data.alertRecipients && data.data.alertRecipients.length >0 && 
        data.data.alertRecipients && data.data.alertRecipients.length>0 &&
        data.data.alertRecipients[0].recipientOrgId === 0 && data.data.alertRecipients[0].recipientUserId === 0) {
        this.setState({ receptdata: ['All'] });
        this.setState({ empData: ['All'] })
      } else {
        for (var i = 0; i < data.data.alertRecipients.length; i++) {
          if (data.data.alertRecipients[i].organizationName) {
            if (!orgData.includes(data.data.alertRecipients[i].organizationName)) {
              orgData.push(data.data.alertRecipients[i].organizationName)
            }
          }
          this.setState({ receptdata: orgData })
          if (data.data.alertRecipients[i].recipientOrgId === 0 && data.data.alertRecipients[i].recipientUserId === -1) {
            this.setState({ receptdata: ['All'] });

          }
          if (data.data.alertRecipients[i].recipientOrgId === -1 && data.data.alertRecipients[i].recipientUserId === 0) {
            this.setState({ empData: ['All'] });

          }

        }
        for (var i = 0; i < data.data.alertRecipients.length; i++) {

          if (!empData.includes(data.data.alertRecipients[i].employeeName)) {
            if (data.data.alertRecipients[i].employeeName) {
              empData.push(data.data.alertRecipients[i].employeeName + '<' + data.data.alertRecipients[i].employeeEmailId + '>')

            }
            if (data.data.alertRecipients[i].recipientOrgId === 0 && data.data.alertRecipients[i].recipientUserId === -1) {
              this.setState({ receptdata: ['All'] });

            }
            if (data.data.alertRecipients[i].recipientOrgId === -1 && data.data.alertRecipients[i].recipientUserId === 0) {
              this.setState({ empData: ['All'] });

            }
          }
        }
      } */
      this.setState({ loading: false });
    });
  }
  downloadPdf() {
    window.location.href =
      config.API1 + "/customalertdocuments/" + this.state.fileName;
  }
  getHeaderKeys = () => {
    return [
      {
        title: (
          <IntlMessages id="admin.org.licensehistory.username"></IntlMessages>
        ),
        dataIndex: "employeeName",
        render: (data, record) => (
          <spin>
            {record.recipientOrgId === 0 && record.recipientUserId === 0
              ? "All Employees"
              : record.recipientUserId === 0
              ? "All Employees"
              : record.employeeName}
          </spin>
        ),
      },

      {
        title: <IntlMessages id="admin.organiztion.orgname"></IntlMessages>,
        render: (data, record) => (
          <spin>
            {record.recipientOrgId === 0 && record.recipientUserId === 0
              ? "All Organization"
              : record.recipientOrgId === 0
              ? "All Organisations"
              : record.organizationName}
          </spin>
        ),
      },
      // ? <Tag color="green">Active</Tag> : <Tag color="red">Inactive</Tag>}
    ];
  };

  render() {
    let isAllOrgs = -1;
    if (this.state.alertRecipients && this.state.alertRecipients.length > 0) {
      isAllOrgs = this.state.alertRecipients.findIndex(
        (e) => e.recipientOrgId === 0 && e.recipientUserId === 0
      );
    }
    isAllOrgs = isAllOrgs > -1 ? true : false;
    return (
      <Spin spinning={this.state.loading}>
        <div>
          <Row gutter={24} style={{ marginBottom: "20px" }}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <label>
                <b>
                  <IntlMessages id="admin.customalert.name" />:
                </b>
              </label>
              <p>{this.state.alertName}</p>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <label>
                <b>
                  <IntlMessages id="admin.customalert.summary" />:
                </b>
              </label>
              <p>{this.state.summary}</p>
            </Col>
          </Row>
          <Row gutter={24} style={{ marginBottom: "20px" }}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <label>
                <b>
                  <IntlMessages id="admin.customalert.description" />:
                </b>
              </label>
              <p style={{wordWrap : "break-word"}}>
                {this.state.description &&
                this.state.description !== "undefined"
                  ? this.state.description
                  : ""}
              </p>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <label>
                <b>
                  {" "}
                  {this.state.fileName ? (
                    <IntlMessages id="admin.customalert.uploadedfile" />
                  ) : (
                    <IntlMessages id="admin.customalert.nouploadedfile" />
                  )}
                </b>
              </label>
              <div>
                <a
                  href={
                    config.API1 + "/customalertdocuments/" + this.state.fileName
                  }
                  target="_blank"
                >
                  {this.state.fileName}
                </a>

                {/* <Button type="primary" onClick={() => this.removeLogo()} ><IntlMessages id="admin.remove.logo" /></Button> */}
              </div>{" "}
            </Col>
          </Row>
          <Row gutter={24} style={{ marginBottom: "20px" }}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <label>
                <b>
                  <IntlMessages id="admin.customalert.sendalertto" />:
                </b>
              </label>
              <p>
                {this.state.isEmailNotification ? "Email" : ""}{" "}
                {this.state.isPushNotification ? "Push Notification" : ""}{" "}
                {this.state.isSMSNotification ? "SMS " : ""}
              </p>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <label>
                <b>
                  <IntlMessages id="admin.customalert.userscanreply" />:
                </b>
              </label>
              <p>{this.state.userCanReply ? "Yes" : "No"}</p>
            </Col>
          </Row>
          <Row gutter={24} style={{ marginBottom: "20px" }}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <label>
                <b>
                  <IntlMessages id="admin.manageOrders.createdDate" />:
                </b>
              </label>
              <p>{moment(this.state.insertDate).format("MM/DD/YYYY - hh:mm ")}</p>
            </Col>
          </Row>
          <Row>
            <label>
              <b>
                {" "}
                <IntlMessages id="admin.customalert.targetedfor" />:
              </b>
            </label>
          </Row>
          <Row gutter={24} style={{ marginBottom: "20px" }}>
            {isAllOrgs !== false ? (
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <label>
                  <b>
                    <IntlMessages id="admin.invoice.parentorganization" />:
                  </b>
                </label>
                <p>All</p>
              </Col>
            ) : (
              ""
            )}
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <label>
                <b>
                  <IntlMessages id="admin.invoice.organization/division" />:
                </b>
              </label>
              <Select
                mode="multiple"
                // size={size}
                disabled
                value={
                  this.state.receptdata &&
                  this.state.receptdata.length > 0 &&
                  this.state.receptdata !== undefined
                    ? this.state.receptdata
                    : undefined
                }
                // onChange={handleChange}
                style={{ width: "100%" }}
              >
                {/* {children} */}
              </Select>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <label>
                {Permission.isRole("Administrator") ||
                Permission.isRole("CSR") ? (
                  <b>
                    <IntlMessages id="admin.manageOrders.travellerNameb2c" />
                  </b>
                ) : (
                  <b>
                    <IntlMessages id="admin.manageOrders.travellerName" />:
                  </b>
                )}
              </label>
              <Select
                mode="multiple"
                // size={size}
                disabled
                value={
                  this.state.empData &&
                  this.state.empData !== undefined &&
                  this.state.empData.length > 0
                    ? this.state.empData
                    : undefined
                }
                style={{ width: "100%" }}
              >
                {/* {children} */}
              </Select>
            </Col>
          </Row>
          <div></div>,
        </div>
      </Spin>
    );
  }
}

function mapStateToProps(state) {
  return {
    ...state.user,
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...CustomAlertActions }, dispatch);
}
const WrappedForm = Form.create({ name: "Alert-view" })(CustomAlertViewin);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    WrappedForm
  )
);
