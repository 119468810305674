import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import * as itiniaryActions from "../../../redux/actions/itinerary-actions";
import * as userActions from "../../../redux/actions/user-actions";
import * as travellerActions from "../../../redux/actions/travellers-actions";
import * as orgActions from "../../../redux/actions/organization-actions";
import { connect } from "react-redux";
import IntlMessages from "../../../services/intlMesseges";
import { notifyUser } from "../../../services/notification-service";
import {
  Form,
  Input,
  Row,
  Col,
  Button,
  DatePicker,
  Typography,
  Select,
  Spin,
  Modal,
  Radio,
 Table
} from "antd";
import moment from "moment";
import OrgTree from "../../shared/components/orgTree";
import AppLocale from "../../../languageProvider";
import * as Permission from "../../../services/permissions";
const { RangePicker } = DatePicker;
const { Text, Title } = Typography;

const dateFormat = "MM/DD/YYYY  HH:mm:00";
const dateFormathh = "YYYY-MM-DD HH:mm:00";
const { Option } = Select;
let _airportData = [];

function range(start, end) {
  const result = [];
  for (let i = start; i < end; i++) {
    result.push(i);
  }
  return result;
}
function disabledDate(current) {
  // Can not select days before today and today
  return (
    current &&
    current <
      moment()
        .endOf("day")
        .subtract(1, "days")
  );
}



class UpdateManualItinerary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formLayout: undefined,
      fromDate: "",
      fromlocation: "",
      tolocation: "",
      todate: "",
      departuredate: "",
      flightnumber: "",
      isActive: true,
      userid: 0,
      loading: false,
      data: [],
      parentOrgData: {},
      TravellerData: [],
      pagination: { pageSize: 10, current: 1 },
      filters: {},
      airportData: [],
      stateuser: 1,
      itineraryData: {},
      travellerIternaryId: 0,
      searchtrue: false,
      flightno: "",
      departture: "",
      formexpired: false,
      resultsFound: false,
      disableSearchForm: false,
      manualItineraryData: { pnrNumber: "", userId: "", segmentData: [] },
      isAddFlightSegment: false,
      segmentIndex: -1,
      showRemovePopup: false,
      isAddFlightDisable: true,
     // isNewSegment : false,
     isindex : undefined,
     alwaysData:[],
     flightData: [],
     selectedFlight: null,
     selectedRow:'',
     selectField : false,
     visible: false,
     selectedFlightId: null,
     ident : "",
    };
    this.handleAddTraveller = this.handleAddTraveller.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.getdivision = this.getdivision.bind(this);
    this.getflightinformation = this.getflightinformation.bind(this);
    this.getAirportUpdate = this.getAirportUpdate.bind(this);
    this.disableDepartureTime = this.disableDepartureTime.bind(this);
    this.disabledRangeTime = this.disabledRangeTime.bind(this);
    this.removeFlightSegment = this.removeFlightSegment.bind(this);
    this.appendSegmentData = this.appendSegmentData.bind(this);
    this.appendSegmentDataEdit = this.appendSegmentDataEdit.bind(this);

    this.saveItinerary = this.saveItinerary.bind(this);
    this.updateFlightSegment = this.updateFlightSegment.bind(this);
  }
  
  

  validateFormButton = (field, data) => {
    const items = JSON.parse(localStorage.getItem("user"));

    const obj = { ...this.state.manualItineraryData, [field]: data };
    this.setState({ manualItineraryData: obj });
    if (items.role == "Membership" || items.role == "Employee" || items.role == "Limited Access") {
      this.setState({ isAddFlightDisable: false });
     
    } else {
      if (!!obj.userId && !!obj.pnrNumber) {
        this.setState({ isAddFlightDisable: false });
       
      }
    }
  };

  saveItinerary = (e) => {
    e.preventDefault();
    if (
      this.state.manualItineraryData.pnrNumber == null ||
      this.state.manualItineraryData.pnrNumber == ""
    ) {
      alert("Pnr field can't be blank!");
      return;
    }
    this.setState({ loading: true });
    this.props
      .saveManualItinerary(this.state.manualItineraryData)
      .then((resp) => {
        if (resp.data.message === "Itinerary added successfully.") {
          const { language } = this.props;
          const currentAppLocale = AppLocale[language.locale];
          //notifyUser(currentAppLocale.messages["admin.managedayitinerary.success"], "success");
          notifyUser(resp.data.message, "success");
          this.props.history.push(
            "../../../list/" +
              this.props.match.params.orgid +
              "/" +
              this.props.match.params.divid +
              "/"
          );
          this.setState({ loading: false });
        } else {
          notifyUser(resp.data.message, "error");
          this.setState({ loading: false });
        }
      });
  };

  updateItinerary = (e) => {
    this.setState({ loading: true });
    e.preventDefault();
    this.props
      .updateManualItineraryById(
        this.state.travellerIternaryId,
        this.state.manualItineraryData
      )
      .then((updateResp) => {

        if (
          updateResp.data.message === "Traveller Iternary updated successfully."
        ) {
          const { language } = this.props;
          const currentAppLocale = AppLocale[language.locale];
          notifyUser(
            currentAppLocale.messages["admin.managedayitinerary.updatesuccess"],
            "success"
          );
          // notifyUser("Traveler Itinerary updated successfully", "success");
          this.props.history.push(
            "../../../list/" +
              this.props.match.params.orgid +
              "/" +
              this.props.match.params.divid +
              "/"
          );
          this.setState({ loading: false });
        } else {
          notifyUser(updateResp.data.message, "error");
          this.setState({ loading: false });
        }
      });
  };

  toInputUppercase = (e) => {
    e.target.value = ("" + e.target.value).toUpperCase();
  };

  removeFlightSegment = (e) => {
    e.preventDefault();
    var manualItineraryData = this.state.manualItineraryData;
    if (this.state.isindex > -1) {
      manualItineraryData.segmentData.splice(this.state.isindex, 1);
    }
    this.setState({
      manualItineraryData: manualItineraryData,
      showRemovePopup: false,
    });
  };
  
  

  // Remove fight Segment modal
  HidePopup = () => {
    this.setState({ showRemovePopup: false });
  };

  showModal = (index) => {
    this.setState({
      showRemovePopup: true,
      isindex : index
    });
  };
  handleOk = () => {
    this.setState({ showRemovePopup: false });
  };
  updateFlightSegment = (e, index, update = null) => {
    e.preventDefault();
    if (
      this.state.manualItineraryData &&
      this.state.manualItineraryData.segmentData &&
      this.state.manualItineraryData.segmentData.length > 0
    ) {
      
      var segmentData = this.state.manualItineraryData.segmentData[index];
      var itineraryDataTemp = {};
      itineraryDataTemp["flightNumber"] = segmentData.flightNumber;
      itineraryDataTemp["airlineCode"] = segmentData.airlineCode;
      itineraryDataTemp["fromLocationId"] = segmentData.fromLocationId;
      itineraryDataTemp["toLocationId"] = segmentData.toLocationId;
      itineraryDataTemp["departureDate"] = segmentData.toDate;
      itineraryDataTemp["ident"] = segmentData.ident;
      //ident = segmentData.ident
     // itineraryDataTemp["fromLocationName"] = segmentData.fromLocationName;

      if (update == "update") {
        this.state.manualItineraryData.segmentData.splice(index, 1);
      }

      this.setState({
        disablefields: true,
        rangedate: [
          moment(segmentData.fromDate, "YYYY-MM-DD HH:mm:ss"),
          moment(segmentData.toDate, "YYYY-MM-DD HH:mm:ss"),
        ],
      });
      this.setState({
        itineraryData: itineraryDataTemp,
        segmentIndex: index,
        isAddFlightSegment: !this.state.isAddFlightSegment,
      });
    }
    if (this.props.match.params.id === "new") {
      this.setState({ formexpired: false });
      this.setState({ disableSearchForm: false });
    }
    else if(moment(segmentData.toDate, "YYYY-MM-DD HH:mm:ss") < moment()) {
      this.setState({ disableSearchForm: true });
    }
    else {
      this.setState({ formexpired: false });
      this.setState({ disableSearchForm: false });
      this.setState({ resultsFound: false });
    }
    
  };
  
  appendSegmentData = () => {
    this.setState({ isAddFlightSegment: !this.state.isAddFlightSegment });
    this.setState({ segmentIndex: -1 });
    this.setState({
      disablefields: true,
      itineraryData: {},
      rangedate: [
        moment(new Date(), "YYYY-MM-DD HH:mm:ss"),
        moment(new Date(), "YYYY-MM-DD HH:mm:ss"),
      ],
    });
  };
  
  appendSegmentDataEdit = () => {
  this.setState({ isAddFlightSegment: !this.state.isAddFlightSegment });
    this.setState({ segmentIndex: -1 });
     this.setState({
      disablefields: true,
      resultsFound : false,
      itineraryData: {},
      rangedate: [
        moment(new Date(), "YYYY-MM-DD HH:mm:ss"),
        moment(new Date(), "YYYY-MM-DD HH:mm:ss"),
      ],
      });
      // for expire update add new segment condition
    var segmentData = this.state.manualItineraryData.segmentData;
    if(moment(segmentData.toDate, "YYYY-MM-DD HH:mm:ss") < moment()) {
     this.setState({ disableSearchForm: true });
    }
    else {
      this.setState({ formexpired: false });
      this.setState({ disableSearchForm: false });
    }
    
  };
  onChange = async (selected) => {
    this.props.form.setFieldsValue({ travellerName: null });
    this.setState({ parentOrgId: selected, TravellerData: [] });
    // let travlerlistdata = await this.getTravellerData({
    //   filters: { orgId: selected },
    let data = {
      orgId: selected,
      filters: {},
      pagination: { page: 1, pageSize: 10 },
      sorter: {},
    };
    let travlerlistdata = await this.props.getUserListing(data);
    this.setState({ TravellerData: travlerlistdata.data });
  };

  // onChange   = async (selected) => {
  //   console.log(selected)
  //     this.props.form.setFieldsValue({ travellerName: null });
  //     this.setState({ parentOrgId: selected, TravellerData: [] });
  //     let travlerlistdata = await this.props.getTraveller({
  //       orgId: selected,
  //     //  orgId: selected,
  //       // filters: { isActiveMembership: true },
  //      } );
  //     console.log(travlerlistdata);
  //   };

  disableDepartureTime() {
    var depDate = this.props.form.getFieldValue("departuredate");
    if (depDate) {
      if (
        moment(new Date(depDate._d)).format("MM/DD/YYYY") ===
        moment(new Date()).format("MM/DD/YYYY")
      ) {
        if (depDate._d.getHours() === new Date().getHours()) {
          return {
            disabledHours: () => range(0, 24).slice(0, new Date().getHours()),
            disabledMinutes: () => range(0, new Date().getMinutes()),
            disabledSeconds: () => range(0, new Date().getSeconds()),
          };
        } else {
          return {
            disabledHours: () => range(0, 24).slice(0, new Date().getHours()),
          };
        }
      } else {
        return {};
      }
    } else {
      return {
        disabledHours: () => range(0, 24).slice(0, new Date().getHours()),
        disabledMinutes: () => range(0, new Date().getMinutes()),
        disabledSeconds: () => range(0, new Date().getSeconds()),
      };
    }
  }
  disabledRangeTime(a, type) {
    if (type === "start") {
      var depDate = this.props.form.getFieldValue("todate")
        ? this.props.form.getFieldValue("todate")[0]
        : "";
      if (depDate) {
        if (
          moment(new Date(depDate._d)).format("MM/DD/YYYY") ===
          moment(new Date()).format("MM/DD/YYYY")
        ) {
          if (depDate._d.getHours() === new Date().getHours()) {
            return {
              disabledHours: () => range(0, 24).slice(0, new Date().getHours()),
              disabledMinutes: () => range(0, new Date().getMinutes()),
              disabledSeconds: () => range(0, new Date().getSeconds()),
            };
          } else {
            return {
              disabledHours: () => range(0, 24).slice(0, new Date().getHours()),
            };
          }
        } else {
          return {};
        }
      } else {
        return {
          disabledHours: () => range(0, 24).slice(0, new Date().getHours()),
          disabledMinutes: () => range(0, new Date().getMinutes()),
          disabledSeconds: () => range(0, new Date().getSeconds()),
        };
      }
    }
  }
  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <div style={{ padding: 8 }}>
          <Input
            ref={(node) => {
              this.searchInput = node;
            }}
            value={selectedKeys}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? e.target.value.trimLeft() : "")
            }
            onPressEnter={() =>
              this.handleSearch(
                selectedKeys,
                confirm,
                dataIndex,
                clearFilters,
                setSelectedKeys
              )
            }
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Button
            type="primary"
            onClick={() =>
              this.handleSearch(
                selectedKeys,
                confirm,
                dataIndex,
                clearFilters,
                setSelectedKeys
              )
            }
            icon="search"
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            <IntlMessages id="admin.search" />
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters, dataIndex)}
            size="small"
            style={{ width: 90 }}
          >
            <IntlMessages id="admin.reset" />
          </Button>
        </div>
      );
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
  });

  async componentDidMount() {
    if (
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.id &&
      this.props.match.params.id !== "new"
    ) {
      this.props
        .getManualItineraryByIdx(this.props.match.params.id)
        .then((data) => {
          this.setState({ itineraryData: data.data });
          if (
            data.data.segmentData !== null &&
            data.data.segmentData.length > 0
          ) {
            this.setState({ resultsFound: true });
          }

          if (data.data.segmentData && data.data.segmentData.length > 0) {
            this.setState({ manualItineraryData: data.data });
          }
          this.setState({ travellerIternaryId: data.data.manualItineraryId });
          this.setState({
            disablefields: true,
            rangedate: [
              moment(data.data.fromDate, "YYYY-MM-DD HH:mm:ss"),
              moment(data.data.toDate, "YYYY-MM-DD HH:mm:ss"),
            ],
          });

          if (data.data && new Date(data.data.toDate) < new Date()) {
            this.setState({ formexpired: true });
          }
          if (data.data.travellerName && data.data.userId) {
            this.setState({
              TravellerData: [
                {
                  firstName: data.data.travellerName,
                  userId: data.data.userId,
                  lastName: "",
                },
              ],
            });
          }

          this.state.manualItineraryData.segmentData.map((segment, index) => {
            if (
              !_airportData.includes(
                {
                  displayName: segment.fromLocationName,
                  airportID: segment.fromLocationId,
                } || {
                  displayName: segment.toLocationName,
                  airportID: segment.toLocationId,
                }
              )
            ) {
              _airportData.push({
                displayName: segment.fromLocationName,
                airportID: segment.fromLocationId,
              });
              _airportData.push({
                displayName: segment.toLocationName,
                airportID: segment.toLocationId,
              });
            }
          });

          let _userData = [];
          _userData.push({
            userId: data.data.userId,
            displayName: data.data.travellerName,
          });
          _userData.push({
            userId: data.data.userId,
            displayName: data.data.travellerName,
          });
          this.setState({ userData: _userData });
        });
    }

    if (
      Permission.isRole("Admin") ||
      Permission.isRole("Travel Manager") ||
      Permission.isRole("Administrator") ||
      Permission.isRole("Division Manager") ||
      Permission.isRole("CSR")
    ) {
      this.getdivision({ orgId: this.props.match.params.orgid });
      if (
        this.props.match &&
        this.props.match.params &&
        this.props.match.params.orgid &&
        this.props.match.params.orgid !== "new"
      ) {
        if (this.props.match.params.orgid)
          this.props
            .getOrganizationId(this.props.match.params.orgid)
            .then(async (resp) => {
              this.setState({ orgName: resp.data.name });
            });
        this.getTravellerData({
          orgId: this.props.match.params.orgid,
          // filters: { isActiveMembership: true },
        });
      }
    }
    this.getairportData({});
  }
  getAirportUpdate() {
    this.getairportData({});
  }
  getdivision(data) {
    this.props
      .getDivisions(data)
      .then((resp) => {
        // pager.total = resp.paging.totalRecords;
        this.setState({
          loading: false,
          parentOrgData: resp.data,
          // pagination: pager
        });
      })
      .catch((_ex) => {
        this.setState({ loading: false });
      });
  }
  getTravellerData(params) {
    if (
      this.props.match &&
      this.props.match.params &&
      parseInt(this.props.match.params.orgid) === 1
    ) {
      this.props
        .getTravellersListing(params)
        .then((resp) => {
          this.setState({
            TravellerData: resp.data,
          });
        })
        .catch((ex) => {
          this.setState({ loading: false });
        });
    } else if (
      this.props.match &&
      this.props.match.params &&
      parseInt(this.props.match.params.orgid) > 1
    ) {
      let data = {
        // orgId: /* this.props.match.params.orgid */ this.props.form.getFieldValue('parent'),
        orgId: this.props.form.getFieldValue("parent")
          ? this.props.form.getFieldValue("parent")
          : this.props.match.params.orgid,
        filters: {},
        pagination: { page: 1, pageSize: 10 },
        sorter: {},
      };
      this.props
        .getUserListing(data)
        .then((resp) => {
          this.setState({
            TravellerData: resp.data,
          });
        })
        .catch((ex) => {
          this.setState({ loading: false });
        });
    } else {
    }
  }
  async getairportData(params, value) {
    var info = [];
    await this.props.getAirportCodesData(params).then((data) => {
      if (value === 1) {
        info = [];
        info = this.state.airportData;
        this.setState({ airportData: [...data.data,...this.state.alwaysData] });
      } else if (value === 2) {
        info = [];
        if (data.data && data.data.length > 0) {
          let _da1 = data.data;
          let _da2 = this.state.airportData;
          let da3 = [];
          if (this.state.airportData && this.state.airportData.length > 0) {
            da3 = _da2.concat(_da1);
          }
          this.setState({ airportData: [...da3,...this.state.alwaysData] });
        }
      } else {
        info = [...this.state.alwaysData];
        info = [...info,...data.data];
        if (_airportData.length > 0) {
          info.concat(_airportData);
          this.setState({ airportData: info.concat(_airportData) });
        } else {
          this.setState({ airportData: info });
        }
      }
    });
  }
  getdata() {
    this.props
      .getItinerary(parseInt(this.props.userData.userID))
      .then((resp) => {
        this.setState({ data: resp.data });
      });
  }
  handleAddTraveller() {
   this.props.form.validateFields(
      ["fromlocation", "tolocation", "todate", "travellerName", "pnrNumber"],
      (err, values) => {
        let _idTraveller;
        if (values.travellerName) {
          _idTraveller = values.travellerName;
        } else {
          _idTraveller = this.props.userData.userID;
        }
         if (!err) {
          this.setState({ loading: true });
         
        
         var data = {
            userId: _idTraveller,
            fromLocationId: parseInt(values.fromlocation),
            fromDate: moment(values.todate[0]).format(dateFormathh),
            toLocationId: parseInt(values.tolocation),
            toDate: moment(values.todate[1]).format(dateFormathh),
            departureDate: moment(values.departuredate).format(dateFormathh),
            flightNumber: this.props.form.getFieldValue("flightnumber"),
            airlineCode: this.props.form.getFieldValue("airlineCode"),
            isActive: true,
            ident :this.state.ident
          };
           if (parseInt(values.fromlocation) !== parseInt(values.tolocation)) {
            if (this.state.travellerIternaryId === 0) {
              this.state.manualItineraryData.pnrNumber = values.pnrNumber;
              this.state.manualItineraryData.userId = _idTraveller;
              this.state.manualItineraryData.segmentData.push(data);
              this.resetFlightInformationForm();
              this.setState({
                isAddFlightSegment: !this.state.isAddFlightSegment,
              });
              this.setState({ loading: false });
            } else {
              if (
                this.state.manualItineraryData &&
                this.state.manualItineraryData.segmentData &&
                this.state.manualItineraryData.segmentData.length > 0
              )
                if (
                  this.state.segmentIndex !== -1 &&
                  this.state.manualItineraryData.segmentData[
                    this.state.segmentIndex
                  ]
                ) {
                  var segmentData = this.state.manualItineraryData.segmentData[
                    this.state.segmentIndex
                  ];
                  segmentData.fromLocationId = data.fromLocationId;
                  segmentData.toLocationId = data.toLocationId;
                  segmentData.toDate = data.toDate;
                  segmentData.fromDate = data.fromDate;
                  segmentData.departureDate = data.departureDate;
                  segmentData.flightNumber = data.flightNumber;
                  segmentData.airlineCode = data.airlineCode;
                  segmentData.isActive = true;
                } else {
              // added new flight segment
               this.props
              .AddFlightSegment(this.state.travellerIternaryId, {segmentData: [data]})
              .then((response) => { 
                if (response.status === 400) {
                  notifyUser(response.message, "error");
                } else {
                  this.state.manualItineraryData.segmentData.push(data);
                  this.setState({ loading: true });
                  notifyUser(response.data.message, "success");
                  this.setState({ loading: false });
                }
                
                }).catch(error => {
                  this.setState({
                    isAddFlightSegment: {},
                  });
                 
                
                });
              }
            }
            this.resetFlightInformationForm();
            this.setState({
              isAddFlightSegment: !this.state.isAddFlightSegment,
            });
            this.setState({ loading: false });
          } else {
            notifyUser(
              "From Location and To Location cannot be same!",
              "error"
            );
            this.setState({ loading: false });
          }
        }
       
      }
    );
  }

  onSearch(value, type) {
    this.setState({ searchairport: value });
    this.getairportData(
      {
        orgId: 1,
        filters: { displayName: value },
        pagination: { page: 1, pageSize: 10 },
      },
      1
    );
    
    
  }
  loadAccountManagers = (e) => {
    var _search;
    if (this.state.searchairport) {
      _search = { displayName: this.state.searchairport };
    } else {
      _search = {};
    }
    if (e.target.scrollTop + e.target.offsetHeight >= e.target.scrollHeight) {
      var datas = this.state.stateuser + 1;
      this.getairportData(
        {
          orgId: 1,
          filters: _search,
          pagination: { page: datas, pageSize: 10 },
        },
        2
      );
      this.setState({ stateuser: datas });
    }
  };
  loadotherTravellers = (e) => {
    let _search = { isActiveMembership: true };
    if (this.state.seachtrav) {
      _search = { displayName: this.state.seachtrav, isActiveMembership: true };
    }
    if (e.target.scrollTop + e.target.offsetHeight >= e.target.scrollHeight) {
      var datas = this.state.stateuser + 1;
      if (parseInt(this.props.match.params.orgid) === 1) {
        this.props
          .getTravellersListing({
            orgId: this.props.match.params.orgid,
            filters: _search,
            pagination: { page: datas, pageSize: 10 },
            sorter: {},
          })
          .then((resp) => {
            let data = this.state.TravellerData;
            this.setState({ stateuser: datas });
            this.setState({
              TravellerData: data.concat(resp.data),
            });
          })

          .catch((ex) => {
            this.setState({ loading: false });
          });
      } else {
        this.props
          .getUserListing({
            orgId: this.props.form.getFieldValue("parent")
              ? this.props.form.getFieldValue("parent")
              : this.props.match.params.orgid,
            filters: _search,
            pagination: { page: datas, pageSize: 10 },
            sorter: {},
          })
          .then((resp) => {
            let data = this.state.TravellerData;
            this.setState({ stateuser: datas });
            this.setState({
              TravellerData: data.concat(resp.data),
            });
          })
          .catch((ex) => {
            this.setState({ loading: false });
          });
      }
    }
  };
  searchtravellers = (e, value) => {
    this.setState({ seachtrav: e });

    if (e === "" || e === null) {
      this.setState({ stateuser: 1, TravellerData: [] });
    }
    if (
      this.props.match &&
      this.props.match.params &&
      parseInt(this.props.match.params.orgid) === 1
    ) {
      let data = {
        orgId: this.props.match.params.orgid,
        filters: { displayName: e },
        pagination: { page: 1, pageSize: 10 },
        sorter: {},
      };
      this.props
        .getTravellersListing(data)
        .then((resp) => {
          if (value === 1) {
            let data = this.state.TravellerData;

            this.setState({
              fetching: false,
              TravellerData: data.concat(resp.data),
            });
          } else {
            this.setState({
              fetching: false,
              TravellerData: resp.data,
            });
          }
        })
        .catch((ex) => {
          this.setState({ fetching: false });
        });
    } else {
      let data = {
        // orgId: /* this.props.match.params.orgid */ this.props.form.getFieldValue('parent'),
        orgId: this.props.form.getFieldValue("parent")
          ? this.props.form.getFieldValue("parent")
          : this.props.match.params.orgid,
        filters: { displayName: e },
        pagination: { page: 1, pageSize: 10 },
        sorter: {},
      };
      this.props
        .getUserListing(data)
        .then((resp) => {
          if (value === 1) {
            let data = this.state.TravellerData;

            this.setState({
              fetching: false,
              TravellerData: data.concat(resp.data),
            });
          } else {
            this.setState({
              fetching: false,
              TravellerData: resp.data,
            });
          }
        })
        .catch((ex) => {
          this.setState({ fetching: false });
        });
    }
    
  };
  getflightinformation() {
    let self = this;
    this.props.form.validateFields(
      ["flightnumber", "departuredate", "airlineCode"],
      (err, values) => {
        this.setState({
          flightno: values.flightnumber,
          departture: values.departuredate,
        });
        if (!err) {
          this.setState({ loading: true });

          this.props
            .getFlightInformation(
              this.props.form.getFieldValue("airlineCode"),
              this.props.form.getFieldValue("flightnumber"),
              moment(this.props.form.getFieldValue("departuredate")).format(
                dateFormathh
              )
            )
            .then((resp) => {
              if (resp.data) {
                this.showModalSegment();
                this.setState({ flightData: resp.data });
                this.setState({ loading: false });
                this.setState({ selectField: true });
                const flightDataWithKeys = this.state.flightData.map((item) => ({
                  ...item,
                  key: this.generateRandomKey(),
                }));
                
                this.setState({
                  flightData: flightDataWithKeys,
                });                
                
              } else {
                notifyUser(
                  "We are are unable to find your flight, Please make sure you have entered the correct flight details , If the flight details are correct then please select the flight details from  trip segments section.",
                  "error"
                );
                this.setState({ loading: false, resultsFound: false });
              }
            });
            
        }
      }
    );
  }
  // generated random key
  generateRandomKey = () => {
    return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
  };
  
  handleSelectChange = (selectedFlight) => {
    let self = this;
    // this.setState({ selectedFlightId: id });
 // console.log(selectedFlightId)
    // const selectedFlight = this.state.flightData.find(
    //   (flight) => flight.ident === id
    // );
    
   this.setState({selectedRow:selectedFlight.key})
   this.setState({ident:selectedFlight.ident})
    let airportData = [];
    airportData.push({
      displayName: selectedFlight.departureAirportName,
      airportID: selectedFlight.departureAirportID,
    });
    airportData.push({
      displayName: selectedFlight.arivalAirportName,
      airportID: selectedFlight.arivalAirportID,
    });
    this.setState({
      airportData: airportData.concat(selectedFlight),
      alwaysData:airportData
    });
    
    let _itineraryData = this.state.itineraryData;
    // _itineraryData.fromDate = resp.data.departureDate;
    _itineraryData.fromDate = [
      moment(selectedFlight.departureDate, "YYYY-MM-DD HH:mm:ss"),
    ];
    _itineraryData.toDate = [
      moment(selectedFlight.arivalDate, "YYYY-MM-DD HH:mm:ss"),
    ];

    self.props.form.setFieldsValue({
      fromlocation: selectedFlight.departureAirportID,
      tolocation: selectedFlight.arivalAirportID,
      // fromdate: moment(resp.data.departureDate),
      todate: [
        moment(selectedFlight.departureDate, "YYYY-MM-DD HH:mm:ss"),
        moment(selectedFlight.arivalDate, "YYYY-MM-DD HH:mm:ss"),
      ],
    });
    this.setState({
      rangedate: [
        moment(selectedFlight.departureDate, "YYYY-MM-DD HH:mm:ss"),
        moment(selectedFlight.arivalDate, "YYYY-MM-DD HH:mm:ss"),
      ],
      loading: false,
      itineraryData: _itineraryData,
      resultsFound: true,
    });
  };
  
  // show modal
  showModalSegment = () => {
    this.setState({
      visible: true,
    });
  };

  handleSelectionOk = () => {
    this.setState({
      visible: false,
    });
  };

  // Hide modal
  handleSelectionCancel = () => {
    this.setState({
      visible: false,
      resultsFound: false,
    });
    this.props.form.setFieldsValue({ departuredate: null,
      flightnumber: null,
      airlineCode: null, fromlocation: null,
      fromdate: null,
      tolocation: null,
      todate: null, });
  };

  resetFlightInformationForm = () => {
    this.setState({ disablefields: true });
    var itineraryData = this.state.itineraryData;
    itineraryData.fromDate = null;
    this.setState({
      itineraryData: itineraryData,
      resultsFound: false,
    });

    this.props.form.setFieldsValue({
      fromlocation: null,
      fromdate: null,
      tolocation: null,
      todate: null,
      departuredate: null,
      flightnumber: null,
      airlineCode: null,
      userId: null,
    });
  };
  
  render() {
    const { getFieldDecorator } = this.props.form;
    const { formLayout } = this.state;
    const formItemLayout =
      formLayout === "horizontal"
        ? {
            labelCol: { span: 4 },
            wrapperCol: { span: 14 },
          }
        : null;
        
        
        
        const columns = [
          {
            title: 'Departure Airport',
            dataIndex: 'departureAirportName',
            key: 'departureAirportName',
          },
          {
            title: 'Arrival Airport',
            dataIndex: 'arivalAirportName',
            key: 'key',
          },
          {
            title: 'Date Range',
            dataIndex: 'dateRange',
            key: 'dateRange',
            render: (text, record) => (
              <span>
                {record.departureDate} To {record.arivalDate} 
              </span>
            ),
          },
        ];
        
    return (
      <div>
        <Spin spinning={this.state.loading}>
          <Row gutter={24}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              {this.state.travellerIternaryId === 0 ? (
                <div>
                  <Typography.Title level={4}>
                    <IntlMessages id="admin.itinerary.additinerary"></IntlMessages>{" "}
                    {(Permission.isRole("Administrator") ||
                      Permission.isRole("CSR")) &&
                    this.state.orgName
                      ? "(" + this.state.orgName + ")"
                      : ""}
                  </Typography.Title>
                </div>
              ) : (
                <Typography.Title level={4}>
                  <IntlMessages id="admin.itinerary.updateitinerary"></IntlMessages>{" "}
                  {(Permission.isRole("Administrator") ||
                    Permission.isRole("CSR")) &&
                  this.state.orgName
                    ? "(" + this.state.orgName + ")"
                    : ""}
                </Typography.Title>
              )}
            </Col>
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={12}
              style={{ textAlign: "right" }}
            >
              <Button
                type="primary"
                className=""
                htmlType="button"
                onClick={() => this.props.history.goBack()}
              >
                <IntlMessages id="admin.userlisting.back" />
              </Button>
            </Col>
          </Row>
          <hr />
          <Row gutter={24}>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <Form.Item {...formItemLayout} label={<IntlMessages id="PNR" />}>
                {getFieldDecorator("pnrNumber", {
                  rules: [
                    {
                      required: true,
                      message: (
                        <IntlMessages id="admin.input.required"></IntlMessages>
                      ),
                    },
                    {
                      pattern: new RegExp(/^[a-zA-Z0-9]*$/),
                      message: (
                        <IntlMessages id="Please add valid PNR."></IntlMessages>
                      ),
                    },
                  ],
                  initialValue:
                    this.state.manualItineraryData.pnrNumber === null
                      ? ""
                      : this.state.manualItineraryData.pnrNumber,
                })(
                  <Input
                    onChange={(e) =>
                      this.validateFormButton("pnrNumber", e.target.value)
                    }
                    disabled={this.state.formexpired}
                    maxLength={"50"}
                  />
                )}
              </Form.Item>
            </Col>

            {this.props &&
            this.props.match &&
            this.props.match.params &&
            this.props.match.params.orgid !== "1" &&
            !Permission.isRole("Membership") &&
            !Permission.isRole("Employee") ? (
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Form.Item
                  {...formItemLayout}
                  label={<IntlMessages id="admin.organization.division" />}
                >
                  {this.props.match.params.id &&
                  this.props.match.params.id === "new"
                    ? getFieldDecorator("parent")(
                        <OrgTree
                          parent={-1}
                          organizationId={this.props.match.params.orgid}
                          disabled={
                            this.state.disablefields || this.state.formexpired
                          }
                          onChange={this.onChange}
                        />
                      )
                    : //  : this.state.itineraryData && this.state.itineraryData.orgId
                      getFieldDecorator("parent")(
                        <OrgTree
                          parent={this.state.itineraryData.orgId}
                          organizationId={this.props.match.params.orgid}
                          disabled={
                            this.state.disablefields || this.state.formexpired
                          }
                          onChange={this.onChange}
                        />
                      )
                  //   : "lll"
                  }
                </Form.Item>
              </Col>
            ) : (
              ""
            )}

            {!Permission.isRole("Membership") &&
            !Permission.isRole("Employee") && !Permission.isRole("Limited Access") ? (
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Form.Item
                  {...formItemLayout}
                  label={<IntlMessages id="admin.manageOrders.travellerName" />}
                >
                  {getFieldDecorator("travellerName", {
                    rules: [
                      {
                        required: true,
                        message: (
                          <IntlMessages id="admin.input.required"></IntlMessages>
                        ),
                      },
                    ],
                    initialValue:
                      this.state.manualItineraryData.userId !== null &&
                      this.state.TravellerData &&
                      this.state.TravellerData.length > 0
                        ? this.state.manualItineraryData.userId
                        : "",

                    // value: this.state.data.userId
                  })(
                    <Select
                      style={{ width: "100%" }}
                      onClick={this.searchtravellers}
                      showSearch
                      disabled={
                        this.state.disablefields || this.state.formexpired
                      }
                      filterOption={false}
                      onSearch={this.searchtravellers}
                      allowClear
                      onChange={(e) => this.validateFormButton("userId", e)}
                    >
                      {this.state.TravellerData
                        ? this.state.TravellerData.map(function(item) {
                            return (
                              <Option key={item.userId} value={item.userId}>
                                {item.firstName} {item.lastName}{" "}
                                {item.userName ? "<" + item.userName + ">" : ""}
                              </Option>
                            );
                          })
                        : ""}
                    </Select>
                  )}
                </Form.Item>
              </Col>
            ) : (
              ""
            )}
          </Row>

          {this.state.manualItineraryData.segmentData &&
          this.state.manualItineraryData.segmentData.length > 0 &&
          !this.state.isAddFlightSegment ? (
            <>
              <Row gutter={24}>
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={24}
                  style={{ marginBottom: "20px", marginTop: "20px" }}
                >
                  <h6 style={{ fontWeight: "normal" }}>
                    <b>
                      <IntlMessages id="admin.itinerary.existingFlightsegments" />
                    </b>{" "}
                  </h6>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col xs={8} sm={8} md={3} lg={3} xl={3}>
                  <Form.Item
                    label={
                      <IntlMessages id="userdash-fromlocation"></IntlMessages>
                    }
                  ></Form.Item>
                </Col>
                <Col xs={8} sm={8} md={3} lg={3} xl={3}>
                  <Form.Item
                    label={
                      <IntlMessages id="userdash-tolocation"></IntlMessages>
                    }
                  ></Form.Item>
                </Col>
                <Col xs={8} sm={8} md={3} lg={3} xl={3}>
                  <Form.Item
                    label={
                      <IntlMessages id="userdash-departureDate"></IntlMessages>
                    }
                  ></Form.Item>
                </Col>
                <Col xs={8} sm={8} md={3} lg={3} xl={3}>
                  <Form.Item
                    label={
                      <IntlMessages id="userdash-arrivalDate"></IntlMessages>
                    }
                  ></Form.Item>
                </Col>
                <Col xs={8} sm={8} md={3} lg={3} xl={3}>
                  <Form.Item
                    label={
                      <IntlMessages id="admin.itinerary.airlinecode" />
                    }
                  ></Form.Item>
                </Col>
                <Col xs={8} sm={8} md={3} lg={3} xl={3}>
                  <Form.Item
                    label={
                      <IntlMessages id="userdash-flightnumber" />
                    }
                  ></Form.Item>
                </Col>
                
                <Col xs={8} sm={8} md={4} lg={4} xl={4}>
                  <Form.Item
                    label={<IntlMessages id="userdash-action"></IntlMessages>}
                  ></Form.Item>
                </Col>
              </Row>
            </>
          ) : (
            ""
          )}
          {this.state.manualItineraryData.segmentData &&
            this.state.manualItineraryData.segmentData.length > 0 &&
            !this.state.isAddFlightSegment &&
            this.state.manualItineraryData.segmentData.map((segment, index) => (
              <>
                <Row gutter={24}>
                  <Col xs={8} sm={8} md={3} lg={3} xl={3}>
                    <Select
                      style={{ width: "100%" }}
                      disabled={true}
                      defaultValue={
                        this.state.manualItineraryData.segmentData[index]
                          .fromLocationId
                      }
                    >
                      {this.state.airportData
                        ? this.state.airportData.map(function(item) {
                            return (
                              <Option
                                key={item.airportID}
                                value={item.airportID}
                              >
                                {item.displayName}
                              </Option>
                            );
                          })
                        : ""}
                    </Select>
                  </Col>
                  <Col xs={8} sm={8} md={3} lg={3} xl={3}>
                    <Select
                      style={{ width: "100%" }}
                      disabled={true}
                      defaultValue={
                        this.state.manualItineraryData.segmentData[index]
                          .toLocationId
                      }
                    >
                      {this.state.airportData
                        ? this.state.airportData.map(function(item) {
                            return (
                              <Option
                                key={item.airportID}
                                value={item.airportID}
                              >
                                {item.displayName}
                              </Option>
                            );
                          })
                        : ""}
                    </Select>
                  </Col>

                  <Col xs={8} sm={8} md={3} lg={3} xl={3} className={"datesegment"}>
                    {/* <DatePicker
                      showTime
                      disabled={true}
                      format={dateFormat}
                      disabledDate={true}
                      disabledTime={true}
                      defaultValue={moment(
                        this.state.manualItineraryData.segmentData[index]
                          .fromDate,
                        dateFormathh
                      )}
                    /> */}
                   
                  <Form.Item>
                    {getFieldDecorator("fromDate", {
                      rules: [],
                      initialValue:
                      this.state.manualItineraryData.segmentData[index]
                      .fromDate
                    })(<Input disabled={true}/>)}
                  </Form.Item>
                    
                  
                  </Col>
                  <Col xs={8} sm={8}  md={3} lg={3} xl={3} className={"datesegment"}>
                    {/* <DatePicker
                      showTime
                      disabled={true}
                      format={dateFormat}
                      disabledDate={true}
                      disabledTime={true}
                      defaultValue={moment(
                        this.state.manualItineraryData.segmentData[index]
                          .toDate,
                        dateFormathh
                      )}
                    /> */}
                     <Form.Item >
                    {getFieldDecorator("toDate", {
                      rules: [],
                      initialValue:
                      this.state.manualItineraryData.segmentData[index]
                      .toDate
                    })(<Input disabled={true}/>)}
                  </Form.Item>
                    
                  </Col>
                <Col className={"airline-col"} xs={8} sm={8} md={3} lg={3} xl={3}>
                    <Form.Item
                      
                    >
                      {getFieldDecorator("airlinecode", {
                        rules: [],
                        initialValue: this.state.manualItineraryData.segmentData[index].airlineCode !== null &&
                        this.state.manualItineraryData.segmentData[index].airlineCode !== ""
                          ? this.state.manualItineraryData.segmentData[index].airlineCode
                          : "NAN"
                         
                      })(
                        <Input
                        disabled={true} />
                      )}
                    </Form.Item>
                  </Col>
                  <Col className={"airline-col"} xs={8} sm={8} md={3} lg={3} xl={3}>
                    <Form.Item
                    >
                      {getFieldDecorator("flightNumber", {
                        rules: [],
                        initialValue: this.state.manualItineraryData.segmentData[index].flightNumber !== null &&
                        this.state.manualItineraryData.segmentData[index].flightNumber !== ""
                          ? this.state.manualItineraryData.segmentData[index].flightNumber
                          : "NAN"
                        
                      })(
                        <Input
                        disabled={true} />
                      )}
                    </Form.Item>
                  </Col>
                  

                  <Col xs={8} sm={8} md={4} lg={4} xl={4} className="segment-btnss">
                    {this.props.match &&
                    this.props.match.params &&
                    this.props.match.params.id &&
                    this.props.match.params.id === "new" ? (
                      <>
                        <Button class="primary" className="itinary-btn" onClick={() =>this.showModal(index)}>
                          Remove
                        </Button>
                        <Button
                          style={{
                            marginLeft: "10px",
                          }}
                          class="primary"
                          className="itinary-btn"
                          onClick={(e) =>
                            this.updateFlightSegment(e, index, "update")
                          }
                        >
                          Edit
                        </Button>
                      </>
                    ) : (
                      ""
                    )}
                    <Modal
                      title="Flight Segment"
                      visible={this.state.showRemovePopup}
                      onCancel={this.HidePopup}
                      onOk={(e) => this.removeFlightSegment(e, index)}
                      okText="Yes"
                      cancelText="No"
                      className="modalStyle"
                    >
                      <p>Are You Sure! You want to Remove Flight Segment.</p>
                    </Modal>
                    {this.props.match &&
                    this.props.match.params &&
                    this.props.match.params.id &&
                    this.props.match.params.id !== "new" ? (
                      <Button
                       // style={{ marginLeft: "10px" }}
                        class="primary"
                        onClick={(e) => this.updateFlightSegment(e, index)}
                      >
                        Edit  
                      </Button>
                    ) : (
                      ""
                    )}
                  </Col>
                </Row>
                <br />
              </>
            ))}

          {this.state.isAddFlightSegment ? (
            <>
              <Row gutter={24}>
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={24}
                  style={{ marginBottom: "20px", marginTop: "20px" }}
                >
                  <h6 style={{ fontWeight: "normal" }}>
                    <b>
                      <IntlMessages id="admin.itinerary.flightsegments" />
                    </b>{" "}
                  </h6>
                </Col>
              </Row>

              <Form layout={formLayout} onSubmit={this.getflightinformation}>
                <Row gutter={24}>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Form.Item
                      {...formItemLayout}
                      label={<IntlMessages id="userdash-departuredate" />}
                    >
                      {getFieldDecorator("departuredate", {
                        rules: [
                          {
                            required: true,
                            message: (
                              <IntlMessages id="admin.input.required"></IntlMessages>
                            ),
                          },
                        ],
                        initialValue: this.state.itineraryData.departureDate
                          ? moment(
                              this.state.itineraryData.departureDate,
                              dateFormathh
                            )
                          : "",
                      })(
                        <DatePicker
                          showTime
                          disabled={
                            this.state.formexpired ||
                            this.state.disableSearchForm
                          }
                          format={"MM/DD/YYYY - HH:mm:00"}
                          disabledDate={disabledDate}
                          disabledTime={this.disableDepartureTime}
                        />
                      )}
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Form.Item
                      {...formItemLayout}
                      label={<IntlMessages id="admin.itinerary.airlinecode" />}
                      name="airlineCode"
                    >
                      {getFieldDecorator("airlineCode", {
                        rules: [
                          {
                            required: true,
                            message: (
                              <IntlMessages id="admin.input.required"></IntlMessages>
                            ),
                          },
                          {
                            pattern: new RegExp(/^[a-zA-Z0-9]*$/),
                            message: (
                              <IntlMessages id="admin.itinerary.validFlightNumber"></IntlMessages>
                            ),
                          },
                        ],
                        initialValue:
                          this.state.itineraryData.airlineCode === null
                            ? ""
                            : this.state.itineraryData.airlineCode,
                      })(
                        <Input
                          disabled={
                            this.state.formexpired ||
                            this.state.disableSearchForm
                          }
                          onInput={this.toInputUppercase}
                        />
                      )}
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Form.Item
                      {...formItemLayout}
                      label={<IntlMessages id="userdash-flightnumber" />}
                    >
                      {getFieldDecorator("flightnumber", {
                        rules: [
                          {
                            required: true,
                            message: (
                              <IntlMessages id="admin.input.required"></IntlMessages>
                            ),
                          },
                          {
                            pattern: new RegExp(/^[a-zA-Z0-9]*$/),
                            message: (
                              <IntlMessages id="admin.itinerary.validFlightNumber"></IntlMessages>
                            ),
                          },
                        ],

                        initialValue:
                          this.state.itineraryData.flightNumber === null
                            ? ""
                            : this.state.itineraryData.flightNumber,
                      })(
                        <Input
                          disabled={
                            this.state.formexpired ||
                            this.state.disableSearchForm
                          }
                        />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                {!this.state.formexpired ? (
                  <Row gutter={24}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      {/* {!this.state.formexpired ? */}
                      <Button
                        style={{ textAlign: "right" }}
                        type="primary"
                        class="primary"
                        onClick={this.getflightinformation}
                        disabled={this.state.disableSearchForm}
                      >
                        Search
                      </Button>
                      {/*   : ''} */}
                      {this.props.form.getFieldValue("flightnumber") !== null &&
                      this.props.form.getFieldValue("flightnumber") !==
                        undefined &&
                      this.props.form.getFieldValue("flightnumber") !== "" &&
                      this.state.resultsFound ? (
                        <span>
                          &nbsp;
                          <Button
                            style={{ textAlign: "right" }}
                            type="reset"
                            class="primary"
                            onClick={this.resetFlightInformationForm}
                            disabled={
                              this.state.formexpired ||
                              this.state.disableSearchForm
                            }
                          >
                            Reset
                          </Button>
                        </span>
                      ) : (
                        ""
                      )}
                    </Col>
                    {this.state.selectField ? (
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
               
                    <Modal
                    title={<h2>Please Select Flight Segment</h2>}
                    visible={this.state.visible}
                    onOk={this.handleSelectionOk}
                    onCancel={this.handleSelectionCancel}
                    className="modal-selection"
                   >
                          <Radio.Group
                            // onChange={this.handleSelectChange}
                            value={this.state.selectedFlightId}
                          >
                                            <Table
                                              dataSource={this.state.flightData}
                                              columns={columns}
                                              key="key"
                                              pagination={false}
                                              onRow={(record) => {
                                                
                                                return {
                                                  onClick: () => {
                                                    this.handleSelectChange(record);
                                                  },
                                                  className: record.key == this.state.selectedRow ? 'selected-row' : '', // Add this line to conditionally apply a class
                                                };
                                              }}
                                            
                                            />
                                            
                          </Radio.Group>
                  </Modal>
              </Col>
            ) : ""}
                  </Row>
                  
                 
                ) : (
                  ""
                )}
              </Form>
              <hr />
              <Row gutter={24} style={{ marginTop: "20px" }}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <h5>
                    <IntlMessages id="admin.itinerary.TripSegments"></IntlMessages>{" "}
                  </h5>
                </Col>
              </Row>

              <Form layout={formLayout} onSubmit={this.handleAddTraveller}>
                <Row gutter={24}>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Form.Item
                      label={
                        <IntlMessages id="userdash-fromlocation"></IntlMessages>
                      }
                    >
                      {getFieldDecorator("fromlocation", {
                        rules: [
                          {
                            required: true,
                            message: (
                              <IntlMessages id="admin.input.required"></IntlMessages>
                            ),
                          },
                        ],

                        initialValue: this.state.itineraryData.fromLocationId,
                      })(
                        <Select
                          style={{ width: "100%" }}
                          onPopupScroll={this.loadAccountManagers}
                          showSearch
                          onSearch={this.onSearch}
                          disabled={
                            /* this.state.formexpired || (this.state.itineraryData.fromDate && this.state.itineraryData.fromDate !== null) &&  */
                            this.state.formexpired ||
                            this.state.resultsFound ||
                            (this.props.form.getFieldValue("flightnumber") !==
                              null &&
                              this.props.form.getFieldValue("flightnumber") !==
                                undefined &&
                              this.props.form.getFieldValue("flightnumber") !==
                                "" &&
                              this.state.resultsFound) ||
                            this.state.disableSearchForm
                          }
                          optionFilterProp="children"
                          onSelect={this.getAirportUpdate}
                          filterOption={(input, option) =>
                            option.props.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {this.state.airportData
                            ? this.state.airportData.map(function(item) {
                                return (
                                  <Option
                                    key={item.airportID}
                                    value={item.airportID}
                                  >
                                    {item.displayName}
                                  </Option>
                                );
                              })
                            : ""}
                        </Select>
                      )}
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Form.Item
                      label={
                        <IntlMessages id="userdash-tolocation"></IntlMessages>
                      }
                    >
                      {getFieldDecorator("tolocation", {
                        rules: [
                          {
                            required: true,
                            message: (
                              <IntlMessages id="admin.input.required"></IntlMessages>
                            ),
                          },
                        ],

                        initialValue: this.state.itineraryData.toLocationId,
                      })(
                        <Select
                          // onSearch={this.onSearch}
                          onPopupScroll={this.loadAccountManagers}
                          showSearch
                          disabled={
                            /* this.state.formexpired || (this.state.itineraryData.fromDate && this.state.itineraryData.fromDate !== null) &&  */

                            this.state.formexpired ||
                            this.state.resultsFound ||
                            (this.props.form.getFieldValue("flightnumber") !==
                              null &&
                              this.props.form.getFieldValue("flightnumber") !==
                                undefined &&
                              this.props.form.getFieldValue("flightnumber") !==
                                "" &&
                              this.state.resultsFound) ||
                            this.state.disableSearchForm
                          }
                          onSearch={this.onSearch}
                          optionFilterProp="children"
                          onSelect={this.getAirportUpdate}
                          filterOption={(input, option) =>
                            option.props.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          style={{ width: "100%" }}
                        >
                          {this.state.airportData &&
                          this.state.airportData.length > 0
                            ? this.state.airportData.map(function(item) {
                                return (
                                  <Option
                                    key={item.airportID}
                                    value={item.airportID}
                                  >
                                    {item.displayName}
                                  </Option>
                                );
                              })
                            : ""}
                        </Select>
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Form.Item
                      {...formItemLayout}
                      label={<IntlMessages id="admin.itinerary.fromtodate" />}
                    >
                      {getFieldDecorator("todate", {
                        rules: [
                          {
                            required: true,
                            message: (
                              <IntlMessages id="admin.input.required"></IntlMessages>
                            ),
                          },
                        ],

                        initialValue:
                          this.state.rangedate &&
                          this.state.rangedate.length === 2
                            ? this.state.rangedate
                            : this.state.rangedate,
                      })(
                        <RangePicker
                          style={{ width: "100%" }}
                          // disabled={
                          //    this.state.formexpired || (this.state.itineraryData.fromDate && this.state.itineraryData.fromDate !== null) &&
                          //   moment(this.state.rangedate[0]).isBefore(moment())
                          //  || (
                          //   this.state.resultsFound
                          //   && !!this.props.form.getFieldValue("flightnumber")
                          //   )
                          // }

                          disabled={
                            /* this.state.formexpired || (this.state.itineraryData.fromDate && this.state.itineraryData.fromDate !== null) &&  */

                            this.state.formexpired ||
                            this.state.resultsFound ||
                            (this.props.form.getFieldValue("flightnumber") !==
                              null &&
                              this.props.form.getFieldValue("flightnumber") !==
                                undefined &&
                              this.props.form.getFieldValue("flightnumber") !==
                                "" &&
                              this.state.resultsFound) ||
                            this.state.disableSearchForm
                          }
                          disabledDate={disabledDate}
                          disabledTime={this.disabledRangeTime}
                          showTime={{
                            hideDisabledOptions: true,
                          }}
                          format={"MM/DD/YYYY - HH:mm:00"}
                        />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                {!this.state.formexpired ? (
                  <Row>
                    <Col xs={8} sm={8} md={8} lg={2} xl={2}>
                      <Button
                        onClick={this.handleAddTraveller}
                        type="primary"
                        style={{
                          display: "inline-block",
                          marginRight: "20px",
                          verticalAlign: "top",
                        }}
                      >
                        <IntlMessages
                        
                       id={
                         this.state.travellerIternaryId === null ||
                         this.state.travellerIternaryId === 0 || 
                         this.state.segmentIndex === -1
                           ? (this.props.location.pathname.includes('new')
                           ? "admin.manageiteneary.add" : "admin.manageitenearysave.add")
                           : "admin.manageiteneary.update"
     
                       }
                      />
                      
                       
                      </Button>
                    </Col>
                    <Col xs={8} sm={8} md={8} lg={2} xl={2}>
                      <Button
                        onClick={(e) => {
                          this.resetFlightInformationForm();
                          this.setState({
                            isAddFlightSegment: !this.state.isAddFlightSegment,
                            formexpired: false,
                            disableSearchForm: false
                          });
                        }}
                        type="primary"
                        style={{
                          display: "inline-block",
                          marginRight: "10px",
                          verticalAlign: "top",
                          marginLeft: "57px",
                        }}
                      >
                        <IntlMessages id="admin.manageiteneary.cancel" />
                      </Button>
                    </Col>
                  </Row>
                ) : (
                  ""
                )}
              </Form>
            </>
          ) : (
            ""
          )}
        </Spin>
        <hr />
        <Row>
          {this.state.travellerIternaryId === null ||
          this.state.travellerIternaryId === 0 ? (
            <Col xs={8} sm={8} md={8} lg={4} xl={4}>
              <div
                className="add-traveler"
                hidden={this.state.isAddFlightSegment}
              >
                <div className="wrap">
                  <Button
                    disabled={this.state.isAddFlightDisable}
                    onClick={this.appendSegmentData}
                  >
                    [+] Add Trip Segment
                  </Button>
                </div>
              </div>
            </Col>
          ) : (
            ""
          )}
          {this.state.manualItineraryData.segmentData &&
          this.state.manualItineraryData.segmentData.length > 0 &&
          !this.state.isAddFlightSegment ? (
            <Col xs={8} sm={8} md={8} lg={2} xl={2}>
              <div className="add-traveler">
                <div className="wrap">
                  {this.state.travellerIternaryId === null ||
                  this.state.travellerIternaryId === 0 ? (
                    <Button onClick={(e) => this.saveItinerary(e)}>
                      {"Save Itinerary"}
                    </Button>
                  ) : (
                    <div className="wrap add-segment">
                    <Button onClick={(e) => this.updateItinerary(e)}>
                    {" "}
                    {"Update Itinerary"}
                   </Button>
                  <Button
                    //disabled={this.state.isAddFlightDisable}
                    onClick={this.appendSegmentDataEdit}
                  >
                    [+] Add Trip Segment
                  </Button>
                  
                  </div>
                    
                     )}
                </div>
              </div>
            </Col>
          ) : (
            ""
          )}
        </Row>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    ...state.user,
    ...state.language,
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { ...itiniaryActions, ...userActions, ...travellerActions, ...orgActions },
    dispatch
  );
}
const WrappedForm = Form.create({ name: "Update-itinerary" })(
  UpdateManualItinerary
);
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    WrappedForm
  )
);
