import React, { Component } from "react";
import ActivityLog from "../audit-logs/audit-logs";

class BillingAuditLogs extends Component {
  state = {
    data: []
  }
  render() {
      return (
        <ActivityLog for="invoice"/>
      )
  }
}
export default BillingAuditLogs;