import React, { Component } from "react";
import { Switch, withRouter, Route } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import routeOptions from "../app/routes/route";
import PrivateRoute from "./private-routes.jsx";
import ErrorBoundry from "./components/shared/components/errorBoundry"
import { ConfigProvider } from "antd";
import { IntlProvider } from "react-intl";
import AppLocale from "../app/languageProvider";
import * as languageAction from "../app/redux/actions/language-actions.js";
import * as themeAction from "../app/redux/actions/theme-actions";
// import Header from "./components/header/header.jsx";
import "./index.scss";
import "./App.less";

class App extends Component {

  render() {
    let path =  window.location.pathname;
     if(path == "/"){
     document.title ="Login – CAP Travel Assistance"
     document.querySelector('meta[name="description"]').content = "Login to the CAP Travel Assistance Portal to enjoy everything the CAP Plan has to offer. Visit the website to login"
    }
    else {
     document.title ="CAP Travel Assistance"
     document.querySelector('meta[name="description"]').content = "CAP Travel Assistance Portal to enjoy everything the CAP Plan has to offer.";
    }
    let routes = routeOptions.routes.map(({ path, component, exact }, i) => (
      <Route
        key={Math.random() + "ROUTE_"}
        exact={exact}
        path={path}
        component={component}
      />
    ));
    let privateRoute = routeOptions.private.map(
      ({ path, component, exact, roles, status }, i) => (
        <PrivateRoute
          key={Math.random() + "REDIRECT_"}
          roles={roles}
          exact={exact}
          path={path}
          component={component}
          status={status}
          currPath={this.props.location.pathname}
        />
      )
    );

    const { language } = this.props;
    const currentAppLocale = AppLocale[language.locale];

    return (
       
      <ConfigProvider locale={currentAppLocale.antd}>
      {/* <ErrorBoundry> */}
        <IntlProvider
          locale={currentAppLocale.locale}
          messages={currentAppLocale.messages}
        >
          <div className="app-container App">
            {/* <Header match={this.props.match} selectedtheme={this.props.theme} location={this.props.location}
              history={this.props.history} /> */}
            <Switch>
              {routes}
              {privateRoute}
            </Switch>
          </div>
        </IntlProvider>
        {/* </ErrorBoundry> */}
      </ConfigProvider>
    );
  }
}

App.propTypes = {
  language: PropTypes.object,
  theme: PropTypes.object,
  location: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object
};
function mapStateToProps(state) {
  return {
    ...state.language,
    ...state.theme
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...languageAction, ...themeAction }, dispatch);
}
export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    { forwardRef: true }
  )(App)
);
