import React from 'react';
import { StripeProvider, Elements , injectStripe} from 'react-stripe-elements';
import _CheckoutForm from './CheckoutForm'; // Create this component for the payment form
import config from "../../../../../config"
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
const CheckoutForm = injectStripe(_CheckoutForm)

const YourPaymentComponent = ({ showBilling, childformdata, parentformData, handlePaymentSection }) => {
  return (
    <StripeProvider apiKey={config.stripeKey}>
      <Elements>
        <CheckoutForm
          showBilling={showBilling}
          childformdata={childformdata}
          parentformData={parentformData}
          handlePaymentSection={handlePaymentSection}
        />
      </Elements>
    </StripeProvider>
  );
};


function mapStateToProps(state) {
    return {
    };
  }
  function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch);
  }

export default connect(mapStateToProps, mapDispatchToProps, null, {
    forwardRef: true
  })(YourPaymentComponent);
