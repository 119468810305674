import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as orderActions from "../../../../../redux/actions/order-actions";
//import * as OrgActions from "../../../../../redux/actions/organization-actions";
import * as B2B2COrgActions from "../../../../../redux/actions/b2b2c-organization-actions";
import IntlMessages from "../../../../../services/intlMesseges";
// import Moment from "react-moment";
import { Typography, Form, Button, Row, Col, Table, Input, Icon } from "antd";

import moment from "moment";
const dateFormat = "MM/DD/YYYY - HH:mm";

class EditB2B2cOrganizationLicense extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      organizationName: null,
      loading: false,
      data: [],
      orderInfo: {},
      pagination: {
        pageSize: 10,
        current: 1,
        hideOnSinglePage: true,
        showTotal: (total, range) => {
          return (
            <span>
              <IntlMessages id="admin.text.showing" /> {range[0]}-{range[1]}{" "}
              <IntlMessages id="admin.text.of" /> {total}{" "}
              <IntlMessages id="admin.text.results" />
            </span>
          );
        },
      },
      filters: {},
    };
  }

  async componentDidMount() {
    if (
      this.props.match &&
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.id &&
      this.props.match.params.id !== "new"
    ) {
      this.setState({ loading: true, id: this.props.match.params.id });
      this.setState({ loading: false });
    }
    this.handleTableChange({ current: 1, pageSize: 10 }, {}, {});
    this.props
      .getOrganizationId(this.props.match.params.orgid)
      .then((resp) => {
        if (resp.data) {
          this.setState({
            organizationName: resp.data.name,
            orgMembershipNumber: resp.data.membershipNumber,
          });
        }
      })
      .catch((_ex) => {
        this.setState({ loading: false });
      });
  }

  getHeaderKeys = () => {
    return [
      {
        title: <IntlMessages id="admin.invoice.companyname" />,
        dataIndex: "organizationName",
        ...this.getColumnSearchProps("organizationName"),
        render: (_text, record) => (
          <span>
            {record.licenseDetails.organizationName != null
              ? record.licenseDetails.organizationName
              : "---"}
          </span>
        ),
      },
      {
        title: <IntlMessages id="admin.division.name" />,
        dataIndex: "divisionName",
        ...this.getColumnSearchProps("divisionName"),
        render: (_text, record) => (
          <span>
            {record.licenseDetails.divisionName &&
            record.licenseDetails.divisionName !== null
              ? record.licenseDetails.divisionName
              : "---"}
          </span>
        ),
      },
      {
        title: <IntlMessages id="admin.org.licensehistory.username" />,
        dataIndex: "user",
        //width: "30%",
        ...this.getColumnSearchProps("user"),
        render: (_text, record) => (
          <span>
            {record.licenseDetails.user !== null
              ? record.licenseDetails.user
              : "N/A"}
          </span>
        ),
      },
      {
        title: <IntlMessages id="admin.org.licensehistory.useremail" />,
        dataIndex: "userName",
        //width: "30%",
        //...this.getColumnSearchProps("userName"),
        render: (_text, record) => (
          <span>
            {record.licenseDetails.userEmail &&
            record.licenseDetails.userEmail !== null
              ? record.licenseDetails.userEmail
              : "N/A"}
          </span>
        ),
      },
      {
        title: <IntlMessages id="admin.org.licensehistory.type" />,
        dataIndex: "type",
        //width: "20%",
        render: (_text, record) => <span>{record.licenseDetails.type}</span>,
      },
      {
        title: <IntlMessages id="admin.tablelist.datetime" />,
        dataIndex: "date",
        //width: "20%",
        render: (_text, record) => (
          <span>{moment(record.licenseDetails.date).format(dateFormat)}</span>
        ),
      },
    ];
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <div style={{ padding: 8 }}>
          <Input
            ref={(node) => {
              this.searchInput = node;
            }}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? e.target.value.trimLeft() : "")
            }
            onPressEnter={() =>
              this.handleSearch(
                selectedKeys,
                confirm,
                dataIndex,
                clearFilters,
                setSelectedKeys
              )
            }
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Button
            type="primary"
            onClick={() =>
              this.handleSearch(
                selectedKeys,
                confirm,
                dataIndex,
                clearFilters,
                setSelectedKeys
              )
            }
            disabled={
              selectedKeys != "" && selectedKeys !== null ? false : true
            }
            icon="search"
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            <IntlMessages id="admin.search" />
          </Button>
          <Button
             disabled={
              selectedKeys != "" && selectedKeys !== null ? false : true
            }
            onClick={() => this.handleReset(clearFilters, dataIndex)}
            size="small"
            style={{ width: 90 }}
          >
            <IntlMessages id="admin.reset" />
          </Button>
        </div>
      );
    },
    filterIcon: (filtered) => (
      <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
  });

  handleSearch = (
    selectedKeys,
    confirm,
    dataIndex,
    clearFilters,
    setSelectedKeys
  ) => {
    if (selectedKeys === "") {
      return false;
    }
    confirm();
    this.setState({ searchText: selectedKeys });
    let filters = this.state.filters;
    filters[dataIndex] = {
      val: selectedKeys,
      clearf: clearFilters,
      filter: true,
      setSelectedKeys: setSelectedKeys,
      confirm: confirm,
    };
    this.setState({ filters: filters });
  };

  handleReset = (clearFilters, dataIndex) => {
    clearFilters();
    let filters = this.state.filters;
    if (filters[dataIndex]) {
      if (
        filters[dataIndex].setSelectedKeys &&
        typeof filters[dataIndex].setSelectedKeys === "function"
      ) {
        filters[dataIndex].setSelectedKeys("");
        //filters[dataIndex].confirm();
      }
    }
    filters[dataIndex] = { val: "", clearf: "", filter: false };
    this.setState({ filters: filters });
    this.setState({ searchText: "" });
  };
  
  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({ loading: true });
    this.props
      .getLicenseLogs({
        licenseId: this.props.match.params.id,
        filters: filters,
        pagination: { page: pagination.current, pageSize: pagination.pageSize },
        sorter: sorter,
      })
      .then((resp) => {
        pager.total = resp.paging.totalRecords;
        this.setState({
          loading: false,
          data: resp.data,
          pagination: pager,
        });
      })
      .catch((_ex) => {
        this.setState({ loading: false });
      });
  };

  render() {
    let _state = this.state;
    let _this = this;
    let filtertag = Object.keys(this.state.filters).map(function(key1) {
      let keyLabel = _this.getHeaderKeys().find((el) => el.dataIndex === key1);
      if (keyLabel.title.props && keyLabel.title.props.id) {
        keyLabel = <IntlMessages id={keyLabel.title.props.id} />;
      } else {
        keyLabel = key1;
      }
      if (_state.filters[key1].filter) {
        return (
          <span>
            <Button
              key={key1}
              type="primary"
              onClick={() =>
                _this.handleReset(_state.filters[key1].clearf, key1)
              }
            >
              {keyLabel} : {_state.filters[key1].val}
              <Icon type="close" />
            </Button>
            &nbsp;
          </span>
        );
      }
    });
    return (
      <div>
        <Row gutter={24}>
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography.Title level={4}>
              <IntlMessages id="admin.org.licensehistory.title" />
              {this.state.orgMembershipNumber !== null
                ? " (" +
                  this.state.orgMembershipNumber +
                  "-" +
                  this.props.match.params.id +
                  ")"
                : ""}
            </Typography.Title>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <Button
              type="primary"
              className="right-fl"
              htmlType="button"
              onClick={() => this.props.history.push("./")}
            >
              <IntlMessages id="admin.userlisting.back" />
            </Button>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col>{filtertag}</Col>
        </Row>
        <Row>
          <Col>
            <Table
              columns={this.getHeaderKeys()}
              rowKey={(record) => record.userId}
              dataSource={this.state.data}
              pagination={this.state.pagination}
              loading={this.state.loading}
              onChange={this.handleTableChange}
              scroll={{ x: "100%" }}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...orderActions, ...B2B2COrgActions }, dispatch);
}
const WrappedForm = Form.create({ name: "edit-order" })(
  EditB2B2cOrganizationLicense
);
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    WrappedForm
  )
);
