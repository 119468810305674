import API from "../api/product-api";
import {notifyUser} from "../../services/notification-service"
export function getProducts({ filters, pagination = { page: 1, pageSize: 20 }, sorter }) {
    return async function(dispatch, getState) {
      try {
        let resp = await API.getProductsData({
          filters: filters,
          pagination: pagination,
          sorter : sorter
        });
        return resp;
  
      } catch (e) {
        return {"error" : true};
      }
    };
  }
  export function getProduct(idx) {
    return async function(dispatch, getState) {
      try {
        let resp = await API.getProduct(idx);
       return resp.data;
      } catch (e) {
        /*dispatch({
          type: Types.USER_LISTING_ERROR,
          payload: { errorMessage: "Listing error", isLoggedIn: false }
        })*/;
        return {"error" : true};
      }
    };
  }

  export function updateProduct(data) {
    return async function(dispatch, getState) {
      try {
        let resp = await API.updateProduct(data);
        return resp;
      } catch (e) {
        return {"error" : true};
      }
    };
  }

  export function addProduct(data) {
    return async function(dispatch, getState) {
      try {
        let resp = await API.addProduct(data);
        return resp;
      } catch (e) {
        return {"error" : true};
      }
    };
  }

  export function updateProductStatus(data) {
    return async function(dispatch, getState) {
      try {
        let resp = await API.updateProductStatus(data);
        if(resp){
          notifyUser("Product Status Updated", 'info');
        }
      } catch (e) {
        notifyUser("Error Updating Product Status", 'error');
        return {"error" : true};
      }
    };
  }