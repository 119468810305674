import Axios from "../../services/axios-service";

const getTravellersList = data => {
  return Axios.get(
    "/api/Travelers?search=" + encodeURIComponent(JSON.stringify(data))+(data.filters && data.filters.orgId ? "&orgId="+data.filters.orgId : ""),
    undefined,
    undefined
  );
};
const getB2CTravellersList = data => {
  return Axios.get(
    "/api/Travelers/B2C?search=" + encodeURIComponent(JSON.stringify(data))+(data.filters && data.filters.orgId ? "&orgId="+data.filters.orgId : ""),
    undefined,
    undefined
  );
};
const getTravellerbyId = data => {
  return Axios.get("/api/travelers/" + data, undefined, undefined);
};
const updateTraveller = (data, id) => {
  return Axios.put("/api/travelers/" + id, data, undefined);
};
const addTraveller = data => {
  return Axios.post("/api/travelers/", data, undefined);
};
const getTravellerLocations = data => {
  return Axios.get(
    "/userLocations?search=" + JSON.stringify(data),
    undefined,
    undefined
  );
};
const assignIdentityToTraveller = data => {
  return Axios.post("/api/Users/AssignIdentity", data, undefined, undefined);
};

const getTravelersGeoLocation = data => {
  return Axios.get("/api/TravelersGeoLocation", undefined, undefined);
};

export default {
  getTravellersList,
  getB2CTravellersList,
  getTravellerbyId,
  updateTraveller,
  addTraveller,
  getTravellerLocations,
  assignIdentityToTraveller,
  getTravelersGeoLocation
};
