import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as userActions from "../../../redux/actions/user-actions";
import DataTable from "../../shared/components/DataTable";
import IntlMessages from "../../../services/intlMesseges";
import { Row, Col } from "react-bootstrap";

class UserListing extends Component {
  constructor(props) {
    super(props);
    this.state = { userListing: {}, loading: false };
  }

  getHeaderKeys = () => {
    return [
      { key: "id", lable: "ID" },
      { key: "first", lable: "First Name" },
      { key: "last", lable: "Last Name" },
      { key: "email", lable: "Email" },
      { key: "phone", lable: "Phone" },
      { key: "action", lable: "Action", ignore: true }
    ];
  };
  async componentDidMount() {
    this.paginateHandlefunction(1);
  }

  deleteItem = id => {
    let confirmDelete = window.confirm("Delete item forever?");
    if (confirmDelete) {
      this.setState({ loading: true });
      this.props
        .deleteUser({ id: id })
        .then(item => {
          this.setState({ loading: false });
          this.props.history.push("users");
        })
        .catch(err => {
          console.log(err);
          this.setState({ loading: false });
        });
    }
  };

  editItem = id => {
    this.props.history.push("users/" + id);
  };

  paginateHandlefunction = pageNumber => {
    console.log(`active page is ${pageNumber}`);
    this.setState({ loading: true });
    this.props
      .getUserListing({ filters: {}, pagination: { page: pageNumber } })
      .then(resp => {
        this.setState({ loading: false, userListing: resp });
      })
      .catch(ex => {
        this.setState({ loading: false });
      });
  };

  render() {
    const {
      userListing: { dataItems, pagination }
    } = this.state;
    return (
      <div className="">
        <Row>
          <Col>
            <h1 style={{ margin: "20px 0" }}>
              {" "}
              <IntlMessages id="admin.userlisting.manageUsers" />
            </h1>
          </Col>
        </Row>
        <Row>
          <Col>
            <DataTable
              items={dataItems || []}
              pagination={pagination}
              headerData={this.getHeaderKeys()}
              paginateHandle={this.paginateHandlefunction}
              deleteItemFromState={this.deleteItem}
              editItemFromState={this.editItem}
              loading={this.state.loading}
            ></DataTable>
          </Col>
        </Row>
      </div>
    );
  }
}

UserListing.propTypes = {
  location: PropTypes.object,
  getUserListing: PropTypes.func
};
function mapStateToProps(state) {
  return {
    ...state.user
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...userActions }, dispatch);
}
export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    { forwardRef: true }
  )(UserListing)
);
