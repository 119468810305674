import React, { Component } from "react";
export default class GlobeIconsvg extends Component {
  render() {
    return (
      <svg className="svg" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 125.2 105">
        <g>
          <path
            className="st0"
            d="M87.7,18.2c-4.4-4.4-9.6-7.9-15.4-10.3c-5.6-2.3-11.7-3.6-18.1-3.6c-6.4,0-12.5,1.3-18.1,3.6
         c-5.8,2.4-11,5.9-15.4,10.3c-4.4,4.4-7.9,9.6-10.3,15.4c-2.3,5.6-3.6,11.7-3.6,18.1c0,6.4,1.3,12.5,3.6,18.1
         c2.4,5.8,5.9,11,10.3,15.4c4.4,4.4,9.6,7.9,15.4,10.3c5.6,2.3,11.7,3.6,18.1,3.6c6.4,0,12.5-1.3,18.1-3.6c5.8-2.4,11-5.9,15.4-10.3
         c4.4-4.4,7.9-9.6,10.3-15.4c2.3-5.6,3.6-11.7,3.6-18.1c0-6.4-1.3-12.5-3.6-18.1C95.6,27.8,92.1,22.6,87.7,18.2z"
          />
          <path
            className="st0"
            d="M51.1,4.4C40,5.2,29.2,9.7,20.8,18.2c-4.5,4.5-8,9.8-10.3,15.3c-4.8,11.6-4.8,24.7,0,36.3
         c1.9,4.6,4.6,9,8.1,13c0.3,0.3,0.6,0.4,1,0.5c0.4,0,0.7-0.1,1-0.4c3.1-3.6,12.3-6.1,12.9-10.9c0.5-3.6-5.4-5-7-8.2
         c-1.8-3.7-0.4-8.5-2.6-11.9c-2.4-3.5-11.4-3.8-13.8-7.2c-1.3-1.9-0.3-4.7,0.4-6.9c0.3-1,1.5-1.5,2.4-1.8c1.4-0.4,2.9,0,4.3,0
         c0.1,0,13.5-2,17.6-3.8c4.1-1.8,1.9-4.1,3.7-4.9c1.8-0.7,4.6,4.7,7.4,2.3c2.8-2.4,5-14.1,6-16.8c1-2.7,4.7-0.1,5.7,0.5
         c1,0.6-1.7,5.8-0.5,6.8c1.3,1,6.5-2.4,7.1-3c3.9-3.9,3-7.5,0.8-9.2C62.7,6.1,56,8.4,52.1,4.8C51.8,4.5,51.5,4.4,51.1,4.4z"
          />
          <path
            className="st0"
            d="M90.2,20.9c1.7,2,6.1,8.4,8,13.1c2.3,5.8,3.5,12,3.4,18.2c-0.1,6.2-1.3,12.3-3.8,18.1
         C95.4,75.8,92,81,87.4,85.5c-4.6,4.5-9.8,7.9-15.4,10.1c-2.3,0.9,4.3-6.9,0.2-11.2c-4.1-4.3-21.5,0.7-21-8.2
         c0.5-8.9,4.3-8.8,6.4-13.5c2-4.6,4.5-0.9,13.2-3.8c8.7-3,20.2-2.5,24-6.5c3.8-3.9-14.4,0.8-17.8-0.1c-3.4-0.8-4-4.9-6.4-4.7
         c-2.4,0.1-1,4.5-3.7,5.3c-2.7,0.8-9.9,2.1-10.3-1c-1.7-11.7,7.4-8,7.9-8.7c2.4-2.8-2.9-5,0.4-8.9c3.2-3.9,6.4-4.6,8.1-7.3
         c1.7-2.7-4-4.8-1.4-7C74.2,17.9,88.4,18.8,90.2,20.9z"
          />
        </g>
        <path
          className="st1"
          d="M81.9,90.1c-0.4-0.4-0.4-1.1,0.1-2l-5.6-3.7c0.6-0.6,1.2-1,2.2-1.1l6.5-0.9c0.5-0.1,1-0.1,1.4-0.2
     c0.7-1.1,2.9-3.9,4.1-5.3l3-3.9l-14.3-4.3c-1.5-0.4,1.3-3.8,2.7-3.4l18.9,0.5l4.8-4.8c3.7-3.7,8.7-5.4,9.9-4.3
     c1.1,1.1-0.6,6.1-4.3,9.9l-4.8,4.8l0.5,18.9c0.4,1.4-3,4.2-3.4,2.7l-4.3-14.3l-3.9,3c-1.5,1.1-4.2,3.3-5.3,4.1
     c-0.1,0.4-0.1,0.9-0.2,1.4l-0.9,6.5c-0.1,1-0.5,1.6-1.1,2.2L84,90C83.1,90.5,82.3,90.5,81.9,90.1z"
        />
      </svg>
    );
  }
}
