import React, { Component } from "react";
import { Tabs, Button } from "antd";
import moment from "moment";
import config from "../../../../../../config";

const { TabPane } = Tabs;
const dateFormat = "MM/DD/YYYY HH:mm";
let flightStatus = {
  L: "LANDED",
  l: "LANDED",
  LANDED: "LANDED",
  SCHEDULED: "SCHEDULED",
  S: "SCHEDULED",
  s: "SCHEDULED",
  A: "In-Transit",
  U: "Unknown",
  PAXC: "Cancelled By Passenger",
  C: "CANCELED",
  c: "CANCELED"
};

export default class TravelerDetailsByAirport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      travelerData: ""
    };
  }

  componentDidMount() {}

  onInfoWindowPersonTabClick = key => {
    let arr = key.split("-");
    let pnrId = arr[1];
    this.props.onInfoWindowPersonTabClick(pnrId);
  };

  _bindTravelerInfoWindowContent(data) {
    return (
      <Tabs
        className="MapTravellerToolTip"
        tabPosition="left"
        onTabClick={this.onInfoWindowPersonTabClick}
      >
        {this._bindTravelerDetails(data)}
      </Tabs>
    );
  }

  _bindTravelerDetails(travellers) {
    return travellers.map((value, i) => (
      <TabPane
        tab={
          <span>
            <img src={config.API1 + "/travel-icons/" + value.icon}></img>
            {value.firstName +" "+ value.lastName}
          </span>
        }
        key={value.email + value.segmentNumber + i + "-" + value.pnrId}
      >
        <div className="headerContent">
          <i className="fas fa-user"></i>{" "}
          {value.firstName +" "+ value.lastName}
        </div>
        <ul>
          <li>
            <span>Status:</span> {flightStatus[value.status]}
          </li>
          <li>
            <span>Departure:</span>{" "}
            {value.departureAirportCode + " " + value.departureAirport}
          </li>
          <li>
            <span>Date/Time:</span>{" "}
            {moment(value.departureDateTime).format(dateFormat)}
          </li>
          <li>
            <span>Arrival:</span>{" "}
            {value.arrivalAirportCode + " " + value.arrivalAirport}
          </li>
          <li>
            <span>Date/Time:</span>{" "}
            {moment(value.arrivalDateTime).format(dateFormat)}
          </li>
          {this._bindEmergencyContactDetails(value)}
          {value.source !== "Manual" && (
            <li>
              <Button
                type="primary"
                onClick={() => {
                  const url =
                    "traveler-itinerary-viewer/" +
                    value.pnrId +
                    "/" +
                    value.email;
                  window.open(url);
                }}
              >
                <i className="fas fa-atlas"></i>
              </Button>
            </li>
          )}
        </ul>
      </TabPane>
    ));
  }

  _bindEmergencyContactDetails(data) {
    let countryCode = "";
    if (flightStatus[data.status] === "LANDED") {
      countryCode = data.arrivalCountryCode;
    } else {
      countryCode = data.departureCountryCode;
    }

    let emergenyContacts = this.props.adminConfig.emergencyContacts[
      countryCode
    ];
    if (emergenyContacts && emergenyContacts.length > 0) {
      return emergenyContacts.map(
        (value, i) =>
          value.number !== "0" &&
          value.number !== "" && (
            <li>
              <span>{value.service_category}:</span>
              {value.number}
            </li>
          )
      );
    }
  }

  render() {
    return this._bindTravelerInfoWindowContent(
      this.props.travelerInfoWindowData
    );
  }
}
